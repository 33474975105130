const ProspectingMap = require('./ProspectingMap');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./ProspectingMapPage.jade');
const {isKartoEnabled} = require('../../utils/Karto');

// @vue/component
module.exports = {
    bemName: 'ProspectingMapPage',
    components: {
        ProspectingMap,
    },
    mixins: [
        i18nMixin({
            prefix: 'ProspectingMapPage.',
            keys: ['errorMessage'],
        }),
    ],
    computed: {
        useKarto() {
            const pageName = 'prospectingMapPage';
            return isKartoEnabled(pageName);
        },
    },
    template: template(),
};
