const $ = require('jquery');
const _ = require('lodash');

const validators = {
    intlPhone: require('./IntlPhoneValidator'),
    authRestRemote: require('./AuthRestRemoteValidator'),
    minMaxDates: require('./MinMaxDatesValidator'),
    stripeElement: require('./StripeElementValidator'),
    numberFactor: require('./NumberFactorValidator'),
};

_.forEach(validators, (validator, key) => {
    $.fn.bootstrapValidator.validators[key] = validator;
});
