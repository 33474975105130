const _ = require('lodash');
const {isMobile, isTablet} = require('browser-detect');

const template = require('../templates/savedSearches/savedSearchesView.jade');
const VueView = require('../vue/VueView');
const Account = require('../authentication/Account');
const BrowserNotifications = require('../notifications/BrowserNotifications');
const EventPack = require('../utils/EventPack');
const i18nMixin = require('../vue/components/mixins/i18n');
const AuthenticationPopup = require('../authentication/AuthenticationPopup');

class SavedSearchesView extends VueView {
    constructor() {
        super({
            template,
        });
        this._eventPack = new EventPack();
    }

    show(options) {
        const view = this;
        const vueData = this.vueData = {
            translationContext: (isMobile() || isTablet()) ? 'mobileOrTablet' : 'desktop',
            displayDeniedNotificationsMessage: false,
            displayNotificationsBlock: false,
            notificationsEnabled: false,
            savedSearches: [],
            hasError: false,
            isLoading: false,
            savedSearchesInError: {},
        };
        // @vue/component
        const vueOptions = {
            bemName: 'saved-searches-page',
            mixins: [
                i18nMixin({
                    prefix: 'savedSearchesView.',
                    keys: [
                        'noSavedSearches',
                        'createSavedSearch',
                        'accountCreationPitch',
                        'createAccount',
                        'error',
                        'savedSearchesTitle',
                    ],
                }),
            ],
            data() {
                return vueData;
            },
            computed: {
                deniedNotificationsMessage() {
                    return this.t('notification.enable.messageNotAllowed', {context: this.translationContext});
                },
                notificationsAction() {
                    return this.notificationsEnabled ? 'disable' : 'enable';
                },
                notificationsBlockText() {
                    return this.t(`notification.${this.notificationsAction}.message`, {context: this.translationContext});
                },
                notificationsButtonText() {
                    return this.t(`notification.${this.notificationsAction}.button`);
                },
                showNotificationState() {
                    return true;
                },
                showPushNotificationState() {
                    return Account.hasPushNotifications() && !BrowserNotifications.areNotificationsUnsupportedOrDenied();
                },
                showNotificationIcon() {
                    return false;
                },
                criteriaModificationEnabled() {
                    return false;
                },
                criteriaModificationHidden() {
                    return false;
                },
                isRegistered() {
                    const account = Account.getAuthenticatedAccount();
                    return Account.isRegistered(account);
                },
                hasSavedSearches() {
                    return !_.isEmpty(this.savedSearches);
                },
            },
            methods: {
                onCreateSavedSearch() {
                    view.emit('createSaveSearch');
                },
                editCriteria(savedSearchId) {
                    view.emit('editCriteria', savedSearchId);
                },
                editFrequency(savedSearchId) {
                    view.emit('editFrequency', savedSearchId);
                },
                deleteSaveSearch(savedSearchId) {
                    view.emit('deleteSaveSearch', savedSearchId);
                },
                onCreateAccount() {
                    const authenticationPopup = new AuthenticationPopup();
                    authenticationPopup.showCreateAccount();
                },
                onNotificationsButtonClick() {
                    switch (this.notificationsAction) {
                        case 'enable':
                            BrowserNotifications.enablePushNotifications({silent: false}, (err) => {
                                if (err) {
                                    console.error(err);
                                }
                            });
                            break;
                        case 'disable':
                            BrowserNotifications.disablePushNotifications((err) => {
                                if (err) {
                                    console.error(err);
                                }
                            });
                            break;
                    }
                },
            },
        };
        super.show(options, vueOptions);
        this._bindEvents();
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
    }

    startLoading() {
        _.extend(this.vueData, {
            hasError: false,
            isLoading: true,
        });
    }

    showSavedSearches(savedSearches) {
        const {vueData} = this;
        vueData.savedSearches = savedSearches;
        vueData.isLoading = false;
        this._updatePushNotificationsBlockState();
    }

    _updatePushNotificationsBlockState() {
        const {vueData} = this;
        if (BrowserNotifications.areNotificationsSupported()) {
            vueData.displayNotificationsBlock = true;
            vueData.notificationsEnabled = BrowserNotifications.areNotificationsCurrentlyEnabled();
            vueData.displayDeniedNotificationsMessage = BrowserNotifications.areNotificationsDenied();
        } else {
            vueData.displayNotificationsBlock = false;
            vueData.displayDeniedNotificationsMessage = false;
        }
    }

    showErrorMessage() {
        _.extend(this.vueData, {
            hasError: true,
            isLoading: false,
        });
    }

    updateSavedSearch(savedSearch, previousSavedSearchId) {
        const newSavedSearch = _.cloneDeep(savedSearch);
        const {savedSearches} = this.vueData;
        const savedSearchIdToReplace = previousSavedSearchId || newSavedSearch._id;
        const indexToReplace = savedSearches.findIndex(savedSearch => savedSearch._id === savedSearchIdToReplace);
        savedSearches.splice(indexToReplace, 1, newSavedSearch);
    }

    addSavedSearch(savedSearch) {
        const newSavedSearch = _.cloneDeep(savedSearch);
        const {savedSearches} = this.vueData;
        const currentIndex = _.findIndex(savedSearches, {_id: newSavedSearch._id});
        if (currentIndex >= 0) {
            savedSearches.splice(currentIndex, 1, newSavedSearch);
        } else {
            savedSearches.unshift(newSavedSearch);
        }
    }

    removeSavedSearch(error, id) {
        const {savedSearches} = this.vueData;
        const currentIndex = _.findIndex(savedSearches, {_id: id});
        if (error) {
            this.vueData.savedSearchesInError = _.extend(
                {},
                this.vueData.savedSearchesInError,
                {
                    [id]: true,
                }
            );
            console.warn('remove Saved Search (' + id + ') : error : ', error);
        } else {
            savedSearches.splice(currentIndex, 1);
        }
    }

    _bindEvents() {
        this._eventPack.on(BrowserNotifications, 'notificationEnabledChanged',
            _.bind(this._updatePushNotificationsBlockState, this));
    }
}

module.exports = SavedSearchesView;
