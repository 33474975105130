const _ = require('lodash');
const {isMobile} = require('browser-detect');

const ContactHelper = require('./utils/ContactHelper');
const localStorage = require('./utils/localStorage/LocalStorage');
const isNewPropertyAd = require('./realEstateAdContact/isNewPropertyAd');
const {isProgrammeWithAtLeastOneLotHousing} = require('../common/ProgrammeHelper');
const PropertyTypes = require('../common/PropertyTypes');
const AdTypeHelper = require('../common/AdTypeHelper');
const {
    AGENCY_ACCOUNT_TYPE,
    MANDATARY_ACCOUNT_TYPE,
    PROMOTEUR_ACCOUNT_TYPES,
    BROKER_ACCOUNT_TYPE,
} = require('../common/AccountTypes');

const DEFAULT_APPROVAL_QUESTIONS = [
    {
        name: 'wantsToReceiveSimilarSuggestions',
        isReversed: true,
        shouldDisplay: function (account) {
            const answer = account[this.name];
            if (answer === false) {
                const lastUpdate = localStorage.getValue(`${this.name}LastUpdate`);
                if (!lastUpdate) {
                    return true;
                } else {
                    const dayInMs = 1000 * 60 * 60 * 24;
                    const lastUpdateDate = new Date(lastUpdate);
                    const now = new Date();
                    const diff = now.getTime() - lastUpdateDate.getTime();
                    return diff > dayInMs;
                }
            } else {
                return !_.isBoolean(answer) || (answer === false && !this.isReversed);
            }
        },
    },
];

function getMapping() {
    const approvalQuestions =
        ContactHelper.getApprovalQuestionsWithOptions(DEFAULT_APPROVAL_QUESTIONS);

    const mapping = {
        'bouygues-immobilier': {
            isFirstNameRequired: true,
            showPostalCodeInput: true,
            showIsInvestmentProjectQuestion: true,
            approvalQuestions,
            userMessageMaxLength: 255,
        },
        capelli: {
            showIsInvestmentProjectQuestion: true,
            approvalQuestions,
        },
        'les-nouveaux-constructeurs': {
            isFirstNameRequired: true,
        },
        mgc: {
            approvalQuestions,
        },
        nexity: {
            isFirstNameRequired: true,
        },
        'vinci-ws': {
            showIsInvestmentProjectQuestion: true,
        },
        visiteonline: {
            showAskForInfo: false,
            showMessageInput: false,
            showPostalCodeInput: true,
            showIsInvestmentProjectQuestion: true,
            isFirstNameRequired: true,
        },
    };

    _.each([
        'copra',
        'demathieu-bard',
        'eiffage',
        'equinox',
        'lamotte',
        'premium-promotion',
        'sully',
        'urbis',
    ], name => {
        mapping['clickCall-' + name] = {
            isFirstNameRequired: true,
            showPostalCodeInput: true,
            showIsInvestmentProjectQuestion: true,
        };
    });
    return mapping;
}

function getDefaultMapping() {
    return {
        showAskForInfo: true,
        showMessageInput: true,
        approvalQuestions: ContactHelper.getApprovalQuestionsWithOptions(DEFAULT_APPROVAL_QUESTIONS),
    };
}

module.exports = {
    getContactFlags,
};

function _getFlagsByAccountAndAdProperties(accountAndRealEstateAdInfo) {
    const {accountType, realEstateAd} = accountAndRealEstateAdInfo;
    const {propertyType, programmeRef} = realEstateAd;
    const flags = {};
    const displayHasPropertyToSellQuestion = AdTypeHelper.isBuyTransactionAd(realEstateAd)
        && _.includes([AGENCY_ACCOUNT_TYPE, MANDATARY_ACCOUNT_TYPE], accountType);
    if (isNewPropertyAd({accountType, propertyType, programmeRef})) {
        flags.showPostalCodeInput = true;
        if (isMobile()) {
            flags.showMessageInput = false;
        }
        if (isProgrammeOrLotHousing(accountAndRealEstateAdInfo)) {
            flags.showIsInvestmentProjectQuestion = true;
        }
    } else if (displayHasPropertyToSellQuestion) {
        flags.showHasPropertyToSellQuestion = true;
    }
    return flags;
}

function getContactFlags({contactEmailType, accountType} = {}, realEstateAd = {}) {
    return _.defaults(
        (contactEmailType && getMapping()[contactEmailType]) || {},
        _getFlagsByAccountAndAdProperties({accountType, realEstateAd}),
        getDefaultMapping()
    );
}

function isProgrammeOrLotHousing(accountAndRealEstateAdInfo) {
    const {accountType, realEstateAd} = accountAndRealEstateAdInfo;
    const {propertyType, hasToBeBuilt} = realEstateAd;
    const isLotHousing = _.includes(PropertyTypes.housingSingle, propertyType)
        && _.includes([...PROMOTEUR_ACCOUNT_TYPES, BROKER_ACCOUNT_TYPE], accountType)
        && !hasToBeBuilt;
    return isProgrammeWithAtLeastOneLotHousing(realEstateAd) || isLotHousing;
}
