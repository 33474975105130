const ServerConfig = require('./ServerConfig');
const {getImageUrlFromAliasAndFileUrl} = require('../common/FileServerUrlGenerator');

module.exports = {
    getImageUrlFromAlias,
};

function getImageUrlFromAlias(imageName, parameters) {
    return getImageUrlFromAliasAndFileUrl(ServerConfig.config.fileUrl, imageName, parameters);
}
