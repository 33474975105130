const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'energy';

module.exports = namespaceComponents(NAMESPACE, {
    'rating': require('./Rating'),
    'chart': require('./Chart'),
    'diagnostics': require('./Diagnostics'),
    'diagnostic': require('./Diagnostic'),
    'dpe': require('./newEnergyDiagnosticComponents/Dpe'),
    'ges': require('./newEnergyDiagnosticComponents/Ges'),
    'consumption': require('./Consumption'),
});
