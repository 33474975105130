const _ = require('lodash');
const template = require('./RatingStars.jade');
const RatingStarsIcon = require('./RatingStarsIcon');
const commonProps = require('./mixins/commonProps');
const NumberFormatter = require('../../../../common/NumberFormatter');

const {
    EMPTY_STAR_COLOR_FILL_PERCENTAGE,
    FULL_STAR_COLOR_FILL_PERCENTAGE,
} = require('./constants');
const STAR_NUMBER = 5;
const MIN_RATING = 0;
const MAX_RATING = STAR_NUMBER;
const DECIMAL_AFTER_THE_COMMA_FOR_RATING_TEXT = 1;

// @vue/component
module.exports = {
    components: {
        RatingStarsIcon,
    },
    mixins: [
        commonProps,
    ],
    props: {
        rating: {
            type: Number,
            required: true,
            validator: rating => rating >= MIN_RATING && rating <= MAX_RATING,
        },
        showRatingText: Boolean,
    },
    computed: {
        starsColorFillPercentageList() {
            const starIndexes = _.range(STAR_NUMBER);
            return _.map(starIndexes, this.getStarColorFillPercentage);
        },
        ratingText() {
            return `${NumberFormatter.formatNumber(this.rating, DECIMAL_AFTER_THE_COMMA_FOR_RATING_TEXT)}/${MAX_RATING}`;
        },
    },
    methods: {
        getStarColorFillPercentage(index) {
            const {rating} = this;
            let colorFillPercentage;
            if (index <= Math.floor(rating - 1)) {
                colorFillPercentage = FULL_STAR_COLOR_FILL_PERCENTAGE;
            } else if (index > Math.ceil(rating - 1)) {
                colorFillPercentage = EMPTY_STAR_COLOR_FILL_PERCENTAGE;
            } else {
                colorFillPercentage = Math.round((rating - index) * 100);
            }
            return colorFillPercentage;
        },
    },
    template: template(),
};
