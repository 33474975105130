const $ = require('jquery');
const Account = require('./Account');
const {i18n: {translate}} = require('fack');
const _ = require('lodash');
const {EventEmitter} = require('events');
const FormEnterKeyHandler = require('../fields/FormEnterKeyHandler');
const ServerConfig = require('../ServerConfig');
const ApplicationConfig = require('../app/ApplicationConfig');
const Recaptcha = require('./Recaptcha');
const UNHANDLED_ERROR_KEY = 'unhandled';

module.exports = LoginForm;

/*
 * requires a form with email & password fields, a .tryAuthenticate button
 * and optionally a .loading section and .errorZone section
 */
function LoginForm($container) {
    const emitter = new EventEmitter();
    const $form = $container.find('form');
    let submitEnabled = true;
    const $submitButton = $form.find('[type="submit"]');
    let captchaId;
    const $captcha = $form.find('#captcha');
    $captcha.hide();
    const canDisplayCaptcha = ServerConfig.config.recaptchaSiteKey && $captcha;
    if (canDisplayCaptcha) {
        Recaptcha.preload();
        checkIfMustDisplayCaptcha(); //email field can be prefilled
        $form.find('input[name=email]').on('change', checkIfMustDisplayCaptcha);
    }
    $form.on('submit', submitForm);
    FormEnterKeyHandler.init($form);

    //not binding button click since it's a submit button so it'll work out of the box anyway
    //enter key is also working out of the box since it's a basic form submit
    $container.on('click', '.lostPassword', lostPassword);

    return emitter;

    function submitForm() {
        if (submitEnabled) {
            authenticate();
        }
        // never submit form: it is useless for authentication and can lead to password reveal (see f4/kimono/www#10042)
        return false;
    }

    function disableSubmit() {
        $submitButton.prop('disabled', true);
        submitEnabled = false;
    }

    function enableSubmit() {
        $submitButton.prop('disabled', false);
        submitEnabled = true;
    }

    function authenticate() {
        const $credentialId = $form.find('input[name=email]');
        const credentialId = $credentialId.val();
        const $password = $form.find('input[name=password]');
        const password = $password.val();

        if (!credentialId) {
            showEmptyError($credentialId);
        }
        if (!password) {
            showEmptyError($password);
        }
        let captchaToken;
        let blockSubmitBecauseOfCaptcha = false;
        if (captchaId != undefined) {
            captchaToken = Recaptcha.getResponse(captchaId);
            if (!captchaToken) {
                blockSubmitBecauseOfCaptcha = true;
            }
        }
        if (credentialId && password && !blockSubmitBecauseOfCaptcha) {
            toggleLoading(true);
            clearError();
            const dataToSend = {
                id: credentialId,
                password,
            };
            if (captchaId != undefined) {
                dataToSend.captcha = captchaToken;
            }
            Account.authenticate(dataToSend, function (err, authenticationResult) {
                toggleLoading(false);
                const credentialsErrorKey = ApplicationConfig.applicationPro ? 'proCredentials' :
                    'publicCredentials';
                if (err) {
                    if (err.message == 'timeout' || err.message == 'abort') {
                        //abort occurs on timeout in CORS mode.
                        showError('timeout');
                    } else if (err.statusCode >= 500) {
                        showError('server');
                    } else {
                        console.warn('unhandled error type', err);
                        showError(UNHANDLED_ERROR_KEY);
                    }
                } else if (!authenticationResult.success && authenticationResult.reason) {
                    if (authenticationResult.reason.message == 'requiresProAccount') {
                        showError('proRequired');
                    } else if (authenticationResult.reason.message == 'requiresPublicAccount') {
                        showError('publicRequired');
                    } else if (authenticationResult.reason.message == 'closedOrDisabled') {
                        showError('closedOrDisabled');
                    } else if (authenticationResult.reason.message == 'invalidCaptcha') {
                        displayCaptcha({reload: true});
                        showError('invalidCaptcha');
                    } else {
                        checkIfMustDisplayCaptcha({reload: true});
                        console.warn('unhandled reason', authenticationResult.reason);
                        showError(UNHANDLED_ERROR_KEY);
                    }
                } else if (!authenticationResult.success) {
                    checkIfMustDisplayCaptcha({reload: true});
                    showError(credentialsErrorKey);
                }
            });
        }
    }

    function toggleLoading(enabled) {
        $form.find('.loginBoxMiddle').toggleClass('isLoading', enabled);
    }

    function clearEmptyError($element) {
        const $formGroup = $element.closest('.form-group');
        $formGroup.removeClass('has-error');
        $element.off('change.clearEmptyError');
    }

    function showEmptyError($element) {
        const $formGroup = $element.closest('.form-group');
        if ($formGroup.hasClass('has-error')) {
            return;
        }
        $formGroup.addClass('has-error');
        $element.on('change.clearEmptyError', _.partial(clearEmptyError, $element));
    }

    function clearError() {
        $form.find('.errorMessage').remove();
        $form.removeClass('has-error');
        $form.off('change.clearError', 'input');
    }

    function showError(errorKeySuffix) {
        if ($form.hasClass('has-error')) {
            return;
        }
        $form.addClass('has-error');
        const $errorMessage = $('<span class="errorMessage"/>');
        $errorMessage.text(translate('loginError.' + errorKeySuffix));
        $form.find('.errorZone').append($errorMessage);
        $form.on('change.clearError', 'input', clearError);
    }

    function lostPassword() {
        const $modal = $('.modal');
        if ($modal) {
            $modal.modal('hide');
        }
    }

    function checkIfMustDisplayCaptcha(options) {
        const $email = $form.find('input[name=email]');
        const $emailSpinner = $form.find('.emailSpinner');
        const login = $email.val();
        if (login) {
            //make the request and disable submit while doing the request
            disableSubmit();
            $emailSpinner.show();
            // eslint-disable-next-line handle-callback-err
            Account.captchaValidationRequired(login, function (err, result) {
                $emailSpinner.hide();
                if (result && result.captchaValidationRequired) {
                    displayCaptcha(options);
                } else {
                    if (options && options.reload && captchaId != undefined) {
                        Recaptcha.reset(captchaId);
                    }
                    enableSubmit();
                }
            });
        }
    }

    function displayCaptcha(options) {
        options = _.defaults(options, {
            reload: false,
        });
        const captchaIsAlreadyShown = (captchaId != undefined);
        if (!captchaIsAlreadyShown) {
            $captcha.show();
            Recaptcha.render($captcha[0], {
                sitekey: ServerConfig.config.recaptchaSiteKey,
                size: $form.width() < 304 ? 'compact' : 'normal',
                callback: enableSubmit,
                'expired-callback': disableSubmit,
            }, (id) => {
                captchaId = id;
            });
        } else if (options.reload) {
            Recaptcha.reset(captchaId);
        }
    }

}
