const _ = require('lodash');
const {EventEmitter} = require('events');

const SlideshowFullscreenView = require('./SlideshowFullscreenView');
const SlideshowView = require('./SlideshowView');
const EventPack = require('../utils/EventPack');

module.exports = class SlideshowWidget extends EventEmitter {
    constructor(options) {
        options = _.defaults(options || {}, {});
        super(options);
        this._slideshowFullscreenView = new SlideshowFullscreenView(options);
        this._slideshowView = new SlideshowView(options);
        this._eventPack = new EventPack();
        this._eventPackFullscreen = new EventPack();
    }

    updateContactInfo(contact) {
        this._slideshowView.updateContactInfo(contact);
        this._slideshowFullscreenView.updateContactInfo(contact);
    }

    show(options) {
        this._eventPack.on(this._slideshowView, {
            enterFullscreen: _.bind(this._enterFullscreen, this),
            showAdContent: _.bind(this.emit, this, 'showAdContent'),
            openContactSection: _.bind(this.emit, this, 'openContactSection'),
        });
        this._eventPackFullscreen.on(this._slideshowFullscreenView, {
            exitFullscreen: _.bind(this.exitFullscreen, this),
            showAdContent: _.bind(this.emit, this, 'showAdContent'),
            openContactSection: _.bind(this.emit, this, 'openContactSection'),
            openLiveRoom: _.bind(this.emit, this, 'openLiveRoom'),
        });
        this._slideshowView.show(options);
    }

    hide() {
        this._eventPack.removeAllListeners();
        this._eventPackFullscreen.removeAllListeners();
        this._slideshowView.hide();
        this._slideshowFullscreenView.hide();
    }

    _enterFullscreen() {
        if (!this._slideshowFullscreenView.isShown()) {
            this._slideshowView.hide();
            this._slideshowFullscreenView.show({startPhotoIndex: this._slideshowView.getCurrentPhotoIndex()});
        }
    }

    exitFullscreen() {
        if (this._slideshowFullscreenView.isShown()) {
            this._slideshowFullscreenView.hide();
            this._slideshowView.show({startPhotoIndex: this._slideshowFullscreenView.getCurrentPhotoIndex()});
        }
    }
};
