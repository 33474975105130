const _ = require('lodash');
const RealtimeServer = require('../RealtimeServer');
const {
    ADD_IN_CURRENT_FAVORITE_ADS,
    REMOVE_FROM_CURRENT_FAVORITE_ADS,
} = require('../store/Mutations');

module.exports = {
    namespaced: true,
    state() {
        return {
            currentFavoriteAdsIds: [],
        };
    },
    getters: {
        currentFavoriteAdsIds(state) {
            return state.currentFavoriteAdsIds;
        },
    },
    mutations: {
        [ADD_IN_CURRENT_FAVORITE_ADS](state, adsIds) {
            state.currentFavoriteAdsIds = _.union(state.currentFavoriteAdsIds, adsIds); // union also removes duplicates
        },
        [REMOVE_FROM_CURRENT_FAVORITE_ADS](state, adsIds) {
            state.currentFavoriteAdsIds = _.without(state.currentFavoriteAdsIds, ...adsIds);
        },
    },
    actions: {
        init({dispatch}) {
            RealtimeServer.on('ad:follower:toggle:success', ({id, followed}) => {
                dispatch('updateCurrentFavoriteAdsIds', [{id, isFavorite: followed}]);
            });
        },
        updateCurrentFavoriteAdsIds({commit}, adsIdsAndStates) {
            const adsIdsAndStatesPartition = _.partition(adsIdsAndStates, 'isFavorite');
            const [idsToAdd, idsToRemove] = _.map(adsIdsAndStatesPartition, obj => {
                return _.map(obj, 'id');
            });
            commit(ADD_IN_CURRENT_FAVORITE_ADS, idsToAdd);
            commit(REMOVE_FROM_CURRENT_FAVORITE_ADS, idsToRemove);
        },
    },
};
