const template = require('../templates/account/authenticationPage.jade');
const AuthenticationViewController = require('./AuthenticationViewController');
const _ = require('lodash');
const ApplicationConfig = require('../app/ApplicationConfig');

module.exports = class AuthenticationView extends AuthenticationViewController {
    constructor(options = {}) {
        const {$container, origin} = options;
        super({
            template,
            $container,
        });
        this.origin = origin;
    }

    getSections() {
        const {$element} = this;
        return _.extend({
            $body: $element.find('.bodyContainer'),
            $title: $element.find('.header h4'),
        }, super.getSections());
    }

    show(options) {
        const {requiredForPage} = options;
        super.show({
            showSocialConnection: AuthenticationViewController.options.showSocialConnection,
            showCreateAccount: AuthenticationViewController.options.showCreateAccount,
            disableAccountCreation: ApplicationConfig.disableAccountCreation,
            origin: this.origin,
        });

        if (requiredForPage) {
            _.invoke(requiredForPage, 'addAuthenticationAdditionalView', this);
        }
        this.$container.removeClass('isLoading');
        super.initEvents();

        super.fillDomForLogin();
    }
};
