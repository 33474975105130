const _ = require('lodash');
const ExternalAuthentication = require('./ExternalAuthentication');
const PROVIDERS = require('./ExternalAuthenticationProviders');
const NativeInterfaceProxy = require('./utils/NativeInterfaceProxy');

_.each(PROVIDERS, (provider) => {
    const method = `authenticateWith${_.capitalize(provider)}`;
    module.exports[method] = () => authenticate(method);
});
module.exports.PROVIDERS = PROVIDERS;

function authenticate(method) {
    const nativeMethod = NativeInterfaceProxy[method];
    if (nativeMethod) {
        nativeMethod();
    } else {
        ExternalAuthentication[method]();
    }
}
