const _ = require('lodash');

// @vue/component
module.exports = {
    data() {
        return {
            searchCriteria: {
                minPrice: null,
                maxPrice: null,
                minRooms: null,
                maxRooms: null,
                minArea: null,
                maxArea: null,
                minBedrooms: null,
                maxBedrooms: null,
                minGardenSurfaceArea: null,
                maxGardenSurfaceArea: null,
            },
        };
    },
    methods: {
        updateFormFromRange(event) {
            const {name, min, max} = event;
            this.searchCriteria['min' + _.upperFirst(name)] = min;
            this.searchCriteria['max' + _.upperFirst(name)] = max;
        },
    },
};
