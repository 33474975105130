const {i18n: {translate}} = require('fack');
const template = require('../templates/userDirectory/userContact.jade');
const GoogleTagManager = require('../stats/GoogleTagManager');
const EventPack = require('../utils/EventPack');
const VueView = require('../vue/VueView');
const AgencyContactForm = require('../agency/components/AgencyContactForm');

module.exports = class AgencyContactView extends VueView {
    constructor() {
        super({
            template,
        });
        this._eventPack = new EventPack();
    }

    show(options) {
        const {gtmFrom} = options;
        // @vue/component
        const vueOptions = {
            components: {
                AgencyContactForm,
            },
            data() {
                return {
                    isFormDisplayed: true,
                    agency: {
                        id: options.accountId,
                    },
                    displayName: options.displayName,
                };
            },
            computed: {
                formattedName() {
                    return this.displayName ?
                        translate('agencyContactView.title_withName', {name: this.displayName})
                        : translate('agencyContactView.title');
                },
            },
            mounted() {
                this.sendOpenContactModalGTMEvent();
            },
            methods: {
                handleAgencyContactFormSubmit(reason) {
                    GoogleTagManager.sendAgencyContactFormSubmittedEvent(this.agency.id, reason, gtmFrom);
                    this.isFormDisplayed = false;
                },
                sendOpenContactModalGTMEvent() {
                    GoogleTagManager.sendAgencyContactFormShownEvent(this.agency.id, gtmFrom);
                },
            },
            template: template(),
        };
        super.show(null, vueOptions);
    }
};
