const _ = require('lodash');
const $ = require('jquery');
const View = require('./views/View');
const MarkerIcon = require('./MarkerIcon');
const MapApi = require('./MapApi');
const MarkerUtil = require('./MarkerUtil');
const SunAnimatorView = require('./sun/SunAnimatorView');
const MapCreation = require('./utils/MapCreation');
const {isWebglEnabled} = require('./utils/MapHelper');
const async = require('async');
const Errors = require('./utils/Errors');
const BrowserDetect = require('browser-detect');
const ProgrammeModelsManager = require('./ProgrammeModelsManager');
const Options = require('./Options');

const mapTutorialTemplate = require('./templates/mapTutorial.jade');
const ApplicationConfig = require('./app/ApplicationConfig');
const CircleZoneAroundMeHelper = require('./geolocation/CircleZoneAroundMeHelper');
const CircleZoneHelper = require('../common/CircleZoneHelper');
const LimitHelper = require('./LimitHelper');
const Account = require('./authentication/Account');
const DetailedSheetAdOnMapView = require('./DetailedSheetAdOnMapView');
const EventPack = require('./utils/EventPack');
const MapOptionsLocalStorage = require('./utils/localStorage/MapOptionsLocalStorage');

const $BODY = $('body');
const LONG_CLICK_MIN_DURATION = 500;

module.exports = class SideMapView extends View {
    constructor() {
        super();
        this.mapEnabled = false;
        this.map = null;
        this.$map = null;
        this.overlayView = null;
        this.mapIdleCallback = _.bind(this._handleMapIdle, this);
        this._freeMap = (BrowserDetect.isIOS() && BrowserDetect.isMobile());
        this._isMapExpanded = false;
        this._on3dChangeHandler = _.bind(this._on3dChange, this);
        this._onZoomChangedHandler = _.bind(this._zoomChanged, this);
        this._onElevationsEnabledOptionChangedCbk = _.bind(this._onElevationsEnabledOptionChanged, this);
        this.sunAnimatorView = new SunAnimatorView();
        const highlightedAds3dMarkers = this._highlightedAds3dMarkers = {};
        this._detailedSheetAdOnMapView = new DetailedSheetAdOnMapView({highlightedAds3dMarkers});
        this._programmeModelsManagerEventPack = new EventPack();
    }

    _setSunAnimatorVisible(isVisible) {
        if (this.sunAnimatorView) {
            this.sunAnimatorView.hide();
            if (isVisible) {
                this.sunAnimatorView.show({
                    $container: this.$map,
                    map: this.map,
                });
            }
        }
    }

    updateLotPositionAndHeightFromModel(realEstateAd) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.updateLotPositionAndHeightFromModel(realEstateAd);
        }
    }

    setLotHovered(realEstateAd, hovered) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.setLotHovered(realEstateAd, hovered);
        }
    }

    setSelectedLot(realEstateAd, selected) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.setSelectedLot(realEstateAd, selected);
        }
    }

    setSelectedProgramme(realEstateAd, selected) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.setSelectedProgramme(realEstateAd, selected);
        }
    }

    _bindProgrammeModelsManager() {
        if (this._programmeModelsManager) {
            this._programmeModelsManagerEventPack.on(this._programmeModelsManager, {
                closeLot: (lot, event) => {
                    this.emit('closeLot', lot, event);
                },
                lotClick: (lot, event) => {
                    this.emit('lotClick', lot, event);
                },
                lotMouseOver: lot => {
                    this.emit('lotMouseOver', lot);
                },
                lotMouseOut: lot => {
                    this.emit('lotMouseOut', lot);
                },
                lotModelLoaded: lot => {
                    if (this.realEstateAd && this.realEstateAd.id == lot.id) {
                        this.updateLotPositionAndHeightFromModel(this.realEstateAd);
                        this._detailedSheetAdOnMapView.setMarkerPositionAndHeight(lot.position, lot.height);
                    }
                },
                cameraMarkerClick: facadeCameraInfo => {
                    const cameraConfig = facadeCameraInfo.cameraConfig;
                    cameraConfig.position = {
                        lat: cameraConfig.lat,
                        lon: cameraConfig.lon,
                    };
                    this.moveCameraToPosition(cameraConfig);
                },
                programmeModelSelected: (selected) => {
                    this.emit('programmeModelSelected', selected);
                },
            });
        }
    }

    _unbindProgrammeModelsManager() {
        this._programmeModelsManagerEventPack.removeAllListeners();
    }

    loadData(options, cb) {
        const tasks = {
            api: MapApi.init,
            markerIcon: MarkerIcon.init,
        };
        async.parallel(tasks, (err) => {
            if (err) {
                Errors.showError(err);
            } else {
                cb();
            }
        });
    }

    show() {
        Options.on('elevationsEnabled_changed', this._onElevationsEnabledOptionChangedCbk);
        this.toggleMap(true);
    }

    _toggleTutorialBtn(isVisble) {
        if (isVisble) {
            this._addTutorialBtn();
        } else {
            this._removeTutorialBtn();
        }
    }

    _addTutorialBtn() {
        if (!this.$mapTutorialBtn) {
            this.$mapTutorialBtn = $("<button class='btn btn-map' id='mapTutorialBtn'><i class='fa fa-question'></i></button>");
            this.$mapTutorialBtn.on('click', () => {
                this._openTutorial();
            });
            this.$map.append(this.$mapTutorialBtn);
        }
    }

    _removeTutorialBtn() {
        if (this.$mapTutorialBtn) {
            this.$mapTutorialBtn.remove();
            delete this.$mapTutorialBtn;
        }
    }

    _shouldUseFallbackAnimation() {
        let enableBackupAnimation = false;
        const browser = BrowserDetect.getBrowser();
        const browserVersion = Number(browser.version.split('.')[0]);
        if ((BrowserDetect.isIOS() && browserVersion < 9)
            || BrowserDetect.isMSIE()
            || (BrowserDetect.isGoogleChrome() && browserVersion < 35)) {
            enableBackupAnimation = true;
        }
        return enableBackupAnimation;
    }

    _openTutorial() {
        if (!this.$mapTutorial) {
            this.$mapTutorial = this.renderTemplate(mapTutorialTemplate, {
                enableBackupAnimation: this._shouldUseFallbackAnimation(),
            });
            this.$mapTutorial.find('.closeTutorial').on('click', () => {
                this._closeTutorial();
            });
            this.$mapTutorial.find('.switchVersion').on('click', (event) => {
                this.$mapTutorial.find('.mapTutorialVersionContainer').hide();
                const version = $(event.currentTarget).attr('data-open-version');
                this.$mapTutorial.find('.' + version).show();
            });
            this.$mapTutorial.find('.mapTutorialVersionContainer').hide();
            if (BrowserDetect.isMobile() || BrowserDetect.isTablet()) {
                this.$mapTutorial.find('.tactileVersion').show();
            } else {
                this.$mapTutorial.find('.mouseVersion').show();
            }
            this.$map.append(this.$mapTutorial);
        } else {
            this.$mapTutorial.show();
        }
    }

    _closeTutorial() {
        if (this.$mapTutorial) {
            this.$mapTutorial.addClass('hideWithAnimation');
            _.delay(() => {
                if (this.$mapTutorial) {
                    this.$mapTutorial.remove();
                    delete this.$mapTutorial;
                }
            }, 300);
        }
    }

    hide(options, cb = _.noop) {
        this._closeTutorial();
        this._removeTutorialBtn();
        Options.removeListener('elevationsEnabled_changed', this._onElevationsEnabledOptionChangedCbk);
        this.toggleMap(false);
        cb();
    }

    initDetailedSheetMap(realEstateAd) {
        if (this.realEstateAd != realEstateAd) {
            if (this.$centerOnAdMapButton) {
                this.$centerOnAdMapButton.toggle(true);
                if (!this.handleCenterOnAdClick) {
                    this.handleCenterOnAdClick = _.bind(this._onClickCenterOnAd, this);
                    this.$centerOnAdMapButton.on('click', this.handleCenterOnAdClick);
                }
            }
            this.realEstateAd = realEstateAd;
        }
    }

    _onClickCenterOnAd(event) {
        event.preventDefault();
        this.centerOnAd();
    }

    isIn3d() {
        return this._isIn3d;
    }

    mustShowUndisclosedAddressMessage(realEstateAd) {
        return this._detailedSheetAdOnMapView.mustShowUndisclosedAddressMessage(realEstateAd);
    }

    updateMarkerHeight(selectedProgramme, markerHeight) {
        this._detailedSheetAdOnMapView.updateMarkerHeight(selectedProgramme, markerHeight);
    }

    setBlurOverlaysVisible(visible) {
        this._detailedSheetAdOnMapView.setBlurOverlaysVisible(visible);
    }

    _canShowBlurOverlays(realEstateAd) {
        //check if ad programme is already loaded, otherwise blur will be canceled later on
        return !this._programmeModelsManager || !this._programmeModelsManager.isBlurOverlayHidden(realEstateAd);
    }

    canDisplayMarkerForAd(realEstateAd) {
        return !this._programmeModelsManager || this._programmeModelsManager.canDisplayMarkerForAd(realEstateAd);
    }

    initDetailedSheetAdOnMapView(realEstateAd, onClickCallback) {
        this._detailedSheetAdOnMapView.show({
            map: this.map,
            realEstateAd,
            onClickCallback,
            displayBlurOverlays: this._canShowBlurOverlays(realEstateAd),
            openContactForm: () => this.emit('openContactSectionFromMap'),
        });
    }

    showDetailedSheetContactInfo(contact) {
        this._detailedSheetAdOnMapView.showContactInfo(contact);
    }

    clearDetailedSheetMap() {
        if (this.$centerOnAdMapButton) {
            this.$centerOnAdMapButton.toggle(false);
            this.$centerOnAdMapButton.off('click', this.handleCenterOnAdClick);
            this.handleCenterOnAdClick = null;
        }
        this._detailedSheetAdOnMapView.hide();
        delete this.realEstateAd;
    }

    _on3dChange(isIn3d) {
        this._isIn3d = isIn3d;
        this.emit('on3dchange', isIn3d);
        this._toggleTutorialBtn(this._isIn3d);
        if (!this._isIn3d) {
            this._closeTutorial();
        }
    }

    _onElevationsEnabledOptionChanged(isEnabled) {
        if (this.map) {
            this.map.setOptions({
                elevation: isEnabled,
            });
        }
    }

    toggleMap(enabled) {
        if (enabled === undefined) {
            enabled = !this.mapEnabled;
        }
        if (enabled !== this.mapEnabled) {
            this._togglePoiInterface(enabled);
            this.closeMapExpand();
            this.mapEnabled = enabled;
            this.$map = $('#map');
            this.$map.toggleClass('disabled', !enabled);
            this._fixHeightOnIPadIOS7Landscape();
            if (enabled) {
                if (!this.map) {
                    this.createMap();
                } else {
                    this._bindProgrammeModelsManager();
                    _.invoke(this.watcher, 'init', this.map);
                    this._updateGeoLocationCircleAndMarker(true);
                    const $element = $(this.map.getDiv());
                    const divSize = 'auto';
                    $element.width(divSize);
                    $element.height(divSize);
                    this._setSunAnimatorVisible(true);
                    this.resizeMap();
                    this.emit('toggledMap', enabled);
                    this.map.on('on3dchange', this._on3dChangeHandler);
                    this.map.on('idle', this.mapIdleCallback);
                    this.map.on('zoom_changed', this._onZoomChangedHandler);
                }
            } else {
                _.invoke(this.watcher, 'destroy');
                CircleZoneAroundMeHelper.abortGeolocation();
                this._updateGeoLocationCircleAndMarker(false);
                this._unbindProgrammeModelsManager();
                this._setSunAnimatorVisible(false);
                this.emit('toggledMap', false);
                if (this.map) {
                    this.map.removeListener('zoom_changed', this._onZoomChangedHandler);
                    this.map.removeListener('on3dchange', this._on3dChangeHandler);
                    this.map.removeListener('idle', this.mapIdleCallback);
                    if (this._geolocationClickedHandler) {
                        this.map.removeListener('mapGeolocationClicked', this._geolocationClickedHandler);
                        this._geolocationClickedHandler = null;
                    }
                    if (this._freeMap && this.map.conf.enableWebGL && this.map._renderer) {
                        const gpuVersion = this.map._renderer.getRenderEngine()._webGL.glInfo.VERSION;
                        if (gpuVersion.indexOf('IMGSGX543') != -1 || gpuVersion.indexOf('IMGSGX535') != -1) {
                            this.destroyMap();
                        }
                    }
                }
            }
        } else {
            this.emit('toggledMap', enabled);
        }
        return this.map;
    }

    destroyMap() {
        if (this._poiClickedHandler) {
            this.map.removeListener('poiClicked', this._poiClickedHandler);
            this._poiClickedHandler = null;
        }
        this._cameraManager.removeListener('cameraChanged', this._onCameraChangedHandler);
        this._cameraManager.removeListener('mapIdle', this._onCameraMapIdleHandler);
        delete this._onCameraChangedHandler;
        delete this._onCameraMapIdleHandler;

        if (this._programmeModelsManager) {
            this._programmeModelsManager.setMap(null);
        }
        this.removeListener('mapExpanded', this.handleMapExpandCallback);
        this.emit('hide');
        this.$map.empty();
        this.$map.replaceWith($("<div id ='map' class='disabled'></div>"));
        this._cameraManager.setMap(null);
        delete this.$map;
        delete this.map;
    }

    createMap() {
        if (BrowserDetect.isMobile()) {
            $('.mainPageContainer').addClass('isLoading');
        }

        const createMapOptions = {
            view: this,
            $map: this.$map,
            showGraphicLevelButton: true,
            enablePoiAgencies: !Account.isShowRoom() && !ApplicationConfig.applicationPro,
            onCameraCreatedCallback: _.bind(this._bindPoiInterface, this),
            onMapCreatedCallback: _.bind(this._handleMapCreated, this),
            showExpandMapButton: !BrowserDetect.isMobile(),
            getMapOptionsCallback: () => {
                return {
                    mapTypeControlOptions: {
                        position: MapApi.api.ControlPosition.TOP_LEFT,
                    },
                };
            },
        };
        if (Account.isShowRoom() || !BrowserDetect.isMobile()) {
            _.extend(createMapOptions, {
                ui: {
                    enableGeoloc: false,
                },
            });
        }
        MapCreation.createMap(createMapOptions);
        this._updateAccountFilterForPoiAgency();
    }

    _onGeolocationClicked() {
        this._startGeolocButtonSpinner();
        this.emit('geolocationClicked', _.bind(this._onGeolocEnded, this));
    }

    _onGeolocEnded(err, circleLocationItem) {
        if (err) {
            console.warn(err, 'error on geolocation');
        } else {
            this.setGeoLocationCircleAndMarker(circleLocationItem, true);
        }
        this._stopGeolocButtonSpinner();
    }

    _onCameraChanged(eventInfo) {
        this.emit('cameraChanged', eventInfo);
    }

    _onCameraMapIdle(eventInfo) {
        this.emit('mapIdle', eventInfo);
    }

    _handleMapCreated(map) {
        if (this.mapEnabled) {
            window.map = map; // used by tests !!
            this._cameraManager.setMap(map);
            this._onCameraChangedHandler = _.bind(this._onCameraChanged, this);
            this._onCameraMapIdleHandler = _.bind(this._onCameraMapIdle, this);
            this._cameraManager.on('cameraChanged', this._onCameraChangedHandler);
            this._cameraManager.on('mapIdle', this._onCameraMapIdleHandler);
            map.on('idle', _.bind(this.emit, this, 'mapIdle'));
            map.on('click', _.bind(this.emit, this, 'mapClick'));
            map.on('idle', this.mapIdleCallback);
            map.on('on3dchange', this._on3dChangeHandler);
            map.on('zoom_changed', this._onZoomChangedHandler);
            map.on('mousedown', _.bind(this._onMouseDown, this));
            map.on('mouseup', _.bind(this._onMouseUp, this));
            map.on('maptypeid_manually_changed', () => {
                MapOptionsLocalStorage.save3dPreferenceFromMapTypeId(map.getMapTypeId());
            });

            this._initOverlayView();
            this.$centerOnAdMapButton = $("<div id='centerOnAdMapButton' "
                + "class='kimono-mapSimpleButton'><span>Centrer sur le bien</span></div>");
            this.$map.append(this.$centerOnAdMapButton);
            this.handleWindowResizeCallback = _.bind(this.handleWindowResize, this);
            this.handleMapExpandCallback = _.bind(this._handleMapExpanded, this);
            this._geolocationClickedHandler = _.bind(this._onGeolocationClicked, this);
            this.on('mapGeolocationClicked', this._geolocationClickedHandler);
            $(window).on('resize', this.handleWindowResizeCallback);
            this.on('mapExpanded', this.handleMapExpandCallback);
            this.emit('mapCreated');
            this.$element = $(map.getDiv());
            if (map.conf.enableWebGL && !this._programmeModelsManager) {
                this._programmeModelsManager = new ProgrammeModelsManager(map, this);
            }
            this.mapInitialized = true;
            this._updateGeoLocationCircleAndMarker(true);
            this._bindProgrammeModelsManager();
            this.resizeMap();
            if (BrowserDetect.isMobile()) {
                $('.mainPageContainer').removeClass('isLoading');
            }
            this._setSunAnimatorVisible(true);
            this.emit('toggledMap', true);
        }
    }

    _bindPoiInterface() {
        if (this.poiInterface) {
            this._poiClickedHandler = _.bind(this._onPoiClicked, this);
            this.poiInterface.on('poiClicked', this._poiClickedHandler);
        }
    }

    _onPoiClicked(marker) {
        this.emit('poiClicked', marker);
    }

    onQualityLevelChanged(level) {
        this._setSunAnimatorVisible((level != 'low'));
    }

    _zoomChanged() {
        if (this.map) {
            this.emit('zoomChanged', this.map.getZoom());
        }
    }

    closeMapExpand() {
        this.emit('closeMapExpand');
    }

    _handleMapExpanded() {
        this.handleWindowResize();
    }

    isMapExpanded() {
        return this._isMapExpanded;
    }

    openMapExpand() {
        this.emit('openMapExpand');
    }

    centerOnAd() {
        //TODO refactor use of blurInfo.bbox, see DetailedSheetPage.initCamera
        if (this._programmeModelsManager && this._programmeModelsManager.isCameraAlreadyHandled(this.realEstateAd)) {
            this._programmeModelsManager.centerOnAd(this.realEstateAd);
        } else if (this.realEstateAd.blurInfo && this.realEstateAd.blurInfo.bbox) {
            const bbox = this.realEstateAd.blurInfo.bbox;
            const ne = new MapApi.api.LatLng(bbox[1], bbox[0]);
            const sw = new MapApi.api.LatLng(bbox[3], bbox[2]);
            const bounds = new MapApi.api.LatLngBounds(ne, sw);
            this.moveCameraToBounds(bounds, {
                zoom: 18,
                theta: 45,
                phi: 0,
            });
        } else {
            const ad = this.realEstateAd || this.incompleteRealEstateAd;
            if (ad && ad.position) {
                _.defer(() => {
                    this.moveCameraToPosition({
                        zoom: 18,
                        theta: 45,
                        phi: 0,
                        position: ad.position,
                    });
                });
            }
        }
    }

    setAccountFilterForPoiAgency({accountType, id} = {}) {
        this._accountFilterForPoiAgency = {accountType, id};
        this._updateAccountFilterForPoiAgency();
    }

    _updateAccountFilterForPoiAgency() {
        if (this.poiInterface) {
            this.poiInterface.setAccountFilterAgency(this._accountFilterForPoiAgency);
            delete this._accountFilterForPoiAgency;
        }
    }

    _togglePoiInterface(enable) {
        if (this.poiInterface) {
            this.poiInterface.toggle(enable);
        }
    }

    _initOverlayView() {
        this.overlayView = new MapApi.api.OverlayView();
        this.overlayView.setMap(this.map);
    }

    handleWindowResize() {
        this.resizeMap();
        this._cameraManager.triggerCameraChanged({});
    }

    _handleMapIdle(idleEvent) {
        if (idleEvent && idleEvent.isHuman) {
            this._updateCameraFromMap({skipCameraUrl: false, isHuman: true});
        }
        // Send map idle event: looks like cameraChanged event has a lot of restriction (isHuman, skipCameraUrl, etc)
        //   so use an independent event to avoid breaking other functionality.
        // This event is always sent on a map idle whether from human interaction or from map/site behaviour
        this._cameraManager.triggerMapIdle();
    }

    setGeoLocationCircleAndMarker(geolocationCircleZone, moveCameraOnCircle) {
        this._hideGeoLocationCircleAndMarker();
        this._showGeoLocationCircleAndMarker(geolocationCircleZone, moveCameraOnCircle);
    }

    _showGeoLocationCircleAndMarker(geolocationCircleZone, moveCameraOnCircle) {
        if (geolocationCircleZone) {
            this._geolocationCircles = {
                circles: [],
                moveCameraOnCircle,
                geolocationCircleZone,
            };
        }
        this._updateGeoLocationCircleAndMarker(true);
    }

    _updateGeoLocationCircleAndMarker(visible) {
        if (this.mapInitialized) {
            let map = null;
            if (this.mapEnabled && visible) {
                map = this.map;
            }
            if (this._geolocationCircles) {
                if (this._geolocationCircles.moveCameraOnCircle) {
                    const boundingBox = this._getCircleZoneBoundingBox();
                    const sw = new MapApi.api.LatLng(boundingBox.south, boundingBox.west);
                    const ne = new MapApi.api.LatLng(boundingBox.north, boundingBox.east);
                    this._cameraManager.fitBounds(new MapApi.api.LatLngBounds(sw, ne), {isHuman: true});
                    delete this._geolocationCircles.moveCameraOnCircle;
                }
                if (map) {
                    const circle = this._geolocationCircles.geolocationCircleZone.geometry;
                    this._geolocationCircles.circles =
                        CircleZoneAroundMeHelper.createGeolocationCircles(circle, this.overlayView.getProjection());
                }
                _.each(this._geolocationCircles.circles, function (circle) {
                    circle.setMap(map);
                });
            }

            if (map) {
                if (!this._scaleCirclesGeolocHandle) {
                    this._scaleCirclesGeolocHandle = _.bind(this._scaleCirclesGeoloc, this);
                    this.map.on('bounds_changed', this._scaleCirclesGeolocHandle);
                }
            } else if (this._scaleCirclesGeolocHandle) {
                this.map.removeListener('bounds_changed', this._scaleCirclesGeolocHandle);
                delete this._scaleCirclesGeolocHandle;
            }
        }
    }

    _getCircleZoneBoundingBox() {
        let boundingBox = this._geolocationCircles.geolocationCircleZone.boundingBox;
        if (!boundingBox) {
            boundingBox = CircleZoneHelper.getCircleBbox({
                point: this._geolocationCircles.geolocationCircleZone.geometry.point,
                radius: this._geolocationCircles.geolocationCircleZone.geometry.accuracy,
            });
        }
        return boundingBox;
    }

    onSearchPageOpen() {
        if (BrowserDetect.isMobile()) {
            this.toggleGeolocButton(true);
        }
    }

    _scaleCirclesGeoloc() {
        if (this._geolocationCircles && this._geolocationCircles.geolocationCircleZone) {
            CircleZoneAroundMeHelper.scaleGeolocationCircles(this._geolocationCircles.circles, this.overlayView.getProjection());
        }
    }

    _hideGeoLocationCircleAndMarker() {
        this._updateGeoLocationCircleAndMarker(false);
        this._geolocationCircles = null;
    }

    getCameraManager() {
        return this._cameraManager;
    }

    moveCameraToRealEstateAd(realEstateAd, cameraOptions) {
        if (!this._programmeModelsManager || !this._programmeModelsManager.isCameraAlreadyHandled(realEstateAd)) {
            this._cameraManager.moveCameraToRealEstateAd(realEstateAd, cameraOptions);
        }
    }

    moveCameraToBounds(latLngBounds, cameraOptions) {
        this._cameraManager.moveCameraToBounds(latLngBounds, cameraOptions);
    }

    moveCameraToPosition(cameraOptions) {
        this._cameraManager.moveCameraToPosition(cameraOptions);
    }

    getCameraFromMap() {
        return this.mapEnabled ? this._cameraManager.getCameraFromMap() : null;
    }

    setDefaultBounds(cameraOptions) {
        this._cameraManager.setDefaultBounds(cameraOptions);
    }

    setCamera(camera, options) {
        this._cameraManager.setCamera(camera, options);
    }

    isCameraMoving() {
        return this._cameraManager.isCameraMoving();
    }

    _updateCameraFromMap(eventInfo) {
        this._cameraManager.updateCameraFromMap(eventInfo);
    }

    _startGeolocButtonSpinner() {
        if (this.map) {
            const $mapElement = $(this.map.getDiv());
            if ($mapElement) {
                this.$geolocButton = $mapElement.find('.f4map-geoloc');
                if (this.$geolocButton.length) {
                    this.$geolocButton.addClass('loading');
                    this.$geolocButton.find('i').remove();
                    this.$geolocButton.append($('<i>').addClass('md md-rotate-right md-spin'));
                    this.$geolocButton.attr('disabled', true);
                }
            }
        }
    }

    _stopGeolocButtonSpinner() {
        if (this.$geolocButton && this.$geolocButton.length) {
            this.$geolocButton.removeClass('loading');
            this.$geolocButton.find('i').remove();
            this.$geolocButton.removeClass('disabled');
            this.$geolocButton.attr('disabled', false);
        }
    }

    toggleGeolocButton(show) {
        if (this.map) {
            const $mapElement = $(this.map.getDiv());
            if ($mapElement) {
                this.$geolocButton = $mapElement.find('.f4map-geoloc');
                if (this.$geolocButton) {
                    this.$geolocButton.toggle(show);
                }
            }
        }
    }

    resizeMap() {
        if (this.map) {
            this.map.emit('resize');
            const $div = $(this.map.getDiv());
            CircleZoneAroundMeHelper.onResize($div.width(), $div.height());
        }
    }

    _fixHeightOnIPadIOS7Landscape() {
        if (navigator.userAgent.match(/iPad;.*CPU.*OS 7_\d/i)) {
            if (this.mapEnabled) {
                $BODY.css({
                    position: 'fixed',
                    height: window.innerHeight,
                });
                window.scrollTo(0, 0);
            } else {
                $BODY.css({
                    position: '',
                    height: '',
                });
            }
        }
    }

    isShown() {
        return Boolean(this.map);
    }

    getEncodedLimit() {
        const maxMarkerIconSize = MarkerIcon.getMaxSize();
        return LimitHelper.getEncodedLimit(this.map, this.overlayView, maxMarkerIconSize);
    }

    limitToBounds(limit) {
        return LimitHelper.limitToBounds(limit);
    }

    moveCameraToNeighborhoodZone(overlays, teleport) {
        this._cameraManager.moveCameraToNeighborhoodZone(overlays, teleport);
    }

    fitCameraToBounds(latLngBounds, options) {
        this._cameraManager.fitCameraToBounds(latLngBounds, options);
    }

    fitCameraToRealEstateAds(realEstateAds, options) {
        this._cameraManager.fitCameraToRealEstateAds(realEstateAds, options);
    }

    createMarker(realEstateAd, options) {
        MarkerUtil.createMarker(realEstateAd, this.map, options, this._highlightedAds3dMarkers);
    }

    deleteMarker(realEstateAd, skipAnimation) {
        MarkerUtil.deleteStackedMarker(realEstateAd, skipAnimation, this._highlightedAds3dMarkers);
    }

    showRealEstateAdMarker(realEstateAd, visible) {
        MarkerUtil.showRealEstateAdMarker(realEstateAd, this.map, visible);
    }

    addOverlay(overlay) {
        if (this.map) {
            overlay.setMap(this.map);
        }
    }

    removeOverlay(overlay) {
        overlay.setMap(null);
    }

    isWebglEnabled() {
        return isWebglEnabled(this.map);
    }

    _onMouseDown(event) {
        this._lastMouseDownLatLng = event.latLng;
        this._lastMouseDownDate = Date.now();
    }

    _onMouseUp(event) {
        const latLng = event.latLng;
        if (this._lastMouseDownLatLng && latLng.equals(this._lastMouseDownLatLng)
            && (Date.now() - this._lastMouseDownDate) >= LONG_CLICK_MIN_DURATION) {
            this.emit('longclick', event);
        }
        delete this._lastMouseDownLatLng;
        delete this._lastMouseDownDate;
    }
};
