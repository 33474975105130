const _ = require('lodash');
const urlUtils = require('url');
const {i18n: {translate}} = require('fack');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ServerConfig = require('../../ServerConfig');
const template = require('./PartnerServiceBlock.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            keys: [
                'title',
                'pitch',
                'buttonText',
            ],
        }),
    ],
    props: {
        service: {
            required: true,
            type: Object,
        },
    },
    computed: {
        pitch() {
            return this.t('pitch', {
                partnerName: this.service.partnerName,
            });
        },
        serviceName() {
            return this.service.name;
        },
        url() {
            return generateServiceUrlIfNeeded(this.service.url);
        },
    },
    methods: {
        t(key, options) {
            return translate(`partnerServicesView.${this.serviceName}.${key}`, options);
        },
    },
    template: template(),
};

function generateServiceUrlIfNeeded(url) {
    const parsedUrl = urlUtils.parse(url);
    if (!parsedUrl.host) {
        const {protocol, host} = urlUtils.parse(ServerConfig.config.wwwUrl);
        _.extend(parsedUrl, {protocol, host});
        url = urlUtils.format(parsedUrl);
    }
    return url;
}
