const _ = require('lodash');

module.exports = {
    getABTestingString,
};

function getABTestingString(obj) {
    if (_.isEmpty(obj)) {
        return 'noABTesting';
    } else {
        return _.map(obj, (value, key) => `${key}-${value}`).sort().join('-');
    }
}
