const SavedSearches = require('./SavedSearches');
module.exports = {
    handleScroll,
    getFloatingAlertButtonVisibility,
};

function handleScroll(lastAds, searchCriteria, hasFixedCountAndSort, isMobile) {
    let isLastAdsVisible = false;
    if (lastAds && lastAds.length > 0) {
        const lastAdsBottom = lastAds.offset().top + lastAds.height();
        if (isMobile) {
            const windowBottom = window.scrollY + window.innerHeight;
            isLastAdsVisible = lastAdsBottom < windowBottom;
        } else {
            isLastAdsVisible = lastAdsBottom < window.innerHeight;
        }
    }
    return this.getFloatingAlertButtonVisibility(hasFixedCountAndSort && !isLastAdsVisible, searchCriteria);
}

function getFloatingAlertButtonVisibility(enabled, searchCriteria, afterSavedSearchesLoaded = false) {
    const matchingSavedSearch = searchCriteria && SavedSearches.findMatchingSavedSearch(searchCriteria);
    let valueToSet;
    if (matchingSavedSearch == null) {
        if (afterSavedSearchesLoaded && !enabled) {
            valueToSet = true;
        }
        if (this.showFloatingButton !== enabled) {
            valueToSet = enabled;
        }
    } else if (this.showFloatingButton) {
        valueToSet = false;
    }
    return valueToSet;
}
