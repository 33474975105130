/* global $JssorSlider$, $JssorArrowNavigator$, $JssorThumbnailNavigator$ */
const $ = require('jquery');
const _ = require('lodash');
const BrowserDetect = require('browser-detect');
const {i18n: {translate}} = require('fack');

const CompositeVueView = require('../vue/CompositeVueView');
const imageResConfig = require('../imageResConfig');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');
const EventPack = require('../utils/EventPack');
const AccountLogo = require('../ad/components/AccountLogo');
const ServerConfig = require('../ServerConfig');
const {getImageUrlFromAlias} = require('../ImageHelper');
const agencyLogoSize = require('../ads/agencyLogoSize');

const DELAY_TO_FIX_ORIENTATION_CHANGE_IOS = 700;
const SPACING_X = 5;
const SLIDE_HEIGHT = 30;
const SLIDE_WIDTH = 40;
const MIN_WINDOW_HEIGHT_MOBILE = 300;

module.exports = class SlideshowBaseView extends CompositeVueView {
    constructor(options) {
        const slideshowImageResConfig = imageResConfig.getTable(options.imageResConfigName);
        options = _.defaults(options || {}, {
            maxWidth: slideshowImageResConfig.width,
            maxHeight: slideshowImageResConfig.height,
            fit: slideshowImageResConfig.fit,
            virtualTours: [],
            photos: [],
            fullscreenAllowed: true,
            fullscreenAllowedOnImagesClick: true,
            stretchSmallImages: true,
            isProgrammeOrResidenceOrLot: false,
            hasThumbnails: true,
            photosCounter: true,
            noPhotoUrl: 'images/no_photo.png',
            showContact: false,
            preloadAllImages: !BrowserDetect.isIOS(),
            nbrPreloadedImages: null,
            displayNew360Preview: false,
            arrowKeyNavigation: true,
            useArrows: !BrowserDetect.isMobile() && !BrowserDetect.isTablet(),
            useSpinner: !BrowserDetect.isIOS(),
            startPhotoIndex: 0,
        });
        super(options);
        this.options = options;
        this._eventPack = new EventPack();
    }

    updateContactInfo(contact) {
        if (this.vueData) {
            this.vueData.contact = contact;
        }
        if (contact.canShowContactForms) {
            this.options.contact = contact;
            this._updateContactPhoneElement(contact);
            this._updateContactEmailElement(contact);
        }
    }

    _updateContactPhoneElement(contact) {
        this.$container.find('.contactFromSlideshowPhone').toggle(Boolean(contact && contact.phoneToDisplay));
    }

    _updateContactEmailElement(contact) {
        this.$container.find('.contactFromSlideshowPhotos').toggle(Boolean(contact && contact.hasEmailToDisplay));
    }

    show(options) {
        options = _.defaults({}, options, this.options);
        this._logErrorPhotos(options.photos);
        this._createElement(options);
        this._bindEvents();
        this._updateContactPhoneElement(options.contact);
        this._updateContactEmailElement(options.contact);
    }

    hide() {
        super.hide();
        this._eventPack.removeAllListeners();
        this._unbindJSSOREvents();
    }

    _bindEvents() {
        this._eventPack.on(this.$element, {
            click: {
                '.arrows': _.bind(this._stopEvent, this),
                '.slides': _.bind(this._clickSlides, this),
                '.showAdContentFromSlideshow': _.bind(this._showAdContent, this),
            },
        });
        this._bindContactSectionEvents();
        this._eventPack.on($(window), 'resize', _.bind(this._handleResize, this));
        this._bindJSSOREvents();
    }

    _bindContactSectionEvents() {
        this._eventPack.on(this.$element, {
            click: {
                '.contactFromSlideshowDocumentation': event => {
                    this._stopEvent(event);
                    this.emit('openContactSection', 'slideShowDocumentation', {askingFor: ['documentation']});
                },
                '.contactFromSlideshowPhone': event => {
                    this._stopEvent(event);
                    this.emit('openContactSection', 'slideShowPhone', {showPhoneFromSlideShow: true, event});
                },
                '.contactFromSlideshowPhotos': event => {
                    this._stopEvent(event);
                    this.emit('openContactSection', 'slideShowPhotos', {askingFor: ['photos']});
                },
            },
        });
    }

    _bindJSSOREvents() {
        this._setCurrentSlideHandler = _.bind(this._setCurrentSlider, this);
        this._jssorSlider.$On($JssorSlider$.$EVT_PARK, this._setCurrentSlideHandler);
        this.dragStartHandler = _.bind(this._dragStart, this);
        this._jssorSlider.$On($JssorSlider$.$EVT_DRAG_START, this.dragStartHandler);
        this.dragStopHandler = _.bind(this._dragStop, this);
        this._jssorSlider.$On($JssorSlider$.$EVT_DRAG_END, this.dragStopHandler);
    }

    _unbindJSSOREvents() {
        this._jssorSlider.$Off($JssorSlider$.$EVT_PARK, this._setCurrentSlideHandler);
        this._jssorSlider.$Off($JssorSlider$.$EVT_DRAG_START, this.dragStartHandler);
        this._jssorSlider.$Off($JssorSlider$.$EVT_DRAG_END, this.dragStopHandler);
    }

    _computeNbrPhotos(photos, virtualTours) {
        let nbrPhotos = photos.length;
        _.each(virtualTours, function ({fileServerUrl, url}) {
            //virtualTours should already be filtered
            if (fileServerUrl || url) {
                nbrPhotos += 1;
            }
        });
        return nbrPhotos;
    }

    _dragStart(position) {
        this._startDragPosition = position;
    }

    _dragStop(position) {
        this._hasDragged = (position != this._startDragPosition);
    }

    _stopEvent(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    _clickSlides(event) {
        if (this._hasDragged) {
            this._stopEvent(event);
            this._hasDragged = null;
        }
    }

    _showAdContent(event) {
        this.emit('showAdContent', event);
    }

    _createElement({
        hasThumbnails,
        photos,
        startPhotoIndex,
        photosCounter,
        virtualTours,
        stretchSmallImages,
        nbrPreloadedImages,
        fit,
        maxWidth,
        maxHeight,
        noPhotoUrl,
        useSpinner,
        preloadAllImages,
        useArrows,
        displayNew360Preview,
        linkUrl,
        showContact,
        showLiveRoomButton,
        slideshowTitle,
        isProgrammeOrResidenceOrLot,
        adId,
        fullscreenAllowed,
        contact,
        arrowKeyNavigation,
        displaySlideshowPhoneButton,
        photoWatermarkUrl,
        imageResConfigName,
        accountDisplayName,
    }) {
        this._currentPhotoIndex = startPhotoIndex;
        this._nbrPhotos = photosCounter ? this._computeNbrPhotos(photos, virtualTours) : null;
        const isMobile = BrowserDetect.isMobile();
        const sliderOptions = {
            $AutoPlay: false,
            $DragOrientation: 1,                     //[Optional] Orientation to drag slide, 0 no drag, 1 horizontal, 2 vertical, 3 either, default value is 1 (Note that the $DragOrientation should be the same as $PlayOrientation when $DisplayPieces is greater than 1, or parking position is not 0)
            $ArrowKeyNavigation: arrowKeyNavigation, //[Optional] Allows keyboard (arrow key) navigation or not, default value is false
            $SlideDuration: 500,                     //Specifies default duration (swipe) for slide in milliseconds
            $HWA: !BrowserDetect.isIOS(),
            $ThumbnailNavigatorOptions: {           //[Optional] Options to specify and enable thumbnail navigator or not
                $Class: $JssorThumbnailNavigator$,  //[Required] Class to create thumbnail navigator instance
                $ChanceToShow: 2,                   //[Required] 0 Never, 1 Mouse Over, 2 Always
                $ActionMode: 1,                     //[Optional] 0 None, 1 act by click, 2 act by mouse hover, 3 both, default value is 1
                $ParkingPosition: 0,                //[Optional] The offset position to park thumbnail
                $AutoCenter: 0,                     //[Optional] Auto center thumbnail items in the thumbnail navigator container, 0: None, 1: Horizontal, 2: Vertical, 3: Both, default value is 3
                $Orientation: 1,
                $Lanes: 1,
            },
            $FillMode: this._hasPreviewImage(virtualTours) ? 1 : (stretchSmallImages ? 2 : 5),
            $StartIndex: startPhotoIndex,
        };
        if (nbrPreloadedImages != null) {
            sliderOptions.$LazyLoading = nbrPreloadedImages;
        }

        if (!isMobile) {
            sliderOptions.$ArrowNavigatorOptions = {    //[Optional] Options to specify and enable arrow navigator or not
                $Class: $JssorArrowNavigator$,          //[Required] Class to create arrow navigator instance
                $ChanceToShow: 2,                       //[Required] 0 Never, 1 Mouse Over, 2 Always
            };
        }
        this._containerSize = this._getContainerSize();
        const templateOptions = {
            hasThumbnails,
            photos,
            fit,
            maxWidth,
            maxHeight,
            noPhotoUrl,
            useSpinner,
            preloadAllImages,
            useArrows,
            displayNew360Preview,
            virtualTours,
            linkUrl,
            VirtualTourHelper,
            showContact,
            showLiveRoomButton,
            slideshowTitle,
            isProgrammeOrResidenceOrLot,
            adId,
            photosCounter,
            width: this._containerSize.width,
            height: this._containerSize.height,
            showEnterFullScreenArrow: fullscreenAllowed && this._hasPhotos(),
            displaySlideshowPhoneButton,
        };
        if (hasThumbnails) {
            const nbThumb = Math.floor(this._containerSize.width / SLIDE_WIDTH);
            templateOptions.slideWidth = Math.floor((this._containerSize.width - 2 * SPACING_X) / nbThumb) - SPACING_X;
            templateOptions.slideHeight = SLIDE_HEIGHT;
            sliderOptions.$ThumbnailNavigatorOptions.$DisplayPieces = nbThumb;
            sliderOptions.$ThumbnailNavigatorOptions.$DisableDrag = (photos.length <= nbThumb);
            sliderOptions.$ThumbnailNavigatorOptions.$SpacingX = SPACING_X;
        }
        if (useArrows) {
            templateOptions.arrowTopPosition = accountDisplayName ? templateOptions.height / 2 : templateOptions.height / 2 - 20;
        }
        const vueData = this.vueData = {
            nbrPhotos: this._nbrPhotos,
            accountDisplayName,
            contact,
            photoWatermarkUrl,
        };
        // @vue/component
        const vueOptions = {
            bemName: 'slideshow',
            components: {
                AccountLogo,
            },
            data() {
                return vueData;
            },
            computed: {
                hasPhotos() {
                    return this.nbrPhotos > 0;
                },
                showWatermarkLogo() {
                    return this.photoWatermarkUrl && !this.accountDisplayName;
                },
                imageUrlForAccountLogo() {
                    return this.photoWatermarkUrl || getImageUrlFromAlias(_.get(this.contact, 'imageName'), agencyLogoSize);
                },
                showAccountLogo() {
                    return Boolean(this.accountDisplayName);
                },
                bottomRightCornerBlockModifiers() {
                    return {
                        withThumbnails: this.hasPhotos,
                        fullscreen: this.isFullscreenSlideshow,
                        hasAccountLogo: this.showAccountLogo,
                    };
                },
                needThumbnails() {
                    return _.size(photos) > 0 || _.size(virtualTours) > 0;
                },
                isFullscreenSlideshow() {
                    return _.includes(imageResConfigName, 'Fullscreen');
                },
                watermarkLogoModifiers() {
                    const {needThumbnails, isFullscreenSlideshow} = this;
                    return {
                        thumbnailsDisplayed: needThumbnails,
                        fullscreen: isFullscreenSlideshow,
                    };
                },
                showThumbnails() {
                    return hasThumbnails && this.needThumbnails;
                },
                thumbnailsModifiers() {
                    return {
                        hideLastThumbnail: showContact,
                        fullscreen: this.isFullscreenSlideshow,
                        hasAccountLogo: this.showAccountLogo,
                    };
                },
                thumbnailStyle() {
                    const {width, slideHeight} = templateOptions;
                    return {
                        width: width + 'px',
                        height: (slideHeight + 10) + 'px',
                        left: 0,
                    };
                },
                tracking() {
                    const field = `account-logo-${_.kebabCase(imageResConfigName)}`;
                    const accountWebsite = _.get(this.contact, 'accountWebsite');
                    if (accountWebsite) {
                        return {
                            href: accountWebsite,
                            type: 'account-website',
                            field,
                            rel: 'nofollow',
                        };
                    } else if (_.get(this.contact, 'agencyPageEnabled')) {
                        const {accountType, agencyId} = this.contact;
                        const accountTypeInFrench = translate('userDirectory.accountTypeUrl.' + accountType);
                        return {
                            href: `${ServerConfig.config.wwwUrl}/${accountTypeInFrench}-${agencyId}`,
                            type: 'account-public-page',
                            field,
                        };
                    } else {
                        return {field};
                    }
                },
            },
        };
        super.show(templateOptions, vueOptions);
        this._jssorSlider = new $JssorSlider$(this.$container.find('.slider').get(0), sliderOptions);
        this._updateContactPhoneElement(contact);
    }

    _hasPreviewImage(virtualTours) {
        return _.find(virtualTours, function (content) {
            return content.fileServerUrl || content.previewImageUrl;
        });
    }

    _hasPhotos() {
        return this._nbrPhotos > 0;
    }

    _logErrorPhotos(photos) {
        _.each(photos, function (photo) {
            if (!photo.photo) {
                const originalUrlPhoto = photo.url_photo || photo.photo_url;
                if (originalUrlPhoto) {
                    console.warn('Current ad was imported with error: ' + photo.error +
                        ' original url photo is : ' + originalUrlPhoto);
                }
            }
        });
    }

    _getContainerSize() {
        if (BrowserDetect.isMobile()) {
            return this._getMobileSize();
        }
        return this._getDefaultContainerSize();
    }

    _getDefaultContainerSize() {
        return {
            width: this.$container.width(),
            height: this.$container.height(),
        };
    }

    _getMobileSize() {
        return {
            width: this.$container.width(),
            height: Math.min(this.$container.width() * 2 / 3, MIN_WINDOW_HEIGHT_MOBILE),
        };
    }

    _handleResize() {
        if (BrowserDetect.isIOS()) {
            //check if size will change later
            _.delay(() => this._resize(), DELAY_TO_FIX_ORIENTATION_CHANGE_IOS);
        } else {
            this._resize();
        }
    }

    _resize(options) {
        if (!_.isEqual(this._containerSize, this._getContainerSize())) {
            this.hide(options);
            this.show(_.extend({startPhotoIndex: this.getCurrentPhotoIndex()}, options));
        }
    }

    getCurrentPhotoIndex() {
        return this._currentPhotoIndex;
    }

    _setCurrentSlider(currentPhotoIndex) {
        const {$element} = this;
        const $currentSlideNumber = $element.find('.currentSlide');
        let displayCurrentSlideNumber = currentPhotoIndex + 1;
        if (displayCurrentSlideNumber > this._nbrPhotos) {
            displayCurrentSlideNumber = this._nbrPhotos;
        }
        $currentSlideNumber.html(displayCurrentSlideNumber);
        this._currentPhotoIndex = currentPhotoIndex;

        const classNameCurrentPrint = 'slideImg-currentForPrint';
        const $slide = $element.find('.slide');
        $slide.removeClass(classNameCurrentPrint);
        const $currentSlide = $slide.eq(currentPhotoIndex);
        let $printSlide;
        if ($currentSlide.hasClass('slideImg')) {
            $printSlide = $currentSlide;
        } else {
            const allSlidesImg = $element.find('.slideImg');
            $printSlide = allSlidesImg.length ? allSlidesImg.eq(0) : $element.find('#slideContact');
        }
        if ($printSlide) {
            $printSlide.addClass(classNameCurrentPrint);
        }
    }
};
