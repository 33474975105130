const _ = require('lodash');
const _toNumber = require('underscore.string/toNumber');

module.exports = {
    statToGauge,
};

function statToGauge(stat) {
    switch (stat.nom1_carte) {
        case 'Education':
            return education(stat);
        case 'Quartier animé':
            return quartieranime(stat);
        case 'Commerces':
            return commerces(stat);
        case 'Transports':
            return transports(stat);
        case 'Médecins généralistes':
            return medecinsgeneralistes(stat);
        default:
            return 0;
    }
}

function education(stat) {
    let result = stat.id_jauge;
    if (result > 5) {
        result = 5;
    }
    if (result > 0) {
        result++;
    }
    return result;
}

function commerces(stat) {
    if (stat.id_jauge == 99) {
        return 0;
    } else {
        return 6 - Math.floor(stat.id_jauge / 2);
    }
}

function quartieranime(stat) {
    const result = Math.floor(stat.id_jauge / 2); //from 0 to 6
    return 6 - result;
}

function transports(stat) {
    let result = stat.id_jauge;
    if (result >= 99) {
        result = 0;
    }
    if (result > 5) {
        result = 5;
    }
    if (result > 0) {
        result++;
    }
    return result;
}

function medecinsgeneralistes(stat) {
    if (stat.id_jauge == 99) {
        return 0;
    }
    const qty = stat.carte_stat_valeur.replace(',', '.');
    const qtyNumber = _toNumber(qty, 1);
    if (_.isNaN(qtyNumber)) {
        console.warn('could not estimate gauge', stat);
        return 6 - Math.round(stat.id_jauge / 2);
    } else if (qtyNumber >= 5000) {
        return 1;
    } else if (qtyNumber >= 2500) {
        return 2;
    } else if (qtyNumber >= 1500) {
        return 3;
    } else if (qtyNumber >= 750) {
        return 4;
    } else if (qtyNumber >= 250) {
        return 5;
    } else {
        return 6;
    }
}
