const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./PhoneContactButton.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PhoneContactButton.',
            keys: ['displayPhoneNumberText'],
        }),
    ],
    props: {
        contact: {
            type: Object,
            default: null,
        },
        loading: Boolean,
    },
    computed: {
        callAccountTypeNameText() {
            const accountType = this.contact && this.contact.accountType;
            return this.t('callAccountType', {accountType});
        },
    },
    methods: {
        handleClick() {
            this.$emit('click', this);
        },
    },
    template: template(),
};
