const template = require('./AgencyPageSkeleton.jade');
const loadingPlaceholderMixin = require('../../vue/components/mixins/loadingPlaceholderMixin');

// @vue/component
module.exports = {
    constants: {
        LOADING_PLACEHOLDER_LINE_PERCENTAGE_LIST: [
            30,
            30,
            60,
        ],
    },
    mixins: [
        loadingPlaceholderMixin,
    ],
    template: template(),
};
