const {i18n: {translate}} = require('fack');
const template = require('./RealEstateEstimatorMainDescriptor.jade');
const CommonStepMixin = require('../../components/mixins/CommonStepMixin');
const {PROPERTY_TYPE_OPTION, ESTIMATOR_STORE_MODULE_NAME} = require('../../Constants');

// @vue/component
module.exports = {
    mixins: [
        CommonStepMixin(ESTIMATOR_STORE_MODULE_NAME),
    ],
    computed: {
        title() {
            return this.realEstatePropertyDescriptorTranslate('sections.describe');
        },
        translatedPropertyTypeOptions() {
            return this.translateOptions(PROPERTY_TYPE_OPTION, 'type');
        },
        shouldAskForHouseFloorQuantity() {
            return this.isHouseLike;
        },
        shouldAskForFlatFloorQuantity() {
            return this.isFlatLike;
        },
        shouldAskForElevator() {
            return this.isFlatLike;
        },
        shouldAskForLandSurfaceArea() {
            return this.isHouseLike;
        },
        shouldAskForFloor() {
            return this.isFlatLike;
        },
        propertyTypeIsRequiredText() {
            return translate('propertyTypeIsRequired');
        },
    },
    watch: {
        shouldAskForLandSurfaceArea(shouldAskForLandSurfaceArea) {
            if (!shouldAskForLandSurfaceArea) {
                this.clearRealEstatePropertyStateInStore('landSurfaceArea');
            }
        },
        shouldAskForFloor(shouldAskForFloor) {
            if (!shouldAskForFloor) {
                this.clearRealEstatePropertyStateInStore('floor');
            }
        },
        shouldAskForElevator(shouldAskForElevator) {
            if (!shouldAskForElevator) {
                this.clearRealEstatePropertyStateInStore('hasElevator');
            }
        },
    },
    template: template(),
};
