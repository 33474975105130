const PtzSimulatorPage = require('./ptz/PtzSimulatorPage');
const PtzResultsPage = require('./ptz/PtzResultsPage');
const PinelSimulatorPage = require('./pinel/PinelSimulatorPage');
const PinelResultsPage = require('./pinel/PinelResultsPage');

module.exports = {
    ptzSimulatorPage: PtzSimulatorPage,
    ptzResultsPage: PtzResultsPage,
    pinelSimulatorPage: PinelSimulatorPage,
    pinelResultsPage: PinelResultsPage,
};
