const _ = require('lodash');
const blogData = require('../../common/data/blogData');
const BlogHelper = require('./BlogHelper');
const PageManager = require('../pages/PageManager');
const ArticlesHubPageFactory = require('./hub/ArticlesHubPageFactory');
const ArticlePage = require('./article/ArticlePage');

module.exports = {
    registerPages,
};

function registerPages(options) {
    _.each(blogData.blogs, blog => {
        const articlesHubPageFactory = new ArticlesHubPageFactory(_.extend({
            blog,
        }, options));
        PageManager.addPage(articlesHubPageFactory);
        BlogHelper.setBlogFactory(blog.slug, articlesHubPageFactory);
    });
    PageManager.addPage(new ArticlePage(options));
}
