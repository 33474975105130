const {formatForHtml} = require('../../../../common/PriceFormatter');
const template = require('./FormattedPrice.jade');

// @vue/component
module.exports = {
    props: {
        highlightPricePerSurfaceUnit: Boolean,
        transactionType: {
            type: String,
            required: true,
        },
        price: {
            type: Number,
            required: true,
        },
        surfaceArea: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        formattedPriceValue() {
            return formatForHtml(this.price);
        },
        formattedPriceBySquareMeter() {
            return formatForHtml(this.price / this.surfaceArea);
        },
        squareMeterPriceComponent() {
            return this.highlightPricePerSurfaceUnit ? 'strong' : 'span';
        },
    },
    template: template(),
};
