const _ = require('lodash');
const $ = require('jquery');
const {i18n: {translate}} = require('fack');
const BrowserDetect = require('browser-detect');
const savedOptions = require('../Options').read();
const SearchFieldsHandler = require('./SearchFieldsHandler');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const SearchTitleGenerator = require('../../common/SearchTitleGenerator');
const AlertCreationFeedbackModalView = require('../views/AlertCreationFeedbackModalView');
const GeolocationHelper = require('../geolocation/GeolocationHelper');
const LocalStorageSavedSearch = require('../utils/localStorage/LocalStorageSavedSearch');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const Views = require('../views/Views');
const SearchByTravelTimeView = require('../views/SearchByTravelTimeView');
const SavedSearches = require('./SavedSearches');
const template = require('../templates/saveSearchView.jade');
const CompositeVueView = require('../vue/CompositeVueView');
const FormUtils = require('../fields/FormUtils');
const Account = require('../authentication/Account');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const Analytics = require('../utils/Analytics');
const EventPack = require('../utils/EventPack');
const {translateAndFadeOut} = require('../utils/IconAnimation');
const CriteriaListFormatter = require('../../common/CriteriaListFormatter');
const ContactHelper = require('../utils/ContactHelper');
const SpinnerHelper = require('../utils/SpinnerHelper');
const PrimaryDomainUrl = require('../utils/PrimaryDomainUrl');
const ApplicationConfig = require('../app/ApplicationConfig');
const ConfirmationModal = require('../utils/ConfirmationModal');
const Fields = require('./Fields');
const SavedSearchNotificationsConfigurationView = require('../views/savedSearch/SavedSearchNotificationsConfigurationView.js');
const SearchFiltersHelper = require('../../common/SearchFiltersHelper');

const ADS_LIMIT_REACHED_ERROR_CODE = 'adsLimitReached';
const INVALID_EMAIL_ERROR_CODE = 'invalidEmail';

module.exports = class SaveSearchView extends CompositeVueView {
    constructor(options = {disableSuccessPopup: false}) {
        super({
            template,
            $container: $('body'),
        });
        this._eventPack = new EventPack();
        this._analyticsEventName = _.get(options, 'analyticsEventName', 'saved-searches');
        this._alertCreationFeedbackView = new AlertCreationFeedbackModalView();
        this._compactForm = options.compactForm;
        this._locationRequired = options.locationRequired;
        this._disableSuccessPopup = options.disableSuccessPopup;
        this.locations = {};
    }

    setSearchCriteria(searchCriteria) {
        this._searchFieldsHandler.resetFields();
        this._search.searchCriteria = _.defaults(searchCriteria, DefaultConfiguration.search);
        this._setValues();
        this._searchFieldsHandler.resetForm();
    }

    show(showOptions = {}) {
        this._modalType = showOptions.modalType;
        if (this._modalType === 'criteria') {
            this._updateFunc = SavedSearches.updateCriteria;
            this._shouldAskEmailOnEdit = false;
        } else if (this._modalType === 'frequency') {
            this._updateFunc = SavedSearches.updateFrequency;
            this._shouldAskEmailOnEdit = true;
        }
        this._useSearchFieldsHandler = (this._modalType !== 'frequency');
        // Beware when merging, for this fix may have been implemented on master...
        const optionalFields = _.defaults({}, showOptions.optionalFields, Fields.getOptionalFields());
        const options = _.defaults({
            optionalFields,
        }, showOptions, {
            search: {searchCriteria: DefaultConfiguration.search},
            compactForm: this._compactForm,
            isMobile: BrowserDetect.isMobile(),
            locationRequired: this._locationRequired,
            fields: Fields.getCommonFields(),
        });
        this._search = options.search;
        this._isEdit = Boolean(this._search._id);
        this._approvalQuestions = ContactHelper.getApprovalQuestionsWithOptions();
        const askForNotifications = this.askForNotifications = showOptions.criteriaSummaryEnabled;
        const askEmail = !askForNotifications && (this._shouldAskEmailOnEdit || !this._isEdit);
        const criteriaList = CriteriaListFormatter.getCapitalizedCriteria(options.search.searchCriteria);
        const {criteriaModificationEnabled, criteriaModificationHidden = true} = showOptions;
        const view = this;
        const {propertyType} = options.search.searchCriteria;
        this.vueData = {
            displayAboutPropertyGroup: SearchFiltersHelper.shouldDisplayAboutPropertyGroup(propertyType),
            searchCriteria: options.search.searchCriteria,
        };

        // @vue/component
        const vueOptions = {
            mixins: [
                require('./components/mixins/rangeInputMixin'),
            ],
            data() {
                return view.vueData;
            },
            computed: {
                criteriaList() {
                    return criteriaList;
                },
                showNotificationState() {
                    return false;
                },
                saveName() {
                    return view._search.saveName;
                },
                emailNotificationMode() {
                    return view._search.emailNotificationMode;
                },
                pushNotificationMode() {
                    return view._search.pushNotificationMode;
                },
                criteriaModificationEnabled() {
                    return criteriaModificationEnabled;
                },
                criteriaModificationHidden() {
                    return criteriaModificationHidden;
                },
                isOwner() {
                    return true;
                },
                fieldsVisibility() {
                    const {propertyType, filterType} = this.searchCriteria;
                    return SearchFiltersHelper.getFilterFieldsVisibility(propertyType, filterType, this.isAdmin);
                },
            },
            methods: {
                // When on mobile the input change must not trigger search
                onInputChange: _.noop,
            },
        };

        super.show(_.extend({}, options, {
            approvalQuestions: this._approvalQuestions,
            isFromPartnerHost: this.isFromPartnerHost(),
            cguPageUrl: PrimaryDomainUrl.generatePrimaryDomainCguUrl(),
            isEdit: this._isEdit,
            modalType: this._modalType,
            Account,
            askEmail,
            canSeeOptin: askForNotifications || askEmail,
            askForNotifications,
        }), vueOptions);

        if (askForNotifications) {
            this._addSavedSearchNotificationsConfigurationView();
        }
        const {$element} = this;
        this._$optinBlock = $element.find('.optinBlock');
        this._$selectFrequencySelector = $element.find("select[name='emailNotificationMode']");
        this._$showAdvancedCriteriaBtn = $element.find('.advancedCriteriaBtn');
        this._$advancedCriteriaBlock = $element.find('.advancedCriteriaContainer');
        this.advancedCriteriaHidden = options.advancedCriteriaHidden;
        this._$showCriteriaBtn = $element.find('.search-criteria-summary__modification-button');
        this._$criteriaBlock = $element.find('.criteriaContainer');
        this.criteriaModificationHidden = options.criteriaModificationHidden;
        this._$criteriaSummaryList = $element.find('.criteria-summary-in-save-search-popup');
        this._initModals();
        this._bindEvents(options);
        this._$form = $element.find('form');
        this._initAccountUpdater();
        this._refreshOptinBlock();
        this.openModal();
        this._initForm();
        this._setValues();
    }

    showSaveSearchModificationPopup(search, analyticsEventName) {
        this.show({
            search,
            pitchKey: 'pitchHomePage',
            suffix: 'criteria',
            criteriaSummaryEnabled: false,
            criteriaModificationEnabled: true,
            criteriaModificationHidden: false,
            frequencyModificationEnabled: false,
            advancedCriteriaEnabled: true,
            advancedCriteriaHidden: false,
            searchClassesAdded: 'searchFilterView saveSearchWithSearchFiltersView',
            analyticsEventName,
            modalType: 'criteria',
        });
    }

    _initModals() {
        const {$element} = this;
        this._$modal = $element.find('#saveSearchModalPopup');
        this._$saveButton = this._$modal.find('.saveSearchButton');
        this._$errorModal = $element.find('.errorSavingModal');

        this._$errorModal.modal({backdrop: 'static', show: false});

        if (this.criteriaModificationHidden) {
            this._$criteriaBlock.hide();
        }
        if (this.advancedCriteriaHidden) {
            this._$advancedCriteriaBlock.hide();
        }
    }

    _bindEvents(options) {
        this._eventPack.on(this.$element, {
            click: {
                '.removeSavedSearch': () => {
                    this.removeSearchFromEdition(options.search._id, () => {
                        this.hide();
                    });
                },
            },
        });
        this._eventPack.on(this._$modal, 'hidden.bs.modal', () => {
            this.hide();
        });
        this._eventPack.on(this._$errorModal, 'hidden.bs.modal', _.bind(this._onCloseErrorPopup, this));
        this._eventPack.on(this._$selectFrequencySelector, 'change', _.bind(this._refreshOptinBlock, this));
        this._eventPack.on(this._$showAdvancedCriteriaBtn, 'click', _.bind(this._showAdvancedCriteria, this));
        this._eventPack.on(this._$showCriteriaBtn, 'click', _.bind(this._showCriteria, this));
    }

    _onCloseErrorPopup() {
        const errorCode = this._$errorModal.find('.errorMessage').data('errorCode');
        if (errorCode === ADS_LIMIT_REACHED_ERROR_CODE) {
            this._showCriteria();
        } else {
            this.hide();
        }
        SpinnerHelper.stopButtonSpinner(this._$saveButton);
    }

    removeSearchFromEdition(savedSearchId, callback) {
        this._addSpinnerOnRemoveSavedSearchButton();
        this._disableSaveSearchButton();
        this.removeSearch(savedSearchId, callback);
    }

    _showAdvancedCriteria() {
        this._$showAdvancedCriteriaBtn.hide();
        this._$advancedCriteriaBlock.show();
    }

    _showCriteria() {
        this._$showCriteriaBtn.hide();
        this._$criteriaBlock.show();
        if (this._$criteriaSummaryList) {
            this._$criteriaSummaryList.hide();
        }
    }

    _initForm() {
        const aroundMeEnabled = GeolocationHelper.geolocationSupported()
            && BrowserDetect.isMobile();
        const {$element} = this;
        const validatorOptions = {
            container: null, //avoid popover
            excluded: [':disabled', ':hidden', ':not(:visible)'],
            fields: SearchFiltersHelper.getIgnoredFiltersInValidator(),
        };
        if (this._useSearchFieldsHandler) {
            this._searchFieldsHandler = new SearchFieldsHandler($element, {
                submit: _.bind(this._submit, this),
                $button: this._$saveButton,
                locationsField: {
                    aroundMeEnabled,
                },
                validatorOptions,
            });
            this._searchFieldsHandler.on('change', (changes) => {
                // Should be removed once this is using vue not appending the searchFilterView to header
                const {propertyType, filterType} = this._searchFieldsHandler.getRawFilters();
                const showEdpFields = SearchFiltersHelper.getFilterFieldsVisibility(
                    propertyType,
                    filterType,
                    this.isAdmin
                );
                this.$element.find('.vue-edp-field').toggle(showEdpFields.energyClassification);

                this.emit('change', changes);
                this._hideUnusedCriteriaBlocks();
            });
            this._searchFieldsHandler.on('travelTimeSelected', _.bind(this._openTravelTimeSearch, this));
        } else {
            FormUtils.init({
                $form: this._$form,
                submit: _.bind(this._submit, this),
                validatorOptions,
            });
        }
    }

    _hideUnusedCriteriaBlocks() {
        const $filterBlocks = this.$element.find('.showOnlyIfNotEmpty');
        _.each($filterBlocks, function (block) {
            $(block).toggle($(block).find('div').not('.hidden').filter(function () {
                return $(this).data('field');
            }).length > 0);
        });
    }

    _initAccountUpdater() {
        this.accountUpdater = ContactHelper.getUpdater(_.extend({
            $form: this._$form,
        }, this.getEmailPaths()));
    }

    getEmailPaths() {
        return {
            paths: {
                '.email': 'email',
            },
            destinationPaths: {
                '.email': 'email',
            },
        };
    }

    _refreshOptinBlock() {
        this._$optinBlock.toggle(this._$selectFrequencySelector.val() !== 'never');
    }

    _setValues() {
        this._setContactValues();
        this._setFormValues();
    }

    _setContactValues() {
        this.accountUpdater.fillWithUserInfos();
    }

    _getCounterPrefix() {
        return this._isEdit ? `${this._modalType}-modification` : 'creation';
    }

    _openTravelTimeSearch() {
        SearchByTravelTimeView.open({
            submitCallback: travelTimeZone => {
                this._searchFieldsHandler.setTravelTimeSearch(travelTimeZone);
            },
            defaultItem: this._searchFieldsHandler.getFirstTravelTimeItem(),
            parentView: this,
            $container: this.$element,
        });
    }

    hide() {
        this._clearForm();
        this._eventPack.removeAllListeners();
        this.closeModal();
        super.hide();
        //_searchFieldsHandler might not be initialized
        _.invoke(this._searchFieldsHandler, 'destroyFields');
    }

    openModal() {
        this.incCounter(`.${this._getCounterPrefix()}-opened`);
        this._setModalVisible(true);
    }

    closeModal() {
        this._setModalVisible(false);
    }

    incCounter(name) {
        Analytics.incCounter(this._analyticsEventName + name);
    }

    _clearForm() {
        if (this._$form) {
            FormUtils.clear({$form: this._$form});
            this._$form = null;
        }
    }

    isFromPartnerHost() {
        return savedOptions.isInEmbeddedMode || ApplicationConfig.isOnPartnersDomain;
    }

    _setFormValues() {
        if (this._useSearchFieldsHandler) {
            this._searchFieldsHandler.setFieldsValuesFromSearch(this._search.searchCriteria);
            this._hideUnusedCriteriaBlocks();
        } else if (this._search) {
            FormUtils.setValues(this._$form, this._getValuesFromSearch());
        }
    }

    _getValuesFromSearch() {
        return _.extend(_.pick(this._search, 'emailNotificationMode'), _.get(this._search, 'searchCriteria'));
    }

    _setModalVisible(visible) {
        if (this._$modal) {
            this._$modal.modal(visible ? 'show' : 'hide');
        }
    }

    _animateAlertIcon() {
        if (!this.isFromPartnerHost()) {
            translateAndFadeOut({
                $start: this.$element.find('.iconAlertToMove'),
                $finish: $('#btn-user-info').find('.userInfoPict'),
                iconClass: 'flyingSaveSearchIcon',
                finishElementClass: 'newSavedSearch',
            });
        }
    }

    _onSaveSucceeded(search, callback) {
        this._animateAlertIcon();
        this.closeModal();
        if (!this._$errorModal.is(':visible')) {
            this.hide();
        }
        if (this._disableSuccessPopup) {
            this._openSavedSearchAdsOrSearchAdsPage(search);
        } else {
            this._alertCreationFeedbackView.show({
                translationContext: {context: this._isEdit ? 'edit' : ''},
                cb: () => {
                    this._openSavedSearchAdsOrSearchAdsPage(search);
                    this._alertCreationFeedbackView.hide();
                    callback();
                },
            });
        }
    }

    _openSavedSearchAdsOrSearchAdsPage(search) {
        const {savedSearchAdsPageEnabled} = savedOptions;
        if (savedSearchAdsPageEnabled) {
            PageManager.openPage(PagesFactories.savedSearchAdsPageFactory, {savedSearch: search});
        } else {
            const {searchCriteria} = search;
            this._openSearchPage(searchCriteria);
        }
    }

    _openSearchPage(searchCriteria) {
        searchCriteria.locations = this.locations;
        LocalStorageSavedSearch.save(searchCriteria);
        if (BrowserDetect.isMobile()) {
            Views.header.search(searchCriteria);
        } else {
            PageManager.openPage(PagesFactories.searchPageFactory, {search: searchCriteria});
        }
    }

    _submit(err, cb) {
        if (_.isFunction(err)) { // not the same parameters whether form is initialized with FormUtils or SearchFieldsHandler
            cb = err;
            err = null;
        }
        if (!err) {
            this.incCounter('.save-clicked');
            if (this._isEdit) {
                this._disableRemoveSavedSearchButton();
            }
            this._sendForm(this._getFormValues(), cb);
        } else {
            if (err.isTravelTime) {
                SearchByTravelTimeView.showError(err);
                this._openTravelTimeSearch();
            }
            cb(err);
        }
    }

    _sendForm(search, cb) {
        const completeSearch = this._transformSaveSearch(search);
        this._writeEmailToAccount(completeSearch);
        if (this._isEdit) {
            this._updateSearch(completeSearch, cb);
        } else {
            this.saveSearch(completeSearch, cb);
        }
    }

    _transformSaveSearch(search) {
        return _.extend({}, this._search, search, {
            isUsingExtendedFilters: Boolean(search.isUsingExtendedFilters),
            emailNotificationMode: this.getEmailNotificationMode(search),
            saveName: this._isValidSearchName(search.saveName) ? search.saveName : this._search.saveName,
            email: search.email || _.get(Account.getAuthenticatedAccount(), 'email'),
        });
    }

    getEmailNotificationMode({emailNotificationMode}) {
        return emailNotificationMode || (UserAgentHelper.isFromNativeApp() ? 'never' : 'asap');
    }

    _isValidSearchName(name) {
        return name && (/\S/.test(name));
    }

    _writeEmailToAccount(search) {
        if (search.email) {
            this.accountUpdater.updateAccount(function (err) {
                if (err) {
                    console.log('savesearchview could not update account data', err);
                }
            });
        }
    }

    _updateAccount(updatedData) {
        Account.update(_.extend(updatedData, {
            id: Account.getAuthenticatedAccountId(),
        }), (err) => {
            if (err) {
                console.error('SaveSearchView could not update account');
                this._$errorModal.modal('show');
            }
        }, {
            emitAccountChange: false,
        });
    }

    _updateSearch(search, cb) {
        this.asyncHelper.doAsync({
            func: cb => this._updateFunc(search, cb),
            callback: (err, result) => {
                this.emit('updateSearch', err, result, search);
                this._onSavedSearch(err, result, () => {
                    cb();
                    this.emit('saveSearch', err, search);
                });
            },
            name: 'updateSearch',
        });
    }

    saveSearch(search, cb) {
        // warning : if we accept savedSearch for FavoritesPage and MyRealEstateAdsPage, we need to add
        // if (configuration.loadOptions.includeAccountIdInQueries)
        //     cleanFilters.accountId = Account.getAuthenticatedAccountId();
        this.asyncHelper.doAsync({
            func: cb => SavedSearches.create(search, cb),
            callback: (err, savedSearch) => {
                if (!err && savedSearch) {
                    this.emit('savedSearchCreated', err, savedSearch);
                }
                // For a currently unknown reason the create button remains disabled very often after being used once,
                // preventing then to create another alert. To fix this we stop the spinner, enabling the button again.
                SpinnerHelper.stopButtonSpinner(this._$saveButton);
                this._onSavedSearch(err, savedSearch, () => {
                    cb();
                    this.emit('saveSearch', err, search);
                });
            },
            name: 'saveSearch',
        });
    }

    _onSavedSearch(err, search, callback) {
        if (err) {
            this._onSaveFailed(err);
        } else {
            this._onSaveSucceeded(search, callback);
        }
    }

    _onSaveFailed(err) {
        const {error: errorCode} = err;
        let translationKey;
        if (errorCode === ADS_LIMIT_REACHED_ERROR_CODE) {
            translationKey = 'adsLimitReachedError';
        } else if (errorCode === INVALID_EMAIL_ERROR_CODE) {
            translationKey = 'invalidEmailError';
        } else {
            translationKey = 'error';
        }
        const errMessage = translate(`saveSearchView.${translationKey}`);
        this._$errorModal.find('.errorMessage').text(errMessage);
        this._$errorModal.find('.errorMessage').data('errorCode', errorCode);
        this._$errorModal.modal('show');
    }

    _getFormValues() {
        let formValues;
        const savedSearch = {};
        if (this._useSearchFieldsHandler) {
            formValues = this._searchFieldsHandler.getFilters();
        } else {
            formValues = FormUtils.readValues(this._$form);
        }
        this.locations = formValues.locations;
        if (this.askForNotifications) {
            const {emailNotificationMode, intention, emailNotificationEnabled, pushNotificationEnabled, notificationEmail} =
                this._savedSearchNotificationsConfigurationView.vm;
            savedSearch.pushNotificationMode = pushNotificationEnabled ? 'asap' : 'never';
            savedSearch.emailNotificationMode = emailNotificationEnabled ? emailNotificationMode : 'never';
            savedSearch.email = notificationEmail;
            savedSearch.intention = intention;
        }
        if (this._useSearchFieldsHandler) {
            if (!savedSearch.email) {
                savedSearch.email = formValues.email || this._search.email;
            }
            const keysToRemoveSearchCriteria = ['email'].concat(_.keys(this._approvalQuestions));
            savedSearch.searchCriteria = _.omit(formValues, keysToRemoveSearchCriteria);
            savedSearch.saveName = SearchTitleGenerator.getTitle(savedSearch.searchCriteria, 'savedSearch');
        }
        savedSearch.pushNotificationMode = savedSearch.pushNotificationMode || this._search.pushNotificationMode;
        savedSearch.emailNotificationMode = savedSearch.emailNotificationMode || this._search.emailNotificationMode;
        return savedSearch;
    }

    _addSpinnerOnRemoveSavedSearchButton() {
        SpinnerHelper.startButtonSpinner(this.$element.find('.removeSavedSearch'));
    }

    _disableRemoveSavedSearchButton() {
        this.$element.find('.removeSavedSearch').addClass('disabled');
    }

    _disableSaveSearchButton() {
        this.$element.find('.saveSearchButton').addClass('disabled');
    }

    removeWithConfirmation(savedSearchId, onClose) {
        ConfirmationModal.showConfirm({
            cb: _.bind(this.removeSearch, this, savedSearchId),
            onClose,
            translationsKeysPath: 'saveSearchView.removeConfirmationModal',
        });
    }

    removeSearch(savedSearchId, callback) {
        this.emit('preRemoveSearch', savedSearchId);
        this.asyncHelper.doAsync({
            func: cb => SavedSearches.deactivate(savedSearchId, cb),
            callback: (err, id) => {
                if (err) {
                    console.warn('remove Saved Search (' + id + ') : error : ', err);
                    // alert wasn't removed, so we restore the remove alert button
                }
                this.emit('removeSearch', err, id);
                if (callback) {
                    callback();
                }
            },
            name: 'removeSearch',
        });
    }

    _addSavedSearchNotificationsConfigurationView() {
        this._savedSearchNotificationsConfigurationView = new SavedSearchNotificationsConfigurationView({
            $container: this.$element.find('.savedSearchNotificationsConfigurationView'),
        });
        this._savedSearchNotificationsConfigurationView.show({
            savedSearch: this._search,
            isEditMode: this._isEdit,
        });
    }
};
