const _ = require('lodash');
const template = require('./EmphasisedTags.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const TextFormatter = require('../../../common/TextFormatter');
const AdLabelHelper = require('../../../common/AdLabelHelper');

const SIZES = [
    'big',
];

const VARIANT_SECONDARY = 'secondary';
const VARIANT_SECOND_COLOR = 'secondColor';
const VARIANT_PRIMARY = 'primary';
const VARIANT_MAIN = 'main';

const BADGES_VARIANTS = {
    withModel3d: VARIANT_SECONDARY,
    tag360: VARIANT_SECONDARY,
    tagVideo: VARIANT_SECONDARY,
    bienIciExclusive: VARIANT_SECOND_COLOR,
    exclusiveTag: VARIANT_MAIN,
    residenceStudent: VARIANT_MAIN,
    residenceSenior: VARIANT_MAIN,
    residenceTourism: VARIANT_MAIN,
    promotedAsExclusive: VARIANT_SECOND_COLOR,
    onlineBooking: VARIANT_SECOND_COLOR,
    hasToBeBuilt: VARIANT_PRIMARY,
    newBuild: VARIANT_PRIMARY,
    lifeAnnuitySale: VARIANT_MAIN,
    propertyWithoutUsufruct: VARIANT_MAIN,
    specialOffer: VARIANT_MAIN,
};

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'EmphasisedTags.',
            keys: [
                'tag360',
                'tagVideo',
                'exclusiveTag',
                'bienIciExclusive',
                'onlineBooking',
                'hasToBeBuilt',
                'lifeAnnuitySale',
                'propertyWithoutUsufruct',
                'withModel3d',
                'newBuild',
                'promotedAsExclusive',
                'residenceStudent',
                'residenceSenior',
                'residenceTourism',
                'specialOffer',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            default: null,
            validator: size => _.includes(SIZES, size),
            values: SIZES,
        },
        noDeliveryDate: Boolean,
    },
    computed: {
        isAdWithVirtualTour360() {
            return AdLabelHelper.isAdWithVirtualTour360(this.realEstateAd);
        },
        isAdWithHighlightedVideo() {
            return AdLabelHelper.isAdWithHighlightedVideo(this.realEstateAd);
        },
        deliveryDateText() {
            const {noDeliveryDate, realEstateAd} = this;
            let deliveryDate;
            if (!noDeliveryDate) {
                deliveryDate = TextFormatter.getDeliveryDateText(realEstateAd);
            }
            return deliveryDate;
        },
        deliveryDateModifiers() {
            return _.compact([
                this.size,
            ]);
        },
        badges() {
            const badges = [];
            const {
                realEstateAd,
                isAdWithVirtualTour360,
                isAdWithHighlightedVideo,
            } = this;
            if (realEstateAd.with3dModel) {
                badges.push(this.getBadge('withModel3d'));
            }
            if (isAdWithVirtualTour360) {
                badges.push(this.getBadge('tag360', {
                    modifiers: {
                        virtualTourTester: realEstateAd.with360BecauseOfVirtualTourTesterRole,
                    },
                }));
            }
            if (isAdWithHighlightedVideo) {
                badges.push(this.getBadge('tagVideo'));
            }
            if (realEstateAd.isBienIciExclusive) {
                badges.push(this.getBadge('bienIciExclusive'));
            } else if (realEstateAd.isExclusiveSaleMandate) {
                badges.push(this.getBadge('exclusiveTag'));
            }
            _.forEach(TextFormatter.getResidenceTypes(this.realEstateAd), type => {
                const key = `residence${_.upperFirst(type)}`;
                badges.push(this.getBadge(key));
            });
            if (realEstateAd.endOfPromotedAsExclusive > 0) {
                badges.push(this.getBadge('promotedAsExclusive'));
            }
            if (realEstateAd.onlineBookingUrl) {
                badges.push(this.getBadge('onlineBooking', {
                    icon: 'online-booking',
                }));
            } else if (realEstateAd.hasToBeBuilt) {
                badges.push(this.getBadge('hasToBeBuilt'));
            } else if (realEstateAd.newProperty) {
                badges.push(this.getBadge('newBuild'));
            }
            if (realEstateAd.adType === 'lifeAnnuitySale') {
                badges.push(this.getBadge('lifeAnnuitySale'));
            } else if (realEstateAd.isPropertyWithoutUsufruct) {
                badges.push(this.getBadge('propertyWithoutUsufruct'));
            }
            if (realEstateAd.specialOffer) {
                badges.push(this.getBadge('specialOffer'));
            }
            return badges;
        },
    },
    methods: {
        getBadge(key, options) {
            return _.extend({
                key,
                text: this[key],
                variant: BADGES_VARIANTS[key],
            }, options);
        },
    },
    template: template(),
};
