const {INITIAL_STEP} = require('../Constants');
const {buildStore, buildComputed} = require('../RealEstatePropertyDescriptorStoreHelpers');

function getState() {
    return {
        step: INITIAL_STEP,
        optins: {},
        agenciesToContact: [],
    };
}

function getRealEstateProperty() {
    return {
        propertyType: null,
        surfaceArea: null,
        address: null,
        roomsQuantity: null,
        price: null,
    };
}

module.exports = {
    computed: buildComputed(getState(), getRealEstateProperty()),
    get: (moduleName) => buildStore(getState(), getRealEstateProperty(), moduleName),
};
