const _ = require('lodash');
const MapApi = require('./MapApi');

const ClipperLib = require('clipper.js');

//test with https://www.kimono.cka.f4-group.com/annonce/location/hellemmes-lille/maison/4pieces/century-21-202_2424_9173?camera=8_2.9759942_50.286583_0.9_0&q=%2Frecherche%2Flocation%2Fnord-59%2Fmaisonvilla%3Fprix-max%3D750%26tri%3Dpertinence-desc
const ClipperScale = 100000000000;
const MergeScale = 4000000;

module.exports = {
    mergePolygons, //called from Zone:43
};

/**
 * called from Zone:43
 * @param {object[]} overlaysByGeometries array of objects with latLngBounds, overlays, id
 * @param {object} options some options & some displayConfiguration all in the same place!
 * @param {boolean} options.isHole reverse render, make a wolrd covering polygon with a hole
 * @param {object} options.bounds
 * @returns {*}
 */
function mergePolygons(overlaysByGeometries, options) {
    //marks overlays with overlaps flag
    _detectOverlappingOverlaysByGeometries(overlaysByGeometries);

    let arrayPolygonPointsToMerge = [];
    let arrayPolygonPoints = [];
    _.each(overlaysByGeometries, (overlaysByGeometry) => {
        const points = _extractPolygonsPointsFromOverlays(overlaysByGeometry.overlays);
        if (overlaysByGeometry.overlaps) {
            arrayPolygonPointsToMerge = arrayPolygonPointsToMerge.concat(points);
        } else {
            arrayPolygonPoints = arrayPolygonPoints.concat(points);
        }
    });
    const mergedPath = _mergePolygons(arrayPolygonPointsToMerge);
    const paths = new MapApi.api.MVCArray(arrayPolygonPoints.concat(mergedPath.getArray()));
    if (options.isHole) {
        return _createPolygonsWithHole(options, paths, options.bounds);
    }
    return [new MapApi.api.Polygon(_.defaults({}, options, {paths: paths}))];
}

function _detectOverlappingOverlaysByGeometries(overlaysByGeometries) {
    _.each(overlaysByGeometries, (overlaysByGeometryA) => {
        _.each(overlaysByGeometries, (overlaysByGeometryB) => {
            if (overlaysByGeometryA !== overlaysByGeometryB && _areOverlaysOverlapping(overlaysByGeometryA, overlaysByGeometryB)) {
                overlaysByGeometryA.overlaps = true;
                overlaysByGeometryB.overlaps = true;
            }
        });
    });
}

function _areOverlaysOverlapping(overlaysByGeometryA, overlaysByGeometryB) {
    const boundsA = overlaysByGeometryA.latLngBounds;
    const boundsB = overlaysByGeometryB.latLngBounds;
    return !(
        boundsA.sw.lng() > boundsB.ne.lng() || boundsA.ne.lng() < boundsB.sw.lng()
        || boundsA.sw.lat() > boundsB.ne.lat() || boundsA.ne.lat() < boundsB.sw.lat()
    );
}

function _createPolygonsWithHole(options, paths, bounds) {
    const polygons = [];
    const sizeLat = 85;
    const sizeLon = 180;
    const scale = 1.1;
    const halfWidth = (bounds.right - bounds.left) * 0.5;
    const halfHeight = (bounds.top - bounds.bottom) * 0.5;
    const centerX = bounds.left + halfWidth;
    const centerY = bounds.bottom + halfHeight;
    bounds.left = Math.max(centerX - halfWidth * scale, -sizeLon);
    bounds.right = Math.min(centerX + halfWidth * scale, sizeLon);
    bounds.bottom = Math.max(centerY - halfHeight * scale, -sizeLat);
    bounds.top = Math.min(centerY + halfHeight * scale, sizeLat);
    const noWrap = true;
    const worldBorder = new MapApi.api.MVCArray([
        new MapApi.api.LatLng(sizeLat, -sizeLon, noWrap),
        new MapApi.api.LatLng(-sizeLat, -sizeLon, noWrap),
        new MapApi.api.LatLng(-sizeLat, sizeLon, noWrap),
        new MapApi.api.LatLng(sizeLat, sizeLon, noWrap),
        new MapApi.api.LatLng(sizeLat, -sizeLon, noWrap),
    ]);
    paths.insertAt(0, worldBorder);
    const polygonOptions = _.defaults({}, options, {
        paths,
    });
    polygons.push(new MapApi.api.Polygon(polygonOptions));
    return polygons;
}

function _convertArrayPolygonToClipper(arrayPolygon) {
    const clipperPaths = [];
    for (let j = 0; j < arrayPolygon.length; j++) {
        const array = arrayPolygon[j];
        const subjPolygon = new ClipperLib.Path();
        for (let i = 0; i < array.length; i++) {
            subjPolygon.push(new ClipperLib.IntPoint(array[i].lat(), array[i].lng()));
        }
        //used when checking for overlap
        subjPolygon.bbox = ClipperLib.Clipper.GetBounds([subjPolygon]);
        clipperPaths.push(subjPolygon);
    }
    return clipperPaths;
}

function _groupOverlappingClipperPaths(clipperPaths) {
    let groupedClipperPaths = [];
    for (let i = 0; i < clipperPaths.length; ++i) {
        const path = clipperPaths[i];
        const newListLists = [];
        let newList = [path];
        for (let j = 0; j < groupedClipperPaths.length; ++j) {
            const oldList = groupedClipperPaths[j];
            if (_isOverlapping(path, oldList)) {
                newList = oldList.concat(newList);
            } else {
                newListLists.push(oldList);
            }
        }
        newListLists.push(newList);
        groupedClipperPaths = newListLists;
    }
    return groupedClipperPaths;

    function _isOverlapping(path, listPath) {
        return _.find(listPath, function (currentPath) {
            return !(currentPath.bbox.left > path.bbox.right || currentPath.bbox.right < path.bbox.left
                || currentPath.bbox.top > path.bbox.bottom || currentPath.bbox.bottom < path.bbox.top);
        });
    }
}

function _mergeGroupOverlappingClipperPaths(groupedClipperPaths) {
    let mergedPaths = [];
    _.each(groupedClipperPaths, overlappingPolygons => {
        const cpr = new ClipperLib.Clipper();
        const solutionPolygons = new ClipperLib.Paths();
        ClipperLib.JS.ScaleUpPaths(overlappingPolygons, ClipperScale);
        if (overlappingPolygons.length > 1) {
            const offset = new ClipperLib.ClipperOffset();
            offset.AddPaths(overlappingPolygons, ClipperLib.JoinType.jtMiter, ClipperLib.EndType.etClosedPolygon);
            overlappingPolygons = new ClipperLib.Paths();
            offset.Execute(overlappingPolygons, MergeScale);
        }
        cpr.AddPaths(overlappingPolygons, ClipperLib.PolyType.ptSubject, true);
        const succeeded = cpr.Execute(ClipperLib.ClipType.ctUnion, solutionPolygons);
        if (succeeded) {
            _.each(solutionPolygons, solutionPolygon => {
                if (solutionPolygon.length > 2) {
                    const firstPoint = solutionPolygon[0];
                    const lastPoint = solutionPolygon[solutionPolygon.length - 1];
                    if (lastPoint.X != firstPoint.X || lastPoint.Y != firstPoint.Y) {
                        solutionPolygon.push(new ClipperLib.IntPoint(firstPoint.X, firstPoint.Y));
                    }
                }
            });
        }
        mergedPaths = mergedPaths.concat(solutionPolygons);
    });
    ClipperLib.JS.ScaleDownPaths(mergedPaths, ClipperScale);
    return mergedPaths;
}

function _mergePolygons(arrayPolygon) {
    let finalClipperPaths = arrayPolygon;
    if (arrayPolygon.length) {
        const clipperPaths = _convertArrayPolygonToClipper(arrayPolygon);
        const groupedClipperPaths = _groupOverlappingClipperPaths(clipperPaths);
        finalClipperPaths = _mergeGroupOverlappingClipperPaths(groupedClipperPaths);
    }
    return _convertClipperPaths2OverlayPaths(finalClipperPaths);
}

function _convertClipperPaths2OverlayPaths(clipperPaths) {
    const paths = new MapApi.api.MVCArray();
    for (let i = 0; i < clipperPaths.length; i++) {
        const array = [];
        for (let j = 0; j < clipperPaths[i].length; j++) {
            const lat = clipperPaths[i][j].X;
            const lon = clipperPaths[i][j].Y;
            array.push(new MapApi.api.LatLng(lat, lon));
        }
        paths.push(new MapApi.api.MVCArray(array));
    }
    return paths;
}

function _extractPolygonsPointsFromOverlays(overlays) {
    let arrayPolygon = [];
    _.each(overlays, (overlay) => {
        const overlayGeometry = [];
        if (overlay.getPaths) {
            _.each(overlay.getPaths().getArray(), (array) => {
                const newArray = _.clone(array.getArray());
                overlayGeometry.push(newArray);
            });
        } else if (overlay.getPath) {
            const newArray = _.clone(overlay.getPath().getArray());
            overlayGeometry.push(newArray);
        }
        arrayPolygon = arrayPolygon.concat(overlayGeometry);
    });
    return arrayPolygon;
}
