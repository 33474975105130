const namespaceOrIdPattern = '([^/]+)';
const amepiPrefix = 'amepi/';
const userRealEstateAdsPrefix = 'annonces-';

const searchUrlPrefixes = {
    namespaceOrIdPattern,
    amepi: amepiPrefix,
    amepiPattern: amepiPrefix + namespaceOrIdPattern,
    favorites: 'favoris',
    myRealEstateAds: 'mes-annonces',
    search: 'recherche',
    userRealEstateAds: userRealEstateAdsPrefix,
    userRealEstateAdsPattern: userRealEstateAdsPrefix + namespaceOrIdPattern,
};

module.exports = searchUrlPrefixes;
