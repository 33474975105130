const _ = require('lodash');
const {getLinkToAds} = require('../../search/LoadUserAdsHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./AdsLinkButton.jade');
const {props: {variant: {PRIMARY_VARIANT, LINK_VARIANT}}} = require('../../vue/components/Button');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AdsLinkButton.',
            keys: [
                'title',
            ],
        }),
    ],
    props: {
        agencyId: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: PRIMARY_VARIANT,
            validator: variant => _.includes([PRIMARY_VARIANT, LINK_VARIANT], variant),
        },
    },
    computed: {
        adsLinkUrl() {
            return getLinkToAds(this.agencyId);
        },
    },
    template: template(),
};
