const Account = require('../authentication/Account');
const Options = require('../Options');

module.exports = {
    getCommonFields,
    getOptionalFields,
};

function getCommonFields() {
    const isNotShowRoom = !Account.isShowRoom();
    return {
        propertyType: true,
        minRooms: true,
        maxRooms: true,
        minPrice: true,
        maxPrice: true,
        minArea: true,
        maxArea: true,
        newProperty: true,
        extensionType: true,
        parkingBox: isNotShowRoom,
        shop: isNotShowRoom,
        building: isNotShowRoom,
        premises: isNotShowRoom,
        castle: true,
        office: isNotShowRoom,
        townhouse: true,
        others: isNotShowRoom,
        annexe: Account.isAdmin(),
        terrain: isNotShowRoom,
        loft: true,
        opticalFiber: true,
        chargingStations: Options.get('chargingStationsEnabled'),
    };
}

function getOptionalFields() {
    const buildingPlotSearcher = Account.hasRole('buildingPlotSearcher');
    const promotedAsExclusiveSearcher = Account.hasRole('promotedAsExclusiveSearcher');
    return {
        buildingPlot: buildingPlotSearcher,
        exportableAd: Account.isCatalogueViewer(),
        isPromotedAsExclusive: promotedAsExclusiveSearcher,
        notToBeBuilt: Account.isAdmin(),
    };
}
