const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const template = require('./ContractAndExtensionLabels.jade');
const ContractOrExtensionLabel = require('./ContractOrExtensionLabel');

const NO_CONTRACT_COLOR = 'lightpink';

// @vue/component
module.exports = {
    components: {
        ContractOrExtensionLabel,
    },
    props: {
        account: {
            type: Object,
            required: true,
        },
        large: Boolean,
    },
    computed: {
        contractsAndExtensions() {
            const {account: {
                highestContract,
                extensions,
            }} = this;
            const contract = _.defaultTo(highestContract, {
                contractLabel: translate('contractNames.noContract'),
                contractColor: NO_CONTRACT_COLOR,
            });
            return _(contract)
                .concat(extensions)
                .compact()
                .value();
        },
        modifiers() {
            return _.pick(this, 'large');
        },
    },
    template: template(),
};
