const {i18n: {translate}} = require('fack');

module.exports = {
    validate: function (validator, $field) {
        // see https://stripe.com/docs/js/element/the_element_container for available classes

        const $stripeElementNode = $field.siblings('.StripeElement');

        let errorMessage;
        if ($stripeElementNode.hasClass('StripeElement--empty')) {
            errorMessage = translate('formErrorMessages.fieldRequired');
        } else if ($stripeElementNode.hasClass('StripeElement--invalid')) {
            errorMessage = $stripeElementNode.attr('error-message');
        }

        if (errorMessage) {
            return {valid: false, message: errorMessage};
        } else {
            return true;
        }
    },
};
