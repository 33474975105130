const template = require('./FieldWithDropdown.jade');

// @vue/component
module.exports = {
    props: {
        right: Boolean,
    },
    methods: {
        closeDropdown() {
            this.$refs.dropdown.forceClose();
        },
    },
    template: template(),
};
