const _ = require('lodash');

module.exports = {
    getAccountName,
};

function getAccountName(account) {
    return account.display_name
        || _.get(account, 'company.name')
        || _.get(account, 'company.company.corporateName');
}
