const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const UrlHelper = require('../../common/UrlHelper');
const SimpleContentVueView = require('../vue/SimpleContentVueView');

const DEFAULT_CONFIGURATION = {
    title: 'Bien’ici',
    pageContext: 'public',
    gtmCategory: 'Content',
    enableCookiesBannerSurfaceExpanded: true,
};

module.exports = class SimpleContentPage extends ApplicationPage {
    constructor(configuration) {
        _.defaults(configuration, DEFAULT_CONFIGURATION);
        super(configuration);
        this.configuration = configuration;
        const {contentPath, contentSubPath, view} = configuration;

        this.contentPath = contentPath;
        this.contentSubPath = contentSubPath;
        this.ContentView = view || SimpleContentVueView;
    }

    open(options) {
        this.options = _.extend(options, this.configuration);
        super.open(options);
    }

    getMiddleViews() {
        return new this.ContentView();
    }

    getUrlPattern() {
        return this.configuration.urlPattern || '/' + this.getName();
    }

    _loadData(options, cb) {
        const {contentPath, contentSubPath, requiresRegistration} = this;
        const slug = contentPath ? UrlHelper.slugify(contentPath) : this.getName();
        const paths = ['/html'];
        if (requiresRegistration) {
            paths.push('private');
        }
        if (contentSubPath) {
            paths.push(contentSubPath);
        }
        paths.push(slug + '.html');
        options.url = paths.join('/');
        this.simpleContentLoadDataRequest(options, cb);
    }
};
