const _ = require('lodash');
const ContactBlockWithTitle = require('./BlockWithTitle');
const template = require('./Call.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    components: {
        ContactBlockWithTitle,
    },
    mixins: [
        i18nMixin({
            prefix: 'Call.',
            keys: [
                'title',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formattedTexts() {
            const {contact} = this;
            const translationKeys = [
                'callerPhoneNumber',
                'targetPhoneNumber',
                'status',
            ];
            const context = contact.callerPhoneNumber ? '' : 'withheldNumber';
            if (contact.transactionType) {
                translationKeys.splice(2, 0, 'transactionType');
            }
            if (contact.answered) {
                translationKeys.push(
                    'duration',
                    'callWaitingTime'
                );
            }
            return _.map(translationKeys, translationKey => {
                return this.t(translationKey, _.extend({context}, contact));
            });
        },
    },
    template: template(),
};
