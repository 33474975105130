const WindowMessages = require('../utils/events/WindowMessages');
const OpenWindow = require('../utils/OpenWindow');
const LinkClickHandler = require('../app/LinkClickHandler');
const GoogleTagManager = require('../stats/GoogleTagManager');
const safeFrameAdUtils = require('../utils/safeFrameAdUtils');
const sessionStorage = require('../utils/sessionStorage');

module.exports = {
    bind,
};

function bind() {
    WindowMessages.on('openUrl', function ({id, url}) {
        sendGTMEvent({type: 'openUrl', id, url});
        LinkClickHandler.openInSameWindow(url);
    });
    WindowMessages.on('openTab', function ({id, url}) {
        sendGTMEvent({type: 'openTab', id, url});
        OpenWindow.open({url});
    });
    WindowMessages.on('advertisementClosed', function () {
        safeFrameAdUtils.removeAdAndResetSafeFrameRelatedStyles();
        sessionStorage.setValue('closedAd', true);
        safeFrameAdUtils.handleAdEventsTracking('close_Ad');
    });
    WindowMessages.on('hideSafeFrameContainer', function () {
        sessionStorage.setValue('adDisplayed', false);
        safeFrameAdUtils.removeAdAndResetSafeFrameRelatedStyles();
    });
    WindowMessages.on('displaySafeFrameContainer', function () {
        sessionStorage.setValue('adDisplayed', true);
        safeFrameAdUtils.handleAdEventsTracking('display_Ad');
    });
}

function sendGTMEvent(data) {
    GoogleTagManager.sendEvent('adClick', data);
}
