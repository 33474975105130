const _ = require('lodash');
const template = require('./ShareButton.jade');
const Views = require('../../../views/Views');
const i18nMixin = require('../mixins/i18n');
const ABORT_ERROR_NAME = 'AbortError';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ShareButton.',
            keys: [
                'errorMessage',
            ],
        }),
    ],
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    methods: {
        share() {
            const data = _.pick(this, ['title', 'text', 'url']);
            navigator.share(data)
                .catch((err) => {
                    if (!err.toString().startsWith(ABORT_ERROR_NAME)) {
                        console.error(err);
                        Views.volatileFeedback.showError(this.errorMessage);
                    }
                });
            this.$emit('share-interface-opened');
        },
    },
    template: template(),
};
