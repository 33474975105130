const _ = require('lodash');

// @vue/component
module.exports = {
    props: {
        collection: {
            type: Array,
            required: true,
        },
        predicate: {
            type: Object,
            required: true,
        },
    },
    computed: {
        element() {
            return _.find(this.collection, this.predicate);
        },
    },
    render() {
        return this.$scopedSlots.default({
            element: this.element,
        });
    },
};
