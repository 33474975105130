const {resourceUrl} = require('fack');
const template = require('./ClickableImage.jade');

// @vue/component
module.exports = {
    props: {
        caption: {
            type: String,
            required: true,
        },
        imagePath: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    computed: {
        fullPath() {
            return resourceUrl(`images/${this.imagePath}`);
        },
        imageStyles() {
            return {
                backgroundImage: `url(${this.fullPath})`,
            };
        },
    },
    template: template(),
};
