/* global f4:false */
const $ = require("jquery");
const api = f4.map;
const _ = require("lodash");

module.exports = class PopupLayer extends api.OverlayView {
    constructor(options) {
        super();
        if (('map' in options)) {
            this.setMap(options.map);
        }
        this.marker = null;
        this.boundToleranceInPx = 0;
        this.transform = this.get3dTransform();
    }

    get3dTransform() {
        const el = document.createElement('p');
        let has3d;
        const transforms = {
            'webkitTransform': '-webkit-transform',
            'OTransform': '-o-transform',
            'msTransform': '-ms-transform',
            'MozTransform': '-moz-transform',
            'transform': 'transform',
        };

        //Add it to the body to get the computed style
        document.body.insertBefore(el, null);
        let key = null;
        for (const t in transforms) {
            if (el.style[t] !== undefined) {
                el.style[t] = 'translate3d(1px,1px,1px)';
                has3d = window.getComputedStyle(el).getPropertyValue(transforms[t]);
                if (has3d) {
                    key = t;
                }
            }
        }
        document.body.removeChild(el);
        if (has3d !== undefined && has3d.length > 0 && has3d !== "none") {
            return key;
        }
        return null;
    }

    draw() {
        this.movePopup();
    }

    onAdd() {
        this.onRemove();
        const $container = $('<div>')
            .addClass('pinsContainer');
        $container.appendTo(this.getPanes().floatPane);
        this.$container = $container;
        if (this.$node) {
            this._showNode();
        }
    }

    onRemove() {
        if (this.$container) {
            this.$container.empty();
            delete this.$container;
            if (this.getMap()) {
                this.getMap().setHoverEnabled(true);
            }
        }
    }

    movePopup() {
        const $node = this.$node;
        const map = this.getMap();
        let margin;
        let popupSize;
        let screenSize;
        let markerPos;
        let anchor;
        let markerSize;
        if (this.$container && $node && this.marker && map) {
            const boundToleranceInPx = this.boundToleranceInPx;
            margin = 10;
            const $popupEl = $($node.children()[0]);
            popupSize = {width: $popupEl.outerWidth(), height: $popupEl.outerHeight()};
            const $screen = $(map.getDiv());
            screenSize = {width: $screen.width(), height: $screen.height()};
            markerPos = this.getMarkerPositionInScreen(this.marker);
            anchor = this.getMarkerAnchor();
            markerSize = this.getMarkerSize();

            const possiblePositions = getPossiblePositions();
            let popupPos = _.find(possiblePositions, function (availablePosition) {
                return canFit(availablePosition, 0);
            });
            if (!popupPos) {
                popupPos = _.find(possiblePositions, function (availablePosition) {
                    return canFit(availablePosition, boundToleranceInPx);
                });
            }
            if (!popupPos) {
                popupPos = {
                    left: -popupSize.width * 0.5,
                    top: -popupSize.height - markerSize.height,
                };
            }

            popupPos.left += markerPos.x;
            popupPos.top += markerPos.y;
            popupPos.left = Math.round(popupPos.left);
            popupPos.top = Math.round(popupPos.top);

            if (this._lastPosX != popupPos.left || this._lastPosY != popupPos.top) {
                this._lastPosX = popupPos.left;
                this._lastPosY = popupPos.top;
                const css = {};
                if (this.transform) {
                    css[this.transform] = "translate3d(" + popupPos.left + "px," + popupPos.top + "px,0)";
                } else {
                    css.left = popupPos.left;
                    css.top = popupPos.top;
                }
                $node.css(css);
            }
        }

        function getPossiblePositions() {
            const variableLeft = getVariableValueFromVerticality('left');
            const variableTop = getVariableValueFromVerticality('top');
            return [
                {
                    top: -popupSize.height - anchor.y,
                    left: variableLeft,
                },
                {
                    top: variableTop,
                    left: markerSize.width - anchor.x,
                },
                {
                    top: markerSize.height - anchor.y,
                    left: variableLeft,
                },
                {
                    left: -popupSize.width - anchor.x,
                    top: variableTop,
                },
                //angles outside screen:
                {
                    left: variableLeft,
                    top: variableTop,
                },
            ];
        }

        function canFit(popupPosition, ajustValueInPx) {
            const screenBounds = {
                left: 0 - ajustValueInPx,
                top: 0 - ajustValueInPx,
                right: screenSize.width + ajustValueInPx,
                bottom: screenSize.height + ajustValueInPx,
            };
            const top = markerPos.y + popupPosition.top;
            const left = markerPos.x + popupPosition.left;
            const right = left + popupSize.width;
            const bottom = top + popupSize.height;
            return left >= screenBounds.left
                && right <= screenBounds.right
                && top >= screenBounds.top
                && bottom <= screenBounds.bottom;
        }

        function getVariableValueFromVerticality(direction) {
            let markerPosOnAxis, screenSideSize, popupSideSize;

            if (direction == 'top') {
                markerPosOnAxis = markerPos.y;
                screenSideSize = screenSize.height;
                popupSideSize = popupSize.height;
            } else {
                markerPosOnAxis = markerPos.x;
                screenSideSize = screenSize.width;
                popupSideSize = popupSize.width;
            }

            if (screenSideSize > popupSideSize) {
                if (screenSideSize < popupSideSize + 2 * margin) {
                    margin = (screenSideSize - popupSideSize) * 0.5;
                }
            } else {
                margin = 0;
            }

            if (markerPosOnAxis - popupSideSize * 0.5 >= margin) {
                if (markerPosOnAxis + popupSideSize * 0.5 <= screenSideSize - margin) {
                    return -popupSideSize * 0.5;
                } else {
                    return screenSideSize - margin - markerPosOnAxis - popupSideSize;
                }
            } else {
                return -markerPosOnAxis + margin;
            }
        }
    }

    getMarkerSize() {
        if (this.marker && this.getMap() && this.getMap()._renderer) {
            const pin = this.getMap()._markersById[this.marker.id];
            if (pin) {
                const size = this.getMap()._renderer._pinsManager.getPinSize(pin);
                return new api.Size(size.width, size.height);
            }
        }
        if (this.marker && this.marker.icon) {
            if (this.marker.icon.scaledSize) {
                return this.marker.icon.scaledSize;
            } else if (this.marker.icon.size) {
                return this.marker.icon.size;
            }
        }
        return new api.Size(0, 0);
    }

    getMarkerAnchor() {
        if (this.marker && this.getMap() && this.getMap()._renderer) {
            const pin = this.getMap()._markersById[this.marker.id];
            if (pin) {
                const anchor = this.getMap()._renderer._pinsManager.getPinAnchor(pin);
                return new api.Point(anchor.x, anchor.y);
            }
        }
        if (this.marker && this.marker.anchor) {
            return this.marker.anchor;
        }
        return new api.Point(0, 0);
    }

    setRelativePosition(boundToleranceInPx) {
        if (!boundToleranceInPx) {
            this.boundToleranceInPx = 0;
        } else {
            this.boundToleranceInPx = boundToleranceInPx;
        }
        this.movePopup();
    }

    showPopup(marker, html) {
        const $node = this.$node = $(html);
        $node.css({'pointer-events': 'auto'});
        this._lastPosX = null;
        this._lastPosY = null;
        this.marker = marker;
        if (this.$container) {
            this._showNode($node);
        }
    }

    _showNode() {
        const $node = this.$node;
        this.$container
            .empty()
            .append($node);
        if (this.marker) {
            this._movePopupBinding = _.bind(this.movePopup, this);
            this.marker.on("position_changed", this._movePopupBinding);
        }
        this.movePopup();
    }

    hidePopup() {
        if (this.$container) {
            this.$container.empty();
        } else {
            console.warn("calling PopupLayer.hidePopup while container is not set");
        }
        if (this.marker && this._movePopupBinding) {
            this.marker.removeListener("position_changed", this._movePopupBinding);
            delete this._movePopupBinding;
            this.marker = null;
        }
        const map = this.getMap();
        if (map) {
            map.setHoverEnabled(true);
        }
    }

    getMarkerPositionInScreen(marker) {
        return this.getProjection().fromLatLngAndHeightToContainerPixel(marker.getPosition(), marker.getHeight());
    }
};
