const $ = require('jquery');
const template = require('../templates/searchListModeSelectionHeaderView.jade');
const View = require('../views/View');
const _ = require('lodash');
const EventPack = require('../utils/EventPack');

module.exports = class SearchListModeSelectionHeaderView extends View {
    constructor(options) {
        super({
            template,
        });
        this._searchListModeModel = options.searchListModeModel;
    }

    show(options) {
        this._eventsWhileShown = new EventPack();
        super.show(options);
        const {$element} = this;
        this._eventsWhileShown.on($element, 'click', '[data-mode]', evt => {
            evt.preventDefault(); // prevent click on ads when list is scrolled
            evt.stopPropagation();
            const $button = $(evt.target);
            const mode = $button.attr('data-mode');
            $element.find('[data-mode].active').removeClass('active');
            this.emit('selectMode', mode);
            $button.addClass('active');
        });
        this._displayInSelectedMode();
        this._eventsWhileShown.on(this._searchListModeModel, 'mode', _.bind(this._displayInSelectedMode, this));
        this._eventsWhileShown.on(this._searchListModeModel, 'mapModeAvailable', _.bind(this._updateMapModeButton, this));
    }

    hide() {
        this._eventsWhileShown.removeAllListeners();
        View.prototype.hide.apply(this, arguments);
    }

    _updateMapModeButton() {
        const mapModeAvailable = this._searchListModeModel.getMapModeAvailable();
        const $button = this.$element.find('[data-mode="map"]');
        $button.toggle(Boolean(mapModeAvailable) && !$button.hasClass('active'));
    }

    _updateGalleryModeButton() {
        const $button = this.$element.find('[data-mode="gallery"]');
        $button.toggle(!$button.hasClass('active'));
    }

    _displayInSelectedMode() {
        const selectedMode = this._searchListModeModel.getMode();
        _.each(this.$element.find('[data-mode]'), function (el) {
            const $el = $(el);
            const mode = $el.attr('data-mode');
            const selected = mode == selectedMode;
            $el.toggleClass('active', selected);
        });
        this._updateMapModeButton();
        this._updateGalleryModeButton();
    }
};
