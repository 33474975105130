const template = require('./BreadcrumbLink.jade');

// @vue/component
module.exports = {
    props: {
        index: {
            type: Number,
            required: true,
        },
        url: {
            type: String,
            default: undefined,
        },
        text: {
            type: String,
            required: true,
        },
        isLast: Boolean,
    },
    template: template(),
};
