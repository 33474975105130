const _ = require('lodash');
const _numberFormat = require('underscore.string/numberFormat');
const $ = require('jquery');
const {EventEmitter} = require('events');
const MapApi = require('../MapApi');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const BlurHelper = require('../BlurHelper');
const MapHelper = require('./MapHelper');

const FIT_ADS_MAX_ZOOM = 18;

module.exports = class CameraManager extends EventEmitter {
    constructor() {
        super();
        this._map = null;
        this._camera = _.clone(DefaultConfiguration.camera);
    }

    setMap(map) {
        this._map = map;
    }

    isWebglEnabled() {
        return this._map && this._map.conf.enableWebGL;
    }

    getCameraFromMap() {
        const map = this._map;
        const center = map.getCenter();
        return _.extend(
            {
                '3d': map.get3d(),
                zoom: map.getZoom(),
                phi: map.getHeading(),
                theta: map.getTilt(),
            },
            positionToLatLon({
                lat: center.lat(),
                lon: center.lng(),
            }));
    }

    moveCameraToBounds(latLngBounds, cameraOptions) {
        if (this._map && this._map._renderer) {
            if (this.isWebglEnabled()) {
                const center = latLngBounds.getCenter();
                const boundsZoom = this._map._renderer.getZoomFromBoundsFromApi(latLngBounds);
                cameraOptions.zoom = Math.min(boundsZoom, cameraOptions.zoom);
                cameraOptions.position = {
                    lon: center.lng(),
                    lat: center.lat(),
                };
                this.moveCameraToPosition(cameraOptions);
            } else {
                this._map.panToBounds(latLngBounds);
                this._onCameraMoveEnded(cameraOptions);
            }
        }
    }

    moveCameraToNeighborhoodZone(overlays, teleport) {
        const latLngBounds = new MapApi.api.LatLngBounds();
        _.each(overlays, function (overlay) {
            if (overlay.type == 'Error') {
                console.warn('overlay got an error', overlay.message);
            } else {
                const paths = overlay.getPaths();
                paths.forEach(function (path) {
                    path.forEach(function (latLng) {
                        latLngBounds.extend(latLng);
                    });
                });
            }
        });
        const mapAngles = MapHelper.getAnglesFromMapType(this._map.getMapTypeId());
        this.moveCameraToBounds(latLngBounds, {
            '3d': mapAngles.is3d,
            zoom: 18,
            theta: mapAngles.theta,
            phi: mapAngles.phi,
            teleport,
        });
    }

    getCamera() {
        return this._camera;
    }

    setCamera(camera, options) {
        options = options || {};
        options = _.defaults(options, {teleport: true});
        const map = this._map;
        if (map) {
            if (this._hasCameraAnimation) {
                this._abortCameraAnimation();
            }
            options = _.extend({skipCameraUrl: !(camera)}, options);
            const cameraConf = this._getCameraFromApi();
            if (cameraConf && !(this._cameraEquals(cameraConf, camera))) {
                camera.cameraNotSet = camera.cameraNotSet || false;
                _.extend(this._camera, camera);
                options.position = {lat: +camera.lat, lon: +camera.lon};
                options.zoom = this._camera.zoom;
                options.theta = this._camera.theta;
                options.phi = this._camera.phi;
                options['3d'] = this._camera['3d'];
                if (!options.teleport) {
                    this.moveCameraToPosition(options);
                } else {
                    this._setCamera(options);
                }
            } else if (options.forceCameraChanged) {
                this._onCameraMoveEnded(options);
            }
        }
    }

    isCameraMoving() {
        if (this._map && this._map._renderer) {
            return this._map._renderer.isMoving();
        }
        return false;
    }

    updateCameraFromMap(eventInfo) {
        const camera = this._getCameraFromApi();
        if (camera && !(this._cameraEquals(camera, this._camera))) {
            camera.cameraNotSet = camera.cameraNotSet || false;
            _.extend(this._camera, camera); //copy camera values to this._camera
            this.triggerCameraChanged(eventInfo);
        } else if (eventInfo.skipCameraUrl || eventInfo.forceCameraChanged) {
            this.triggerCameraChanged(eventInfo);
        }
    }

    triggerMapIdle() {
        if (this._map) {
            this.emit('mapIdle');
        }
    }

    fitCameraToBounds(latLngBounds, options) {
        options = options || {};
        this.fitBounds(latLngBounds, options);
    }

    fitBounds(latLngBounds, options) {
        if (this._map) {
            options = _.defaults(options || {}, {
                minZoom: DefaultConfiguration.camera.zoomMin,
                maxZoom: DefaultConfiguration.camera.zoomMax,
                zoom: DefaultConfiguration.camera.zoomMax,
                teleport: true,
            });
            if (options && options.margin) {
                this._addMarginToBounds(latLngBounds, options.margin);
            }
            if (options.maxZoom != null && options.zoom > options.maxZoom) {
                options.zoom = options.maxZoom;
            } else if (options.minZoom != null && options.zoom < options.minZoom) {
                options.zoom = options.minZoom;
            }
            this.moveCameraToBounds(latLngBounds, options);
        }
    }

    //margin can be {x,y} or a single value. Peut-être que ça ne marche pas si les bounds sont près des limites 180 -180
    _addMarginToBounds(bounds, margin) {
        const $element = $(this._map.getDiv());
        const width = $element.width();
        const height = $element.height();
        if (_.isNumber(margin)) {
            margin = {x: margin, y: margin};
        }

        const wantedBoundsHeight = height - 2 * margin.y;
        const wantedBoundsWidth = width - 2 * margin.x;
        const xScale = margin.x / wantedBoundsWidth;
        const yScale = margin.y / wantedBoundsHeight;

        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const boundsLatAmplitude = Math.abs(sw.lat() - ne.lat());
        const boundsLngAmplitude = Math.abs(sw.lng() - ne.lng());
        const latMargin = boundsLatAmplitude * yScale;
        const lngMargin = boundsLngAmplitude * xScale;
        bounds.extend(new MapApi.api.LatLng(
            sw.lat() - latMargin,
            sw.lng() - lngMargin
        ));
        bounds.extend(new MapApi.api.LatLng(
            ne.lat() + latMargin,
            ne.lng() + lngMargin
        ));
    }

    setDefaultBounds(options) {
        this.fitCameraToBounds(new MapApi.api.LatLngBounds(
            DefaultConfiguration.camera.bounds.sw,
            DefaultConfiguration.camera.bounds.ne
        ), options);
    }

    triggerCameraChanged(eventInfo) {
        if (this._map) {
            this.emit('cameraChanged', eventInfo);
        }
    }

    _getCameraFromApi() {
        const map = this._map;
        if (!map._renderer) {
            return null;
        }

        const center = map.getCenter();
        return {
            '3d': map.get3d(),
            lat: +center.lat(),
            lon: +center.lng(),
            zoom: map.getZoom(),
            phi: map.getHeading(),
            theta: map.getTilt(),
        };
    }

    moveCameraToPosition(cameraOptions) {
        if (this._map) {
            if (this._hasCameraAnimation) {
                this._abortCameraAnimation();
            }
            const moveCameraToPositionHandler = _.bind(this._moveCameraToPosition, this);
            this._hasCameraAnimation = true;
            if (cameraOptions.asyncCameraOptions && !cameraOptions.teleport) {
                this._asyncAbortCallback = this._moveCameraAsync(moveCameraToPositionHandler, cameraOptions);
            } else {
                moveCameraToPositionHandler(cameraOptions);
            }
        }
    }

    _moveCameraAsync(callback, cameraOptions) {
        let timerIdTeleportFace;
        const that = this;
        const asyncCameraOptions = cameraOptions.asyncCameraOptions;
        if (asyncCameraOptions.waitDelay) {
            timerIdTeleportFace = setTimeout(teleportFast, asyncCameraOptions.waitDelay);
            if (asyncCameraOptions.waitForEventsCallback) {
                asyncCameraOptions.waitForEventsCallback(onWaitReady);
            } else {
                onWaitReady();
            }
        } else {
            moveNow();
        }

        function onWaitReady() {
            // waiting events has ended, move if it was short waiting => inferior to cameraAnimationDelay
            if (timerIdTeleportFace != null) {
                onAbort();
                moveNow();
            }
        }

        function teleportFast() {
            onAbort();
            cameraOptions.teleport |= true;
            moveNow();
        }

        function moveNow() {
            if (asyncCameraOptions.mustAbortMoveCameraToPosition && asyncCameraOptions.mustAbortMoveCameraToPosition()) {
                that._onCameraMoveEnded(cameraOptions);
            } else {
                callback(cameraOptions);
            }
        }

        function onAbort() {
            if (timerIdTeleportFace != null) {
                clearTimeout(timerIdTeleportFace);
                timerIdTeleportFace = null;
            }
        }

        return onAbort;
    }

    moveCameraToRealEstateAd(realEstateAd, cameraOptions) {
        let sw;
        let ne;
        const position = realEstateAd.blurInfo && realEstateAd.blurInfo.position || realEstateAd.position;
        if (realEstateAd.blurInfo && realEstateAd.blurInfo.bbox) {
            const bbox = realEstateAd.blurInfo.bbox;
            if (_.isArray(bbox)) {
                ne = new MapApi.api.LatLng(bbox[1], bbox[0]);
                sw = new MapApi.api.LatLng(bbox[3], bbox[2]);
            } else {
                console.log('bbox is not an array for ad ' + this.realEstateAd.id, bbox);
            }
        } else if (position && position.lat && (position.lon || position.lng)) {
            ne = sw = new MapApi.api.LatLng(position.lat, position.lon || position.lng);
        } else {
            console.warn('no Position for Ad ', this.realEstateAd);
        }
        if (ne) {
            const latLngBounds = new MapApi.api.LatLngBounds(ne, sw);
            this.moveCameraToBounds(latLngBounds, cameraOptions);
        } else {
            this._onCameraMoveEnded(cameraOptions);
        }
    }

    _onCameraMoveEnded(cameraOptions) {
        this._asyncAbortCallback = null;
        this._hasCameraAnimation = false;
        this.updateCameraFromMap(_.extend({skipCameraUrl: true}, cameraOptions));
        if (cameraOptions.onArrivalCallback) {
            cameraOptions.onArrivalCallback();
        }
    }

    _moveCameraToPosition(cameraOptions) {
        const map = this._map;
        if (map) {
            cameraOptions = _.extend(positionToLatLon(cameraOptions.position), cameraOptions);
            if (this.isWebglEnabled()) {
                const mapAngles = MapHelper.getAnglesFromMapType(this._map.getMapTypeId());
                cameraOptions = _.defaults(cameraOptions, {
                    '3d': mapAngles.is3d,
                    zoom: 18,
                    theta: mapAngles.theta,
                    phi: mapAngles.phi,
                });
                if (!cameraOptions.teleport) {
                    this._playAnimationTo(cameraOptions);
                } else {
                    this._setCamera(cameraOptions);
                }
            } else {
                map.panTo(cameraOptions.position);
                map.setZoom(cameraOptions.zoom);
                this._onCameraMoveEnded(cameraOptions);
            }
        }
    }

    _setCamera(cameraOptions) {
        const position = positionToLatLon(cameraOptions.position);
        const center = new MapApi.api.LatLng(position.lat, position.lon);
        this._map.setOptions({
            center,
            zoom: cameraOptions.zoom,
            tilt: cameraOptions.theta,
            heading: cameraOptions.phi,
            '3d': Boolean(cameraOptions['3d']),
        });
        this._onCameraMoveEnded(cameraOptions);
    }

    getFacadeCameraOptions() {
        const currentCenter = this._map.center;
        const zoom = this._map._renderer._cameraManager.getZoom(); // Read from camera
        return {
            cameraConfig: {
                lat: currentCenter._lat,
                lon: currentCenter._lng,
                zoom: Math.round(zoom),
                theta: this._map.getTilt(),
                phi: this._map.getHeading(),
                originalScreenWidth: Math.max($(this._map.getDiv()).innerWidth(), 100),
            },
        };
    }

    /**
     * Move camera to a facade camera position and angles
     *
     * @param {{zoom: number, lat: number, lon: number, theta: number, phi: number}} facadeCamera Facade Camera configuration
     * @param {{teleport: boolean, durationInMS: number, easing: string}} options Movement configuration
     */
    gotoFacadeCamera(facadeCamera, {teleport, durationInMS, easing}) {
        if (facadeCamera && this._map && this._map._renderer) {
            if (teleport || !this.isWebglEnabled()) {
                this._teleportToFacadeCamera(facadeCamera);
            } else {
                this._animateToFacadeCamera(facadeCamera, {durationInMS, easing});
            }
        }
    }

    _animateToFacadeCamera({zoom, lat, lon, theta, phi}, {durationInMS, easing}) {
        // Get current camera position and target
        const currentCenter = this._map.getCenter()._to900913();
        const f4MapCameraManager = this._map._renderer._cameraManager;
        const cameraOptions = {
            zoom: f4MapCameraManager.getZoom(), // Read from camera for precision
            lat: currentCenter.lat,
            lon: currentCenter.lon,
            camera: {
                phi: this._map.getHeading(),
                theta: this._map.getTilt(),
            },
        };
        const currentCamera = f4MapCameraManager.getWantedTargetAndCameraPos(cameraOptions);

        // Compute wanted camera position and target
        const latLng900913 = new MapApi.api.LatLng(lat, lon)._to900913();
        // avoid zooming too much
        zoom = f4MapCameraManager.limits.clampZoom(zoom);
        const mapAngles = MapHelper.getAnglesFromMapType(this._map.getMapTypeId(), {theta, phi});
        const finalPositionOptions = {
            '3d': mapAngles.is3d,
            lat: latLng900913.lat,
            lon: latLng900913.lon,
            zoom,
            camera: {
                theta: mapAngles.theta,
                phi: mapAngles.phi,
            },
        };
        const wantedCamera = f4MapCameraManager.getWantedTargetAndCameraPos(finalPositionOptions);

        // Create animation from current position to desired position in facade animation
        const animationKeys = [];
        animationKeys.push({camPos: currentCamera.posCamera, camTarget: currentCamera.posTarget});
        animationKeys.push({camPos: wantedCamera.posCamera, camTarget: wantedCamera.posTarget});

        f4MapCameraManager.startAnimation({
            posAndTargetList: animationKeys,
            durationInMS,
            easing,
            onEndReachIdleEvent: this._map._renderer.makeIdleEvent(true),
        });
    }

    _teleportToFacadeCamera({zoom, lat, lon, theta, phi}) {
        const mapAngles = MapHelper.getAnglesFromMapType(this._map.getMapTypeId(), {theta, phi});
        // avoid zooming too much
        zoom = this._map._renderer._cameraManager.limits.clampZoom(zoom);
        const cameraOptions = {
            zoom,
            position: {lat, lon},
            '3d': mapAngles.is3d,
            theta: mapAngles.theta,
            phi: mapAngles.phi,
        };
        this._setCamera(cameraOptions);
    }

    fitCameraToRealEstateAds(realEstateAds, options) {
        options = options || {};
        const latLngBounds = new MapApi.api.LatLngBounds();
        let hasAtLeastOneCoordinates = false;
        _.each(realEstateAds, function (realEstateAd) {
            if (realEstateAd.blurInfo && realEstateAd.blurInfo.bbox) {
                const bbox = realEstateAd.blurInfo.bbox;
                const ne = new MapApi.api.LatLng(bbox[1], bbox[0]);
                const sw = new MapApi.api.LatLng(bbox[3], bbox[2]);
                latLngBounds.extend(ne);
                latLngBounds.extend(sw);
                hasAtLeastOneCoordinates = true;
            } else {
                const displayedPosition = BlurHelper.getDisplayedPosition(realEstateAd);
                if (displayedPosition && null != displayedPosition.lat
                    && (null != displayedPosition.lng || null != displayedPosition.lon)) {
                    const latLng = new MapApi.api.LatLng(displayedPosition.lat, displayedPosition.lng || displayedPosition.lon);
                    latLngBounds.extend(latLng);
                    hasAtLeastOneCoordinates = true;
                }
            }

        });

        if (!hasAtLeastOneCoordinates) {
            console.warn('all ads without position');
            // Use default latlng bounds
            const nE = new MapApi.api.LatLng(DefaultConfiguration.camera.bounds.ne.lat, DefaultConfiguration.camera.bounds.ne.lng);
            const sW = new MapApi.api.LatLng(DefaultConfiguration.camera.bounds.sw.lat, DefaultConfiguration.camera.bounds.sw.lng);
            latLngBounds.extend(nE);
            latLngBounds.extend(sW);
        }
        this.fitCameraToBounds(latLngBounds, _.defaults(options, {
            maxZoom: FIT_ADS_MAX_ZOOM,
            // margin from marker size
            margin: {x: 29, y: 30},
        }));
    }

    _abortCameraAnimation() {
        if (this._asyncAbortCallback) {
            this._asyncAbortCallback();
            this._asyncAbortCallback = null;
        }
        if (this._map && this._map._renderer && this._map._renderer._cameraManager) {
            this._map._renderer._cameraManager.stopAnimation();
        }
    }

    _playAnimationTo(cameraOptions) {
        const map = this._map;
        if (!map || !map._renderer) {
            return;
        }
        const onCameraMoveEndedHandler = _.bind(this._onCameraMoveEnded, this, cameraOptions);
        map.once('idle', onCameraMoveEndedHandler);
        cameraOptions.onAbortCbk = _.bind(this._abortCameraAnimation, this);
        let latLng = new MapApi.api.LatLng(cameraOptions.lat, cameraOptions.lon);
        if (this.isWebglEnabled()) {
            const currentCenter = map.getCenter()._to900913();
            const zoom = map._renderer._cameraManager.getZoom(); // Read from camera
            const isMapType3d = MapHelper.isMapType3d(map.getMapTypeId());
            let options = {
                '3d': isMapType3d,
                zoom,
                lat: currentCenter.lat,
                lon: currentCenter.lon,
            };
            if (isMapType3d) {
                options.camera = {
                    phi: map.getHeading(),
                    theta: map.getTilt(),
                };
            }
            const currentPoint = map._renderer._cameraManager.getWantedTargetAndCameraPos(options);
            latLng = latLng._to900913();
            options = {
                '3d': isMapType3d,
                lat: latLng.lat,
                lon: latLng.lon,
                zoom: cameraOptions.zoom,
            };
            if (isMapType3d) {
                options.camera = {
                    phi: cameraOptions.phi,
                    theta: cameraOptions.theta,
                };
            }
            map._renderer._cameraManager.isTouching = false;
            const finalPoint = map._renderer._cameraManager.getWantedTargetAndCameraPos(options);
            const pointList = [];
            pointList.push({camPos: currentPoint.posCamera, camTarget: currentPoint.posTarget});

            const from = map.getCenter();
            const to = new MapApi.api.LatLng(cameraOptions.lat, cameraOptions.lon);
            const distance = MapApi.api.geometry.spherical.computeDistanceBetween(from, to);
            const duration = cameraOptions.durationInMS ? cameraOptions.durationInMS : 2000;
            if (distance > 2000.0 && currentPoint.posCamera.z < distance * 2 && finalPoint.posCamera.z < distance * 2) {
                const newPosDestCamera = finalPoint.posCamera.clone();
                const newPosSrcCamera = currentPoint.posCamera.clone();
                newPosDestCamera.z = 0;
                newPosSrcCamera.z = 0;
                const posIntermediateCamera = newPosDestCamera.addSelf(newPosSrcCamera).multiplyScalar(0.5);
                posIntermediateCamera.z = distance * 2;
                const newPosDestTarget = finalPoint.posTarget.clone();
                const newPosSrcTarget = currentPoint.posTarget.clone();
                const posIntermediateTarget = newPosDestTarget.addSelf(newPosSrcTarget).multiplyScalar(0.5);
                pointList.push({camPos: posIntermediateCamera, camTarget: posIntermediateTarget});
            }
            pointList.push({camPos: finalPoint.posCamera, camTarget: finalPoint.posTarget});
            cameraOptions = _.defaults(cameraOptions, {mustUpdate2d3dOnStop: true});

            const animOptions = {
                posAndTargetList: pointList,
                durationInMS: duration,
                easing: 'easeInOutCubic',
                enableWorkOnMove: false,
                onEndReachIdleEvent: map._renderer.makeIdleEvent(false),
                mustUpdate2d3dOnStop: cameraOptions.mustUpdate2d3dOnStop,
                onStopCbk: cameraOptions.onStopCbk ? cameraOptions.onStopCbk : null,
                onAbortCbk: cameraOptions.onAbortCbk ? cameraOptions.onAbortCbk : null,
                preloadTiles: true,
                dontPreLoadLayersOnAnimation: map.dontPreLoadLayersOnAnimation,
            };
            map._renderer._cameraManager.startAnimation(animOptions);
        } else {
            map.setCenter(latLng, cameraOptions.zoom);
        }
    }

    _cameraEquals(camera1, camera2) {
        const precision = 1000;
        return camera1.zoom === camera2.zoom
            && roundValue(camera1.lon, precision) === roundValue(camera2.lon, precision)
            && roundValue(camera1.lat, precision) === roundValue(camera2.lat, precision)
            && camera1['3d'] === camera2['3d']
            && camera1.phi === camera2.phi
            && camera1.theta === camera2.theta;
    }
};

function positionToLatLon(position) {
    function formatNumber(number) {
        const decimals = 7;
        return _numberFormat(number, decimals, '.', '');
    }

    const lon = position.lng || position.lon;
    const lat = position.lat;
    return {
        lat: +formatNumber(+lat),
        lon: +formatNumber(+lon),
    };
}

function roundValue(value, precision) {
    return Math.round(value * precision) / precision;
}
