const _ = require('lodash');

module.exports = {
    numberFilesValidator(value) {
        return filesValidator(value, _.isNumber);
    },
    stringFilesValidator(value) {
        return filesValidator(value, _.isString);
    },
};

function filesValidator(value, typeCheckFunction) {
    return null == value
        || typeCheckFunction(value)
        || _.isArray(value) && _.every(value, typeCheckFunction);
}
