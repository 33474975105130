const _ = require('lodash');
const View = require('../views/View');
const $ = require('jquery');

const template = require('../templates/sunAnimatorTemplate.jade');
const noUiSlider = require('../lib/noUiSlider/nouislider');
const SunAnimator = require('./SunAnimator');

module.exports = class SunAnimatorView extends View {
    constructor(options) {
        super(options);
        this.template = template;
        this._sunAnimator = new SunAnimator();
        this._sunAnimator.on('dateChange', ({dateAndDayTimePercent}) => {
            this._setSliderDayTimeAndPercent(dateAndDayTimePercent.dayTimePercent);
            this._setDatePickerDate(dateAndDayTimePercent.date);
        });
        this._on3dChangeHandler = _.bind(this._on3dChange, this);
        this._onDivRemovedHandler = _.bind(this.hide, this);
    }

    show(options) {
        this.setMap(options.map);
        this.$container = options.$container;
        super.show(options);
        this._createDatePicker();
        this._createSlider();
        this._createTimeButtons();
        this._toggleDisplayOnMapVisibility(this._isIn3d);
        this._on3dChange(this._isIn3d);
        $('.kimono-sunAnimatorActions', this.$element).on('click', (event) => {
            event.stopPropagation();
            event.preventDefault();
        });
    }

    hide() {
        super.hide();
        this.setMap(null);
        if (this.$element) {
            delete this.$element;
        }
    }

    _on3dChange(isIn3d) {
        this._isIn3d = isIn3d;
        this._toggleDisplayOnMapVisibility(isIn3d);
    }

    _toggleDisplayOnMapVisibility(isVisible) {
        if (this.$element) {
            this.$element.toggle(isVisible);
        }
    }

    setMap(map) {
        if (this._map != map) {
            this._sunAnimator.setMap(map);
            if (this._map) {
                this._map.removeListener('on3dchange', this._on3dChangeHandler);
                this._map.removeListener('divRemoved', this._onDivRemovedHandler);
            }
            this._map = map;
            if (this._map) {
                this._map.on('on3dchange', this._on3dChangeHandler);
                this._map.on('divRemoved', this._onDivRemovedHandler);
                this._isIn3d = map.isIn3d();
            }
        }
    }

    animateDateAndDayTimePercent(dateAndDayTimePercent) {
        this._sunAnimator.animateDateAndDayTimePercent(dateAndDayTimePercent);
    }

    getDateAndDayTimePercent() {
        return this._sunAnimator.getDateAndDayTimePercent();
    }

    getSunAnimator() {
        return this._sunAnimator;
    }

    _createDatePicker() {
        this._$dateSelector = this.$element.find('#kimono-dateSelector');
        let containerSelector = this.$container.attr('id');
        if (containerSelector) {
            containerSelector = '#' + containerSelector;
        }
        this._$dateSelector.datepicker({
            autoclose: true,
            format: 'd MM yyyy',
            language: 'fr',
            container: containerSelector,
        });
        this._setDatePickerDate(this._sunAnimator.getDateAndDayTimePercent().date);
        this._$dateSelector.on('change', () => {
            if (!this._sunAnimator.isPlaying()) {
                const newDate = this._getDatePickerDate();
                const dateAndDayTimePercent = this._sunAnimator.getDateAndDayTimePercent();
                dateAndDayTimePercent.date = newDate;
                this._sunAnimator.animateDateAndDayTimePercent(dateAndDayTimePercent);
            }
        });
        this.$element.on('hide.bs.dropdown', () => {
            if (this._$dateSelector) {
                this._$dateSelector.datepicker('hide');
            }
        });
    }

    _getDatePickerDate() {
        return this._$dateSelector.datepicker('getDate');
    }

    _setDatePickerDate(date) {
        if (this._$dateSelector) {
            const currentDate = this._getDatePickerDate();
            if (!currentDate || (currentDate.valueOf() !== date.valueOf())) {
                this._$dateSelector.datepicker('setDate', new Date(date));
            }
        }
    }

    _createTimeButtons() {
        $('.kimono-sunPositionBtn', this.$element).on('click', e => {
            const dayTimePercent = +$(e.currentTarget).attr('data-percentage-btw-start-and-end');
            const dateAndDayTimePercent = this._sunAnimator.getDateAndDayTimePercent();
            dateAndDayTimePercent.dayTimePercent = dayTimePercent;
            this.animateDateAndDayTimePercent(dateAndDayTimePercent);
        });
    }

    _createSlider() {
        this._startSliderSeconds = null;
        this._endSliderSeconds = null;
        this._sunPositionSlider = this.$element.find('#kimono-sunPositionSlider').get(0);
        const dateAndDayTimePercent = this._sunAnimator.getDateAndDayTimePercent();
        noUiSlider.create(this._sunPositionSlider, {
            start: [dateAndDayTimePercent.dayTimePercent],
            range: {
                min: [0],
                max: [100],
            },
        });
        this._sunPositionSlider.noUiSlider.on('update', (values, handle) => {
            this._updateSunAndSky(values);
            if (!this._sunAnimator.isPlaying() || !this.$element /*not displayed anymore*/) {
                const dayTimePercent = parseFloat(values[handle]);
                const dateAndDayTimePercent = this._sunAnimator.getDateAndDayTimePercent();
                dateAndDayTimePercent.dayTimePercent = dayTimePercent;
                this._sunAnimator.setDateAndDayTimePercent(dateAndDayTimePercent);
            }
        });
    }

    _updateSunAndSky(values) {
        if (this.$element) {
            const $sky = this.$element.find('#kimono-sky');
            const $sun = this.$element.find('#kimono-sunPosition');
            const path = this.$element.find('#kimono-theMotionPath').get(0);
            const pathLength = path.getTotalLength();
            const p = path.getPointAtLength((values) / 100 * pathLength);
            const transform = 'translate(' + p.x + 'px,' + p.y + 'px)';
            $sun.css({transform: transform});
            const bgpx = -(140 - Math.round(p.y)) + 'px';
            $sky.css({'background-position-x': bgpx});
        }
    }

    _setSliderDayTimeAndPercent(dayTimePercent) {
        if (this._sunPositionSlider) {
            const currentValue = this._sunPositionSlider.noUiSlider.get();
            if (currentValue != dayTimePercent) {
                this._sunPositionSlider.noUiSlider.set(dayTimePercent);
            }
        }
    }
};
