const _ = require('lodash');
const $ = require('jquery');
const async = require('async');
const InputWidgetFactory = require('../fields/InputWidgetFactory');
const View = require('../views/View');
const CompositeVueView = require('../vue/CompositeVueView');
const sectionTemplate = require('../templates/components/realEstateAdSection.jade');
const {i18n: {translate}} = require('fack');
const FormUtils = require('../fields/FormUtils');
const AdsSchema = require('../AdsSchema');
const AdsRequestManager = require('../AdsRequestManager');
const AuthenticationPopup = require('../authentication/AuthenticationPopup');
const Account = require('../authentication/Account');
const adsManager = require('../search/adsManager');
const Errors = require('../utils/Errors');
const AdTypeHelper = require('../../common/AdTypeHelper');
const Views = require('../views/Views');
const RealtimeServer = require('../RealtimeServer');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const RealEstateAdLoader = require('../RealEstateAdLoader');
const isNullOrEmpty = require('../../common/isNullOrEmpty');
const i18nMixin = require('../vue/components/mixins/i18n');
const karto = require('../loadKarto');

const INDEXATION_SUCCESS_VOLATILE_DURATION_IN_SECONDS = 5;
const WAIT_FOR_AD_INDEXATION_DELAY_IN_SECONDS = 3;

let headerFields = {
    main: {
        keepAlive: true,
        fields: [
            {
                name: 'adType',
                required: true,
                keepAlive: true,
            },
            {
                name: 'propertyType',
                required: true,
                keepAlive: true,
            },
        ],
    },
};

const contactField = {
    name: 'contact',
    schema: {
        name: 'contactFields',
        type: 'contactFields',
    },
};

const cguField = {
    name: 'cgu',
    required: true,
};

const publicFooterFields = {
    contact: {
        fields: [
            contactField,
            cguField,
        ],
    },
};

const proFooterFields = {
    contact: {
        subTitle: 'contact_subtitle',
        fields: [
            contactField,
            cguField,
        ],
    },
};

const addressFields = [
    'city',
    'postalCode',
    'street',
    'displayDistrictName',
];

module.exports = class RealEstateAdCreationModificationView extends CompositeVueView {
    /**
     *
     * @param {object} options
     * @param {string} options.currentRealEstateAdId given by crossroads from url
     * @param {node} options.template
     * @param {boolean} options.isCreation
     * @param {string} options.mode "public" or "pro"
     * @param {function} options.onAccountChange callback when catching event "change" from Account
     * @constructor
     */
    constructor(options) {
        super();
        _.extend(this, options);
        this.isInProMode = options.mode == 'pro';
        this.isBusy = false;
        this.fields = {};
        this.autoImported = false;
    }

    loadData(options, cb) {
        AdsSchema.init(cb);
    }

    show(options) {
        this.currentRealEstateAdId = options.currentRealEstateAdId;
        super.show(options, getVueOptions());
    }

    createElement() {
        if (this.isCreation) {
            this.$element = this.renderTemplate(this.template);
            this.initForm();
        } else {
            this.getRealEstateAdValues();
        }
        return this.$element;
    }

    initForm(fieldValues) {
        this.sections = {};
        this.fieldValues = fieldValues || {};
        this.$form = this.$element.find('form');
        this.autoImported = Boolean(fieldValues && fieldValues.publicationImportId);
        this.onlyBlurFields = (fieldValues
            && fieldValues.userRelativeData
            && fieldValues.userRelativeData.canModifyAdBlur
            && !fieldValues.userRelativeData.canModifyAd);
        this.$element.find('.warningBlockForImport').toggle(this.autoImported && !this.onlyBlurFields);
        this.$fieldsContainer = this.$form.find('.dynamicFields');
        this.footerFields = this.getFooterFields(_.get(fieldValues, 'userRelativeData.isOwner', this.isCreation));
        if (this.onlyBlurFields) {
            headerFields = {};
        }
        this.schema = JSON.parse(AdsSchema.schema); //parse: second part of the dirty way to deep copy
        this.adFields = AdsSchema.formConfiguration;
        this._filterAdTypeAndPropertyType();
        this._initFormUtils();
        this._updateFields();
        this.bindEvents();
        this.initModals();
    }

    getFooterFields(isOwner) {
        let footerFields;
        if (this.onlyBlurFields) {
            footerFields = {};
        } else if (this.isInProMode) {
            footerFields = proFooterFields;
        } else {
            footerFields = publicFooterFields;
        }
        updateContactSchema(footerFields, isOwner);
        return footerFields;
    }

    clearForm() {
        if (this.$form) {
            //no needs this.clearModals(); because this.$element will be removed from DOM
            this._clearFormUtils();
            //no need this.unbindEvents(); because this.$fieldsContainer will be removed from DOM
        }
        this.$fieldsContainer = null;
        this.$form = null;
        this.fieldValues = null;
        // remove map if exists
        const addressField = _.get(this.sections, 'address.fields.street');
        if (addressField && karto.modificationAdMap) {
            addressField.clearMapElements();
            kartoEngine.Engine.getInstance().removeMap(karto.modificationAdMap);
            karto.setModificationAdMap(null);
        }
        this.sections = null;
    }

    _filterAdTypeAndPropertyType() {
        const toFilter = ['adType', 'propertyType'];
        const validEntries = {
            adType: _.keys(this.adFields),
            propertyType: [],
        };
        _.each(this.adFields, function (adType) {
            validEntries.propertyType = _.union(validEntries.propertyType, _.keys(adType));
        });
        _.each(toFilter, fieldName => {
            const fieldSchema = this.getFieldInfoFromSchema(fieldName);
            //TODO: don't modify the adsSchema to avoid the dirty deep copy
            if (fieldSchema) {
                fieldSchema.values = _.filter(fieldSchema.values, function (enumKey) {
                    return _.includes(validEntries[fieldName], enumKey);
                });
            } else {
                console.warn('could not find ' + fieldName + ' in schema');
            }
        });
    }

    _initFormUtils() {
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this.submitForm, this),
        });
    }

    _clearFormUtils() {
        FormUtils.clear({
            $form: this.$form,
        });
    }

    hide() {
        if (this.isShown()) {
            this.$element.find(':focus').blur(); //remove bootstrap validator popover
        }
        this.clearForm();
        super.hide();
    }

    _openDetailedSheetPage(realEstateAdId) {
        this.emit('adModificationFinished', {
            realEstateAdId,
            pushToHistory: false,
        });
    }

    bindEvents() {
        this.$fieldsContainer.on('change', 'select[name=adType],select[name=propertyType]', () => {
            const hasAdTypeChanged = this.hasAdTypeChanged();
            const hasPropertyTypeChanged = this.hasPropertyTypeChanged();
            if (hasAdTypeChanged || hasPropertyTypeChanged) {
                this._updateFields();
            }
        });
        this.$fieldsContainer.on('change', [
            'select[name=adType]',
            'select[name=propertyType]',
            'input[name="isExclusiveSaleMandate"]',
            'input[name="newProperty"]',
        ].join(','), () => {
            this.updateAdInfoForBlur();
        });
        this.$fieldsContainer.on('click', '.collapse-closed', function (event) {
            const currentTarget = $(event.currentTarget);
            const currentState = currentTarget.attr('data-collapse');
            toggleCollapseState(currentTarget, currentState);
        });
        this.$element.find('.realEstateAdModificationCancel').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this._openDetailedSheetPage(this.currentRealEstateAdId);
        });
    }

    hasPropertyTypeChanged() {
        return this.hasAdTypeOrPropertyTypeChange('propertyType');
    }

    hasAdTypeChanged() {
        return this.hasAdTypeOrPropertyTypeChange('adType');
    }

    hasAdTypeOrPropertyTypeChange(type) {
        const newType = this.sections.main.fields[type].getFieldValue();
        const hasChanged = newType != this.fieldValues[type];
        this.fieldValues[type] = newType;
        return hasChanged;
    }

    initModals() {
        this.$savingModal = this.$element.find('.savingModal');
        this.$errorModal = this.$element.find('.errorModal');
        this.$successModal = this.$element.find('.successModal');
        this.$savingModal.modal({
            backdrop: 'static',
            keyboard: false,
            show: false,
        });
        this.$errorModal.modal({
            backdrop: 'static',
            show: false,
        });
        this.$errorModal.on('hide.bs.modal', () => {
            this.isBusy = false;
        });
        this.$successModal.modal({
            backdrop: 'static',
            keyboard: false,
            show: false,
        });
    }

    _removeDynamicFields() {
        _.each(this.sections, (section, sectionKey) => {
            removeDynamicFieldsFromSection(section);
            if (!section.keepAlive) {
                section.$element.remove();
                delete this.sections[sectionKey];
            }
        });
    }

    _storeCurrentValues() {
        this.processFieldsThroughSections(function (field, fieldKey) {
            if (!field.keepAlive) {
                this.fieldValues[fieldKey] = field.getFieldValue();
            }
        });
    }

    _restoreStoredValues() {
        this.processFieldsThroughSections((field, fieldKey) => {
            const value = this.fieldValues[fieldKey];
            if (fieldKey == 'street' && this.fieldValues.dataFromUser) {
                _.extend(value, _.pickBy({
                    street: this.fieldValues.dataFromUser.street,
                    postalCode: this.fieldValues.dataFromUser.postalCode,
                    city: this.fieldValues.dataFromUser.city,
                }));
            }
            if (value != null) {
                field.setFieldValue(value);
            }
        });
    }

    _updateFields() {
        if (!this.$form) {
            //module has been cleared before this method was called (from a defer)
            return;
        }
        this._clearFormUtils();
        if (!this.fieldValues.adType && !this.isCreation) {
            this.overrideAdType();
        }
        let fieldsInfo = this.getFieldsInfo();
        fieldsInfo = fieldsInfo ? mergeFields([headerFields, fieldsInfo, this.footerFields]) : headerFields;
        //sauvegarder les champs
        //supprimer les elements du dom, sauf les header
        //recreer les champs, en préremplissant avec les valeurs sauvegardées si besoin
        this._storeCurrentValues();
        this._removeDynamicFields();
        _.each(fieldsInfo, (sectionInfo, sectionName) => {
            const $section = this.processSection(sectionName, sectionInfo);
            _.each(sectionInfo.fields, fieldInfo => {
                const fieldName = getFieldName(fieldInfo);
                const info = this.getFieldInfo(fieldName, fieldInfo);
                if (this.shouldCreateField(fieldInfo, sectionName, fieldName, info)) {
                    const field = InputWidgetFactory.newInputWidget(
                        $section || this.$fieldsContainer,
                        this.getWidgetOptions(info, fieldInfo)
                    );
                    if (field) {
                        field.keepAlive = fieldInfo.keepAlive;
                        this.sections[sectionName].fields[fieldName] = field;
                        //TODO remove this if specified in matrix
                        if (sectionInfo.collapseType == 'closed') {
                            field.hide();
                        }
                    }
                }
            });
        });

        //track personal info to update on login/out
        this.emailToDisplay = this.getContactFieldValue('emailToDisplay');
        this.phoneToDisplay = this.getContactFieldValue('phoneToDisplay');
        this.contactNameToDisplay = this.getContactFieldValue('contactNameToDisplay');

        this._restoreStoredValues();
        this.updateAdInfoForBlur();
        this._initFormUtils();
        const $textarea = this.$element.find('textarea');
        if ($textarea.length) {
            $textarea.height($textarea.prop('scrollHeight'));
        }
    }

    overrideAdType() {
        const propertyType = this.fieldValues.propertyType;
        switch (propertyType) {
            case 'programme':
                this.fieldValues.adType = 'buy';
                break;
            case 'residence':
                this.fieldValues.adType = 'rent';
                break;
            default:
                console.warn('no ad type and property type is not programme or residence');
        }
    }

    getFieldsInfo() {
        if (this.onlyBlurFields) {
            return this.getReducedFormFieldsInfo();
        } else {
            const adType = this.fieldValues.adType;
            const propertyType = this.fieldValues.propertyType;
            return this.adFields[adType] && this.adFields[adType][propertyType];
        }
    }

    getReducedFormFieldsInfo() {
        //TODO only check against import data, not manual data
        const valuesInForm = this.fieldValues.street || {};
        const fieldsInfo = {
            address: {
                collapseType: 'always_opened',
                fields: [],
            },
        };
        const disabledInfo = {};
        const manualPlacementOverride = _.get(Account.getAuthenticatedAccount(), 'allowAdManualPlacement', false);
        let allowManualPlacement = manualPlacementOverride;
        _.each(addressFields, fieldName => {
            const fieldValue = valuesInForm[fieldName];
            const nullOrEmpty = isNullOrEmpty(fieldValue);
            disabledInfo[fieldName] = !manualPlacementOverride && !nullOrEmpty;
            if (nullOrEmpty) {
                allowManualPlacement = true;
            }
        });
        this._allowManualPlacement = allowManualPlacement;
        _.each(addressFields, fieldName => {
            fieldsInfo.address.fields.push({
                name: fieldName,
                disabled: disabledInfo, //disabled is only used for street, since city & postalCode are set to "ignore" type
                allowManualPlacement,
                useCustomBlurType: false,
            });
        });
        return fieldsInfo;
    }

    getWidgetOptions(info, fieldInfo) {
        return _.extend(info, {
            required: this.isFieldRequired(fieldInfo),
            translationContext: this.getTranslationContext(), //this is not a property of the field, it should not be in info
            disabled: fieldInfo.disabled,
            allowManualPlacement: fieldInfo.allowManualPlacement,
            useCustomBlurType: _.defaultTo(fieldInfo.useCustomBlurType, true),
        });
    }

    shouldCreateField(fieldInfo, sectionName, fieldName, info) {
        const isProField = fieldInfo.professionalOnly && !this.isInProMode && !Account.hasRole('adModifier');
        const isAlreadyDisplayed = Boolean(this.sections[sectionName].fields[fieldName]);
        return !isAlreadyDisplayed && !isProField && info;
    }

    getFieldInfo(fieldName, fieldInfo) {
        const info = fieldInfo.schema || this.getFieldInfoFromSchema(fieldName);
        if (!info) {
            console.warn('missing schema info for field ' + fieldName);
        }
        return info;
    }

    processSection(sectionName, sectionInfo) {
        let title = sectionName;
        //do not recreate section already displayed (for header sections)
        if (!this.sections[sectionName]) {
            title = translate('realEstateAdSection.' + title);
            let subTitle = sectionInfo.subTitle;
            if (subTitle) {
                subTitle = translate('realEstateAdSection.' + subTitle);
            }
            const $element = $(sectionTemplate({
                section: sectionName,
                collapse: sectionInfo.collapseType || '',
                title,
                subTitle,
            }));
            this.$fieldsContainer.append($element);
            this.sections[sectionName] = _.extend({
                $element: $element,
                fields: {},
            }, _.omit(sectionInfo, 'fields'));
            return $element;
        } else {
            return this.sections[sectionName].$element;
        }
    }

    isFieldRequired(fieldInfo) {
        if (Account.hasRole('adModifier') && !this.isCreation || !fieldInfo.required) {
            return false;
        } else {
            return fieldInfo.required;
        }
    }

    getTranslationContext() {
        const adType = this.fieldValues.adType;
        return {
            adType: adType ? AdTypeHelper.getTransactionTypeFromAdType(adType) : null,
            fai: this.isInProMode ? 'fai' : '',
        };
    }

    getFieldInfoFromSchema(keyName) {
        return _.find(this.schema, function (column) {
            return column.name === keyName;
        });
    }

    updateAdInfoForBlur() {
        if (this.sections && this.sections.address && this.sections.address.fields.street) {
            this.sections.address.fields.street.setAdInfoForBlur({
                accountIds: this.fieldValues.accountIds,
                importAccountId: this.fieldValues.importAccountId,
                adType: this.hasFieldValue('adType') ? this.getFieldValue('adType') : this.fieldValues.adType,
                propertyType: this.hasFieldValue('propertyType') ?
                    this.getFieldValue('propertyType') : this.fieldValues.propertyType,
                isExclusiveSaleMandate: this.hasFieldValue('isExclusiveSaleMandate') ?
                    this.getFieldValue('isExclusiveSaleMandate') : this.fieldValues.isExclusiveSaleMandate,
                newProperty: this.hasFieldValue('newProperty') ? this.getFieldValue('newProperty') : this.fieldValues.newProperty,
                postalCode: this.fieldValues.street && this.fieldValues.street.postalCode,
                precisionGeoloc: this.fieldValues.precisionGeoloc,
                flowBlurType: _.get(this.fieldValues, 'street.flowBlurType'),
                usePositionWithoutRandom: this.fieldValues.usePositionWithoutRandom,
            });
        }
    }

    submitForm() {
        if (this.isBusy) {
            return;
        }
        this.toggleModal('$savingModal', 'show');
        if (this.onlyBlurFields) {
            this.sendForm();
        } else {
            this.checkFieldsToSend();
        }
    }

    checkFieldsToSend() {
        const fieldsToSend = this.getFieldsToSend();
        if (fieldsToSend instanceof Error) {
            console.error('Error uploading files', fieldsToSend.message);
            this.toggleModal('$savingModal', 'hide');
            this.toggleModal('$successModal', 'show');
            return;
        }
        const fieldsToCheck = [];
        this.processFieldsThroughSections(function (field, fieldName) {
            if (_.includes(fieldsToSend, fieldName)) {
                fieldsToCheck.push(field);
            }
        });
        const that = this;
        async.each(fieldsToCheck, function (field, cb) {
            if (field.whenReady) {
                field.whenReady(cb);
            } else {
                _.defer(cb);
            }
        }, function (err) {
            if (err) {
                console.error('Error uploading files', err);
                that.toggleModal('$savingModal', 'hide');
                that.toggleModal('$errorModal', 'show');
            } else {
                that.checkAuthentication();
            }
        });
    }

    getFieldsToSend() {
        let adType;
        let propertyType;
        if (this.sections.main && this.sections.main.fields) {
            adType = this.sections.main.fields.adType && this.sections.main.fields.adType.getFieldValue();
            propertyType = this.sections.main.fields.propertyType && this.sections.main.fields.propertyType.getFieldValue();
        }
        const fieldsInfo = this.adFields[adType] && this.adFields[adType][propertyType];

        if (!fieldsInfo) {
            return new Error('invalid adType or propertyType');
        }

        let fieldsToSend = [];
        const checks = [headerFields, fieldsInfo, this.footerFields];
        _.each(checks, function (array) {
            _.each(array, function (section) {
                fieldsToSend = fieldsToSend.concat(_.map(section.fields, getFieldName));
            });
        });
        const idx = fieldsToSend.indexOf('cgu');
        if (idx >= 0) {
            fieldsToSend.splice(idx, 1);
        }
        return fieldsToSend;

    }

    checkAuthentication() {
        const that = this;
        const email = this.getContactFieldValue('emailToDisplay');
        if (!Account.isRegistered()) {
            this.toggleModal('$savingModal', 'hide');
            const authenticationPopup = new AuthenticationPopup();
            authenticationPopup.on('authenticated', sendFormAfterLogin);
            authenticationPopup.on('close', function () {
                authenticationPopup.removeListener('authenticated', sendFormAfterLogin);
                authenticationPopup.hide();
            });
            if (email) {
                Account.checkCredentialIdAvailable({id: email}, function (err, value) {
                    if (err) {
                        authenticationPopup.showCreateAccount(email);
                    } else if (value && value.valid) {
                        authenticationPopup.showCreateAccount(email);
                    } else {
                        authenticationPopup.showLogin(email);
                    }
                });
            } else {
                authenticationPopup.showCreateAccount();
            }
        } else {
            this.sendForm();
        }

        function sendFormAfterLogin() {
            that.toggleModal('$savingModal', 'show');
            that.sendForm();
        }
    }

    getSerializedForm(fieldsToSend) {
        const serializedForm = {};

        this.processFieldsThroughSections(function (field, fieldName) {
            if (_.includes(fieldsToSend, fieldName)) {
                field.serializeInto(serializedForm);
            }
        });

        if (serializedForm.propertyType == 'programme' || serializedForm.propertyType == 'residence') {
            delete serializedForm.adType;
        }

        //todo clean this
        const contact = {};
        if (serializedForm.phoneToDisplay) {
            contact.phone = serializedForm.phoneToDisplay;
            delete serializedForm.phoneToDisplay;
        }
        if (serializedForm.emailToDisplay) {
            contact.email = serializedForm.emailToDisplay;
            delete serializedForm.emailToDisplay;
        }
        if (serializedForm.contactNameToDisplay) {
            contact.name = serializedForm.contactNameToDisplay;
            delete serializedForm.contactNameToDisplay;
        }
        if (!_.isEmpty(contact)) {
            serializedForm.contacts = [contact];
        }

        //todo cleanup, only one id, and no accountId
        const {
            _id,
            accountId,
            techId,
        } = this.fieldValues;
        serializedForm.accountId = accountId;
        serializedForm.id = _id;
        serializedForm._id = _id;
        serializedForm.techId = techId;

        return serializedForm;
    }

    sendForm() {
        let serializedForm = {};
        if (this.onlyBlurFields) {
            const {fields} = this.sections.address;
            const addressInputWidgetValues = fields.street.getFieldValue();
            const data = {
                blurType: addressInputWidgetValues.blurType,
                blurSeed: addressInputWidgetValues.blurSeed,
            };

            //also send street, postalCode & city if missing from import or account has allowAdManualPlacement flag
            if (this._allowManualPlacement) {
                _.each(addressFields, fieldName => {
                    const previousValue = _.get(this.fieldValues, ['street', fieldName]);
                    const newValue = addressInputWidgetValues[fieldName];
                    if (!isEqualOrBothEmpty(newValue, previousValue)) {
                        data[fieldName] = newValue;
                    }
                });
                const newPosition = addressInputWidgetValues.position;
                const oldPosition = _.get(this.fieldValues, 'street.position');
                if (!isSamePosition(oldPosition, newPosition)) {
                    data.position = newPosition;
                    //force to manual position in order to avoid being overridden by flowPosition
                    data.position.isManual = true;
                    delete data.position._removeManualPosition;
                }
            }
            data.displayDistrictName = fields.displayDistrictName.getFieldValue();
            const realEstateAdId = this.currentRealEstateAdId;
            // eslint-disable-next-line handle-callback-err
            RealEstateAdLoader.loadRealEstateAd({realEstateAdId}, (err, ad) => {
                const adTitle = ad ? RealEstateAdTitleGenerator.getTitle(ad, 'full') : realEstateAdId;
                const onAdModifiedCallback = _.bind(AdsRequestManager.setBlur, AdsRequestManager, this.currentRealEstateAdId, data);
                this._modifyAd(onAdModifiedCallback, realEstateAdId, adTitle);
            });
        } else {
            const fieldsToSend = this.getFieldsToSend();
            if (fieldsToSend instanceof Error) {
                this.showError(fieldsToSend.message);
                return;
            }
            serializedForm = this.getSerializedForm(fieldsToSend);
            if (this.isCreation) {
                AdsRequestManager.sendForm(serializedForm, 'add', (err, add) => {
                    if (err) {
                        this.showError(err);
                    } else {
                        this.showSuccess(add.id);
                    }
                });
            } else {
                const adTitle = RealEstateAdTitleGenerator.getTitle(serializedForm, 'full');
                const onAdModifiedCallback = _.bind(AdsRequestManager.sendForm, AdsRequestManager, serializedForm, 'edit');
                this._modifyAd(onAdModifiedCallback, this.currentRealEstateAdId, adTitle);
            }
        }
    }

    _modifyAd(requestFunc, adId, adTitle) {
        let isIndexed = false;
        let isSaved = false;
        let idTimerWaitForAdIndexed = null;
        const realtimeContext = RealtimeServer.openContext();
        realtimeContext.on('ad:update', onAdIndexed);
        realtimeContext.join('ad#' + adId);
        requestFunc((err, ad) => {
            if (err) {
                this.showError(err);
            } else {
                isSaved = true;
                if (isIndexed) {
                    this._openAdPage(ad.id);
                } else {
                    idTimerWaitForAdIndexed = setTimeout(() => {
                        idTimerWaitForAdIndexed = null;
                        this.showSuccess(ad.id);
                    }, WAIT_FOR_AD_INDEXATION_DELAY_IN_SECONDS * 1000);
                }
            }
        });
        const openAdPageFunc = _.bind(this._openAdPage, this);
        const $successModal = this.$successModal;

        function onAdIndexed({source, id}) {
            if (source == 'clientEdition') {
                isIndexed = true;
                if (isSaved) {
                    if (idTimerWaitForAdIndexed != null) {
                        clearTimeout(idTimerWaitForAdIndexed);
                        idTimerWaitForAdIndexed = null;
                        openAdPageFunc(id);
                    } else {
                        const translationKey = 'realEstateAdModificationView.';
                        if (($successModal.data('bs.modal') || {}).isShown) {
                            const successIndexedText = translate(translationKey + 'successIndexed', {adTitle});
                            $successModal.find('.contentText').text(successIndexedText);
                            $successModal.find('.btn').text(translate(translationKey + 'indexationShowAdButton'));
                        }
                        Views.volatileFeedback.showSuccess(translationKey + 'indexationSuccess', {
                            translationKeyParams: {adTitle},
                            buttonTranslationKey: translationKey + 'indexationShowAdButton',
                            animDuration: INDEXATION_SUCCESS_VOLATILE_DURATION_IN_SECONDS,
                            buttonClickCallback: () => {
                                openAdPageFunc(id);
                            },
                        });
                    }
                }
                realtimeContext.off('ad:update', onAdIndexed);
                realtimeContext.leave('ad#' + id);
            }
        }
    }

    _openAdPage(adId) {
        this._openDetailedSheetPage(adId);
        this.toggleModal('$savingModal', 'hide');
        this.toggleModal('$successModal', 'hide');
    }

    showSuccess(adId) {
        this.$successModal.one('hidden.bs.modal', _.bind(this._openAdPage, this, adId));
        this.toggleModal('$savingModal', 'hide');
        this.toggleModal('$successModal', 'show');
    }

    showError(err) {
        this.toggleModal('$savingModal', 'hide');
        console.error('Error creating ad: ', err);
        const strErr = err.message || JSON.stringify(err, null, '    ');
        this.$errorModal.find('.errorMessage').text(strErr);
        this.toggleModal('$errorModal', 'show');
    }

    getFieldValue(fieldName) {
        const section = _.find(this.sections, function (section) {
            return section.fields[fieldName];
        });
        return section && section.fields[fieldName].getFieldValue();
    }

    hasFieldValue(fieldName) {
        const section = _.find(this.sections, function (section) {
            return section.fields[fieldName];
        });
        return Boolean(section);
    }

    getContactFieldValue(fieldName) {
        const contactWidget = this.sections && this.sections.contact
            && this.sections.contact.fields && this.sections.contact.fields.contact;
        return contactWidget && contactWidget.getFieldValue() && contactWidget.getFieldValue()[fieldName];
    }

    getRealEstateAdValues() {
        const realEstateAdId = this.currentRealEstateAdId;

        //TODO this is dirty, but I don't know how to do better with a "put-all-in-one-file" design
        const modificationTemplate = this.template;
        this.template = View.defaultTemplate;
        this.$element = this.renderTemplate(this.template);

        this.asyncHelper.doAsync({
            func: cb => adsManager.loadEditableRealEstateAdById({
                id: realEstateAdId,
                disableErrorPage: true,
            }, cb),
            callback: (err, realEstateAd) => {
                if (err) {
                    const {code, statusCode, status} = err;
                    const errorCode = code || statusCode;
                    if (status === 'abort') {
                        //do nothing
                    } else if (errorCode === 403) {
                        this.emit('realEstateAdForbiddenAcces');
                    } else if (errorCode === 404) {
                        this.emit('realEstateAdNotFound');
                    } else {
                        console.error('error loading realEstateAd', err);
                        Errors.showError(err);
                    }
                } else if (!realEstateAd) {
                    this.emit('realEstateAdNotFound');
                } else {
                    realEstateAd.displayDistrictName = _.defaultTo(realEstateAd.displayDistrictName, true);
                    this.renderTemplate(modificationTemplate).appendTo(this.$element);
                    this.injectVueViews(this.$element, getVueOptions());
                    this.initForm(formatRealEstateAd(realEstateAd));
                }
            },
            name: 'loadRealEstateAdToEdit',
        });
    }

    toggleModal(modalType, value) {
        this[modalType].modal(value);
        this.isBusy = value == 'show';
    }

    processFieldsThroughSections(func) {
        _.each(this.sections, section => {
            _.each(section.fields, _.bind(func, this));
        });
    }
};

function updateContactSchema(footerFields, isOwner) {
    const contactField = _.find(_.get(footerFields, 'contact.fields'), {name: 'contact'});
    _.set(contactField, 'schema.ignoreAccountValues', !isOwner);
}

function toggleCollapseState($target, currentState) {
    const show = currentState == 'closed';
    const newState = show ? 'opened' : 'closed';
    $target.attr('data-collapse', newState);
    $target.find('.collapseState').toggleClass('fa-caret-down', show)
        .toggleClass('fa-caret-right', !show);
    $target.siblings().toggle(show);
}

function mergeFields(fields) {
    const result = {};
    _.each(fields, function (fieldsToMerge) {
        _.each(fieldsToMerge, function (section, sectionName) {
            if (result[sectionName]) {
                //only merge fields, not other data, use base {} to avoid modifying raw section
                result[sectionName].fields = result[sectionName].fields.concat(section.fields);
            } else {
                //cloning here otherwise the line "result[sectionName].fields = " will override the raw section
                result[sectionName] = _.clone(section);
            }
        });
    });
    return result;
}

function getFieldName(fieldInfo) {
    return _.isObject(fieldInfo) ? fieldInfo.name : fieldInfo;
}

function isEqualOrBothEmpty(str1, str2) {
    return _.isEqualWith(str1, str2, (str1, str2) => {
        if (!str1 && !str2) {
            return true;
        }
    });
}

function isSamePosition(pos1, pos2) {
    const keysToCompare = ['lat', 'lon'];
    return _.isEqual(_.pick(pos1, keysToCompare), _.pick(pos2, keysToCompare));
}

function formatRealEstateAd(realEstateAd) {
    const formattedRealEstateAd = formatAddress(realEstateAd);
    formatContact(formattedRealEstateAd);
    formatPhotos(formattedRealEstateAd);
    formatRequiredBoolean(formattedRealEstateAd);
    return formattedRealEstateAd;
}

function formatAddress(realEstateAd) {
    const addressWidgetValues = ['city', 'street', 'postalCode', 'position', 'isManual', 'blurType', 'blurSeed', 'flowBlurType'];
    const ad = _.omit(realEstateAd, addressWidgetValues);
    //this is a hack to match fieldsMatrix, but it should really be an object named address, not street
    ad.street = {};
    _.each(addressWidgetValues, function (value) {
        if (realEstateAd[value]) {
            ad.street[value] = realEstateAd[value];
        }
    });
    const seedUsingAddress = _.compact([realEstateAd.street, realEstateAd.postalCode, realEstateAd.city]).join('-');
    ad.street.blurSeed = ad.street.blurSeed || seedUsingAddress || ad.id || (Date.now() + '');
    return ad;
}

function formatContact(realEstateAd) {
    //todo : better handling for multiples contacts in the same ad
    //todo : clean use of emailToDisplay / phoneToDisplay / contactNameToDisplay
    const contact = {};
    if (realEstateAd.contacts && realEstateAd.contacts[0]) {
        const firstContact = realEstateAd.contacts[0];
        contact.emailToDisplay = realEstateAd.emailToDisplay = firstContact && firstContact.email;
        contact.phoneToDisplay = realEstateAd.phoneToDisplay = firstContact && firstContact.phone;
        contact.contactNameToDisplay = realEstateAd.contactNameToDisplay = firstContact && firstContact.name;
    }
    realEstateAd.contact = contact;
    return realEstateAd;
}

function formatPhotos(realEstateAd) {
    realEstateAd.photos = _.map(realEstateAd.photos, 'photo');
    realEstateAd.panoramicPhoto = _.map(realEstateAd.panoramicPhoto, 'photo');
    return realEstateAd;
}

function formatRequiredBoolean(realEstateAd) {
    // this is a hack to add removed required booleans
    if (!realEstateAd.isChargesIncludedRent) {
        realEstateAd.isChargesIncludedRent = false;
    }
    if (!realEstateAd.isFurnished) {
        realEstateAd.isFurnished = false;
    }
    return realEstateAd;
}

function getVueOptions() {
    // @vue/component
    return {
        mixins: [
            i18nMixin({
                keys: {
                    'realEstateAdCreationView.title': 'adCreationHeaderTitle',
                    'realEstateAdModificationView.title': 'adModificationHeaderTitle',
                },
            }),
        ],
        bemName: 'ad-creation-or-modification',
    };
}

function removeDynamicFieldsFromSection(section) {
    _.each(section.fields, (field, fieldKey) => {
        if (field.fields) { // field can then be considered a section as well (for FieldsGroupWidget instances, for example)
            removeDynamicFieldsFromSection(field);
        } else if (!field.keepAlive) {
            field.clear();
            delete section.fields[fieldKey];
        }
    });
}
