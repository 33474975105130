const RealEstateSubmissionPage = require('./RealEstateSubmissionPage');

module.exports = class RealEstateRentSubmissionPage extends RealEstateSubmissionPage {
    constructor() {
        super({
            transactionType: 'rent',
        });
    }

    getUrlPattern() {
        return '/louer-mon-bien';
    }
};
