const _ = require('lodash');

const template = require('./pinelResults.jade');
const LoanResultsView = require('../LoanResultsView');
const Highcharts = require('../../utils/Highcharts');

const getNewPropertiesSearchUrlAroundPrice = require('../utils/getNewPropertiesSearchUrlAroundPrice');
const {CHART_OPTIONS, SERIES_OPTIONS} = require('../data/PIE_OPTIONS');

const DURATIONS = {
    6: {taxReductionPercent: 9},
    9: {taxReductionPercent: 12},
    12: {taxReductionPercent: 14},
};
const DEFAULT_SELECTED_DURATION = 9;

module.exports = class PinelResultsView extends LoanResultsView {
    constructor(configuration) {
        super(_.extend({
            name: 'PinelResultsView',
        }, configuration));
        this.template = template;
    }

    _getSearchAdsUrl({results}) {
        const searchAdsUrls = {};
        _.each(results, (result, years) => {
            searchAdsUrls[years] = getNewPropertiesSearchUrlAroundPrice(result.MontantLogement, {
                'eligible-loi-pinel': 'oui',
            });
        });
        return searchAdsUrls;
    }

    show(options) {
        super.show(_.extend({
            DURATIONS,
        }, options));
        this.results = options.results;
        this.$chartContainer = this.$element.find('.loanSimulatorCharts');
        this._setActiveTab(DEFAULT_SELECTED_DURATION);
        this._createPie();
    }

    _bindEvents() {
        super._bindEvents();
        this._eventPack.on(this.$element.find('li[data-years]'), 'click', e => {
            const years = e.currentTarget.getAttribute('data-years');
            this._setActiveTab(years);
            this._updateChart(years);
        });
    }

    _setActiveTab(years) {
        this._toggleActiveClass(this.$element.find('.active[data-years]'));
        this._toggleActiveClass(this.$element.find(`[data-years="${years}"]`));
        this.emit('tabChanged', _.extend({
            investmentYears: years,
            eligible: this.results.eligible,
        }, this.results[years]));
    }

    _toggleActiveClass($elements) {
        $elements.toggleClass('active');
    }

    _updateChart(years) {
        this.$chartContainer.highcharts().series[0].update(this._getSeriesData(years));
    }

    _createPie() {
        Highcharts.onReady(() => {
            if (this.$chartContainer.length) {
                this.$chartContainer.highcharts(_.extend({
                    series: [this._getSeriesData(DEFAULT_SELECTED_DURATION)],
                }, CHART_OPTIONS));
            }
        }, this);
    }

    _getSeriesData(years) {
        return _.extend({
            data: this._getPieData(years, this.results[years]),
        }, SERIES_OPTIONS);
    }

    _getPieData(years, {PieLoyer, PieGainFisc, participation} = {}) {
        if (!years) {
            return [];
        }
        return [
            ['Votre locataire', +PieLoyer],
            ['Votre réduction d\'impôts', +PieGainFisc],
            ['Votre épargne', Math.round(participation * 12 * +years)],
        ];
    }
};
