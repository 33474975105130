const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const BaseStat = require('./BaseStat');
const {getAvailableStats, getColor} = require('../StatsConfig');

const {
    BASE_STATS_MODES,
    DASHBOARD_PRO_MODE,
} = require('./constants');

// @vue/component
module.exports = {
    components: {
        BaseStat,
    },
    props: {
        mode: {
            type: String,
            default: null,
            validator: mode => _.includes(BASE_STATS_MODES, mode),
        },
        buildUrlForStat: {
            type: Function,
            default: _.noop,
        },
    },
    computed: {
        displayStats() {
            const {stats} = this;
            return stats && !_.isEmpty(stats);
        },
        modifiers() {
            const {mode} = this;
            return {
                [mode]: Boolean(mode && mode !== DASHBOARD_PRO_MODE),
            };
        },
    },
    methods: {
        getUrl(statsName) {
            return this.buildUrlForStat(statsName);
        },
        translateStat(key, config = {}) {
            return translate(`BaseStats.${key}`, config);
        },
        makeStats(stats) {
            return _(getAvailableStats())
                .map(statName => {
                    const value = _.get(stats, statName);
                    if (_.isUndefined(value)) {
                        return undefined;
                    }
                    return {
                        name: statName,
                        value,
                        label: this.translateStat(`${statName}.label`, {
                            count: value,
                        }),
                        description: this.translateStat(`${statName}.description`, {
                            context: this.isAggregatedForAccount ? 'account' : '',
                        }),
                        color: getColor(statName),
                    };
                })
                .compact()
                .value();
        },
    },
};
