const template = require('./AdPublicationCertificateLink.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AdPublicationCertificateLink.',
            keys: [
                'text',
            ],
        }),
    ],
    props: {
        realEstateAdId: {
            type: String,
            required: true,
        },
    },
    computed: {
        url() {
            return `/certificat-de-publication/${this.realEstateAdId}.pdf`
                + `?access_token=${encodeURIComponent(Account.getAccessToken())}`;
        },
    },
    template: template(),
};
