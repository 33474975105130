const $ = require('jquery');
const _ = require('lodash');

const ANIMATION_END_EVENTS = 'webkitAnimationEnd oanimationend msAnimationEnd animationend';

module.exports = {
    setAnimation,
};

let pendingAnimations = [];

function setAnimation(options) {
    bindAnimationEnd(options.$eventElementToAnimate, options);
    pendingAnimations.push(options);
}

function animationEnded(e) {
    const data = e.data;
    const target = e.target;
    if (data && data.$elementToAnimate.is($(target))) {
        const currentAnimations = _.filter(pendingAnimations, animation => {
            return data.eventCurrentAnim == animation.eventCurrentAnim && data.$elementToAnimate.is(animation.$elementToAnimate);
        });
        if (currentAnimations.length) {
            _.each(currentAnimations, currentAnimation => {
                unbindAnimationEnd(currentAnimation.$elementToAnimate);
                currentAnimation.$elementToAnimate.removeClass(currentAnimation.eventCurrentAnim);
            });
            pendingAnimations = _.difference(pendingAnimations, currentAnimations);
        }
        if (data.eventCallBack) {
            data.eventCallBack();
        }
    }
}

function bindAnimationEnd($e, options) {
    $e.on(ANIMATION_END_EVENTS, options, animationEnded);
}

function unbindAnimationEnd($e) {
    $e.off(ANIMATION_END_EVENTS, animationEnded);
}
