const template = require('./CustomerId.jade');
const accountFormTranslate = require('../../utils/accountForm/accountFormTranslate');
const ServerConfig = require('../../ServerConfig');

// @vue/component
module.exports = {
    props: {
        value: {
            type: String,
            default: undefined,
        },
        required: Boolean,
        accountId: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            customerId: this.value,
        };
    },
    computed: {
        valueToEmit() {
            return this.customerId || undefined;
        },
        remoteUrl() {
            return `${ServerConfig.config.accountUrl}/account/${this.accountId}/isCustomerIdAvailable`;
        },
    },
    watch: {
        value() {
            this.customerId = this.value;
        },
        valueToEmit(customerId) {
            this.$emit('input', customerId);
        },
    },
    methods: {
        accountFormTranslate,
    },
    template: template(),
};
