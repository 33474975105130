const _ = require('lodash');
const {formatRoundNumber} = require('./NumberFormatter');
let {i18n: {translate}} = require('fack');

const unitsForProperty = {
    price: '€',
    rooms: 'room',
    bedrooms: 'bedroom',
    area: 'squareMeter',
    gardenSurfaceArea: 'squareMeter',
};

module.exports = {
    init,
    formatRange,
    formatRangeForProperty,
};

function init({t}) {
    translate = t;
}

function formatRangeForProperty(property, properties) {
    return formatRange({
        min: getValueForLimit('min'),
        max: getValueForLimit('max'),
        unit: unitsForProperty[property] || property,
    });

    function getValueForLimit(limit) {
        // access dynamically to minBedrooms, minRooms, minPrice, minArea, minGardenSurfaceArea
        return properties[limit + _.capitalize(property)];
    }
}

function formatRange({min, max, unit, translationContext}) {
    let translationKey;
    const translationOptions = {
        min: formatRoundNumber(min, unit),
        max: formatRoundNumber(max, unit),
        context: translationContext,
    };
    if (min && max && min != max) {
        translationKey = 'range.minMax';
        translationOptions.min = formatRoundNumber(min); //no unit for min value
    } else if (min || max) {
        if (min == max) {
            translationKey = 'range.singleValue';
        } else if (max) {
            translationKey = 'range.max';
        } else { // min
            translationKey = 'range.min';
        }
    }
    return translationKey && translate(translationKey, translationOptions);
}
