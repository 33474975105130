const _ = require('lodash');
const template = require('./Button.jade');
const {handleLinkClick} = require('../../../app/LinkClickHandler');

const DEFAULT_SIZE = 'default';
const SMALL = 'sm';
const LARGE = 'lg';
const SIZES = [
    DEFAULT_SIZE,
    SMALL,
    LARGE,
    'bigBtn',
];
const DEFAULT_VARIANT = 'default';
const PRIMARY_VARIANT = 'primary';
const LINK_VARIANT = 'link';
const VARIANTS = [
    DEFAULT_VARIANT,
    PRIMARY_VARIANT,
    'inverse',
    'secondColor',
    'noBorder',
    'secondary',
    LINK_VARIANT,
    'localBannerCallToAction', // TODO: generalize this variant if needed
];

// @vue/component
module.exports = {
    props: {
        type: {
            type: String,
            default: 'button',
        },
        variant: {
            type: String,
            default: DEFAULT_VARIANT,
            validator: variant => _.includes(VARIANTS, variant),
            values: VARIANTS,
            PRIMARY_VARIANT,
            LINK_VARIANT,
        },
        size: {
            type: String,
            default: DEFAULT_SIZE,
            validator: size => _.includes(SIZES, size),
            values: SIZES,
        },
        icon: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: null,
        },
        fab: Boolean,
        href: {
            type: String,
            default: null,
        },
        block: Boolean,
        squared: Boolean,
        iconColor: {
            type: String,
            default: null,
            validator: color => color === 'primary',
        },
        target: {
            type: String,
            default: null,
        },
    },
    computed: {
        tag() {
            return this.href ? 'a' : 'button';
        },
        typeAttr() {
            return this.href ? null : this.type;
        },
        isIconOnly() {
            return this.icon && !this.$slots.default;
        },
        classes() {
            const {size} = this;
            const classes = _.map(_.compact([
                _.compact([_.defaultTo(this.variant, DEFAULT_VARIANT), this.theme]).join('-'),
                size === DEFAULT_SIZE ? null : size,
            ]), name => 'btn-' + name);
            if (this.isIconOnly && !this.squared) {
                classes.push('btn-icon');
            }
            if (this.fab) {
                classes.push('btn-elevated');
                if (!this.squared) {
                    classes.push('btn-rounded');
                }
            }
            if (this.block) {
                classes.push('btn-block');
            }
            return classes;
        },
        iconClass() {
            const classes = [];
            const {iconColor} = this;
            if (iconColor) {
                classes.push(`btn__icon--${iconColor}`);
            }
            return classes;
        },
    },
    methods: {
        onClick(event) {
            if (this.$listeners.click) {
                event.stopPropagation();
                event.preventDefault();
                this.$emit('click');
            } else if (this.href) {
                event.stopPropagation();
                handleLinkClick(event.currentTarget, event);
            }
        },
        getIconElement() {
            return this.$refs.icon.$el;
        },
    },
    template: template(),
};
