const _ = require('lodash');

const CompositeVueView = require('../vue/CompositeVueView');
const FormUtils = require('../fields/FormUtils');
const EventPack = require('../utils/EventPack');

module.exports = class ModalFormView extends CompositeVueView {
    constructor(options) {
        super(options);
        this._eventsWhileShown = new EventPack();
    }

    show(options) {
        super.show(options);
        this.$modal = this.$element.find('.modal');
        this.$form = this.$element.find('form');
        this.$submitButton = this.$form.find('button.btn-primary');
        this.initForm();
        this.$modal.modal('show');
        this._eventsWhileShown.on(this.$modal, 'hidden.bs.modal', _.bind(this.hide, this));
    }

    hideForm() {
        if (this.$form) {
            this.$modal.modal('hide');
            FormUtils.clear({
                $form: this.$form,
            });
            this.$form = null;
        }
    }

    hide() {
        this._eventsWhileShown.removeAllListeners();
        this.hideForm();
        super.hide(...arguments);
    }

    submitForm() {
        throw new Error('Must be implemented by child');
    }

    initForm() {
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this.submitForm, this),
        });
    }
};
