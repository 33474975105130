const _ = require('lodash');

const template = require('./LocalExpertBanner.jade');

const i18nMixin = require('../../vue/components/mixins/i18n');
const {
    LOCAL_EXPERT_DEFAULT_SIZE_TYPE,
    getLocalExpertBannerSizeTypes,
    LOCAL_EXPERT_SIZES,
    resolveStaticPhotoUrl,
    storeLocalBannerEvent,
} = require('../LocalBannersHelper');

const AVAILABLE_IMAGE_COUNT = 9;
const SIZES = getLocalExpertBannerSizeTypes();

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'LocalExpertBanner.',
            keys: ['moreInfo'],
        }),
    ],
    constants: {
        RATING_DESCRIPTION_STYLE: {
            fontSize: '10px',
            textTransform: 'none',
            lineHeight: 1.25,
        },
    },
    props: {
        agency: {
            type: Object,
            required: true,
        },
        sizeType: {
            type: String,
            default: LOCAL_EXPERT_DEFAULT_SIZE_TYPE,
            validator: size => _.includes(_.keys(SIZES), size),
        },
        enableAnalytics: Boolean,
        searchCriteria: {
            type: Object,
            default: () => ({}),
        },
        footerText: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        bannerStyles() {
            const {width, height} = this.dimensions;
            return {
                width: width + 'px',
                height: height + 'px',
            };
        },
        bannerSize() {
            const {width, height} = this.dimensions;
            return `${width}x${height}`;
        },
        sizeModifiers() {
            const {bannerSize} = this;
            return {
                small: bannerSize === LOCAL_EXPERT_SIZES.default,
                big: bannerSize === LOCAL_EXPERT_SIZES.big,
            };
        },
        dimensions() {
            const {w: width, h: height} = SIZES[this.sizeType];
            return {width, height};
        },
        backgroundImageUrl() {
            const randomImageName = _.random(AVAILABLE_IMAGE_COUNT - 1) + '.jpg';
            return resolveStaticPhotoUrl('images/localexpert/' + randomImageName);
        },
        imgStyles() {
            return {
                backgroundImage: `url(${this.backgroundImageUrl})`,
            };
        },
        showFooterText() {
            return Boolean(this.footerText);
        },
        footerTextStyle() {
            return {
                maxWidth: this.dimensions.width + 'px',
            };
        },
    },
    mounted() {
        this.printEvent();
    },
    methods: {
        printEvent() {
            this.storeLocalExpertEvent('print');
        },
        clickEvent() {
            this.storeLocalExpertEvent('click');
        },
        storeLocalExpertEvent(eventName) {
            const {enableAnalytics, agency, searchCriteria} = this;
            if (enableAnalytics) {
                const {id: agencyId} = agency;
                const event = {
                    agencyId: agency.id,
                    searchedPostalCodes: _.get(searchCriteria, 'postalCodes', []),
                    event: eventName,
                    matchingBannerConfig: {
                        theme: 'expert',
                    },
                };
                storeLocalBannerEvent(event, (err) => {
                    if (err) {
                        console.error(`Could not store ${eventName} event for agency ${agencyId}`, err);
                    }
                });
            }
        },
    },
    template: template(),
};
