const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./ActionsDropdown.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ActionsDropdown.',
            keys: {
                title: 'title',
                editFrequency: 'editFrequencyLabel',
                editCriteria: 'editCriteriaLabel',
                deleteSaveSearch: 'deleteSaveSearchLabel',
            },
        }),
    ],
    props: {
        canEditFrequency: Boolean,
    },
    template: template(),
};
