const AccountForm = require('./AccountForm');
const Account = require('./Account');
const FieldCheck = require('../fields/FieldCheck');
const _ = require('lodash');
const FormLoadingState = require('../FormLoadingState');
const {i18n: {translate}} = require('fack');
const {EventEmitter} = require('events');

/**
 *
 * @param {node} $element pre built element
 * @constructor
 */

module.exports = class CreateAccountForm extends EventEmitter {
    constructor($element) {
        super();
        this.$element = $element;
        this.$form = this.$element.find('form');
        const account = Account.getAuthenticatedAccount();
        this.accountForm = AccountForm({
            $form: this.$form,
            submit: _.bind(this.submitForm, this),
            account: (account && account.email) ? account : null,
        });
    }

    clear() {
        this.accountForm.clear();
    }

    submitForm() {
        this.$form.find('.errorZone').empty().hide();
        const $validateButton = this.$form.find('.tryCreateAccount');
        const loadingState = new FormLoadingState({
            $form: this.$form,
            $button: $validateButton,
        });
        loadingState.show();
        this.createAccount(function () {
            loadingState.hide();
        });
    }

    showError(translatedMessage) {
        this.$form.find('.errorZone').text(translatedMessage).show();
    }

    createAccount(cb) {
        const accountData = this.accountForm.getAccountData();
        Account.create(accountData, err => {
            if (err) {
                if (err.message == 'account unavailable') {
                    const $elem = this.$form.find('input[name=email]');
                    FieldCheck.toggleError($elem, false, 'emailUnavailable');
                    FieldCheck.scrollToElement($elem);
                } else if (err.message == 'timeout' || err.message == 'abort') {
                    //abort occurs on timeout in CORS mode.
                    this.showError(translate('loginError.timeout'));
                } else {
                    console.warn('unhandled error type', err);
                    this.showError(translate('loginError.server'));
                }
            } else {
                this.emit('accountCreated');
            }
            cb();
        });
    }
};
