const Account = require('../authentication/Account');

module.exports = function (data, callback) {
    Account.authAjax({
        url: '/sendFormContactMail',
        type: 'POST',
        data,
        disableErrorPage: true,
        callback,
    });
};
