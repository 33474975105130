const {authAjax} = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');

module.exports = {
    getLastInquiryEstimationsByTimeRange,
};

function getLastInquiryEstimationsByTimeRange(timeRangeInDays, callback) {
    return authAjax({
        url: `${ServerConfig.config.estimationUrl}/prospecting-map-estimations?timeRangeInDays=${timeRangeInDays}`,
        disableErrorPage: true,
        callback,
    });
}
