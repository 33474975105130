const _ = require('lodash');
const Entities = require('html-entities').AllHtmlEntities;

const ProgrammeHelper = require('./ProgrammeHelper');
const PriceFormatter = require('./PriceFormatter');
const TextFiltersFormatter = require('./TextFiltersFormatter');
const {cityNameToInTheCity} = require('@bienici/city-name-formatter');
const BaseTitleGenerator = require('./BaseTitleGenerator');
const {singleBuilt} = require('./PropertyTypes');
const {isMobile} = require('browser-detect');

let {i18n: {translate}} = require('fack');

module.exports = {
    init,
    getTitle,
};

function init({t}) {
    translate = t;
}

function getRoomsQuantityText(realEstateAd, translationContext) {
    const {roomsQuantity, isStudio} = realEstateAd;
    return isStudio ? null : TextFiltersFormatter.formatQuantity(roomsQuantity, 'room', {translationContext});
}

function getSurfaceAreaText({surfaceArea, landSurfaceArea, propertyType}) {
    return TextFiltersFormatter.formatSurfaceArea(surfaceArea, landSurfaceArea, propertyType);
}

function getRoomsQtyAndSurfaceText(roomsQtyText, surfaceText) {
    if (roomsQtyText || surfaceText) {
        return 'de ' + _.compact([roomsQtyText, surfaceText]).join(' et ');
    }
}

function getAdContext(realEstateAd) {
    if (ProgrammeHelper.isProgrammeOrResidence(realEstateAd)) {
        return 'programmeOrResidence';
    }
    if (realEstateAd.isPropertyWithoutUsufruct) {
        return 'propertyWithoutUsufruct';
    }
    return realEstateAd.adType;
}

function getProgrammeSolePropertyTypeText(realEstateAd) {
    if (ProgrammeHelper.isProgrammeWithSolePropertyType(realEstateAd)) {
        const firstPropertyType = realEstateAd.relatedAdsIds[0].propertyType;
        return setTextForProgrammesWithSolePropertyType(realEstateAd, firstPropertyType);
    }
}

function getLotsQuantityTexts(realEstateAd) {
    if (ProgrammeHelper.isProgramme(realEstateAd) && realEstateAd.condominiumPartsQuantity) {
        const lotsQuantityText = translate('titleParts.lotsQuantityText', {
            count: realEstateAd.condominiumPartsQuantity,
            context: realEstateAd.newProperty ? 'newProperty' : '',
        });
        return [lotsQuantityText, `de ${lotsQuantityText}`];
    }
    return [];
}

function getTranslationKeys(realEstateAd, titleKey, dataModeListEnabled) {
    const translationKeys = ['adTitle.' + titleKey];

    if (
        ProgrammeHelper.isProgrammeOrResidence(realEstateAd) &&
        titleContainsPropertyType(realEstateAd.title, realEstateAd.propertyType)
    ) {
        translationKeys.unshift(`adTitle.${titleKey}_titleContainsPropertyType`);
    }
    if (realEstateAd.isBuildingPlot && (dataModeListEnabled || isMobile())) {
        translationKeys.unshift(`adTitle.${titleKey}_buildingPlot`);
    }

    return translationKeys;
}

function getTitle(realEstateAd, titleKey, dataModeListEnabled) {
    const roomsQtyText = getRoomsQuantityText(realEstateAd, titleKey);
    const surfaceText = getSurfaceAreaText(realEstateAd);
    const singleSurfaceText = hasSingleSurface(realEstateAd) ? surfaceText : null;

    const roomsQtyAndSurfaceText = getRoomsQtyAndSurfaceText(roomsQtyText, surfaceText);

    //TODO get city from import (not from ad : it can be : "Marseille (chateau Gombert)")
    const cityOrPostalCode = realEstateAd.city || realEstateAd.postalCode;
    const inCityText = cityNameToInTheCity(realEstateAd.city);
    const hasDistrictInfo = _.get(realEstateAd, 'district.type_id') === 1;

    const context = getAdContext(realEstateAd);
    const programmeSolePropertyTypeText = getProgrammeSolePropertyTypeText(realEstateAd);

    const [lotsQuantityText, ofLotsQuantityText] = getLotsQuantityTexts(realEstateAd);

    const translationKeys = getTranslationKeys(realEstateAd, titleKey, dataModeListEnabled);
    const title = realEstateAd.title && Entities.decode(realEstateAd.title);

    let programmeReplacementAdOverviewTitle;
    if (ProgrammeHelper.isProgrammeWithoutTitle(realEstateAd)) {
        programmeReplacementAdOverviewTitle = 'Programme immobilier neuf';
    }

    return BaseTitleGenerator.translateAndCleanup(translationKeys, _.extend(
        _.pick(realEstateAd, [
            'newProperty',
            'transactionType',
            'adType',
            'isFurnished',
            'address',
            'propertyType',
            'isStudio',
            'isDuplex',
            'isTriplex',
            'relatedAdsIds',
        ]),
        {
            title,
            programmeReplacementAdOverviewTitle,
            roomsQtyText,
            surfaceText,
            singleSurfaceText,
            roomsQtyAndSurfaceText,
            lotsQuantityText,
            ofLotsQuantityText,
            cityOrPostalCode,
            inCityText,
            district: _.get(realEstateAd, 'district.libelle'),
            hasDistrictInfo,
            priceText: PriceFormatter.formatForHtml(realEstateAd.price),
            context,
            programmeSolePropertyTypeText,
        }
    ));
}

function hasSingleSurface({surfaceArea, landSurfaceArea, propertyType}) {
    return !TextFiltersFormatter.isSurfaceRange(surfaceArea, landSurfaceArea, propertyType);
}

function titleContainsPropertyType(title, propertyType) {
    const propertyTypeText = _.deburr(translate(`enum.propertyType.${propertyType}`));
    const valuesToTest = _([title, propertyTypeText])
        .map(_.deburr)
        .map(_.toLower)
        .value();
    return _.includes(...valuesToTest);
}

function hasOnlyOneRelatedLot({relatedAdsIds}) {
    const {isLotType, availableLotQuantity} = relatedAdsIds[0];
    return relatedAdsIds.length <= 1
        && (!isLotType || availableLotQuantity === 1);
}

function setTextForProgrammesWithSolePropertyType(realEstateAd, firstPropertyType) {
    if (shouldDisplayRelatedAdTitle(firstPropertyType)) {
        return translate('titleParts.propertyTypeWithAdjectives', {
            propertyType: firstPropertyType,
            count: hasOnlyOneRelatedLot(realEstateAd) ? 1 : 2,
            newProperty: true,
        });
    }
}

function shouldDisplayRelatedAdTitle(adPropertyType) {
    // "unknown" est actuellement inclus dans toutes les catégories de biens (PropertyTypes.js)
    return _.includes(singleBuilt, adPropertyType) && adPropertyType !== 'unknown';
}
