const _ = require('lodash');
const Urls = require('../Urls');

const ContactPage = require('./ContactPage');

module.exports = class ContactUsPage extends ContactPage {
    constructor(configuration) {
        configuration = _.defaults(configuration || {}, {
            name: 'contactUsForm',
            title: 'Nous contacter',
            bodyClass: 'nous-contacter',
            formType: 'contactUs',
            gtmCategory: 'Forms',
        });
        super(configuration);
        this.configuration = configuration;
    }

    open(options) {
        super.open(_.assignIn(options, this.configuration));
    }

    getUrlPattern() {
        return '/nous-contacter';
    }

    parseUrl(url) {
        this.formData = Urls.formContactUs.parseUrl(url);
        return this.formData;
    }

    getUrl() {
        return '/nous-contacter/';
    }
};
