const {i18n: {translate}} = require('fack');
const _ = require('lodash');
const Account = require('../../authentication/Account');
const template = require('./SortSelector.jade');

// @vue/component
module.exports = {
    props: {
        sortBy: {
            type: String,
            required: true,
        },
        sortOrder: {
            type: String,
            required: true,
        },
    },
    computed: {
        sortSelected() {
            return this.sortBy + '-' + this.sortOrder;
        },
        selectOptions() {
            const isAdmin = Account.isAdmin();
            const account = Account.getAuthenticatedAccount();
            const canSeeSortsByStats = (account && account.company != null) || isAdmin;
            let sortsByStats = [];
            if (canSeeSortsByStats) {
                sortsByStats = [
                    'views-desc',
                    'followers-desc',
                    'contactRequests-desc',
                    'phoneDisplays-desc',
                ];
            }
            const sorts = _.compact([
                'relevance-desc',
                isAdmin && 'relevanceDev-desc',
                'publicationDate-desc',
                'price-asc',
                'price-desc',
                'pricePerSquareMeter-asc',
                'pricePerSquareMeter-desc',
                'surfaceArea-asc',
                'surfaceArea-desc',
                ...sortsByStats,
            ]);
            return sorts.map(value => ({label: translate(`sortingView.${value}`), value}));
        },
    },
    methods: {
        emitNewSort(newSortSelected) {
            if (this.sortSelected !== newSortSelected) {
                const [sortBy, sortOrder] = newSortSelected.split('-');
                this.$emit('sort-changed', sortBy, sortOrder);
            }
        },
    },
    template: template(),
};
