const _ = require('lodash');
const i18nMixin = require('../../vue/components/mixins/i18n');
const VueView = require('../../vue/VueView');
const template = require('./articlesHubMenu.jade');
const Account = require('../../authentication/Account');
const ScrollHelper = require('../../utils/ScrollHelper');
const BlogAdminMenu = require('../components/hub/BlogAdminMenu');
const BlogCategoryHeader = require('../components/hub/BlogCategoryHeader');
const BlogCategorySelector = require('../components/hub/BlogCategorySelector');
const BreadcrumbHelper = require('../../utils/BreadcrumbHelper');

module.exports = class ArticlesHubMenuView extends VueView {
    constructor(options) {
        super({
            template,
        });
        this.options = options;
        if (options) {
            this.menu = options.blog.tags || null;
            this.category = options.category || null;
        }
        this.pageTitle = options.blog.name;
    }

    show() {
        const isBlogger = Account.isBlogger();
        const {pageTitle, menu, category} = this;
        const view = this;
        const vueData = {
            displayCategoryHeaderContent: true,
            selectedCategory: category,
            display: true,
            selectedArticle: null,
        };
        this.vueData = vueData;
        // @vue/component
        const vueOptions = {
            components: {
                BlogAdminMenu,
                BlogCategoryHeader,
                BlogCategorySelector,
            },
            mixins: [
                i18nMixin({
                    prefix: 'ArticlesHubMenuView.',
                    keys: [
                        'breadcrumbHomePage',
                    ],
                }),
            ],
            data() {
                return vueData;
            },
            computed: {
                displayAdminMenu() {
                    return isBlogger;
                },
                pageTitle() {
                    return pageTitle;
                },
                classesForPositioning() {
                    return {
                        'noMenu': !(menu && menu.length > 1),
                        'giveRoomForBloggerMenu': isBlogger,
                    };
                },
                categories() {
                    return menu;
                },
                breadcrumbData() {
                    return this.selectedCategory || this.selectedArticle ?
                        BreadcrumbHelper.getBreadcrumbPartsFromItems(this.getBreadcrumbItems()) : [];
                },
                isBreadcrumbVisible() {
                    return this.breadcrumbData.length > 1;
                },
            },
            methods: {
                onCategorySelected(event, category) {
                    view._articlesHubMenuHandler(event, category);
                },
                getBreadcrumbItems() {
                    let items = [];
                    if (this.selectedCategory) {
                        items = _.map(this.selectedCategory.path, (path, index) => {
                            return {
                                name: index > 0 ? path.name : this.breadcrumbHomePage,
                                slug: path.slug,
                            };
                        });
                        if (this.selectedArticle) {
                            items.push({
                                name: this.selectedArticle.title,
                                slug: null,
                            });
                        }
                    }
                    return items;
                },
            },
        };
        super.show(null, vueOptions);
        this._goToPageTop();
    }

    toggle(visibility) {
        this.vueData.display = visibility;
    }

    hide() {
        this.category = null;
        super.hide();
    }

    _articlesHubMenuHandler(event, category) {
        this._goToPageTop();
        this.selectCategory(category);
        this.emit('newSearch', {
            blog: this.options.blog,
            category,
        });
    }

    getCurrentCategory() {
        return this.category;
    }

    removeSubMenu() {
        this.vueData.displayCategoryHeaderContent = false;
    }

    selectCategory(category) {
        this.category = category;
        _.extend(this.vueData, {
            selectedCategory: category,
            displayCategoryHeaderContent: true,
        });
    }

    selectArticle(article) {
        this.vueData.selectedArticle = article;
    }

    _goToPageTop() {
        ScrollHelper.scrollToTop();
    }
};
