const moment = require('../../common/momentFr');

module.exports = class SunAnimation {

    constructor() {
        this._requestId = null;
    }

    isPlaying() {
        return this._requestId != null;
    }

    play(options) {

        this.stop();

        const updateDateCallback = options.updateCallback;
        const startDateAndDayTimePercent = options.startDateAndDayTimePercent;
        const endDateAndDayTimePercent = options.endDateAndDayTimePercent;
        const animDuration = options.animDuration;
        const animationStartDate = new Date();
        const that = this;

        request();

        function request() {
            that._requestId = requestAnimationFrame(() => {
                let currentFadeDuration = new Date() - animationStartDate;
                if (currentFadeDuration >= animDuration) {
                    currentFadeDuration = animDuration;
                    that.stop();
                } else {
                    request();
                }
                const date = (endDateAndDayTimePercent.date.valueOf() - startDateAndDayTimePercent.date.valueOf())
                    * (currentFadeDuration / animDuration) + startDateAndDayTimePercent.date.valueOf();
                const dayTimePercent = (endDateAndDayTimePercent.dayTimePercent - startDateAndDayTimePercent.dayTimePercent)
                    * (currentFadeDuration / animDuration) + startDateAndDayTimePercent.dayTimePercent;
                const dateAndDayTimePercent = {
                    date: new Date(moment(date).startOf('day')),
                    dayTimePercent,
                };
                updateDateCallback(dateAndDayTimePercent);
            });
        }
    }

    stop() {
        if (this._requestId) {
            window.cancelAnimationFrame(this._requestId);
            this._requestId = null;
        }
    }
};
