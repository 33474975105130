const $ = require('jquery');
const _ = require('lodash');
const {EventEmitter} = require('events');
const AsyncHelper = require('../utils/AsyncHelper');
const AnimationViewHelper = require('./utils/AnimationViewHelper');
const renderTemplate = require('./utils/renderTemplate');

const ANIMATIONS = [
    'anim-open',
    'anim-close',
    'anim-open-right',
    'anim-open-left',
    'anim-close-right',
    'anim-close-left',
];

module.exports = class View extends EventEmitter {
    constructor(options = {}) {
        super();
        this.$element = null;
        this.$container = options.$container || $('.mainPageContainer');
        this.template = options.template || this.template || View.defaultTemplate;
        this.asyncHelper = new AsyncHelper();
        this.currentAnimation = null;
    }

    show(options) {
        if (this.$element) {
            this.$element.remove();
        }
        const $element = this.$element = this.createElement(options);
        this.$container.append($element);
    }

    createElement(options) {
        return this.renderTemplate(this.template, options);
    }

    renderTemplate(template, templateOptions) {
        return renderTemplate(template, templateOptions);
    }

    hide(options, cb = _.noop) {
        this.emit('hide');
        this.asyncHelper.cancelAll();
        if (this.$element) {
            this.$element.remove();
            this.$element = null;
        }
        cb();
    }

    isShown() {
        return Boolean(this.$element);
    }

    // Make a request and manage result callback when redo request and manage request triggering after view has
    // been cleared to prevent race conditions
    doAsync(options) {
        this.asyncHelper.doAsync(options);
    }

    static defaultTemplate() {
        return '<div/>';
    }

    shouldPlayOpenAnimation() {
        return false;
    }

    shouldPlayCloseAnimation() {
        return false;
    }

    playOpenAnimation(cb = _.noop, children = null) {
        if (this.shouldPlayOpenAnimation()) {
            this.playAnim(this.configuration.animations.openAnimation, cb, children);
        } else {
            cb();
        }
    }

    playCloseAnimation(cb = _.noop, children = null) {
        if (this.shouldPlayCloseAnimation()) {
            this.playAnim(this.configuration.animations.closeAnimation, cb, children);
        } else {
            cb();
        }
    }

    playAnim(animWanted, callback = _.noop, childName = null) {
        const $elementToAnimate = childName ? this.$element.find(childName) : this.$element;
        _.each(_.without(ANIMATIONS, animWanted), anim => {
            $elementToAnimate.removeClass(anim);
        });
        $elementToAnimate.addClass(animWanted);
        if ($elementToAnimate.css('animationDuration') != '0s') {
            AnimationViewHelper.setAnimation({
                $elementToAnimate: $elementToAnimate,
                eventCurrentAnim: animWanted,
                eventCallBack: callback,
                $eventElementToAnimate: $elementToAnimate,
            });
        } else {
            $elementToAnimate.removeClass(animWanted);
            callback();
        }
    }

    setCloseAnimation(animation) {
        this._setAnimation('closeAnimation', animation);
    }

    _setAnimation(type, animation) {
        if (this.configuration.animations) {
            this.configuration.animations[type] = animation;
        }
    }

    setContainer($container) {
        this.$container = $container;
    }
};
