const browserDetect = require('browser-detect');
const _ = require('lodash');
const Cookies = require('../Cookies.js');

const UNKNOWN_QUALITY = 0;
const LOW_QUALITY = 1;
const MEDIUM_QUALITY = 2;
const HIGH_QUALITY = 3;

// https://developer.apple.com/library/ios/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/OpenGLESPlatforms/OpenGLESPlatforms.html
const knownGraphicsQualities = {
    ios: {
        gpuVersions: [
            {gpuRegex: /Apple A([89]|\d{2})X? GPU/, quality: HIGH_QUALITY},       // iPad Air 2, iPhone 6, 6+, A8 to A99
            {gpuRegex: /Apple A7 GPU/, quality: MEDIUM_QUALITY},     // iPad Air, iPad Mini Retina, iPad Mini 3, iPhone 5s
            {gpuRegex: /IMGSGX554/, quality: MEDIUM_QUALITY},        // iPad 4
            {gpuRegex: /IMGSGX543/, quality: LOW_QUALITY},           // iPad 2, iPad 3, iPad Mini, iPhone 4s, iPhone 5, iPhone 5c
            {gpuRegex: /IMGSGX535/, quality: LOW_QUALITY},           // iPad, iPhone 3GS, iPhone 4
        ],
    },
    android: {
        uaDevices: [
            {userAgentRegex: /LG-D855/, quality: LOW_QUALITY},          // LG D855 (LG G3 Europe)
            {userAgentRegex: /Nexus 4/, quality: MEDIUM_QUALITY},       // Google Nexus 4
            {userAgentRegex: /SM-G900/, quality: MEDIUM_QUALITY},       // Samsung Galaxy S5
            {userAgentRegex: /GT-I950[56]/, quality: MEDIUM_QUALITY},      // Samsung Galaxy S4
            {userAgentRegex: /GT-I9300/, quality: LOW_QUALITY},         // Samsung Galaxy S3
            // To be completed...
        ],
    },
};

let gpuVersion = null;

module.exports = {
    UnknownQuality: UNKNOWN_QUALITY,
    LowQuality: LOW_QUALITY,
    MediumQuality: MEDIUM_QUALITY,
    HighQuality: HIGH_QUALITY,
    detectGraphicsQuality,
    getIOSQuality,
};

function getIOSQuality() {
    const gpuVersion = getGpuVersion();
    // Determine gpu from version string
    const gpuInfo = _.find(knownGraphicsQualities.ios.gpuVersions, function (gpuInfo) {
        return gpuInfo.gpuRegex.test(gpuVersion);
    });

    // Return the quality if it exists
    if (gpuInfo) {
        return gpuInfo.quality;
    }
    return HIGH_QUALITY; // Use maximum quality on unknown iOS gpu version, see bug #10875
}

function getGpuVersion() {
    if (gpuVersion == null) {
        const parameters = {
            antialias: false,
            depth: false,
        };
        const canvasDetect = document.createElement('canvas');
        let gl = canvasDetect.getContext('webgl', parameters);
        if (!gl) {
            gl = canvasDetect.getContext('experimental-webgl', parameters);
        }
        if (gl) {
            gpuVersion = gl.getParameter(gl.VERSION);
        } else {
            gpuVersion = 'unknown';
        }
    }
    return gpuVersion;
}

function detectGraphicsQuality() {
    // See if we have results for the webgl benchmarking
    const webglBenchmarkScore = Cookies.getWebGLBenchmarkScore();
    if (webglBenchmarkScore != null) {
        // Convert score into graphics quality
        if (webglBenchmarkScore <= 3.0) {
            return LOW_QUALITY;

        } else if (webglBenchmarkScore > 3.0 && webglBenchmarkScore <= 8.0) {
            return MEDIUM_QUALITY;

        } else {
            return HIGH_QUALITY;
        }

    } else {

        if (browserDetect.isIOS()) {
            return getIOSQuality();
        } else if (browserDetect.isAndroid()) {
            // Determine device from user agent
            const ua = navigator.userAgent;
            const deviceInfo = _.find(knownGraphicsQualities.android.uaDevices, function (deviceInfo) {
                return deviceInfo.userAgentRegex.test(ua);
            });

            // Return the quality if it exists
            if (deviceInfo) {
                return deviceInfo.quality;
            }
        }

        // Unknown device (will revert to default quality)
        return UNKNOWN_QUALITY;
    }
}
