const {resourceUrl} = require('fack');

const template = require('./LinkWithIconBlock.jade');

// @vue/component
module.exports = {
    props: {
        href: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            required: true,
        },
        rel: {
            type: String,
            default: null,
        },
    },
    computed: {
        iconUrl() {
            return resourceUrl('images/illustrations/' + this.icon + '.svg');
        },
        tag() {
            return this.href ? 'a' : 'button';
        },
    },
    methods: {
        onClick(event) {
            this.$emit('click', event);
        },
    },
    template: template(),
};
