const _ = require('lodash');

const ServerConfig = require('../../ServerConfig');
const {getReferrer} = require('../../authentication/Account');
const savedOptions = require('../../Options').read();
const querystring = require('querystring');
const BrowserDetect = require('browser-detect');
const UserAgentHelper = require('../../../common/nativeApp/UserAgentHelper');
const {get: getStore} = require('../../store');

const DEFAULT_ADVERTISEMENT_TYPE = 'block';
const ADVERTISEMENT_SIZE_PER_TYPE = {
    banner: {w: 728, h: 90},
    [DEFAULT_ADVERTISEMENT_TYPE]: {w: 300, h: 250},
    halfPage: {w: 300, h: 600},
    fullWidth: {w: 1920, h: 90},
};

const SEARCH_CRITERIA_FOR_ADVERTISEMENT = [
    'filterType',
    'propertyType',
    'minPrice',
    'maxPrice',
    'newProperty',
];

module.exports = {
    DEFAULT_ADVERTISEMENT_TYPE,
    getAdsData,
};

function getAdsData({
    page,
    safeFrame = null,
    type = DEFAULT_ADVERTISEMENT_TYPE,
    searchCriteria = {},
    tags,
    contract,
}) {
    const postalCodes = getPostalCodes(searchCriteria);
    const sectionCriteria = _.extend({
        page,
        postalCodes,
        departments: getDepartmentFromPostalCodes(postalCodes),
        agencyId: searchCriteria.author,
        tags,
        referrer: getReferrer(),
        contract,
        domain: savedOptions.isInEmbeddedMode ? document.referrer : document.location.host,
    }, _.pick(searchCriteria, SEARCH_CRITERIA_FOR_ADVERTISEMENT));

    const {w: width, h: height} = ADVERTISEMENT_SIZE_PER_TYPE[type];

    const urlParameters = {
        section: buildUrlSection(sectionCriteria),
        allowPersonalizedAds: getStore().getters['cmp/allowPersonalizedAds'], // 'getStore' still required because we use it at different commercial Ad
        safeFrame,
    };
    if (!_.isEmpty(postalCodes)) {
        urlParameters.postalcodes = postalCodes.join(',');
    }

    return {
        width,
        height,
        toAdvertisementUrl: _.partial(toAdvertisementUrl, width, height, urlParameters), // still requires spot and index parameters
    };
}

function toAdvertisementUrl(width, height, urlParameters, spot, index = 0) {
    const device = (UserAgentHelper.isFromNativeApp() ? 'app-' : (BrowserDetect.isMobile() ? 'mobile-' : ''));
    const advertisementId = `${spot}-${device}w${width}h${height}-${index + 1}`;
    return `${ServerConfig.config.advertisementUrl}/iframe/${advertisementId}?${querystring.stringify(urlParameters)}`;
}

function getPostalCodes(search) {
    return _(search.locations)
        .map(location => {
            return _.invoke(location, 'getPostalCodes') || location.postalCodes;
        })
        .flattenDeep()
        .compact()
        .value();
}

function getDepartmentFromPostalCodes(postalCodes) {
    return _(postalCodes)
        .map(getCorseDepartment)
        .map(postalCode => /^9[78]/.test(postalCode) ? postalCode.substr(0, 3) : postalCode.substr(0, 2))
        .uniq()
        .value();
}

function getCorseDepartment(postalCode) {
    if (!postalCode.startsWith('20')) {
        return postalCode;
    }
    return postalCode.startsWith('202') ? '2B' : '2A';
}

function buildUrlSection(data) {
    return _(data)
        .pickBy(_.isArray)
        .flatMap((values, key) => _.map(values, val => [key, val]))
        .union(_(data)
            .omitBy(value => _.isArray(value) || value === '' || _.isNil(value))
            .toPairs()
            .value()
        )
        .invokeMap('join', '_')
        .join(',');
}
