const _ = require('lodash');
const Views = require('../views/Views');
const SearchPageBase = require('./SearchPageBase');

module.exports = class SearchPage extends SearchPageBase {
    constructor(configuration = {}) {
        const finalConfiguration = _.defaults({}, configuration, {
            name: 'search',
            searchFiltersFromHeaderEnabled: true,
        });

        super(finalConfiguration);
    }

    open(options, cb) {
        super.open(options, cb);
        const headerView = Views.header;
        if (_.isEmpty(options.search.filterType) && headerView && headerView.hasAdvancedSearchView()) {
            headerView.openAdvancedSearchView();
        }
    }
};
