const template = require('./PlaceDiscoveryPageBreadcrumb.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

const DISCOVER_DISTRICT_PAGE_URL = '/decouvrez-votre-futur-quartier';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PlaceDiscoveryPageBreadcrumb.',
            keys: [
                'discoverDistrictTitle',
                'backToAd',
            ],
        }),
    ],
    props: {
        items: {
            type: Array,
            required: true,
        },
        previousUrl: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        discoverDistrictBreadcrumbItem() {
            return {
                text: this.discoverDistrictTitle,
                url: DISCOVER_DISTRICT_PAGE_URL,
            };
        },
        breadcrumbItems() {
            return [
                this.discoverDistrictBreadcrumbItem,
                ...this.items,
            ];
        },
    },
    template: template(),
};
