const CheckboxesField = require('./CheckboxesField');
const NumberFormatter = require('../../common/NumberFormatter');
const SearchFiltersConfiguration = require('../search/SearchFiltersConfiguration');
const Account = require('../authentication/Account');
const intersects = require('../../common/intersects');
const _ = require('lodash');

module.exports = class NamedCheckboxField extends CheckboxesField {
    constructor(...args) {
        super(...args);
        this.name = this.$element.find('input').attr('name');
    }

    updateCount(count, adsTotalLimit) {
        const $target = this.$element.find('.counter');
        if (count == null) {
            $target.text('');
            this.$element.removeClass('noMatch');
        } else if (count > adsTotalLimit) {
            $target.text(' (' + NumberFormatter.formatNumber(adsTotalLimit) + '+)');
            this.$element.removeClass('noMatch');
        } else {
            $target.text(' (' + NumberFormatter.formatNumber(count) + ')');
            const checked = this.$element.find('input').is(':checked');
            this.$element.toggleClass('noMatch', count <= 0 && !checked);
        }
    }

    updateTitle() {
        _.each(this.$element, function (element) {
            element.setAttribute('title', element.innerText);
        });
    }

    updateVisibility({propertyType, filterType}) {
        const name = this.name.replace(/\[|\]/g, ''); // to accept multiple checkboxes with the same name
        const conf = SearchFiltersConfiguration.advancedFiltersConfiguration[name] || {};
        const isPropertyTypeMatching = !conf.propertyType || intersects(conf.propertyType, propertyType);
        const isFilterTypeMatching = !conf.filterType || intersects(conf.filterType, filterType);
        const displayed = (isPropertyTypeMatching && isFilterTypeMatching)
            || Account.isAdmin();
        this.$element.toggleClass('hidden', !displayed);
    }
};
