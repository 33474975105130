const _ = require('lodash');

const LoanSimulatorPage = require('../LoanSimulatorPage');
const Pages = require('../../pages/Pages');
const SimpleContentView = require('../../views/SimpleContentView');
const SimulatorFormView = require('./PtzSimulatorFormView');
const ContactFormView = require('./PtzContactFormView');

module.exports = class PtzSimulatorPage extends LoanSimulatorPage {
    constructor(configuration) {
        configuration = _.extend({
            name: 'PtzSimulatorPage',
            gtmCategory: 'ToolsAndSimulators',
            gtmPrefix: 'ptz',
            requestUrl: '/getPTZSimulation',
            requestName: 'getPTZSimulation',
            defaultName: 'PtzSimulatorPage',
            title: 'Calculez votre prêt à taux zéro',
            defaultContentPath: 'ptz-simulator',
        }, configuration);
        super(configuration);
    }

    _createViews() {
        this.contentView = new SimpleContentView();
        this.simulatorFormView = new SimulatorFormView();
        this.contactFormView = new ContactFormView();
    }

    getUrlPattern() {
        return '/calcul-pret-a-taux-zero';
    }

    _getSimulatorPage() {
        return Pages.ptzSimulatorPage;
    }

    _getResultsPage() {
        return Pages.ptzResultsPage;
    }

};
