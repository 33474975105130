const url = require('url');
const resourceUrl = require('fack/browser/resourceUrl');

module.exports = {
    setApiUrl,
    resolve,
};

let baseUrl;

function setApiUrl(apiUrl) {
    baseUrl = url.resolve(apiUrl, '..');
}

function resolve(file) {
    if (baseUrl) {
        return url.resolve(baseUrl, resourceUrl(file));
    } else {
        return resourceUrl(file);
    }
}
