const ServerConfig = require('../ServerConfig');
const Urls = require('../Urls');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const TextFormatter = require('../../common/TextFormatter');

module.exports = function getRealEstateSharedData(realEstateAd) {
    return {
        url: ServerConfig.config.wwwUrl + Urls.detailedSheet.makeUrl(realEstateAd),
        title: RealEstateAdTitleGenerator.getTitle(realEstateAd, 'full'),
        price: TextFormatter.formatPrice(realEstateAd.price),
        description: TextFormatter.generateDescription(realEstateAd, {decorate: false}),
        adType: realEstateAd.adType,
        id: realEstateAd.id,
    };
};
