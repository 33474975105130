const _ = require('lodash');
const PageFactory = require('../../pages/PageFactory');
const Urls = require('../../Urls');
const SearchUrlPrefixes = require('../../../common/SearchUrlPrefixes');

module.exports = class SearchPageBaseFactory extends PageFactory {
    constructor(pageConfiguration) {
        super();
        const urlPrefixPattern = this._urlPrefixPattern = pageConfiguration.urlPrefixPattern
            || pageConfiguration.urlPrefix
            || SearchUrlPrefixes.search;

        this._pageConfiguration = _.defaults({}, pageConfiguration, {urlPrefixPattern});
    }

    getUrlPattern() {
        return Urls.search.getPattern(this._urlPrefixPattern);
    }
};
