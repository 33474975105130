const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const ArticleHelper = require('../../../blog/article/ArticleHelper');
const async = require('async');
const template = require('./ServicesPresentation.jade');

const ARTICLES_SLUGS = [
    'votre-devis-de-demenagement-personnalise',
    'besoin-d-aide-pour-votre-projet-deco',
];

// @vue/component
module.exports = {
    data() {
        return {
            articles: null,
        };
    },
    created() {
        this.articles = _.map(ARTICLES_SLUGS, slug => ({
            isLoading: true,
            articlePath: articlePathFromSlug(slug),
        }));
        getArticles((ignoredError, articles) => {
            // it is better to have no article displayed than an error
            this.articles = articles;
        });
    },
    methods: {
        t: translate,
        imageClass(article) {
            return {
                'loading-placeholder-block': article.isLoading,
            };
        },
        imageStyle(article) {
            return {
                'background-image': article.photoUrl ? 'url(' + article.photoUrl + ')' : null,
            };
        },
        articleFormattedTitle(article) {
            return ArticleHelper.getHTMLFormattedTitle(article);
        },
    },
    template: template(),
};

function getArticles(callback) {
    async.map(ARTICLES_SLUGS, (slug, cb) => {
        ArticleHelper.getArticleFromSlug(slug, (err, article) => {
            if (err || !article) {
                // avoid an error feedback if getting article failed
                cb(null);
            } else {
                _.extend(article, {
                    articlePath: articlePathFromSlug(article.slug),
                    photoUrl: ArticleHelper.getFullSizePhotoUrl(article),
                });
                cb(null, article);
            }
        });
    }, (error, articles) => {
        articles = _.compact(articles);
        callback(error, articles);
    });
}

function articlePathFromSlug(slug) {
    return `/article/${slug}`;
}
