const _ = require('lodash');

const LoanSimulatorBasePage = require('./LoanSimulatorBasePage');
const LoanSimulatorContactHelper = require('./LoanSimulatorContactHelper');
const PageManager = require('../pages/PageManager');

module.exports = class LoanSimulatorPage extends LoanSimulatorBasePage {
    constructor(configuration) {
        configuration = _.extend({
            defaultName: 'loanSimulatorPage',
            title: 'Simulation financière',
        }, configuration);
        super(configuration);
    }

    _createViews() {
    }

    _loadData(options, cb) {
        this._updatePageName(options || {});
        this.contentPath = this.configuration.defaultContentPath;
        if (_hasToOpenContactView(options)) {
            this.contentPath += '-contact';
        }
        super._loadData(options, cb);
    }

    _updatePageName({simulatorData}) {
        this.name = `${this.configuration.defaultName}-${simulatorData ? 'ContactForm' : 'SimulatorForm'}`;
    }

    _getBuiltInView(options) {
        return _hasToOpenContactView(options) ? this.contactFormView : this.simulatorFormView;
    }

    _getBuiltInContainer() {
        return this.contentView.$element.find('.loanSimulatorFormContent');
    }

    _bindEvents() {
        this._eventPack.on(this.currentBuiltInView, 'submit', _.bind(this._onSimulatorSubmit, this));
    }

    _onSimulatorSubmit({simulatorData, contactData}) {
        if (this._canSkipContactForm() || contactData) {
            // eslint-disable-next-line handle-callback-err
            this._requestSimulation({simulatorData, contactData}, (err, results) => {
                PageManager.openPage(this._getResultsPage(), {results, simulatorData, contactData});
            });
        } else {
            this.sendGtmEvent(`${this.configuration.gtmPrefix}-simulatorFulfilled`, simulatorData);
            this._openSimulatorPage(_.extend({}, this.options, {simulatorData}));
        }
    }

    _canSkipContactForm() {
        return this.contactFormView.canSkipContactForm() && LoanSimulatorContactHelper.areContactDataSetAndOptInsChecked();
    }
};

function _hasToOpenContactView(options) {
    return Boolean(options.simulatorData);
}
