const _ = require('lodash');
const template = require('../templates/slideshow.jade');

const SlideshowBaseView = require('./SlideshowBaseView');

module.exports = class SlideshowView extends SlideshowBaseView {
    constructor(options) {
        options = _.extend({}, options, {
            imageResConfigName: 'slideshow',
            template,
        });
        super(options);
    }

    _bindEvents() {
        if (this.options.fullscreenAllowed && this.options.fullscreenAllowedOnImagesClick && this._hasPhotos()) {
            this._eventPack.on(this.$container, {
                click: {
                    '.slide:not(.showAdContentFromSlideshow)': _.bind(this._clickSlideFullscreen, this),
                    '.enterFullscreen': _.bind(this._enterFullscreen, this),
                },
            });
        }
        super._bindEvents();
    }

    _enterFullscreen() {
        this.emit('enterFullscreen');
    }

    _clickSlideFullscreen(event) {
        if (!event.originalEvent || !event.originalEvent._LastDragSucceded) {
            this._enterFullscreen(event);
        }
    }
};
