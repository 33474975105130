const ServerConfig = require('../ServerConfig');
const Cookies = require('../Cookies');
const RealtimeServer = require('../RealtimeServer');
const LoadScriptHelper = require('../utils/LoadScriptHelper');

let performanceCalc;
let testCompleted;
let webglTestCount = 0;

module.exports = {
    run,
};

function getBench(cb) {
    if (!performanceCalc) {
        const {webGlBenchmarkUrl} = ServerConfig.config;
        LoadScriptHelper.loadScript(webGlBenchmarkUrl, {
            crossoriginAllowed: true,
            errorCallback: function () {
                const message = `Failed to load WebGLBench at ${webGlBenchmarkUrl}`;
                cb(new Error(message));
            },
            successCallback: function () {
                const {WebGLBench} = window;
                delete window.WebGLBench; // avoid cluttering global object
                performanceCalc = new WebGLBench();
                cb(null, performanceCalc);
            },
        });
    } else {
        cb(null, performanceCalc);
    }
}

function run() {
    // Run the test
    if (testCompleted !== true) {
        const testParams = {
            testList: [
                'vertex_buffer_upload',
                'texture_upload',
                'render_high_poly',
                'fragment_shader',
                'pixel_read',
            ],
        };
        getBench((err, performanceCalc) => {
            if (err) {
                console.error(err);
            } else {
                performanceCalc.calculate(testParams, onWebGLBenchTestFinished);
            }
        });
    }
}

function onWebGLBenchTestFinished(testError, testData) {
    webglTestCount++;
    if (!testError) {
        // Set the score if test successful
        let webglBenchmarkScore = Cookies.getWebGLBenchmarkScore();
        if (webglBenchmarkScore == null) {
            webglBenchmarkScore = 0.0;
        }
        const newWebglBenchmarkScore = testData.benchmarkScore;
        Cookies.setWebGLBenchmarkScore(newWebglBenchmarkScore);

        // Determine if we need to run again
        if (webglTestCount < 5 && Math.abs(newWebglBenchmarkScore - webglBenchmarkScore) > 0.5) {
            run();
        } else {
            testCompleted = true;
            RealtimeServer.getSocket().emit('data', {name: 'webglbench:score', value: testData.benchmarkScore});
            RealtimeServer.getSocket().emit('webglbench:testdata', testData);
            // free memory
            performanceCalc = null;
        }
    } else {
        testCompleted = true;
    }
}
