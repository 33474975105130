const _ = require('lodash');

const View = require('./View');
const SelectPicker = require('../utils/SelectPicker');
const EventPack = require('../utils/EventPack');

module.exports = class BaseAccountStatsSelectorView extends View {
    constructor(options) {
        super(options);
        this._eventPack = new EventPack();
    }

    show(options) {
        super.show(options);
        SelectPicker.enable(this.$element);
        this._eventPack.on(this.$element, 'change', _.bind(this._emitChanges, this));
    }

    _emitChanges() {
        // implement this
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
    }

    getValue() {
        return this.$element.selectpicker('val');
    }
};
