const _ = require('lodash');

module.exports = {
    clampedInverseLinearInterpolation,
    linearInterpolation,
};

function linearInterpolation(a, b, t) {
    return a * (1 - t) + b * t;
}

/**
 * Expresses the position of the argument 'a' between two given bounds 'x' and 'y', on a linear function.
 * @param {number} x first given bound
 * @param {number} y second given bound
 * @param {number} a argument between the two given bounds
 * @returns {number} Result is between 0 and 1
 */
function clampedInverseLinearInterpolation(x, y, a) {
    return _.clamp((a - x) / (y - x), 0, 1);
}
