const _ = require('lodash');
const {
    i18n: {translate},
} = require('fack');

module.exports = function ({keys, prefix = ''} = {}) {
    let mapping;
    if (_.isArray(keys)) {
        const [keysToMap, mappedKeys] = _.partition(keys, _.isString);
        mapping = _.extend(
            ...mappedKeys,
            keysToMapping(keysToMap)
        );
    } else {
        mapping = keys;
    }

    // @vue/component
    return {
        computed: _(mapping)
            .map((key, translationKey) => [key, function () {
                return this.t(translationKey);
            }])
            .fromPairs()
            .value(),
        methods: {
            translate,
            t(key, options) { // overridable component translate function
                return translate(prefix + key, options);
            },
        },
    };
};

function keysToMapping(keys) {
    return _(keys)
        .map(key => [key, key])
        .fromPairs()
        .value();
}
