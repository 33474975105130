const _ = require('lodash');
const {EventEmitter} = require('events');

const emitter = new EventEmitter();

module.exports = {
    bind,
    on,
};

function bind() {
    window.addEventListener('message', function (e) {
        //const origin = e.originalEvent.origin; //todo: handle only events from same domain
        try {
            const data = e.data;
            const message = _.isString(data) ? JSON.parse(data) : data;
            if (message.command) {
                emitter.emit(message.command, message);
            }
        } catch (e) {
            // this can happen for messages for which we are not the target (like twitter or facebook messages)
            void(e);
        }
    }, false);
}

function on(command, handler) {
    emitter.on(command, handler);
}
