const {i18n: {translate: t}} = require('fack');
const _ = require('lodash');
const urlUtils = require('url');

const getRealEstateSharedData = require('./ad/getRealEstateSharedData');

// last xtor data is empty as it describes the recipient ; it is sent nevertheless for tracking compatibility
const REAL_ESTATE_AD_SHARE_TRACKING_PARAMS = {
    at_canal: 'CRM',
    at_medium: 'combtoc',
    at_campaign: 'share_realestatead',
    at_link: 'Voir_Annonce',
    at_type_emailing: 'email_auto',
};
const AD_DESCRIPTION_MAX_LENGTH = 300;

module.exports = {
    getShareContent,
};

function getShareContent(realEstateAd) {
    const {title, description, url, adType, id} = getRealEstateSharedData(realEstateAd);
    const adTrackingParams = _.pickBy({
        at_metier_src: adType,
        at_adid: id,
        ...REAL_ESTATE_AD_SHARE_TRACKING_PARAMS,
    });
    const urlWithTracking = getUrlWithTracking(url, adTrackingParams);
    const hasDescription = (_.isString(description) && !_.isEmpty(_.trim(description))) ? 'exist' : null;
    const truncatedDescription = _.truncate(description, {
        length: AD_DESCRIPTION_MAX_LENGTH,
        omission: ' [...]',
        separator: ' ',
    });
    return {
        title: t('AdShareButton.title'),
        url: urlWithTracking,
        sharedText: t('AdShareButton.text', {
            adTitle: title,
            adDescription: truncatedDescription,
            hasDescription: hasDescription,
            url: urlWithTracking,
        }),
    };
}

function getUrlWithTracking(url, adTrackingParams) {
    const parsedUrl = urlUtils.parse(url, true);
    delete parsedUrl.search; //force format to use parsedUrl.query
    _.extend(parsedUrl.query, adTrackingParams);
    return urlUtils.format(parsedUrl);
}
