const i18nMixin = require('../../vue/components/mixins/i18n');
const querystring = require('querystring');
const isValidPosition = require('../../utils/isValidPosition');
const ServerConfig = require('../../ServerConfig');
const template = require('./ChargingStationsTestPageLink.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'chargingStations.',
            keys: [
                'goToTestInterface',
                'noPosition',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            chargingStationsUrl: ServerConfig.config.chargingStationsUrl,
        };
    },
    computed: {
        hasPosition() {
            const {position} = this.realEstateAd;
            return isValidPosition(position);
        },
        url() {
            const {position, propertyType} = this.realEstateAd;
            return this.chargingStationsUrl + '/test/map#' + querystring.stringify({
                propertyType,
                latitude: position.lat,
                longitude: position.lon,
            });
        },
    },
    template: template(),
};
