const $ = require('jquery');
const _ = require('lodash');

const ServerConfig = require('../ServerConfig');
const Views = require('../views/Views');
const BrowserDetect = require('browser-detect');

module.exports = {
    init,
    isSubscribed,
    subscribeSw,
    resubscribeSw,
    unsubscribeSw,
    isPushNotificationSupported,
    isPushNotificationDenied,
};

let _isPushNotificationSupported = false;
let _isSubscribed = false;

const serviceWorkerRegistration = $.Deferred();

function init() {
    if (isSwAllowed()) {
        navigator.serviceWorker.register('/ServiceWorker.js').then(r => {
            checkRegistration(r, err => {
                if (err) {
                    errorFeedback(err, 'Failed Service worker registration check', null);
                    serviceWorkerRegistration.reject(err);
                } else {
                    r.update();
                    detectSupport(r);
                    serviceWorkerRegistration.resolve(r);
                }
            });
        }).catch(err => {
            errorFeedback(err, 'Could not register serviceWorker', null);
            serviceWorkerRegistration.reject(err);
        });
    } else {
        console.log('Service worker api not available');
        serviceWorkerRegistration.reject(new Error('Service worker api not available'));
    }
}

function errorFeedback(err, log, userTranslationKeys) {
    //TODO send to sentry
    console.error(log, err);
    if (err.code) {
        console.log('Error code: ' + err.code);
    }
    if (err.name) {
        console.log('Error name: ' + err.name);
    }

    if (userTranslationKeys) {
        const context = BrowserDetect.isMobile() || BrowserDetect.isTablet() ? 'mobileOrTablet' : 'desktop';
        Views.volatileFeedback.showError(userTranslationKeys, {translationKeyParams: {context}});
    }
}

function checkRegistration(r, cb) {
    if (typeof(r.update) !== 'function') {
        cb(new Error('Service Worker registration misses update function'));
    } else if (!r.pushManager) {
        cb(new Error('Service Worker registration misses pushManager'));
    } else {
        cb();
    }
}

function isNotificationSupported() {
    return 'Notification' in window;
}

function resubscribeSw(options, cb) {
    if (isNotificationSupported() && Notification.permission === 'granted') {
        subscribeSw(options, cb);
    } else {
        cb();
    }
}

function subscribeSw({silent = false}, cb) {
    if (isSwAllowed()) {
        serviceWorkerRegistration.then(r => {
            r.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: getAppServerKey(),
            }).then(pushSubscription => {
                _isSubscribed = true;
                cb(null, pushSubscription);
            }).catch(err => {
                const userTranslationKeys = silent ? null : _.compact([
                    err.name ? `pushNotification.${err.name}` : null,
                    'pushNotification.subscriptionError',
                ]);
                errorFeedback(err, 'Could not subscribe to notifications', userTranslationKeys);
                cb();
            });
        }, err => {
            console.log('Service worker not available to subscribe to notifications', err);
            cb();
        });
    } else {
        cb();
    }
}

function detectSupport(r) {
    r.pushManager.subscribe({
        applicationServerKey: 'fake', // not an ArrayBuffer to force an error
    }).then(function (pushSubscription) {
        _isPushNotificationSupported = Boolean(pushSubscription) && isNotificationSupported();
        if (_isPushNotificationSupported) {
            return pushSubscription.unsubscribe();
        }
    }).catch(function (e) {
        if (!/sender id/g.test(e.message)) { // for old samsung browser and old chrome
            _isPushNotificationSupported = isNotificationSupported();
        }
    });
}

function unsubscribeSw(cb) {
    if (isSwAllowed()) {
        serviceWorkerRegistration.then(r => {
            let subscription;
            r.pushManager.getSubscription().then(pushSubscription => {
                subscription = pushSubscription;
                return pushSubscription.unsubscribe();
            }).then(success => {
                if (!success) {
                    cb('Could not unsubscribe to service worker');
                } else {
                    _isSubscribed = false;
                    cb(null, subscription);
                }
            }).catch(e => {
                cb(e);
            });
        }, err => {
            console.log('Service worker not available to unsubscribe to notifications', err);
            cb();
        });
    } else {
        cb();
    }
}

function getAppServerKey() {
    const vapidPublicKey = ServerConfig.config.publicVapidKey;
    return urlBase64ToUint8Array(vapidPublicKey);
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function isSwAllowed() {
    return 'serviceWorker' in navigator;
}

function isPushNotificationSupported() {
    return _isPushNotificationSupported;
}

function isPushNotificationDenied() {
    return isNotificationSupported() && Notification.permission === 'denied';
}

function isSubscribed() {
    return _isSubscribed;
}
