const BrowserDetect = require('browser-detect');

module.exports = {
    check,
    isStartSupported,
};

const OS_SUPPORTED = 'osSupported';
const OS_TOO_OLD = 'osTooOld';

// for cover page
const MINIMUM_START_SUPPORTED_VERSION = {
    iOS: {major: 5}, // iOS < 5 not supported
    android: {major: 4, minor: 0}, // android < 4.0 not supported
};

// for banner
const MINIMUM_SUPPORTED_VERSION = {
    iOS: {major: 9},
};

function checkOSVersion(currentVersion, minimalVersion, cb) {
    if (!currentVersion || !currentVersion.major || !currentVersion.minor || !minimalVersion) {
        // unknown OS versions are considered as supported
        cb(OS_SUPPORTED);
    } else if (currentVersion.major >= minimalVersion.major) {
        if (!minimalVersion.minor) {
            cb(OS_SUPPORTED);
        } else if ((currentVersion.major == minimalVersion.major) && (currentVersion.minor < minimalVersion.minor)) {
            cb(OS_TOO_OLD);
        } else {
            cb(OS_SUPPORTED);
        }
    } else {
        cb(OS_TOO_OLD);
    }
}

//result can be among 'osSupported', 'osTooOld', 'osNotSupported'
function check(cb) {
    if (BrowserDetect.isBot() || (!BrowserDetect.isMobile() && !BrowserDetect.isTablet())) {
        //no OS check on desktop
        cb(OS_SUPPORTED);
    } else if (BrowserDetect.isIOS()) {
        checkOSVersion(BrowserDetect.getIOSVersion(), MINIMUM_SUPPORTED_VERSION.iOS, cb);
    } else if (BrowserDetect.isAndroid()) {
        checkOSVersion(BrowserDetect.getAndroidVersion(), MINIMUM_SUPPORTED_VERSION.android, cb);
    } else {
        cb(OS_SUPPORTED);
    }
}

//result can be among 'osSupported', 'osTooOld', 'osNotSupported'
function isStartSupported(cb) {
    if (!BrowserDetect.isMobile() && !BrowserDetect.isTablet()) {
        //no OS check on desktop
        cb(OS_SUPPORTED);
    } else if (BrowserDetect.isIOS()) {
        checkOSVersion(BrowserDetect.getIOSVersion(), MINIMUM_START_SUPPORTED_VERSION.iOS, cb);
    } else if (BrowserDetect.isAndroid()) {
        checkOSVersion(BrowserDetect.getAndroidVersion(), MINIMUM_START_SUPPORTED_VERSION.android, cb);
    } else {
        cb(OS_SUPPORTED);
    }
}
