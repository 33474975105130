const template = require('./CommercialAd.jade');
const Options = require('../../../Options');

// @vue/component
module.exports = {
    props: {
        type: {
            type: String,
            required: true,
        },
        safeFrame: Boolean,
    },
    data() {
        return {
            commercialAdsEnabled: Options.get('commercialAdsEnabled'),
        };
    },
    template: template(),
};
