const _ = require('lodash');
const diacritics = require('diacritics');
const {i18n: {translate}} = require('fack');
const ServerConfig = require('../ServerConfig');
const MapServers = require('./MapServers');
const poiConfigs = require('../poi/poiConfigs');
const poiConfigsForSearchByTravelTime = poiConfigs.suggestedForSearchByTravelTime;
const AddressPositionHelper = require('./AddressPositionHelper');
const async = require('async');
const DISTANCE_IN_METERS_TO_DISPLAY_APPROXIMATED_INFO = 300;

const poiTags = _.map(poiConfigsForSearchByTravelTime, 'tag');
const tagsForSearchByTravelTime = poiTags
    .concat(['address']);

const PARENTHESIS_REGEXP = /\(([^)]+)\)/;
const ADDRESS_SUGGESTIONS_TAGS = ['street', 'municipality', 'housenumber', 'locality'];

module.exports = {
    getAddressSuggestions,
    getAddressSuggestionsForTravelTime,
    getDisplayNameWithHashTags,
    parseAddress,
    formatAddress,
    queryAddressAndPosition,
    queryNearestAddressFromPosition,
};

function getAddressSuggestions(data, callback) {
    getSuggestionAndIgnoreErrors(data, queryAddressSuggestion, callback);
}

function getAddressSuggestionsForTravelTime(data, callback) {
    getSuggestionAndIgnoreErrors(data, queryAddressSuggestionForTravelTime, callback);
}

function getSuggestionAndIgnoreErrors(data, queryFunction, callback) {
    if (data.q) {
        queryFunction(data,
            (err, suggestions) => {
                if (err) {
                    console.error(err);
                    callback(null);
                } else {
                    callback(suggestions);
                }
            });
    } else {
        callback([]);
    }
}

function getDisplayNameWithHashTags({displayName, tags}) {
    const hashtags = findMatchingPoiHashtags(tags);
    return _.join([displayName].concat(hashtags), ' ');
}

function findMatchingPoiHashtags(tags) {
    const matchingPoiConfigs = _.compact(_.map(tags, findMatchPoiConfigWithTag));
    return translateAndConvertToHashtags(_.map(matchingPoiConfigs, 'tag'));
}

function getTagConf(tag) {
    return _.find(poiConfigs.all, conf => conf.tag == tag);
}

function prepareSuggestion(suggestion) {
    let displayName;
    if (suggestion.text) {
        displayName = suggestion.number != null ? suggestion.number + ' ' + suggestion.text : suggestion.text;
    } else {
        displayName = suggestion.name;
        const cityName = getCityName(suggestion.admin_limits);
        if (cityName) {
            displayName += ' ' + cityName;
        }
    }
    suggestion.displayName = displayName;
    const validTag = _.intersection(suggestion.tags, _.map(poiConfigs.all, 'tag').concat(ADDRESS_SUGGESTIONS_TAGS));
    suggestion.tagsToDisplay = [];
    _.each(validTag, (tag, key) => {
        suggestion.tagsToDisplay[key] = {
            title: translateTagName(tag),
            icon: getTagConf(tag),
        };
    });
}

function getCityName(adminLimits = []) {
    let cityName = '';
    const adminLimit = _.first(adminLimits);
    if (adminLimit) {
        const postalCodesText = _.first(_.get(adminLimit, 'postal_codes', []));
        cityName = postalCodesText ? postalCodesText + ' ' + adminLimit.name : adminLimit.name;
    }
    return cityName;
}

function convertGeoJSONCentroidToPosition(centroid) {
    const [lng, lat] = centroid.coordinates;
    return {
        lng,
        lat,
    };
}

function parseHashTags(text) {
    const hashtags = text.match(/\B#[a-zàâéèçô_]+\b/ig);
    if (hashtags && hashtags.length > 0) {
        const matchingPoiConfigs = _.map(hashtags, findMatchPoiConfigWithHashtag);
        const hashTagToPoiConfigs = _.zipObject(hashtags, matchingPoiConfigs);
        return {
            q: removeHashtags(text, _.keys(_.pickBy(hashTagToPoiConfigs))),
            tags: _.map(hashTagToPoiConfigs, 'tag'),
        };
    } else {
        return {
            q: text,
            tags: tagsForSearchByTravelTime,
        };
    }
}

function removeHashtags(text, hashtags) {
    return _.reduce(hashtags, (text, hashtag) => text.replace(hashtag, ''), text);
}

function findMatchPoiConfigWithHashtag(hashtag) {
    return _.find(poiConfigs.all, poiConfig => isPoiConfigMatchingHashtag(poiConfig, hashtag));
}

function findMatchPoiConfigWithTag(tag) {
    return _.find(poiConfigs.all, poiConfig => poiConfig.tag == tag);
}

function translateAndConvertToHashtags(tags) {
    return _.map(tags, translateAndConvertToHashtag);
}

function translateAndConvertToHashtag(tag) {
    return toHashtag(translateTagName(tag));
}

function toHashtag(tag) {
    return '#' + diacritics.remove(tag.replace(/\s+/g, '_')).toLowerCase();
}

function translateTagName(tag) {
    let baseTranslationKey = 'poi.tag';
    if (_.includes(ADDRESS_SUGGESTIONS_TAGS, tag)) {
        baseTranslationKey = 'addressSuggestion.tag';
    }
    return translate(`${baseTranslationKey}.${tag}`, {defaultValue: tag});
}

function isPoiConfigMatchingHashtag(poiConfig, hashtag) {
    const normalizedTag = diacritics.remove(hashtag.toLowerCase());
    const poiHashtag = translateAndConvertToHashtag(poiConfig.tag);
    return normalizedTag == poiHashtag;
}

function queryAddressSuggestionForTravelTime(data, callback) {
    const {q, tags} = parseHashTags(data.q);
    if (q) {
        queryAddressSuggestion(_.extend({}, data, {
            q,
            tags: tags.join(','),
        }), callback);
    } else {
        async.setImmediate(_.partial(callback, null, []));
    }
}

function queryNearestAddressFromPosition(position, callback) {
    return MapServers.request({
        url: ServerConfig.config.geocoderUrl + '/reverse',
        data: position,
        serverErrorMessage: 'queryReverseGeocoding',
        callback: (err, results) => {
            const feature = _.first(_.get(results, 'features'));
            const nearestAddress = _.get(feature, 'properties.label');
            let address = formatAddress(nearestAddress, position);
            if (nearestAddress) {
                const distance = _.get(feature, 'properties.distance');
                if (distance == null || distance > DISTANCE_IN_METERS_TO_DISPLAY_APPROXIMATED_INFO) {
                    address = translate('transportDurations.nearAddress') + address;
                }
            }
            callback(err, {
                address,
                position,
            });
        },
    });
}

function queryAddressSuggestion(data, callback) {
    return MapServers.request({
        url: ServerConfig.config.geocoderUrl + '/suggestions',
        data,
        serverErrorMessage: 'querySuggestions',
        callback: (err, suggestions) => {
            _.each(suggestions, prepareSuggestion);
            callback(err, suggestions);
        },
    });
}

function parseAddress(address) {
    let addressWithoutPosition;
    let position;
    if (address) {
        addressWithoutPosition = getAddressWithoutPosition(address);
        position = AddressPositionHelper.textToPosition(addressWithoutPosition);
        if (position) {
            addressWithoutPosition = '';
        } else {
            position = getAddressFirstPosition(address);
        }
    }
    return {
        address: addressWithoutPosition,
        position,
    };
}

function queryAddressAndPosition(addressWithPosition, callback) {
    const {address, position} = parseAddress(addressWithPosition);
    if (address) {
        queryAddressSuggestionForTravelTime({q: address}, (err, suggestions) => {
            const suggestion = _.first(suggestions);
            if (!err && suggestion) {
                const displayName = getDisplayNameWithHashTags(suggestion);
                callback(null, {
                    position: position || convertGeoJSONCentroidToPosition(suggestion.centroid),
                    address: formatAddress(displayName, position),
                });
            } else {
                callback(err, null);
            }
        });
    } else if (position) {
        queryNearestAddressFromPosition(position, callback);
    } else {
        async.setImmediate(_.partial(callback, new Error('No address input for travel time suggestion query')));
    }
}

function formatAddress(address, position) {
    if (address) {
        address = getAddressWithoutPosition(address);
    }
    if (position && address && AddressPositionHelper.textToPosition(address)) {
        address = '';
    }
    const positionText = AddressPositionHelper.positionToText(position);
    if (positionText) {
        if (address) {
            address += ' (' + positionText + ')';
        } else {
            address = positionText;
        }
    }
    return address;
}

function getAddressFirstPosition(address) {
    return _.find(_.map(PARENTHESIS_REGEXP.exec(address), AddressPositionHelper.textToPosition));
}

function getAddressWithoutPosition(address) {
    return _.trim(address.replace(PARENTHESIS_REGEXP, ''));
}
