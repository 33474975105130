const _ = require('lodash');
const DefaultConfiguration = require('./DefaultConfiguration');
const isNullOrEmpty = require('./isNullOrEmpty');
const SearchFiltersConfiguration = require('../js/search/SearchFiltersConfiguration');
const intersects = require('./intersects');
const {PROPERTY_TYPES} = require('./constants/PropertyType');

const basicFilters = [
    'filterType',
    'propertyType',
    'zoneIdsByTypes',
    'minPrice',
    'maxPrice',
    'minRooms',
    'maxRooms',
    'minArea',
    'maxArea',
    'minBedrooms',
    'maxBedrooms',
    'minGardenSurfaceArea',
    'maxGardenSurfaceArea',
];
const advancedFilters = [
    'isOnLastFloor',
    'isGroundFloor',
    'isNotGroundFloor',
    'hasPool',
    'hasBalcony',
    'hasTerrace',
    'hasBalconyOrTerrace',
    'hasCellar',
    'hasParking',
    'hasFirePlace',
    'hasDoorCode',
    'hasIntercom',
    'hasGarden',
    'hasElevator',
    'workToDo',
    'noWorkToDo',
    'isExclusiveSaleMandate',
    'hasCaretaker',
    'isDuplex',
    'hasSeparateToilet',
    'isFurnished',
    'isNotFurnished',
    'hasPhoto',
    'has3DModel',
    'isInStudentResidence',
    'isInSeniorResidence',
    'isInTourismResidence',
    'isInManagedResidence',
    'isNotInResidence',
    'isNotLifeAnnuitySale',
    'isLifeAnnuitySaleOnly',
    'isNotPropertyWithoutUsufruct',
    'isPropertyWithoutUsufructOnly',
    'isPreview',
    'isEligibleForPinelLaw',
    'newProperty',
    'isPromotedAsExclusive',
    'hasToBeBuilt',
    'hasNotToBeBuilt',
    'opticalFiberStatus',
    'chargingStations',
    'isEligibleForDenormandieLaw',
    'energyClassification',
    'isDisabledPeopleFriendly',
    'flatSharing',

    //restricted fields
    'isBuildingPlot',
    'exportableAd',
    'reference',
    'isLeading',
    'highlighted',
    'geocoding',
    'queryString',
    'adsNestedQueryString',
    'contactRequestsNestedQueryString',
    'is3dHighlighted',
    //'extensionType', special field
];

module.exports = {
    getInternalFilters,
    getAdvancedFilters,
    getAdvancedFiltersCount,
    getFiltersCount,
    getUserInputFilters,
    getFirstZoneId,
    getBasicFilters,
    getVueFilters,
    getFilters,
    getRawFilters,
    shouldDisplayAboutPropertyGroup,
    getIgnoredFiltersInValidator,
    getFilterFieldsVisibility,
};

function getInternalFilters() {
    return [
        'sortBy',
        'sortOrder',
        'page',
        'resultsPerPage', // TODO: remove this when all clients are up-to-date
        'maxAuthorizedResults', // TODO: remove this when all clients are up-to-date
        'mapMode', // TODO: should not be in search criteria
        'camera', // TODO: should not be in search criteria
    ];
}

function getAdvancedFilters(filters) {
    return _(filters)
        .omitBy(isNullOrEmpty)
        .pick(advancedFilters)
        .value();
}

function getBasicFilters(filters) {
    return _(filters)
        .omitBy(isNullOrEmpty)
        .pick(basicFilters)
        .value();
}

function getUserInputFilters(filters) {
    return _(filters)
        .omitBy(isNullOrEmpty)
        .omit(getInternalFilters())
        .value();
}

function getFiltersCount(filters) {
    let count = _.size(getUserInputFilters(filters));
    if (_.size(filters.filterType) > 1) { // rent & buy
        count--;
    }
    // only admin can add annexe type
    if (_.size(_.without(filters.propertyType, 'annexe')) >= _.size(DefaultConfiguration.allPropertyTypes)) { // all types
        count--;
    }
    if (_.includes(filters.onTheMarket, true)) {
        count--;
    }
    if (filters.extensionType && filters.extensionType === 'extendedIfNoResult') {
        count--;
    }

    return count;
}

function getAdvancedFiltersCount(filters) {
    let count = _.size(getAdvancedFilters(filters));
    //now handling special filters
    if (!_.isEqual([true], filters.onTheMarket)) {
        count++;
    }
    if (filters.extensionType && filters.extensionType !== 'extendedIfNoResult') {
        count++;
    }
    if (filters.buyNewPropertySetInFilterType && filters.newProperty === true) {
        count--;
    } //removes newProperty count
    if (filters.minBedrooms || filters.maxBedrooms) {
        count++;
    }
    if (filters.minGardenSurfaceArea || filters.maxGardenSurfaceArea) {
        count++;
    }
    return count;
}

function getFirstZoneId({zoneIdsByTypes}) {
    const {zoneIds} = zoneIdsByTypes || {};
    return _.first(zoneIds);
}

function getVueFilters(self) {
    if (self.vueData) {
        const searchCriteria = {
            minPrice: self.vueData.searchCriteria.minPrice,
            minArea: self.vueData.searchCriteria.minArea,
            maxArea: self.vueData.searchCriteria.maxArea,
            maxRooms: self.vueData.searchCriteria.maxRooms,
            minBedrooms: self.vueData.searchCriteria.minBedrooms,
            maxBedrooms: self.vueData.searchCriteria.maxBedrooms,
            minGardenSurfaceArea: self.vueData.searchCriteria.minGardenSurfaceArea,
            maxGardenSurfaceArea: self.vueData.searchCriteria.maxGardenSurfaceArea,
        };

        // MaxPrice and minRooms are used in HP and are not in Vue
        if (!self.isHomePage) {
            searchCriteria.maxPrice = self.vueData.searchCriteria.maxPrice;
            searchCriteria.minRooms = self.vueData.searchCriteria.minRooms;
        }

        return searchCriteria;
    }
}

function getFilters(self) {
    const filters = self.searchFieldsHandler.getFilters();
    return _.extend(filters, self.getVueFilters(self));
}

function getRawFilters(self) {
    const rawFilters = self.searchFieldsHandler.getRawFilters();
    return _.extend(rawFilters, self.getVueFilters(self));
}

function shouldDisplayAboutPropertyGroup(propertyType) {
    const parkingOrTerrain = [PROPERTY_TYPES.TERRAIN, PROPERTY_TYPES.PARKING];
    return _.difference(propertyType, parkingOrTerrain).length !== 0;
}

function getIgnoredFiltersInValidator() {
    return {
        minPrice: {
            excluded: true,
        },
        maxPrice: {
            excluded: true,
        },
        minRooms: {
            excluded: true,
        },
        maxRooms: {
            excluded: true,
        },
        minArea: {
            excluded: true,
        },
        maxArea: {
            excluded: true,
        },
        minBedrooms: {
            excluded: true,
        },
        maxBedrooms: {
            excluded: true,
        },
        minGardenSurfaceArea: {
            excluded: true,
        },
        maxGardenSurfaceArea: {
            excluded: true,
        },
    };
}

function getFilterFieldsVisibility(propertyType, filterType, isAdmin) {
    const configs = SearchFiltersConfiguration.advancedFiltersConfiguration;
    const filterFieldsVisibility = {};
    _.forEach(configs, (conf, key) => {
        if (!propertyType) {
            filterFieldsVisibility[key] = true;
            return;
        }
        const isPropertyTypeMatching = !conf.propertyType || intersects(conf.propertyType, propertyType);
        const isFilterTypeMatching = !conf.filterType || intersects(conf.filterType, filterType);
        filterFieldsVisibility[key] = Boolean(isPropertyTypeMatching && isFilterTypeMatching)
            || Boolean(isAdmin);
    });
    return filterFieldsVisibility;
}
