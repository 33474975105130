const template = require('./Overlay.jade');
const _ = require('lodash');

const VARIANTS = [
    'dark',
    'light',
];

/**
 * Component that covers a DOM element with a small fade.
 *
 * Covered element is the nearest parent with the 'kimono-overlay-parent' class.
 */
// @vue/component
module.exports = {
    bemName: 'KimonoOverlay', // It avoids having a simple '.overlay' class, with potential conflicts.
    props: {
        variant: {
            type: String,
            required: true,
            values: VARIANTS,
            validator: value => _.includes(VARIANTS, value),
        },
        noPointerEventHack: Boolean, // Should not be used, it is a hack to get current behaviour. Remove it after fixing f4/kimono/www#15873
    },
    computed: {
        modifiers() {
            const {variant, noPointerEventHack: noPointerEvent} = this;
            return {
                [variant]: true,
                noPointerEvent,
            };
        },
    },
    template: template(),
};
