const template = require('./FloatingContainer.jade');

module.exports = {
    props: {
        visible: Boolean,
        translationContext: {
            type: String,
            default: undefined,
        },
    },
    template: template(),
};
