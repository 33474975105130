const _ = require('lodash');
const urlUtil = require('url');
const ServerConfig = require('./ServerConfig');
const Account = require('./authentication/Account');
const isNullOrEmpty = require('../common/isNullOrEmpty');

module.exports = {
    sendForm,
    setOnTheMarket,
    setBlur,
    setFeaturedProperty,
};

function sendForm(serializedForm, mailType, callback) {
    const url = mailType == 'add' ? '/api/addRealEstateAd' : '/api/editRealEstateAd';
    if (!Account.isAuthenticated()) {
        callback({where: url, error: new Error('Requires authenticated account')});
        return null;
    } else {
        return Account.postJson({
            url: ServerConfig.config.importerUrl + url,
            data: {
                ad: _.omitBy(serializedForm, isNullOrEmpty),
                type: 'edition',
                mailType,
            },
            disableErrorPage: true,
            serverErrorMessage: 'sendForm',
            callback,
        });
    }
}

function setOnTheMarket(realEstateAdId, value, callback) {
    if (!Account.isAuthenticated()) {
        callback({where: 'setOnTheMarket', error: new Error('Requires authenticated account')});
        return null;
    } else {
        return Account.postJson({
            url: `${ServerConfig.config.importerUrl}/api/setOnTheMarket`,
            data: {
                realEstateAdId,
                value,
            },
            disableErrorPage: true,
            serverErrorMessage: 'setOnTheMarket',
            callback,
        });
    }
}

function setFeaturedProperty(propertyName, realEstateAdId, value, callback) {
    if (!Account.isAuthenticated()) {
        callback({where: 'setFeaturedProperty', error: new Error('Requires authenticated account')});
        return null;
    } else {
        const {protocol, host} = urlUtil.parse(ServerConfig.config.adFeaturingUrl);
        const url = urlUtil.format({
            protocol,
            host,
            pathname: `api/realEstateAd/${realEstateAdId}/${propertyName}`,
        });
        return Account.putJson({
            url,
            data: {
                value,
            },
            serverErrorMessage: 'setFeaturedProperty',
            disableErrorPage: true,
            callback,
        });
    }
}

function setBlur(realEstateAdId, data, callback) {
    if (!Account.isAuthenticated()) {
        callback({where: 'setBlur', error: new Error('Requires authenticated account')});
        return null;
    } else {
        return Account.postJson({
            url: `${ServerConfig.config.importerUrl}/api/setBlur`,
            data: _.extend(data, {
                realEstateAdId,
            }),
            serverErrorMessage: 'setBlur',
            disableErrorPage: true,
            callback,
        });
    }
}
