const _ = require('lodash');
const async = require('async');

const ApplicationPage = require('../pages/ApplicationPage');
const Account = require('../authentication/Account');
const Error404 = require('../Error404');
const {getDefaultPeriod} = require('../stats/StatsHelper');
const {
    getAdsNotificationBoostsHistory,
    getDefaultSelectedTransactionTypes,
    formatHistoryData,
} = require('./AdsNotificationBoostsHistoryHelpers');
const AccountAdsNotificationBoostsHistoryView = require('./AccountAdsNotificationBoostsHistoryView');

module.exports = class AccountAdsNotificationBoostsHistoryPage extends ApplicationPage {
    constructor() {
        super({
            name: 'accountAdsNotificationBoostsHistoryPage',
            title: 'Emailings+',
            isRegistrationRequired: true,
        });
    }

    getUrlPattern() {
        return '/emailings-plus/{idOrNamespace}';
    }

    parseUrl(url, accountId) {
        return {
            accountId,
        };
    }

    getMiddleViews() {
        // TODO: add SelectOwnedAccountView when we pass SelectOwnedAccountView to Vue
        const _accountAdsNotificationBoostsHistoryView = new AccountAdsNotificationBoostsHistoryView();
        return [_accountAdsNotificationBoostsHistoryView];
    }

    isPageAccessAllowed() {
        return Account.hasRole('adsNotificationBoostsHistoryViewer');
    }

    _loadData(options, cb) {
        const {accountId} = options;
        if (accountId) {
            async.auto({
                account: _.partial(Account.get, accountId),
                adsNotificationBoostsHistory: [
                    'account', (cb, {account}) => {
                        const {startDate, endDate} = getDefaultPeriod();
                        const data = {
                            accountId: account && account.id,
                            startDate,
                            endDate,
                            transactionTypes: getDefaultSelectedTransactionTypes(),
                        };
                        getAdsNotificationBoostsHistory(data, cb);
                    },
                ],
            }, (err, results) => {
                const {account, adsNotificationBoostsHistory} = results;
                if (err) {
                    if (err.code == 404) {
                        cb(new Error404(err.message));
                    } else {
                        cb(err);
                    }
                } else if (account && adsNotificationBoostsHistory && adsNotificationBoostsHistory.success) {
                    cb(null, _.extend(options, {
                        account,
                        boostsHistory: formatHistoryData(adsNotificationBoostsHistory.data.boostsHistory),
                    }));
                } else {
                    cb(new Error404('Account not found or could not get ads'
                        + ` notification boosts history for account ${accountId}`));
                }
            });
        } else {
            cb(new Error404('No account id or namespace'));
        }
    }
};
