const _ = require('lodash');
const async = require('async');

const templateSuggestionItem = require('../templates/poiAndAddressSuggestionItem.jade');
const AddressSuggestionHelper = require('../utils/AddressSuggestionHelper');
const {i18n: {translate}} = require('fack');
const CancelableCallback = require('../utils/CancelableCallback');
const ZoneByTimeHelper = require('../utils/ZoneByTimeHelper');

const BrowserDetect = require('browser-detect');
const EventPack = require('../utils/EventPack');

module.exports = class SearchByTravelTimeForm {
    constructor({$addressInput, $scrollableContainer}) {
        this._$addressInput = $addressInput;
        this._$scrollableContainer = $scrollableContainer;
        this._initLocationSuggestions();
        if (BrowserDetect.isMobile()) {
            this._initAddressInputListeners();
        }
    }

    suggest({address, mode, duration}, callback) {
        this._pendingCallback = CancelableCallback((err, result) => {
            if (!err && result) {
                const {address, position} = result;
                callback(null, {
                    address,
                    position,
                    mode,
                    duration,
                    type: 'travelTimeZone',
                });
            } else {
                err = err || new Error('No suggestions found for suggest');
                callback(err);
            }
        });
        const {position} = AddressSuggestionHelper.parseAddress(address);
        if (position) {
            async.setImmediate(_.partial(this._pendingCallback, null, {address, position}));
        } else {
            AddressSuggestionHelper.queryAddressAndPosition(address, this._pendingCallback);
        }
    }

    _getSuggestions(text, callback) {
        AddressSuggestionHelper.getAddressSuggestionsForTravelTime({
            q: text,
        }, callback);
    }

    _getSuggestionDisplayName(suggestion) {
        return AddressSuggestionHelper.getDisplayNameWithHashTags(suggestion);
    }

    _initLocationSuggestions() {
        const datasetsConf = [
            {
                display: _.bind(this._getSuggestionDisplayName, this),
                source: _.throttle(_.bind(this._getSuggestions, this), 300),
                templates: {
                    suggestion: templateSuggestionItem,
                    empty: "<div class='noSuggestions'>" + translate('noAddressFound') + '</div>',
                },
            },
        ];
        this._$addressInput.f4typeahead({
            hint: false,
            highlight: true,
            minLength: 1,
            autoselect: 'first',
        }, datasetsConf);
    }

    _initAddressInputListeners() {
        this._eventPack = new EventPack();
        this._eventPack.on(this._$addressInput, {
            'f4typeahead:opened, focus': () => {
                this._setAddressInputInFullScreen();
            },
            'f4typeahead:closed': () => {
                this._unsetAddressInputFullScreen();
            },
            'f4typeahead:selected': () => {
                this._$addressInput.blur(); //make input keyboard disappear
            },
        });
    }

    _removeAddressInputListeners() {
        this._eventPack.removeAllListeners();
    }

    _setAddressInputInFullScreen() {
        this._$scrollableContainer.addClass('fullScreenAddressSuggestions');
        this._scrollTop = this._$scrollableContainer.scrollTop();
        this._$scrollableContainer.scrollTop(0);
        if (BrowserDetect.isIOS()) {
            // scroll down using proprietary webkit method
            // https://developer.mozilla.org/fr/docs/Web/API/Element/scrollIntoViewIfNeeded
            const domElement = this._$scrollableContainer.get(0);
            if (domElement.scrollIntoViewIfNeeded) { //non standard feature, check if it exists
                domElement.scrollIntoViewIfNeeded({opt_center: false});
            }
        }
    }

    _unsetAddressInputFullScreen() {
        this._$scrollableContainer.removeClass('fullScreenAddressSuggestions');
        if (this._scrollTop) {
            this._$scrollableContainer.scrollTop(this._scrollTop);
        }
    }

    suggestAndComputeZoneByTime(item, callback) {
        this.suggest(item, (err, zoneParams) => {
            if (!err && zoneParams) {
                this._pendingCallback = CancelableCallback(callback);
                ZoneByTimeHelper.compute(zoneParams, this._pendingCallback);
            } else {
                err = err || new Error('No search by travel time suggestion found for computing travel time zone.');
                callback(err, zoneParams);
            }
        });
    }

    cancelRequest() {
        if (this._pendingCallback) {
            this._pendingCallback.cancel();
            this._pendingCallback = null;
        }
        if (BrowserDetect.isMobile()) {
            this._removeAddressInputListeners();
        }
    }
};
