const {authAjax} = require('../authentication/Account');
const buildUrl = require('./buildUrl');

module.exports = {
    getFileDocumentPreview,
    getFileUploadUrlForDocumentType,
    deleteFileDocument,
};

function getFileDocumentPreview(dossierId, documentType, fileId, callback) {
    authAjax({
        url: buildUrl(`/dossier/${dossierId}/document/${documentType}/preview/${fileId}`),
        method: 'GET',
        disableErrorPage: true,
        xhrFields: {
            responseType: 'blob',
        },
        returnJqXHR: true,
        callback,
    });
}

function getFileUploadUrlForDocumentType(dossierId, documentType) {
    return buildUrl(`/dossier/${dossierId}/documents/${documentType}`);
}

function deleteFileDocument(dossierId, documentType, fileId, callback) {
    authAjax({
        url: buildUrl(`/dossier/${dossierId}/document/${documentType}/${fileId}`),
        method: 'DELETE',
        disableErrorPage: true,
        callback,
    });
}
