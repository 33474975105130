const Vue = require('vue');
const _ = require('lodash');

// @vue/component
module.exports = Vue.extend({
    mixins: [
        require('../../form/components/mixins/configMultiselectRadioButton'),
        require('./mixins/withTranslations'),
    ],
    props: {
        min: {
            required: true,
            type: Number,
        },
        max: {
            required: true,
            type: Number,
        },
        value: {
            type: [Number, Object],
            default: undefined,
        },
    },
    computed: {
        options() {
            return _.map(_.range(this.min, this.max), i => {
                return {label: `${i}`, value: i};
            }).concat([
                {label: `${this.max}+`, value: {gte: this.max}},
            ]);
        },
    },
});
