const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'real-estate-property-descriptor';

module.exports = namespaceComponents(NAMESPACE, {
    'address-field': require('./AddressField'),
    'number-radio-group': require('./NumberRadioGroup'),
    'optins': require('./Optins'),
    'agencies-to-contact': require('./AgenciesToContact'),
});
