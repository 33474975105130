const template = require('./SeeMoreDescription.jade');
const ContainerQueries = require('../../mixins/ContainerQueries');

const MAX_HEIGHT_IN_PX = 400;
// 20px less the actual height to avoid flickering in android - cf f4/kimono/www#17064
const MAX_TRUNCATE_HEIGHT_IN_PX = MAX_HEIGHT_IN_PX - 20;

// @vue/component
module.exports = {
    mixins: [
        ContainerQueries({
            shouldTruncateDescription: {
                minHeight: MAX_TRUNCATE_HEIGHT_IN_PX,
            },
        }),
    ],
    props: {
        descriptionText: {
            type: String,
            default: '',
        },
        descriptionHtml: {
            type: String,
            default: '',
        },
        seeMoreButtonText: {
            type: String,
            default: '',
        },
        seeMoreButtonEnabled: Boolean,
    },
    data() {
        return {
            shouldTruncate: true,
        };
    },
    computed: {
        shouldTruncateDescription() {
            return this.shouldTruncate && this.breakpoints.shouldTruncateDescription;
        },
        isSeeMoreButtonDisplayed() {
            return this.seeMoreButtonEnabled && this.shouldTruncateDescription;
        },
        descriptionModifiers() {
            return {
                partial: this.shouldTruncateDescription,
            };
        },
        inlineStyle() {
            return this.shouldTruncateDescription ? `max-height:${MAX_HEIGHT_IN_PX}px` : '';
        },
        trimmedDescriptionText() {
            return this.descriptionText.trim();
        },
        trimmedDescriptionHtml() {
            return this.descriptionHtml.trim();
        },
    },
    methods: {
        disableExcerpt() {
            this.shouldTruncate = false;
        },
    },
    template: template(),
};
