const _ = require('lodash');
const SelectPicker = require('../../utils/SelectPicker');
const hasBooleanAttribute = require('../utils/hasBooleanAttribute');

module.exports = {
    init,
    handlesField,
    setValue,
    getValue,
};

function init({$form}) {
    SelectPicker.enable($form.find('select'));
}

function handlesField($field) {
    return $field.prop('tagName') == 'SELECT';
}

function getValue($select) {
    let val = $select.val();
    if (_.isNil(val)) {
        val = undefined;
    } else if (hasBooleanAttribute($select, 'data-number')) {
        if (val == '') {
            val = undefined;
        } else {
            val = _.toNumber(val);
            if (!_.isFinite(val)) {
                val = undefined;
            }
        }
    }
    return val;
}

function setValue($select, val) {
    $select.val(val);
    $select.selectpicker('render');
}
