const Router = require('./Router');
const History = require('./History');

function back() {
    const successUrl = _getPreviousUrlInHistory();
    History.pushState(null, null, successUrl);
    Router.parseUrl(successUrl);
}

function _getPreviousUrlInHistory() {
    const currentIndex = History.getCurrentIndex();
    const currentLocation = window.location;
    const currentHost = currentLocation.host;
    const currentPathname = currentLocation.pathname.replace(/\/$/g, ''); //remove last / of pathname
    let url = '/';
    if (currentIndex && currentPathname) {
        for (let index = currentIndex; index >= 0; index--) {
            const historyUrl = History.getStateByIndex(index) && History.getStateByIndex(index).url;
            if (historyUrl.indexOf(currentHost) < 0) {
                break; //stop if history is not within kimono website
            }
            if (historyUrl.indexOf(currentPathname) < 0) {
                url = historyUrl;
                break;
            }
        }
    }
    return url;
}

module.exports = {
    back,
};
