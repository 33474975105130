const {resourceUrl} = require('fack');
const template = require('./BlockContentImage.jade');

// @vue/component
module.exports = {
    props: {
        imageName: {
            type: String,
            default: undefined,
        },
        imageFullPath: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        imagePath() {
            const {imageFullPath, imageName} = this;
            return imageFullPath || resourceUrl(`images/contentillustrations/${imageName}`);
        },
        backgroundStyles() {
            return {
                'background-image': `url(${this.imagePath})`,
            };
        },
    },
    template: template(),
};
