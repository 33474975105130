const FileUploaderWidget = require('./FileUploaderWidget');
const {getImageUrlFromAlias} = require('./ImageHelper');
const ServerConfig = require('./ServerConfig');
const Account = require('./authentication/Account');

module.exports = class ImageUploaderWidget extends FileUploaderWidget {
    _getAcceptedFiles() {
        return 'image/*';
    }

    _getTranslationKey() {
        return 'image';
    }

    _getFileUniqueIdentifier(response) {
        return response.filename;
    }

    _getFileDownloadUrl(fileName) {
        return getImageUrlFromAlias(fileName);
    }

    _getUploadServerUrl() {
        return ServerConfig.config.uploadUrl + '/upload?access_token=' + encodeURIComponent(Account.getAccessToken());
    }
};
