const template = require('./DocumentListBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'DocumentListBlock.',
            keys: [
                'identity',
                'domicile',
                'pro',
                'ressources',
            ],
        }),
    ],
    template: template(),
};
