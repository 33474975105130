// like alert, but with HTML
const _ = require('lodash');
const $ = require('jquery');

const EventPack = require('./EventPack');
const View = require('./../views/View');

const template = require('../templates/views/modalMessageView.jade');

let currentModal = null;

class ModalMessage extends View {
    constructor(options = {}) {
        super(_.defaults(options, {
            $container: $('body'),
        }));
        this.template = template;
        this._eventPack = new EventPack();
    }

    show(options) {
        super.show(options);
        this.$modal = this.$element;
        this._eventPack.on(this.$modal, 'hidden.bs.modal', () => {
            this.hide();
            if (options.hideCallback) {
                options.hideCallback();
            }
        });
        this.$modal.modal('show');
    }

    hide() {
        this.$modal.modal('hide');
        this._eventPack.removeAllListeners();
        this.$modal = null;
        currentModal = null;
        super.hide();
    }
}

function show(options) {
    if (_.isString(options)) {
        options = {message: options};
    }
    currentModal = new ModalMessage();
    currentModal.show(options);
}

function hide() {
    if (currentModal) {
        currentModal.hide();
    }
}

module.exports = {
    show,
    hide,
};
