const template = require('./SetFeaturedProductPlaceholder.jade');

const returnEmptyString = () => '';

// @vue/component
module.exports = {
    bemName: 'SetFeaturedProduct',
    // Define properties used by SetFeaturedProduct template as we include it in SetFeaturedProductPlaceholder template
    computed: {
        priceAsText: returnEmptyString,
        oldPriceAsText: returnEmptyString,
        durationAsText: returnEmptyString,
        effectiveDurationAsText: returnEmptyString,
        dateAsText: returnEmptyString,
        modifiers: () => {},
        hasSpecialOfferDuration: () => false,
    },
    template: template(),
};
