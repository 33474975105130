const template = require('./ParagraphTitle.jade');
const UrlHelper = require('../../../common/UrlHelper');

// @vue/component
module.exports = {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        anchor() {
            const titleWithoutNonBreakingSpace = this.title.replace(/&nbsp;/g, '');
            return UrlHelper.slugify(titleWithoutNonBreakingSpace);
        },
    },
    template: template(),
};
