const _ = require('lodash');
const BrowserDetect = require('browser-detect');
const {i18n: {translate}} = require('fack');

const EventPack = require('../utils/EventPack');
const Views = require('../views/Views');
const ContainerView = require('../views/ContainerView');
const LandingPageSearchFormView = require('../views/landingPages/LandingPageSearchFormView');
const SimpleContentWithAdsView = require('../views/SimpleContentWithAdsView');
const SimpleContentPage = require('./SimpleContentPage');
const template = require('../templates/landingPage/landingPageContainerView.jade');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const SearchLinksView = require('../views/SearchLinksView');

module.exports = class SearchLandingPage extends SimpleContentPage {
    constructor(options) {
        options = _.defaults(options, {
            name: 'home',
            url: `/${options.slug}`,
            gtmCategory: 'Homepage',
            title: translate(`landingPages.titles.${options.slug}`),
            contentSubPath: 'landing-page',
        });
        super(options);
        this.contentPath = this.configuration.slug;
        this._containerView = new ContainerView({template});
        this._searchFormView = new LandingPageSearchFormView(options);
        this._contentView = new SimpleContentWithAdsView({
            pageName: this.configuration.name,
        });
        this._searchLinksView = new SearchLinksView();
        this._containerView.setSubViews([
            {view: this._searchFormView, containerSelector: '.content'},
            {view: this._contentView, containerSelector: '.content'},
            {view: this._searchLinksView, containerSelector: '.searchLinks'},
        ]);
        this._eventPack = new EventPack();
    }

    open(options) {
        const {forcedFields, searchButtonTitle, slug, underlineLinks, allowedPlaceTypes} = this.configuration;
        const landingSearchButtonTitle = searchButtonTitle
            || translate(`landingPages.searchButtonTitles.${slug}`, {defaultValue: ''});
        super.open(_.extend({}, options, {
            forcedFields,
            locationsField: {
                allowedPlaceTypes,
            },
            imageName: this._getImageName(),
            searchButtonTitle: landingSearchButtonTitle,
            underlineLinks,
        }));
        this._eventPack.on(this._searchFormView, 'search', _.bind(this._search, this));
    }

    _search(search) {
        if (BrowserDetect.isMobile()) {
            Views.header.search(search);
        } else {
            PageManager.openPage(PagesFactories.searchPageFactory, {search});
        }
    }

    getMiddleViews() {
        return this._containerView;
    }

    getUrlPattern() {
        return this.configuration.url;
    }

    _getImageName() {
        let imageName = this.configuration.slug;
        const regexMatch = /(-[tf][2-4]){2}/.exec(imageName);
        if (regexMatch && regexMatch.length > 0) {
            imageName = imageName.replace(regexMatch[0], '-defaut');
        }
        return imageName;
    }
};
