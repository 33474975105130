const Title = require('../pages/Title');
const ApplicationPage = require('../pages/ApplicationPage');
const VueView = require('../vue/VueView');
const i18nMixin = require('../vue/components/mixins/i18n');
const Account = require('../authentication/Account');

module.exports = class ErrorPage extends ApplicationPage {
    constructor({template, name}) {
        super();
        this.name = name;
        // @vue/component
        const vueOptions = {
            mixins: [
                i18nMixin({
                    prefix: `errorPages.${name}.`,
                    keys: [
                        'title',
                        'message',
                        'action',
                    ],
                }),
            ],
            methods: {
                disconnect() {
                    Account.forgetAuthentication();
                },
            },
        };
        this.contentView = new VueView({template}, vueOptions);
    }

    open(options) {
        this._displayError();
        super.open(options);
    }

    getName() {
        return this.name;
    }

    getMiddleViews() {
        return this.contentView;
    }

    _displayError() {
        Title.setTitle({
            title: 'Oups...',
        });
    }
};
