const $ = require('jquery');
const Dropzone = require('./lib/dropzone');
const template = require('./templates/components/fileUploaderWidget.jade');
const {EventEmitter} = require('events');
const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const EventPack = require('./utils/EventPack');

Dropzone.autoDiscover = false; // disable autodiscover as we always manually initialize dropzones, to avoid double initialization
const {confirm: dropzoneConfirm} = Dropzone;

const MULTIPLE_TRANSLATION_KEY = 'multiple';

module.exports = class FileUploaderWidget extends EventEmitter {
    constructor({maxFileSizeInMB, dropzoneHiddenInputId} = {}) {
        super();
        this.fileInfos = [];
        this.uploadingFiles = [];
        this.errorFiles = [];
        this.isValidationInhibited = false;
        this.maxFileSizeInMB = maxFileSizeInMB;
        this.dropzoneHiddenInputId = dropzoneHiddenInputId;
        this._eventPack = new EventPack();
        this._filesNames = {};
    }

    init(options) {
        this.myOptions = options;
        let {element} = options;
        const {maxFiles = null} = options;
        this.$container = options.$container || $(options.container);
        if (!options.name) {
            options.name = 'file ' + new Date().toString();
        }
        this._initValidators(options);
        if (!element) {
            const $element = $(template());
            this.$container.append($element);
            element = $element.get(0);
        }
        const serverUrl = this._getUploadServerUrl();
        _.defaults(this.myOptions, {autoProcessQueue: true});

        const multiple = maxFiles !== 1;
        const dropzoneTranslationOptions = getTranslations('default', multiple);
        const translationKey = this._getTranslationKey();
        if (translationKey) {
            _.extend(dropzoneTranslationOptions, getTranslations(translationKey, multiple));
        }
        const dropzoneOptions = _.extend(dropzoneTranslationOptions, {
            url: serverUrl,
            maxFiles,
            addRemoveLinks: true,
            acceptedFiles: this._getAcceptedFiles(),
            thumbnail: function (file, dataUrl) {
                if (dataUrl) {
                    this.defaultOptions.thumbnail(file, dataUrl);
                }
            },
            error: function (file, message) {
                if (message instanceof Array) {
                    const response = _.find(message, ['file.oldFilename', file.name]);

                    if (response.status === Dropzone.ERROR) {
                        const error = _.get(response, 'error', response);
                        const translatedError = translate(`fileUploader.dropzone.${translationKey}.${error}`);

                        this.defaultOptions.error(file, translatedError);
                    }
                } else {
                    this.defaultOptions.error(file, _.get(message, 'message', message));
                }
            },
            autoProcessQueue: this.myOptions.autoProcessQueue,
        });

        // Max number of files before splitting in several requests
        if (this.myOptions.parallelUploads) {
            dropzoneOptions.parallelUploads = this.myOptions.parallelUploads;
            dropzoneOptions.uploadMultiple = true;
        }

        const maxFileSizeInMB = this.maxFileSizeInMB;
        if (maxFileSizeInMB) {
            dropzoneOptions.maxFilesize = maxFileSizeInMB;
        }
        const dropzoneHiddenInputId = this.dropzoneHiddenInputId;
        if (dropzoneHiddenInputId) {
            dropzoneOptions.hiddenInputId = dropzoneHiddenInputId;
        }
        if (this._getUploadRequestHeaders) {
            dropzoneOptions.headers = this._getUploadRequestHeaders();
        }
        Dropzone.confirm = (question, accepted, rejected) => {
            if (this._shouldDisplayConfirmation(question)) {
                dropzoneConfirm(question, accepted, rejected);
            } else {
                accepted();
            }
        };

        this.myDropzone = new Dropzone(element, dropzoneOptions);
        this._eventPack.on(this.myDropzone, {
            success: _.bind(this._onFileUploaded, this),
            addedfile: _.bind(this._onAddedFile, this),
            removedfile: _.bind(this._onRemovedFile, this),
            error: _.bind(this._onErrorFile, this),
            complete: _.bind(this._updateValidation, this),
        });

        if (options.files) {
            this.setFiles(options.files);
        }
    }

    _initValidators(options) {
        const key = options.name;
        if (options.addHiddenField !== false) {
            this.$hiddenInput = $('<input class="hidden" type="text" name="' + key + '">');
            this.$container.append(this.$hiddenInput);
        }
        //keep this in defer because bootstrapValidator is destroyed in between
        _.defer(() => {
            if (null == this.$container) {
                this.$container = options.$container;
            }
            const $form = this.$container.closest('form');
            const bootstrapValidator = $form.data('bootstrapValidator');
            if (this.$container !== options.$container || !bootstrapValidator) {
                return; //race condition, element was cleared
            }
            //maybe have a better way to get the form validator
            const photoValidators = {
                validators: {
                    callback: {
                        callback: () => {
                            if (this._checkRequired()) {
                                if (this._checkError()) {
                                    return true;
                                } else {
                                    return {
                                        message: translate('formErrorMessages.photoValidationError'),
                                        value: false,
                                    };
                                }
                            } else {
                                return {
                                    message: translate('formErrorMessages.photoRequiredError'),
                                    value: false,
                                };
                            }
                        },
                    },
                },
            };
            bootstrapValidator.addField(key, photoValidators);
            bootstrapValidator.enableFieldValidators(key, false, 'notEmpty');
        });
    }

    setFiles(filesAliases) {
        if (!_.isEqual(filesAliases, this.getFiles())) {
            this._setFiles(filesAliases);
        }
    }

    _setFiles(filesAliases) {
        this._withoutValidation(() => { // avoid validation of intermediate states
            this.myDropzone.removeAllFiles(true);
            this.fileInfos = [];
            this._addFiles(filesAliases);
        });
        this._updateValidation();
    }

    _addFiles(filesAliases) {
        _.each(filesAliases, fileAlias => {
            this._addFile(fileAlias);
        });
    }

    _addFile(fileAlias) {
        const {myDropzone} = this;
        const name = this._filesNames[fileAlias];
        const file = {name, size: null};
        myDropzone.options.addedfile.call(myDropzone, file);
        const url = this._getFileDownloadUrl(fileAlias);
        myDropzone.options.thumbnail.call(myDropzone, file, url);
        myDropzone.files.push(file);
        this._registerFile(file, fileAlias);
        return file;
    }

    clear() {
        if (this.$container) {
            this.$container.empty();
        }
        this.$container = null;
        this._eventPack.removeAllListeners();
    }

    _registerFile(file, fileUniqueIdentifier) {
        const fileInfo = {
            file,
            fileUniqueIdentifier,
        };
        this._unregisterFile(fileInfo);
        this.fileInfos.push(fileInfo);
        return fileInfo;
    }

    getFiles() {
        return _.map(this.fileInfos, 'fileUniqueIdentifier');
    }

    _unregisterFile(fileInfo) {
        if (fileInfo) {
            this.fileInfos = _.reject(this.fileInfos, {fileUniqueIdentifier: fileInfo.fileUniqueIdentifier});
        }
    }

    isUploadingFile() {
        return this.uploadingFiles.length > 0;
    }

    _registerErrorFile(file) {
        this._unregisterErrorFile(file);
        this.errorFiles.push(file);
    }

    _unregisterErrorFile(file) {
        const idx = this.errorFiles.indexOf(file);
        if (idx > -1) {
            this.errorFiles.splice(idx, 1);
        }
    }

    _registerUploadingFile(file) {
        this._unregisterUploadingFile(file);
        this.uploadingFiles.push(file);
        this.emit('uploading');
    }

    _unregisterUploadingFile(file) {
        const idx = this.uploadingFiles.indexOf(file);
        if (idx > -1) {
            this.uploadingFiles.splice(idx, 1);
            if (this.uploadingFiles.length == 0) {
                this.emit('uploads-finished');
            }
        }
    }

    setUploadFinishedCallback(onUploadFinishedCallback) {
        this._onUploadFinishedCallback = onUploadFinishedCallback;
    }

    _onErrorFile(file, errMessage) {
        this._unregisterUploadingFile(file);
        this._registerErrorFile(file);
        if (!this.isUploadingFile() && this._onUploadFinishedCallback) {
            this._onUploadFinishedCallback(errMessage);
        }
    }

    _onFileUploaded(file, response) {
        this._registerFile(file, this._getFileUniqueIdentifier(response, file));
        this._unregisterUploadingFile(file);
        if (!this.isUploadingFile() && this._onUploadFinishedCallback) {
            this._onUploadFinishedCallback();
        }
    }

    _onRemovedFile(file) {
        this._unregisterErrorFile(file);
        const fileInfo = this._getFileInfoByFile(file);
        this._unregisterFile(fileInfo);
        this._updateValidation();
    }

    _updateValidation() {
        if (!this.isValidationInhibited) {
            const {
                $container,
                $hiddenInput,
            } = this;
            if ($hiddenInput) {
                //update value of hidden input for validation
                const text = new Date().toDateString();
                $hiddenInput.val(text);
            }
            if ($container) {
                const $form = $container.find('form');
                if ($form.bootstrapValidator) { // when the file uploader is used without BootstrapValidator
                    $form.bootstrapValidator('revalidateField', this.myOptions.name);
                }
            }
            this.emit('filesChanged', this.getFiles());
        }
    }

    _checkRequired() {
        return !(this.myOptions.required && this.fileInfos.length == 0);
    }

    _checkError() {
        return this.errorFiles.length == 0;
    }

    _getFileInfoByFile(file) {
        return _.find(this.fileInfos, fileInfo => fileInfo.file.name == file.name);
    }

    _onAddedFile(file) {
        this._registerUploadingFile(file);
        if (this.myOptions.maxFiles == 1) {
            this._withoutValidation(() => {
                _.each(this.fileInfos, fileInfo => {
                    this._removeFile(fileInfo.file);
                });
                this.fileInfos = [];
                _.each(this.errorFiles, file => {
                    this._removeFile(file);
                });
                this.errorFiles = [];
            });
        }
    }

    _removeFile(file) {
        this.myDropzone.removeFile(file);
    }

    _withoutValidation(func) {
        const wasValidationInhibited = this.isValidationInhibited;
        this.isValidationInhibited = true;
        func();
        this.isValidationInhibited = wasValidationInhibited;
    }

    _getAcceptedFiles() {
        return null; // default value to accept all file types
    }

    _getTranslationKey() {
        return undefined;
    }

    _getFileDownloadUrl() {
        throw new Error('Must be implemented by child');
    }

    _getUploadServerUrl() {
        throw new Error('Must be implemented by child');
    }

    enable() {
        this.myDropzone.enable();
    }

    disable() {
        this.myDropzone.disable();
    }

    _getFileUniqueIdentifier() {
        throw new Error('Must be implemented by child');
    }

    _shouldDisplayConfirmation() {
        // keep default dropzone behaviour
        return true;
    }
};

function getTranslations(key, multiple) {
    const translations = _.omit(translate(`fileUploader.dropzone.${key}`, {returnObjectTrees: true}), MULTIPLE_TRANSLATION_KEY);
    const multipleTranslations = translate(`fileUploader.dropzone.${key}.${MULTIPLE_TRANSLATION_KEY}`, {returnObjectTrees: true});
    if (multiple) {
        _.extend(translations, multipleTranslations);
    }
    return translations;
}
