const _ = require('lodash');
const $ = require('jquery');
const VueView = require('../vue/VueView');

module.exports = class FooterBaseView extends VueView {
    show(options, vueOptions) {
        this.options = options;
        this.$container = this.$container || _.get(options, '$container') || $('.mainPageFooter');
        super.show(options, _.extend({
            name: 'Footer',
        }, vueOptions));
    }

    hide(...args) {
        this.$container = null;
        super.hide(...args);
    }

    update(options = {}) {
        this.hide();
        this.show(options);
    }
};
