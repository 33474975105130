const _ = require('lodash');

const Zone = require('../Zone');

module.exports = class SearchZone extends Zone {
    constructor(zoneData) {
        const geometries = _.map(zoneData, 'geometry');
        const ids = _.map(_.map(zoneData, 'id'), function (id) {
            return _.isString(id) ? id : id.type + '-' + id.id;
        });
        super(_.zipObject(ids, geometries));
        this.ids = ids;
        this.names = _.map(zoneData, 'name');
        this.postalCodes = _.map(zoneData, 'postalCodes');
        this.ref = _.map(zoneData, 'ref');
        if (!geometries || geometries.length === 0) {
            console.warn('Zone #' + this.ids + ' ' + this.names + ' has no geometry.');
        }
    }
};
