const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const {
    formatEnergyValueAndClassification,
    isEnergyPerformanceClassificationNotSubmittedOrUndefined,
} = require('../helper/energyDiagnosticsHelper');
const dpeOrGesObjectValidator = require('../dpeOrGesObjectValidator');
const VALID_ENERGY_DIAGNOSTIC_CLASSIFICATIONS = require('../helper/validEnergyDiagnosticClassifications');

// @vue/component
module.exports = {
    props: {
        dpe: {
            type: Object,
            required: true,
            validator: dpeOrGesObjectValidator,
        },
        ges: {
            type: Object,
            required: true,
            validator: dpeOrGesObjectValidator,
        },
        hasNoLabelForDpeAndGes: Boolean,
        expanded: Boolean,
    },
    computed: {
        labelExpandedClass() {
            return {'label-expanded': this.expanded};
        },
    },
    methods: {
        invalidOrMissingClassificationLabel(classification) {
            if (this.hasNoLabelForDpeAndGes) {
                return this.labelClassification('missing');
            } else {
                switch (classification) {
                    case 'NS':
                        return this.labelClassification('NS');
                    case 'VI':
                        return this.labelClassification('VI');
                    default:
                        return this.labelClassification('missing');
                }
            }
        },
        labelClassification(path) {
            return translate(`enum.classification.${path}`);
        },
        notSubmittedOrNullClassification(classification) {
            return isEnergyPerformanceClassificationNotSubmittedOrUndefined(classification);
        },
        dataConfiguration(classification) {
            const {dpe, ges} = this;
            const data = _.map(VALID_ENERGY_DIAGNOSTIC_CLASSIFICATIONS, classification => {
                return {
                    classification,
                };
            });
            const activeClassificationData = _.find(data, {classification});
            _.extend(activeClassificationData, {
                active: true,
                dpeValue: formatEnergyValueAndClassification(dpe).value,
                gesValue: formatEnergyValueAndClassification(ges).value,
            });
            return data;
        },
        isExpandedOrActive(active) {
            return this.expanded || active;
        },
    },
};
