const LocalStorage = require('./LocalStorage');
const MapHelper = require('../MapHelper');
const {
    camera: {
        '3d': DEFAULT_3D,
    },
} = require('../../../common/DefaultConfiguration');

const MAP_3D_PREFERENCE_KEY = 'map3dPreference';

module.exports = {
    save3dPreferenceFromMapTypeId,
    get3dPreference,
};

function save3dPreferenceFromMapTypeId(mapTypeId) {
    const is3d = MapHelper.isMapType3d(mapTypeId);
    if (is3d === DEFAULT_3D) {
        LocalStorage.remove(MAP_3D_PREFERENCE_KEY);
    } else {
        LocalStorage.setValue(MAP_3D_PREFERENCE_KEY, is3d);
    }
}

function get3dPreference() {
    return LocalStorage.getValue(MAP_3D_PREFERENCE_KEY);
}
