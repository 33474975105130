const _ = require('lodash');
const Pages = require('../pages/Pages');
const RealEstateAdModificationView = require('./RealEstateAdModificationView');
const ApplicationPage = require('../pages/ApplicationPage');
const PageManager = require('../pages/PageManager');
const MapApi = require('../MapApi');
const GoogleTagManager = require('../stats/GoogleTagManager');
const Account = require('../authentication/Account');
const EventPack = require('../utils/EventPack');
const AdModificationForbiddenAccessPage = require('../errorPages/AdModificationForbiddenAccessPage');
const {isKartoEnabled} = require('../utils/Karto');

module.exports = class RealEstateAdModificationPage extends ApplicationPage {
    constructor(config) {
        const configuration = _.extend(config, {
            name: 'realEstateAdModification',
            bodyClass: 'realEstateAdModificationPage',
            title: 'Modifier une annonce',
            isRegistrationRequired: true,
        });
        super(configuration);
        this.configuration = configuration;
        this._eventPack = new EventPack();
    }

    open(options) {
        this.options = _.extend(options, this.configuration);
        super.open(options);
        this._bindModificationEvents();
        GoogleTagManager.sendEvent('editRealEstateAd', {
            realEstateAdId: options.currentRealEstateAdId,
            agencyId: Account.getAuthenticatedAccountId(), // for compatibility with old events
        });
    }

    _bindModificationEvents() {
        this._eventPack.on(this.realEstateAdModificationView, {
            realEstateAdNotFound: () => {
                PageManager.openPage(Pages.page404);
            },
            realEstateAdForbiddenAcces: () => {
                PageManager.openPage(new AdModificationForbiddenAccessPage());
            },
            adModificationFinished: options => {
                const openOptions = this.options;
                const {reopenOptions} = openOptions;
                PageManager.openPage(Pages.detailedSheet, _.defaults({}, options, reopenOptions, openOptions));
            },
        });
    }

    close(options) {
        this._eventPack.removeAllListeners();
        super.close(options);
    }

    getMiddleViews(options) {
        const middleView = new RealEstateAdModificationView(options);
        this.realEstateAdModificationView = middleView;
        return middleView;
    }

    parseUrl(url, id) {
        return {
            currentRealEstateAdId: id,
        };
    }

    getUrlPattern() {
        return '/modifier-une-annonce/{id}';
    }

    getUrl() {
        return '/modifier-une-annonce/' + this.options.currentRealEstateAdId;
    }

    _loadData(options, cb) {
        if (!isKartoEnabled('adModificationPage')) {
            MapApi.init(() => {
                cb(null, options);
            });
        } else {
            cb(null, options);
        }
    }
};
