const _ = require('lodash');
const {EventEmitter} = require('events');
const intersects = require('../common/intersects');

const EVENTS_NAMES = [
    'itemAddedOnInit',
    'beforeItemAdd',
    'itemAdded',
    'beforeItemRemove',
    'itemRemoved',
    'change',
];

const WRAPPER_EVENTS_NAMES = [
    'focusin',
    'focusout',
];

module.exports = class TagsInputWidget extends EventEmitter {
    constructor(options) {
        super();

        this.$input = options.$input;
        this.monoTagTypes = options.monoTagTypes || [];
        this.tagsInputOptions = {
            focusOnItemRemoved: options.focusOnItemRemoved,
            tagClass: options.tagClass || '',
            itemText: item => item.getText(),
            itemValue: item => item.getValue(),
            confirmKeys: [],
            maxChars: options.maxChars || 20,
            addOnBlur: false,
        };
    }

    init() {
        this._initTagsinput();
    }

    focus() {
        this.getTagsInput().focus();
    }

    blur() {
        const input = this.getTagsInput();
        if (input && input.length) {
            input.blur();
        }
    }

    disable(disabled) {
        this.getTagsInput().attr('disabled', disabled);
        this.$input.attr('disabled', disabled);
    }

    _setTagsInputValue(value) {
        return this.getTagsInput().val(value);
    }

    destroy() {
        return this._invokeTagsinputFn('destroy');
    }

    resetWidget() {
        this._setTagsInputValue('');
        this._removeAllTags();
    }

    getTagsInput() {
        return this._invokeTagsinputFn('input');
    }

    getInputValue() {
        return this.getTagsInput().val();
    }

    _getItems() {
        return this._invokeTagsinputFn('items');
    }

    getTagsItems() {
        return _.invokeMap(this._getItems(), 'getItem');
    }

    getTagsLocations() {
        return _.clone(this._getItems());
    }

    getTagsTypes() {
        return _.invokeMap(this._getItems(), 'getType');
    }

    getTagsZoneIds() {
        return _(this._getItems())
            .invokeMap('getZoneInfoIds')
            .flatten()
            .compact()
            .value();
    }

    getTagsZoneInfos() {
        return _(this._getItems())
            .invokeMap('getZoneInfos')
            .flatten()
            .compact()
            .value();
    }

    onSubmitFns() {
        return _.compact(_.invokeMap(this._getItems(), 'getOnSubmitFn'));
    }

    hasTags() {
        return this._getItems().length > 0;
    }

    hasTagsTypes(types) {
        return intersects(_.flatten([types]), this.getTagsTypes());
    }

    _hasMonoTagsTypes() {
        return this.hasTagsTypes(this.monoTagTypes);
    }

    isMonoTagItem(item) {
        return _.includes(this.monoTagTypes, item.getType());
    }

    addTag(item) {
        this._invokeTagsinputFn('add', item);
    }

    _removeTag(item) {
        this._invokeTagsinputFn('remove', item);
    }

    _removeAllTags() {
        _.each(this.getTagsLocations(), _.bind(this._removeTag, this));
    }

    _onBeforeItemRemove(event) {
        const item = event.item;
        if (this.isMonoTagItem(item)) {
            this._toggleTagsInput(true);
        }
        if (item.onBeforeRemove) {
            item.onBeforeRemove();
        }
    }

    _onBeforeItemAdd(event) {
        const item = event.item;
        if (item.canBeAdded()) {
            this._removeMonoTags(item);
            if (this.isMonoTagItem(item)) {
                this._toggleTagsInput(false);
            }
        } else {
            event.cancel = true;
        }
    }

    _toggleTagsInput(isVisible) {
        const $tagsInput = this.getTagsInput();
        $tagsInput.toggle(isVisible);
    }

    _removeMonoTags(item) {
        const isMonoTagItem = this.isMonoTagItem(item);
        if (isMonoTagItem || this._hasMonoTagsTypes()) {
            this._removeAllTags();
        }
    }

    _initTagsinput() {
        this._invokeTagsinputFn(this.tagsInputOptions);
        this._bindEvents();
    }

    _bindEvents() {
        _.each(EVENTS_NAMES, eventName => {
            this.$input.on(eventName, (...args) => {
                if (eventName == 'beforeItemAdd') {
                    this._onBeforeItemAdd(...args);
                } else if (eventName == 'beforeItemRemove') {
                    this._onBeforeItemRemove(...args);
                }
                this.emit(eventName, ...args);
            });
        });
        this._bindWrapperEvents();
    }

    _bindWrapperEvents() {
        _.each(WRAPPER_EVENTS_NAMES, eventName => {
            this.getTagsInputWrapper().on(eventName, _.bind(this.emit, this, eventName));
        });
    }

    getTagsInputWrapper() {
        return this._invokeTagsinputFn('findInputWrapper');
    }

    _invokeTagsinputFn(...args) {
        return this.$input.tagsinput(...args);
    }
};
