const _ = require('lodash');

const CityLocationItem = require('./CityLocationItem');

module.exports = class NeighborhoodLocationItem extends CityLocationItem {
    getValue() {
        const cityValue = super.getValue();
        return `${cityValue} (${this._item.libelle})`;
    }

    getText() {
        const cityText = super.getText();
        return `${cityText} \u2013 ${this._item.libelle}`; // U+2013 : EN DASH
    }

    getUrlValue() {
        const cityUrlValue = super.getUrlValue();
        return `${cityUrlValue} - ${this._item.libelle}`;
    }

    getZoneInfos() {
        const zoneInfos = super.getZoneInfos();
        return _.extend(zoneInfos, {id: this.getZoneInfoIds()});
    }

    getZoneInfoIds() {
        return this._item._id;
    }

    getTypeForServerRequest() {
        return this.getType();
    }

    getItemInseeCode() {
        return this._item.city.insee_code;
    }
};
