const SelectPickerField = require('./SelectPickerField');
const _ = require('lodash');

module.exports = class FilterTypeSelectPickerField extends SelectPickerField {
    constructor(...args) {
        super(...args);
        this._buyNewAllowed = this.$element.get(0).hasAttribute('data-buyNewAllowed');
    }

    convertToSearch(filters) {
        let fieldValues = filters.filterType;
        if (fieldValues) {
            fieldValues = _.castArray(fieldValues);
        }
        const buyNewIndex = _.indexOf(fieldValues, 'buyNew');
        if (buyNewIndex >= 0) {
            filters.buyNewPropertySetInFilterType = true;
            filters.newProperty = true;
            fieldValues[buyNewIndex] = 'buy';
            filters.filterType = fieldValues;
        }
    }

    convertFromSearch(search) {
        let filterTypes = _.castArray(search.filterType);
        if (this._buyNewAllowed && search.newProperty && _.isEqual(filterTypes, ['buy'])) {
            filterTypes = ['buyNew'];
        }
        search.filterType = filterTypes;
    }
};
