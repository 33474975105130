const {ResizeObserver} = require('@juggle/resize-observer');
const MapCreation = require('../../utils/MapCreation');
const template = require('./Map.jade');

// @vue/component
module.exports = {
    props: {
        options: {
            type: Object,
            default: null,
        },
        advancedOptions: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        MapCreation.createSimpleMap(this.$refs.map, this.options, this.advancedOptions, map => {
            this.map = map;
            this.$emit('created', map);
            this.resizeMap();
        });
        const observer = this.observer = new ResizeObserver(this.resizeMap);
        observer.observe(this.$el);
    },
    destroyed() {
        this.observer.disconnect();
    },
    methods: {
        resizeMap() {
            const {map} = this;
            if (map) {
                map.emit('resize');
            }
        },
    },
    template: template(),
};
