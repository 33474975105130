const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./NewsletterLegalMentions.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            keys: [
                'shortMention',
                'longMention',
            ],
        }),
        require('./newsletterTranslateMixin'),
    ],
    template: template(),
};
