module.exports = {
    getConf,
};

const ARTICLE_URL_PREFIX = '/article/';
const IMPROVE_MY_ADS_ARTICLE_URL = ARTICLE_URL_PREFIX + 'ameliorer-mes-annonces-sur-bienici';

function getConf() {
    return {
        improvableConf: {
            needProfessionalPict: {
                blogUrl: ARTICLE_URL_PREFIX + 'des-reportages-photos-de-qualite-professionnelle-a-des-tarifs-tres-avantageux',
                iconClass: 'fa-camera',
            },
            needVirtualTour: {
                blogUrl: ARTICLE_URL_PREFIX + 'decidez-vos-prospects-avec-les-solutions-de-visites-360-denvisite',
                iconClass: 'fa-arrows',
            },
            needHomeStaging: {
                blogUrl: ARTICLE_URL_PREFIX + 'le-home-staging-3d-simplifie-avec-rhinov',
                iconClass: 'fa-home',
            },
        },
        diagnosticConf: {
            hasNoPhoto: {
                blogUrl: IMPROVE_MY_ADS_ARTICLE_URL + '#photos_supp',
                iconClass: 'fa-camera',
            },
            hasNoAddress: {
                blogUrl: IMPROVE_MY_ADS_ARTICLE_URL + '#geolocalisation_annonce',
                iconClass: 'fa-map-marker',
            },
            hasNoPrice: {
                blogUrl: IMPROVE_MY_ADS_ARTICLE_URL + '#prix_annonce',
                iconClass: 'fa-eur',
            },
        },
    };
}
