const _ = require('lodash');

const template = require('../loanContactForm.jade');
const LoanContactFormView = require('../LoanContactFormView');

module.exports = class PtzFormContactView extends LoanContactFormView {
    constructor(configuration) {
        super(_.extend({
            name: 'PtzFormContactView',
            gtmEventName: 'PtzContactFormSubmitted',
        }, configuration));
        this.template = template;
    }
};
