const _ = require('lodash');
const $ = require('jquery');
const {EventEmitter} = require('events');

const SendWelcomeEmailView = require('../SendWelcomeEmailView');
const EventPack = require('../../utils/EventPack');

module.exports = class UserAdminActions extends EventEmitter {
    constructor({$element}) {
        super();
        this.$element = $element;
        this._sendWelcomeEmailView = new SendWelcomeEmailView();
    }

    bind() {
        this._eventsWhileShown = new EventPack();
        this._eventsWhileShown.on(this.$element, {
            click: {
                '.sendWelcomeEmail': _.bind(this._sendWelcomeEmail, this),
            },
        });
    }

    unbind() {
        this._eventsWhileShown.removeAllListeners();
        this._sendWelcomeEmailView.hide();
    }

    _sendWelcomeEmail(event) {
        const userId = getUserIdFromEvent(event);
        const userEmail = getDataFromEvent(event, 'userEmail');
        this._sendWelcomeEmailView.show({
            userId,
            userEmail,
        });
    }
};

function getUserIdFromEvent(event) {
    return getDataFromEvent(event, 'userId');
}

function getDataFromEvent(event, dataName) {
    return $(event.currentTarget).attr('data-' + dataName);
}
