const DateFormatter = require('../../../common/formatters/DateFormatter');

module.exports = {
    getDossierLabel,
};

function getDossierLabel(dossier) {
    const dossierDate = DateFormatter.humanizeDay(dossier.updatedAt) + ' ' + DateFormatter.humanizeTime(dossier.updatedAt);
    return `${dossier.title ? dossier.title : ''} ${dossier.complete ? dossierDate : ''}`;
}
