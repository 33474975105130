module.exports = {
    props: {
        info: {
            type: Object,
            required: true,
        },
    },
    computed: {
        classification() {
            return this.info.classification;
        },
        hasClassification() {
            const {classification} = this;
            return classification && classification.length == 1;
        },
        label() {
            return this.info.label;
        },
    },
};
