const _ = require('lodash');
const TRACKING_PREFIX = 'tracking-';
const DATA_TRACKING_PREFIX = 'data-tracking-';

// info: v-tracking directive is now available to set tracking class in Vue
const actions = [
    // agencies:
    'agenciesToContact',
    'contactAgencies',
    // alerts:
    'createAlert',
    'updateAlert-criteria',
    'updateAlert-frequency',
    // save search banner:
    'saveSearchBannerClicked',
    'saveSearchBannerDisplayed',
    //contact form:
    'submitCompactContactForm',
    'submitContactFormToGetContent',
    // documents interaction:
    'askBrochure',
    'askPlan',
    'openBrochure',
    'openDocument',
    'openPhoto',
    'openPlan',
    // virtual tours:
    'openVirtualTour',
    'openVirtualTour360',
    // satisfaction survey:
    'openSatisfactionSurveyModal',
    'closeSatisfactionSurveyModal',
    'dontShowSatisfactionSurveyModal',
];

module.exports = {
    TRACKING_PREFIX,
    DATA_TRACKING_PREFIX,
    TrackingClasses: _.zipObject(actions, _.map(actions, action => TRACKING_PREFIX + action)),
};
