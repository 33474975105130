const _ = require('lodash');
const GoogleTagManager = require('../stats/GoogleTagManager');
const Ajax = require('../utils/Ajax');
const ServerConfig = require('../ServerConfig');

module.exports = {
    onAdListDisplayed,
    onDetailedSheetOpened,
};

function onAdListDisplayed(adsInList) {
    const customerIdsToAdIds = {};
    _(adsInList)
        .filter('customerId')
        .filter('status.onTheMarket')
        .each(ad => {
            const {customerId, id: adId} = ad;
            if (!customerIdsToAdIds[customerId]) {
                customerIdsToAdIds[customerId] = [];
            }
            customerIdsToAdIds[customerId].push(adId);
        });
    fetchAgenciesAndSendEvent(customerIdsToAdIds);
}

function onDetailedSheetOpened(realEstateAd) {
    const {customerId, id: adId} = realEstateAd;
    if (customerId) {
        fetchAgenciesAndSendEvent({
            [customerId]: [adId],
        });
    }
}

function fetchAgenciesAndSendEvent(customerIdsToAdIds) {
    const customerIds = _.keys(customerIdsToAdIds);
    if (!_.isEmpty(customerIds)) {
        fetchAudienceTargetingProductsEligibleAgencies(customerIds, ['emailTargeting', 'displayTargeting'],
            (err, agenciesPerOptions) => {
                if (err) {
                    console.warn(err, 'Could not fetch agencies for audience targeting');
                } else {
                    _.each(agenciesPerOptions, (customerIds, option) => {
                        const audienceCustomerIdsToAdIds = _(customerIds)
                            .mapKeys()
                            .mapValues(customerId => customerIdsToAdIds[customerId])
                            .value();
                        if (!_.isEmpty(audienceCustomerIdsToAdIds)) {
                            GoogleTagManager.sendEvent(`audienceTargeting-${option}-agencies`, {
                                customerIdsToAdIds: audienceCustomerIdsToAdIds,
                            });
                        }
                    });
                }
            }
        );
    }
}

function fetchAudienceTargetingProductsEligibleAgencies(customerIds, options, cb) {
    Ajax.request({
        url: ServerConfig.config.accountUrl + '/accounts/audienceTargetingProducts/eligibleIds',
        data: {ids: customerIds, options},
        disableErrorPage: true,
        callback: cb,
    });
}
