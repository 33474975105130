const _ = require('lodash');

const template = require('./EstimationResults.jade');
const {
    ESTIMATOR_TRANSLATION_KEY_PREFIX,
    LOADING_STEPS_COUNT,
    LOADING_STEP_DURATION_IN_MS,
    ESTIMATOR_STORE_MODULE_NAME,
} = require('../../Constants');
const CommonStepMixin = require('../../components/mixins/CommonStepMixin');
const RealEstateAdTitleGenerator = require('../../../../common/RealEstateAdTitleGenerator');

const ADDRESS_NOT_FOUND_ERR = 'addressNotFound';

// @vue/component
module.exports = {
    mixins: [
        CommonStepMixin(ESTIMATOR_STORE_MODULE_NAME),
    ],
    constants: {
        ADDRESS_NOT_FOUND_ERR,
    },
    computed: {
        propertySummary() {
            return RealEstateAdTitleGenerator.getTitle(_.extend({}, this.realEstateProperty, this.flatType), 'estimationResults');
        },
        errorMessage() {
            return this.t([
                ESTIMATOR_TRANSLATION_KEY_PREFIX + `error.${this.error}`,
                ESTIMATOR_TRANSLATION_KEY_PREFIX + 'error.defaultMessage',
            ], {address: this.realEstateProperty_address});
        },
        getContainerClass() {
            return {
                'estimate-result': !this.loading,
                error: this.error,
            };
        },
        loadingSteps() {
            const context = `${this.transactionType}_${this.context}`;
            return _(_.range(1, LOADING_STEPS_COUNT + 1))
                .map(stepIndex => [
                    stepIndex * LOADING_STEP_DURATION_IN_MS,
                    this.realEstatePropertyDescriptorTranslate('loading.step' + stepIndex, {context}),
                ])
                .fromPairs()
                .value();
        },
    },
    methods: {
        handleLoadingDone() {
            this.commitToStore('loading', false);
        },
    },
    template: template(),
};
