const _ = require('lodash');
const PolygonZIndex = require('./utils/PolygonZIndex');

const Zone = require('./Zone');

const defaultDisplayOptions = {
    strokeOpacity: 1,
    fillColor: '#ffb82f',
    strokeColor: '#ffb82f',
    strokeWeight: 1,
    fillOpacity: 0.3,
    isHole: false,
    zIndex: PolygonZIndex.zone,
};

module.exports = class NeighborhoodZone extends Zone {
    constructor(zoneData, displayOptions) {
        displayOptions = displayOptions || defaultDisplayOptions;
        super({neighborhood: zoneData.geometry}, displayOptions);
        _.extend(this, zoneData);
    }
};
