const ServerConfig = require('./ServerConfig');
const {getImageUrlFromAliasAndFileUrl, getFileUrlFromAliasAndFileUrl} = require('../common/FileServerUrlGenerator');
const ACCEPTED_FILES = {
    pdf: '.pdf',
    image: 'image/*',
};
const TRANSLATION_KEYS = {
    pdf: 'pdf',
    image: 'image',
};

module.exports = {
    getImageUrlFromAlias,
    getFileUrlFromAlias,
    ACCEPTED_FILES,
    TRANSLATION_KEYS,
};

function getImageUrlFromAlias(filename, parameters) {
    return getImageUrlFromAliasAndFileUrl(ServerConfig.config.fileUrl, filename, parameters);
}

function getFileUrlFromAlias(filename, parameters) {
    return getFileUrlFromAliasAndFileUrl(ServerConfig.config.fileUrl, filename, parameters);
}
