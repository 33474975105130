const _ = require('lodash');
const SearchPageBaseFactory = require('./SearchPageBaseFactory');
const MyRealEstateAdsPage = require('../../pages/MyRealEstateAdsPage');
const SearchUrlPrefixes = require('../../../common/SearchUrlPrefixes');
const ApplicationConfig = require('../../app/ApplicationConfig');
const Urls = require('../../Urls');
const DefaultConfiguration = require('../../../common/DefaultConfiguration');

module.exports = class MyRealEstateAdsPageFactory extends SearchPageBaseFactory {
    constructor(pageConfiguration) {
        super(_.defaults(pageConfiguration, {
            loadOptions: {
                requestName: 'Load my ads',
                urlSuffix: 'myads',
                filters: {
                    isAuthor: true,
                },
                includeAccountIdInQueries: true,
                leadingCount: 0,
            },
            searchDefaults: _.defaults({
                sortBy: ApplicationConfig.applicationPro ? 'contactRequests' : 'publicationDate',
            }, DefaultConfiguration.userRealEstateAdsBaseSearch),
            urlPrefix: SearchUrlPrefixes.myRealEstateAds,
        }));
    }

    createPage() {
        return new MyRealEstateAdsPage(this._pageConfiguration);
    }

    getPageUrl() {
        const searchDefaults = this._pageConfiguration.searchDefaults;
        //TODO remove _.clone when Urls.search.makeUrl will not modify its second parameter anymore
        return Urls.search.makeUrl(searchDefaults, _.clone(searchDefaults), this._urlPrefixPattern);
    }

    getRealEstateAdsLoadingOptionsWithCountBySearchCriterion(callback) {
        const pageConfiguration = this._pageConfiguration;
        const loadOptions = _.assignIn({}, pageConfiguration.loadOptions, {
            callback,
            enableAggregates: true,
        });
        loadOptions.filters = _.assignIn({}, loadOptions.filters, pageConfiguration.searchDefaults, {
            size: 0,
            resultsPerPage: 0,
        });
        return loadOptions;
    }
};
