const $ = require('jquery');
const template = require('../templates/searchListModeSelectionView.jade');
const View = require('../views/View');
const _ = require('lodash');

module.exports = class SearchListModeSelectionView extends View {
    constructor(options) {
        super({
            template,
        });
        this.searchListModeModel = options.searchListModeModel;
        this.modeListener = _.bind(this.displayInSelectedMode, this);
        this.mapModeAvailableListener = _.bind(this.updateMapModeButton, this);
    }

    show() {
        View.prototype.show.apply(this, arguments);
        _.each(this.$element.find('[data-mode]'), button => {
            const $button = $(button);
            const mode = $button.attr('data-mode');
            $button.on('click', _.bind(this.handleSelectMode, this, mode));
        });
        this.updateMapModeButton();
        this.displayInSelectedMode();
        this.searchListModeModel.on('mode', this.modeListener);
        this.searchListModeModel.on('mapModeAvailable', this.mapModeAvailableListener);
    }

    hide() {
        this.searchListModeModel.removeListener('mapModeAvailable', this.mapModeAvailableListener);
        this.searchListModeModel.removeListener('mode', this.modeListener);
        View.prototype.hide.apply(this, arguments);
    }

    updateMapModeButton() {
        const mapModeAvailable = this.searchListModeModel.getMapModeAvailable();
        this.$element.find('.mapResults').toggle(Boolean(mapModeAvailable));
    }

    displayInSelectedMode() {
        const selectedMode = this.searchListModeModel.getMode();
        _.each(this.$element.find('[data-mode]'), function (el) {
            const $el = $(el);
            const mode = $el.attr('data-mode');
            $el.toggleClass('active', mode == selectedMode);
        });
    }

    handleSelectMode(mode) {
        const {$element} = this;
        //immediate visual feedback
        $element.find('[data-mode].active').removeClass('active');
        $element.find('[data-mode="' + mode + '"]').addClass('active');
        this.emit('selectMode', mode);
    }
};
