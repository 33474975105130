const {i18n: {translate}} = require('fack');
const template = require('./PricesResult.jade');

// @vue/component
module.exports = {
    props: {
        price: {
            type: Number,
            default: undefined,
        },
        minPrice: {
            type: Number,
            default: undefined,
        },
        maxPrice: {
            type: Number,
            default: undefined,
        },
        surfaceArea: {
            type: Number,
            default: undefined,
        },
        transactionType: {
            type: String,
            required: true,
        },
    },
    computed: {
        estimationTitle() {
            return this.resultTranslate('title');
        },
        estimationRangePrice() {
            return this.resultTranslate('rangePrice');
        },
    },
    methods: {
        resultTranslate(key) {
            return translate(`realEstateEstimator.results.${key}`, {context: this.transactionType});
        },
    },
    template: template(),
};
