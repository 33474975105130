const template = require('./CookiesManagerOpener.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {onDidomiReady} = require('../DidomiHelper');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'cmp.CookiesManagerOpener.',
            keys: ['title'],
        }),
    ],
    methods: {
        openCookiesManager() {
            onDidomiReady(function (Didomi) {
                Didomi.preferences.show();
            });
        },
    },
    template: template(),
};
