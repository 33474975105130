// @vue/component
module.exports = {
    mixins: [
        require('./mixins/configMultiselectRadioButton'),
    ],
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            options: [
                {label: 'Standard', value: 'standard'},
                {label: 'Ouverte', value: 'open-plan-kitchen'},
                {label: 'Kitchenette', value: 'kitchenette'},
            ],
        };
    },
};
