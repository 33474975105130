const _ = require('lodash');
const Account = require('../authentication/Account');
const RealEstateAdCreationModificationView = require('./RealEstateAdCreationModificationView');

/**
 * Methods to implement:
 * - handleAccountChange()
 */
module.exports = class RealEstateAdCreationBaseView extends RealEstateAdCreationModificationView {
    constructor(options) {
        super(_.extend({
            isCreation: true,
        }, options));
        this.accountChangeListener = _.bind(this.handleAccountChange, this);
    }

    show(options) {
        super.show(options);
        Account.on('change', this.accountChangeListener);
    }

    hide() {
        Account.removeListener('change', this.accountChangeListener);
        super.hide();
    }
};
