const template = require('./AdShareButton.jade');
const ShareButton = require('../../../vue/components/ShareButton/ShareButton');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const GoogleTagManager = require('../../../stats/GoogleTagManager');
const {getShareContent} = require('../../../AdSharingHelper');

// @vue/component
module.exports = {
    components: {
        ShareButton,
    },
    mixins: [
        i18nMixin({
            prefix: 'AdShareButton.',
            keys: [
                'title',
                'nativeShareTitle',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sharedData() {
            return getShareContent(this.realEstateAd);
        },
    },
    methods: {
        onShareInterfaceOpened() {
            GoogleTagManager.sendRealEstateAdShareEvent('native', this.realEstateAd);
        },
    },
    template: template(),
};
