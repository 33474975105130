const _ = require('lodash');
const realEstateEstimatorTemplate = require('./RealEstateEstimator.jade');
const {
    INITIAL_STEP,
    ESTIMATOR_STORE_MODULE_NAME,
} = require('../../Constants');
const {sendEstimation} = require('./../sendEstimation');
const AddressInput = require('../../components/AddressInput');
const RealEstateStepper = require('../../components/RealEstateStepper');
const RealEstateEstimatorMainDescriptor = require('./RealEstateEstimatorMainDescriptor');
const RealEstateEstimatorSecondaryDescriptor = require('./RealEstateEstimatorSecondaryDescriptor');
const RealEstateContactForm = require('../../components/RealEstateContactForm');
const EstimationResults = require('./EstimationResults');
const sendEstimationEmails = require('../sendEstimationEmails');
const Views = require('../../../views/Views');

module.exports = {
    components: {
        RealEstateStepper: RealEstateStepper(ESTIMATOR_STORE_MODULE_NAME),
        AddressInput: AddressInput(ESTIMATOR_STORE_MODULE_NAME),
        RealEstateEstimatorMainDescriptor,
        RealEstateEstimatorSecondaryDescriptor,
        RealEstateContactForm: RealEstateContactForm(ESTIMATOR_STORE_MODULE_NAME),
        EstimationResults,
    },
    extends: require('../../components/RealEstatePropertyDescriptor'),
    mixins: [
        require('../../components/mixins/CommonStepMixin')(ESTIMATOR_STORE_MODULE_NAME),
    ],
    constants: {
        INITIAL_STEP,
    },
    data() {
        return {
            suggestedAgenciesStep: 3,
            resultStep: 4,
            sendingEmailsInProgress: false,
            checkedAgenciesIdsToContact: [],
            hasSentEmailToAgencies: false,
            isNewEstimation: false,
        };
    },
    computed: {
        getRealEstateEstimatorContainerClass() {
            return {
                'is-in-real-estate-property-form': this.step > INITIAL_STEP && this.step < this.resultStep,
                'is-loading': this.loading,
                'is-landing': this.step == INITIAL_STEP,
                'is-landing-flat': this.step === INITIAL_STEP && this.propertyTypePreselection === 'flat',
                'is-landing-house': this.step === INITIAL_STEP && this.propertyTypePreselection === 'house',
                'show-result': this.step === this.resultStep && !this.loading,
            };
        },
        hasToShowAgenciesBlock() {
            const {isBuy, step, resultStep, loading, error} = this;
            return isBuy
                && step === resultStep
                && !loading
                && !error;
        },
        hasToShowCheckableAgenciesToContact() {
            return this.hasAgenciesToContact && !this.hasSentEmailToAgencies;
        },
        canSelectAgenciesToContact() {
            const {isBuy, sender_intention: intention} = this;
            return isBuy && (intention !== 'sell') && this.hasAgenciesToContact;
        },
        hasContactedAgencies() {
            return !_.isEmpty(this.contactedAgencies);
        },
        hasCheckedAtLeastOneAgency() {
            return !_.isEmpty(this.checkedAgenciesIdsToContact);
        },
        contactedAgencies() {
            const {canSelectAgenciesToContact, agenciesToContactForDisplay, checkedAgenciesIdsToContact} = this;
            if (canSelectAgenciesToContact) {
                return _.filter(agenciesToContactForDisplay, ({id}) => _.includes(checkedAgenciesIdsToContact, id));
            } else {
                return agenciesToContactForDisplay;
            }
        },
    },
    watch: {
        propertyType(propertyType) {
            this.$emit('update:property-type', propertyType);
        },
    },
    methods: {
        newEstimation() {
            this.commitToStore('clearEstimation');
            this.isNewEstimation = true;
        },
        updateCheckedAgencyIds(checkedAgencyIds) {
            this.checkedAgenciesIdsToContact = checkedAgencyIds;
        },
        contactAgencies() {
            const {checkedAgenciesIdsToContact, results: {id: estimationId}} = this;
            this.sendingEmailsInProgress = true;
            sendEstimationEmails({
                accountIds: checkedAgenciesIdsToContact,
                estimationId,
            }, (err) => {
                if (!err || err.name === 'EmailAlreadySentError') {
                    this.hasSentEmailToAgencies = true;
                } else {
                    Views.volatileFeedback.showError();
                }
                this.sendingEmailsInProgress = false;
            });
        },
        sendForm() {
            this.commitToStore('loading', true);
            this.resultLoaded = false;
            this.hasSentEmailToAgencies = false;
            const {
                realEstateProperty,
                sender,
                flatType,
                transactionType,
                canSelectAgenciesToContact,
                isBuy,
            } = this;
            const sendEstimationRequestToAgencies = !canSelectAgenciesToContact && isBuy;
            const estimationParameters = {
                realEstateProperty,
                flatType,
                sender,
                suggestedAgencyIds: this.getAgenciesToContactIds(),
                transactionType,
                sendEstimationRequestToAgencies,
            };
            sendEstimation(estimationParameters, (err, results) => {
                if (sendEstimationRequestToAgencies) {
                    this.hasSentEmailToAgencies = true;
                }
                this.resultLoaded = true;
                this.updateAccountInfos();
                if (err) {
                    this.error = 'defaultMessage';
                    if (_.isArray(err.error)) {
                        //fields validation error
                        console.warn(_.map(err.error, field => `${field.dataPath} ${field.message}`).join('\n'));
                    } else {
                        this.error = _.defaultTo(err.error, this.error);
                    }
                } else if (results.error) {
                    this.error = results.error;
                } else {
                    this.error = null;
                    this.results = results;
                    this.estimationDate = new Date();
                }
            });
        },
        suggestedAgenciesToContactText() {
            return this.realEstatePropertyDescriptorTranslate('suggestedAgenciesToContact', {
                count: _.size(this.agenciesToContact),
            });
        },
    },
    template: realEstateEstimatorTemplate(),
};
