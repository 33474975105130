const {i18n: {translate}} = require('fack');
const _ = require('lodash');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/configMultiselectRadioButton'),
    ],
    props: {
        value: {
            type: Boolean,
            default: null,
        },
        name: {
            type: String,
            default: () => _.uniqueId('boolean-radio-group-'),
        },
        compacted: Boolean,
    },
    computed: {
        options() {
            return _.map([true, false], value => ({
                label: translate(`enum.BooleanRadioGroup.${value}`),
                value,
            }));
        },
    },
};
