const _ = require('lodash');
const accountFormTranslate = require('../../../utils/accountForm/accountFormTranslate');
const arrayToObject = require('../../../utils/arrayToObject');
const template = require('./audienceTargetingProductsOption.jade');
const moment = require('../../../../common/momentFr');

const MONTHLY_VOLUME = 'monthlyVolume';
const MANUAL_MODE_PROPERTY_NAME = 'manualMode';

module.exports = ({
    monthlyVolumeStep,
    productOption,
    additionalOptionProperties,
    getAutofillStartDate,
}) => {
    const COMMON_PROPERTIES = [
        'startDate',
        'endDate',
        'trackingCode',
    ];
    const straightforwardProperties = _.concat(COMMON_PROPERTIES, additionalOptionProperties);
    if (monthlyVolumeStep) {
        straightforwardProperties.push(MONTHLY_VOLUME);
    }
    // @vue/component
    return {
        props: {
            value: {
                type: Object,
                default: undefined,
            },
            name: {
                type: String,
                required: true,
            },
        },
        data() {
            const data = _.extend({
                activated: Boolean(this.value),
            }, arrayToObject(straightforwardProperties, name => _.get(this.value, name)));
            if (monthlyVolumeStep && !data[MONTHLY_VOLUME]) {
                data[MONTHLY_VOLUME] = monthlyVolumeStep;
            }
            return data;
        },
        computed: {
            valueToEmit() {
                if (this.activated) {
                    return _(this) // even keys with undefined values are to be kept because of the comparison done in function 'removeAlreadySet'
                        .pick(straightforwardProperties)
                        .mapValues(value => {
                            return value === '' ? undefined : value;
                        })
                        .value();
                } else {
                    return undefined;
                }
            },
            requiresCostPerClickInput() {
                return _.includes(straightforwardProperties, 'costPerClick');
            },
            fieldSetTitle() {
                return accountFormTranslate(`audienceTargetingProducts.${productOption}.title`);
            },
            isMonthlyVolumesFieldVisible() {
                return _.includes(straightforwardProperties, MONTHLY_VOLUME);
            },
            monthlyVolumeStep() {
                return monthlyVolumeStep;
            },
            isManualModeAvailable() {
                return _.includes(straightforwardProperties, MANUAL_MODE_PROPERTY_NAME);
            },
            isManualModeEnabled() {
                return this[MANUAL_MODE_PROPERTY_NAME];
            },
            maxDate() {
                return moment().add(5, 'years').format('YYYY-MM-DD');
            },
            maxDateOfStartDate() {
                const {endDate, maxDate} = this;
                return (!endDate || moment(maxDate).isBefore(endDate)) ? maxDate : endDate;
            },
            minDateOfEndDate() {
                const {startDate, maxDate} = this;
                return (!startDate || moment(maxDate).isBefore(startDate)) ? moment().format('YYYY-MM-DD') : startDate;
            },
        },
        watch: {
            value(value) {
                _.extend(
                    this,
                    {activated: Boolean(value)},
                    arrayToObject(straightforwardProperties, name => _.get(value, name))
                );
            },
            valueToEmit(value) {
                this.$emit('input', value);
            },
            activated(value) {
                if (value && getAutofillStartDate) {
                    this.startDate = getAutofillStartDate();
                }
            },
        },
        methods: {
            accountFormTranslate,
            getInputName(inputField) {
                return `v-audienceTargetingProducts.${productOption}.${inputField}`;
            },
            getInputLabel(inputField, context) {
                return this.accountFormTranslate([
                    `audienceTargetingProducts.${inputField}`,
                    `audienceTargetingProducts.${productOption}.${inputField}`,
                ], _.extend(context, {
                    monthlyVolumeStep,
                }));
            },
            dateWarningTranslate(key, options) {
                return this.accountFormTranslate('dateValidation.' + key, options);
            },
            numberFactorWarningTranslate(options) {
                return this.accountFormTranslate('numberFactorWarning', options);
            },
        },
        template: template(),
    };
};
