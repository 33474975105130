const _numberFormat = require('underscore.string/numberFormat');
let {i18n: {translate}} = require('fack');

module.exports = {
    init,
    formatNumber,
    formatNumberWithoutEmptyDecimals,
    formatRoundNumber,
    formatNumberWithUnit,
};

function init({t}) {
    translate = t;
}

function formatNumber(value, decimals) {
    return _numberFormat(+value, decimals, ',', '\u00A0');
}

function formatNumberWithoutEmptyDecimals(value, maxDecimals) {
    return formatNumber(value, maxDecimals).replace(/,0+$/, '');
}

function formatRoundNumber(value, unit) {
    let str;
    if (value >= 999000) {
        str = formatWithSuffix(value, 1e6, 'm', 2);
    } else if (value >= 9990) {
        str = formatWithSuffix(value, 1e3, 'k', 1);
    } else {
        str = formatNumber(Math.round(value), 0);
    }
    return formatNumberWithUnit(value, unit, str);
}

function formatWithSuffix(value, suffixValue, suffix, maxDigits) {
    const displayedValue = Math.round((value / suffixValue) * 100) / 100;
    return numberFormatWithoutTrailingDecimalZero(displayedValue, maxDigits) + suffix;
}

function numberFormatWithoutTrailingDecimalZero(value, maxDecimals) {
    const str = formatNumber(value, maxDecimals);
    return str
        .replace(/0+$/, '')
        .replace(/,$/, '');
}

function formatNumberWithUnit(value, unit, formattedValue) {
    formattedValue = formattedValue || formatNumber(value);
    const unitText = unit ? '\u00A0' + translate('enum.unit.' + unit, {
        count: value,
        defaultValue: unit,
        context: 'full',
    }) : '';
    return formattedValue + unitText;
}
