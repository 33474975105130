const $ = require('jquery');
const _ = require('lodash');
const template = require('./templates/options/optionsPopup.jade');
const Options = require('./Options');
const uuid = require('uuid/v1');
const FormUtils = require('./fields/FormUtils');
const homePageSlide = require('./data/homePageSlide');

module.exports = class OptionsPopup {
    constructor() {
    }

    showOptions() {
        // Create options form
        this.createForm();
        // Show modal
        this.$element.modal({
            keyboard: true,
        });
        this.$element.on('hide.bs.modal', () => {
            // Verify options saved before quitting
            if (this.optionsChanged()) {
                const result = confirm('Enregistrer les modifications avant de quitter ?');
                if (result) {
                    this.saveAndApplyOptions();
                }
            }
        });
        this.$element.on('hidden.bs.modal', () => {
            this.$element.remove();
            delete this.$element;
        });
    }

    createForm() {
        const options = Options.read();
        const homePageSlides = _.map(homePageSlide(), 'name');
        this.$element = $(template({
            uuid,
            homePageSlides,
        }));
        this.$form = this.$element.find('form');
        $('body').append(this.$element);
        FormUtils.init({
            $form: this.$form,
        });
        // Fill form values
        this.fillForm(options);
        const updateSaveButtonState = _.bind(this.updateSaveButtonState, this);
        updateSaveButtonState();
        _.each($('.option'), function (el) {
            const $el = $(el);
            $el.change(updateSaveButtonState);
        });
        this._readOnlyOptions = this.getReadOnlyOptions();
        const $reset = $('[data-action="reset"]');
        $reset.click((e) => {
            this.fillForm(Options.getDefaults());
            updateSaveButtonState();
            e.preventDefault(); // prevent submit event on last form button
        });
        const $save = $('[data-action="save"]');
        $save.click((e) => {
            e.preventDefault(); // prevent submit event on last form button
            this.saveAndApplyOptions();
        });
    }

    fillForm(options) {
        FormUtils.setValues(this.$form, options);
    }

    getFormOptions() {
        return FormUtils.readValues(this.$form);
    }

    optionsChanged() {
        return !_.isEqual(this.getFormOptions(), Options.read());
    }

    updateSaveButtonState() {
        const $save = $('[data-action="save"]');
        $save.attr('disabled', !this.optionsChanged());
    }

    getReadOnlyOptions() {
        const readOnlyOptions = [];
        FormUtils.eachInputWithName(this.$form, ($input, name) => {
            if ($input.is(':disabled')) {
                readOnlyOptions.push(name);
            }
        });
        return readOnlyOptions;
    }

    saveAndApplyOptions() {
        const formOptions = this.getFormOptions();
        const optionsToSave = _.omit(formOptions, this._readOnlyOptions);
        Options.save(optionsToSave, () => {
            location.reload();
        });
    }
};
