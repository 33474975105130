const _ = require('lodash');
const StaticFileResolver = require('./StaticFileResolver');

// /!\ new category types must be whitelisted in kimono-analytics server

const errors = module.exports = {

    /*
     un serveur renvoie une erreur dans data.error
     c'est une erreur du server
     */
    HTTP_SERVER_ERROR: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Comment, une erreur&nbsp;?',
        subtitle: "Malgré notre vigilance, une erreur s'est glissée entre les mailles du filet&nbsp;!",
        funnyText: "<a href=\"javascript:location.reload(true)\">Cliquez ici</a> pour chasser l'erreur.",
    },
    /*
     une requête renvoie une erreur 4xx
     c'est une erreur du client (bug)
     */
    HTTP_CLIENT_ERROR: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Comment, une erreur&nbsp;?',
        subtitle: "Malgré notre vigilance, une erreur s'est glissée entre les mailles du filet&nbsp;!",
        funnyText: "<a href=\"javascript:location.reload(true)\">Cliquez ici</a> pour chasser l'erreur.",
    },
    /*
     une requête renvoie une erreur 5xx
     c'est une erreur serveur (temporaire ou un bug)
     */
    HTTP_SERVER_DOWN: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Comment, une erreur serveur&nbsp;?',
        subtitle: "Ça, c'est une mission pour nos Super Développeurs&nbsp;!",
        funnyText: 'Buvez un jus de fruits, détendez-vous et '
        + '<a href="javascript:location.reload(true)">cliquez ici</a> pour ré-essayer.',
    },
    /*
     une requête timeout
     soit c'est un problème du serveur (surcharge)
     soit c'est la connexion de l'utilisateur qui est trop lente ou a été coupée
     */
    HTTP_TIMEOUT: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Aïe&nbsp;! La connexion a été interrompue.',
        subtitle: ' Soit vous avez perdu internet, soit nos serveurs sont dans les choux.',
        funnyText: '<a href="javascript:location.reload(true)">Cliquez ici</a> pour relancer la page.',
    },
    /*
     une requête a été coupée
     soit c'est un problème de serveur (redémarrage ou crash varnish)
     soit l'utilisateur n'as plus de réseau
     */
    HTTP_CLOSED: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Recherche connexion désespérément.',
        subtitle: 'Nos serveurs et votre appareil ont rompu leur relation à distance.',
        funnyText: '<a href="javascript:location.reload(true)">Cliquez ici</a> pour relancer la page.',
    },
    /*
     exception Javascript, causée :
     soit par un bug chez nous
     soit par une extension/un adware
     */
    JAVASCRIPT_EXCEPTION: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Aïe&nbsp;!!',
        subtitle: 'Une vilaine erreur est survenue&nbsp;!',
        funnyText: '<a href="javascript:location.reload(true)">Essayez à nouveau</a> '
        + 'ou tentez avec un autre navigateur&nbsp;! Si le problème persiste, contactez le support.',
    },
    /*
     On ne sait pas ...
     */
    UNKNOWN: {
        backgroundImage: StaticFileResolver.resolve('images/superman_full.jpg'),
        title: 'Une erreur&nbsp;? Vraiment&nbsp;?',
        subtitle: "Quelque chose a mal tourné, mais rien d'irréparable&nbsp;!",
        funnyText: '<a href="javascript:location.reload(true)">Cliquez ici</a> pour ré-essayer.',
    },
};

_.each(errors, (conf, key) => {
    conf.type = key.toLowerCase();
});
