const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const PageManager = require('./PageManager');

module.exports = class SideMapPage extends ApplicationPage {
    _setOpenPageOptions(options) {
        this._openPageOptions = options;
    }

    _openPage(page, options) {
        const searchView = this.searchView;
        const searchController = searchView || this.searchController;
        const mapController = searchView || this.mapController;
        const headerTitle = this._getBackTitle();
        const reopenState = _.invoke(searchView || searchController, 'getReopenState');
        const closePageUrl = _.invoke(this, '_getClosePageUrl') || this.getUrl();
        const openPageOptions = this._openPageOptions || {};
        const {ownedAccounts, showOwnedAccountSelection, appendReturnUrlToDetailedSheetUrl, author} = openPageOptions;
        PageManager.openPage(page, _.defaults({}, options, {
            reopen: options => PageManager.openPage(this, options),
            reopenOptions: _.extend({
                searchView,
                searchController,
                mapController,
            }, openPageOptions, reopenState),
            headerTitle,
            searchView,
            mapController,
            searchController,
            closePageUrl,
            hasSearchPageSplitScreen: mapController.getSplitScreenStatus(),
            doesWindowSupportSplitScreen: mapController.doesWindowSupportSplitScreen(),
            ownedAccounts,
            showOwnedAccountSelection,
            appendReturnUrlToDetailedSheetUrl,
            author,
        }));
    }

    _reopenPage(options) {
        const openPageOptions = this._openPageOptions;
        openPageOptions.reopen(_.defaults({}, options, openPageOptions.reopenOptions));
    }
};
