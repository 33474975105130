const _ = require('lodash');

const Cookies = require('./Cookies');
const getAbTestingOptions = require('./abTesting/getAbTestingOptions');

const AB_TESTING_TARGETS = [
    'contact',
];

const AB_TESTING_OPTIONS = {
    relatedAdsPosition: [
        'relatedAdsAfterDocumentationServices',
        'relatedAdsAfterDescription',
        'relatedAdsAfterSlideShow',
    ],
};

const COOKIE_NAME = 'AB-TESTING';
const OPTIONS_COOKIE_NAME = 'kimonoOptions';
let savedOptions = {};

module.exports = {
    init,
    getSavedOptions,
    getSavedOptionsForContact,
    getSavedOptionsForEstimator,
    saveAbTestingOptionsInCookie,
    overrideDefaultValuesByEtcdValues,
};

function init() {
    if (_.isEmpty(AB_TESTING_OPTIONS)) {
        return;
    }
    const cookieValue = Cookies.getJsonCookie(COOKIE_NAME);
    savedOptions = getAbTestingOptions(AB_TESTING_OPTIONS, cookieValue);

    Cookies.setJsonCookie(COOKIE_NAME, savedOptions, {
        path: '/',
        expires: 365,
    });
    return savedOptions;
}

function overrideDefaultValuesByEtcdValues(kimonoOptionsFromEtcd) {
    let isAbTestingOptionsChanged = false;
    const kimonoOptionsFromCookie = Cookies.getJsonCookie(OPTIONS_COOKIE_NAME) || {};

    _.forEach(_.keys(AB_TESTING_OPTIONS), key => {
        const abTestingOptionFromEtcd = kimonoOptionsFromEtcd[key];
        if (_.has(kimonoOptionsFromEtcd, key) && !_.has(kimonoOptionsFromCookie, key)
            && abTestingOptionFromEtcd !== savedOptions[key]) {
            isAbTestingOptionsChanged = true;
            savedOptions[key] = abTestingOptionFromEtcd;
        }
    });
    if (isAbTestingOptionsChanged) {
        Cookies.setJsonCookie(COOKIE_NAME, savedOptions, {
            path: '/',
            expires: 365,
        });
    }
}

function saveAbTestingOptionsInCookie(changedOptions) {
    let isAbTestingCookieChange = false;
    const cookieValue = Cookies.getJsonCookie(COOKIE_NAME);
    _.forEach(_.keys(AB_TESTING_OPTIONS), key => {
        if (changedOptions[key]) {
            isAbTestingCookieChange = true;
            cookieValue[key] = changedOptions[key];
        }
    });
    if (isAbTestingCookieChange) {
        Cookies.setJsonCookie(COOKIE_NAME, cookieValue, {
            path: '/',
            expires: 365,
        });
    }
}

function getSavedOptions() {
    return savedOptions;
}

function getSavedOptionsForContact() {
    return getSavedOptionsFor('contact');
}

function getSavedOptionsForEstimator() {
    return getSavedOptionsFor('estimator');
}

function getSavedOptionsFor(target) {
    if (_.includes(AB_TESTING_TARGETS, target)) {
        return getSavedOptions();
    } else {
        return {};
    }
}
