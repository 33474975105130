const _ = require('lodash');
const NumberFormatter = require('./NumberFormatter');
let {i18n: {translate}} = require('fack');
const isSoldRealEstateAd = require('../js/utils/isSoldRealEstateAd');

const MARKER_TRANSLATION_PREFIX = 'marker.';

module.exports = {
    toRange,
    formatForHtml,
    formatForMarker,
    formatForHtmlWithDecimal,
    isProgrammePreview,
    isPriceRange,
    isPriceValidForCreditSimulation,
    init,
};

function init({t}) {
    translate = t;
}

function isPriceValidForCreditSimulation(adPrice) {
    const price = isPriceRange(adPrice) ? adPrice[0] : adPrice;
    return _.isNumber(price);
}

function formatForHtml(price) {
    let priceText;
    if (_.isString(price)) {
        price = +price;
    }
    if (_.isNumber(price)) {
        priceText = NumberFormatter.formatNumber(Math.round(price), 0) + '\u00A0€';
    } else if (isPriceRange(price)) {
        if (price[0] == undefined && price[1] == undefined) {
            priceText = '';
        } else if (price[0] == price[1]) {
            priceText = NumberFormatter.formatNumber(Math.round(price[0]), 0) + '\u00A0€';
        } else if (price[0] == undefined) {
            priceText = 'jusque ' + NumberFormatter.formatNumber(Math.round(price[1]), 0) + '\u00A0€';
        } else if (price[1] == undefined) {
            priceText = 'à partir de ' + NumberFormatter.formatNumber(Math.round(price[0]), 0) + '\u00A0€';
        } else {
            priceText = NumberFormatter.formatNumber(Math.round(price[0]), 0)
                + ' à ' + NumberFormatter.formatNumber(Math.round(price[1]), 0) + '\u00A0€';
        }
    }
    return priceText;
}

function isPriceRange(price) {
    return _.isArray(price) && price.length == 2;
}

function isProgrammePreview(realEstateAd) {
    const isPreview = (realEstateAd && realEstateAd.programState == 'preview');
    const isProgrammePreview = realEstateAd
        && realEstateAd.programme
        && realEstateAd.programme.programState == 'preview';
    return isPreview || isProgrammePreview;
}

function formatForMarker(price, realEstateAd = {}) {
    let str;
    if (isSoldRealEstateAd(realEstateAd)) {
        str = translate('soldAds.soldText', {propertyType: realEstateAd.propertyType});
    } else if (realEstateAd.onlineBookingUrl) {
        str = _.toUpper(markerTranslate('onlineBooking'));
    } else if (_.isNumber(price)) {
        str = formatRoundNumberToEuro(price);
    } else if (isPriceRange(price)) {
        const [fromPrice, toPrice] = price;
        if (fromPrice == undefined) {
            str = markerTranslate('until') + ' ' + formatRoundNumberToEuro(toPrice);
        } else if (toPrice == undefined) {
            str = markerTranslate('from') + ' ' + formatRoundNumberToEuro(fromPrice);
        } else {
            str = NumberFormatter.formatRoundNumber(fromPrice) + ' ' + markerTranslate('to') + ' '
                + formatRoundNumberToEuro(toPrice);
        }
    } else if (isProgrammePreview(realEstateAd)) {
        str = markerTranslate('previewProgramme');
    } else {
        str = markerTranslate('unknownPrice', {context: realEstateAd.transactionType});
    }
    return str;
}

function formatRoundNumberToEuro(price) {
    const euroSymbol = markerTranslate('euroSymbol');
    return NumberFormatter.formatRoundNumber(price, euroSymbol);
}

function formatForHtmlWithDecimal(price) {
    let priceText;
    if (_.isString(price)) {
        price = +price;
    }
    if (_.isNumber(price)) {
        priceText = NumberFormatter.formatNumberWithoutEmptyDecimals(price, 2) + '\u00A0€';
    }
    return priceText;
}

function toRange(priceOrPriceRange) {
    if (isPriceRange(priceOrPriceRange)) {
        return priceOrPriceRange;
    } else {
        return [priceOrPriceRange, priceOrPriceRange];
    }
}

function markerTranslate(key, options) {
    return translate(MARKER_TRANSLATION_PREFIX + key, options);
}
