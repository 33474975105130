const _ = require('lodash');
const assert = require('assert');

module.exports = getAbTestingOptions;

function getAbTestingOptions(availableOptions, currentValue) {
    if (_.isArray(_(availableOptions).values().first())) {
        const savedOptions = getABTestingValuesUsingShufflePerOption(availableOptions, currentValue);
        removeUnwantedSavedOptions(savedOptions);
        return savedOptions;
    } else {
        return getABTestingValuesUsingSpecificConfigurations(availableOptions, currentValue);
    }
}

function getABTestingValuesUsingShufflePerOption(availableOptions, currentValue) {
    return _.mapValues(availableOptions, (optionConfig, key) => {
        const values = _.flatMap(optionConfig, config => {
            if (_.isPlainObject(config)) {
                const {value, weight = 1} = config;
                assert.ok(_.isInteger(weight), 'weight must be an integer: ' + weight);
                return _.times(weight, _.constant(value));
            } else {
                return config;
            }
        });
        return getABTestingValue(key, currentValue, values);
    });
}

// Used to remove options not in use, for example when in mobile mode but doing an abTest only on desktop
function removeUnwantedSavedOptions(savedOptions) {
    _.each(savedOptions, (value, key) => {
        if (value === null) {
            delete savedOptions[key];
        }
    });
}

function getABTestingValuesUsingSpecificConfigurations(availableOptions, currentValue) {
    if (currentValue && _.find(availableOptions, currentValue)) {
        return currentValue;
    } else {
        return _(availableOptions).values().shuffle().first();
    }
}

function getABTestingValue(key, currentValue, values) {
    return alreadyHasCurrentValue(currentValue, key, values) ? currentValue[key] : _.sample(values);
}

function alreadyHasCurrentValue(currentValue, key, values) {
    return isCurrentValueValid(currentValue) && isCurrentValueContentValid(currentValue, key, values);
}

function isCurrentValueValid(currentValue) {
    return currentValue && _.isObject(currentValue);
}

function isCurrentValueContentValid(currentValue, key, values) {
    return _.has(currentValue, key) && _.includes(values, currentValue[key]);
}
