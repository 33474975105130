const {EventEmitter} = require('events');

module.exports = class Field extends EventEmitter {
    constructor(fieldName, $container, options) {
        super();
        this.fieldName = fieldName;
        this.$element = $container.find('[data-field="' + fieldName + '"]');
        this.$input = this.$element.find('input');
        this.$container = $container;
        this.options = options;
        if (this.init) {
            this.init();
        }
    }

    onChange() {
        this.emitChanges();
    }

    reset() {
    }

    setDisabled(disabled) {
        this.$element.prop('disabled', disabled);
    }

    updatePlaceholder(search) {
        void(search);
    }

    updateField(search) {
        this.updatePlaceholder(search);
        this.updateVisibility(search);
    }

    updateVisibility(/*search*/) {
    }
};
