const ErrorPage = require('../errorPages/ErrorPage');
const template = require('../templates/404.jade');

module.exports = class Page404 extends ErrorPage {
    constructor() {
        super({
            name: '404',
            template,
        });
    }
};
