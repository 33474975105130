const _ = require('lodash');
const template = require('./JobOffer.jade');
const PitchBlock = require('../../../vue/components/PitchBlock');
const ContainerQueries = require('../../../vue/mixins/ContainerQueries');
const {BREAKPOINTS_LIMITS_XS} = require('../../../vue/utils/Breakpoints');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const DateFormatter = require('../../../../common/formatters/DateFormatter');

// @vue/component
module.exports = {
    components: {
        PitchBlock,
    },
    mixins: [
        ContainerQueries({xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS}}),
        i18nMixin({
            prefix: 'JobOffer.',
            keys: [
                'applyButtonText',
                'companyInformationTitle',
                'jobDescriptionTitle',
                'requiredSkillsTitle',
                'seeMoreButtonText',
                'seeLessButtonText',
                'soughtProfileTitle',
            ],
        }),
    ],
    props: {
        offer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isJobOfferDetailsContainerDisplayed: false,
        };
    },
    computed: {
        seeMoreOrLessButtonTitle() {
            return this.isJobOfferDetailsContainerDisplayed ? this.seeLessButtonText : this.seeMoreButtonText;
        },
        publishedAtText() {
            return this.t('publishedAtText', {date: DateFormatter.humanizeDay(this.offer.publishedDate)});
        },
        hasTags() {
            return !_.isEmpty(this.tags);
        },
        hasRequiredSkills() {
            return !_.isEmpty(this.requiredSkills);
        },
        companyInformation() {
            return this.offer.companyInformation;
        },
        description() {
            return this.offer.description;
        },
        soughtProfile() {
            return this.offer.soughtProfile;
        },
        requiredSkills() {
            return this.offer.requiredSkills;
        },
        tags() {
            return this.offer.tags;
        },
    },
    methods: {
        toggleJobOfferDetails() {
            this.isJobOfferDetailsContainerDisplayed = !this.isJobOfferDetailsContainerDisplayed;
        },
    },
    template: template(),
};
