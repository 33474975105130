const template = require('./templates/kelQuartierDescription.jade');
const _ = require('lodash');
const NumberFormatter = require('../common/NumberFormatter');
const {i18n: {translate}} = require('fack');
const ApplicationConfig = require('./app/ApplicationConfig');

let neighborhoodData = {};

module.exports = {
    getDescription,
    dataFromStats,
    ofCity,
    numberSuffix,
};

function getDescription(data) {
    neighborhoodData = data;
    const showLinks = ApplicationConfig.showNeighborhoodDescriptionLinksInAdDetailedSheet;
    let options = {
        dataFromStats,
        data,
        links: getLinks(data),
        ofCity,
        numberSuffix,
        t: translate,
        showLinks,
    };
    if (showLinks) {
        options = _.extend(options, {
            discoveryUrl: data.inPage ? data.discoveryUrl : null,
        });
    }
    return template(options);
}

function getLinks(place) {
    const links = {};
    let p = place;
    while (p) {
        links[p.type] = p.discoveryUrl;
        p = p.parent;
    }
    return links;
}

function findStat(stats, statKey) {
    if (_.isString(statKey)) {
        return _.find(stats, function (stat) {
            return stat.nom1_carte == statKey;
        });
    } else {
        return stats[statKey];
    }
}

function dataFromStats(options, type) {
    const stat = getDataStat(options);
    const formatFunctions = {
        value: formatValue,
        rawValueAsNumber: formatRawValueAsNumber,
        rawValueAsPercentageNumber,
        percent: formatPercentValue,
    };
    return stat != null ? formatFunctions[type](stat) : null;
}

function formatValue(stat) {
    return stat.carte_stat;
}

function formatRawValueAsNumber(stat) {
    return NumberFormatter.formatNumber(+(stat.carte_stat_valeur.replace(',', '.')));
}

function rawValueAsPercentageNumber(stat) {
    return NumberFormatter.formatNumber(+(stat.carte_stat_valeur.replace(',', '.')) * 100);
}

function formatPercentValue(stat) {
    return stat.carte_stat.replace(/ /g, '\u00A0'); //non breakable space
}

function getDataStat(options) {
    const stats = neighborhoodData[options.target || 'stats'] || {};
    const stat = findStat(stats, options.key);
    return hasStat(stat) ? stat : null;
}

function hasStat(stat) {
    return stat && stat.carte_stat != null && stat.carte_stat != 'ND' && stat.carte_stat != 'NA';
}

function ofCity(cityName) {
    if (/^[aeiouyh]/i.test(cityName)) {
        return "d'" + cityName;
    } else if (/^Le /i.test(cityName)) {
        return cityName.replace(/^Le /i, 'du ');
    } else if (/^Les /i.test(cityName)) {
        return cityName.replace(/^Les /i, 'des ');
    } else {
        return 'de ' + cityName;
    }
}

function numberSuffix(text) {
    let result = text;
    if (text == '1') {
        result += 'er';
    } else {
        result += 'e';
    }
    return result;
}
