const _ = require('lodash');
const async = require('async');

const CompositeVueView = require('../vue/CompositeVueView');

module.exports = class ContainerView extends CompositeVueView {
    constructor(options = {}) {
        super(options);
        if (options.template) {
            this.template = options.template;
        }
        this.subViews = [];
    }
    /**
     * A structure representing a View and config
     *
     * @typedef {Object} ViewWithConfig
     * @property {View} view
     * @property {string} [containerSelector]
     * @property {string} [className]
     */
    /**
     *
     * @param {ViewWithConfig[]} subViews Array of objects
     * @example [{view: new View(), containerSelector:".container"}, {view: new View(), className:"containedView"}]
     */
    setSubViews(subViews) {
        this.subViews = subViews;
    }

    show(options, vueOptions) {
        super.show(options, vueOptions);
        _.each(this.subViews, _.bind(this._showView, this, options));
    }

    hide(options) {
        async.forEachOf(this.subViews, _.bind(this._hideView, this, options), () => {
            super.hide(options);
        });
    }

    update(options) {
        _.each(this.subViews, _.bind(this._updateView, this, options));
    }

    _showView(options, {view, className, containerSelector}) {
        view.setContainer(this._getContainer(containerSelector));
        view.show(options);
        if (className && _.isString(className)) {
            view.$element.addClass(className);
        }
    }

    _getContainer(containerSelector) {
        return containerSelector ? this.$element.find(containerSelector) : this.$element;
    }

    _updateView(options, {view}) {
        if (view.update) {
            view.update(options);
        }
    }

    _hideView(options, {view}, index, cb) {
        view.hide(options, cb);
    }

    loadData(options, cb) {
        const viewsWithDataToLoad = _.filter(this.subViews, 'view.loadData');
        async.each(viewsWithDataToLoad, ({view}, cb) => {
            view.loadData(options, cb);
        }, (err) => {
            cb(err, options);
        });
    }
};
