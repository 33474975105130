const _ = require('lodash');

module.exports = value => {
    if (_.isBoolean(value)) {
        return value;
    }
    if (value == 'true') {
        return true;
    }
    if (value == 'false') {
        return false;
    }
    return null;
};
