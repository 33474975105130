const _ = require('lodash');

module.exports = {
    inject: {
        // Define default behaviors in case these injections are not provided by a parent component to prevent errors.
        declareAsyncLoadingChildComponent: { // This method will be called to indicate that this component has begun loading.
            default: function () {
                console.warn('declareAsyncLoadingChildComponent is not provided by the parent component.');
                return _.noop;
            },
        },
        asyncLoadingDone: { // This method will be invoked when the component has finished loading.
            default: function () {
                console.warn('asyncLoadingDone is not provided by the parent component.');
                return _.noop;
            },
        },
    },
    mounted() {
        // This call will increase the count within the associated instance of SubComponentsLoadingWaiter.
        this.declareAsyncLoadingChildComponent();
    },
};
