const _ = require('lodash');
const async = require('async');
const AbstractAccountStatsPage = require('./AbstractAccountStatsPage');
const Account = require('../authentication/Account');
const Error404 = require('../Error404');

module.exports = class AccountStatsPage extends AbstractAccountStatsPage {
    constructor(options) {
        options = _.defaults(options || {}, {
            name: 'accountStats',
            title: 'Statistiques',
            isRegistrationRequired: true,
            showOwnedAccountSelection: true,
        });
        super(options);
    }

    isPageAccessAllowed() {
        return (!this.isOpen || Account.hasRole('accountStatsViewer') || this.isAncestorOfDisplayedAccount);
    }

    _loadData(options, cb) {
        //options.accountId can also be a namespace it seems
        if (options.accountId) {
            async.auto({
                account: _.partial(Account.get, options.accountId),
                isAncestorOfDisplayedAccount: [
                    'account', function (callback, {account}) {
                        Account.isAncestorOfAccount(account && account.id, callback);
                    },
                ],
                relatedAccounts: [
                    'account', (cb, results) => {
                        Account.getRelatedAccounts([results.account], cb);
                    },
                ],
            }, (err, results) => {
                if (err) {
                    if (err.code == 404) {
                        cb(new Error404(err.message));
                    } else {
                        cb(err);
                    }
                } else if (results.account) {
                    this.isAncestorOfDisplayedAccount = results.isAncestorOfDisplayedAccount;
                    this.accountId = results.account.id;
                    this.accountNamespace = results.account.namespace;
                    options.relatedAccounts = results.relatedAccounts;
                    cb(null, _.extend(options, {
                        account: results.account,
                        isWelcomeSender: Account.isWelcomeSender(),
                        isAncestorOfDisplayedAccount: results.isAncestorOfDisplayedAccount,
                    }));
                } else {
                    cb(new Error404('account not found'));
                }
            });
        } else {
            cb(new Error404('no account id'));
        }
    }

    parseUrl(url, accountId) {
        return {
            accountId,
            isAdmin: Account.hasRole('admin'),
        };
    }

    getUrlPattern() {
        return '/statistiques/{id}';
    }

    getUrl() {
        return '/statistiques/' + (this.accountNamespace || this.accountId);
    }
};
