const _ = require('lodash');
const uuid = require('uuid/v1');
const template = require('./ToggleGroup.jade');
const {TOGGLE_GROUP_TYPES, TOGGLE_GROUP_RADIO_TYPE} = require('./constants');

const OPTION_PROPS = ['value'];

// @vue/component
module.exports = {
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: () => _.uniqueId('toggle-group-'),
        },
        label: {
            type: String,
            required: true,
        },
        options: {
            required: true,
            type: Array,
            validator(options) {
                return _.every(options, isValidOption);
            },
        },
        optionClass: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        dataValueJson: { // for compatibility wih FormUtils
            type: Boolean,
        },
        // "value" can be of any type
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: undefined,
        },
        fillWidth: {
            type: Boolean,
        },
        type: {
            type: String,
            default: TOGGLE_GROUP_RADIO_TYPE,
            validator: function (value) {
                return _.includes(TOGGLE_GROUP_TYPES, value);
            },
        },
        attributes: {
            type: Object,
            default: () => {},
        },
        compacted: Boolean,
    },
    computed: {
        formattedOptions() {
            return _.map(this.options, option => {
                if (_.isString(option)) {
                    option = {label: option, value: option};
                }
                const {label, value, slotName} = option;
                const valueAsString = _.isString(value) ? value : JSON.stringify(value);
                const id = this.type === TOGGLE_GROUP_RADIO_TYPE ? this.name + '-' + valueAsString : uuid();

                return {
                    id,
                    valueAsString,
                    value,
                    label,
                    slotName,
                    checked: this.type === TOGGLE_GROUP_RADIO_TYPE ? _.isEqual(this.value, value) : option.checked,
                };
            });
        },
        inputName() {
            return `${this.name}${this.type === TOGGLE_GROUP_RADIO_TYPE ? '' : '[]'}`;
        },
        modifier() {
            return {
                [this.type]: true,
                'fill-width': this.fillWidth,
                'compacted': this.compacted,
            };
        },
    },
    methods: {
        onChange(el) {
            if (this.type === TOGGLE_GROUP_RADIO_TYPE) {
                const formattedOption = _.find(this.formattedOptions, {id: el.id});
                this.$emit('input', formattedOption.value);
            } else {
                _.set(_.find(this.formattedOptions, {id: el.id}), 'checked', el.checked);
                this.$emit('input', this.formattedOptions);
            }
        },
    },
    template: template(),
};

function isValidOption(o) {
    return _.isString(o)
        || _.every(OPTION_PROPS, prop => _.has(o, prop));
}
