const _ = require('lodash');
const moment = require('../../common/momentFr');
const StatsConfig = require('../stats/StatsConfig');

const DEFAULT_CHART_OPTIONS = {
    legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal',
    },
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },
    chart: {
        type: 'column',
    },
    xAxis: {
        type: 'datetime',
        minTickInterval: 24 * 3600 * 1000,
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        minRange: 1,
        title: {
            text: '',
        },
    },
    tooltip: {
        xDateFormat: '%A %e %b %Y',
        shared: true,
        crosshairs: true,
    },
    plotOptions: {
        line: {
            enableMouseTracking: true,
        },
        series: {
            marker: {
                enabled: false,
            },
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
        column: {
            pointPadding: 0,
            borderWidth: 1,
            groupPadding: 0,
            shadow: false,
        },
    },
};

class StatsChart {
    constructor($container, options) {
        this._chart = createEmptyChart($container, options);
    }

    update({statNames, stats, days}) {
        const chartSeries = getChartSeries({statNames, stats, days});
        // TODO : upgrade HighCharts to >5.0, and replace this with chart.update()
        _.each(chartSeries, series => {
            const foundSeries = _.find(this._chart.series, {name: series.name});
            if (foundSeries) {
                foundSeries.update(series);
            } else {
                this._chart.addSeries(series);
            }
        });
        const seriesToDelete = [];
        _.each(this._chart.series, series => {
            if (!_.includes(_.map(chartSeries, 'name'), series.name)) {
                seriesToDelete.push(series);
            }
        });
        _.invokeMap(seriesToDelete, 'remove');
    }

    hide() {
        this._chart.destroy();
    }
}

module.exports = {
    DEFAULT_CHART_OPTIONS,
    StatsChart,
    getChartSeries,
    mergeChartOptions,
};

function createEmptyChart($container, options) {
    $container.highcharts(mergeChartOptions(DEFAULT_CHART_OPTIONS, options));
    return $container.highcharts();
}

function createPerDayStatSeries(data, daysTab, property) {
    return _(daysTab)
        .map(function (dateStr) {
            return [new Date(dateStr).valueOf(), _.get(data, [dateStr, property], 0)];
        })
        .sortBy(function (datum) {
            return datum[0];
        })
        .value();
}

function getDaysTab(startDate, endDate) {
    // Date aggregation is done in UTC by default in Elasticsearch
    const firstDay = moment.utc(startDate);
    const lastDay = moment.utc(endDate);
    const days = [];
    for (let day = firstDay.clone(); day <= lastDay; day = day.add(1, 'day')) {
        days.push(day.format('YYYY-MM-DD'));
    }
    return days;
}

function getStatSeries(statName, stats, daysTab) {
    const [statPrefix, statSuffix] = statName.split('.');
    const statData = stats[statPrefix];
    let series;
    if (statData) {
        series = statData.perDay ? {data: createPerDayStatSeries(statData.perDay, daysTab, statSuffix)} : statData;
    }
    return series;
}

function getChartSeries({statNames, stats, days: {startDate, endDate}, seriesConfig = {}}) {
    const daysTab = getDaysTab(startDate, endDate);
    return _.transform(statNames, (result, statName) => {
        const series = getStatSeries(statName, stats, daysTab);
        if (series && _.includes(StatsConfig.getAvailableStats(), statName)) {
            result.push(_.extend(seriesConfig[statName] || {}, {
                name: StatsConfig.getLabel(statName),
                color: StatsConfig.getColor(statName),
            }, series));
        }
    }, []);
}

function mergeChartOptions() {
    return _.extendWith(...arguments, (objValue, srcValue) => {
        if (_.isArray(srcValue)) {
            if (!_.isArray(objValue)) {
                return [objValue].concat(srcValue);
            } else {
                throw new Error('Unexpected chart options : merge rules not defined for arrays in object and sources');
            }
        } // don't do anything otherwise, _.extendWith will default to standard _.extend
    });
}
