const ApplicationPage = require('./ApplicationPage');
const VueView = require('../vue/VueView');

module.exports = class VueApplicationPage extends ApplicationPage {
    getVueComponent(/*options*/) {
        throw new Error('Must be implemented by child');
    }

    getMiddleViews(options) {
        return new VueView({}, this.getVueComponent(options));
    }

    loadData(options, cb) {
        if (this._loadData) {
            this._loadData(options, cb);
        } else {
            cb(null, options);
        }
    }
};
