const ImageUploaderWidget = require('../../ImageUploaderWidget');
const {stringFilesValidator} = require('./helpers/filesValidator');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/fileUploaderMixin'),
    ],
    props: {
        value: {
            validator: stringFilesValidator,
            default: undefined,
        },
    },
    methods: {
        getUploader() {
            return new ImageUploaderWidget();
        },
    },
};
