const $ = require('jquery');
const {EventEmitter} = require('events');
const BrowserDetect = require('browser-detect');

const _ = require('lodash');

const Account = require('../authentication/Account');
const FormUtils = require('../fields/FormUtils');

const SearchFields = require('../searchFields/SearchFields');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const EventPack = require('../utils/EventPack');
const isNullOrEmpty = require('../../common/isNullOrEmpty');

module.exports = class SearchFieldsHandler extends EventEmitter {
    /**
     *
     * @param {node} $element
     * @param {object} options
     * @param {function} options.submit function called on submit
     * @param {object} (options.validatorOptions)
     * @param {object} (options.locationsField) conf for locations field (option key built from input.attr('name') + Field)
     */
    constructor($element, options) {
        super();
        this._fields = {};

        this._eventToRelayHandler = {
            default: _.bind(this.emit, this),
        };

        this.options = options;
        this.$element = $element;
        this.defaultSearch = options.defaultSearch || DefaultConfiguration.search;
        this._eventPack = new EventPack();
        this._setForm($element);
        this._initForm(options);
        this._initFields();
        this._bindEvents();
    }

    _setForm($element) {
        const $form = $element.is('form') ? $element.filter('form') : $element.find('form');
        if ($form.length > 1) {
            throw new Error('Too many forms in element');
        }
        this.$form = $form;
    }

    setAroundMeSearch() {
        if (this._fields.locations) {
            this._fields.locations.setAroundMeSearch();
        }
    }

    getFirstTravelTimeItem() {
        if (this._fields.locations) {
            return this._fields.locations.getFirstTravelTimeItem();
        }
        return null;
    }

    setTravelTimeSearch(travelTimezone) {
        if (this._fields.locations) {
            this._fields.locations.setTravelTimeSearch(travelTimezone);
        }
    }

    setDrawZoneSearch(drawZone) {
        if (this._fields.locations) {
            this._fields.locations.setDrawZoneSearch(drawZone);
        }
    }

    addSuggestion(suggestion) {
        if (this._fields.locations) {
            this._fields.locations.addSuggestion(suggestion);
        }
    }

    setSuggestions(suggestions) {
        if (this._fields.locations) {
            this._fields.locations.setSuggestions(suggestions);
        }
    }

    hasLocationTagsTypes(types) {
        return this._fields.locations ? this._fields.locations.hasLocationTagsTypes(types) : false;
    }

    getLocationIds() {
        return this._fields.locations ? this._fields.locations.getLocationIds() : [];
    }

    getLocationsZoneInfos() {
        return this._fields.locations ? this._fields.locations.getLocationsZoneInfos() : [];
    }

    getSelectedLocations() {
        return this._fields.locations ? this._fields.locations.getSelectedLocations() : [];
    }

    getSelectedSuggestions() {
        return this._fields.locations ? this._fields.locations.getSelectedSuggestions() : [];
    }

    destroyFields() {
        this._unbindEvents();
        _.forEach(this._fields, _destroyField);
    }

    resetFields() {
        _.invokeMap(this._fields, 'reset');
    }

    setFieldsValuesFromSearch(search, fieldsList = null) {
        let filters = this.convertFiltersFromSearch(search);
        if (fieldsList) {
            filters = _.pick(filters, fieldsList);
        }
        FormUtils.setValues(this.$form, filters);
        this.updateFields(search);
    }

    setDefaultSearch() {
        this.setFieldsValuesFromSearch(this.defaultSearch);
    }

    updateFields(search) {
        _.invokeMap(this._fields, 'updateField', search);
    }

    updateAdvancedFilterCounts({adsTotalLimit, filterCounts = {}}) {
        _.forEach(this._fields, _.partial(_updateFieldCount, filterCounts, adsTotalLimit));
    }

    getFilters() {
        const filters = _.defaults(FormUtils.readValues(this.$form), this.defaultSearch);

        // In homepage, old field minRooms return string 'null' or '1'
        if (filters.minRooms) {
            filters.minRooms = JSON.parse(filters.minRooms);
        }

        if (filters.isSold && !Account.isOnTheMarketFilterViewer()) {
            filters.onTheMarket = [false];
        }
        return this.convertFiltersToSearch(filters);
    }

    getRawFilters() {
        const filters = FormUtils.readValues(this.$form);
        return this.convertFiltersToSearch(filters);
    }

    convertFiltersFromSearch(originalSearch) {
        const search = _.cloneDeep(originalSearch);
        _.invokeMap(this._fields, 'convertFromSearch', search);
        return search;
    }

    convertFiltersToSearch(originalFilters) {
        const filters = _.cloneDeep(originalFilters);
        _.invokeMap(this._fields, 'convertToSearch', filters);
        return _.omitBy(filters, isNullOrEmpty);
    }

    onSubmit(cb) {
        if (this._fields.locations) {
            this._fields.locations.onSubmit(cb);
        } else {
            _.defer(cb);
        }
    }

    preventLetterTypingInNumberPlaceholder() {
        $(':input[type="number"]').on('keypress', function (key) {
            const isNotNumericKey = key.charCode < 48 || key.charCode > 57;
            let isNotDeleteKey = key.keyCode != 8;
            if (BrowserDetect.isFirefox()) {
                isNotDeleteKey = isNotDeleteKey && key.keyCode == 0;
            }
            if (isNotNumericKey && isNotDeleteKey) {
                return false;
            }
        });
    }

    resetForm() {
        FormUtils.resetForm(this.$form);
    }

    _initForm({$button, submit, afterSubmit, validatorOptions}) {
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this._submit, this, submit),
            afterSubmit,
            $button,
            validatorOptions,
        });
    }

    submit() {
        this.$form.trigger('submit.bv');
    }

    _submit(submit, cb) {
        this.onSubmit(err => {
            if (_.get(err, 'noSuggestionSelected') && this._fields.locations) {
                this._fields.locations.openSuggestion();
            } else if (_.get(err, 'isGeolocation')) {
                this.setSuggestions([]);
            }
            submit(err, cb);
        });
    }

    _initFields() {
        const FIELD_CLASSES = SearchFields.get();
        _.forEach(this._getFieldsFromTemplate(), fieldInfos => {
            const type = fieldInfos.type;
            const fieldName = fieldInfos.name;
            const Class = FIELD_CLASSES[type];
            if (Class) {
                this._fields[fieldName] = new Class(fieldName, this.$element, _.get(this.options, `${fieldName}Field`, {}));
            }
        });
    }

    _getFieldsFromTemplate() {
        return _.map(this.$element.find('[data-field]'), function (fieldElement) {
            const name = $(fieldElement).attr('data-field');
            const type = $(fieldElement).attr('data-type');
            return {name, type: type || name};
        });
    }

    _bindEvents() {
        FormUtils.eachInputWithName(this.$form, ($input, name) => {
            this._eventPack.on($input, 'change', _.bind(this._onChange, this, {fieldName: name}));
        });
        _.forEach(this._fields, field => {
            this._eventPack.on(field, 'eventToRelay', _.bind(this._handleEventToRelay, this));
        });
        if (this._fields.locations) {
            this._eventPack.on(this._fields.locations, 'travelTimeSelected', () => {
                this.emit('travelTimeSelected');
            });
        }
    }

    _onChange({fieldName}) {
        this.updateFields(this.getFilters());
        this.emit('change', {name: fieldName, value: FormUtils.getInputValueByName(this.$form, fieldName)});
    }

    _unbindEvents() {
        this._eventPack.removeAllListeners();
    }

    _handleEventToRelay(eventName, ...args) {
        const handler = this._eventToRelayHandler[eventName] || this._eventToRelayHandler.default;
        handler(eventName, ...args);
    }
};

function _destroyField(field) {
    if (field.asyncHelper) {
        field.asyncHelper.cancelAll();
    }
    if (field.destroy) {
        field.destroy();
    }
}

function _updateFieldCount(counts, adsTotalLimit, field, fieldName) {
    if (field.updateCount && null != counts[fieldName]) {
        field.updateCount(counts[fieldName], adsTotalLimit);
    }
    if (field.updateTitle) {
        field.updateTitle();
    }
}
