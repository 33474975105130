const _ = require('lodash');
const PageManager = require('../pages/PageManager');
const Analytics = require('../utils/Analytics');
const BrowserDetect = require('browser-detect');

module.exports = {
    init,
};

function init() {
    PageManager.once('pageLoaded', firstPageLoaded);
}

function firstPageLoaded(page) {
    const NAVIGATION_START_TIMES = {
        firstPageLoad: _.get(window, 'performance.timing.navigationStart'), // not available in some browsers, like phantomjs, and sometime 0
        firstPageLoadRefined: window.navigationStartRefined,
    };
    sendTimingMeasures(page, NAVIGATION_START_TIMES);
}

function sendTimingMeasures(page, navigationStartTimes) {
    const loadingTimes = _.mapValues(_.pickBy(navigationStartTimes, _.identity), navStart => Date.now() - navStart);
    const {firstPageLoad, firstPageLoadRefined} = loadingTimes; // loadingTimes can be empty, but having a log for each user is still useful, so the code needs to be kept that way
    Analytics.sendTiming('firstPageLoad', {
        pageName: page.getName(),
        timeInMs: firstPageLoad,
        refinedTimeInMs: firstPageLoadRefined,
        isMobile: BrowserDetect.isMobile(),
    });
}
