const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const SPECIAL_CLASSIFICATIONS = ['NS', 'VI'];

module.exports = {
    formatEnergyValueAndClassification,
    isEnergyPerformanceClassificationNotSubmittedOrUndefined,
    isBadEnergyPerformanceClassification,
    hasNoLabelForDpeAndGes,
};

function formatEnergyValueAndClassification(energyValueAndClassification) {
    let formattedEnergyValueAndClassification = _.clone(energyValueAndClassification);
    const {classification, value} = formattedEnergyValueAndClassification;
    if (classification && isSpecialClassification(classification)) {
        delete formattedEnergyValueAndClassification.value;
    } else if (isValidOrMissingValue(value) && isValidClassification(classification)) {
        if (_.isNumber(value)) {
            formattedEnergyValueAndClassification.value = Math.floor(+value);
        } else {
            delete formattedEnergyValueAndClassification.value;
        }
    } else {
        formattedEnergyValueAndClassification = {
            classification: 'missing',
        };
    }
    formattedEnergyValueAndClassification.label = translate('enum.classification.' +
        formattedEnergyValueAndClassification.classification);
    return formattedEnergyValueAndClassification;
}

function isValidOrMissingValue(value) {
    return !_.isNumber(value) || +value !== 0;
}

function isSpecialClassification(classification) {
    return Boolean(classification) && _.includes(SPECIAL_CLASSIFICATIONS, classification);
}

function isValidClassification(classification) {
    return classification && classification.length == 1 && classification >= 'A' && classification <= 'I';
}

function isEnergyPerformanceClassificationNotSubmittedOrUndefined(classification) {
    return _.includes(['NS', 'VI', undefined], classification);
}

function isBadEnergyPerformanceClassification(classification) {
    return _.includes(['F', 'G'], classification);
}

function hasNoLabelForDpeAndGes(energyClassification, greenhouseGazClassification) {
    const gesAndDpeClassifications = [greenhouseGazClassification, energyClassification];
    return (!greenhouseGazClassification && !energyClassification)
        || _.includes(gesAndDpeClassifications, undefined) && _.includes(gesAndDpeClassifications, 'NS');
}
