const View = require('./View');
const template = require('../templates/accountStatsPageViewTemplate.jade');

module.exports = class AccountStatsPageView extends View {
    constructor() {
        super({
            template,
        });
    }

    getSelectAccountHeaderContainer() {
        return this.$element && this.$element.find('.selectAccountHeaderContainer');
    }

    getAccountStatsContainer() {
        return this.$element && this.$element.find('.accountStatsContainer');
    }
};
