const template = require('./FakeInput.jade');

// @vue/component
module.exports = {
    props: {
        isSet: Boolean,
    },
    computed: {
        valueSet() {
            return {set: this.isSet};
        },
    },
    template: template(),
};
