const _ = require('lodash');

const template = require('./ptzResults.jade');
const LoanResultsView = require('../LoanResultsView');

const UrlHelper = require('../../../common/UrlHelper');

module.exports = class PtzResultsView extends LoanResultsView {
    constructor(configuration) {
        super(_.extend({
            name: 'PtzResultsView',
        }, configuration));
        this.template = template;
    }

    _getSearchAdsUrl({simulatorData}) {
        return UrlHelper.addURLParameters('/recherche/achat/' + simulatorData.postalCode, {
            'prix-max': simulatorData.CoutHorsFN,
            neuf: simulatorData.TypeAchat == 13 ? 'oui' : 'non',
        });
    }
};
