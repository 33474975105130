// @vue/component
module.exports = {
    data() {
        return {
            internalValue: this.value,
        };
    },
    watch: {
        value(value) {
            this.setInternalValue(value);
        },
        internalValue(internalValue) {
            this.onInternalValueChange(internalValue);
        },
    },
    methods: {
        setInternalValue(value) {
            this.internalValue = value;
        },
        onInput({target: {value}}) {
            this.internalValue = value;
        },
        onInternalValueChange(internalValue) {
            this.$emit('input', internalValue);
        },
    },
};
