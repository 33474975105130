const _ = require('lodash');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./PropertyTypeField.jade');
const {TOGGLE_GROUP_CHECKBOX_TYPE} = require('../../form/components/constants');
const {propertyTypeSections, getPropertyTypeFormattedSections, removeUnusedType} = require('./PropertyTypeFieldsHelper');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PropertyTypeField.',
            keys: [
                'moreSelectedText',
                'selectAll',
                'unSelectAll',
                'selectedOptionCount',
                'propertyType',
                'mostUsed',
                'others',
            ],
        }),
    ],
    props: {
        showToggleAllOptions: Boolean,
        optionFilters: {
            type: Object,
            default: _.noop,
        },
        defaultSelectedOptions: {
            type: Array,
            default: () => [],
        },
        toggleGroupType: {
            type: String,
            default: TOGGLE_GROUP_CHECKBOX_TYPE,
        },
    },
    data() {
        return {
            openDropDown: false,
            label: '',
            sectionsOpened: true,
            isSet: true,
            currentSelection: [],
            selectedOptions: removeUnusedType(this.defaultSelectedOptions),
            sectionCollapse: _.map(propertyTypeSections, 'collapsed'),
        };
    },
    computed: {
        dropDownSections() {
            return getPropertyTypeFormattedSections({
                translate: this.t,
                selectedOptions: this.selectedOptions,
                optionFilters: this.optionFilters,
                sectionCollapse: this.sectionCollapse,
            });
        },
    },
    watch: {
        selectedOptions() {
            this.setLabel();
            this.$emit('input', this.selectedOptions);
        },
    },
    mounted() {
        this.setLabel();
    },
    methods: {
        setLabel() {
            const selectedOptionCount = this.selectedOptions.length;
            if (selectedOptionCount === 0) {
                this.label = this.propertyType;
                this.isSet = false;
            } else if (selectedOptionCount <= 2) {
                const allOptions = _.flatMap(_.map(this.dropDownSections, 'options'));
                const selectedLabel = _.map(_.filter(allOptions, 'checked'), 'label');
                this.label = _.join(selectedLabel, ', ');
                this.isSet = true;
            } else {
                this.label = this.t('moreSelectedText', {value: selectedOptionCount});
                this.isSet = true;
            }
        },
        valueSelected(options) {
            const allOptions = _.flatMap(_.map(this.dropDownSections, 'options'));
            const updatedOptions = _.map(allOptions, (option) => {
                return _.find(options, {value: option.value}) || option;
            });
            this.selectedOptions = _.map(_.filter(updatedOptions, 'checked'), 'value');
        },
        toggleAllOptions(select) {
            const allOptions = _.flatMap(_.map(this.dropDownSections, 'options'));
            const allSelectedValue = _.map(allOptions, 'value');
            this.selectedOptions = select ? allSelectedValue : [];
        },
        collapseSection(index) {
            const updateSectionCollapse = _.cloneDeep(this.sectionCollapse);
            updateSectionCollapse[index] = !updateSectionCollapse[index];
            this.sectionCollapse = updateSectionCollapse;
        },
    },
    template: template(),
};
