const _ = require('lodash');
const {EventEmitter} = require('events');

const emitter = new EventEmitter();
let currentViews = [];

module.exports = _.extend(emitter, {
    openViews,
    openView,
    closeView,
    isViewOpen,
});

function openViews(views, options) {
    const newViews = _.compact(views);
    const viewsToHide = _.difference(currentViews, newViews);
    const viewsToUpdate = _.intersection(currentViews, newViews);
    const viewsToShow = _.difference(newViews, viewsToUpdate);
    currentViews = [].concat(newViews); //clone array

    _.each(viewsToHide, function (view) {
        view.hide(options);
    });

    _.each(viewsToUpdate, function (view) {
        if (view.update) {
            view.update(options);
        }
    });

    _.each(viewsToShow, function (view) {
        view.show(options);
    });

    emitter.emit('change', currentViews);
}

function openView(newView, options) {
    newView.show(options);
    currentViews.push(newView);
    emitter.emit('change', currentViews);
}

function closeView(newView) {
    newView.hide();
    currentViews = _.without(currentViews, newView);
    emitter.emit('change', currentViews);
}

function isViewOpen(view) {
    return _.includes(currentViews, view);
}
