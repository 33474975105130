const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const {
    isProgrammeOrResidenceOrLot,
    isResidence,
    isProgramme,
} = require('../../../common/ProgrammeHelper');
const {createNamespacedHelpers} = require('vuex');
const {mapGetters: mapRealEstateAdContactsGetters} = createNamespacedHelpers('realEstateAdContacts');
const template = require('./DocumentationService.jade');

// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapRealEstateAdContactsGetters([
            'alreadyContactedProgrammeOrResidence',
        ]),
        document() {
            const {pdf} = this.realEstateAd;
            return _.first(_.filter(pdf, {type: 'brochure'})) || _.first(pdf);
        },
        requestableBrochure() {
            const ad = this.realEstateAd;
            return isProgrammeOrResidenceOrLot(ad) && ad.nothingBehindForm;
        },
        propertyType() {
            let propertyType;
            const ad = this.realEstateAd;
            const isProgrammeAffiliatedAd = Boolean(ad.programmeRef);
            const isResidenceAffiliatedAd = Boolean(ad.residenceReference);
            if (isProgramme(ad) || isProgrammeAffiliatedAd) {
                propertyType = 'programme';
            } else if (isResidence(ad) || isResidenceAffiliatedAd) {
                propertyType = 'residence';
            }
            return propertyType;
        },
        title() {
            return translate('documents.documentationService.title');
        },
        openContactFormBeforeDisplayingContent() {
            return this.document
                ? this.realEstateAd.pdfBehindForm && !this.alreadyContactedProgrammeOrResidence
                : null;
        },
        contentTypeDocumentBlock() {
            return this.document ? 'pdf' : 'nothing';
        },
        getDocumentSpecialType() {
            const {document} = this;
            return _.get(document, 'type', 'brochure');
        },
    },
    methods: {
        emitClickToGetDocument(payload) {
            this.$emit('click-to-get-document', _.extend({
                adId: this.realEstateAd.id,
                contentType: this.contentTypeDocumentBlock,
                specialType: this.getDocumentSpecialType,
            }, payload));
        },
    },
    template: template(),
};
