const _ = require('lodash');
const ServerConfig = require('../../ServerConfig');

// @vue/component
module.exports = {
    computed: _([
        'wwwUrl',
        'proUrl',
    ])
        .map((configProperty) => [configProperty, function () {
            return ServerConfig.config[configProperty];
        }])
        .fromPairs()
        .value(),
};
