const LocalStorage = require('../utils/localStorage/LocalStorage');
const DidomiHelper = require('./DidomiHelper');

module.exports = {
    namespaced: true,
    state() {
        return {
            allowPersonalizedAds: DidomiHelper.getUserConsentToAllowPersonalizedAdsFromLocalStorage(),
        };
    },
    getters: {
        allowPersonalizedAds(state) {
            return state.allowPersonalizedAds;
        },
    },
    mutations: {
        setAllowPersonalizedAds(state, allowPersonalizedAds) {
            state.allowPersonalizedAds = allowPersonalizedAds;
        },
    },
    actions: {
        init({commit}) {
            DidomiHelper.onDidomiReady(function (Didomi) {
                commit('setAllowPersonalizedAds', DidomiHelper.getUserConsentToAllowPersonalizedAds(Didomi));
                Didomi.on('consent.changed', function () {
                    commit('setAllowPersonalizedAds', DidomiHelper.getUserConsentToAllowPersonalizedAds(Didomi));
                });
            });
            LocalStorage.watch(DidomiHelper.DIDOMI_LOCAL_STORAGE_KEY, () => {
                commit('setAllowPersonalizedAds', DidomiHelper.getUserConsentToAllowPersonalizedAdsFromLocalStorage());
            });
        },
    },
};
