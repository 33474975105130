const _ = require('lodash');
const FavoritesManager = require('./FavoritesManager');
const emitAdFollowerToggleEventHelper = require('./emitAdFollowerToggleEvent');
const store = require('../store');

module.exports = {
    emitAdFollowerToggleEvent,
    isAdFavorite,
    updateFavoriteAdsIds,
};

function emitAdFollowerToggleEvent(realEstateAdId, newState, cb) {
    emitAdFollowerToggleEventHelper(realEstateAdId, newState, cb); // to avoid circular dependency with FavoritesManager.js
}

function updateFavoriteAdsIds(ads) {
    FavoritesManager.updateCurrentFavorites(ads);
    const adsWithFavoriteStatus = _.map(ads, ad => {
        const {id, userRelativeData: {isFavorite}} = ad;
        return {id, isFavorite};
    });
    store.get().dispatch('favoriteAds/updateCurrentFavoriteAdsIds', adsWithFavoriteStatus);
}

function isAdFavorite(realEstateAdId) {
    const currentFavoritesAdsIds = store.get().getters['favoriteAds/currentFavoriteAdsIds'];
    return _.includes(currentFavoritesAdsIds, realEstateAdId);
}
