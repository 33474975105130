const ModalFormView = require('./ModalFormView');
const Account = require('../authentication/Account');
const FormLoadingState = require('../FormLoadingState');

module.exports = class AccountModificationModalFormView extends ModalFormView {
    initForm() {
        super.initForm();
        this.loadingState = new FormLoadingState({
            $form: this.$form,
            $button: this.$submitButton,
        });
    }

    updateAccount(data) {
        if (this.loadingState) {
            this.loadingState.show();
        }
        Account.update(data, err => {
            this._onUpdate(err);
        });
    }

    _onUpdate(err) {
        if (err) {
            alert('Erreur: ' + err);
        } else {
            alert('success');
            this.emit('accountUpdated');
            if (this.loadingState) {
                this.loadingState.hide();
            }
            this.currentAccountId = null;
            this.previousOwnerIds = null;
            this.hide();
        }
    }
};
