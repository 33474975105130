const DISPLAY_MODE_GALLERY = 'gallery';
const DISPLAY_MODE_LIST = 'list';
const DISPLAY_MODE_MAP = 'map';

module.exports = {
    DISPLAY_MODE_GALLERY,
    DISPLAY_MODE_LIST,
    DISPLAY_MODE_MAP,
    DISPLAY_MODES: [
        DISPLAY_MODE_GALLERY,
        DISPLAY_MODE_LIST,
        DISPLAY_MODE_MAP,
    ],
};
