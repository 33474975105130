//translation are not yet initialized
// if you want to change text, don't forget text in defaultBrowserMessage.jade used for old IE
const NOT_SUPPORTED_MESSAGE = {
    osTooOld: 'Afin de profiter au mieux de notre site, nous vous recommandons de mettre à jour votre ' +
    "système d'exploitation.",
    osNotSupported: 'Afin de profiter au mieux de notre site, '
    + "nous vous recommandons d'utiliser un autre système d'exploitation.",
    browserNotSupported: "Afin de profiter au mieux de notre site, nous vous recommandons d'utiliser un autre navigateur.",
    browserTooOld: 'Afin de profiter au mieux de notre site, nous vous recommandons de mettre à jour votre navigateur.',
};

require('./utils/polyfills');
require('./utils/polyfillsOutsideServiceWorker');

const Options = require('./Options');
try {
    const Errors = require('./utils/Errors');
    Errors.handleRuntimeScriptErrors(Options.read()); // should be called as soon as possible
} catch (e) {
    if (window.console && window.console.error) {
        window.console.error('got an exception during initialization', e, e ? e.stack : null);
    } else {
        alert('error during initialization: ' + e);
    }
}

const $ = require('jquery');
$(function () {
    $('noscript').remove();
});

function intercept(method) {
    const original = console[method];
    console[method] = function () {
        const text = Array.prototype.slice.apply(arguments).join(' ');
        window.allLogs.push({text: text, stack: new Error().stack});
        // do sneaky stuff
        if (original.apply) {
            // Do this for normal browsers
            original.apply(console, arguments);
        } else {
            // Do this for IE
            original(text);
        }
    };
}

const savedOptions = Options.read();
if (savedOptions.catchLogs && console) {
    window.allLogs = [];
    const methods = ['log', 'warn', 'error'];
    for (let i = 0; i < methods.length; i++) {
        intercept(methods[i]);
    }
}

module.exports = function (applicationConfiguration) {
    const supportedOSCheck = require('./utils/SupportedOSCheck');
    const oldBrowserTemplate = require('../js/templates/oldBrowser.jade');
    const fack = require('fack');
    const Cookies = require('./Cookies.js');
    const UserAgentHelper = require('../common/nativeApp/UserAgentHelper');
    const supportedBrowserCheck = require('./utils/SupportedBrowserCheck');

    const isFromApp = UserAgentHelper.isFromNativeApp();
    if (Cookies.hasDismissedNotSupportedDisclaimer() || isFromApp) {
        require('./app/Application').run(applicationConfiguration);
    } else {
        supportedOSCheck.isStartSupported(function (supportedOSCheckResult) {
            if (supportedOSCheckResult == 'osSupported') {
                supportedBrowserCheck.isStartSupported(function (isSupported, advisableBrowserList) {
                    if (!isSupported) {
                        displayNotSupportedTemplate('browserNotSupported', advisableBrowserList);
                    } else {
                        require('./app/Application').run(applicationConfiguration);
                    }
                });
            } else {
                displayNotSupportedTemplate(supportedOSCheckResult);
            }
        });
    }

    function displayNotSupportedTemplate(messageType, advisableBrowserList) {
        const $oldBrowser = (oldBrowserTemplate)({
            resourceUrl: fack.resourceUrl,
            messageText: NOT_SUPPORTED_MESSAGE[messageType],
            advisableBrowserList,
        });
        const $mainPageContainer = $('.mainPageContainer');
        $mainPageContainer.removeClass('isLoading');
        $('body').append($oldBrowser);
        $('.continueToSite').click(function () {
            $('#oldBrowser').remove();
            $mainPageContainer.addClass('isLoading');
            Cookies.registerNotSupportedDisclaimerDismissed();
            applicationConfiguration.isUnsupportedBrowser = true;
            require('./app/Application').run(applicationConfiguration);
        });
    }
};
