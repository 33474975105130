const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const AccountModificationModalFormView = require('./AccountModificationModalFormView');
const template = require('../templates/account/disableAccountForm.jade');
const FormUtils = require('../fields/FormUtils');
const DisabledReasonTypes = require('../admin/account/DisabledReasonTypes');
const AccountSelectorWithPreview = require('../fields/AccountSelectorWithPreview');

const OBSOLETE_DISABLED_REASON_TYPES = ['waitingFeedback'];

module.exports = class DisableAccountFormView extends AccountModificationModalFormView {
    constructor() {
        super();
        this.template = template;
        this.translationPaths = {
            translationPath: 'accountForm.disableAccount.',
            reasonTranslationPath: 'accountForm.disableAccount.reasons.',
        };
        this.toggleConfig = {
            sendMail: ['contractRefused', 'outstandingDebt', 'contractTermination', 'userAccountRemove'],
            duplicateAccount: ['duplicate'],
        };
    }

    show(options) {
        this.currentAccountId = options.accountId;
        super.show(_.extend({
            accountId: options.accountId,
            userName: options.userName,
            DisabledReasonTypes: _.difference(DisabledReasonTypes, OBSOLETE_DISABLED_REASON_TYPES),
        }, this.translationPaths));
        this.$select = this.$form.find('select');
        this.$masterFieldContainer = this.$form.find('.accountSelector');
        this.duplicateOfSelector = new AccountSelectorWithPreview(
            this.currentAccountId, this.$masterFieldContainer, this.translationPaths.translationPath);
        this.duplicateOfSelector.on('loading', () => {
            this.$submitButton.attr('disabled', true);
        });
        this.duplicateOfSelector.on('ready', () => {
            this.$submitButton.attr('disabled', false);
        });
        this.updateDomElements(this.$select.val());
        this.$message = this.$form.find('[name = "message" ]');
        this.bindEvents();
    }

    bindEvents() {
        this._eventsWhileShown.on(this.$element, 'change', 'select', _.bind(this.handleSelectChange, this));
    }

    handleSelectChange() {
        if (this.$message && this.$select && this.$select.val()) {
            const selectValue = this.$select.val();
            this.updateDomElements(selectValue);
            const reasonTranslationPath = this.translationPaths.reasonTranslationPath;
            const text = translate(reasonTranslationPath + selectValue + 'Message');
            if (selectValue == 'otherReason') {
                this.onOtherReasonSelected(text);
            } else {
                this.setMessageValue(text);
            }
            if (selectValue == 'duplicate') {
                this.duplicateOfSelector._checkSelectedAccount();
            } else {
                this.$submitButton.attr('disabled', false);
            }
        }
    }

    onOtherReasonSelected(text) {
        this.setMessageValue('');
        this.$message.attr('placeholder', text);
    }

    setMessageValue(value) {
        this.$message.val(value);
    }

    updateDomElements(selectValue) {
        this.toggleSendMailCheckBox(selectValue);
        this.toggleDuplicateInput(selectValue);
    }

    toggleSendMailCheckBox(selectValue) {
        const $element = FormUtils.findFormGroupInputWithName(this.$form, 'sendEmail');
        this.toggleDomElement($element, 'sendMail', selectValue);
    }

    toggleDuplicateInput(selectValue) {
        this.toggleDomElement(this.$masterFieldContainer, 'duplicateAccount', selectValue);
    }

    toggleDomElement($element, type, selectValue) {
        const show = _.includes(this.toggleConfig[type], selectValue);
        $element.toggle(show);
    }

    submitForm() {
        const data = FormUtils.readValues(this.$form);
        let duplicateOf;
        if (data.reasonType == 'duplicate') {
            duplicateOf = this.duplicateOfSelector.getSelectedId();
        }
        this.updateAccount({
            id: this.currentAccountId,
            disabled: true,
            disabledReasonType: data.reasonType,
            disabledReason: data.message,
            sendEmailOnDisabled: data.sendEmail && _.includes(this.toggleConfig.sendMail, data.reasonType),
            duplicateOf,
        });
    }

    enableAccount(accountId) {
        this.updateAccount({
            id: accountId,
            disabled: false,
        });
    }
};
