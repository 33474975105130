const _ = require('lodash');

const propertyTypeSections = [
    {
        title: 'mostUsed',
        collapsed: false,
        options: [
            {
                id: 1,
                value: 'house',
                checked: false,
                disabled: false,
            },
            {
                id: 2,
                value: 'flat',
                checked: false,
                disabled: false,
            },
            {
                id: 3,
                value: 'parking',
                checked: false,
                disabled: false,
            },
            {
                id: 4,
                value: 'terrain',
                checked: false,
                disabled: false,
            },
        ],
    },
    {
        title: 'others',
        collapsed: false,
        options: [
            {
                id: 5,
                value: 'loft',
                checked: false,
                disabled: false,
            },
            {
                id: 6,
                value: 'shop',
                checked: false,
                disabled: false,
            },
            {
                id: 7,
                value: 'building',
                checked: false,
                disabled: false,
            },
            {
                id: 8,
                value: 'castle',
                checked: false,
                disabled: false,
            },
            {
                id: 9,
                value: 'premises',
                checked: false,
                disabled: false,
            },
            {
                id: 10,
                value: 'office',
                checked: false,
                disabled: false,
            },
            {
                id: 11,
                value: 'townhouse',
                checked: false,
                disabled: false,
            },
            {
                id: 12,
                value: 'others',
                checked: false,
                disabled: false,
            },
            {
                id: 13,
                value: 'annexe',
                checked: false,
                disabled: false,
            },
        ],
    },
];

const getSelectedCount = (options) => {
    return _.filter(options, {checked: true}).length;
};

const formatSection = (section, index, props) => {
    const clonedSection = _.cloneDeep(section);
    const {translate, selectedOptions, optionFilters, sectionCollapse} = props;
    clonedSection.collapsed = sectionCollapse[index];
    _.forEach(clonedSection.options, (option) => {
        option.label = _.upperFirst(translate(option.value));
        option.checked = _.includes(selectedOptions, option.value);
        return option;
    });
    clonedSection.options = _.filter(clonedSection.options, (option) => !(_.has(optionFilters, option.value)
        && !optionFilters[option.value]));

    const selectedCount = getSelectedCount(clonedSection.options);
    const sectionCountText = translate('selectedOptionCount', {value: selectedCount});
    clonedSection.title = `${translate(clonedSection.title)}${selectedCount !== 0 ? sectionCountText : ''}`;
    return clonedSection;
};

const getPropertyTypeFormattedSections = (props) => {
    return _.map(propertyTypeSections, (section, index) => formatSection(section, index, props));
};

const removeUnusedType = (options) => {
    return _.intersection(options, _.map(_.flatMap(propertyTypeSections, 'options'), 'value'));
};

module.exports = {
    getPropertyTypeFormattedSections,
    propertyTypeSections,
    removeUnusedType,
};
