const template = require('./GesLine.jade');

const GES_COLORS = {
    A: '#96dbf9',
    B: '#7fafcf',
    C: '#6e8fae',
    D: '#596b8d',
    E: '#475072',
    F: '#363457',
    G: '#22123d',
};

// @vue/component
module.exports = {
    mixins: [
        require('../mixins/commonEnergyDiagnosticLine'),
    ],
    props: {
        gesValue: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        style() {
            return this.classificationLineWidth;
        },
        classificationStyle() {
            return {
                background: GES_COLORS[this.classification],
            };
        },
    },
    template: template(),
};
