const {EventEmitter} = require('events');
const urlUtils = require('url');
const util = require('util');
const Ajax = require('../utils/Ajax');
const ServerConfig = require('../ServerConfig');
const Lot = require('./Lot');
const _ = require('lodash');
const async = require('async');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');

module.exports = ProgrammeLotsModelsLoader;

util.inherits(ProgrammeLotsModelsLoader, EventEmitter);

function ProgrammeLotsModelsLoader(id, modelName) {
    this.id = id;
    this._modelName = modelName;
    this._lots = {};
}

const proto = ProgrammeLotsModelsLoader.prototype;

proto.getId = function () {
    return this.id;
};

proto.load = function (cb, shouldLoadLot = _.constant(true)) {
    async.parallel({
        programmeInfos: _.bind(this._getProgrammeLotInfos, this),
        virtualToursInfos: cb => {
            VirtualTourHelper.getVirtualToursForProgramme(this.id, (err, results) => {
                if (err) {
                    console.error(`Could not get virtual tour for program ${this.id}, ignoring.`);
                }
                cb(null, results);
            });
        },
    }, (err, {programmeInfos, virtualToursInfos} = {}) => {
        if (err) {
            cb(err);
        } else {
            this.position = programmeInfos.programmePosition;
            if (!this.position) {
                console.warn('No position for programme.');
            }
            const lotInfos = programmeInfos.lotInfos;
            _.each(lotInfos, (lotInfo) => {
                extendLotInfoWithVirtualTour(lotInfo, virtualToursInfos);
                if (shouldLoadLot(lotInfo)) {
                    this._lots[lotInfo.id] = this._createLot(lotInfo, this._map);
                }
            });
            this._addLotsWithoutModel(lotInfos, virtualToursInfos);
            this._loadLots((err, lots) => {
                cb(err, lots, programmeInfos);
            });
        }
    });
};

proto._createLot = function (lotInfo, map) {
    const lot = new Lot(lotInfo, map);
    lot.on('showVirtualTour', () => {
        this.emit('showVirtualTour');
    });
    lot.on('hideVirtualTour', () => {
        this.emit('hideVirtualTour');
    });
    return lot;
};

proto.setMap = function (map) {
    if (this._map != map) {
        this._map = map;
        this._updateMapLots();
    }
};

proto._updateMapLots = function () {
    _.each(this._lots, (lot) => {
        lot.setMap(this._map);
    });
};

proto._loadLots = function (cb) {
    const lots = _.values(this._lots);
    async.each(lots, function (lot, cb) {
        // only for async loading (first time loading on creation)
        // because _onMarkerModelLoaded is re-called each time setMap map Changed on marker
        lot.once('lotModelLoaded', cb);
        lot.load();
    }, function (err) {
        cb(err, lots);
    });
};

proto.fadeTo = function (id, color, duration) {
    const lot = this.findLotById(id);
    if (lot) {
        lot.fadeTo(color, duration);
    } else {
        console.error('fadeTo for unknown lot ' + id);
    }
};

proto.setColor = function (id, color) {
    const lot = this.findLotById(id);
    if (lot) {
        lot.setColor(color);
    } else {
        console.error('setColor for unknown lot ' + id);
    }
};

proto.setClickable = function (id, clickable) {
    const lot = this.findLotById(id);
    if (lot) {
        lot.setClickable(clickable);
    } else {
        console.error('setClickable for unknown lot ' + id);
    }
};

proto.findLotById = function (id) {
    return this._lots[id];
};

proto.getLots = function () {
    return this._lots;
};

proto._getProgrammeLotInfos = function (cb) {
    const url = ServerConfig.config.kimonoModelsUrl + '/getProgrammeLotInfos';
    return Ajax.request({
        url,
        data: {
            id: this.getId(),
            modelName: this._modelName,
        },
        callback: (err, programmeLotsInfo) => {
            if (err) {
                cb(err);
            } else {
                this.emit('programmePosition', programmeLotsInfo.programmePosition);
                _.each(programmeLotsInfo.lotInfos, function (lotInfo) {
                    _.each([
                        'facadeModel.modelPath',
                        'interiorModel.modelPath',
                    ], function (key) {
                        const value = _.get(lotInfo, key);
                        if (value) {
                            _.set(lotInfo, key, urlUtils.resolve(url, value));
                        }
                    });
                });
                cb(null, programmeLotsInfo);
            }
        },
    });
};

proto.hasModels = function () {
    return Boolean(_.find(this._lots, function (lot) {
        return lot.hasModels();
    }));
};

proto._addLotsWithoutModel = function (lotInfos, allVirtualTours) {
    const remainingVirtualTours = _.reject(allVirtualTours, function (virtualTour) {
        return _.some(lotInfos, function (lotInfo) {
            return lotInfo.id == virtualTour.adId;
        });
    });
    const withoutModelLotIds = _.uniq(_.map(remainingVirtualTours, 'adId'));
    _.each(withoutModelLotIds, (withoutModelLotId) => {
        const lotInfo = {
            id: withoutModelLotId,
            interiorModelExists: false,
            facadeModelExists: false,
        };
        const virtualTours = _.filter(remainingVirtualTours, function (virtualToursInfo) {
            return virtualToursInfo.adId == withoutModelLotId;
        });
        if (!_.isEmpty(virtualTours)) {
            _.extend(lotInfo, {virtualTours: virtualTours});
            this._lots[withoutModelLotId] = this._createLot(lotInfo);
        }
    });
};

function extendLotInfoWithVirtualTour(lotInfo, allVirtualTours) {
    const virtualToursInfo = _.find(allVirtualTours, {id: lotInfo.id});
    if (virtualToursInfo && !_.isEmpty(virtualToursInfo.virtualTours)) {
        _.extend(lotInfo, {virtualTours: virtualToursInfo.virtualTours});
    }
}
