const _ = require('lodash');
const Ajax = require('./Ajax');
const ServerConfig = require('../ServerConfig');

module.exports = {
    getRequestSimulation,
};

function getRequestSimulation(simulatorPath, data, callback) {
    return Ajax.request({
        url: ServerConfig.config.financialUrl + simulatorPath,
        data: _.omitBy(data, _.isNull),
        disableErrorPage: true,
        method: 'POST',
        callback,
    });
}
