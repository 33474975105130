const template = require('./ContactPhoneBox.jade');
const PhoneContact = require('../../agencyInfoPage/components/PhoneContact/PhoneContact');
const i18nMixin = require('../../vue/components/mixins/i18n');

const VISITE_ONLINE_CONTACT_EMAIL_TYPE = 'visiteonline';

// @vue/component
module.exports = {
    components: {
        PhoneContact,
    },
    mixins: [
        i18nMixin({
            prefix: 'ContactPhoneBox.',
            keys: [
                'noContactInformation',
                'contactBySkype',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
        },
        source: {
            type: String,
            required: true,
        },
        detailedSheetData: {
            type: Object,
            required: true,
        },
        displayPhoneNumber: Boolean,
    },
    computed: {
        modifiers() {
            return {
                mobile: this.isMobile,
            };
        },
        couldBeContactedByEmailOrWebservice() {
            const {hasEmailToDisplay, contactEmailType} = this.contact;
            return hasEmailToDisplay || contactEmailType === VISITE_ONLINE_CONTACT_EMAIL_TYPE;
        },
        showNoContactInformation() {
            return !this.couldBeContactedByEmailOrWebservice && !this.contact.phoneToDisplay;
        },
        skypeUrl() {
            return `skype:${this.contact.skypeName}?call&video=true`;
        },
    },
    template: template(),
};
