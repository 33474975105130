const _ = require('lodash');
const adFieldsConfig = require('../../common/adFieldsConfig');

module.exports = {
    getPriceConfiguration,
    getRoomQuantityConfiguration,
    getAreaSizeConfiguration,
    getGeocodingConfiguration,
    advancedFiltersConfiguration: computeAdvancedFiltersConfiguration(),
    getBedroomQuantityConfiguration,
    getGardenAreaSizeConfiguration,
};

const PRICE_DEFAULT = {
    summary: {
        title: 'Votre budget',
        label: 'Votre budget',
        value: 'price',
        unit: '€',
        focusOnMaxInput: true,
    },
};

const PRICE_RENT_OR_BUY = _.extend({
    clearFieldsOnChange: true,
}, PRICE_DEFAULT);

const ROOM_QTY = {
    summary: {
        title: 'Nombre de pièces',
        label: 'Nb de pièces',
        value: 'room',
    },
    values: [
        {label: '1', value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
        {label: '4', value: 4},
        {label: '5', value: 5},
    ],
};

const BEDROOM_QTY = {
    summary: {
        title: 'Nombre de chambres',
        label: 'Nombre de chambres',
        value: 'bedroom',
    },
    values: ROOM_QTY.values,
};

const AREA_SIZE = {
    summary: {
        title: 'Surface du bien',
        label: 'Surface',
        value: 'size',
        unit: 'squareMeter',
    },
};
const GARDEN_AREA_SIZE = {
    summary: {
        title: 'Surface du terrain',
        label: 'Surface du terrain',
        value: 'gardenSize',
        unit: 'landSquareMeter',
    },
};

const GEOCODING_PRECISION = _.compact(_.map(require('../geocodingFilters'), function (filter) {
    return {
        label: filter.label,
        value: filter.value,
    };
}));

function getGeocodingConfiguration() {
    return GEOCODING_PRECISION;
}

function getRoomQuantityConfiguration() {
    return ROOM_QTY;
}

function getBedroomQuantityConfiguration() {
    return BEDROOM_QTY;
}

function getAreaSizeConfiguration() {
    return AREA_SIZE;
}

function getGardenAreaSizeConfiguration() {
    return GARDEN_AREA_SIZE;
}

function getPriceConfiguration(type) {
    if (_.isArray(type) && type.length === 1) {
        type = type[0];
    }
    if (type === 'rent' || type === 'buy') {
        return PRICE_RENT_OR_BUY;
    } else {
        return PRICE_DEFAULT;
    }
}

function computeAdvancedFiltersConfiguration() {
    const result = {};

    _.each(adFieldsConfig, function (fieldConfig) {
        result[fieldConfig.name] = {};
        if (fieldConfig.adTypes) {
            result[fieldConfig.name].filterType = fieldConfig.adTypes;
        }
        if (fieldConfig.propertyTypes) {
            result[fieldConfig.name].propertyType = _.clone(fieldConfig.propertyTypes);
            if (_.without(fieldConfig.propertyTypes, 'house', 'flat', 'parking', 'terrain', 'annexe').length > 0) {
                result[fieldConfig.name].propertyType.push('others');
            }
        }
    });
    return result;
}
