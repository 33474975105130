const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');

const template = require('../../templates/landingPage/landingPageSearchFormView.jade');
const searchFilterTemplate = require('../../templates/landingPage/landingPageSearchFilterView.jade');
const EventPack = require('../../utils/EventPack');
const View = require('../View');
const CompactSearchFilterView = require('../../search/CompactSearchFilterView');

module.exports = class LandingPageSearchFormView extends View {
    constructor({$container}) {
        super({
            template,
            $container,
        });
        this._searchFilterView = new CompactSearchFilterView({template: searchFilterTemplate});
        this._eventPack = new EventPack();
    }

    show(options) {
        const {forcedFields, isTravelTimeIncludedInForm, title, allowedPlaceTypes} = options;
        const h1Content = title.replace(':', ':<br>');
        super.show(_.extend({h1Content}, options));
        this.forcedFields = forcedFields;
        this._searchFilterView.setContainer(this.$element.find('.landingPageSearchFormContainer'));
        this._searchFilterView.show(_.extend({}, options, {
            _t: (key, tOptions) => t(`landingPages.searchForm.${key}`, tOptions),
            formOptions: {
                isTravelTimeIncludedInForm,
                locationsField: {
                    placeholderKey: 'locationField.placeholderSmall',
                    allowedPlaceTypes,
                },
            },
        }));
        this._bindEvents();
    }

    hide(options, cb) {
        this._eventPack.removeAllListeners();
        this._searchFilterView.hide();
        super.hide(options, cb);
    }

    _bindEvents() {
        this._eventPack.on(this._searchFilterView, 'search', search => {
            this.emit('search', _.extend({}, search, this.forcedFields));
        });
    }
};
