const _ = require('lodash');
const BaseAccountStatsSelectorView = require('./BaseAccountStatsSelectorView');
const template = require('../templates/contactsFilterSelectorView.jade');
const AccountContactTypes = require('../data/AccountContactTypes');

module.exports = class ContactsFilterSelectorView extends BaseAccountStatsSelectorView {
    constructor(options) {
        super(options);
        this.template = template;
        this.title = 'Type de contact';
    }

    show(options) {
        super.show(_.extend({AccountContactTypes}, options));
    }

    _emitChanges() {
        this.emit('contactFiltersChanged');
    }
};
