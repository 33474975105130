const _ = require('lodash');

const Views = require('../views/Views');
const EventPack = require('../utils/EventPack');
const AsyncHelper = require('../utils/AsyncHelper');
const SimpleContentPage = require('../pages/SimpleContentPage');
const PageManager = require('../pages/PageManager');
const SimulatorsHelper = require('../utils/SimulatorsHelper');
const Account = require('../authentication/Account');
const ContactHelper = require('../utils/ContactHelper');
const GoogleTagManager = require('../stats/GoogleTagManager');

const CONTACT_DATA_TO_SEND = [
    'email',
    'firstName',
    'lastName',
    'phone',
    'postalCode',
];

module.exports = class LoanSimulatorBasePage extends SimpleContentPage {
    constructor(configuration) {
        configuration = _.extend({
            name: 'LoanSimulator',
            gtmCategory: 'ToolsAndSimulators',
            gtmPrefix: '',
            requestUrl: '/',
            requestName: 'loanSimulatorRequest',
        }, configuration);
        super(configuration);
        this._eventPack = new EventPack();
        this.asyncHelper = new AsyncHelper();

        this._createViews();
    }

    open(options = {}) {
        super.open(options);
        this._showBuiltInView(options);
        this._bindEvents();
    }

    close(...args) {
        this._eventPack.removeAllListeners();
        this.asyncHelper.cancelAll();
        super.close(...args);
    }

    getMiddleViews() {
        return this.contentView; // set by derived classes
    }

    _bindEvents() {
    }

    _showBuiltInView(options) {
        if (this.currentBuiltInView) {
            this.currentBuiltInView.hide();
        }
        this.currentBuiltInView = this._getBuiltInView(options);
        this._setBuiltInViewContainer();
        this.currentBuiltInView.show(options);
    }

    _setBuiltInViewContainer() {
        this.currentBuiltInView.setContainer(this._getBuiltInContainer());
    }

    _requestSimulation(data, callback) {
        const dataToSend = _.extend({}, data, {
            accountData: this._getAccountData(),
            contactData: this._getContactData(data),
        });
        this.sendGtmEvent(`${this.configuration.gtmPrefix}-simulationRequest`, dataToSend);
        this.asyncHelper.doAsync({
            func: cb => SimulatorsHelper.getRequestSimulation(this.configuration.requestUrl, dataToSend, cb),
            callback: (err, results) => {
                if (err) {
                    this._onRequestError();
                } else {
                    callback(null, results);
                }
            },
            name: this.configuration.requestName,
        });
    }

    _onRequestError() {
        this._showErrorPopup(_.bind(this._openSimulatorPage, this));
    }

    _getContactData(data) {
        return _.pick(_.extend(ContactHelper.getUserInfo(), data.contactData), CONTACT_DATA_TO_SEND);
    }

    _getAccountData() {
        return {
            access_token: Account.getAccessToken(),
            id: Account.getAuthenticatedAccountId(),
        };
    }

    _openSimulatorPage(options) {
        PageManager.openPage(this._getSimulatorPage(), options);
    }

    _getSimulatorPage() {
        return null;
    }

    _showErrorPopup(cb) {
        Views.volatileFeedback.showError('loanSimulator.volatileFeedback.error');
        cb();
    }

    sendGtmEvent(eventName, data) {
        GoogleTagManager.sendEvent(eventName, data);
    }
};
