const MapApi = require('../MapApi');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const util = require('util');

module.exports = PoiGridLayer;

function PoiGridLayer(options, allResultsWebGLGrid) {
    this._allResultsWebGLGrid = allResultsWebGLGrid;
    MapApi.api.GridLayer.call(this, options);
}

util.inherits(PoiGridLayer, MapApi.api.GridLayer);

PoiGridLayer.prototype.canWork = function () {
    if (!MapApi.api.GridLayer.prototype.canWork.call(this)) {
        return false;
    }
    return (!this._isMoving());
};

PoiGridLayer.prototype._isMoving = function () {
    return SideMapViewSingleton.get().map._renderer && SideMapViewSingleton.get().map._renderer.isMoving();
};

PoiGridLayer.prototype.forceUpdateTiles = function () {
    this._allResultsWebGLGrid._onMapIdle();
    MapApi.api.GridLayer.prototype.forceUpdateTiles.apply(this, arguments);
};
