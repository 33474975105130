const _ = require('lodash');

module.exports = {
    extendWithNames,
};

function extendWithNames(accountIdsWithSources, accountNames) {
    return _.map(accountIdsWithSources, accountIdWithSource => {
        const {accountId} = accountIdWithSource;
        const name = accountNames[accountId] || accountId;
        return _.extend({name}, accountIdWithSource);
    });
}
