const _ = require('lodash');

const template = require('./RentControlBlock.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const DetailedSheetDetailCell = require('./DetailCell');
const {formatForHtml} = require('../../../../common/PriceFormatter.js');

// @vue/component
module.exports = {
    components: {
        DetailedSheetDetailCell,
    },
    mixins: [
        i18nMixin({
            prefix: 'RentControlBlock.',
            keys: [
                'title',
                'footnote',
            ],
        }),
    ],
    props: {
        isSubjectToRentControl: Boolean,
        maxReferenceRent: {
            type: Number,
            default: undefined,
        },
        rentWithoutCharges: {
            type: Number,
            default: undefined,
        },
        rentExtra: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        maxReferenceRentText() {
            return this.t('maxReferenceRentPrefix') + this.getPriceText(this.maxReferenceRent);
        },
        rentExtraText() {
            return this.t('rentExtraPrefix') + this.getPriceText(this.rentExtra);
        },
        rentWithoutChargesText() {
            return this.t('rentWithoutChargesPrefix') + this.getPriceText(this.rentWithoutCharges);
        },
    },
    methods: {
        getPriceText(price) {
            if (_.isNumber(price)) {
                const formattedPrice = formatForHtml(price);
                return this.t('price', {formattedPrice});
            } else {
                return this.t('notAvailable');
            }
        },
    },
    template: template(),
};
