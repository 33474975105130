const INITIAL_STEP = 0;
const ESTIMATOR_TRANSLATION_KEY_PREFIX = 'realEstateEstimator.';
const SUBMISSION_TRANSLATION_KEY_PREFIX = 'realEstateSubmission.';
const PROPERTY_TYPE_OPTION = [
    {label: 'house', value: 'house'},
    {label: 'flat', value: 'flat'},
];
const SUBMISSION_PROPERTY_TYPE_OPTION = [
    ...PROPERTY_TYPE_OPTION,
    {label: 'loft', value: 'loft'},
    {label: 'terrain', value: 'terrain'},
    {label: 'parking', value: 'parking'},
    {label: 'unknown', value: 'unknown'},
];
const LOADING_STEPS_COUNT = 4;
const LOADING_STEP_DURATION_IN_MS = 1e3;
const ESTIMATOR_STORE_MODULE_NAME = 'Estimator';
const SUBMISSION_STORE_MODULE_NAME = 'Submission';

module.exports = {
    INITIAL_STEP,
    ESTIMATOR_TRANSLATION_KEY_PREFIX,
    SUBMISSION_TRANSLATION_KEY_PREFIX,
    PROPERTY_TYPE_OPTION,
    SUBMISSION_PROPERTY_TYPE_OPTION,
    LOADING_STEPS_COUNT,
    LOADING_STEP_DURATION_IN_MS,
    ESTIMATOR_STORE_MODULE_NAME,
    SUBMISSION_STORE_MODULE_NAME,
};
