const _ = require('lodash');
const ContactBlockWithTitle = require('./BlockWithTitle');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./SenderDetails.jade');

// @vue/component
module.exports = {
    components: {
        ContactBlockWithTitle,
    },
    mixins: [
        i18nMixin({
            prefix: 'SenderDetails.',
            keys: [
                'title',
                'phoneTitle',
                'emailTitle',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
            validator: value => _.has(value, 'displayName'),
        },
    },
    computed: {
        displayName() {
            return this.contact.displayName;
        },
        phone() {
            return this.contact.phone;
        },
        phoneUrl() {
            return `tel:${this.phone}`;
        },
        email() {
            return this.contact.email;
        },
        emailUrl() {
            return `mailto:${this.email}`;
        },

    },
    template: template(),
};
