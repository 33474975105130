const _ = require('lodash');
const template = require('./Card.jade');

const SUCCESS_VARIANT = 'success';
const ERROR_VARIANT = 'error';

const VARIANTS = [
    SUCCESS_VARIANT,
    ERROR_VARIANT,
    'restricted',
];

// @vue/component
module.exports = {
    props: {
        hover: Boolean,
        elevation: Boolean,
        variant: {
            type: String,
            default: undefined,
            values: VARIANTS,
            validator: value => _.includes(VARIANTS, value),
            SUCCESS_VARIANT,
            ERROR_VARIANT,
        },
        closable: Boolean,
        alignStart: Boolean,
        href: {
            type: String,
            default: null,
        },
        vertical: Boolean,
        wrap: Boolean,
        autoAdjustWrap: Boolean,
        fillWidth: Boolean,
        wrapMobile: Boolean,
    },
    computed: {
        modifiers() {
            const {variant, elevation, hover, alignStart, fillWidth} = this;
            return {
                [variant]: Boolean(variant),
                hover,
                elevation,
                alignStart,
                fillWidth,
            };
        },
        contentModifiers() {
            return _.pick(this, ['vertical', 'wrap', 'fillWidth', 'alignStart', 'autoAdjustWrap', 'wrapMobile']);
        },
        cardContainer() {
            return this.href ? 'a' : 'div';
        },
        urlProp() {
            const {href} = this;
            return href && {href};
        },
    },
    template: template(),
};
