const _ = require('lodash');
const template = require('./JobOffers.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const AgencyRequests = require('../AgencyRequests');
const JobOffer = require('./JobOffer');
const AgencyPageSkeleton = require('../AgencyPageSkeleton');

// @vue/component
module.exports = {
    constants: {
        JOB_OFFER_SKELETON_COUNT: 2,
    },
    components: {
        JobOffer,
        AgencyPageSkeleton,
    },
    mixins: [
        i18nMixin({
            prefix: 'JobOffers.',
            keys: [
                'title',
                'errorMessage',
            ],
        }),
    ],
    props: {
        agencyId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            offers: undefined,
            error: false,
            isLoadingStateDisplayed: false,
        };
    },
    computed: {
        isVisible() {
            const {isLoadingStateDisplayed, offers, error} = this;
            return isLoadingStateDisplayed || !_.isEmpty(offers) || error;
        },
    },
    created() {
        const loadingTimer = setTimeout(() => {
            this.isLoadingStateDisplayed = true;
        }, 1000);
        AgencyRequests.getJobAds(this.agencyId, (err, data) => {
            if (err) {
                this.error = err.statusCode !== 403;
            }
            this.offers = _.get(data, 'jobAds');
            this.isLoadingStateDisplayed = false;
            clearTimeout(loadingTimer);
        });
    },
    template: template(),
};
