const _ = require('lodash');
const VALID_ENERGY_DIAGNOSTIC_CLASSIFICATIONS = require('../helper/validEnergyDiagnosticClassifications');

// @vue/component
module.exports = {
    props: {
        classification: {
            type: String,
            default: null,
        },
        active: Boolean,
        expanded: Boolean,
    },
    computed: {
        classificationLineWidth() {
            if (this.expanded) {
                const classificationIndex = _.indexOf(VALID_ENERGY_DIAGNOSTIC_CLASSIFICATIONS, this.classification);
                const width = 100 - (VALID_ENERGY_DIAGNOSTIC_CLASSIFICATIONS.length - 1 - classificationIndex) * 5 + '%';
                return {
                    width,
                };
            } else {
                return {
                    width: '100%',
                };
            }
        },
    },
};
