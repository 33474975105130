// @vue/component
module.exports = {
    mixins: [
        require('./mixins/configMultiselectRadioButton'),
    ],
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            options: [
                {label: 'Moyen', value: 'normal'},
                {label: 'Bon', value: 'luxury'},
                {label: 'Élevé', value: 'high-class'},
            ],
        };
    },
};
