const _ = require('lodash');

const View = require('./View');
const EventPack = require('../utils/EventPack');
const LocalStorage = require('../utils/localStorage/LocalStorage');
const template = require('../templates/saveSearchBanner.jade');

let COUNT = 0;
const MIN_COUNT_TO_DISPLAY = 3;
let hasClosedSaveSearchBannerThisSession = false;

module.exports = class SaveSearchBannerView extends View {
    constructor() {
        super({
            template,
        });
        this.configuration = {
            animations: {
                openAnimation: 'anim-open',
                closeAnimation: 'anim-close',
            },
        };
        this._eventPack = new EventPack();
    }

    show() {
        if (this._shouldShowBanner()) {
            super.show();
            this._bindEvents();
        } else {
            COUNT++;
        }
    }

    _bindEvents() {
        this._eventPack.on(this.$element.find('.closeBanner'), 'click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.storeClosedStatus();
            this.hide();
        });
        this._eventPack.on(this.$element.find('.saveSearchBannerCta'), 'click', _.bind(this.showSavedSearchModal, this));
    }

    showSavedSearchModal() {
        this.emit('saveSearch');
        this.hide();
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
    }

    shouldPlayOpenAnimation() {
        return true;
    }

    shouldPlayCloseAnimation() {
        return true;
    }

    storeClosedStatus() {
        hasClosedSaveSearchBannerThisSession = true;
        LocalStorage.setValue('hasClosedSaveSearchBanner', true);
    }

    static getClosedStatus() {
        const valueInLocalStorage = LocalStorage.getValue('hasClosedSaveSearchBanner');
        return valueInLocalStorage != null ? valueInLocalStorage : hasClosedSaveSearchBannerThisSession;
    }

    _shouldShowBanner() {
        return this.constructor.getClosedStatus() !== true && COUNT >= MIN_COUNT_TO_DISPLAY;
    }
};
