const _ = require('lodash');

const LocationItem = require('./LocationItem');
const {cityNameToInTheCity, cityNameToOfTheCity} = require('@bienici/city-name-formatter');

module.exports = class CityLocationItem extends LocationItem {
    getText() {
        return this._item.name;
    }

    getInTheLocationText() {
        return cityNameToInTheCity(this.getText());
    }

    getOfTheLocationText() {
        return cityNameToOfTheCity(this.getText());
    }

    getSuggestionText() {
        return this._getValueArray().slice(0, 2).join(' ');
    }

    getValue() {
        return this._getValueArray().join('-');
    }

    getUrlValue() {
        const values = this._getValueArray().slice(0, 2);
        if (/delegated-city/.test(this._item.type)) {
            values.push('associee');
        }
        return values.join('-');
    }

    getItemGTMInfo() {
        return {
            name: this.getValue(),
            postalCodes: this.getPostalCodes(),
        };
    }

    getItemValuesToStoreInDB() {
        const itemValues = _.pick(this.getItem(), ['name', 'postalCodes', 'ref', 'zoneIds']);
        return _.extend(itemValues, super.getItemValuesToStoreInDB());
    }

    getZoneInfoIds() {
        return this._item.zoneIds;
    }

    getZoneInfos() {
        return {
            zoneIds: this.getZoneInfoIds(),
            type: this.getType(),
            name: this.getValue(),
            postalCodes: this.getPostalCodes(),
        };
    }

    getTypeForServerRequest() {
        return 'zoneIds';
    }

    getPostalCodes() {
        return _.uniq(this._item.postalCodes);
    }

    _getValueArray() {
        return _.compact([this.getText(), ...this.getPostalCodes()]);
    }

    getLocationProperties() {
        return {
            name: this.getText(),
            type: this.getType(),
            postalCodes: this.getPostalCodes(),
        };
    }
};
