const moment = require('moment');
const _ = require('lodash');

const {formatDetailedDate} = require('../../../../common/TextFormatter');
const template = require('./RowInformation.jade');

// @vue/component
module.exports = {
    bemName: 'data-table',
    inject: ['getAdditionalCellClass'],
    props: {
        columns: {
            type: Array,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            moreInfoToggled: false,
        };
    },
    computed: {
        realEstateAd() {
            return this.item.realEstateAd;
        },
        hasMoreInfoSlot() {
            return this.$scopedSlots['more-info'] && Boolean(this.$scopedSlots['more-info']());
        },
    },
    methods: {
        getCellSlotName(column) {
            return 'cell-' + _.kebabCase(column.label);
        },
        formatTitleDate(date) {
            return formatDetailedDate(date);
        },
        formatDisplayDate(date) {
            return moment(date).calendar();
        },
        toggleMoreInfo() {
            if (this.hasMoreInfoSlot) {
                this.moreInfoToggled = !this.moreInfoToggled;
            }
        },
    },
    template: template(),
};
