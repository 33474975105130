const template = require('./MapTutorialSection.jade');
const {resourceUrl} = require('fack');

// @vue/component
module.exports = {
    props: {
        options: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            displayPoiMenu: false,
        };
    },
    methods: {
        imageUrl(url) {
            return resourceUrl(url);
        },
    },
    template: template(),
};
