require('corejs-typeahead');
const _ = require('lodash');
const Bloodhound = require('corejs-typeahead/dist/bloodhound.js');

const TagsInputWidget = require('./TagsInputWidget');

module.exports = class TagsInputTypeaheadWidget extends TagsInputWidget {
    constructor(options) {
        super(options);
        this.typeaheadEvents = [
            'click',
            'typeahead:active',
            'typeahead:idle',
            'typeahead:open',
            'typeahead:close',
            'typeahead:change',
            'typeahead:render',
            'typeahead:beforeselect',
            'typeahead:select',
            'typeahead:autocomplete',
            'typeahead:cursorchange',
            'typeahead:asyncrequest',
            'typeahead:asynccancel',
            'typeahead:asyncreceive',
        ];
        this.typeaheadOptions = options.typeaheadOptions || {};
        this.typeaheadConfig = this.typeaheadOptions.typeaheadConfig || {};
        this.typeaheadDatasets = this.typeaheadOptions.typeaheadDatasets || {};
        this.bloodHoundConf = options.bloodHoundConf;

        this.engine = null;
    }

    init() {
        if (this.bloodHoundConf) {
            this._initBloodHound();
        }
        this._updateTagsInputOptions();
        super.init();
    }

    resetInput() {
        this.resetWidget();
        this.setTypeaheadInputValue('');
    }

    setTypeaheadInputValue(value) {
        this.$input.typeahead('val', value);
    }

    resetWidget() {
        super.resetWidget();
        this.cancelLastRequest();
    }

    cancelLastRequest() {
        if (this.engine && this.engine.remote) {
            this.engine.remote.cancelLastRequest();
        }
    }

    getEngine() {
        return this.engine || null;
    }

    destroy() {
        this.cancelLastRequest();
        super.destroy();
    }

    closeTypeahead() {
        this.$input.typeahead('close');
    }

    openTypeahead() {
        this.$input.typeahead('open');
    }

    getTypeaheadWrapper() {
        this.$input.typeahead('wrapper');
    }

    _initBloodHound() {
        this._updateBloodHoundConf();
        this.engine = new Bloodhound(this.bloodHoundConf);
        this.engine.initialize();
        this._updateTypeaheadDatasets();
    }

    _updateBloodHoundConf() {
        this.bloodHoundConf = _.defaults({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
        }, this.bloodHoundConf);
    }

    _updateTypeaheadDatasets() {
        this.typeaheadDatasets[0].source = this.engine;
    }

    _updateTagsInputOptions() {
        this.tagsInputOptions = _.extend({
            typeaheadjs: [this.typeaheadConfig, this.typeaheadDatasets],
        }, this.tagsInputOptions);
    }

    _bindEvents() {
        super._bindEvents();
        _.each(this.typeaheadEvents, eventName => {
            this.getTagsInput().on(eventName, (...args) => {
                this.emit(eventName, ...args);
            });
        });
    }

};
