const $ = require('jquery');
const _ = require('lodash');
const async = require('async');
const fack = require('fack');
const translate = fack.i18n.translate;
const Page = require('./Page');
const Title = require('./Title');
const ViewManager = require('../views/ViewManager');
const Views = require('../views/Views');
const {EventEmitter} = require('events');
const metaTagHelper = require('../utils/metaTagHelper');
const UrlHelper = require('../../common/UrlHelper');
const ApplicationConfig = require('../app/ApplicationConfig');
const {authAjax} = require('../authentication/Account');
const RequiresRoleView = require('../views/RequiresRoleView');
const PrimaryDomainUrl = require('../utils/PrimaryDomainUrl');

const DEFAULT_META_DESCRIPTION = $('meta[name="description"]').attr('content');
const DEFAULT_META_IMAGE = fack.resourceUrl('images/share.png');

module.exports = class ApplicationPage extends Page {
    constructor(options) {
        super();
        options = options || {};
        this.options = options;
        this.updatePageTitle(options);
        this.bodyClass = options.bodyClass;
        this.name = options.name;
        this.gtmPersistentFields = options.gtmPersistentFields;
        this.gtmCategory = options.gtmCategory;
        if (options.parseUrl) {
            this.parseUrl = _.bind(options.parseUrl, this);
        }
        if (options.loadData) {
            this.loadData = _.bind(options.loadData, this);
        }
        this.requiresRegistration = options.isRegistrationRequired;
        this.reloadPageOnLogin = !options.disableReloadPageOnLogin;
        if (this.requiresRegistration) {
            this.pageRedirectionOnLogout = options.pageRedirectionOnLogout || '/';
        } else {
            this.pageRedirectionOnLogout = false;
            this.pageRedirectionIfAuthenticated = options.pageRedirectionIfAuthenticated;
        }
        if (options.scrollWindowToTopOnOpen != null) {
            this.scrollWindowToTopOnOpen = options.scrollWindowToTopOnOpen;
        } else {
            this.scrollWindowToTopOnOpen = true;
        }
        _.extend(this, new EventEmitter());
    }

    open(options) {
        if (this.bodyClass) {
            $('body').addClass(this.bodyClass);
        }
        ViewManager.openViews(this.getViews(options), _.extend({
            headerTitle: this.options.title || null,
        }, options));
        if (Views.map) { //why is that here ?!
            Views.map.resizeMap();
        }
    }

    getViews(options) {
        // Default behaviour: header/middle/footer views. Override it for another one
        return _.flatten([
            Views.header,
            this.isPageAccessAllowed(options) ? this.getMiddleViews(options) : new RequiresRoleView(),
            Views.footer,
        ]);
    }

    /**
     * Returns the views located between header and footer views
     * @return {View|View[]}
     */
    getMiddleViews() {
        // implement this method
    }

    /**
     * Returns whether a "you don't have required roles" View is show instead of `getMiddleViews()`
     * @return {Boolean}
     */
    isPageAccessAllowed() {
        return true;
    }

    close() {
        // on mobile, authenticationPopup can be created in each page
        if (Views.authenticationPopup) {
            Views.authenticationPopup.hide();
        }
        if (this.bodyClass) {
            $('body').removeClass(this.bodyClass);
        }
    }

    updatePageTitle(options) {
        this.title = Title.getTitleFromOptions(options);
    }

    //can be overridden
    updateMetaTags(metaTags) {
        metaTags = _.defaults({}, metaTags, {
            title: this.getTitle() || ApplicationConfig.title,
            description: this.getMetaDescription() || DEFAULT_META_DESCRIPTION,
            image: DEFAULT_META_IMAGE,
            //article:tag seems to be an open graph thing
            'article:tag': [],
            'apple-itunes-app': {
                'app-argument': location.href,
            },
            'og:url': this.generateCanonicalUrl(), // this meta is required
        });
        metaTagHelper.setMetaTags(metaTags);
    }

    generateCanonicalUrl() {
        let canonicalUrlBase;
        if (this.getCanonicalUrl) {
            canonicalUrlBase = this.getCanonicalUrl();
        } else {
            canonicalUrlBase = location.href;
        }
        return PrimaryDomainUrl.generatePrimaryDomainUrl(canonicalUrlBase);
    }

    //can be overridden
    getMetaDescription(options) {
        return translate('metaDescriptions.' + this.getName(), _.extend({defaultValue: ''}, options));
    }

    //can be overridden
    getJSONLD() {
        return [];
    }

    getName() {
        return this.name || UrlHelper.slugify(this.getTitle());
    }

    getTitle() {
        return this.title;
    }

    getGTMPersistentFields() {
        return this.gtmPersistentFields || [];
    }

    getGTMCategory() {
        return this.gtmCategory || 'Unclassified';
    }

    /**
     * Load the views data if any.
     * Instead of overriding this method, you can implement a method _loadData that will be called once all views data are loaded.
     * @access public
     * @param {object} options
     * @param {loadData~cb} cb
     */
    loadData(options, cb) {
        const views = this.getViews(options);
        const that = this;
        const viewWithDataToLoad = _.filter(views || [], function (view) {
            return view && view.loadData;
        });
        if (viewWithDataToLoad.length) {
            async.each(viewWithDataToLoad, function (view, cb) {
                view.loadData(options, cb);
            }, done);
        } else {
            done(null);
        }

        function done(err) {
            if (err) {
                cb(err, options);
            } else if (that._loadData) {
                that._loadData(options, cb);
            } else {
                cb(null, options);
            }
        }
    }

    simpleContentLoadDataRequest(options, callback) {
        authAjax({
            url: options.url,
            data: options.data,
            disableErrorPage: options.disableErrorPage,
            serverErrorMessage: 'simpleContentLoadData',
            callback: (err, html) => {
                if (html) {
                    options.htmlContent = html;
                }
                callback(err, options);
            },
        });
    }
};
