const template = require('./FeatureDescription.jade');

// @vue/component
module.exports = {
    props: {
        title: {
            type: String,
            default: undefined,
        },
        useHtmlHeading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        titleTag() {
            return this.useHtmlHeading ? 'h3' : 'div';
        },
    },
    template: template(),
};
