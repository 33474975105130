const _ = require('lodash');
const {optionParameterSupported} = require('../events/featureCheck');

module.exports = class EventPack {
    constructor() {
        this._listeners = [];
    }

    on(emitter, eventNameOrEventMapping, listenerOrSelector, optionsOrListener) {
        if (_.isArray(eventNameOrEventMapping)) { // multiple event names
            _.each(eventNameOrEventMapping, eventName => {
                this.on(emitter, eventName, listenerOrSelector, optionsOrListener);
            });
        } else if (_.isObject(eventNameOrEventMapping)) { // event => listener
            const eventMapping = eventNameOrEventMapping;
            const selector = listenerOrSelector;
            _.each(eventMapping, (listener, eventName) => {
                if (_.isFunction(listener)) {
                    if (selector) {
                        this._onSelector(emitter, eventName, selector, listener);
                    } else {
                        this.on(emitter, eventName, listener);
                    }
                } else { // selector => listener
                    _.each(listener, (listener, selector) => {
                        this._onSelector(emitter, eventName, selector, listener);
                    });
                }
            });
        } else if (arguments.length == 4 && _.isFunction(optionsOrListener)) {
            // case when listenerOrSelector is a selector and optionsOrListener a listener
            this._onSelector.apply(this, arguments);
        } else {
            const eventName = eventNameOrEventMapping;
            const listener = listenerOrSelector;
            const options = optionsOrListener;
            this._listeners.push({
                removeListener: function () {
                    if (emitter.removeListener) {
                        emitter.removeListener(eventName, listener);
                    } else if (emitter.off) {
                        emitter.off(eventName, listener);
                    }
                },
            });
            if (emitter.addEventListener) {
                if (optionParameterSupported && options) {
                    emitter.addEventListener(eventName, listener, options);
                } else {
                    emitter.addEventListener(eventName, listener);
                }
            } else {
                emitter.on(eventName, listener);
            }
        }
    }

    _onSelector(emitter, eventName, emitterSelector, listener) {
        if (eventName == 'linkClick') {
            this.onLinkClick(emitter, emitterSelector, listener);
        } else {
            this._listeners.push({
                removeListener: function () {
                    emitter.off(eventName, emitterSelector, listener);
                },
            });
            emitter.on(eventName, emitterSelector, listener);
        }
    }

    onLinkClick(emitter, emitterSelector, listener) {
        if (!listener) {
            listener = emitterSelector;
            emitterSelector = null;
        }
        this._listeners.push({
            removeListener: function () {
                emitter.off('click', emitterSelector, listener);
            },
        });
        emitter.linkClick(emitterSelector, listener);
    }

    removeAllListeners() {
        _.each(this._listeners, ({removeListener}) => {
            removeListener();
        });
        this._listeners = [];
    }
};
