const _ = require('lodash');
const Account = require('../authentication/Account');
const {isBot} = require('browser-detect');

module.exports = {
    shouldSkipStat,
};

function shouldSkipStat(realEstateAd) {
    const isAdmin = realEstateAd.userRelativeData.isAdmin;
    const accountId = Account.getAuthenticatedAccountId();
    const isOwner = Boolean(_.includes(realEstateAd.userRelativeData.accountIds, accountId));
    const isNetworkOwner = realEstateAd.userRelativeData.importAccountId == accountId;
    //todo probably add ownerIds in here
    //quick fix : do not send stats if you are a meta-account of the owner of the ad
    const isInSearchAccountIds = Boolean(_.includes(realEstateAd.userRelativeData.searchAccountIds, accountId));
    return isAdmin || isOwner || isNetworkOwner || isInSearchAccountIds || isBot();
}
