const _ = require('lodash');

module.exports = {
    getExtendedFilters,
    extendFilters,
};

const FILTER_EXTENSION_LIST = {
    minArea: (value) => Math.floor(value * 0.9),
    maxArea: (value) => Math.ceil(value * 1.1),
    minPrice: (value) => Math.floor(value * 0.95),
    maxPrice: (value) => Math.ceil(value * 1.05),
    maxRooms: (value) => value + 1,
};

function getExtendedFilters(originalFilters) {
    const filters = {};
    _.each(FILTER_EXTENSION_LIST, (extendFunc, key) => {
        const originalFilterValue = originalFilters[key];
        if (originalFilterValue) {
            filters[key] = extendFunc(originalFilterValue);
        }
    });
    return filters;
}

function extendFilters(filters) {
    const extendedFilters = getExtendedFilters(filters);
    return _.extend({}, filters, extendedFilters);
}
