const $ = require('jquery');
const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const template = require('./templates/search/filterHelper.jade');
const shortcutTemplate = require('./templates/search/filterHelperShortcuts.jade');
const RangeTextFormatter = require('../common/RangeTextFormatter');
const NumberFormatter = require('../common/NumberFormatter');
const {EventEmitter} = require('events');
const BrowserDetect = require('browser-detect');

module.exports = class FilterHelper extends EventEmitter {
    constructor(conf, $container, displayOptions) {
        super();
        this.init(conf);
        this.inputMinValue = 0;
        this.inputMaxValue = 0;
        this.$element = $(template({
            summary: this.summary,
            minFieldName: conf.minFieldName,
            maxFieldName: conf.maxFieldName,
            displayOptions,
            t: translate,
        }));
        $container.append(this.$element);
        this.$inputMin = this.$element.find('.inputMin');
        this.$inputMax = this.$element.find('.inputMax');
        this.$shortcutLists = this.$element.find('.shortcutLists');
        this.initDom();
    }

    init(conf, filterType) {
        const tab = conf.configuration(filterType);
        this.summary = tab.summary;
        this.summary.value = tab.summary.value.charAt(0).toUpperCase() + this.summary.value.substring(1).toLowerCase();
        this.steps = tab.steps;
        this.tab = tab;
        this.tabDefaultValues = this.tab.values;
        if (!this.summary.unit) {
            this.summary.unit = '';
        }
    }

    initDom() {
        this.$element.find('.buttonLabel').text(' ' + this.summary.label);
        this.$element.find('.filterHelper').attr('id', this.summary.value);
        this.initShortcutLists();
        this.initBind();
    }

    changeTab(conf, filterType) {
        const tab = conf.configuration(filterType);
        const clearFieldsOnChange = this.tab.clearFieldsOnChange;
        if (this.tab == tab) {
            return;
        }
        this.init(conf, filterType);
        if (clearFieldsOnChange) {
            this.clearFields();
        }
        this.initShortcutLists();
        this.hideDropDownMenu();
        this.updateButtonLabel(this.summary.label);
    }

    clearFields() {
        const values = this.getValues();
        if (values.min !== null || values.max !== null) {
            this.$inputMin.val(null);
            this.$inputMax.val(null);
            this.updateButtonLabel(this.summary.label);
            this.emitChange(this.$inputMin, true);
        }
    }

    clearShortcutsLists() {
        this.$shortcutLists.empty();
    }

    initShortcutLists() {
        this.clearShortcutsLists();
        const options = {
            shortcuts: this.createValuesList() || [],
            type: this.summary.value,
        };
        this.buildShortcutsLists(options);
    }

    createValuesList() {
        if (this.getMinValue() && this.summary.dynamicList) {
            let value = +this.getMinValue();
            const values = [];
            let step = +this.getMinValue() < this.steps.gap ? this.steps.values[0] : this.steps.values[1];
            for (let i = 0; i < 10; ++i) {
                if (i > 7) {
                    step = step * 2;
                }
                value += step;
                values.push({
                    value,
                });
            }
            return this.formatLabel(values);
        } else {
            return this.formatLabel(this.tabDefaultValues);
        }
    }

    formatLabel(tabValues) {
        _.each(tabValues, tabValue => {
            const value = formatValue(tabValue.value);
            const unitText = tabValue.value > 1 && this.summary.plural ? this.summary.unit + 's' : this.summary.unit;
            tabValue.label = value + ' ' + unitText;
        });
        return tabValues;
    }

    buildShortcutsLists(options) {
        this.buildMinShortCutsList(options);
        this.buildMaxShortCutsList(options);
    }

    buildMinShortCutsList(options) {
        this.$shortcutLists.append(shortcutTemplate(_.extend(options, {
            context: 'min',
        })));
    }

    buildMaxShortCutsList(options) {
        this.$maxList = $(shortcutTemplate(_.extend(options, {
            context: 'max',
        })));
        this.$shortcutLists.append(this.$maxList);
    }

    initBind() {
        const $element = this.$element;

        $element.on('shown.bs.dropdown', () => {
            const $input = this.summary.focusOnMaxInput ? this.$inputMax : this.$inputMin;
            if (BrowserDetect.isMSIE() || BrowserDetect.isEdge()) {
                this.$element.find('.dropdown-menu').one('animationend', () => {
                    $input.focus();
                });
            } else { //workaround race condition, input need to be visible to take focus, and it's not visible enough yet
                _.defer(() => {
                    $input.focus();
                });
            }
        });

        $element.on('hide.bs.dropdown', () => {
            this.toggleShortcutLists(true);
        });

        $element.on('click', '.dropdown-menu', event => {
            event.stopPropagation(); //avoid closing popup when receiving a click on it
        });

        //hide dropdown when using tab to go to another input
        $element.on('focusout', event => {
            // event.relatedTarget is only set when somethings going to receive focus.
            try {
                if (event.relatedTarget && !$.contains(this.$element.get(0), event.relatedTarget)) {
                    //click or tab outside
                    this.hideDropDownMenu();
                }
            } catch (e) { // crash on firefox 52 and lower see #5034
                void(e);
            }
        });

        _.each($element.find('input'), (input) => {
            const $input = $(input);
            const isMinToHide = ($input.hasClass('inputMin'));
            $input.on('click focus', event => {
                if (!isMinToHide) {
                    this.$maxList.remove();
                    this.buildMaxShortCutsList({
                        shortcuts: this.createValuesList() || [],
                        type: this.summary.value,
                    });
                }
                this.toggleShortcutLists(isMinToHide);
                event.stopPropagation();
            });
            $input.on('keypress', event => {
                if (event.keyCode === 13) {
                    if (isMinToHide) {
                        $element.find('.inputMax').focus();
                    } else {
                        this.emitChange(this.$inputMin);
                        this.hideDropDownMenu();
                        $input.blur();
                    }
                }
            });
        });

        $element.on('click', 'li', event => this.onSuggestionClick(event));
    }

    onSuggestionClick(event) {
        const $elem = $(event.currentTarget);
        const dataValue = $elem.attr('data-value');
        const inputTarget = $('.' + $elem.attr('data-target'));

        const $targetContext = this.$element.find(inputTarget);
        if ($targetContext.val() !== dataValue) {
            this.setValue(dataValue, inputTarget);
        }
        if ($targetContext.hasClass('inputMin')) {
            this.toggleShortcutLists(false);
            this.$inputMax.focus();
        } else if ($targetContext.hasClass('inputMax')) {
            this.hideDropDownMenu();
        }
        event.stopPropagation();
    }

    setDisabled(disabled) {
        const $filterButton = this.$element.find('.filterButton');
        if (disabled) {
            this.$element.find('.filterButton').attr('disabled', true);
        } else {
            $filterButton.removeAttr('disabled');
        }
    }

    hideDropDownMenu() {
        if (this.$element.hasClass('open')) {
            this.$element.find('.filterButton').dropdown('toggle'); //workaround bootstrap inconsistency
        }
    }

    toggleShortcutLists(isMinHidden) {
        this.$element.find('.min' + this.summary.value + 'shortcutList').toggleClass('hidden', !isMinHidden);
        this.$element.find('.max' + this.summary.value + 'shortcutList').toggleClass('hidden', isMinHidden);
    }

    setValue(value, selector, skipUpdateUrl) {
        const $elem = this.$element.find(selector);
        $elem.val(value);
        this.emitChange($elem, skipUpdateUrl);
    }

    setValues(minValue, maxValue, skipUpdateUrl) {
        const values = this.getValues();
        if (values.min !== minValue || values.max !== maxValue) {
            this.$inputMin.val(minValue);
            this.$inputMax.val(maxValue);
            this.emitChange(this.$inputMin, skipUpdateUrl);
        }
    }

    _sanitizeValues() {
        const inputMinValueStr = this.$inputMin.val();
        const inputMinValue = toInteger(inputMinValueStr);
        const inputMaxValueStr = this.$inputMax.val();
        let inputMaxValue = toInteger(inputMaxValueStr);
        if (null != inputMinValue && null != inputMaxValue && inputMinValue > inputMaxValue) {
            const delta = this.inputMaxValue - this.inputMinValue;
            inputMaxValue = inputMinValue + delta;
            this.$inputMax.val(inputMaxValue);
        }
        const cleanMinValueStr = toStr(inputMinValue);
        if (cleanMinValueStr !== inputMinValueStr) {
            this.$inputMin.val(cleanMinValueStr);
        }
        const cleanMaxValueStr = toStr(inputMaxValue);
        if (cleanMaxValueStr !== inputMaxValueStr) {
            this.$inputMax.val(cleanMaxValueStr);
        }
        this.inputMinValue = inputMinValue;
        this.inputMaxValue = inputMaxValue;
    }

    getMinValue() {
        return toInteger(this.$inputMin.val());
    }

    getMaxValue() {
        return toInteger(this.$inputMax.val());
    }

    getValues() {
        return {min: this.getMinValue(), max: this.getMaxValue()};
    }

    emitChange($elem, skipUpdateUrl) {
        this._sanitizeValues();
        this.updateButtonLabel();
        $elem.trigger('change', skipUpdateUrl);
    }

    updateButtonLabel(forcedLabel) {
        const $buttonLabel = this.$element.find('.buttonLabel');
        let newLabel = '';
        if (!forcedLabel) {
            const values = this.getValues();
            newLabel = RangeTextFormatter.formatRange({
                min: values.min,
                max: values.max,
                unit: this.summary.unit || this.summary.value.toLowerCase(),
            });
        }
        if (!newLabel) {
            newLabel = this.summary.label;
            $buttonLabel.toggleClass('isSet', false);
        } else {
            $buttonLabel.toggleClass('isSet', true);
        }
        $buttonLabel.text(newLabel);
    }
};

function toInteger(val) {
    let num = Math.round(parseFloat(val));
    if (_.isNaN(num) || num <= 0) {
        num = null;
    }
    return num;
}

function toStr(num) {
    return num ? '' + num : '';
}

function formatValue(value) {
    if (parseFloat(value)) {
        return NumberFormatter.formatNumber(value);
    } else {
        return value;
    }
}
