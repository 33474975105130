const _ = require('lodash');
const Vue = require('vue');
const namespaceComponents = require('./helpers/namespaceComponents');
const {NAMESPACE} = require('../Constants');

module.exports = {
    registerVueComponents,
};

function registerVueComponents() {
    registerComponents(require('./Modal'));
    registerComponents({
        'button': require('./Button'),
        'see-more-description': require('./SeeMoreDescription'),
        'icon': require('./Icon'),
        'img': require('./Img'),
        'overlay': require('./Overlay'),
        'submit-button': require('./SubmitButton'),
        'legal-mentions': require('./LegalMentions'),
        'commercial-ad': require('./CommercialAd'),
        'locations-links-cloud': require('./LocationsLinksCloud'),
        'spinner': require('./Spinner'),
        'error-message': require('./ErrorMessage'),
        'paragraph-title': require('./ParagraphTitle'),
        'credit-link': require('./CreditLink'),
        'explicit-link': require('./ExplicitLink'),
        'definition-paragraph': require('./DefinitionParagraph'),
        'app-history-back-button': require('./AppHistoryBackButton'),
        'end-user-license-agreement': require('./EndUserLicenseAgreement'),
        'pitch-block': require('./PitchBlock'),
        'container-element': require('./ContainerElement'),
        'map': require('./Map'),
        'icon-bienici': require('./IconBienici'),
        'card': require('./Card'),
        'badge': require('./Badge'),
        'link-with-icon-block': require('./LinkWithIconBlock'),
        'block-content-image': require('./BlockContentImage'),
        'compact-links': require('./CompactLinks'),
        'feature-description': require('./FeatureDescription'),
        'mandatory-fields-explanation': require('./MandatoryFieldsExplanation'),
        'floating-container': require('./FloatingContainer'),
        'collapsible': require('./Collapsible/Collapsible'),
        'breadcrumb': require('./Breadcrumb'),
        'clickable-image': require('./ClickableImage/ClickableImage'),
    });
    registerComponents(require('./dropdown'));
    registerComponents(require('../../ad/components'));
    registerComponents(require('../../alert/components'));
    registerComponents(require('../../realEstatePropertyDescriptor/estimator/components'));
    registerComponents(require('../../authentication/components'));
    registerComponents(require('../../cmp/components'));
    registerComponents(require('../../homePage/components'));
    registerComponents(require('../../insuranceEstimation/components'));
    registerComponents(require('../../reportAd/components'));
    registerComponents(require('../../creditSimulation/components'));
    registerComponents(require('../../footer/components'));
    registerComponents(require('../../chargingStations/components'));
    registerComponents(require('../../realEstateAdServices/components'));
    registerComponents(require('../../setFeatured/components'));
    registerComponents(require('../../realEstateAdContact/components'));
    registerComponents(require('../../errorPages/components'));
    registerComponents(require('../../rentalApplication/components'));
    registerComponents(require('../../energyPerformanceDiagnostic/components'));
    registerComponents(require('../../search/components'));
    registerComponents(require('../../onlineBooking/components'));
    registerComponents(require('../../contacts/components'));
    registerComponents(require('../../agency/components'));
    registerComponents(require('../../realEstatePropertyDescriptor/components'));
    registerComponents(require('../../realEstatePropertyDescriptor/submission/components'));
    registerComponents(require('./RatingStars'));
    registerComponents(require('../../header/components'));
    registerComponents(require('../../stats/components'));
    registerComponents(require('../../contractAndExtensionLabels/components'));
    registerComponents(require('../../phoneContact/components'));
    registerComponents(require('../../favorites/components'));
    registerComponents(require('../../user/components'));
    registerComponents(require('../../form/components'));
    registerComponents(require('../../karto/components'));
    registerComponents(require('../../placeDiscoveryPage/components'));
    registerComponents(require('../../LandingPage/components'));
}

function registerComponents(components) {
    _.each(namespaceComponents(NAMESPACE, components), (component, id) => {
        Vue.component(id, component);
    });
}
