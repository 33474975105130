const atlasInfo = require('../../public/images/packed/poi-atlas.json');
const StaticFileResolver = require('../utils/StaticFileResolver');

module.exports = {
    getIconFromUrl,
};

function getIconFromUrl(url) {
    const packedInfo = atlasInfo.coordinates[url];
    if (packedInfo) {
        return {
            url: StaticFileResolver.resolve(atlasInfo.url),
            origin: {
                x: packedInfo.x,
                y: packedInfo.y,
            },
            size: {
                width: packedInfo.width,
                height: packedInfo.height,
            },
        };
    } else {
        return {
            url: StaticFileResolver.resolve(url),
        };
    }
}
