module.exports = {
    CHART_OPTIONS: {
        tooltip: {enabled: false},
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: 'transparent',

        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name} (<b>{point.percentage:.0f} %</b>)',
                    distance: -30,
                    style: {
                        fontWeight: 'bold',
                        color: '#fff',
                        textShadow: '1px 1px #54677d,-1px -1px #54677d, -1px 1px #54677d, 1px -1px #54677d',
                    },
                },
                borderWidth: 0,
                size: '100%',
                startAngle: -180,
                endAngle: 180,
                center: ['50%', '50%'],
                colors: ['#54677d', '#0dabd0', '#2DA764'],
            },
        },
    },
    SERIES_OPTIONS: {
        type: 'pie',
        name: 'z',
        innerSize: '50%',
    },
};
