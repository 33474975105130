const _ = require('lodash');
const realEstateAdModificationPublicTemplate = require('../templates/realEstateAdModificationPublicView.jade');
const RealEstateAdCreationModificationView = require('./RealEstateAdCreationModificationView');

module.exports = class RealEstateAdModificationPublicView extends RealEstateAdCreationModificationView {
    constructor(options) {
        options = _.extend({
            template: realEstateAdModificationPublicTemplate,
            isCreation: false,
        }, options);
        super(options);
    }
};
