const _ = require('lodash');

const ContactPage = require('./ContactPage');

module.exports = class AdvicePage extends ContactPage {
    constructor(configuration) {
        configuration = _.defaults(configuration || {}, {
            name: 'adviceForm',
            title: 'Votre avis nous intéresse',
            bodyClass: 'votre-avis-nous-interesse',
            formType: 'opinion',
            gtmCategory: 'Forms',
        });
        super(configuration);
        this.configuration = configuration;
    }

    open(options) {
        super.open(_.assignIn(options, this.configuration));
    }

    getUrlPattern() {
        return '/votre-avis-nous-interesse';
    }
};
