const ACCOUNT_CONTACT_TYPES = require('../data/AccountContactTypes');
const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');

module.exports = {
    fetchAccountContacts(options, cb) {
        const data = {
            filters: ACCOUNT_CONTACT_TYPES,
            ...options,
        };
        this.isLoading = true;
        return Account.authAjax({
            method: 'GET',
            url: ServerConfig.config.contactsExportUrl + '/contactRequests',
            data,
            serverErrorMessage: 'Contacts request failed',
            callback: cb,
        });
    },
};
