const template = require('./DropdownMenu.jade');

const INITIAL_WIDTH_IN_PERCENTAGE = 0.9; // Transition is called in bootstrapOverwrite.styl and the initial size can found in slideUpAnimation.styl.

// @vue/component
module.exports = {
    inject: ['dropdownState'],
    props: {
        right: Boolean,
        block: Boolean,
        sticky: Boolean,
        stayOpenOnClick: Boolean,
    },
    data() {
        return {
            maxHeight: 'none',
            maxWidth: undefined,
            leftOffset: undefined,
            rightOffset: undefined,
        };
    },
    computed: {
        dropdownMenuStyle() {
            const {
                maxHeight,
                maxWidth,
                leftOffset: left,
                rightOffset: right,
            } = this;
            return {
                maxHeight,
                maxWidth,
                left: left && left + 'px',
                right: right && right + 'px',
            };
        },
        dropdownMenuClass() {
            return {
                'dropdown-menu-right': this.right,
            };
        },
        modifiers() {
            return {
                block: this.block,
                sticky: this.sticky,
            };
        },
    },
    watch: {
        'dropdownState.open'(open) {
            if (open) {
                this.$nextTick(() => {
                    this.fitInsideWindow();
                });
                // Wait for the animation to be done in view/css/utils/bootstrapOverwrite.styl#61
                this.timeoutId = setTimeout(this.calculateMaxHeight, 300);
            } else {
                this.maxHeight = 'none';
                this.maxWidth = undefined;
                this.leftOffset = undefined;
                this.rightOffset = undefined;
            }
        },
    },
    destroyed() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },
    methods: {
        onClick(event) {
            if (this.stayOpenOnClick) {
                event.stopPropagation(); //avoid closing popup when receiving a click on it
            }
        },
        calculateMaxHeight() {
            const viewportHeight = window.innerHeight;
            const dropdownTop = this.$el.getBoundingClientRect().top;
            this.maxHeight = `${viewportHeight - dropdownTop}px`;
        },
        fitInsideWindow() {
            const {
                left: dropdownLeftBeforeAnimation,
                right: dropdownRightBeforeAnimation,
                width: widthBeforeAnimation,
            } = this.$el.getBoundingClientRect();
            const widthAfterAnimation = widthBeforeAnimation / INITIAL_WIDTH_IN_PERCENTAGE;
            const sideDiffBetweenAnimation = (widthAfterAnimation - widthBeforeAnimation) / 2;
            const dropdownLeftAfterAnimation = dropdownLeftBeforeAnimation - sideDiffBetweenAnimation;
            const dropdownRightAfterAnimation = dropdownRightBeforeAnimation + sideDiffBetweenAnimation;
            const {innerWidth: windowWidth} = window;
            const rightOverflowInPx = dropdownRightAfterAnimation - windowWidth;
            if (widthAfterAnimation > windowWidth) {
                this.maxWidth = `${windowWidth}px`;
                if (this.right) {
                    this.rightOffset = rightOverflowInPx;
                } else {
                    this.leftOffset = -dropdownLeftAfterAnimation;
                }
            } else {
                let xOffset = 0;
                if (dropdownLeftAfterAnimation < 0) { // the menu overflows on the left
                    xOffset = -dropdownLeftAfterAnimation;
                } else if (rightOverflowInPx > 0) { // the menu overflows on the right
                    xOffset = -rightOverflowInPx;
                }
                if (xOffset) {
                    if (this.right) {
                        this.rightOffset = -xOffset;
                    } else {
                        this.leftOffset = xOffset;
                    }
                }
            }
        },
    },
    template: template(),
};
