const _ = require('lodash');
const template = require('./CompactOverview.jade');
const RealEstateAdTitleGenerator = require('../../../common/RealEstateAdTitleGenerator');
const {resourceUrl} = require('fack/browser');
const AddressFormatter = require('../../../common/formatters/AddressFormatter');

// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        photoUrl: {
            type: String,
            default: undefined,
        },
        photoUrlSizeQueryString: {
            type: String,
            default: undefined,
        },
        titleTranslationKey: {
            type: String,
            default: 'adOverviewTitle',
        },
        showFullAddress: Boolean,
        hidePhoto: Boolean,
    },
    computed: {
        generatedTitleWithHighlight() {
            const {realEstateAd, titleTranslationKey} = this;
            return RealEstateAdTitleGenerator.getTitle(realEstateAd, titleTranslationKey);
        },
        internalPhotoUrl() {
            const {realEstateAd: {photos}, photoUrlSizeQueryString} = this;
            let photoUrl = _.get(photos, '[0].url');
            if (photoUrl && photoUrlSizeQueryString) {
                photoUrl += '?' + photoUrlSizeQueryString;
            }
            return photoUrl || resourceUrl('images/no_photo.png');
        },
        formattedAddress() {
            if (this.showFullAddress) {
                return AddressFormatter.generateFullAddress(this.realEstateAd);
            } else {
                return AddressFormatter.generateCityAndDistrict(this.realEstateAd);
            }
        },
        photoStyle() {
            const {photoUrl, internalPhotoUrl} = this;
            return `background-image:url("${photoUrl || internalPhotoUrl}")`;
        },
    },
    template: template(),
};
