const _ = require('lodash');
const Account = require('../authentication/Account');

module.exports = {
    canBoostAd,
};

function canBoostAd(realEstateAd) {
    const canBoostAd = _.get(realEstateAd, 'userRelativeData.canBoostAd');
    return canBoostAd && Account.canBoostAds();
}
