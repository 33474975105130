let {i18n: {translate}} = require('fack');
const moment = require('./momentFr');
const escapeHtml = require('escape-html');
const _ = require('lodash');

const PriceFormatter = require('./PriceFormatter');
const {
    formatNumber,
    formatRoundNumber,
} = require('./NumberFormatter');
const {
    formatNewProperty,
    formatQuantity,
    formatSurfaceArea,
} = require('./TextFiltersFormatter');
const RealEstateAdTitleGenerator = require('./RealEstateAdTitleGenerator');
const AddressFormatter = require('./formatters/AddressFormatter');
const AdLabelHelper = require('./AdLabelHelper');

const DELIVERY_DATE = 'deliveryDate';
const YEAR_OF_CONSTRUCTION = 'yearOfConstruction';

module.exports = {
    config,
    formatDetailedDate,
    formatSoldDate,
    formatField,
    formatPrice,
    formatPricePerSquareMeter,
    formatFeesAndCharges,
    formatNewOrOld,
    generateDescription,
    getRealEstateAdSummary,
    formatAndTranslateField,
    getDeliveryDateText,
    getResidenceTypes,
    getFormattedResidenceTags,
    removeDecoratedContent,
};

const SQUARE_METER_SUFFIX = 'm²';

const FORMATTER_PER_FIELD_NAME = {
    price: formatPriceOrRent,
    pricePerSquareMeter: formatPricePerSquareMeter,
    floor: formatFloor,
    floorQuantity: formatFloorQuantity,
    charges: formatCharges,
    safetyDeposit: PriceFormatter.formatForHtml,
    agencyRentalFee: formatAgencyRentalFee,
    workToDo: formatWorkToDo,
    flatSharing: formatFlatSharing,
    isDisabledPeopleFriendly: formatBoolean,
    exposition: formatCapitalizedString,
    surfaceArea: formatSurfaceAreaOrUndisclosedText,
    balconyQuantity: _.partial(filterBalconyOrTerraceQuantity, 'balcony'),
    balconySurfaceArea: _.partial(formatBalconyOrTerrace, 'balcony'),
    landSurfaceArea: formatSurfaceArea,
    terracesQuantity: _.partial(filterBalconyOrTerraceQuantity, 'terrace'),
    terraceSurfaceArea: _.partial(formatBalconyOrTerrace, 'terrace'),
    modificationDate: hideAndFormatDate,
    availableDate: formatAvailableDate,
    deliveryDate: formatDeliveryDate,
    publicationDate: hideAndFormatDate,
    closingDate: hideAndFormatDate,
    roomsQuantity: (value, realEstateAd, options) => formatQuantity(value, 'room', options),
    bedroomsQuantity: (value, realEstateAd, options) => formatQuantity(value, 'bedroom', options),
    agencyFeePercentage: formatAgencyFeePercentage,
    feesChargedToThePurchaser: _.noop,
    feesChargedTo: formatFeesChargedTo,
    programState: _.partial(formatEnum, 'programState'),
    newOrOld: formatNewOrOld,
    chargesMethod: formatChargesMethod,
    rentExtra: PriceFormatter.formatForHtml,
    inventoryOfFixturesFees: formatInventoryOfFixturesFees,
    priceWithoutFees: _.noop,
    condominiumPartsQuantity: formatCondominiumPartsQuantity,
    opticalFiberStatus: formatOpticalFiberStatus,
    energyPerformanceDiagnosticDate: formatEnergyDiagnosticPerformanceDate,
};

function config(options) {
    translate = options.t;
}

function formatSurfaceAreaOrUndisclosedText(surfaceArea, landSurfaceArea, propertyType) {
    return formatSurfaceArea(surfaceArea, landSurfaceArea, propertyType) || translate('surfaceArea_undisclosed');
}

function formatEnergyDiagnosticPerformanceDate(date) {
    if (date && moment(date).isValid()) {
        return formatDate(date, 'Do MMMM YYYY');
    }
}

function formatFloor(floor, ad) {
    let {floorQuantity} = ad;
    floorQuantity = floorQuantity - 1;

    let translateKey;
    let floorSuffix = '';
    if (null != floor) {
        floorSuffix = floor == 1 ? 'er' : 'e';
        if (floor === 0) {
            translateKey = 'groundFloor';
        } else if (floorQuantity == floor) {
            translateKey = 'lastFloor';
        } else if (floorQuantity && floorQuantity >= floor) {
            translateKey = 'floorAndFloorQuantity';
        } else {
            translateKey = 'floorOnly';
        }
    }
    return translate(translateKey, {value: floor + floorSuffix, floorQuantity: floorQuantity});
}

function formatFloorQuantity(floorQuantity, ad) {
    if (_.isUndefined(ad.floor)) {
        if (ad.propertyType === 'house' && ad.floorQuantity === 1) {
            return translate('singleStoreyHouse');
        }
        return translateField(floorQuantity - 1, 'formattedFloorQuantity');
    }
}

function formatFlatSharing(flatSharing, ad) {
    return flatSharing && !ad.flatSharingNotAllowed;
}

function formatWorkToDo(workToDo) {
    return workToDo ? 'workToDo' : 'noWorkToDo';
}

function formatAgencyFeePercentage(value, realEstateAd, {isInGreyBox} = {}) {
    //only when not in charges grey box
    const feesChargedToThePurchaser = realEstateAd && (_.includes(['purchaser', 'both'], realEstateAd.feesChargedTo)
        || /*compatibility*/realEstateAd.feesChargedToThePurchaser);
    const inAnotherGreyBox = Boolean(realEstateAd && realEstateAd.price);
    if (!isInGreyBox || (!inAnotherGreyBox && feesChargedToThePurchaser)) {
        return formatNumber(value, 2);
    }
}

function formatFeesChargedTo(value) {
    if (value == 'both') {
        return translate('feesChargedToBoth');
    }
}

function formatBoolean(b) {
    return b;
}

function formatNewOrOld(key) {
    if (!key) {
        return;
    }
    const formattedKey = key.toUpperCase();
    if (formattedKey == 'ANCIEN') {
        return;
    }
    return translate('enum.newOrOld.' + formattedKey) || key;
}

function formatEnum(key, val) {
    return translate(['enum', key, val].join('.')) || val;
}

function generateDescription(info, {decorate = true, adContentHandler} = {}) {
    let description = info.description || '';
    const titleAlreadyInDescription = info.title && _.includes(description, info.title);
    const pitch = info.pitch || '';
    const titleAlreadyInPitch = info.title && _.includes(pitch, info.title);
    if (info.title && !titleAlreadyInDescription && !titleAlreadyInPitch) {
        description = info.title + '.<br>' + description;
    }
    if (decorate !== false) {
        description = processDecoratedContent(description, info, adContentHandler);
    }
    return description;
}

function getAllDecorationContentInDescription(description) {
    //nice test: adapt-immo-210044574
    const decorationRegex = /<span class="importDescriptionDecorator" (?:data-url="([^"]+)")?\s*data-type="([^"]+)">([^<]+?)<\/span>/ig;
    let match;
    const matches = [];
    while ((match = decorationRegex.exec(description)) !== null) {
        const [fullMatch, finalUrl, type, previousText] = match;
        matches.push({fullMatch, finalUrl, type, previousText});
    }
    return matches;
}

function removeDecoratedContent(description) {
    const matches = getAllDecorationContentInDescription(description);
    _.each(matches, decoratedContent => {
        description = replaceAll(description, decoratedContent.fullMatch, '');
    });
    return description;
}

function processDecoratedContent(description, info, adContentHandler) {
    const {
        id: adId,
        status: {
            onTheMarket,
        },
    } = info;
    const matches = getAllDecorationContentInDescription(description);
    _.each(matches, ({type, finalUrl, previousText, fullMatch}) => {
        let newContent;
        switch (type) {
            case 'virtualTour': {
                const matchingTours = _.invoke(adContentHandler, 'getContentByUrl', adId, 'virtualTours', finalUrl);
                const tour = _.first(matchingTours) || {};
                if (tour.accepted) {
                    newContent = `<a href="${finalUrl}" target="_blank" class="openVirtualTourFromDescription">`
                        + 'Voir la visite virtuelle</a> ';
                } else {
                    newContent = `<span title="${previousText}" class="decoratedContent">`
                        + '(visite virtuelle supprimée)</span> ';
                }
                break;
            }
            case 'agencyFeeUrl':
                newContent = `<a href="${finalUrl}" target="_blank" class="decoratedAgencyFeeUrl">`
                    + 'Voir les barèmes des honoraires</a> ';
                break;
            case 'unhandledUrl':
            case 'basicUrl':
                newContent = `<span title="${previousText}" class="decoratedContent">(lien supprimé)</span> `;
                break;
            case 'failingUrl':
                newContent = `<span title="${previousText}" class="decoratedContent">(lien erroné)</span> `;
                break;
            case 'phone':
                if (onTheMarket) {
                    newContent = `<span class="showPhoneNumber" data-phone="${previousText}">`
                        + 'Voir le téléphone</span> ';
                } else {
                    newContent = 'Numéro de téléphone masqué ';
                }
                break;
            case 'email':
                if (onTheMarket) {
                    newContent = `<span class="openContactForm" data-email="${previousText}">Contacter</span> `;
                } else {
                    newContent = 'Adresse e-mail masqué ';
                }
                break;
            default:
                console.log('unhandled decoratedContent.type', type);
                newContent = previousText;
        }
        description = replaceAll(description, fullMatch, newContent);
    });
    return description;
}

function replaceAll(text, pattern, replace) {
    return text.split(pattern).join(replace);
}

function formatDate(date, format = 'LL') {
    if (date) {
        return moment(date).format(format);
    }
}

function formatShortDate(date) {
    return formatDate(date, 'll');
}

function formatDetailedDate(date) {
    return formatDate(date, 'Do MMMM YYYY H:mm:ss');
}

function formatSoldDate(date) {
    const formattedDate = formatDate(date, 'MMMM YYYY');
    if (formattedDate) {
        return translate('soldDate_withValue', {value: formattedDate});
    }
}

function hideAndFormatDate(date, {thresholdDate}) {
    let formattedValue;
    if (thresholdDate && moment(date).isBefore(thresholdDate)) {
        const maxDaysAgo = moment().diff(thresholdDate, 'days');
        const asUnit = maxDaysAgo > 30 ? 'asMonths' : 'asDays';
        const duration = Math.floor(moment.duration(maxDaysAgo + 1, 'days')[asUnit]());
        formattedValue = translate('dateHidden', {value: duration, context: asUnit});
    }
    return formattedValue || translate('dateWithArticle', {value: formatShortDate(date)});
}

function formatPricePerSquareMeter(pricePerSquareMeter) {
    if (_.isNumber(pricePerSquareMeter)) {
        return formatRoundNumber(pricePerSquareMeter, '€/' + SQUARE_METER_SUFFIX);
    }
}

function formatField(value, key, realEstateAd, options) {
    const formatter = FORMATTER_PER_FIELD_NAME[key];
    let formattedValue;
    if (formatter) {
        formattedValue = formatter(value, realEstateAd, _.defaults(options, {
            isInGreyBox: true,
        }));
    } else if (_.isBoolean(value) || /^(is|has)[A-Z]/.test(key)) {
        formattedValue = formatBoolean(value);
    } else {
        formattedValue = value;
    }
    return formattedValue;
}

function formatPrice(price) {
    return PriceFormatter.formatForHtml(price);
}

function formatFeesAndCharges(price, realEstateAd) {
    let formattedFees;
    if (realEstateAd.adType == 'buy') {
        if (_.includes(['purchaser', 'both'], realEstateAd.feesChargedTo)
            || /*compatibility*/realEstateAd.feesChargedToThePurchaser) {
            if (realEstateAd.agencyFeePercentage) {
                formattedFees = translate('agencyFeePercentageInfos', {
                    percentage: formatAgencyFeePercentage(realEstateAd.agencyFeePercentage),
                });
                if (realEstateAd.priceWithoutFees) {
                    const withoutFees = translate('withoutFees', {
                        priceWithoutFees: formatPrice(realEstateAd.priceWithoutFees),
                    });
                    formattedFees = `${formattedFees} (${withoutFees})`;
                }
            } else if (realEstateAd.priceWithoutFees) {
                const fees = price - realEstateAd.priceWithoutFees;
                formattedFees = translate('feesDetails', {
                    fees: formatPrice(fees),
                });
            } else {
                const formattedPrice = formatPrice(price);
                formattedFees = translate('feesChargedToPurchaser', {price: formattedPrice});
            }
        } else if (realEstateAd.feesChargedTo == 'seller' || /*compatibility*/realEstateAd.feesChargedToThePurchaser === false) {
            formattedFees = translate('feesChargedToSeller');
        }
    } else if (realEstateAd.adType == 'rent') {
        if (realEstateAd.chargesMethod == 'real') {
            formattedFees = translate('rentalChargesReal');
        } else if (realEstateAd.charges) {
            formattedFees = 'Dont ' + formatCharges(realEstateAd.charges, {chargesMethod: realEstateAd.chargesMethod});
        } else {
            formattedFees = 'Charges non communiquées';
        }
    }
    return formattedFees;
}

function formatAgencyRentalFee(value, realEstateAd) {
    let result = PriceFormatter.formatForHtmlWithDecimal(value);
    if (realEstateAd.inventoryOfFixturesFees) {
        result += translate('rentalFeeWithInventoryOfFixturesFees', {
            value: PriceFormatter.formatForHtmlWithDecimal(realEstateAd.inventoryOfFixturesFees),
        });
    }
    return result;
}

function formatInventoryOfFixturesFees(value, realEstateAd) {
    if (value && (!realEstateAd || !realEstateAd.agencyRentalFee)) {
        return PriceFormatter.formatForHtmlWithDecimal(value);
    }
}

function formatCondominiumPartsQuantity(value, realEstateAd) {
    if (realEstateAd && realEstateAd.propertyType == 'programme') {
        return value;
    }
}

function formatPriceOrRent(value, realEstateAd) {
    let formattedPrice = formatPrice(value);
    if (!formattedPrice) {
        return;
    }
    if (realEstateAd.transactionType == 'rent') {
        formattedPrice = `Loyer : ${formattedPrice} par mois`;
        if (realEstateAd.chargesMethod != 'real') {
            formattedPrice += ' charges comprises';
        }
    } else {
        const formattedFees = formatFeesAndCharges(value, realEstateAd);
        if (formattedFees) {
            const pricePrefix = realEstateAd.adType === 'lifeAnnuitySale' ? 'Bouquet : ' : 'Prix\u202f: ';
            formattedPrice = `${pricePrefix}${formattedPrice}, ${_.lowerFirst(formattedFees)}`;
        } else if (realEstateAd.adType === 'lifeAnnuitySale') {
            formattedPrice = `Bouquet : ${formattedPrice}`;
        }
    }
    return formattedPrice;
}

function formatCharges(value, realEstateAd, {isInGreyBox} = {}) {
    if (realEstateAd && realEstateAd.chargesMethod == 'real') {
        return;
    }

    if (value) {
        let translationKey;
        switch (realEstateAd && realEstateAd.chargesMethod) {
            case 'advance':
                translationKey = 'rentalChargesAdvance';
                break;
            case 'flat-rate':
                translationKey = 'rentalChargesFlatRate';
                break;
            default:
                translationKey = 'rentalCharges';
        }
        let translation = translate(translationKey, {value: formatPrice(value)});
        if (realEstateAd && isInGreyBox) {
            translation += ' (inclus dans le loyer)';
        }
        return translation;
    }
}

function formatChargesMethod(value) {
    if (value == 'real') {
        return translate('rentalChargesReal');
    }
}

function formatCapitalizedString(value) {
    return _.startCase(value);
}

function formatBalconyOrTerrace(balconyOrTerrace, surface, ad) {
    const formattedSurface = formatSurfaceArea(surface);
    const quantity = ad[balconyOrTerrace + 'Quantity'] || ad[balconyOrTerrace + 'sQuantity'];
    const translateKey = balconyOrTerrace + 'SurfaceAreaAndQuantity';
    if (formattedSurface) {
        return translate(translateKey, {
            value: formattedSurface,
            count: quantity,
        });
    }
}

function filterBalconyOrTerraceQuantity(balconyOrTerrace, quantity, ad) {
    const formattedSurface = formatSurfaceArea(ad[balconyOrTerrace + 'SurfaceArea']);
    return formattedSurface ? null : quantity;
}

function getRealEstateAdSummary(ad) {
    let promotedAsExclusive;
    if (ad.promotedAsExclusiveUntil) {
        const currentDate = new Date();
        promotedAsExclusive = new Date(ad.promotedAsExclusiveUntil) >= currentDate;
    }
    const deliveryDateText = getDeliveryDateText(ad);
    if (_.isNil(ad.with3dModel)) {
        ad.with3dModel = Boolean(ad.model && ad.model.ready);
    }
    ad.with360 = hasAvailable360VirtualTours(ad.virtualTours);
    ad.withHighlightedVideo = hasAvailableHighlightedVideoVirtualTour(ad.virtualTours);
    return {
        title: RealEstateAdTitleGenerator.getTitle(ad, 'simple'),
        formattedAddress: AddressFormatter.generateCityAndPostalCode(ad),
        formattedPrice: formatPrice(ad.price),
        formattedNewProperty: ad.newProperty ? formatNewProperty(ad.newProperty) : null,
        deliveryDateText,
        formatted3dModel: getFormattedEmphasisedTag(ad.with3dModel, 'withModel3d'),
        formatted360Tag: getFormattedEmphasisedTag(AdLabelHelper.isAdWithVirtualTour360(ad), 'tag360'),
        formattedVideoTag: getFormattedEmphasisedTag(AdLabelHelper.isAdWithHighlightedVideo(ad), 'tagVideo'),
        formattedBienIciExclusive: getFormattedEmphasisedTag(ad.isBienIciExclusive, 'bienIciExclusive'),
        formattedExclusiveTag: getFormattedEmphasisedTag(ad.isExclusiveSaleMandate, 'exclusiveTag'),
        formattedResidenceTags: getFormattedResidenceTags(ad),
        formattedLifeAnnuity: getFormattedEmphasisedTag(ad.adType === 'lifeAnnuitySale', 'lifeAnnuitySale'),
        formattedPromotedAsExclusive: getFormattedEmphasisedTag(promotedAsExclusive, 'promotedAsExclusive'),
        formattedOnlineBooking: getFormattedEmphasisedTag(ad.onlineBookingUrl, 'onlineBooking'),
        formattedHasToBeBuilt: getFormattedEmphasisedTag(ad.hasToBeBuilt, 'hasToBeBuilt'),
        formattedPropertyWithoutUsufruct: getFormattedEmphasisedTag(ad.isPropertyWithoutUsufruct, 'propertyWithoutUsufruct'),
    };
}

function getFormattedEmphasisedTag(condition, tag) {
    return condition ? translate(`EmphasisedTags.${tag}`) : null;
}

function formatAvailableDate(value) {
    let formattedResult;
    if (value && moment(value).isAfter()) {
        formattedResult = formatDate(value);
    }
    return formattedResult;
}

function formatDeliveryDate(value) {
    if (value) {
        const deliveryDate = moment(value);
        const deliveryQuarter = deliveryDate.quarter();
        const suffix = deliveryQuarter == 1 ? 'er' : 'e';
        return translate('quarterAndYear', {quarter: deliveryQuarter + suffix, year: deliveryDate.year()});
    }
}

function getDeliveryDateText(realEstateAd) {
    return formatAndTranslateField(realEstateAd.deliveryDate, DELIVERY_DATE, realEstateAd)
        || (realEstateAd.newProperty && formatAndTranslateField(realEstateAd.yearOfConstruction, YEAR_OF_CONSTRUCTION));
}

function formatAndTranslateField(value, key, realEstateAd, options) {
    const formattedValue = formatField(value, key, realEstateAd, options);
    return translateField(formattedValue, key);
}

function translateField(valueOrObject, key) {
    let translatedField;
    const translationContext = [];
    let value;
    if (_.isObject(valueOrObject)) {
        if ('value' in valueOrObject && 'key' in valueOrObject) {
            value = valueOrObject.value;
            key = valueOrObject.key;
        } else {
            value = JSON.stringify(valueOrObject);
        }
    } else {
        value = valueOrObject;
    }

    if (value) { // returns only if value is not false and not empty
        if (key == 'yearOfConstruction' && new Date(value, 1, 1) > new Date()) {
            translationContext.push('future');
        }

        if (!_.isBoolean(value)) {
            translationContext.push('withValue');
        }

        const escapedValue = _.isString(value) ? escapeHtml(value) : value;
        translatedField = translate(
            key,
            {
                value: escapedValue,
                count: +value,
                context: translationContext.join('_'),
                defaultValue: key + escapedValue,
            });
    }

    return translatedField;
}

function getResidenceTypes(ad) {
    const tags = [];

    if (ad.isInStudentResidence) {
        tags.push('student');
    }
    if (ad.isInSeniorResidence) {
        tags.push('senior');
    }
    if (ad.isInTourismResidence) {
        tags.push('tourism');
    }

    return _.isEmpty(tags) ? null : tags;
}

function getFormattedResidenceTags(ad) {
    return _.map(getResidenceTypes(ad), type => translate(`enum.residenceTag.${type}`));
}

function formatOpticalFiberStatus(value) {
    return translate(`enum.opticalFiber.${value}`);
}

function hasAvailableHighlightedVideoVirtualTour(virtualTours) {
    return _.some(getAvailableTours(virtualTours), virtualTour => /^video/.test(virtualTour.type) && virtualTour.highlighted);
}

function hasAvailable360VirtualTours(virtualTours) {
    return _.some(getAvailableTours(virtualTours), virtualTour => /360|3d/.test(virtualTour.type));
}

function getAvailableTours(virtualTours = {}) {
    return _.filter(virtualTours, function (content) {
        if (content.error || content.type == 'site immobilier') {
            return false;
        } else {
            return content.accepted;
        }
    });
}
