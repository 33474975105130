const urlUtil = require('url');
const _ = require('lodash');
const async = require('async');
const ApplicationPage = require('./ApplicationPage');
const PageManager = require('../pages/PageManager');
const Account = require('../authentication/Account');
const ImportsListView = require('../views/ImportsListView');
const ServerConfig = require('../ServerConfig');
const RealtimeServer = require('../RealtimeServer');
const EventPack = require('../utils/EventPack');

const PATH_PREFIX = 'imports';
const MAX_IMPORTS_ACCOUNT = 200;
const DEFAULT_IMPORTS_LIMIT = 50;

module.exports = class ImportsPage extends ApplicationPage {
    constructor() {
        const configuration = {
            name: 'Imports',
            isRegistrationRequired: true,
        };
        super(configuration);
        this.configuration = configuration;
        this.importsListView = new ImportsListView();
        this.eventPack = new EventPack();
    }

    open(options) {
        const title = options.author ? 'Imports de ' + options.author.display_name : 'Tous les imports';
        this.updatePageTitle(title);
        this.options = _.extend(options, this.configuration, {
            title,
        });
        super.open(_.extend({
            headerTitle: options.title || null,
        }, options));
        if (this.isPageAccessAllowed()) {
            this.eventPack.on(this.importsListView, {
                from_changed: from => {
                    PageManager.openPage(this, _.extend(options, {from}));
                },
                to_changed: to => {
                    PageManager.openPage(this, _.extend(options, {to}));
                },
                updateDisplayLimit: updateOptions => {
                    PageManager.openPage(this, _.extend(options, updateOptions));
                },
                invalidateImport: importId => {
                    invalidateImport(importId, _.bind(this.reopenPage, this, options));
                },
                toggleImportBlockage: accountId => {
                    toggleImportBlockage(accountId, _.bind(this.reopenPage, this, options));
                },
                interruptImport: (namespace, importId) => {
                    interruptImport(namespace, importId, _.bind(this.reopenPage, this, options));
                },
            });
            this._realtimeContext = RealtimeServer.openContext();
            const room = options.author ? 'imports#' + options.author.id : 'imports';
            this._realtimeContext.join(room);
            this._realtimeContext.on(
                'import:update',
                _.bind(this.importsListView.updateImport, this.importsListView)
            );
        }
    }

    reopenPage(options) {
        PageManager.openPage(this, options);
    }

    close() {
        if (this._realtimeContext) {
            this._realtimeContext.close();
        }
        this.eventPack.removeAllListeners();
        ApplicationPage.prototype.close.apply(this, arguments);
    }

    getMiddleViews() {
        return this.importsListView;
    }

    getUrl() {
        const options = this.options;
        const query = _.pickBy(_.pick(options, ['from', 'to', 'filename']));
        const {
            size,
        } = options;
        if (size !== DEFAULT_IMPORTS_LIMIT) {
            query.size = size;
        }
        return urlUtil.format({
            pathname: PATH_PREFIX + (options.authorId ? '-' + options.authorId : ''),
            query,
        });
    }

    getUrlPattern() {
        return new RegExp('/' + PATH_PREFIX + '(-[^/]+)?');
    }

    parseUrl(url, authorIdWithPrefix) {
        const options = super.parseUrl(url);
        if (authorIdWithPrefix) {
            options.authorId = authorIdWithPrefix.replace(/^-/, '');
        }
        const {query} = urlUtil.parse(url, true);
        _.extend(options, _.pick(query, ['from', 'to', 'filename']));
        options.size = +query.size || DEFAULT_IMPORTS_LIMIT;
        return options;
    }

    _loadData(options, cb) {
        if (this.isPageAccessAllowed()) {
            async.waterfall([
                function (cb) {
                    if (options.authorId) {
                        Account.get(options.authorId, cb);
                    } else {
                        Account.find({
                            from: 0,
                            size: MAX_IMPORTS_ACCOUNT,
                            'with-import': true,
                        }, cb);
                    }
                },
                function (results, cb) {
                    if (results.accounts) {
                        options.importAccounts = results.accounts;
                    } else {
                        options.author = results;
                    }
                    loadImports(options, cb);
                },
            ], function (err) {
                cb(err, options);
            });
        } else {
            cb(null, options);
        }
    }

    isPageAccessAllowed() {
        return Account.hasRole('importsViewer');
    }
};

function loadImports(options, cb) {
    const {
        author,
        from,
        to,
        size,
        filename,
    } = options;
    const accountId = _.get(author, 'id');
    Account.authAjax({
        url: ServerConfig.config.importerUrl + '/imports',
        data: _.pickBy({
            accountId,
            from,
            to,
            size,
            filename,
        }),
        callback: function (err, data) {
            options.imports = data;
            cb(err, data);
        },
    });
}

function invalidateImport(importId, callback) {
    Account.postJson({
        url: ServerConfig.config.importerUrl + '/invalidateImport',
        data: {
            importId,
        },
        callback,
    });
}

function interruptImport(namespace, importId, callback) {
    Account.postJson({
        url: ServerConfig.config.importerUrl + '/interruptImport',
        data: {
            namespace,
            importId,
        },
        callback,
    });
}

function toggleImportBlockage(accountId, callback) {
    Account.authAjax({
        url: ServerConfig.config.accountUrl + '/toggleImportBlockage',
        method: 'POST',
        data: {
            accountId,
        },
        callback,
    });
}
