const {i18n: {translate}} = require('fack');

module.exports = {
    getPlaceTitle,
};

function getPlaceTitle(
    {type, name, parent, postalCodes, ref},
    {departmentTranslationKey, cityFormatter, showCodes = false, useParentTitle = false} = {}
) {
    let placeTitle = '';
    if (type === 'department') {
        placeTitle = translate(departmentTranslationKey + ref);
        if (showCodes) {
            placeTitle += ' (' + ref + ')';
        }
    } else if (name) {
        if (useParentTitle || type === 'district') {
            placeTitle = cityFormatter(parent.name) + (useParentTitle ? '' : ' - ' + name);
        } else {
            placeTitle = cityFormatter(name);
        }
        if (showCodes) {
            const postalCode = (postalCodes && postalCodes[0]);
            if (postalCode) {
                placeTitle += ' (' + postalCode + ')';
            }
        }
    }
    return placeTitle;
}
