const xhr = require('xhr');
const qs = require('qs');
const _ = require('lodash');
const {cityNameToInTheCity} = require('@bienici/city-name-formatter');

const ServerConfig = require('../ServerConfig');

const LOCAL_EXPERT_DEFAULT_SIZE_TYPE = 'block';
const LOCAL_EXPERT_SIZES = {
    default: '300x250',
    big: '728x90',
    banner: {w: 728, h: 90},
    [LOCAL_EXPERT_DEFAULT_SIZE_TYPE]: {w: 300, h: 250},
    halfPage: {w: 300, h: 600},
};

module.exports = {
    LOCAL_EXPERT_SIZES,
    LOCAL_EXPERT_DEFAULT_SIZE_TYPE,
    getLocalExpertBannerSizeTypes,
    resolveStaticPhotoUrl,
    searchAgencyForLocalExpertBanner,
    fetchBannersTemplatesCount,
    findCityNameForLocalBanner,
    searchAgencyForLocalBanner,
    storeLocalBannerEvent,
};

function fetchBannersTemplatesCount(cb) {
    return xhr({
        uri: ServerConfig.config.wwwUrl + '/local-banners/templates/counts',
        json: true,
        timeout: 10e3, //10sec
    }, function (error, response, counts) {
        if (error) {
            cb(error);
        } else if (response.statusCode >= 400) {
            cb(new Error(`Got ${response.statusCode} when fetching local banners templates counts`));
        } else {
            cb(null, counts);
        }
    });
}

function searchAgencyForLocalBanner(postalCodes, cb) {
    return xhr({
        uri: ServerConfig.config.wwwUrl + '/local-banners/agency?' + qs.stringify({
            postalCodes,
        }, {arrayFormat: 'brackets'}),
        json: true,
        timeout: 10e3, //10sec
    }, function (error, response, agency) {
        if (error) {
            cb(error);
        } else if (response.statusCode >= 400) {
            cb(new Error(`Got ${response.statusCode} when searching for eligible agency`));
        } else {
            cb(null, agency);
        }
    });
}

function findRandomEligibleAgencyForLocalExpert({postalCodes, transactionType, agencyId, disableCalltracking}, cb) {
    return xhr({
        uri: ServerConfig.config.wwwUrl + '/local-banners/expert/agency?' + qs.stringify({
            postalCodes,
            transactionType,
            agencyId,
            disableCalltracking,
        }, {arrayFormat: 'brackets'}),
        json: true,
        timeout: 10e3, //10sec
    }, function (error, response, agencies) {
        if (error) {
            cb(error);
        } else if (response.statusCode >= 400) {
            cb(new Error(`Got ${response.statusCode} when searching for eligible agencies`));
        } else {
            cb(null, _.sample(agencies));
        }
    });
}

/**
 * @param {string[]} filterType
 * @param {string[]} postalCodes
 * @param {string} agencyId
 * @param {boolean} disableCalltracking
 */
function searchAgencyForLocalExpertBanner({filterType, postalCodes, agencyId, disableCalltracking}, cb) {
    const filteredPostalCodes = _.without(postalCodes, '75000', '69000', '13000');
    const agencySearchOptions = {
        transactionType: _.size(filterType) === 1 ? filterType[0] : undefined,
        disableCalltracking,
    };
    if (agencyId) {
        _.extend(agencySearchOptions, {agencyId});
    } else {
        _.extend(agencySearchOptions, {postalCodes: filteredPostalCodes});
    }
    return findRandomEligibleAgencyForLocalExpert(agencySearchOptions, (err, agency) => {
        if (err || !agency) {
            console.error(err, 'Unable to find eligible agency from search options: ', agencySearchOptions);
            cb(err);
        } else {
            _.extend(agency, {
                cityDisplayName: cityNameToInTheCity(agency.city),
            });
            cb(null, agency);
        }
    });
}

function findCityNameForLocalBanner(agency, cb) {
    const postalCode = _.get(agency, 'company.address.postalCode');
    const cityFallback = _.get(agency, 'company.address.city', '');
    if (!postalCode) {
        cb(null, cityFallback);
    } else {
        xhr({
            uri: ServerConfig.config.searchPlacesUrl + '/suggest?' + qs.stringify({
                q: postalCode,
            }),
            json: true,
            timeout: 3e3, //3sec
            headers: {
                Authorization: `Bearer ${ServerConfig.config.mapApiKeyForClient}`,
            },
        }, (error, response, places) => {
            if (error) {
                cb(error);
            } else if (response.statusCode >= 400) {
                cb(new Error(`Got ${response.statusCode} when searching for eligible agencies`));
            } else {
                const matchingPlace = _.find(places, place =>
                    _.includes(place.postalCodes, postalCode)
                    // If it's the same city that the agency really wants to target, we use the properly cased city name
                    && _.lowerCase(place.name) === _.lowerCase(cityFallback)
                );
                if (!matchingPlace) {
                    cb(null, cityFallback);
                } else {
                    cb(null, matchingPlace.name);
                }
            }
        });
    }
}

function storeLocalBannerEvent(event, cb) {
    xhr({
        uri: ServerConfig.config.localBannersUrl + '/event',
        method: 'POST',
        body: event,
        json: true,
        timeout: 10e3, //10sec
    }, cb);
}

function resolveStaticPhotoUrl(file) {
    return ServerConfig.config.wwwUrl + '/static/' + file;
}

function getLocalExpertBannerSizeTypes() {
    return _.pick(LOCAL_EXPERT_SIZES, ['banner', LOCAL_EXPERT_DEFAULT_SIZE_TYPE]);
}
