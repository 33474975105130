const $ = require('jquery');
const {i18n: {translate}} = require('fack');

module.exports = {
    toggleError,
    scrollToElement,
};

const passwordMinLength = 6;
const companyCardNumberMinLength = 4;

function getFieldValidationType($field) {
    return $field.attr('type') || 'text';
}

function scrollToElement($element, afterScrollCallback) {
    const marginOffset = 10;
    if ($element.length) {
        $('html, body').animate({
            scrollTop: Math.abs($element.offset().top - marginOffset),
        }, 'slow', 'swing', afterScrollCallback);
    }
}

function toggleErrorMessage($field, isValid, type) {
    type = type || getFieldValidationType($field);
    const errorText = {
        email: translate('formErrorMessages.email'),
        tel: translate('formErrorMessages.tel'),
        postal_code: translate('formErrorMessages.postal_code'),
        number: translate('formErrorMessages.number'),
        password: translate('formErrorMessages.password', {minLength: passwordMinLength}),
        company_card_number: translate('formErrorMessages.company_card_number', {minLength: companyCardNumberMinLength}),
        passwordConfirmation: translate('formErrorMessages.passwordConfirmation'),
        emailUnavailable: translate('formErrorMessages.emailUnavailable'),
        photoUploadError: translate('formErrorMessages.photoUploadError'),
        emailCheckAvailableError: translate('formErrorMessages.emailCheckAvailableError'),
        numeric: translate('formErrorMessages.numeric'),
        positiveIntegerIncludingZero: translate('formErrorMessages.integer'),
        integer: translate('formErrorMessages.integer'),
        text: translate('formErrorMessages.text'),
        photoValidationError: translate('formErrorMessages.photoValidationError'),
        photoRequiredError: translate('formErrorMessages.photoRequiredError'),
        confirmCheckbox: translate('formErrorMessages.confirmCheckbox'),
    };
    clearErrorMessage($field);
    if (!isValid) {
        setErrorMessage($field, errorText[type]);
    }
}

function clearErrorMessage($field) {
    const $fieldParent = $field.parents('.col-sm-9');
    const $errorMessage = $fieldParent.find('.error-message');
    //remove previous error message
    if ($errorMessage.length > 0) {
        $errorMessage.remove();
    }
}

function setErrorMessage($field, message) {
    const $fieldParent = $field.parents('.col-sm-9');
    clearErrorMessage($field);
    $fieldParent.append('<div class="error-message">' + message + '</div>');
}

function toggleValidationClass($field, isValid) {
    $field.parents('.form-group').toggleClass('has-error', !isValid);
}

function toggleError($field, isValid, type) {
    toggleValidationClass($field, isValid);
    toggleErrorMessage($field, isValid, type);
}
