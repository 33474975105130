const _ = require('lodash');
const template = require('./CustomerReviews.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const AgencyRequests = require('./AgencyRequests');
const CustomerReview = require('./CustomerReview');
const AgencyPageSkeleton = require('./AgencyPageSkeleton');

// @vue/component
module.exports = {
    constants: {
        CUSTOMER_REVIEW_SKELETON_COUNT: 3,
    },
    components: {
        CustomerReview,
        AgencyPageSkeleton,
    },
    mixins: [
        i18nMixin({
            prefix: 'CustomerReviews.',
            keys: [
                'errorMessage',
            ],
        }),
    ],
    props: {
        agencyId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            reviews: undefined,
            displayedSource: undefined,
            isLoadingStateDisplayed: false,
            error: false,
        };
    },
    computed: {
        firstReview() {
            return _.first(this.reviews);
        },
        agencyExternalLink() {
            return _.get(this.firstReview, 'agencyExternalLink');
        },
        source() {
            return _.get(this.firstReview, 'source');
        },
        linkText() {
            return this.t('linkText', {source: this.source});
        },
        isVisible() {
            const {isLoadingStateDisplayed, reviews, error} = this;
            return isLoadingStateDisplayed || !_.isEmpty(reviews) || error;
        },
    },
    created() {
        const loadingTimer = setTimeout(() => {
            this.isLoadingStateDisplayed = true;
            this.$emit('display', true);
        }, 1000);
        AgencyRequests.getReviews(this.agencyId, (err, data) => {
            this.isLoading = false;
            if (err || !data.success) {
                this.error = true;
                this.$emit('display', true);
            } else {
                this.reviews = _.get(data, 'reviews');
                this.$emit('display', !_.isEmpty(this.reviews));
            }
            this.isLoadingStateDisplayed = false;
            clearTimeout(loadingTimer);
        });
    },
    template: template(),
};
