const {i18n: {translate}} = require('fack');
const template = require('./Consumption.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const TextFormatter = require('../../../common/TextFormatter');
const _ = require('lodash');
const EXCESSIVE_CONSUMPTION_CLASSIFICATIONS = ['F', 'G'];

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'EnergyDiagnostics.consumption.',
            keys: [
                'estimatedAmountAnnualExpensesText',
            ],
        }),
    ],
    props: {
        minEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        maxEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        energySimulationReferenceDate: {
            type: String,
            default: undefined,
        },
        energyClassification: {
            type: String,
            default: undefined,
        },
        averageAnnualEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        useLatestEnergyPerformanceDiagnostic: Boolean,
    },
    computed: {
        hasEnergyConsumptionValue() {
            const {
                useLatestEnergyPerformanceDiagnostic,
                averageAnnualEnergyConsumption,
                minEnergyConsumption,
                maxEnergyConsumption,
            } = this;
            return useLatestEnergyPerformanceDiagnostic
                ? Boolean(minEnergyConsumption && maxEnergyConsumption)
                : Boolean(averageAnnualEnergyConsumption);
        },
        energyConsumptionText() {
            const {
                useLatestEnergyPerformanceDiagnostic,
                averageAnnualEnergyConsumption: value,
                minEnergyConsumption: minValue,
                maxEnergyConsumption: maxValue,
            } = this;
            const areMinMaxEqual = Boolean(minValue) && minValue === maxValue;
            const shouldDisplayAverage = !useLatestEnergyPerformanceDiagnostic || areMinMaxEqual;
            const translationKey = shouldDisplayAverage
                ? 'averageEnergyConsumptionText'
                : 'energyConsumptionText';
            const averageValue = areMinMaxEqual ? minValue : value;
            return this.consumptionTranslate(translationKey, {
                minValue: TextFormatter.formatPrice(minValue),
                maxValue: TextFormatter.formatPrice(maxValue),
                value: TextFormatter.formatPrice(averageValue),
            });
        },
        energyPriceIndexationDateText() {
            const {energySimulationReferenceDate} = this;
            if (energySimulationReferenceDate) {
                const date = DateFormatter.humanizeDayLocale(energySimulationReferenceDate);
                return this.consumptionTranslate('energyPriceIndexationDateText', {date});
            } else {
                return this.consumptionTranslate('missingEnergyPriceIndexationDateText');
            }
        },
        excessiveHousingConsumptionText() {
            return this.consumptionTranslate('excessiveHousingConsumptionText', {
                value: this.energyClassification,
            });
        },
        excessiveConsumptionCondition() {
            return _.includes(EXCESSIVE_CONSUMPTION_CLASSIFICATIONS, this.energyClassification);
        },
        notDisclosedInformationText() {
            return this.consumptionTranslate('notDisclosedInformationText');
        },
    },
    methods: {
        consumptionTranslate(key, context) {
            return translate(`EnergyDiagnostics.consumption.${key}`, context);
        },
    },
    template: template(),
};
