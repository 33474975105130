const _ = require('lodash');
const {i18n: {translate}, resourceUrl} = require('fack');
const Account = require('../authentication/Account');
const ApplicationConfig = require('../app/ApplicationConfig');
const BrowserDetect = require('browser-detect');
const EventPack = require('../utils/EventPack');
const FooterBaseView = require('./FooterBaseView');
const PlayStoreUrl = require('../android/PlayStoreUrl');
const PageManager = require('../pages/PageManager');
const ServerConfig = require('../ServerConfig');
const {isInEmbeddedMode} = require('../Options').read();
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const {PUBLIC_BLOG_PATH} = require('../../common/data/blogData');

module.exports = class FooterView extends FooterBaseView {
    constructor() {
        super();
        this._eventPack = new EventPack();
    }

    show(options) {
        const account = Account.getAuthenticatedAccount();
        const wantsToReceiveDirectOffersAndNewsLetter = _.get(account, 'wantsToReceiveDirectOffersAndNewsLetter');
        const userMail = _.get(account, 'email');
        const isPro = ApplicationConfig.applicationPro;
        const displayNewsLetterForm = !isPro || Account.isAuthenticated();
        const subscriptionFormVisible = _.defaultTo(
            options.subscriptionFormVisible, !wantsToReceiveDirectOffersAndNewsLetter && displayNewsLetterForm
        );
        let template;
        if (!UserAgentHelper.isFromNativeApp() && !isInEmbeddedMode) {
            const templateFunc = require('./footer.jade');
            template = templateFunc({
                isMobile: BrowserDetect.isMobile(),
            });
        }

        // @vue/component
        super.show(options, {
            data() {
                return _.defaults({
                    isPro,
                    userMail,
                    alreadyHasUserMail: Boolean(userMail),
                    subscriptionFormVisible,
                    isSubscribed: Boolean(wantsToReceiveDirectOffersAndNewsLetter),
                    Account,
                    appLinksEnabled: !isPro,
                    config: ServerConfig.config,
                    storeBadges: {
                        appStore: {
                            href: 'https://itunes.apple.com/fr/app/bienici-achat-et-location-de-biens-immobiliers'
                                + '/id1181371766?mt=8',
                            title: 'Bien’ici sur l’App Store',
                            imageSrc: resourceUrl('images/badges/appstore-badge.svg'),
                            imageAlt: 'App Store',
                        },
                        googlePlay: {
                            href: getPlayStoreUrl(),
                            title: 'Bien’ici sur Google Play',
                            imageSrc: resourceUrl('images/badges/google-play-badge.png'),
                            imageAlt: 'Google Play',
                        },
                        appGallery: {
                            href: 'https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C102236063?&locale=fr_FR',
                            title: 'Bien’ici sur App Gallery',
                            imageSrc: resourceUrl('images/badges/app-gallery-badge.png'),
                            imageAlt: 'App Gallery',
                        },
                    },
                }, options);
            },
            computed: {
                b2bUrl() {
                    return this.config.b2bUrl;
                },
                corporateUrl() {
                    return this.config.corporateUrl;
                },
                b2bOurOffersUrl() {
                    return `${this.b2bUrl}/nos-offres`;
                },
                contactFormUrl() {
                    if (isPro && !Account.isAuthenticated()) {
                        return this.b2bUrl + '/contact';
                    } else {
                        return '/nous-contacter';
                    }
                },
                links() {
                    const {b2bOurOffersUrl, b2bUrl, contactFormUrl, corporateUrl} = this;
                    const ourOffersCategory = this.ourOffersTranslate('title');
                    const assistingYouCategory = this.assistingYouTranslate('title');
                    const discoverCategory = this.discoverTranslate('title');
                    const companyCategory = this.companyTranslate('title');
                    return [
                        {
                            category: ourOffersCategory,
                            href: b2bOurOffersUrl,
                            name: this.ourOffersTranslate('toEachJobItsOwnSolutions'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: ourOffersCategory,
                            href: b2bOurOffersUrl + '/agent-immobilier',
                            name: this.ourOffersTranslate('realEstateAgent'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: ourOffersCategory,
                            href: b2bOurOffersUrl + '/promoteur-immobilier',
                            name: this.ourOffersTranslate('developer'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: ourOffersCategory,
                            href: b2bOurOffersUrl + '/constructeur-de-maisons',
                            name: this.ourOffersTranslate('houseBuilder'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: assistingYouCategory,
                            href: '/besoin-d-aide',
                            name: this.assistingYouTranslate('needHelp'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: assistingYouCategory,
                            href: b2bUrl + '/actualites',
                            name: this.assistingYouTranslate('news'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: assistingYouCategory,
                            href: contactFormUrl,
                            name: this.assistingYouTranslate('contactUs'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: assistingYouCategory,
                            href: corporateUrl,
                            name: this.assistingYouTranslate('about'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: discoverCategory,
                            href: corporateUrl,
                            name: this.discoverTranslate('bienici'),
                            userTypeVisibility: this.isMobile ? 'all' : 'normal',
                            deviceVisibility: 'all',
                        },
                        {
                            category: discoverCategory,
                            href: '/decouvrez-votre-futur-quartier',
                            name: this.discoverTranslate('neighborhood'),
                            deviceVisibility: 'all',
                        },
                        {
                            category: discoverCategory,
                            href: '/vendre-ou-louer-mon-bien',
                            name: this.discoverTranslate('sellOrRent'),
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: discoverCategory,
                            href: '/agences-immobilieres',
                            name: this.discoverTranslate('agencies'),
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: discoverCategory,
                            href: PUBLIC_BLOG_PATH,
                            name: this.discoverTranslate('realEstateAdvices'),
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: companyCategory,
                            href: '/nous-contacter',
                            name: this.companyTranslate('contactUs'),
                            userTypeVisibility: this.isMobile ? 'all' : 'normal',
                            deviceVisibility: 'all',
                        },
                        {
                            category: companyCategory,
                            href: corporateUrl + '/carrieres/la-vie-chez-bienici',
                            name: this.companyTranslate('weAreHiring'),
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: companyCategory,
                            href: '/besoin-d-aide',
                            name: this.companyTranslate('needHelp'),
                            deviceVisibility: 'desktop',
                        },
                        {
                            category: companyCategory,
                            href: b2bUrl,
                            name: this.companyTranslate('proSolutions'),
                            deviceVisibility: 'all',
                        },
                        {
                            category: companyCategory,
                            href: '/votre-avis-nous-interesse',
                            name: this.companyTranslate('yourOpinion'),
                            deviceVisibility: 'desktop',
                        },
                    ];
                },
                legalLinks() {
                    const legalLinks = [
                        {
                            href: '/informations-legales',
                            name: this.footerTranslate('legalNotices'),
                            deviceVisibility: 'all',
                        },
                        {
                            href: '/politique-de-confidentialite',
                            name: this.footerTranslate('privacyPolicy'),
                            deviceVisibility: 'all',
                        },
                    ];
                    if (isPro && Account.isRegistered()) {
                        legalLinks.push({
                            href: '/conditions-generales-de-vente',
                            name: this.footerTranslate('termsOfSales'),
                            userTypeVisibility: 'pro',
                            deviceVisibility: 'all',
                        });
                    }
                    return legalLinks;
                },
                columnLinks() {
                    return this.isMobile ? this.mobileLinks : this.desktopLinks;
                },
                desktopLinks() {
                    const {links} = this;
                    return _(links)
                        .filter(
                            link => link.category !== ''
                                && ['desktop', 'all'].includes(link.deviceVisibility)
                                && (
                                    isPro ? ['pro', 'all'].includes(link.userTypeVisibility)
                                        : [null, undefined, 'normal', 'all'].includes(link.userTypeVisibility)
                                )
                        )
                        .groupBy('category')
                        .map((links, category) => ({category, links}))
                        .value();
                },
                mobileLinks() {
                    return [{
                        category: '',
                        links: _(this.desktopLinks)
                            .map(column => column.links)
                            .flatten()
                            .concat(this.legalLinks)
                            .filter(link => ['mobile', 'all'].includes(link.deviceVisibility))
                            .value(),
                    }];
                },
                firstColumn() {
                    return this.columnLinks[0];
                },
                lastColumn() {
                    return this.columnLinks[1];
                },
            },
            methods: {
                t: translate,
                discoverTranslate(key) {
                    return this.footerTranslate(`discover.${key}`);
                },
                companyTranslate(key) {
                    return this.footerTranslate(`company.${key}`);
                },
                ourOffersTranslate(key) {
                    return this.footerTranslate(`ourOffers.${key}`);
                },
                assistingYouTranslate(key) {
                    return this.footerTranslate(`assistingYou.${key}`);
                },
                footerTranslate(key) {
                    return this.t(`footer.${key}`);
                },
            },
            template,
        });

        this._eventPack.on(PageManager, 'pageOpen', () => {
            this.vm.storeBadges.googlePlay.href = getPlayStoreUrl();
        });
    }

    hide(options, cb) {
        this._eventPack.removeAllListeners();
        super.hide(options, cb);
    }
};

function getPlayStoreUrl() {
    return PlayStoreUrl.generate({from: 'footer'});
}
