const Ajax = require('./Ajax');
const ServerConfig = require('../ServerConfig');

module.exports = {
    request,
};

function request() {
    return Ajax.withHeaders({
        Authorization: `Bearer ${ServerConfig.config.mapApiKeyForClient}`,
    }, arguments);
}
