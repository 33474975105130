const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const Title = require('../../pages/Title');
const Urls = require('../../Urls');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const Options = require('../../Options');
const {INITIAL_STEP} = require('../Constants');
const template = require('./RealEstateEstimatorPage.jade');
const RealEstatePropertyDescriptorPage = require('../RealEstatePropertyDescriptorPage');

module.exports = class RealEstateEstimatorPage extends RealEstatePropertyDescriptorPage {

    parseUrl(url) {
        return Urls.estimationLandingPage.parseUrl(url);
    }

    onPropertyTypeChange(propertyType) {
        this.propertyType = propertyType;
    }

    onStepChange(step) {
        super.onStepChange(step);
        const pageTitle = this.getTitle();
        if (null != pageTitle) {
            Title.setTitle(pageTitle);
        }
    }

    sendStepToGTM(step) {
        GoogleTagManager.sendEvent('realEstateEstimatorStepChanged', {step});
    }

    getPageContext() {
        return (this.step === INITIAL_STEP && this.propertyTypePreselection) ? this.propertyTypePreselection : this.propertyType;
    }

    getTitle() {
        return this._translate(`pageTitle.step${this.step}`);
    }

    getMetaDescription() {
        return this._translate('pageMetaDescription.content');
    }

    _translate(key) {
        return translate(`realEstateEstimator.${key}`, {
            context: this.getPageContext(),
            transactionType: this.transactionType,
        });
    }

    getLoadOptions(options) {
        this.propertyTypePreselection = options.propertyTypePreselection;
        const {transactionType} = this;
        const htmlPageNameCommonBasis = `real-estate-${transactionType}-estimator-landing-content`;
        const templateName =
            this.propertyTypePreselection ? `${htmlPageNameCommonBasis}-${this.propertyTypePreselection}` : htmlPageNameCommonBasis;
        return _.extend({}, options, {
            url: `/html/${templateName}.html`,
            data: {
                commercialAdsEnabled: Options.get('commercialAdsEnabled'),
            },
        });
    }

    getMiddleViewsMixin() {
        const page = this;
        return [
            {
                provide: {
                    page: 'realEstateEstimator' + _.upperFirst(page.propertyTypePreselection),
                    searchCriteria: {},
                },
                data() {
                    return {
                        propertyTypePreselection: page.propertyTypePreselection,
                    };
                },
                methods: {
                    onPropertyTypeChange(propertyType) {
                        page.onPropertyTypeChange(propertyType);
                    },
                },
            },
        ];
    }

    getTemplate() {
        return template;
    }
};
