const _ = require('lodash');
const template = require('./ExternalAuthentication.jade');
const UserAgentHelper = require('../../../common/nativeApp/UserAgentHelper');

let ExternalAuthentication;
if (UserAgentHelper.isFromNativeApp()) {
    ExternalAuthentication = require('../../ExternalNativeAuthentication');
} else {
    ExternalAuthentication = require('../../ExternalAuthentication');
}

// @vue/component
module.exports = {
    props: {
        providers: {
            type: Array,
            required: true,
        },
    },
    methods: {
        authenticate(provider) {
            const method = ExternalAuthentication[`authenticateWith${_.capitalize(provider)}`];
            if (method) {
                method();
            }
        },
    },
    template: template(),
};
