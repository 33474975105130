const template = require('./NotificationState.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'NotificationState.',
        }),
    ],
    props: {
        notificationState: {
            type: String,
            default: null,
        },
        context: {
            type: String,
            default: null,
        },
    },
    computed: {
        label() {
            const {notificationState, context} = this;
            return this.t(notificationState, {context});
        },
        areNotificationsEnabled() {
            return this.notificationState !== 'never';
        },
    },
    template: template(),
};
