const {mapGetters} = require('vuex');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./ApplicantEditor.jade');
const ApplicantDocumentEditor = require('./ApplicantDocumentEditor');
const ValidatableForm = require('../../../form/components/ValidatableForm');

const DOSSIER_TYPES = [
    {
        value: 'personal',
        label: 'Mon dossier locataire',
    },
    {
        value: 'roommate',
        label: 'Co-locataire',
    },
    {
        value: 'spouse',
        label: 'Conjoint(e)',
    },
    {
        value: 'guarantor',
        label: 'Garant',
    },
];

// @vue/component
module.exports = {
    components: {
        ApplicantDocumentEditor,
        ValidatableForm,
    },
    mixins: [
        i18nMixin({
            prefix: 'ApplicantEditor.',
            keys: [
                'createTitle',
                'editTitle',
            ],
        }),
    ],
    props: {
        documents: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            DOSSIER_TYPES,
        };
    },
    computed: {
        ...mapGetters('rentalApplication', {
            isGloballyProcessing: 'isProcessing',
            documentsCategories: 'documentsCategories',
            currentDossier: 'currentDossier',
        }),
        dossierType() {
            return this.currentDossier ? this.currentDossier.type : '';
        },
        dossierTitle() {
            return this.currentDossier ? this.currentDossier.title : '';
        },
    },
    mounted() {
        if (!this.dossierType) {
            this.setDossierInfo('type', DOSSIER_TYPES[0].value);
        }
    },
    methods: {
        getFilesIds(documentsCategory) {
            return this.documents[documentsCategory];
        },
        setDossierInfo(prop, dossierType) {
            this.$store.commit('rentalApplication/setCurrentDossier', {
                ...this.currentDossier,
                [prop]: dossierType,
            });
        },
        onTypeChange(dossierType) {
            this.setDossierInfo('type', dossierType);
        },
        onTitleChange(dossierTitle) {
            this.setDossierInfo('title', dossierTitle);
        },
    },
    template: template(),
};
