const _ = require('lodash');
const async = require('async');
const OpenWindow = require('../../utils/OpenWindow');
const {loadImage: preloadImage} = require('../../utils/ImageLoader');

const template = require('./Slideshow.jade');

const DEFAULT_SLIDE_DURATION_IN_MS = 8e3;

// @vue/component
module.exports = {
    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentSlideIndex: 0,
            slidesIdOfCurrentSlide: 0,
            preloadingSlidesStates: [],
            timeout: null,
            currentSlide: {},
            forceNextSlideReinit: false,
        };
    },
    computed: {
        description() {
            return this.currentSlide.description;
        },
        background() {
            return this.currentSlide.backgroundUrl;
        },
        isCommercialAdSlide() {
            return this.currentSlide.isCommercialAd;
        },
        topLeftPictureUrl() {
            return this.currentSlide.topLeftPictureUrl;
        },
        bottomRightPictureUrl() {
            return this.currentSlide.bottomRightPictureUrl;
        },
        externalLink() {
            return this.currentSlide.externalLink;
        },
        descriptionContainerStyle() {
            const backgroundColor = this.currentSlide.descriptionBackgroundColor;
            return {backgroundColor};
        },
        backgroundStyle() {
            return getBackgroundImageStyle(this.background);
        },
        nextSlideIndex() {
            return this.forceNextSlideReinit ? 0 : (this.currentSlideIndex + 1) % this.slides.length;
        },
        currentSlideUniqueId() {
            return `${this.slidesIdOfCurrentSlide}-${this.currentSlideIndex}`;
        },
        isNextSlidePreloaded() {
            return this.preloadingSlidesStates[this.nextSlideIndex];
        },
        descriptionClassObject() {
            return {'commercial-description': this.isCommercialAdSlide};
        },
        slideDuration() {
            return this.currentSlide.duration || DEFAULT_SLIDE_DURATION_IN_MS;
        },
    },
    watch: {
        slides() {
            this.resetSlides();
        },
    },
    created() {
        this.slidesId = this.slidesIdOfCurrentSlide;
    },
    mounted() {
        this.setCurrentSlide(this.currentSlideIndex);
    },
    destroyed() {
        clearTimeout(this.timeout);
    },
    methods: {
        prepareNextSlide() {
            if (!this.isNextSlidePreloaded) {
                const {nextSlideIndex} = this;
                preloadSlideImages(this.slides[nextSlideIndex], err => {
                    if (err) {
                        console.error('Could not load slide images', err);
                    } else {
                        this.preloadingSlidesStates.splice(nextSlideIndex, 1, true);
                        this.scheduleNextSlide();
                    }
                });
            } else {
                this.scheduleNextSlide();
            }
        },
        scheduleNextSlide() {
            this.timeout = setTimeout(this.switchToNextSlide, this.slideDuration);
        },
        switchToNextSlide() {
            const oldSlideUniqueId = this.currentSlideUniqueId;
            this.setCurrentSlide(this.nextSlideIndex);
            this.forceNextSlideReinit = false;

            if (oldSlideUniqueId == this.currentSlideUniqueId) {
                // Slide is the same (most likely a unique slide in the list), there won't be any transition: do it ourselves
                this.prepareNextSlide();
            }
        },
        setCurrentSlide(slideIndex) {
            this.currentSlide = this.slides[slideIndex] || {};
            this.currentSlideIndex = slideIndex;
            this.slidesIdOfCurrentSlide = this.slidesId;
        },
        getSlideElementKey(elementType) {
            return elementType + this.currentSlideUniqueId;
        },
        resetSlides() {
            this.slidesId++;
            this.preloadingSlidesStates = [];
            if (_.isEmpty(this.currentSlide)) {
                // no slide: force the slide now
                this.setCurrentSlide(0);
            } else {
                // already sliding: schedule at next rotation
                this.forceNextSlideReinit = true;
            }
        },
        onSlideDisplaying() {
            this.prepareNextSlide();
            this.$emit('slide-displayed', this.currentSlide);
        },
        openNewTab() {
            const url = this.externalLink;
            if (url) {
                OpenWindow.open({url});
            }
        },
    },
    template: template(),
};

function preloadSlideImages(slide, callback) {
    const urls = _(slide)
        .pick([
            'backgroundUrl',
            'topLeftPictureUrl',
            'bottomRightPictureUrl',
        ])
        .values()
        .compact()
        .value();
    async.each(urls, preloadImage, callback);
}

function getBackgroundImageStyle(url) {
    return {
        backgroundImage: `url(${url})`,
    };
}
