const _ = require('lodash');
const $ = require('jquery');
const MapApi = require('./MapApi');
const MarkerUtil = require('./MarkerUtil');
const View = require('./views/View');
const PopupLayerHelper = require('./PopupLayerHelper');
const ApplicationConfig = require('./app/ApplicationConfig');
const undisclosedAddressDialogTemplate = require('./templates/dialog.jade');
const EventPack = require('./utils/EventPack');
const RealEstateAdLoader = require('./RealEstateAdLoader');
const MapBottomButtonsView = require('./map/MapBottomButtonsView');

module.exports = class DetailedSheetAdOnMapView extends View {
    constructor(options) {
        super(options);
        this._eventPack = new EventPack();
        this._marker = null;
        this._highlightedAds3dMarkers = options.highlightedAds3dMarkers;
        this._$undisclosedAddressDialog = null;
        this._mapBottomButtonsView = null;
        this._undisclosePopupLayer = null;
        this._realEstateAd = null;
        this._blurOverlaysRealEstateAdId = null;
        this._blurOverlays = null;
        this._blurOverlaysVisible = false;
    }

    _showUndisclosedAddressDialog(realEstateAd) {
        if (this._map && this._$map && this._marker) {
            this._$undisclosedAddressDialog = this.renderTemplate(undisclosedAddressDialogTemplate, {
                isPopup: false,
                showMessage: this.mustShowUndisclosedAddressMessage(realEstateAd),
                contact: realEstateAd && realEstateAd.contactRelativeData,
            });
            if (!this._undisclosePopupLayer) {
                this._undisclosePopupLayer = PopupLayerHelper.create(this._map);
            }
            PopupLayerHelper.openPopup(this._undisclosePopupLayer, this._marker, this._$undisclosedAddressDialog);
        }
    }

    _hideUndisclosedAddressDialog() {
        if (this._undisclosePopupLayer) {
            PopupLayerHelper.closePopup(this._undisclosePopupLayer);
            PopupLayerHelper.destroy(this._undisclosePopupLayer);
            this._undisclosePopupLayer = null;
        }
    }

    showContactInfo(contact) {
        const {_realEstateAd: realEstateAd} = this;
        if (this._marker && realEstateAd) {
            realEstateAd.contactRelativeData = contact;
        }
        const {_$map: $map} = this;
        if ($map) {
            const mapBottomButtonsView = this._mapBottomButtonsView = new MapBottomButtonsView({
                $container: $map,
            });
            mapBottomButtonsView.show({
                onlineBookingUrl: realEstateAd.onlineBookingUrl,
                contact,
                openContactForm: this._openContactForm,
            });
        }
    }

    _hideContactInfo() {
        const {_mapBottomButtonsView: mapBottomButtonsView} = this;
        if (mapBottomButtonsView) {
            mapBottomButtonsView.hide();
            this._mapBottomButtonsView = null;
        }
    }

    setBlurOverlaysVisible(visible) {
        if (this._blurOverlaysVisible != visible) {
            const overlays = this._blurOverlays;
            if (visible) {
                _.each(overlays, (overlay) => {
                    if (this._map) {
                        overlay.setMap(this._map);
                    }
                });
            } else {
                _.each(overlays, (overlay) => {
                    overlay.setMap(null);
                });
            }
            this._blurOverlaysVisible = visible;
        }
    }

    _hideBlurOverlays() {
        if (this._blurOverlaysRealEstateAdId) {
            this.asyncHelper.cancel('loadBlurOverlays');
            this.setBlurOverlaysVisible(false);
            delete this._blurOverlays;
            delete this._blurOverlaysRealEstateAdId;
        }
        this._blurOverlaysVisible = false;
    }

    _showBlurOverlays(realEstateAd) {
        if (this._blurOverlaysRealEstateAdId != realEstateAd.id) {
            this._hideBlurOverlays();
            this.asyncHelper.doAsync({
                func: cb => RealEstateAdLoader.loadBlurOverlays(realEstateAd, cb),
                callback: (err, overlays) => {
                    if (err) {
                        console.error('Error loading blur overlays ', err);
                    } else {
                        this._blurOverlaysRealEstateAdId = realEstateAd.id;
                        this._blurOverlays = overlays;
                        this.setBlurOverlaysVisible(this._map);
                    }
                },
                name: 'loadBlurOverlays',
            });
        } else {
            this.setBlurOverlaysVisible(this._map);
        }
    }

    show(options) {
        if (!this._map) {
            this.hide();
            this._map = options.map;
            this._$map = $(this._map.getDiv());
            const realEstateAd = options.realEstateAd;
            this._realEstateAd = realEstateAd;
            this._openContactForm = options.openContactForm;
            this._marker = MarkerUtil.createDetailedSheetMarker(
                realEstateAd,
                this._map,
                options.onClickCallback,
                this._highlightedAds3dMarkers
            );
            this._showUndisclosedAddressDialog(realEstateAd);
            if (options.displayBlurOverlays) {
                this._showBlurOverlays(realEstateAd);
            }
        }
    }

    hide(options, cb = _.noop) {
        this._hideUndisclosedAddressDialog();
        this._hideContactInfo();
        this._hideBlurOverlays();
        if (this._realEstateAd != null && this._marker != null) {
            MarkerUtil.deleteDetailedSheetMarker(this._realEstateAd, this._marker, this._highlightedAds3dMarkers);
            delete this._marker;
        }
        this._map = null;
        this._$map = null;
        cb();
    }

    updateMarkerHeight(selectedProgramme, forceHeight) {
        if (this._realEstateAd && this._realEstateAd.id == selectedProgramme.id && this._marker) {
            this._marker.setOptions({forceHeight});
        }
    }

    setMarkerPositionAndHeight(position, height) {
        if (this._marker) {
            this._marker.setOptions({
                position: new MapApi.api.LatLng(position.lat, position.lng),
                forceHeight: height,
                disableLine: true,
                disableHeight: false,
                fixedHeight: false,
            });
        }
    }

    mustShowUndisclosedAddressMessage(realEstateAd) {
        if (ApplicationConfig.applicationPro) {
            return false;
        }
        if (realEstateAd.isSmallMarker) {
            return false;
        }
        if (!realEstateAd.blurInfo) {
            return !realEstateAd.addressKnown;
        }
        if (_.includes([
            'cityOrArrondissement',
            'postalCode',
        ], realEstateAd.blurInfo.type)) {
            const blurOrigin = realEstateAd.blurInfo.origin;
            if (_.includes([
                'network',
                'agency',
                'manual',
                'custom',
                'accounts',
            ], blurOrigin)) {
                return true;
            }
            return !realEstateAd.addressKnown;
        }
        return false;
    }
};
