const SimpleContentVueView = require('../vue/SimpleContentVueView');
const newPropertyLandingCommercialAdTemplate = require('../templates/newPropertyLandingCommercialAd.jade');
const HomePageCommercialAd = require('../homePage/components/CommercialAd/CommercialAd');
const HomePageContent = require('../homePage/components/HomePageContent/HomePageContent');
const Options = require('../Options');

module.exports = class SimpleContentWithAdsView extends SimpleContentVueView {
    constructor(options = {}) {
        super(options);
        this.pageName = options.pageName;
        this.overTemplate = options.overTemplate;
        this.searchCriteria = options.searchCriteria || null;
    }

    show(options) {
        const vueOptions = {
            provide: {
                page: this.pageName,
                searchCriteria: this.searchCriteria,
            },
            components: {
                'kimono-new-property-landing-commercial-ad': this._getAdComponent(newPropertyLandingCommercialAdTemplate),
                'kimono-hp-commercial-ad': HomePageCommercialAd,
                HomePageContent,
            },
        };
        if (this.overTemplate) {
            vueOptions.template = this.overTemplate();
        }
        super.show(options, vueOptions);
    }

    _getAdComponent(template) {
        // @vue/component
        return {
            props: {
                type: {
                    type: String,
                    required: true,
                },
                safeFrame: Boolean,
            },
            data() {
                return {
                    commercialAdsEnabled: Options.get('commercialAdsEnabled'),
                };
            },
            template: template(),
        };
    }
};
