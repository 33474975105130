const _ = require('lodash');
const urlUtil = require('url');

const CompositeVueView = require('../vue/CompositeVueView');
const template = require('../templates/contactsPage/accountContactsView.jade');
const ContactsFilterSelectorView = require('./ContactsFilterSelectorView');
const EventPack = require('../utils/EventPack');
const DateRangePicker = require('../form/components/DateRangePicker');
const moment = require('../../common/momentFr');
const ContactsResults = require('../contacts/components/ContactsResults');
const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');

module.exports = class AccountContactsView extends CompositeVueView {
    constructor(configuration) {
        super(configuration);
        this.template = template;
        this._eventPack = new EventPack();
    }

    show(options) {
        this.options = options;
        this.dates = {
            startDate: moment(this.options.startDate),
            endDate: moment(this.options.endDate),
        };
        const vueData = {
            dates: this.dates,
            filters: null,
        };
        // @vue/component
        const accountContactsView = this;
        const vueOptions = {
            components: {
                DateRangePicker,
                ContactsResults,
            },
            data() {
                return vueData;
            },
            computed: {
                accountId() {
                    return _.get(options, 'account.id') || Account.getAuthenticatedAccountId();
                },
                startDate() {
                    return this.dates.startDate;
                },
                endDate() {
                    return this.dates.endDate;
                },
                isOwnerView() {
                    return !_.has(options, 'account.id');
                },
                exportLink() {
                    const {accountId, startDate, endDate, filters} = this;
                    const {protocol, host} = urlUtil.parse(ServerConfig.config.contactsExportUrl);
                    return urlUtil.format({
                        protocol,
                        host,
                        pathname: 'contactRequests.csv',
                        query: {
                            accountId,
                            access_token: Account.getAccessToken(),
                            fromDate: moment(startDate).startOf('day').toISOString(),
                            toDate: moment(endDate).add(1, 'day').startOf('day').toISOString(),
                            'filters[]': filters,
                        },
                    });
                },
            },
            methods: {
                periodChanged(newDates) {
                    this.dates = newDates;
                    accountContactsView._onDatesChanged(newDates);
                },
            },
        };
        super.show(this.options, vueOptions);
        this._contactsFilterSelectorView = new ContactsFilterSelectorView({
            $container: this.$element.find('.selector'),
        });
        this._contactsFilterSelectorView.show(options);
        vueData.filters = this._contactsFilterSelectorView.getValue() || [];
        this._contactsFilterSelectorView.on('contactFiltersChanged', () => {
            vueData.filters = this._contactsFilterSelectorView.getValue() || [];
        });
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
        this._contactsFilterSelectorView.hide();
        this._contactsFilterSelectorView = null;
    }

    _getFormValues() {
        return _.extend({
            filters: [].concat(this._contactsFilterSelectorView.getValue()).join(','),
        }, this.dates);
    }

    update(options) {
        _.extend(this.options, options);
    }

    _onDatesChanged(dates) {
        this.dates = dates;
    }
};
