const _ = require('lodash');
const SearchZone = require('./search/SearchZone');
const NeighborhoodZone = require('./NeighborhoodZone');
const ServerConfig = require('./ServerConfig');
const async = require('async');
const Ajax = require('./utils/Ajax');
const {isKartoEnabled} = require('./utils/Karto');
const SearchPlacesHelper = require('./SearchPlacesHelper');

module.exports = {
    fetchZoneData,
    fetchNeighborhoodZone,
    fetchSearchZones,
};

// eslint-disable-next-line complexity
function fetchZoneData(zoneInfo, callback) {
    const data = {};
    if (zoneInfo.name && zoneInfo.postal_code) {
        _.extend(data, {
            name: zoneInfo.name,
            postalCode: zoneInfo.postal_code,
        });
    }
    if (zoneInfo.type == 'district' && zoneInfo.id_polygone) {
        _.extend(data, {type: 'neighborhood', id: zoneInfo.id_polygone});
    } else if (zoneInfo.type_id == '1') { // Kel Quartier district
        _.extend(data, {type: 'neighborhood', id: zoneInfo.id});
    } else if ((!zoneInfo.name || !zoneInfo.postal_code) && zoneInfo.zoneIds && zoneInfo.zoneIds.length) {
        data.zoneIds = zoneInfo.zoneIds;
    } else if ((!zoneInfo.name || !zoneInfo.postal_code) && zoneInfo.id) {
        data.zoneIds = [zoneInfo.id];
    } else if (zoneInfo.name) {
        data.name = zoneInfo.name;
    } else if (zoneInfo.postal_code) {
        data.postalCode = zoneInfo.postal_code;
    }

    if (zoneInfo.type == 'postalCode') {
        return SearchPlacesHelper.fetchPostalCodeGeometry(zoneInfo.id, (err, geometry) => {
            callback(err, {geometry});
        });
    } else {
        return fetchZones(data, 'fetchZoneData', (err, results) => {
            callback(err, _.first(results));
        });
    }
}

function fetchNeighborhoodZone(zoneInfo, zoneOptions, callback) {
    return fetchZoneData(zoneInfo, function (err, data) {
        if (err) {
            callback(err);
        } else if (data) {
            const zoneData = isKartoEnabled('placeDiscoveryPage') ? data : new NeighborhoodZone(data, zoneOptions);
            callback(null, zoneData);
        } else {
            callback(null, null);
        }
    });
}

function getSearchZoneQuery(searchLocation) {
    if (_.isString(searchLocation)) {
        return {id: searchLocation};
    }
    return _.omit(searchLocation, 'travelTimeInfos');
}

function fetchSearchZones(searchLocationInfos, callback) {
    const loadSearchZoneRequests = [];
    const parallelsFunc = _.map(searchLocationInfos, searchLocation => {
        const query = getSearchZoneQuery(searchLocation);
        return cb => {
            loadSearchZoneRequests.push(fetchZones(query, 'fetchSearchZone', cb));
        };
    });
    async.parallel(
        parallelsFunc,
        (err, zonesData) => {
            if (err && err.status === 'abort') {
                return;
            }
            const zone = zonesData ? new SearchZone(_.compact(_.flatten(zonesData))) : null;
            loadSearchZoneRequests.length = 0;
            callback(err, zone);
        });

    return {
        abort: function () {
            _.each(loadSearchZoneRequests, function (request) {
                request.abort();
            });
        },
    };
}

function fetchZones(data, serverErrorMessage, callback) {
    return Ajax.request({
        url: ServerConfig.config.resUrl + '/zones.json',
        data,
        disableErrorPage: true,
        serverErrorMessage,
        callback,
    });
}
