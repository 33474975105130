const _ = require('lodash');
const HomePageBase = require('./HomePageBase');
const savedOptions = require('../Options').read();

const REGIONAL_PARTNER_DEFAULT_BACKGROUND_FILENAME = 'default.jpg';

module.exports = class RegionalPartnerHomePage extends HomePageBase {
    constructor(configuration) {
        _.defaults(configuration, {
            url: `/${configuration.partnerName.toLowerCase()}`,
        });
        super(configuration);
        this.partnerName = configuration.partnerName;
    }

    open(options) {
        super.open(_.extend({
            homepageBackgroundFilename: _.get(
                savedOptions,
                'homepageBackgroundFilename',
                REGIONAL_PARTNER_DEFAULT_BACKGROUND_FILENAME
            ),
        }, options, {isRegionalPartner: true}));
    }

    _loadData(options, callback) {
        const loadOptions = _.extend({}, options, {url: `/html/${this.partnerName}HomePageContent.html`});
        this.simpleContentLoadDataRequest(loadOptions, (err, optionsWithHtml) => {
            if (err) {
                console.error("Can't load data", err);
                super._loadData(options, callback);
            } else {
                super._loadData(optionsWithHtml, callback);
            }
        });
    }
};
