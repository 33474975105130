const LoadScriptHelper = require('./utils/LoadScriptHelper');
const ServerConfig = require('./ServerConfig');
const {isWebGL2Supported} = require('./utils/WebglSupport');

let isInitialized = false;

const karto = {
    engine: null,
    noWebGl: false,
    editableAdMarker: null,
    modificationAdMap: null,
    init: function loadKarto(node, options, onMapInitializedCbk) {
        if (!isInitialized) {
            LoadScriptHelper.loadScript(ServerConfig.config.kartoApiUrl, {
                crossoriginAllowed: true,
                successCallback: () => {
                    initKarto(node, options, onMapInitializedCbk);
                    isInitialized = true;
                },
                errorCallback: function (err) {
                    console.error('Failed to load Map: ' + err);
                },
                attributes: {
                    crossorigin: 'anonymous',
                },
            });
        } else {
            initKarto(node, options, onMapInitializedCbk);
        }
        function initKarto(node, options, onMapInitializedCbk) {
            if (!isWebGL2Supported()) {
                console.error('WebGL is not fully supported or disabled on your browser');
                karto.noWebGl = true;
                if (typeof onMapInitializedCbk === 'function') {
                    onMapInitializedCbk(null);
                }
            } else {
                kartoEngineLoader.load(() => {
                    const apiKey = ServerConfig.config.kartoApiKeyForClient;
                    const engine = kartoEngine.Engine.getInstance(apiKey);
                    karto.engine = engine;
                    const map = engine.createMap(node, options);
                    map.initialize().then(() => {
                        onMapInitializedCbk(map);
                    }).catch((err)=> {
                        console.error('Error during map initialization: ' + err);
                        onMapInitializedCbk(null);
                    });
                });
            }
        }
    },
    setModificationAdMap: function (map) {
        this.modificationAdMap = map;
    },
    setEditableAdMarker: function (marker) {
        this.editableAdMarker = marker;
    },
};

module.exports = karto;
