const assert = require('assert');
const _ = require('lodash');
const $ = require('jquery');
const bootstrapValidator = require('../bootstrapValidator');
const {DEFAULT_VALIDATOR_OPTIONS} = require('../FormUtils');

module.exports = function (options = {}) {
    // @vue/component
    return {
        provide() {
            const vm = this;
            return {
                revalidateField(name) {
                    if (fieldHasValidator(name)) {
                        callValidator('revalidateField', name);
                    }
                },
                addField(name) {
                    if (vm._isFormValidatorInitialized) { // it will automatically be added when initialized
                        callValidator('addField', name);
                    }
                },
                removeField(name) {
                    if (fieldHasValidator(name)) {
                        callValidator('removeField', name);
                    }
                },
            };

            function callValidator(methodName, ...args) {
                const bootstrapValidator = vm.getValidator();
                if (bootstrapValidator) {
                    bootstrapValidator[methodName](...args);
                }
            }

            function fieldHasValidator(name) {
                const bootstrapValidator = vm.getValidator();
                return Boolean(bootstrapValidator && bootstrapValidator.getOptions(name));
            }
        },

        created() {
            bootstrapValidator.init();
            this._isFormValidatorInitialized = false;
        },

        mounted() {
            this.initializeFormValidator();
            this._isFormValidatorInitialized = true;
        },

        methods: {
            initializeFormValidator() {
                this.$form = this.findForm();
                assert.strictEqual(this.$form.length, 1);
                // todo: remove the `initBootstrapValidator` option when bootstrapValidator is always initialised via this mixin
                if (options.initBootstrapValidator !== false) {
                    const bootstrapValidator = this.$form.bootstrapValidator(_.defaults({}, options, {
                        autoFocus: true,
                        submitButtons: '.noSubmitButtons', //submit button handling done manually
                        feedbackIcons: false,
                    }, DEFAULT_VALIDATOR_OPTIONS));
                    if (options.showSuccessClass !== true) {
                        bootstrapValidator.on('bv-success.field.bv', function (e, data) {
                            // inspired from > https://f4-group.github.io/bootstrapvalidator/examples/hiding-success-class/
                            const $parent = data.element.parents('.form-group');
                            $parent.removeClass('has-success');
                            $parent.find('.form-control-feedback[data-bv-icon-for="' + data.field + '"]').hide();
                        });
                    }
                }
            },

            findForm() {
                const $el = $(this.$el);
                if (this.$el.tagName == 'FORM') {
                    return $el;
                } else {
                    const $childForm = $el.find('form');
                    if ($childForm.length) {
                        return $childForm;
                    } else {
                        return $el.closest('form');
                    }
                }
            },
            getValidator() {
                return this.$form.data('bootstrapValidator');
            },
            validateForm() {
                const isValid = this.getValidator()
                    .resetForm() // reset validation cache in case fields values changes without user interactions
                    .validate()
                    .isValid();
                if (!isValid) {
                    //hack to allow autoFocus to work since we're not using submit hook
                    this.getValidator()._onError({
                        isDefaultPrevented: _.constant(false),
                    });
                }
                return isValid;
            },
        },
    };
};
