const template = require('./ValidatableForm.jade');
const validationMixin = require('../../fields/vue/validationMixin');
const {DEFAULT_VALIDATOR_OPTIONS: {feedbackIcons}} = require('../../fields/FormUtils');

// @vue/component
module.exports = {
    mixins: [
        validationMixin({
            feedbackIcons,
            showSuccessClass: true,
        }),
    ],
    template: template(),
};
