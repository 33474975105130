const $ = require('jquery');
const _ = require('lodash');
const UrlHelper = require('../common/UrlHelper');
const ServerConfig = require('./ServerConfig');
const Ajax = require('./utils/Ajax');

const DISCOVERY_URL_PREFIX = '/immobilier';

module.exports = {
    getPlaceDetails,
    getEditorialCities,
    setPlaceDiscoveryUrls,
    extractBlocksFromDescription,
};

function getResource(path, data, callback) {
    return Ajax.request({
        url: ServerConfig.config.resUrl + path,
        data,
        disableErrorPage: true,
        serverErrorMessage: 'getPlaceDetails',
        callback: function (err, data) {
            if (err && err.code == 404) {
                callback(null, null);
            } else {
                callback(err, data);
            }
        },
    });

}

function getPlaceDetails(options, callback) {
    getResource('/placeDetails.json', options, callback);
}

function getEditorialCities(callback) {
    getResource('/editorialCities.json', null, callback);
}

function setPlaceDiscoveryUrls(place) {
    let parentDiscoveryUrl;
    if (place.parent) {
        setPlaceDiscoveryUrls(place.parent);
        parentDiscoveryUrl = place.parent.discoveryUrl;
        if (place.parent.children) {
            setChildrenDiscoveryUrls(place.parent.children, parentDiscoveryUrl);
        }
    } else {
        parentDiscoveryUrl = DISCOVERY_URL_PREFIX;
    }
    const lastElt = parentDiscoveryUrl.split('/').slice(-1);
    const slugName = UrlHelper.slugify(place.name);
    let discoveryUrl = parentDiscoveryUrl;
    if (lastElt[0] != slugName || slugName != 'paris') {
        discoveryUrl = parentDiscoveryUrl + '/' + slugName;
    }
    if (place.children) {
        setChildrenDiscoveryUrls(place.children, discoveryUrl);
    }
    _.extend(place, {
        discoveryUrl,
    });
}

function setChildrenDiscoveryUrls(children, parentDiscoveryUrl) {
    _.each(children, function (child) {
        child.discoveryUrl = parentDiscoveryUrl + '/' + UrlHelper.slugify(child.name);
    });
}

function extractBlocksFromDescription(description) {
    let subtitle;
    let pitch;
    const blocks = [];
    let currentBlockElements = [];
    if (description) {
        const $description = $(description);
        _.each($description, function (el) {
            if (/^h/i.test(el.tagName)) {
                pushCurrentBlock();
            }
            currentBlockElements.push(el);
        });
        pushCurrentBlock();
    }
    return {
        subtitle,
        pitch,
        blocks,
    };

    function pushCurrentBlock() {
        if (currentBlockElements.length) {
            if (!blocks.length && currentBlockElements[0].tagName == 'H2') {
                subtitle = toHtml(currentBlockElements.shift());
                pitch = toHtml(currentBlockElements);
            } else {
                blocks.push(toHtml(currentBlockElements));
            }
            currentBlockElements = [];
        }
    }

    function toHtml(el) {
        return $('<div>').append(el).html();
    }
}

//todo fallback on kq when no details
