const _ = require('lodash');
const AdTypeHelper = require('../common/AdTypeHelper');
const GeneratedRelevanceScorer = require('./GeneratedRelevanceScorer');

module.exports = {
    computeAdRelevance,
};

function computeAdRelevance(realEstateAd) {
    const _source = _.clone(realEstateAd);
    const innerAd = _.clone(realEstateAd);
    const commonProps = {
        onTheMarket: realEstateAd.status.onTheMarket,
        photoCount: realEstateAd.photos ? realEstateAd.photos.length : 0,
        adType: AdTypeHelper.translateCodeToFrench(realEstateAd.adType),
        model: {
            ready: realEstateAd.with3dModel,
        },
    };
    _.extend(innerAd, commonProps);
    _.extend(_source, commonProps, {
        realEstateAds: [innerAd],
    });
    return GeneratedRelevanceScorer.computeAdRelevance(_source);
}
