const _ = require('lodash');
const moment = require('moment');
const async = require('async');
const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');

module.exports = {
    getDefaultPeriod,
    getAccountPeriodStats,
    getLocalBannersStats,
};

function getDefaultPeriod() {
    const now = moment();
    return {
        startDate: now.clone().startOf('day').subtract(29, 'days'),
        endDate: now.clone().endOf('day'),
    };
}

function getAccountPeriodStats(data, callback) {
    return Account.authAjax({
        url: `${ServerConfig.config.statsUrl}/getAccountPeriodStats`,
        data,
        disableErrorPage: true,
        serverErrorMessage: 'loadAccountStats',
        callback,
    });
}

function getLocalBannersStats(data, callback) {
    async.parallel({
        internalPrints: (cb) => getInternalLocalBannersStats(data, cb),
        adplorerPrints: (cb) => getExternalLocalExpertStats(data, cb),
    }, callback);
}

function getInternalLocalBannersStats(data, callback) {
    const {accountId, dateFrom, dateTo} = data;
    return getAccountPeriodStats({
        accountId,
        startDate: dateFrom,
        endDate: dateTo,
    }, (err, result) => {
        if (err) {
            callback(err);
        } else {
            const {accountStats: {localBannerEvents: events, localExpertEvents: {total: oldLocalExpertEventsStats}}} = result;
            const localBannerEventsThemeExpertStats = _.get(events, 'expert.total', 0);
            events.expert = {total: localBannerEventsThemeExpertStats + oldLocalExpertEventsStats};
            callback(null, events);
        }
    });
}

function getExternalLocalExpertStats(data, callback) {
    const baseUrl = ServerConfig.config.audienceApiConfigurerUrl;
    return Account.authAjax({
        url: `${baseUrl}/local-expert-agency-stats`,
        method: 'GET',
        data,
        disableErrorPage: true,
        serverErrorMessage: 'loadAccountStats',
        callback,
    });
}
