// @vue/component
module.exports = {
    methods: {
        baseTranslate(key, options) {
            return this.translate('rentalApplication.' + key, options);
        },
        applicantTranslate(key, options) {
            return this.baseTranslate('applicant.' + key, options);
        },
        applicantDocumentsTranslate(key, options) {
            return this.applicantTranslate('documents.' + key, options);
        },
    },
};
