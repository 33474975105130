const $ = require('jquery');
const _ = require('lodash');
const {EventEmitter} = require('events');

const defaultPoiAgencyConfig = require('./poiAgencyConfig.js');
const defaultPoiConfigs = require('./poiConfigs').map;
const defaultPoiPublicTransportConfigs = require('./poiPublicTransportConfigs.js');
const infoLayersInterface = require('../templates/infoLayersInterface.jade');
const MarkerAnimation = require('../MarkerAnimation');
const MapApi = require('../MapApi');
const PoiInfo = require('./PoiInfo');
const PopupLayerHelper = require('../PopupLayerHelper');
const renderTemplate = require('../views/utils/renderTemplate');

module.exports = class PoiInterface extends EventEmitter {
    constructor(map, options) {
        super();
        this._poiTransportLayer = null;
        this._poiLayer = null;
        this._hoveredPoi = null;
        this._popupPoi = null;
        this.clickEnabled = true;
        this.map = map;
        this.options = options;

        const poiOptions = options.poiOptions;
        const poiTransportOptions = options.poiTransportOptions;
        this.popupLayer = PopupLayerHelper.create(map);

        this.poiConfigs = _.clone(defaultPoiConfigs);
        if (this.options.enablePoiAgencies) {
            this.poiAgencyConfig = _.clone(defaultPoiAgencyConfig);
        }
        if (options.poisEnabled) {
            _.each(defaultPoiConfigs, (poiCategory, key) => {
                this.poiConfigs[key] = _.clone(poiCategory);
                if (options.poisEnabled[key] != null) {
                    this.poiConfigs[key].selected = options.poisEnabled[key].selected;
                } else {
                    this.poiConfigs[key].selected = false;
                }
            });
        }
        this.poiPublicTransportConfigs = _.clone(defaultPoiPublicTransportConfigs);
        const poisTransportEnabled = options.poisTransportEnabled;
        if (_.isBoolean(poisTransportEnabled)) {
            _.each(defaultPoiPublicTransportConfigs, (poiCategory, key) => {
                this.poiPublicTransportConfigs[key] = _.defaults({selected: poisTransportEnabled}, poiCategory);
            });
        }

        if (options.showPoisButton) {
            this.$infoLayersInterface = renderTemplate(infoLayersInterface, {
                poiCategories: this.poiConfigs,
                lineTransportCategories: this.poiPublicTransportConfigs,
                poiAgency: this.poiAgencyConfig,
            });
            $(map.getDiv()).append(this.$infoLayersInterface);
            this.$infoLayersInterface.find('.dropdown-menu.keepOpen li').on('click', event => {
                event.stopPropagation();
                event.preventDefault();
            });

            //Limit the height of the menu for small screen bug#1353
            this.$infoLayersInterfaceMenu = this.$infoLayersInterface.find('.dropdown-menu.keepOpen');

            this._onResizeHandler = _.bind(this.onResize, this);
            $(window).on('resize', this._onResizeHandler);
            this._bindCategoryButtons();
            this.onResize();
        }
        this._closePoiInfoHandler = _.bind(this.closePoiInfo, this);
        this.map.on('click', this._closePoiInfoHandler);
        this.closePoiInfo();

        const PoiLayer = require('./PoiLayer');
        this._poiTransportLayer = new PoiLayer(_.extend({
            onlyWay: true,
            map,
        }, poiTransportOptions));
        let newOptions = _.extend({
            onlyCentroid: true,
            map,
            onMarkerCreatedCallback: _.bind(this.onMarkerCreated, this),
            onMarkerDeletedCallback: _.bind(this.onMarkerDeleted, this),
        }, poiOptions);
        if (this.options.enablePoiAgencies) {
            newOptions = _.extend(newOptions, {
                poiAgencyConfig: this.poiAgencyConfig,
                importAccountId: options.importAccountIdForPoiAgencies,
            });
        }
        this._poiLayer = new PoiLayer(newOptions);
        this.updatePoiTags();
    }

    _bindCategoryButtons() {
        const $toggleCategoryButtons = this.$infoLayersInterface.find('.kimono-categoryToggler');
        _.each($toggleCategoryButtons, (toggleCategoryButton) => {
            const $toggleCategoryButton = $(toggleCategoryButton);
            const id = $toggleCategoryButton.attr('data-category-id');
            const thisTags = this.poiConfigs[id]
                || this.poiPublicTransportConfigs[id]
                || (this.poiAgencyConfig && this.poiAgencyConfig[id]);
            $toggleCategoryButton.on('click', () => {
                $toggleCategoryButton.toggleClass('active');
                const selected = $toggleCategoryButton.is('.active');
                $toggleCategoryButton.find('i')
                    .toggleClass('fa-check-square-o', selected)
                    .toggleClass('fa-square-o', !selected);
                thisTags.selected = selected;
                this.emit('togglePoi', {
                    id,
                    selected,
                });
                this.updatePoiTags();
            });
        });
    }

    setAccountFilterAgency(accountFilterAgency) {
        if (this._poiLayer) {
            this._poiLayer.setAccountFilterAgency(accountFilterAgency);
        }
    }

    openPoiInfo(marker, isPopup) {
        const $html = $(PoiInfo.getPoiTooltipHTML(marker, isPopup));

        const $poiInfo = $html.find('.kimono-PoiInfo');

        if (marker.agencyInfo) {
            const {poiInterfaceCreatedCallback} = this.options;
            if (poiInterfaceCreatedCallback) {
                poiInterfaceCreatedCallback(marker, $html);
            }
            $html.addClass('poiAgency');
        } else {
            $poiInfo.css({background: marker.color});
            $html.removeClass('poiAgency');
        }

        if (isPopup) {
            if (this._hoveredPoi && marker.id == this._hoveredPoi.id) {
                $poiInfo.addClass('noAnimation');
            }
            PopupLayerHelper.openPopup(this.popupLayer, marker, $html);
            const $poiCloseButton = $html.find('.kimono-poiCloseButton');
            if (!marker.agencyInfo) {
                $poiCloseButton.css({
                    background: marker.color,
                    color: '#fff',
                });
            }
            $poiCloseButton.on('click touchstart', event => {
                this.closePoiInfo();
                event.stopPropagation();
            });
        } else {
            PopupLayerHelper.openPopup(this.popupLayer, marker, $html);
        }
    }

    static openAgencyPoiInfo(marker, infowindow, cb) {
        if (marker.agencyInfo) {
            if (infowindow) {
                cb(marker, $(infowindow.getDOMContent()));
            } else {
                cb(marker);
            }
            infowindow.open();
        }
    }

    onMarkerCreated(marker) {
        if (marker.animate !== false) {
            MarkerAnimation.animateScale(marker,
                {
                    initialScale: 0,
                    finalScale: 1,
                    duration: 500,
                    easing: $.easing.easeOutCubic,
                });
        }
        marker.onMouseOverListener = MapApi.api.event.addListener(marker, 'mouseover', () => {
            if (!this._popupPoi) {
                this.openPoiInfo(marker, false);
                if (marker.transportTag) {
                    this._updateTransportLayer(this.poiPublicTransportConfigs[marker.transportTag].tags);
                }
                this._hoveredPoi = marker;
            }
        });
        marker.onMouseOutListener = MapApi.api.event.addListener(marker, 'mouseout', () => {
            if (this._hoveredPoi) {
                this._updateTransportLayer();
                this.closePoiInfo();
                this._hoveredPoi = null;
            }
        });
        marker.onMouseClickListener = MapApi.api.event.addListener(marker, 'click', () => {
            this.emit('poiClicked', marker);
            if (null == this._popupPoi || (this._popupPoi && marker.id != this._popupPoi.id)) {
                this.openPoiInfo(marker, true);
                if (marker.transportTag) {
                    this._updateTransportLayer(this.poiPublicTransportConfigs[marker.transportTag].tags);
                } else {
                    this._updateTransportLayer();
                }
                this._hoveredPoi = null;
                this._popupPoi = marker;
            }
        });
    }

    onMarkerDeleted(marker) {
        if (this._popupPoi == marker || this._hoveredPoi == marker) {
            this.closePoiInfo();
        }

        if (marker.animate !== false) {
            MarkerAnimation.animateScale(marker,
                {
                    initialScale: marker.icon.scale,
                    finalScale: 0,
                    duration: 300,
                    easing: $.easing.easeInCubic,
                }, removeMarker);
        } else {
            removeMarker();
        }

        function removeMarker() {
            const map = marker.getMap();
            if (map) {
                MapApi.api.event.removeListener(marker.onMouseOverListener);
                MapApi.api.event.removeListener(marker.onMouseOutListener);
                MapApi.api.event.removeListener(marker.onMouseClickListener);
                marker.setMap(null);
            }
        }
    }

    updatePoiTags() {
        if (this.poiAgencyConfig) {
            this._poiLayer.setAgencyEnabled(this.poiAgencyConfig.agency.selected);
        }
        const tags = _(this.poiConfigs)
            .filter('selected')
            .flatMap(poiCategory => {
                const {color, tags} = poiCategory;
                _.each(tags, tag => {
                    tag.color = color;
                });
                return tags;
            })
            .value();
        this._poiLayer.setTags(tags);

        this.currentTransportTags = _(this.poiPublicTransportConfigs)
            .filter('selected')
            .flatMap('tags')
            .value();
        this._poiTransportLayer.setTags(this.currentTransportTags);
    }

    _updateTransportLayer(transportTags) {
        this._poiTransportLayer.setTags(transportTags || this.currentTransportTags);
    }

    toggle(enabled) {
        this.$infoLayersInterface.toggleClass('hidden', !enabled);
    }

    getPoiLayer() {
        return this._poiLayer;
    }

    closePoiInfo() {
        if (this._popupPoi || this._hoveredPoi) {
            this._updateTransportLayer();
            PopupLayerHelper.closePopup(this.popupLayer);
            this._popupPoi = null;
            this._hoveredPoi = null;
        }
    }

    onResize() {
        if (this.map) {
            this.$infoLayersInterfaceMenu.css({
                maxHeight: $(this.map.getDiv()).height() - 110,
                overflow: 'hidden',
                overflowY: 'auto',
            });
        }
    }

    reset() {
        this.closePoiInfo();
        if (this.map) {
            $(window).off('resize', this._onResizeHandler);
            this.map.removeListener('mapClick', this._closePoiInfoHandler);
            if (this._poiLayer) {
                this._poiLayer.setMap(null);
            }
            if (this._poiTransportLayer) {
                this._poiTransportLayer.setMap(null);
            }
            delete this._closePoiInfoHandler;
            delete this._poiLayer;
            delete this._poiTransportLayer;
            delete this.map;
        }
    }

    setClickEnabled(enabled) {
        this.clickEnabled = enabled;
        if (this._poiLayer) {
            this._poiLayer.setClickEnabled(enabled);
        }
    }
};
