const _ = require('lodash');
const _toSentence = require('underscore.string/toSentence');

const {
    formatRange,
} = require('./RangeTextFormatter');
const {
    formatNumberWithUnit,
    formatNumberWithoutEmptyDecimals,
} = require('./NumberFormatter');

const SQUARE_METER_SUFFIX = 'm²';
const HECTARE_SUFFIX = 'hectare';
const HECTARE_SUFFIX_PLURAL = HECTARE_SUFFIX + 's';
const SQUARE_METER_IN_ONE_HECTARE = 1e4;

let {i18n: {translate}} = require('fack');

module.exports = {
    init,
    formatFilterType,
    formatBooleanFilters,
    formatNewProperty,
    formatQuantity,
    formatSurfaceArea,
    formatSurface,
    formatPropertyTypes,
    isSurfaceRange,
    formatEnergyClassification,
};

function init({t}) {
    translate = t;
}

function formatFilterType(filterType) {
    let translationSubKey;
    if (_.isArray(filterType)) {
        if (filterType.length == 1) {
            translationSubKey = filterType[0];
        } else {
            translationSubKey = 'both';
        }
    } else {
        translationSubKey = filterType;
    }
    return translate('filterType.' + translationSubKey);
}

function formatBooleanFilters(search) {
    return _(search)
        .pickBy((value, key) => value === true
            || (key === 'opticalFiberStatus' && value === 'deploye'))
        .map((value, key) => translate('searchFilter.' + key))
        .join(', ');
}

function formatNewProperty(newProperty) {
    if (null == newProperty) {
        return '';
    } else {
        const translationKey = newProperty ? 'NEUF' : 'ANCIEN';
        return translate('enum.newOrOld.' + [translationKey]).toLowerCase();
    }
}

function formatQuantity(quantity, unit, {translationContext} = {}) {
    let text = '';
    if (quantity) {
        if (_.has(quantity, 'gte')) {
            text = formatRange({
                min: quantity.gte,
                unit,
                translationContext,
            });
        } else if (_.isArray(quantity) && quantity.length > 1) {
            const min = quantity[0];
            const max = _.last(quantity);
            text = formatRange({
                min,
                max,
                unit,
                translationContext,
            });
        } else {
            if (isArrayWithExactlyOneElement(quantity)) {
                quantity = quantity[0];
            }
            text = formatNumberWithUnit(quantity, unit);
        }
    }
    return text;
}

function formatSurfaceArea(surfaceArea, landSurfaceArea, propertyType) {
    const relevantSurface = getRelevantSurface(surfaceArea, landSurfaceArea, propertyType);
    return relevantSurface ? formatSurface(relevantSurface) : null;
}

function formatSurface(surface, decimals) {
    let text;
    if (isSurfaceRange(surface)) {
        text = formatRange({
            min: surface[0],
            max: _.last(surface),
            unit: 'squareMeter',
        });
    } else {
        if (isArrayWithExactlyOneElement(surface)) {
            surface = surface[0];
        }
        const surfaceAreaText = formatNumberWithoutEmptyDecimals(surface, decimals);
        if (surfaceAreaText != '0') {
            let unitText;
            if (surface >= SQUARE_METER_IN_ONE_HECTARE) {
                const surfaceInHectares = surface / SQUARE_METER_IN_ONE_HECTARE;
                text = formatNumberWithoutEmptyDecimals(surfaceInHectares, 2);
                unitText = surfaceInHectares < 2 ? HECTARE_SUFFIX : HECTARE_SUFFIX_PLURAL; // only works in French
            } else {
                text = surfaceAreaText;
                unitText = SQUARE_METER_SUFFIX;
            }
            text += '\u00A0' + unitText;
        }
    }
    return text;
}

function isArrayWithExactlyOneElement(o) {
    return _.isArray(o) && o.length == 1;
}

function formatPropertyTypes(propertyTypes) {
    const translatedPropertyTypes = _.map(propertyTypes, propertyType => translate('enum.propertyType.' + propertyType));
    return _.upperFirst(translatedPropertyTypes.join(', '));
}

function isSurfaceRange(surfaceArea, landSurfaceArea, propertyType) {
    const surface = getRelevantSurface(surfaceArea, landSurfaceArea, propertyType);
    return surface && _.isArray(surface) && (surface[0] != _.last(surface));
}

function getRelevantSurface(surfaceArea, landSurfaceArea, propertyType) {
    let relevantSurface;
    if (landSurfaceArea
        && (propertyType == 'terrain'
            || (propertyType == 'programme' && !surfaceArea))) {
        relevantSurface = landSurfaceArea;
    } else if (surfaceArea) {
        relevantSurface = surfaceArea;
    }
    return relevantSurface;
}

function formatEnergyClassification(energyClassification) {
    if (energyClassification) {
        const prefix = translate('EnergyDiagnostics.edpInitials') + ' : ';
        return prefix + _toSentence(energyClassification, ', ', ' ou ');
    }
}
