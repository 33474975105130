const _ = require('lodash');
const SearchPageBaseFactory = require('./SearchPageBaseFactory');
const FavoritesPage = require('../../pages/FavoritesPage');
const SearchUrlPrefixes = require('../../../common/SearchUrlPrefixes');

module.exports = class FavoritesPageFactory extends SearchPageBaseFactory {
    constructor(pageConfiguration) {
        super(_.defaults(pageConfiguration, {
            urlPrefix: SearchUrlPrefixes.favorites,
        }));
    }

    createPage() {
        return new FavoritesPage(this._pageConfiguration);
    }
};
