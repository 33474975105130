const _ = require('lodash');
const geocodingFilters = require('./geocodingFilters');

module.exports = {
    findMatching,
};

function findMatching(ad) {
    if (ad.position) {
        if (ad.position && ad.position.origin && ad.position.origin == 'graphicModel') {
            return {
                label: 'Coordonnées précises',
                color: '#74f969',
                details: "Position déterminée d'après les modèles graphiques",

            };
        }
        if (ad.position && ad.position.isManual) {
            return {
                label: 'Coordonnées précises',
                color: '#74f969',
                details: 'Position saisie manuellement',

            };
        }
        if (ad.position && ad.position.origin && ad.position.origin == 'flow') {
            return {
                label: 'Coordonnées précises',
                color: '#74f969',
                details: "Position déterminée d'après les latitude et longitude spécifiées",

            };
        }
        return _.find(geocodingFilters, function (filter) {
            return isMatching(filter.value, ad.position);
        });
    } else {
        return null;
    }
}

function isMatching(value, position) {
    return _.every(value, function (matchingValues, key) {
        const keyParts = key.split('.');
        let fieldValue = position;
        _.each(keyParts, function (keyPart) {
            fieldValue = fieldValue && fieldValue[keyPart];
        });
        return _.includes(matchingValues, fieldValue);
    });
}
