const _ = require('lodash');
const MapApi = require('../MapApi');
const {
    camera: {
        theta: DEFAULT_THETA_2D,
        phi: DEFAULT_PHI_2D,
    },
} = require('../../common/DefaultConfiguration');

module.exports = {
    isWebglEnabled,
    getMapTypeIdFromOptions,
    isMapType3d,
    getAnglesFromMapType,
};

function isWebglEnabled(map) {
    return map.conf.enableWebGL;
}

function getMapTypeIdFromOptions(canDo3d, elevation) {
    const MapTypeId = MapApi.api.MapTypeId;
    if (canDo3d) {
        if (elevation) {
            return MapTypeId.TERRAIN;
        } else {
            return MapTypeId.FLAT_TERRAIN;
        }
    } else {
        return MapTypeId.ROADMAP;
    }
}

function isMapType3d(mapTypeId) {
    const {
        api: {
            MapTypeId: {
                FLAT_TERRAIN,
                TERRAIN,
            },
        },
    } = MapApi;
    const mapTypeIdListIn3d = [
        FLAT_TERRAIN,
        TERRAIN,
    ];
    return _.includes(mapTypeIdListIn3d, mapTypeId);
}

function getAnglesFromMapType(mapTypeId, {theta = 45, phi = 0} = {}) {
    const is3d = isMapType3d(mapTypeId);
    return {
        is3d,
        theta: is3d ? theta : DEFAULT_THETA_2D,
        phi: is3d ? phi : DEFAULT_PHI_2D,
    };
}
