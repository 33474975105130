const Options = require('../../../Options');

const randomValue = Math.random();

// @vue/component
module.exports = {
    data() {
        return {
            adsPrintsActivationRate: Options.get('adsPrintsActivationRate'),
        };
    },
    computed: {

        adPrintCollectEnabled() {
            return randomValue < this.adsPrintsActivationRate;
        },
    },
    mounted() {
        this.handleAdsPrintsActivationRateEventListener('on');
    },
    beforeDestroy() {
        this.handleAdsPrintsActivationRateEventListener('removeListener');
    },
    methods: {
        handleAdsPrintsActivationRateEventListener(action) {
            if (!Options.verifyIfOptionInCookie('adsPrintsActivationRate')) {
                Options[action]('adsPrintsActivationRate_changed', (newAdsPrintsActivationRateValue) => {
                    this.adsPrintsActivationRate = newAdsPrintsActivationRateValue;
                });
            }
        },
    },
};
