const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');
const {ESTIMATOR_TRANSLATION_KEY_PREFIX} = require('../../../Constants');

// @vue/component
module.exports = {
    mixins: [
        require('../../../components/mixins/withTranslations'),
    ],
    methods: {
        t(key, options) {
            return t(ESTIMATOR_TRANSLATION_KEY_PREFIX + key, _.extend({context: this.context}, options));
        },
    },
};
