const {i18n: {translate}} = require('fack');
const _ = require('lodash');
const template = require('./Address.jade');
const arrayToObject = require('../../utils/arrayToObject');

const FIELD_NAMES = ['city', 'postalCode', 'street'];

// @vue/component
module.exports = {
    mixins: [
        require('../../form/components/mixins/baseFieldMixin'),
    ],
    props: {
        value: {
            type: Object,
            default: undefined,
        },
        cityRequired: Boolean,
        postalCodeRequired: Boolean,
        requiredCityMessage: {
            type: String,
            default: undefined,
        },
        requiredPostalCodeMessage: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return this.getAddressFromValue();
    },
    watch: _.extend({
        value: {
            handler() {
                _.extend(this, this.getAddressFromValue());
            },
            deep: true,
        },
    }, arrayToObject(FIELD_NAMES, () => function () {
        this.emitAddress();
    })),
    methods: {
        t: translate,
        getSubComponentName(name) {
            return this.name + '.' + name;
        },
        getAddressFromValue() {
            return arrayToObject(FIELD_NAMES, name => _.get(this.value, name));
        },
        emitAddress() {
            this.$emit('input', _.pick(this, FIELD_NAMES));
        },
    },
    template: template(),
};
