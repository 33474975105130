const template = require('./PrivacyLink.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PrivacyLink.',
            keys: [
                'label',
            ],
        }),
    ],
    template: template(),
};
