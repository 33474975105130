const {i18n: {translate}} = require('fack');
const _ = require('lodash');

const template = require('./RealEstateSubmissionPage.jade');
const {SUBMISSION_TRANSLATION_KEY_PREFIX} = require('../Constants');
const RealEstatePropertyDescriptorPage = require('../RealEstatePropertyDescriptorPage');
const GoogleTagManager = require('../../stats/GoogleTagManager');

module.exports = class RealEstateSubmissionPage extends RealEstatePropertyDescriptorPage {

    sendStepToGTM(step) {
        GoogleTagManager.sendEvent(
            'realEstateSubmissionStepChanged',
            {step, intention: this.transactionType}
        );
    }

    getTitle() {
        return this._translate('pageTitle');
    }

    getMetaDescription() {
        return this._translate('pageMetaDescription');
    }

    _translate(key) {
        return translate(SUBMISSION_TRANSLATION_KEY_PREFIX + key, {
            context: this.transactionType,
        });
    }

    getLoadOptions(options) {
        const templateName = `real-estate-${this.transactionType}-submission-landing-content`;
        return _.extend({}, options, {
            url: `/html/${templateName}.html`,
        });
    }

    getTemplate() {
        return template;
    }
};
