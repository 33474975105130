const $ = require('jquery');
const template = require('./NewsletterSubscription.jade');
const SpinnerHelper = require('../../utils/SpinnerHelper');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const Views = require('../../views/Views');
const Account = require('../../authentication/Account');
const NewsletterForm = require('./NewsletterForm');
const NewsletterLegalMentions = require('./NewsletterLegalMentions');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    components: {
        NewsletterForm,
        NewsletterLegalMentions,
    },
    mixins: [
        i18nMixin({
            keys: [
                'title',
                'alreadySubscribed',
                'subscribe',
            ],
        }),
        require('./newsletterTranslateMixin'),
    ],
    props: {
        isSubscribed: {
            required: true,
            type: Boolean,
        },
        alreadyHasUserMail: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            displayLegalMentions: false,
        };
    },
    methods: {
        toggleButtonSpinner(enabled) {
            const $validationButton = $(this.$refs.validationButton);
            if (enabled) {
                SpinnerHelper.startButtonSpinner($validationButton);
            } else {
                SpinnerHelper.stopButtonSpinner($validationButton);
            }
        },
        subscribeToNewsletterFromForm(userMail) {
            this.userMail = userMail;
            this.subscribeToNewsletter();
        },
        subscribeToNewsletter() {
            this.toggleButtonSpinner(true);
            Account.subscribeToNewsletter(this.userMail, err => {
                if (err) {
                    console.error('Newsletter subscription has failed.');
                    Views.volatileFeedback.showError('newsLetter.errorMessage');
                } else {
                    this.sendGTMTracking();
                    this.updateFooterContactWhenSubscribed();
                }
                this.toggleButtonSpinner(false);
            });
        },
        sendGTMTracking() {
            GoogleTagManager.sendEvent('newsLetterSubscription', {
                userMail: this.userMail,
            });
        },
        updateFooterContactWhenSubscribed() {
            this.$emit('update:isSubscribed', true);
        },
    },
    template: template(),
};
