const $ = require('jquery');
const _ = require('lodash');
const Views = require('../../views/Views');
const ApplicationPage = require('../../pages/ApplicationPage');
const ArticlesContainerView = require('../list/ArticlesContainerView');
const ArticlesListView = require('../list/ArticlesListView');
const ImgAnimationView = require('../../views/ImgAnimationView');
const Pages = require('../../pages/Pages');
const PageManager = require('../../pages/PageManager');
const BrowserDetect = require('browser-detect');
const Error404 = require('../../Error404');
const ArticleHelper = require('../article/ArticleHelper');
const BlogHelper = require('../BlogHelper');

module.exports = class ArticlesHubPage extends ApplicationPage {
    constructor(options) {
        const configuration = {
            name: 'articlesHubPage',
            title: options.blog.name,
            blog: options.blog,
            isPro: options.mode == 'pro',
            gtmCategory: 'BlogContent',
        };
        super(configuration);
        this.configuration = configuration;
    }

    open(options) {
        this.options.category = options.category;
        const isMobile = BrowserDetect.isMobile();
        this.animationEnabled = !isMobile;
        this.options = _.extend(options, this.configuration);
        this.bodyClass = 'blog';
        this.scrollWindowToTopOnOpen = options.scrollWindowToTopOnOpen ? options.scrollWindowToTopOnOpen : false;
        super.open(this.options);
        if (!this.articlesContainerView) {
            super.open(this.options);
            return;
        }
        this.articlesListView.setArticlesContainer(this.articlesContainerView.getArticlesListContainer());
        if (!this.options.isFromArticleInList
            && this.options.imgAnimationView
            && this.animationEnabled
            && this.options.fromArticle) {
            this.options.imgAnimationView.hide();
        }
        this.articlesListView.showArticles({
            fromArticle: this.options.isFromArticleInList,
            scrollToArticle: true,
            useHTMLHeadingsTags: true,
        });
        this.openArticleHandler = _.bind(this.openArticle, this);
        this.articlesListView.on('openArticle', this.openArticleHandler);
        this.newSearchListener = _.bind(this.newSearch, this);
        this.getHubMenuView().on('newSearch', this.newSearchListener);
        if (this.options.imgAnimationView && this.animationEnabled && this.options.fromArticle) {
            const target = this.articlesListView.getImgTarget(this.options.fromArticle.slug);
            if (target) {
                this.options.imgAnimationView.animToTarget(target, true);
            } else {
                this.options.imgAnimationView.hide();
            }
        }
        if (options.category) {
            this.getHubMenuView().selectCategory(options.category);
        }
    }

    close(options) {
        if (options && !options.imgAnimationViewBack && this.options.imgAnimationViewBack) {
            this.options.imgAnimationViewBack.hide();
        }
        if (options && !options.imgAnimationView && this.options.imgAnimationView) {
            this.options.imgAnimationView.hide();
        }
        if (this.newSearchListener) {
            this.getHubMenuView().removeListener('newSearch', this.newSearchListener);
            this.newSearchListener = null;
        }
        if (this.openArticleHandler) {
            this.articlesListView.removeListener('openArticle', this.openArticleHandler);
            this.openArticleHandler = null;
        }
        super.close();
    }

    getMiddleViews() {
        if (!this.articlesContainerView) {
            if (this.options.articlesContainerView) {
                this.articlesContainerView = this.options.articlesContainerView;
            } else {
                this.articlesContainerView = new ArticlesContainerView(this.options);
            }
        }
        if (!this.articlesListView) {
            if (this.options.articlesListView) {
                this.articlesListView = this.options.articlesListView;
            } else {
                const options = {
                    articlesList: this.options.articlesList,
                    currentCategory: this.options.category,
                    //isInfiniteScroll: true,
                    //maxByStepArticles: 6,
                };
                this.articlesListView = new ArticlesListView(options);
            }
        }
        return [
            this.getHubMenuView(),
            this.articlesContainerView,
            this.articlesListView,
        ];
    }

    isPageAccessAllowed() {
        return BlogHelper.isBlogVisibleByUser(this.configuration.blog, this.configuration.isPro);
    }

    openArticle(event) {
        const currentElement = event.currentTarget;
        const slug = currentElement.dataset.slug;
        const options = {
            category: this.getHubMenuView().getCurrentCategory(),
            article: ArticleHelper.getArticleFromArticlesList(this.options.articlesList.articles, slug),
            isVisible: true,
            articlesHubView: this.articlesHubView,
            articlesContainerView: this.articlesContainerView,
            articlesListView: this.articlesListView,
            articleHubPage: this,
            blog: this.configuration.blog,
        };
        this.articlesListView.saveScrollTopValue();
        if (this.animationEnabled) {
            const imgAnimationViewOptions = {
                $img: $(currentElement).find('.articlesHub__article__photo-container'),
            };
            const imgAnimationView = new ImgAnimationView(imgAnimationViewOptions);
            imgAnimationView.show();
            options.imgAnimationView = imgAnimationView;
        }
        PageManager.openPage(new Pages.ArticlePage(), options);
    }

    newSearch(options) {
        this.articlesContainerView.emptyArticleContainer();
        const hubPageFactory = BlogHelper.getBlogHubPageFactory(options.blog);
        PageManager.openPage(hubPageFactory.createPage(), {
            category: options.category || (options.blog && BlogHelper.getBlogHomeTag(options.blog)),
            articlesContainerView: this.articlesContainerView,
            scrollWindowToTopOnOpen: false,
        });
    }

    getName() {
        return this.configuration.name;
    }

    getUrl() {
        let url = '/' + this.configuration.blog.slug;
        const categorySlug = this.options && this.options.category && this.options.category.slug;
        if (categorySlug && (this.configuration.blog.slug != categorySlug)) {
            url += '/' + categorySlug;
        }
        return url;
    }

    getTitle() {
        return this._title;
    }

    parseUrl(url, categorySlug, subCategorySlug) {
        let category = _.find(this.configuration.blog.tags, {isHome: true});

        if (categorySlug) {
            category = _.find(this.configuration.blog.tags, {slug: categorySlug});
            if (subCategorySlug) {
                category = _.find(category.subTags, {slug: subCategorySlug});
            }
        }

        return {
            category,
        };
    }

    setTitle(category) {
        if (category && category.path.length > 1) {
            this._title = `${this.configuration.blog.name} - ${category.name}`;
        } else {
            this._title = this.configuration.blog.name;
        }
    }

    getArticleDataBySlug(slug, articlesList) {
        return _.find(articlesList.articles, function (article) {
            return article.slug == slug;
        });
    }

    loadData(options, cb) {
        if (!options.category) {
            options.category = this.options.category;
        }
        if (options.category) {
            this.setTitle(options.category);
            this.getArticlesList(options.category.slug, (err, results) => {
                if (!err) {
                    if (options.fromArticle) {
                        options.isFromArticleInList = this.getArticleDataBySlug(options.fromArticle.slug, results);
                    }
                    options.articlesList = results;
                    cb(null, options);
                } else {
                    cb(err);
                }
            });
        } else {
            cb(new Error404());
        }
    }

    getArticlesList(tag, callback) {
        let params = {};
        const tags = [];
        // tags.push(this.configuration.blog.slug); TODO : ES article request currently fails when sending multiple tags
        if (tag) {
            tags.push(tag);
        } else {
            tags.push(this.configuration.blog.slug);
        }
        params = _.extend(params, {
            tags,
        });
        return ArticleHelper.getArticles({
            params,
            callback: function (err, data) {
                callback(err, data);
            },
        });
    }

    getMetaDescription() {
        return _.get(this, 'options.category.description');
    }

    getHubMenuView() {
        return Views.articlesHubMenuViews[this.configuration.blog.slug];
    }

    getSelectedHeaderItem() {
        return this.configuration.blog.slug;
    }
};
