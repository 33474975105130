const _ = require('lodash');
const Account = require('../authentication/Account');
const RealEstateAdCreationPublicView = require('./RealEstateAdCreationPublicView');
const RealEstateAdCreationProView = require('./RealEstateAdCreationProView');
const ApplicationPage = require('../pages/ApplicationPage');
const MapApi = require('../MapApi');

module.exports = class RealEstateAdCreationPage extends ApplicationPage {
    constructor(mode) {
        const configuration = {
            name: 'realEstateAdCreation',
            bodyClass: 'realEstateAdCreationPage',
            title: 'Publier une annonce',
            isRegistrationRequired: mode == 'pro',
            disableReloadPageOnLogin: true,
            mode,
        };
        super(configuration);
        this.configuration = configuration;
    }

    open(options) {
        this.options = _.extend(options, this.configuration);
        super.open(options);
    }

    getMiddleViews() {
        let middleView;
        if (this.configuration.mode == 'pro') {
            middleView = new RealEstateAdCreationProView();
        } else {
            middleView = new RealEstateAdCreationPublicView();
        }
        return middleView;
    }

    isPageAccessAllowed() {
        return Account.isAdPublisher();
    }

    getUrlPattern() {
        return '/publier-une-annonce';
    }

    _loadData(options, cb) {
        // Load map if not already loaded
        MapApi.init(function () {
            cb(null, options);
        }, this);
    }
};
