const _ = require('lodash');

// @vue/component
module.exports = {
    mixins: [
        require('./validatableMixin'),
    ],
    props: {
        name: {
            type: String,
            required: true,
        },
        required: Boolean,
        label: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        showRequiredStar: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        labelText() {
            return this.label;
        },
        finalLabel() {
            return this.addRequiredStarIfNeeded(this.labelText);
        },
        finalPlaceholder() {
            return this.computePlaceholder(this.labelText);
        },
        requiredStar() {
            return this.required && this.showRequiredStar ? '*' : '';
        },
    },
    watch: {
        required() {
            this.updateValidation();
            this.revalidate();
        },
    },
    methods: {
        addRequiredStarIfNeeded(label) {
            return label ? label + this.requiredStar : undefined;
        },
        computePlaceholder(...alternatives) {
            let placeholder = _.find([this.placeholder, ...alternatives]);
            if (!this.label) {
                placeholder = this.addRequiredStarIfNeeded(placeholder);
            }
            return placeholder;
        },
    },
};
