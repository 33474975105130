let SearchFields = {};

module.exports = {
    registerFieldClass,
    get,
};

function get() {
    return SearchFields;
}

function registerFieldClass(fieldClasses) {
    SearchFields = fieldClasses;
}
