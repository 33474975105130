const _ = require('lodash');

module.exports = {
    removeDataFromOwnersFromAccount,
    convertArrayToAccountData,
};

function removeDataFromOwnersFromAccount(account) {
    // back to original data not from dataFromOwners for some values
    const originalAccountData = getOriginalAccountData(account);
    return _.extend({}, account, originalAccountData);
}

function getOriginalAccountData(account) {
    //todo: handle arrays and objects (for company.logoUrl for example)
    const originalAccountDataKeys = _.keys(_.omitBy(account.dataFromOwners, _.isObject));
    originalAccountDataKeys.push('proOptions');
    return convertArrayToAccountData(originalAccountDataKeys, account);
}

function convertArrayToAccountData(array, account) {
    return _.zipObject(array, _.map(array, _.partial(getValueFromDataFromUserAndImport, _, account)));
}

function getValueFromDataFromUserAndImport(key, account) {
    const {dataFromUser, dataFromImport} = account;
    return _.get(dataFromUser, key, _.get(dataFromImport, key));
}
