const Account = require('../authentication/Account');
const {updateFavoriteAdsIds} = require('../favorites');
const MapApi = require('../MapApi');
const isNullOrEmpty = require('../../common/isNullOrEmpty');
const {
    resultsPerPage: EXTENDED_TO_SEARCH_AROUND_ADS_TOTAL_LIMIT,
} = require('../../common/DefaultConfiguration');

const _ = require('lodash');

const ALL_SHRUNK_RESULTS_SIZE = 10000;

module.exports = {
    loadResults,
    loadRealEstateAdById,
    loadRealEstateAdByIdWithFilters,
    loadEditableRealEstateAdById,
    loadRawRealEstateAdById,
    prepareRealEstateAds,
    countAds,
    loadAllShrunkAds,
    prepareFilters,
};

/**
 * used to get realEstateAd formatted for edition
 * @param {object} options
 * @param {string} options.id the ad id
 * @param {function} callback
 * @returns {request} the request, in case you want to cancel it
 */
function loadEditableRealEstateAdById(options, callback) {
    options.url = '/editableRealEstateAd.json';
    return loadRawRealEstateAdById(options, callback);
}

/**
 * used to get realEstateAd formatted for display
 * @param {object} options
 * @param {string} options.id the ad id
 * @param {function} callback
 * @returns {request} the request, in case you want to cancel it
 */
function loadRealEstateAdById(options, callback) {
    return loadRawRealEstateAdById(options, callback);
}

/**
 * used to get unmodified realEstateAd, for edition
 * @param {object} options
 * @param {string} options.id the ad id
 * @param {string} options.accountId current user id
 * @param {string} options.accessToken current user accessToken
 * @param {boolean} [options.disableErrorPage] don't show error page on error
 * @param {string} [options.url=/realEstateAd.json] the url to call
 * @param {function} callback
 * @returns {request} the request, in case you want to cancel it
 */
function loadRawRealEstateAdById(options, callback) {
    const data = addAccessTokenToData({
        id: options.id,
    });
    return Account.authAjax({
        url: options.url || '/realEstateAd.json',
        data,
        serverErrorMessage: 'loadRawRealEstateAdById',
        disableErrorPage: options.disableErrorPage,
        callback: (err, ad) => {
            if (!err) {
                updateFavoriteAdsIds([ad]);
            }
            callback(err, ad);
        },
    });
}

function getExtensionType(options) {
    let extensionType;
    const filters = options.filters;
    //todo kill me while cleaning extended request server side
    if (filters.extensionType) {
        if (filters.extensionType != 'none') {
            extensionType = filters.extensionType;
        }
    } else {
        extensionType = 'extendedIfNoResult';
    }
    if (extensionType == 'extendedIfNoResult' && !options.canExtendResults) {
        extensionType = null;
    }
    return extensionType;
}

function prepareFilters(options) {
    const omittedFilters = ['tags', 'locations', 'extensionType', 'buyNewPropertySetInFilterType'];
    const filters = options.filters;
    if (filters.locations) {
        filters.zoneIdsByTypes = getZoneIdsByTypes(filters.locations);
    }
    //cloning to avoid changing given filters
    let queryFilters = _.clone(filters || {});
    queryFilters = _.omit(queryFilters, omittedFilters);
    return _.omitBy(queryFilters, isNullOrEmpty);
}

function getZoneIdsByTypes(locationsItems) {
    const zoneIdsByTypes = {};
    _.each(locationsItems, locationItem => {
        const type = locationItem.getTypeForServerRequest();
        zoneIdsByTypes[type] = _.flatten(_.union(zoneIdsByTypes[type] || [], [locationItem.getZoneInfoIds()]));
    });
    if (_.isEmpty(zoneIdsByTypes)) {
        return;
    }
    return zoneIdsByTypes;
}

function prepareData(options, optionalSize) {
    const queryFilters = prepareFilters(options);
    if (optionalSize) {
        queryFilters.size = optionalSize;
    }
    const data = {filters: JSON.stringify(queryFilters)};
    const extensionType = getExtensionType(options);
    if (extensionType) {
        data.extensionType = extensionType;
    }
    if (options.savedSearchId) {
        data.savedSearchId = options.savedSearchId;
    }
    return data;
}

function loadResults(options) {
    const data = prepareData(options);
    if (options.enableAggregates) { // avoid sending parameter with 'false' value;
        data.enableAggregates = true;
    }
    if (options.leadingCount >= 0) {
        data.leadingCount = options.leadingCount;
    }
    return requestRealEstateAds({
        requestName: options.requestName || 'Load ads',
        urlSuffix: options.urlSuffix,
        data,
        formatResults: function (data) {
            return makeResult(data, options.filters);
        },
        callback: options.callback,
    });
}

function loadRealEstateAdByIdWithFilters(options, callback) {
    const data = prepareData(options);
    data.onlyRealEstateAd = options.id;
    return requestRealEstateAds({
        requestName: options.requestName || 'Load ad with filters',
        urlSuffix: 'one',
        data,
        callback,
    });
}

function countAds(options, callback) {
    const data = prepareData(options);
    data.onlyCount = true;
    data.onlyOwnData = options.onlyOwnData;
    return requestRealEstateAds({
        requestName: 'Count ' + options.name,
        urlSuffix: (options.name ? options.name + '-' : '') + 'count',
        data,
        callback: function (err, result) {
            callback(err, result ? result.total : null);
        },
    });
}

function loadAllShrunkAds(options, callback) {
    const data = prepareData(options, ALL_SHRUNK_RESULTS_SIZE);
    data.positionOnly = true;
    if (options.minimalData) {
        // Set the precision of geohash : Precision 8 leads to noticeable difference between real position and hashed
        data.geohashPositionOutputOnly = options.geoHashPrecision || 9;
    }
    return requestRealEstateAds({
        requestName: 'Load ' + options.name + ' (all)',
        urlSuffix: (options.name ? options.name + '-' : '') + 'all',
        data,
        callback,
    });
}

function requestRealEstateAds(options) {
    const data = addTokenAndIdToData(options.data);
    const callback = options.callback;
    let url = '/realEstateAds.json';
    if (options.urlSuffix) {
        url = '/realEstateAds-' + options.urlSuffix + '.json';
    }
    return Account.authAjax({
        url,
        data,
        serverErrorMessage: 'requestRealEstateAds',
        disableErrorPage: true,
        callback: function (err, results) {
            if (err && err.status != 'abort') {
                console.error('requestRealEstateAds ' + err);
            }
            if (!err && results) {
                updateFavorites(results, data);
                if (options.formatResults) {
                    results = options.formatResults(results);
                }
            }
            callback(err, results);
        },
    });
}

function updateFavorites(data, {onlyCount, positionOnly, onlyRealEstateAd}) {
    let ads;
    if (onlyRealEstateAd) {
        ads = [data];
    } else if (!onlyCount && !positionOnly) {
        ads = _.union(data.realEstateAds, data.leadingAds);
    }
    updateFavoriteAdsIds(ads);
}

function makeResult(data, filters) {
    const {isExtendedWithSearchAround, total} = data;
    const adsTotalLimit = isExtendedWithSearchAround ? EXTENDED_TO_SEARCH_AROUND_ADS_TOTAL_LIMIT : data.adsTotalLimit;
    const hasTooManyAds = isExtendedWithSearchAround ? false : total > adsTotalLimit;
    const titleOptions = _.extend({
        count: _.min([total, adsTotalLimit]),
        hasTooManyAds,
    }, filters);
    const realEstateAds = prepareRealEstateAds(data.realEstateAds);
    const leadingAds = prepareRealEstateAds(data.leadingAds || []);
    return _.extend(
        {
            totalResults: total,
            values: realEstateAds,
            leadingAds,
            titleOptions,
        },
        _.pick(data, [
            'adsTotalLimit',
            'from',
            'perPage',
            'filterCounts',
            'updatedFilters',
            'pageIndex',
            'isExtendedWithSearchAround',
        ])
    );
}

function prepareRealEstateAds(realEstateAds) {
    return _.map(realEstateAds, function (realEstateAd) {
        const formattedRealEstateAd = realEstateAd;
        if (realEstateAd.photos && realEstateAd.photos[0]) {
            formattedRealEstateAd.firstImage = realEstateAd.photos[0].url;
        }
        if (realEstateAd.position && realEstateAd.position.coordinates && realEstateAd.position.type == 'point') {
            const lat = realEstateAd.position.coordinates[1];
            const lng = realEstateAd.position.coordinates[0];
            formattedRealEstateAd.position = new MapApi.api.LatLng(lat, lng);
        }
        return formattedRealEstateAd;
    });
}

function addTokenAndIdToData(data) {
    return addAccountIdToData(addAccessTokenToData(data));
}

function addAccessTokenToData(data) {
    return addAccountData(data, 'access_token');
}

function addAccountIdToData(data) {
    return addAccountData(data, 'id');
}

function addAccountData(data, key) {
    return addToData(data, key, getAccountData(key));
}

function getAccountData(key) {
    const account = Account.getAuthenticatedAccount();
    return account && account[key] || null;
}

function addToData(data, key, value) {
    if (value) {
        data[key] = value;
    }
    return data;
}
