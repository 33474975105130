const async = require('async');
const Account = require('./Account');
const urlHelper = require('../../common/UrlHelper');
const History = require('../utils/History');

module.exports = function (cb) {
    return async.some(['timedToken', 'access_token'], tryToAuthenticateWithToken, cb);
};

function tryToAuthenticateWithToken(tokenName, cb) {
    const currentState = History.getState();
    const currentUrl = currentState.url;
    const encodedToken = urlHelper.getURLParameter(currentUrl, tokenName);
    if (encodedToken) {
        const token = decodeURIComponent(encodedToken);
        const updatedUrl = urlHelper.removeURLParameter(currentUrl, tokenName);
        History.replaceState(currentState.data, currentState.title, updatedUrl);
        Account.authenticate({[tokenName]: token}, (err, {success} = {}) => {
            cb(!err && success);
        });
    } else {
        async.setImmediate(cb);
    }
}
