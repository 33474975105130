const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const SUBJECTS_TRANSLATION_KEY_ROOT_BY_FORM_TYPE = {
    contactUs: 'formContact.subjects.',
    opinion: 'formContact.subjects.',
    reportAd: 'ReportAdForm.reasons.',
};

module.exports = function (formType, subject, advertiserAccountType) {
    const subjectTranslationKeyRoot = SUBJECTS_TRANSLATION_KEY_ROOT_BY_FORM_TYPE[formType];
    const reasonOrSubReasonIfExists = translate(subjectTranslationKeyRoot + subject);
    if (_.includes(subject, '.subReasons')) {
        const reasonTranslationKeySuffix = `${_.first(_.split(subject, '.subReasons'))}.label`;
        const reason = _.upperFirst(translate(subjectTranslationKeyRoot + reasonTranslationKeySuffix, {
            accountType: advertiserAccountType,
        }));
        return `${reason} - ${reasonOrSubReasonIfExists}`;
    } else {
        return reasonOrSubReasonIfExists;
    }
};
