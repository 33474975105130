const _ = require('lodash');

module.exports = class AsyncHelper {
    constructor() {
        this._runningCalls = {};
    }

    /**
     * Make a request and manage result callback when redo request and manage request triggering after view has been cleared to prevent race conditions
     * @param {object} options
     * @param {string} options.name
     * @param {function} options.func
     * @param {function} options.callback
     */
    doAsync(options) {
        let name = options.name;
        if (name) {
            this.cancel(name);
        } else {
            name = _.uniqueId('_');
        }
        const runningCall = {};
        this._runningCalls[name] = runningCall;
        const request = options.func(doAsyncCallback);
        if (!runningCall.finished && request) { // already finished if the callback is called synchronously
            runningCall.request = request;
        }

        function doAsyncCallback(err) {
            if (runningCall.finished) {
                console.warn('Callback called twice');
            } else if (!runningCall.canceled) {
                runningCall.finished = true;
                delete runningCall.request;
                if (err && (err === 'abort' || err.status === 'abort')) {
                    return;
                }
                options.callback.apply(null, arguments);
            }
        }
    }

    cancel(name) {
        const runningCall = this._runningCalls[name];
        if (runningCall) {
            runningCall.canceled = true;
            if (runningCall.request && runningCall.request.abort) {
                runningCall.request.abort();
            }
            delete this._runningCalls[name];
        }
    }

    cancelAll() {
        _.each(this._runningCalls, (runningCall, name) => {
            this.cancel(name);
        });
    }
};
