const _ = require('lodash');

module.exports = class FieldsGroupWidget {
    constructor() {
        this.fields = {};
    }

    setFieldValue(value) {
        _.each(this.fields, field => {
            field.setFieldValue(value[field.name]);
        });
    }

    getFieldValue() {
        const result = {};
        _.each(this.fields, field => {
            result[field.name] = field.getFieldValue();
        });
        return result;
    }

    serializeInto(result) {
        _.extend(result, this.getFieldValue());
    }

    addField(field) {
        this.fields[field.name] = field;
    }
};
