module.exports = {
    namespaced: true,
    state() {
        return {
            userAlreadyContactedProgrammeOrResidence: false,
        };
    },
    getters: {
        alreadyContactedProgrammeOrResidence(state) {
            return state.userAlreadyContactedProgrammeOrResidence;
        },
    },
    mutations: {
        setUserAlreadyContactedProgrammeOrResidence(state, alreadyContacted) {
            state.userAlreadyContactedProgrammeOrResidence = alreadyContacted;
        },
    },
};
