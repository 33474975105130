module.exports = Page;

/**
 * a page contains views, handle url, and handles its own model
 * @constructor
 */
function Page() {
    this.isOpen = false; //modified by PageManager
}

const proto = Page.prototype;

/**
 * the page should display itself (and its views)
 * @param {object} options options to use to display (probably from parseUrl)
 * @abstract
 * @access public
 */
proto.open = function (options) {
    // implement this method
    void(options);
};

/**
 * the page should hide itself
 * @access public
 */
proto.close = function () {
};

/**
 * the page should parse the url, and create an option item from it (which may be given to the open function
 * @abstract
 * @access public
 * @returns {object} options that this page should accept to open itself or load asynchronous data
 */
proto.parseUrl = function (url) {
    void(url);
    // implement this method
    return {};
};

/**
 * gives time to the page to load asynchronous data based on the option
 * this data should be added inside options
 * @access public
 * @param {object} options
 * @param {loadData~cb} cb
 */
proto.loadData = function (options, cb) {
    cb(null, options);
};

/**
 * loadData callback
 * @callback loadData~cb
 * @param {error} error if any
 * @param {object} options
 */

/**
 *
 * @returns {string|null}
 * @access public
 */
proto.getName = function () {
    return null;
};

/**
 *
 * @returns {string|null}
 * @access public
 */
proto.getTitle = function () {
    return null;
};

/**
 *
 * @returns {string|null}
 * @access public
 */
proto.getUrl = function () {
    return null;
};

/**
 *
 * @access public
 * @param {function} callback
 */
proto.getRealtimePageInfo = function (callback) {
    setImmediate(callback);
};

/**
 *
 * @returns {string|null}
 * @access public
 */
proto.getSelectedHeaderItem = function () {
    return this.getName();
};
