const _ = require('lodash');
const suggestionTemplate = require('../../templates/citySuggestionItem.jade');
const {i18n: {translate: t}} = require('fack');
const SearchPlacesHelper = require('../../SearchPlacesHelper');

// @vue/component
module.exports = {
    extends: require('./TextField'),
    mixins: [
        require('./mixins/suggestionsMixin'),
    ],
    props: {
        noSuggestionsMessage: {
            type: String,
            default() {
                return t('noCityFound');
            },
        },
    },
    methods: {
        querySuggestions(text, callback) {
            if (text) {
                SearchPlacesHelper.querySuggestions(text, (err, suggestions) => {
                    if (err) {
                        console.error('City suggestions error', err);
                    }
                    callback(suggestions || []);
                });
            } else {
                callback([]);
            }
        },
        getSuggestionValue(selectedItem) {
            const city = _.get(selectedItem, 'city');
            if (city) {
                this.$emit('update:postal-code', _.first(city.postalCodes));
                return city.name;
            }
            return null;
        },
        formatSuggestion(suggestion) {
            return suggestionTemplate(suggestion);
        },
    },
};
