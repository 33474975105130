const LocalStorage = require('./localStorage/LocalStorage');
const {getI18nLanguage} = require('../Cookies');
const NEW_USER_KEY_NAME = 'isNewUser';

module.exports = {
    detectNewUserFromI18nCookie,
    isNewUser,
};

function detectNewUserFromI18nCookie() {
    const isNewUser = !getI18nLanguage();
    LocalStorage.setValue(NEW_USER_KEY_NAME, isNewUser);
}

function isNewUser() {
    return LocalStorage.getValue(NEW_USER_KEY_NAME);
}
