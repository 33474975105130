const {EventEmitter} = require('events');
const util = require('util');

const IframeWidget = require('../IframeWidget');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');

module.exports = VirtualTour;

function VirtualTour(tour) {
    this.tour = tour;
}

util.inherits(VirtualTour, EventEmitter);

const proto = VirtualTour.prototype;

proto.open = function () {
    const virtualTourUrl = VirtualTourHelper.getUrlForTour(this.tour);
    if (VirtualTourHelper.canOpenVirtualTourInIframe(this.tour)) {
        const iframeWidget = new IframeWidget({
            iframeSrc: virtualTourUrl,
            showTitleContainer: false,
        });
        iframeWidget.on('close', () => {
            this.emit('close');
        });
        iframeWidget.on('open', () => {
            this.emit('open');
        });
        iframeWidget.show();
    } else {
        window.open(virtualTourUrl);
    }
};
