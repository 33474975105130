const CityLocationItem = require('./CityLocationItem');
const {i18n: {translate: t}} = require('fack');

module.exports = class DepartmentLocationItem extends CityLocationItem {
    getInTheLocationText() {
        return t('department.in.' + this._getDepartmentCode());
    }

    getPostalCodes() {
        return [this._getDepartmentCode()];
    }

    _getDepartmentCode() {
        return this._item.ref;
    }
};
