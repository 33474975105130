const {i18n: {translate}} = require('fack');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./Dpe.jade');
const {
    isBadEnergyPerformanceClassification,
    isEnergyPerformanceClassificationNotSubmittedOrUndefined,
} = require('../helper/energyDiagnosticsHelper');

const DpeLine = require('./DpeLine');

// @vue/component
module.exports = {
    components: {
        DpeLine,
    },
    mixins: [
        i18nMixin({
            prefix: 'EnergyDiagnostics.',
            keys: [
                'badEnergyPerformanceTextTop',
                'badEnergyPerformanceTextBottom',
                'badEnergyPerformanceTextInline',
                'finalEnergyConsumptionText',
            ],
        }),
        require('../mixins/commonEnergyDiagnostic'),
    ],
    props: {
        finalEnergyConsumption: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        isDpeClassificationWorstThanGesClassification() {
            const {dpe: {classification: dpeClassification}, ges: {classification: gesClassification}} = this;
            return (dpeClassification <= 'G') && (dpeClassification >= gesClassification);

        },
        energyPerformanceDiagnosticClassification() {
            const {dpe: {classification: dpeClassification}, ges: {classification: gesClassification}} = this;
            return isEnergyPerformanceClassificationNotSubmittedOrUndefined(gesClassification)
            || this.isDpeClassificationWorstThanGesClassification
                ? dpeClassification
                : gesClassification;
        },
        areDpeAndGesBothNotSubmittedOrNullClassificationLabel() {
            return this.notSubmittedOrNullClassification(this.dpe.classification)
                && this.notSubmittedOrNullClassification(this.ges.classification);
        },
        dpeDataConfiguration() {
            return this.dataConfiguration(this.energyPerformanceDiagnosticClassification);
        },
        dpeTitle() {
            return this.dpeTranslation('title', {context: 'newEPD'});
        },
        highestEfficiencyDescription() {
            return this.dpeTranslation('highestEfficiencyDescription');
        },
        lowestEfficiencyDescription() {
            return this.dpeTranslation('lowestEfficiencyDescription');
        },
        expandedOrActiveDpeData() {
            return this.dpeDataConfiguration.filter(dpeData => {
                return this.isExpandedOrActive(dpeData.active);
            });
        },
        dpeContainerClass() {
            const {expanded, areDpeAndGesBothNotSubmittedOrNullClassificationLabel} = this;
            return {
                expanded: expanded,
                'dpe__container--without-padding-top': areDpeAndGesBothNotSubmittedOrNullClassificationLabel,
            };
        },
    },
    methods: {
        isNotBadEnergyPerformanceClassification(classification) {
            return !isBadEnergyPerformanceClassification(classification);
        },
        dpeTranslation(path, context) {
            return translate(`diagnosticModal.dpe.${path}`, context);
        },
    },
    template: template(),
};
