const _ = require('lodash');

const template = require('./BlogCategorySelector.jade');
const ContainerQueries = require('../../../vue/mixins/ContainerQueries');
const ApplicationConfig = require('../../../app/ApplicationConfig');
const BlogCategory = require('./BlogCategory');

const SMALL_MENU_BREAKPOINT_PUBLIC = 940;
const SMALL_MENU_BREAKPOINT_PRO = 450;

// @vue/component
module.exports = {
    components: {
        BlogCategory,
    },
    mixins: [
        ContainerQueries({
            smallPublic: {maxWidth: SMALL_MENU_BREAKPOINT_PUBLIC},
            smallPro: {maxWidth: SMALL_MENU_BREAKPOINT_PRO},
        }),
    ],
    props: {
        categories: {
            type: Array,
            default: () => [],
        },
        selectedCategory: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        smallMenuEnabled() {
            const {smallPro, smallPublic} = this.breakpoints;
            return ApplicationConfig.applicationPro ? smallPro : smallPublic;
        },
        homeCategory() {
            return _.find(this.categories, {isHome: true});
        },
        internalSelectedCategory() {
            return this.selectedCategory || this.homeCategory;
        },
    },
    methods: {
        getHref(category) {
            const slugs = category.path.map((category) => category.slug);
            return `/${slugs.join('/')}`;
        },
        getCategoryModifiers(category) {
            const isSelected = this.isSelected(category);
            return {
                highlight: this.smallMenuEnabled && isSelected,
                underline: !this.smallMenuEnabled && isSelected,
            };
        },
        handleMenuItemClick(event, category) {
            this.$emit('category-selected', event, category);
        },
        isSelected(category) {
            return this.internalSelectedCategory.slug === category.slug;
        },
        isParentSelected(category) {
            return this.isSelected(category)
                || Boolean(_.find(category.subTags, {slug: this.internalSelectedCategory.slug}));
        },
        desktopToggleModifiers(category) {
            return {
                underline: this.isParentSelected(category),
            };
        },
        itemModifiers(category) {
            return {
                highlight: this.isSelected(category),
            };
        },
        homeModifiers(category) {
            return {
                underline: this.isSelected(category),
            };
        },
        hasSubTags(category) {
            return Boolean(category.subTags);
        },
    },
    template: template(),
};
