const $ = require('jquery');
const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const template = require('../templates/fields/dateField.jade');

module.exports = class DateWidget {
    constructor($container, data) {
        this.$container = $container;
        this.required = data.required;
        this.$element = $(template({
            title: data.title,
            data,
            t: translate,
        }));
        this.name = data.name;
        this.$input = this.$element.find('.form-control');
        this.initInput();
    }

    initInput() {
        this.$container.append(this.$element);
        this.$datePicker = this.$element.find('.datepicker');
        const datepicker = this.$datePicker.datepicker({
            language: 'fr',
            autoclose: true,
            todayHighlight: true,
            todayBtn: true,
            format: 'yyyy-mm-dd',
        });
        const self = this;
        datepicker.on('clearDate changeDate', function () {
            self.$input.trigger('input'); //for bootstrap validator
        });
    }

    clear() {
        this.$element.remove();
    }

    getDate() {
        const date = this.$datePicker.datepicker('getUTCDate');
        if (date && _.isNaN(date.valueOf())) {
            return null;
        }
        return date;
    }

    serializeInto(result) {
        result[this.name] = this.getFieldValue();
    }

    getFieldValue() {
        const date = this.getDate();
        return date && date.toISOString();
    }

    /**
     *
     * @param {string} value date in ISO 8601 string format
     */
    setFieldValue(value) {
        this.$datePicker.datepicker('setUTCDate', new Date(value));
    }

    hide() {
        this.$element.hide();
    }
};
