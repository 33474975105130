const _ = require('lodash');
const template = require('./BlockWithTitle.jade');

// @vue/component
module.exports = {
    bemName: 'block-with-title',
    props: {
        title: {
            type: String,
            required: true,
        },
        noBorder: Boolean,
        bold: Boolean,
    },
    computed: {
        modifiers() {
            return {
                withBorder: !this.noBorder,
            };
        },
        contentModifiers() {
            return _.pick(this, 'bold');
        },
    },
    template: template(),
};
