const _ = require('lodash');
const {logger} = require('fack');

const adFieldsConfig = require('./adFieldsConfig');
const getPropertyTypeForAdType = require('./getPropertyTypeForAdType');
const AdTypeHelper = require('./AdTypeHelper');

const collapsedFormSections = ['additional_criteria'];

const DEFAULT_PROPERTY_TYPE = 'unknown';

const adFieldsCache = {};

module.exports = {
    getAdFieldsWithSections,
    getAdFieldsForDisplay,
};

function getAdFieldsWithSections({mode = 'display', adType, propertyType}) {
    buildModeInCacheIfNeeded(mode);
    if (adType || propertyType) {
        return getAdFieldsByAdAndPropertyType(adType, propertyType, mode);
    } else {
        return adFieldsCache[mode];
    }
}

function getAdFieldsForDisplay(options) {
    const adFieldsConfig = getAdFieldsWithSections(options);
    let adFields;
    if (!adFieldsConfig.error) {
        adFields = _.flatten(_.values(adFieldsConfig));
    }
    return adFields || ['id']; //at the very least, return id field
}

function getAdFieldsByAdAndPropertyType(adType, propertyType = DEFAULT_PROPERTY_TYPE, mode = 'display') {
    adType = adType || AdTypeHelper.getTransactionTypeFromAd({adType, propertyType});
    if (adFieldsCache[mode][adType]) {
        return adFieldsCache[mode][adType][propertyType] || adFieldsCache[mode][adType][DEFAULT_PROPERTY_TYPE];
    } else {
        return handleError(new Error(`Can't find ad fields matching : ${JSON.stringify({mode, adType, propertyType})}`));
    }
}

function handleError(error) {
    logger.error(error);
    return {
        error: {
            message: error.message,
        },
    };
}

function buildModeInCacheIfNeeded(mode) {
    if (!isModeAlreadyInCache(mode)) {
        const adFields = {};
        _.each([undefined].concat(AdTypeHelper.getAdTypes()), function (adType) {
            adFields[adType] = {};
            _.each(getPropertyTypes(adType), function (propertyType) {
                adFields[adType][propertyType] = getFieldsAndOptions(adType, propertyType, mode);
            });
        });
        adFieldsCache[mode] = adFields;
    }
}

function isModeAlreadyInCache(mode) {
    return adFieldsCache[mode];
}

function getFieldsAndOptions(adType, propertyType, mode) {
    const fields = getFields(adType, propertyType, mode);
    _.each(fields, function (sectionFields, sectionName) {
        fields[sectionName] = setSectionDisplayOptions(sectionFields, sectionName, mode);
    });
    return fields;
}

function getFields(adType, propertyType, mode) {
    const fields = {};
    _.each(adFieldsConfig, function (fieldConfig) {
        const sectionName = getSectionData(fieldConfig, mode);
        if (isFieldAvailableInBoth(fieldConfig, adType, propertyType) && sectionName) {
            addFieldToFields(fields, fieldConfig, sectionName, propertyType);
        }
    });
    return fields;
}

function isFieldAvailableInBoth(fieldConfig, adType, propertyType) {
    return isFieldAvailable(fieldConfig, 'adTypes', adType)
        && isFieldAvailable(fieldConfig, 'propertyTypes', propertyType);
}

function getSectionData(field, mode) {
    return field.section && field.section[mode];
}

function isRequired(fieldConfig, propertyType) {
    return fieldConfig.required || isRequiredForThisPropertyType(fieldConfig, propertyType);
}

function isRequiredForThisPropertyType(fieldConfig, propertyType) {
    return _.some(fieldConfig.propertyTypes, function (elem) {
        return elem.name == propertyType && elem.required;
    });
}

function addFieldToFields(fields, field, sectionName, propertyType) {
    fields[sectionName] = fields[sectionName] || {};
    fields[sectionName].fields = fields[sectionName].fields || [];
    fields[sectionName].fields.push(formatField(field, propertyType));
}

function formatField(field, propertyType) {
    return cleanupField(setRequiredParam(field, propertyType));
}

function setRequiredParam(field, propertyType) {
    return _.extend({}, {
        required: isRequired(field, propertyType),
    }, field);
}

function isFieldAvailable(fieldConfig, type, value) {
    if (!fieldConfig[type]) {
        return true;
    } else if (_.isString(fieldConfig[type])) {
        return value == fieldConfig[type];
    } else {
        return _.some(fieldConfig[type], function (elem) {
            return value == (_.isObject(elem) ? elem.name : elem);
        });
    }
}

function getPropertyTypes(adType) {
    return getPropertyTypeForAdType(adType);
}

function setSectionDisplayOptions(sectionFields, section, mode) {
    if (mode == 'form') {
        return _.extend(sectionFields, {
            collapseType: _.includes(collapsedFormSections, section) ? 'closed' : 'always_opened',
        });
    } else {
        return _.map(sectionFields.fields, field => field.name || field);
    }
}

function cleanupField(field) {
    const cleanField = _.pick(field,
        'name',
        'professionalOnly',
        'required'
    );
    return isNameEnough(cleanField) ? cleanField.name : cleanField;
}

function isNameEnough(field) {
    return (_.size(field) == 1 && field.name) || (_.size(field) == 2 && field.name && field.required === false);
}
