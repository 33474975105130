const _ = require('lodash');
const $ = require('jquery');
require('bootstrap-3-typeahead');
const Typeahead = $.fn.typeahead.noConflict().Constructor;
const ApplicationConfig = require('../app/ApplicationConfig');
const Account = require('../authentication/Account');
const View = require('./View');
const Error404 = require('../Error404');
const template = require('../templates/userDirectory/selectOwnedAccountTemplate.jade');
const CityArrondissementHelper = require('../../common/CityArrondissementHelper');
const {i18n: {translate}} = require('fack');

module.exports = class SelectOwnedAccountView extends View {
    constructor(options) {
        super(_.defaults({
            template,
        }, options));
        if (options) {
            this.menu = options.menu;
        }
    }

    show(options) {
        if (!options.ownedAccounts || !options.ownedAccounts.length) {
            return; //nothing to display
        }

        if (ApplicationConfig.applicationPro && Account.hasOnlyClosedOrDisabledOwnedAccounts(options.ownedAccounts)) {
            return; // no active account
        }

        const canShowOwnAccountData = !ApplicationConfig.applicationPro;
        const mainAccount = getMainAccount(options);
        super.show(_.extend({}, options, {
            mainAccount,
            selectedAccount: getSelectedAccount(options),
            menuItem: this.menu,
            canShowOwnAccountData,
            translationContext: {context: ApplicationConfig.applicationPro ? 'pro' : 'public'},
            Account,
            displayContactLink: (ApplicationConfig.applicationPro && mainAccount.canSeeChildrenContacts)
                || Account.hasRole('contactViewer'),
        }));

        this.$selectAccount = this.$element.find('#selectAccount');
        this.$dropdownSpecial = this.$element.find('.dropdownSpecial');
        this.toggleSelectAccountDropdownMenuHandler = _.bind(this.toggleDropdown, this);
        this.$toggleSelectAccountDropdownMenu = this.$element.find('.dropdownSpecial .dropdown-toggleSpecial');
        this.$toggleSelectAccountDropdownMenu.on('click', this.toggleSelectAccountDropdownMenuHandler);

        let elements = _.clone(options.ownedAccounts);
        if (canShowOwnAccountData) {
            elements = getMainAccountElements(options).concat(elements);
        }

        new Typeahead(this.$selectAccount, {
            autoSelect: false,
            source: elements,
            minLength: 0,
            items: 'all',
            showHintOnFocus: 'all',
            displayText: accountToText,
            afterSelect: selectedItem => {
                if (selectedItem) {
                    this.emit('accountSelected', selectedItem._id, selectedItem.ownData);
                    this.toggleDropdown();
                }
            },
        });

        $('body').addClass('withOwnedAccounts');

        //Todo Maybe not the best way to hide this element
        this.$dropdownSpecial.on('click', function (event) {
            event.stopPropagation();
        });
        this.$bodyWithOwnedAccounts = $('body.withOwnedAccounts');
        this.hideDropdownHandler = _.bind(this.hideDropdown, this);
        this.$bodyWithOwnedAccounts.on('click', this.hideDropdownHandler);
        this.keyDownHandler = _.bind(function (e) {
            // escape key maps to keycode `27`
            if (e.keyCode == 27) {
                this.hideDropdown();
            }
        }, this);
        this.$bodyWithOwnedAccounts.on('keydown', this.keyDownHandler);
    }

    toggleDropdown() {
        this.$dropdownSpecial.toggleClass('isOpen');
        this.$selectAccount.focus();
    }

    hideDropdown() {
        this.$dropdownSpecial.removeClass('isOpen');
    }

    hide(options, cb) {
        if (this.isShown()) {
            this.$toggleSelectAccountDropdownMenu.off('click', this.toggleSelectAccountDropdownMenuHandler);
            this.$bodyWithOwnedAccounts.off('click', this.hideDropdownHandler);
            this.$bodyWithOwnedAccounts.off('keydown', this.keyDownHandler);
        }
        $('body').removeClass('withOwnedAccounts');
        super.hide(options, cb);
    }

    loadData(options, cb) {
        if (options.ownedAccounts) {
            _.defer(cb);
        } else {
            const authorId = options.authorId || options.author && options.author.id;
            const accountId = options.accountId || options.account && options.account.id;
            getOwnedAccounts(authorId || accountId, (err, ownedAccounts) => {
                if (err) {
                    console.error(err, 'Error loading OwnedAccounts #', authorId);
                    if (err.code == 404) {
                        cb(new Error404(err.message));
                    } else {
                        cb(err);
                    }
                } else {
                    options.ownedAccounts = ownedAccounts;
                    cb(null, options);
                }
            });
        }
    }
};

function getMainAccountElements(options) {
    const mainAccount = getMainAccount(options);
    const elements = [];
    const isNetworkOrImport = _.includes(['network', 'import'], mainAccount.accountType);
    if (!isNetworkOrImport) {
        const mainAccountDisplayName = mainAccount.display_name + ' ('
            + translate('ownedAccountSelection.globalAccount_public') + ')';
        elements.push({
            _id: mainAccount.id,
            rawDisplayName: mainAccountDisplayName,
            ownData: false,
        });
        const mainAccountDisplayNameWithOwnData = mainAccount.display_name + ' ('
            + translate('ownedAccountSelection.onlyOwnDataAccount_public') + ')';
        elements.push({
            _id: mainAccount.id,
            rawDisplayName: mainAccountDisplayNameWithOwnData,
            ownData: true,
        });
    }
    return elements;
}

function getMainAccount(options) {
    let mainAccount;
    if (ApplicationConfig.applicationPro) {
        mainAccount = Account.getAuthenticatedAccount();
    } else {
        mainAccount = getSelectedAccount(options);
    }
    return mainAccount;
}

function getSelectedAccount(options) {
    return options.author || options.account
        || _.get(options.searchView || options.searchController, 'configuration.author');
}

function accountToText(account) {
    let text = account.rawDisplayName || account.display_name;
    if (account.company && account.company.address) {
        if (account.company.address.postalCode &&
            CityArrondissementHelper.isArrondissementPostalCode(account.company.address.postalCode)) {
            text += ' - ' + CityArrondissementHelper.getArrondissementName(account.company.address.postalCode);
        } else if (account.company.address.city) {
            text += ' - ' + account.company.address.city;
        }
    }
    if (account.closed || account.disabled) {
        text += ' (inactif)';
    }
    return text;
}

function getOwnedAccounts(authorId, cb) {
    if (ApplicationConfig.applicationPro) {
        _.defer(() => {
            cb(null, Account.getAuthenticatedAccountOwnedAccounts());
        });
    } else if (authorId && Account.hasRole('accountStatsViewer')) {
        Account.findOwnedAccounts(authorId, cb);
    } else {
        _.defer(() => {
            cb(null, []);
        });
    }
}
