const _ = require('lodash');
const {MODIFIER_SEPARATOR} = require('../Constants');

module.exports = {
    install(Vue) {
        Vue.directive('cq', {
            bind($el, binding, vnode) {
                vnode.context.$on('$cq:resize', breakpoints => {
                    const {classList} = $el;
                    const elementClassesWithoutMod = _.filter(classList, className => !_.includes(className, MODIFIER_SEPARATOR));
                    _.each(elementClassesWithoutMod, elementClass => {
                        _.each(breakpoints, (value, name) => {
                            classList.toggle(`${elementClass}${MODIFIER_SEPARATOR}${name}`, value);
                        });
                    });
                });
            },
            unbind($el, binding, vnode) {
                vnode.context.$off('$cq:resize');
            },
        });
    },
};
