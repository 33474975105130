const {EventEmitter} = require('events');
const _ = require('lodash');

const {INITIAL_STEP} = require('./Constants');
const ApplicationPage = require('../pages/ApplicationPage');
const EventPack = require('../utils/EventPack');
const History = require('../utils/History');
const ScrollHelper = require('../utils/ScrollHelper');
const VueView = require('../vue/VueView');

//workaround History not having unbind
const localEventEmitter = new EventEmitter();
History.Adapter.bind(window, 'statechange', (event) => {
    localEventEmitter.emit('statechange', event);
});

module.exports = class RealEstatePropertyDescriptorPage extends ApplicationPage {
    constructor(options) {
        super(options);
        this.transactionType = options.transactionType;
        this.step = INITIAL_STEP;
        this._eventPack = new EventPack();
    }

    onPreStepChange(step) {
        const url = location.href;
        History.replaceState({step}, document.title, url);
    }

    onStepChange(step) {
        this.step = step;
        this.sendStepToGTM(step);
        ScrollHelper.scrollToTop();
    }

    onStepChangeFromUserInteraction(step) {
        const url = location.href;
        History.pushState({step}, document.title, url);
    }

    // override this function to add more data
    getLoadOptions(options) {
        return options;
    }

    _loadData(options, callback) {
        const loadOptions = this.getLoadOptions(options);
        this.simpleContentLoadDataRequest(loadOptions, callback);
    }

    open(options) {
        super.open(options);
        // todo: move in created when page component is available
        const vueView = this.vueView;
        this._eventPack.on(localEventEmitter, 'statechange', () => {
            const {data} = History.getState();
            const historyStep = _.get(data, 'step', INITIAL_STEP);
            vueView.vm.stepToGo = historyStep;
        });
    }

    close() {
        this._eventPack.removeAllListeners();
        super.close();
    }

    // override this function to add more options for vueView
    getMiddleViewsMixin() {
        return [];
    }

    // override this function to set template for vueView
    getTemplate() {
        throw new Error('Must be implemented by subclass');
    }

    getMiddleViews(options) {
        const page = this;
        const template = this.getTemplate();
        const vueView = new VueView(
            {template},
            // @vue/component
            {
                mixins: this.getMiddleViewsMixin(),
                data() {
                    return {
                        transactionType: page.transactionType,
                        firstStepHtmlContent: '',
                        step: INITIAL_STEP,
                        stepToGo: INITIAL_STEP,
                    };
                },
                mounted() {
                    // safari 12 workaround to iframe html injection breaking vue first render
                    this.$nextTick(() => {
                        this.firstStepHtmlContent = options.htmlContent;
                    });
                },
                methods: {
                    onPreStepChange(step) {
                        page.onPreStepChange(step);
                    },
                    onStepChange(step) {
                        page.onStepChange(step);
                    },
                    onStepChangeFromUserInteraction(step) {
                        page.onStepChangeFromUserInteraction(step);
                    },
                },
            }
        );
        this.vueView = vueView;
        return vueView;
    }
};
