const _ = require('lodash');
const AccountStatsPageView = require('../views/AccountStatsPageView');
const AccountStatsView = require('../views/AccountStatsView');
const SelectOwnedAccountView = require('../views/SelectOwnedAccountView');
const ApplicationPage = require('./ApplicationPage');
const Account = require('../authentication/Account');
const PageManager = require('./PageManager');
const Pages = require('./Pages');
const BrowserDetect = require('browser-detect');
const EventPack = require('../utils/EventPack');

module.exports = class AbstractAccountStatsPage extends ApplicationPage {
    constructor(configuration) {
        super(configuration);
        this._onlyOwnData = false;
        this._accountStatsPageView = new AccountStatsPageView();
        this._accountStatsPageView.show(); //trick so we can insert accountStatsView and selectOwnedAccountView on the right dom element
        this._eventPack = new EventPack();
    }

    open(options) {
        options = _.extend(options, {
            accountId: this.accountId,
        });
        super.open(options);

        if (this._selectOwnedAccountView) {
            this._eventPack.on(this._selectOwnedAccountView, 'accountSelected', _.bind(this._onAccountSelected, this));
        }
    }

    close() {
        this._eventPack.removeAllListeners();
        delete this._selectOwnedAccountView;
        super.close();
    }

    getMiddleViews() {
        if (!this._accountStatsView) {
            this._accountStatsView = new AccountStatsView(this._accountStatsPageView.getAccountStatsContainer());
        }
        const views = [this._accountStatsView];
        const showOwnedAccountSelection = this.options.showOwnedAccountSelection && !BrowserDetect.isMobile();
        if (showOwnedAccountSelection) {
            if (!this._selectOwnedAccountView) {
                this._selectOwnedAccountView = new SelectOwnedAccountView({
                    $container: this._accountStatsPageView.getSelectAccountHeaderContainer(),
                    menu: 'myAccountStatsPage',
                });
            }
            views.push(this._selectOwnedAccountView);
        }
        return views;
    }

    _onAccountSelected(accountId, onlyOwnData) {
        //Pages.myAccountStatsPage does not exist yet in public application
        if (accountId == Account.getAuthenticatedAccountId() && Pages.myAccountStatsPage) {
            PageManager.openPage(Pages.myAccountStatsPage, {onlyOwnData});
        } else {
            PageManager.openPage(Pages.accountStatsPage, {
                accountId,
                isAdmin: Account.isAdmin(),
                onlyOwnData,
            });
        }
    }
};
