const _ = require('lodash');

const RangeTextFormatter = require('./RangeTextFormatter');
const TextFiltersFormatter = require('./TextFiltersFormatter');
const BaseTitleGenerator = require('./BaseTitleGenerator');

const RANGE_CRITERIA = [
    'price',
    'rooms',
    'bedrooms',
    'area',
    'gardenSurfaceArea',
];

module.exports = {
    getCriteria,
    getCapitalizedCriteria,
};

function getCriteria(search, options) {
    const criteria = getVisibleCriteria(search, options);
    const rangeCriteria = _.map(RANGE_CRITERIA, criterion => RangeTextFormatter.formatRangeForProperty(criterion, criteria));
    const booleanCriteria = TextFiltersFormatter.formatBooleanFilters(_.omit(criteria, 'newProperty'));
    const formattedCriteria = [
        TextFiltersFormatter.formatPropertyTypes(criteria.propertyType),
        ...rangeCriteria,
        TextFiltersFormatter.formatNewProperty(criteria.newProperty),
        TextFiltersFormatter.formatEnergyClassification(criteria.energyClassification),
        booleanCriteria,
    ];
    return _.compact(formattedCriteria);
}

function getCapitalizedCriteria(search, options) {
    return getCriteria(search, options).map(criterion => _.upperFirst(criterion));
}

function getVisibleCriteria(search, {hiddenCriteria = []} = {}) {
    hiddenCriteria = ['excludeAgencyNew', 'buyNewPropertySetInFilterType', ...hiddenCriteria];
    const propertyTypeForTitle = BaseTitleGenerator.getPropertyTypeForTitle(search);
    if (_.includes(['studio', 'twoRoomsFlat', 'threeRoomsFlat'], propertyTypeForTitle)) {
        hiddenCriteria.push('minRooms', 'maxRooms');
    }
    const {propertyType} = search;
    if (!_.isArray(propertyType) || _.size(propertyType) <= 1) {
        hiddenCriteria.push('propertyType');
    }

    return _.omit(search, hiddenCriteria);
}
