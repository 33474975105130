const {resourceUrl} = require('fack');

const AdsNotificationBoostsHistory = require('./components/AdsNotificationBoostsHistory');
const template = require('./AccountAdsNotificationBoostsHistoryPage.jade');
const CompositeVueView = require('../vue/CompositeVueView');
const Account = require('../authentication/Account');
const UserAdminActions = require('../views/utils/UserAdminActions');
const {insertUserInfoView} = require('../views/UserInfoView');
const {getImageUrlFromAlias} = require('../ImageHelper');
const EventPack = require('../utils/EventPack');

module.exports = class AccountAdsNotificationBoostsHistoryView extends CompositeVueView {
    constructor() {
        super();
        this.template = template;
        this._eventPack = new EventPack();
    }

    hide() {
        if (this._userAdminActions) {
            this._userAdminActions.unbind();
        }
        this._eventPack.removeAllListeners();
        super.hide();
    }

    show(options) {
        const {account, boostsHistory} = options;
        const displayUserInfo = !((account && account.id) === Account.getAuthenticatedAccountId());

        const vueOptions = {
            components: {
                AdsNotificationBoostsHistory,
            },
            computed: {
                accountId() {
                    return account.id;
                },
                boostsHistory() {
                    return boostsHistory;
                },
                displayUserInfo() {
                    return displayUserInfo;
                },
                agencyBackgroundImageUrl() {
                    return getImageUrlFromAlias(account.agencyBackgroundImage) || resourceUrl('images/homepage/hp_pro.jpg');
                },
                accountInfoBlockStyle() {
                    return `background-image:url('${this.agencyBackgroundImageUrl}')`;
                },
            },
        };
        super.show(options, vueOptions);
        if (displayUserInfo) {
            this._displayUserInfo(options);
        }
    }

    _displayUserInfo(options) {
        insertUserInfoView(this.$element.find('.accountInfoContainer'), {
            user: options.account,
            isAuthor: false,
            hideActionsLinks: true,
            canSeeAdsNotificationBoostsHistory: false,
        });
        this._userAdminActions = new UserAdminActions({$element: this.$element});
        this._userAdminActions.bind();
        const $imgLoader = this.$element.find('#imgLoader');
        this._eventPack.on($imgLoader, 'load', () => {
            this.$element.find('.accountInfoBlock').addClass('bgIsLoaded');
            $imgLoader.remove();
        });
        if ($imgLoader[0].complete) {
            $imgLoader.load();
        }
    }
};
