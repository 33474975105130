const $ = require('jquery');
const {i18n: {translate}} = require('fack');
const MailCheckPlugin = require('../../fields/plugins/MailCheckPlugin');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseInputMixin'),
    ],
    props: {
        placeholder: {
            type: String,
            default: translate('placeholder.email'),
        },
    },
    beforeCreate() {
        this.type = 'email';
    },
    mounted() {
        MailCheckPlugin.listenOnInput($(this.$refs.input));
    },
};
