const _ = require('lodash');
const moment = require('moment');

const AccountStatsBaseStats = require('./AccountStatsBaseStats');
const template = require('./AccountStats.jade');
const MomentValidator = require('../../vue/validators/Moment');
const CancelableCallback = require('../../utils/CancelableCallback');
const StatsHelper = require('../StatsHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');
const RealtimeServer = require('../../RealtimeServer');

const {FETCH_ACCOUNT_STATS_THROTTLE_IN_MS} = require('./constants');
// Fetching account stats can use a lot of Elasticsearch resources if the account has many real estate ads,
// and accounts could receive a lot of "stats updated" notifications: we don't want to fetch their stats everytime.
const FETCH_ACCOUNT_STATS_FROM_REALTIME_NOTIFICATION_THROTTLE_IN_MS = 60e3;

const {
    ACCOUNT_CONTACT_TYPES,
    ACCOUNT_NOT_FOUND_ERROR_TRANSLATION_KEY,
    DASHBOARD_PRO_MODE,
    DATE_FORMAT,
    DEFAULT_ERROR_TRANSLATION_KEY,
} = require('./constants');

// @vue/component
module.exports = {
    components: {
        AccountStatsBaseStats,
    },
    extends: require('./BaseStats'),
    mixins: [
        i18nMixin({
            prefix: 'AccountStats.',
            keys: [
                ACCOUNT_NOT_FOUND_ERROR_TRANSLATION_KEY,
                DEFAULT_ERROR_TRANSLATION_KEY,
            ],
        }),
    ],
    props: {
        accountId: {
            type: String,
            required: true,
        },
        startDate: {
            type: Object,
            required: true,
            validator: MomentValidator,
        },
        endDate: {
            type: Object,
            required: true,
            validator: MomentValidator,
        },
        transactionTypes: {
            type: [Array, String],
            validator: transactionTypes => {
                if (_.isArray(transactionTypes)) {
                    return !_.isEmpty(transactionTypes) && _.isEmpty(_.difference(transactionTypes, ACCOUNT_CONTACT_TYPES));
                } else {
                    return _.includes(ACCOUNT_CONTACT_TYPES, transactionTypes);
                }
            },
            default: null,
        },
        onlyOwnData: Boolean,
    },
    data() {
        return {
            isLoading: true,
            pendingCallback: null,
            stats: null,
            errorTranslationKey: null,
            loadingDisabled: true,
        };
    },
    computed: {
        spinnerContainerStyles() {
            const styles = {};
            if (this.mode === DASHBOARD_PRO_MODE) {
                styles.padding = 0;
            }
            return styles;
        },
        periods() {
            return _(['startDate', 'endDate'])
                .map(property => [
                    property,
                    this.getFormattedDate(this[property]),
                ])
                .fromPairs()
                .value();
        },
        errorMessage() {
            const {errorTranslationKey} = this;
            return errorTranslationKey && this[errorTranslationKey];
        },
        isSameOrBeforeEndDateDay() {
            return moment().startOf('day').isSameOrBefore(this.endDate);
        },
    },
    watch: {
        onlyOwnData() {
            this.fetchStats();
        },
        endDate() {
            this.fetchStats();
        },
        startDate() {
            this.fetchStats();
        },
        transactionTypes() {
            this.fetchStats();
        },
        isSameOrBeforeEndDateDay() {
            this.enableOrDisableLiveNotifications();
        },
        accountId() {
            this.closeRealtime();
            this.getStatsAndRegisterForUpdates();
        },
    },
    mounted() {
        this.fetchStats = _.throttle(this.retrieveStats, FETCH_ACCOUNT_STATS_THROTTLE_IN_MS, {
            leading: false,
        });
        this.fetchStatsFromNotification = _.throttle(
            this.fetchStatsWithoutLoading,
            FETCH_ACCOUNT_STATS_FROM_REALTIME_NOTIFICATION_THROTTLE_IN_MS);
        this.getStatsAndRegisterForUpdates();
    },
    destroy() {
        this.closeRealtime();
    },
    methods: {
        getStatsAndRegisterForUpdates() {
            this.connectRealtime();
            this.enableOrDisableLiveNotifications();
            this.fetchStats();
        },
        connectRealtime() {
            const realtimeContext = this.realtimeContext = RealtimeServer.openContext();
            realtimeContext.join(`accountAds#${this.accountId}:stats`);
        },
        closeRealtime() {
            _.invoke(this.realtimeContext, 'close');
        },
        enableOrDisableLiveNotifications() {
            this.isSameOrBeforeEndDateDay ? this.enableLiveNotifications() : this.disableLiveNotifications();
        },
        enableLiveNotifications() {
            this.realtimeContext.on('stats', this.fetchStatsFromNotification);
        },
        disableLiveNotifications() {
            this.realtimeContext.off('stats', this.fetchStatsFromNotification);
        },
        fetchStatsWithoutLoading() {
            this.loadingDisabled = true;
            this.fetchStats();
        },
        retrieveStats() {
            if (this.loadingDisabled) {
                this.loadingDisabled = false;
            } else {
                this.isLoading = true;
            }

            this.setPendingCallback();

            const {
                onlyOwnData,
                periods,
                transactionTypes,
                accountId,
            } = this;

            const {
                startDate,
                endDate,
            } = periods;

            const data = _.omitBy({
                accountId,
                onlyOwnData,
                startDate,
                endDate,
                transactionTypes,
            }, _.isNil);
            StatsHelper.getAccountPeriodStats(data, this.pendingCallback);
        },
        setPendingCallback() {
            // eslint-disable-next-line handle-callback-err
            this.pendingCallback = CancelableCallback((err, data) => {
                this.cancelStatsRequest();
                this.errorTranslationKey = null;
                if (err) {
                    if (err.code == 404) {
                        this.errorTranslationKey = ACCOUNT_NOT_FOUND_ERROR_TRANSLATION_KEY;
                    } else {
                        console.error(err.message);
                        this.errorTranslationKey = DEFAULT_ERROR_TRANSLATION_KEY; //TODO link to retry
                    }
                } else {
                    if (data && _.isString(data)) {
                        data = JSON.parse(data);
                    }
                    this.$emit('received-stats', data);
                    this.stats = this.makeStats(data.accountStats);
                }
                this.isLoading = false;
            });
        },
        cancelStatsRequest() {
            _.invoke(this.pendingCallback, 'cancel');
            this.pendingCallback = null;
        },
        getFormattedDate(date) {
            return date.format(DATE_FORMAT);
        },
    },
    template: template(),
};
