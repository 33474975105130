const LoanFormView = require('./LoanFormView');
const FormUtils = require('../fields/FormUtils');

module.exports = class LoanSimulatorFormView extends LoanFormView {
    constructor(options) {
        super(options);
    }

    show(options) {
        this.contactData = options.contactData;
        super.show(options);
    }

    _initForm(options) {
        super._initForm();
        this._fillFormValues(options);
    }

    _fillFormValues({previousSimulatorData}) {
        if (previousSimulatorData != null) {
            FormUtils.setValues(this.$form, previousSimulatorData);
        }
    }

    _getSubmitData() {
        return {simulatorData: this._getFormData(), contactData: this.contactData};
    }

    _submitForm() {
        this.showSpinner();
        super._submitForm();
    }
};
