const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');
const AsyncHelper = require('../utils/AsyncHelper');

module.exports = {
    adminRecompute,
};

function adminRecompute({
    realEstateAdId,
    action,
    requestMethod = 'GET',
    callback,
}) {
    new AsyncHelper().doAsync({
        func: function (callback) {
            return Account.authAjax({
                url: `${ServerConfig.config.importerUrl}/${action}/${realEstateAdId}`,
                method: requestMethod,
                disableErrorPage: true,
                callback,
            });
        },
        callback,
        name: 'adminRecompute',
    });
}
