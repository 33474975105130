const _ = require('lodash');
const $ = require('jquery');

const View = require('../views/View');

const EventPack = require('../utils/EventPack');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const SearchFieldsHandler = require('./SearchFieldsHandler');
const SearchByTravelTimeView = require('../views/SearchByTravelTimeView');
const SearchByTravelTimeForm = require('../views/SearchByTravelTimeForm');
const BrowserDetect = require('browser-detect');
const {housingAndBusiness} = require('../../common/PropertyTypes');
const defaultTemplate = require('../templates/compactSearchFormView.jade');
const intersects = require('../../common/intersects');

module.exports = class CompactSearchFilterView extends View {
    constructor({template, $container} = {}) {
        super({$container});
        this.template = template || defaultTemplate;
        this._eventPack = new EventPack();
    }

    _hasValidTravelTimeValues({mode, duration, address, type} = {}) {
        return (type == 'travelTimeZone' || _.some([mode, duration, address], value => !_.isNil(value)));
    }

    _getFirstTravelTimeZoneItem({locations} = {}) {
        return _.find(locations, location => location.type == 'travelTimeZone');
    }

    _getFirstTravelTimeItemValues(defaultSearch) {
        return _.pick(this._getFirstTravelTimeZoneItem(defaultSearch), ['address', 'mode', 'duration', 'type']);
    }

    _initTravelTimeSearch(options) {
        const defaultSearch = options.defaultSearch;
        const travelTimeTravelParams = this._getFirstTravelTimeItemValues(defaultSearch);
        _.extend(defaultSearch, travelTimeTravelParams);
        options.addressPlaceholderContext = BrowserDetect.isMobile() ? 'small' : options.addressPlaceholderContext;
        options.isTravelTimeIncludedInForm = this._hasValidTravelTimeValues(defaultSearch);
    }

    show(options) {
        this._initTravelTimeSearch(options);
        super.show(options);
        let submitCallback;
        if (options.isTravelTimeIncludedInForm) {
            submitCallback = _.bind(this._submitTravelTimeSearch, this);
        } else {
            submitCallback = _.bind(this._submit, this);
        }

        this._searchFieldsHandler = new SearchFieldsHandler(this.$element, _.assignIn({
            submit: submitCallback,
        }, _.extend(options.formOptions || {}, {
            defaultSearch: this.getDefaultSearchConfig(options),
        })));
        this._handleMinRoomsFilter();
        this._searchFieldsHandler.setDefaultSearch();
        this._searchFieldsHandler.preventLetterTypingInNumberPlaceholder();
        this._eventPack.on(this._searchFieldsHandler, {
            travelTimeSelected: _.bind(this._openTravelTimeSearch, this),
            change: ({name}) => {
                if (name === 'filterType') {
                    this._handleMinRoomsFilter();
                }
            },
        });

        if (options.isTravelTimeIncludedInForm) {
            this._searchByTravelTimeForm = new SearchByTravelTimeForm({
                $addressInput: this.$element.find('.searchAddress input'),
                $scrollableContainer: $('body'),
            });
        }
    }

    _openTravelTimeSearch() {
        SearchByTravelTimeView.open({
            submitCallback: travelTimeZone => {
                this._searchFieldsHandler.setTravelTimeSearch(travelTimeZone);
            },
            defaultItem: this._searchFieldsHandler.getFirstTravelTimeItem(),
            parentView: this,
        });
    }

    _submitTravelTimeSearch(err, cb) {
        if (err) {
            SearchByTravelTimeView.showError(err);
            cb(err);
        } else {
            this._searchByTravelTimeForm.suggestAndComputeZoneByTime(this._searchFieldsHandler.getFilters(), (err, item) => {
                if (!err && item) {
                    this._searchFieldsHandler.setTravelTimeSearch(item);
                    this.emit('search', this._searchFieldsHandler.getFilters());
                } else {
                    err = err || new Error('No item computed for travel time submit.');
                    SearchByTravelTimeView.showError(err);
                }
                cb(err);
            });
        }
    }

    _submit(err, cb) {
        if (_.get(err, 'isTravelTime')) {
            SearchByTravelTimeView.showError(err);
            this._openTravelTimeSearch();
        } else if (!err) {
            this.emit('search', this._searchFieldsHandler.getFilters());
        }
        cb(err);
    }

    hide() {
        super.hide();
        if (this._searchByTravelTimeForm) {
            this._searchByTravelTimeForm.cancelRequest();
            this._searchByTravelTimeForm = null;
        }
        this._searchFieldsHandler.destroyFields();
        this._eventPack.removeAllListeners();
    }

    getDefaultSearchConfig(options) {
        return _.defaults(_.get(options, 'defaultSearch'), DefaultConfiguration.search);
    }

    _handleMinRoomsFilter() {
        const propertyTypes = this._searchFieldsHandler.getFilters().propertyType;
        const minRoomsFilterEnabled = intersects(housingAndBusiness, propertyTypes);
        this.$element.find('.minRoomsHomePageSelectPicker').parent().toggle(minRoomsFilterEnabled);
    }
};
