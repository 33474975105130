const urlUtil = require('url');
const ServerConfig = require('../ServerConfig');

const View = require('./View');
const template = require('../templates/detailedSheetBehindAuthenticationView.jade');

module.exports = class DetailedSheetBehindAuthenticationView extends View {
    constructor(options) {
        super(options);
        this.template = template;
        const {realEstateAdId} = options;
        this.realEstateAdId = realEstateAdId;
    }

    show() {
        const {protocol, host} = urlUtil.parse(ServerConfig.config.wwwUrl);
        const adLink = urlUtil.format({
            protocol,
            host,
            pathname: '/annonce/' + this.realEstateAdId,
        });
        super.show({adLink});
    }
};
