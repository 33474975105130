const OGIC_MULTIREGIONAL_LINK = 'https://www.ogic.fr/programmes/offres-juin-23-nat/?utm_source=BienIci&utm_medium=HomePage' +
    '&utm_campaign=CampJuin23HpBI23Juin';

const HIGHLIGHT = 'highlight';

const DEFAULT_SLIDE = {
    name: 'Caen',
    isDefault: true,
};

module.exports = function () {
    /**
     * Data used to configure homePage slides
     * @typedef {Object} SlideData
     * @property {string} name Codename of the slide and asset directory, sub directory of public/images/homepage/commercialAdSlides. Mandatory pictures names: 'background.jpg' and 'logo.png'
     * @property {string} campaign Campaign code sent as parameter of GTM event
     * @property {string} [topLeftPicture] Filename of top left corner picture. Mandatory if bottomRightPicture is set
     * @property {string} [bottomRightPicture] Filename of bottom right corner picture. Mandatory if topLeftPicture is set
     * @property {boolean} [hasLogo=true] Set logo picture display. If undefined OR true: Mandatory picture name: 'logo.png'
     * @property {string} [logoFrom] Use logo from specified slide's directory instead of own directory
     * @property {string} [externalLink] URL of the popup when clicking on a corner picture
     * @property {string} description Slide description showing on the lower dark container
     * @property {string} [descriptionBackgroundColor] Background color of the slide description
     * @property {Object[]} [periods] Periods when slide is showing (startDate and endDate format 'YYYY-MM-DD')
     * @property {Object[]} [disks] Localisation of the slide. Required only if the slide is not global. Mandatory if postalCodesPrefixes
     * @property {string[]} [postalCodesPrefixes] List of postal codes used for localisation. Mandatory if disks is set
     * @property {number} [priority=0] Priority used to choose between two local or two global slides. The higher the number, the higher the priority.
     * @property {boolean} [isCommercialAd] Define if it's a normal slide or a commercial one
     * @property {boolean} [isDefault] Show this slide when there is no matching zone
     * @property {string} [backgroundUrl] Set directly a path to an image
     */
    return [
        DEFAULT_SLIDE,
        {
            name: 'ogic-multiregional',
            campaign: 'ogic-multiregional-june-2023',
            externalLink: OGIC_MULTIREGIONAL_LINK,
            description: `<a href="${OGIC_MULTIREGIONAL_LINK}">Offres spéciales sur une sélection de `
                + 'résidences. Découvrir les offres ici. *Conditions sur ogic.fr</a>',
            periods: [
                {
                    startDate: '2023-06-09',
                    endDate: '2023-06-19',
                },
            ],
            topLeftPicture: HIGHLIGHT,
            bottomRightPicture: HIGHLIGHT,
            disks: [
                {
                    center: {lat: 46.1382, lng: 5.4558},
                    radius: 62000,
                },
                {
                    radius: 28000,
                    center: {lat: 43.5664, lng: 5.1274},
                },
                {
                    radius: 32000,
                    center: {lat: 43.5462, lng: 4.6042},
                },
                {
                    radius: 18000,
                    center: {lat: 43.8052, lng: 4.8246},
                },
                {
                    radius: 33000,
                    center: {lat: 43.4299, lng: 5.4792},
                },
                {
                    radius: 7000,
                    center: {lat: 43.6861, lng: 5.7551},
                },
                {
                    center: {lat: 45.7818, lng: 4.6650},
                    radius: 30e3,
                },
                {
                    center: {lat: 46.0925, lng: 4.5462},
                    radius: 26e3,
                },
                {
                    center: {lat: 45.7249, lng: 4.9780},
                    radius: 15e3,
                },
                {
                    center: {lat: 45.5208, lng: 4.7474},
                    radius: 10e3,
                },
                {
                    center: {lat: 48.9178, lng: 2.2860},
                    radius: 4.8e3,
                },
                {
                    center: {lat: 48.8844, lng: 2.2011},
                    radius: 4.3e3,
                },
                {
                    center: {lat: 48.831, lng: 2.2015},
                    radius: 5.2e3,
                },
                {
                    center: {lat: 48.78, lng: 2.2938},
                    radius: 6e3,
                },
                {
                    radius: 10000,
                    center: {lat: 48.9169, lng: 2.4864},
                },
                {
                    radius: 5000,
                    center: {lat: 48.9398, lng: 2.3419},
                },
                {
                    radius: 3000,
                    center: {lat: 48.998, lng: 2.5606},
                },
                {
                    radius: 3000,
                    center: {lat: 48.8284, lng: 2.577},
                },
            ],
            postalCodesPrefixes: ['01', '13', '69', '92', '93'],
            isCommercialAd: true,
        },
        {
            name: 'Bordeaux',
            postalCodesPrefixes: ['16', '17', '19', '23', '24', '33', '40', '47', '64', '79', '86', '87'],
            disks: [
                {
                    radius: 100e3,
                    center: {
                        lat: 44.7172,
                        lng: -0.5701,
                    },
                },
            ],
        },
        {
            name: 'Quimper',
            postalCodesPrefixes: ['22', '29', '35', '56'],
            disks: [
                {
                    radius: 100e3,
                    center: {
                        lat: 47.6467,
                        lng: -1.4402,
                    },
                },
                {
                    radius: 100e3,
                    center: {
                        lat: 48.1981,
                        lng: -3.6387,
                    },
                },
            ],
        },
        {
            name: 'Toulouse',
            postalCodesPrefixes: ['09', '11', '12', '30', '31', '32', '34', '46', '48', '65', '66', '81', '82'],
            disks: [
                {
                    radius: 100e3,
                    center: {
                        lat: 43.5574,
                        lng: 1.4573,
                    },
                },
            ],
        },
    ];
};
