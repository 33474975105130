const _ = require('lodash');

const {TRACKING_PREFIX, DATA_TRACKING_PREFIX} = require('../../stats/TrackingClasses');

module.exports = {
    inserted($el, {value}) {
        const trackingClassAndAttributes = getTrackingClassAndAttributes(value);
        addClassAndAttributes($el, trackingClassAndAttributes);
    },
    update($el, {value, oldValue}) {
        if (value && !_.isEqual(value, oldValue)) {
            const oldTrackingClassAndAttributes = getTrackingClassAndAttributes(oldValue);
            const trackingClassAndAttributes = getTrackingClassAndAttributes(value);
            removeClassAndAttributes($el, oldTrackingClassAndAttributes);
            addClassAndAttributes($el, trackingClassAndAttributes);
        }
    },
};

function addClassAndAttributes($el, {trackingClass, trackingAttributes}) {
    if (trackingClass) {
        $el.classList.add(addClassPrefix(trackingClass));
    }
    _.forEach(_.keys(trackingAttributes), attributeKey => {
        if (trackingAttributes[attributeKey] != null) {
            $el.setAttribute(addAttributePrefix(attributeKey), trackingAttributes[attributeKey]);
        }
    });
}

function removeClassAndAttributes($el, {trackingClass, trackingAttributes}) {
    $el.classList.remove(addClassPrefix(trackingClass));
    _.forEach(_.keys(trackingAttributes), attributeKey => {
        $el.removeAttribute(addAttributePrefix(attributeKey));
    });
}

function getTrackingClassAndAttributes(value) {
    if (_.isObject(value)) {
        return {
            trackingClass: value.class,
            trackingAttributes: _.omit(value, 'class'),
        };
    } else if (_.isString(value)) {
        return {trackingClass: value};
    } else {
        return {};
    }
}

function addAttributePrefix(attributeName) {
    return DATA_TRACKING_PREFIX + attributeName;
}

function addClassPrefix(className) {
    return TRACKING_PREFIX + className;
}
