// @vue/component
module.exports = {
    inject: {
        revalidateField: {
            default: null,
        },
        addField: {
            default: null,
        },
        removeField: {
            default: null,
        },
    },
    watch: {
        internalValue() {
            this.revalidate();
        },
    },
    mounted() {
        if (this.addField) {
            this.addField(this.name);
        }
    },
    destroyed() {
        if (this.removeField) {
            this.removeField(this.name);
        }
    },
    methods: {
        revalidate() {
            this.$nextTick(() => { // wait for DOM input element updated by Vue before validating
                if (this.revalidateField) {
                    this.revalidateField(this.name);
                }
            });
        },
        updateValidation() {
            if (this.removeField && this.addField) {
                this.$nextTick(() => {
                    const name = this.name;
                    this.removeField(name);
                    this.addField(name);
                });
            }
        },
    },
};
