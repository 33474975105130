const _ = require('lodash');
const FormUtils = require('../fields/FormUtils');
const template = require('../templates/searchByTravelTimeView.jade');
const View = require('./View');
const EventPack = require('../utils/EventPack');
const AddressSuggestionHelper = require('../utils/AddressSuggestionHelper');
const SearchByTravelTimeForm = require('./SearchByTravelTimeForm');
const Views = require('./Views');
const SpinnerHelper = require('../utils/SpinnerHelper');
const CancelableCallback = require('../utils/CancelableCallback');

module.exports = class SearchByTravelTimeView extends View {
    constructor(options) {
        super(_.defaults({
            template,
        }, options));
        this._eventPack = new EventPack();
    }

    show({submitCallback, defaultItem = {mode: 'car'}, saveZoneOnSubmit, context, requireAllFields}) {
        super.show({context, requireAllFields});
        this._openModal();
        this._$form = this.$element.find('form');
        this._$validateButton = this._$form.find('.validateParameterBtn');
        this._$addressInput = this.$element.find('.searchAddressInput');
        FormUtils.init({
            $form: this._$form,
            submit: () => {
                if (requireAllFields === false) {
                    submitCallback(_.extend(this._getForm(), {
                        type: 'travelTimeZone',
                        canBeUsedIncomplete: true,
                    }));
                    this._closeModal();
                } else {
                    const suggestFuncName = saveZoneOnSubmit ? 'suggestAndComputeZoneByTime' : 'suggest';
                    this._searchByTravelTimeForm[suggestFuncName](this._getForm(), (err, travelTimeZone) => {
                        if (!err && travelTimeZone) {
                            submitCallback(travelTimeZone);
                        } else {
                            err = err || new Error('No travel time zone found');
                            SearchByTravelTimeView.showError(err);
                        }
                        this._closeModal();
                    });
                }
            },
        });
        FormUtils.setValues(this._$form, defaultItem);
        this._initAddressFromPosition(defaultItem);
    }

    hide() {
        this._closeModal();
        this._eventPack.removeAllListeners();
        super.hide();
    }

    _initAddressFromPosition(defaultItem) {
        const {position} = AddressSuggestionHelper.parseAddress(_.get(defaultItem, 'address'));
        if (position) {
            this._showFormSpinner();
            this._toggleSuggestionSpinner(true);
            this._$addressInput.val('');
            this._pendingCallback = CancelableCallback((err, {address}) => {
                if (err) {
                    this._showError(err);
                } else {
                    this._$addressInput.val(address);
                    this._hideFormSpinner();
                }
                this._toggleSuggestionSpinner(false);
            });
            AddressSuggestionHelper.queryNearestAddressFromPosition(position, this._pendingCallback);
        }
    }

    _getSuggestionDisplayName(suggestion) {
        return AddressSuggestionHelper.getDisplayNameWithHashTags(suggestion);
    }

    _getForm() {
        let values = FormUtils.readValues(this._$form);
        values = _.omitBy(values, value => value === '' || value == null);
        if (values.duration != null) {
            values.duration = +values.duration;
        }
        return values;
    }

    _openModal() {
        this.$element.modal('show');
        this._searchByTravelTimeForm = new SearchByTravelTimeForm({
            $addressInput: this.$element.find('.searchAddress input'),
            $scrollableContainer: this.$element,
        });
        this._eventPack.on(this.$element, 'hidden.bs.modal', () => {
            this._eventPack.removeAllListeners();
            this._searchByTravelTimeForm.cancelRequest();
            this.emit('close');
            this.hide();
        });

    }

    _closeModal() {
        this.$element.modal('hide');
    }

    _toggleSuggestionSpinner(visible) {
        this.$element.find('.searchAddress .twitter-typeahead').toggleClass('pending', visible);
    }

    _showFormSpinner() {
        SpinnerHelper.startButtonSpinner(this._$validateButton);
    }

    _hideFormSpinner() {
        SpinnerHelper.stopButtonSpinner(this._$validateButton);
    }

    static showError(err) {
        err = err || new Error('Travel time request returned empty results');
        console.error(err);
        Views.volatileFeedback.showError('transportDurations.transportError.' + (err.type || 'default'));
    }

    static open({
        submitCallback,
        closeCallback = _.noop,
        parentView,
        $container,
        saveZoneOnSubmit = false,
        defaultItem,
        context = '',
        requireAllFields,
    }) {
        const eventsWhileTravelTimeShown = new EventPack();
        const searchByTravelTimeView = new SearchByTravelTimeView({$container});
        searchByTravelTimeView.show({submitCallback, saveZoneOnSubmit, defaultItem, context, requireAllFields});
        eventsWhileTravelTimeShown.on(searchByTravelTimeView, 'close', () => {
            eventsWhileTravelTimeShown.removeAllListeners();
            closeCallback();
        });
        if (parentView) {
            eventsWhileTravelTimeShown.on(parentView, 'hide', _.bind(searchByTravelTimeView.hide, searchByTravelTimeView));
        }
    }
};
