const _ = require('lodash');

const template = require('./SubmitButton.jade');
const {
    props: {
        size: {
            values: SIZES,
            LARGE: LARGE_SIZE,
        },
    },
} = require('./Button');

// @vue/component
module.exports = {
    props: {
        submitting: Boolean,
        block: Boolean,
        size: {
            type: String,
            default: LARGE_SIZE,
            validator: size => _.includes(SIZES, size),
        },
    },
    template: template(),
};
