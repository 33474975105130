const template = require('./DataTableHeader.jade');

// @vue/component
module.exports = {
    bemName: 'data-table',
    inject: ['getAdditionalCellClass'],
    props: {
        columns: {
            type: Array,
            required: true,
        },
        defaultSortKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            sortKey: this.defaultSortKey,
            isReverseSorting: false,
        };
    },
    methods: {
        getHeaderModifiers(item) {
            const {sortable, label} = item;
            const {sortKey, isReverseSorting} = this;
            return {
                sortable,
                sortAsc: sortable && sortKey === label && isReverseSorting,
                sortDesc: sortable && sortKey === label && !isReverseSorting,
            };
        },
        sortData(column) {
            const {label, sortable} = column;
            if (sortable) {
                if (label === this.sortKey) {
                    this.isReverseSorting = !this.isReverseSorting;
                } else {
                    this.sortKey = label;
                    this.isReverseSorting = false;
                }
                this.$emit('sort-data', this.sortKey, this.isReverseSorting);
            }
        },
    },
    template: template(),
};
