const Ajax = require('../../utils/Ajax');
const ServerConfig = require('../../ServerConfig');

module.exports = {
    getReviews,
    getJobAds,
};

function getReviews(agencyId, callback) {
    Ajax.request({
        method: 'GET',
        url: `${ServerConfig.config.customerRatingsUrl}/reviews/${agencyId}`,
        disableErrorPage: true,
        callback,
    });
}

function getJobAds(agencyId, callback) {
    Ajax.request({
        method: 'GET',
        url: `${ServerConfig.config.jobAdsUrl}/account/${agencyId}/job-ads`,
        disableErrorPage: true,
        callback,
    });
}
