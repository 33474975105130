const PropertyTypes = require('./PropertyTypes');

const specificAdTypeToPropertyTypeMap = {
    prestige: PropertyTypes.housing,
    businessTakeOver: PropertyTypes.business,
    leaseback: PropertyTypes.business,
    holidays: PropertyTypes.housing,
    lifeAnnuitySale: PropertyTypes.housing,
};

module.exports = getPropertyTypeForAdType;

function getPropertyTypeForAdType(adType) {
    return specificAdTypeToPropertyTypeMap[adType] || PropertyTypes.all;
}
