const _ = require('lodash');
const SimpleContentPage = require('./SimpleContentPage');

module.exports = class SitemapPage extends SimpleContentPage {
    constructor() {
        super({
            urlPattern: '/plan-de-site/:siloName:',
            contentSubPath: 'sitemap',
        });
    }

    parseUrl(url, siloName) {
        return {
            siloName,
        };
    }

    open(options) {
        super.open(options);
        // Overwrite the generic title by the "h1" title present in the rendered HTML.
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(options.htmlContent, 'text/html');
        const h1Title = doc.querySelector('h1').textContent;
        this.updatePageTitle(h1Title);
    }

    _loadData(options, cb) {
        this.contentPath = options.siloName;
        super._loadData(_.extend({
            disableErrorPage: true, // Needed to show Page404 instead of superman on 404 error
        }, options), cb);
    }
};
