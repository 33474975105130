const {i18n: {translate: t}} = require('fack');

const CityLocationItem = require('./CityLocationItem');

module.exports = class PostalCodeItem extends CityLocationItem {
    getSuggestionText() {
        return `${this._item.name}  ${t('locationField.suggestionPostalCodeSuffix')}`;
    }

    getText() {
        return this.getSuggestionText();
    }

    getUrlValue() {
        return this._item.name;
    }
};
