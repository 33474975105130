const _ = require('lodash');
const template = require('./AudienceTargetingProducts.jade');
const arrayToObject = require('../../../utils/arrayToObject');

const AUDIENCE_TARGETING_PRODUCTS = ['emailTargeting', 'displayTargeting', 'advertisementTargeting', 'externalBannerTargeting'];

// @vue/component
module.exports = {
    props: {
        value: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return arrayToObject(AUDIENCE_TARGETING_PRODUCTS, name => _.get(this.value, name));
    },
    computed: {
        valueToEmit() {
            const productsToEmit = arrayToObject(AUDIENCE_TARGETING_PRODUCTS, name => _.get(this, name));
            if (_.every(productsToEmit, _.isNil)) {
                return undefined;
            } else {
                return productsToEmit;
            }
        },
    },
    watch: {
        value() {
            const productsFromValue = arrayToObject(AUDIENCE_TARGETING_PRODUCTS, name => _.get(this.value, name));
            _.extend(this, productsFromValue);
        },
        valueToEmit(value) {
            this.$emit('input', value);
        },
    },
    template: template(),
};
