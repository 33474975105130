module.exports = [
    {
        contentPath: 'faq',
        title: "Besoin d'aide",
        scrollWindowToTopOnOpen: 'anchor',
        bodyClass: 'besoin-d-aide',
        gtmCategory: 'Content',
    },
    {
        contentPath: 'privacy-policy',
        title: 'Politique de confidentialité',
        bodyClass: 'defaultPageWithNumberedTitles privacyPolicy',
        gtmCategory: 'Content',
        enableCookiesBannerSurfaceExpanded: false,
    },
    {
        contentPath: 'cookies-policy',
        title: 'Gestion des cookies',
        bodyClass: 'defaultPageWithNumberedTitles cookiesPolicy',
        gtmCategory: 'Content',
    },
];
