const _ = require('lodash');
const template = require('./AgenciesToContact.jade');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const {TrackingClasses} = require('../../stats/TrackingClasses');

// @vue/component
module.exports = {
    constants: {
        AGENCY_INFO_PADDING_TOP_TO_PREVENT_OVERLAPPING_WITH_LABEL_CONTENT_CHECKBOX: '15px',
        AGENCY_OVERVIEW_PADDING: '10px',
    },
    props: {
        agencies: {
            type: Array,
            required: true,
        },
        showAgenciesPhoneNumbers: {
            type: Boolean,
        },
        hasToSendTrackingEvent: {
            type: Boolean,
        },
        checkable: {
            type: Boolean,
        },
        translationKeyPrefix: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            checkedAgenciesObject: this.getCheckedAgenciesObjectFromAgencies(),
            tracking: TrackingClasses,
        };
    },
    computed: {
        checkedAgenciesIds() {
            return _.keys(_.pickBy(this.checkedAgenciesObject));
        },
        agencyClass() {
            return {
                [this.tracking.agenciesToContact]: !this.checkable,
            };
        },
    },
    watch: {
        checkedAgenciesIds(checkedAgenciesIds, checkedAgenciesIdsBefore) {
            if (!_.isEqual(checkedAgenciesIds, checkedAgenciesIdsBefore)) {
                this.emitCheckedAgencies();
            }
        },
        checkable() {
            this.checkAllAgencies();
        },
        agencies() {
            this.checkAllAgencies();
        },
    },
    mounted() {
        if (this.hasToSendTrackingEvent && !_.isEmpty(this.agencies) && this.translationKeyPrefix) {
            const eventPrefix = this.translationKeyPrefix.replace('.', '-');
            GoogleTagManager.sendEvent(eventPrefix + 'AgenciesToContact');
        }
        this.emitCheckedAgencies();
    },
    methods: {
        checkAllAgencies() {
            this.checkedAgenciesObject = this.getCheckedAgenciesObjectFromAgencies();
        },
        getCheckedAgenciesObjectFromAgencies() {
            return _(this.agencies)
                .map(({id}) => [id, true])
                .fromPairs()
                .value();
        },
        emitCheckedAgencies() {
            this.$emit('update:checked-agencies-ids', this.checkedAgenciesIds);
        },
        getPrefixedAgencyId(agencyId) { // to avoid conflicts with potential elements with the same id on the page
            return `agency-to-contact-${agencyId}`;
        },
    },
    template: template(),
};
