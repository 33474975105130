const _ = require('lodash');
const {mapGetters} = require('vuex');
const template = require('./ContactApplicationSwitch.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');
const {
    getDossiers,
    updateDossier,
} = require('../Dossier');
const ApplicantEditor = require('./applicant/ApplicantEditor');
const PresentationModal = require('./PresentationModal');
const VerificationModal = require('./VerificationModal');
const VerificationEditModal = require('./VerificationEditModal');
const {getDossierLabel} = require('./helper');
const rentalApplicationTrackingMixin = require('../../form/components/mixins/rentalApplicationTrackingMixin');

const DOSSIER_STATUS = {
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    PASS: 'PASS',
};

const SUBTYPES = {
    RECTO: 'RECTO',
    VERSO: 'VERSO',
};

// @vue/component
module.exports = {
    components: {
        ApplicantEditor,
        PresentationModal,
        VerificationModal,
        VerificationEditModal,
    },
    mixins: [
        require('../../form/components/mixins/withInternalValue'),
        i18nMixin({
            prefix: 'ContactApplicationSwitch.',
            keys: [
                'title',
                'newBadge',
                'text',
                'errorMessage',
                'retryButton',
                'dossierLoading',
                'modalValidationButtonText',
                'modalIncompleteButtonText',
                'modalLoadingButtonText',
                'wantsToSubmitRentalApplicationCheckboxText',
                'errorToSubmitRentalApplicationText',
                'presentationButton',
                'createDossier',
                'noDossiers',
                'addParticipant',
                'completeDossierActionMessage',
                'incompleteDossierActionMessage',
            ],
        }),
        require('../../form/components/mixins/withInternalValue'),
        rentalApplicationTrackingMixin,
    ],
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    submitRentalApplication: false,
                    dossiers: [],
                };
            },
        },
        hadAnErrorCreatingTheRentalApplication: Boolean,
        accountType: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            isGettingDossiers: false,
            dossiers: [],
            hasError: false,
            showApplicantEditorModal: false,
            showPresentationModal: false,
            showVerificationModal: false,
            verificationModalFirstTime: false,
            showVerificationEditModal: false,
            DocumentsErrorsByCat: [],
            dossierToSubmit: [],
        };
    },
    computed: {
        ...mapGetters('rentalApplication', [
            'currentDossier',
            'documents',
            'documentsCategories',
            'errors',
            'isCallingDossiers',
            'isComplete',
            'isProcessing',
            'loadedDossiers',
            'uploadsFinished',
            'activeInstance',
        ]),
        dossierStatusMessage() {
            return this.getTranslationBasedOnDossierStatus(
                'completeDossierMessage',
                'incompleteDossierMessage',
                'noDossiers'
            );
        },
        dossierStatusActionMessage() {
            return this.getTranslationBasedOnDossierStatus(
                'completeDossierActionMessage',
                'incompleteDossierActionMessage',
                'createDossier'
            );
        },
        hasCompleteDossier() {
            return _.get(this.dossier, 'complete', false);
        },
        isRegistered() {
            return Account.isRegistered();
        },
        text() {
            return this.t('text', {accountType: this.accountType});
        },
        submitText() {
            if (this.isProcessing) {
                return this.modalLoadingButtonText;
            } else if (this.isComplete) {
                return this.modalValidationButtonText;
            } else {
                return this.modalIncompleteButtonText;
            }
        },
        isFirstTimeRentalApplication() {
            return this.dossiers.length === 0;
        },
        visibleDossiers() {
            return this.dossiers.filter((dossier) => {
                return [
                    DOSSIER_STATUS.IN_PROGRESS,
                    DOSSIER_STATUS.COMPLETED,
                    DOSSIER_STATUS.PASS,
                ].indexOf(dossier.status) !== -1;
            });
        },
        currentInstanceIsActive() {
            return this._uid === this.activeInstance;
        },
    },
    watch: {
        isProcessing(processing) {
            if (!processing && this.currentInstanceIsActive) {
                this.addRectoVersoErrors();
                this.DocumentsErrorsByCat = this.getDocumentsErrorsByCat();
                if (this.DocumentsErrorsByCat.length === 0) {
                    // The dossier is complete
                    this.showApplicantEditorModal = false;
                }
                this.handleFolderForValidation();
            }
        },
        loadedDossiers(dossiers) {
            this.dossiers = dossiers;
        },
    },
    mounted() {
        if (this.isRegistered && !this.isCallingDossiers) {
            this.$store.commit('rentalApplication/setIsCallingDossiers', true);
            this.fetchDossiers();
        }
    },
    methods: {
        makeInstanceActive() {
            this.$store.commit('rentalApplication/setActiveInstance', this._uid);
        },
        resetToDefault() {
            this.showApplicantEditorModal = false;
            this.showPresentationModal = false;
            this.showVerificationModal = false;

            this.$store.commit('rentalApplication/setCurrentDossier', null);
            this.$store.commit('rentalApplication/reset');
            this.$store.commit('rentalApplication/resetDocuments');
            this.$store.commit('rentalApplication/resetFilesCount');
        },
        fetchDossiers() {
            this.resetToDefault();

            this.hasError = false;
            this.isGettingDossiers = true;
            getDossiers((err, dossiers) => {
                if (err) {
                    this.hasError = true;
                } else {
                    this.$store.commit(
                        'rentalApplication/setLoadedDossiers',
                        _.map(dossiers, (dossier) => {
                            return {
                                checked: false,
                                ...dossier,
                            };
                        })
                    );
                }
                this.$store.commit('rentalApplication/setIsCallingDossiers', false);
                this.isGettingDossiers = false;
            });
        },
        getTranslationBasedOnDossierStatus(completeDossierKey, incompleteDossierKey, createDossier) {
            const {dossier} = this;
            let message;
            if (this.isFirstTimeRentalApplication) {
                message = this.t(createDossier);
            } else if (dossier) {
                message = this.t(dossier.complete ? completeDossierKey : incompleteDossierKey);
            }
            return message;
        },
        openPresentationModal() {
            this.$store.dispatch('rentalApplication/updatePopupSource', 'createFolder');
            this.showPresentationModal = true;
        },
        openVerificationModal(source) {
            const popupSource = source ? 'addParticipant' : 'createFolder';
            this.$store.dispatch('rentalApplication/updatePopupSource', popupSource);
            this.showVerificationModal = true;
        },
        openNextStep() {
            this.showApplicantEditorModal = true;
        },
        openParticipantModal() {
            this.showDossierEditorModal = true;
        },
        submit() {
            updateDossier(this.currentDossier.id, this.currentDossier.type, this.currentDossier.title, (err) => {
                if (err) {
                    this.hasError = true;
                } else {
                    this.$store.commit('rentalApplication/reset');
                    this.$store.commit('rentalApplication/startProcessing');
                    this.handleFolderModificationTracking();
                }
                this.isGettingDossiers = false;
            });
        },
        getDocumentsErrorsByCat() {
            const documentsCategoriesErrors
                = _(this.errors)
                    .groupBy('category')
                    .map((errors, category) => ({
                        category,
                        errors: _.uniq(_.map(errors, 'error')),
                    }))
                    .value();
            _.forEach(this.documentsCategories, (category) => {
                if (!_.some(this.documents, {documentType: category})) {
                    documentsCategoriesErrors.push({
                        category,
                        errors: ['unAvailable'],
                    });
                }
            });
            this.$store.commit('rentalApplication/addDocumentsCategoriesErrors', documentsCategoriesErrors);
            return documentsCategoriesErrors;
        },
        addRectoVersoErrors() {
            _.forEach(this.documents, (file) => {
                const hasRecto = _.includes(file.metadata.subType, SUBTYPES.RECTO);
                const hasVerso = _.includes(file.metadata.subType, SUBTYPES.VERSO);

                if (hasRecto !== hasVerso) {
                    const checkRegex = new RegExp(`${SUBTYPES.RECTO}|${SUBTYPES.VERSO}$`);
                    let otherSideOfDocSubType = _.replace(file.metadata.subType, checkRegex, SUBTYPES.VERSO);
                    let error = 'missingVerso';

                    if (!hasRecto && hasVerso) {
                        otherSideOfDocSubType = _.replace(file.metadata.subType, checkRegex, SUBTYPES.RECTO);
                        error = 'missingRecto';
                    }

                    const hasOtherSide = this.documents.some(
                        document => document.metadata.subType === otherSideOfDocSubType
                    );

                    if (!hasOtherSide) {
                        this.$store.commit('rentalApplication/addError', {
                            category: this.getDocumentErrorCategory(file.fileName),
                            error,
                            id: file.id,
                            oldFilename: file.oldFilename,
                        });
                    } else {
                        this.$store.commit('rentalApplication/removeError', file);
                    }
                }
            });
        },
        getDocumentErrorCategory(fileName) {
            return _.find(
                this.$store.getters['rentalApplication/documentsCategories'],
                (docCategory) => _.includes(fileName, docCategory)
            );
        },
        getDossierLabel(dossier) {
            const dossierLabel = getDossierLabel(dossier);
            const translationKey = dossier.complete ? 'completeDossierMessage' : 'incompleteDossierMessage';
            return this.t(translationKey, {
                dossierLabel,
            });
        },
        getDossierActionLabel(dossier) {
            return dossier.complete ? this.completeDossierActionMessage : this.incompleteDossierActionMessage;
        },
        getDossierCheckBoxName(dossierId) {
            return `wantsToSubmitRentalApplication-${dossierId}`;
        },
        setWantsToSubmitRentalApplication(dossier) {
            const {checked, id: dossierId} = dossier;
            const index = _.findIndex(this.dossierToSubmit, {dossierId});
            if (index >= 0) {
                this.dossierToSubmit[index].checked = checked;
            } else {
                this.dossierToSubmit.push({dossierId, checked});
            }
            this.$emit('input', {
                dossiers: _.map(_.filter(this.dossierToSubmit, {checked: true}), 'dossierId'),
                submitRentalApplication: _.some(this.dossierToSubmit, {'checked': true}),
            });
        },
        openVerificationEditModal(dossier) {
            this.$store.commit('rentalApplication/setCurrentDossier', dossier);
            this.$store.dispatch(
                'rentalApplication/updatePopupSource',
                this.currentDossier.complete ? 'editModal' : 'completeModal'
            );

            if (dossier.status === DOSSIER_STATUS.COMPLETED || dossier.status === DOSSIER_STATUS.PASS) {
                this.showVerificationEditModal = true;
            } else {
                this.openNextStep();
            }
        },
    },
    template: template(),
};
