const _ = require('lodash');
const $ = require('jquery');
require('moment-duration-format');

const moment = require('../../common/momentFr');
const CompositeVueView = require('../vue/CompositeVueView');
const Highcharts = require('../utils/Highcharts');
const {destroyVue, injectVueViews} = require('../vue/VueInjector');
const importsComponents = require('../admin/imports/components');

const importsListTemplate = require('../templates/admin/importsList.jade');
const importsListLineTemplate = require('../templates/admin/importsListLine.jade');
const importDetailsModalTemplate = require('../templates/admin/importDetailsModal.jade');

const EventPack = require('../utils/EventPack');

const DATE_PICKER_OPTIONS = {
    language: 'fr',
    autoclose: true,
    clearBtn: true,
    todayHighlight: true,
    format: 'dd/mm/yyyy',
};
const dateFormatForMoment = 'D/MM/YYYY';

const DEFAULT_CHART_OPTIONS = {
    legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal',
    },
    credits: {
        enabled: false,
    },
    chart: {
        type: 'area',
        zoomType: 'x',
    },
    xAxis: {
        type: 'datetime',
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        minRange: 1,
        title: {
            text: '',
        },
    },
    tooltip: {
        xDateFormat: '%A %e %b %Y, à %H:%M',
        shared: true,
        crosshairs: true,
    },
    plotOptions: {
        area: {
            enableMouseTracking: true,
            stacking: 'normal',
            lineWidth: 0,
        },
        series: {
            marker: {
                enabled: false,
            },
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
    },
};

module.exports = class ImportsListView extends CompositeVueView {
    constructor() {
        super({
            template: importsListTemplate,
        });
        this.eventPack = new EventPack();
    }

    show(options) {
        this.options = options;
        const {
            imports,
            author,
            size,
            to,
            filename,
        } = options;
        this._displayedImports = imports;
        this._hasImportData = !author || author.import;
        this._showNamespaceColumn = !author;
        this._hasImport = !author || Boolean(author.import);
        const importsListView = this;
        // @vue/component
        const vueOptions = {
            components: importsComponents,
            data() {
                return {
                    selectedFilters: {
                        displayLimit: +size,
                        filename,
                    },
                };
            },
            watch: {
                'selectedFilters.displayLimit': function (size) {
                    importsListView.emitUpdate({size});
                },
                'selectedFilters.filename': function (filename) {
                    importsListView.emitUpdate({filename});
                },
            },
        };
        super.show(_.extend(options, {
            hasImport: this._hasImport,
        }), vueOptions);
        this._fillImportsTable();
        this._bindEvents();
        if (this._hasImportData) {
            this._liveDurationUpdater = setInterval(_.bind(this._updateLiveDuration, this), 1000);
            this._initDatePickers(options);
            this._endDate = to ? new Date(to) : null;
            if (author) {
                Highcharts.onReady(() => {
                    if (this.$element) {
                        this._createEmptyCharts();
                        this._setChartsData(this._displayedImports);
                    }
                });
            }
            this._planDatesUpdate();
        }
    }

    _fillImportsTable() {
        const imports = this._displayedImports;
        const $tbody = this.$element.find('.containerImports tbody');
        for (let importId = 0; importId < Math.min(this.options.size, _.size(imports)); ++importId) {
            $tbody.append(this._renderImportLine(imports[importId]));
        }
    }

    _bindEvents() {
        this.eventPack.on(this.$element, {
            change: {
                'input[name="state-filter"]': event => {
                    this._setCurrentStateFilter($(event.target).val());
                },
            },
        });
    }

    displayImportInfo(importInfo) {
        const $modal = $(super.renderTemplate(importDetailsModalTemplate, {
            importInfo,
        }));
        this.$container.append($modal);
        $modal.modal('show');
        $modal.on('hidden.bs.modal', function () {
            $modal.remove();
        });
    }

    getImportInfoById(id) {
        return _.find(this._displayedImports, importInfo => importInfo._id == id);
    }

    emitUpdate(updateOptions) {
        this.emit('updateDisplayLimit', updateOptions);
    }

    _setCurrentStateFilter(value) {
        const $table = this.$element.find('.imports');
        if (this._currentStateFilter) {
            $table.removeClass(this._currentStateFilter);
        }
        this._currentStateFilter = value;
        if (value) {
            $table.addClass(value);
        }
    }

    renderTemplate(template, options) {
        return super.renderTemplate(template, _.extend({
            formatDate,
            formatDuration,
            statsComputing,
            hasImportData: this._hasImportData,
            showNamespaceColumn: this._showNamespaceColumn,
        }, options));
    }

    update(options) {
        this.hide();
        this.show(options);
    }

    hide() {
        this.eventPack.removeAllListeners();
        delete this._displayedImports;
        clearInterval(this._liveDurationUpdater);
        clearTimeout(this._endDateUpdater);
        clearTimeout(this._datesUpdater);
        super.hide();
    }

    _updateLiveDuration() {
        _.each(this.$element.find('.live-duration'), function (el) {
            const $el = $(el);
            const startDate = new Date($el.attr('data-start'));
            $el.text(formatDuration(Date.now() - startDate, 'ms'));
        });
    }

    matchesFileNameFilter({zipFileName}) {
        const {filename} = this.options;
        return !filename || new RegExp(filename).test(zipFileName);
    }

    updateImport(importInfo) {
        if (
            (!this._endDate || new Date(importInfo.importStart) < this._endDate)
            && (this.matchesFileNameFilter(importInfo))
        ) {
            const currentIndex = _.findIndex(this._displayedImports, imp => imp._id == importInfo._id);
            if (currentIndex >= 0) {
                this._displayedImports.splice(currentIndex, 1, importInfo);
            } else {
                this._displayedImports.splice(0, 0, importInfo);
            }
            const html = this._renderImportLine(importInfo);
            const {$element} = this;
            const $line = $element.find('[data-id=' + importInfo._id + ']');
            if ($line.length > 0) {
                destroyVue($line);
                $line.replaceWith(html);
            } else {
                $element.find('tbody').prepend(html);
                const lastImportsListLine = $element.find('tbody > tr').last();
                destroyVue(lastImportsListLine);
                lastImportsListLine.remove();
            }
            if (!importInfo.running) {
                this._setChartsData(this._displayedImports);
            }
        }
    }

    _renderImportLine(importInfo) {
        const $importLine = this.renderTemplate(importsListLineTemplate, {
            importInfo,
            rowClass: getImportRowClass(importInfo),
        });
        const importsListView = this;
        // @vue/component
        const vueOptions = {
            components: importsComponents,
            computed: {
                importInfo() {
                    return importInfo;
                },
                statusTitle() {
                    const {error, warnings} = importInfo;
                    return error || warnings;
                },
            },
            methods: {
                toggleImportBlockage({accountId}) {
                    importsListView.emit('toggleImportBlockage', accountId);
                },
                invalidateImport({_id: importId}) {
                    importsListView.emit('invalidateImport', importId);
                },
                interruptImport({_id: importId, namespace}) {
                    importsListView.emit('interruptImport', namespace, importId);
                },
                showFullInfo({_id: importId}) {
                    const importInfo = importsListView.getImportInfoById(importId);
                    importsListView.displayImportInfo(importInfo);
                },
            },
        };
        injectVueViews($importLine, vueOptions);
        return $importLine;
    }

    _initDatePickers(options) {
        const startDatepicker = this.$element.find('.start-date')
            .val(dateToInputString(options.from))
            .datepicker(DATE_PICKER_OPTIONS);
        const endDatepicker = this.$element.find('.end-date')
            .val(dateToInputString(options.to))
            .datepicker(DATE_PICKER_OPTIONS);
        startDatepicker.on('changeDate', e => {
            this.emit('from_changed', e.date ? moment(e.date).startOf('day').toISOString() : null);
        });
        endDatepicker.on('changeDate', e => {
            this.emit('to_changed', e.date ? moment(e.date).endOf('day').toISOString() : null);
        });
        this._planEndDateUpdate([startDatepicker, endDatepicker]);
    }

    _planEndDateUpdate(datepickers) {
        this._updateEndDate(datepickers);
        this._endDateUpdater = this._setTimeoutTomorrow(_.bind(this._planEndDateUpdate, this, datepickers));
    }

    _planDatesUpdate() {
        this._datesUpdater = this._setTimeoutTomorrow(() => {
            this._updateDisplayedDates();
            this._datesUpdater = this._planDatesUpdate();
        });
    }

    _updateDisplayedDates() {
        _.each(this.$element.find('.live-date'), function (el) {
            const $el = $(el);
            $el.text(moment($el.attr('data-date')).calendar());
        });
    }

    _setTimeoutTomorrow(func) {
        const tomorrow = moment().add(1, 'd').startOf('day').toDate();
        return setTimeout(func, tomorrow - Date.now());
    }

    _updateEndDate(datepickers) {
        _.each(datepickers, datepicker => {
            const endDate = moment().format(dateFormatForMoment);
            datepicker.datepicker('setEndDate', endDate);
        });
    }

    _createEmptyCharts() {
        const $chartImports = this.$element.find('.chart-imports');
        $chartImports.highcharts(_.extend({
            title: {
                text: 'Imports',
                align: 'left',
            },
        }, DEFAULT_CHART_OPTIONS));
        this._importChart = $chartImports.highcharts();
    }

    _setChartsData(imports) {
        if (this._importChart) {
            const importsSeries = getImportsSeries(imports);
            updateSeries(this._importChart, importsSeries);
        }
    }
};

function updateSeries(chart, allSeries) {
    _.each(allSeries, function (series, i) {
        if (chart.series[i]) {
            chart.series[i].setData(series.data);
        } else {
            chart.addSeries(series);
        }
    });
}
function getImportsSeries(imports) {
    const series = [
        {
            name: 'Nouvelles',
            key: 'new',
            data: [],
            color: '#00abd2',
        },
        {
            name: 'Retirées du marché',
            key: 'expired',
            data: [],
            color: '#f07a7a',
        },
        {
            name: 'Mises à jour',
            key: 'updated',
            data: [],
            color: '#ffa115',
        },
    ];
    _.each(imports, function (imp) {
        if (imp.stats && imp.importStart && imp.importEnd) {
            _.each(series, function (aSeries) {
                addPoints(aSeries.data, imp.stats[aSeries.key]);
            });
        }

        function addPoints(series, value) {
            const x1 = new Date(imp.importStart).valueOf();
            const x0 = x1 - 1;
            const x2 = new Date(imp.importEnd).valueOf();
            const x3 = x2 + 1;
            series.push(
                [x0, 0],
                [x1, value],
                [x2, value],
                [x3, 0]
            );
        }
    });
    _.each(series, function (aSeries) {
        aSeries.data = _.sortBy(aSeries.data, getDate);
    });
    return series;
}

function getDate(pt) {
    return pt[0];
}

function dateToInputString(date) {
    if (date) {
        return moment(date).format(dateFormatForMoment);
    } else {
        return null;
    }
}

function statsComputing(current, previous) {
    if (!previous || !current) {
        return null;
    }
    /*
     P1 = nombre d’ID présent la veille et absent le jour de l’import.
     P2 = nombre d’ID présent le jour de l’import et absent la veille.

     P3 = nombre de lots le jour de l’import
     P4 = nombre de lots la veille

     La formule donnant un pourcentage de différence :
     ( P1 + P2 ) / ( P3 + P4 ) * 100
     */

    return (current.expired + current.new) / (current.total + previous.total) * 100;
}

function formatDuration(duration, unit) {
    return moment.duration(duration, unit).format('d[j] h[h] m[min] s[s]');
}

function formatDate(data) {
    return moment(data).format('dddd Do MMMM YYYY, HH:mm');
}

function getImportRowClass({
    running,
    imported,
    warnings,
    error,
}) {
    if (running) {
        return 'running';
    } else if (imported) {
        return warnings ? 'warning' : 'success';
    } else if (error) {
        return 'error';
    } else if (warnings) {
        return 'warning';
    } else {
        return 'interrupted';
    }
}
