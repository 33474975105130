const {i18n: {translate}} = require('fack');
const template = require('./Diagnostics.jade');
const GoogleTagManager = require('../../stats/GoogleTagManager');

// @vue/component
module.exports = {
    props: {
        dpe: {
            type: Object,
            required: true,
        },
        ges: {
            type: Object,
            required: true,
        },
        minEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        maxEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        energySimulationReferenceDate: {
            type: String,
            default: undefined,
        },
        averageAnnualEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        finalEnergyConsumption: {
            type: Number,
            default: undefined,
        },
        useLatestEnergyPerformanceDiagnostic: Boolean,
        hasEnergyPerformanceDiagnosticDate: Boolean,
        hasNoLabelForDpeAndGes: Boolean,
    },
    data() {
        return {
            isInFullMode: false,
            hasOpenedDiagnostics: false,
        };
    },
    computed: {
        energyPerformanceDiagnosticDateDescription() {
            const context = this.useLatestEnergyPerformanceDiagnostic ? '' : 'old';
            return this.energyDiagnosticsTranslation('dateDescription', {context});
        },
        showDescription() {
            const {isInFullMode, hasEnergyPerformanceDiagnosticDate, hasNoLabelForDpeAndGes} = this;
            return isInFullMode && hasEnergyPerformanceDiagnosticDate && !hasNoLabelForDpeAndGes;
        },
        newEnergyPerformanceDiagnosticLinkText() {
            const context = this.isInFullMode ? '' : 'isInFullMode';
            return this.energyDiagnosticsTranslation('linkText', {context});
        },
        energyClassification() {
            return this.dpe.classification;
        },
    },
    methods: {
        openAllDiagnosticsComponents(from) {
            GoogleTagManager.sendEvent('diagnosticsOpened', {
                from,
            });
            this.isInFullMode = true;
        },
        toggleIsInFullMode() {
            this.isInFullMode = !this.isInFullMode;
            if (this.isInFullMode && !this.hasOpenedDiagnostics) {
                GoogleTagManager.sendEvent('diagnosticsOpened', {
                    from: 'new_energy_diagnostics',
                });
                this.hasOpenedDiagnostics = true;
            }
        },
        energyDiagnosticsTranslation(path, context) {
            return translate(`EnergyDiagnostics.${path}`, context);
        },
    },
    template: template(),
};
