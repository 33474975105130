const _ = require('lodash');
const urlUtils = require('url');

const SearchPageBase = require('./SearchPageBase');
const SavedSearches = require('../search/SavedSearches');
const Error404 = require('../Error404');
const Urls = require('../Urls');
const UrlFormatter = require('../../common/UrlFormatter');
const SimpleView = require('../views/SimpleView');
const savedSearchAdsResumeTemplate = require('../templates/savedSearchAdsResumeView.jade');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');

module.exports = class SavedSearchAdsPage extends SearchPageBase {
    constructor(configuration) {
        super(_.defaults(configuration, {
            name: 'savedSearchAdsPage',
            mapFilterBtnEnabled: false,
            mapButtonsSearchEnabled: false,
            sortingEnabled: false,
            filterEnabled: false,
            saveSearchAllowed: false,
            savedSearchResumeEnabled: true,
            searchResumeView: new SimpleView(savedSearchAdsResumeTemplate),
        }));
    }

    getUrl() {
        const options = this.searchView ? this.searchView.getSearchState() : {};
        return Urls.savedSearch.makeUrl(options.savedSearch, options);
    }

    getPaginationUrl(pageNumber) {
        const options = this.searchView ? this.searchView.getSearchState() : {};
        return Urls.savedSearch.makeUrl(options.savedSearch, _.defaults({page: pageNumber}, options));
    }

    parseUrl(url, savedSearchId) {
        const {query: {page, camera}} = urlUtils.parse(url, true);
        return {
            savedSearch: {
                _id: savedSearchId,
            },
            page,
            camera: camera && UrlFormatter.cameraFromUrl(camera),
        };
    }

    _loadData(options, cb) {
        const {savedSearch, page} = options;
        this._loadSavedSearchIfNeeded(savedSearch, (err, loadedSavedSearch) => {
            if (err) {
                cb(err);
            } else {
                super._loadData(_.extend({}, options, {
                    search: _.extend({}, loadedSavedSearch.searchCriteria, {
                        sortBy: 'publicationDate',
                        sortOrder: 'desc',
                        page,
                    }),
                    savedSearch: loadedSavedSearch,
                }), cb);
            }
        });
    }

    _loadSavedSearchIfNeeded(savedSearch, cb) {
        if (isIncompleteSavedSearch(savedSearch)) {
            this._loadSavedSearchById(savedSearch._id, cb);
        } else {
            _.defer(_.partial(cb, null, savedSearch));
        }
    }

    _loadSavedSearchById(id, cb) {
        if (!id) {
            cb(new Error404('No saved search id'));
        } else {
            SavedSearches.getOneById(id, {
                updateLastViewDateForSearch: true,
                updateLastInteractionDate: true,
            }, (err, savedSearch) => {
                if (err) {
                    cb(err);
                } else if (!savedSearch) {
                    cb(new Error404('No saved search found with this id'));
                } else if (savedSearch.enabled === false) {
                    PageManager.openPage(PagesFactories.searchPageFactory, {search: savedSearch.searchCriteria});
                } else {
                    cb(null, savedSearch);
                }
            });
        }
    }
};

function isIncompleteSavedSearch(savedSearch) {
    return _.size(_.keys(savedSearch)) <= 1;
}
