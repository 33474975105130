const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const NativeInterfaceProxy = require('./NativeInterfaceProxy');

module.exports = {
    open,
};

function open({url, windowName, parameters}) {
    // Detect if ios app: perform action to open browser
    if (NativeInterfaceProxy.openNativeBrowser) {
        NativeInterfaceProxy.openNativeBrowser(url);
    } else {
        window.open(url, (windowName && !UserAgentHelper.isFromNativeAndroidApp()) || '_blank', parameters); // always open links to other websites in new windows
    }
}
