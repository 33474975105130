const _ = require('lodash');
const PageManager = require('../pages/PageManager');

const PAGE_MANAGER_EVENT_TO_FORWARD = 'pageOpen';

module.exports = {
    init,
    insidePrerenderer,
    notifyPageOpen,
};

function init() {
    if (insidePrerenderer()) {
        window.callPhantomOnPageOpen = true; // tell the prerenderer that it should wait for the pageOpen event
        PageManager.on(PAGE_MANAGER_EVENT_TO_FORWARD, () => {
            notifyPageOpen();
        });
    }
}

function insidePrerenderer() {
    return _.isFunction(window.callPhantom);
}

function notifyPageOpen() {
    if (insidePrerenderer()) {
        window.callPhantom({event: PAGE_MANAGER_EVENT_TO_FORWARD});
    }
}
