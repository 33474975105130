const BrowserDetect = require('browser-detect');
const _ = require('lodash');

const Field = require('./Field');
const AsyncHelper = require('../utils/AsyncHelper');
const SuggestionWidget = require('../SuggestionWidget');
const LocationManager = require('../LocationManager');
const SuggestionPopupView = require('../SuggestionPopupView');

module.exports = class LocationField extends Field {
    constructor(...args) {
        super(...args);
        this.asyncHelper = new AsyncHelper();
    }

    init() {
        this.enablePopup = BrowserDetect.isMobile();
        this.$searchButton = this.$container.find('#doSearch');
        this.$searchLocation = this.$element.find('#searchLocation');
        this._suggestionWidget = new SuggestionWidget(_.extend({
            $input: this.$searchLocation,
            $searchButton: this.$searchButton,
            $spinner: this.$searchButton.find('i'),
            autoSelect: true,
            enablePopup: this.enablePopup,
        }, this.options));
        this.setSuggestions(this.options.suggestions);
        this._bindEvents();
    }

    onChange(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    reset() {
        this._suggestionWidget.resetWidget();
    }

    destroy() {
        this._unbindEvents();
        this._suggestionWidget.destroy();
    }

    setAroundMeSearch() {
        const aroundMeTag = this._suggestionWidget.getEmptyGeolocationItem({isAroundMe: true});
        this.addSuggestion(aroundMeTag);
    }

    setTravelTimeSearch(travelTimeZone) {
        const travelTimeTag = this._suggestionWidget.getTravelTimeItem(travelTimeZone);
        this.addSuggestion(travelTimeTag);
    }

    getFirstTravelTimeItem() {
        return this._suggestionWidget.getFirstTravelTimeItem();
    }

    setDrawZoneSearch(drawZone) {
        const drawZoneTag = this._suggestionWidget.getDrawingItem(drawZone);
        this.addSuggestion(drawZoneTag);
    }

    addSuggestion(suggestion) {
        this._suggestionWidget.addSuggestion(suggestion);
    }

    setSuggestions(suggestions) {
        this._suggestionWidget.setSuggestions(suggestions);
    }

    setDisabled(disabled) {
        this._suggestionWidget.setDisabled(disabled);
    }

    getSuggestionsFromSearch(search = {}, cb = _.noop()) {
        const locationNames = search.locationNames;
        if (search.locations && search.locations.length) {
            cb(search.locations);
        } else if (locationNames && locationNames.length) {
            this.asyncHelper.doAsync({
                func: cb => LocationManager.getLocationItemsFromNames(locationNames, cb),
                callback: (err, suggestions = []) => {
                    if (err) {
                        console.error('error on getLocationFromNames', err);
                    }
                    //LocationManager.getLocationItemsFromNames may return undefined for some locations
                    cb(_.compact(suggestions));
                },
                name: 'getLocationItemsFromNames',
            });
        } else {
            cb(search.locations);
        }
    }

    getLocations() {
        return _.clone(this._suggestionWidget.getSelectedLocations()) || [];
    }

    getLocationIds() {
        return this._suggestionWidget.getLocationIds();
    }

    getLocationsZoneInfos() {
        return this._suggestionWidget.getTagsZoneInfos();
    }

    getSelectedLocations() {
        return this._suggestionWidget.getSelectedLocations();
    }

    getSelectedSuggestions() {
        return this._suggestionWidget.getSelectedSuggestions();
    }

    hasLocationTagsTypes(types) {
        return this._suggestionWidget.hasLocationTagsTypes(types);
    }

    onSubmit(cb) {
        this._suggestionWidget.onSubmit(cb);
    }

    openSuggestion() {
        this._suggestionWidget.forceFocus();
    }

    _openSuggestionPopupView() {
        if (!this._suggestionPopupView) {
            const options = _.extend({
                autoSelect: true,
                firstTravelTimeItem: this.getFirstTravelTimeItem(),
            }, _.omit(this.options, 'enablePopup'));
            this._suggestionPopupView = new SuggestionPopupView(options);
            this._suggestionPopupView.on('close', () => {
                delete this._suggestionPopupView;
            });

            this._suggestionPopupView.on('change', () => {
                const suggestion = this._suggestionPopupView.getFirstSuggestion();
                if (suggestion) {
                    this.addSuggestion(suggestion);
                    if (this._suggestionPopupView) {
                        this._suggestionPopupView.hide();
                    }
                }
            });
            this._suggestionPopupView.show();
        }
    }

    _bindEvents() {
        this._suggestionWidget.on('eventToRelay', _.bind(this.emit, this, 'eventToRelay'));
        this._suggestionWidget.on('typeahead:select', item => {
            if (item.from == 'travelTimeZone') {
                this.emit('travelTimeSelected');
            }
        });
        this._bindPopupViewIfNeeded();
    }

    _bindPopupViewIfNeeded() {
        if (this.enablePopup) {
            this._suggestionWidget.on('focusin', _.bind(this._openSuggestionPopupView, this));
        }
    }

    _unbindEvents() {
        this._suggestionWidget.removeAllListeners('eventToRelay');
        this._unbindPopupViewIfNeeded();
    }

    _unbindPopupViewIfNeeded() {
        if (this.enablePopup) {
            this._suggestionWidget.removeAllListeners('focusin');
        }
    }

    updateField(search) {
        const locations = this.getSelectedLocations();
        _(locations)
            .filter('update')
            .each(location => {
                location.update(this.options);
            });
        this._suggestionWidget.refresh();
        super.updateField(search);
    }

};
