const MP = require('mercatorProjection');

module.exports = {
    getCircleBbox,
    getScaleFromLatitude,
};

function getCircleBbox({point, radius}) {
    const lon = point.lon || point.lng;
    const lat = point.lat;
    const lon900913 = MP.convertLon4326To900913(lon);
    let lonRadius = Math.abs(lon - MP.convertLon900913To4326(lon900913 - radius));
    const lat900913 = MP.convertLat4326To900913(lat);
    let latRadius = Math.abs(lat - MP.convertLat900913To4326(lat900913 - radius));
    const scale = getScaleFromLatitude(lat900913);
    lonRadius *= scale;
    latRadius *= scale;
    return computeDiskBbox(point, lonRadius, latRadius);
}

function computeDiskBbox(center, lonRadius, latRadius) {
    const lon = center.lon || center.lng;
    const left = lon - lonRadius;
    const bottom = center.lat - latRadius;
    const right = lon + lonRadius;
    const top = center.lat + latRadius;
    return {west: left, south: bottom, east: right, north: top};
}

function getScaleFromLatitude(lat) {
    return Math.cosh(Math.PI * lat / MP.MAX_EXTENT);
}
