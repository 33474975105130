const _ = require('lodash');

const template = require('./Price.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {
    formatPrice,
    formatPricePerSquareMeter,
    formatFeesAndCharges,
    formatSoldDate,
} = require('../../../common/TextFormatter');
const {
    isRentAd,
    isBuyTransactionAd,
} = require('../../../common/AdTypeHelper');
const {
    BOTH_FEES_CHARGED,
    PREVIEW,
    REAL_CHARGE_METHOD,
    PRICE_SIZES,
} = require('./constants');

const isSoldRealEstateAd = require('../../utils/isSoldRealEstateAd');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'Price.',
            keys: [
                'estimatedPriceNote',
                'chargesIncluded',
                'feesChargedToBoth',
                'priceEvolutionTitle',
                'perMonth',
                'programInPreview',
                'unknownLocationPrice',
                'unknownPrice',
                'sold',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        showPriceDetails: Boolean,
        size: {
            type: String,
            default: null,
            validator: size => _.includes(PRICE_SIZES, size),
        },
    },
    computed: {
        realEstateAdPrice() {
            return this.realEstateAd.price;
        },
        formattedPrice() {
            return formatPrice(this.realEstateAdPrice);
        },
        formattedLifeAnnuityMonthlyAllowance() {
            return formatPrice(this.lifeAnnuityMonthlyAllowance);
        },
        formattedFeesAndCharges() {
            return formatFeesAndCharges(this.realEstateAdPrice, this.realEstateAd);
        },
        formattedRentExtraPrice() {
            return formatPrice(this.rentExtra);
        },
        formattedPricePerSquareMeter() {
            return formatPricePerSquareMeter(this.realEstateAd.pricePerSquareMeter);
        },
        isBuyTransactionType() {
            return isBuyTransactionAd(this.realEstateAd);
        },
        isRentTransactionType() {
            return isRentAd(this.realEstateAd);
        },
        isRealChargesMethod() {
            return this.realEstateAd.chargesMethod === REAL_CHARGE_METHOD;
        },
        isEstimationRequest() {
            return this.realEstateAd.isEstimationRequest;
        },
        isPreview() {
            const {realEstateAd} = this;
            return _.get(realEstateAd, 'programState') === PREVIEW ||
                _.get(realEstateAd, 'programme.programState') === PREVIEW;
        },
        priceHasDecreased() {
            return this.realEstateAd.priceHasDecreased;
        },
        lifeAnnuityMonthlyAllowance() {
            return this.realEstateAd.lifeAnnuityMonthlyAllowance;
        },
        lifeAnnuityMonthlyAllowanceText() {
            return this.t('lifeAnnuityMonthlyAllowanceText', {
                lifeAnnuityPrice: this.formattedLifeAnnuityMonthlyAllowance,
            });
        },
        hasFeesChargedToBoth() {
            return this.realEstateAd.feesChargedTo === BOTH_FEES_CHARGED;
        },
        rentExtra() {
            return this.realEstateAd.rentExtra;
        },
        rentExtraText() {
            return this.t('rentExtraText', {
                rentExtraPrice: this.formattedRentExtraPrice,
            });
        },
        priceText() {
            if (this.isPreview) {
                return this.programInPreview;
            } else if (this.isRentTransactionType) {
                return this.unknownLocationPrice;
            } else {
                return this.unknownPrice;
            }
        },
        rentText() {
            return this.perMonth + (this.isRealChargesMethod ? '' : ` ${this.chargesIncluded}`);
        },
        displayPricePerSquareMeter() {
            return this.formattedPricePerSquareMeter && this.isBuyTransactionType;
        },
        sizeModifiers() {
            return _.compact([this.size]);
        },
        isSoldAd() {
            return isSoldRealEstateAd(this.realEstateAd);
        },
        realEstateAdSoldDateLabel() {
            const realEstateAdSoldDate = _.get(this.realEstateAd, 'saleInfo.date');
            return formatSoldDate(realEstateAdSoldDate);
        },
        soldLabel() {
            return this.t('sold', {propertyType: this.realEstateAd.propertyType});
        },
    },
    template: template(),
};
