/*global grecaptcha*/
const $ = require('jquery');

const Errors = require('../utils/Errors');
const LoadScriptHelper = require('../utils/LoadScriptHelper');

const loaded = $.Deferred();

module.exports = {
    preload,
    render,
    reset,
    getResponse,
};

function preload() {
    if (loaded.state() !== 'resolved') {
        window.RecaptchaLoaded = function () {
            loaded.resolve();
        };
        LoadScriptHelper.loadScript('https://www.google.com/recaptcha/api.js?onload=RecaptchaLoaded&render=explicit', {
            errorCallback: function () {
                const message = 'Failed to load Recaptcha';
                Errors.showUnexpectedError(message);
                loaded.reject('Could not load recaptcha');
            },
        });
    }
}

function render(container, parameters, callback) {
    loaded.then(() => {
        const widgetId = grecaptcha.render(container, parameters);
        callback(widgetId);
    });
}

function reset(widgetId) {
    grecaptcha.reset(widgetId);
}

function getResponse(widgetId) {
    return grecaptcha.getResponse(widgetId);
}
