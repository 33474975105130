const $ = require('jquery');
const _ = require('lodash');
const ApplicationConfig = require('../app/ApplicationConfig');

module.exports = {
    setMetaTags,
    removeMeta,
};

const TWITTER_NAMESPACE = 'twitter';
const NAMESPACEABLE_META = ['title', 'description', 'image'];
const ROBOTS_META_NAME = 'robots';
const META_WITH_NAME_AS_KEY = [
    'description',
    ROBOTS_META_NAME,
    'apple-itunes-app',
    ..._.map(NAMESPACEABLE_META, key => [TWITTER_NAMESPACE, key].join(':')),
];
const NAMESPACES = ['og', TWITTER_NAMESPACE];

function setMetaTags(metaTags) {
    _.each(NAMESPACEABLE_META, key => {
        _.each(NAMESPACES, namespace => {
            const keyWithNamespace = [namespace, key].join(':');
            if (_.has(metaTags, key) && !_.has(metaTags, keyWithNamespace)) {
                metaTags[keyWithNamespace] = metaTags[key];
            }
        });
    });

    _.each(metaTags, (contents, key) => {
        setMetaTag(key, contents);
    });
}

/**
 *
 * @param {string} key - the metatag key property
 * @param {string|Array|Object.<string,string>} contents - metatag attributes
 *  if string, set 'content' attribute
 */
function setMetaTag(key, contents) {
    // facebook does not support uri starting with '//'
    if (key == 'image' && _.isString(contents)) {
        contents = contents.replace(/^\/\//, 'https://');
    }

    // for multiple tags like 'article:tag', remove all before update
    const hasContent = !_.isEmpty(contents);
    if (_.isArray(contents) || !hasContent) {
        removeMeta(key);
    }

    if (hasContent) {
        if (_.isArray(contents)) {
            _.each(contents, _.partial(insertMeta, key));
        } else {
            upsertMeta(key, contents);
        }
    }
}

function insertMeta(key, content) {
    const keyType = getKeyType(key);
    const $meta = $('<meta>').attr(keyType, key).appendTo('head');
    updateMeta($meta, content);
}

function upsertMeta(key, content) {
    const $meta = getMetaElement(key);
    if (!$meta.length) {
        insertMeta(key, content);
    } else {
        updateMeta($meta, content);
    }
}

function updateMeta($meta, content) {
    content = _.isString(content) ? {content} : content;
    if (content === undefined) {
        console.warn(`trying to set meta ${$meta.attr('name') || $meta.attr('property')} to undefined`);
    } else {
        $meta.attr(content);
    }
}

function removeMeta(key) {
    if (!ApplicationConfig.blockSearchEngineIndexing && key === ROBOTS_META_NAME) {
        const $meta = getMetaElement(key);
        $meta.remove();
    }
}

function getMetaElement(key) {
    const keyType = getKeyType(key);
    return $(`meta[${keyType}="${key}"]`);
}

function getKeyType(key) {
    return _.includes(META_WITH_NAME_AS_KEY, key) ? 'name' : 'property';
}
