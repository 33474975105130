const $ = require('jquery');
const _ = require('lodash');
const Account = require('../../authentication/Account');
const ServerConfig = require('../../ServerConfig');
const querystring = require('querystring');
const errorMessageModalTemplate = require('../../templates/errorMessageModal.jade');
const {i18n: {translate}} = require('fack');
const {getImageUrlFromAlias} = require('../../ImageHelper');

const ARTICLE_PHOTO_HEIGHT = 1000;
const ARTICLE_PHOTO_SMALL_WIDTH = 300;
const ARTICLE_PHOTO_FULL_WIDTH = 700;

module.exports = {
    createArticle,
    updateArticle,
    getArticleFromSlug,
    getArticles,
    removeArticle,
    updateArticlePublishedState,
    getSmallSizePhotoUrl,
    getFullSizePhotoUrl,
    showError,
    getArticleFromArticlesList,
    fillMissingLocationData,
    getHTMLFormattedTitle,
};

function createArticle(article, callback) {
    return sendArticle({
        method: 'POST',
        data: article,
        callback,
    });
}

function updateArticle(article, id, callback) {
    return sendArticle({
        method: 'PUT',
        id,
        data: article,
        callback,
    });
}

function sendArticle({method, id, data, callback}) {
    let url = ServerConfig.config.blogUrl + '/article';

    if (id) {
        url += '/' + id;
    }

    return Account.sendJson({
        method,
        url,
        data,
        disableErrorPage: true,
        callback,
    });
}

function getArticleFromSlug(slug, callback) {
    return getArticles({
        params: {
            slug,
        },
        callback: (err, results) => {
            if (!err && results) {
                callback(null, _.first(results.articles));
            } else {
                callback(err);
            }
        },
    });
}

function removeArticle(id, callback) {
    return Account.authAjax({
        method: 'DELETE',
        url: ServerConfig.config.blogUrl + '/article/' + id,
        disableErrorPage: true,
        callback,
    });
}

function getArticles(options) {
    let url = ServerConfig.config.blogUrl + '/articles';

    const params = _.extend(Account.isBlogger() ? {} : {
        published: true,
    }, options.params);

    if (!_.isEmpty(params)) {
        url += '?' + querystring.stringify(params);
    }
    return Account.authAjax({
        method: 'GET',
        url,
        disableErrorPage: true,
        callback: (err, result) => {
            fillMissingLocationData(_.get(result, 'articles'));
            options.callback(err, result);
        },
    });
}

function fillMissingLocationData(articles) {
    _(articles)
        .map('compactSearchForm.locations')
        .flatten()
        .filter({type: 'travelTimeZone'})
        .each(location => {
            _.extend(location, {canBeUsedIncomplete: true});
        });
}

function updateArticlePublishedState(options) {
    let url = ServerConfig.config.blogUrl + '/setArticlePublishedState';

    if (options && options.id) {
        url += '/' + options.id;
    }

    return Account.putJson({
        url,
        data: {
            published: options && options.published,
        },
        callback: options && options.callback,
    });
}

function getSmallSizePhotoUrl(article) {
    return getPhotoUrl(article, ARTICLE_PHOTO_SMALL_WIDTH);
}

function getFullSizePhotoUrl(article) {
    return getPhotoUrl(article, ARTICLE_PHOTO_FULL_WIDTH);
}

function getPhotoUrl(article, width) {
    const firstPhoto = _.get(article, 'photos[0]');
    return firstPhoto ? getImageUrlFromAlias(firstPhoto.filename, {width, height: ARTICLE_PHOTO_HEIGHT}) : null;
}

function showError(err, errorKey) {
    if (err) {
        console.error(err);
    }
    if (errorKey) {
        const $body = $('body');
        const errorMessage = translate('blog.errorPopup.' + errorKey);
        console.error(errorMessage);
        const $modal = $(errorMessageModalTemplate({
            errorTitle: translate('blog.errorPopup.title'),
            errorMessage,
        }));
        $body.append($modal);
        $modal.modal('show');
        $modal.on('hidden.bs.modal', function () {
            $modal.remove();
            $body.removeClass('modal-open'); //this class should be removed on modal hide, but it is not
        });
    }
}

function getArticleFromArticlesList(articles, slug) {
    return _.find(articles, function (article) {
        return (article.slug === slug);
    });
}

function getHTMLFormattedTitle(article) {
    return article.title.replace(/[ ]([;!?])/g, '&nbsp;$1');
}
