const _ = require('lodash');
const async = require('async');
const fack = require('fack');
const assert = require('assert');
const {loadImage} = require('./utils/ImageLoader');

module.exports = {
    init,
    getMaxSize,
    drawDefaultCanvas,
    drawSimpleSquareCanvas,
    drawHoveredCanvas,
    drawSelectedCanvas,
};

let blurImages;
let stackedImage;
let favoriteImage;
let alreadySeenImage;

const roundedWidth = 10;
const RETRY_DELAY_IN_MS = 500;
const MARKERS_IMAGES_FOLDER_PATH = 'images/map/markers/';
const ICON_FONT_FAMILY = 'Material Design Iconic Font';
const FONT_FAMILY = 'Roboto-Condensed';
const ADDING_WIDTH = 4;

function init(cb) {
    //todo atlas (all in one image) ?
    const tasks = {};
    if (!stackedImage) {
        tasks.stackedImage = _.partial(loadMarkerBg, 'images/house.png');
    }
    if (!favoriteImage) {
        tasks.favoriteImage = _.partial(loadMarkerBg, 'images/favorite.png');
    }
    if (!alreadySeenImage) {
        tasks.alreadySeenImage = _.partial(loadMarkerBg, 'images/check.png');
    }
    if (!blurImages) {
        const imagesUrl = _.mapValues({
            normal: 'bg',
            hovered: 'bg-hovered',
            selected: 'bg-selected',
            new: 'new',
            withModel: '3d',
            with360: '360',
            with360BecauseOfVirtualTourTesterRole: '360-strikethrough',
            withModelAndWith360: 'plus',
            withVideo: 'video',
        }, fileName => {
            return `${MARKERS_IMAGES_FOLDER_PATH}${fileName}.png`;
        });
        tasks.blurImages = _.partial(loadImages, imagesUrl);
    }
    async.parallel(tasks, function (err, results) {
        if (!err) {
            if (tasks.stackedImage) {
                stackedImage = results.stackedImage;
            }
            if (tasks.favoriteImage) {
                favoriteImage = results.favoriteImage;
            }
            if (tasks.alreadySeenImage) {
                alreadySeenImage = results.alreadySeenImage;
            }
            if (tasks.blurImages) {
                blurImages = results.blurImages;
            }
        } else {
            console.error('Error loading images: ', err);
        }
        cb(err);
    });
}

function loadImages(imagesUrlByName, callback) {
    const tasks = _.mapValues(imagesUrlByName, function (url) {
        return _.partial(loadMarkerBg, url);
    });
    async.parallel(tasks, (err, imagesByName) => {
        assert.strictEqual(err, null); // loadMarkerBg always returns a null error
        // loadMarkerBg may return an undefined image in case of error
        callback(null, _.pickBy(imagesByName));
    });
}

function getMaxSize() {
    return {
        width: _.maxBy(blurImages, 'width') || 0,
        height: _.maxBy(blurImages, 'width') || 0,
    };
}

function loadMarkerBg(url, callback) {
    const src = fack.resourceUrl(url);
    loadImage(src, (err, image) => {
        if (err) {
            const errorMessage = `Could not load marker background image ${src}`;
            console.error(`${errorMessage}, retrying once in ${RETRY_DELAY_IN_MS}ms`, err);
            setTimeout(() => {
                loadImage(src, (err, image) => {
                    if (err) {
                        console.error(`${errorMessage}, skipping`, src, err);
                    }
                    callback(null, image);
                });
            }, RETRY_DELAY_IN_MS);
        } else {
            callback(null, image);
        }
    });
}

function drawDefaultCanvas(info) {
    info = _.extend({
        image: blurImages.normal,
        color: '#ffffff',
        isBig: false,
    }, _.clone(info));
    return drawMarkerCanvas(info);
}

function drawHoveredCanvas(info) {
    info = _.extend({
        image: blurImages.hovered,
        color: '#ffffff',
        isBig: false,
    }, _.clone(info));
    return drawMarkerCanvas(info);
}

function drawSelectedCanvas(info) {
    info = _.extend({
        image: blurImages.selected,
        color: '#ffffff',
        isBig: true,
    }, _.clone(info));
    return drawMarkerCanvas(info);
}

function computeRightSideImageInfo(info) {
    const sideImageInfo = getDefaultImageInfo();
    if (info.isStacked) {
        sideImageInfo.image = stackedImage;
    } else {
        if (info.alreadySeen) {
            sideImageInfo.image = alreadySeenImage;
        }
        if (info.isFavorite) {
            sideImageInfo.image = favoriteImage;
        }
    }

    if (sideImageInfo.image) {
        sideImageInfo.width = sideImageInfo.image.width;
        sideImageInfo.height = sideImageInfo.image.height;
        sideImageInfo.margin = 2;
    }
    return sideImageInfo;
}

function copyImage(src, dest, options) {
    dest.drawImage(
        src,
        options.src.x,
        options.src.y,
        options.src.width,
        options.src.height,
        options.dest.x,
        options.dest.y,
        options.dest.width,
        options.dest.height
    );
}

function copyImageInfo(imageInfo) {
    if (imageInfo.image) {
        // left rounded border -> canvas
        copyImage(imageInfo.image, imageInfo.context, {
            src: {
                x: 0,
                y: 0,
                width: imageInfo.image.width,
                height: imageInfo.image.height,
            },
            dest: {
                x: imageInfo.destX,
                y: imageInfo.destY,
                width: imageInfo.image.width,
                height: imageInfo.image.height,
            },
        });
    }
}

function drawImageInfo(imageInfo) {
    if (imageInfo.image) {
        // left rounded border -> canvas
        if (!imageInfo.destWidth && !imageInfo.destHeight) {
            copyImageInfo(imageInfo);
        } else {
            const destWidth = imageInfo.destWidth || imageInfo.image.width;
            const destHeight = imageInfo.destHeight || imageInfo.image.height;
            copyImage(imageInfo.image, imageInfo.context, {
                src: {
                    x: imageInfo.roundedLeft ? 0 : roundedWidth,
                    y: 0,
                    width: roundedWidth,
                    height: imageInfo.image.height,
                },
                dest: {
                    x: imageInfo.destX,
                    y: imageInfo.destY,
                    width: roundedWidth,
                    height: destHeight,
                },
            });

            // center -> canvas center
            copyImage(imageInfo.image, imageInfo.context, {
                src: {
                    x: roundedWidth,
                    y: 0,
                    width: 1,
                    height: imageInfo.image.height,
                },
                dest: {
                    x: imageInfo.destX + roundedWidth,
                    y: imageInfo.destY,
                    width: destWidth - roundedWidth * 2,
                    height: destHeight,
                },
            });

            // right rounded -> canvas
            copyImage(imageInfo.image, imageInfo.context, {
                src: {
                    x: imageInfo.roundedRight ? imageInfo.image.width - roundedWidth : imageInfo.image.width - roundedWidth * 2,
                    y: 0,
                    width: roundedWidth,
                    height: imageInfo.image.height,
                },
                dest: {
                    x: imageInfo.destX + destWidth - roundedWidth,
                    y: imageInfo.destY,
                    width: roundedWidth,
                    height: destHeight,
                },
            });
        }
    }
}

function drawSimpleSquareCanvas(color) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 1;
    canvas.height = 1;
    context.fillStyle = color;
    context.fillRect(0, 0, 1, 1);
    return canvas;
}

function drawMarkerCanvas(info) {
    const canvas = document.createElement('canvas');
    const {image: baseImage, text: baseText, color: textColor, isBig, textIconCode} = info;
    if (!baseImage) { // image could not be loaded
        return canvas;
    }
    const context = canvas.getContext('2d');

    const rightSideImageInfo = computeRightSideImageInfo(info);
    const leftSideImageInfoList = computeLeftSideImageInfoList(info);
    const {textIconWidth, textWidth, text} = getTextValues();

    const destWidth = textIconWidth + textWidth + 2 * ADDING_WIDTH + rightSideImageInfo.width;

    const allImageInfoWidth = _.sum(_.map(leftSideImageInfoList, 'width'));

    const canvasWidth = allImageInfoWidth + destWidth;
    const canvasHeight = baseImage.height;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const destX = drawLeftSideImage();
    drawRightSideImage(destX);
    drawTextImage();
    copyImageFromData();
    return canvas;

    function drawLeftSideImage() {
        let destX = 0;
        _.each(leftSideImageInfoList, (imageInfo) => {
            imageInfo.destX = destX;
            imageInfo.destY = 0;
            imageInfo.context = context;
            copyImageInfo(imageInfo);
            destX += imageInfo.width;
        });
        return destX;
    }
    function getTextValues() {
        let textWidth = 0;
        const text = baseText && textIconCode ? ` ${baseText}` : baseText;
        if (text) {
            setContextTextAttributes(context, {fontFamily: FONT_FAMILY, isBig, textColor});
            textWidth = getPositionXInCanvas(context, text) + rightSideImageInfo.margin;
        }
        let textIconWidth = 0;
        if (textIconCode) {
            setContextTextAttributes(context, {fontFamily: ICON_FONT_FAMILY, isBig, textColor});
            textIconWidth = getPositionXInCanvas(context, textIconCode);
        }
        return {textIconWidth, textWidth, text};
    }
    function drawRightSideImage(destX) {
        if (rightSideImageInfo.image || text) {
            let roundLeft = true;
            if (_.some(leftSideImageInfoList, 'image')) {
                roundLeft = false;
            }
            const imageInfo = {
                destX,
                destY: 0,
                destWidth,
                destHeight: canvasHeight,
                context,
                image: baseImage,
                roundedLeft: roundLeft,
                roundedRight: true,
            };
            drawImageInfo(imageInfo);
        }
    }
    function drawTextImage() {
        const yTextPosition = isBig ? 24 : 16;
        if (textIconCode) {
            setContextTextAttributes(context, {fontFamily: ICON_FONT_FAMILY, isBig, textColor});
            const xTextPosition = allImageInfoWidth + ADDING_WIDTH;
            context.fillText(textIconCode, xTextPosition, yTextPosition);
        }

        if (text) {
            setContextTextAttributes(context, {fontFamily: FONT_FAMILY, isBig, textColor});
            const xTextPosition = allImageInfoWidth + ADDING_WIDTH + textIconWidth;
            context.fillText(text, xTextPosition, yTextPosition);
        }
    }
    function copyImageFromData() {
        if (rightSideImageInfo.image) {
            const xSideImgPosition = canvas.width - rightSideImageInfo.width - ADDING_WIDTH;
            const ySideImgPosition = isBig ? 7 : 2;
            copyImage(rightSideImageInfo.image, context, {
                src: {
                    x: 0,
                    y: 0,
                    width: rightSideImageInfo.width,
                    height: rightSideImageInfo.height,
                },
                dest: {
                    x: xSideImgPosition,
                    y: ySideImgPosition,
                    width: rightSideImageInfo.width,
                    height: rightSideImageInfo.height,
                },
            });
        }
    }
}

function getDefaultImageInfo() {
    return {
        width: 0,
        height: 0,
        margin: 0,
        image: null,
    };
}

function computeLeftSideImageInfoList(info) {
    const {isBig, isNew, with3dModel, with360, withVideo, with360BecauseOfVirtualTourTesterRole} = info;
    const newImageInfo = computeImageInfo({
        displayed: isNew && !isBig,
        image: blurImages.new,
    });
    const withModelImageInfo = computeImageInfo({
        displayed: with3dModel && !isBig,
        image: blurImages.withModel,
    });
    const with360ImageInfo = computeImageInfo({
        displayed: with360 && !isBig,
        image: with360BecauseOfVirtualTourTesterRole ? blurImages.with360BecauseOfVirtualTourTesterRole : blurImages.with360,
    });
    const withModelAndWith360ImageInfo = computeImageInfo({
        displayed: with3dModel && with360 && !isBig,
        image: blurImages.withModelAndWith360,
    });
    const withVideoImageInfo = computeImageInfo({
        displayed: withVideo && !isBig && !with3dModel && !with360,
        image: blurImages.withVideo,
    });
    return [
        newImageInfo,
        withVideoImageInfo,
        withModelImageInfo,
        withModelAndWith360ImageInfo,
        with360ImageInfo,
    ]; //order matters
}

function computeImageInfo({displayed, image}) {
    const imageInfo = getDefaultImageInfo();
    if (displayed) {
        imageInfo.image = image;
        imageInfo.width = image.width;
        imageInfo.height = image.height;
    }
    return imageInfo;
}

function setContextTextAttributes(context, {fontFamily, isBig, textColor}) {
    const fontSize = isBig ? '18px' : '13px';
    _.assign(context, {
        textAlign: 'left',
        textBaseline: 'bottom',
        fillStyle: textColor ? textColor : 'white',
        font: `${fontSize} ${fontFamily}`,
    });
}

function getPositionXInCanvas(context, text) {
    return Math.ceil(context.measureText(text).width / 2) * 2;
}
