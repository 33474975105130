const {'blue-grey': BLUE_GREY} = require('../colors');

module.exports = {
    agency: {
        name: 'Agences immobilières',
        color: BLUE_GREY,
        selected: true,
        url: 'data:image/svg+xml,' + require('../../public/images/poi/agency.svg'),
        minZoom: 16,
        title: {
            zoomMin: 19, zoomMax: 21,
        },
        normalSize: [20, 20],
        highlightedSize: [35, 35],
    },
};
