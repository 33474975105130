const {i18n: {translate}} = require('fack');
const ServerConfig = require('../../ServerConfig');

// @vue/component
module.exports = {
    mixins: [
        require('../../form/components/EmailField'),
        require('./mixins/withInjectedAccountData'),
    ],
    props: {
        accountId: {
            type: String,
            default: undefined,
        },
        forLogin: Boolean,
        label: {
            type: String,
            default: translate('label.email'),
        },
    },
    computed: {
        extraAttributes() {
            return this.forLogin ? {
                'data-bv-remote': true,
                'data-bv-remote-message': translate('formErrorMessages.emailUnavailable'),
                'data-bv-remote-delay': 1000,
                'data-bv-remote-name': 'id',
                'data-bv-remote-data': JSON.stringify({accountId: this.accountId}),
                'data-bv-remote-url': ServerConfig.config.accountUrl + '/credentialIdAvailable',
                'data-bv-emailaddress-message': translate('formErrorMessages.email'),
            } : {};
        },
    },
};
