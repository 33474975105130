const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const LoginForm = require('./LoginForm');
const CreateAccountForm = require('./CreateAccountForm');
const Account = require('./Account');
const focusUtil = require('../utils/focus');
const ApplicationConfig = require('../app/ApplicationConfig');
const ContactHelper = require('../utils/ContactHelper');
const renderTemplate = require('../views/utils/renderTemplate');
const VueView = require('../vue/VueView');
const CompositeVueView = require('../vue/CompositeVueView');
const Options = require('../Options');
const AUTHENTICATION_PROVIDERS = require('../ExternalAuthenticationProviders');
const i18nMixin = require('../vue/components/mixins/i18n');

const loginTemplate = require('../templates/account/login.jade');
const createAccountTemplate = require('../templates/account/createAccount.jade');

const HEADER_BACKGROUND_CLASSES = {
    homePagePro: 'proAuthentication',
    authenticationRequired: 'headerLock',
};

class AuthenticationViewController extends CompositeVueView {
    constructor(options) {
        super(options);

        this.suggestedEmail = null;
        //allows us to bind/unbind at will
        this.onAccountChanges = _.bind(function () {
            if (Account.getAuthenticatedAccount()) {
                this.emit('authenticated');
                this.hide();
            }
        }, this);
    }

    show(templateOptions) {
        const {origin} = templateOptions;
        const externalProviders = this._getAvailableExternalProviders();
        // @vue/component
        const vueOptions = {
            mixins: [
                i18nMixin({
                    prefix: `authentication.${origin}.`,
                    keys: [
                        'headerTitle',
                        'headerSubtitle',
                    ],
                }),
            ],
            computed: {
                externalProviders() {
                    return externalProviders;
                },
                backgroundClass() {
                    return HEADER_BACKGROUND_CLASSES[origin];
                },
            },
        };
        super.show(templateOptions, vueOptions);
    }

    getSections() {
        const {$element} = this;
        return {
            $toggleButton: $element.find('.toggleLoginCreation'),
            $socialContainerPitch: $element.find('.socialContainerPitch'),
            $socialContainerConnectWith: $element.find('.socialContainerConnectWith'),
            $socialContainerPitchQuestion: $element.find('.socialContainerPitchQuestion'),
            $pitchAndSocialContainerElseSpan: $element.find('.pitchAndSocialContainerElseBar span'),
        };
    }

    initEvents() {
        this.bindAccountEvent();
        this.bindToggleLoginCreation();
    }

    clearForms() {
        if (this.createAccountVueView) {
            this.createAccountVueView.hide();
            this.createAccountVueView = null;
        }
        if (this.createAccountForm) {
            this.createAccountForm.clear();
            this.createAccountForm = null;
        }
    }

    _getAvailableExternalProviders() {
        const options = Options.read();
        return _.filter(AUTHENTICATION_PROVIDERS, socialMediaName => {
            return _.defaultTo(options[socialMediaName + 'LoginEnabled'], true);
        });
    }

    fillDom(sections) {
        this.clearForms();
        const $container = sections.$body;
        if (this.isLoginPage) {
            this.$element.removeClass('createAccount');
            const requiresPublicAccount = Boolean(ApplicationConfig.requiresPublicAccount);
            const context = requiresPublicAccount ? '' : 'pro';
            $container.empty().append(renderTemplate(loginTemplate, {
                requiresEmailCredentialId: requiresPublicAccount,
                context,
                showLostUserNameLink: !requiresPublicAccount,
            }));
            if (requiresPublicAccount) {
                sections.$title.text(translate('authentication.login'));
            }
            sections.$toggleButton.text(translate('authentication.createAnAccount'));
            sections.$socialContainerPitch.html(translate('authentication.pitch', {context})).addClass('authenticationPitch');
            sections.$socialContainerConnectWith.html(translate('authentication.socialContainerConnectWith'));
            sections.$socialContainerPitchQuestion.text(translate('authentication.socialContainerPitchQuestion'));
            sections.$pitchAndSocialContainerElseSpan.text(translate('authentication.pitchAndSocialContainerElseSpan'));
        } else {
            this.$element.addClass('createAccount');
            $container.empty();
            this.createAccountVueView = new VueView({
                template: createAccountTemplate,
                $container,
            });
            this.createAccountVueView.show(_.extend({
                showPasswordSection: true,
                showEmailAsLoginSection: true,
                approvalQuestions: ContactHelper.getApprovalQuestionsWithOptions(),
            }, AuthenticationViewController.options));
            sections.$title.text(translate('authentication.createYourAccount'));
            sections.$toggleButton.text(translate('accountForm.alreadyHasAccount'));
            sections.$socialContainerPitch.html(translate('accountForm.socialContainerPitch', {
                context: Account.getReferrer(),
            }));
            sections.$socialContainerConnectWith.html(translate('accountForm.socialContainerConnectWith'));
            sections.$socialContainerPitchQuestion.text(translate('accountForm.socialContainerPitchQuestion'));
            sections.$pitchAndSocialContainerElseSpan.text(translate('accountForm.pitchAndSocialContainerElseSpan'));
        }
    }

    fillDomForLogin(suggestedEmail) {
        this.isLoginPage = true;
        const sections = this.getSections();
        this.fillDom(sections);
        if (suggestedEmail) {
            this.suggestedEmail = suggestedEmail;
        }
        this.prefillEmailIfSuggested();
        new LoginForm(sections.$body);
        this.setFocusOnFirstInput();
    }

    prefillEmailIfSuggested() {
        if (this.suggestedEmail) {
            const $emailField = this.$element.find('input[type=email]');
            $emailField.val(this.suggestedEmail);
        }
    }

    fillDomForCreateAccount(suggestedEmail) {
        if (ApplicationConfig.disableAccountCreation) {
            this.fillDomForLogin(suggestedEmail);
            return;
        }

        this.isLoginPage = false;
        const sections = this.getSections();
        this.fillDom(sections);
        if (suggestedEmail) {
            this.suggestedEmail = suggestedEmail;
        }
        this.createAccountForm = new CreateAccountForm(sections.$body);
        this.prefillEmailIfSuggested();
        this.setFocusOnFirstInput();
        // refill input with the same value after emptying it, so as to force trigger (bs validator) :
        const emailInput = this.$element.find('input[type=email]');
        if (emailInput) {
            const emailFilledValue = emailInput.val();
            emailInput.val('').trigger('input').val(emailFilledValue).trigger('input');
        }
        this.createAccountForm.on('success', () => {
            this.hide();
        });
    }

    bindToggleLoginCreation() {
        this.$element.on('click.toggleLoginCreation', '.toggleLoginCreation', () => {
            if (this.isLoginPage) {
                this.fillDomForCreateAccount();
            } else {
                this.fillDomForLogin();
            }
        });
    }

    unbindToggleLoginCreation() {
        if (this.$element) {
            this.$element.off('click.toggleLoginCreation');
        }
    }

    bindAccountEvent() {
        Account.on('change', this.onAccountChanges);
    }

    unbindAccountEvent() {
        Account.removeListener('change', this.onAccountChanges);
    }

    setFocusOnFirstInput() {
        const elementToFocus = this.$element.find('input').get(0);
        if (elementToFocus) {
            focusUtil.giveFocusOnPc(elementToFocus);
        }
    }

    hide() {
        this.clearForms();
        this.unbindToggleLoginCreation();
        this.unbindAccountEvent();
        super.hide();
    }
}

AuthenticationViewController.options = {
    showCompanySection: false,
    showProContactSection: false,
    showApprovalQuestionsSection: true,
    showSocialConnection: true,
};

module.exports = AuthenticationViewController;
