const template = require('./MainButton.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'MainButton.',
            keys: [
                'liveRoomCTA',
                'virtualTour360',
                'tag360',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasButton() {
            return this.realEstateAd.status.onTheMarket
                && (this.onlineBookingUrl || this.hasLiveRoomButton || this.realEstateAd.with360);
        },
        onlineBookingUrl() {
            return this.realEstateAd.onlineBookingUrl;
        },
        hasLiveRoomButton() {
            return this.realEstateAd.withLiveRoom;
        },
        virtualTourModifiers() {
            return {
                strikethrough: Boolean(this.realEstateAd.with360BecauseOfVirtualTourTesterRole),
            };
        },
    },
    methods: {
        emitClick(specialType, index = 0) {
            this.$emit('click', {
                adId: this.realEstateAd.id,
                contentType: 'virtualTours',
                specialType,
                index,
            });
        },
    },
    template: template(),
};
