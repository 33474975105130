const AllFileUploaderWidget = require('../../AllFileUploaderWidget');
const {stringFilesValidator} = require('./helpers/filesValidator');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/fileUploaderMixin'),
    ],
    props: {
        value: {
            type: [String, Array],
            validator: stringFilesValidator,
            default: undefined,
        },
        acceptedFormat: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        getUploader() {
            return new AllFileUploaderWidget(this.acceptedFormat);
        },
    },
};
