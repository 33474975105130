const _ = require('lodash');
const {DEVELOPER_ACCOUNT_TYPE} = require('../../../common/AccountTypes');
const {getAccountImageUrl} = require('../../authentication/Account');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./ContactInfoBlock.jade');

const ACCOUNT_IMAGE_SIZE = {
    height: 50,
    width: 80,
};

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ContactInfoBlock.',
            keys: {
                statistics: 'statisticsTitle',
                loadingAgency: 'loadingAgencyText',
                developerAccountType: 'developerAccountTypeText',
            },
        }),
    ],
    props: {
        contact: {
            type: Object,
            default: null,
        },
        onlineBookingUrl: {
            type: String,
            default: null,
        },
        onlineMeetingScheduleLink: {
            type: String,
            default: null,
        },
        onTheMarket: Boolean,
        statisticsUrl: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        onlineBookingModifier() {
            return {
                onlineBooking: this.onlineBookingUrl != null,
            };
        },
        displayOnlineMeetingScheduleText() {
            return this.onlineMeetingScheduleLink && !this.isMobile;
        },
        onlineMeetingScheduleText() {
            return this.displayOnlineMeetingScheduleText ? this.t('onlineMeetingSchedule') : undefined;
        },
        onlineMeetingScheduleIcon() {
            return this.isMobile ? 'calendar' : '';
        },
        sendMessageText() {
            return (this.onlineMeetingScheduleLink && this.isMobile) ? undefined : this.t('sendMessage');
        },
        onlineMeetingModifier() {
            return {
                onlineMeeting: this.onlineMeetingScheduleLink != null,
            };
        },
        contactToggleInfoClass() {
            return {
                contactButton: this.onTheMarket,
            };
        },
        logoUrl() {
            const {
                height,
                width,
            } = ACCOUNT_IMAGE_SIZE;
            return this.contact ? getAccountImageUrl(this.contact, width, height) : null;
        },
        contactName() {
            const {
                contact,
                loadingAgencyText,
            } = this;
            return contact ? contact.contactNameToDisplay : loadingAgencyText;
        },
        hasEmailToDisplay() {
            return _.get(this.contact, 'hasEmailToDisplay');
        },
        phoneToDisplay() {
            return _.get(this.contact, 'phoneToDisplay');
        },
        contactAddress() {
            return _.get(this.contact, 'address');
        },
        contactAccountType() {
            return _.get(this.contact, 'accountType');
        },
        isDeveloperAccountType() {
            return this.contactAccountType === DEVELOPER_ACCOUNT_TYPE;
        },
        displayContactName() {
            return !this.isMobile || !this.logoUrl;
        },
        callButtonText() {
            return this.t('call', {
                context: this.isMobile ? 'mobile' : '',
            });
        },
        displayContactButton() {
            return this.onTheMarket && this.hasEmailToDisplay;
        },
        displayPhoneButton() {
            return this.onTheMarket && this.phoneToDisplay && (this.isMobile || !this.hasEmailToDisplay);
        },
        shouldDisplayOnlineMeetingScheduleButton() {
            return this.onTheMarket && this.onlineMeetingScheduleLink != null;
        },
        phoneButtonClass() {
            const {
                isMobile,
                hasEmailToDisplay,
                shouldDisplayOnlineMeetingScheduleButton,
            } = this;
            return {
                onRightButton: isMobile && hasEmailToDisplay && !shouldDisplayOnlineMeetingScheduleButton,
                directPhoneCall: isMobile,
                contactButton: !isMobile,
            };
        },
    },
    methods: {
        sendPhoneEvent() {
            const phoneEventName = 'click-' + (this.isMobile ? 'call' : 'show-phone');
            this.$emit(phoneEventName, this.$refs.phoneButton);
        },
    },
    template: template(),
};
