const $ = require('jquery');
const _ = require('lodash');

const View = require('./View');

const template = require('../templates/views/volatileFeedbackView.jade');

const EXPECTED_ANIMATION_COUNT = 2;
const ANIMATION_END_EVENTS = 'webkitAnimationEnd oanimationend msAnimationEnd animationend';

const ANIM_DURATION_SUCCESS = 3;
const ANIM_DURATION_FAIL = 5;

class VolatileFeedbackView extends View {
    constructor() {
        super({
            template,
            $container: $('#volatileFeedbackView'),
        });
    }

    /**
     *
     * @param {string} style error, success
     * @param {string|string[]} [translationKey]
     * @param {object} [options]
     */
    _showFeedback(style, translationKey = 'volatileDefaultFeedback.' + style, options = {}) {
        this.$container.empty();
        this.show(_.extend({style, translationKey}, options));
        this.$element.css('animation', 'displayFromTop 500ms ease-in normal, displayFromTop 1s ease-in '
            + options.animDuration + 's reverse forwards');
        this._removeAfterTwoAnimations(this.$element);
        if (options.buttonClickCallback) {
            this.$element.find('.feedbackVolatileButton').on('click', () => {
                this.$element.remove();
                options.buttonClickCallback();
            });
        }
    }

    //use parameter $element instead of this.$element to avoid removing next overlapping feedback
    _removeAfterTwoAnimations($element) {
        let count = 0;
        const listener = () => {
            count++;
            if (count >= EXPECTED_ANIMATION_COUNT) {
                $element.off(ANIMATION_END_EVENTS, listener);
                $element.remove();
            }
        };
        $element.on(ANIMATION_END_EVENTS, listener);
    }

    showSuccess(translationKey, options = {}) {
        _.defaults(options, {animDuration: ANIM_DURATION_SUCCESS});
        this._showFeedback('success', translationKey, options);
    }

    showError(translationKey, options = {}) {
        _.defaults(options, {animDuration: ANIM_DURATION_FAIL});
        this._showFeedback('error', translationKey, options);
    }
}

module.exports = VolatileFeedbackView;
