const _ = require('lodash');
const SearchPageBase = require('./SearchPageBase');
const noAdsTemplate = require('../templates/search/results/favoritesViewNoResult.jade');
const favoritesGuestMessageTemplate = require('../templates/search/favoritesGuestMessage.jade');
const Account = require('../authentication/Account');
const DefaultConfiguration = require('../../common/DefaultConfiguration');

module.exports = class FavoritesPage extends SearchPageBase {
    constructor(options) {
        super(_.defaults(options || {}, {
            name: 'favorites',
            filters: {
                isFollower: true,
            },
            loadOptions: {
                requestName: 'Load my favorites',
                urlSuffix: 'favorites',
                filters: {
                    isFollower: true,
                },
                includeAccountIdInQueries: true,
                leadingCount: 0,
            },
            countTotalAds: true,
            filterTypeMultiple: true,
            noAdsTemplate,
            noAdsTemplateOptions: {
                hideNewSearchButton: options && options.hideNewSearchButton || false,
            },
            zoomToResults: true,
            searchDefaults: _.defaults({
                sortBy: 'publicationDate',
                sortOrder: 'desc',
                filterType: ['buy', 'rent'],
                propertyType: DefaultConfiguration.allPropertyTypes,
                onTheMarket: [true, false],
            }, DefaultConfiguration.search),
            enableOnTheMarketFilter: true,
            appendReturnUrlToDetailedSheetUrl: false,
            saveLastSearchInLocalStorage: false,
            saveSearchAllowed: false,
            disableReloadPageOnLogin: false,
            gtmCategory: 'Account',
            disableSaveSearchBanner: true,
        }));

        //authenticated mode
        this.pageRedirectionOnLogout = '/';
        this.requiresRegistration = options && options.requiresRegistration || false;
        this.reloadPageOnLogin = true;
    }

    open() {
        super.open(...arguments);
        this.favoritesGuestMessageClosed = false;
        const eventPack = this._eventPack;
        eventPack.on(this.listView, 'messageClosed', _.bind(this._handleListViewMessageClosed, this));
        eventPack.on(this.searchView, 'realEstateAds', _.bind(this._handleRealEstateAdsForMessage, this));
    }

    _handleListViewMessageClosed() {
        this.favoritesGuestMessageClosed = true;
    }

    _handleRealEstateAdsForMessage(realEstateAds) {
        const listView = this.listView;
        if (realEstateAds.length && !this.favoritesGuestMessageClosed && !Account.isRegistered()) {
            listView.showMessage(favoritesGuestMessageTemplate());
        } else {
            listView.hideMessage();
        }
    }

    _loadData(options, cb) {
        Account.getAccountAndCreateGuestIfNeeded((err) => {
            if (err) {
                cb(err);
            } else {
                super._loadData(options, cb);
            }
        });
    }
};
