const _ = require('lodash');
const $ = require('jquery');
const FieldSerialization = require('./FieldSerialization');
const renderTemplate = require('../views/utils/renderTemplate');
const template = require('../templates/fields/field.jade');

module.exports = class FieldInputWidget {
    constructor($container, data) {
        this.template = data.template || template;
        this.$container = $container;
        //TODO data should really only contain data,
        //translationContext and the like should be split
        this.$element = renderTemplate(this.template, {
            title: data.title,
            data,
            required: data.required,
            noLabel: data.noLabel,
        });
        this.name = data.name;
        this.data = data;
        this.title = data.title;
        this.required = data.required;
        this.disabled = data.disabled;
        this.inputId = data.inputId;
        this.serialize = FieldSerialization.getSerializeFunction(data);
        this.initInput();
    }

    initInput() {
        this.$container.append(this.$element);
        this.$input = this.$element.find(':input');
        if (this.required) {
            this.$input.attr('required', true);
        }
        if (this.disabled) {
            this.$input.attr('disabled', true);
        }
        if (this.inputId) {
            this.$input.attr('id', this.inputId);
        }
    }

    clear() {
        this.$input.off();
        this.$element.remove();
    }

    setFieldValue(value) {
        const $input = this.$input;
        if ($input.length > 1) {
            //for radio inputs
            _.each($input, function (singleInput) {
                const $singleInput = $(singleInput);
                if ($singleInput.attr('value') === ('' + value)) {
                    $singleInput.attr('checked', 'checked');
                } else if ($singleInput.is(':checked')) {
                    $singleInput.attr('checked', null);
                }
            });
        } else {
            $input.val(value);
        }
    }

    getFieldValue() {
        let $input = this.$input;
        if ($input.length > 1) {
            //todo move radio inputs to special booleanWidget
            //for radio inputs
            $input = $(_.find($input, function (input) {
                return $(input).is(':checked');
            }));
        }
        return this.serialize($input.val());
    }

    serializeInto(result) {
        const value = this.getFieldValue();
        if (value != null) {
            result[this.name] = value;
        }
    }

    getElement() {
        return this.$element;
    }

    hide() {
        this.$element.hide();
    }
};
