const SOCIAL_NETWORK_LINKS_KEYS = [
    'facebook',
    'twitter',
    'linkedin',
    'instagram',
];

const EXTERNAL_LINKS_KEYS = [
    'website',
    ...SOCIAL_NETWORK_LINKS_KEYS,
];

module.exports = {
    SOCIAL_NETWORK_LINKS_KEYS,
    EXTERNAL_LINKS_KEYS,
};
