const FINANCIAL_SERVICE_RANK = 1;
const MOVE_SERVICE_RANK = 2;
const RENTAL_SERVICE_RANK = 3;

const services = [
    {
        name: 'movingPartnerQuotation',
        url: 'https://bienici.diday.com/',
        icon: 'demenagement',
        rank: MOVE_SERVICE_RANK,
    },
    {
        name: 'rentalPartnerQuotation',
        url: 'https://www.visale.fr/visale-pour-les-locataires/avantages/?utm_source=Bien_Ici' +
            '&utm_medium=Locataires_texte_Integration_partenaire&utm_campaign=v1lu52_AL-Visale-2024-AL-Visale' +
            '&utm_content=Conversion',
        icon: 'visale',
        isAdCriteriaMatchingServicePreRequest(ad) {
            return ad.adType === 'rent';
        },
        rank: RENTAL_SERVICE_RANK,
        startDate: '2024-07-16',
        endDate: '2024-10-01',
    },
];

module.exports = {
    services,
    FINANCIAL_SERVICE_RANK,
};
