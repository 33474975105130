const {PROPERTY_TYPES} = require('../../common/constants/PropertyType');
const {AD_TYPES} = require('../../common/constants/AdType');
const {DEFAULT_USER_SEARCH} = require('../../common/PropertyTypes');

const pages = [
    {
        slug: 'achat-appartement',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
        },
    },
    {
        slug: 'achat-appartement-en-duplex',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.FLAT],
            isDuplex: true,
        },
    },
    {
        slug: 'achat-appartement-au-dernier-etage',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            isOnLastFloor: true,
        },
    },
    {
        slug: 'achat-appartement-avec-balcon',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            hasBalcony: true,
        },
    },
    {
        slug: 'achat-appartement-avec-terrasse',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.FLAT],
            hasTerrace: true,
        },
    },
    {
        slug: 'achat-appartement-neuf',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            newProperty: true,
        },
    },
    {
        slug: 'achat-appartement-t2-f2',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            minRooms: 2,
            maxRooms: 2,
        },
    },
    {
        slug: 'achat-appartement-t3-f3',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            minRooms: 3,
            maxRooms: 3,
        },
    },
    {
        slug: 'achat-appartement-t4-f4',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            minRooms: 4,
            maxRooms: 4,
        },
    },
    {
        slug: 'achat-appartement-t5-f5',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            minRooms: 5,
            maxRooms: 5,
        },
    },
    {
        slug: 'achat-bureau',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.OFFICE],
            filterType: AD_TYPES.BUY,
        },
    },
    {
        slug: 'achat-hotel-particulier',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.TOWNHOUSE],
            filterType: AD_TYPES.BUY,
        },
    },
    {
        slug: 'achat-local-commercial',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.PREMISES],
            filterType: AD_TYPES.BUY,
            minRooms: null,
        },
    },
    {
        slug: 'achat-loft',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.LOFT],
            filterType: AD_TYPES.BUY,
        },
    },
    {
        slug: 'achat-logement-etudiant',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.FLAT, PROPERTY_TYPES.HOUSE],
            isInStudentResidence: true,
        },
    },
    {
        slug: 'achat-maison',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.HOUSE],
            filterType: AD_TYPES.BUY,
        },
    },
    {
        slug: 'achat-maison-3-chambres',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.HOUSE],
            minBedrooms: 3,
            maxBedrooms: 3,
        },
    },
    {
        slug: 'achat-maison-4-chambres',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.HOUSE],
            minBedrooms: 4,
            maxBedrooms: 4,
        },
    },
    {
        slug: 'achat-maison-avec-jardin',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.HOUSE],
            hasGarden: true,
        },
    },
    {
        slug: 'achat-maison-avec-piscine',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            propertyType: [PROPERTY_TYPES.HOUSE],
            hasPool: true,
        },
    },
    {
        slug: 'achat-maison-neuve',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.HOUSE],
            filterType: AD_TYPES.BUY,
            newProperty: true,
        },
    },
    {
        slug: 'achat-parking',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.PARKING],
            filterType: AD_TYPES.BUY,
            minRooms: null,
        },
    },
    {
        slug: 'achat-studio',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.BUY,
            minRooms: 1,
            maxRooms: 1,
        },
    },
    {
        slug: 'achat-terrain',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.TERRAIN],
            filterType: AD_TYPES.BUY,
            minRooms: null,
        },
    },
    {
        slug: 'achat-viager',
        forcedFields: {
            propertyType: DEFAULT_USER_SEARCH,
            filterType: AD_TYPES.BUY,
            isLifeAnnuitySaleOnly: true,
        },
    },
    {
        slug: 'acheter-pour-habiter-dans-l-immobilier-neuf',
        subtitle: 'Profitez des avantages d’un bien immobilier neuf\u202f: prêt à taux zéro, '
            + 'TVA réduite et dernières normes énergétiques…',
        underlineLinks: [
            {
                link: '/acheter-pour-investir-en-locatif-dans-l-immobilier-neuf',
                key: 'linkToInvestNewPropertyLandingPage',
            },
        ],
        forcedFields: {
            filterType: AD_TYPES.BUY,
            newProperty: true,
        },
        name: 'newPropertyBuyToLive',
    },
    {
        slug: 'faire-construire-sa-maison',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.HOUSE],
            hasToBeBuilt: true,
            filterType: AD_TYPES.BUY,
            minRooms: null,
        },
        allowedPlaceTypes: 'department',
    },
    {
        slug: 'immobilier-neuf',
        forcedFields: {
            filterType: AD_TYPES.BUY,
            newProperty: true,
        },
    },
    {
        slug: 'location-appartement',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
        },
    },
    {
        slug: 'location-appartement-meuble',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
            isFurnished: true,
        },
    },
    {
        slug: 'location-appartement-t2-f2',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
            minRooms: 2,
            maxRooms: 2,
        },
    },
    {
        slug: 'location-appartement-t3-f3',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
            minRooms: 3,
            maxRooms: 3,
        },
    },
    {
        slug: 'location-appartement-t4-f4',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
            minRooms: 4,
            maxRooms: 4,
        },
    },
    {
        slug: 'location-appartement-t5-f5',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: 'rent',
            minRooms: 5,
            maxRooms: 5,
        },
    },
    {
        slug: 'location-bureau',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.OFFICE],
            filterType: AD_TYPES.RENT,
        },
    },
    {
        slug: 'location-local-commercial',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.PREMISES],
            filterType: AD_TYPES.RENT,
        },
    },
    {
        slug: 'location-loft',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.LOFT],
            filterType: 'rent',
        },
    },
    {
        slug: 'location-maison',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.HOUSE],
            filterType: AD_TYPES.RENT,
        },
    },
    {
        slug: 'location-parking',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.PARKING],
            filterType: AD_TYPES.RENT,
        },
    },
    {
        slug: 'location-studio',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
            minRooms: 1,
            maxRooms: 1,
        },
    },
    {
        slug: 'location-studio-meuble',
        forcedFields: {
            propertyType: [PROPERTY_TYPES.FLAT],
            filterType: AD_TYPES.RENT,
            isFurnished: true,
            minRooms: 1,
            maxRooms: 1,
        },
    },
    {
        slug: 'recherche-par-temps-de-trajet',
        subtitle: 'Trouvez votre prochain logement en fonction de vos temps de trajet quotidien !\u202f!',
        defaultSearch: {
            propertyType: DEFAULT_USER_SEARCH,
            filterType: AD_TYPES.BUY,
            locations: [{type: 'travelTimeZone', mode: 'car'}],
        },
    },
];

module.exports = pages;
