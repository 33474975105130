const {i18n: {translate}} = require('fack');

const template = require('./RealEstateNavigationButtons.jade');
const {INITIAL_STEP, ESTIMATOR_TRANSLATION_KEY_PREFIX} = require('../Constants');

// @vue/component
module.exports = {
    constants: {
        INITIAL_STEP,
    },
    props: {
        translationKeyPrefix: {
            type: String,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        suggestedAgenciesStep: {
            type: Number,
            required: true,
        },
        hasErrorInSubmission: Boolean,
        isRequestingAgencies: Boolean,
    },
    computed: {
        nextButtonContext() {
            if (this.translationKeyPrefix === ESTIMATOR_TRANSLATION_KEY_PREFIX && this.step === INITIAL_STEP) {
                return 'firstStep';
            } else {
                return '';
            }
        },
        sendFormButtonText() {
            const translationKey =
                this.translationKeyPrefix === ESTIMATOR_TRANSLATION_KEY_PREFIX ? 'launchEstimation' : 'sendSubmission';
            return this.realEstatePropertyDescriptorTranslate(translationKey);
        },
    },
    methods: {
        realEstatePropertyDescriptorTranslate(key, options) {
            return translate(this.translationKeyPrefix + key, options);
        },
    },
    template: template(),
};
