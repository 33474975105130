const _ = require('lodash');
const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'account';

const withValueFromImportOrOwners = require('./internal/withValueFromImportOrOwners');

const WRAPPED_COMPONENT_NAMES = [
    'checkbox',
    'city-field',
    'number-field',
    'phone-field',
    'select',
    'text-field',
    'textarea',
    'tribool',
    'url-field',
];
const WRAPPED_COMPONENTS = _.zipObject(
    WRAPPED_COMPONENT_NAMES,
    _.map(WRAPPED_COMPONENT_NAMES, name => withValueFromImportOrOwners('kimono-' + name))
);

module.exports = namespaceComponents(NAMESPACE, _.extend({
    'address': require('./Address'),
    'advertisement-targeting': require('./audienceTargetingProducts/advertisementTargeting'),
    'audience-targeting-products': require('./audienceTargetingProducts/AudienceTargetingProducts'),
    'contact': require('./Contact'),
    'display-targeting': require('./audienceTargetingProducts/displayTargeting'),
    'email-field': withValueFromImportOrOwners(require('./EmailField')),
    'email-targeting': require('./audienceTargetingProducts/emailTargeting'),
    'external-banner-targeting': require('./audienceTargetingProducts/externalBannerTargeting'),
    'customer-id': require('./CustomerId'),
    'password-input': require('./PasswordInput'),
    'external-authentication': require('./ExternalAuthentication'),
    'external-provider': require('./SocialMediaButton'),
}, WRAPPED_COMPONENTS));
