const $ = require('jquery');
const _ = require('lodash');

const Views = require('../../views/Views');
const ApplicationPage = require('../../pages/ApplicationPage');
const ArticleView = require('./ArticleView');
const ArticlesContainerView = require('../list/ArticlesContainerView');
const ImgAnimationView = require('../../views/ImgAnimationView');
const Pages = require('../../pages/Pages');
const PagesFactories = require('../../pages/PagesFactories');
const PageManager = require('../../pages/PageManager');
const BrowserDetect = require('browser-detect');
const ArticlesListView = require('../list/ArticlesListView');
const Error404 = require('../../Error404');
const ArticleHelper = require('./ArticleHelper');
const BlogHelper = require('../BlogHelper');
const Options = require('../../Options');

module.exports = class ArticlePage extends ApplicationPage {
    constructor(options) {
        const configuration = _.defaults(options || {}, {
            name: 'articlePage',
            title: 'Article',
            scrollWindowToTopOnOpen: 'anchor',
            isPro: options ? options.mode == 'pro' : false,
            gtmCategory: 'BlogContent',
            commercialAdsEnabled: Options.get('commercialAdsEnabled'),
        });
        super(configuration);
        this.configuration = configuration;
    }

    // eslint-disable-next-line complexity
    open(options) {
        if (!this.blog) {
            return;
        }
        const isMobile = BrowserDetect.isMobile();
        this.animationEnabled = !isMobile;
        this.bodyClass = 'blog';
        this.options = _.extend(options, this.configuration);
        const {article} = this.options;
        if (article.slug != options.articleSlug) {
            this.options.articleSlug = article.slug;
        }
        const articlesHubMenuView = Views.articlesHubMenuViews[this.blog.slug];
        this.options.category = options.category || articlesHubMenuView.getCurrentCategory() ||
            this._getFirstArticleCategoryOrSubCategory(article);

        super.open(this.options);
        if (!this.blog.isCategoriesMenuVisibleInArticle) {
            articlesHubMenuView.toggle(false);
        }
        const {category} = this.options;
        if (category) {
            articlesHubMenuView.selectCategory(category);
        }
        if (article) {
            articlesHubMenuView.selectArticle(article);
        }
        const {articleHubPage} = this.options;
        if (articleHubPage) {
            articleHubPage.articlesListView.hideArticles();
        }
        if (options.fromArticle) {
            options.isFromArticleInList = this.getArticleDataBySlug(this.options.fromArticle.slug, this.options.articlesList);
        }
        this.newSearchListener = _.bind(this.newSearch, this);
        articlesHubMenuView.removeSubMenu();
        articlesHubMenuView.on('newSearch', this.newSearchListener);
        this.currentArticleView.on('newSearch', this.newSearchListener);
        this.articlesSideView.on('openArticle', _.bind(function (event) {
            const currentElement = event.currentTarget;
            const article = this.getArticleDataBySlug(currentElement.dataset.slug, this.options.articlesList);
            const ArticlePageOptions = {
                category: Views.articlesHubMenuViews[this.blog.slug].getCurrentCategory(),
                article,
                articlesContainerView: this.articlesContainerView,
                fromArticle: this.options.article,
                blog: this.blog,
            };
            if (this.animationEnabled) {
                const imgAnimationViewOptions = {
                    $img: $(currentElement).find('.articlesHub__article__photo-container'),
                };
                if (options.imgAnimationView) {
                    ArticlePageOptions.imgAnimationViewBack = this.options.imgAnimationView;
                }
                this.imgAnimationView = new ImgAnimationView(imgAnimationViewOptions);
                this.imgAnimationView.show();
                ArticlePageOptions.imgAnimationView = this.imgAnimationView;
            }
            PageManager.openPage(new Pages.ArticlePage(), ArticlePageOptions);
        }, this));
        this.currentArticleView.on('closeArticle', _.bind(function () {
            if (this.options.articleHubPage) {
                PageManager.openPage(this.options.articleHubPage, {
                    imgAnimationView: options.imgAnimationView,
                    fromArticle: this.options.article,
                });
            } else {
                const hubPageFactory = BlogHelper.getBlogHubPageFactory(this.blog);
                PageManager.openPage(hubPageFactory.createPage(), {
                    category: articlesHubMenuView.getCurrentCategory()
                        || BlogHelper.getBlogHomeTag(this.blog),
                    imgAnimationView: options.imgAnimationView,
                    articlesContainerView: this.articlesContainerView,
                    articlesListView: options.articlesListView,
                    fromArticle: this.options.article,
                });
            }
        }, this));
        this._editArticleHandler = _.bind(this.openEdition, this);
        this.currentArticleView.on('editArticle', this._editArticleHandler);

        this._publicationStateUpdatedHandler = _.bind(this.publicationStateUpdated, this);
        this.currentArticleView.on('publicationStateUpdated', this._publicationStateUpdatedHandler);

        this._deleteArticleHandler = _.bind(this.deleteArticle, this);
        this.currentArticleView.on('deleteArticle', this._deleteArticleHandler);
        if (this.blog.isSimilarArticlesBlockVisible) {
            this.newSideSearch();
        }
        if (this.options.imgAnimationView && this.animationEnabled) {
            const target1 = this.currentArticleView.getImgTarget();
            if (target1) {
                this.options.imgAnimationView.animToTarget(target1, false);
            } else {
                this.options.imgAnimationView.hide();
            }
        }
        if (this.options.imgAnimationViewBack && this.animationEnabled && this.options.fromArticle) {
            const target2 = this.articlesSideView.getImgTarget(this.options.fromArticle.slug);
            if (target2) {
                this.options.imgAnimationViewBack.animToTarget(target2, true);
            } else {
                this.options.imgAnimationViewBack.hide();
            }
        }
        this.currentArticleView.on('search', search);
    }

    updateMetaTags(metaTags) {
        super.updateMetaTags(_.extend({
            image: ArticleHelper.getFullSizePhotoUrl(this.options.article),
        }, metaTags));
    }

    close(options) {
        if (!this.blog.isCategoriesMenuVisibleInArticle) {
            Views.articlesHubMenuViews[this.blog.slug].toggle(true);
        }
        if (options && !options.imgAnimationViewBack && this.options.imgAnimationViewBack) {
            this.options.imgAnimationViewBack.hide();
        }
        if (options && !options.imgAnimationView && this.options.imgAnimationView) {
            this.options.imgAnimationView.hide();
        }
        if (this._editArticleHandler) {
            this.currentArticleView.removeListener('editArticle', this._editArticleHandler);
            this._editArticleHandler = null;
        }
        if (this._deleteArticleHandler) {
            this.currentArticleView.removeListener('editArticle', this._deleteArticleHandler);
            this._deleteArticleHandler = null;
        }
        if (this._publicationStateUpdatedHandler) {
            this.currentArticleView.removeListener('publicationStateUpdated', this._publicationStateUpdatedHandler);
            this._publicationStateUpdatedHandler = null;
        }
        if (this.blog) {
            Views.articlesHubMenuViews[this.blog.slug].removeListener('newSearch', this.newSearchListener);
        }
        if (this.currentArticleView) {
            this.currentArticleView.removeListener('newSearch', this.newSearchListener);
            this.currentArticleView.removeAllListeners('search');
        }
        if (this.options.article) {
            Views.articlesHubMenuViews[this.blog.slug].selectArticle(null);
        }
        this.newSearchListener = null;
        super.close();
    }

    openEdition() {
        PageManager.openPage(Pages.articleModificationPage, {
            article: this.options.article,
        });
    }

    deleteArticle() {
        const mdp = 'supprimer';
        const userCode = prompt("Tapper '" + mdp + "' pour supprimer l'article définitivement");
        if (userCode == mdp) {
            ArticleHelper.removeArticle(this.options.article.id, (err /*,results*/) => {
                if (err) {
                    ArticleHelper.showError(err, 'deleteArticle');
                } else {
                    const hubPageFactory = BlogHelper.getBlogHubPageFactory(this.blog);
                    PageManager.openPage(hubPageFactory.createPage(), {
                        category: Views.articlesHubMenuViews[this.blog.slug].getCurrentCategory()
                            || BlogHelper.getBlogHomeTag(this.blog),
                        articlesContainerView: this.articlesContainerView,
                    });
                }
            });
        }
    }

    getMiddleViews() {
        if (this.options.articlesContainerView) {
            this.articlesContainerView = this.options.articlesContainerView;
        } else {
            this.articlesContainerView = new ArticlesContainerView();
        }
        if (this.options.articlesListView) {
            this.articlesListView = this.options.articlesListView;
        } else {
            this.articlesListView = null;
        }
        this.currentArticleView = new ArticleView({
            blog: this.blog,
            articlesList: this.options.articlesList,
            article: this.options.article,
            category: Views.articlesHubMenuViews[this.blog.slug].getCurrentCategory(),
            isPro: this.configuration.isPro,
        });
        this.articlesSideView = new ArticlesListView({
            articlesList: this.options.articlesList,
            currentCategory: this.options.category,
            currentArticle: this.options.article,
        });
        return [
            Views.articlesHubMenuViews[this.blog.slug],
            this.articlesContainerView,
            this.currentArticleView,
            this.articlesSideView,
            this.articlesListView,
        ];
    }

    newSideSearch() {
        this.$articlesSideContainer = this.currentArticleView.getArticlesSideContainer();
        this.articlesSideView.setArticlesContainer(this.$articlesSideContainer);
        this.articlesSideView.showArticles({
            fromArticle: this.options.isFromArticleInList,
            scrollToArticle: false,
            useHTMLHeadingsTags: false,
        });
        const $moreSimilarArticlesBtn = this.currentArticleView.$element.find('#moreSimilarArticles');
        if (this.blog) {
            let href = '/' + this.blog.slug;
            if (this.options.category && (this.blog.slug != this.options.category.slug)) {
                href += (this.options.category.slug ? '/' + this.options.category.slug : '');
            }
            $moreSimilarArticlesBtn.attr('href', href);
        }
        this.newSimilarSearchListener = _.bind(function (event) {
            event.stopPropagation();
            event.preventDefault();
            this.newSearch({
                blog: this.blog,
                category: this.options.category,
            });
        }, this);
        $moreSimilarArticlesBtn.linkClick(this.newSimilarSearchListener);
    }

    newSearch(options) {
        if (this.options && this.options.imgAnimationView) {
            this.options.imgAnimationView.showImg();
        }
        this.currentArticleView.hide();
        const hubPageFactory = BlogHelper.getBlogHubPageFactory(options.blog);
        PageManager.openPage(hubPageFactory.createPage(), {
            category: options.category || (options.blog && BlogHelper.getBlogHomeTag(options.blog)),
            scrollWindowToTopOnOpen: 'anchor',
            fromArticle: this.options.article,
            imgAnimationView: this.options.imgAnimationView,
        });
    }

    getUrlPattern() {
        return '/article/:articleSlug:';
    }

    getUrl() {
        return '/article/' + this.options.article.slug;
    }

    getName() {
        return this.configuration.name;
    }

    getTitle() {
        return _.get(this, 'options.article.pageTitle')
            || _.get(this, 'options.article.title');
    }

    getMetaDescription() {
        return _.get(this, 'options.article.pitch');
    }

    parseUrl(url, articleSlug) {
        return {
            articleSlug,
        };
    }

    getArticleDataBySlug(slug, articlesList) {
        return _.find(articlesList.articles, function (article) {
            return (article.slug == slug);
        });
    }

    loadData(options, cb) {
        getArticleFromOptions(options, (err, article) => {
            if (err || !article) {
                cb(new Error404());
            } else {
                options.article = article;
                this.blog = options.blog || BlogHelper.getBlogFromTags(
                    options.article.tags,
                    this.configuration.isPro,
                    {allowRequiresBloggerRoleBlogs: true}
                );
                const sideListTag = (options.category && options.category.isHome) ? null : options.category;
                this.getSideList(sideListTag, article, (err, sideListResults) => {
                    if (!err && sideListResults && sideListResults.count) {
                        options.articlesList = sideListResults;
                    }
                    cb(null, options);
                });
            }
        });
    }

    _getFirstArticleCategory(article) {
        return _.find(this.blog.tags, (tag) => {
            return !tag.isHome && _.includes(article.tags, tag.slug);
        });
    }

    _getFirstArticleCategoryOrSubCategory(article) {
        const firstCategory = this._getFirstArticleCategory(article);
        let result = BlogHelper.getBlogHomeTag(this.blog);
        if (firstCategory) {
            const subCategory = _.find(firstCategory.subTags, (subTag) => {
                return _.includes(article.tags, subTag.slug);
            });
            result = subCategory || firstCategory;
        }
        return result;
    }

    getSideList(tag, article, callback) {
        const sideListTag = tag || this._getFirstArticleCategory(article) || this.blog;
        const params = {
            size: 11,
            moreLikeId: article.id,
            tags: sideListTag && sideListTag.slug,
        };
        return ArticleHelper.getArticles({
            params,
            callback: function (err, data) {
                callback(err, data);
            },
        });
    }

    publicationStateUpdated(article) {
        const ArticlePageOptions = {
            article,
            blog: this.blog,
        };
        PageManager.openPage(new Pages.ArticlePage(), ArticlePageOptions);
    }

    getSelectedHeaderItem() {
        return this.blog.slug;
    }
};

function search(search) {
    if (BrowserDetect.isMobile()) {
        Views.header.search(search);
    } else {
        PageManager.openPage(PagesFactories.searchPageFactory, {search});
    }
}

function getArticleFromOptions(options, callback) {
    if (options.article) {
        _.defer(() => {
            callback(null, options.article);
        });
    } else if (options.articleSlug) {
        ArticleHelper.getArticleFromSlug(options.articleSlug, callback);
    } else {
        _.defer(() => {
            callback(new Error('missing info to get article'));
        });
    }
}
