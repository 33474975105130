const assert = require('assert');
const _ = require('lodash');
const Vue = require('vue');
const View = require('../views/View');
const {get: getStore} = require('../store');
const plugins = require('./plugins');

Vue.use(plugins);

module.exports = class VueView extends View {
    constructor(viewOptions, vueOptions) {
        super(viewOptions);
        this.vueOptions = vueOptions;
    }

    show(templateOptions, vueOptions = {}) {
        let {el} = vueOptions;
        if (el) {
            assert(templateOptions == null, 'template options should be null when an element is provided');
        } else {
            super.show(templateOptions);
            el = this.$element.get(0);
        }
        this.vm = new Vue(_.extend({
            el,
        }, this.vueOptions, vueOptions, {
            store: getStore(),
        }));
    }

    hide(options, cb = _.noop) {
        super.hide();
        this.vm.$el.remove();
        this.vm.$destroy();
        this.vm = null;
        cb();
    }
};
