const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const ResetPasswordView = require('../ResetPasswordView');
const Account = require('../authentication/Account');

module.exports = class ResetPasswordPage extends ApplicationPage {
    constructor(options) {
        options = _.defaults(options || {}, {
            name: 'resetPassword',
            title: 'Réinitialiser le mot de passe',
            isRegistrationRequired: false,
            disableReloadPageOnLogin: true, //disable infinite loop on account change
        });
        super(options);
    }

    getMiddleViews() {
        return new ResetPasswordView();
    }

    _loadData(options, cb) {
        Account.authenticate({
            resetPasswordToken: options.resetPasswordToken,
        }, function (err, result) {
            if (err || !result || !result.success) {
                //invalid resetPasswordToken
                cb(null, _.extend(options, {
                    resetPasswordToken: null,
                }));
            } else {
                cb(null, options);
            }
        });
    }

    parseUrl(url, token) {
        return {
            resetPasswordToken: decodeURIComponent(token),
        };
    }

    getUrlPattern() {
        return '/reinitialiser-mot-de-passe/{token}';
    }
};
