const _ = require('lodash');

const Account = require('../authentication/Account');

module.exports = function getAuthenticatedUserContactRequests(realEstateAd) {
    return _.filter(realEstateAd.contactRequests, {
        sender: {
            accountId: Account.getAuthenticatedAccountId(),
        },
    });
};
