const _ = require('lodash');
const $ = require('jquery');
const async = require('async');
const CompositeVueView = require('../vue/CompositeVueView');

class SelectorContainerView extends CompositeVueView {
    constructor(options) {
        super(options);
    }

    setViews(views) {
        this.views = views;
    }

    setView(selector, view) {
        this.views[selector] = view;
    }

    show(options, vueOptions = {}) {
        super.show(options, vueOptions);
        _.each(this.views, (view, selector) => {
            view.setContainer($(selector));
            view.show(options);
        });
    }

    hide(options) {
        _.each(this.views, view => {
            view.hide(options);
        });
        super.hide(options);
    }

    update(options) {
        _.each(this.views, (view, selector) => {
            const $container = this.$element.find(selector);
            view.setContainer($container);
            if (view.update) {
                view.update(_.defaults({
                    $container,
                }, options));
            }
        });
    }

    loadData(options, cb) {
        async.each(this.views, (view, cb) => {
            if (view.loadData) {
                view.loadData(options, cb);
            } else {
                async.setImmediate(cb);
            }
        }, function (err) {
            cb(err, options);
        });
    }
}

module.exports = SelectorContainerView;
