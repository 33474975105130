const _ = require('lodash');
const async = require('async');
const urlUtil = require('url');
const Account = require('../authentication/Account');
const UserRealEstateAdsPageBase = require('./UserRealEstateAdsPageBase');
const Error404 = require('../Error404');
const SearchUrlPrefixes = require('../../common/SearchUrlPrefixes');
const DefaultConfiguration = require('../../common/DefaultConfiguration');

module.exports = class UserRealEstateAdsPage extends UserRealEstateAdsPageBase {
    constructor(configuration) {
        super(_.defaults(configuration || {}, {
            name: 'userRealEstateAds',
            searchWithoutAgencyBtnEnabled: true,
            loadOptions: {
                requestName: 'Load user ads',
                urlSuffix: 'userads',
            },
            searchDefaults: DefaultConfiguration.userRealEstateAdsBaseSearch,
            authorIdPrefix: '',
            resumeHiddenOverflow: false, // workaround to avoid clipped dropdown
            isAnAgency: true,
            gtmCategory: 'Results',
            showOwnedAccountSelection: true,
        }));
    }

    parseUrl(url) {
        const options = super.parseUrl(url);
        const loc = urlUtil.parse(url);
        const pathname = loc.pathname;
        const matches = (new RegExp('^/(' + this.configuration.urlPrefixPattern + ')')).exec(pathname);
        if (matches.length >= 3) {
            this._urlPrefix = matches[1];
            options.authorId = this.configuration.authorIdPrefix + matches[2];
        }
        return options;
    }

    getUrlPrefix() {
        const namespaceOrId = this._author && (this._author.namespace || this._author.id);
        if (namespaceOrId) {
            return SearchUrlPrefixes.userRealEstateAds + namespaceOrId;
        } else {
            return this._urlPrefix || super.getUrlPrefix();
        }
    }

    _loadData(options, cb) {
        const idOrNamespace = options.authorId || this._authorId;
        async.auto({
            ensureAccount: Account.getAccountAndCreateGuestIfNeeded, //ensure guest account because Account.get needs an accessToken
            authorAccount: [
                'ensureAccount',
                (callback) => {
                    Account.get(idOrNamespace, callback);
                },
            ],
        }, (err, {authorAccount}) => {
            if (err) {
                if (err.code == 404) {
                    cb(new Error404(err.message));
                } else {
                    cb(err);
                }
            } else if (authorAccount) {
                options.author = authorAccount;
                super._loadData(options, cb);
            } else {
                cb(new Error404('user ' + idOrNamespace + ' not found'));
            }
        });
    }

    open(options) {
        const author = this._author = options.author;
        this._authorId = options.authorId || this._authorId;
        this.configuration.loadOptions.filters = {
            author: author.id,
            onlyOwnData: options.onlyOwnData,
        };
        if (this.configuration.onlyExclusiveMandates) {
            this.configuration.loadOptions.filters.isExclusiveSaleMandate = true;
        }
        this.configuration.enableOnTheMarketFilter = _.some(options.ownedAccounts, (account) => account._id == options.authorId);
        return super.open(options);
    }

    getAuthorName() {
        return _.get(this, '_author.display_name');
    }

    getTitleOptions() {
        return _.extend({
            authorName: this.getAuthorName(),
        }, super.getTitleOptions());
    }
};
