const {i18n: {translate}} = require('fack');
const i18nMixin = require('../../vue/components/mixins/i18n');

const translationPrefix = 'postalCodeField.';

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseInputMixin'),
        i18nMixin({
            prefix: translationPrefix,
            keys: {
                error: 'errorMessage',
            },
        }),
    ],
    props: {
        placeholder: {
            type: String,
            default: () => translate(`${translationPrefix}placeholder`),
        },
    },
    computed: {
        extraAttributes() {
            return {
                inputmode: 'numeric',
                'data-bv-zipcode': true,
                'data-bv-zipcode-country': 'FR',
                'data-bv-zipcode-message': this.errorMessage,
            };
        },
    },
    beforeCreate() {
        this.type = 'text';
    },
};
