const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const NamesFormatter = require('../../common/NamesFormatter');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');
const {getImageUrlFromAlias} = require('../ImageHelper');
const humanizeDuration = require('humanize-duration');

module.exports = function formatContact(contact) {
    contact = fixLiveTourFormat(contact); // Remove this after livetour format migration
    const {
        callerPhoneNumber,
        duration,
        durationAnswered,
        firstName,
        lastName,
        name,
        phone,
        realEstateAd,
        targetPhoneNumber,
    } = contact;
    const phoneToFormat = callerPhoneNumber || phone;
    return _.assignIn({}, contact, {
        callerPhoneNumber: new PhoneNumberFormatter(phoneToFormat).phoneNumberToDisplay,
        targetPhoneNumber: new PhoneNumberFormatter(targetPhoneNumber).phoneNumberToDisplay,
        displayName: getFormattedName(lastName, firstName) || name || translate('contactsPro.missingName'),
        messageTitle: getMessageTitle(contact),
        realEstateAd: realEstateAd ? formatRealEstateAd(realEstateAd) : undefined,
        durationFormatted: duration ? formatDuration(duration) : undefined,
        callWaitingTimeFormatted: duration ? formatDuration(duration - (durationAnswered || 0)) : undefined,
    });
};

function fixLiveTourFormat(contact) {
    const {
        askForLiveTour,
        liveTourAppointmentDate,
        liveTourType,
        appointmentConfirmationUrl,
    } = contact;
    if (askForLiveTour) {
        return _.extend({
            liveTour: {
                type: liveTourType,
                appointmentDate: liveTourAppointmentDate,
                appointmentConfirmationUrl,
            },
        }, contact);
    } else {
        return contact;
    }
}

function getFormattedName(lastName, firstName) {
    return _.compact([NamesFormatter.formatLastName(lastName), NamesFormatter.formatFirstName(firstName)]).join(' ');
}

function getMessageTitle({
    answered,
    duration,
    transactionType,
    realEstateAd,
    type = 'contactRequest',
}) {
    const titleByType = {
        contactRequest: _.partial(RealEstateAdTitleGenerator.getTitle, realEstateAd, 'contactRequest'),
        accountContacts: () => translate('contactsPro.contactTitle.accountContacts'),
        adSubmissions: () => translate('contactsPro.contactTitle.adSubmissions.' + realEstateAd.transactionType, {
            adResume: RealEstateAdTitleGenerator.getTitle(realEstateAd, 'adSubmission'),
        }),
        estimationRequests: () => translate('contactsPro.contactTitle.estimationRequests'),
        calls: () => {
            return translate(`contactsPro.contactTitle.calls.answered_${answered}`, {
                duration: formatDuration(duration),
                transactionType: transactionType || 'noTransactionType',
            });
        },
    };
    return titleByType[type]();
}

function formatRealEstateAd(realEstateAd) {
    return _.assignIn(VirtualTourHelper.enhanceAd(realEstateAd), {
        photos: _.map(realEstateAd.photos, photo => ({
            url: getImageUrlFromAlias(photo.photo),
        })),
    });
}

function formatDuration(duration) {
    return humanizeDuration(duration * 1000, {
        delimiter: ` ${translate('humanizeDuration.delimiter')} `,
        language: 'fr',
        largest: 2,
        round: true,
    });
}
