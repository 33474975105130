const _ = require('lodash');
const async = require('async');
const spherical = require('spherical');
const LocalStorageSavedSearch = require('../utils/localStorage/LocalStorageSavedSearch');
const {getCurrentPosition} = require('./GeolocationHelper');
const {getLocationFromIp} = require('./GeoIp');

module.exports = {
    filterMatchingZones,
};

/**
 * A point.
 * @typedef PointDefinition
 * @type {object}
 * @property {number} lat The latitude.
 * @property {number} lng The longitude.
 */

/**
 * @typedef DiskDefinition
 * @type {object}
 * @property {PointDefinition} center The disk center.
 * @property {number} radius The disk radius in meters.
 */

/**
 * A zone definition, defined both with a set of circles and the postal codes.
 * @typedef ZoneDefinition
 * @type {object}
 * @property {DiskDefinition[]} disks An array of disks covering the zone.
 * @property {string[]} postalCodesPrefixes All the postal codes prefixes of the zone.
 */

/**
 * @callback filterMatchingZones~callback
 * @param {Error?} err
 * @param {ZoneDefinition[]?} zones The filtered zones.
 */

/**
 * Filter the zones matching the user search zone, determined by either the last search of the user or its physical location.
 * @param {ZoneDefinition[]} zones An array of zones to filter on the user search zone.
 * @param {filterMatchingZones~callback} cb
 */
function filterMatchingZones(zones, cb) {
    if (_.isEmpty(zones)) { // avoid asking the geolocation permission for nothing
        async.setImmediate(cb);
    } else {
        findZonePredicate((err, zonePredicate) => {
            if (err) {
                cb(err);
            } else if (zonePredicate) {
                cb(null, _.filter(zones, zonePredicate));
            } else {
                cb();
            }
        });
    }
}

function findZonePredicate(cb) {
    getFirstResult([
        cb => {
            async.setImmediate(() => cb(null, getZonePredicateFromLastSearch()));
        },
        cb => {
            getZonePredicateFromIp((err, predicate) => {
                if (err) {
                    console.warn('Could not get zone predicate from IP, skipping', err);
                    cb();
                } else {
                    cb(null, predicate);
                }
            });
        },
        getZonePredicateFromBrowserGeolocation,
    ], cb);
}

/**
 * @return {function(ZoneDefinition): boolean}
 */
function getZonePredicateFromLastSearch() {
    const lastSearchPostalCodes = getLastSearchPostalCodes();
    if (!_.isEmpty(lastSearchPostalCodes)) {
        return zone => isZoneMatchingPostalCodes(zone, lastSearchPostalCodes);
    }
}

function getZonePredicateFromIp(cb) {
    getLocationFromIp({timeout: 500}, (err, location) => {
        if (err) {
            cb(err);
        } else {
            const {
                country_code: countryCode,
                city,
                postal_code: postalCode,
                latitude: lat,
                longitude: lng,
            } = location;
            const point = null != lat && {lat, lng};
            if (city // not accurate enough if no city is found
                && (postalCode || point)) {
                cb(null, zone => {
                    const isPostalCodeMatching = countryCode == 'FR'
                        && postalCode
                        && isZoneMatchingPostalCode(zone, postalCode);
                    const isInZone = point && isPointInZone(point, zone);
                    return isPostalCodeMatching || isInZone;
                });
            } else {
                cb();
            }
        }
    });
}

function getZonePredicateFromBrowserGeolocation(cb) {
    getCurrentPosition({
        timeout: 500,
        maximumAge: Infinity,
    }, (err, pos) => {
        if (err) {
            cb(err);
        } else if (pos) {
            const {latitude, longitude, accuracy} = pos.coords;
            const accuracyDisk = {
                center: {lat: latitude, lng: longitude},
                radius: accuracy,
            };
            cb(null, zone => diskIntersectsZone(accuracyDisk, zone));
        } else {
            cb();
        }
    });
}

/**
 * Run the tasks in series and return the truthy result.
 * @param {function[]} tasks
 * @param cb
 */
function getFirstResult(tasks, cb) {
    let result;
    let taskIndex = 0;
    async.whilst(
        () => tasks[taskIndex] && result == null,
        cb => {
            tasks[taskIndex]((err, res) => {
                if (!err) {
                    result = res;
                }
                cb(err);
            });
            ++taskIndex;
        },
        err => {
            cb(err, result);
        }
    );
}

/**
 * @return {string[]|undefined}
 */
function getLastSearchPostalCodes() {
    const lastSearch = LocalStorageSavedSearch.get();
    if (lastSearch) {
        return _.compact(_.map(lastSearch.locationNames, getLocationNamePostalCode));
    }
}

/**
 * @param {string} locationName
 * @return {?string} The postal code if there is one in the location name.
 */
function getLocationNamePostalCode(locationName) {
    const postalCodeMatches = locationName.match(/(^|-)(\d{2,5})$/);
    if (postalCodeMatches) {
        return postalCodeMatches[2];
    }
}

/**
 * @param {ZoneDefinition} zone
 * @param {string} postalCode
 * @return {boolean}
 */
function isZoneMatchingPostalCode(zone, postalCode) {
    return _.some(zone.postalCodesPrefixes, prefix => _.startsWith(postalCode, prefix));
}

/**
 * @param {ZoneDefinition} zone
 * @param {string[]} postalCodesPrefixes
 * @return {boolean}
 */
function isZoneMatchingPostalCodes(zone, postalCodesPrefixes) {
    return _.some(postalCodesPrefixes, postalCode => isZoneMatchingPostalCode(zone, postalCode));
}

/**
 * @param {PointDefinition} point
 * @param {ZoneDefinition} zone
 * @return {boolean}
 */
function isPointInZone(point, zone) {
    return _.some(zone.disks, disk => isPointInDisk(point, disk));
}

/**
 * @param {DiskDefinition} disk
 * @param {ZoneDefinition} zone
 * @return {boolean}
 */
function diskIntersectsZone(disk, zone) {
    return _.some(zone.disks, zoneDisk => diskIntersectsDisk(disk, zoneDisk));
}

/**
 * @param {PointDefinition} point
 * @param {DiskDefinition} disk
 * @return {boolean}
 */
function isPointInDisk(point, disk) {
    return distance(point, disk.center) <= disk.radius;
}

/**
 * @param {DiskDefinition} diskA
 * @param {DiskDefinition} diskB
 * @return {boolean}
 */
function diskIntersectsDisk(diskA, diskB) {
    return distance(diskA.center, diskB.center) <= diskA.radius + diskB.radius;
}

/**
 * @param {PointDefinition} pointA
 * @param {PointDefinition} pointB
 * @return {number} The distance between the points (in meters)
 */
function distance(pointA, pointB) {
    return spherical.distance(pointToGeoJSONCoord(pointA), pointToGeoJSONCoord(pointB));
}

/**
 * @param {PointDefinition} point
 * @return {number[]} The point in GeoJSON format ([lng, lat]).
 */
function pointToGeoJSONCoord(point) {
    return [point.lng, point.lat];
}
