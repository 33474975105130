const _ = require('lodash');
const assert = require('assert');
const VueView = require('./VueView');

const VUE_ROOT_CLASS_PART = 'vue-';

module.exports = {
    injectVueViews,
    destroyVue,
};

function findInjectableElements($element) {
    return $element.find(`[class*=${VUE_ROOT_CLASS_PART}]`);
}

function injectVueViews($element, vueOptions) {
    return _($element)
        .thru(findInjectableElements)
        .reject(getVueVm) // do not re-inject Vue when already injected
        .map(el => {
            assert(!el.__vue__, `Vue already injected on element with class "${el.getAttribute('class')}"`);
            const view = new VueView();
            view.show(null, _.extend({
                name: _.find(el.classList, className => _.includes(className, VUE_ROOT_CLASS_PART)),
                el,
            }, vueOptions));
            return view;
        })
        .value();
}

function destroyVue($element) {
    _.each(findInjectableElements($element), el => {
        const vm = getVueVm(el);
        if (vm) {
            vm.$destroy();
        }
    });
}

function getVueVm(el) {
    return el.__vue__; // undocumented
}
