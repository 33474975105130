const _ = require('lodash');
const SimpleContentVueView = require('../vue/SimpleContentVueView');
const Ajax = require('../utils/Ajax');
const async = require('async');
const placeDetailsHelper = require('../PlaceDetailsHelper');

module.exports = class SearchLinksView extends SimpleContentVueView {
    show() {
        super.show({htmlContent: this.htmlContent});
    }

    loadData(options, callback) {
        async.waterfall([
            cb => {
                if (options.department) {
                    placeDetailsHelper.getPlaceDetails(options, (err, placeDetails) => {
                        if (err || !placeDetails) {
                            cb(err, null);
                        } else {
                            const location = _.pick(placeDetails, ['name', 'nameForSearchUrl']);
                            if (placeDetails.type === 'district') {
                                location.name = placeDetails.parent.name + ' - ' + location.name;
                            }
                            cb(null, {
                                location,
                            });
                        }
                    });
                } else {
                    cb(null, null);
                }
            },
            (data, cb) => {
                Ajax.request({
                    url: '/html/searchLinksContent.html',
                    timeout: 30000,
                    data,
                    disableErrorPage: true,
                    callback: (ignoredError, data) => {
                        // ignore potential errors to avoid displaying error message
                        cb(null, data);
                    },
                });
            },
        ], (err, htmlContent) => {
            if (err) {
                console.error(err);
            }
            this.htmlContent = htmlContent;
            callback(null);
        });
    }
};
