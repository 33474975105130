const _ = require('lodash');
const Account = require('../../authentication/Account');
const $ = require('jquery');

// heavily inspired by https://github.com/F4-Group/bootstrapvalidator/blob/master/src/js/validator/remote.js

const VALIDATOR_NAME = 'authRestRemote';
const HTML_TIMER_KEY = `bv.${VALIDATOR_NAME.toLowerCase()}.timer`;

module.exports = {
    html5Attributes: {
        message: 'message',
        name: 'name',
        method: 'method',
        url: 'url',
        data: 'data',
        delay: 'delay',
    },

    /**
     * Destroy the timer when destroying the bootstrapValidator (using validator.destroy() method)
     */
    destroy: function (validator, $field) {
        if ($field.data(HTML_TIMER_KEY)) {
            clearTimeout($field.data(HTML_TIMER_KEY));
            $field.removeData(HTML_TIMER_KEY);
        }
    },

    /**
     * Request a rest remote server to check the input value
     *
     * @param {BootstrapValidator} validator Plugin instance
     * @param {jQuery} $field Field element
     * @param {Object} options Can consist of the following keys:
     * - url {String} Can have {value} if you want the inputted value in the url
     * - type {String} [optional] Can be GET (default) or POST
     * - data {Object|Function} [optional]: If {value} is not in url, it will be added to data like:
     *  {
     *      <fieldName>: <fieldValue>
     *  }
     * - delay in ms. Adds a after input is changed before query is triggered. Resets on each change.
     * - name {String} [optional]: Override the field name for the request.
     * - message: The invalid message
     * @returns {Deferred}
     */
    validate: function (validator, $field, options) {
        const result = new $.Deferred();
        const value = $field.val();

        if (value === '') {
            result.resolve($field, VALIDATOR_NAME, {valid: true});
            return result;
        }

        const {method = 'GET', delay} = options;
        let {data = {}, url, name} = options;
        if (!name) {
            name = $field.attr('data-bv-field');
        }

        // handle data from html5
        if (_.isString(data)) {
            data = JSON.parse(data);
        } else {
            // avoid modifying parameter
            data = _.clone(data);
        }

        // handle value in url
        if (_.includes(url, '{value}')) {
            url = url.replace(/{value}/g, encodeURIComponent(value));
        } else {
            data[name] = value;
        }

        function runQuery() {
            const query = Account.authAjax({
                url,
                type: method,
                data,
                disableErrorPage: true,
                // eslint-disable-next-line handle-callback-err
                callback: function (err, valid) {
                    result.resolve($field, VALIDATOR_NAME, {valid: _.includes([true, 'true'], valid)});
                },
            });

            result.fail(function () {
                query.abort();
            });
        }

        if (delay) {
            // Since the form might have multiple fields with the same name
            // I have to attach the timer to the field element
            if ($field.data(HTML_TIMER_KEY)) {
                clearTimeout($field.data(HTML_TIMER_KEY));
            }

            $field.data(HTML_TIMER_KEY, setTimeout(runQuery, delay));
        } else {
            runQuery();
        }
        return result;
    },
};
