const _ = require('lodash');

const FormUtils = require('../fields/FormUtils');
const {i18n: {translate}} = require('fack');
const {removeDataFromOwnersFromAccount, convertArrayToAccountData} = require('./AccountForModification');
const {BLUR_TYPES} = require('../BlurHelper');
const {DEVELOPER_ACCOUNT_TYPE} = require('../../common/AccountTypes');

_.each(BLUR_TYPES, function (blurType) {
    if (blurType.radius == undefined) {
        blurType.radius = null;
    }
    return blurType;
});

/**
 * this handles createAccount (pro) and updateAccount (pro & public)
 * @param {object} options
 * @param {Node} options.$form
 * @param {object} [options.account]
 * @param {function} options.submit {@link FormUtils~init}
 * @param {function} options.fieldSuccess {@link FormUtils~init}
 * @param {function} options.formError {@link FormUtils~init}
 * @param {object[]} [options.contractAccounts] pre loaded contractAccounts
 */
module.exports = AccountForm;

function AccountForm(options) {
    const $form = options.$form;

    let accountForModification;
    init();
    if (options.account) {
        accountForModification = removeDataFromOwnersFromAccount(options.account);
        fill(accountForModification);
    }

    return {
        getAccountData,
        clear,
        spreadBlurType,
        removeAlreadySet,
    };

    /**
     * todo: ne devrait pas être hardcodé pour pro a mon avis.
     */
    function init() {
        FormUtils.init({
            $form: $form,
            submit: options.submit,
            fieldSuccess: options.fieldSuccess,
            formError: options.formError,
        });
    }

    function getContractAccountIds() {
        return _.map(options.contractAccounts, 'id');
    }

    function getExtensionAccountIds() {
        return _.map(options.extensionAccounts, 'id');
    }

    function getContractAndExtensionAccountIds() {
        return [...getContractAccountIds(), ...getExtensionAccountIds()];
    }

    function initOwnerIds(account) {
        const userOwnerIdsIncludingContractsAndExtension = _.get(account, 'dataFromUser.ownerIds', []);
        const contractAccountIds = _.intersection(userOwnerIdsIncludingContractsAndExtension, getContractAccountIds());
        const extensionAccountIds = _.intersection(userOwnerIdsIncludingContractsAndExtension, getExtensionAccountIds());
        const contractAndExtensionAccountIds = _.intersection(
            userOwnerIdsIncludingContractsAndExtension,
            getContractAndExtensionAccountIds()
        );
        FormUtils.setInputValue($form.find('[name="contractType"]'), _.first(contractAccountIds));
        FormUtils.setInputValue($form.find('[name="extensionType"]'), extensionAccountIds);
        const userOwnerIdsExcludingContractsAndExtension = _.difference(
            userOwnerIdsIncludingContractsAndExtension,
            contractAndExtensionAccountIds
        );
        $form.find('[name="user/ownerIds"]').val(userOwnerIdsExcludingContractsAndExtension.join(','));
        const importOwnerIds = _.get(account, 'dataFromImport.ownerIds', []);
        $form.find('[name="import/ownerIds"]').val(importOwnerIds.join(','));
    }

    function fill(account) {
        account.blurTypes = convertBlurTypesToForm(account.blurTypes);

        const originalAccountContactOptions = getOriginalAccountContactOptions(account);

        account.contactModeAvailableOnAds = convertContactOptionsToAccountFlag(originalAccountContactOptions);

        FormUtils.setValues($form, account);
        const passwordCred = _.find(account.credentials, function (cred) {
            return cred.type == 'password';
        });
        if (passwordCred) {
            $form.find('input[name="login"]').val(passwordCred.id);
        }
        initOwnerIds(account);
        addInheritedDataIndicators(account);

        delete account.contactModeAvailableOnAds;
    }

    function getOriginalAccountContactOptions(account) {
        return convertArrayToAccountData(['displayContactByEmailOnAds', 'displayContactByPhoneOnAds'], account);
    }

    function fillOwnersBlurTypes(blurTypes) {
        if (!blurTypes) {
            return;
        }
        _.each(blurTypes, (value, category) => {
            if (_.isObject(value)) {
                _.each(value, (value, subCategory) => {
                    setOwnerBlurType('.' + category + '.' + subCategory, value);
                });
            } else {
                setOwnerBlurType('.' + category, value);
            }
        });
    }

    function setOwnerBlurType(key, blurTypeKey) {
        if (blurTypeKey) {
            const translation = translate('accountForm.blurTypeFromOwner', {blurType: blurTypeKey});
            $form.find('.blurTypeOwnerData[data-key="' + key + '"]').text(translation);
        } //TODO afficher les réglages par défaut sinon
    }

    function addInheritedDataIndicators(account) {
        const dataFromOwners = account.dataFromOwners || {};
        fillOwnersBlurTypes(convertBlurTypesToForm(dataFromOwners.blurTypes));
    }

    function getAccountData(additionalDataFromVm) {
        let data = FormUtils.readValues($form, {removeUndefined: false});
        delete data['password-confirmation'];

        _.each(data, function (val, name) {
            if (/^file /.test(name)) { // hidden fields from file uploader
                delete data[name];
            }
        });
        data.blurTypes = _.omit(data.blurTypes, 'global');
        convertBlurTypesFromForm(data.blurTypes);
        storeRoles(data);
        convertPasswordToCredentials(data);
        convertContactOptionsSimpleFlagFromFormIntoDualFlags(data);
        data = removeUnchangedValues(_.extend(data, additionalDataFromVm));

        if (!((accountForModification || {}).dataFromUser || {}).blurTypes && isFullDefaultBlur(data.blurTypes)) {
            //only send blurTypes if it was already set or is not full default
            delete data.blurTypes;
        }
        return data;
    }

    function convertContactOptionsSimpleFlagFromFormIntoDualFlags(data) {
        const simpleContactFlag = data.contactModeAvailableOnAds;
        let displayContactByEmailOnAds = null;
        let displayContactByPhoneOnAds = null;
        switch (simpleContactFlag) {
            case 'emailOnly':
                displayContactByEmailOnAds = true;
                displayContactByPhoneOnAds = false;
                break;
            case 'phoneOnly':
                displayContactByEmailOnAds = false;
                displayContactByPhoneOnAds = true;
                break;
            case 'emailAndPhone':
                displayContactByEmailOnAds = true;
                displayContactByPhoneOnAds = true;
                break;
        }
        data.displayContactByEmailOnAds = displayContactByEmailOnAds;
        data.displayContactByPhoneOnAds = displayContactByPhoneOnAds;
        delete data.contactModeAvailableOnAds;
    }

    function removeUnchangedValues(data) {
        return removeAlreadySet(data, accountForModification, (accountForModification || {}).dataFromImport || {});
    }

    function removeAlreadySet(userData, previousData, importData) {
        previousData = previousData || {};
        importData = importData || {};
        const result = {};
        _.each(userData, function (value, key) {
            if (value === undefined || value === '') {
                value = null;
            }
            let previousValue = previousData[key];
            if (previousValue === undefined) {
                previousValue = null;
            }
            if (_.includes([
                'ownerIds', // special case: the resulting value is a merge of user and import data
            ], key)) {
                const previousValue = _.get(previousData.dataFromUser, key);
                if (!_.isEqual(value, previousValue) && (previousValue != null || value != null)) {
                    //do not set if both are null or undefined
                    result[key] = value;
                }
            } else if (_.isObject(value) && !_.isArray(value)) {
                const data = removeAlreadySet(value, previousValue, importData[key]);
                if (_.size(data) > 0) {
                    result[key] = data;
                }
            } else if (!_.isEqual(value, previousValue)) {
                if (_.isEqual(importData[key], value)) {
                    result[key] = null; //explicit loss
                } else {
                    result[key] = value;
                }
            } else {
                //value is the same, ignore
            }
        });
        return result;
    }

    function storeRoles(accountData) {
        //roles == undefined means that the field is not present in the form
        if (accountData.roles != undefined) {
            const roles = [];
            _.each(_.keys(accountData.roles), function (roleName) {
                if (accountData.roles[roleName]) {
                    roles.push(roleName);
                }
            });
            if (roles.length) {
                accountData.roles = roles;
            } else {
                accountData.roles = null;
            }
        }
    }

    function convertBlurTypesToForm(object) {
        if (!object) {
            return null;
        }
        const result = {};
        _.each(object, function (value, attributeName) {
            if (value && value.type) {
                value = _.find(BLUR_TYPES, value);
                result[attributeName] = value ? value.key : null;
            } else if (_.isObject(value)) {
                result[attributeName] = convertBlurTypesToForm(value);
            } else {
                result[attributeName] = 'default';
            }
        });
        return result;
    }

    function convertBlurTypesFromForm(object) {
        _.each(object, function (value, attributeName) {
            if (_.isObject(value)) {
                convertBlurTypesFromForm(value);
            } else if (_.isString(value)) {
                const blurType = _.find(BLUR_TYPES, {key: value});
                value = blurType && blurType.type != 'default' ? _.omit(blurType, 'key') : null;
                object[attributeName] = value;
            }
            if (!value || _.isEmpty(value)) {
                delete object[attributeName];
            }
        });
    }

    function isFullDefaultBlur(blurTypes) {
        let isDefault = true;
        _.each(blurTypes, function (blurType) {
            if (blurType.type) {
                if (blurType.type != 'default') {
                    isDefault = false;
                }
            } else {
                _.each(blurType, function (blurLevel) {
                    if (blurLevel.type != 'default') {
                        isDefault = false;
                    }
                });
            }
        });
        return isDefault;
    }

    function clear() {
        FormUtils.clear({
            $form: $form,
        });
    }

    function spreadBlurType() {
        const $input = FormUtils.findInputWithName($form, 'blurTypes.global');
        const value = FormUtils.getInputValue($input);
        const $blurTypesForm = $form.find('#section_type-de-localisation');
        FormUtils.eachInputWithName($blurTypesForm, $input => {
            FormUtils.setInputValue($input, value);
        });
    }
}

function convertPasswordToCredentials(accountData) {
    const password = accountData.password;
    if (password) {
        const id = accountData.login || accountData.email;
        accountData.credentials = [{type: 'password', id, password}];
        delete accountData.password;
    }
    delete accountData.login;
}

function getDefaultBooleanValue(account, name) {
    const defaultValuesOfBooleanInheritedData = {
        ignoreAdContact: false,
        callTrackingEnabled: false,
        displayLoweredPriceAds: true,
        displayLogoOnSearchPage: false,
        virtualTourEnabled: false,
        virtualToursBehindForm: false,
        pdfBehindForm: false,
        nothingBehindForm: false,
        highlightMailContact: account.accountType == DEVELOPER_ACCOUNT_TYPE,
        ignoreAdsFlowPosition: false,
        enableRecentAdsPromotion: false,
        photoWatermarkEnabled: false,
        boostAdsInSuggestions: false,
        highlightedOnMap: false,
        eligibleForEstimationRequests: false,
        eligibleForSavedSearchCreationAlert: false,
        eligibleForRentalAdSubmissionRequests: false,
        eligibleForSaleAdSubmissionRequests: false,
        canEnablePublicPage: false,
        localFameBanner: false,
        automaticLeadingAds: false,
        automaticHighlightedAds: false,
        exportableAds: true,
        includeRealEstateAdsInAlert: true,
        canEditDescendantsAds: false,
        displayInsuranceEstimationOnAds: true,
        canBuyTemporaryLeadingAds: false,
        canSeeChildrenContacts: true,
        wantsToReceiveLeadingAdsSuggestions: true,
        rentalApplicationEnabled: false,
        publishAdsOnlyOnBienIci: false,
        hasAdsRelevanceDynamicBonus: false,
        canSeeProspectingMap: false,
        isExternalAdvertisementEnabled: false,
        jobAdsEnabled: false,
        allowNewPropertyImport: true,
    };
    const dataFromOwners = account.dataFromOwners || {};
    let inheritedValue;
    if (name == 'exportableAds') {
        const exportableAdsFromImport = _.get(account, 'dataFromImport.exportableAds', null);
        inheritedValue = _.get(dataFromOwners, 'exportableAds', exportableAdsFromImport);
    } else {
        inheritedValue = _.get(dataFromOwners, name);
    }
    const defaultValue = defaultValuesOfBooleanInheritedData[name];
    return inheritedValue != null ? inheritedValue : defaultValue;
}

function getDefaultLabelForBoolean(account, name) {
    const defaultValue = getDefaultBooleanValue(account, name);
    return translate('byDefault_withValue', {value: defaultValue ? 'Oui' : 'Non'});
}

function convertContactOptionsToAccountFlag({displayContactByEmailOnAds, displayContactByPhoneOnAds}) {
    if (_.every([displayContactByEmailOnAds, displayContactByPhoneOnAds], _.isBoolean)) {
        const value = _.compact([displayContactByEmailOnAds && 'email', displayContactByPhoneOnAds && 'phone']);
        return value.length > 1 ? 'emailAndPhone' : `${value[0]}Only`;
    } else {
        return 'default';
    }
}

function getDefaultValueForContactModeAvailableOnAds(account) {
    const dataFromOwners = account.dataFromOwners || {};
    let inheritedContactModeAvailableOnAds = convertContactOptionsToAccountFlag(dataFromOwners);
    if (inheritedContactModeAvailableOnAds === 'default') { // in case no inheritance data
        inheritedContactModeAvailableOnAds = 'emailAndPhone';
    }
    return inheritedContactModeAvailableOnAds;
}

function getProOptionsDefaultValues(contractAccount) {
    const defaultValues = {};
    do {
        const {proOptions} = contractAccount;
        //TODO: when we receive the ranking, the option with biggest rank will be returned
        if (proOptions) {
            for (const [option, optionValue] of Object.entries(proOptions)) {
                defaultValues[option] = optionValue;
            }
        }
        contractAccount = contractAccount.dataFromOwners;
    } while (!_.isUndefined(contractAccount));
    return defaultValues;
}

_.extend(AccountForm, {
    getDefaultLabelForBoolean,
    getDefaultValueForContactModeAvailableOnAds,
    getProOptionsDefaultValues,
});
