const _ = require('lodash');
const VirtualTourHelper = require('../../virtualTours/VirtualTourHelper');

module.exports = class AdContentHandler {
    constructor() {
        this._init();
    }

    updateContent(adId, {pdf = [], virtualTours = []}) {
        this.pdf[adId] = pdf;
        this.virtualTours[adId] = VirtualTourHelper.getAvailableTours(virtualTours);
    }

    /**
     *
     * @param {string} adId
     * @param {string} contentType pdf,virtualTours
     * @param {int} index
     */
    getContent(adId, contentType, index = 0) {
        const contents = this[contentType];
        if (contentType === 'virtualTours') {
            return _.find(this.virtualTours[adId], {id: index});
        } else {
            return contents && contents[adId][index];
        }
    }

    getContentByUrl(adId, contentType, url) {
        const contents = this[contentType][adId];
        return _.filter(contents, content => {
            return content.url === url;
        });
    }

    cleanup(ids) {
        if (ids) {
            this.pdf = _.omit(this.pdf, ids);
            this.virtualTours = _.omit(this.virtualTours, ids);
        } else {
            this._init();
        }
    }

    _init() {
        this.pdf = {};
        this.virtualTours = {};
    }

};
