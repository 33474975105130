const async = require('async');
const _ = require('lodash');

const {INITIAL_STEP} = require('../Constants');
const template = require('./RealEstateStepper.jade');
const RealEstatePropertyProgress = require('./RealEstatePropertyProgress');
const RealEstateNavigationButtons = require('./RealEstateNavigationButtons');
const CommonStepMixin = require('./mixins/CommonStepMixin');
const ScrollHelper = require('../../utils/ScrollHelper');

module.exports = (storeModuleName) => ({
    components: {
        RealEstatePropertyProgress,
        RealEstateNavigationButtons,
    },
    mixins: [
        require('../../fields/vue/validationMixin')({
            excluded: [':disabled', ':hidden', ':not(:visible)'],
        }),
        CommonStepMixin(storeModuleName),
    ],
    props: {
        stepToGo: {
            type: Number,
            required: true,
        },
        resultStep: {
            type: Number,
            required: true,
        },
        suggestedAgenciesStep: {
            type: Number,
            required: true,
        },
        isNewEstimation: Boolean,
        hasErrorInSubmission: Boolean,
        isRequestingAgencies: Boolean,
        hasAgenciesToContact: Boolean,
    },
    computed: {
        shouldInlineLabelAndField() {
            return this.step > INITIAL_STEP;
        },
    },
    watch: {
        stepToGo(stepToGo) {
            if (this.isEstimation) {
                this.commitToStore('loading', false);
            }
            if (stepToGo === INITIAL_STEP) {
                this.setStepAndEmitChange(INITIAL_STEP);
            } else {
                this.setStep(INITIAL_STEP);
                this.gotoStepWithNoHistoryChange(stepToGo);
            }
        },
        isNewEstimation(isNewEstimation) {
            if (isNewEstimation) {
                this.gotoStep(INITIAL_STEP);
                this.$emit('update:is-new-estimation', false);
            }
        },
    },
    methods: {
        setStep(step) {
            this.commitToStore('step', step);
        },
        setStepAndEmitChange(step) {
            if (this.step != step) {
                this.setStep(step);
                this.$nextTick(() => { // to give Vue time to handle state changes
                    ScrollHelper.scrollToTop();
                    this.$emit('update-step', step);
                });
            }
        },
        goToInitialStep() {
            this.gotoStep(INITIAL_STEP);
        },
        goToPreviousStep() {
            this.gotoStep(this.step - 1);
        },
        goToNextStep() {
            this.gotoStep(this.step + 1);
        },
        gotoStep(step) {
            if (step <= this.suggestedAgenciesStep && !this.isRequestingAgencies && !this.hasAgenciesToContact) {
                this.$emit('request-agencies-to-contact');
            }
            this.$emit('on-pre-step-change', this.step);
            const stepBefore = this.step;
            this.gotoStepWithNoHistoryChange(step, () => {
                if (this.step != stepBefore) {
                    this.$emit('on-step-change-from-user-interaction', this.step);
                }
            });
        },
        gotoStepWithNoHistoryChange(step, cb = _.noop) {
            if (step <= this.step) {
                this.setStepAndEmitChange(step);
                async.setImmediate(cb);
            } else {
                const initialStep = this.step;
                async.eachSeries(_.range(this.step, step), (step, callback) => {
                    this.$nextTick(() => {
                        if (this.validateForm()) {
                            this.setStep(step + 1);
                            callback();
                        } else {
                            callback(new Error(`step ${this.step} form is not valid`));
                        }
                    });
                }, (err) => {
                    const {step} = this;
                    if (initialStep != step) {
                        this.$emit('update-step', step);
                    }
                    cb(err);
                });
            }
        },
        submit() {
            if (this.validateForm()) {
                this.$emit('send-form');
                this.gotoStep(this.step + 1);
            }
        },
    },
    template: template(),
});
