const template = require('./ContactLabels.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'contactsPro.labels.',
        }),
    ],
    props: {
        labelTranslationKeys: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getVariant(labelTranslationKey) {
            let variant = null;
            switch (labelTranslationKey) {
                case 'rentalApplication':
                    variant = 'primary';
                    break;
                case 'isInvestmentProject':
                case 'hasPropertyToSell':
                    variant = 'main';
                    break;
            }
            return variant;
        },
    },
    template: template(),
};
