const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');

module.exports = class LocationItem {
    constructor(options) {
        this._item = options.item;
    }

    //used to compare values so we don't have twice the same location item in tags
    getValue() {
        return this.getText();
    }

    getUrlValue() {
        return this.getValue();
    }

    getName() {
        return this.getUrlValue();
    }

    getText({context} = {}) {
        return t(`locationField.${this.getType()}`, {context});
    }

    getInTheLocationText() {
        return this.getText({context: 'in'});
    }

    getSuggestionText() {
        return this.getText();
    }

    getType() {
        return this._item.type;
    }

    getTypeForServerRequest() {
        return this.getType();
    }

    getItem() {
        return this._item;
    }

    getItemValuesToStoreInDB() {
        return _.pick(this.getItem(), ['boundingBox', 'type', '_id']);
    }

    getBoundingBox() {
        return this._item.boundingBox;
    }

    getZoneInfoIds() {
        return this._item._id;
    }

    getZoneInfos() {
        return {
            id: this.getZoneInfoIds(),
            type: this.getType(),
        };
    }

    getOnSubmitFn() {
        return this._onSubmit ? _.bind(this._onSubmit, this) : null;
    }

    canBeAdded() {
        return true;
    }

    getItemInseeCode() {
        return this._item.insee_code;
    }
};
