const LocationItem = require('./LocationItem');

module.exports = class DrawingLocationItem extends LocationItem {
    constructor(options) {
        super(options);
        this.value = 'drawnZone';
    }

    getValue() {
        return this.value + '-' + this.getItemId();
    }

    getItemId() {
        return this.getItem()._id;
    }

    getUrlValue() {
        return 'dessin-' + this.getItemId();
    }
};
