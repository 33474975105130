const template = require('./MenuLine.jade');

// @vue/component
module.exports = {
    props: {
        href: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        rootModifiers() {
            return {withoutLink: !this.href};
        },
    },
    template: template(),
};
