const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const VueView = require('../../vue/VueView');
const BrowserNotifications = require('../../notifications/BrowserNotifications');
const Account = require('../../authentication/Account');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');
const i18nMixin = require('../../vue/components/mixins/i18n');
const savedSearchNotificationsConfigurationViewTemplate = require('./savedSearchNotificationsConfigurationView.jade');

const BREAKPOINTS_LIMITS_SM = 480;
const BREAKPOINTS_LIMITS_XS = 310;
const EMAIL_NOTIFICATION_FREQUENCIES = ['asap', 'day', 'week'];
const EMAIL_NOTIFICATION_INTENTIONS = [
    'main-home-buy',
    'secondary-home-buy',
    'invest',
    'rent',
    'sell',
    'inquire',
    'other',
];

module.exports = class SavedSearchNotificationsConfigurationView extends VueView {
    constructor(options) {
        super(_.extend({
            template: savedSearchNotificationsConfigurationViewTemplate,
        }, options));
    }

    show({savedSearch, isEditMode}) {
        let {emailNotificationMode} = savedSearch;
        if (!_.includes(EMAIL_NOTIFICATION_FREQUENCIES, emailNotificationMode)) {
            emailNotificationMode = 'asap';
        }
        const {pushNotificationMode, email} = savedSearch;
        let intention;
        const notificationEmail = _.defaultTo(email, Account.getPropertyValue('email'));
        const notificationsUnsupportedOrDenied = BrowserNotifications.areNotificationsUnsupportedOrDenied();
        let pushNotificationVisible = true;
        if (!isEditMode && !Account.hasPushNotifications() && notificationsUnsupportedOrDenied) {
            pushNotificationVisible = false;
        }
        const pushNotificationEnabled = (pushNotificationMode === 'asap') ||
            (_.isUndefined(pushNotificationMode) && !(pushNotificationVisible && notificationsUnsupportedOrDenied));
        const warningText = this.notificationWarning;
        const displayIntentionQuestion = !isEditMode;
        const emailNotificationEnabled = true;
        super.show(null, {
            bemName: 'saved-search-notifications-configuration-view',
            mixins: [
                ContainerQueries({
                    small: {
                        maxWidth: BREAKPOINTS_LIMITS_SM,
                        minWidth: BREAKPOINTS_LIMITS_XS + 1,
                    },
                }),
                i18nMixin({
                    prefix: 'saveSearchView.',
                    keys: {
                        'emailNotifications.frequency.label': 'frequencyLabel',
                        'emailNotifications.intention.label': 'intentionLabel',
                        'emailNotifications.intention.default': 'intentionPlaceholder',
                    },
                }),
                require('../../fields/vue/validationMixin')({
                    initBootstrapValidator: false, // already initialised in parent form
                }),
            ],
            data() {
                return {
                    pushNotificationEnabled,
                    emailNotificationEnabled,
                    emailNotificationMode,
                    notificationEmail,
                    warningText,
                    pushNotificationVisible,
                    intention,
                    displayIntentionQuestion,
                };
            },
            computed: {
                emailNotificationsFrequencies() {
                    return EMAIL_NOTIFICATION_FREQUENCIES.map(value => ({
                        value,
                        label: translate('saveSearchView.emailNotifications.frequency.options.' + value),
                    }));
                },
                emailNotificationsIntentions() {
                    return EMAIL_NOTIFICATION_INTENTIONS.map(value => ({
                        value,
                        label: translate('saveSearchView.emailNotifications.intention.options.' + _.camelCase(value)),
                    }));
                },
            },
        });
    }

    get notificationWarning() {
        let warningKey;
        if (!BrowserNotifications.areNotificationsSupported()) {
            warningKey = 'notificationsUnsupported';
        } else if (BrowserNotifications.areNotificationsDenied()) {
            warningKey = 'notificationsDeniedWarning';
        }
        return warningKey && translate(`saveSearchView.${warningKey}`);
    }
};
