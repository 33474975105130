const _ = require('lodash');

const OPTIONS_MAPPING = {
    none: {hasBalcony: false, hasTerrace: false},
    balcony: {hasBalcony: true, hasTerrace: false},
    terrace: {hasBalcony: false, hasTerrace: true},
};

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/configMultiselectRadioButton'),
    ],
    props: {
        hasBalcony: {
            type: Boolean,
        },
        hasTerrace: {
            type: Boolean,
        },
    },
    data() {
        return {
            options: _.map(OPTIONS_MAPPING, (v, key) => {
                return {
                    label: this.t(`${this.name}.${key}`),
                    value: key,
                };
            }),
        };
    },
    computed: {
        value() {
            return _.findKey(OPTIONS_MAPPING, {
                hasBalcony: this.hasBalcony,
                hasTerrace: this.hasTerrace,
            });
        },
    },
    methods: {
        onInput($event) {
            _.each(OPTIONS_MAPPING[$event], (value, key) => {
                this.$emit(`update:${key}`, value);
            });
        },
    },
};
