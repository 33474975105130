const _ = require('lodash');
const urlUtil = require('url');
const Account = require('../authentication/Account');
const UrlFormatter = require('../../common/UrlFormatter');
const AgencyInfoBasePage = require('./AgencyInfoBasePage');
const ApplicationConfig = require('../app/ApplicationConfig');
const Options = require('../Options');
const {getImageUrlFromAlias} = require('../ImageHelper');
const {agencyInfoPage: {makeAgencyInfoPageUrl}} = require('../Urls');
const ServerConfig = require('../ServerConfig');

module.exports = class AgencyInfoPage extends AgencyInfoBasePage {
    constructor() {
        super({
            name: 'agencyInfoPage',
            title: 'Agence',
            gtmCategory: 'Results',
        });
    }

    _getAccountIdOrNamespace(options) {
        return options.authorId || this._authorId;
    }

    _canOpenPage(options) {
        return Account.isAccountModifier() || _.get(options, 'author.agencyPageEnabled');
    }

    getUrlPattern() {
        return UrlFormatter.getAgencyInfosRegexp();
    }

    parseUrl(url) {
        const loc = urlUtil.parse(url);
        const pathname = loc.pathname;
        const matchAgencyInfosRegex = UrlFormatter.getAgencyInfosRegexp().exec(pathname);
        return {
            authorId: matchAgencyInfosRegex && matchAgencyInfosRegex[1],
        };
    }

    getJSONLD() {
        const jsonLd = [];
        const isEmbeddedMode = ApplicationConfig.isOnPartnersDomain || Options.get('isInEmbeddedMode');
        if (!isEmbeddedMode) {
            const {
                author: agency,
                rating: agencyRating,
            } = this.options;
            const pageUrl = `${ServerConfig.config.wwwUrl}${makeAgencyInfoPageUrl(agency)}`;
            const realEstateAgent = {
                '@context': 'https://schema.org',
                '@type': 'RealEstateAgent',
                image: getImageUrlFromAlias(agency.imageName),
                name: agency.display_name,
                '@id': pageUrl,
                url: pageUrl,
            };
            const address = _.get(agency.company, 'address');
            if (!_.isEmpty(address)) {
                const {street, city, postalCode} = address;
                realEstateAgent.address = {
                    '@type': 'PostalAddress',
                    streetAddress: street,
                    addressLocality: city,
                    postalCode,
                    addressCountry: {
                        '@type': 'Country',
                        name: 'FR',
                    },
                };
            }
            if (agencyRating) {
                realEstateAgent.aggregateRating = {
                    '@type': 'AggregateRating',
                    ratingValue: agencyRating.score,
                    bestRating: 5,
                    ratingCount: agencyRating.count,
                };
            }
            jsonLd.push(realEstateAgent);
        }
        return jsonLd;
    }
};
