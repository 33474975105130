const _ = require('lodash');
const ServerConfig = require('./ServerConfig');
const io = window.io = require('socket.io-client'); //fallbacks rely on global...
const Account = require('./authentication/Account');
const RemoteLoggerClient = require('remote-logger-client');

module.exports = {
    getSocket,
    connect,
    emit,
    on,
    off,
    join,
    leave,
    openContext,
};

const prefix = 'kimono:';
let socket;
const _allJoinedRooms = {};
let _joinedAccountRooms = [];

function connect() {
    const {watcherUrl} = ServerConfig.config;

    const socketIoOptions = {};
    if (shouldForceBase64Polling()) {
        _.extend(socketIoOptions, {
            transports: ['polling'],
            forceBase64: true,
        });
    }
    console.log('Connecting to realtime server on %s', watcherUrl);
    socket = io.connect(watcherUrl, socketIoOptions);
    socket.on('connect', function () {
        console.log('Connected to realtime server');
        _.each(_allJoinedRooms, function ({data}, roomName) {
            socketJoin(roomName, data);
        });
    });

    RemoteLoggerClient.init(socket);
    Account.on('change:id', joinAccountRooms); // for backward compatibility with account servers without rooms
    Account.on('change', joinAccountRooms);
    joinAccountRooms();
    on('account_changed', function (info) {
        Account.setAuthenticatedAccount(info.account);
    });
}

function shouldForceBase64Polling() {
    return /polling-base64/i.test(navigator.userAgent);
}

function joinAccountRooms() {
    const account = Account.getAuthenticatedAccount();
    const rooms = _.get(account, 'rooms', []);
    const roomsToJoin = _.difference(rooms, _joinedAccountRooms);
    const roomsToLeave = _.difference(_joinedAccountRooms, rooms);
    _.each(roomsToJoin, join);
    _.each(roomsToLeave, leave);
    _joinedAccountRooms = _.clone(rooms);
}

function getSocket() {
    return socket;
}

function emit(channel, message) {
    socket.emit(prefix + channel, message);
}

function on(channel, handler) {
    socket.on(prefix + channel, handler);
}

function off(channel, handler) {
    socket.off(prefix + channel, handler);
}

function join(roomName, data = {}) {
    if (!_allJoinedRooms[roomName]) {
        _allJoinedRooms[roomName] = {data, joinCount: 1};
        socketJoin(roomName, data);
    } else {
        _allJoinedRooms[roomName].joinCount++;
    }
}

function socketJoin(roomName, data = {}) {
    socket.emit('join', _.extend({}, data, {name: prefix + roomName}));
}

function leave(roomName, data = {}) {
    if (_allJoinedRooms[roomName]) {
        _allJoinedRooms[roomName].joinCount--;
        if (_allJoinedRooms[roomName].joinCount == 0) {
            delete _allJoinedRooms[roomName];
            socket.emit('leave', _.extend({}, data, {name: prefix + roomName}));
        }
    }
}

function openContext() {
    let joinedRooms = [];
    let handlers = [];
    return {
        off: _off,
        on: _on,
        join: _join,
        leave: _leave,
        close: _close,
        emit,
    };

    function _off(channel, handler) {
        const idx = _.findIndex(handlers, function (info) {
            return (info.channel == channel && info.handler == handler);
        });
        if (idx != -1) {
            handlers.splice(idx, 1);
            off(channel, handler);
        } else {
            console.log('RealtimeServer off cannot unregister channel' + channel);
        }
    }

    function _on(channel, handler) {
        handlers.push({channel: channel, handler: handler});
        on(channel, handler);
    }

    function _join(room, data) {
        joinedRooms.push(room);
        join(room, data);
    }

    function _leave(room, data) {
        _.without(joinedRooms, room);
        leave(room, data);
    }

    function _close() {
        _.each(joinedRooms, leave);
        _.each(handlers, function (h) {
            off(h.channel, h.handler);
        });
        joinedRooms = null;
        handlers = null;
    }
}
