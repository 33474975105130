const _ = require('lodash');
const template = require('./RangeField.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

const SurfaceAreaField = require('../../form/components/SurfaceAreaField');
const PriceField = require('../../form/components/PriceField');
const NumberField = require('../../form/components/NumberField');

const RANGE_TYPES = {
    'SurfaceAreaField': SurfaceAreaField,
    'PriceField': PriceField,
    'NumberField': NumberField,
};

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'RangeField.',
        }),
    ],
    props: {
        name: {
            type: String,
            required: true,
        },
        componentType: {
            type: String,
            required: true,
            validator: value => Object.keys(RANGE_TYPES).includes(value),
        },
        initialMin: {
            type: Number,
            default: null,
        },
        initialMax: {
            type: Number,
            default: null,
        },
        lowPriorityInput: {
            type: String,
            default: 'max',
        },
    },
    data() {
        return {
            min: this.initialMin,
            max: this.initialMax,
            inputToFocus: {
                type: String,
                default: 'min',
                validator: value => _.includes(['max', 'min'], value),
            },
        };
    },
    computed: {
        currentComponent() {
            return RANGE_TYPES[this.componentType];
        },
        minName() {
            return this.minMaxName('min');
        },
        maxName() {
            return this.minMaxName('max');
        },
    },
    watch: {
        initialMin() {
            this.min = this.initialMin;
        },
        initialMax() {
            this.max = this.initialMax;
        },
    },
    methods: {
        clearMinWhenMismatch() {
            if (this.min && this.max && this.min > this.max) {
                this[this.lowPriorityInput] = null;
            }
        },
        onBlur() {
            this.clearMinWhenMismatch();
            this.$emit('change', {
                name: this.name,
                min: this.min,
                max: this.max,
            });
        },
        onFinish() {
            this.$emit('finish');
        },
        minMaxName(prefix) {
            return prefix + _.upperFirst(this.name);
        },
    },
    template: template(),
};
