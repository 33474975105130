const _ = require('lodash');
const ExtendedSearch = require('./ExtendedSearch');
const SearchFiltersHelper = require('./SearchFiltersHelper');
const ProgrammeHelper = require('./ProgrammeHelper');

module.exports = {
    getSimilarFilters,
};

function getSimilarFilters({realEstateAd, searchCriteria = {}, maxCount, excludeAdsWithSameAccountId}) {
    const author = searchCriteria.author;
    const advancedFilters = SearchFiltersHelper.getAdvancedFilters(searchCriteria);
    const adFilters = getFiltersFromAd(realEstateAd, searchCriteria);
    const baseSimilarFilters = _.extend({}, advancedFilters, adFilters, {
        onTheMarket: [true],
        size: maxCount,
        from: 0,
        excludeAlreadyContacted: true,
        author,
        excludeAdById: realEstateAd.id,
    });
    if (excludeAdsWithSameAccountId) {
        baseSimilarFilters.excludeAdsWithAccountId = getAccountIdToExclude(realEstateAd);
    }
    return ExtendedSearch.extendFilters(baseSimilarFilters);
}

function getAccountIdToExclude(realEstateAd) {
    let excludeAdsWithAccountId = realEstateAd.accountIds && realEstateAd.accountIds.length &&
        realEstateAd.accountIds[0];
    if (excludeAdsWithAccountId == 'nexity-NEXITY-LOT') {
        excludeAdsWithAccountId = 'nexity-NEXITY-PRG';
    }
    return excludeAdsWithAccountId;
}

function getFiltersFromAd(realEstateAd, searchCriteria) {
    const pricesFilter = getPricesFilter(realEstateAd.price, searchCriteria);
    const surfacesAreaFilter = getSurfacesAreaFilter(realEstateAd.surfaceArea, searchCriteria);
    const roomsQuantityFilter = getRoomsQuantityFilter(realEstateAd.roomsQuantity, searchCriteria);
    const propertyType = ProgrammeHelper.isProgrammeOrResidence(realEstateAd) ? null : realEstateAd.propertyType;
    return _.extend({
        propertyType,
        filterType: realEstateAd.adType || realEstateAd.transactionType,
        newProperty: realEstateAd.newProperty,
    }, pricesFilter, surfacesAreaFilter, roomsQuantityFilter);
}

function getPricesFilter(price, searchCriteria) {
    return getValuesFromAdOrSearchCriteria(price, searchCriteria, 'minPrice', 'maxPrice');
}

function getSurfacesAreaFilter(surfaceArea, searchCriteria) {
    return getValuesFromAdOrSearchCriteria(surfaceArea, searchCriteria, 'minArea', 'maxArea');
}

function getRoomsQuantityFilter(roomsQuantity, searchCriteria) {
    return getValuesFromAdOrSearchCriteria(roomsQuantity, searchCriteria, 'minRooms', 'maxRooms');
}

function getValuesFromAdOrSearchCriteria(value, searchCriteria, minKey, maxKey) {
    const filter = {};
    const minValue = _.isArray(value) ? value[0] : value;
    const maxValue = _.isArray(value) ? value[1] : value;
    filter[minKey] = searchCriteria[minKey] ? Math.max(minValue, searchCriteria[minKey]) : minValue;
    filter[maxKey] = searchCriteria[maxKey] ? Math.min(maxValue, searchCriteria[maxKey]) : maxValue;
    return filter;
}
