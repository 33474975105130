const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const ApplicationPage = require('./ApplicationPage');
const UserSearchView = require('../views/UserSearchView');
const Urls = require('../Urls');
const History = require('../utils/History');
const SearchTitleGenerator = require('../../common/SearchTitleGenerator');
const Views = require('../views/Views');

module.exports = class UserDirectoryPage extends ApplicationPage {
    constructor() {
        const configuration = {
            name: 'agences-immobilieres',
            title: 'Agences immobilières',
            resultsPerPage: 48,
            gtmCategory: 'Directory',
            bodyClass: 'userDirectory',
            headerTitle: 'Agences immobilières',
        };
        super(configuration);
        this.configuration = configuration;
        this.userSearchView = new UserSearchView(this.configuration);
    }

    open(options) {
        this.options = _.extend(options, this.configuration);
        this._onSearchHandler = _.bind(this._onSearch, this);
        this.userSearchView.on('search', this._onSearchHandler);
        super.open(this.options);
        this.updatePageHeaderTitle();
    }

    close() {
        this.userSearchView.removeListener('search', this._onSearchHandler);
        super.close();
    }

    _onSearch(searchCriteria, {pushToHistory = true} = {}) {
        this.options.searchCriteria = searchCriteria;
        if (pushToHistory) {
            History.pushState(History.getState(), this.getTitle(), this.getUrl());
        } else {
            History.replaceState(History.getState(), this.getTitle(), this.getUrl());
        }
        this.updateMetaTags();
        this.updatePageHeaderTitle();
    }

    getMiddleViews() {
        return this.userSearchView;
    }

    getUrlPattern() {
        return /^\/(agences-immobilieres|utilisateurs)/;
    }

    getUrl() {
        return this.userSearchView.getUrlForPage(this.userSearchView.currentPage);
    }

    parseUrl(url) {
        const searchCriteria = Urls.userSearch.parseUrl(url);
        if (_.includes(['premium', 'contractType'], searchCriteria['sort-type'])) { // backward compatibility
            searchCriteria['sort-type'] = 'contractRank';
        }
        return {
            searchCriteria,
        };
    }

    getTitle() {
        return this.getTranslationWithLocation('userDirectory.pageTitle');
    }

    getTranslationWithLocation(translationKey) {
        const location = this.formatCurrentLocation();
        return translate(translationKey, {
            location,
            context: location ? 'withLocation' : '',
        });
    }

    getMetaDescription() {
        return this.getTranslationWithLocation('metaDescriptions.userDirectory');
    }

    updatePageHeaderTitle() {
        Views.header.update({
            headerTitle: this.getTranslationWithLocation('userDirectory.title'),
        });
    }

    formatCurrentLocation() {
        const {searchCriteria} = this.options;
        return SearchTitleGenerator.formatLocationsNames(searchCriteria.locations, {
            formatter: 'getInTheLocationText',
            showLocationCode: false,
            allowMultipleLocations: true,
        });
    }
};
