const $ = require('jquery');
const _ = require('lodash');
const Raven = require('../lib/raven'); // must be in the bundle to avoid thinking the error comes from outside,
const Analytics = require('./Analytics');

// and npm package works only server-side
const unexpectedErrorTemplate = require('../templates/unexpectedError.jade');

const ERR_SERVER_DATA = 'ERR_SERVER_DATA';
const ERR_CONNECTION_REFUSED = 'ERR_CONNECTION_REFUSED';

const ErrorContent = require('./ErrorsContent');

module.exports = _.extend({
    setErrorContainer,
    showUnexpectedError,
    handleRuntimeScriptErrors,
    ignoreNextOnbeforeunload,
    sendErrorToAnalytics,
    showError,
    handleJsException,
    ERR_SERVER_DATA,
    ERR_CONNECTION_REFUSED,
}, ErrorContent);

let previousErrorHandler; // error handler installed by error tools (Errorception, Sentry.io...)
let _ignoreNextOnbeforeunload = false;
let alreadyInErrorPage = false;
let $container = $('body');
let raven;

const ERROR_IGNORE_LIST = {
    ignoreErrors: [
        '_avast_',
        // iOS chrome password filler
        // https://github.com/getsentry/sentry/issues/5267
        // https://bugs.chromium.org/p/chromium/issues/detail?id=709132
        'Blocked a frame with origin',
        // eshopcomp : https://sentry.io/bienici/kimono-prod/issues/440831939/
        //TODO notify user and redirect to removal page https://malwaretips.com/blogs/remove-app-eshopcomp-com/
        '/release/Shared/App/SharedApp.js',
    ],
    ignoreUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // GTM
        /googletagmanager\.com\/gtm\.js/,
    ],
};

function getErrorCategory(error) {
    let category = ErrorContent.UNKNOWN;
    if (error) {
        const {statusCode, code, status} = error;
        const httpStatusCode = statusCode || code;
        if (httpStatusCode >= 400 && httpStatusCode <= 599) {
            if (httpStatusCode < 500) {
                category = ErrorContent.HTTP_CLIENT_ERROR;
            } else {
                category = ErrorContent.HTTP_SERVER_DOWN;
            }
        } else {
            switch (status) {
                case 'timeout':
                    category = ErrorContent.HTTP_TIMEOUT;
                    break;
                case ERR_CONNECTION_REFUSED:
                    category = ErrorContent.HTTP_CLOSED;
                    break;
                case ERR_SERVER_DATA:
                    category = ErrorContent.HTTP_SERVER_ERROR;
                    break;
            }
        }
    }
    return category;
}

function sendErrorToAnalytics(error) {
    const category = getErrorCategory(error);
    const errorStr = getStringFromError(error);
    Analytics.sendError(category, errorStr);
}

function showError(error) {
    const category = getErrorCategory(error);
    showErrorPage(category, error);
}

function showUnexpectedError(details) {
    showErrorPage(module.exports.UNKNOWN, details);
}

function showJavaScriptError(messageOrEvent, source/*, lineno, colno, error*/) {
    if (source) {
        showErrorPage(module.exports.JAVASCRIPT_EXCEPTION, messageOrEvent.reason || messageOrEvent);
    } else if (!(messageOrEvent instanceof $.Event)) { // jQuery error event
        console.error('An error occurred outside of handled codebase, ignoring.', messageOrEvent);
    }
}

function setErrorContainer(node) {
    $container = $(node);
}

function getStringFromError(error) {
    if (_.isError(error)) {
        return error.toString();
    } else {
        return JSON.stringify(error);
    }
}

function showErrorPage(categoryConfig, error) {
    if (error instanceof $.Event) { // jQuery error event
        return;
    }
    if (alreadyInErrorPage) {
        console.error('Unexpected error on error page', error);
        return;
    }
    alreadyInErrorPage = true;
    if (raven) {
        raven.uninstall();
    }

    let errorStr;
    if (error) {
        errorStr = getStringFromError(error);
        console.trace();
        console.error('Unexpected error ' + errorStr);
        if (window.allLogs && arguments[4]) {
            window.allLogs.push({text: error, stack: arguments[4].stack});
        }
    }
    const $template = unexpectedErrorTemplate(_.extend({
        error,
        logs: window.allLogs,
    }, categoryConfig));
    const elementsToKeep = $container.find('link');
    elementsToKeep.detach();
    $container.empty().append(elementsToKeep).append($template);
    $container.find('.btn-logsToShow').on('click', function () {
        $('body').find('.block-logsToShow__container').toggle();
    });
    $container.find('.block-logsToShow__item').on('click', function (event) {
        $(event.target).find('.block-logsToShow__stack').toggle();
    });
    Analytics.sendError(categoryConfig, errorStr);
}

function testIgnorePatternOn(ignoredPattern, tested) {
    if (_.isString(ignoredPattern)) {
        return _.includes(tested, ignoredPattern);
    } else {
        return ignoredPattern.test(tested);
    }
}

function errorHandler(messageOrEvent, source/*, lineno, colno, error*/) {
    const ignoredError =
        _.some(ERROR_IGNORE_LIST.ignoreErrors, ignoredPattern => testIgnorePatternOn(ignoredPattern, messageOrEvent))
        || _.some(ERROR_IGNORE_LIST.ignoreUrls, ignoredPattern => testIgnorePatternOn(ignoredPattern, source));
    if (!ignoredError) {
        if (previousErrorHandler && !alreadyInErrorPage) {
            previousErrorHandler.apply(this, arguments);
        }
        showJavaScriptError(messageOrEvent, source);
    }
}

function handleRuntimeScriptErrors(options) {
    previousErrorHandler = window.onerror;
    window.onerror = errorHandler;
    addEventListener('unhandledrejection', event => {
        errorHandler(event, getErrorSource(event.reason));
        event.preventDefault();
    });
    window.onbeforeunload = handleOnbeforeunload;
    if (!options.devToolsFriendlySentryMode) { // see https://github.com/getsentry/raven-js/issues/920
        const sentryDsn = $('meta[name="sentry-dsn"]').attr('content');
        if (sentryDsn) {
            raven = Raven.config(sentryDsn, ERROR_IGNORE_LIST).install();
        }
    }
}

function getErrorSource(error) {
    const {fileName, stack} = error;
    return fileName // Firefox only
        || (stack && parseSourceFromStack(stack)); // other browsers
}

function parseSourceFromStack(stack) {
    const matches = stack.match(/(https?:\/\/.*)\n/);
    return matches && matches[1];
}

function ignoreNextOnbeforeunload() {
    _ignoreNextOnbeforeunload = true;
}

function handleOnbeforeunload() {
    // avoid cleanup on mailto: click
    // see http://stackoverflow.com/questions/9740510/mailto-link-in-chrome-is-triggering-window-onbeforeunload-can-i-prevent-this
    if (_ignoreNextOnbeforeunload) {
        _ignoreNextOnbeforeunload = false;
    }
}

function handleJsException(err) {
    if (raven) {
        raven.captureException(err);
    }
    showError(err);
}
