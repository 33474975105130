const _ = require('lodash');
const DEFAULT_OPTIONS = require('./DefaultOptions');

module.exports = {
    OPTIONS: _.defaults({
        commercialAdsEnabled: false,
        financialPartnerCreditSimulationEnabled: false,
    }, DEFAULT_OPTIONS),
    DOMAIN: 'boursorama.com',
};
