const _ = require('lodash');
const async = require('async');
const {i18n: {translate}} = require('fack');

const Account = require('../authentication/Account');
const Urls = require('../Urls');
const Views = require('../views/Views');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const SideMapPage = require('./SideMapPage');
const EventPack = require('../utils/EventPack');
const History = require('../utils/History');
const ContactFormSender = require('../utils/ContactFormSender');
const AsyncHelper = require('../utils/AsyncHelper');
const RealEstateAdLoader = require('../RealEstateAdLoader');
const Pages = require('./Pages');
const PagesFactories = require('./PagesFactories');
const Error404 = require('../Error404');
const SpinnerHelper = require('../utils/SpinnerHelper');
const SearchDataLoader = require('../search/SearchDataLoader');
const GoogleTagManager = require('../stats/GoogleTagManager');
const FavoritesManager = require('../favorites/FavoritesManager');
const DefaultConfiguration = require('../../common/DefaultConfiguration');

const SideOfMapView = require('../views/ContainerView');
const SuggestionView = require('../views/SuggestionView');
const BackButtonNavView = require('../views/BackButtonNavView');
const SideMapHelperView = require('../views/SideMapHelperView');
const $ = require('jquery');

const sideOfMapTemplate = require('../templates/views/sideOfMapView.jade');

const SimilarAdsHelper = require('../../common/SimilarAdsHelper');
const ServerConfig = require('../ServerConfig');

class AdSuggestionPage extends SideMapPage {
    constructor(configuration) {
        super(_.extend({
            name: 'adSuggestionPage',
            title: "Suggestion d'annonces",
            gtmCategory: 'DetailedSheets',
        }, configuration));
        configuration = _.defaults(configuration, {
            showMap: true,
            saveSearchAllowed: true,
        });
        this._eventPack = new EventPack();
        this._eventPackWhileMapShown = new EventPack();
        this.sideOfMapView = new SideOfMapView({template: sideOfMapTemplate});
        this.suggestionView = new SuggestionView();
        this.backButtonNavView = new BackButtonNavView();
        this.sideOfMapView.setSubViews([
            {view: this.backButtonNavView, className: 'header'},
            {view: this.suggestionView, className: 'sideOfMapContent'},
        ]);
        this.asyncHelper = new AsyncHelper();
        this.configuration = configuration;
    }

    getUrlPattern() {
        return '/suggestion/{id}';
    }

    _canShowMap() {
        if (this.configuration.isMobile) {
            return this.configuration.showMap;
        }
        return (this.mapController.doesWindowSupportFullScreen() && this.configuration.showMap);
    }

    open(options) {
        options = _.defaults(options, {
            showContactFeedback: true,
        });
        this.realEstateAdId = options.realEstateAdId;
        this.realEstateAd = options.realEstateAd;
        this.similarAds = options.similarAds;
        this.closePageUrl = options.closePageUrl;
        this.camera = options.camera;
        this.sender = options.sender;
        _.extend(options, this.configuration, {
            updateUrl: _.bind(this._updateUrl, this),
            backURL: this._getSearchDefaultedClosePageUrl(),
            backExtraTitle: options.headerTitle,
            backToDetailedSheetUrl: this._getDetailedSheetUrl(this.realEstateAd, this._getClosePageUrl()),
        });
        this._initSearchAndMapControllers(options);
        const showMap = options.showMap = this._canShowMap();
        this._eventPack.on($(window), 'resize', _.bind(this._handleWindowResize, this));
        super.open(options);
        this.mapController.setMapModeAvailable(showMap);
        this.mapController.handleToggleMap({showMap});
        if (showMap) {
            this._initMap();
        }
        this._eventPack.on(this.mapController, 'toggledMap', _.bind(this._onMapToggled, this));
        this._eventPack.on(this.backButtonNavView, 'goBack', _.bind(this._reopenPage, this));
        this._eventPack.on(this.suggestionView, {
            backToAd: _.bind(this._backToAd, this),
            showAdSuggestion: _.bind(this._openAd, this),
            sendContact: _.bind(this._sendContacts, this),
        });
        this.openOptions = options;
        this._setOpenPageOptions(options);
        const canOpenSuggestionsPage = this._hasEnoughContactInformation();
        GoogleTagManager.sendEvent('adSuggestions', {
            forAd: this.realEstateAdId,
            similarAdsCount: this.similarAds.length,
            canOpenSuggestionsPage,
        });
        if (!canOpenSuggestionsPage) {
            console.log('not enough contact data, redirecting to ad page');
            this._backToAd();
        }
    }

    _reopenPage(options) {
        const previousRealEstateAd = this.realEstateAd || (options && options.realEstateAd);
        const searchOptions = {previousRealEstateAd};
        if (this.suggestionView) {
            this.suggestionView.setCloseAnimation('anim-close');
        }
        if (this.backButtonNavView) {
            this.backButtonNavView.setCloseAnimation('anim-close');
        }
        if (this.searchController) {
            super._reopenPage(searchOptions);
        } else {
            // Direct page access: the controllers are not initialized
            this._initSearchAndMapControllers(options);
            this._openPage(PagesFactories.searchPageFactory, searchOptions);
        }
    }

    _initSearchAndMapControllers(options = {}) {
        let mapController = options.mapController;
        if (!mapController) {
            mapController = options.mapController = new SideMapHelperView(options);
        }
        this.searchController = options.searchController;
        this.mapController = mapController;
    }

    _initMarkers() {
        const eventPackWhileMapShown = this._eventPackWhileMapShown;
        eventPackWhileMapShown.on(this.mapController, 'adClick', ({realEstateAd}) => {
            this._openAd(realEstateAd.id);
        });
        eventPackWhileMapShown.on(this.suggestionView, {
            mouseoverAd: adId => {
                const realEstateAd = this._findAd(adId);
                this.mapController.hoverAd(realEstateAd);
            },
            mouseoutAd: adId => {
                const realEstateAd = this._findAd(adId);
                this.mapController.unHoverAd(realEstateAd);
            },
        });
        this.mapController.showListMarkers(this.similarAds);
    }

    _findAd(adId) {
        if (this.realEstateAd.id == adId) {
            return this.realEstateAd;
        }
        return _.find(this.similarAds, similarAd => similarAd.id == adId);
    }

    _openAd(realEstateAdId) {
        const backButtonNavView = this.backButtonNavView;
        if (backButtonNavView) {
            backButtonNavView.setCloseAnimation('anim-close-left');
        }
        const openOptions = this.openOptions;
        this._openPage(Pages.detailedSheet, {
            incompleteRealEstateAd: _.find(this.similarAds, {id: realEstateAdId}),
            realEstateAdId,
            context: {
                fromSuggestion: this.realEstateAdId,
            },
            animations: {
                openAnimation: 'anim-open-right',
                closeAnimation: 'anim-close-left',
            },
            closePageUrl: this.getUrl(),
            closeAdSuggestionPageOptions: { //Parameters used to open next AdSuggestionPage to prevent stacking url in q
                closePageUrl: this._getClosePageUrl(),
                reopen: openOptions.reopen,
                reopenOptions: openOptions.reopenOptions,
                headerTitle: openOptions.headerTitle || '',
            },
        });
    }

    _backToAd() {
        const {reopen, reopenOptions, headerTitle = null} = this.openOptions;
        this._openPage(Pages.detailedSheet, {
            incompleteRealEstateAd: this.realEstateAd,
            realEstateAdId: this.realEstateAdId,
            headerTitle,
            animations: {
                openAnimation: 'anim-open-left',
            },
            reopen,
            reopenOptions,
            closePageUrl: this._getSearchDefaultedClosePageUrl(),
        });
    }

    _getSearchDefaultedClosePageUrl() {
        return this._getClosePageUrl() || Urls.search.makeUrl(DefaultConfiguration.search);
    }

    close(...args) {
        this._eventPack.removeAllListeners();
        this._clearMap();
        this.asyncHelper.cancelAll();
        super.close(...args);
    }

    _getClosePageUrl() {
        return this.closePageUrl;
    }

    _registerMapEvent() {
        this._eventPackWhileMapShown.on(this.mapController, {
            mapExpanded: _.bind(this._updateShowMap, this),
            cameraChanged: eventInfo => {
                if (eventInfo.isHuman) {
                    this.camera = SideMapViewSingleton.get().getCameraFromMap();
                    this._updateUrl({pushToHistory: false});
                }
            },
        });
    }

    getViews({showMap}) {
        const views = [Views.header];
        if (showMap) {
            views.push(SideMapViewSingleton.get());
        }
        return views.concat([this.mapController, this.sideOfMapView]);
    }

    parseUrl(url, realEstateAdId) {
        return Urls.suggestion.parseUrl(url, realEstateAdId);
    }

    getUrl() {
        return Urls.suggestion.makeUrl(this.realEstateAdId, this._getClosePageUrl(), this.camera);
    }

    _initCamera(options) {
        if (this.camera) {
            SideMapViewSingleton.get().setCamera(this.camera, options);
        } else {
            SideMapViewSingleton.get().fitCameraToRealEstateAds(this.similarAds, options);
        }
        this._teleportWhenShowMap = false;
    }

    _loadData(options, callback) {
        this._teleportWhenShowMap = !options.shouldPushUrl;
        const {search, realEstateAd, realEstateAdId, similarAds} = options;
        const closePageUrl = Urls.suggestion.makeUrl(options.realEstateAdId, options.closePageUrl, options.camera);
        async.auto({
            account: cb => {
                Account.getAccountAndCreateGuestIfNeeded(cb);
            },
            loadSearchData: cb => {
                SearchDataLoader.loadSearchData(options, cb);
            },
            loadMap: cb => {
                if (this.configuration.showMap) {
                    SideMapViewSingleton.get().loadData(options, cb);
                } else {
                    _.defer(cb);
                }
            },
            realEstateAd: [
                'account', cb => {
                    if (realEstateAd) {
                        options.realEstateAd = this._enrichedAd({
                            realEstateAd,
                            search,
                            closePageUrl,
                        });
                        _.defer(cb);
                    } else {
                        RealEstateAdLoader.loadRealEstateAd({
                            realEstateAdId,
                            disableErrorPage: true,
                            // filters:, //TODO
                        }, (err, realEstateAd) => {
                            options.realEstateAd = realEstateAd ? this._enrichedAd({
                                realEstateAd,
                                search,
                                closePageUrl,
                            }) : null;
                            cb(err);
                        });
                    }
                },
            ],
            similarAds: [
                'account', cb => {
                    // Depending on RealEstateAd request and similar ads request order,
                    // Don't know which realEstateAd to use: options.realEstateAd or realEstateAd.
                    // To be fixed later : should wait for RealEstateAd async request to use options.realEstateAd
                    if (similarAds) {
                        options.similarAds = this._processSimilarAds({
                            similarAds,
                            baseAd: options.realEstateAd,
                            search,
                            closePageUrl,
                        });
                        _.defer(cb);
                    } else {
                        RealEstateAdLoader.loadSimilarAds({
                            realEstateAdId,
                            searchCriteria: search,
                            disableErrorPage: true,
                        }, (err, similarAds) => {
                            options.similarAds = this._processSimilarAds({
                                similarAds,
                                baseAd: options.realEstateAd,
                                search,
                                closePageUrl,
                            });
                            options.showContactFeedback = false;
                            cb(err);
                        });
                    }
                },
            ],
        }, err => {
            this._onLoadData(err, options, callback);
        });
    }

    _onLoadData(err, options, cb) {
        if (err && (err.code == 404 || err.status == 404)) {
            cb(new Error404(err.message));
            return;
        }
        if (!options.similarAds.length) {
            this._reopenPage(options);
            return;
        }
        cb(err, options);
    }

    _processSimilarAds({similarAds, baseAd, search, closePageUrl}) {
        let context = null;
        if (baseAd && baseAd.id) {
            context = {
                fromSuggestion: baseAd.id,
            };
        }
        return _.map(similarAds, realEstateAd => this._enrichedAd({
            realEstateAd,
            search,
            context,
            baseAd,
            closePageUrl,
        }));
    }

    _updateUrl(options) {
        options = _.defaults(options || {}, {
            pushToHistory: true,
        });
        const newUrl = this.getUrl();
        const state = {
            realEstateAdId: this.realEstateAdId,
            q: this._getClosePageUrl(),
            camera: this.camera,
        };
        if (options.pushToHistory) {
            History.pushState(state, this.title, newUrl);
        } else {
            History.replaceState(state, this.title, newUrl);
        }
    }

    _sendContacts(realEstateAdIds, $button) {
        GoogleTagManager.sendEvent('suggestionAdsToContact', {
            forAd: this.realEstateAdId,
            similarAdsCount: this.similarAds && this.similarAds.length,
            similarAdsToContactCount: realEstateAdIds && realEstateAdIds.length,
        });
        SpinnerHelper.startButtonSpinner($button);
        ContactFormSender.sendAdContact({
            contact: {
                sender: this._getSender(),
            },
            realEstateAdIds,
        }, (err, contactResult) => {
            SpinnerHelper.stopButtonSpinner($button);
            if (err) {
                Views.volatileFeedback.showError('adSuggestions.error');
                console.log(err);
            } else {
                if (contactResult.track !== false) {
                    _.each(realEstateAdIds, (realEstateAdId) => {
                        GoogleTagManager.sendContactEvent('contactFormSent', {
                            sentFrom: 'SuggestionAds',
                            realEstateAd: this._findAd(realEstateAdId),
                            realEstateAdId,
                            fromAd: this.realEstateAdId,
                        });
                        FavoritesManager.toggle(realEstateAdId, true, {$start: $button});
                    });
                }
                Views.volatileFeedback.showSuccess('adSuggestions.success');
                this._backToAd();
            }
        });
    }

    _getSender() {
        let sender = {};
        if (this.sender) {
            sender = this.sender;
        } else {
            const account = Account.getAuthenticatedAccount();
            if (account) {
                sender.email = account.email;
                if (account.contact) {
                    sender.firstName = account.contact.firstName;
                    sender.lastName = account.contact.lastName;
                    sender.phone = account.contact.phone;
                }
            }
        }
        return sender;
    }

    _hasEnoughContactInformation() {
        const sender = this._getSender();
        return Boolean(sender.email && sender.lastName && sender.phone);
    }

    _enrichedAd({realEstateAd, search, closePageUrl, context, baseAd}) {
        const enrichedAd = _.extend({
            detailedSheetUrl: this._getDetailedSheetUrl(realEstateAd, closePageUrl, context),
        }, realEstateAd);
        if (baseAd && (realEstateAd.propertyType == 'programme' || realEstateAd.propertyType == 'residence')) {
            enrichedAd.relatedAdsFilters = this._getRelatedAdsFilters(search, baseAd);
        }
        return enrichedAd;
    }

    _getDetailedSheetUrl(realEstateAd, closePageUrl, context) {
        return Urls.detailedSheet.makeUrl(realEstateAd, closePageUrl, null, null, context);
    }

    _getRelatedAdsFilters(searchCriteria, baseAd) {
        const maxCount = ServerConfig.config.similarAdsMaxCount;
        return SimilarAdsHelper.getSimilarFilters({
            realEstateAd: baseAd,
            searchCriteria,
            maxCount,
            excludeAdsWithSameAccountId: true,
        });
    }

    _initMap() {
        if (SideMapViewSingleton.get().isShown() && !this._mapInitialiazed) {
            this._initCamera({teleport: this._teleportWhenShowMap});
            this._registerMapEvent();
            this._initMarkers();
            this._mapInitialiazed = true;
        }
    }

    _clearMap() {
        if (this._mapInitialiazed) {
            this._eventPackWhileMapShown.removeAllListeners();
            this._mapInitialiazed = false;
        }
    }

    _handleWindowResize() {
        const width = $(window).width();
        if (width != this._lastWidth) {
            this._updateShowMap();
            this._lastWidth = width;
        }
    }

    _updateShowMap() {
        if (!SideMapViewSingleton.get().isMapExpanded()) {
            this._teleportWhenShowMap = true;
            this.mapController.handleToggleMap({
                showMap: this._canShowMap(),
            });
        }
    }

    _onMapToggled(visible) {
        if (visible) {
            this._initMap();
        } else {
            this._clearMap();
        }
    }

    _getBackTitle() {
        return translate('adSuggestions.backLabelExtraContent');
    }
}

module.exports = AdSuggestionPage;
