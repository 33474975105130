const template = require('./RatingStarsIcon.jade');
const commonProps = require('./mixins/commonProps');

const {
    EMPTY_STAR_COLOR_FILL_PERCENTAGE,
    FULL_STAR_COLOR_FILL_PERCENTAGE,
} = require('./constants');
const STAR_CHARACTER_SIDE_BEARING_PERCENTAGE = 10;
const COMPONENT_CLASS_NAME = 'rating-stars-icon';

// @vue/component
module.exports = {
    mixins: [
        commonProps,
    ],
    props: {
        colorFillPercentage: {
            type: Number,
            required: true,
            validator: colorFillPercentage =>
                colorFillPercentage >= EMPTY_STAR_COLOR_FILL_PERCENTAGE && colorFillPercentage <= FULL_STAR_COLOR_FILL_PERCENTAGE,
        },
    },
    computed: {
        isEmpty() {
            return this.colorFillPercentage === EMPTY_STAR_COLOR_FILL_PERCENTAGE;
        },
        isFull() {
            return this.colorFillPercentage === FULL_STAR_COLOR_FILL_PERCENTAGE;
        },
        isPartial() {
            return !this.isEmpty && !this.isFull;
        },
        maskStyle() {
            return {
                width: this.isPartial && `${this.maskWidth}%`,
            };
        },
        iconClass() {
            return {
                [`${COMPONENT_CLASS_NAME}__filled-icon`]: this.isFull,
            };
        },
        maskWidth() {
            const range = 100 - (2 * STAR_CHARACTER_SIDE_BEARING_PERCENTAGE);
            return (this.colorFillPercentage / 100 * range) + STAR_CHARACTER_SIDE_BEARING_PERCENTAGE;
        },
        ratingStarsIconClass() {
            const {theme} = this;
            return {
                [`${COMPONENT_CLASS_NAME}--${theme}`]: theme,
            };
        },
    },
    template: template(),
};
