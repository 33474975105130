const template = require('../templates/AdTypesSelectorAccountStatsView.jade');
const BaseAccountStatsSelectorView = require('./BaseAccountStatsSelectorView');

module.exports = class AdTypesSelectorAccountStatsView extends BaseAccountStatsSelectorView {
    constructor(options) {
        super(options);
        this.template = template;
    }

    _emitChanges() {
        this.emit('adTypeChanges');
    }

    getValue() {
        let adTypeValue = super.getValue();
        if (adTypeValue === 'all') { //it could be better to return all stats from the server when we ask instead of null
            adTypeValue = null;
        }
        return adTypeValue;
    }
};
