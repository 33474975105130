const BrowserDetect = require('browser-detect');
const DeviceGraphicsQualityDetect = require('./utils/DeviceGraphicsQualityDetect');
const querystring = require('querystring');

const isMobile = BrowserDetect.isMobile();
const isIOS = BrowserDetect.isIOS();
const isTablet = BrowserDetect.isTablet();
const tableImage = {
    standard: {
        slideshow: {
            width: 600,
            height: 370,
            fit: 'cover',
        },
        slideshowFullscreen: {
            width: 2000,
            height: 2000,
        },
        searchResults: {
            width: 300,
            height: 180,
            fit: 'cover',
        },
        tooltipDetails: {
            width: 100,
            height: 100,
            fit: 'cover',
        },
    },
    mobile: {
        slideshow: {
            width: 600,
            height: 370,
            fit: 'cover',
        },
        slideshowFullscreen: {
            width: 2000,
            height: 2000,
        },
        searchResults: {
            width: 600,
            height: 370,
            fit: 'cover',
        },
        tooltipDetails: {
            width: 100,
            height: 100,
            fit: 'cover',
        },
    },
    smallMobile: {
        slideshow: {
            width: 600,
            height: 370,
            fit: 'cover',
        },
        slideshowFullscreen: {
            width: 600,
            height: 300,
        },
        searchResults: {
            width: 600,
            height: 370,
            fit: 'cover',
        },
        tooltipDetails: {
            width: 100,
            height: 100,
            fit: 'cover',
        },
    },
};

function getTable(table) {
    if (isMobile || isTablet) {
        if (isIOS && DeviceGraphicsQualityDetect.getIOSQuality() <= DeviceGraphicsQualityDetect.LowQuality) {
            return tableImage.smallMobile[table];
        }
        return tableImage.mobile[table];
    }
    return tableImage.standard[table];
}

module.exports = {
    getTable,
    photoUrlSizeQueryString,
};

function photoUrlSizeQueryString(table) {
    const config = getTable(table);
    return querystring.stringify(config);
}
