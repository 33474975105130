const _ = require('lodash');

const UrlFormatter = require('../../../common/UrlFormatter');
const {getSearchOptionNameFromQueryName} = require('../../../common/SearchUrl');
const template = require('./SearchSeoLinks.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {search} = require('../../Urls');
const CityLocationItem = require('../../locationItems/CityLocationItem');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PropertyTypeField.',
            keys: [
                'house',
                'flat',
            ],
        }),
    ],
    props: {
        seoLinksCriteria: {
            type: Object,
            required: true,
        },
    },
    computed: {
        links() {
            const {searchCriteria, criteriaToAdd} = this.seoLinksCriteria;
            const {
                transactionType,
                propertyType,
                city,
                postalCode,
                locationType,
            } = searchCriteria;

            return _.map(criteriaToAdd, (criteria) => {
                const searchCriteria = {
                    filterType: [transactionType],
                    propertyType,
                    locations: [
                        new CityLocationItem({
                            item: {
                                name: city,
                                postalCodes: [postalCode],
                                type: locationType,
                            },
                        }),
                    ],
                    [criteria]: true,
                };
                const seoLink = search.makeUrl(searchCriteria);
                const filter = getSearchOptionNameFromQueryName(criteria);
                return {
                    content: `${this.transactionType} ${this.propertyType} ${filter} ${city}`,
                    href: seoLink,
                };
            });
        },
        isSeoLinksCriteriaEmpty() {
            return _.isEmpty(this.seoLinksCriteria);
        },
        transactionType() {
            const {searchCriteria: {transactionType}} = this.seoLinksCriteria;
            return _.capitalize(UrlFormatter.filterTypeToUrl(transactionType));
        },
        propertyType() {
            const {searchCriteria: {propertyType}} = this.seoLinksCriteria;
            return this.t(propertyType) || '';
        },
        title() {
            return `${this.transactionType} ${this.propertyType}`;
        },
    },
    template: template(),
};
