module.exports = {
    isArrondissementPostalCode,
    getArrondissementName,
    ordinalNumber,
};

const CITY_WITH_ARRONDISSEMENT_PER_POSTAL_CODE = {
    13: 'Marseille',
    69: 'Lyon',
    75: 'Paris',
};

function isArrondissementPostalCode(postalCode) {
    return /^(130[0-1]|6900|75)/.test(postalCode)
        && !/^(13|69|75)000/.test(postalCode);
}

function getArrondissementName(postalCode) {
    const departmentNumber = postalCode.substr(0, 2);
    const arrondissementNumber = +postalCode.substr(3, 2);
    return CITY_WITH_ARRONDISSEMENT_PER_POSTAL_CODE[departmentNumber] + ' ' + ordinalNumber(arrondissementNumber);
}

function ordinalNumber(num) {
    if (num == 1) {
        return '1er';
    } else {
        return num + 'e';
    }
}
