const moment = require('moment');
const _ = require('lodash');

const REDIRECT_MIN_DURATION_IN_MONTHS = 3;

module.exports = function (realEstateAd) {
    const onTheMarket = _.get(realEstateAd, 'onTheMarket', _.get(realEstateAd, 'status.onTheMarket'));
    if (!onTheMarket) {
        if (!realEstateAd.closingDate) { //old imported ads and residence lot have no closingDate
            return !realEstateAd.residenceReference;
        } else {
            return moment().diff(moment(realEstateAd.closingDate), 'months', true) >= REDIRECT_MIN_DURATION_IN_MONTHS;
        }
    } else {
        return false;
    }
};
