const _ = require('lodash');
const uuid = require('uuid/v1');

module.exports = {
    loadScript,
};

const defaultOptions = {
    type: 'text/javascript',
    async: true,
};

const MAX_RETRIES = 5;
const RETRY_DELAY_IN_MS = 500;

function loadScript(src, options) {
    options = _.defaults(options || {}, defaultOptions);
    const scriptId = uuid();
    let ready = false;
    let retries = 0;
    let startTime = Date.now();
    let script = prepareScriptTag();
    injectScript(script);

    function onLoad() {
        if (!ready && (!script.readyState || script.readyState == 'complete')) {
            ready = true;
            if (options.successCallback) {
                options.successCallback();
            }
        }
    }

    function onError() {
        if (retries < MAX_RETRIES) {
            setTimeout(() => {
                retries++;
                startTime = Date.now();
                script = prepareScriptTag();
                replaceScript(script);
            }, RETRY_DELAY_IN_MS);
            console.warn(`Could not load ${src} after ${Date.now() - startTime}ms, retrying in ${RETRY_DELAY_IN_MS}ms`);
        } else {
            const error = new Error(`Could not load ${src} after ${retries} retries`);
            if (options.errorCallback) {
                options.errorCallback(error);
            } else {
                console.error(error);
            }
        }
    }

    function prepareScriptTag() {
        const script = document.createElement('script');
        script.type = options.type;
        script.async = options.async;
        if (options.crossoriginAllowed) {
            script.setAttribute('crossorigin', 'anonymous');
        }
        _.each(options.attributes, function (value, key) {
            script.setAttribute(key, value);
        });

        script.onload = script.onreadystatechange = onLoad;
        script.onerror = onError;
        script.id = scriptId;
        script.src = src;
        return script;
    }
}

function injectScript(script) {
    const firstScript = document.getElementsByTagName('script')[0];
    if (firstScript) {
        firstScript.parentNode.insertBefore(script, firstScript);
    } else { //sometimes (chrome on iOS) there's no firstScript
        document.body.appendChild(script);
    }
}

function replaceScript(script) {
    const oldChild = document.getElementById(script.id);
    oldChild.parentNode.replaceChild(script, oldChild); // cannot use replaceWith due to lack of internet explorer support
}
