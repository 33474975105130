const MapServers = require('./utils/MapServers');
const ServerConfig = require('./ServerConfig');
const _ = require('lodash');

module.exports = {
    querySuggestions,
    getHierarchyLocation,
    getAllDepartments,
    fetchPostalCodeGeometry,
};

const SUGGESTIONS_TYPES = [
    'city',
    'delegated-city',
];

function querySuggestions(text, callback) {
    return callSearchPlaces('/suggest', {
        data: {q: text, type: _.join(SUGGESTIONS_TYPES, ',')},
        serverErrorMessage: 'querySuggestions',
    }, (err, cities) => {
        if (err) {
            callback(err);
        } else {
            const suggestions = _.map(cities, city => {
                return {
                    value: city.name,
                    city,
                };
            });
            callback(null, suggestions);
        }
    });
}

function getHierarchyLocation(data, callback) {
    return callSearchPlaces('/containingAdminLimits.json', {
        data,
        disableErrorPage: true,
        serverErrorMessage: 'loadHierarchy',
    }, callback);
}

function getAllDepartments(callback) {
    return callSearchPlaces('/departments.json', {
        disableErrorPage: true,
        serverErrorMessage: 'getAllDepartments',
    }, callback);
}

function fetchPostalCodeGeometry(postalCode, callback) {
    return callSearchPlaces('/geometryByPostalCode', {
        data: {postalCode},
        disableErrorPage: true,
        serverErrorMessage: 'fetchPostalCodeGeometry',
    }, callback);
}

function callSearchPlaces(path, options, callback) {
    return MapServers.request({
        url: ServerConfig.config.searchPlacesUrl + path,
        ...options,
        callback,
    });
}
