const _ = require('lodash');

const template = require('./templates/detailedSheet/detailedSheetsNavigation.jade');
const View = require('./views/View');
const Urls = require('./Urls');
const RealEstateAdTitleGenerator = require('../common/RealEstateAdTitleGenerator');
const NumberFormatter = require('../common/NumberFormatter');
const {isLot} = require('../common/ProgrammeHelper');

/**
 * Interface to navigate among several detailed sheets
 * @constructor
 */
module.exports = class DetailedSheetsNavView extends View {
    constructor(configuration = {}) {
        super(_.defaults({
            template,
        }, configuration));
        this.configuration = _.defaults(configuration, {
            animations: {
                openAnimation: 'anim-open',
                closeAnimation: 'anim-close',
            },
        });
    }

    _hide(options, cb) {
        if (this.$element) {
            const $detailedSheetToggleMap = this.$element.find('#detailedSheetToggleMap');
            $detailedSheetToggleMap.off('click', this._toggleMapClickHandler);
        }
        super.hide(options, cb);
    }

    hide(options, cb) {
        this.playCloseAnimation(_.bind(this._hide, this, options, cb));
    }

    show(options) {
        this.options = _.extend(options, this.configuration);
        if (options.$container) {
            this.$container = options.$container;
        }
        this.closePageUrl = options.closePageUrl;
        let backAd;
        if (options.realEstateAd && isLot(options.realEstateAd)) {
            backAd = options.realEstateAd.programmeRef ? options.realEstateAd.programme : options.realEstateAd.residence;
        }
        if (!options.closePageUrl) {
            options.closePageUrl = options.defaultClosePageUrl;
        }
        super.show(options);
        this.$listNavigation = this.$element.find('.detailedSheetsListNavigationContainer');
        this.$closeButton = this.$element.find('#closeDetailedSheet');
        this.$previousLink = this.$element.find('#selectPreviousDetailedSheet');
        this.$nextLink = this.$element.find('#selectNextDetailedSheet');
        this.$current = this.$element.find('.currentDetailedSheetNumber');
        this.$total = this.$element.find('.totalDetailedSheets');
        this.$backToDescription = this.$element.find('#closeNavContactSection');
        this.$backToDescription.find('span.closeTitle').html(
            RealEstateAdTitleGenerator.getTitle(this.options.realEstateAd, 'backToAd')
        );
        this.$backToDescription.click(_.bind(function () {
            this.closeNavContactSection();
        }, this));
        if (options.realEstateAd && isLot(options.realEstateAd)) {
            let backText;
            if (backAd) {
                backText = RealEstateAdTitleGenerator.getTitle(backAd, 'backToAd');
            } else {
                backText = options.realEstateAd.programmeRef ? 'Programme' : 'Résidence';
            }
            this.$closeButton.find('span.closeTitle').html(backText);
            this.$closeButton.linkClick(_.bind(function (e) {
                this.closeLot();
                e.stopPropagation();
                e.preventDefault();
            }, this));
        } else if (options.searchController) {
            this.$closeButton.linkClick(_.bind(function (e) {
                this.options.direction = null;
                this.close();
                e.preventDefault();
                e.stopPropagation();
            }, this));
        }
        this._toggleMapClickHandler = _.bind(this._toggleMapClicked, this);
        const $detailedSheetToggleMap = this.$element.find('#detailedSheetToggleMap');
        $detailedSheetToggleMap.on('click', this._toggleMapClickHandler);
        this.playOpenAnimation();
    }

    shouldPlayOpenAnimation() {
        return !this.options.direction;
    }

    shouldPlayCloseAnimation() {
        return !this.options.direction && this.options.hasSearchPageSplitScreen;
    }

    _toggleMapClicked() {
        this.emit('toggleMapClick');
    }

    close() {
        this._toggleMapClickHandler = _.bind(this._toggleMapClicked, this);
        this.emit('closeDetailedSheet');
    }

    closeNavContactSection() {
        this.emit('closeContactSection');
    }

    closeLot() {
        this.emit('closeLot', {
            from: this.realEstateAd,
        });
    }

    _showListNavigation() {
        this.$listNavigation.show();
    }

    _hideListNavigation() {
        this.$listNavigation.hide();
    }

    setMapEnabled(enabled) {
        if (this.$element) {
            const $toggleMapButton = this.$element.find('#detailedSheetToggleMap');
            if (enabled) {
                $toggleMapButton.html('Fiche');
            } else {
                $toggleMapButton.html('Carte');
            }
            this.$element.toggleClass('isDetailedSheetFullScreen', !enabled);
            if (!this.configuration.isMobile) {
                if (enabled) {
                    $toggleMapButton.hide();
                } else {
                    $toggleMapButton.show();
                }
            }
        }
    }

    setSearchResults(searchResults) {
        this.searchResults = searchResults;
        this._hideListNavigation();
        this.update(this.options);
    }

    updateProgramme(programme) {
        if (programme) {
            const backText = RealEstateAdTitleGenerator.getTitle(programme, 'simple');
            this.$closeButton.find('span.closeTitle').html(backText);
            const closePageUrl = Urls.detailedSheet.makeUrl(programme, this.closePageUrl);
            this.$closeButton.attr('href', closePageUrl);
        }
    }

    updateResidence(residence) {
        this.updateProgramme(residence);
    }

    update(options) {
        const that = this;
        const realEstateAd = options.realEstateAd;
        if (options.searchController && options.searchController.searchResults) {
            this.searchResults = options.searchController.searchResults;
        }

        const searchResults = this.searchResults;
        this.realEstateAd = realEstateAd;

        if (!realEstateAd || !isLot(realEstateAd)) {
            this.$closeButton.find('span.closeTitle').text(options.headerTitle);
        }

        let hasNavigation = false;
        if (realEstateAd && searchResults) {
            const totalResults = searchResults.totalResults;
            if (null == totalResults) {
                if (options.createSearchNavigationIfMissing) {
                    loadFirstPageAndSetPrevNextButtons();
                }
                //else we should not be creating fallback navigation
            } else if (totalResults > 1) {
                const adIndex = searchResults.findAdIndex(realEstateAd);
                if (adIndex >= 0) {
                    hasNavigation = true;
                    this._setPrevNextButtons(adIndex);
                } else {
                    loadFirstPageAndSetPrevNextButtons();
                }
            }
        }
        if (!hasNavigation || (options.context && options.context.inHighlightedBox)) {
            this._hideListNavigation();
        }

        function loadFirstPageAndSetPrevNextButtons() {
            searchResults.loadPage(0, function (err) {
                if (err) {
                    console.error(err); // todo: handle error
                } else {
                    const adIndex = searchResults.findAdIndex(realEstateAd);
                    if (adIndex >= 0) {
                        that._setPrevNextButtons(adIndex);
                    }
                }
            });
        }
    }

    _setPrevNextButtons(adIndex) {
        const searchResults = this.searchResults;
        const totalResults = searchResults.totalResults;
        const adsTotalLimit = searchResults.adsTotalLimit;
        this.$current.text(adIndex >= 0 ? NumberFormatter.formatNumber(adIndex + 1) : '?');
        if (totalResults > adsTotalLimit) {
            this.$total.text('+ ' + NumberFormatter.formatNumber(adsTotalLimit));
        } else {
            this.$total.text(NumberFormatter.formatNumber(totalResults));
        }
        if (adIndex + 1 < totalResults && adIndex + 1 < searchResults.maxAuthorizedResults) {
            const nextAdIndex = adIndex + 1;
            // eslint-disable-next-line handle-callback-err
            searchResults.getAd(nextAdIndex, _.bind(function (err, nextAd) {
                this._setNextButtonAd(nextAd);
            }, this));
        } else {
            this.$nextLink.attr('disabled', true);
        }
        if (adIndex > 0) {
            const prevAdIndex = adIndex - 1;
            // eslint-disable-next-line handle-callback-err
            searchResults.getAd(prevAdIndex, _.bind(function (err, prevAd) {
                this._setPreviousButtonAd(prevAd);
            }, this));
        } else {
            this.$previousLink.attr('disabled', true);
        }
        this._showListNavigation();
    }

    _updateNavigationButton(ad, $navigationButton, direction) {
        if (ad) {
            const url = Urls.detailedSheet.makeUrl(ad, this.closePageUrl);
            $navigationButton
                .attr('href', url)
                .attr('disabled', false);
            $navigationButton.linkClick(_.bind(function (event) {
                this.emit('adClick', {
                    realEstateAd: ad,
                    realEstateAdId: ad.id,
                    direction,
                });
                event.stopPropagation();
                event.preventDefault();
            }, this));
        } else {
            $navigationButton
                .removeAttr('href')
                .attr('disabled', true);
        }
    }

    _setPreviousButtonAd(previousAd) {
        this._updateNavigationButton(previousAd, this.$previousLink, 'previous');
    }

    _setNextButtonAd(nextAd) {
        this._updateNavigationButton(nextAd, this.$nextLink, 'next');
    }
};
