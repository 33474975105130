const $ = require('jquery');
const callWithGlobalJQuery = require('./utils/callWithGlobalJQuery');
callWithGlobalJQuery(function () {
    require('./lib/jquery.cookie');
});

module.exports = {
    setJsonCookie,
    getJsonCookie,
    remove,
    hasDismissedNoWebGlDisclaimer,
    registerNoWebGlDisclaimerDismissed,
    hasDismissedNotSupportedDisclaimer,
    registerNotSupportedDisclaimerDismissed,
    getSelectedGraphicOptionsLevel,
    setSelectedGraphicOptionsLevel,
    getWebGLBenchmarkScore,
    setWebGLBenchmarkScore,
    saveListMode,
    getListModeForPage,
    getTrackingCode,
    getI18nLanguage,
};

const NO_WEBGL_DISMISSED_COOKIE_NAME = 'noWebGlDismissed';
const NOT_SUPPORTED_DISMISSED_COOKIE_NAME = 'notSupportedDismissed';
const GRAPHIC_OPTIONS_LEVEL_COOKIE_NAME = 'graphicOptionsLevel';
const WEBGL_BENCHMARK_SCORE_COOKIE_NAME = 'webGLBenchmarkScore';
const LIST_MODE_COOKIE_NAME = 'listMode';
const TRACKING_CODE_COOKIE_NAME = 'xtor';
const I18N_COOKIE_NAME = 'i18next';

function setJsonCookie(name, data, cookiesParameters) {
    $.cookie(name, JSON.stringify(data), cookiesParameters);
}

function saveListMode(pageName, mode) {
    const savedListMode = getJsonCookie(LIST_MODE_COOKIE_NAME) || {};
    savedListMode[pageName] = mode;
    setJsonCookie(LIST_MODE_COOKIE_NAME, savedListMode, {
        path: '/',
        expires: 365,
    });
}

function getListModeForPage(pageName) {
    const savedListMode = getJsonCookie(LIST_MODE_COOKIE_NAME) || {};
    return savedListMode[pageName];
}

function getJsonCookie(name) {
    //noinspection UnusedCatchParameterJS
    const str = $.cookie(name);
    try {
        return JSON.parse(str);
    } catch (e) {
        console.warn('Could not parse cookie %s: %s', name, str);
    }
    return undefined;
}

function remove(name, options) {
    $.removeCookie(name, options);
}

function hasDismissedDisclaimer(cookieName) {
    const val = $.cookie(cookieName);
    return Boolean(val);
}

function hasDismissedNoWebGlDisclaimer() {
    return hasDismissedDisclaimer(NO_WEBGL_DISMISSED_COOKIE_NAME);
}

function hasDismissedNotSupportedDisclaimer() {
    return hasDismissedDisclaimer(NOT_SUPPORTED_DISMISSED_COOKIE_NAME);
}

function registerDisclaimerDismissed(cookieName) {
    $.cookie(cookieName, true, {
        path: '/',
        expires: 365, //one year
    });
}

function registerNoWebGlDisclaimerDismissed() {
    registerDisclaimerDismissed(NO_WEBGL_DISMISSED_COOKIE_NAME);
}

function registerNotSupportedDisclaimerDismissed() {
    registerDisclaimerDismissed(NOT_SUPPORTED_DISMISSED_COOKIE_NAME);
}

function getSelectedGraphicOptionsLevel() {
    return $.cookie(GRAPHIC_OPTIONS_LEVEL_COOKIE_NAME) || null;
}

function setSelectedGraphicOptionsLevel(level) {
    $.cookie(GRAPHIC_OPTIONS_LEVEL_COOKIE_NAME, level, {
        path: '/',
        expires: 365, //one year
    });
}

function getWebGLBenchmarkScore() {
    return $.cookie(WEBGL_BENCHMARK_SCORE_COOKIE_NAME) || null;
}

function setWebGLBenchmarkScore(score) {
    $.cookie(WEBGL_BENCHMARK_SCORE_COOKIE_NAME, score, {
        path: '/',
        expires: 365, //one year
    });
}

function getTrackingCode() {
    return $.cookie(TRACKING_CODE_COOKIE_NAME);
}

function getI18nLanguage() {
    return $.cookie(I18N_COOKIE_NAME);
}
