const _ = require('lodash');

module.exports = MarkerScaleHelper;

function MarkerScaleHelper(scalingSteps) {

    // Handle scaling steps for markers
    this._currentMarkerScale = 1.0;

    this._maxScalingStepZoom = 1;
    this._minScalingStepZoom = 30;
    this._maxScalingStepValue = 0.25;
    this._minScalingStepValue = 1.0;
    this._scalingSteps = scalingSteps;

    // Get min and max extents for zooming values
    _.each(this._scalingSteps, step => {
        const zoom = step.zoom;
        const scale = step.scale;
        if (zoom >= this._maxScalingStepZoom) {
            this._maxScalingStepZoom = zoom;
            this._maxScalingStepValue = scale;
        }
        if (zoom <= this._minScalingStepZoom) {
            this._minScalingStepZoom = zoom;
            this._minScalingStepValue = scale;
        }
    });

}

MarkerScaleHelper.prototype.getMarkerScaleForZoom = function (zoom) {

    // See if we are above/below max/min zooms
    if (zoom >= this._maxScalingStepZoom) {
        return this._maxScalingStepValue;

    } else if (zoom <= this._minScalingStepZoom) {
        return this._minScalingStepValue;
    }

    // Find step values for zooms bigger and smaller
    let zoom1 = 100;
    let scale1;
    let zoom2 = 0;
    let scale2;
    _.each(this._scalingSteps, step => {
        const stepZoom = step.zoom;
        const stepScale = step.scale;
        if (stepZoom >= zoom && stepZoom < zoom1) {
            zoom1 = stepZoom;
            scale1 = stepScale;
        }
        if (stepZoom <= zoom && stepZoom > zoom2) {
            zoom2 = stepZoom;
            scale2 = stepScale;
        }
    });

    // Use exact scale if it is the case or interpolate otherwise
    let scale;
    if (zoom1 == zoom2) {
        scale = scale1;

    } else {
        const f = (zoom - zoom2) / (zoom1 - zoom2);
        scale = scale2 + f * (scale1 - scale2);
    }
    return scale;
};
