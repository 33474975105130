const History = require('../utils/History');
const Router = require('../utils/Router');
const _ = require('lodash');
const Account = require('../authentication/Account');
const AuthenticationPage = require('./AuthenticationPage');
const Errors = require('../utils/Errors');
const CancelableCallback = require('../utils/CancelableCallback');
const RealtimeServer = require('../RealtimeServer');
const {EventEmitter} = require('events');
const emitter = new EventEmitter();
const Page = require('./Page');
const PageFactory = require('./PageFactory');
const Pages = require('./Pages');
const Error404 = require('../Error404');
const Title = require('../pages/Title');
const GoogleTagManager = require('../stats/GoogleTagManager');
const $ = require('jquery');
const {setCanonicalLink} = require('../utils/SeoUtils');

const MEDIA_ATTRIBUTE = 'media';
const MEDIA_ATTRIBUTE_VALUE_TO_APPLY_MAIN_STYLE_SHEET = 'all';

module.exports = _.extend(emitter, {
    init,
    addPage,
    addRoute,
    openPage,
    getCurrentPage,
    overrideHomePageRoute,
    restoreHomePageRoute,
    getPreviousUrl,
});

let currentPage;
let pendingCallback;
let $mainPageContainer;
let previousUrl;

function init($container) {
    $mainPageContainer = $container;
    Account.on('change:id', handleChange);
    Account.on('change:registration', handleChange);

    function handleChange() {
        if (pendingCallback) {
            //ignore account change, avoid infinite loop on reset password page
            return;
        }
        if (Account.isRegistered() && Account.isShowRoom()) {
            overrideHomePageRoute();
            const account = Account.getAuthenticatedAccount();
            const redirectionUrl = getRedirectionUrl(account);
            if (redirectionUrl) {
                Router.redirectToUrl(redirectionUrl);
            }
        } else {
            restoreHomePageRoute();
            const pageRedirectionOnLogout = currentPage && currentPage.pageRedirectionOnLogout;
            const reloadPageOnLogin = currentPage && currentPage.reloadPageOnLogin;
            if (pageRedirectionOnLogout && !Account.isRegistered()) {
                History.pushState(null, null, pageRedirectionOnLogout);
                Router.parseUrl(pageRedirectionOnLogout);
            } else if (reloadPageOnLogin) {
                Router.parseCurrentUrl();
            }
        }
    }
}

function restoreHomePageRoute() {
    removeCurrentHomePageRoute();
    addPage(Pages.home);
}

function getPreviousUrl() {
    return previousUrl;
}

function overrideHomePageRoute() {
    const isRedirectionPageNeeded = Account.isShowRoom();
    if (isRedirectionPageNeeded) {
        const account = Account.getAuthenticatedAccount();
        const redirectionUrl = getRedirectionUrl(account);
        if (redirectionUrl && redirectionUrl != '/') {
            removeCurrentHomePageRoute();
            Router.addRoute('/', function () {
                Router.redirectToUrl(redirectionUrl);
            });
        }
    }
}

function getRedirectionUrl(account) {
    return _.get(account, 'showRoom.redirectionUrl');
}

function removeCurrentHomePageRoute() {
    const homePageRoute = Router.routes['/:?query:'];
    if (homePageRoute) {
        homePageRoute.dispose();
    } else {
        console.trace();
        console.warn('cannot dispose homePageRoute');
    }
}

function addPage(page) {
    addRoute(page.getUrlPattern(), page);
}

function addRoute(pattern, routePage) {
    Router.addRoute(pattern, function () {
        const state = History.getState();
        const args = Array.prototype.slice.call(arguments, 0);
        const page = createPage(routePage);
        const options = page.parseUrl.apply(page, [state.url].concat(args));
        _openPage(page, options, false);
    });
}

function openPage(page, options) {
    _openPage(createPage(page), options, true);
}

function createPage(routePage) {
    let page;
    if (routePage instanceof Page) {
        page = routePage;
    } else if (routePage instanceof PageFactory) {
        page = routePage.createPage();
    } else {
        page = new routePage();
    }
    return page;
}

function _openPage(page, options, shouldPushUrl) {
    const pageRedirectionIfAuthenticated = page.pageRedirectionIfAuthenticated;
    const isRegistered = Account.isRegistered();
    if (pendingCallback) {
        pendingCallback.cancel();
    }
    const redirectToUrl = _.get(page, 'options.redirectToUrl');
    if (redirectToUrl) {
        Router.redirectToUrl(redirectToUrl);
    } else if (page.requiresRegistration && !isRegistered) {
        loadDataAndOpenPage(new AuthenticationPage({
            requiredForPage: page,
        }));
    } else if (pageRedirectionIfAuthenticated && isRegistered) {
        Router.redirectToUrl(pageRedirectionIfAuthenticated);
    } else {
        loadDataAndOpenPage(page, options, shouldPushUrl);
    }
}

function closePage(pageToClose, nextPage, options) {
    if (pageToClose && pageToClose.isOpen) {
        pageToClose.close(options, nextPage);
        pageToClose.isOpen = false;
    }

    $('head').find([
        'link[rel="next"]',
        'link[rel="prev"]',
        'link[rel="canonical"]',
    ].join(',')).remove();
}

function loadDataAndOpenPage(page, options, shouldPushUrl) {
    const handleSpinner = setTimeout(function () {
        $mainPageContainer.addClass('isLoading');
    }, 1000);
    pendingCallback = CancelableCallback(function (err, loadedOptions) {
        loadedOptions = loadedOptions || {};
        pendingCallback = null;
        clearTimeout(handleSpinner);
        if (err) {
            hideSpinner();
            if (err instanceof Error404) {
                openPage(Pages.page404, {});
                console.warn(err.message);
            } else {
                Errors.showError(err);
            }
        } else {
            loadedOptions.lastPage = currentPage;
            closePage(currentPage, page, options);
            currentPage = page;
            page.isOpen = true;
            page.on('readyToBeShown', hideSpinner);
            if (page.open.length == 2) {
                pendingCallback = CancelableCallback(onPageOpen);
                page.open(loadedOptions, pendingCallback);

            } else {
                page.open(loadedOptions);
                onPageOpen();
            }
            emitter.emit('pageLoaded', page);
        }

        function onPageOpen() {
            const $mainStyleSheet = $('#main-css');
            if ($mainStyleSheet.attr(MEDIA_ATTRIBUTE) == MEDIA_ATTRIBUTE_VALUE_TO_APPLY_MAIN_STYLE_SHEET) {
                pendingCallback = null;
                hideSpinner();
                emitter.emit('open', page); // magic event to close side menu
                sendPageOpenInfo(page);
                const pageTitle = page.getTitle();
                if (null != pageTitle) {
                    Title.setTitle(pageTitle);
                }

                if (shouldPushUrl) {
                    const url = page.getUrl();
                    if (url) {
                        History.pushState(null, pageTitle || document.title, url);
                    }
                }
                if (page.updateUrl) {
                    page.updateUrl({pushToHistory: false});
                }
                const canonicalUrl = page.generateCanonicalUrl();
                setCanonicalLink(canonicalUrl);
                page.updateMetaTags();
                if (page.setNextPrevLinksUrls) {
                    page.setNextPrevLinksUrls();
                }
                replaceJSONLD(page.getJSONLD());

                emitter.emit('pageOpen', page);
                GoogleTagManager.sendChangePageEvent(currentPage);
                $mainPageContainer.removeClass('initialLoading');
                page.removeListener('readyToBeShown', hideSpinner);
                previousUrl = location.href;
            } else {
                $mainStyleSheet.one('load', () => {
                    $mainStyleSheet.attr(MEDIA_ATTRIBUTE, MEDIA_ATTRIBUTE_VALUE_TO_APPLY_MAIN_STYLE_SHEET);
                    onPageOpen();
                });
            }
        }
    });
    pendingCallback.on('canceled', function () {
        page.removeListener('readyToBeShown', hideSpinner);
        clearTimeout(handleSpinner);
    });
    options = _.extend(options || {}, {shouldPushUrl});
    page.loadData(options, pendingCallback); //TODO : when opening other page, cancel current loadData
}

function hideSpinner() {
    $mainPageContainer.removeClass('isLoading');
}

function replaceJSONLD(newJSONLD) {
    const $head = $('head');
    $head.find('script[type="application/ld+json"]').remove();
    _.each(newJSONLD, jsonLD => {
        const $node = $('<script>').attr('type', 'application/ld+json');
        $node.text(JSON.stringify(jsonLD));
        $head.append($node);
    });
}

function sendPageOpenInfo(page) {
    // eslint-disable-next-line handle-callback-err
    page.getRealtimePageInfo((err, data) => {
        // Don't do anything on error, this is only for statistics
        if (data && data.realtimePageInfo) {
            RealtimeServer.emit(data.eventName, _.extend({
                useRedisList: true,
                name: page.getName(),
            }, data.realtimePageInfo));
        }
    });
}

function getCurrentPage() {
    return currentPage;
}
