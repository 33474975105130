const _ = require('lodash');

const template = require('./LocalBannerSettings.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'LocalBannerSettings.',
            keys: [
                'themeTitle',
                'templateTitle',
                'postalCodesTitle',
                'postalCodesHelp',
                'invalidPostalCodes',
                'addPostalCodeText',
                'removePostalCodeText',
            ],
        }),
    ],
    props: {
        name: {
            type: String,
            required: true,
        },
        banner: {
            type: Object,
            required: true,
        },
        templatesCounts: {
            type: Object,
            required: true,
        },
        usedThemes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            postalCodeKeys: [],
        };
    },
    computed: {
        bannerToEmit: {
            get() {
                return this.banner;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        availableThemes() {
            const usedThemes = _.reject(this.usedThemes, theme => theme === this.bannerToEmit.theme);
            const themesWithTemplates = _.filter(_.keys(this.templatesCounts), theme => this.templatesCounts[theme] > 0);
            return _.reject(
                themesWithTemplates,
                theme => _.includes(usedThemes, theme)
            );
        },
        themeOptions() {
            return _.map(this.availableThemes, theme => ({value: theme, label: this.t('themes.' + theme)}));
        },
        templateOptions() {
            return Array(this.templatesCounts[this.bannerToEmit.theme])
                .fill()
                .map((_, index) => ({label: index + 1, value: index}));
        },
        additionalPostalCodes() {
            return _.slice(this.bannerToEmit.postalCodes, 1);
        },
    },
    methods: {
        onThemeChange(theme) {
            this.bannerToEmit.theme = theme;
            this.bannerToEmit.template.index = 0;
        },
        addPostalCodeField() {
            this.bannerToEmit.postalCodes.push('');
        },
        removePostalCodeField(index) {
            this.bannerToEmit.postalCodes.splice(index, 1);
        },
        postalCodeKey(index) {
            if (!this.postalCodeKeys[index]) {
                this.postalCodeKeys[index] = _.uniqueId('postalCode');
            }
            return this.postalCodeKeys[index];
        },
    },
    template: template(),
};
