const template = require('../templates/userDirectory/userSearch.jade');
const userListTemplate = require('../templates/userDirectory/userList.jade');
const contractTypeSelectTemplate = require('../templates/userDirectory/contractTypeSelect.jade');
const CompositeVueView = require('../vue/CompositeVueView');
const Account = require('../authentication/Account');
const _ = require('lodash');

const $ = require('jquery');
const ServerConfig = require('../ServerConfig');
const Roles = require('../../common/Roles');
const Urls = require('../Urls');
const NumberFormatter = require('../../common/NumberFormatter');
const UserAdminActions = require('./utils/UserAdminActions');
const {i18n: {translate}} = require('fack');
const LocationManager = require('../LocationManager');
const async = require('async');
const EventPack = require('../utils/EventPack');
const SeoUtils = require('../utils/SeoUtils');
const GoogleTagManager = require('../stats/GoogleTagManager');
const SearchFieldsHandler = require('../search/SearchFieldsHandler');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const UserSortingView = require('./UserSortingView');
const ContractAndExtensionAccounts = require('../data/ContractAndExtensionAccounts');
const {insertUserInfoView} = require('./UserInfoView');
const i18nMixin = require('../vue/components/mixins/i18n');

const MAX_ACCOUNTS_RESULTS_COUNT = 10000; // ES limit set in account server

module.exports = class UserSearchView extends CompositeVueView {
    constructor(configuration) {
        super({
            template,
        });
        this.configuration = configuration;
        this._eventPack = new EventPack();
        this._defaultSearchConfig = DefaultConfiguration.userSearch(Account.isAdmin());
    }

    loadData(options, cb) {
        const tasks = {
            enhanceCriteriaLocations: cb => {
                LocationManager.addLocationNamesFromItemsInSearchCriteria(options.searchCriteria, cb);
            },
            searchResults: [
                'enhanceCriteriaLocations',
                (cb) => {
                    if (!_.isEmpty(options.searchCriteria)) {
                        //make the search in order to display results
                        const {resultsPerPage} = this.configuration;
                        const maxPage = Math.ceil((MAX_ACCOUNTS_RESULTS_COUNT - resultsPerPage) / resultsPerPage);
                        if (options.searchCriteria.currentPage > maxPage) {
                            this.currentPage = options.searchCriteria.currentPage = maxPage;
                        }
                        this._askResultsToServer(options.searchCriteria, (err, agencies) => {
                            if (err) {
                                console.error('Could not fetch agencies matching criteria', err, options.searchCriteria);
                            }
                            cb(err, agencies);
                        });
                    } else {
                        _.defer(cb);
                    }
                },
            ],
        };
        async.auto(tasks, (err, results) => {
            if (err) {
                console.error('Could not preload data for agencies directory', err);
            }
            _.extend(options, _.pick(results, ['searchResults']));
            cb(err, options);
        });
    }

    update(options) {
        this.hide();
        this.show(options);
    }

    show(options) {
        this.currentPage = _.get(options, 'searchCriteria.currentPage', this._defaultSearchConfig.currentPage);
        const templateOptions = this._getTemplateOptions(options);
        const vueData = this.vueData = {
            extensionAccounts: [],
            selectedExtensionAccounts: [],
        };
        const vueOptions = {
            mixins: [
                i18nMixin({
                    prefix: 'userDirectory.',
                }),
            ],
            data: vueData,
            computed: {
                isExtensionSelectorDisabled() {
                    return this.extensionAccountOptions.length === 0;
                },
                extensionAccountOptions() {
                    return _.map(this.extensionAccounts, ({id: value, label}) => ({value, label}));
                },
                extensionSelectorPlaceholder() {
                    return this.t('placeholder.extension');
                },
                extensionSelectorDataCountSelectedText() {
                    return `{0} ${this.t('dataCountSelectedText.extension')}`;
                },
                rolesOptions() {
                    const formattedRolesByCategory = _(Roles.getRolesByCategory())
                        .mapKeys((value, key) => translate('roles.categories.' + key))
                        .mapValues(value => {
                            return _.map(value, option => {
                                return {
                                    label: `${option} (${translate('roles.labels.' + option)})`,
                                    value: option,
                                };
                            });
                        })
                        .value();
                    return _.map(formattedRolesByCategory, (options, label) => {
                        return {label, options};
                    });
                },
                isAllUsersViewer() {
                    return Account.isAllUsersViewer();
                },
                proSelectionTitle() {
                    return this.proSelectionTranslate('title');
                },
                proSelectionOptions() {
                    const translationsObject = this.proSelectionTranslate('values', {returnObjectTrees: true});
                    return _.map(translationsObject, (value, key) => {
                        return {label: value, value: JSON.parse(key)};
                    });
                },
            },
            methods: {
                proSelectionTranslate(key, options) {
                    return this.t('proSelection.' + key, options);
                },
            },
        };
        super.show(templateOptions, vueOptions);
        if (Account.isDirViewer()) {
            this._initExtensionSelector(options.searchCriteria);
            this._initContractSelector(options.searchCriteria);
        }
        this._initSearchFieldsHandler();
        this._initSortingView(_.get(options, 'searchCriteria["sort-type"]', this._defaultSearchConfig['sort-type']));
        this._$results = this.$element.find('.results');
        this.$searchWords = this.$element.find('.searchWords');
        this.$search = this.$element.find('.search');

        this._initUserAdminActions();
        this._bindEvents();

        //prefill fields
        if (options.searchCriteria) {
            this._searchFieldsHandler.setFieldsValuesFromSearch(options.searchCriteria);
        }

        if (options.searchResults) { //we have some results already
            this._toggleFeatureDescriptionVisibility(false);
            const {isLastPage, isLastAuthorizedPage} = this.getPageValues(options.searchResults, this.currentPage);
            if (isLastPage || isLastAuthorizedPage) {
                const searchCriteria = this._getSearchCriteria(options.searchCriteria);
                this.emit('search', searchCriteria, {pushToHistory: false});
            }
            this._showResults(null, options.searchResults);
        }
        this._eventPack.on(this._searchFieldsHandler, 'change', _.bind(this._onFiltersChange, this));
    }

    _initContractSelector(searchCriteria) {
        const $contractSelector = this.$element.find('.contractSelector');
        $contractSelector.attr('disabled', 'disabled');
        const contractTypeFromUrl = _.get(searchCriteria, 'contractType');
        if (contractTypeFromUrl) {
            const selectContractTypes = _.isArray(contractTypeFromUrl) ? contractTypeFromUrl : [contractTypeFromUrl];
            // create empty options to wait for loading, to keep values & generate pagination properly
            _.each(selectContractTypes, value => {
                $contractSelector.append($('<option>').attr('value', value).text(''));
            });
        }
        ContractAndExtensionAccounts.get('contractType', (err, contractAccounts) => {
            if (err) {
                console.log('Failed to load contract accounts', err);
            } else {
                $contractSelector.removeAttr('disabled');
                //replaces options content
                const selectOptions = this.renderTemplate(contractTypeSelectTemplate, {contractAccounts});
                $contractSelector.html(selectOptions.html());
                $contractSelector.selectpicker('refresh');
                if (searchCriteria) {
                    this._searchFieldsHandler.setFieldsValuesFromSearch(searchCriteria, ['contractType']);
                }
            }
        });
    }

    _initExtensionSelector(searchCriteria) {
        const extensionTypeFromUrl = _.get(searchCriteria, 'extensionType');
        if (extensionTypeFromUrl) {
            this.vueData.selectedExtensionAccounts =
                _.isArray(extensionTypeFromUrl) ? extensionTypeFromUrl : [extensionTypeFromUrl];
        }
        ContractAndExtensionAccounts.get('extensionType', (err, extensionAccounts) => {
            if (err) {
                console.error('Failed to load extension accounts', err);
            } else {
                this.vueData.extensionAccounts = extensionAccounts;
                if (searchCriteria) {
                    this._searchFieldsHandler.setFieldsValuesFromSearch(searchCriteria, ['extensionType']);
                }
            }
        });
    }

    _initSortingView(sortType) {
        if (Account.isDirViewer()) {
            this.userSortingView = new UserSortingView({
                $container: this.$element.find('.resultsHeaderBoxRight'),
            });
            this.userSortingView.show({sortType, Account});
            this._eventPack.on(this.userSortingView, 'sortChanged', sortValue => {
                this._searchIfCriteriaChanged({
                    'sort-type': sortValue,
                    currentPage: 1,
                }, _.bind(this._toggleLoading, this, false));
            });
        }
    }

    _getTemplateOptions(options) {
        return _.extend({}, options, {
            redirectAllMailsTo: ServerConfig.config.redirectAllMailsTo,
            roles: Roles.getKeysList(),
            isRestrictedToAgencies: !Account.isDirViewer(),
            isFeatureDescriptionVisible: !(Account.isAdmin() || Account.isDirViewer()),
            Account,
        });
    }

    _initSearchFieldsHandler() {
        this._searchFieldsHandler = new SearchFieldsHandler(this.$element, {
            submit: _.bind(this._onSubmit, this),
            afterSubmit: _.bind(this._toggleLoading, this, false),
            defaultSearch: this._defaultSearchConfig,
            locationsField: {
                placeholderKey: 'userDirectory.placeholder.where',
                travelTimeEnabled: false,
            },
        });
    }

    _onFiltersChange(changes) {
        const searchOptions = {
            [changes.name]: changes.value,
            currentPage: 1,
        };
        this._searchIfCriteriaChanged(searchOptions, _.bind(this._toggleLoading, this, false));
    }

    _bindEvents() {
        this._bindResultsElements();
    }

    _initUserAdminActions() {
        this._userAdminActions = new UserAdminActions({$element: this.$element});
        this._userAdminActions.bind();
    }

    _initSuggestions() {
        // bind the change event after setting the locations to avoid triggering a useless search
    }

    hide() {
        if (this.userSortingView) {
            this.userSortingView.hide();
        }
        this._userAdminActions.unbind();
        this._searchFieldsHandler.destroyFields();
        this._eventPack.removeAllListeners();
        super.hide();
    }

    _onSubmit(err, cb) {
        if (err) {
            cb(err);
        } else {
            this._searchIfCriteriaChanged({currentPage: 1}, cb);
        }
    }

    _toggleLoading(state) {
        if (state) {
            this._$results.empty();
        }
        this._toggleFeatureDescriptionVisibility(false);
        this.$element.find('.userDirectoryLoading').toggle(state);
        this.$element.find('.resultsHeader').toggle(!state);
    }

    _refreshResults(cb) {
        this._search(null, cb);
    }

    _searchIfCriteriaChanged(searchOptions, cb) {
        const searchCriteria = this._getSearchCriteria(searchOptions);
        if (!_.isEqual(this.lastSearchCriteria, searchCriteria)) {
            this.lastSearchCriteria = _.cloneDeep(searchCriteria);
            this._search(searchOptions, cb);
        } else {
            cb();
        }
    }

    _search(searchOptions, cb) {
        const searchCriteria = this._getSearchCriteria(searchOptions);
        this.currentPage = searchCriteria.currentPage;
        this._toggleLoading(true);
        this.emit('search', searchCriteria);
        this._askResultsToServer(searchCriteria, (err, results) => {
            this._showResults(err, results, cb);
        });
    }

    _askResultsToServer(searchCriteria, callback) {
        searchCriteria = _.extend(searchCriteria, this.getFromAndSize(searchCriteria.currentPage));
        searchCriteria = _.omit(searchCriteria, 'currentPage');
        let locations = [];
        _.each(searchCriteria.locations, location => {
            locations = locations.concat(location.getZoneInfoIds());
        });
        searchCriteria.locations = locations;
        this.doAsync({
            func: cb => this._requestSearch(searchCriteria, cb),
            callback,
            name: 'find-account',
        });
    }

    _requestSearch(searchCriteria, callback) {
        // Also search with imports to be merged in Directory view if Admin or DirViewer
        async.auto({
            users: cb => {
                Account.find(_.omitBy(searchCriteria, _.isNil), cb);
            },
            relatedAccounts: [
                'users', (cb, {users: {accounts}}) => {
                    Account.getRelatedAccounts(accounts, cb);
                },
            ],
        }, (err, results) => {
            let searchResult;
            if (!err) {
                searchResult = results.users;
                searchResult.accounts = this.updateUsersAccount(results);
                searchResult.relatedAccounts = results.relatedAccounts;
            }
            callback(err, searchResult);
        });
    }

    _showResults(err, results = {}, cb = _.noop) {
        if (err) {
            this._renderResultsTemplate({error: err});
        } else {
            const totalPages = this.getTotalPages(results);
            if (totalPages && this.currentPage > totalPages) {
                this._searchIfCriteriaChanged({currentPage: totalPages}, _.bind(this._toggleLoading, this, false));
            } else {
                this.results = results;
                this.updateUserCount(results);
                if (!_.isEmpty(results.accounts)) {
                    const needsPagination = results.count > this.configuration.resultsPerPage;
                    const {queryStatus} = results;
                    const options = {
                        needsPagination,
                        queryStatus,
                    };
                    let pageValues;
                    if (needsPagination) {
                        pageValues = this.getPageValues(results, this._getCurrentPage());
                        _.extend(options, pageValues);
                        options.urls = this.getPaginationUrls(pageValues);
                    }
                    this._renderResultsTemplate(options);
                    this._insertUsersResults(results);

                    if (needsPagination && (options.isLastPage || options.isLastAuthorizedPage)) {
                        const translationKey = pageValues.isLastPage ? 'nextPage' : 'maxAuthorizedPageReached';
                        this.$element.find('.nextPage')
                            .attr('disabled', true)
                            .text(translate(`searchResults.${translationKey}`));
                    }
                    SeoUtils.updateNextPrevLinks(options.urls);
                } else {
                    SeoUtils.updateNextPrevLinks();
                    this._renderResultsTemplate({noResults: true});
                }
            }
        }
        cb();
    }

    _renderResultsTemplate(options) {
        const {needsPagination, isFirstPage, urls, error, noResults, queryStatus} = options;
        this._$results.append(this.renderTemplate(userListTemplate, {}));
        // @vue/component
        this.injectVueViews(
            this._$results,
            {
                computed: {
                    hasError() {
                        return Boolean(error) || this.isManagedError;
                    },
                    hasEmptyResults() {
                        return noResults;
                    },
                    isManagedError() {
                        return queryStatus && queryStatus.elasticSearchShardsFailed;
                    },
                    errorMessage() {
                        return this.isManagedError ? translate('userDirectory.managedError') : translate('userDirectory.error');
                    },
                    noResultsMessage() {
                        return translate('userDirectory.noResult');
                    },
                    needsPagination() {
                        return needsPagination;
                    },
                    isFirstPage() {
                        return isFirstPage;
                    },
                    nextPageUrl() {
                        return urls.next;
                    },
                    previousPageUrl() {
                        return urls.prev;
                    },
                    nextPageForFirstPageText() {
                        return translate('searchResults.nextPageForFirstPage');
                    },
                    nextPageText() {
                        return translate('searchResults.nextPage');
                    },
                    previousPageText() {
                        return translate('searchResults.previousPage');
                    },
                },
                mounted() {
                    if (this.isManagedError) {
                        SeoUtils.updateNextPrevLinks();
                    }
                },
            }
        );
    }

    _insertUsersResults({accounts, relatedAccounts}) {
        const $container = this._$results.find('.users');
        _.each(accounts, user => {
            insertUserInfoView($container, {
                user,
                contactGtmSource: 'UserDirectory',
                relatedAccounts,
                onAccountUpdated: () => {
                    this._refreshResults(() => {
                        this._toggleLoading(false);
                    });
                },
            });
        });
    }

    _toggleFeatureDescriptionVisibility(isVisible) {
        this.$element.toggleClass('hideUserDirectoryFeatureDescription', !isVisible);
    }

    _bindResultsElements() {
        this._eventPack.on(this._$results, {
            click: {
                '.userInfoPhoneRevealBtn': e => {
                    const userId = $(e.currentTarget).data('userid');
                    GoogleTagManager.sendAgencyPhoneNumberShownEvent(userId, 'fromDirectory');
                    this._$results.find('.userInfoDetailContainer[data-userId=' + userId + ']').show();
                },
            },
        });
    }

    updateUsersAccount(results) {
        const {users, relatedAccounts} = results;
        return _.map(users.accounts, account => {
            if (account.ownerIds) {
                account.contractInfo = _.map(account.ownerIds, ownerId => {
                    return relatedAccounts[ownerId];
                });
            }
            return account;
        });
    }

    updateUserCount(results) {
        const isCountHidden = !Account.isDirViewer() && results.count > results.countLimit;
        const finalCount = isCountHidden ? results.countLimit : results.count || 0;
        const formattedCount = NumberFormatter.formatNumber(finalCount);
        const translationKey = this.getUserCountTranslationKey(isCountHidden);
        const countText = translate('userDirectory.' + translationKey, {
            count: finalCount,
            formattedCount,
        });
        this.$element.find('.userCount').html(countText);
    }

    getUserCountTranslationKey(isCountHidden) {
        if (isCountHidden) {
            return 'hiddenCount';
        } else if (Account.isDirViewer()) {
            return 'userFoundCount';
        } else {
            return 'agencyFoundCount';
        }
    }

    getPageValues(results, currentPage) {
        return {
            currentPage,
            totalPages: this.getTotalPages(results),
            isFirstPage: currentPage === 1,
            isLastPage: currentPage >= this.getTotalPages(results),
            isLastAuthorizedPage: results.lastAuthorizedPageReached,
        };
    }

    _getSearchCriteria(searchOptions = {}) {
        return _.extend(this._searchFieldsHandler.getFilters(), _.defaults({}, searchOptions, {
            currentPage: this._getCurrentPage(),
            'sort-type': this._getCurrentSort(),
        }));
    }

    _getCurrentSort() {
        return (this.userSortingView && this.userSortingView.getSortingValue()) || this._defaultSearchConfig['sort-type'];
    }

    getPaginationUrls(options) {
        const {previousPage, nextPage} = this.getOtherPagesValues(options);
        return {
            prev: this.getUrlForPage(previousPage),
            next: this.getUrlForPage(nextPage),
        };
    }

    getUrlForPage(page) {
        const searchCriteria = this._withoutDefaultSearchCriteria(this._getSearchCriteria({currentPage: page}));
        return null == page ? null : Urls.userSearch.makeUrl(searchCriteria);
    }

    _withoutDefaultSearchCriteria(searchCriteria) {
        return _.omitBy(searchCriteria, (value, key) => value == this._defaultSearchConfig[key]);
    }

    getTotalPages(results) {
        return Math.ceil(results.count / this.configuration.resultsPerPage);
    }

    getFromAndSize(currentPage) {
        return {
            from: (currentPage - 1) * this.configuration.resultsPerPage,
            size: this.configuration.resultsPerPage,
        };
    }

    getOtherPagesValues({isFirstPage, isLastPage, currentPage, totalPages}) {
        return {
            previousPage: !isFirstPage ? Math.min(currentPage - 1, totalPages) : null,
            nextPage: !isLastPage ? Math.min(currentPage + 1, totalPages) : null,
        };
    }

    _getCurrentPage() {
        return this.currentPage || 1;
    }

};
