const _ = require('lodash');

const template = require('./ImportActionsDropdown.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const Account = require('../../../authentication/Account');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ImportActionsDropdown.',
            keys: _([
                'toggleImportBlockage',
                'invalidateImport',
                'interruptImport',
                'showFullInfo',
            ])
                .map(translationKey => [translationKey, `${translationKey}Label`])
                .fromPairs()
                .value(),
        }),
    ],
    props: {
        importInfo: {
            type: Object,
            required: true,
        },
    },
    computed: {
        iconName() {
            let iconName;
            const {running, manuallyInvalidated, imported, warnings, error} = this.importInfo;
            if (running) {
                iconName = 'spinner';
            } else if (manuallyInvalidated) {
                iconName = 'retry';
            } else if (imported) {
                iconName = warnings ? 'warning' : 'check-thin';
            } else if (error) {
                iconName = 'error';
            } else {
                iconName = 'cancel';
            }
            return iconName;
        },
        canActOnImports() {
            return Account.canActOnImports();
        },
        running() {
            return this.importInfo.running;
        },
    },
    template: template(),
};
