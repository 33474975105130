const template = require('./ActionsBanner.jade');
const RealEstateAdNotificationBoostButton = require('../../../notificationBoost/components/RealEstateAdNotificationBoostButton');
const Account = require('../../../authentication/Account');
const {
    canMarkAdsAsLeading,
    canHighlightAds,
} = require('../../../setFeatured');
const {canBoostAd} = require('../../../notificationBoost');
const {isLot} = require('../../../../common/ProgrammeHelper');

// @vue/component
module.exports = {
    components: {
        RealEstateAdNotificationBoostButton,
    },
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        displayModificationIfOutOfTheMarket: Boolean,
    },
    computed: {
        realEstateAdId() {
            return this.realEstateAd.id;
        },
        displayUserActionsContainer() {
            const {
                displayStateContainer,
                displayModificationContainer,
            } = this;
            return displayStateContainer || displayModificationContainer;
        },
        isNotLot() {
            return !isLot(this.realEstateAd);
        },
        displayMarkAsLeadingAdButton() {
            return canMarkAdsAsLeading(this.realEstateAd) && this.isNotLot;
        },
        displayHighlightAdButton() {
            return canHighlightAds(this.realEstateAd) && this.isNotLot;
        },
        canBuyTemporaryLeadingAds() {
            return Account.canBuyTemporaryLeadingAds();
        },
        canModifyAd() {
            return this.realEstateAd.userRelativeData.canModifyAd;
        },
        canModifyAdBlur() {
            return this.realEstateAd.userRelativeData.canModifyAdBlur;
        },
        displayStateContainer() {
            const {
                onTheMarket,
                canChangeOnTheMarket,
                displayMarkAsLeadingAdButton,
                displayHighlightAdButton,
                canBoostAds,
            } = this;
            const canPerformActions = canChangeOnTheMarket || displayMarkAsLeadingAdButton || displayHighlightAdButton
                || canBoostAds;
            return onTheMarket && canPerformActions;
        },
        displayModificationContainer() {
            const {
                isMobile,
                displayModificationIfOutOfTheMarket,
                onTheMarket,
                canModifyAd,
                canModifyAdBlur,
            } = this;
            const canDisplayButton = !isMobile && (onTheMarket || displayModificationIfOutOfTheMarket);
            return canDisplayButton && (canModifyAd || canModifyAdBlur);
        },
        onTheMarket() {
            return this.realEstateAd.status.onTheMarket;
        },
        canChangeOnTheMarket() {
            return this.realEstateAd.userRelativeData.canChangeOnTheMarket;
        },
        canBoostAds() {
            const {
                realEstateAd,
                isNotLot,
            } = this;
            return canBoostAd(realEstateAd) && isNotLot;
        },
        bannerModifiers() {
            return {
                outOfTheMarket: !this.onTheMarket,
            };
        },
    },
    methods: {
        applyOnTheMarketChange(realEstateAd) {
            this.$emit('out-of-the-market', realEstateAd);
        },
        featurePropertyChanged(feature, payload) {
            this.$emit('featured-property-changed', feature, payload);
        },
    },
    template: template(),
};
