const _ = require('lodash');
const Views = require('../views/Views');

module.exports = {
    scrollToElement,
    getHeaderBottom,
    scrollToTop,
};

function getHeaderBottom() {
    const $sectionHeader = Views.header.getSectionHeaderElement();
    return ($sectionHeader.position().top + ($sectionHeader.is(':visible') ? $sectionHeader.outerHeight(true) : 0));
}

function scrollToElement(element, {animated = false, delay = 0, offset = 0, intoView = false, instant = false} = {}) {
    if (element == null) {
        throw new Error('no element passed to scrollToElement function');
    }
    const windowScroll = window.pageYOffset;
    const scrollTop = element.getBoundingClientRect().top + windowScroll - getHeaderBottom();
    const top = scrollTop + offset;
    const behavior = animated ? 'smooth' : (instant ? 'instant' : 'auto');
    setTimeout(() => {
        if ('scrollBehavior' in document.documentElement.style) {
            if (intoView) {
                element.scrollIntoView({behavior});
            } else {
                window.scroll({
                    top,
                    behavior,
                });
            }
        } else {
            window.scroll(0, top);
        }
    }, delay);
}

function scrollToTop() {
    if (window.top === window.self) {
        window.scrollTo(0, 0);
    } else { // inside iframe
        _.each(document.querySelectorAll('html, body'), element => {
            element.scrollTop = 0; //IE 10 does not have scrollTo method on html & body, so using scrollTop directly
        });
    }
}
