// @vue/component
module.exports = {
    methods: {
        getLoadingPlaceholderLineStyle(loadingPlaceholderLinePercentage) {
            return {
                width: `${loadingPlaceholderLinePercentage}%`,
            };
        },
        getLoadingPlaceholderLineStyleInPx(loadingPlaceholderLineWidthInPx) {
            return {
                width: `${loadingPlaceholderLineWidthInPx}px`,
            };
        },
    },
};
