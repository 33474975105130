module.exports = {
    handlesField,
    getValue,
};

function handlesField($field) {
    return $field.prop('tagName') == 'INPUT' && $field.attr('type') == 'number';
}

function getValue($input) {
    return $input.val() ? parseFloat($input.val()) : undefined;
}
