const ContainerView = require('./ContainerView');
const _ = require('lodash');
const MapCreation = require('../utils/MapCreation');
const MapApi = require('../MapApi');
const {isKartoEnabled, addZonePolygons} = require('../utils/Karto');
const KelQuartierDescription = require('../KelQuartierDescription');
const ZoneManager = require('../ZoneManager');
const Errors = require('../utils/Errors');
const {getImageUrlFromAlias} = require('../ImageHelper');
const {i18n: {translate}} = require('fack');
const PlaceDetailsHelper = require('../PlaceDetailsHelper');
const PolygonZIndex = require('../utils/PolygonZIndex');
const SearchLinksView = require('../views/SearchLinksView.js');
const PlaceDiscoveryPageBreadcrumb = require('../../js/placeDiscoveryPage/components/PlaceDiscoveryPageBreadcrumb');

const template = require('../templates/placeDiscovery.jade');
const BrowserDetect = require('browser-detect');

const zoneOptions = {
    strokeOpacity: 1,
    fillColor: '#ffb82f',
    strokeColor: '#ffb82f',
    strokeWeight: 2,
    fillOpacity: 0.3,
    isHole: false,
    zIndex: PolygonZIndex.zone,
};

const ELIGIBLE_PLACE_TYPES_FOR_DISPLAYING_NOTORIETY_AGENCIES_BLOCK = ['city', 'district', 'arrondissement'];
module.exports = class PlaceDiscoveryView extends ContainerView {
    constructor() {
        super();
        this.template = template;
        this.SearchLinksView = new SearchLinksView();
        this.useKarto = isKartoEnabled('placeDiscoveryPage');
        this.setSubViews([
            {view: this.SearchLinksView, containerSelector: '.searchLinks'},
        ]);
    }

    show(options) {
        const vueOptions = this._getVueOptions(options);
        super.show(getTemplateOptions(options), vueOptions);
        const {placeDetails} = options;
        if (!this.useKarto) {
            this._createMap(placeDetails);
        }
    }

    update(options) {
        this.hide(options);
        this.show(options);
    }

    _createMap(placeDetails) {
        //remove element from DOM before creating map, reinsert after
        const createMapOptions = {
            view: this,
            $map: this.$element.find('#placeDiscoveryPageMap'),
            onCameraCreatedCallback: _.bind(this._handleCameraCreatedCallback, this, placeDetails),
            enablePoiAgencies: true,
            poisTransportEnabled: true,
            getMapOptionsCallback: () => {
                return {
                    draggableTouch: false,
                    scrollwheel: false,
                    scaleControl: !BrowserDetect.isMobile(),
                    minZoomLayer2D: 20,
                    ui: {
                        enableLoadingSpinner: false,
                        enableGeoloc: false,
                    },
                    mapTypeControlOptions: {
                        position: MapApi.api.ControlPosition.TOP_LEFT,
                    },
                    keyboardShortcuts: false,
                };
            },
        };
        MapCreation.createMap(createMapOptions);
    }

    _handleCameraCreatedCallback(placeDetails) {
        const boundingBox = placeDetails.boundingBox;
        const sw = new MapApi.api.LatLng(boundingBox.south, boundingBox.west);
        const ne = new MapApi.api.LatLng(boundingBox.north, boundingBox.east);
        const bounds = new MapApi.api.LatLngBounds(sw, ne);
        this._cameraManager.fitBounds(bounds);
        this._loadZone(placeDetails, this.map);
    }

    _loadZone(placeDetails, map) {
        this.asyncHelper.doAsync({
            func: cb => ZoneManager.fetchNeighborhoodZone(placeDetails, zoneOptions, cb),
            callback: onZoneReceived,
            name: 'loadDiscoveryZone',
        });
        const {useKarto} = this;
        function onZoneReceived(err, zone) {
            if (err) {
                if (err.status !== 'abort') {
                    Errors.showError(err);
                }
            } else {
                if (!zone) {
                    console.error('No zone found for id %s', placeDetails.id);
                    Errors.showError(err);
                } else {
                    if (useKarto) {
                        addZonePolygons(map, zone);
                    } else {
                        zone.setMap(map);
                    }
                }
            }
        }
    }

    _getVueOptions({placeDetails = {}, placeTitleWithoutCode, parentTitleWithoutCode, previousUrl}) {
        const self = this;
        // @vue/component
        return {
            components: {
                PlaceDiscoveryPageBreadcrumb,
            },
            computed: {
                placeDetails() {
                    return placeDetails;
                },
                displayAgencies() {
                    return _.includes(ELIGIBLE_PLACE_TYPES_FOR_DISPLAYING_NOTORIETY_AGENCIES_BLOCK, placeDetails.type);
                },
                isCity() {
                    return placeDetails.type === 'city';
                },
                zoneIds() {
                    const {parent} = placeDetails;
                    const {zoneIds} = this.isCity ? placeDetails : parent;
                    return zoneIds;
                },
                placeTitle() {
                    return this.isCity ? placeTitleWithoutCode : parentTitleWithoutCode;
                },
                allAgenciesUrl() {
                    return `/agences-immobilieres?lieu=${placeDetails.nameForNotorietyExtensionButtonUrl}`;
                },
                breadcrumbItems() {
                    return getBreadcrumbChildItems(this.placeDetails);
                },
                previousUrl() {
                    return previousUrl;
                },
            },
            mounted() {
                const kartoMapComponent = this.$refs.kartoMap;
                if (kartoMapComponent) {
                    kartoMapComponent.$on('loadZone', (map) => {
                        self._loadZone(placeDetails, map);
                    });
                }
            },
        };
    }
};

function getBreadcrumbChildItems(placeDetails) {
    const items = [];
    if (placeDetails.parent && (placeDetails.parent.name !== placeDetails.name || placeDetails.name !== 'Paris')) {
        items.push(...getBreadcrumbChildItems(placeDetails.parent));
    }
    items.push({
        text: placeDetails.name,
        url: placeDetails.discoveryUrl,
    });
    return items;
}

function getTemplateOptions(options) {
    const placeDetails = options.placeDetails;
    PlaceDetailsHelper.setPlaceDiscoveryUrls(placeDetails);
    setPlaceSearchLinks(placeDetails);
    placeDetails.photos = _.map(placeDetails.photos, photoNameToUrl);
    const blockInfo = PlaceDetailsHelper.extractBlocksFromDescription(placeDetails.description);
    return _.extend({
        placeDetails,
        placeTitle: options.placeTitle,
        placeMapTitle: options.placeMapTitle,
        placeDiscoveryPagekQTitle: _tKqTitle(placeDetails.type),
        placeDiscoveryPagekQDescription: KelQuartierDescription.getDescription(_.extend(options.placeDetails,
            {inPage: true})),
        placeDiscoveryPageChildrenTitle: getChildrenTitle(placeDetails),
        placeDiscoveryPageSiblingsTitle: getSiblingTitle(placeDetails),
        placeDiscoveryPagePhotosTitle: getPhotoPageTitle(placeDetails),
    }, blockInfo);
}

function photoNameToUrl(name) {
    return getImageUrlFromAlias(name, {width: 640, height: 480});
}

function getChildrenTitle(placeDetails) {
    const childrenTitle = placeDetails.childrenType && _tChildrenTitle(placeDetails.childrenType) + ' ';
    return childrenTitle + getPlaceNameByType(placeDetails);
}

function getSiblingTitle(placeDetails) {
    return placeDetails.parent ? getChildrenTitle(placeDetails.parent) : null;
}

function getPhotoPageTitle(placeDetails) {
    return 'Galerie ' + getPlaceNameByType(placeDetails);
}

function getPlaceNameByType(placeDetails) {
    return (placeDetails.type == 'department') ? _tOfTheDepartment(placeDetails.insee_code) : 'de ' + placeDetails.name;
}

function setPlaceSearchLinks(place) {
    _.extend(place, {
        buyText: 'Acheter dans ' + _tPlaceTypeWithArticle(place),
        buyUrl: '/recherche/achat/' + place.nameForSearchUrl,
        rentText: 'Louer dans ' + _tPlaceTypeWithArticle(place),
        rentUrl: '/recherche/location/' + place.nameForSearchUrl,
    });
}

function _t(key) {
    return translate('neighborhood.' + key);
}

function _tKqTitle(key) {
    return _t('kqDescriptionTitlePrefixeByType.' + key);
}

function _tChildrenTitle(key) {
    return _t('childrenTitlePrefixByType.' + key);
}

function _tOfTheDepartment(inseeCode) {
    return translate('department.of.' + inseeCode);
}

function _tPlaceTypeWithArticle(place) {
    return _t('this-' + place.type);
}
