const _ = require('lodash');

const LoanFormView = require('./LoanFormView');
const GoogleTagManager = require('../stats/GoogleTagManager');
const ContactHelper = require('../utils/ContactHelper');

module.exports = class LoanFormContactView extends LoanFormView {
    constructor(configuration) {
        super(configuration);
        this.configuration = _.defaults(configuration, {
            name: 'LoanFormContactView',
            gtmEventName: 'LoanContactFormSubmitted',
        });
    }

    show(options) {
        this.simulatorData = options.simulatorData;
        super.show(_.extend({
            approvalQuestions: ContactHelper.getApprovalQuestionsWithOptions(),
            endUserLicenseAgreementContext: options.name,
        }, options));
    }

    _submitForm() {
        this.showSpinner();
        this._sendGtmEvent();
        this._contactUpdater.updateAccount(err => {
            if (err) {
                console.error(this.configuration.name + ' could not update account contact data');
            }
            super._submitForm();
        });
    }

    _sendGtmEvent() {
        GoogleTagManager.sendEvent(this.configuration.gtmEventName, this._getGtmInfos());
    }

    _getSubmitData() {
        return {simulatorData: this.simulatorData, contactData: this._getContactData()};
    }

    _getContactData() {
        return this._getFormData();
    }

    canSkipContactForm() {
        return true;
    }
};
