const _ = require('lodash');
const _toSentence = require('underscore.string/toSentence');
const MapApi = require('../MapApi');

module.exports = GeoJSON2GoogleMaps;

const GEOMETRY_CONVERTERS = {
    Point: pointToGoogleMaps,
    MultiPoint: multiPointToGoogleMaps,
    LineString: lineStringToGoogleMaps,
    MultiLineString: multiLineStringToGoogleMaps,
    Polygon: polygonToGoogleMaps,
    MultiPolygon: multiPolygonToGoogleMaps,
    GeometryCollection: geometryCollectionToGoogleMaps,
};

const GEOJSON_CONVERTERS = _.extend({
    FeatureCollection: featureCollectionToGoogleMaps,
    Feature: featureToGoogleMaps,
}, GEOMETRY_CONVERTERS);

function GeoJSON2GoogleMaps(geojson, options) {
    const opts = options || {};
    const converter = GEOJSON_CONVERTERS[geojson.type];
    if (converter) {
        return converter(geojson, opts);
    } else {
        return toError('Invalid GeoJSON object: GeoJSON object must be one of '
            + _toSentence(_.keys(GEOJSON_CONVERTERS), ', ', 'or')
            + '.');
    }
}

function featureCollectionToGoogleMaps(geojson, opts) {
    const features = geojson.features;
    if (!features) {
        return toError('Invalid GeoJSON object: FeatureCollection object missing "features" member.');
    } else {
        const obj = [];
        for (let i = 0; i < features.length; i++) {
            const feature = features[i];
            obj.push(geometryToGoogleMaps(feature.geometry, opts, feature.properties));
        }
        return obj;
    }
}

function featureToGoogleMaps(geojson, opts) {
    const properties = geojson.properties;
    const geometry = geojson.geometry;
    if (!(properties && geometry)) {
        return toError('Invalid GeoJSON object: Feature object missing "properties" or "geometry" member.');
    } else {
        return geometryToGoogleMaps(geometry, opts, properties);
    }
}

function pointCoordinatesToGoogleMaps(coordinates, opts, geojsonProperties) {
    opts.position = pointToLatLng(coordinates, opts);
    const marker = new MapApi.api.Marker(opts);
    if (geojsonProperties) {
        marker.set('geojsonProperties', geojsonProperties);
    }
    return marker;
}

function pointToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    return pointCoordinatesToGoogleMaps(geojsonGeometry.coordinates, opts, geojsonProperties);
}

function multiPointToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    const markers = [];
    const points = geojsonGeometry.coordinates;
    for (let i = 0; i < points.length; i++) {
        const marker = pointCoordinatesToGoogleMaps(points[i], opts, geojsonProperties);
        markers.push(marker);
    }
    return markers;
}

function lineStringCoordinatesToGoogleMaps(coordinates, opts, geojsonProperties) {
    const path = [];
    for (let i = 0; i < coordinates.length; i++) {
        const point = coordinates[i];
        const ll = pointToLatLng(point, opts);
        path.push(ll);
    }
    opts.path = path;
    const polyline = new MapApi.api.Polyline(opts);
    if (geojsonProperties) {
        polyline.set('geojsonProperties', geojsonProperties);
    }
    return polyline;
}

function lineStringToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    return lineStringCoordinatesToGoogleMaps(geojsonGeometry.coordinates, opts, geojsonProperties);
}

function multiLineStringToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    const polylines = [];
    const lineStrings = geojsonGeometry.coordinates;
    for (let i = 0; i < lineStrings.length; i++) {
        const polyline = lineStringCoordinatesToGoogleMaps(lineStrings[i], opts, geojsonProperties);
        polylines.push(polyline);
    }
    return polylines;
}

function polygonCoordinatesToGoogleMaps(coordinates, opts, geojsonProperties) {
    const paths = [];
    let exteriorDirection;
    let interiorDirection;
    for (let i = 0; i < coordinates.length; i++) {
        const path = [];
        for (let j = 0; j < coordinates[i].length; j++) {
            const point = coordinates[i][j];
            const ll = pointToLatLng(point, opts);
            path.push(ll);
        }
        if (!i) {
            exteriorDirection = isPathCcw(path);
        } else {
            if (i == 1) {
                interiorDirection = isPathCcw(path);
            }
            if (exteriorDirection == interiorDirection) {
                path.reverse();
            }
        }
        paths.push(path);
    }
    opts.paths = paths;
    const polygon = new MapApi.api.Polygon(opts);
    if (geojsonProperties) {
        polygon.set('geojsonProperties', geojsonProperties);
    }
    return polygon;
}

function polygonToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    return polygonCoordinatesToGoogleMaps(geojsonGeometry.coordinates, opts, geojsonProperties);
}

function multiPolygonToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    const polygons = [];
    const coordinates = geojsonGeometry.coordinates;
    for (let i = 0; i < coordinates.length; i++) {
        const polygon = polygonCoordinatesToGoogleMaps(coordinates[i], opts, geojsonProperties);
        polygons.push(polygon);
    }
    return polygons;
}

function geometryCollectionToGoogleMaps(geojsonGeometry, opts, geojsonProperties) {
    let googleObj = [];
    const geometries = geojsonGeometry.geometries;
    if (!geometries) {
        googleObj = toError('Invalid GeoJSON object: GeometryCollection object missing "geometries" member.');
    } else {
        for (let i = 0; i < geometries.length; i++) {
            googleObj.push(geometryToGoogleMaps(geometries[i], opts, geojsonProperties || null));
        }
    }
    return googleObj;
}

function geometryToGoogleMaps(geojsonGeometry, options, geojsonProperties) {
    if (geojsonGeometry.coordinates) {
        const opts = _.clone(options);
        const converter = GEOMETRY_CONVERTERS[geojsonGeometry.type];
        if (converter) {
            return converter(geojsonGeometry, opts, geojsonProperties);
        } else {
            return toError('Invalid GeoJSON object: Geometry object must be one of '
                + _toSentence(_.keys(GEOMETRY_CONVERTERS), ', ', 'or')
                + '.');
        }
    } else {
        return toError('Invalid GeoJSON object: Geometry object missing "coordinates" member.');
    }
}

function toError(message) {
    return {
        type: 'Error',
        message,
    };
}

function isPathCcw(path) {
    let a = 0;
    for (let i = 0; i < path.length - 2; i++) {
        const p1 = path[i];
        const p2 = path[i + 1];
        const p3 = path[i + 2];
        a += (p2.lat() - p1.lat()) * (p3.lng() - p1.lng()) - (p3.lat() - p1.lat()) * (p2.lng() - p1.lng());
    }
    return a > 0;
}

function pointToLatLng(point, opts) {
    return new MapApi.api.LatLng(point[1], point[0], opts.noWrap);
}
