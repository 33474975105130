const _ = require('lodash');

const Options = require('../../Options');

const OPTION_KEY = 'financialPartnerCreditSimulationEnabled';

// @vue/component
module.exports = {
    data() {
        return {
            financialPartnerCreditSimulationEnabled: Options.get(OPTION_KEY),
        };
    },
    mounted() {
        this.handleEventListener('on');
    },
    beforeDestroy() {
        this.handleEventListener('removeListener');
    },
    methods: {
        handleEventListener(action) {
            if (!Options.verifyIfOptionInCookie(OPTION_KEY)) {
                Options[action](`${OPTION_KEY}_changed`, isEnabled => {
                    if (_.isBoolean(isEnabled)) {
                        this.financialPartnerCreditSimulationEnabled = isEnabled;
                    }
                });
            }
        },
    },
};
