const assert = require('assert');
const _ = require('lodash');
const VueView = require('../../vue/VueView');
const {getImageUrlAndConvertToWebpIfSupported} = require('../../utils/ImageConverterHelper');
const template = require('./slideshowViewTemplate.jade');
const homePageSlideHelper = require('./homePageSlideHelper');
const homePageSlide = require('../../data/homePageSlide');
const GoogleTagManager = require('../../stats/GoogleTagManager');

const REGIONAL_PARTNERS_BACKGROUNDS_DIR = 'images/homepage/backgrounds/regional-partners';

const DISPLAY_TIMEOUT_IN_MS = 500;

module.exports = class SlideshowView extends VueView {
    constructor({
        isRegionalPartner,
        homepageBackgroundFilename,
        commercialAdsEnabled,
        commercialAdsSlideOverride,
    }) {
        super();
        this.isRegionalPartner = isRegionalPartner;
        this.homepageBackgroundFilename = homepageBackgroundFilename;
        this.commercialAdsEnabled = commercialAdsEnabled;
        this.commercialAdsSlideOverride = commercialAdsSlideOverride;

        const defaultCitySlidesData = homePageSlide().filter((slides) => slides.isDefault);
        this.defaultCitySlides = homePageSlideHelper.transformDataToSlides(defaultCitySlidesData);

        this.citySlides = homePageSlide().filter((slides) => !slides.isCommercialAd);
        this.commercialSlides = homePageSlide().filter((slides) => slides.isCommercialAd);
    }

    show() {
        const {commercialAdsEnabled} = this;
        const view = this;

        // @vue/component
        super.show({}, {
            data() {
                return {
                    slides: [],
                    displayedSlidesCount: 0,
                    initialTimeout: null,
                };
            },

            mounted() {
                this.setDefaultSlideIfGeolocTooSlow();
                this.addMatchingSlides();
            },

            methods: {
                setDefaultSlideIfGeolocTooSlow() {
                    // In case the geoloc is too slow, fall back to default slides
                    this.initialTimeout = setTimeout(() => {
                        this.setSlides(this.getDefaultSlides());
                    }, DISPLAY_TIMEOUT_IN_MS);
                },

                getDefaultSlides() {
                    const defaultSlides = commercialAdsEnabled ?
                        homePageSlideHelper.getCurrentGlobalSlides(view.commercialSlides) : [];
                    return defaultSlides.concat(view.defaultCitySlides);
                },

                addMatchingSlides() {
                    homePageSlideHelper.getMatchingSlides(
                        this.getSlides(),
                        view.commercialAdsSlideOverride,
                        (err, matchingSlides) => {
                            assert.strictEqual(err, null); // homePageSlideHelper.getMatchingSlides always returns null

                            this.setSlides(matchingSlides);
                        }
                    );
                },

                setSlides(newSlides) {
                    clearTimeout(this.initialTimeout);

                    if (!_.isEqual(this.slides, newSlides)) {
                        this.slides = newSlides;
                    }
                },

                getSlides() {
                    let slides = [];
                    if (commercialAdsEnabled) {
                        slides = view.commercialSlides;
                    }

                    if (view.isRegionalPartner) {
                        slides = slides.concat(view.getRegionalPartnerSlide());
                    } else {
                        slides = slides.concat(view.citySlides);
                    }

                    return slides;
                },

                slideDisplayed(slide) {
                    ++this.displayedSlidesCount;
                    this.sendTrackingData(slide);
                },

                sendTrackingData({gtmData}) {
                    if (gtmData) {
                        ++gtmData.occurence;
                        const position = this.displayedSlidesCount;
                        GoogleTagManager.sendEvent('slideshowAdShown', _.extend({position}, gtmData));
                    }
                },
            },
            template: template(),
        });
    }

    getRegionalPartnerSlide() {
        return {
            backgroundUrl:
                getImageUrlAndConvertToWebpIfSupported(`${REGIONAL_PARTNERS_BACKGROUNDS_DIR}/${this.homepageBackgroundFilename}`),
        };
    }
};
