const _ = require('lodash');
const $ = require('jquery');
const WindowMessages = require('../utils/events/WindowMessages');
const DirectOffersAndNewsLetterPopupView = require('./DirectOffersAndNewsLetterPopupView');
const PageManager = require('../pages/PageManager');
const Account = require('../authentication/Account');
const LocalStorage = require('../utils/localStorage/LocalStorage');
const ApplicationConfig = require('../app/ApplicationConfig');
const UrlHelper = require('../../common/UrlHelper');

const HAS_DISMISSED_POPUP_LOCALSTORAGE_KEY = 'hasDismissedDirectOffersAndNewsLetterPopup';
const OPENED_PAGES_BEFORE_SHOWING_DIRECTOFFERSNEWSLETTERPOPUP = 5;

module.exports = {
    bind,
};

let openedPagesCount = 0;

const directOffersAndNewsLetterPopupView = new DirectOffersAndNewsLetterPopupView();

function bind() {
    if (!ApplicationConfig.applicationPro) {
        Account.on('change:id', () => {
            resetCounter();
            setHasDismissedPopup(false);
        });
        Account.on('change:registration', resetCounter);
        PageManager.on('pageOpen', openDirectOffersAndNewsLetterPopupOnConditionsMet);
        directOffersAndNewsLetterPopupView.on('close', () => setHasDismissedPopup(true));
    }
    // the message can be sent by the advertisements
    WindowMessages.on('openWantsToReceiveDirectOffersAndNewsLetterPopup', openWantsToReceiveDirectOffersAndNewsLetterPopup);
    const openPopupFromUrl = UrlHelper.getURLParameter(location.href, 'inscription-newsletter');
    if (openPopupFromUrl != null) {
        openWantsToReceiveDirectOffersAndNewsLetterPopup();
    }
}

function resetCounter() {
    openedPagesCount = 0;
}

function openDirectOffersAndNewsLetterPopupOnConditionsMet() {
    if (!hasDismissedPopup()
        && openedPagesCount < OPENED_PAGES_BEFORE_SHOWING_DIRECTOFFERSNEWSLETTERPOPUP) {
        if ($("input[name='wantsToReceiveDirectOffersAndNewsLetter']").length > 0) {
            openedPagesCount = 0;
        } else {
            openedPagesCount++;
            if (openedPagesCount === OPENED_PAGES_BEFORE_SHOWING_DIRECTOFFERSNEWSLETTERPOPUP
                && _.isNil(_.get(Account.getAuthenticatedAccount(), 'wantsToReceiveDirectOffersAndNewsLetter'))
            ) {
                openWantsToReceiveDirectOffersAndNewsLetterPopup();
            }
        }
    }
}

function openWantsToReceiveDirectOffersAndNewsLetterPopup() {
    if (!directOffersAndNewsLetterPopupView.isShown()) {
        directOffersAndNewsLetterPopupView.show();
    }
}

function setHasDismissedPopup(hasDismissed) {
    if (hasDismissed) {
        LocalStorage.setValue(HAS_DISMISSED_POPUP_LOCALSTORAGE_KEY, true);
    } else {
        LocalStorage.remove(HAS_DISMISSED_POPUP_LOCALSTORAGE_KEY);
    }
}

function hasDismissedPopup() {
    return LocalStorage.getValue(HAS_DISMISSED_POPUP_LOCALSTORAGE_KEY);
}
