const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');

module.exports = {
    deleteCredentials,
    generateAutologinLink,
    generateStockExportLink,
    geocodeAccount,
    reindexAccountAds,
};

function deleteCredentials(accountId, cb) {
    Account.authAjax({
        url: `${ServerConfig.config.accountUrl}/account/${accountId}/credentials`,
        method: 'DELETE',
        serverErrorMessage: 'deleteCredentials',
        callback: function (err) {
            if (err) {
                console.error(err);
                alert('La suppression des informations de connexion a échoué');
            } else {
                alert('Informations de connexion supprimées avec succès');
            }
            cb();
        },
    });
}

function generateAutologinLink(userId, accountType, callback) {
    Account.authAjax({
        url: ServerConfig.config.accountUrl + '/account/timedToken?id=' + userId,
        serverErrorMessage: 'getTimedToken',
        disableErrorPage: true,
        callback,
    });
}

function generateStockExportLink(userId, callback) {
    Account.authAjax({
        url: `${ServerConfig.config.accountUrl}/account/stockToken`,
        data: {
            id: userId,
        },
        serverErrorMessage: 'getStockToken',
        disableErrorPage: true,
        callback,
    });
}

function geocodeAccount(accountId) {
    Account.authAjax({
        url: `${ServerConfig.config.accountUrl}/account/geocode?id=${accountId}`,
        method: 'POST',
        serverErrorMessage: 'geocodeAccount',
        callback: function (err) {
            if (err) {
                console.error(err);
                alert('Le géocodage du compte a échoué');
            } else {
                alert('Compte géocodé avec succès');
            }
        },
    });
}

function reindexAccountAds(accountId) {
    Account.authAjax({
        url: `${ServerConfig.config.indexerUrl}/reindexAccountAds?accountId=${accountId}`,
        method: 'POST',
        serverErrorMessage: 'reindexAccountAds',
        callback: function (err) {
            if (err) {
                console.error(err);
            } else {
                alert('Annonces ré-indexées !');
            }
        },
    });
}
