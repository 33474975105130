const {addURLParameters} = require('../common/UrlHelper');

module.exports = {
    getImageUrlFromAliasAndFileUrl,
    getFileUrlFromAliasAndFileUrl,
};

function getImageUrlFromAliasAndFileUrl(fileUrl, imageName, parameters) {
    if (imageName) {
        return addURLParameters(`${fileUrl}/photo/${imageName}`, parameters);
    }
}

function getFileUrlFromAliasAndFileUrl(fileUrl, fileName, parameters) {
    if (fileName) {
        return addURLParameters(`${fileUrl}/raw/${fileName}`, parameters);
    }
}
