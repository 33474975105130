const _ = require('lodash');
const Account = require('../authentication/Account');
const buildUrl = require('./buildUrl');

module.exports = {
    getDossiers,
    initDossier,
    updateDossier,
    getApplicantDocumentsInDossier,
};

function getDossiers(cb) {
    Account.authAjax({
        url: buildUrl('/dossiers'),
        method: 'GET',
        disableErrorPage: true,
        callback: function (err, data) {
            cb(err, _.get(data, 'dossiers'));
        },
    });
}

function initDossier(existingDossierId, cb) {
    let url = '/dossier/init';

    if (existingDossierId) {
        url = url + `?existingDossierId=${existingDossierId}`;
    }

    Account.authAjax({
        url: buildUrl(url),
        method: 'GET',
        disableErrorPage: true,
        callback: function (err, data) {
            cb(err, _.get(data, 'dossier'));
        },
    });
}

function updateDossier(dossierId, type, title, cb) {
    Account.authAjax({
        url: buildUrl(`/dossier/${dossierId}`),
        method: 'PUT',
        data: {
            title,
            type,
        },
        disableErrorPage: true,
        callback: function (err, data) {
            cb(err, _.get(data, 'dossier'));
        },
    });
}

function getApplicantDocumentsInDossier(dossier) {
    return dossier.documents;
}
