const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');

// @vue/component
module.exports = {
    props: {
        context: {
            type: String,
            default: '',
        },
        translationKeyPrefix: {
            type: String,
            default: '',
        },
    },
    computed: {
        question() {
            return this.t(`${this.name}.question`);
        },
        labelText() {
            return this.label || this.question;
        },
        finalPlaceholder() {
            return this.computePlaceholder(this.t(`${this.name}.placeholder`, {defaultValue: ''}), this.label, this.question);
        },
    },
    methods: {
        t(key, options) {
            return t(this.translationKeyPrefix + key, _.extend({context: this.context}, options));
        },
    },
};
