const VueView = require('../vue/VueView');
const i18nMixin = require('../vue/components/mixins/i18n');
const template = require('./components/MapBottomButtons.jade');

module.exports = class MapBottomButtonsView extends VueView {
    show({onlineBookingUrl, contact, openContactForm}) {
        // @vue/component
        super.show(null, {
            name: 'MapBottomButtons',
            mixins: [
                i18nMixin({
                    prefix: 'MapBottomButtons.',
                }),
            ],
            computed: {
                displayOpenContactFormButton() {
                    return contact.hasEmailToDisplay && !onlineBookingUrl;
                },
                onlineBookingUrl() {
                    return onlineBookingUrl;
                },
                openContactFormButtonText() {
                    return this.t('openContactFormButtonText', {
                        accountType: contact.accountType,
                    });
                },
            },
            methods: {
                openContactForm() {
                    openContactForm();
                },
            },
            template: template(),
        });
    }
};
