const _ = require('lodash');
const Account = require('../../../js/authentication/Account');
const HeaderLink = require('./HeaderLink');
const template = require('./HeaderLinks.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    components: {
        HeaderLink,
    },
    mixins: [
        i18nMixin({
            prefix: 'HeaderLinks.',
        }),
    ],
    provide() {
        return {
            isSelected: names => _.includes(names, this.selectedHeaderItem),
        };
    },
    props: {
        selectedHeaderItem: {
            type: String,
            default: null,
        },
        firstOwnedAccountNamespaceOrAccountId: {
            type: String,
            default: null,
        },
        registeredPartnerOffersLink: {
            type: String,
            required: true,
        },
    },
    computed: {
        canSeeProspectingMap() {
            return _.get(Account.getAuthenticatedAccount(), 'canSeeProspectingMap');
        },
        canAccessAdsNotificationBoostsHistoryPage() {
            return Account.canDisplayAdsNotificationBoostsHistory();
        },
        canEnablePublicPage() {
            return Account.canEnablePublicPage();
        },
    },
    methods: {
        getText(key, context) {
            return this.t(key, {context});
        },
        getSmallText(key) {
            return this.t(key, {context: 'small'});
        },
    },
    template: template(),
};
