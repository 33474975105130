const _ = require('lodash');

module.exports = {
    getSerializeFunction,
};

const serializeFunctions = {
    boolean: serializeBoolean,
    number: serializeNumber,
    integer: serializeInteger,
};

function getSerializeFunction(data) {
    return serializeFunctions[data['data-serialization']]
        || serializeFunctions[data.type]
        || _.identity;
}

function serializeBoolean(value) {
    if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    }
}

function serializeNumber(value) {
    if ('' === value || null == value) {
        return null;
    } else {
        value = value
            .replace(/\s+/g, '')
            .replace(',', '.');
        return parseFloat(value);
    }
}

function serializeInteger(value) {
    if ('' === value || null == value) {
        return null;
    } else {
        return parseInt(value, 10);
    }
}
