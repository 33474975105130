const _ = require('lodash');
const latlonFormatter = require('latlon-formatter');
const lonLatParser = require('lon-lat-parser');

module.exports = {
    textToPosition,
    positionToText,
};

function positionToText(position) {
    if (position) {
        const options = {
            degrees: true,
            template: '{degree}° {prime}′ {doublePrime}″ {direction}',
        };
        return latlonFormatter.angle(position.lat, _.extend({}, options, {customTokens: formatLat}))
            + ', ' + latlonFormatter.angle(position.lng, _.extend({}, options, {customTokens: formatLng}));
    }
    return '';
}

function textToPosition(text) {
    return toPosition(lonLatParser(text));
}

function formatLat(f) {
    return {
        direction: f.sign >= 0 ? 'N' : 'S',
    };
}

function formatLng(f) {
    return {
        direction: f.sign >= 0 ? 'E' : 'O',
    };
}

function toPosition(lonLat) {
    if (lonLat) {
        return {
            lat: lonLat.lat,
            lng: lonLat.lon,
        };
    }
    return null;
}
