const LocationManager = require('../LocationManager');

module.exports = {
    loadSearchData,
};

function loadSearchData(options, cb) {
    if (!options.search && options.searchResults) {
        options.search = options.searchResults.searchCriteria;
    }
    if (!options.search) {
        options.search = {};
    }
    if (!options.search.locations) {
        LocationManager.addLocationNamesFromItemsInSearchCriteria(options.search, function (err) {
            if (err) {
                cb(err);
            } else {
                cb(null, options);
            }
        });
    } else {
        cb(null, options);
    }
}
