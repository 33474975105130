const _ = require('lodash');
const {convertGteToNumber} = require('../../../common/formatters/gteConvertor');
const Account = require('../../authentication/Account');
const AbTesting = require('../../AbTesting');
const ServerConfig = require('../../ServerConfig');

module.exports = {
    sendEstimation,
};

const HEATING_TYPE_CONVERSION = {
    electric: {
        heatingEnergy: 'electric',
    },
    subfloor: {
        heatingSource: 'subfloor',
    },
    'heat-pump': {
        heatingEnergy: 'heat-pump',
    },
    'gas-old': {
        heatingEnergy: 'gas',
        isHeatingRecent: false,
    },
    'gas-recent': {
        heatingEnergy: 'gas',
        isHeatingRecent: true,
    },
    community: {
        heatingOperator: 'community',
    },
};

function removeHiddenFieldsValues(realEstateProperty) {
    const estimatorData = _.clone(realEstateProperty);

    if (!estimatorData.hasBalcony) {
        delete estimatorData.balconySurfaceArea;
    }
    if (!estimatorData.hasTerrace) {
        delete estimatorData.terraceSurfaceArea;
    }
    return estimatorData;
}

function applyConversionsAndDefaults(userInputRealEstateProperty) {
    const estimatorData = convertGteToNumber(userInputRealEstateProperty);
    convertHeatingType(estimatorData);
    convertBathAndShowerRoomsQuantity(estimatorData);
    return _.defaults(estimatorData, {
        energyClassification: 'D',
        isLowEnergyBuilding: estimatorData.yearOfConstruction >= 2012,
    });
}

function isNotProvided(value) {
    return _.isNil(value);
}

function convertHeatingType(estimatorData) {
    if (estimatorData.heatingType) {
        _.extend(estimatorData, HEATING_TYPE_CONVERSION[estimatorData.heatingType]);
        delete estimatorData.heatingType;
    }
}

function convertBathAndShowerRoomsQuantity(estimatorData) {
    estimatorData.bathroomsQuantity = Math.ceil(estimatorData.bathAndShowerRoomsQuantity / 2);
    estimatorData.showerRoomsQuantity =
        estimatorData.bathAndShowerRoomsQuantity - estimatorData.bathroomsQuantity;
    delete estimatorData.bathAndShowerRoomsQuantity;
}

function sendEstimation({
    realEstateProperty,
    flatType,
    sender,
    transactionType,
    suggestedAgencyIds = null,
    sendEstimationRequestToAgencies = false,
}, cb) {
    const userInputRealEstateProperty = _.omitBy(
        _.extend(removeHiddenFieldsValues(realEstateProperty), flatType),
        isNotProvided
    );
    const realEstatePropertyForEstimator = applyConversionsAndDefaults(userInputRealEstateProperty);
    const data = {
        realEstatePropertyForEstimator,
        realEstateProperty: userInputRealEstateProperty,
        sender,
        transactionType,
        sendEstimationRequestToAgencies,
    };
    data.abTest = AbTesting.getSavedOptionsForEstimator();
    if (transactionType === 'buy') {
        data.suggestedAgencyIds = suggestedAgencyIds;
    }
    Account.getAccountAndCreateGuestIfNeeded(() => {
        Account.postJson({
            url: `${ServerConfig.config.estimationUrl}/estimation`,
            data,
            disableErrorPage: true,
            callback: cb,
        });
    });
}
