const _ = require('lodash');

const LoanSimulatorPage = require('../LoanSimulatorPage');
const Pages = require('../../pages/Pages');
const SimpleContentView = require('../../views/SimpleContentView');
const SimulatorFormView = require('../PinelSimulatorFormView');
const ContactFormView = require('./PinelContactFormView');

module.exports = class PinelSimulatorPage extends LoanSimulatorPage {
    constructor(configuration) {
        configuration = _.extend({
            name: 'pinelSimulator',
            gtmCategory: 'ToolsAndSimulators',
            gtmPrefix: 'pinel',
            requestUrl: '/getPinelSimulation',
            requestName: 'getPinelSimulation',
            defaultName: 'PinelSimulatorPage',
            title: 'Investir en loi Pinel',
            defaultContentPath: 'pinel-simulator',
        }, configuration);
        super(configuration);
    }

    _createViews() {
        this.contentView = new SimpleContentView();
        this.simulatorFormView = new SimulatorFormView();
        this.contactFormView = new ContactFormView();
    }

    getUrlPattern() {
        return '/investir-en-loi-pinel';
    }

    _getSimulatorPage() {
        return Pages.pinelSimulatorPage;
    }

    _getResultsPage() {
        return Pages.pinelResultsPage;
    }
};
