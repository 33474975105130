const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const Account = require('../authentication/Account');
const Error404 = require('../Error404');
const PageManager = require('./PageManager');
const PagesFactories = require('./PagesFactories');
const AgencyContactView = require('../views/AgencyContactView');
const EventPack = require('../utils/EventPack');

module.exports = class AgencyContactPage extends ApplicationPage {
    constructor(configuration) {
        configuration = _.defaults(configuration || {}, {
            name: 'agencyContactPage',
            title: 'Contactez une agence',
        });
        super(configuration);
        this.agencyContactView = new AgencyContactView();
        this._eventPack = new EventPack();
    }

    open(options) {
        super.open(_.extend({
            gtmFrom: 'AgencyContactPage',
            accountId: this.accountId,
            displayName: options.account.display_name,
        }, options));
        this._eventPack.on(this.agencyContactView, 'close', () => {
            PageManager.openPage(PagesFactories.userRealEstateAdsPageFactory, {authorId: this.accountId});
        });
    }

    close() {
        this._eventPack.removeAllListeners();
        super.close();
    }

    getMiddleViews() {
        return this.agencyContactView;
    }

    _loadData({namespaceOrId}, cb) {
        if (namespaceOrId) {
            Account.get(namespaceOrId, (err, account) => {
                if (err) {
                    if (err.code === 404) {
                        cb(new Error404(err.message));
                    } else {
                        cb(err);
                    }
                } else if (account) {
                    this.accountId = account.id;
                    this.accountNamespace = account.namespace;
                    cb(null, {
                        namespaceOrId,
                        account,
                    });
                } else {
                    cb(new Error404('account not found'));
                }
            });
        } else {
            cb(new Error404('no account id'));
        }
    }

    parseUrl(url, namespaceOrId) {
        return {
            namespaceOrId,
        };
    }

    getUrlPattern() {
        return '/agence/{namespaceOrId}/contact';
    }

    getUrl() {
        return `/agence/${this.accountNamespace || this.accountId}/contact`;
    }
};
