const template = require('../templates/commonComponents/lostPasswordForm.jade');
const {i18n: {translate}} = require('fack');
const CompositeVueView = require('../vue/CompositeVueView');
const _ = require('lodash');
const FormUtils = require('../fields/FormUtils');
const Account = require('../authentication/Account');
const History = require('../utils/History');
const Router = require('../utils/Router');
const ApplicationConfig = require('../app/ApplicationConfig');

module.exports = class LostPasswordView extends CompositeVueView {
    constructor(options) {
        super({
            template,
        });
        this.options = options;
    }

    show(options) {
        const vueOptions = {
            computed: {
                headerTitle() {
                    return this.lostPasswordTranslate('h1');
                },
                headerSubtitle() {
                    return this.lostPasswordTranslate('h2');
                },
            },
            methods: {
                lostPasswordTranslate(key) {
                    return translate(`lostPassword.${key}`);
                },
            },
        };
        super.show(options, vueOptions);
    }

    createElement() {
        this.$element = this.renderTemplate(this.template, {
            requiresEmailCredentialId: Boolean(ApplicationConfig.requiresPublicAccount),
            showLostUserNameLink: Boolean(ApplicationConfig.requiresProAccount),
        });
        this.$form = this.$element.find('form');
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this.submitForm, this),
        });

        return this.$element;
    }

    hide(options, cb) {
        FormUtils.clear({
            $form: this.$form,
        });
        super.hide(options, cb);
    }

    submitForm(cb) {
        const data = FormUtils.readValues(this.$form);
        if (data && data.credentialId) {
            Account.requestResetAccountPassword({
                id: data.credentialId,
            }, (err, value) => {
                if (err || (value && !value.success)) {
                    const $errorModal = this.$element.find('.errorModal');
                    let errorKey = 'lostPassword.error';
                    if (err && err.code == 404) {
                        errorKey = 'lostPassword.accountNotFound';
                    } else if (value && value.reason && value.reason == 'closedOrDisabled') {
                        errorKey = 'lostPassword.closedOrDisabled';
                    } else {
                        console.error('an error occurred when calling password reset', err);
                    }
                    $errorModal.find('.modal-text').text(translate(errorKey));
                    $errorModal.modal({
                        backdrop: 'static',
                        show: true,
                    });
                } else if (value && value.success) {
                    const successUrl = '/';
                    this.$element.find('.successModal')
                        .one('hidden.bs.modal', function () {
                            _.defer(function () {
                                History.pushState(null, null, successUrl);
                                Router.parseUrl(successUrl);
                            });
                        })
                        .modal({
                            backdrop: 'static',
                            keyboard: false,
                            show: true,
                        });
                }
                cb();
            });
        }
    }
};
