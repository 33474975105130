const _ = require('lodash');
const {resourceUrl} = require('fack');
const template = require('./AgencyMap.jade');
const isValidPosition = require('../../utils/isValidPosition');
const MapApi = require('../../MapApi');
const {kartoConfig} = require('../../../common/DefaultConfiguration');

const MARKERS_IMAGES_FOLDER_PATH = 'images/map/markers/';
const MARKER_ICON_URL_DEFAULT = resourceUrl(`${MARKERS_IMAGES_FOLDER_PATH}agency.png`);

// @vue/component
module.exports = {
    props: {
        agency: {
            type: Object,
            required: true,
        },
        useKarto: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: null,
        },
        markerIconUrl: {
            type: String,
            default: MARKER_ICON_URL_DEFAULT,
        },
    },
    computed: {
        mapProvider() {
            return this.useKarto ? 'kimono-karto-map' : 'kimono-map';
        },
        agencyPosition() {
            return this.agency.position;
        },
        agencyLocation() {
            const {agencyPosition} = this;
            return agencyPosition && getLocationFromAgencyPosition(agencyPosition);
        },
        zoomWithPosition() {
            return this.useKarto ? 14 : 15;
        },
        defaultConfigurationZoom() {
            return this.useKarto ? 5 : 6;
        },
        zoom() {
            const {zoomWithPosition, defaultConfigurationZoom} = this;
            return this.agencyLocation ? zoomWithPosition : defaultConfigurationZoom;
        },
        mapOptions() {
            const {agencyLocation, zoom, options, useKarto} = this;
            const {lng, lat} = kartoConfig.defaultCenter;
            const baseOptions = {
                zoom,
                center: useKarto ? [
                    _.get(agencyLocation, 'lng', lng),
                    _.get(agencyLocation, 'lat', lat),
                ] : agencyLocation,
            };
            return _.extend(baseOptions, useKarto ? {...kartoConfig, ...options} : options);
        },
    },
    methods: {
        handleMapCreated(map) {
            const position = map.getCenter(); // default position to avoid errors on setVisible
            if (this.agencyLocation) {
                this.$emit('has-position');
                if (this.useKarto) {
                    map.graphicsPresetsManager.setAutoUpdate(false);
                    map.graphicsPresetsManager.setCurrentPresetFromName(kartoEngine.GraphicsPresetName.LOW);
                    map.createMarker3D(
                        {
                            position: [position.lng, position.lat],
                            altitude: 28,
                            line: {
                                enable: true,
                            },
                            icon: {
                                url: this.markerIconUrl,
                            },
                            clickable: false,
                            zIndex: 1,
                        }
                    );
                } else {
                    new MapApi.api.Marker({
                        icon: {
                            url: this.markerIconUrl,
                        },
                        map,
                        position,
                        clickable: false,
                    });
                }
            }
            this.$emit('created', map);
        },
    },
    template: template(),
};

function getLocationFromAgencyPosition(agencyPosition) {
    const {location, lat, lon: lng} = agencyPosition;
    if (!isValidPosition({lat, lng}) && !location) {
        return undefined;
    }
    return location || {lat, lng};
}
