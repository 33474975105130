const {EventEmitter} = require('events');
const _ = require('lodash');
const moment = require('../../common/momentFr');
const SunCalc = require('suncalc');
const SunAnimation = require('./SunAnimation');

const ANIM_DURATION_IN_MS = 500;

module.exports = class SunAnimator extends EventEmitter {
    constructor() {
        super();
        this._sunAnimation = new SunAnimation();
        this._onMapIdleHandler = _.bind(this._updateDateInMap, this);
    }

    setMap(map) {
        const previousMap = this._map;
        if (previousMap != map) {
            if (previousMap) {
                previousMap.removeListener('idle', this._onMapIdleHandler);
                previousMap.setDate(this._mapBackups.date); // set the date first as it clears the sun position
                previousMap.setSunPosition(this._mapBackups.sunPosition);
                delete this._mapBackups;
            }
            this._map = map;
            if (map) {
                map.on('idle', this._onMapIdleHandler);
                this._mapBackups = {
                    date: map.getDate(),
                    sunPosition: map.getSunPosition(),
                };
            }
            delete this._dateAndDayTimePercent;
            this.setDateAndDayTimePercent({
                //use current year
                date: moment.utc().month(3/*April*/).date(23).startOf('day').toDate(),
                dayTimePercent: 75,
            });
        }
    }

    animateDateAndDayTimePercent(dateAndDayTimePercent) {
        this.setDateAndDayTimePercentAnimated(dateAndDayTimePercent);
    }

    setDateAndDayTimePercent(dateAndDayTimePercent) {
        if (!_.isEqual(this._dateAndDayTimePercent, dateAndDayTimePercent)) {
            this._dateAndDayTimePercentWithoutAnimation = _.clone(dateAndDayTimePercent);
            this._setDateAndDayTimePercent(dateAndDayTimePercent);
        }
    }

    getDateAndDayTimePercent() {
        return _.clone(this._dateAndDayTimePercentWithoutAnimation);
    }

    isPlaying() {
        return this._sunAnimation.isPlaying();
    }

    _setDateAndDayTimePercent(dateAndDayTimePercent) {
        this._dateAndDayTimePercent = dateAndDayTimePercent;
        this.emit('dateChange', {dateAndDayTimePercent});
        this._updateDateInMap();
    }

    setDateAndDayTimePercentAnimated(dateAndDayTimePercent) {
        this._dateAndDayTimePercentWithoutAnimation = _.clone(dateAndDayTimePercent);
        if (this._map) {
            this._sunAnimation.play({
                startDateAndDayTimePercent: this._dateAndDayTimePercent,
                endDateAndDayTimePercent: dateAndDayTimePercent,
                animDuration: ANIM_DURATION_IN_MS,
                updateCallback: dateAndDayTimePercent => this.setDateAndDayTimePercent(dateAndDayTimePercent),
            });
        } else {
            this.setDateAndDayTimePercent(dateAndDayTimePercent);
        }
    }

    _computeMapDate(dateAndDayTimePercent) {
        const msPerHours = 60 * 60 * 1000;
        const msPerDegreesLon = (24 * msPerHours) / 360;
        const center = this._map.getCenter();
        const lon = center.lng();
        const lat = center.lat();
        const noon = dateAndDayTimePercent.date.valueOf() + (12 * msPerHours) - msPerDegreesLon * lon;
        const sunCalcProperties = SunCalc.getTimes(new Date(noon), lat, lon);
        let start = sunCalcProperties.sunrise.valueOf();
        let end = sunCalcProperties.sunset.valueOf();
        if (isNaN(start) || isNaN(end)) {
            start = noon - 12 * msPerHours;
            end = noon + 12 * msPerHours;
        }
        return new Date(Math.round(start + ((end - start) * dateAndDayTimePercent.dayTimePercent / 100)));
    }

    _updateDateInMap() {
        if (this._map) {
            const date = this._computeMapDate(this._dateAndDayTimePercent);
            this._map.setDate(date);
        }
    }

};
