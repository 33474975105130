const _ = require('lodash');
const $ = require('jquery');
const {i18n: {translate}} = require('fack');

const SideMapPage = require('./SideMapPage');
const Pages = require('./Pages');
const PagesFactories = require('./PagesFactories');
const PageManager = require('./PageManager');
const Urls = require('../Urls');
const Views = require('../views/Views');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const ViewManager = require('../views/ViewManager');
const History = require('../utils/History');
const DetailedSheetView = require('../DetailedSheetView');
const SimpleView = require('../views/SimpleView');
const adNotFoundTemplate = require('../templates/adNotFound.jade');
const DetailedSheetsNavView = require('../DetailedSheetsNavView');
const SearchDataLoader = require('../search/SearchDataLoader');
const AsyncHelper = require('../utils/AsyncHelper');
const RealEstateAdLoader = require('../RealEstateAdLoader');
const RealtimeServer = require('../RealtimeServer');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const SideMapHelperView = require('../views/SideMapHelperView');
const Title = require('../pages/Title');
const Account = require('../authentication/Account');
const async = require('async');
const MapCreation = require('../utils/MapCreation');
const ProgrammeHelper = require('../../common/ProgrammeHelper');
const ApplicationConfig = require('../app/ApplicationConfig');
const BrowserDetect = require('browser-detect');
const SelectOwnedAccountView = require('../views/SelectOwnedAccountView');
const GoogleTagManager = require('../stats/GoogleTagManager');
const EventPack = require('../utils/EventPack');
const SavedSearches = require('../search/SavedSearches');
const adIsClosedForTooLong = require('../../common/adIsClosedForTooLong');
const DetailedSheetBehindAuthenticationView = require('../views/DetailedSheetBehindAuthenticationView');
const {rateTheApp} = require('../utils/NativeInterfaceProxy');
const DetailedSheetStatsContext = require('../stats/DetailedSheetStatsContext');
const UserTracking = require('../stats/UserTracking');
const urlUtil = require('url');
const SearchUrlPrefixes = require('../../common/SearchUrlPrefixes');
const MapHelper = require('../utils/MapHelper');
const metaTagHelper = require('../utils/metaTagHelper');
const AdModificationForbiddenAccessPage = require('../errorPages/AdModificationForbiddenAccessPage');
const AuthenticationPage = require('../pages/AuthenticationPage');
const {canMarkAdsAsLeading} = require('../setFeatured');
const {setCanonicalLink} = require('../utils/SeoUtils.js');
const {getDidomiUserId} = require('../cmp/DidomiHelper');

const NON_PATTERN_PREFIXES = _.omitBy(SearchUrlPrefixes, (value, key) => /Pattern$/.test(key));
const CAMERA_ANIMATION_DELAY = 1500;
const DETAILEDSHEET_MAX_ZOOM = 18;

module.exports = class DetailedSheetPage extends SideMapPage {
    /**
     * @constructor
     * @augments Page
     * @param {object} [configuration]
     * @param {boolean} [configuration.showMap]
     * @param {boolean} [configuration.showDescription]
     * @param {boolean} [configuration.isMobile]
     * @param {object} [configuration.advancedSearchView] use for mobile
     */
    constructor(configuration) {
        configuration = _.defaults({}, configuration, {
            isMobile: false,
            showMap: true,
            showDescription: true,
            pageRedirectionOnLogout: false,
            requiresRegistration: false,
            createSearchNavigationIfMissing: true, //pro website shouldn't do it
            name: 'detailedSheet',
            type: 'detailedSheet',
            saveSearchAllowed: true,
            cameraAnimationDelay: CAMERA_ANIMATION_DELAY,
            gtmPersistentFields: ['resultsShown', 'detailedSheetOpened'],
            gtmCategory: 'DetailedSheets',
            partnerServicesEnabled: true,
            enableCguLinkFullText: false,
            dataModeListEnabled: true,
            pageContext: 'public',
        });

        // TODO : it would be better to improve Searchview.update
        if (!configuration.isMobile && Account.isShowRoom()) {
            configuration.saveSearchAllowed = false;
        }

        super(configuration);
        this.configuration = configuration;

        this.reloadPageOnLogin = true;
        this.pageRedirectionOnLogout = this.configuration.pageRedirectionOnLogout;
        this.requiresRegistration = this.configuration.requiresRegistration;
        this.createSearchNavigationIfMissing = this.configuration.createSearchNavigationIfMissing;
        this.asyncHelper = new AsyncHelper();
        this._accountSelectedHandler = _.bind(this._onAccountSelected, this);
        this._eventPack = new EventPack();
    }

    _handleAdUpdateNotification(message) {
        const isInRelatedAds = _.find(this.realEstateAd.relatedAds, {id: message.id});
        const filters = _.invoke(this._getSearchResults(), 'getFilters');
        if (message.id == this.realEstateAdId) {
            this._doAsyncLoadRealEstateAd({
                realEstateAdId: this.realEstateAdId,
                filters,
                callback: (err, realEstateAd) => {
                    if (err) {
                        console.warn('Could not reload ad', err);
                    } else if (!realEstateAd) {
                        console.error('no ad from _handleAdUpdateNotification');
                        console.trace();
                    } else {
                        this.realEstateAd = realEstateAd;
                        // programme in lot is now properly loaded
                        this._setProgrammeOrLotIfNeeded();
                    }
                },
                name: 'reloadDetailedSheetAd',
            });
        } else if (isInRelatedAds) {
            this._doAsyncLoadRealEstateAd({
                realEstateAdId: message.id,
                filters,
                callback: (err, realEstateAd) => {
                    if (err) {
                        console.warn('Could not reload ad', err);
                    } else {
                        this.realEstateAd.relatedAds[message.id] = realEstateAd;
                    }
                },
                name: 'reloadRelatedAd',
            });
        }
    }

    open(options, cb) {
        this.options = options;
        this._setOpenPageOptions(options);
        this.realEstateAd = options.realEstateAd;
        this.previousRealEstateAd = options.previousRealEstateAd;
        this._showNeighborhoodInfoOnMap = options.showNeighborhoodInfoOnMap;
        if (options.lastMapVisible != null) {
            this._lastMapVisible = options.lastMapVisible;
        }
        this.realEstateAdId = this.realEstateAd ? this.realEstateAd.id : options.realEstateAdId;
        this.incompleteRealEstateAd = options.incompleteRealEstateAd;
        this.neighborhoodInfo = options.neighborhoodInfo;
        this.detailedSheetScrollTo = options.scrollTo;
        _.extend(options, this._getFromSuggestionOptions(options));
        const defaultSearch = options.defaultSearch;
        const defaultClosePageUrl = defaultSearch ? Urls.search.makeUrl(defaultSearch) : null;
        options.defaultClosePageUrl = this._defaultClosePageUrl = defaultClosePageUrl;
        if (!options.closePageUrl && options.search) {
            options.closePageUrl = Urls.search.makeUrl(options.search);
        }
        this._closePageUrl = options.closePageUrl;
        if (options.closePageUrl === options.defaultClosePageUrl) {
            options.closePageUrl = null;
        }
        this.detailedSheetView = null;
        options = _.extend(options, this.configuration);
        this._initSearchAndMapControllers(options);
        this._eventPack.on(this.mapController, {
            mapExpanded: _.bind(this._onMapExpanded, this),
            toggledMap: _.bind(this._onMapToggled, this),
            adClick: _.bind(this._openAd, this),
        });
        this.detailedSheetsNavView = new DetailedSheetsNavView(this._getDetailedSheetNavOptions());

        this._eventPack.on(this.detailedSheetsNavView, {
            adClick: _.bind(this._openAd, this),
            closeDetailedSheet: _.bind(this._reopenPage, this),
            closeLot: _.bind(this._handleCloseLot, this),
            closeContactSection: _.bind(this.closeContactSection, this),
            toggleMapClick: _.bind(this._toggleMap, this),
        });

        this._openContactSectionFromMapHandler = () => {
            if (this.configuration.isMobile) {
                const options = {
                    scrollTo: 'contact',
                    contactOptions: {
                        type: 'map',
                    },
                };
                this._toggleMap(options);
            } else {
                this.openContactSection('map');
            }
            if (SideMapViewSingleton.get().isMapExpanded()) {
                SideMapViewSingleton.get().closeMapExpand();
            }
        };
        SideMapViewSingleton.get().on('openContactSectionFromMap', this._openContactSectionFromMapHandler);
        if (this.configuration.showDescription) {
            this.detailedSheetView = this.getDetailedSheetViewToDisplay(options);
            this._eventPack.on(this.detailedSheetView, {
                openContactSection: _.bind(this.openContactSection, this),
                showContactSection: _.bind(this.showContactSection, this),
                adClick: _.bind(this._openAd, this),
                closeContactSection: _.bind(this.closeContactSection, this),
                DOMready: _.bind(this.handleLoadAsyncData, this, options),
                print: _.bind(this._onPrintDetailedSheetView, this),
                saveSearch: _.bind(this.saveSearch, this),
                toggleNeighborhoodInfo: _.bind(this._toggleNeighborhoodInfo, this),
                statusChange: _.bind(this._onStatusChange, this),
                lotMouseHovered: (realEstateAd, hovered) => {
                    SideMapViewSingleton.get().setLotHovered(realEstateAd, hovered);
                },
            });
        }
        const {context, openedFromList: openedFrom, isHighlightedInList} = this.options;
        // duplicate inHighlightedBox and only use inLeadingAdsBox for statistics
        // TODO remove inHighlightedBox after data team confirmation
        const inLeadingAdsBox = context && context.inLeadingAdsBox;
        const detailedSheetStatsContext = {
            inHighlightedBox: inLeadingAdsBox,
            inLeadingAdsBox,
            openedFrom,
            isHighlightedInList,
        };
        DetailedSheetStatsContext.init(this.realEstateAd, detailedSheetStatsContext);
        _.extend(options, {
            updateUrl: _.bind(this.updateUrl, this),
            mapFilterBtnEnabled: false,
        });
        options.showMap = this.showMap = this._canShowMap();
        if (this._requiresAnotherAccount(options)) {
            const pageToOpen = Account.isRegistered() ? new AdModificationForbiddenAccessPage() : new AuthenticationPage();
            PageManager.openPage(pageToOpen);
        } else if (this.showMap) {
            // Load map if not already loaded
            SideMapViewSingleton.get().loadData(options, () => {
                this.openAndInitViews(options, cb);
            });
            SideMapViewSingleton.get().toggleGeolocButton(false);
        } else {
            this.openAndInitViews(options, cb);
        }
        if (this.selectOwnedAccountView) {
            this.selectOwnedAccountView.on('accountSelected', this._accountSelectedHandler);
        }
    }

    _requiresAnotherAccount({setAsLeading}) {
        return setAsLeading && !canMarkAdsAsLeading(this.realEstateAd);
    }

    _getFromSuggestionOptions({closeAdSuggestionPageOptions, context, closePageUrl, camera}) {
        const fromSuggestionAdId = _.get(context, 'fromSuggestion');
        if (!closeAdSuggestionPageOptions && fromSuggestionAdId) {
            return {
                closeAdSuggestionPageOptions: {closePageUrl},
                closePageUrl: Urls.suggestion.makeUrl(fromSuggestionAdId, closePageUrl, camera),
                headerTitle: translate('adSuggestions.backLabelExtraContent'),
            };
        }
    }

    saveSearch() {
        if (this.searchController) {
            this.searchController.showSaveSearch();
        }
    }

    _onAccountSelected(accountId, onlyOwnData) {
        if (accountId == Account.getAuthenticatedAccountId()) {
            PageManager.openPage(PagesFactories.myRealEstateAdsPageFactory, {onlyOwnData});
        } else {
            PageManager.openPage(PagesFactories.userRealEstateAdsPageFactory, {
                authorId: accountId,
                onlyOwnData,
            });
        }
    }

    _onPrintDetailedSheetView() {
        RealtimeServer.emit('detailedSheetView:print', {
            account: {
                id: Account.getAuthenticatedAccountId(),
            },
        });
    }

    _onClickDetailedSheetMarker(ad, event) {
        if (this._canShowMap()) {
            this.mapController.openDetailedSheetWhenAdClick(ad, null, event);
        }
    }

    openAndInitViews(options, callback) {
        _.extend(options, {
            hasSearchPageSplitScreen: this.mapController.getSplitScreenStatus(),
            doesWindowSupportSplitScreen: this.mapController.doesWindowSupportSplitScreen(),
        });
        ViewManager.openViews(this.getViews(), options);
        this.emit('readyToBeShown');
        this._registerRealtimeEvents();
        const mapController = this.mapController;
        this.detailedSheetsNavView.setSearchResults(this._getSearchResults());
        const searchController = this.searchController;
        if (searchController) {
            searchController.updateCardClass(options.realEstateAd);
            if (this.configuration.showDescription) {
                searchController.updateAdViewedStatus(options.realEstateAd);
            }
        }
        this.handleWindowResizeCallback = _.bind(this.handleWindowResize, this);
        $(window).on('resize', this.handleWindowResizeCallback);
        $(window).on('orientationchange', this.handleWindowResizeCallback);
        this._handleToggleFullScreenSlideShow = _.bind(mapController.handleToggleMap, mapController);
        if (this.detailedSheetView && this.showMap) {
            this.detailedSheetView.on('toggleSlideShowFullScreen', this._handleToggleFullScreenSlideShow);
        }
        const $body = $('body');
        $body.addClass('detailedSheetMap');
        if (!BrowserDetect.isMobile()) {
            this._updateShowMap(true);
        }
        if (this.showMap) {
            SideMapViewSingleton.get().closeMapExpand();
        }
        this._initDetailedSheetToggleMapButton();
        this.bindGTMEvents(options);
        //wait for map to be created before finishing open()
        callback();
    }

    handleLoadAsyncData(options) {
        this._loadMissingData(options);
        this.asyncHelper.doAsync({
            func: cb => _.defer(cb),
            callback: () => this.initDetailedSheetDescription(),
            name: 'detailedSheetDescriptionOnInit',
        });
    }

    handleWindowResize() {
        const width = $(window).width();
        if (width != this._lastWidth) {
            if (!SideMapViewSingleton.get().isMapExpanded()) {
                this._updateShowMap();
            }
            this._lastWidth = width;
        }
    }

    _canShowMap() {
        if (this.configuration.isMobile) {
            return this.configuration.showMap;
        }
        return (this.mapController.doesWindowSupportFullScreen() && this.configuration.showMap);
    }

    _updateShowMap(forceUpdateShowMap) {
        this._setShowMap(this._canShowMap(), forceUpdateShowMap);
    }

    _setShowMap(showMap, forceUpdateShowMap) {
        if (this.showMap != showMap || forceUpdateShowMap) {
            this.showMap = showMap;
            if (!this.showMap) {
                if (this.detailedSheetView) {
                    this.detailedSheetView._toggleNeighborhoodInfo(false);
                }
            } else {
                SideMapViewSingleton.get().initDetailedSheetMap(this.realEstateAd);
            }
            this.mapController.handleToggleMap({
                showMap,
                forceUpdateShowMap,
                showDrawingButton: false,
            });
        }
    }

    _toggleNeighborhoodInfo(visible) {
        if (visible) {
            const mustToggleMap = !this.showMap;
            if (mustToggleMap) {
                this._showNeighborhoodInfoOnMap = mustToggleMap;
                this._toggleMap();
            } else if (!this.configuration.isMobile) {
                this._showNeighborhoodOverlays();
            }
        } else {
            this._hideNeighborhoodOverlays();
        }
    }

    _onMapToggled(visible) {
        const showMap = this.showMap;
        const mapController = this.mapController;
        mapController.setMapModeAvailable(showMap);
        const detailedSheetView = this.detailedSheetView;
        if (detailedSheetView) {
            detailedSheetView.setMapEnabled(showMap);
        }
        const detailedSheetsNavView = this.detailedSheetsNavView;
        if (detailedSheetsNavView) {
            detailedSheetsNavView.setMapEnabled(showMap);
        }
        if (visible) {
            const hasMapVisibilityChanged = (this._lastMapVisible != visible) || this.configuration.isMobile;
            if (hasMapVisibilityChanged) {
                this._teleportWhenShowMap = true;
            }
            this.emit('toggledMap', true);
            mapController.enableMapHover();

            if (!this._onCloseLot3dHandler) {
                this._onCloseLot3dHandler = _.bind(this._handleCloseLot, this);
                SideMapViewSingleton.get().on('closeLot', this._onCloseLot3dHandler);
            }

            this._setProgrammeOrLotIfNeeded();

            const canClickOnMarker = (mapController.canClickOnDetailedSheetMarker() && this._canShowMap());
            let onClickDetailedSheetMarkerCallback = null;
            if (canClickOnMarker) {
                onClickDetailedSheetMarkerCallback = _.bind(this._onClickDetailedSheetMarker, this, this.realEstateAd);
            }
            mapController.setDetailedSheetAd(this.realEstateAd, onClickDetailedSheetMarkerCallback);

            this._initCamera();
        } else if (this._onCloseLot3dHandler) {
            SideMapViewSingleton.get().removeListener('closeLot', this._onCloseLot3dHandler);
            delete this._onCloseLot3dHandler;
        }
        this._lastMapVisible = visible;
    }

    _setProgrammeOrLotIfNeeded() {
        if (this.realEstateAd.programmeRef) {
            SideMapViewSingleton.get().setSelectedLot(this.realEstateAd, true);
        } else {
            SideMapViewSingleton.get().setSelectedLot(null);
            SideMapViewSingleton.get().setSelectedProgramme(this.realEstateAd, true);
        }
    }

    _initDetailedSheetToggleMapButton() {
        if (this.detailedSheetsNavView) {
            this.detailedSheetsNavView.setMapEnabled(this.showMap, SideMapViewSingleton.get().isMapExpanded());
        }
    }

    _toggleMap(options) {
        if (this.configuration.isMobile) {
            let newPage;
            const showMap = !this.showMap;
            if (showMap && !this.showMap) {
                newPage = Pages.detailedSheetMap;
            } else if (!showMap && !this.configuration.showDescription) {
                newPage = Pages.detailedSheetDescription;
            }
            if (newPage) {
                const {
                    reopen,
                    reopenOptions,
                    closeAdSuggestionPageOptions,
                } = this.options;
                const openOptions = {
                    reopen,
                    reopenOptions,
                    realEstateAd: this.realEstateAd,
                    neighborhoodInfo: this.neighborhoodInfo,
                    showNeighborhoodInfoOnMap: this._showNeighborhoodInfoOnMap,
                    showMap,
                    lastMapVisible: this.showMap,
                    closeAdSuggestionPageOptions,
                };
                _.extend(openOptions, options, this.options);
                super._openPage(newPage, openOptions);
            }
        } else {
            this._expandMapHandler = _.bind(this._expandMap, this);
            this.on('toggledMap', this._expandMapHandler);
            this._setShowMap(true, false);
        }
    }

    _expandMap() {
        SideMapViewSingleton.get().openMapExpand();
        if (this._expandMapHandler) {
            this.removeListener('toggledMap', this._expandMapHandler);
            delete this._expandMapHandler;
        }
    }

    _onMapExpanded() {
        this._updateShowMap();
    }

    _handleCloseLot() {
        const adToGoBack = this.realEstateAd.programme || this.realEstateAd.residence;
        const adId = ProgrammeHelper.getProgrammeOrResidenceId(this.realEstateAd);
        if (adToGoBack && adId) {
            this._openAd({
                realEstateAd: adToGoBack,
                realEstateAdId: adId,
                direction: 'previous',
            });
        }
    }

    _initSearchAndMapControllers(options) {
        this.searchController = options.searchController;
        if (!options.mapController) {
            options.mapController = new SideMapHelperView(options);
        }
        this.mapController = options.mapController;
    }

    _initCamera() {
        if (this._showNeighborhoodInfoOnMap) {
            this._showNeighborhoodOverlays();
        } else {
            const cameraFromUrl = (this.searchController && this.searchController.camera) || this.options.camera;
            // Verify that we have camera information and that it is valid
            const sideMapViewSingleton = SideMapViewSingleton.get();
            if (cameraFromUrl && _.isObject(cameraFromUrl)) {
                sideMapViewSingleton.setCamera(cameraFromUrl, {forceCameraChanged: this._teleportWhenShowMap});
            } else {
                const onArrivalCallback = this._getSetHoverEnabledCallback();
                const asyncCameraOptions = {
                    waitForEventsCallback: _.bind(this._waitForEvents, this),
                    mustAbortMoveCameraToPosition: _.bind(this.mustAbortMoveCameraToPosition, this),
                    waitDelay: this.options.cameraAnimationDelay,
                };
                const mapAngles = MapHelper.getAnglesFromMapType(sideMapViewSingleton.map.getMapTypeId());
                sideMapViewSingleton.moveCameraToRealEstateAd(this.realEstateAd, {
                    zoom: DETAILEDSHEET_MAX_ZOOM,
                    theta: mapAngles.theta,
                    phi: mapAngles.phi,
                    teleport: this._teleportWhenShowMap,
                    asyncCameraOptions,
                    onArrivalCallback,
                });
            }
            this._teleportWhenShowMap = false;
        }
    }

    _waitForEvents(callback) {
        this._realEstateAdOnWaitForEvent = this.realEstateAd;
        if (this.detailedSheetView) {
            const eventsToWait = this.detailedSheetView.getEventsCameraToWait(this.options);
            async.map(eventsToWait, (eventName, cb) => {
                if (this.detailedSheetView) {
                    this.detailedSheetView.once(eventName, cb);
                }
            }, callback);
        } else {
            callback();
        }
    }

    mustAbortMoveCameraToPosition() {
        return (this._realEstateAdOnWaitForEvent.id != (this.realEstateAd && this.realEstateAd.id));
    }

    _getSetHoverEnabledCallback() {
        return this.mapController.getSetHoverEnabledCallback();
    }

    getViews() {
        const views = [
            Views.header,
            this.showMap ? SideMapViewSingleton.get() : null,
            this.mapController,
            this.detailedSheetView,
            this.detailedSheetsNavView,
        ];
        const showOwnedAccountSelection = !BrowserDetect.isMobile() && this.options.showOwnedAccountSelection;
        if (showOwnedAccountSelection) {
            if (!this.selectOwnedAccountView) {
                this.selectOwnedAccountView = new SelectOwnedAccountView({
                    menu: 'detailedSheetPage',
                });
            }
            views.push(this.selectOwnedAccountView);
        }
        return views;
    }

    initDetailedSheetDescription() {
        const {
            configuration: {showDescription},
            isAdFound,
            showMap,
            detailedSheetView,
        } = this;
        if (showDescription && isAdFound) {
            this.mapMode = showMap;
            this._toggleMapStyle(showMap);
            Title.setTitle(this.getTitle());
        }
        if (detailedSheetView) {
            detailedSheetView.detailSheetDescriptionInitialized = true;
            detailedSheetView.emit('detailSheetDescriptionInitialized');
        }
    }

    _showNeighborhoodOverlays() {
        delete this._showNeighborhoodInfoOnMap;
        this.asyncHelper.doAsync({
            func: cb => RealEstateAdLoader.loadNeighborhoodOverlays(this.realEstateAd, cb),
            callback: (err, overlays) => {
                if (err) {
                    console.error('Error loading neighborhood overlays ', err);
                } else {
                    this.neighborhoodOverlays = overlays;
                    _.each(this.neighborhoodOverlays, overlay => {
                        SideMapViewSingleton.get().addOverlay(overlay);
                    });
                    SideMapViewSingleton.get().moveCameraToNeighborhoodZone(overlays, this._teleportWhenShowMap);
                }
            },
            name: 'loadNeighborhoodOverlays',
        });
    }

    _hideNeighborhoodOverlays() {
        this.asyncHelper.cancel('loadNeighborhoodOverlays');
        if (this.neighborhoodOverlays) {
            _.each(this.neighborhoodOverlays, overlay => {
                SideMapViewSingleton.get().removeOverlay(overlay);
            });
            this.neighborhoodOverlays = null;
        }
        delete this._showNeighborhoodInfoOnMap;
    }

    openContactSection(type, options) {
        const {detailedSheetView} = this;
        const {slideshowWidget} = detailedSheetView;
        if (slideshowWidget) {
            slideshowWidget.exitFullscreen();
        }
        this.toggleContactSection(detailedSheetView.canShowContactForms(), _.extend({
            openedFrom: type,
        }, options));
        this.updateUrl({pushToHistory: true});
    }

    showContactSection() {
        this.toggleContactSection(false);
    }

    closeContactSection() {
        this.toggleContactSection(false);
        this.updateUrl({pushToHistory: true});
    }

    toggleContactSection(isVisible, options) {
        if (this.detailedSheetsNavView.isShown()) {
            this.detailedSheetsNavView.$element.toggleClass('scrollUp', isVisible);
        }
        this.detailedSheetScrollTo = isVisible ? 'contact' : null;
        if (this.detailedSheetView) {
            this.detailedSheetView.toggleMoreInfoContact(isVisible, options);
        }
        if (isVisible) {
            metaTagHelper.setMetaTags({robots: 'noindex'});
        } else {
            metaTagHelper.removeMeta('robots');
        }
    }

    updateUrl(options) {
        if (options && options.pushToHistory) {
            History.pushState(History.getState(), document.title, this.getUrl());
        } else {
            History.replaceState(History.getState(), document.title, this.getUrl());
        }
    }

    close(options, nextPage) {
        if (!nextPage || nextPage !== this) {
            // unselect programme only if going out of DetailedSheetPage,
            // to avoid unselecting/reselecting the programme, and allow us to keep the camera
            this.unselectProgrammeOrLot();
        }
        if (this._onCloseLot3dHandler) {
            SideMapViewSingleton.get().removeListener('closeLot', this._onCloseLot3dHandler);
            delete this._onCloseLot3dHandler;
        }

        this._unregisterRealtimeEvents();
        this._hideNeighborhoodOverlays();
        this.mapController.clearDetailedSheetAd(options);
        this.asyncHelper.cancelAll();
        const $body = $('body');
        $body.removeClass('detailedSheetMap');
        if (this._openContactSectionFromMapHandler) {
            SideMapViewSingleton.get().removeListener('openContactSectionFromMap', this._openContactSectionFromMapHandler);
            delete this._openContactSectionFromMapHandler;
        }

        if (this._expandMapHandler) {
            this.removeListener('toggledMap', this._expandMapHandler);
            delete this._expandMapHandler;
        }
        if (this.detailedSheetView) {
            this.removeDetailedSheetViewListeners();
        }
        this._eventPack.removeAllListeners();
        this.removeWindowListeners();
        this.realEstateAd = null;
        if (this.selectOwnedAccountView) {
            this.selectOwnedAccountView.removeListener('accountSelected', this._accountSelectedHandler);
            delete this.selectOwnedAccountView;
        }
        super.close();
    }

    unselectProgrammeOrLot() {
        const sideMapView = SideMapViewSingleton.get();
        sideMapView.setSelectedLot(null);
        sideMapView.setSelectedProgramme(null);
    }

    removeWindowListeners() {
        $(window).off('resize', this.handleWindowResizeCallback);
        $(window).off('orientationchange', this.handleWindowResizeCallback);
    }

    removeDetailedSheetViewListeners() {
        if (this._handleToggleFullScreenSlideShow) {
            this.detailedSheetView.removeListener('toggleSlideShowFullScreen', this._handleToggleFullScreenSlideShow);
            this._handleToggleFullScreenSlideShow = null;
        }
    }

    parseUrl(url) {
        return Urls.detailedSheet.parseUrl(url);
    }

    _loadData(options, loadDataCallBack) {
        if (options.fromSavedSearchId) {
            SavedSearches.updateLastInteractionDate(options.fromSavedSearchId, (err) => {
                if (err) {  // We do not want to break the page loading
                    console.error('lastInteractionDate could not be updated', err);
                }
            });
        }
        if (options.shouldPushUrl === false) {
            this._lastMapVisible = null;
        }
        if (!options.realEstateAd) {
            options.realEstateAd = options.incompleteRealEstateAd;
        }
        const ad = options.realEstateAd;
        if (ad) {
            options.defaultSearch = this.options.defaultSearch;
        }
        async.auto({
            undergoingAuthentication: cb => {
                // should only wait on direct page access (refresh, open in new tab, ...)
                Account.waitForUndergoingAuthentication(cb); // required for already contacted info, which uses authAjax
            },
            savedSearches: [
                'undergoingAuthentication',
                cb => {
                    this.getSavedSearches(options, cb);
                },
            ],
            adAndFilters: [
                'undergoingAuthentication', // required for authAjax calls hidden in _doAsyncLoadRealEstateAd
                cb => {
                    if (ad) {
                        async.setImmediate(cb);
                    } else {
                        //in pro, must always open a my realEstate ads page when closing a newly loaded detailed sheet page
                        if (ApplicationConfig.applicationPro) {
                            _.defaults(options, {closePageUrl: PagesFactories.myRealEstateAdsPageFactory.getPageUrl()});
                        }
                        this._doAsyncLoadRealEstateAd({
                            realEstateAdId: options.realEstateAdId,
                            filters: this._getFilters(options),
                            disableErrorPage: true,
                            name: 'loadDataDetailedSheetAd',
                            callback: (err, realEstateAd, neighborhoodInfo, search) => {
                                _.extend(options, {realEstateAd, neighborhoodInfo});
                                cb(err, search);
                            },
                        });
                    }
                },
            ],
            searchData: [
                'adAndFilters',
                (cb, results) => {
                    if (!_.get(options.searchController, 'searchResults')) {
                        // if we arrive on an ad page directly from its url, we must set a defaultSearch in
                        // order to be able to come back to a relevant search page (defaultClosePageUrl <=> click on "retour")
                        // without using the q parameter from the url.
                        if (!options.search) {
                            options.defaultSearch = results.adAndFilters;
                        }
                        options.search = results.adAndFilters;
                        this.asyncHelper.doAsync({
                            name: 'loadDataDetailedSheetSearchData',
                            func: cb => SearchDataLoader.loadSearchData(options, cb),
                            callback: cb,
                        });
                    } else {
                        async.setImmediate(cb);
                    }
                },
            ],
        }, err => {
            redirectToSearchIfClosedForTooLong(err, options);
        });

        function redirectToSearchIfClosedForTooLong(err, options) {
            const redirectToSearch = !err && !Account.isOnTheMarketFilterViewer() && !ApplicationConfig.applicationPro
                && adIsClosedForTooLong(options.realEstateAd);
            if (redirectToSearch) {
                PageManager.openPage(PagesFactories.searchPageFactory, {
                    searchView: options.searchController,
                    search: options.search || options.defaultSearch,
                    volatileFeedbackOnOpen: {
                        translationKey: 'redirectAdClosedForTooLongFeedback',
                        type: 'error',
                    },
                });
            } else {
                loadDataCallBack(err, options);
            }
        }
    }

    getSavedSearches(options, cb) {
        this.asyncHelper.doAsync({
            func: cb => SavedSearches.getAll(cb),
            name: 'loadSavedSearches',
            callback: (err, savedSearches = []) => {
                options.savedSearches = savedSearches;
                cb(err, options);
            },
        });
    }

    _getFilters({searchController, search}) {
        const searchResults = _.get(searchController, 'searchResults');
        if (searchResults) {
            return searchResults.getFilters();
        }
        return search;
    }

    _loadMissingData(options) {
        if (!options.neighborhoodInfo) {
            this._loadNeighborhoodInfos(options);
        }
        if (options.showDescription) {
            const filters = this._getFilters(options);
            if (options.realEstateAd.programmeRef && !options.realEstateAd.programme) {
                this._loadProgramme(options.realEstateAd.programmeRef, filters);
            }
            if (options.realEstateAd.residenceReference && !options.realEstateAd.residence) {
                this._loadResidence(options.realEstateAd.residenceReference, filters);
            }
            if (!options.realEstateAd.relatedAds && ProgrammeHelper.isProgrammeOrResidence(options.realEstateAd)) {
                this._loadRelatedAds(options, filters);
            }
            if (RealEstateAdLoader.shouldLoadSimilarAds(options.realEstateAd)) {
                this._loadSimilarAds(options, filters);
            }
            this._loadCompleteAd(options);
        }
    }

    _loadRelatedAds({realEstateAd}, filters) {
        this.detailedSheetView.showRelatedAdsSpinner();
        this.asyncHelper.doAsync({
            func: cb => RealEstateAdLoader.loadRelatedAds({
                realEstateAd,
                filters: realEstateAd.relatedAdsFilters || filters,
            }, cb),
            callback: (err, ads = []) => {
                if (err) {
                    console.error('Error getting related ads');
                    return;
                }
                if (this.realEstateAd) {
                    if (this.realEstateAd.relatedAds) {
                        _.each(this.realEstateAd.relatedAds, ad => this._realtimeContext.leave('ad#' + ad.id));
                    }
                    this.realEstateAd.relatedAds = _.map(ads, ad => {
                        this._realtimeContext.join('ad#' + ad.id);
                        return _.extend(ad, {
                            programme: this.realEstateAd.id == ad.programmeRef ? this.realEstateAd : null,
                            residence: this.realEstateAd.id == ad.residenceReference ? this.realEstateAd : null,
                        });
                    });
                    if (this.detailedSheetView) {
                        this.detailedSheetView.updateRelatedAds(this.realEstateAd.relatedAds);
                    }
                }
            },
            name: 'loadRelatedAds',
        });
    }

    _loadSimilarAds(options, filters) {
        this.asyncHelper.doAsync({
            func: cb => RealEstateAdLoader.loadSimilarAds({
                realEstateAdId: options.realEstateAd.id,
                searchCriteria: filters,
                excludeAdsWithSameAccountId: options.realEstateAd.status.onTheMarket,
            }, cb),
            callback: (err, results) => {
                if (err) {
                    console.error('Error getting similar ads for ', options.realEstateAd.id);
                } else {
                    this.realEstateAd.similarAds = results;
                    if (this.detailedSheetView) {
                        this.detailedSheetView.updateSimilarAds(this.realEstateAd.similarAds);
                    }
                }
            },
            name: 'loadSimilarAds',
        });
    }

    _loadProgramme(ref, filters) {
        if (this.detailedSheetView) {
            this._doAsyncLoadRealEstateAdOnly({
                realEstateAdId: ref,
                filters,
                callback: (err, programme) => {
                    if (err) {
                        console.error('Error getting programme');
                    } else {
                        this.realEstateAd.programme = programme;
                        this.detailedSheetView.updateProgramme(programme);
                        this.detailedSheetsNavView.updateProgramme(programme);
                        setCanonicalLink(this.getCanonicalUrl());
                    }
                },
                name: 'loadProgramme',
            });
        }
    }

    _loadResidence(ref, filters) {
        if (this.detailedSheetView) {
            this._doAsyncLoadRealEstateAdOnly({
                realEstateAdId: ref,
                filters,
                callback: (err, residence) => {
                    if (err) {
                        console.error('Error getting residence');
                    } else {
                        this.realEstateAd.residence = residence;
                        this.detailedSheetView.updateResidence(residence);
                        this.detailedSheetsNavView.updateResidence(residence);
                    }
                },
                name: 'loadResidence',
            });
        }
    }

    _loadCompleteAd(options) {
        const startTime = Date.now(); //WHAT THE FUCK ?!
        if (this.detailedSheetView) {
            this._doAsyncLoadRealEstateAdOnly({
                realEstateAdId: options.realEstateAd.id,
                callback: (err, ad) => {
                    if (err) {
                        console.error('Error getting realEstateAd', err);
                    } else if (!ad) {
                        console.error('Missing realEstateAd from mongo');
                    } else {
                        //why are we erasing the ES version with Mongo version ??!!
                        this.detailedSheetView.updateAd(ad);
                        if (this.detailedSheetScrollTo == 'contact') {
                            if (ad.status.onTheMarket) {
                                const openedFrom = (options.contactOptions && options.contactOptions.type) || 'refresh';
                                this.toggleContactSection(true, _.extend({
                                    openedFrom,
                                }, options.contactOptions));
                            } else {
                                this.toggleContactSection(false);
                            }
                        } else {
                            this.toggleContactSection(false, {startTime: startTime});
                        }
                    }
                    if (this.detailedSheetView) {
                        this.detailedSheetView.loadCompleteAdInitialized = true;
                        this.detailedSheetView.emit('loadCompleteAdInitialized');
                    }
                },
                name: 'loadCompleteAd',
            });
        }
    }

    _loadNeighborhoodInfos(options) {
        if (options.showDescription && this.detailedSheetView) {
            this.asyncHelper.doAsync({
                func: cb => RealEstateAdLoader.loadNeighborhoodInfo(options.realEstateAd, cb),
                callback: (err, neighborhoodInfo) => {
                    if (err) {
                        console.error('Error getting neighborhood info');
                    }
                    if (this.detailedSheetView) {
                        this.detailedSheetView.updateNeighborhoodInfo(neighborhoodInfo);
                        this.detailedSheetView.emit('neighborHoodInfoInitialized');
                    }
                },
                name: 'loadNeighborhoodInfo',
            });
        }
    }

    _doAsyncLoadRealEstateAdOnly(options) {
        this.asyncHelper.doAsync({
            func: cb => RealEstateAdLoader.loadRealEstateAd({realEstateAdId: options.realEstateAdId}, cb),
            callback: options.callback,
            name: options.name,
        });
    }

    _doAsyncLoadRealEstateAd(options) {
        return this.asyncHelper.doAsync({
            func: cb => RealEstateAdLoader.load(options.realEstateAdId,
                {
                    disableErrorPage: options.disableErrorPage,
                    filters: options.filters,
                }, cb),
            callback: options.callback,
            name: options.name,
        });
    }

    getUrl() {
        const camera = this.options.camera;
        let closePageUrl = this._getClosePageUrl();
        if (closePageUrl && closePageUrl === this._defaultClosePageUrl
            || this.options.appendReturnUrlToDetailedSheetUrl === false) {
            //don't need to put it in url
            closePageUrl = null;
        }
        const context = _.pick(this.options.context, 'trackingOrigin', 'fromSuggestion');
        return Urls.detailedSheet.makeUrl(this.realEstateAd, closePageUrl, this.detailedSheetScrollTo, camera, context);
    }

    getCanonicalUrl() {
        const {realEstateAd} = this;
        const ad = realEstateAd.programme || realEstateAd;
        return Urls.detailedSheet.makeUrl(ad, null, null, null);
    }

    _getClosePageUrl() {
        return this._closePageUrl;
    }

    getTitle() {
        return RealEstateAdTitleGenerator.getTitle(this.realEstateAd, 'detailedSheetTitle');
    }

    getMetaDescription() {
        return RealEstateAdTitleGenerator.getTitle(this.realEstateAd, 'detailedSheetMetaDescription');
    }

    updateMetaTags() {
        const realEstateAd = this.realEstateAd;
        const propertyType = realEstateAd.propertyType;
        super.updateMetaTags({
            image: _.get(realEstateAd, 'photos[0].url'),
            'article:tag': _.compact([
                realEstateAd.adType,
                realEstateAd.city,
                propertyType,
            ]),
        });
    }

    getJSONLD() {
        const realEstateAd = this.realEstateAd;
        const accommodation = {
            '@context': 'http://schema.org',
            '@type': 'Accommodation', //TODO house or apartment
            address: {
                '@type': 'PostalAddress',
                addressLocality: realEstateAd.city,
                postalCode: realEstateAd.postalCode,
            },
            numberOfRooms: realEstateAd.roomsQuantity,
        };

        if (realEstateAd.surfaceArea) {
            accommodation.floorSize = {
                '@type': 'QuantitativeValue',
                unitCode: 'MTK',
                value: realEstateAd.surfaceArea,
            };
        }

        const offer = {
            '@type': 'Offer',
            priceSpecification: {
                '@type': 'PriceSpecification',
                priceCurrency: 'EUR',
                valueAddedTaxIncluded: true,
            },
        };
        if (_.isArray(realEstateAd.price)) {
            const [minPrice, maxPrice] = realEstateAd.price;
            _.extend(offer.priceSpecification, {
                minPrice,
                maxPrice,
                price: minPrice,
            });
        } else {
            offer.priceSpecification.price = realEstateAd.price;
        }
        const product = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            name: this.getTitle(),
            offers: offer,
            image: _.get(realEstateAd, 'photos[0].url'),
        };
        return [accommodation, product];
    }

    _toggleMapStyle(mapMode) {
        if (this.detailedSheetView && this.detailedSheetView.isShown()) {
            this.detailedSheetView.$element.find('.sheetMode-map').toggleClass('active', mapMode);
            this.detailedSheetView.$element.find('.sheetMode-description').toggleClass('active', !mapMode);
        }
    }

    _getDetailedSheetNavOptions() {
        const realEstateAd = this.realEstateAd;
        return {
            realEstateAd,
            createSearchNavigationIfMissing: this.createSearchNavigationIfMissing,
            isMobile: this.configuration.isMobile,
            hasSearchPageSplitScreen: this.mapController.getSplitScreenStatus(),
        };
    }

    _openAd({
        realEstateAd: incompleteRealEstateAd,
        previousRealEstateAd,
        realEstateAdId,
        direction,
        openedFromList,
        isHighlightedInList,
    }) {
        // update position if model was already loaded
        SideMapViewSingleton.get().updateLotPositionAndHeightFromModel(incompleteRealEstateAd);
        const {
            context,
            closeAdSuggestionPageOptions,
            reopen,
            reopenOptions,
        } = this.options;
        const openOptions = {
            previousRealEstateAd,
            incompleteRealEstateAd,
            realEstateAdId,
            context,
            closeAdSuggestionPageOptions,
            reopen,
            reopenOptions,
            openedFromList,
            isHighlightedInList,
        };
        if (direction) {
            const animDirections = getAnimationDirections(direction);
            _.extend(openOptions, {
                animations: {
                    openAnimation: 'anim-open-' + animDirections.open,
                },
                direction,
            });
            if (this.detailedSheetView) {
                this.detailedSheetView.setCloseAnimation('anim-close-' + animDirections.close);
            }
        }

        this._openPage(this.getDetailedSheetPageToOpen(), openOptions);
    }

    getDetailedSheetPageToOpen() {
        if (this.configuration.mobile) {
            if (!this.showMap) {
                return Pages.detailedSheetDescription;
            } else if (!this.configuration.showDescription) {
                return Pages.detailedSheetMap;
            } else {
                return Pages.detailedSheet;
            }
        } else {
            return Pages.detailedSheet;
        }
    }

    _onStatusChange() {
        if (this.detailedSheetView) {
            this._onListRefreshNeeded();
        }
    }

    _onListRefreshNeeded() {
        const searchController = this.searchController;
        if (searchController) {
            searchController.clearPagesCache();
            searchController.refreshSearchList();
        }
    }

    getRealtimePageInfo(callback) {
        Account.getAccountAndCreateGuestIfNeeded(function (err, account) {
            if (err) {
                console.error(err, 'Could not get account or guest');
            }
            getDidomiUserId(didomiUserId => {
                const realtimePageInfo = _.extend({
                    access_token: _.get(account, 'access_token'),
                    didomiUserId,
                }, DetailedSheetStatsContext.get());
                callback(
                    null,
                    {
                        realtimePageInfo,
                        eventName: 'ad:page:open',
                    }
                );
            });
        });
    }

    getDetailedSheetViewToDisplay(options) {
        const isRealEstateAdFound = options.realEstateAd;
        this.isAdFound = isRealEstateAdFound;
        if (isRealEstateAdFound) {
            return new DetailedSheetView(_.defaults(options, this.configuration));
        } else {
            let backUrl = '/';
            if (options.search) {
                backUrl = Urls.search.makeUrl(options.search, null, 'recherche');
            }
            options.backUrl = backUrl;
            return new SimpleView(adNotFoundTemplate);
        }
    }

    _registerRealtimeEvents() {
        const realtimeContext = this._realtimeContext = RealtimeServer.openContext();
        realtimeContext.join('ad#' + this.realEstateAdId);
        realtimeContext.on('ad:update', _.bind(this._handleAdUpdateNotification, this));
        if (this.realEstateAd && this.realEstateAd.relatedAds) {
            _.each(this.realEstateAd.relatedAds, ad => {
                realtimeContext.join('ad#' + ad.id);
            });
        }
    }

    _unregisterRealtimeEvents() {
        const realtimeContext = this._realtimeContext;
        if (realtimeContext) {
            realtimeContext.close();
        }
    }

    bindGTMEvents(options) {
        const data = UserTracking.getAdObjectForGtm(this.realEstateAd);
        if (options.context) {
            data.inHighlightedBox = options.context.inHighlightedBox;
        }

        if (this.detailedSheetView) {
            this._eventPack.on(this.detailedSheetView, {
                sendOpenContactFormEvent: type => {
                    this.sentContactEvent('openContactForm', data, {type});
                },
                showPhoneNumber: source => {
                    this.sentContactEvent('phoneNumberShown', data, {source});
                },
                contactEmailSent: _.bind(this.onContactMailSent, this, data),
            });
        }

        if (this.showMap && SideMapViewSingleton.get().map) {
            const mapOptions = MapCreation.getMapStats(SideMapViewSingleton.get().map);
            _.extend(data, mapOptions);
        }
        _.extend(data, {
            openedFromList: options.openedFromList || '',
            referrer: Account.getReferrer(),
        });
        UserTracking.onDetailedSheetOpened(data, this.realEstateAd);
    }

    onContactMailSent(data, options) {
        const contactRequest = options.contact;
        contactRequest.sender.accountId = Account.getAuthenticatedAccountId();
        if (this.realEstateAd.contactRequests) {
            this.realEstateAd.contactRequests.unshift(contactRequest);
        } else {
            this.realEstateAd.contactRequests = [contactRequest];
        }
        const {contactRequests} = this.realEstateAd;
        _.set(options.realEstateAd, 'contactRequests', contactRequests);  // In order to open suggestion page with correct updated ad
        _.invoke(this.detailedSheetView, 'contactView.updateContactList', contactRequests);
        const searchController = this.searchController;
        if (searchController) {
            searchController.refreshAdOnUserEvent({adId: this.realEstateAdId, contactRequest}, 'contactRequests');
        }
        if (options.track !== false) {
            const optionsToSend = _.extend(
                _.pick(options, 'sentFrom'),
                _.pick(options.contact, 'askForDocumentation', 'askForMeeting', 'askForPhotos')
            );
            this.sentContactEvent('contactFormSent', data, optionsToSend);
        }
        if (rateTheApp) {
            _.delay(rateTheApp, 500);
        }
        if (this.shouldOpenSuggestionPage(options)) {
            this._openAdsSuggestionPage(options);
        } else {
            GoogleTagManager.sendEvent('adSuggestions', {
                forAd: this.realEstateAdId,
                similarAdsCount: 0,
                canOpenSuggestionsPage: false,
            });
        }
    }

    shouldOpenSuggestionPage({startTime, similarAds = []}) {
        const timer = startTime - Date.now();
        return similarAds.length && startTime && timer < 10 * 1000; //only open suggestions if it took less than 10sec.
    }

    sentContactEvent(eventName, {inHighlightedBox}, options) {
        GoogleTagManager.sendContactEvent(eventName, _.extend({
            inHighlightedBox,
            realEstateAd: this.realEstateAd,
            realEstateAdId: this.realEstateAdId,
        }, options));
    }

    _openAdsSuggestionPage(options) {
        if (this.detailedSheetView) {
            this.detailedSheetView.setCloseAnimation('anim-close-left');
        }
        const {reopen, reopenOptions, closeAdSuggestionPageOptions} = this.options;
        const {search} = this._getSearchState() || {};
        const openOptions = _.extend({}, options, {
            closePageUrl: _.get(closeAdSuggestionPageOptions, 'closePageUrl', this._getClosePageUrl()),
            reopen: _.get(closeAdSuggestionPageOptions, 'reopen', reopen),
            reopenOptions: _.get(closeAdSuggestionPageOptions, 'reopenOptions', reopenOptions),
            headerTitle: _.get(closeAdSuggestionPageOptions, 'headerTitle', this._getBackTitle()),
            search,
        });
        this._openPage(Pages.adSuggestionPage, openOptions);
    }

    _reopenPage() {
        const openOptions = {
            previousRealEstateAd: this.realEstateAd,
            from: 'detailedSheetPage',
        };
        const fromSuggestion = _.get(this, 'options.context.fromSuggestion');
        if (fromSuggestion) {
            openOptions.animations = {
                openAnimation: 'anim-open-left',
                closeAnimation: 'anim-close-right',
            };
        }
        if (this.detailedSheetView) {
            this.detailedSheetView.setCloseAnimation('anim-close-right');
        }
        super._reopenPage(openOptions);
    }

    getSelectedHeaderItem() {
        const {path} = urlUtil.parse(this._getClosePageUrl());
        return _.findKey(NON_PATTERN_PREFIXES, prefix => new RegExp('^/' + prefix).test(path));
    }

    addAuthenticationAdditionalView(authenticationView) {
        const {realEstateAdId} = this.parseUrl(location.href);
        new DetailedSheetBehindAuthenticationView({
            realEstateAdId,
            $container: authenticationView.$element.find('.pitchAndSocialContainer'),
        }).show();
    }

    _getSearchResults() {
        if (this.searchController) {
            return this.searchController.searchResults;
        }
    }

    _getSearchState() {
        if (this.searchController) {
            return this.searchController.getSearchState();
        }
    }

    _getBackTitle() {
        return this.options.headerTitle;
    }
};

function getAnimationDirections(direction) {
    if (direction == 'next') {
        return {open: 'right', close: 'left'};
    } else {
        return {open: 'left', close: 'right'};
    }
}
