const {resourceUrl} = require('fack');
const template = require('./AgencyPageHeader.jade');
const {loadImage} = require('../../utils/ImageLoader');
const {getImageUrlFromAlias} = require('../../ImageHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');
const AgencyExternalLinks = require('./AgencyExternalLinks');
const AgencyFeesUrl = require('./AgencyFeesUrl');
const AgencyBanner = require('./AgencyBanner');

const DEFAULT_BACKGROUND_IMAGE_PATH = 'images/homepage/hp_pro_default.jpg';

// @vue/component
module.exports = {
    components: {
        AgencyExternalLinks,
        AgencyFeesUrl,
        AgencyBanner,
    },
    mixins: [
        i18nMixin({
            prefix: 'AgencyPageHeader.',
            keys: [
                'agencyFees',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
        showContactUs: Boolean,
    },
    data() {
        return {
            isImageLoaded: false,
        };
    },
    computed: {
        imageUrl() {
            const {agencyBackgroundImage} = this.agency;
            return agencyBackgroundImage ? getImageUrlFromAlias(agencyBackgroundImage) : resourceUrl(DEFAULT_BACKGROUND_IMAGE_PATH);
        },
        modifiers() {
            return {
                isLoaded: this.isImageLoaded,
            };
        },
        feesUrl() {
            return this.agency.feesUrl;
        },
        agencyName() {
            return this.agency.display_name || '';
        },
    },
    mounted() {
        loadImage(this.imageUrl, err => {
            if (err) {
                console.error('Could not load background image', err);
            } else {
                this.isImageLoaded = true;
            }
        });
    },
    template: template(),
};
