const _ = require('lodash');
const template = require('./Breadcrumb.jade');
const BreadcrumbLink = require('./BreadcrumbLink');
const DEFAULT_ALIGNMENT = 'left';
const ALIGNMENTS = [
    DEFAULT_ALIGNMENT,
    'center',
    'right',
];
const DEFAULT_TEXT_SIZE = 'very-small';
const TEXT_SIZES = [
    DEFAULT_TEXT_SIZE,
    'small',
    'medium',
];

// @vue/component
module.exports = {
    components: {
        BreadcrumbLink,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        align: {
            type: String,
            default: DEFAULT_ALIGNMENT,
            validator: value => _.includes(ALIGNMENTS, value),
            values: ALIGNMENTS,
        },
        textSize: {
            type: String,
            default: DEFAULT_TEXT_SIZE,
            validator: value => _.includes(TEXT_SIZES, value),
            values: TEXT_SIZES,
        },
    },
    computed: {
        breadcrumbModifiers() {
            return {
                [this.align]: true,
                [`${this.textSize}-text`]: true,
            };
        },
    },
    template: template(),
};
