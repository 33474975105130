const _ = require('lodash');
const adsManager = require('../search/adsManager');
const SearchFiltersHelper = require('../../common/SearchFiltersHelper');

module.exports = {
    clean,
};

function clean(searchCriteria) {
    // todo: maybe need deep clone ?
    const cleanFilters = _.omit(
        adsManager.prepareFilters({filters: _.clone(searchCriteria)}),
        SearchFiltersHelper.getInternalFilters()
    );
    // todo: remove other empty array filters
    if (cleanFilters.geocoding && cleanFilters.geocoding.length == 0) {
        delete cleanFilters.geocoding;
    }
    if (searchCriteria.locations) {
        cleanFilters.locationNames = _.map(searchCriteria.locations, (location) => location.getName());
    }
    if (cleanFilters.locationNames && cleanFilters.locationNames.length == 0) {
        delete cleanFilters.locationNames;
    }
    //filterType may be array if admin
    if (cleanFilters.filterType) {
        cleanFilters.filterType = _.castArray(cleanFilters.filterType);
    }
    return cleanFilters;
}
