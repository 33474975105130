const _ = require('lodash');
const {ESTIMATOR_STORE_MODULE_NAME} = require('./Constants');

const KEPT_PATHS_ON_CLEAR_ESTIMATION = ['optins', 'sender'];

function getSender() {
    return _.extend({
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
    }, getSenderProjectInfo());
}

function getSenderProjectInfo() {
    return {
        intention: 'sell',
        userStatus: null,
    };
}

function buildMutations(list, moduleName) {
    return _.extend(_.mapValues(list, (value, key) => {
        return function (state, value) {
            state[key] = value;
        };
    }), (_.includes(moduleName, ESTIMATOR_STORE_MODULE_NAME) && {
        clearEstimation(state) {
            _.each(_.omit(state, _.union(KEPT_PATHS_ON_CLEAR_ESTIMATION, ['realEstateProperty'])), (value, key) => {
                state[key] = null;
            });
            _.each(state.realEstateProperty, (value, key) => {
                state.realEstateProperty[key] = null;
            });
            _.extend(state.sender, getSenderProjectInfo());
        },
        clearRealEstateEstimationKeys(state, keys) {
            _.each(keys, key => {
                state.realEstateProperty[key] = null;
            });
        },
        clearFlatType(state) {
            state.flatType = null;
        },
    }));
}

function buildGettersAndSetters(list, path) {
    return _.zipObject(_.map(list, key => _.compact([path, key]).join('_')), _.map(list, key => {
        return {
            get() {
                return this.getStoreValue(path, key);
            },
            set(value) {
                this.setStoreValue(path, key, value);
            },
        };
    }));
}

const methods = {
    getFullPath(path, key) {
        return _.compact([this.moduleName, path, key]);
    },
    setStoreValue(path, key, value) {
        this.$store.commit(this.getFullPath(path, key).join('/'), value);
    },
    getStoreValue(path, key) {
        return _.get(this.$store.state, this.getFullPath(path, key));
    },
};

const buildComputed = (state, realEstateProperty) => {
    return _.extend({},
        buildGettersAndSetters(_.union(_.keys(state), ['sender', 'realEstateProperty'])),
        buildGettersAndSetters(_.keys(realEstateProperty), 'realEstateProperty'),
        buildGettersAndSetters(_.keys(getSender()), 'sender')
    );
};

const buildStore = (state, realEstateProperty, moduleName) => {
    const store = {
        MODULE_NAME_SLASHED: `${moduleName}/`,
    };
    store[moduleName] = {
        namespaced: true,
        state,
        mutations: buildMutations(state, moduleName),
        modules: {
            realEstateProperty: {
                namespaced: true,
                state: realEstateProperty,
                mutations: buildMutations(realEstateProperty, moduleName),
            },
            sender: {
                namespaced: true,
                state: getSender(),
                mutations: buildMutations(getSender(), moduleName),
            },
        },
    };
    return store;
};

module.exports = {
    buildComputed,
    buildStore,
    methods,
};
