const Field = require('./Field');
const FilterHelper = require('../FilterHelper');
const SearchFiltersConfiguration = require('../search/SearchFiltersConfiguration');

const MINMAX_FIELD_OPTIONS = {
    minMaxPrice: {
        configuration: SearchFiltersConfiguration.getPriceConfiguration,
        minFieldName: 'minPrice',
        maxFieldName: 'maxPrice',
        displayOptions: {
            showUnit: true,
        },
    },
    minMaxRooms: {
        configuration: SearchFiltersConfiguration.getRoomQuantityConfiguration,
        minFieldName: 'minRooms',
        maxFieldName: 'maxRooms',
    },
    minMaxBedrooms: {
        configuration: SearchFiltersConfiguration.getBedroomQuantityConfiguration,
        minFieldName: 'minBedrooms',
        maxFieldName: 'maxBedrooms',
    },
    minMaxArea: {
        configuration: SearchFiltersConfiguration.getAreaSizeConfiguration,
        minFieldName: 'minArea',
        maxFieldName: 'maxArea',
        displayOptions: {
            showUnit: true,
        },
    },
    minMaxGardenSurfaceArea: {
        configuration: SearchFiltersConfiguration.getGardenAreaSizeConfiguration,
        minFieldName: 'minGardenSurfaceArea',
        maxFieldName: 'maxGardenSurfaceArea',
        displayOptions: {
            showUnit: true,
            pullLeft: true,
        },
    },
};

module.exports = class MinMaxField extends Field {
    init() {
        this.conf = MINMAX_FIELD_OPTIONS[this.fieldName];
        this.minFieldName = this.conf.minFieldName;
        this.maxFieldName = this.conf.maxFieldName;
        this._filterHelper = new FilterHelper(this.conf, this.$element, this.conf.displayOptions);
    }

    setDisabled(disabled) {
        this._filterHelper.setDisabled(disabled);
    }

    updateTab(filterType) {
        this._filterHelper.changeTab(this.conf, filterType);
    }

    updateField(search) {
        super.updateField(search);
        this._filterHelper.updateButtonLabel();
    }

    updatePlaceholder(search) {
        const filterType = search.filterType;
        this.updateTab(filterType);
    }

    clearFields() {
        this._filterHelper.clearFields();
    }
};
