const _ = require('lodash');
const moment = require('moment');

const VueView = require('./vue/VueView');
const template = require('./templates/detailedSheet/stats.jade');

module.exports = class StatsView extends VueView {
    constructor({
        $container,
        detailedSheetView,
    }) {
        super({
            $container,
        });
        this.detailedSheetView = detailedSheetView;
    }

    show() {
        const {detailedSheetView} = this;

        // @vue/component
        const vueOptions = {
            name: 'StatsView',
            data() {
                return {
                    realEstateAd: detailedSheetView.realEstateAd,
                };
            },
            computed: {
                realEstateAdId() {
                    return this.realEstateAd.id;
                },
                fromDate() {
                    const {realEstateAd} = this;
                    if (realEstateAd) {
                        return moment(realEstateAd.thresholdDate || realEstateAd.publicationDate).format('LL');
                    }
                    return null;
                },
                canSeeStats() {
                    return Boolean(_.get(this.realEstateAd, 'userRelativeData.canSeeStats'));
                },
            },
            methods: {
                clickOnTitle() {
                    detailedSheetView._clickTitleSection();
                },
                updateStats(stats) {
                    detailedSheetView.stats = stats;
                    if (detailedSheetView._adStatsChartCreated) {
                        detailedSheetView._updateStatsGraph();
                    }
                },
            },
            template: template(),
        };

        super.show(null, vueOptions);
    }
};
