const _ = require('lodash');

const ZoneManager = require('../ZoneManager');
const Errors = require('../utils/Errors');
const CancelableCallback = require('../utils/CancelableCallback');
const ZoneByTimeMarkers = require('../utils/ZoneByTimeMarkers');

let searchZone = null;
let searchZoneRequest = null;
let lastLoadSearchZoneCallback;

module.exports = {
    loadSearchZone,
    fetchSearchZone,
    removeSearchZone,
    updateSearchZone,
};

function mustLoadSearchZone(searchLocationIds) {
    if (searchZone) {
        const inter = _.intersection(searchZone.ids, searchLocationIds);
        return ((inter.length != searchZone.ids.length) || (inter.length != searchLocationIds.length));
    }
    return true;
}

function getZonesIds(zones) {
    const ids = [];
    _.each(zones, zone => {
        if (zone.id) {
            ids.push(zone.id);
        }
        if (zone.zoneIds) {
            ids.push(...zone.zoneIds);
        }
    });
    return _.uniq(ids);
}

function loadSearchZone(searchLocationInfos, callback) {
    //TODO refactor SearchZoneManager, avoid singleton SearchZoneRequest, handle abort properly
    if (searchLocationInfos && searchLocationInfos.length) {
        if (mustLoadSearchZone(getZonesIds(searchLocationInfos))) {
            abortRequest();
            lastLoadSearchZoneCallback = CancelableCallback(function (err, zone) {
                removeSearchZone();
                ZoneByTimeMarkers.createMarkers(searchLocationInfos);
                if (err) {
                    if (err.status !== 'abort') {
                        Errors.showError(err);
                    }
                } else {
                    searchZone = zone;
                    if (!zone) {
                        console.error('No zone found for location infos %s', searchLocationInfos);
                        Errors.showError(new Error('no zone found'));
                    }
                    callback(zone);
                }
            });
            fetchSearchZone(searchLocationInfos, lastLoadSearchZoneCallback);
        } else {
            callback(searchZone);
        }
    } else {
        removeSearchZone();
        callback(searchZone);
    }
}

function updateSearchZone(options) {
    ZoneByTimeMarkers.updateMarkers(options);
}

function fetchSearchZone(searchLocationInfos, callback) {
    searchZoneRequest = ZoneManager.fetchSearchZones(searchLocationInfos, function (err, zone) {
        searchZoneRequest = null;
        callback(err, zone);
    });
}

function hideSearchZone() {
    ZoneByTimeMarkers.deleteMarkers();
    if (searchZone) {
        searchZone.setMap(null);
    }
}

function abortRequest() {
    if (lastLoadSearchZoneCallback) {
        lastLoadSearchZoneCallback.cancel();
    }
    if (searchZoneRequest) {
        searchZoneRequest.abort();
    }
    lastLoadSearchZoneCallback = null;
    searchZoneRequest = null;
}

function removeSearchZone() {
    hideSearchZone();
    searchZone = null;
    abortRequest();
}
