const _ = require('lodash');
const Entities = require('html-entities').AllHtmlEntities;
const ApplicationConfig = require('../app/ApplicationConfig');

module.exports = {
    getTitleFromOptions,
    setTitle,
    setPrefix,
};

let prefix;
let suffix;

function setPrefix(value) {
    prefix = value;
    updateTitle();
}

function getTitleFromOptions(title) {
    if (_.isObject(title)) {
        title = title.title;
    }
    if (!title) {
        title = ApplicationConfig.title;
    }
    return title;
}

function setTitle(options) {
    const title = getTitleFromOptions(options);
    if (null != title) {
        suffix = title;
        updateTitle();
    }
}

function updateTitle() {
    let title;
    if (suffix) {
        suffix = Entities.decode(suffix);
        if (prefix) {
            title = prefix + ' ' + suffix;
        } else {
            title = suffix;
        }
        document.title = title;
        const cleanTitle = document.title.split(') ');
        document.cleanTitle = cleanTitle.length > 1 ? cleanTitle[1] : cleanTitle[0];
    }
}
