const _ = require('lodash');
const {EventEmitter} = require('events');
const BrowserDetect = require('browser-detect');
const {i18n: {translate: t}} = require('fack');
const async = require('async');

const ZoneSuggestionManager = require('./ZoneSuggestionManager');
const templateSuggestionItem = require('./templates/suggestionItem.jade');
const templateSuggestionHeader = require('./templates/suggestionHeader.jade');
const isMobile = Boolean(BrowserDetect.isMobile());
const isTablet = Boolean(BrowserDetect.isTablet());
const TagsInputTypeaheadWidget = require('./TagsInputTypeaheadWidget');
const TagsInputWidget = require('./TagsInputWidget');
const LocationGTMHandler = require('./locationItems/LocationGTMHandler');
const {loseFocusOnActiveElement} = require('./utils/focus');

const TAG_CLASSSES = 'label label-tag';
const MONOTAG_CLASSES = `${TAG_CLASSSES} monoTag`;

module.exports = class SuggestionWidget extends EventEmitter {
    constructor(options) {
        super();
        this.locationGTMHandler = new LocationGTMHandler();
        this.options = options;
        this.naturalLanguageEnabled = options.naturalLanguageEnabled;
        this.isPopup = options.isPopup;
        this.aroundMeEnabled = options.aroundMeEnabled;
        this.travelTimeEnabled = _.defaultTo(options.travelTimeEnabled, true);
        this.disableBlur = options.disableBlur;
        this.enablePopup = options.enablePopup;
        this.$input = options.$input;
        this.$form = this.$input.closest('form');
        this.$searchButton = options.$searchButton;
        this.$spinner = options.$spinner;
        //prevent change focus when click on delete
        this.$input.parent().on('mousedown', '.tag', event => {
            event.preventDefault();
        });
        this.$input.parent().on('mousedown', '.bootstrap-tagsinput', () => {
            this._mouseDown = true;
        });
        this.$input.parent().on('mouseup', '.bootstrap-tagsinput', () => {
            _.defer(() => {
                this._mouseDown = false;
            });
        });

        this.initTagsInput();
        this._bindEvents();
        // Remove autocorrect (ios) on input
        //this.ttinput.attr("autocorrect", "off");
    }

    initTagsInput() {
        this._isDestroyed = false;
        if (this.enablePopup) {
            this.tagsInputWidget = new TagsInputWidget(this.getTagsInputConf());
        } else {
            this.tagsInputWidget = new TagsInputTypeaheadWidget(this.getTagsInputTypeaheadConf());
        }
        this.tagsInputWidget.init();
        this._updatePlaceholder();
    }

    getTagsInputTypeaheadConf() {
        return _.extend(this.getTagsInputConf(),
            {
                typeaheadOptions: this.getTypeaheadConfig(),
                bloodHoundConf: this.getBloodHoundConf(),
            });
    }

    getTagsInputConf() {
        return {
            focusOnItemRemoved: !this.enablePopup,
            $input: this.$input,
            maxChars: 40,
            tagClass: item => this.tagsInputWidget.isMonoTagItem(item) ? MONOTAG_CLASSES : TAG_CLASSSES,
            monoTagTypes: ['circleZone', 'drawnZone', 'travelTimeZone'],
        };
    }

    getTypeaheadConfig() {
        return {
            typeaheadConfig: {
                highlight: true,
                minLength: 0,
                autoselect: this.options.autoSelect,
                classNames: {
                    menu: 'tt-dropdown-menu',
                    wrapper: 'twitter-typeahead isfocused',
                },
            },
            typeaheadDatasets: [
                {
                    display: locationItem => locationItem.getText(),
                    limit: 100,
                    updateOnAsync: true,
                    templates: {
                        suggestion: locationItem => {
                            return templateSuggestionItem({
                                t,
                                type: locationItem.getType(),
                                text: locationItem.getSuggestionText(),
                            });
                        },
                        header: this._getTemplateHeader(),
                        empty: this._getTemplateHeader(),
                    },
                },
            ],
        };
    }

    _getTemplateHeader() {
        return parameters => {
            const suggestionTypes = _.map(parameters.suggestions, suggestion => suggestion.getType());
            const noSuggestion = _.without(suggestionTypes, 'circleZone', 'drawnZone', 'travelTimezone').length == 0;
            let {allowedPlaceTypes: context} = this.options;
            if (!context && _.isEmpty(this.getLocalSuggestions())) {
                context = 'withoutLocalSuggestions';
            }
            return templateSuggestionHeader(_.extend({
                t,
                _,
                context,
            }, _.extend({noSuggestion}, parameters)));
        };
    }

    getBloodHoundConf() {
        const {allowedPlaceTypes} = this.options;
        let cacheTag = 'suggest';
        if (allowedPlaceTypes) {
            cacheTag += allowedPlaceTypes;
        }
        return {
            identify: locationItem => locationItem.getValue(),
            sufficient: 10,
            remote: {
                url: cacheTag, // if using the same url, cached results would be used
                rateLimitBy: 'throttle',
                prepare: (query, settings) => {
                    return _.extend(settings, {data: {query: query}, text: query});
                },
                transform: suggestions => {
                    if (this._isDestroyed) {
                        return [];
                    }
                    let locationItems = ZoneSuggestionManager.convertToLocationItems(suggestions);
                    this.saveLocationList(locationItems);
                    if (!this.getInputValue()) {
                        locationItems = _.union(locationItems, this.getLocalSuggestions());
                    }
                    return locationItems;
                },
                transport: (data, onSuccess, onError) => {
                    this.toggleSpinner(true);
                    const text = data.text;
                    this._requestSuggestions(text, (err, suggestions) => {
                        this.toggleSpinner(false);
                        if (err) {
                            onError(err);
                        } else {
                            onSuccess(suggestions);
                        }
                    });
                },
            },
        };
    }

    _requestSuggestions(text, callback) {
        const {allowedPlaceTypes} = this.options;
        const query = {q: text};
        if (allowedPlaceTypes) {
            query.type = allowedPlaceTypes;
        }
        this._requestHandler = ZoneSuggestionManager.ask(query, (err, suggestions) => {
            if (!err && this._isSubmitted) {
                const input = this.getInputValue();
                if (!input || text == input) {
                    this._isDoingRequest = false;
                    this._isSubmitted = false;
                    if (suggestions && suggestions.length) {
                        this._selectFirstSuggestion(suggestions);
                    }
                }
            }
            callback(err, suggestions);
        });
    }

    _selectFirstSuggestion(suggestions) {
        const locationItems = ZoneSuggestionManager.convertToLocationItems(suggestions);
        this.saveLocationList(locationItems);
        const firstSuggestion = locationItems && locationItems.length ? locationItems[0] : null;
        this.addSuggestion(firstSuggestion);
        this.onSelect(firstSuggestion);
        this.blur();
    }

    saveLocationList(locationItems) {
        this.locationGTMHandler.saveLocationList(locationItems);
    }

    getInputValue() {
        return this.tagsInputWidget.getInputValue();
    }

    openTypeahead() {
        if (!this.enablePopup) {
            this.tagsInputWidget.openTypeahead();
        }
    }

    getTagsInput() {
        return this.tagsInputWidget.getTagsInput();
    }

    onSelect(item) {
        if (this._isDoingRequest) {
            this._isSubmitted = true;
        } else {
            this.locationGTMHandler.sentGTMEvent(item);
            this.emit('typeahead:select', item);
            if (item.from === 'travelTimeZone') {
                _.defer(() => { //using defer because we can't blur during event handling (or typeahead sets focus again ?)
                    loseFocusOnActiveElement(); //remove current focus
                });
            }
        }
    }

    _bindEvents() {
        if (this.$input.attr('required')) {
            this.$input.on('change', () => {
                this.$form.bootstrapValidator('revalidateField', this.$input.attr('name'));
            });
        }

        this.$input.parent().on('change', (evt) => {
            if (this._isDoingRequest && this.getTagsInput().is(':focus')) {
                evt.preventDefault();
                this._isSubmitted = true;
            }
        });

        this.tagsInputWidget.on('typeahead:beforeselect', () => {
            this.locationGTMHandler.saveInputValue(this.getInputValue());
        });
        this.tagsInputWidget.on('typeahead:select', (ev, item) => {
            this.onSelect(item);
        });
        this.tagsInputWidget.on('itemAdded', () => {
            this._isSubmitted = false;
            this._updatePlaceholder();
            _.defer(() => { //focus is set somewhere in the lib with delay after click on suggestion
                this.blur();
            });
            this.emit('change');
        });
        this.tagsInputWidget.on('itemRemoved', () => {
            this._isSubmitted = false;
            const suggestions = this.getSelectedSuggestions();
            if (this._mouseDown) {
                this._toggleFocusClass(!this.tagsInputWidget.hasTags());
                if (suggestions.length) {
                    _.defer(() => {
                        this.blur();
                    });
                }
            }
            this._updatePlaceholder();
            this.emit('change');
        });
        this.tagsInputWidget.on('typeahead:asyncrequest', () => {
            this.emit('typeahead:asyncrequest');
            this._isDoingRequest = true;
        });

        this.tagsInputWidget.on('typeahead:asyncreceive', () => {
            this._isDoingRequest = false;
            this._isSubmitted = false;
        });

        this.tagsInputWidget.on('typeahead:asynccancel', () => {
            this._isDoingRequest = false;
            this._isSubmitted = false;
            this._abortRequest();
        });

        this.tagsInputWidget.on('focusin', () => {
            this.emit('focusin');
            this._updatePlaceholder();
            this._toggleFocusClass(true);
        });
        this.tagsInputWidget.on('focusout', () => {
            this.emit('focusout');
            this._updatePlaceholder();
            this._toggleFocusClass(!this.tagsInputWidget.hasTags() || Boolean(this.getInputValue()));
        });
    }

    _abortRequest() {
        if (this._requestHandler) {
            this._requestHandler.abort();
            this._requestHandler = null;
        }
    }

    getEmptyGeolocationItem(options) {
        return ZoneSuggestionManager.getEmptyGeolocationItem(options);
    }

    getTravelTimeItem(travelTimeZone, options) {
        return ZoneSuggestionManager.getTravelTimeItem(travelTimeZone, options);
    }

    getDrawingItem(drawZone) {
        return ZoneSuggestionManager.getDrawingItem(drawZone);
    }

    getLocalSuggestions() {
        const locals = [];
        if (this.aroundMeEnabled) {
            locals.push(this.getEmptyGeolocationItem({isAroundMe: true}));
        }
        if (this.travelTimeEnabled) {
            locals.push(this.getTravelTimeItem(null, this.options));
        }
        return locals;
    }

    getFirstTravelTimeItem() {
        return _(this.getSelectedSuggestions())
            .filter({type: 'travelTimeZone'})
            .first();
    }

    destroy() {
        this._isDestroyed = true;
        this._abortRequest();
        this._isDoingRequest = false;
        this._isSubmitted = false;
        this.tagsInputWidget.destroy();
    }

    setSuggestions(suggestions) {
        this.resetWidget();
        this.addSuggestions(suggestions);
    }

    addSuggestions(suggestions) {
        const locationItems = ZoneSuggestionManager.convertToLocationItems(suggestions);
        this._addLocationItems(locationItems);
        this._updatePlaceholder();
    }

    addSuggestion(suggestion) {
        this.addSuggestions([suggestion]);
    }

    focus() {
        if (isMobile || isTablet) {
            return;
        }
        this.forceFocus();
    }

    forceFocus() {
        this.$input.tagsinput('input').focus();
    }

    blur() {
        this.tagsInputWidget.blur();
        this.focusSearchButton();
    }

    focusSearchButton() {
        const $button = this._getButton();
        if ($button) {
            $button.focus();
        }
    }

    refresh() {
        this.tagsInputWidget.$input.tagsinput('refresh');
    }

    resetInput() {
        this.tagsInputWidget.resetInput();
        this.destroy();
        this.initTagsInput();
        this.forceFocus();
    }

    resetWidget() {
        this.tagsInputWidget.resetWidget();
        this._updatePlaceholder();
    }

    toggleSpinner(show) {
        this.$spinner.toggleClass('md-loop md-spin', show).toggle(show);
    }

    getSelectedLocations() {
        return this.tagsInputWidget.getTagsLocations();
    }

    getSelectedSuggestions() {
        return this.tagsInputWidget.getTagsItems();
    }

    getLocationIds() {
        return this.tagsInputWidget.getTagsZoneIds();
    }

    getTagsZoneInfos() {
        return this.tagsInputWidget.getTagsZoneInfos();
    }

    hasLocationTagsTypes(types) {
        return this.tagsInputWidget.hasTagsTypes(types);
    }

    setDisabled(disabled) {
        this.tagsInputWidget.disable(disabled);
    }

    onSubmit(cb) {
        if (this._isDoingRequest && this._isSubmitted) {
            cb(new Error('suggestionInputFailed'));
        } else if (this.getInputValue()) {
            const error = new Error('No suggestion selected');
            error.noSuggestionSelected = true;
            cb(error);
        } else {
            this._submit(cb);
        }
    }

    _getPlaceholderText() {
        const {placeholderKey: optionPlaceholderTranslationKey, allowedPlaceTypes: context} = this.options;
        let placeholderTranslationKey = null;
        const hasTags = this.tagsInputWidget.hasTags();
        // TODO: clarify the placeholder handling (including the options sent to the widget)
        // which is too complex ; for example this.naturalLanguageEnabled can be undefined
        if ((this.naturalLanguageEnabled === false) && hasTags) {
            placeholderTranslationKey = 'locationField.placeholderAddCity';
        } else if (this.naturalLanguageEnabled === false || !hasTags) {
            if (optionPlaceholderTranslationKey) {
                placeholderTranslationKey = optionPlaceholderTranslationKey;
            } else if (isMobile || this.naturalLanguageEnabled) {
                placeholderTranslationKey = 'locationField.placeholderSmall';
            } else {
                placeholderTranslationKey = 'locationField.placeholder';
            }
        } else if (this.naturalLanguageEnabled && (this.enablePopup || hasTags)) {
            placeholderTranslationKey = 'locationField.placeholderAddCity';
        }
        return placeholderTranslationKey ? t(placeholderTranslationKey, {context}) : '';
    }

    _submit(cb) {
        const onSubmitFns = this.tagsInputWidget.onSubmitFns();
        async.parallel(onSubmitFns, cb);
    }

    _updatePlaceholder() {
        let placeholderText = this._getPlaceholderText();
        if (!this.naturalLanguageEnabled) {
            placeholderText = _.capitalize(placeholderText);
        }
        const $input = this.getTagsInput();
        this.tagsInputWidget.$input.tagsinput('updatePlaceholder', placeholderText);
        //for natural language, to hide placeholder ?
        $input.toggleClass('smallInput', this.tagsInputWidget.hasTags());
    }

    _toggleFocusClass(toggle) {
        this.tagsInputWidget.getTagsInputWrapper().toggleClass('isfocused', toggle);
    }

    _getButton() {
        let $searchButton = this.$searchButton && this.$searchButton.find('.btn');
        if ($searchButton && $searchButton.length == 0) {
            $searchButton = this.$searchButton;
        }
        return $searchButton;
    }

    _addLocationItems(tags) {
        _.each(tags, tag => {
            this._addLocationItem(tag);
        });
    }

    _addLocationItem(tag) {
        this.tagsInputWidget.addTag(tag);
    }
};
