const _ = require('lodash');
const $ = require('jquery');
const {EventEmitter} = require('events');
const emitter = new EventEmitter();
const emitAdFollowerToggleEvent = require('./emitAdFollowerToggleEvent');
const RealtimeServer = require('../RealtimeServer');
const Account = require('../authentication/Account');
const {rateTheApp} = require('../utils/NativeInterfaceProxy');
const GoogleTagManager = require('../stats/GoogleTagManager');
const IconAnimation = require('../utils/IconAnimation');

const ICON_CLASS = 'flyingFavoriteIcon';

const currentFavorites = [];

module.exports = _.extend(emitter, {
    init,
    toggle,
    updateCurrentFavorites,
    isFavorite,
});

function init() {
    RealtimeServer.on('ad:follower:toggle:error', function (message) {
        console.error('Favorite ad %s error: %s', message.id, message.message);
    });
    RealtimeServer.on('ad:follower:toggle:success', function ({id: realEstateAdId, followed}) {
        setInternalState(realEstateAdId, followed);
        if (followed && rateTheApp) {
            _.delay(rateTheApp, 500);
        }
        GoogleTagManager.sendEvent(followed ? 'favorite_added' : 'favorite_removed', {
            realEstateAdId,
            accountId: Account.getAuthenticatedAccountId(),
        });
    });
}

function toggle(realEstateAdId, newState, {$start} = {}) {
    if (newState != isFavorite(realEstateAdId)) {
        emitAdFollowerToggleEvent(realEstateAdId, newState, err => {
            if (err) {
                console.error(err, 'Could not emit ad follower toggle event');
            } else if ($start) {
                startAnimation(newState, $start);
            }
        });
    }
}

function startAnimation(newState, $start) {
    if (newState) {
        IconAnimation.translateAndFadeOut({
            $start,
            $finish: $('#btn-user-info').find('.userInfoPict'),
            iconClass: ICON_CLASS,
            finishElementClass: 'newFav',
        });
    } else {
        IconAnimation.fallDown({
            $start,
            iconClass: ICON_CLASS,
        });
    }
}

function updateCurrentFavorites(realEstateAds) {
    _.each(realEstateAds, realEstateAd => {
        const isFavorite = realEstateAd.userRelativeData.isFavorite;
        if (null != isFavorite) {
            setInternalState(realEstateAd.id, isFavorite);
        }
    });
}

function setInternalState(realEstateAdId, newState) {
    const currentState = isFavorite(realEstateAdId);
    if (currentState != newState) {
        if (newState) {
            currentFavorites.push(realEstateAdId);
        } else {
            _.pull(currentFavorites, realEstateAdId);
        }
        process.nextTick(() => emitter.emit('change', realEstateAdId, newState)); // allows to retrieve correct favorite status in favoriteAdsStore.js, on markers update, as store is updated on same realtime event handler
    }
}

function isFavorite(realEstateAdId) {
    return _.includes(currentFavorites, realEstateAdId);
}
