module.exports = {
    isAdWithVirtualTour360,
    isAdWithHighlightedVideo,
};

function isAdWithVirtualTour360(realEstateAd) {
    const {with360, hasLotWith360} = realEstateAd;
    return Boolean(with360 || hasLotWith360);
}

function isAdWithHighlightedVideo(realEstateAd) {
    const {withHighlightedVideo, with3dModel, with360} = realEstateAd;
    return Boolean(withHighlightedVideo && !with3dModel && !with360);
}
