const _ = require('lodash');
const template = require('./BillingInformation.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');

// @vue/component
module.exports = {
    mixins: [
        require('../../form/components/mixins/withInternalValue'),
        i18nMixin({
            prefix: 'BillingInformation.',
            keys: {
                contactLastName: 'contactLastNameLabel',
                email: 'emailLabel',
                corporateName: 'corporateNameLabel',
                siret: 'siretLabel',
                'address.city': 'cityLabel',
                'address.postalCode': 'postalCodeLabel',
                'address.street': 'streetLabel',
                siretSirenNotValid: 'invalidSiretOrSirenMsg',
            },
        }),
    ],
    props: {
        value: {
            type: Object,
            required: true,
        },
        disabled: Boolean,
    },
    created() {
        this.internalValue = getInitialValue(this.value);
    },
    template: template(),
};

function getInitialValue(valuesFromProps) {
    const account = Account.getAuthenticatedAccount() || {};
    const email = _.first(Account.getAllEmails(account));
    const {
        company: {
            corporateName,
            address: addressFromAccountCompany,
        } = {},
        siret, // not in account server mapping, but sometimes mapped in importer
        contacts,
        contact,
        billing: billingFromAccount = {},
    } = account;
    const address = _.pick(addressFromAccountCompany, ['city', 'street', 'postalCode']);
    const contactLastName = _([contact])
        .concat(contacts)
        .map('lastName')
        .compact()
        .first();

    const valuesFromAccountData = {
        email,
        corporateName,
        contactLastName,
        siret,
        address,
    };

    return _.defaultsDeep(
        {},
        valuesFromProps,
        billingFromAccount,
        valuesFromAccountData,
        {
            email: null,
            corporateName: null,
            address: {
                street: null,
                postalCode: null,
                city: null,
            },
            contactLastName: null,
            siret: null,
        }
    );
}
