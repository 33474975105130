const _ = require('lodash');
const async = require('async');
const ServerConfig = require('./ServerConfig');
const Ajax = require('./utils/Ajax');

module.exports = {
    init,
    schema: null,
    formConfiguration: null,
};

function init(cb) {
    if (module.exports.schema && module.exports.formConfiguration) {
        _.defer(cb);
    } else {
        loadSchemaAndFormConfiguration(cb);
    }
}

function loadSchemaAndFormConfiguration(cb) {
    async.parallel([
        function (cb) {
            querySchema(function (err, data) {
                if (!err) {
                    module.exports.schema = JSON.stringify(data);
                } //stringify: first part of the dirty way to deep copy schema
                cb(err, data);
            });
        },
        function (cb) {
            queryFormAdFields(function (err, data) {
                if (!err) {
                    module.exports.formConfiguration = data;
                }
                cb(err, data);
            });
        },
    ], cb);
}

function querySchema(callback) {
    return Ajax.request({
        url: ServerConfig.config.importerUrl + '/adsSchema',
        serverErrorMessage: 'querySchema',
        callback,
    });
}

function queryFormAdFields(callback) {
    //adFields are in form mode
    return queryAdFields('formAdFields', callback);
}

function queryAdFields(type, callback) {
    return Ajax.request({
        url: ServerConfig.config.resUrl + '/' + type + '.json',
        serverErrorMessage: 'queryAdFields',
        callback,
    });
}
