const _ = require('lodash');
const {isSupported: isWebPSupported} = require('./WebPSupport');
const {resourceUrl} = require('./ResourceManager');

module.exports = {
    getImageUrlAndConvertToWebpIfSupported,
};

function getImageUrlAndConvertToWebpIfSupported(path) {
    if (isWebPSupported()) {
        path = _.replace(path, /\.(png|jpg)$/, '.webp');
    }
    return resourceUrl(path);
}
