const _ = require('lodash');

const template = require('./ActionButton.jade');
const {adminRecompute} = require('../../admin/adminRecompute');
const Views = require('../../views/Views');

const ACTIONS_TO_REQUEST_METHOD = {
    ..._([
        'geocodeAd',
        'updateDistrictForAd',
        'computeBlurForAd',
        'indexAd',
    ])
        .map(action => [action, 'GET'])
        .fromPairs()
        .value(),
    recomputeChargingStationsForAd: 'POST',
};
const ACTIONS = _.keys(ACTIONS_TO_REQUEST_METHOD);

module.exports = {
    props: {
        realEstateAdId: {
            type: String,
            required: true,
        },
        action: {
            type: String,
            required: true,
            validator: action => _.includes(ACTIONS, action),
            values: ACTIONS,
        },
    },
    data() {
        return {
            disabled: false,
        };
    },
    methods: {
        onClick() {
            this.disabled = true;
            const {action, realEstateAdId} = this;
            adminRecompute({
                action,
                realEstateAdId,
                requestMethod: ACTIONS_TO_REQUEST_METHOD[action],
                callback: (err) => {
                    if (err) {
                        const {message} = err;
                        Views.volatileFeedback.showError(message);
                        console.error(message);
                    } else {
                        window.location.reload();
                    }
                    this.disabled = false;
                },
            });
        },
    },
    template: template(),
};
