const _ = require('lodash');
const $ = require('jquery');
const StringToBoolean = require('../../utils/StringToBoolean');

module.exports = {
    handlesField,
    filterInputsWithValue,
    getValue,
    setValue,
};

function handlesField($field) {
    return $field.prop('tagName') == 'INPUT' && $field.attr('type') == 'radio';
}

function filterInputsWithValue(inputs) {
    const checkedInput = _.find(inputs, input => $(input).is(':checked'));
    const input = checkedInput || _.first(inputs);
    return input ? [input] : [];
}

function getValue($input) {
    let val;
    if ($input.is(':checked')) {
        val = $input.val();
        if ($input.attr('data-type') == 'boolean') {
            return StringToBoolean(val);
        }
    }
    return val;
}

function setValue($input, val) {
    const targetValue = (val != null && String(val)) || '';
    $input.prop('checked', $input.val() == targetValue);
}
