const AccountModificationModalFormView = require('./AccountModificationModalFormView');
const AccountSelectorWithPreview = require('../fields/AccountSelectorWithPreview');
const template = require('../templates/account/addOwnerAccount.jade');

module.exports = class AddOwnerAccountView extends AccountModificationModalFormView {
    constructor() {
        super();
        this.template = template;
    }

    show(options) {
        this.currentAccountId = options.accountId;
        this.previousOwnerIds = options.ownerIds;
        super.show({
            accountId: options.accountId,
            userName: options.userName,
        });
        this.ownerIdSelector = new AccountSelectorWithPreview(
            this.currentAccountId, this.$form.find('.accountSelector'), 'accountForm.addOwner.');
        this.ownerIdSelector.on('loading', () => {
            this.$submitButton.attr('disabled', true);
        });
        this.ownerIdSelector.on('ready', () => {
            this.$submitButton.attr('disabled', false);
        });
    }

    submitForm() {
        const newOwnerId = this.ownerIdSelector.getSelectedId();
        if (newOwnerId) {
            const finalOwnerIds = (this.previousOwnerIds || []).concat([newOwnerId]);
            this.updateAccount({
                id: this.currentAccountId,
                ownerIds: finalOwnerIds,
            });
        } else { //nothing to do
            this.hide();
        }
    }
};
