const _ = require('lodash');
const {resourceUrl} = require('fack');
const moment = require('moment');
const agencyLogoSize = require('../../../ads/agencyLogoSize');
const {getImageUrlFromAlias} = require('../../../ImageHelper');
const {computeAdRelevance} = require('../../../RelevanceScorer');
const {getAvailableTours} = require('../../../virtualTours/VirtualTourHelper');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const {getTitle} = require('../../../../common/RealEstateAdTitleGenerator');
const template = require('./Photo.jade');
const {photoUrlSizeQueryString} = require('../../../imageResConfig');
const AccountLogo = require('../AccountLogo');

const NO_PHOTO_URL = resourceUrl('images/no_photo.png');
const PHOTO_URL_SIZE_QUERY_STRING = photoUrlSizeQueryString('searchResults');

// @vue/component
module.exports = {
    components: {
        AccountLogo,
    },
    mixins: [
        i18nMixin({
            prefix: 'Photo.',
            keys: [
                {
                    'details.alreadySeen': 'alreadySeen',
                    'relevanceTitle.client': 'relevanceTitleClient',
                    'relevanceTitle.indexed': 'relevanceTitleIndexed',
                },
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        hideAlreadySeen: Boolean,
        displayRelevance: Boolean,
        displayPhotoCount: Boolean,
        enableLastContactDateTag: Boolean,
        displayMatchingDate: Boolean,
        displayAsHighlighted: Boolean,
        lastViewDateForSearch: {
            type: String,
            default: null,
        },
    },
    constants: {
        NO_PHOTO_URL,
    },
    computed: {
        alternativeText() {
            return getTitle(this.realEstateAd, 'simpleWithTransactionType');
        },
        photoUrl() {
            let photoUrl = _.get(this.realEstateAd, 'photos.[0].url');
            if (photoUrl) {
                photoUrl = `${photoUrl}?${PHOTO_URL_SIZE_QUERY_STRING}`;
            }
            return photoUrl || NO_PHOTO_URL;
        },
        displayAlreadySeen() {
            return !this.hideAlreadySeen && _.get(this.realEstateAd, 'userRelativeData.lastViewDate');
        },
        clientRelevance() {
            return computeAdRelevance(this.realEstateAd);
        },
        relevanceTitle() {
            const {
                realEstateAd: {relevance},
                clientRelevance: {details, score},
            } = this;
            return `${this.relevanceTitleClient}: ${score} / ${this.relevanceTitleIndexed}: ${relevance}\n${details}`;
        },
        relevanceContent() {
            const {
                clientRelevance: {score},
                realEstateAd: {relevance},
            } = this;
            if (score === relevance) {
                return score;
            } else {
                return `${score} / ${relevance}`;
            }
        },
        accountDisplayName() {
            return this.realEstateAd.accountDisplayName;
        },
        hasWatermark() {
            return Boolean(this.realEstateAd.photoWatermarkAlias);
        },
        watermarkUrl() {
            return getImageUrlFromAlias(this.realEstateAd.photoWatermarkAlias, agencyLogoSize);
        },
        adLogoToDisplayUrl() {
            return getImageUrlFromAlias(_.get(this.realEstateAd, 'logoToDisplay.photo'), agencyLogoSize);
        },
        imageUrlForAccountLogo() {
            return this.watermarkUrl || this.adLogoToDisplayUrl;
        },
        showLabelInfoOnAd() {
            return (this.enableLastContactDateTag && this.hasUserEvents) ||
                (this.realEstateAd.matchingDate && this.displayMatchingDate);
        },
        labelInfoOnAdModifiers() {
            return {
                hasEvent: this.hasUserEvents,
                matchingOldDate: !this.hasUserEvents && this.lastViewDateForSearch && this.hasMatchingDateBeforeLastView,
            };
        },
        hasUserEvents() {
            const {contactRequests, phoneDisplays} = this.realEstateAd;
            return !(_.isEmpty(contactRequests) && _.isEmpty(phoneDisplays));
        },
        hasMatchingDateBeforeLastView() {
            const matchingDateTime = moment(this.realEstateAd.matchingDate);
            return matchingDateTime.isBefore(this.lastViewDateForSearch);
        },
        badgeVariant() {
            const {hasEvent, matchingOldDate} = this.labelInfoOnAdModifiers;
            if (matchingOldDate) {
                return 'secondary';
            } else if (hasEvent) {
                return 'success';
            } else {
                return 'secondColor';
            }
        },
        prefix() {
            if (this.hasUserEvents) {
                return this.t(`lastEventPrefix.${this.lastEvent.type}`);
            } else {
                return '';
            }
        },
        dateInPastFromNow() {
            let date = this.realEstateAd.matchingDate;
            if (this.hasUserEvents) {
                date = this.lastEvent.date;
            }
            const dateInPastFromNowText = moment.min(moment(date), moment()).fromNow();
            return `${this.prefix} ${dateInPastFromNowText}`;
        },
        lastEvent() {
            const {contactRequests, phoneDisplays} = this.realEstateAd;
            const contactRequestLastEvent = {
                type: 'contactRequest',
                date: _.get(_.maxBy(contactRequests, 'date'), 'date'),
            };
            const phoneDisplayLastEvent = {
                type: 'phoneDisplay',
                date: _.get(_.maxBy(phoneDisplays, 'date'), 'date'),
            };
            return _.maxBy([contactRequestLastEvent, phoneDisplayLastEvent], 'date');
        },
        photosAndVirtualToursCount() {
            const {realEstateAd} = this;
            const nbPhotos = _.get(realEstateAd, 'photos.length', 0);
            const nbVirtualTours = _.filter(
                getAvailableTours(realEstateAd.virtualTours || []),
                content => content.url || content.fileServerUrl
            ).length;
            return `${nbPhotos + nbVirtualTours}\u00a0`;
        },
        highlightedModifiers() {
            return {
                highlighted: this.displayAsHighlighted,
            };
        },
        hasAccountLogo() {
            return Boolean(this.accountDisplayName);
        },
        labelInfoAndPhotosCounterModifiers() {
            return _.pick(this, 'hasAccountLogo');
        },
    },
    template: template(),
};
