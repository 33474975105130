const template = require('./SocialMediaButton.jade');

// @vue/component
module.exports = {
    props: {
        provider: {
            type: String,
            required: true,
        },
    },
    computed: {
        imageClass() {
            return [
                'fa',
                'fa-' + this.provider,
            ];
        },
    },
    template: template(),
};
