module.exports = {
    handlesField,
    setValue,
};

function handlesField($field) {
    return $field.prop('tagName') == 'INPUT' && $field.attr('type') == 'date';
}

function setValue($input, val) {
    const valueAsDate = new Date(val);
    if (!isNaN(valueAsDate)) {
        $input.val(valueAsDate.toISOString().replace(/T.*/, ''));
        //.prop() returns undefined if not set, so check null to see if it is supported
        if ($input.prop('valueAsDate') !== null) {
            $input.prop('valueAsDate', valueAsDate);
        }
    } else {
        $input.val(val);
    }
}
