const _ = require('lodash');

module.exports = {
    parse,
};

function parse(locationsStr) {
    return _(locationsStr || '')
        .split(',')
        .reject(location => /^france$/i.test(location))
        .compact()
        .value();
}
