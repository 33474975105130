const _ = require('lodash');
const PropertyTypes = require('./PropertyTypes');

const BUY_AD_TYPES = [
    'buy',
    'investment',
    'prestige',
    'businessTakeOver',
    'lifeAnnuitySale',
];

const HOUSE_TO_BUY = {
    section: {
        form: 'additional_criteria',
    },
    propertyTypes: [
        'house',
    ],
    adTypes: BUY_AD_TYPES,
};

const RENT_CONTROL_MODEL = {
    section: {
        form: 'presentation',
        display: 'rentControl',
    },
    adTypes: [
        'rent',
    ],
    propertyTypes: PropertyTypes.housingSingle,
};

module.exports = [
    {
        name: 'adType',
        section: {
            form: 'main',
            display: 'root',
        },
        required: true,
    },
    {
        name: 'propertyType',
        section: {
            form: 'main',
            display: 'root',
        },
        required: true,
    },
    {
        name: 'title',
        section: {
            form: 'main',
            display: 'root',
        },
        required: true,
    },
    {
        name: 'isExclusiveSaleMandate',
        section: {
            form: 'main',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'isPropertyWithoutUsufruct',
        section: {
            form: 'main',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'isBienIciExclusive',
        section: {
            form: 'main',
            display: 'ad',
        },
        professionalOnly: true,
    },
    {
        name: 'reference',
        section: {
            form: 'main',
            display: 'ad',
        },
        professionalOnly: true,
    },
    {
        name: 'city',
        section: {
            form: 'address',
            display: 'root',
        },
        required: true,
    },
    {
        name: 'postalCode',
        section: {
            form: 'address',
            display: 'root',
        },
        required: true,
    },
    {
        name: 'street',
        section: {
            form: 'address',
        },
        required: true,
    },
    {
        name: 'photos',
        section: {
            form: 'presentation',
            display: 'root',
        },
        propertyTypes: _.map(PropertyTypes.all, name => {
            return {
                name,
                required: !_.includes(['parking', 'terrain'], name),
            };
        }),
    },
    {
        // est-ce bien le loyer dans lots_residences.csv:8 ?
        name: 'price',
        section: {
            form: 'presentation',
            display: 'property',
        },
        required: true,
    },
    {
        name: 'priceWithoutFees',
        section: {
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
    },

    {
        name: 'charges',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.singleBuilt,
    },
    {
        name: 'chargesMethod',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    _.extend({name: 'rentExtra'}, RENT_CONTROL_MODEL),
    _.extend({name: 'isSubjectToRentControl'}, RENT_CONTROL_MODEL),
    _.extend({name: 'rentWithoutCharges'}, RENT_CONTROL_MODEL),
    _.extend({name: 'maxReferenceRent'}, RENT_CONTROL_MODEL),
    {
        // pour les annonces de location pro, et il faut afficher ttc
        name: 'agencyRentalFee',
        section: {
            form: 'presentation',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: [
            'rent',
        ],
    },
    {
        name: 'inventoryOfFixturesFees',
        section: {
            form: 'presentation',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'safetyDeposit',
        section: {
            form: 'presentation',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: [
            'rent',
        ],
    },
    { //old name for feesChargedTo, keep for compatibility
        name: 'feesChargedToThePurchaser',
        section: {
            form: 'presentation',
            display: 'property',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'feesChargedTo',
        section: {
            form: 'presentation',
            display: 'property',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'agencyFeePercentage',
        section: {
            form: 'presentation',
            display: 'property',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'lifeAnnuityMonthlyAllowance',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'lifeAnnuityAgeOfMan',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'lifeAnnuityAgeOfWoman',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
    },
    {
        name: 'surfaceArea',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: _.map([...PropertyTypes.singleBuilt, ...PropertyTypes.programmeAndResidence], name => {
            return {
                name,
                required: name != 'parking',
            };
        }),
    },
    {
        name: 'landSurfaceArea',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: _.union(
            PropertyTypes.housingSingle,
            PropertyTypes.business,
            [
                {
                    name: 'terrain',
                    required: true,
                },
                'programme',
            ]
        ),
    },
    {
        name: 'propertySubType',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: _.union(
            PropertyTypes.housingSingle,
            PropertyTypes.business,
            ['terrain']
        ),
    },
    {
        // lots_residences.csv:5 (attentino ce n'est pas un chiffre). voir comment extraire studio
        name: 'roomsQuantity',
        section: {
            form: 'presentation',
            display: 'property',
        },
        required: true,
        propertyTypes: [
            ...PropertyTypes.programmeAndResidence,
            ...PropertyTypes.housingSingle,
        ],
    },
    {
        // si != studio , >0
        name: 'bedroomsQuantity',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'leaseTerm',
        section: {
            form: 'additional_criteria',
            display: 'ad',
        },
        adTypes: [
            'rent',
        ],
    },
    {
        name: 'description',
        section: {
            form: 'presentation',
            display: 'root',
        },
        required: true,
    },
    {
        name: 'pitch',
        section: {
            display: 'root',
        },
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'energyValue',
        section: {
            form: 'presentation',
            display: 'root',
        },
        required: true,
        propertyTypes: PropertyTypes.withEnergyDiagnostic,
    },
    {
        name: 'energyClassification',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.withEnergyDiagnostic,
    },
    {
        name: 'greenhouseGazValue',
        section: {
            form: 'presentation',
            display: 'root',
        },
        required: true,
        propertyTypes: PropertyTypes.withEnergyDiagnostic,
    },
    {
        name: 'greenhouseGazClassification',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.withEnergyDiagnostic,
    },
    {
        name: 'useJuly2021EnergyPerformanceDiagnostic',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'energyPerformanceDiagnosticDate',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'minEnergyConsumption',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'maxEnergyConsumption',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'averageAnnualEnergyConsumption',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'energySimulationReferenceDate',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'epdFinalEnergyConsumption',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'isRecent',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: PropertyTypes.singleBuilt,
    },
    {
        name: 'isRefurbished',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: PropertyTypes.singleBuilt,
    },
    {
        name: 'workToDo',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
        propertyTypes: PropertyTypes.singleBuilt,
    },
    {
        name: 'noWorkToDo',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
        propertyTypes: PropertyTypes.singleBuilt,
    },
    {
        // voir aussi leaseType (nature du bail) qui peut valoir \"Location meublée\" d'après le MBI, mais n'est jamais renseigné, mais ça peut être utile dans de futur Poliris ?
        name: 'isFurnished',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'isNotFurnished',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'isServicedLot',
        section: {
            form: 'presentation',
            display: 'property',
        },
        propertyTypes: [
            'terrain',
        ],
    },
    {
        name: 'isDisabledPeopleFriendly',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: _.without(PropertyTypes.all, 'terrain'),
    },
    {
        name: 'hasTerrace',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasBalconyOrTerrace',
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'terracesQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'terraceSurfaceArea',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasBalcony',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'balconyQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'balconySurfaceArea',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasConservatory',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasGeorisquesMention',
        section: {
            display: 'root',
        },
        propertyTypes: PropertyTypes.all,
    },
    {
        name: 'hasGarden',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.housingSingle, 'castle'),
    },
    {
        name: 'hasEnclosedGarden',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.housingSingle, 'castle'),
    },
    {
        name: 'gardenSurfaceArea',
        section: {
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.housingSingle, 'castle'),
    },
    {
        name: 'hasGarage',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.housing, 'flat'),
    },
    {
        name: 'garagesQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.housing, 'flat'),
    },
    {
        name: 'hasParking',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'indoorParkingQuantity',
        section: {
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'outdoorParkingQuantity',
        section: {
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'parkingPlacesQuantity',
        section: {
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'hasEnclosedParking',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'enclosedParkingQuantity',
        section: {
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'hasUndergroundParking',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.programmeAndResidence,
    },
    {
        name: 'vehiclesQuantity',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'parking',
        ],
    },
    {
        name: 'hasPool',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'isOnLastFloor',
        propertyTypes: [
            'flat',
            'office',
            'premises',
        ],
    },
    {
        name: 'isGroundFloor',
        propertyTypes: [
            'flat',
            'office',
            'premises',
        ],
    },
    {
        name: 'isNotGroundFloor',
        propertyTypes: [
            'flat',
            'office',
            'premises',
        ],
    },
    {
        name: 'floorQuantity',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        name: 'floor',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: [
            'flat',
            'office',
            'premises',
        ],
    },
    {
        name: 'yearOfConstruction',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'exposition',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'heating',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        // à voir si on merge avec programHeatingType
        name: 'heatingSource',
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'heatingOperator',
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'heatingEnergy',
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'hasCellar',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.union(PropertyTypes.housingSingle, ['programme']),
    },
    {
        name: 'cellarsOrUndergroundsQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: _.union(PropertyTypes.housingSingle, ['programme']),
    },
    {
        name: 'hasParquet',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasFirePlace',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'firePlacesQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'isDuplex',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'flat',
            'loft',
        ],
    },
    {
        name: 'hasBathroom',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'bathroomsQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasShowerRoom',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'showerRoomsQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasSeparateToilet',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'toiletQuantity',
        section: {
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        // dans c21, il faut parser les différents types de pièce
        name: 'hasSeparateKitchen',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasEquippedKitchen',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasElevator',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: [
            'flat',
            'office',
            'premises',
            'programme',
            'residence',
        ],
    },
    {
        name: 'hasDoorCode',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'hasVideophone',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'hasIntercom',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'hasCaretaker',
        section: {
            form: 'additional_criteria',
            display: 'condominium',
        },
        propertyTypes: _.without(PropertyTypes.housing, 'house'),
    },
    {
        name: 'hasAlarm',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'hasTVCable',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'opticalFiberStatus',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.all, 'parking'),
    },
    {
        name: 'chargingStations',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'flat',
            'parking',
            'programme',
        ],
    },
    {
        name: 'isCalm',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.union(PropertyTypes.housingSingle, ['programme']),
    },
    {
        name: 'hasAirConditioning',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingAndBusiness,
    },
    {
        // remplace hasFaceToFace. doit-on conserver 'vis-à-vis: oui' ? n'y a-t-il pas un doublon avec hasUnobstructedView ?
        name: 'hasNoFaceToFace',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'hasUnobstructedView',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'isNew',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'newOrOld',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        adTypes: [
            'buy',
            'rent',
            'investment',
            'prestige',
        ],
        propertyTypes: _.without(PropertyTypes.singleBuilt, 'parking'),
    },
    {
        name: 'isInSeniorResidence',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'rent',
            'investment',
            'prestige',
            'lifeAnnuitySale',
        ],
        propertyTypes: PropertyTypes.housing,
    },
    {
        // remplace isStudentResidence (annonces.csv:148+152 ?).  location uniquement et pas dans le formulaire. attention on a aussi isStudentHousing (annonces.csv:152). et code accroche (programmes_enrichis.csv:100)?
        name: 'isInStudentResidence',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'rent',
            'investment',
            'prestige',
        ],
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'isInTourismResidence',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'investment',
            'prestige',
        ],
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'isInManagedResidence',
        section: {
            display: 'property',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'investment',
            'prestige',
        ],
        propertyTypes: PropertyTypes.housing,
    },
    {
        name: 'flatSharingNotAllowed',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'flatSharing',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        adTypes: [
            'rent',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'isInCondominium',
        section: {
            form: 'additional_criteria',
            display: 'root',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
        propertyTypes: [
            'flat',
            'house',
            'parking',
            'terrain',
            'loft',
            'unknown',
        ],
    },
    {
        name: 'condominiumPartsQuantity',
        section: {
            form: 'additional_criteria',
            display: 'program',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
        propertyTypes: [
            'flat',
            'house',
            'parking',
            'terrain',
            'loft',
            'programme',
            'unknown',
        ],
    },
    {
        name: 'annualCondominiumFees',
        section: {
            form: 'additional_criteria',
            display: 'root',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
        propertyTypes: [
            'flat',
            'house',
            'parking',
            'terrain',
            'loft',
            'unknown',
        ],
    },
    {
        name: 'isCondominiumInProcedure',
        section: {
            form: 'additional_criteria',
            display: 'root',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
        propertyTypes: [
            'flat',
            'house',
            'parking',
            'terrain',
            'loft',
            'unknown',
        ],
    },
    {
        name: 'condominiumDetailedProcedure',
        section: {
            form: 'additional_criteria',
            display: 'root',
        },
        professionalOnly: true,
        adTypes: BUY_AD_TYPES,
        propertyTypes: [
            'flat',
            'house',
            'parking',
            'terrain',
            'loft',
            'unknown',
        ],
    },
    {
        name: 'reportAmount',
        section: {
            form: 'additional_criteria',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: [
            'investment',
            'businessTakeOver',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        name: 'buyingValue',
        section: {
            form: 'additional_criteria',
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: [
            'investment',
            'businessTakeOver',
        ],
        propertyTypes: PropertyTypes.housingSingle,
    },
    {
        // nom à revoir
        name: 'isLotOverlookingStreet',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'terrain',
        ],
    },
    {
        // nom à revoir
        name: 'isAgriculturalLand',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'terrain',
        ],
    },
    {
        // nom à revoir
        name: 'isSlopingGround',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'terrain',
        ],
    },
    {
        name: 'isBuildingPlot',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'terrain',
        ],
    },
    {
        name: 'hasWaterbody',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        propertyTypes: [
            'terrain',
            'townhouse',
            'building',
            'castle',
        ],
    },
    {
        name: 'isInParkingBuilding',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        professionalOnly: true,
        propertyTypes: [
            'parking',
        ],
    },
    {
        name: 'isIsolatedParking',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
        professionalOnly: true,
        propertyTypes: [
            'parking',
        ],
    },
    {
        name: 'isParkingIncluded',
        section: {
            form: 'additional_criteria',
            display: 'program',
        },
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'programState',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'rent',
            'investment',
        ],
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'isEligibleForPinelLaw',
        section: {
            display: 'program',
        },
        adTypes: [
            'buy',
            'investment',
        ],
        propertyTypes: [
            'flat',
            'house',
            'loft',
            'programme',
        ],
    },
    {
        name: 'investmentType',
        section: {
            display: 'ad',
        },
        professionalOnly: true,
        adTypes: [
            'investment',
        ],
        propertyTypes: [
            'flat',
            'house',
            'parking',
            'terrain',
            'loft',
            'programme',
        ],
    },
    {
        // remplace officeAddress
        name: 'POSStreet',
        section: {
            display: 'POS',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        // remplace officeAddress
        name: 'POSPostalCode',
        section: {
            display: 'POS',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        // remplace officeAddress
        name: 'POSCity',
        section: {
            display: 'POS',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'officeAddress',
        section: {
            display: 'property',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'pdf',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'commercialName',
        section: {
            display: 'root',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'POSOpeningPeriod',
        section: {
            display: 'POS',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'programHeatingType',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'annexPriceIncluded',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'hasWashingMachine',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasPhonePoint',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasCafeteria',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasManager',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasConvenienceALS',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasConvenienceLunch',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasTelevision',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasMeetingRoom',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasLinenService',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasCleaningService',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasCopyMachine',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasTwoWheelersRoom',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasFreeInternet',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'hasFreeOpticFiberInternet',
        section: {
            display: 'program',
        },
        professionalOnly: true,
        propertyTypes: [
            'residence',
        ],
    },
    {
        name: 'publicationDate',
        section: {
            display: 'ad',
        },
    },
    {
        name: 'modificationDate',
        section: {
            display: 'ad',
        },
    },
    {
        name: 'closingDate',
        section: {
            display: 'ad',
        },
    },
    {
        name: 'position',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'blurInfo',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'district',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'id',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'accountId',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'residenceReference',
        section: {
            display: 'root',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'rent',
            'investment',
            'prestige',
        ],
    },
    {
        name: 'newProperty',
        section: {
            display: 'root',
        },
        professionalOnly: true,
        propertyTypes: _.without(PropertyTypes.all, 'terrain'),
    },
    {
        name: 'adCreatedByPro',
        section: {
            display: 'root',
        },
        professionalOnly: true,
    },
    {
        name: 'programmeRef',
        section: {
            display: 'root',
        },
        professionalOnly: true,
        adTypes: [
            'buy',
            'rent',
            'investment',
            'prestige',
        ],
    },
    {
        // il y a aussi des champs dans lots_enrichis.csv, à voir (completionDate, deliveryDate)
        name: 'availableDate',
        section: {
            form: 'additional_criteria',
            display: 'property',
        },
    },
    {
        // nombre de biens disponible pour ce type d'annonce (MGC)
        name: 'availableLotQuantity',
        section: {
            display: 'property',
        },
    },
    {
        name: 'roomsQuantityType',
        section: {
            display: 'root',
        },
        propertyTypes: [
            'flat',
        ],
    },
    {
        name: 'isStudio',
        section: {
            display: 'root',
        },
        propertyTypes: [
            'flat',
        ],
    },
    {
        name: 'isPreview',
        adTypes: [
            'buy',
            'investment',
        ],
    },
    {
        name: 'has3DModel',
        propertyTypes: _.union(PropertyTypes.housingSingle, ['programme', 'office']),
    },
    {
        name: 'virtualTours',
        section: {
            display: 'root',
        },
    },
    {
        name: 'hasLotWith360',
        section: {
            display: 'root',
        },
        propertyTypes: [
            'programme',
        ],
    },
    {
        name: 'with360',
        section: {
            display: 'root',
        },
    },
    {
        name: 'agencyFeeUrl',
        section: {
            display: 'ad',
        },
    },
    {
        name: 'agencyFeeFilename',
        section: {
            display: 'ad',
        },
    },
    {
        name: 'promotedIfRecent',
        section: {
            display: 'root',
        },
    },
    {
        name: 'relevanceBonus',
        section: {
            display: 'root',
        },
    },
    {
        name: 'isLotType',
        section: {
            display: 'root',
        },
    },
    {
        name: 'photoWatermarkAlias',
        section: {
            display: 'root',
        },
    },
    {
        name: 'accountType',
        section: {
            display: 'root',
        },
    },
    {
        name: 'deliveryDate',
        section: {
            display: 'property',
        },
    },
    {
        name: 'isLeading',
        section: {
            form: 'bienici_team',
        },
    },
    {
        name: 'highlighted',
        section: {
            form: 'bienici_team',
        },
    },
    {
        name: 'is3dHighlighted',
        section: {
            form: 'bienici_team',
            display: 'root',
        },
    },
    {
        name: 'onlineBookingUrl',
        section: {
            form: 'bienici_team',
            display: 'root',
        },
    },
    {
        name: 'onlineMeetingScheduleLink',
        section: {
            form: 'bienici_team',
            display: 'root',
        },
    },
    {
        name: 'displayDistrictName',
        section: {
            form: 'address',
            display: 'root',
        },
    },
    _.extend({name: 'hasToBeBuilt'}, HOUSE_TO_BUY),
    _.extend({name: 'hasNotToBeBuilt'}, HOUSE_TO_BUY),
    {
        name: 'isEligibleForDenormandieLaw',
        section: {
            form: 'presentation',
            display: 'property',
        },
        adTypes: BUY_AD_TYPES,
        propertyTypes: PropertyTypes.FLAT_AND_HOUSE,
    },
    {
        name: 'saleInfo',
        section: {
            display: 'root',
        },
    },
    {
        name: 'singleStoreyHouse',
        propertyTypes: [
            'house',
        ],
    },
    {
        name: 'specialOffer',
        section: {
            display: 'root',
        },
    },
    {
        name: 'accountDisplayName',
        section: {
            display: 'root',
        },
    },
    {
        name: 'logoToDisplay',
        section: {
            display: 'root',
        },
    },
];
