const _ = require('lodash');
const $ = require('jquery');
const fack = require('fack');
const BrowserDetect = require('browser-detect');
const async = require('async');
const MP = require('mercatorProjection');
const {EventEmitter} = require('events');

const MapApi = require('../MapApi');
const ServerConfig = require('../ServerConfig');
const PoiInterface = require('../poi/PoiInterface');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const GraphicsQualityDetect = require('../utils/DeviceGraphicsQualityDetect');
const CookiesController = require('../Cookies.js');
const PopupLayerHelper = require('../PopupLayerHelper');
const ApplicationConfig = require('../app/ApplicationConfig');
const CameraManager = require('./CameraManager');
const renderTemplate = require('../views/utils/renderTemplate');
const MapOptionsLocalStorage = require('./localStorage/MapOptionsLocalStorage');
const {
    getMapTypeIdFromOptions,
    isWebglEnabled,
} = require('./MapHelper');

const graphicOptionsMenuTemplate = require('../templates/graphicOptionsMenu.jade');
const mapAttributionTemplate = require('../templates/mapAttribution.jade');
const expandMapButtonTemplate = require('../templates/expandMapButton.jade');
const webGLDisabledTemplate = require('../templates/webGLDisabled.jade');

const isMobile = Boolean(BrowserDetect.isMobile());
const isTablet = Boolean(BrowserDetect.isTablet());
const enabledSSAO = (!isMobile && !isTablet);
const savedOptions = require('../Options').read();

module.exports = {
    createMapHelper,
    getMapStats,
};

const DEFAULT_GRAPHIC_OPTIONS = {
    shadow: false,
    ssao: false,
    ssaoWhenMoving: false,
    waterRipple: false,
    waterReflection: false,
    dontPreLoadLayersOnAnimation: true,
    minZoomLayer2D: 15,
    useDevicePixelRatio: false,
    skipWorkIn3d: false,
    useWebWorkers: !(isMobile || isTablet),
    elevation: false,
};

const MAP_OPTIONS = _.mapValues({
    high: {
        shadow: true,
        ssao: enabledSSAO,
        waterRipple: true,
        waterReflection: true,
        useDevicePixelRatio: true,
        ssaoWhenMoving: enabledSSAO,
        dontPreLoadLayersOnAnimation: false,
        elevation: true, // if enabled in Options
        area: {
            elevationsEnabled: true,
        },
    },
    medium: {
        shadow: true,
        ssao: enabledSSAO,
        waterRipple: true,
        dontPreLoadLayersOnAnimation: true,
        useDevicePixelRatio: false,
        elevation: true, // if enabled in Options
        area: {
            elevationsEnabled: true,
        },
    },
    low: {
        minZoomLayer2D: 16,
        dontPreLoadLayersOnAnimation: true,
        useDevicePixelRatio: false,
        skipWorkIn3d: true,
    },
}, function (graphicOptions) {
    return _.defaults(graphicOptions, DEFAULT_GRAPHIC_OPTIONS);
});

const CAMERA_OPTIONS = _.mapValues({
    high: {},
    medium: {},
    low: {
        camera: {
            defaultMaxTheta: {
                17: 60,
                18: 70,
                19: 80,
            },
        },
    },
}, function (options) {
    return _.defaults(options, {
        camera: {
            defaultMaxTheta: 80,
        },
    });
});

const POISTRANSPORTLAYER_OPTIONS = _.mapValues({
    high: {},
    medium: {},
    low: {},
}, function (options) {
    return _.defaults(options, {
        workOnMove: false,
    });
});

const POISLAYER_OPTIONS = _.mapValues({
    high: {},
    medium: {},
    low: {},
}, function (options) {
    return _.defaults(options, {
        workOnMove: false,
    });
});

const BUILDINGSLAYER_OPTIONS = _.mapValues({
    high: {
        minimisedGeoPrecision: 0.01, // in meters, 0.01 = 1cm maximum granulation
    },
    medium: {
        workOnMove: false,
        minimisedGeoPrecision: 0.1, // in meters, 0.1 = 10cm maximum granulation
    },
    low: {
        minZoom: 17,
        workOnMove: false,
        maxDistance: 2500,
        unloadDistance: 3500,
        minimisedLevel: 0x01,  // buildings only (no roofs, colors or names)
        minimisedGeoPrecision: 0.2, // in meters, 0.2 = 20cm maximum granulation (exceeding can create render errors)
    },
}, function (options) {
    return _.defaults(options, {
        maxDistance: 7500,
        unloadDistance: 10000,
        workOnMove: true,
        minZoom: 16,
        minimisedLevel: 0xFF,  // everything
        minimisedDataEnabled: true,
    });
});

const DETAILSLAYER_OPTIONS = _.mapValues({
    high: {},
    medium: {},
    low: {
        minZoom: 17,
        maxDistance: 2500,
        details: [
            'tree',
            'barrier',
            'fence',
            'crane',
        ],
    },
}, function (options) {
    return _.defaults(options, {
        minZoom: 16,
        workOnMove: false,
        maxDistance: 7500,
        details: [
            'fountain',
            'scrub',
            'tree',
            'rock',
            'vineyard',
            'sculpture',
            'street_lamp',
            'flag',
            'barrier',
            'fence',
            'wind_generator',
            'petroleum_well',
            'crane',
            'power_line',
            'power_pole',
            'power_tower',
            'aerial_pylon',
            'aerial_line',
            'lighthouse',
            'cemetery',
            'submarine',
            'ghost',
        ],
        useExclusionZones: true,
    });
});

/**
 * Create a map. Same as createMap() where options.$map is replaced by the node parameter
 * @param {node} node - the HTML node where the map will be appended
 * @param {Object} options - a combination of MapApi.api.Map~MapOptions and kimono-specific options
 * @private
 */
function createMapHelper(node, options) {
    options = _.defaults(options, {
        usePois: true,
        enablePoiAgencies: false,
        showGraphicLevelButton: false,
        showPoisButton: true,
        showExpandMapButton: false,
    });
    if (options.level == null) {
        options.level = _getDefaultGraphicOptionsLevel();
    }
    if (!MapApi.api) {
        MapApi.init(() => {
            initSimpleMap(node, options);
        });
    } else {
        initSimpleMap(node, options);
    }
}

/**
 * Helper to create and initialize a kimono map
 * @param {node} node - the HTML node where the map will be appended
 * @param {Object} options - a combination of MapApi.api.Map~MapOptions and kimono-specific options
 * @private
 */
function initSimpleMap(node, options) {
    let view = options.view;
    if (!view) {
        view = options.view = new EventEmitter();
    }

    const mapOptions = _getMapOptions(options);
    const map = view.map = new MapApi.api.Map(node, mapOptions);
    if (options.showExpandMapButton) {
        _createExpandMapButton(map, options);
    }
    view._cameraManager = new CameraManager();
    view._cameraManager.setMap(map);
    if (options.onMapCreatedCallback) {
        async.setImmediate(_.partial(options.onMapCreatedCallback, map));
    }
    if (map._renderer) {
        map.setTimeRatio(0);
        if (isWebglEnabled(map)) {
            _createBuildingLayer(map, options);
            _createDetailsLayer(map, options);
        } else {
            _createNoWebGlWarning(map);
        }
        _createPoisLayer(map, options);
        if (options.showGraphicLevelButton) {
            _initGraphicOptionsMenu(map, options);
        } else {
            _setMapOptions(map, options);
            _setCameraOptions(map, options.level);
        }
        _setViewHideHandler(options);
        if (options.onCameraCreatedCallback) {
            async.setImmediate(options.onCameraCreatedCallback);
        }
    }
}

function _setViewHideHandler(options) {
    const view = options.view;
    if (!view._onViewHideHandler) {
        view._onViewHideHandler = _.bind(onViewHide, view);
        view.on('hide', view._onViewHideHandler);
    }
}

function _getMapOptions(options) {
    let mapOptions = options.mapOptions || {};
    mapOptions = _.extend({}, getMapOptions(options.level), mapOptions);
    if (options.getMapOptionsCallback) {
        mapOptions = _.extend({}, mapOptions, options.getMapOptionsCallback());
    }
    const attributionTemplate = options.mapAttributionTemplate || mapAttributionTemplate;
    const aroundMeEnabled = _.get(options, 'ui.enableGeoloc', true);
    mapOptions = _.defaults(mapOptions, {
        center: new MapApi.api.LatLng(+DefaultConfiguration.camera.lat, +DefaultConfiguration.camera.lon),
        zoom: +DefaultConfiguration.camera.zoom,
        attribution: attributionTemplate({
            wwwUrl: ServerConfig.config.wwwUrl,
            resourceUrl: fack.resourceUrl,
        }),
        updateHRef: false,
        tiles: {
            enableMovingSubdivide: false,
        },
        time: {
            initialDate: 'Wed, 23 Apr 2014 16:19:38 +0200', // todo change time with the longitude
        },
        graphicOptions: {
            waterReflection: false,
        },
        ui: {
            enableLoadingSpinner: false,
            enableGeoloc: aroundMeEnabled,
        },
        buildingLayerOptions: getBuildingLayerOptions(options.level),
        detailsLayer: null,
        styles: [ // todo: remove kimono POIs and replace them with F4map POIs
            {
                featureType: 'poi',
                stylers: [{visibility: 'off'}],
            },
            {
                featureType: 'transit',
                stylers: [{visibility: 'off'}],
            },
        ],
        watcher: {
            name: ApplicationConfig.title,
        },
    });

    if (isMobile && mapOptions.ui.enableGeoloc) {
        mapOptions.onGeolocClickedCallback = function () {
            options.view.emit('mapGeolocationClicked');
        };
    }

    if (isTablet || isMobile) {
        mapOptions.minDT = 1000;
    }

    mapOptions.keyboardShortcuts = Boolean(savedOptions.mapKeyboard || mapOptions.keyboardShortcuts);
    mapOptions.debugExtraKeys = savedOptions.mapKeyboard;
    if (options.mockupLimit) {
        initMockupLimit(options.mockupLimit, mapOptions);
    }
    return mapOptions;
}

function get3dOption() {
    const user3dPreference = MapOptionsLocalStorage.get3dPreference();
    if (user3dPreference == null) {
        return Boolean(DefaultConfiguration.camera['3d']);
    } else {
        return user3dPreference;
    }
}

let precipitationLayer = null;
let cloudLayer = null;
let windLayer = null;
let _weatherAnimationTimeout = null;

function initChristmasMode(map, level) {
    if (savedOptions.christmasDesignEnabled && !isMobile) {
        if (level != 'low') {
            if (!precipitationLayer) {
                createChristmasWeather();
            }
        } else if (precipitationLayer) {
            deleteChristmasWeather();
        }
    }

    function deleteChristmasWeather() {
        if (cloudLayer) {
            cloudLayer.setMap(null);
            cloudLayer = null;
        }
        if (windLayer) {
            windLayer.setMap(null);
            windLayer = null;
        }
        if (precipitationLayer) {
            precipitationLayer.setMap(null);
            precipitationLayer = null;
        }
        onDivRemovedForWeather();
    }

    function createChristmasWeather() {
        const weather = MapApi.api.weather;
        precipitationLayer = new weather.PrecipitationLayer();
        cloudLayer = new weather.CloudLayer();
        windLayer = new weather.WindLayer();
        windLayer.setHeading(90); // west to east
        windLayer.setSpeed(3);
        precipitationLayer.setMap(map);
        cloudLayer.setMap(map);
        windLayer.setMap(map);
        animateSnowDensity(0, true);
        map.on('divRemoved', onDivRemovedForWeather);

        function animateSnowDensity(step, withMoreDensity) {
            if (precipitationLayer) {
                precipitationLayer.setType({
                    speed: 15,
                    particleWidth: 1.1,
                    particleHeight: 1.1,
                    particleAlpha: step >= 4 ? 0.9 : 0.0,
                    particleCount: 300 * step - 4,
                    windFactor: 2.5,
                });
            }
            if (cloudLayer) {
                cloudLayer.setCover(step);
                cloudLayer.setOpacity(step / 100);
            }
            _weatherAnimationTimeout = setTimeout(() => {
                if (withMoreDensity) {
                    step = step + 1;
                } else {
                    step = step - 1;
                }
                if (step > 8) {
                    withMoreDensity = false;
                } else if (step < 1) {
                    withMoreDensity = true;
                }
                animateSnowDensity(step, withMoreDensity);
            }, 5000);
        }
    }

    function onDivRemovedForWeather() {
        if (_weatherAnimationTimeout != null) {
            clearTimeout(_weatherAnimationTimeout);
            _weatherAnimationTimeout = null;
        }
        if (map) {
            map.removeListener('divRemoved', onDivRemovedForWeather);
        }
    }
}

function getScaleFromLatitude(lat) {
    return Math.cosh(Math.PI * lat / MP.MAX_EXTENT);
}

function initMockupLimit(mockupLimit, mapOptions) {
    const distance = mockupLimit.distance;
    const zoomMin = mockupLimit.zoomMin;
    const lon = mapOptions.center.lng();
    const lat = mapOptions.center.lat();
    const lon900913 = MP.convertLon4326To900913(lon);
    let lonRadius = Math.abs(lon - MP.convertLon900913To4326(lon900913 - distance));
    const lat900913 = MP.convertLat4326To900913(lat);
    let latRadius = Math.abs(lat - MP.convertLat900913To4326(lat900913 - distance));
    const scale = getScaleFromLatitude(lat900913);
    lonRadius *= scale;
    latRadius *= scale;
    const sw = new MapApi.api.LatLng(lat - latRadius, lon - lonRadius).to900913();
    const ne = new MapApi.api.LatLng(lat + latRadius, lon + lonRadius).to900913();
    let camera = mapOptions.camera;
    if (!camera) {
        camera = mapOptions.camera = {};
    }
    camera.zoomMin = zoomMin;
    let limit = mapOptions.limit;
    if (!limit) {
        limit = mapOptions.limit = {};
    }
    limit.mockup = {
        xMin: sw.lon,
        xMax: ne.lon,
        yMin: sw.lat,
        yMax: ne.lat,
    };
}

function _setGraphicOptions(map, options) {
    const level = options.level;
    const view = options.view;
    _setMapOptions(map, options);
    if (view.buildingLayer) {
        view.buildingLayer.setOptions(getBuildingLayerOptions(level));
    }
    if (view.detailsLayer) {
        view.detailsLayer.setOptions(getDetailsLayerOptions(level, view.detailsLayer.customDetailsOptions));
    }
    if (view.poiInterface && view.poiInterface.getPoiLayer()) {
        const poiLayer = view.poiInterface.getPoiLayer();
        poiLayer.setOptions(_getPoisLayerOptions(level));
    }
    _setCameraOptions(map, level);
    initChristmasMode(map, level);
}

function _setMapOptions(map, options) {
    const level = options.level;
    const view = options.view;
    map.setOptions(getMapOptions(level));
    if (map._renderer._tileManager) {
        map._renderer._tileManager.onRefreshMaterial();
    }
    if (view.onQualityLevelChanged) {
        view.onQualityLevelChanged(level);
    }
}

function _createPoisLayer(map, options) {
    if (options.usePois) {
        _createPoisInterface(map, options);
    }
    options.view.popupLayer = PopupLayerHelper.create(map);
}

function _createExpandMapButton(map, options) {
    const view = options.view;
    view._isMapExpanded = false;
    const $expandMapButton = renderTemplate(expandMapButtonTemplate);
    $expandMapButton.appendTo($(map.getDiv()));
    $expandMapButton.on('click', function () {
        toggleExpand(!view._isMapExpanded);
    });
    $expandMapButton.toggle(true);
    if (view.on) {
        view.on('toggledMap', onMapToggled);
        view.on('closeMapExpand', onCloseMapExpand);
        view.on('openMapExpand', onOpenMapExpand);
    }
    map.on('divRemoved', onDivRemoved);

    function onDivRemoved() {
        view.removeListener('toggledMap', onMapToggled);
        view.removeListener('closeMapExpand', onCloseMapExpand);
        view.removeListener('openMapExpand', onOpenMapExpand);
        map.removeListener('divRemoved', onDivRemoved);
    }

    function onOpenMapExpand() {
        toggleExpand(true);
    }

    function onCloseMapExpand() {
        toggleExpand(false);
    }

    function onMapToggled(enabled) {
        $expandMapButton.toggle(enabled);
    }

    function toggleExpand(fullScreen) {
        if (view._isMapExpanded != fullScreen) {
            view._isMapExpanded = fullScreen;
            view.$element.parent().toggleClass('kimono-mapFullscreen', fullScreen);
            if (view.emit) {
                view.emit('mapExpanded', fullScreen);
            }
            if (fullScreen) {
                $(document).on('keyup', onEscKey);
            } else {
                $(document).off('keyup', onEscKey);
            }
        }
    }

    function onEscKey(event) {
        if (event.keyCode == 27/*esc*/) {
            toggleExpand(false);
        }
    }
}

function _getDefaultGraphicOptionsLevel() {
    let defaultLevel = CookiesController.getSelectedGraphicOptionsLevel();
    if (!defaultLevel) {
        // Determine if device should be on low quality for blacklist
        const detectedQuality = GraphicsQualityDetect.detectGraphicsQuality();
        // If we detect the quality then set it
        if (detectedQuality != GraphicsQualityDetect.UnknownQuality) {
            if (detectedQuality == GraphicsQualityDetect.LowQuality) {
                defaultLevel = 'low';
            } else if (detectedQuality == GraphicsQualityDetect.MediumQuality) {
                defaultLevel = 'medium';
            } else if (detectedQuality == GraphicsQualityDetect.HighQuality) {
                defaultLevel = 'high';
            }
        } else {
            // Use default level
            const isSmall = isMobile || isTablet;
            if (isSmall) {
                defaultLevel = 'low';
            } else {
                defaultLevel = 'medium';
            }
        }
    }
    return defaultLevel;
}

function _initGraphicOptionsMenu(map, options) {
    let $graphicOptionsMenu;
    if (isWebglEnabled(map)) {
        const view = options.view;
        const $map = $(map.getDiv());
        $graphicOptionsMenu = renderTemplate(graphicOptionsMenuTemplate, {}).appendTo($map);
        view._onMapToggledHandler = onMapToggledMap;
        view.on('toggledMap', view._onMapToggledHandler);
        $graphicOptionsMenu.on('click', 'a', handleGraphicOptionsLevelClick);
        //stopPropagation on $graphicOptionsMenu breaks dropdown auto initialization, so init manually
        $graphicOptionsMenu.find('#kimono-graphicOptionsButton').dropdown();
        updateLineWidget(options.level);
    } else {
        options.level = 'low';
    }
    _setGraphicOptions(map, options);

    function onMapToggledMap(enabled) {
        if ($graphicOptionsMenu) {
            $graphicOptionsMenu.css('display', enabled ? 'block' : 'none');
        }
        if (enabled && map._renderer) {
            options.level = _getDefaultGraphicOptionsLevel();
            _setGraphicOptions(map, options);
            updateLineWidget(options.level);
        }
    }

    function updateLineWidget(level) {
        $graphicOptionsMenu.find('.selected').removeClass('selected');
        $graphicOptionsMenu.find('[data-level="' + level + '"]').closest('li').addClass('selected');
    }

    function handleGraphicOptionsLevelClick(event) {
        const $element = $(event.target);
        const $li = $element.closest('li');
        if (!$li.hasClass('selected')) {
            $graphicOptionsMenu.find('.selected').removeClass('selected');
            $li.addClass('selected');
            const graphicOptionsLevel = $element.attr('data-level');
            $graphicOptionsMenu.removeClass('open');
            options.level = graphicOptionsLevel;
            _setGraphicOptions(map, options);
            CookiesController.setSelectedGraphicOptionsLevel(graphicOptionsLevel);
        }
    }
}

function getOptions(optionsByLevel, level) {
    if (level) {
        return optionsByLevel[level];
    } else {
        return optionsByLevel;
    }
}

function _getPoisTransportLayerOptions(level) {
    return getOptions(POISTRANSPORTLAYER_OPTIONS, level);
}

function _getPoisLayerOptions(level) {
    return getOptions(POISLAYER_OPTIONS, level);
}

function _getCameraOptions(level) {
    return getOptions(CAMERA_OPTIONS, level);
}

function _setCameraOptions(map, level) {
    const cameraOptions = _getCameraOptions(level);
    _.each(cameraOptions, function (value, key) {
        const cameraOption = _.defaults(map.conf.get(key), value);
        map.conf.set(key, cameraOption);
    });
    if (map._renderer._cameraManager) {
        map._renderer._cameraManager.rebuildLimits();
    }
}

function getMapOptions(level) {
    const mapOptions = getOptions(MAP_OPTIONS, level);

    // Activate elevations only if enabled in saved options too
    // todo: should not store elevation in returned mapOptions. If we delete it now, it will remove it from MAP_OPTIONS too
    mapOptions.elevation = (mapOptions.elevation && savedOptions.elevationsEnabled);

    // Combine 3d and elevation into a single mapTypeId
    const canDo3d = get3dOption();
    mapOptions.mapTypeId = getMapTypeIdFromOptions(canDo3d, mapOptions.elevation);

    return mapOptions;
}

function _createBuildingLayer(map, options) {
    const view = options.view;
    view.buildingLayer = _configureBuildingLayer(map, options.level);
}

function _createDetailsLayer(map, options) {
    const view = options.view;
    const customDetailsOptions = options.details;
    const level = options.level;
    if (map.isIn3d()) {
        const detailsOptions = _.extend({
            map,
            ignoreStartDate: true, // keep details starting in the future for programmes
        }, getDetailsLayerOptions(level, customDetailsOptions));
        detailsOptions.details = _.uniq(detailsOptions.details);
        const DetailsLayer = MapApi.api.DetailsLayer;
        const {DefaultDetailsConfiguration} = DetailsLayer;
        if (savedOptions.christmasDesignEnabled && DefaultDetailsConfiguration) {
            const {subCategories} = DefaultDetailsConfiguration.tree;
            _.extend(detailsOptions, {
                tree: {
                    subCategories: _.defaults({
                        coniferous: subCategories.xmas,
                    }, subCategories),
                },
            });
        }
        if (!view.detailsLayer) {
            view.detailsLayer = new DetailsLayer(detailsOptions);
        } else {
            view.detailsLayer.setMap(map);
            view.detailsLayer.setOptions(detailsOptions);
        }
        view.detailsLayer.customDetailsOptions = options.details;
    } else {
        map.once('on3dchange', _.bind(_createDetailsLayer, this, map, options));
    }
}

function _createPoisInterface(map, options) {
    const view = options.view;
    if (!view.poiInterface) {
        options.poiOptions = _getPoisLayerOptions(options.level);
        options.poiTransportOptions = _getPoisTransportLayerOptions(options.level);
        view.poiInterface = new PoiInterface(map, options);
        view.poiInterface.on('togglePoi', function (data) {
            view.emit('togglePoi', data);
        });
    }
}

function onViewHide() {
    if (this.xhrRequestBuildingLayer) {
        this.xhrRequestBuildingLayer.abort();
        delete this.xhrRequestBuildingLayer;
    }
    if (this._onMapToggledHandler) {
        this.removeListener('toggledMap', this._onMapToggledHandler);
        delete this._onMapToggledHandler;
    }
    if (this.popupLayer) {
        PopupLayerHelper.destroy(this.popupLayer);
        delete this.popupLayer;
    }
    if (this.poiInterface) {
        this.poiInterface.reset();
        delete this.poiInterface;
    }

    if (this.watcher) {
        this.watcher.destroy();
        delete this.watcher;
    }

    delete this.buildingLayer;
    delete this.detailsLayer;
    this.removeListener('hide', this._onViewHideHandler);
    delete this._onViewHideHandler;
}

function getBuildingLayerOptions(level) {
    const {modelsHosts, buildingsUrl} = ServerConfig.config;
    const modelUris = _.map(modelsHosts, toMappingUrl);
    return _.extend({
        requestOptions: {
            uri: buildingsUrl,
            path: (tilePath) => `/buildings/${tilePath}.json`,
            params: {
                modelUris,
            },
        },
    }, getOptions(BUILDINGSLAYER_OPTIONS, level));
}

function toMappingUrl(modelsHost) {
    const queryString = savedOptions.showAllModels ? '' : '?ready=true';
    return `https://${modelsHost}/mapping.json${queryString}`;
}

function getDetailsLayerOptions(level, customDetailsOptions) {
    const detailsOptions = getOptions(DETAILSLAYER_OPTIONS, level);
    _.each(customDetailsOptions || {}, function (value, key) {
        if (value === false) {
            detailsOptions.details = _.without(detailsOptions.details, key);
        } else {
            detailsOptions.details.push(key);
        }
    });
    return detailsOptions;
}

function _configureBuildingLayer(map, level) {
    const options = _.extend({
        map,
    }, getBuildingLayerOptions(level));
    map.buildingLayer.setOptions(options);
    return map.buildingLayer;
}

function getMapStats(map) {
    const enabled = isWebglEnabled(map);
    return {
        mapQualityOption: enabled ? _getDefaultGraphicOptionsLevel() : 'low',
        webglEnabled: enabled,
    };
}

function _createNoWebGlWarning(map) {
    const $noGlTemplate = renderTemplate(webGLDisabledTemplate);
    $noGlTemplate.appendTo($(map.getDiv()));
    $noGlTemplate.on('click', function () {
        $noGlTemplate.toggleClass('showMessage');
        _.delay(function () {
            $noGlTemplate.find('.message').toggleClass('hidden');
        }, 200);
    });
    toggleNoWebGlWarning();

    map.on('zoom_changed', toggleNoWebGlWarning);

    function toggleNoWebGlWarning() {
        const show = map.getZoom() >= map.minZoomLayer2D;
        $noGlTemplate.toggle(show);
    }
}
