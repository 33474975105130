const _ = require('lodash');
const SearchPageBase = require('./SearchPageBase');
const Account = require('../authentication/Account');
const SelectOwnedAccountView = require('../views/SelectOwnedAccountView');
const PageManager = require('./PageManager');
const PagesFactories = require('./PagesFactories');
const BrowserDetect = require('browser-detect');

module.exports = class UserRealEstateAdsPageBase extends SearchPageBase {
    constructor(configuration) {
        super(_.defaults({}, configuration, {
            filterEnabled: true,
            statsEnabled: true,
            articleEditionEnabled: true,
            countTotalAds: true,
            filterTypeMultiple: true,
            zoomToResults: true,
            appendReturnUrlToDetailedSheetUrl: false,
            saveLastSearchInLocalStorage: false,
            saveSearchAllowed: false,
        }));
        this._accountSelectedHandler = onAccountSelected;
    }

    open(options) {
        super.open(options);
        if (this.selectOwnedAccountView) {
            this.selectOwnedAccountView.on('accountSelected', this._accountSelectedHandler);
        }
    }

    getViews(options) {
        const views = super.getViews(options);
        const showOwnedAccountSelection = this.configuration.showOwnedAccountSelection && !BrowserDetect.isMobile();
        if (showOwnedAccountSelection) {
            if (!this.selectOwnedAccountView) {
                this.selectOwnedAccountView = new SelectOwnedAccountView({
                    menu: 'searchPage',
                });
            }
            views.push(this.selectOwnedAccountView);
        }
        return views;
    }

    close(options) {
        if (this.selectOwnedAccountView) {
            this.selectOwnedAccountView.removeListener('accountSelected', this._accountSelectedHandler);
            delete this.selectOwnedAccountView;
        }
        super.close(options);
    }
};

function onAccountSelected(accountId, onlyOwnData) {
    if (accountId == Account.getAuthenticatedAccountId()) {
        PageManager.openPage(PagesFactories.myRealEstateAdsPageFactory, {onlyOwnData});
    } else {
        PageManager.openPage(PagesFactories.userRealEstateAdsPageFactory, {
            authorId: accountId,
            onlyOwnData,
        });
    }
}
