const _ = require('lodash');
const SearchUrl = require('../../../common/SearchUrl');
const template = require('./LocationsLinksCloud.jade');

// @vue/component
module.exports = {
    props: {
        locations: {
            type: Array,
            required: true,
        },
        searchData: {
            type: Object,
            required: true,
        },
        titleLocation: {
            type: Object,
            default: undefined,
        },
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        locationTitleUrl() {
            if (this.titleLocation) {
                const {locationName, postalOrDepartmentCode} = this.titleLocation;
                return this.makeSearchUrl(locationName, postalOrDepartmentCode);
            } else {
                return null;
            }
        },
        locationsToDisplay() {
            return _.map(this.locations, location => {
                const {locationName, postalOrDepartmentCode} = location;
                return {
                    locationName,
                    url: this.makeSearchUrl(locationName, postalOrDepartmentCode),
                };
            });
        },
    },
    methods: {
        makeSearchUrl(locationName, postalOrDepartmentCode) {
            const {
                propertyType,
                minRooms,
                maxRooms,
                filterType,
                newProperty,
                hasToBeBuilt,
                isLifeAnnuitySaleOnly,
                isFurnished,
                isInStudentResidence,
                isEligibleForPinelLaw,
                hasBalcony,
                hasPool,
                hasGarden,
                isDuplex,
                hasTerrace,
                minBedrooms,
                maxBedrooms,
                isOnLastFloor,
            } = this.searchData;

            const locationNameInLowerCase = locationName.toLowerCase();

            return SearchUrl.makeSearchUrl({
                search: {
                    locationNames: [[locationNameInLowerCase, postalOrDepartmentCode].join('-')],
                    propertyType,
                    filterType,
                    minRooms,
                    maxRooms,
                    newProperty,
                    hasToBeBuilt,
                    isLifeAnnuitySaleOnly,
                    isFurnished,
                    isInStudentResidence,
                    isEligibleForPinelLaw,
                    hasBalcony,
                    hasPool,
                    hasGarden,
                    isDuplex,
                    hasTerrace,
                    minBedrooms,
                    maxBedrooms,
                    isOnLastFloor,
                },
            });
        },
    },
    template: template(),
};
