const REAL_CHARGE_METHOD = 'real';
const BOTH_FEES_CHARGED = 'both';

module.exports = {
    CHARGES_METHODS: ['advance', 'flat-rate', REAL_CHARGE_METHOD],
    PREVIEW: 'preview',
    REAL_CHARGE_METHOD,
    BOTH_FEES_CHARGED,
    FEES_CHARGED_LIST: [BOTH_FEES_CHARGED, 'purchaser', 'seller'],
    PRICE_SIZES: ['small', 'big'],
};
