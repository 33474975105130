const _ = require('lodash');
const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');

let contractCache;
let extensionCache;

module.exports = {
    get,
};

function get(type, cb) {
    const cache = type === 'contractType' ? contractCache : extensionCache;
    if (cache) {
        _.defer(() => {
            cb(null, cache);
        });
    } else {
        fetchContractOrExtensionAccounts(type, (err, contractOrExtensionAccounts) => {
            if (err) {
                console.error('Could not fetch contract or extension accounts', err);
                cb(err);
            } else if (type === 'contractType') {
                contractCache = prepareAccounts(contractOrExtensionAccounts);
                cb(null, contractCache);
            } else {
                extensionCache = prepareAccounts(contractOrExtensionAccounts);
                cb(null, extensionCache);
            }
        });
    }
}

function prepareAccounts(contractAccounts) {
    return _.map(contractAccounts, account => {
        return {
            id: account.id,
            label: account.company.name,
            agencyEmailCampaign: account.agencyEmailCampaign,
            isExternalAdvertisementEnabled: account.isExternalAdvertisementEnabled,
        };
    });
}

function fetchContractOrExtensionAccounts(type, cb) {
    //requires dirViewer rights
    Account.authAjax({
        url: ServerConfig.config.accountUrl + '/accounts',
        type: 'GET',
        data: {
            type,
            disabled: false,
            size: 2000, //hopefully fetch all contractType accounts
        },
        callback: (err, results) => {
            if (err) {
                cb(err);
            } else {
                cb(null, results.accounts);
            }
        },
    });
}
