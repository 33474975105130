const template = require('./AppHistoryBackButton.jade');
const HistoryHelper = require('../../utils/HistoryHelper');
const UserAgentHelper = require('../../../common/nativeApp/UserAgentHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AppHistoryBackButton.',
            keys: [
                'backButtonText',
            ],
        }),
    ],
    computed: {
        isFromNativeApp() {
            return UserAgentHelper.isFromNativeApp();
        },
    },
    methods: {
        back() {
            HistoryHelper.back();
        },
    },
    template: template(),
};
