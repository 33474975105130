const _ = require('lodash');

const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./AgencyPagePublishBanner.jade');
const Account = require('../../authentication/Account');
const Views = require('../../views/Views');
const ApplicationConfig = require('../../app/ApplicationConfig');
const Options = require('../../Options');
const ServerConfig = require('../../ServerConfig');
const {props: {variant: {PRIMARY_VARIANT, default: DEFAULT_VARIANT}}} = require('../../vue/components/Button');
const {props: {variant: {SUCCESS_VARIANT, ERROR_VARIANT}}} = require('../../vue/components/Card.js');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AgencyPagePublishBanner.',
            keys: [
                'editButtonText',
                'accessRequiredModalTitle',
                'accessRequiredModalPitch',
                'disableButtonText',
                'enableButtonText',
                'errorMessage',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isPageEnabled: this.agency.agencyPageEnabled,
            showAccessRequiredModal: false,
        };
    },
    computed: {
        agencyId() {
            return this.agency.id;
        },
        isPageOwner() {
            return Account.getAuthenticatedAccountId() === this.agencyId;
        },
        userContext() {
            return this.isPageOwner ? 'your' : 'this';
        },
        pageContext() {
            return this.isPageEnabled ? 'enabled' : 'disabled';
        },
        displayBanner() {
            const hasAccess = this.isPageOwner || Account.hasRole('accountModifier');
            const isEmbeddedMode = ApplicationConfig.isOnPartnersDomain || Options.get('isInEmbeddedMode');
            return hasAccess && !isEmbeddedMode;
        },
        editPageLink() {
            const accountUpdateLink = this.isPageOwner
                ? '/modifier-mon-compte'
                : `/modification-du-compte-${this.agencyId}`;
            return accountUpdateLink + '#section_page-publique';
        },
        pageStateHtml() {
            const accountType = this.translate(`accountType.${this.agency.accountType}`);
            const publicPageUrl = `${ServerConfig.config.wwwUrl}/${accountType}-${this.agencyId}`;

            return this.getTranslation('pageState', {publicPageUrl});
        },
        toggleButtonText() {
            return this.isPageEnabled ? this.disableButtonText : this.enableButtonText;
        },
        cardVariant() {
            return this.isPageEnabled ? SUCCESS_VARIANT : ERROR_VARIANT;
        },
        toggleButtonVariant() {
            return this.isPageEnabled ? DEFAULT_VARIANT : PRIMARY_VARIANT;
        },
    },
    methods: {
        updatePagePublicState() {
            const oldValue = this.isPageEnabled;
            const data = {
                id: this.agencyId,
                agencyPageEnabled: !oldValue,
            };
            Account.update(data, (err) => {
                if (err) {
                    Views.volatileFeedback.showError(this.errorMessage);
                } else {
                    this.isPageEnabled = !oldValue;
                    Views.volatileFeedback.showSuccess(this.getTranslation('successMessage'));
                }
            });
        },
        togglePagePublicState() {
            if (this.agency.canEnablePublicPage) {
                this.updatePagePublicState();
            } else {
                this.showAccessRequiredModal = true;
            }
        },
        closeAccessRequiredModal() {
            this.showAccessRequiredModal = false;
        },
        getTranslation(key, options) {
            return this.translate(
                `AgencyPagePublishBanner.${key}`,
                _.extend({context: this.pageContext, userContext: this.userContext}, options)
            );
        },
    },
    template: template(),
};
