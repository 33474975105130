module.exports = {
    handlesField,
    getValue,
};

function handlesField($field) {
    return $field.prop('tagName') == 'INPUT' && $field.attr('type') == 'tel';
}

function getValue($input) {
    const intlTelInput = $input.data('plugin_intlTelInput');
    const internationalPhoneNumber = intlTelInput && intlTelInput.getNumber();
    return internationalPhoneNumber ? internationalPhoneNumber.replace('+33', '0') : $input.val();
}
