const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./CreditSimulationCompactLink.jade');
const {openFinancialPartnerTab} = require('../../financialPartners');
const ApplicationConfig = require('../../app/ApplicationConfig');
const CreditSimulationMixin = require('./CreditSimulationMixin');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'creditSimulation.',
            keys: [
                'homePageButtonMessage',
            ],
        }),
        CreditSimulationMixin,
    ],
    computed: {
        visible() {
            return this.financialPartnerCreditSimulationEnabled && !ApplicationConfig.applicationPro;
        },
    },
    methods: {
        openFinancialPartnerTab() {
            return openFinancialPartnerTab({context: 'homePage'});
        },
    },
    template: template(),
};
