const $ = require('jquery');
const {i18n: {translate}} = require('fack');
const uuid = require('uuid/v1');
const template = require('../templates/fields/checkboxField.jade');

module.exports = class ConfirmWidget {
    constructor($container, data) {
        this.$element = $(template({
            data,
            uuid,
        }));
        this.name = data.name;
        const $input = this.$input = this.$element.find(':input');
        $input.attr({
            name: data.name,
            required: data.required,
            'data-bv-choice': true,
            'data-bv-choice-min': data.required ? 1 : 0,
            'data-bv-choice-message': translate('formErrorMessages.fieldRequired'),
        });
        $container.append(this.$element);
    }

    clear() {
        this.$element.remove();
    }

    hide() {
        this.$element.hide();
    }

    serialize(value) {
        if (value === true) {
            return true;
        } else if (value === false) {
            return false;
        }
    }

    getFieldValue() {
        return this.serialize(this.$input.is(':checked'));
    }

    setFieldValue(value) {
        if (value) {
            this.$input.attr('checked', 'checked');
        } else {
            this.$input.attr('checked', null);
        }
    }

    serializeInto(result) {
        const value = this.getFieldValue();
        if (value != null) {
            result[this.name] = value;
        }
    }
};
