const _ = require('lodash');
const $ = require('jquery');

module.exports = FormLoadingState;

function FormLoadingState(options) {
    this.$form = options.$form;
    this.$button = options.$button;
    this.$disabledInputs = [];
}

const proto = FormLoadingState.prototype;

proto.show = function () {
    this.toggle(true);
};

proto.hide = function () {
    this.toggle(false);
};

proto.toggle = function (isLoading) {
    const $button = this.$button;
    $button.attr('disabled', isLoading);
    if (isLoading) {
        this._appendFormOverlay();
        this._disableInputsInForm();
    } else {
        this._enableDisabledInputs();
        this._removeFormOverlay();
    }
};

proto._disableInputsInForm = function () {
    const $form = this.$form;
    _.each($form.find('input'), input => {
        const $input = $(input);
        if (!$input.is(':disabled')) {
            this.$disabledInputs.push($input);
            $input.attr('disabled', true);
        }
    });
};

proto._enableDisabledInputs = function () {
    _.each(this.$disabledInputs, function ($input) {
        $input.attr('disabled', false);
    });
    this.$disabledInputs = [];
};

proto._appendFormOverlay = function () {
    const $form = this.$form;
    $form.css('position', 'relative');
    $('<div class=".authenticationForm-loadingOverlay"></div>').appendTo($form);
};

proto._removeFormOverlay = function () {
    const $form = this.$form;
    $form.css('position', null);
    $form.find('.authenticationForm-loadingOverlay').remove();
};
