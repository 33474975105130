const template = require('./LandingPageBreadcrumb.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'LandingPageBreadcrumb.',
            keys: ['firstItem'],
        }),
    ],
    props: {
        currentTitle: {
            type: String,
            required: true,
        },
    },
    computed: {
        breadcrumbItems() {
            return [
                {
                    'text': this.t('firstItem'),
                    'url': '/',
                },
                {'text': this.currentTitle},
            ];
        },
    },
    template: template(),
};
