const _ = require('lodash');
const Atlas = require('./Atlas');

function formatTags(config) {
    if (config.tags) {
        return _.map(config.tags, function (tag) {
            const formattedConfig = _.extend({tag: tag}, config);
            delete formattedConfig.tags;
            return formattedConfig;
        });
    } else {
        return config;
    }
}

function reformatTagConfigs(configs) {
    return _(configs)
        .map(config => _.extend({
            minZoom: 13,
            icon: Atlas.getIconFromUrl(config.url),
        }, config))
        .map(formatTags)
        .flatten()
        .value();
}

const alwaysTags = reformatTagConfigs([
    {
        tags: ['Mairie'],
        minZoom: 16,
        title: {zoomMin: 16, zoomMax: 21},
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Bâtiment historique'],
        minZoom: 17,
        title: true,
    },
    {
        tags: ['Police'],
        minZoom: 17,
        title: {zoomMin: 19, zoomMax: 21},
    },
]);

//Vie de quartier
const neighborhoodLifeTags = reformatTagConfigs([
    {
        tags: ['Mosquée'],
        url: 'images/poi/mosque.png',
        minZoom: 16,
        title: {zoomMin: 18, zoomMax: 21},
    },
    {
        tags: ['Synagogue'],
        url: 'images/poi/synagogue.png',
        minZoom: 16,
        title: {zoomMin: 18, zoomMax: 21},
    },
    {
        tags: ['Église'],
        url: 'images/poi/eglise.png',
        minZoom: 16,
        title: {zoomMin: 18, zoomMax: 21},
    },
    {
        tags: ['Piscine'],
        url: 'images/poi/piscine.png',
        minZoom: 17,
        title: {zoomMin: 18, zoomMax: 21},
    },
    {
        tags: ['Aire de jeux'],
        url: 'images/poi/jeux.png',
        minZoom: 17,
        title: {zoomMin: 18, zoomMax: 21},
    },
    {
        tags: ['Cinéma'],
        url: 'images/poi/cinema.png',
        minZoom: 16,
        title: {zoomMin: 16, zoomMax: 21},
    },
    {
        tags: ['Parc'],
        minZoom: 17,
        title: {zoomMin: 17, zoomMax: 21, enableCategory: false},
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Théâtre'],
        url: 'images/poi/theatre.png',
        minZoom: 16,
        title: {zoomMin: 18, zoomMax: 21},
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Bar', 'Pub'],
        url: 'images/poi/sortie.png',
        minZoom: 17,
        title: {zoomMin: 19, zoomMax: 21},
    },
    {
        tags: ['Café'],
        url: 'images/poi/cafe.png',
        minZoom: 18,
        title: {zoomMin: 19, zoomMax: 21},
    },
    {
        tags: ['Restaurant', 'Restauration rapide'],
        url: 'images/poi/resto.png',
        minZoom: 18,
        title: {zoomMin: 19, zoomMax: 21},
    },
    {
        tags: ['Centre artistique'],
        url: 'images/poi/art.png',
        minZoom: 17,
        title: {zoomMin: 17, zoomMax: 21},
    },
    {
        tags: ['Bibliothèque'],
        url: 'images/poi/livre.png',
        minZoom: 16,
        title: {zoomMin: 16, zoomMax: 21},
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Librairie'],
        url: 'images/poi/livre.png',
        minZoom: 18,
        title: {zoomMin: 20, zoomMax: 21},
    },
]);

const shoppingTags = reformatTagConfigs([
    {
        tags: ['Banque'],
        url: 'images/poi/commerce.png',
        minZoom: 20,
        title: {zoomMin: 20, zoomMax: 21},
    },
    {
        tags: ['Distributeur de billets'],
        url: 'images/poi/commerce.png',
        minZoom: 20,
        title: {zoomMin: 20, zoomMax: 21},
    },
    {
        tags: ['Boulangerie'],
        url: 'images/poi/commerce.png',
        minZoom: 18,
        title: {zoomMin: 20, zoomMax: 21},
    },
    {
        tags: ['Supermarché', 'Grand magasin'],
        url: 'images/poi/alimentation.png',
        minZoom: 17,
        title: {zoomMin: 19, zoomMax: 21},
    },
    {
        tags: ['Marché'],
        url: 'images/poi/alimentation.png',
        minZoom: 18,
        title: {zoomMin: 19, zoomMax: 21},
    },
    {
        tags: [
            'Primeur',
            'Produits bio',
            'Épicerie',
            'Boucherie',
            'Caviste',
            'Poissonnerie',
            'Journaux',
            'Coiffeur',
            'Spa',
            'Bricolage',
            'Jardinerie',
        ],
        url: 'images/poi/commerce.png',
        minZoom: 18,
        title: {zoomMin: 20, zoomMax: 21},
    },
    {
        tags: ['Pressing'],
        url: 'images/poi/pressing.png',
        minZoom: 18,
        title: {zoomMin: 20, zoomMax: 21},
    },
    {
        tags: ['Bureau de poste'],
        url: 'images/poi/poste.png',
        minZoom: 18,
        title: {zoomMin: 19, zoomMax: 21, enableCategory: true},
    },
    {
        tags: ['Centre commercial'],
        url: 'images/poi/commerce.png',
        minZoom: 17,
        title: {zoomMin: 19, zoomMax: 21},
    },
]);

const healthTags = reformatTagConfigs([
//Santé
    {
        tags: ['Hôpital', 'Clinique'],
        url: 'images/poi/hopital.png',
        minZoom: 16,
        title: {
            zoomMin: 17, zoomMax: 21,
        },
    },
    {
        tags: [
            'Pharmacie',
            'Médecin',
            'Dentiste',
        ],
        url: 'images/poi/pharmacie.png',
        minZoom: 17,
        title: {
            zoomMin: 19, zoomMax: 21,
        },
    },
    /*{
     tags: ["Vétérinaire"],
     url: "images/poi/pharmacie.png", //TODO specific icon
     minZoom: 17,
     title: {
     zoomMin: 19, zoomMax: 21,
     },
     },*/
]);

const educationTags = reformatTagConfigs([
//Education
    {
        tags: ['École primaire', 'École maternelle', 'École élémentaire'],
        url: 'images/poi/education.png',
        minZoom: 16,
        title: {
            zoomMin: 17, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Crèche'],
        url: 'images/poi/creche.png',
        minZoom: 16,
        title: {
            zoomMin: 17, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Collège'],
        url: 'images/poi/lycee.png',
        minZoom: 16,
        title: {
            zoomMin: 17, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Lycée'],
        url: 'images/poi/lycee.png',
        minZoom: 16,
        title: {
            zoomMin: 17, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Université'],
        url: 'images/poi/universite.png',
        minZoom: 17,
        title: {
            zoomMin: 18, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
]);

const transportTags = reformatTagConfigs([
//Transport
    {
        tags: ['Station essence'],
        url: 'images/poi/essence.png',
        minZoom: 18,
    },
    {
        tags: ['bus_stop', 'trolleybus_stop'],
        transportTag: 'bus',
        url: 'images/poi/bus.png',
        minZoom: 17,
        title: {
            zoomMin: 20, zoomMax: 21,
        },
    },
    {
        tags: ['rer_station'],
        transportTag: 'rer',
        url: 'images/poi/rer.png',
        minZoom: 13,
        title: {
            zoomMin: 20, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Gare SNCF', 'Gare ferroviaire'],
        url: 'images/poi/gare.png',
        minZoom: 13,
        title: {
            zoomMin: 16, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Parking public'],
        url: 'images/poi/parking.png',
        minZoom: 19,
        title: {
            zoomMin: 20, zoomMax: 21,
        },
    },
    {
        tags: ['subway_station'],
        transportTag: 'metro',
        url: 'images/poi/metro.png',
        minZoom: 14,
        title: {
            zoomMin: 16, zoomMax: 21, enableCategory: true,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['tram_stop'],
        transportTag: 'tramway',
        url: 'images/poi/tramway.png',
        minZoom: 17,
        title: {
            zoomMin: 20, zoomMax: 21,
        },
        suggestedForSearchByTravelTime: true,
    },
    {
        tags: ['Location de vélos'],
        url: 'images/poi/velo.png',
        minZoom: 17,
        getTags: function (poiData) {
            return poiData.network && [poiData.network];
        },
    },
    {
        tags: ['Location de voitures'],
        url: 'images/poi/locauto.png',
        minZoom: 17,
        getTags: function (poiData) {
            return poiData.network && [poiData.network];
        },
    },
    {
        tags: ['Station de recharge'],
        url: 'images/poi/charging_station.png',
        minZoom: 18,
    },

]);

const mapConfig = {
    always: {
        tags: alwaysTags,
        name: 'Toujours',
        hidden: true,
        selected: true,
    },
    neighborhoodLife: {
        tags: neighborhoodLifeTags,
        name: 'Sorties',
        color: '#f07eb8',
        selected: true,
    },
    shopping: {
        tags: shoppingTags,
        name: 'Commerces',
        color: '#fdb82a',
        selected: true,
    },
    education: {
        tags: educationTags,
        name: 'Écoles',
        color: '#a18565',
        selected: true,
    },
    transport: {
        tags: transportTags,
        name: 'Transports',
        color: '#0ea4cd',
        selected: true,
    },
    health: {
        tags: healthTags,
        name: 'Santé',
        color: '#43b36e',
        selected: true,
    },
};

const all = _.flatMap(mapConfig, 'tags');

const suggestedForSearchByTravelTime = _.filter(all, 'suggestedForSearchByTravelTime');

module.exports = {
    all,
    map: mapConfig,
    suggestedForSearchByTravelTime,
};
