const ApplicationPage = require('./ApplicationPage');
const SelectorContainerView = require('../views/SelectorContainerView');
const template = require('../templates/landingPage/landingPageContainerView.jade');
const Ajax = require('../utils/Ajax');
const SimpleContentWithAdsView = require('../views/SimpleContentWithAdsView');

module.exports = class NewPropertyLandingPage extends ApplicationPage {
    constructor({name, title} = {}) {
        super({
            name: name || 'newPropertyLandingPage',
            title: title || 'Acheter ou investir dans l’immobilier neuf',
        });
        this._containerView = new SelectorContainerView();
        this._containerView.template = template;
        this._containerView.setViews({
            '.content': new SimpleContentWithAdsView({pageName: this.name}),
        });
    }

    getMiddleViews() {
        return this._containerView;
    }

    getUrlPattern() {
        return '/acheter-ou-investir-dans-l-immobilier-neuf';
    }

    _loadData(options, callback) {
        Ajax.request({
            url: '/html/' + this.getName() + '.html',
            callback: (err, html) => {
                if (html) {
                    options.htmlContent = html;
                }
                callback(err, options);
            },
        });
    }
};
