const StringToBoolean = require('../../utils/StringToBoolean');
const hasBooleanAttribute = require('../utils/hasBooleanAttribute');

module.exports = {
    handlesField,
    getValue,
    setValue,
};

function handlesField($field) {
    return $field.prop('tagName') == 'INPUT' && $field.attr('type') == 'checkbox';
}

function getValue($input) {
    let checked = $input.is(':checked');
    if (hasBooleanAttribute($input, 'data-reversed')) {
        checked = !checked;
    }
    const ignoreValue = $input.attr('legacy-ignore-value') === 'true';
    const inputValue = $input.attr('value');
    if (inputValue && !ignoreValue) {
        if (checked) {
            const boolInputValue = StringToBoolean(inputValue);
            return boolInputValue === null ? inputValue : boolInputValue;
        } else {
            return undefined;
        }
    } else {
        return checked;
    }
}

function setValue($input, val) {
    $input.prop('checked', getCheckedValue($input, val));
}

function getCheckedValue($input, val) {
    if ($input.attr('value') == null) {
        return StringToBoolean(val);
    }
    // a custom value exists in the checkbox for the checked state
    return val !== undefined && $input.attr('value') === val.toString();
}
