const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const autosize = require('autosize');
const AddressInputWidget = require('../AddressInputWidget');
const FieldInputWidget = require('./FieldInputWidget');
const ContactInputWidget = require('./ContactInputWidget');
const DateWidget = require('./DateWidget');
const PhotoWidget = require('./PhotoWidget');
const EnumWidget = require('./EnumWidget');
const ConfirmWidget = require('./ConfirmWidget');
const FieldOverwriteConfiguration = require('./FieldOverwriteConfiguration');
const FieldsGroupWidget = require('./FieldsGroupWidget');
const UrlInputWidget = require('./UrlInputWidget');

module.exports = {
    newInputWidget,
};

const FIELD_TYPES = {
    photos: {
        render: renderPhoto,
        data: {
            template: require('../templates/fields/photoField.jade'),
        },
    },
    string: {render: renderString},
    boolean: {render: renderBoolean},
    enum: {render: renderEnum},
    number: {render: renderNumber},
    positiveInteger: {render: renderPositiveInteger},
    positiveIntegerIncludingZero: {render: renderPositiveIntegerIncludingZero},
    positiveNumberIncludingZero: {render: renderPositiveNumberIncludingZero},
    rateInPercent: {render: renderPositiveNumberIncludingZero},
    surface: {render: renderPositiveNumberIncludingZero},
    price: {render: renderPositiveNumber},
    priceIncludingZero: {render: renderPositiveNumberIncludingZero},
    positiveNumber: {render: renderPositiveNumber},
    integer: {render: renderInteger},
    textarea: {render: renderTextArea},
    email: {render: renderMail},
    date: {render: renderDate},
    phone: {render: renderPhone},
    url: {render: renderUrl},
    contactName: {
        render: renderContact,
        data: {
            baseType: 'string',
            contactFieldName: 'name',
        },
    },
    contactEmail: {
        render: renderContact,
        data: {
            baseType: 'email',
            contactFieldName: 'email',
        },
    },
    contactPhone: {
        render: renderContact,
        data: {
            baseType: 'phone',
            contactFieldName: 'phone',
        },
    },
    contactFields: {
        render: renderContactFields,
        data: {
            fields: [
                {
                    name: 'contactNameToDisplay',
                    baseType: 'contactName',
                },
                {
                    name: 'phoneToDisplay',
                    baseType: 'contactPhone',
                },
                {
                    name: 'emailToDisplay',
                    baseType: 'contactEmail',
                },
            ],
        },
    },
    address: {
        render: renderAddress,
    },
    ignore: {
        render: renderNothing,
    },
    confirmCheckbox: {
        render: renderConfirmCheckbox,
    },
};

/**
 *
 * @param {node} $container
 * @param {object} info
 * @param {string} info.name
 * @param {string} info.placeholderKey
 * @param {string} info.translationContext
 * @param {boolean} info.required
 * @param {boolean} info.disabled
 * @param {string} info.inputId
 * @param {boolean} info.noLabel
 * @param {boolean} info.allowManualPlacement
 * @returns {*}
 */
function newInputWidget($container, info) {
    const name = info.name;
    let typeName = info.type;
    const overwriteConfiguration = FieldOverwriteConfiguration[name] || null;
    if (overwriteConfiguration) {
        typeName = overwriteConfiguration.type || typeName;
    }
    let type = FIELD_TYPES[typeName];
    if (_.isNil(type)) {
        console.error('unknown ad field type, using default type instead of ', typeName);
        type = FIELD_TYPES.string;
    }

    const data = _.defaults({name: name}, info);
    if (overwriteConfiguration && overwriteConfiguration.data) {
        _.extend(data, overwriteConfiguration.data);
    }
    if (type.data) {
        _.extend(data, type.data);
    }
    if (!data.placeholder) {
        data.placeholder = getPlaceholder(info);
    }
    if (!data.placeholder) {
        delete data.placeholder;
    }

    if (_.isObject(data.min) && info && info.translationContext && info.translationContext.adType) {
        data.min = data.min[info.translationContext.adType];
    }

    return type.render($container, data);
}

/**
 *
 * @param {object} info
 * @param {string} info.name
 * @param {string} info.placeholderKey
 * @param {string} info.translationContext
 * @returns {string}
 */
function getPlaceholder(info) {
    return translate(_.compact([info.name + '_placeholderInForm', info.placeholderKey]), _.extend({},
        info.translationContext,
        {
            defaultValue: '',
        }
    ));
}

function renderNothing() {
    //rendered by another field
    return null;
}

function renderAddress($container, {required, disabled, allowManualPlacement, useCustomBlurType} = {}) {
    return new AddressInputWidget({
        $container: $container,
        required,
        disabled,
        allowManualPlacement: allowManualPlacement != null ? allowManualPlacement : !disabled,
        useCustomBlurType,
    });
}

function renderConfirmCheckbox($container, data) {
    setTitleInDataIfNeeded(data);
    return new ConfirmWidget($container, data);
}

function setTitleInDataIfNeeded(data) {
    _.defaults(data, {
        title: getFieldTitle(data.name, data.required, data.translationContext),
    });
}

function renderPhoto($container, data) {
    setTitleInDataIfNeeded(data);
    return new PhotoWidget($container, data);
}

function renderDate($container, data) {
    setTitleInDataIfNeeded(data);
    return new DateWidget($container, data);
}

function renderField($container, data) {
    setTitleInDataIfNeeded(data);
    return new FieldInputWidget($container, data);
}

function renderString($container, data) {
    _.defaults(data, {
        type: 'text',
        placeholderKey: 'placeholder.text',
    });
    return renderField($container, data);
}

function renderUrl($container, data) {
    setTitleInDataIfNeeded(data);
    return new UrlInputWidget($container, data);
}

function renderMail($container, data) {
    _.defaults(data, {
        type: 'email',
        placeholderKey: 'placeholder.email',
    });
    return renderField($container, data);
}

function renderPhone($container, data) {
    _.defaults(data, {
        type: 'tel',
        placeholderKey: 'placeholder.phone',
        'data-bv-phone': true,
        'data-bv-phone-country': 'FR',
        'data-bv-phone-message': translate('formErrorMessages.tel'),
    });
    return renderField($container, data);
}

function renderBoolean($container, data) {
    _.defaults(data, {
        'data-serialization': 'boolean',
        template: require('../templates/fields/boolField.jade'),
    });
    return renderField($container, data);
}

function renderNumber($container, data) {
    _.defaults(data, {
        type: 'number',
        step: 'any', //increase/decrease by 1 with no validation
        min: 0,
        placeholderKey: 'placeholder.value',
        'data-bv-numeric-message': translate('formErrorMessages.numeric'),
    });
    return renderField($container, data);
}

function renderInteger($container, data) {
    _.defaults(data, {
        type: 'number',
        'data-bv-integer': true,
        'data-serialization': 'integer',
        step: 1,
        placeholderKey: 'placeholder.value',
        'data-bv-integer-message': translate('formErrorMessages.integer'),
    });
    return renderField($container, data);
}

function renderPositiveInteger($container, data) {
    _.defaults(data, {
        min: 1,
    });
    return renderInteger($container, data);
}

function renderPositiveIntegerIncludingZero($container, data) {
    _.defaults(data, {
        min: 0,
    });
    return renderInteger($container, data);
}

function renderPositiveNumberIncludingZero($container, data) {
    _.defaults(data, {
        min: 0,
    });
    return renderNumber($container, data);
}

function renderPositiveNumber($container, data) {
    _.defaults(data, {
        min: 0.1,
    });
    return renderNumber($container, data);
}

function renderEnum($container, data) {
    _.defaults(data, {
        type: 'enum',
        title: getFieldTitle(data.name, data.required, data.translationContext),
    });
    return new EnumWidget($container, data);
}

function renderTextArea($container, data) {
    _.defaults(data, {
        template: require('../templates/fields/textAreaField.jade'),
        placeholderKey: 'placeholder.text',
    });
    const field = renderField($container, data);
    autosize($container.find('textarea'));
    return field;
}

function renderContact($container, data) {
    data = _.extend({}, FIELD_TYPES[data.baseType].data, data, {
        title: getFieldTitle(data.name, data.required, data.translationContext),
    });
    return new ContactInputWidget($container, data);
}

function renderContactFields($container, data) {
    const field = new FieldsGroupWidget();
    _.each(data.fields, function (fieldData) {
        fieldData = _.extend({}, FIELD_TYPES[fieldData.baseType].data, fieldData, {
            title: getFieldTitle(fieldData.name, data.required, data.translationContext),
            required: data.required,
            ignoreAccountValues: data.ignoreAccountValues,
        });
        field.addField(new ContactInputWidget($container, fieldData));
    });
    return field;
}

function getFieldTitle(fieldName, required, translationContext) {
    const context = {
        context: translationContext.adType,
        fai: translationContext.fai,
        publicOrPro: translationContext.publicOrPro,
    };
    let title = translate(fieldName, context);
    if (required) {
        title += '*';
    }
    return title;
}
