const _ = require('lodash');

const template = require('./SearchSeoContent.jade');

// @vue/component
module.exports = {
    props: {
        seoContent: {
            type: String,
            required: true,
        },
    },
    computed: {
        hasSeoContent() {
            return !_.isEmpty(this.seoContent);
        },
    },
    template: template(),
};
