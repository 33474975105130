const _ = require('lodash');

const ZoneSuggestionManager = require('../../ZoneSuggestionManager');

module.exports = {
    handlesField,
    getValue,
    setValue,
};

function handlesField($field) {
    return $field.prop('tagName') == 'INPUT' && $field.attr('type') == 'tagsInput';
}

function getValue($input) {
    return $input.tagsinput('items');
}

function setValue($input, values) {
    const currentValues = getValue($input);
    if (!_.isEqual(values, currentValues)) { // avoid triggering unnecessary 'change' events
        if (currentValues.length > 0) { // also to avoid unnecessary 'change' event
            $input.tagsinput('removeAll');
        }
        const items = convertValueLocationItems(values);
        _.forEach(items, item => {
            $input.tagsinput('add', item);
        });
    }
}

function convertValueLocationItems(val) {
    val = _.castArray(val);
    return ZoneSuggestionManager.convertToLocationItems(val);
}
