const _ = require('lodash');
const UrlHelper = require('../../common/UrlHelper');
const $ = require('jquery');

const SelectPickerField = require('./SelectPickerField');
const SearchFiltersConfiguration = require('../search/SearchFiltersConfiguration');

module.exports = class GeocodingSelectPickerField extends SelectPickerField {
    constructor(...args) {
        super(...args);
        this.conf = SearchFiltersConfiguration.getGeocodingConfiguration();
        this._initGeocoder();
    }

    keyToGeocodingFilter(key) {
        return _.find(this.conf, function (filter) {
            return UrlHelper.slugify(filter.label) == UrlHelper.slugify(key);
        });
    }

    _initGeocoder() {
        _.each(this.conf, entry => {
            const $option = $('<option>').attr('value', JSON.stringify(entry.value)).text(entry.label);
            if (entry.example) {
                $option.attr('data-subtext', entry.example);
            }
            this.$element.append($option);
        });
        this.refresh();
    }

    convertToSearch(filters) {
        if (filters[this.fieldName]) {
            filters[this.fieldName] = _.map(filters[this.fieldName], JSON.parse);
        }
    }

    convertFromSearch(search) {
        if (search[this.fieldName]) {
            search[this.fieldName] = _.map(search[this.fieldName], JSON.stringify);
        }
    }
};
