const _ = require('lodash');

module.exports = {
    formatFirstName,
    formatLastName,
};

function formatFirstName(firstNames) {
    const firstNamesFormatted = upperFirstBySeparator(_.toLower(firstNames), '-');
    return upperFirstBySeparator(firstNamesFormatted, ' ');
}

function upperFirstBySeparator(firstNames, separator) {
    return _(firstNames)
        .split(separator)
        .map(_.upperFirst)
        .join(separator);
}

function formatLastName(lastName) {
    return _.toUpper(lastName);
}
