const _ = require('lodash');

const View = require('./View');
const EventPack = require('../utils/EventPack');
const template = require('../templates/views/backButtonNavView.jade');

class BackButtonNavView extends View {
    constructor(options = {}) {
        super(options);
        this.configuration = _.defaults(options, {
            animations: {
                openAnimation: 'anim-open',
                closeAnimation: 'anim-close',
            },
        });
        this.template = template;
        this._eventPack = new EventPack();
    }

    show(options) {
        super.show(options);
        if (options.searchController) {
            this.bindEvents();
        }
        this.playOpenAnimation();
    }

    bindEvents() {
        this._eventPack.onLinkClick(this.$element, '.goBack', event => {
            event.preventDefault(); //avoid following link
            this.emit('goBack');
        });
    }

    _hide(options, cb) {
        this._eventPack.removeAllListeners();
        super.hide(options);
        cb();
    }

    hide(options, cb) {
        this.playCloseAnimation(_.bind(this._hide, this, options, cb));
    }

    _shouldAnimate() {
        return !this.isMobile;
    }

    shouldPlayOpenAnimation() {
        return this._shouldAnimate();
    }

    shouldPlayCloseAnimation() {
        return this._shouldAnimate();
    }
}

module.exports = BackButtonNavView;
