const template = require('./BlogCategoryHeader.jade');

// @vue/component
module.exports = {
    props: {
        category: {
            type: Object,
            default: undefined,
        },
        displayContent: Boolean,
    },
    template: template(),
};
