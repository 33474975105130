const SetFeaturedButton = require('./SetFeaturedButton');
const template = require('./SetFeaturedWrapper.jade');
const ActionAndButton = require('./mixins/ActionAndButton');

// @vue/component
module.exports = {
    components: {
        SetFeaturedButton,
    },
    mixins: [
        ActionAndButton(),
    ],
    data() {
        return {
            disabled: false,
            featuredValueToSet: null,
        };
    },
    methods: {
        toggleValue() {
            this.featuredValueToSet = !this.isFeatured;
        },
        setDisabled(value) {
            this.disabled = value;
        },
        featurePropertyChanged(feature, payload) {
            this.$emit('featured-property-changed', feature, payload);
        },
    },
    template: template(),
};
