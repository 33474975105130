const _ = require('lodash');
const blogData = require('../../common/data/blogData');
const ArticlesHubMenuView = require('./hub/ArticlesHubMenuView');
const Account = require('../authentication/Account');

module.exports = {
    getBlog,
    getDefaultBlog,
    setBlogFactory,
    getBlogHubPageFactory,
    createHubMenuViews,
    getAllTags,
    getBlogFromTags,
    getBlogHomeTag,
    getTag,
    getProBlogTagWithFilters,
    isBlogVisibleByUser,
    getAllSubTags,
};

const articlesHubMenuViews = [];
const blogFactories = {};

function getBlog(slug, isPro) {
    return _.find(blogData.blogs, {slug: slug, pro: isPro});
}

function getDefaultBlog() {
    return _.first(blogData.blogs);
}

function setBlogFactory(slug, articlesHubPageFactory) {
    blogFactories[slug] = articlesHubPageFactory;
}

function getBlogHubPageFactory(blog) {
    const selectedBlog = blog || getDefaultBlog();
    return blogFactories[selectedBlog.slug];
}

function createHubMenuViews() {
    _.each(blogData.blogs, function (blog) {
        articlesHubMenuViews[blog.slug] = new ArticlesHubMenuView({
            blog,
        });
    });
    return articlesHubMenuViews;
}

function getAllTags(filter) {
    let allTags = [];
    _.each(blogData.blogs, function (blog) {
        allTags = allTags.concat(getFilteredTagsIfNeeded(blog.tags));
    });
    return allTags;

    function getFilteredTagsIfNeeded(tags) {
        return filter ? _.filter(tags, filter) : tags;
    }
}

function getAllSubTags() {
    return _(blogData.blogs)
        .flatMap(blog => _.flatMap(blog.tags, tag => tag.subTags))
        .compact()
        .value();
}

function getBlogFromTags(tags, isPro, {allowRequiresBloggerRoleBlogs} = {}) {
    const blogWithMatchingTag = _.find(blogData.blogs, function (blog) {
        return isBlogVisibleByUser(blog, isPro, allowRequiresBloggerRoleBlogs) && _.includes(tags, blog.slug);
    });
    if (blogWithMatchingTag) {
        return blogWithMatchingTag;
    } else {
        return findFirstBlog(isPro);
    }
}

function findFirstBlog(isPro) {
    return _.find(blogData.blogs, function (blog) {
        return isBlogVisibleByUser(blog, isPro);
    });
}

function isBlogVisibleByUser(blog, isPro, allowRequiresBloggerRoleBlogs = false) {
    return (blog.pro == isPro && (allowRequiresBloggerRoleBlogs || !blog.requiresBloggerRole)) || Account.hasRole('blogger');
}

function getBlogHomeTag(blog) {
    return _.find(blog.tags, {isHome: true});
}

function getTag(tagSlug) {
    return _.first(getAllTags((tag) => tag.slug == tagSlug));
}

function getProBlogTagWithFilters(mainBlogSlug, filters) {
    const isPro = true;
    const blog = getBlog(mainBlogSlug, isPro);
    const tag = _.find(_.get(blog, 'tags'), filters);
    return _.get(tag, 'slug');
}
