const {i18n: {translate}} = require('fack');
const template = require('./RealEstateSubmissionMainDescriptor.jade');
const CommonStepMixin = require('../../components/mixins/CommonStepMixin');
const {SUBMISSION_PROPERTY_TYPE_OPTION, SUBMISSION_STORE_MODULE_NAME} = require('../../Constants');

// @vue/component
module.exports = {
    mixins: [
        CommonStepMixin(SUBMISSION_STORE_MODULE_NAME),
    ],
    computed: {
        title() {
            return this.realEstatePropertyDescriptorTranslate('sections.describe');
        },
        translatedPropertyTypeOptions() {
            return this.translateOptions(SUBMISSION_PROPERTY_TYPE_OPTION, 'type');
        },
        propertyTypeIsRequiredText() {
            return translate('propertyTypeIsRequired');
        },
        shouldAskForRoomsQuantity() {
            const {propertyType} = this;
            return propertyType && propertyType !== 'terrain' && propertyType !== 'parking';
        },
    },
    watch: {
        shouldAskForRoomsQuantity(shouldAskForRoomsQuantity) {
            if (!shouldAskForRoomsQuantity) {
                this.commitToStore('realEstateProperty/roomsQuantity', null);
            }
        },
    },
    template: template(),
};
