const template = require('./AgencyPage.jade');
const AgencyPageHeader = require('./AgencyPageHeader');
const AgencyPageSection = require('./AgencyPageSection');
const AgencyPageCustomerRatings = require('./AgencyPageCustomerRatings');
const AgencyAdsLinks = require('./AgencyAdsLinks');
const AgencyPageMap = require('./AgencyPageMap');
const ContactAgencyButton = require('../../agency/components/ContactAgencyButton');
const JobOffers = require('./JobOffers/JobOffers');
const AgencyPagePublishBanner = require('./AgencyPagePublishBanner');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {BREAKPOINTS_LIMITS_SM} = require('../../vue/utils/Breakpoints');
const {isKartoEnabled} = require('../../utils/Karto');

// @vue/component
module.exports = {
    components: {
        AgencyPageHeader,
        AgencyPageSection,
        AgencyPageCustomerRatings,
        AgencyAdsLinks,
        AgencyPageMap,
        ContactAgencyButton,
        JobOffers,
        AgencyPagePublishBanner,
    },
    mixins: [
        ContainerQueries({small: {maxWidth: BREAKPOINTS_LIMITS_SM}}),
        i18nMixin({
            prefix: 'AgencyPage.',
            keys: [
                'title',
                'seeMoreButtonText',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
    },
    computed: {
        showFloatingContactAgencyButton() {
            return this.breakpoints.small;
        },
        agencyId() {
            return this.agency.id;
        },
        agencyDescription() {
            return this.agency.description;
        },
        useKarto() {
            const pageName = 'agencyPage';
            return isKartoEnabled(pageName);
        },
    },
    template: template(),
};
