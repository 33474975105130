const template = require('./configMultiselectRadioButton.jade');

// @vue/component
module.exports = {
    mixins: [
        require('./baseFieldMixin'),
    ],
    props: {
        compacted: Boolean,
    },
    computed: {
        fillWidth() {
            return this.options.length > 2;
        },
    },
    methods: {
        onInput($event) {
            this.$emit('input', $event);
        },
    },
    template: template(),
};
