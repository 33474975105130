const _ = require('lodash');

const template = require('./AgencyRating.jade');
const {getRating} = require('./Ratings');
const i18nMixin = require('../../vue/components/mixins/i18n');
const loadingPlaceholderMixin = require('../../vue/components/mixins/loadingPlaceholderMixin');
const DateFormatter = require('../../../common/formatters/DateFormatter');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'agency.Rating.',
            keys: [
                'longSourceTextPrefix',
                'explanationText',
            ],
        }),
        loadingPlaceholderMixin,
    ],
    props: {
        agencyId: {
            type: String,
            required: true,
        },
        multiline: Boolean,
        withLongSourceText: Boolean,
        withExplanationText: Boolean,
        inheritColor: Boolean,
        inColumn: Boolean,
        descriptionStyle: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            count: undefined,
            date: undefined,
            displayComponent: false,
            isLoading: false,
            score: undefined,
            source: undefined,
        };
    },
    computed: {
        descriptionModifiers() {
            return _.pick(this, 'inheritColor');
        },
        formattedDate() {
            const {date} = this;
            return date && DateFormatter.humanizeDay(date);
        },
        description() {
            return this.t('description', {
                count: this.count,
                source: this.source,
                date: this.formattedDate,
            });
        },
        starsModifiers() {
            return _.pick(this, ['multiline', 'isLoading', 'inColumn']);
        },
        modifiers() {
            return _.pick(this, 'inColumn');
        },
        longSourceTextDescription() {
            return this.t('longSourceTextDescription', {
                source: this.source,
            });
        },
    },
    watch: {
        displayComponent(value) {
            if (value) {
                this.$emit('display');
            }
        },
    },
    created() {
        const loadingTimer = setTimeout(() => {
            this.displayComponent = true;
            this.isLoading = true;
        }, 1000);
        getRating(this.agencyId, (err, data, jqXHR) => {
            const rating = _.get(data, 'rating');
            if (err || !rating || !rating.count) {
                this.displayComponent = false;
            } else {
                _.extend(this, _.pick(rating, [
                    'count',
                    'score',
                    'source',
                ]));
                this.date = jqXHR.getResponseHeader('date');
                this.displayComponent = true;
            }
            this.isLoading = false;
            clearTimeout(loadingTimer);
        });
    },
    template: template(),
};
