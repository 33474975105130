const _ = require('lodash');
const ProgrammeHelper = require('./ProgrammeHelper');
const Roles = require('./Roles');

const {AD_TYPES, AD_TYPES_FRENCH} = require('../common/constants/AdType');
const {PROPERTY_TYPES} = require('../common/constants/PropertyType');

const adTypesToFrench = {
    leaseback: AD_TYPES_FRENCH.LEASEBACK,
    rent: AD_TYPES_FRENCH.RENT,
    holidays: AD_TYPES_FRENCH.HOLIDAYS,
    investment: AD_TYPES_FRENCH.INVESTMENT,
    buy: AD_TYPES_FRENCH.BUY,
    prestige: AD_TYPES_FRENCH.PRESTIGE,
    businessTakeOver: AD_TYPES_FRENCH.BUSINESS_TAKE_OVER,
    lifeAnnuitySale: AD_TYPES_FRENCH.LIFE_ANNUITY_SALE,
};

const adTypeToTransactionType = {
    buy: AD_TYPES.BUY,
    investment: AD_TYPES.BUY,
    businessTakeOver: AD_TYPES.BUY,
    prestige: AD_TYPES.BUY,
    leaseback: AD_TYPES.BUY,
    rent: AD_TYPES.RENT,
    lifeAnnuitySale: AD_TYPES.BUY,
    holidays: AD_TYPES.HOLIDAYS,
};

const enabledAdTypes = [
    AD_TYPES.BUY,
    AD_TYPES.INVESTMENT,
    AD_TYPES.BUSINESS_TAKE_OVER,
    AD_TYPES.PRESTIGE,
    AD_TYPES.LEASEBACK,
    AD_TYPES.RENT,
    AD_TYPES.LIFE_ANNUITY_SALE,
    // AD_TYPES.HOLIDAYS,
];

module.exports = {
    getTransactionTypeFromAd,
    getTransactionTypeFromAdType,
    translateFrenchToCode,
    translateCodeToFrench,
    getEnabledFrenchAdTypesFromTransactionTypes,
    getAdTypes,
    enabledAdTypes,
    isRentAd,
    isBuyTransactionAd,
};

function getTransactionTypeFromAd(ad) {
    let transactionType = getTransactionTypeFromAdType(ad.adType);
    if (!transactionType && ProgrammeHelper.isProgrammeOrResidence(ad)) {
        transactionType = ad.propertyType == PROPERTY_TYPES.PROGRAMME ? AD_TYPES.BUY : AD_TYPES.RENT;
    }
    return transactionType;
}

function getTransactionTypeFromAdType(adType) {
    return adTypeToTransactionType[adType] || adTypeToTransactionType[translateFrenchToCode(adType)];
}

function translateCodeToFrench(adType) {
    return adTypesToFrench[adType] || adType;
}

function translateFrenchToCode(adTypeFrench) {
    return _.invert(adTypesToFrench)[adTypeFrench] || adTypeFrench;
}

function getEnabledFrenchAdTypesFromTransactionTypes(transactionTypes, viewerAccount) {
    return _(transactionTypes)
        .castArray()
        .map(transactionType => getEnabledAdTypesFromTransactionType(transactionType, viewerAccount))
        .flatten()
        .map(translateCodeToFrench)
        .value();
}

function getEnabledAdTypesFromTransactionType(transactionType, viewerAccount) {
    const adTypes = [];
    _.each(adTypeToTransactionType, (_transactionType, adType) => {
        if (_transactionType === transactionType && (Roles.admin.test(viewerAccount) || _.includes(enabledAdTypes, adType))) {
            adTypes.push(adType);
        }
    });
    return adTypes;
}

function getAdTypes() {
    return _.keys(adTypesToFrench);
}

function isRentAd(ad) {
    return getTransactionTypeFromAd(ad) == AD_TYPES.RENT;
}

function isBuyTransactionAd(realEstateAd) {
    return getTransactionTypeFromAd(realEstateAd) === AD_TYPES.BUY;
}
