const _ = require('lodash');
const template = require('./AboutAgency.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AboutAgency.',
        }),
    ],
    props: {
        agency: {
            type: Object,
            default: null,
        },
    },
    computed: {
        title() {
            return this.t('title', {
                context: _.get(this.agency, 'accountType', 'loading'),
            });
        },
    },
    template: template(),
};
