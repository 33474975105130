const assert = require('assert');
const $ = require('jquery');
const _ = require('lodash');
const {isMobile, isTablet} = require('browser-detect');
const {i18n: {translate}, resourceUrl} = require('fack');
const urlUtil = require('url');
const PhoneNumberFormatter = require('@bienici/phone-number-formatter');

const moment = require('../common/momentFr');
const template = require('./templates/detailedSheet/detailedSheet.jade');
const neighborhoodTemplate = require('./templates/neighborhood.jade');
const contactInfoBlockLoadingViewTemplate = require('./templates/contactInfoBlockLoadingView.jade');
const phonePrintTemplate = require('./templates/detailedSheet/phonePrint.jade');
const similarAdsTemplate = require('./templates/detailedSheet/similarAds.jade');
const agencyFeeUrlCellTemplate = require('./templates/detailedSheet/urlCell.jade');
const EventPack = require('./utils/EventPack');
const ContactView = require('./ContactView');
const TextFormatter = require('../common/TextFormatter');
const PriceFormatter = require('../common/PriceFormatter');
const Account = require('./authentication/Account');
const SlideshowWidget = require('./slideshow/SlideshowWidget');
const IframeWidget = require('./IframeWidget');
const Urls = require('./Urls');
const KelQuartierHelper = require('../common/KelQuartierHelper');
const NativeInterfaceProxy = require('./utils/NativeInterfaceProxy');
const UserAgentHelper = require('../common/nativeApp/UserAgentHelper');
const GoogleTagManager = require('./stats/GoogleTagManager');
const KelQuartierDescription = require('./KelQuartierDescription');
const PlaceDetailsHelper = require('./PlaceDetailsHelper');
const ApplicationConfig = require('./app/ApplicationConfig');
const Options = require('./Options');
const phoneContactCounter = require('./analytics/phoneContactCounter');
const ProgrammeHelper = require('../common/ProgrammeHelper');
const VirtualTourHelper = require('./virtualTours/VirtualTourHelper');
const DetailedSheetDetailsExtractor = require('../common/DetailedSheetDetailsExtractor');
const SideMapViewSingleton = require('./views/SideMapViewSingleton');
const ShareHelper = require('./utils/ShareHelper');
const RelatedAdsView = require('./views/RelatedAdsView');
const AdContentHandler = require('./views/utils/AdContentHandler');
const StatsView = require('./StatsView');
const OpenWindow = require('./utils/OpenWindow');
const SaveSearchBannerView = require('./views/SaveSearchBannerView');
const RealEstateAdTitleGenerator = require('../common/RealEstateAdTitleGenerator');
const AddressFormatter = require('../common/formatters/AddressFormatter');
const TextFiltersFormatter = require('../common/TextFiltersFormatter');
const MailSharingHelper = require('./MailSharingHelper');
const agencyLogoSize = require('./ads/agencyLogoSize');
const {getImageUrlFromAlias} = require('./ImageHelper');
const {getFileUrlFromAlias} = require('./FileHelper');
const Errors = require('./utils/Errors');
const AppInstallBannerContainer = require('./android/AppInstallBannerContainer');
const CompositeVueView = require('./vue/CompositeVueView');
const {services: partnerServices} = require('./data/partnerServices');
const {updateAdFeaturedProperty} = require('./setFeatured');
const store = require('./store');
const i18nMixin = require('./vue/components/mixins/i18n');
const {hasNoLabelForDpeAndGes} = require('./energyPerformanceDiagnostic/components/helper/energyDiagnosticsHelper');
const {updateMarketStatusProperties} = require('./ad/components/toggleMarket/helpers');
const RealEstateAdNotificationBoostButton = require('./notificationBoost/components/RealEstateAdNotificationBoostButton');
const AdContactInfo = require('./ad/components/detailedSheet/AdContactInfo');
const AdShareButton = require('./ad/components/detailedSheet/AdShareButton');
const getRealEstateSharedData = require('./ad/getRealEstateSharedData');
const ActionsBanner = require('./ad/components/detailedSheet/ActionsBanner');
const ContactsResults = require('./contacts/components/ContactsResults');
const MainButton = require('./ad/components/detailedSheet/MainButton');
const RealEstateAdStatsChart = require('./stats/components/RealEstateAdStatsChart');
const {isLot} = require('../common/ProgrammeHelper');

const savedOptions = Options.read();

const DETAILED_NEIGHBORHOOD_DATA = {
    population: [
        'Age moyen',
        "Nombre d'habitants",
        'Densité de population',
        'Taille des ménages',
        'Enfants et adolescents',
        'Adultes',
        'Personnes âgées',
        'Habitants de moins de 25 ans',
        'Habitants de 25 à 55 ans',
        'Habitants de plus de 55 ans',
    ],
    income: [
        'Revenu mensuel',
        'Chômage',
    ],
    realEstate: [
        'Propriétaires (vs. locataires)',
        'Type de maison',
        'Résidences secondaires',
        'Logements vacants',
        'Logement social HLM',
    ],
    taxes: [
        'Taxe habitation',
        'Taxe foncière',
        'Taxe enlèvt ordures ménagères',
    ],
    school: [
        'Résultats des lycées',
        ['Sélectivité des lycées', 'points'],
        ['Valeur ajoutée des lycées', 'points'],
    ],
};
const NAVIGATOR_SHARE_SUPPORTED = 'share' in navigator;

/**
 *  DetailedSheet view
 * @param {Object} options Options
 * @param {jQuery.node} options.$container Container jquery object
 * @param {?Object} options.realEstateAd Real estate ad (may be null if initFromDom is true)
 * @param {boolean} options.initFromDom Init from "#detailedSheet" DOM object in $container (#detailedSheet has become .section-detailedSheet)
 * @param {string} [options.scrollTo] Scroll to some element on init
 * @param {object} options.neighborhoodInfo district or city data
 * @param {object} options.userInfos
 * @param {boolean} options.showDescription
 * @param {boolean} options.showMap
 * @constructor
 */

const DATA_TRANSLATION = _([
    'htmlLinkText',
    'pdfLinkText',
])
    .map(key => [`publicationCertificate.${key}`, key])
    .fromPairs()
    .value();

module.exports = class DetailedSheetView extends CompositeVueView {
    constructor(options) {
        super({
            template,
        });
        this.configuration = options = _.defaults(options, {
            $container: null, //required
            realEstateAd: null,
            initFromDom: false,
            scrollTo: null,
            isMobile: false,
            animations: {
                openAnimation: 'anim-open',
                closeAnimation: 'anim-close',
            },
        });
        this.isWaitingToOpenContact = false;
        this.wantContactToBeOpen = false;
        this.isReadyToOpenContact = false;
        this.isMobile = options.isMobile;
        this.$mapOptions = null;
        this.model = {};
        this.neighborhoodInfo = options.neighborhoodInfo || {};
        this.marker = null;
        this.closePageUrl = options.closePageUrl;
        this.contactView = new ContactView();
        this.relatedAdsView = new RelatedAdsView();
        this.adContentHandler = new AdContentHandler();
        this.statsView = null;
        if (!ApplicationConfig.applicationPro
            && !Account.isShowRoom()) {
            this.saveSearchBannerView = new SaveSearchBannerView();
        }
        this.initPrint();
        this._iframeEventPack = new EventPack();
    }

    initPrint() {
        if (this.initPrintDone) {
            return;
        }

        // TODO : move print management outside this view

        this.initPrintDone = true;
        this.$phonePrintTemplate = null;
        const that = this;

        function removePrintTemplate() {
            if (that.$phonePrintTemplate) {
                that.$phonePrintTemplate.remove();
                that.$phonePrintTemplate = null;
            }
        }

        const beforePrint = function () {
            if (that._isShown) {
                removePrintTemplate();
                const contact = that.options.contact;
                if (that.contactView && contact) {
                    that.$phonePrintTemplate = $(phonePrintTemplate({
                        address: contact.address,
                        contactName: contact.contactNameToDisplay,
                        accountType: translate('detailedSheetView.contactAccountType.' + contact.accountType),
                        phoneNumber: new PhoneNumberFormatter(contact.phoneToDisplay).phoneNumberToDisplay,
                    }));
                    that.$phonePrintTemplate.prependTo(that.$container);
                    that.emit('print');
                }
            }
        };
        const afterPrint = function () {
            if (that._isShown) {
                removePrintTemplate();
            }
        };

        if (window.matchMedia && window.matchMedia('print')) {
            window.matchMedia('print').addListener(function (mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
        }
        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    }

    setMapEnabled(enabled) {
        this.configuration.showMap = enabled;
        if (this.$element) {
            this.$element.css('width', enabled ? '' : '100%');
        }
        this.contactView.setMapEnabled(enabled);
    }

    getEventsCameraToWait(options) {
        const eventsToWait = [];
        if (!this.slideshowInitialized) {
            eventsToWait.push('slideshowInitialized');
        }
        if (!this.loadCompleteAdInitialized) {
            eventsToWait.push('loadCompleteAdInitialized');
        }
        if (!this.detailSheetDescriptionInitialized) {
            eventsToWait.push('detailSheetDescriptionInitialized');
        }
        if (!this.descriptionInitialized) {
            eventsToWait.push('descriptionInitialized');
        }
        if (!this.neighborHoodInitialized) {
            eventsToWait.push('neighborHoodInitialized');
        }
        if (!options.neighborhoodInfo) {
            eventsToWait.push('neighborHoodInfoInitialized');
        }
        return eventsToWait;
    }

    // eslint-disable-next-line complexity
    show(options) {
        if (!this._isShown) {
            this._isShown = true;
            this._eventsWhileShown = new EventPack();
            this.pageContext = options.pageContext;
            this.searchController = options.searchController;
            this.descriptionInitialized = false;
            this.neighborHoodInitialized = false;
            this.detailSheetDescriptionInitialized = false;
            this.loadCompleteAdInitialized = false;
            this.slideshowInitialized = false;
            this.realEstateAd = VirtualTourHelper.enhanceAd(options.realEstateAd);
            this.adContentHandler.updateContent(this.realEstateAd.id, this.realEstateAd);
            if (options.$container) {
                this.$container = options.$container;
            }
            if (this.realEstateAd.modificationDate == this.realEstateAd.publicationDate) {
                delete this.realEstateAd.modificationDate;
            }
            const isOnProSite = ApplicationConfig.applicationPro;
            const userRelativeData = this.realEstateAd.userRelativeData;
            const isAdmin = Account.isAdmin();
            const canSeeContactsList = userRelativeData.isOwner ||
                userRelativeData.isNetwork ||
                Account.hasRole('contactViewer');
            const canShowContactForms = this.canShowContactForms();
            const {realEstateAd} = this;
            const self = this;
            const {
                energyValue,
                energyClassification,
                greenhouseGazValue,
                greenhouseGazClassification,
                useJuly2021EnergyPerformanceDiagnostic: useLatestEnergyPerformanceDiagnostic,
                energyPerformanceDiagnosticDate,
                minEnergyConsumption,
                maxEnergyConsumption,
                energySimulationReferenceDate,
                averageAnnualEnergyConsumption,
                epdFinalEnergyConsumption,
            } = realEstateAd;
            _.extend(options, {
                isAdmin,
                canSeeContactsList,
                displayAdReminderExplanation: !isOnProSite && !this.realEstateAd.status.onTheMarket,
                hasNoLabelForDpeAndGes: hasNoLabelForDpeAndGes(energyClassification, greenhouseGazClassification),
            });
            _.defaults(options, {
                showMap: this.configuration.showMap,
            });
            const dpe = {
                value: energyValue,
                classification: energyClassification,
            };
            const ges = {
                value: greenhouseGazValue,
                classification: greenhouseGazClassification,
            };
            const {
                canSeePublicationCertificateHtml,
                canSeePublicationCertificatePdf,
            } = userRelativeData;
            const accessTokenUrlParameter = `?access_token=${encodeURIComponent(Account.getAccessToken())}`;
            // @vue/component
            const vueData = this.vueData = {
                isExactPositionViewer: Account.hasRole('exactPositionViewer'),
                isAdModifier: Account.hasRole('adModifier'),
                realEstateAd,
                chartStats: {},
                startDate: moment(getOldestDateForStats(this.realEstateAd)),
                endDate: moment(),
                displayInsuranceEstimation: false,
                displayReportButton: !isOnProSite,
                displayPartnerServices: options.partnerServicesEnabled && savedOptions.showPartnerServices,
                partnerServices,
                canBuyTemporaryLeadingAds: Account.canBuyTemporaryLeadingAds(),
                displayDocumentService: realEstateAd.status.onTheMarket,
                canShowContactForms,
                contact: undefined,
                hasEmailToDisplay: true,
                contactSentToPage: false,
                enableCguLinkFullText: options.enableCguLinkFullText,
                infoAsked: options.infoAsked || '',
                specialType: options.specialType,
                displayModalCompactContactForm: false,
                contentData: undefined,
                setAsLeading: options.setAsLeading,
                dpe,
                ges,
                useLatestEnergyPerformanceDiagnostic,
                hasEnergyPerformanceDiagnosticDate: Boolean(energyPerformanceDiagnosticDate),
                minEnergyConsumption,
                maxEnergyConsumption,
                energySimulationReferenceDate,
                averageAnnualEnergyConsumption,
                epdFinalEnergyConsumption,
                agency: undefined,
                canSeePublicationCertificateHtml,
                canSeePublicationCertificatePdf,
                rentControl: DetailedSheetDetailsExtractor(realEstateAd).rentControl || {},
                hasNoLabelForDpeAndGes: hasNoLabelForDpeAndGes(energyClassification, greenhouseGazClassification),
            };
            // @vue/component
            const vueOptions = {
                components: {
                    RealEstateAdNotificationBoostButton,
                    ActionsBanner,
                    AdContactInfo,
                    AdShareButton,
                    ContactsResults,
                    MainButton,
                    RealEstateAdStatsChart,
                },
                constants: {
                    CONTACTS_PER_PAGE: 10,
                },
                mixins: [
                    i18nMixin({
                        prefix: 'DetailedSheetView.',
                        keys: [
                            DATA_TRANSLATION,
                            'nativeShareTitle',
                            'mailShare',
                            'georisquesMentionHtml',
                            'readMore',
                            'onlineMeetingInfo',
                        ],
                    }),
                ],
                data() {
                    return vueData;
                },
                computed: {
                    hasOnlineMeetingScheduleLink() {
                        return Boolean(this.realEstateAd.onlineMeetingScheduleLink);
                    },
                    seeMoreButtonEnabled() {
                        return true;
                    },
                    displayCompactContactForm() {
                        return this.canShowContactForms && this.hasEmailToDisplay;
                    },
                    hasGeorisquesMention() {
                        return _.defaultTo(realEstateAd.hasGeorisquesMention, true);
                    },
                    displayRelatedAds() {
                        return Options.get('relatedAdsPosition');
                    },
                    contactedRealEstateAd() {
                        const {contentData, realEstateAd} = this;
                        return _.get(contentData, 'realEstateAd', realEstateAd);
                    },
                    displayAboutSection() {
                        const {realEstateAd} = this;
                        const details = DetailedSheetDetailsExtractor(realEstateAd);
                        const hasDetailsProperty = !_.isEmpty(details.property);
                        const hasDetailsCondominium = !_.isEmpty(details.condominium);
                        const hasDetailsProgram = !_.isEmpty(details.program);
                        const isInCondominium = realEstateAd.isInCondominium;
                        const hasGeorisquesMention = this.hasGeorisquesMention;

                        return hasDetailsProperty || hasDetailsCondominium || hasDetailsProgram || isInCondominium
                            || hasGeorisquesMention;
                    },
                    isNotLot() {
                        return !isLot(this.realEstateAd);
                    },
                    canBeSetAsLeading() {
                        return this.isNotLot;
                    },
                    publicationCertificateUrl() {
                        return `/certificat-de-publication/${this.realEstateAdId}`;
                    },
                    publicationCertificateHtmlUrl() {
                        return this.publicationCertificateUrl + accessTokenUrlParameter;
                    },
                    publicationCertificatePdfUrl() {
                        return `${this.publicationCertificateUrl}.pdf${accessTokenUrlParameter}`;
                    },
                    onTheMarket() {
                        return this.realEstateAd.status.onTheMarket;
                    },
                    hasToDisplayFavoritesInDetails() {
                        return ApplicationConfig.hasToDisplayFavorites && !Account.isShowRoom();
                    },
                    displayOutOfTheMarketAdOverview() {
                        return !isOnProSite;
                    },
                    isDisplayModeList() {
                        return false;
                    },
                    realEstateAds() { // Similar ads, required a 'realEstateAds' name because of adOverview mixin
                        const {realEstateAd} = this;
                        return _.concat([realEstateAd], realEstateAd.similarAds); // needed realEstateAd because it is used for setting ad on the market
                    },
                    disableAlreadySeen() {
                        return ApplicationConfig.disableAlreadySeen || Account.isShowRoom();
                    },
                    enableLastContactDateTag() {
                        return ApplicationConfig.enableLastContactDateTag;
                    },
                    displayAboutAgency() {
                        return !isOnProSite;
                    },
                    contactsResultsOptions() {
                        const resultsViewOptions = {
                            accountId: _.get(options, 'account.id', Account.getAuthenticatedAccountId()),
                        };
                        const {realEstateAd} = this;
                        const contactData = _.map(realEstateAd.contactRequests, contactRequest => (_.assignIn(contactRequest, {
                            realEstateAd,
                            type: 'contactRequest',
                        }, contactRequest.sender)));
                        return _.extend(resultsViewOptions, {
                            contactData,
                            startDate: moment(getOldestDateForStats(realEstateAd)),
                            endDate: moment(),
                        });
                    },
                    realEstateAdId() {
                        return this.realEstateAd.id;
                    },
                    realEstateAdPitch() {
                        return this.realEstateAd.pitch;
                    },
                    descriptionHtml() {
                        return TextFormatter.generateDescription(
                            this.realEstateAd,
                            {adContentHandler: this.adContentHandler}
                        );
                    },
                    descriptionTitle() {
                        return RealEstateAdTitleGenerator.getTitle(this.realEstateAd, 'detailedSheetDescription');
                    },
                    hasSomePhotos() {
                        const {realEstateAd: {photos, virtualTours}} = this;
                        return _.size(photos) > 0 || _.size(virtualTours) > 0;
                    },
                    displaySlideshow() {
                        return canShowContactForms || this.hasSomePhotos;
                    },
                    slideshowContainerStyle() {
                        return this.displaySlideshow ? '' : 'background-image:url(' + resourceUrl('images/no_photo.png') + ')';
                    },
                    slideshowContainerClasses() {
                        return _.get(this.realEstateAd, 'status.highlighted') ? 'highlighted' : '';
                    },
                    detailedSheetHeaderDescriptionContainerClasses() {
                        const containerClass = 'detailed-sheet-header-description-container';
                        return {
                            [containerClass]: true,
                            [containerClass + '--has-account-logo']: Boolean(this.realEstateAd.accountDisplayName),
                        };
                    },
                    displayModificationIfOutOfTheMarket() {
                        return self.pageContext === 'public'; // always display modification buttons on public domain for special roles
                    },
                    contactsInfo() {
                        return this.realEstateAd.contactsInfo;
                    },
                    hasSpecialOffer() {
                        return Boolean(this.realEstateAd.specialOffer);
                    },
                    specialOffer() {
                        return this.realEstateAd.specialOffer;
                    },
                },
                methods: {
                    applyFeaturedPropertyOnAd(propertyName, modifiedAd) {
                        // Replace the component realEstateAd by an updated copy to avoid mutation
                        this.realEstateAd = updateAdFeaturedProperty(realEstateAd, propertyName, modifiedAd);
                        self.emit('statusChange');
                    },
                    getDocument({adId, contentType, index, specialType, openContactFormBeforeDisplayingContent}) {
                        if (contentType === 'pdf') { // document available to be opened
                            if (openContactFormBeforeDisplayingContent) {
                                self.showAdContentFromContentData({adId, contentType, index, specialType}); // open form to display documentation
                            } else {
                                self.openContent({adId, contentType, index}); // open documentation
                            }
                        } else if (contentType === 'nothing') { // no document to display
                            self.showAdContentFromContentData({adId, contentType, index, specialType}); // open form to request documentation
                        }
                    },
                    contactEmailSent($event) {
                        self.emitContactSentToPage($event);
                    },
                    beforeContactEmailSentFromModal() {
                        self.openContent(this.contentData);
                    },
                    showAdContentFromContentData(contentData) {
                        self.showAdContentFromContentData(contentData);
                    },
                    applyOnTheMarketChange(modifiedAd) {
                        const newAd = updateMarketStatusProperties(realEstateAd, modifiedAd);
                        this.realEstateAd = newAd;
                        self.onToggleMarketStatus(newAd);
                    },
                },
            };
            super.show(options, vueOptions);
            this.showStatsView();
            if (options.savedSearches && _.get(options, 'lastPage.disableSaveSearchBanner', false) === false) {
                this.showSaveSearchBanner(options.savedSearches);
            }
            this.playOpenAnimation(_.bind(this._initDetailedSheet, this));
            AppInstallBannerContainer.searchAndUpdateContainer(this.$element);
        }
    }

    update(options) {
        this.hide({disableCloseAnimation: true});
        this.show(options);
    }

    shouldPlayOpenAnimation() {
        //do not animate on open if this page is the first page seen (= no lastPage)
        return this.shouldAnimate() && this.options.lastPage;
    }

    shouldPlayCloseAnimation() {
        return this.shouldAnimate();
    }

    shouldAnimate() {
        return this.options.doesWindowSupportSplitScreen && this.options.hasSearchPageSplitScreen && !this.isMobile;
    }

    _initDetailedSheet() {
        if (this._isShown) {
            this._initDomNeededElements();
            this.initRelatedAds();
            this._bindDetailedSheet();
            if (this.canShowContactForms()) {
                this.openContactPlaceholderLoading();
            }
            this.emit('DOMready');
        }
    }

    showSaveSearchBanner(savedSearches) {
        if (this.saveSearchBannerView && _.isEmpty(savedSearches) && this.doesLastPageAllowSavedSearches) {
            this.saveSearchBannerView.setContainer(this.$element.find('.saveSearchBannerContainer'));
            this.saveSearchBannerView.show();
        }
    }

    doesLastPageAllowSavedSearches() {
        return _.get(this.options, 'lastPage.configuration.saveSearchAllowed', true);
    }

    updateAgencyFeeUrl() {
        const $agencyFeeUrlCell = this.$element.find('.agencyFeeUrl').closest('.labelInfo');
        if (this.realEstateAd && (this.realEstateAd.agencyFeeUrl || this.realEstateAd.agencyFeeFilename)) {
            const $newAgencyFeeUrlCell = this.renderTemplate(agencyFeeUrlCellTemplate, {
                key: 'agencyFeeUrl',
                url: this.realEstateAd.agencyFeeFilename ?
                    getFileUrlFromAlias(this.realEstateAd.agencyFeeFilename) : this.realEstateAd.agencyFeeUrl,
            });
            if ($agencyFeeUrlCell.length) {
                $agencyFeeUrlCell.replaceWith($newAgencyFeeUrlCell);
            } else {
                const $aboutThisAdList = this.$element.find('.detailsSection_aboutThisAd .allDetails');
                $aboutThisAdList.append($newAgencyFeeUrlCell);
            }
        } else {
            $agencyFeeUrlCell.remove();
        }
    }

    initRelatedAds() {
        const $container = this.$element.find('.relatedRealEstateAdsContainer');
        if (ProgrammeHelper.isProgrammeOrResidence(this.realEstateAd)) {
            this.relatedAdsView.setContainer($container);
            this.relatedAdsView.show(this.getRelatedAdsOptions());
            this.onRelatedAdsUpdate();
        } else {
            $container.hide();
        }
    }

    onRelatedAdsUpdate() {
        const relatedAds = this.relatedAdsView.getRelatedAds();
        this.updateContactNeededForContent(relatedAds);
        if (!_.isUndefined(relatedAds)) {
            GoogleTagManager.sendEvent('relatedAdsShown');
        }
    }

    updateContactNeededForContent(ads) {
        const contentTypes = ['virtualTours', 'pdf'];
        _.each(contentTypes, contentType => {
            const key = contentType + 'BehindForm';
            this.realEstateAd[key] = this.realEstateAd[key] && _.every(ads, ad => ad[key]);
        });
    }

    createContactPlaceholderLoading() {
        // todo: use the same component to loading and contact

        const $contactPlaceholderLoading = this.$contactPlaceholderLoading = this.renderTemplate(
            contactInfoBlockLoadingViewTemplate
        );

        const $contactPlaceholderLoadingContainer = this.$contactPlaceholderLoadingContainer =
            $("<div class='contactPlaceholderLoadingContainer'><div class='contactPlaceholderLoading__bg'></div></div>")
                .append($contactPlaceholderLoading)
                .appendTo(this.$container);

        const view = this;
        this.injectVueViews($contactPlaceholderLoadingContainer, {
            methods: {
                waitingToOpenContact() {
                    view.$contactPlaceholderLoadingContainer.addClass('isWaitingToOpenContact');
                    view.isWaitingToOpenContact = true;
                    if (view.isReadyToOpenContact) {
                        view.isWaitingToOpenContact = false;
                        view.wantContactToBeOpen = true;
                        view.emit('openContactSection', 'footer');
                    }
                },
            },
        });

        this.$contactPlaceholderLoadingContainer.toggleClass('isDetailedSheetFullScreen', !this.options.showMap);
    }

    openContactPlaceholderLoading() {
        this.$contactPlaceholderLoadingContainer.addClass('isOpen');
    }

    closeContactPlaceholderLoading() {
        if (!this.$contactPlaceholderLoadingContainer) {
            return;
        }
        this.$contactPlaceholderLoadingContainer.removeClass('isOpen');
        setTimeout(() => {
            this.$contactPlaceholderLoadingContainer.remove();
        }, 500);
    }

    toggleOpacityContactPlaceholderLoading(isVisible) {
        if (!this.$contactPlaceholderLoadingContainer) {
            return;
        }
        this.$contactPlaceholderLoadingContainer.toggleClass('fadeOut', !isVisible);
        setTimeout(() => {
            this.$contactPlaceholderLoadingContainer.remove();
        }, 500);
    }

    showRelatedAdsSpinner() {
        this.relatedAdsView.toggleSpinner(true);
    }

    updateRelatedAds(ads) {
        if (this.realEstateAd) {
            this.relatedAdsView.update(this.getRelatedAdsOptions(ads));
            this.onRelatedAdsUpdate();
        }
    }

    getRelatedAdsOptions(ads) {
        return {
            closePageUrl: this.closePageUrl,
            relatedAds: ads || this.realEstateAd.relatedAds,
            metaAdPropertyType: this.realEstateAd.propertyType,
            adContentHandler: this.adContentHandler,
            canShowContentLinks: this.realEstateAd.status.onTheMarket,
            enableNoContentButton: !ApplicationConfig.applicationPro,
        };
    }

    updateProgramme(programme) {
        this.realEstateAd.programme = programme;
        this.updateContactNeededForContent([programme]);
    }

    updateResidence(residence) {
        this.realEstateAd.residence = residence;
        this.updateContactNeededForContent([residence]);
    }

    toggleMoreInfoContact(isVisible, options) {
        if (this.canShowContactSection()) {
            if (options && options.startTime && !isVisible) {
                this.isReadyToOpenContact = true;
                if (!this.isWaitingToOpenContact && !this.wantContactToBeOpen) {
                    this.toggleOpacityContactPlaceholderLoading(false);
                    this.emit('showContactSection');
                }
            } else {
                this.toggleOpacityContactPlaceholderLoading(false);
                this.contactView.toggleMoreInfoContact(isVisible, options);
                if (this.isWaitingToOpenContact) {
                    this.isWaitingToOpenContact = false;
                    this.emit('openContactSection', (options && options.openedFrom) || 'footer');
                } else if (isVisible) {
                    this.emit('sendOpenContactFormEvent', (options && options.openedFrom) || 'footer');
                }
            }
        } else {
            this.toggleOpacityContactPlaceholderLoading(false);
        }
    }

    createElement(options) {
        this.options = options;
        const realEstateAd = this.realEstateAd;
        const isProgrammeOrResidence = ProgrammeHelper.isProgrammeOrResidence(realEstateAd);
        const isShowRoom = Account.isShowRoom();
        const details = DetailedSheetDetailsExtractor(realEstateAd);
        const isMobileOrTablet = isMobile() || isTablet();
        _.extend(options, {
            realEstateAd,
            details,
            TextFormatter,
            PriceFormatter,
            RealEstateAdTitleGenerator,
            AddressFormatter,
            TextFiltersFormatter,
            savedOptions,
            isFromNativeIosApp: UserAgentHelper.isFromNativeIosApp(),
            isFromNativeAndroidApp: UserAgentHelper.isFromNativeAndroidApp(),
            canSeePlan: isProgrammeOrResidence,
            isProgrammeOrResidence,
            with360: realEstateAd.with360,
            with360BecauseOfVirtualTourTesterRole: realEstateAd.with360BecauseOfVirtualTourTesterRole,
            Account,
            isShowRoom,
            isAuthenticated: Account.isAuthenticated(),
            adContentHandler: this.adContentHandler,
            adSharingEnabled: savedOptions.adSharingEnabled && realEstateAd.status.onTheMarket && !isShowRoom,
            chargingStationsEnabled: canSeeChargingStations(realEstateAd),
            displayAdShareButton: isMobileOrTablet && NAVIGATOR_SHARE_SUPPORTED,
            relatedAdsPosition: 'relatedAdsAfterSlideShow',
        });
        this.$element = this.renderTemplate(this.template, options);
        if (this.canShowContactForms()) {
            this.createContactPlaceholderLoading();
        }
        this.$mapOptions = this.$element.filter('#detailedSheetMapOptions');
        this.$element.find('.detailedSheetContainer').focus();
        if (options.initFromDom) {
            this.findElementFromContainer();
        }
        this._initModel(this.realEstateAd);
        const {similarAds} = this.realEstateAd;
        if (similarAds) {
            // Do not inject Vue here, as it will be done afterwards, at the end of the CompositeVueView#show() call
            this.updateSimilarAds(similarAds, false);
        }

        this.asyncHelper.doAsync({
            func: cb => _.defer(cb),
            callback: () => this._initDescription(),
            name: 'initDescription',
        });
        this.asyncHelper.doAsync({
            func: cb => _.defer(cb),
            callback: () => this._initNeighborhoodData(),
            name: 'initNeighborhoodData',
        });
        return this.$element;
    }

    showAdContent({currentTarget}) {
        const adId = currentTarget.getAttribute('data-realEstateAdId');
        const realEstateAd = _.find(this.relatedAdsView.getRelatedAds(), {id: adId});
        const contentData = {
            adId,
            contentType: currentTarget.getAttribute('data-type'),
            index: currentTarget.getAttribute('data-index') || 0,
            specialType: currentTarget.getAttribute('data-specialType'),
            realEstateAd,
        };
        this.showAdContentFromContentData(contentData);
    }

    showAdContentFromContentData(contentData) {
        const {contentType, specialType} = contentData;
        if (contentType == 'nothing' // no content, we can only display a form
            || (this.realEstateAd[contentType + 'BehindForm']
                && !this.options.userAlreadyContactedProgrammeOrResidence
                && this.canShowContactForms()
                && !Account.hasRole('adContentWithoutFormViewer'))) {
            _.extend(this.vueData, {
                displayModalCompactContactForm: true,
                infoAsked: contentData.contentType,
                specialType,
                contentData,
            });
        } else if (specialType === 'liveRoom') {
            this.openLiveRoom();
        } else {
            this.openContent(contentData);
        }
    }

    _initNeighborhoodData() {
        if (this._canEnableNeighborhoodInfo()) {
            if (this.neighborhoodInfo.stats || this.neighborhoodInfo.description) {
                this._initNeighborhoodStatsAndDescription();
            }
            this.neighborHoodInitialized = true;
            this.emit('neighborHoodInitialized');
        } else {
            this._removeNeighborhoodLoading();
        }
    }

    // used to replace ES ad with Mongo ad. TODO remove this and replace with a getContacts
    updateAd(ad) {
        this._initModel(ad);
        if (this.realEstateAd.id == ad.id) { //restore data from ES
            const dataFromEs = [
                'accountDisplayName',
                'relatedAds',
                'programme',
                'virtualTours',
                'pdfBehindForm',
                'virtualToursBehindForm',
                'nothingBehindForm',
                'highlightMailContact',
            ];
            _.extend(ad, _.pick(this.realEstateAd, dataFromEs));
        }
        const {contactRelativeData} = ad;
        if (contactRelativeData) {
            this.options.contact = contactRelativeData;
            const {userAlreadyContactedProgrammeOrResidence} = contactRelativeData;
            this.options.userAlreadyContactedProgrammeOrResidence = userAlreadyContactedProgrammeOrResidence;
            this.commitUserAlreadyContactedProgrammeOrResidenceToStore(userAlreadyContactedProgrammeOrResidence);
            this.vueData.agency = getAgencyFromContactRelativeData(contactRelativeData);
        }

        const canShowContactForms = this.canShowContactForms();
        if (this.options.contact) {
            if (this.canShowContactSection()) {
                this._initContact(this.options);
            }
            if ((canShowContactForms || ad.accountDisplayName) && this.slideshowWidget) {
                this.slideshowWidget.updateContactInfo({
                    ...this.options.contact,
                    canShowContactForms,
                });
            }
            if (canShowContactForms) {
                SideMapViewSingleton.get().showDetailedSheetContactInfo(this.options.contact);
            }
        }
        this.realEstateAd = ad;
        this.updateAgencyFeeUrl();
    }

    commitUserAlreadyContactedProgrammeOrResidenceToStore(alreadyContacted) {
        store.get().commit('realEstateAdContacts/setUserAlreadyContactedProgrammeOrResidence', alreadyContacted);
    }

    _removeNeighborhoodLoading() {
        this.$element.find('.neighborhoodSection .centerLoadingSpinner').hide();
    }

    _initDescription() {
        this.descriptionInitialized = true;
        this.emit('descriptionInitialized');
    }

    _initDomNeededElements() {
        this.asyncHelper.doAsync({
            func: cb => _.defer(cb),
            callback: () => this._initSlideshowWidget(),
            name: 'initSlideshowWidget',
        });
    }

    findElementFromContainer() {
        this.$element = this.$container.find('.section-detailedSheet');
        this.$mapOptions = this.$container.find('#detailedSheetMapOptions');
    }

    _initModel(realEstateAd) {
        _.extend(this.model, realEstateAd);
    }

    _getSlideShowTitle() {
        return RealEstateAdTitleGenerator.getTitle(this.model, 'full');
    }

    _bindContactViewElements(options) {
        this._unbindContactViewElements();
        const hasPhone = Boolean(options.contact.phoneToDisplay);
        const couldBeContactedByEmailOrWebservice =
            options.contact.hasEmailToDisplay || options.contact.contactEmailType === 'visiteonline';
        const highlightMailContact = this.realEstateAd.highlightMailContact;
        const displayPhoneButton = hasPhone && (!highlightMailContact || !couldBeContactedByEmailOrWebservice);
        const displayContactSectionButton = couldBeContactedByEmailOrWebservice && (highlightMailContact || !hasPhone);
        this.$element.find('.openPhoneSectionBtn').toggleClass('isAvailable', displayPhoneButton);
        this.$element.find('.openContactSectionBtn').toggleClass('isAvailable', displayContactSectionButton);
        this._openContactSectionHandler = _.bind(function (type, options) {
            this.emit('openContactSection', type, options);
        }, this);
        this.contactView.on('openContactSection', this._openContactSectionHandler);
        this._phoneNumberShownHandler = _.bind(function (source) {
            this.emit('showPhoneNumber', source);
        }, this);
        this.contactView.on('showPhoneNumber', this._phoneNumberShownHandler);
        this._contactEmailSentHandler = _.bind(this.emitContactSentToPage, this);
        this.contactView.on('contactEmailSent', this._contactEmailSentHandler);
        this._closeContactSectionHandler = _.bind(function () {
            this.emit('closeContactSection');
        }, this);
        this.contactView.on('closeContactSection', this._closeContactSectionHandler);
    }

    emitContactSentToPage(options) {
        this.vueData.contactSentToPage = true;
        this.emit('contactEmailSent', _.extend({
            realEstateAd: this.realEstateAd,
            realEstateAdId: this.realEstateAd.id,
            closePageUrl: this.closePageUrl,
            doesWindowSupportSplitScreen: this.options.doesWindowSupportSplitScreen,
            hasSearchPageSplitScreen: this.options.hasSearchPageSplitScreen,
        }, options));
        if (ProgrammeHelper.isProgrammeOrResidenceOrLot(this.realEstateAd)) {
            this.commitUserAlreadyContactedProgrammeOrResidenceToStore(true);
        }
    }

    _unbindContactViewElements() {
        if (this._openContactSectionHandler && this.iframeWidget) {
            this.iframeWidget.removeListener('openContactSection', this._openContactSectionHandler);
            this._openContactSectionHandler = null;
        }
        this.$element.find('.openPhoneSectionBtn').removeClass('isAvailable');
        this.$element.find('.openContactSectionBtn').removeClass('isAvailable');
        if (this._openContactSectionHandler) {
            this.contactView.removeListener('openContactSection', this._openContactSectionHandler);
            this._openContactSectionHandler = null;
        }
        if (this._phoneNumberShownHandler) {
            this.contactView.removeListener('showPhoneNumber', this._phoneNumberShownHandler);
            this._phoneNumberShownHandler = null;
        }
        if (this._contactEmailSentHandler) {
            this.contactView.removeListener('contactEmailSent', this._contactEmailSentHandler);
            this._contactEmailSentHandler = null;
        }
        if (this._closeContactSectionHandler) {
            this.contactView.removeListener('closeContactSection', this._closeContactSectionHandler);
            this._closeContactSectionHandler = null;
        }
    }

    _openContactSection() {
        this.emit('openContactSection', 'mail');
    }

    _openPhoneSection(from, event) {
        phoneContactCounter.increment();
        if (this.contactView.$element) {
            this.contactView.showPhoneNumber({source: from, event, displayPhoneNumber: true});
        }
    }

    //Todo should be in DetailedSheetPage
    _shareOnFacebook() {
        const shareData = getRealEstateSharedData(this.realEstateAd);
        ShareHelper.shareOnFacebook(shareData.url, 'realEstateAdShare', {
            realEstateAdId: this.realEstateAd.id,
        });
    }

    _shareOnMail() {
        MailSharingHelper.ad(this.model);
    }

    _nativeShare() {
        const {url, title, price} = getRealEstateSharedData(this.realEstateAd);
        NativeInterfaceProxy.shareAd(url, title, price);
        GoogleTagManager.sendRealEstateAdShareEvent('native', this.realEstateAd);
    }

    _printPage() {
        window.print();
    }

    _initContact(options) {
        this.contactView.$container = $('body');
        const {contact, realEstateAd, searchController, showMap} = options;
        const searchCriteria = _.get(searchController, 'searchCriteria');
        const author = _.get(searchController, 'author');
        this.contactView.show({
            contact,
            realEstateAdId: this.model.id,
            ownerIds: this.model.userRelativeData.accountIds,
            isMobile: this.isMobile,
            showMap,
            searchCriteria,
            author,
            realEstateAd,
        });
        this._bindContactViewElements(options);
        this.updateCompactContactFormViews(contact, searchCriteria, author);
        this.isReadyToOpenContact = true;
        if (this.isWaitingToOpenContact) {
            this.emit('openContactSection', 'footer');
        }
    }

    updateCompactContactFormViews(
        {
            accountType,
            contactEmailType,
            hasEmailToDisplay,
            rentalApplicationEnabled,
        }, searchCriteria, author) {
        const {vueData, model: {id: realEstateAdId}} = this;
        if (hasEmailToDisplay) {
            vueData.contact = {
                contactEmailType,
                accountType,
                realEstateAdId,
                searchCriteria,
                author,
                rentalApplicationEnabled,
            };
        } else {
            vueData.hasEmailToDisplay = false;
        }
    }

    _initNeighborhoodStatsAndDescription() {
        this.formatNeighborhoodStats();
        this.initNeighborhoodDom();
    }

    formatNeighborhoodStats() {
        const neighborhoodStats = {};
        _.each(DETAILED_NEIGHBORHOOD_DATA, (section, sectionName) => {
            neighborhoodStats[sectionName] = [];
            if (this.neighborhoodInfo.stats) {
                _.each(section, statName => {
                    if (_.isArray(statName)) {
                        const _statName = statName[0];
                        const stats = this.neighborhoodInfo.stats[_statName];
                        if (stats) {
                            stats.unit = statName[1];
                            pushToStatArrayIfValue(neighborhoodStats[sectionName], stats);
                        }
                    } else {
                        pushToStatArrayIfValue(neighborhoodStats[sectionName], this.neighborhoodInfo.stats[statName]);
                    }
                });
            }
            this.neighborhoodInfo.orderedStats = neighborhoodStats;
        });
    }

    canShowContactForms() {
        return this.canShowContactSection() && this.realEstateAd.status.onTheMarket;
    }

    canShowContactSection() {
        return Boolean(this.realEstateAd
            && !this.realEstateAd.userRelativeData.isOwner
            && ApplicationConfig.applicationPro !== true);
    }

    _initSlideshowWidget() {
        const $detailedSheet = this.$element;
        const $slideshow = $detailedSheet.find('.slideshow');
        if ($slideshow.length > 0) {
            const {photoWatermarkAlias, accountDisplayName} = this.realEstateAd;
            const photoWatermarkUrl = getImageUrlFromAlias(photoWatermarkAlias, agencyLogoSize);

            this.slideshowWidget = new SlideshowWidget({
                slideshowTitle: this._getSlideShowTitle(),
                $container: $slideshow,
                photos: this.realEstateAd.photos,
                showContact: this.canShowContactForms(),
                virtualTours: this.realEstateAd.status.onTheMarket ?
                    VirtualTourHelper.getAvailableTours(this.realEstateAd.virtualTours) : null,
                displayNew360Preview: this.realEstateAd.newProperty,
                showLiveRoomButton: this.realEstateAd.withLiveRoom,
                isProgrammeOrResidenceOrLot: ProgrammeHelper.isProgrammeOrResidenceOrLot(this.realEstateAd),
                adId: this.realEstateAd.id,
                photoWatermarkUrl,
                displaySlideshowPhoneButton: !this.realEstateAd.highlightMailContact,
                contact: this.options.contact,
                accountDisplayName,
            });
            this.slideshowWidget.show();
            this._bindSlideShow();
        }
        this.slideshowInitialized = true;
        this.emit('slideshowInitialized');
    }

    _bindSlideShow() {
        this._unbindSlideShow();
        if (this.slideshowWidget) {
            this.slideshowEventPack = new EventPack();
            this.slideshowEventPack.on(this.slideshowWidget, {
                openContactSection: (type, options) => {
                    this.emit('openContactSection', type, options);
                },
                toggleSlideShowFullScreen: (showMap) => {
                    this.emit('toggleSlideShowFullScreen', {showMap});
                },
                openLiveRoom: () => {
                    this.openLiveRoom();
                },
            });

            this._eventsWhileShown.on(this.slideshowWidget, 'showAdContent', _.bind(this.showAdContent, this));
        }
    }

    openContent({adId, contentType, index}) {
        this.realEstateAd[contentType + 'BehindForm'] = false;
        if (contentType === 'nothing') {
            return;
        }
        const content = this.adContentHandler.getContent(adId, contentType, index);
        if (!content) {
            console.warn(`no content for ${adId} with ${contentType} type at index ${index}`);
            return;
        }
        const contentOpenerByType = {
            virtualTours: _.bind(this._openVirtualTour, this),
            defaults: _.bind(this.openNewTab, this),
        };
        const opener = contentOpenerByType[contentType] || contentOpenerByType.defaults;
        opener(content, `${contentType}Content${adId}`);
    }

    openNewTab({url}, windowName) {
        const parameters = 'location,resizable,scrollbars,dependent,top=0,left=0';
        //width & height can be set in parameters
        OpenWindow.open({url, windowName, parameters});
    }

    _removeIframeWidget() {
        this._iframeEventPack.removeAllListeners();
        this.iframeWidget.hide();
        delete this.iframeWidget;
    }

    _openTourInIframeWidget(tour, widgetConfig, windowName) {
        if (this.iframeWidget) {
            this._removeIframeWidget();
        }

        //TODO handle size information
        const urlForTour = VirtualTourHelper.getUrlForTour(tour);
        this._enrichTourUrlWithAccountIdIfNeeded(tour, urlForTour, (err, urlForTour) => {
            assert.strictEqual(err, null); // _enrichTourUrlWithAccountIdIfNeeded always returns a null error
            if (VirtualTourHelper.canOpenVirtualTourInIframe(tour)) {
                SideMapViewSingleton.get().toggleMap(false);
                this.iframeWidget = new IframeWidget(_.extend({
                    iframeSrc: urlForTour,
                }, widgetConfig));
                this.iframeWidget.show();
                this._iframeEventPack.on(this.iframeWidget, {
                    close: () => {
                        SideMapViewSingleton.get().toggleMap(this.options.showMap);
                        this._removeIframeWidget();
                    },
                });
            } else {
                this.openNewTab({url: urlForTour}, windowName);
            }
        });
    }

    _openVirtualTour(tour, windowName) {
        this._openTourInIframeWidget(tour, {
            title: this._getSlideShowTitle(),
            withGenericContentLabel: tour.roomsQuantity || tour.isDuplex || tour.propertyType,
            showContactButton: this.canShowContactForms(),
            showLiveRoomButton: tour.envisiteLiveRoomUrl,
        }, windowName);

        if (this.iframeWidget) {
            if (this._openContactSectionHandler) {
                //close event is triggered right after openContactSection event
                this._iframeEventPack.on(this.iframeWidget, 'openContactSection', this._openContactSectionHandler);
            }
            this._iframeEventPack.on(this.iframeWidget, {
                openLiveRoom: () => {
                    //close event is triggered right after openLiveRoom event
                    this.openLiveRoom(tour);
                },
            });
        }
    }

    /**
     * @callback _enrichTourUrlWithAccountIdIfNeededCallback
     * @param {null} err
     * @param {string} url
     */

    /**
     *
     * @param {{requiresAccountIdInQueryString: boolean}} tour
     * @param {string} url
     * @param {_enrichTourUrlWithAccountIdIfNeededCallback} cb
     * @private
     */
    _enrichTourUrlWithAccountIdIfNeeded({requiresAccountIdInQueryString}, url, cb) {
        if (requiresAccountIdInQueryString) {
            Account.getAccountAndCreateGuestIfNeeded((err, account) => {
                if (err) {
                    Errors.showUnexpectedError(err);
                } else {
                    const parsedUrl = urlUtil.parse(url, true);
                    delete parsedUrl.search; //enable use of query
                    parsedUrl.query.accountId = account.id;
                    url = urlUtil.format(parsedUrl);
                    cb(null, url);
                }
            });
        } else {
            cb(null, url);
        }
    }

    openLiveRoom(tour) {
        if (!tour) {
            tour = _.first(VirtualTourHelper.getVirtualToursWithLiveRoom(this.realEstateAd.virtualTours));
        }
        this._enrichTourUrlWithAccountIdIfNeeded(tour, tour.envisiteLiveRoomUrl, (err, url) => {
            assert.strictEqual(err, null); // _enrichTourUrlWithAccountIdIfNeeded always returns a null error
            if (err) {
                Errors.showUnexpectedError(err);
            } else {
                this._openLiveRoomAtUrl(url);
            }
        });
    }

    _openLiveRoomAtUrl(url) {
        const envisiteLiveRoomTour = {
            url,
            https: true,
            accepted: true,
        };
        this._openTourInIframeWidget(envisiteLiveRoomTour, {});
    }

    _unbindSlideShow() {
        if (this.slideshowEventPack) {
            this.slideshowEventPack.removeAllListeners();
        }
    }

    initNeighborhoodDom() {
        const $detailedSheet = this.$element;
        const neighborhoodSection = $detailedSheet.find('.neighborhoodSection');
        PlaceDetailsHelper.setPlaceDiscoveryUrls(this.neighborhoodInfo);
        this.neighborhoodInfo.neighborhoodDescription = KelQuartierDescription.getDescription(this.neighborhoodInfo);
        let editorialDescription = this.neighborhoodInfo && (this.neighborhoodInfo.pitch || this.neighborhoodInfo.description);
        if (editorialDescription) {
            editorialDescription = editorialDescription.replace(/<\w+>/g, '').replace(/<\/\w+>/g, '');
        }
        const editorialPhoto = this.neighborhoodInfo
            && this.neighborhoodInfo.photos
            && this.neighborhoodInfo.photos[0];

        const editorialData = {
            description: editorialDescription,
            photo: getImageUrlFromAlias(editorialPhoto, {width: 640, height: 480}),
        };

        const {postalCode} = this.model;
        const departmentCode = postalCode && postalCode.substring(0, 2);

        const $template = $(neighborhoodTemplate({
            neighborhoodInfo: this.neighborhoodInfo,
            t: translate,
            statToGauge: KelQuartierHelper.statToGauge,
            realEstateAd: this.model,
            departmentCode,
            isMobile: this.isMobile,
            editorialData: editorialData,
            RealEstateAdTitleGenerator,
            showLinks: ApplicationConfig.showNeighborhoodDescriptionLinksInAdDetailedSheet,
        }));

        const {neighborhoodInfo} = this;
        // @vue/component
        const vueOptions = {
            data() {
                return {
                    neighborhoodInfo,
                    departmentCode,
                };
            },
        };
        this.injectVueViews($template, vueOptions);
        this._removeNeighborhoodLoading();
        neighborhoodSection.append($template);
    }

    _canEnableNeighborhoodInfo() {
        return _.get(this.realEstateAd, 'blurInfo.type') !== 'postalCode';
    }

    updateNeighborhoodInfo(neighborhoodInfo) {
        this.neighborhoodInfo = _.extend(this.neighborhoodInfo, neighborhoodInfo);
        if (neighborhoodInfo && this._canEnableNeighborhoodInfo()) {
            this._initNeighborhoodStatsAndDescription();
            this._toggleNeighborhoodInfo(false);
        } else {
            const $detailedSheet = this.$element;
            const neighborhoodSection = $detailedSheet.find('.neighborhoodSection');
            neighborhoodSection.hide();
        }
    }

    _bindEvents() {
        this._eventsWhileShown.on(this.relatedAdsView, 'adClick', _.bind(this.onRelatedAdsOpen, this));
        this._eventsWhileShown.on(this.relatedAdsView, 'lotMouseHovered', _.bind(this.emit, this, 'lotMouseHovered'));
        if (this.saveSearchBannerView) {
            this._eventsWhileShown.on(this.saveSearchBannerView, 'saveSearch', _.bind(this.emit, this, 'saveSearch'));
        }
        this._eventsWhileShown.on(this.$element, {
            click: {
                '.seeNeighborhood': _.bind(this._toggleNeighborhoodInfo, this),
                '.showAdContent': _.bind(this.showAdContent, this),
                '.showPhoneNumber': (event) => {
                    const $currentTarget = $(event.currentTarget);
                    const rawPhone = $currentTarget.attr('data-phone');
                    let formattedPhoneFromContact;
                    if (this.options.contact) { //this.options.contact is null while contacts are being loaded
                        const displayNumber = this.options.contact.phoneToDisplay;
                        formattedPhoneFromContact = new PhoneNumberFormatter(displayNumber).phoneNumberToDisplay;
                    }
                    const phoneToDisplay = new PhoneNumberFormatter(rawPhone).phoneNumberToDisplay;
                    if (phoneToDisplay === formattedPhoneFromContact && this.contactView.$element) {
                        this._openPhoneSection('descriptionContent', event);
                    } else {
                        //TODO make link when in mobile or skype is available
                        $currentTarget.removeClass('showPhoneNumber').text(phoneToDisplay + ' ');
                        if (this.pageContext !== 'pro') {
                            this.emit('showPhoneNumber', 'fromDetailedSheetDescription');
                        }
                    }
                },
                '.openContactForm': (event) => {
                    if (this.pageContext === 'pro') {
                        const $currentTarget = $(event.currentTarget);
                        const email = $currentTarget.attr('data-email');
                        $currentTarget.removeClass('openContactForm').text(email + ' ');
                    } else {
                        this.toggleMoreInfoContact(true, {openedFrom: 'descriptionContent'});
                    }
                },
                '.openVirtualTourFromDescription': (event) => {
                    const $currentTarget = $(event.currentTarget);
                    const url = $currentTarget.attr('href');
                    const tour = _.first(this.adContentHandler.getContentByUrl(this.realEstateAd.id, 'virtualTours', url));
                    if (tour) {
                        this._openVirtualTour(tour);
                        event.preventDefault(); //prevent following link
                    } else {
                        console.log(`Could not find tour for url ${url}, allowing link to open it.`);
                    }
                },
                '.shareOnFacebook': _.bind(this._shareOnFacebook, this),
                '.shareOnMail': _.bind(this._shareOnMail, this),
                '.androidNativeShare': _.bind(this._nativeShare, this),
                '.print-page': _.bind(this._printPage, this),
                '.openContactSectionBtn': _.bind(this._openContactSection, this),
                '.openPhoneSectionBtn': _.bind(this._openPhoneSection, this, 'fromDetailedSheet'),
            },
        });
    }

    _bindDetailedSheet() {
        this._unbindDetailedSheet();
        this._bindEvents();
    }

    _unbindDetailedSheet() {
        this._eventsWhileShown.removeAllListeners();
    }

    hide(options = {}, cb = _.noop) {
        if (this._isShown) {
            this.contactView.toggleContactInfo(false);
            if (this.canShowContactForms()) {
                this.closeContactPlaceholderLoading();
            }
            if (!options.disableCloseAnimation) {
                this.playCloseAnimation(() => {
                    if (this.searchController) {
                        this.searchController.emit('animEnded');
                    }
                    this._hide();
                });
            } else {
                this._hide();
            }
            this.statsView.hide();
            this._isShown = false;
            this.adContentHandler.cleanup();
            AppInstallBannerContainer.searchAndUpdateContainer(null);
        }
        cb();
    }

    _hide() {
        this._eventsWhileShown.removeAllListeners();
        this._unbindSlideShow();
        if (this.slideshowWidget) {
            this.slideshowWidget.hide();
        }
        this._unbindContactViewElements();
        this.contactView.hide();

        this._unbindDetailedSheet();

        if (this.chart) {
            this.chart.hide();
            delete this.chart;
        }
        if (ProgrammeHelper.isProgrammeOrResidence(this.realEstateAd)) {
            this.relatedAdsView.hide();
        }
        this.$mapOptions.remove();
        super.hide();
    }

    _toggleNeighborhoodInfo(visible) {
        visible = _.isBoolean(visible) ? visible : !this.neighborhoodInfo.visible;
        this.neighborhoodInfo.visible = visible;
        this._updateNeighborhoodButton(visible);
        this.emit('toggleNeighborhoodInfo', visible);
    }

    _updateNeighborhoodButton(visible) {
        let showTranslationKey;
        let hideTranslationKey;

        if (this.neighborhoodInfo) {
            if (this.neighborhoodInfo.type == 'city') {
                showTranslationKey = 'showCityZone';
                hideTranslationKey = 'hideCityZone';
            } else if (this.neighborhoodInfo.type == 'arrondissement') {
                showTranslationKey = 'showArrondissementZone';
                hideTranslationKey = 'hideArrondissementZone';
            } else {
                showTranslationKey = 'showNeighborhoodZone';
                hideTranslationKey = 'hideNeighborhoodZone';
            }
        } else if (this.realEstateAd.district && +this.realEstateAd.district.type_id !== 1) {
            showTranslationKey = 'showCityZone';
            hideTranslationKey = 'hideCityZone';
        } else {
            showTranslationKey = 'showNeighborhoodZone';
            hideTranslationKey = 'hideNeighborhoodZone';
        }
        const $neighborhoodZone = this.$element.find('.seeNeighborhood');
        $neighborhoodZone.html(translate(visible ? hideTranslationKey : showTranslationKey));
    }

    _clickTitleSection() {
        this.vueData.chartStats = this.stats;
        this.$element.find('.detailedSheetStats').toggleClass('open');

        GoogleTagManager.sendEvent('showAdStats', {
            realEstateAdId: this.realEstateAd.id,
            agencyId: Account.getAuthenticatedAccountId(),
        });
    }

    showStatsView() {
        const statsView = this.statsView = new StatsView({
            $container: this.$element.find('.stats'),
            detailedSheetView: this,
        });
        statsView.show();
    }

    onRelatedAdsOpen(data) {
        this.emit('adClick', _.extend(data, {
            previousRealEstateAd: (ProgrammeHelper.isProgrammeOrResidence(this.realEstateAd)) ? this.realEstateAd : null,
            direction: 'next',
        }));
    }

    updateSimilarAds(similarAds = [], injectVueOnSimilarAds = true) {
        const $similarAdsInDetailedSheet = this.$element.find('.similarAdsInDetailedSheet');
        $similarAdsInDetailedSheet.empty();
        if (similarAds.length || !this.realEstateAd.status.onTheMarket) {
            _.each(similarAds, similarAd => {
                similarAd.detailedSheetUrl = Urls.detailedSheet.makeUrl(similarAd);
            });
            const $element = this.renderTemplate(similarAdsTemplate, {
                realEstateAd: this.realEstateAd,
                similarAds,
                TextFormatter,
                PriceFormatter,
                RealEstateAdTitleGenerator,
                AddressFormatter,
                closePageUrl: this.closePageUrl || this.options.defaultClosePageUrl,
            });
            $similarAdsInDetailedSheet.append($element);
            if (injectVueOnSimilarAds) {
                // @vue/component
                const vueOptions = {
                    data() {
                        return {
                            realEstateAds: similarAds,
                        };
                    },
                    computed: {
                        disableAlreadySeen() {
                            return ApplicationConfig.disableAlreadySeen || Account.isShowRoom();
                        },
                        enableLastContactDateTag() {
                            return ApplicationConfig.enableLastContactDateTag;
                        },
                    },
                };
                this.injectVueViews($element, vueOptions);
            }
        }
    }

    onToggleMarketStatus(realEstateAd) {
        this.update({realEstateAd});
        this.emit('statusChange');
    }
};

function pushToStatArrayIfValue(section, stats) {
    if (stats && stats.carte_stat && stats.carte_stat != 'NA') {
        section.push(stats);
    }
}

function getOldestDateForStats({publicationDate, thresholdDate}) {
    // start before the publication date to see the graphs start with zero
    return thresholdDate || moment(publicationDate).subtract(1, 'day').toDate();
}

function canSeeChargingStations({chargingStations}) {
    if (savedOptions.chargingStationsEnabled) {
        const providers = _.get(chargingStations, 'providers');
        // todo: create a new canSeeDebugInfo role?
        return !_.isEmpty(providers) || Account.hasRole('exactPositionViewer') || Account.hasRole('adModifier');
    }
    return false;
}

function getAgencyFromContactRelativeData(
    {
        accountType,
        address,
        contactNameToDisplay,
        imageName,
        agencyId,
        agencyPageEnabled,
    }) {
    return {
        accountType,
        company: {
            address,
        },
        display_name: contactNameToDisplay,
        imageName,
        id: agencyId,
        agencyPageEnabled,
    };
}
