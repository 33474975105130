const _ = require('lodash');
const Ajax = require('./utils/Ajax');
const PlaceDetailsHelper = require('./PlaceDetailsHelper');

module.exports = {
    fetchNeighborhoodStatsById,
};

function fetchNeighborhoodStatsById(options, callback) {
    const data = options;
    if (!data.id) {
        console.trace('fetchNeighborhoodStats : invalid id, options=' + JSON.stringify(options), '(ad may be outside France....)');
        callback(null, null);
        return null;
    }
    return Ajax.request({
        url: '/neighborhoodStatsById.json',
        data,
        disableErrorPage: true,
        serverErrorMessage: 'fetchNeighborhoodStatsById',
        callback: function (err, data) {
            if (data) {
                const blockInfo = PlaceDetailsHelper.extractBlocksFromDescription(data.description);
                _.extend(data, blockInfo);
            }
            callback(err, data);
        },
    });
}
