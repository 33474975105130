let touchStartPositions;
let touchCurrentPositionX;
let touchLastPositionX;
let isAnimated = false;

let $menu;
let widthMenu;
let positionMenu = 0;
const minPixelToStartAnimation = 40;
const maxPixelToMoveOnYToSkipAnimation = 20;

module.exports = {
    touchStart,
    touchMove,
    touchEnd,
    shouldMoveMenu,
};

function touchStart($element, event) {
    initMenuValues($element);
    initStartPosition(event);
}

function initMenuValues($element) {
    $menu = $element;
    widthMenu = $element.width();
    positionMenu = 0;
}

function initStartPosition(event) {
    touchStartPositions = getTouchPosition(event);
}

function touchMove(event) {
    if (isAnimated) {
        return;
    }
    const positions = getTouchPosition(event);
    touchCurrentPositionX = positions.x;
    followFinger();
    touchLastPositionX = touchCurrentPositionX;
}

function followFinger() {
    setLastPositionIfNeeded();
    getMenuPosition();
    $menu.css(getTransformCssValue(positionMenu, '.1s'));
}

function getMenuPosition() {
    positionMenu -= touchLastPositionX - touchCurrentPositionX;
    positionMenu = Math.min(positionMenu, 0);
    positionMenu = Math.max(positionMenu, -widthMenu);
}

function setLastPositionIfNeeded() {
    if (!touchLastPositionX) {
        touchLastPositionX = touchStartPositions.x - minPixelToStartAnimation;
    }
}

function shouldMoveMenu(event) {
    return hasMoveOnXEnough(event) && !hasMovedTooMuchOnYDuringInit(event);
}

function hasMoveOnXEnough(event) {
    return getMovementLengthFromStart(event, 'x') > minPixelToStartAnimation;
}

function hasMovedTooMuchOnYDuringInit(event) {
    return getMovementLengthFromStart(event, 'y') > maxPixelToMoveOnYToSkipAnimation && touchLastPositionX == null;
}

function getMovementLengthFromStart(event, axe) {
    return touchStartPositions[axe] - getTouchPosition(event)[axe];
}

function getTouchPosition(event) {
    return {
        x: Math.round(event.originalEvent.touches[0].clientX),
        y: Math.round(event.originalEvent.touches[0].clientY),
    };
}

function touchEnd(onCloseMenu) {
    if (isAnimated) {
        return;
    }
    const shouldCloseTheMenu = positionMenu < (-widthMenu * 0.5);
    if (shouldCloseTheMenu) {
        closeMenu(onCloseMenu);
    } else {
        reopenMenu();
    }
}

function closeMenu(onCloseMenu) {
    $menu.css(getTransformCssValue());
    onCloseMenu();
}

function reopenMenu() {
    isAnimated = true;
    $menu.css(getTransformCssValue(0, '.3s'));
    setTimeout(function () {
        isAnimated = false;
        $menu.css(getTransformCssValue());
    }, 300);
}

function getTransformCssValue(value, duration = '') {
    const cssTranslateValue = value ? `translateX(${positionMenu}px)` : '';
    return {
        '-webkit-transform': cssTranslateValue,
        '-moz-transform': cssTranslateValue,
        transform: cssTranslateValue,
        'transition-duration': duration,
    };
}
