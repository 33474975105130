const RealEstateSubmissionPage = require('./RealEstateSubmissionPage');

module.exports = class RealEstateSellSubmissionPage extends RealEstateSubmissionPage {
    constructor() {
        super({
            transactionType: 'sell',
        });
    }

    getUrlPattern() {
        return '/vendre-mon-bien';
    }
};
