const template = require('./MandatoryFieldsExplanation.jade');
const i18nMixin = require('../mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'MandatoryFieldsExplanation.',
        }),
    ],
    props: {
        short: Boolean,
    },
    computed: {
        explanation() {
            return this.t('explanation', {context: this.short ? 'short' : null});
        },
    },
    template: template(),
};
