const {i18n: {translate}} = require('fack');
const template = require('./PasswordInput.jade');

// @vue/component
module.exports = {
    props: {
        value: {
            type: String,
            default: undefined,
        },
        required: Boolean,
    },
    methods: {
        t: translate,
        accountFormTranslate(key) {
            return translate('accountForm.' + key);
        },
    },
    template: template(),
};
