module.exports =
    {
        bus: {
            tags: [
                {
                    tag: 'LigneDeBus',
                    polyline: {
                        strokeWeightMin: {zoom: 15, weight: 1},
                        strokeWeightMax: {zoom: 18, weight: 3},
                        strokeColor: '#0dabd0',
                    },
                    minZoom: 15,
                },
                {
                    tag: 'trolleybus_line',
                    polyline: {
                        strokeWeightMin: {zoom: 15, weight: 1},
                        strokeWeightMax: {zoom: 18, weight: 3},
                        strokeColor: '#0dabd0',
                    },
                    minZoom: 15,
                },
            ],
            name: 'Bus',
            selected: false,
        },

        metro: {
            tags: [
                {
                    tag: 'LigneDeMétro',
                    polyline: {
                        strokeWeightMin: {zoom: 13, weight: 1},
                        strokeWeightMax: {zoom: 18, weight: 10},
                        strokeColor: '#0dabd0',
                    },
                    minZoom: 13,
                },
            ],
            name: 'Métro',
            selected: false,
        },

        rer: {
            tags: [
                {
                    tag: 'LigneDeRER',
                    polyline: {
                        strokeWeightMin: {zoom: 11, weight: 1},
                        strokeWeightMax: {zoom: 18, weight: 8},
                        strokeColor: '#0dabd0',
                    },
                    minZoom: 11,
                },
            ],
            name: 'RER',
            selected: false,
        },

        tramway: {
            tags: [
                {
                    tag: 'LigneDeTramway',
                    polyline: {
                        strokeWeightMin: {zoom: 13, weight: 1},
                        strokeWeightMax: {zoom: 18, weight: 8},
                        strokeColor: '#0dabd0',
                    },
                    minZoom: 13,
                },
            ],
            name: 'Tramway',
            selected: false,
        },
    };
