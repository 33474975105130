const _ = require('lodash');

const {humanizeDayLocale: formattedDate} = require('../../../common/formatters/DateFormatter');
const template = require('./SpecialOffer.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'SpecialOffer.',
            keys: [
                'title',
                'duration',
                'seeMoreButtonText',
            ],
        }),
    ],
    props: {
        offer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formattedDescriptionWithLegalNotice() {
            const {description, legalNotice} = this.offer;
            if (description && legalNotice) {
                return formattedText(description) + '\n\n' + formattedText(legalNotice);
            } else if (legalNotice) {
                return '<br>' + formattedText(legalNotice);
            } else {
                return description;
            }
        },
        durationText() {
            const {startDate, endDate} = this.offer;
            const translationContext = {};

            if (startDate && endDate) {
                translationContext.fromDate = formattedDate(startDate);
                translationContext.toDate = formattedDate(endDate);
            } else if (startDate) {
                translationContext.context = 'startDateOnly';
                translationContext.fromDate = formattedDate(startDate);
            } else {
                translationContext.context = 'endDateOnly';
                translationContext.toDate = formattedDate(endDate);
            }
            return this.t('duration', translationContext);
        },
        hasToDisplayName() {
            const {name, title} = this.offer;
            return Boolean(name) && (name !== title);
        },
    },
    template: template(),
};

function formattedText(specialOfferText) {
    return _.trim(specialOfferText);
}
