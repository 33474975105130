module.exports = {
    pro: {
        senderTypes: {
            agency: {
                subjects: [
                    'publishAds',
                    'beingContactedByCommercialTeam',
                    'billingQuestion',
                    'askingForPropositionInfos',
                    'technicalIssuePro',
                    'other',
                ],
            },
            propertyManager: {
                subjects: [
                    'publishAds',
                    'beingContactedByCommercialTeam',
                    'billingQuestion',
                    'askingForPropositionInfos',
                    'technicalIssuePro',
                    'other',
                ],
            },
            developer: {
                subjects: [
                    'publishAds',
                    'beingContactedByCommercialTeam',
                    'billingQuestion',
                    'documentationApplication',
                    '3dModelApplication',
                    'askingForPropositionInfos',
                    'technicalIssuePro',
                    'other',
                ],
            },
            other: {
                contactUs: {
                    subjects: [
                        'offerPartnership',
                        'other',
                    ],
                },
            },
        },
    },
    public: {
        senderTypes: {
            individual: {
                subjects: [
                    'askingQuestion',
                    'technicalIssue',
                    'suggestAgency',
                    'other',
                ],
            },
            press: {
                contactUs: {
                    subjects: [
                        'contactCommunicationTeam',
                        'askingToDoAReport',
                    ],
                },
                opinion: {
                    subjects: [
                        'contactCommunicationTeam',
                    ],
                },
            },
            other: {
                contactUs: {
                    subjects: [
                        'offerPartnership',
                        'other',
                    ],
                },
                opinion: {
                    subjects: [
                        'contactUs',
                    ],
                },
            },
        },
    },
};
