const _ = require('lodash');
const template = require('./ErrorMessage.jade');

// @vue/component
module.exports = {
    props: {
        error: {
            default: null,
            type: [String, Error],
        },
    },
    computed: {
        errorMessage() {
            const {error} = this;
            if (_.isError(error)) {
                return error.message;
            } else {
                return error;
            }
        },
    },
    template: template(),
};
