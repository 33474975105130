const _ = require('lodash');
const MapApi = require('./MapApi');
const onecolor = require('onecolor');

const ProgrammeLotsModelsLoader = require('./api/ProgrammeLotsModelsLoader');
const EventPack = require('./utils/EventPack');
const {
    blue: BLUE,
    red: RED,
    yellow: YELLOW,
} = require('./colors');

const MIN_DISTANCE_TO_LOAD = 1000;
const NOT_AVAILABLE_COLOR = RED;
const ON_THE_MARKET_COLOR = YELLOW;
const SELECTED_COLOR = BLUE;
const HIGHLIGHTED_COLOR = BLUE;

module.exports = class ProgrammeModels {
    constructor(programmeModelsManager, {model, facadeCameras, facadeCamerasId, map}) {
        this._programmeModelsManager = programmeModelsManager;
        this._model = model;
        this._modelName = getModelName(model);
        this._facadeCameras = facadeCameras;
        this._facadeCamerasId = facadeCamerasId;
        this.programIds = model.programIds;
        this._facadeVisibilityEnabled = false;
        this._lots = {};
        this._facadesVisible = false;
        this._isSelected = false;
        this._selectedLotAdId = null;
        this._highlightedLotAdId = null;
        this._facadeCameraEventPack = new EventPack();
        this._mapEventPack = new EventPack();
        this.setMap(map);
    }

    getFacadeCameras() {
        return this._facadeCameras;
    }

    getFacadeCamerasId() {
        return this._facadeCamerasId;
    }

    showFacadeWithDelay() {
        this._showLotsFacades();
        this._stopFacadeDelay();
        this._setFacadesVisible(true);
        this._facadeDelayTimeout = setTimeout(_.bind(this._stopFacadeDelay, this), 1000);
    }

    _stopFacadeDelay() {
        if (this._facadeDelayTimeout) {
            clearTimeout(this._facadeDelayTimeout);
            this._facadeDelayTimeout = null;
        }
        this._setFacadesVisible(false);
    }

    _setFacadeVisibilityEnabled(isVisibilityEnabled) {
        if (this._facadeVisibilityEnabled != isVisibilityEnabled) {
            this._facadeVisibilityEnabled = isVisibilityEnabled;
            this._setFacadesVisible(isVisibilityEnabled);
        }
    }

    setMap(map) {
        if (this.map != map) {
            this.map = map;
            if (this._programmeLotsModelsLoader) {
                this._programmeLotsModelsLoader.setMap(map);
            }

            this._mapEventPack.removeAllListeners();

            if (this.map && this._isSelected) {
                this._showLotsFacades();
                this._onCameraChanged();
                this._mapEventPack.on(this.map, {
                    idle: () => {
                        this._onCameraChanged();
                    },
                });
            } else {
                this._hideLotsFacades();
            }
        }
    }

    _onCameraChanged() {
        const distance = MapApi.api.geometry.spherical.computeDistanceBetween(this.map.getCenter(),
            MapApi.api.LatLng.from900913(this._model.lat, this._model.lon));
        if (distance <= MIN_DISTANCE_TO_LOAD) {
            this.showFacadeWithDelay();
        }
    }

    _loadLots(programme) {
        if (!this._allLotsHaveBeenRequested) {
            this._programmeLotsModelsLoader = new ProgrammeLotsModelsLoader(programme.id, this._modelName);
            this._programmeLotsModelsLoader.setMap(this.map);
            this._programmeLotsModelsLoader.load((err, lots) => {
                if (err) {
                    console.error('Could not load lots', err);
                } else {
                    this._onAllLotsLoaded(programme, lots);
                }
            }, (lotInfo) => {
                //only keep lots in relatedAdsIds
                return _.find(programme.relatedAdsIds, {id: lotInfo.id});
            });
            this._allLotsHaveBeenRequested = true;
        } else {
            this._afterLotsLoaded();
        }
    }

    _afterLotsLoaded() {
        if (this._isSelected) {
            const hasModels = this._programmeLotsModelsLoader.hasModels();
            this._programmeModelsManager.getFacadeCameraControllerView().toggleBtnVisibleLots(hasModels);
            this._updateLots();
            this.showFacadeWithDelay();
        } else {
            this._hideLotsFacades();
        }
    }

    _hideLotsFacades() {
        _.each(this._lots, lot => {
            lot.setMap(null);
        });
    }

    _showLotsFacades() {
        _.each(this._lots, lot => {
            lot.setMap(this.map);
        });
    }

    unload() {
        this.setMap(null);
        this._lots = {};
        this._allLotsHaveBeenRequested = false;
    }

    _onAllLotsLoaded(programme, lots) {
        lots = _.filter(lots, 'id'); // looks like we're receiving a bogus entry at the end, find out what it is and remove it
        _.each(lots, lot => {
            const adInfo = _.find(programme.relatedAdsIds, {id: lot.id});
            this._lots[lot.id] = lot;
            const ad = _.extend({
                id: lot.id,
                programme,
                isLot: true,
            }, this.getLotPositionAndHeightFromModel(lot));
            lot.setClickable(true);
            lot.onTheMarket = adInfo.onTheMarket;
            lot.programme = programme; // why ?
            lot.ad = ad;
            lot.on('click', event => {
                this._programmeModelsManager.emit('lotClick', ad, event);
                this._setSelectedLot(lot, true);
            });
            lot.on('mouseover', () => {
                this._programmeModelsManager.setLotHovered(ad, true);
            });
            lot.on('mouseout', () => {
                this._programmeModelsManager.setLotHovered(ad, false);
            });
            this._programmeModelsManager.emit('lotModelLoaded', ad);
        });
        this._afterLotsLoaded();
    }

    setSelectedLotAdId(lotRealEstateAdId, selected) {
        const lot = this._lots[lotRealEstateAdId];
        if (lot) {
            this._setSelectedLot(lot, selected);
        } else {
            this._unselectLot();
            this._selectedLotAdId = lotRealEstateAdId;
            //color will be applied automatically upon loading
            this._updateLots();
        }
    }

    _setSelectedLot(lot, selected) {
        if (selected) {
            this._selectLot(lot);
        } else {
            this._unselectLot();
        }
    }

    setSelectedProgramme(programme) {
        if (programme && this.hasProgramme(programme.id)) {
            if (!this._isSelected) {
                this._isSelected = true;
                this._loadLots(programme);
                this._facadeCameraEventPack.on(this._programmeModelsManager.getFacadeCameraControllerView(), {
                    selectFacade: () => {
                        this.showFacadeWithDelay();
                    },
                    facadeVisibilityChanged: (enabled) => {
                        this._setFacadeVisibilityEnabled(enabled);
                    },
                });
                this._facadeVisibilityEnabled = false;
                const forcedLotsVisibility =
                    this._programmeModelsManager.getFacadeCameraControllerView().getUserForceLotsVisibility();
                this._setFacadeVisibilityEnabled(forcedLotsVisibility);
            }
        } else if (this._isSelected) {
            this._isSelected = false;
            this.setSelectedLotAdId(null);
            this._hideLotsFacades();
            this._facadeCameraEventPack.removeAllListeners();
            this._mapEventPack.removeAllListeners();
        }
    }

    _updateLot(lot, fade = false) {
        const color = this._getLotColor(lot);
        if (fade) {
            lot.fadeTo(color);
        } else {
            lot.setColor(color);
        }
    }

    _isLotHighlighted(lot) {
        return this._highlightedLotAdId == lot.id;
    }

    _isLotSelected(lot) {
        return this._selectedLotAdId == lot.id;
    }

    _selectLot(lot) {
        if (this._selectedLotAdId != lot.id) {
            this._unselectLot();
            this._selectedLotAdId = lot.id;
            // ensure to not play anim loop when going back to programme
            this._updateLot(lot);
            //unhighlight lot if there was one (most probably the one just selected)
            this.setLotHovered(lot, false);
        }
    }

    _unselectLot() {
        if (this._selectedLotAdId) {
            const lot = this._lots[this._selectedLotAdId];
            delete this._selectedLotAdId;
            if (lot) {
                this._updateLot(lot);
            }
        }
    }

    _updateLots() {
        _.each(this._lots, lot => {
            this._updateLot(lot, true);
        });
    }

    _getLotColor(lot) {
        let oColor;
        if (this._isLotSelected(lot)) {
            oColor = onecolor(SELECTED_COLOR);
        } else if (this._isLotHighlighted(lot)) {
            oColor = onecolor(HIGHLIGHTED_COLOR);
        } else if (lot.onTheMarket) {
            oColor = onecolor(ON_THE_MARKET_COLOR);
        } else {
            oColor = onecolor(NOT_AVAILABLE_COLOR);
        }
        const isLotFacadeVisible = this._facadesVisible || this._isLotHighlighted(lot) || this._isLotSelected(lot);
        oColor = oColor.alpha(isLotFacadeVisible ? 0.6 : 0.0);
        return oColor.cssa();
    }

    getModelHeight() {
        return _.get(this._model, 'modelBoundingBox.maxZ');
    }

    _highlightLot(lot) {
        if (this._highlightedLotAdId != lot.id) {
            this._unhighlightLot();
            this._highlightedLotAdId = lot.id;
            this._updateLot(lot);
        }
    }

    _unhighlightLot() {
        if (this._highlightedLotAdId) {
            const lot = this._lots[this._highlightedLotAdId];
            delete this._highlightedLotAdId;
            if (lot) {
                this._updateLot(lot);
            }
        }
    }

    setLotHovered(realEstateAd, hovered) {
        const lot = this._lots[realEstateAd.id];
        if (lot && hovered) {
            this._highlightLot(lot);
            this._programmeModelsManager.onLotMouseOver(lot.ad);
            this.showFacadeWithDelay();
        } else {
            const previousLot = this._lots[this._highlightedLotAdId];
            this._unhighlightLot();
            if (previousLot) {
                this._programmeModelsManager.onLotMouseOut(previousLot.ad);
            }
        }
    }

    hasProgramme(programmeId) {
        return _.includes(this.programIds, programmeId);
    }

    hasLot(lotId) {
        return (this._lots[lotId] != null);
    }

    hasLotModel(lotId) {
        const lot = this._lots[lotId];
        return _.get(lot, 'facadeModel3d') || _.get(lot, 'interiorModel3d');
    }

    getModel() {
        return this._model;
    }

    getLotPositionAndHeightFromModel(realEstateAd) {
        const lot = this._lots[realEstateAd.id];
        if (lot) {
            return {
                position: {
                    lat: lot.lat,
                    lng: lot.lon,
                },
                height: lot.height,
            };
        }
    }

    _setFacadesVisible(visible) {
        visible = visible || this._facadeVisibilityEnabled;
        if (this._facadesVisible != visible) {
            this._facadesVisible = visible;
            this._updateLots();
        }
    }
};

function getModelName({modelPath}) {
    return _.last(modelPath.split('/')).replace('.bis', '');
}
