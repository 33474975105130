const _ = require('lodash');
const urlUtil = require('url');
const querystring = require('querystring');

const UrlFormatter = require('../common/UrlFormatter');
const DefaultConfiguration = require('../common/DefaultConfiguration');
const UrlHelper = require('../common/UrlHelper');
const {i18n: {translate}} = require('fack');
const SearchUrl = require('../common/SearchUrl');
const LocationsParser = require('../common/LocationsParser');
const formContactUsUrl = require('./formContact/FormContactUsUrl');
const savedOptions = require('./Options').read();

module.exports = {
    pinel: {
        pattern: UrlFormatter.getPinelPattern(),
        parseUrl: parseLoanSimulatorUrl,
        makeUrl: makePinelUrl,
    },
    ptz: {
        pattern: UrlFormatter.getPtzPattern(),
        parseUrl: parseLoanSimulatorUrl,
        makeUrl: makePtzUrl,
    },
    detailedSheet: {
        pattern: UrlFormatter.getDetailedSheetRegexp(),
        parseUrl: parseDetailedSheetUrl,
        makeUrl: makeDetailedSheetUrl,
    },
    search: {
        getPattern: UrlFormatter.getSearchPattern,
        parseUrl: parseSearchUrl,
        makeUrl: makeSearchUrl,
    },
    myContacts: {
        makeUrl: makeMyContactsUrl,
    },
    savedSearch: {
        makeUrl: makeSavedSearchUrl,
    },
    json: {
        makeUrlParams: makeJsonUrlParams,
        parseUrlParams: parseJsonUrlParams,
    },
    userSearch: {
        parseUrl: parseUserSearchUrl,
        makeUrl: makeUserSearchUrl,
    },
    suggestion: {
        parseUrl: parseSuggestionUrl,
        makeUrl: makeSuggestionUrl,
    },
    formContact: {
        parseUrl: url => urlUtil.parse(url, true).query,
    },
    formContactUs: {
        parseUrl: formContactUsUrl.parseUrl,
    },
    zoho: {
        makeZohoAccountUrl,
    },
    homePage: {
        makeUrl: makeHomePageUrl,
    },
    savedSearches: {
        parseUrl: url => urlUtil.parse(url, true).query,
    },
    estimationLandingPage: {
        parseUrl: parseEstimationLandingPageUrl,
    },
    agencyInfoPage: {
        makeAgencyInfoPageUrl,
    },
};

function makeZohoAccountUrl(zohoId) {
    return 'https://crm.zoho.com/crm/EntityInfo.do?module=Accounts&id=' + zohoId;
}

function makePinelUrl({simulatorData}) {
    return makeLoanSimulatorUrl(UrlFormatter.getPinelPattern(), simulatorData);
}

function makePtzUrl({simulatorData}) {
    return makeLoanSimulatorUrl(UrlFormatter.getPtzPattern(), simulatorData);
}

function makeLoanSimulatorUrl(pattern, simulatorData) {
    return `/${pattern}${makeJsonUrlParams(simulatorData)}`;
}

function parseLoanSimulatorUrl(url) {
    const loc = urlUtil.parse(url);
    const query = loc.search;
    const simulatorData = query ? querystring.parse(query.substring(1)) : {};
    return _.mapValues(simulatorData, value => +value);
}

function parseEstimationLandingPageUrl(url) {
    const loc = urlUtil.parse(url);
    const pathName = loc.pathname;
    let propertyTypePreselection;
    if (/appartement$/.test(pathName)) {
        propertyTypePreselection = 'flat';
    } else if (/maison$/.test(pathName)) {
        propertyTypePreselection = 'house';
    }
    return {
        propertyTypePreselection,
    };
}

function parseDetailedSheetUrl(url) {
    const loc = urlUtil.parse(url);
    const pathname = loc.pathname;
    const match = UrlFormatter.getDetailedSheetRegexp().exec(pathname);
    let searchPart = null;
    if (loc.search) {
        searchPart = loc.search.substring(1);
    }
    let camera;
    let inHighlightedBox = false;
    let inLeadingAdsBox = false;
    let trackingOrigin;
    let fromSuggestion;
    let fromSavedSearchId;
    let closePageUrl;
    if (searchPart) {
        const parsedSearch = querystring.parse(searchPart);
        if (parsedSearch.camera) {
            camera = UrlFormatter.cameraFromUrl(parsedSearch.camera);
        }
        if (parsedSearch['depuis-mise-en-avant'] == 'oui') {
            inHighlightedBox = true;
            inLeadingAdsBox = true;
        }
        fromSuggestion = parsedSearch['depuis-suggestion'];
        const trackingInfo = UrlHelper.getURLTrackingParameters(url);
        if (!_.isEmpty(trackingInfo)) {
            trackingOrigin = trackingInfo;
        }
        if (parsedSearch.fromSavedSearchId) {
            fromSavedSearchId = parsedSearch.fromSavedSearchId;
            closePageUrl = makeSavedSearchUrl({_id: fromSavedSearchId});
        } else {
            closePageUrl = parsedSearch.q;
        }
    }

    const actionParameter = match[3];
    const setAsLeading = actionParameter === 'mettre-a-la-une' ? true : null;
    const scrollTo = (actionParameter && !setAsLeading) ? actionParameter : '';

    return {
        realEstateAdId: decodeURIComponent(match[2]),
        detailedSheetUrlExtra: match[1] || '',
        scrollTo,
        setAsLeading,
        closePageUrl,
        camera,
        context: {
            inHighlightedBox,
            inLeadingAdsBox,
            trackingOrigin,
            fromSuggestion,
        },
        fromSavedSearchId,
    };
}

/**
 *
 * @param {object} realEstateAd
 * @param {string} closePageUrl
 * @param {string} [detailedSheetScrollTo]
 * @param {object} [camera]
 * @param {object} [context]
 * @param {boolean} [context.inHighlightedBox]
 * @param {object} [context.trackingOrigin]
 * @returns {string}
 */
function makeDetailedSheetUrl(realEstateAd, closePageUrl, detailedSheetScrollTo, camera, context) {
    realEstateAd = makeDetailedSheetUrlExtra(realEstateAd);
    let detailedSheetUrl = '/annonce/';
    detailedSheetUrl += realEstateAd.detailedSheetUrlExtra + '/';
    detailedSheetUrl += encodeURIComponent(realEstateAd.id);
    if (detailedSheetScrollTo) {
        detailedSheetUrl += '/' + detailedSheetScrollTo;
    }

    const query = {};
    if (camera) {
        query.camera = UrlFormatter.cameraToUrl(camera);
    }
    if (closePageUrl) {
        if (context && context.fromSuggestion && closePageUrl.indexOf('/suggestion') == 0) {
            const options = parseSuggestionUrl(closePageUrl);
            query.q = options.closePageUrl;
        } else {
            query.q = closePageUrl;
        }
    }
    if (context) {
        if (context.inHighlightedBox) {
            query['depuis-mise-en-avant'] = 'oui';
        }
        if (context.trackingOrigin) {
            _.extend(query, context.trackingOrigin);
        }
        if (context.fromSuggestion) {
            query['depuis-suggestion'] = context.fromSuggestion;
        }
    }

    return urlUtil.format({
        pathname: detailedSheetUrl,
        query,
    });
}

function makeDetailedSheetUrlExtra(realEstateAd) {
    const url = [];
    if (realEstateAd.adType) {
        url.push(UrlHelper.slugify(translate('enum.adType.' + realEstateAd.adType)));
    }
    if (realEstateAd.city) {
        url.push(UrlHelper.slugify(realEstateAd.city));
    } else if (realEstateAd.postalCode) {
        url.push(UrlHelper.slugify(realEstateAd.postalCode));
    }
    if (realEstateAd.propertyType) {
        url.push(UrlHelper.slugify(translate('enum.propertyType.' + realEstateAd.propertyType)));
    }
    if (realEstateAd.roomsQuantity) {
        url.push(realEstateAd.roomsQuantity + (realEstateAd.roomsQuantity > 1 ? 'pieces' : 'piece'));
    }
    realEstateAd.detailedSheetUrlExtra = url.join('/');
    return realEstateAd;
}

function parseSearchUrl(url, prefix) {
    return SearchUrl.parseSearchLocation(urlUtil.parse(url, true), prefix);
}

//it looks like options and defaultOptions are not in the same format somehow...
function makeSearchUrl(options, defaultOptions, prefix) {
    defaultOptions = defaultOptions || DefaultConfiguration.search;
    defaultOptions.extensionType = 'extendedIfNoResult';
    return SearchUrl.makeSearchUrl(options, defaultOptions, prefix);
}

function makeMyContactsUrl() {
    return '/mes-contacts';
}

function makeSavedSearchUrl({_id: id, saveName: name}, {camera, page} = {}) {
    const slugName = name ? UrlHelper.slugify(name) : '';
    return '/mon-alerte/' + id + '/' + slugName + makeJsonUrlParams({
        page: (page !== 1) && page,
        camera: camera && UrlFormatter.cameraToUrl(camera),
    });
}

function makeJsonUrlParams(data) {
    const params = _.omitBy(data, value => !value);
    return _.isEmpty(params) ? '' : '?' + querystring.stringify(params);
}

function parseJsonUrlParams(url) {
    const loc = urlUtil.parse(url);
    return querystring.parse(loc.query);
}

function parseUserSearchUrl(url) {
    const state = parseJsonUrlParams(url);
    const locations = state.lieu;
    const currentPage = state.page;
    const search = state.qui;
    const searchCriteria = _.omit(state, ['lieu', 'page', 'qui']);
    if (locations) {
        searchCriteria.locationNames = LocationsParser.parse(locations);
    }
    if (currentPage) {
        searchCriteria.currentPage = +currentPage;
    }
    if (search) {
        searchCriteria.search = search;
    }
    return searchCriteria;
}

function makeUserSearchUrl(searchCriteria) {
    const state = _.omit(searchCriteria, 'access_token', 'locations', 'currentPage', 'search');
    if (searchCriteria.search) {
        state.qui = searchCriteria.search;
    }
    state.lieu = _.map(searchCriteria.locations, location => UrlHelper.slugify(location.getUrlValue())).join(',');
    if (!state.lieu && !_.isEmpty(searchCriteria)) {
        delete state.lieu;
    }
    if (searchCriteria.currentPage != 1) {
        state.page = searchCriteria.currentPage;
    }
    return '/agences-immobilieres' + makeJsonUrlParams(state);
}

function parseSuggestionUrl(url, realEstateAdId) {
    const loc = urlUtil.parse(url);
    const query = loc.search;
    const parsedQuery = query ? querystring.parse(query.substring(1)) : {};
    return {
        realEstateAdId,
        closePageUrl: parsedQuery.q && decodeURIComponent(parsedQuery.q),
        camera: parsedQuery.camera && UrlFormatter.cameraFromUrl(parsedQuery.camera),
    };
}

function makeSuggestionUrl(id, closePageUrl, camera) {
    return '/suggestion/' + encodeURIComponent(id) + makeJsonUrlParams({
        q: closePageUrl,
        camera: camera && UrlFormatter.cameraToUrl(camera),
    });
}

function makeHomePageUrl() {
    return savedOptions.isInEmbeddedMode ? location.href : '/';
}

function makeAgencyInfoPageUrl(agency) {
    const accountTypeUrl = translate(`userDirectory.accountTypeUrl.${agency.accountType}`);
    return `/${accountTypeUrl}-${agency.id}`;
}
