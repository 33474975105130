const semver = require('semver');
const _ = require('lodash');

const versionsMapping = {
    'loginFacebook': '1.53.2786',
    'loginGoogle': '1.53.2786',
    'adSharing': '1.61.0',
};

module.exports = {
    isEnabledFeatureForVersion,
};

function getAndroidAppVersion(ua = navigator.userAgent) {
    return /android-(.*)/.exec(ua)[1];
}

function isEnabledFeatureForVersion(featureName, userAgent) {
    const version = getAndroidAppVersion(userAgent);
    return isDebugVersion(version) || isVersionHigherOrEqual(version, getFeatureVersion(featureName));
}

function getFeatureVersion(featureName) {
    return versionsMapping[featureName];
}

function isVersionHigherOrEqual(currentVersion, version) {
    return semver.gte(currentVersion, version);
}

function isDebugVersion(version) {
    return _.last(versionToArray(version)) === 1;
}

function versionToArray(version) {
    return version.split('.').map(Number);
}
