const _ = require('lodash');
const template = require('./FavoriteToggleButton.jade');
const RealtimeServer = require('../../RealtimeServer');
const Views = require('../../views/Views');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {
    emitAdFollowerToggleEvent,
    isAdFavorite,
} = require('../index');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'FavoriteToggleButton.',
            keys: [
                'add',
                'remove',
                'error_follow',
                'error_unfollow',
            ],
        }),
    ],
    props: {
        realEstateAdId: {
            type: String,
            required: true,
        },
        showToggleText: Boolean,
        noPadding: Boolean,
    },
    data() {
        return {
            animationInProgress: false,
        };
    },
    computed: {
        isSet() {
            return isAdFavorite(this.realEstateAdId);
        },
        text() {
            const {add, isSet, remove} = this;
            return isSet ? remove : add;
        },
        iconClass() {
            return 'favorite' + (this.isSet ? '' : '-off');
        },
        statusModifiers() {
            return _.pick(this, ['isMobile', 'isSet', 'noPadding']);
        },
        animationEndElement() {
            // todo: when this class is in a Vue component, use ref(in Vue store ?) instead of querySelector to target element,
            //  otherwise Vue will destroy and rebuild DOM and lose connection to targeted class
            return document.querySelector('.userInfoPict');
        },
    },
    mounted() {
        RealtimeServer.on('ad:follower:toggle:success', ({id: realEstateAdIdFromEvent}) => {
            if (realEstateAdIdFromEvent === this.realEstateAdId) {
                this.requestInProgress = false;
            }
        });
        RealtimeServer.on('ad:follower:toggle:error', () => {
            const {requestInProgress, isSet} = this;
            if (requestInProgress) {
                this.requestInProgress = false;
                const errorText = this.t('error', {context: isSet ? 'unfollow' : 'follow'});
                Views.volatileFeedback.showError(errorText);
            }
        });
    },
    methods: {
        sendAdFollowerEvent() {
            const {realEstateAdId, isSet} = this;
            emitAdFollowerToggleEvent(realEstateAdId, !isSet, err => {
                if (err) {
                    console.error(err, 'Could not emit ad follower toggle event');
                } else {
                    this.animationInProgress = true;
                    this.requestInProgress = true;
                }
            });
        },
        setAnimationEnd() {
            this.animationInProgress = false;
        },
    },
    template: template(),
};
