const _ = require('lodash');
const ContactHelper = require('../utils/ContactHelper');
const contactFields = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'postalCode',
];

const optIns = ContactHelper.getDefaultApprovalQuestions();

module.exports = {
    areContactDataSetAndOptInsChecked,
};

function areContactDataSetAndOptInsChecked() {
    return areContactDataSet() && areOptInsChecked();
}

function areContactDataSet() {
    return areUserInfosTruthy(contactFields);
}

function areOptInsChecked() {
    return areUserInfosTruthy(optIns);
}

function areUserInfosTruthy(userInfoList) {
    const userInfo = ContactHelper.getUserInfo();
    return _.every(userInfoList, key => userInfo[key]);
}
