const AgencyMap = require('../../agency/components/AgencyMap');
const template = require('./AgencyPageMap.jade');

// @vue/component
module.exports = {
    components: {
        AgencyMap,
    },
    constants: {
        MAP_ZOOM: 17,
        PAGE_NAME: 'agencyPage',
    },
    props: {
        agency: {
            type: Object,
            required: true,
        },
        useKarto: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mapLoaded: false,
            displayAgencyMap: false,
        };
    },
    computed: {
        agencyMapOptions() {
            const mapOptions = this.useKarto ? {
                cooperativeGestures: true,
            } : {
                scrollwheel: false,
                draggableTouch: false,
                ui: {
                    enableGeoloc: false,
                },
            };
            return {
                ...mapOptions,
                zoom: this.useKarto ? this.MAP_ZOOM : this.MAP_ZOOM + 1,
            };
        },
        agencyMapModifiers() {
            return {
                container: this.agency.position && this.mapLoaded,
            };
        },
    },
    methods: {
        handleMapCreated(map) {
            if (!this.useKarto) {
                map.setEnabled(true);
            } else {
                // fixes resizing issue on Safari browser
                this.$nextTick(() => {
                    map.resize();
                });
            }
            this.mapLoaded = true;
        },
        handleHasPosition() {
            this.displayAgencyMap = true;
        },
    },
    template: template(),
};
