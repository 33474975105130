const _ = require('lodash');
const util = require('util');
const {i18n: {translate}} = require('fack');
const MapApi = require('../MapApi');
const async = require('async');
const ServerConfig = require('../ServerConfig');
const LatLng = MapApi.api.LatLng;
const Marker = MapApi.api.Marker;
const Polyline = MapApi.api.Polyline;
const TreeLayer = MapApi.api.TreeLayer;
const Size = MapApi.api.Size;
const ngeohash = require('ngeohash');
const BrowserDetect = require('browser-detect');
const Account = require('../authentication/Account');
const isValidPosition = require('../utils/isValidPosition');
const LimitHelper = require('../LimitHelper');
const PolygonZIndex = require('../utils/PolygonZIndex');
const EventPack = require('../utils/EventPack');
const {IMPORT_ACCOUNT_TYPE, NETWORK_ACCOUNT_TYPE} = require('../../common/AccountTypes');

module.exports = PoiLayer;

util.inherits(PoiLayer, TreeLayer);

const proto = PoiLayer.prototype;
const _super = TreeLayer.prototype;

// todo: replace  in map-extra POILayer with this compatible File
const SHADOW_COLOR = '#ffffff';
const SHADOW_OPACITY = 0.5;
const SHADOW_WEIGHT = 4;

function PoiLayer(options) {
    this._clickEnabled = true;
    this._type = 'pois';
    options = _.extend({
        refreshPeriod: 100,
        tags: options.tags, // old poiConfigs
        server: ServerConfig.config.poiUrl,
        hideTitle: true,
        maxDistance: 7000,
        featureRequestConf: {
            priority: 5,
            workOnMove: Boolean(options.workOnMove),
        },
    }, options || {});
    this.options = options;
    if (options.poiAgencyConfig) {
        this.agencyConfig = options.poiAgencyConfig.agency;
    }
    this.map = options.map;
    this.tilePois = [];
    this.workOnMove = this.options.featureRequestConf.workOnMove;
    this._events = new EventPack();
    this._tileEvents = new EventPack();
    this._mapEvents = new EventPack();
    TreeLayer.call(this, options);
    this.tagConfigs = options.tags || [];
    this._events.on(this, 'tile', _.bind(this._handleTile, this));
    this._titledMarkers = {};
    this._weightedLines = {};
    this._tilesToBeDeleted = [];
    this._tilesToBeLoaded = [];
    this._tilesToBeCreated = [];
    this._poiById = {};
    this._poiAgencyById = {};
    this.setTags(options.tags);
}

proto.canWork = function () {
    if (!_super.canWork.call(this)) {
        return false;
    }
    if (this.workOnMove) {
        return true;
    }
    return this.map._renderer && !this.map._renderer.isMoving();
};

proto.setTags = function (tags) {
    this.tagConfigs = tags;
    this.configTagList = _.map(tags, 'tag');
    this.forEach((tile) => {
        if (tile.active) {
            this.onActivated(tile);
        }
    });
    this.update();
};

proto._getOptionsForPoiRequest = function (tile, tags, onErrorCallback, onSuccessCallback) {
    const center = LatLng.from900913(tile.center.y, tile.center.x);
    const nw = LatLng.from900913(tile.bounds.yMax, tile.bounds.xMin);
    const se = LatLng.from900913(tile.bounds.yMin, tile.bounds.xMax);
    const intersection = 'POLYGON((' + [
        nw.lng() + ' ' + nw.lat(),
        se.lng() + ' ' + nw.lat(),
        se.lng() + ' ' + se.lat(),
        nw.lng() + ' ' + se.lat(),
        nw.lng() + ' ' + nw.lat(),
    ].join(',') + '))';
    return {
        params: {
            onlyWay: this.options.onlyWay,
            onlyCentroid: this.options.onlyCentroid,
            size: this.options.size || 64,
            tags,
            lon: center.lng(),
            lat: center.lat(),
            intersection,
        },
        groupPostProcess: true,
        server: this.options.server,
        fullQueryString: 'tagsAndName',
        onError: onErrorCallback,
        postProcessData: onSuccessCallback,
        priority: this.options.featureRequestConf.priority,
        bounds: tile.bounds,
        center: tile.center,
        tileKey: tile.id,
        noStringify: true,
    };
};

proto._createRequestPoi = function (tile) {
    const zoomConfigs = this.filterConfigsForZoom(tile.zoom);
    const tags = _.map(zoomConfigs, 'tag').join(',');
    const hasTags = tags && tags.length;
    if (hasTags) {
        tile.zoomConfigs = zoomConfigs;
        return (callback) => {
            const options = this._getOptionsForPoiRequest(tile, tags, (err) => {
                err = err || new Error('cannot create Poi');
                callback(err);
            }, (data) => {
                callback(null, data);
            });
            tile.request = this.map._renderer._featuresRequestManager.askToServer(options);
        };
    }
    return null;
};

proto._getOptionsForPoiAgencyRequest = function (tile) {
    const nw = LatLng.from900913(tile.bounds.yMax, tile.bounds.xMin);
    const se = LatLng.from900913(tile.bounds.yMin, tile.bounds.xMax);
    const ne = LatLng.from900913(tile.bounds.yMax, tile.bounds.xMax);
    const sw = LatLng.from900913(tile.bounds.yMin, tile.bounds.xMin);
    const limit = LimitHelper.getEncodeLimitFromBounds(nw, ne, se, sw);
    const searchCriteria = {
        size: 1000,
        limit,
        'is-pro': true,
        closed: false,
        disabled: false,
        precision: ['rooftop', 'street'],
        visibleOnMap: true,
    };
    if (!Account.hasAnyRole()) {
        searchCriteria['max-age'] = 24 * 3600;
    }
    if (this._accountFilterAgency) {
        let nameQueryString;
        if (this._accountFilterAgency.accountType == IMPORT_ACCOUNT_TYPE) {
            nameQueryString = 'importAccountId';
        } else if (this._accountFilterAgency.accountType == NETWORK_ACCOUNT_TYPE) {
            nameQueryString = 'ownerIds';
        }
        if (nameQueryString) {
            searchCriteria.querystring = nameQueryString + ':"' + this._accountFilterAgency.id + '"';
        }
    }
    return searchCriteria;
};

proto._createRequestPoiAgency = function (tile) {
    const hasAgencyPois = (this.agencyConfig
        && this._agencyPoisEnabled
        && tile.zoom >= this.agencyConfig.minZoom);
    if (hasAgencyPois) {
        return (callback) => {
            const options = this._getOptionsForPoiAgencyRequest(tile);
            tile.agencyPoisRequest = this._requestAgencyPois(options, callback);
        };
    }
    return null;
};

proto.requestTilePois = function (tile) {
    let hasTags;
    if (this.map._renderer) {
        const tasks = {};
        const requestPoiCallback = this._createRequestPoi(tile);
        if (requestPoiCallback) {
            tasks.requestPoi = requestPoiCallback;
            hasTags = true;
        }
        const requestAgencyPoiCallback = this._createRequestPoiAgency(tile);
        if (requestAgencyPoiCallback) {
            tasks.requestAgencyPoi = requestAgencyPoiCallback;
            hasTags = true;
        }
        if (hasTags) {
            async.parallel(tasks, (err, results) => {
                if (err) {
                    this.onDeactivated(tile);
                } else {
                    tile.datas = results.requestPoi;
                    tile.agenciesData = results.requestAgencyPoi;
                    tile.request = null;
                    tile.agencyPoisRequest = null;
                    this.createTile(tile);
                }
            });
        }
    }
    return hasTags;
};

proto._requestAgencyPois = function (searchCriteria, callback) {
    let xhrAccount = null;
    let xhrAdminAccount = null;
    let isAborted = false;
    async.waterfall([
        cb => {
            xhrAccount = Account.find(searchCriteria, cb);
        },
        (users, cb) => {
            xhrAdminAccount = Account.getRelatedAccounts(users.accounts, (err, relatedAccounts) => {
                users.relatedAccounts = relatedAccounts;
                cb(err, users);
            });
        },
    ], (err, results) => {
        if (!isAborted) {
            callback(err, results);
        }
    });
    return {
        abort: () => {
            isAborted = true;
            if (xhrAccount) {
                xhrAccount.abort();
            }
            if (xhrAdminAccount) {
                xhrAdminAccount.abort();
            }
        },
    };
};

proto.filterConfigsForZoom = function (zoom) {
    return _.filter(this.tagConfigs, (config) => {
        return (null == config.minZoom || zoom >= config.minZoom)
            && (null == config.maxZoom || zoom <= config.maxZoom);
    });
};

proto.deleteTilePoisAgency = function (poiAgencyIds) {
    this._deletePois(poiAgencyIds, this._poiAgencyById);
};

proto.deleteTilePois = function (poiIds) {
    this._deletePois(poiIds, this._poiById);
};

proto._deletePois = function (poiIds, poiList) {
    _.each(poiIds, (id) => {
        const poi = poiList[id];
        --poi.uses;
        if (poi.uses == 0) {
            delete this._weightedLines[id];
            delete this._titledMarkers[id];
            delete poiList[id];
            _.each(poi.overlays, (overlay) => {
                if (this.options.onMarkerDeletedCallback) {
                    this.options.onMarkerDeletedCallback(overlay);
                } else {
                    overlay.setMap(null);
                }
            });
        }
    });
    poiIds.length = 0;
};

proto.setAgencyEnabled = function (agencyPoisEnabled) {
    if (this._agencyPoisEnabled != agencyPoisEnabled) {
        this._agencyPoisEnabled = agencyPoisEnabled;
    }
};

proto.setAccountFilterAgency = function (accountFilterAgency) {
    if (!_.isEqual(this._accountFilterAgency, accountFilterAgency)) {
        this._accountFilterAgency = accountFilterAgency;
        this.setTags(this.tagConfigs);
    }
};

proto.createPoisAgency = function (tile, poiAgencyData, relatedAccounts) {
    const poiId = poiAgencyData.id;
    const currentPoi = this._createPoi(this._poiAgencyById, poiId, () => {
        const poiOverlays = [];
        const position = poiAgencyData.position;
        if (isValidPosition(position)) {
            const marker = this._createAgencyMarker(poiAgencyData, relatedAccounts);
            poiOverlays.push(marker);
        }
        return poiOverlays;
    });
    if (currentPoi) {
        tile._poiAgencyIds.push(poiId);
    }
};

proto.createPois = function (tile, poiData) {
    const poiId = poiData.id || poiData.placeId || _.uniqueId('poi_');
    const currentPoi = this._createPoi(this._poiById, poiId, () => {
        const tagConf = _.find(tile.zoomConfigs, (config) => {
            if (poiData.graph_tags) {
                return _.includes(poiData.graph_tags, config.tag);
            } else {
                return false;
            }
        });
        const poiOverlays = [];
        if (poiData.way) {
            this._createLines(poiData, poiId, tagConf, poiOverlays);
        } else if (poiData.centroid) {
            const marker = this._createMarker(poiData, poiId, tagConf);
            if (marker) {
                poiOverlays.push(marker);
            }
        } else {
            console.warn('No centroid for poi', poiData);
        }
        return poiOverlays;
    });
    if (currentPoi) {
        tile._poiIds.push(poiId);
    }
};

proto._createPoi = function (poiList, poiId, createOverlaysCallback) {
    let currentPoi = poiList[poiId];
    if (currentPoi) {
        ++currentPoi.uses;
    } else {
        const poiOverlays = createOverlaysCallback();
        currentPoi = {overlays: poiOverlays, uses: 1, id: poiId};
        poiList[poiId] = currentPoi;
    }
    return currentPoi;
};

proto._createLines = function (poiData, poiId, tagConf, overlays) {
    const polylineConf = tagConf && tagConf.polyline || {};
    let strokeWeight = this.getStrokeWeight(this.map.zoom, polylineConf, true);
    let options = {
        map: this.map,
        geoJSON: poiData.way,
        clickable: false,
        disableZBuffer: true,
    };
    options.strokeWeight = strokeWeight;
    options.strokeColor = SHADOW_COLOR;
    options.strokeOpacity = SHADOW_OPACITY;
    options.zIndex = PolygonZIndex.poiLine;
    const blackLine = new Polyline(options);
    blackLine.isShadow = true;
    overlays.push(blackLine);
    if (polylineConf.strokeWeight == null) {
        this.setWeightedLine(blackLine, poiId, polylineConf);
    }

    strokeWeight = this.getStrokeWeight(this.map.zoom, polylineConf);
    options = {
        map: this.map,
        geoJSON: poiData.way,
        clickable: false,
        disableZBuffer: true,
        strokeWeight,
    };
    options.strokeColor = poiData.color || polylineConf.strokeColor;
    options.strokeWeight = strokeWeight;
    options.zIndex = PolygonZIndex.poiLineStroke;
    options.strokeOpacity = 1;
    const line = new Polyline(options);
    overlays.push(line);
    if (polylineConf.strokeWeight == null) {
        this.setWeightedLine(line, poiId, polylineConf);
    }
};

proto._createAgencyMarker = function (agencyInfo, relatedAccounts) {
    const position = agencyInfo.position;
    const {agencyConfig} = this;
    const [width, height] = agencyInfo.highlightedOnMap ? agencyConfig.highlightedSize : agencyConfig.normalSize;
    const options = {
        map: this.map,
        position: new MapApi.api.LatLng(position.lat, position.lon),
        agencyInfo,
        relatedAccounts,
        clickable: true,
        icon: {
            url: agencyConfig.url,
            scaledSize: new Size(width, height),
        },
    };
    if (BrowserDetect.isMobile() || BrowserDetect.isTablet()) {
        options.touchScaling = 2.0;
    }
    const marker = new Marker(options);
    marker.animate = false; // animation do not work well with SVG, it blinks
    const title = agencyInfo.display_name;
    if (title) {
        this.setMarkerTitle(marker, agencyInfo.id, agencyConfig, title);
    }
    if (this.options.onMarkerCreatedCallback) {
        this.options.onMarkerCreatedCallback(marker);
    }
    return marker;
};

// eslint-disable-next-line complexity
proto._createMarker = function (poiData, poiId, tagConf) {
    let marker;
    const title = (tagConf && tagConf.title) ? this.getTitle(tagConf, poiData) : null;
    const tagConfIcon = tagConf.icon;

    const icon = _.clone(tagConf && tagConfIcon);
    const hasIcon = (icon && icon.url && icon.url.indexOf('undefined') == -1);
    if (title || hasIcon) {
        let poiName = poiData.name
            || poiData.overrideName
            || (tagConf && getTranslatedTagName(tagConf.tag));
        if (_.isArray(poiName)) {
            poiName = poiName[0] || ''; //TODO replace table by a string before
        }
        if (icon && icon.url && icon.url.indexOf('undefined') != -1) {
            icon.url = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
        }
        const options = {
            map: this.map,
            position: getCenter(poiData),
            tags: this.getTags(poiData, tagConf),
            icon,
            color: tagConf.color,
            name: poiName,
            transportTag: tagConf.transportTag,
            clickable: this._clickEnabled,
        };
        if (BrowserDetect.isMobile() || BrowserDetect.isTablet()) {
            options.touchScaling = 2.0;
        }
        marker = new Marker(options);
        if (title) {
            this.setMarkerTitle(marker, poiId, tagConf, title);
        }
        if (this.options.onMarkerCreatedCallback) {
            this.options.onMarkerCreatedCallback(marker);
        }
    }
    return marker;
};

proto.getTags = function (poiData, tagConf) {
    let tags;
    if (tagConf.getTags) {
        tags = tagConf.getTags(poiData);
    }
    if (!tags) {
        tags = _.filter(poiData.graph_tags, tag => _.includes(this.configTagList, tag));
    }
    return tags;
};

function getCenter(poiData) {
    const centroid = poiData.centroid;
    if (centroid.lat != undefined) {
        return LatLng.from900913(centroid.lat, centroid.lon);
    } else {
        const latLon = ngeohash.decode(centroid);
        return new LatLng(latLon.latitude, latLon.longitude);
    }
}

proto.getStrokeWeight = function (zoom, polylineConf, isShadow) {
    const zoomMin = polylineConf.strokeWeightMin.zoom;
    const minWeight = polylineConf.strokeWeightMin.weight;

    const zoomMax = polylineConf.strokeWeightMax.zoom;
    const maxWeight = polylineConf.strokeWeightMax.weight;

    let weight = minWeight;
    if (zoom > zoomMin) {
        if (zoom < zoomMax) {
            const zoomLength = zoomMax - zoomMin;
            const ratio = (zoom - zoomMin) / zoomLength;
            weight = (maxWeight - minWeight) * ratio + minWeight;
        } else {
            weight = maxWeight;
        }
    }
    if (isShadow) {
        weight += SHADOW_WEIGHT;
    }
    return weight;
};

proto.setWeightedLine = function (line, poiId, polylineConf) {
    line.polylineConf = polylineConf;
    let pois = this._weightedLines[poiId];
    if (pois == null) {
        pois = this._weightedLines[poiId] = [];
    }
    pois.push(line);
};

proto.setMarkerTitle = function (marker, poiId, tagConf, title) {
    marker.poiTitle = {
        title,
        zoomMin: tagConf.title.zoomMin,
        zoomMax: tagConf.title.zoomMax,
    };
    let titledMarkers = this._titledMarkers[poiId];
    if (titledMarkers == null) {
        titledMarkers = this._titledMarkers[poiId] = [];
    }
    titledMarkers.push(marker);
    this.updateMarkerTitle(marker, this.map.zoom);
};

proto.updateMarkerTitle = function (marker, zoom) {
    let currentTitle = '';
    if (marker.poiTitle
        && (marker.poiTitle.zoomMin == null || zoom >= marker.poiTitle.zoomMin)
        && (marker.poiTitle.zoomMax == null || zoom <= marker.poiTitle.zoomMax)
    ) {
        currentTitle = marker.poiTitle.title;
    }
    marker.setTitle(currentTitle);
};

proto.getTitle = function (tagConf, poiData) {
    let title = poiData.name || poiData.overrideName;
    // check if name == category
    const translatedTag = tagConf.tag && getTranslatedTagName(tagConf.tag);
    if (translatedTag && translatedTag.indexOf(title) != -1) {
        title = null;
    }
    if (_.isArray(title)) {
        title = title[0] || ''; //TODO replace table by a string before
    }
    const useTitle = tagConf.title;
    if (useTitle && useTitle.enableCategory != null && useTitle.enableCategory && title && translatedTag) {
        title = translatedTag + ' ' + title;
    }
    return title;
};

proto._onZoomChanged = function () {
    if (this.map && !this.target) {
        const zoom = this.map.zoom;
        _.each(this._titledMarkers, (markers) => {
            _.each(markers, (marker) => {
                this.updateMarkerTitle(marker, zoom);
            });
        });
        _.each(this._weightedLines, (lines) => {
            _.each(lines, (line) => {
                const strokeWeight = this.getStrokeWeight(zoom, line.polylineConf, line.isShadow);
                line.setStrokeWeight(strokeWeight);
            });
        });
    }
};
proto.handleUnsetMap = function () {
    this._mapEvents.removeAllListeners();
    this.abortRequests();
    _super.handleUnsetMap.apply(this, arguments);
};

proto.handleSetMap = function () {
    const map = this.map;
    map.conf.pins.minBuildingHeight = 1;
    this._mapEvents.on(map, 'zoom_changed', _.bind(this._onZoomChanged, this));
    _super.handleSetMap.apply(this, arguments);
    if (this.map._renderer) {
        const featureManager = this.map._renderer._featuresRequestManager;
        featureManager.priorityRequests.init(this.options.featureRequestConf);
    }
};

// ---------- start to be moved in extra ex: syncTreeLayer----------
proto._handleTile = function (tile) {
    this._tileEvents.on(tile, {
        active: () => {
            this.onActivated(tile);
        },
        inactive: () => {
            this.onDeactivated(tile);
        },
    });
};

proto.addTile = function (tiles, tile) {
    if (tiles.indexOf(tile) == -1) {
        tiles.push(tile);
    }
};

proto.removeTile = function (tiles, tile) {
    const idx = tiles.indexOf(tile);
    if (idx != -1) {
        tiles.splice(idx, 1);
    }
};

proto.loadTile = function (tile) {
    this.addTile(this._tilesToBeLoaded, tile);
    this.removeTile(this._tilesToBeCreated, tile);
    this.removeTile(this._tilesToBeDeleted, tile);
    this.fetchTiles();
};

proto.createTile = function (tile) {
    this.addTile(this._tilesToBeCreated, tile);
    this.removeTile(this._tilesToBeLoaded, tile);
    this.fetchTiles();
};

proto.deleteTile = function (tile) {
    this.addTile(this._tilesToBeDeleted, tile);
    this.removeTile(this._tilesToBeLoaded, tile);
    this.removeTile(this._tilesToBeCreated, tile);
    this.fetchTiles();
};

proto.fetchTiles = function () {
    _.each(this._tilesToBeCreated, (tile) => {
        const oldPoiIds = tile._poiIds;
        tile._poiIds = [];
        _.each(tile.datas, (poiData) => {
            this.createPois(tile, poiData);
        });
        tile.datas = null;
        if (oldPoiIds && oldPoiIds.length) {
            this.deleteTilePois(oldPoiIds);
        }

        const oldPoiAgencyIds = tile._poiAgencyIds;
        tile._poiAgencyIds = [];
        _.each(_.get(tile, 'agenciesData.accounts'), (poiData) => {
            this.createPoisAgency(tile, poiData, _.get(tile, 'agenciesData.relatedAccounts'));
        });
        tile.agenciesData = null;
        if (oldPoiAgencyIds && oldPoiAgencyIds.length) {
            this.deleteTilePoisAgency(oldPoiAgencyIds);
        }
    });
    this._tilesToBeCreated.length = 0;
    if (this._tilesToBeDeleted.length) {
        const tilesReady = this.getReadyTiles(this._tilesToBeDeleted);
        _.each(tilesReady, (tile) => {
            if (tile._poiIds && tile._poiIds.length) {
                this.deleteTilePois(tile._poiIds);
            }
            if (tile._poiAgencyIds && tile._poiAgencyIds.length) {
                this.deleteTilePoisAgency(tile._poiAgencyIds);
            }

        });
        this._tilesToBeDeleted = _.difference(this._tilesToBeDeleted, tilesReady);
    }
    if (this.map._renderer) {
        this.map._renderer.forceRender();
    }
};

proto.getReadyTiles = function (tiles) {
    if (this._tilesToBeLoaded.length == 0) {
        return tiles;
    }
    return [];
};

proto.onActivated = function (tile) {
    this.onDeactivated(tile);
    if (this.requestTilePois(tile)) {
        this.loadTile(tile);
    }
};

proto.onDeactivated = function (tile) {
    this.abortTilePois(tile);
    this.deleteTile(tile);
};

proto.abortRequests = function () {
    _.each(_.clone(this._tilesToBeLoaded), (tile) => {
        this.onDeactivated(tile);
    });
    _.each(_.clone(this._tilesToBeCreated), (tile) => {
        this.onDeactivated(tile);
    });
    this.fetchTiles();
};

proto.abortTilePois = function (tile) {
    if (tile.request) {
        tile.request.abort();
        tile.request = null;
    }
    if (tile.agencyPoisRequest) {
        tile.agencyPoisRequest.abort();
        tile.agencyPoisRequest = null;
    }
};

proto.setClickEnabled = function (enabled) {
    this._clickEnabled = enabled;
    _.each(this._poiById, (poi) => {
        _.each(poi.overlays, (overlay) => {
            overlay.setClickable(enabled);
        });
    });
};

function getTranslatedTagName(tag) {
    return translate('poi.tag.' + tag, {defaultValue: tag});
}

proto.releaseAllTiles = function () {
    _super.releaseAllTiles.call(this);
    this._tileEvents.removeAllListeners();
};

// ---------- end to be moved in extra ----------
