const $ = require('jquery');

module.exports = {
    registerJQueryPlugin,
};

function registerJQueryPlugin() {
    $.fn.linkClick = registerLinkClick;
}

function registerLinkClick() {
    const args = Array.prototype.slice.call(arguments);
    const clickHandler = args[args.length - 1];
    const onArgs = ['click'].concat(args.slice(0, -1)).concat(onClick);
    this.on.apply(this, onArgs);

    function onClick(event) {
        //if not basic left click, abort special handling.
        if (event.which == 1 && !event.ctrlKey && !event.shiftKey && !event.altKey && !event.metaKey) {
            clickHandler.call(this, event);
        }
    }
}
