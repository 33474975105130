const _ = require('lodash');
const template = require('./RealEstateSubmission.jade');
const {INITIAL_STEP, SUBMISSION_STORE_MODULE_NAME} = require('../../Constants');
const RealEstateStepper = require('../../components/RealEstateStepper');
const AddressInput = require('../../components/AddressInput');
const RealEstateSubmissionMainDescriptor = require('./RealEstateSubmissionMainDescriptor');
const RealEstateContactForm = require('../../components/RealEstateContactForm');
const Account = require('../../../authentication/Account');
const ServerConfig = require('../../../ServerConfig');
const GoogleTagManager = require('../../../stats/GoogleTagManager');

// @vue/component
module.exports = {
    components: {
        RealEstateStepper: RealEstateStepper(SUBMISSION_STORE_MODULE_NAME),
        AddressInput: AddressInput(SUBMISSION_STORE_MODULE_NAME),
        RealEstateSubmissionMainDescriptor,
        RealEstateContactForm: RealEstateContactForm(SUBMISSION_STORE_MODULE_NAME),
    },
    extends: require('../../components/RealEstatePropertyDescriptor'),
    mixins: [
        require('../../components/mixins/CommonStepMixin')(SUBMISSION_STORE_MODULE_NAME),
    ],
    constants: {
        INITIAL_STEP,
    },
    data() {
        return {
            suggestedAgenciesStep: 2,
            resultStep: 3,
        };
    },
    computed: {
        getRealEstateSubmissionContainerClass() {
            return {
                'is-in-real-estate-property-form': this.step > INITIAL_STEP && this.step < this.resultStep,
                'is-landing is-landing-submission': this.step == INITIAL_STEP,
                'show-error': this.step === this.suggestedAgenciesStep && !this.hasAgenciesToContact,
                'is-hidden': this.step === this.resultStep,
            };
        },
        hasToShowAgenciesBlock() {
            return this.step === this.resultStep;
        },
    },
    methods: {
        sendForm() {
            const {realEstateProperty, transactionType} = this;
            const intention = {intention: transactionType};
            const sender = {
                ...this.sender,
                ...intention,
            };
            delete sender.userStatus;
            const data = {
                realEstateProperty,
                sender,
                suggestedAgencyIds: this.getAgenciesToContactIds(),
            };
            const realEstatePropertySubmissionInfo = _.extend({}, realEstateProperty, intention);
            Account.getAccountAndCreateGuestIfNeeded(() => {
                Account.postJson({
                    url: `${ServerConfig.config.estimationUrl}/send-submission`,
                    data,
                    disableErrorPage: true,
                    callback: (err) => {
                        this.updateAccountInfos();
                        if (err) {
                            console.error(err, 'Submission: could not send submission');
                        } else {
                            GoogleTagManager.sendEvent(
                                'realEstateSubmissionFormSubmitted',
                                realEstatePropertySubmissionInfo
                            );
                        }
                    },
                });
            });
        },
    },
    template: template(),
};
