const ApplicationPage = require('../ApplicationPage');
const AccountContactsView = require('../../views/AccountContactsView');
const StatsHelper = require('../../stats/StatsHelper');
const moment = require('moment');
const _ = require('lodash');
const urlUtil = require('url');

module.exports = class MyAccountContactsPage extends ApplicationPage {
    constructor({name, title} = {}) {
        super({
            name: name || 'myAccountContacts',
            title: title || 'Mes contacts',
            isRegistrationRequired: true,
        });
        this.accountContactsView = new AccountContactsView();
    }

    getMiddleViews() {
        return this.accountContactsView;
    }

    getUrlPattern() {
        return '/mes-contacts';
    }

    parseUrl(url) {
        return MyAccountContactsPage.getContactsQueryParameters(url);
    }

    static getContactsQueryParameters(url) {
        const parsedUrl = urlUtil.parse(url, true);
        const defaultPeriod = StatsHelper.getDefaultPeriod();
        const startDate = formatDateForUrlParameters(parsedUrl, 'fromDate', defaultPeriod.startDate);
        const endDate = formatDateForUrlParameters(parsedUrl, 'toDate', defaultPeriod.endDate);
        const transactionType = _.get(parsedUrl, 'query.transactionType', '');

        return {startDate, endDate, transactionType};
    }
};

function formatDateForUrlParameters(parsedUrl, fieldAsString, defaultValue) {
    const parsedDate = moment(_.get(parsedUrl, ['query', fieldAsString], defaultValue), moment.ISO_8601, true);
    return parsedDate.isValid() ? parsedDate : defaultValue;
}
