const _ = require('lodash');
const {humanizeDay} = require('../../../common/formatters/DateFormatter');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./RealEstateAdNotificationBoostHistory.jade');
const RealEstateAdTitleGenerator = require('../../../common/RealEstateAdTitleGenerator');

const HISTORY_OBJECT_PROPERTIES = ['adId', 'accountId', 'date', 'numberOfAlertsReached'];

// @vue/component
module.exports = {
    bemName: 'ad-notification-boost-history',
    mixins: [
        i18nMixin({
            prefix: 'RealEstateAdNotificationBoostHistory.',
            keys: [
                'date',
                'emailingSentCount',
                'waiting',
                'adTitle',
            ],
        }),
    ],
    props: {
        history: {
            type: Array,
            required: true,
            validator: (history) => _.every(history, adNotificationBoostDocument => {
                return _.every(HISTORY_OBJECT_PROPERTIES, objectProperty => _.has(adNotificationBoostDocument, objectProperty));
            }),
        },
        showAdTitleColumn: Boolean,
    },
    computed: {
        modifiers() {
            return {
                hasTitleColumn: this.showAdTitleColumn,
            };
        },
    },
    methods: {
        formatDateToHunanizeDay(date) {
            return humanizeDay(date);
        },
        formatAdTitle(key) {
            return RealEstateAdTitleGenerator.getTitle(this.history[key].realEstateAd, 'full');
        },
    },
    template: template(),
};
