const _ = require('lodash');
const template = require('./ModalCompactContactForm.jade');
const FormAndSpinner = require('./mixins/FormAndSpinner');
const Views = require('../../views/Views');
const CommonProps = require('./mixins/CommonProps');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {TrackingClasses} = require('../../stats/TrackingClasses');
const ContactFormSender = require('../../utils/ContactFormSender');

const relatedInfoAsked = {
    //special types :
    plan: 'plan',
    brochure: 'brochure',
    only360: 'virtualTours',
    //names :
    photos: 'photos',
    documentation: 'documentation',
    nothing: 'documentation',
    meeting: 'meeting',
};

// @vue/component
module.exports = {
    components: {
        // @vue/component
        FormAndSpinner: {
            mixins: [
                FormAndSpinner,
            ],
            props: {
                infoAsked: {
                    type: String,
                    required: true,
                },
                specialType: {
                    type: String,
                    default: '',
                },
            },
            computed: {
                sentFrom() {
                    const {specialType, infoAsked} = this;
                    const infoRequired = relatedInfoAsked[specialType || infoAsked];
                    const actionPrefix = specialType && (infoAsked !== 'nothing') ? 'hasSeen' : 'askFor';
                    return `${actionPrefix}${_.upperFirst(infoRequired)}`;
                },
            },
            methods: {
                sendContact(options, cb) {
                    const {infoAsked, sentFrom} = this;
                    if (infoAsked) {
                        options.contact[sentFrom] = true;
                    }
                    ContactFormSender.sendAdContact(options, (err, contact) => {
                        cb(err, {contact});
                    });
                },
            },
        },
    },
    constants: {
        SUBMIT_BUTTON_TRACKING_CLASSES: [TrackingClasses.submitContactFormToGetContent],
    },
    mixins: [
        require('../../form/components/mixins/withInternalValue'),
        i18nMixin({
            prefix: 'modalCompactContactForm.',
        }),
        CommonProps,
    ],
    props: {
        value: Boolean,
        infoAsked: {
            type: String,
            required: true,
        },
        specialType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isSuccess: false,
        };
    },
    methods: {
        onSuccess() {
            this.closeModal();
            Views.volatileFeedback.showSuccess(this.t('success'));
        },
        onError() {
            this.closeModal();
            Views.volatileFeedback.showError(this.t('error'));
        },
        closeModal() {
            this.internalValue = false;
        },
    },
    template: template(),
};
