const template = require('./RealEstateContactForm.jade');
const CommonStepMixin = require('./mixins/CommonStepMixin');
const {getDefaultApprovalQuestions} = require('../../utils/ContactHelper');

// @vue/component
module.exports = (storeModuleName) => ({
    mixins: [
        CommonStepMixin(storeModuleName),
    ],
    props: {
        isRequestingAgencies: Boolean,
        hasAgenciesToContact: Boolean,
        agenciesToContactForDisplay: {
            type: Array,
            required: true,
        },
        isSuggestedAgenciesStep: Boolean,
    },
    computed: {
        approvalQuestions() {
            return getDefaultApprovalQuestions();
        },
        title() {
            return this.realEstatePropertyDescriptorTranslate('sections.moreAboutProject');
        },
        subtitle() {
            const {transactionType, shouldDisplayAgencies} = this;
            const translationOptions = {};
            if (shouldDisplayAgencies) {
                translationOptions.context = transactionType;
            }
            return this.realEstatePropertyDescriptorTranslate('sections.moreAboutProjectSubtitle', translationOptions);
        },
        agenciesToContactPresentation() {
            return this.realEstatePropertyDescriptorTranslate('agenciesToContactPresentation');
        },
        endUserLicenseAgreementTranslationContext() {
            const {isEstimation, shouldDisplayAgencies} = this;
            if (isEstimation) {
                return 'estimation' + (shouldDisplayAgencies ? '_with_agencies_to_contact' : '');
            } else {
                return 'submission';
            }
        },
        subtitleNoAgenciesFound() {
            return this.realEstatePropertyDescriptorTranslate(
                'sections.moreAboutProjectSubtitle',
                {context: 'noAgenciesFound'}
            );
        },
        shouldDisplayAgencies() {
            const {isSubmission, isBuy, sender_intention: intention} = this;
            return isSubmission || (isBuy && intention === 'sell');
        },
        phoneLabel() {
            return this.getQuestion('phone', {context: this.sender_intention});
        },
    },
    template: template(),
});
