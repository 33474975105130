const Account = require('../../authentication/Account');

module.exports = function ({accountIds, estimationId}, callback) {
    Account.authAjax({
        method: 'POST',
        url: '/sendEstimationEmails',
        data: {
            estimationId,
            accountIds,
        },
        disableErrorPage: true,
        serverErrorMessage: 'sendEstimationEmails',
        callback,
    });
};
