const template = require('./AgencyAdsLinks.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const LoadUserAdsHelper = require('../../search/LoadUserAdsHelper');
const AdsLinkButton = require('../../agency/components/AdsLinkButton');
const RealEstateAdSummary = require('../../agency/components/RealEstateAdSummary');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');
const {BREAKPOINTS_LIMITS_XS} = require('../../vue/utils/Breakpoints');

// @vue/component
module.exports = {
    components: {
        AdsLinkButton,
        RealEstateAdSummary,
    },
    mixins: [
        ContainerQueries({xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS}}),
        i18nMixin({
            prefix: 'AgencyAdsLinks.',
            keys: [
                'title',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true,
            userAdsOptions: null,
        };
    },
    computed: {
        displayAdsCount() {
            return this.userAdsOptions;
        },
        speechBubbleModifiers() {
            return {
                noAds: !this.displayAdsCount,
            };
        },
    },
    mounted() {
        LoadUserAdsHelper.loadUserAdsCount({
            author: this.agency,
            origin: 'agency',
        },
        (err, options) => {
            if (!err) {
                this.userAdsOptions = options;
            }
            this.isLoading = false;
        });
    },
    template: template(),
};
