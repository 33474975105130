const _ = require('lodash');
const DefaultConfiguration = require('./DefaultConfiguration');
const ProgrammeHelper = require('./ProgrammeHelper');
const AdTypeHelper = require('./AdTypeHelper');

_.extend(module.exports, {
    buildSearch,
});

function buildSearch(realEstateAd) {
    if (!realEstateAd) {
        return null;
    }
    const search = {
        filterType: [getFilterTypeFromAd(realEstateAd)],
        propertyType: getPropertyTypeFromAd(realEstateAd),
    };
    const postalCode = _.defaultTo(realEstateAd.postalCodeForSearchFilters, realEstateAd.postalCode);
    const locationName = _.compact([realEstateAd.city, postalCode]).join('-');
    if (locationName) {
        search.locationNames = [locationName];
    }
    if (realEstateAd.newProperty) {
        search.newProperty = true;
    }
    return _.defaults(search, DefaultConfiguration.search);
}

function getPropertyTypeFromAd(realEstateAd) {
    return ProgrammeHelper.isProgrammeOrResidence(realEstateAd) ?
        findPropertyTypesFromList(realEstateAd.relatedAds) : [realEstateAd.propertyType];
}

function getFilterTypeFromAd(realEstateAd) {
    return AdTypeHelper.getTransactionTypeFromAd(realEstateAd);
}

function findPropertyTypesFromList(ads) {
    if (!ads) {
        return DefaultConfiguration.search.propertyType;
    } else {
        const allowedPropertyTypes = ['house', 'flat', 'parking', 'terrain'];
        const propertyTypeList = _.uniq(_.map(ads, 'propertyType'));
        const onlyAllowedList = _.filter(propertyTypeList, function (type) {
            return _.includes(allowedPropertyTypes, type);
        });
        if (onlyAllowedList.length != propertyTypeList.length) {
            onlyAllowedList.push('others');
        }
        return onlyAllowedList;
    }
}
