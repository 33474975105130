const SearchPageBaseFactory = require('./SearchPageBaseFactory');
const SavedSearchSearchPage = require('../../pages/SavedSearchSearchPage');

module.exports = class SavedSearchSearchPageFactory extends SearchPageBaseFactory {
    getUrlPattern() {
        return new RegExp('^/mon-alerte/.*');
    }

    createPage() {
        return new SavedSearchSearchPage(this._pageConfiguration);
    }
};
