const _ = require('lodash');

const TOTAL_ADVERTISEMENTS_SLOTS = 4;
const LEADING_ROWS_QUANTITY_WITHOUT_COMMERCIAL_ADS = 1;
const MAX_REAL_ESTATE_ADS = 24;

module.exports = {
    getItemsList,
};

function getItemsList(
    itemsPerRow,
    leadingAds = [],
    realEstateAds = [],
    {
        promotedAdsDisplayedOnTwoColumns,
        enableCommercialAds,
    } = {}
) {
    let indexOfAdToDisplayOnTwoColumns = -1;
    const impossibleCaseToHandleForAdOnTwoColumns = leadingAds.length == 1 && realEstateAds.length == 1 && itemsPerRow == 2;
    if (promotedAdsDisplayedOnTwoColumns
        && itemsPerRow >= 2
        && !impossibleCaseToHandleForAdOnTwoColumns) {
        indexOfAdToDisplayOnTwoColumns = getIndexOfFirstPromotedAsExclusiveAds(realEstateAds);
    }
    if (indexOfAdToDisplayOnTwoColumns >= 0) {
        realEstateAds[indexOfAdToDisplayOnTwoColumns].displayOnTwoColumns = true;
    }
    const realEstateAdsQty = realEstateAds.length;
    const leadingAdsQty = leadingAds.length;
    if (!hasEnoughRealEstateAdsForCommercialAds(realEstateAdsQty, leadingAdsQty, itemsPerRow) || !enableCommercialAds) {
        if (isLastElementInRow(itemsPerRow, indexOfAdToDisplayOnTwoColumns + leadingAdsQty)) {
            const switchDirection = (indexOfAdToDisplayOnTwoColumns == 0) ? 1 : -1;
            switchPositionInArray(
                realEstateAds,
                indexOfAdToDisplayOnTwoColumns,
                indexOfAdToDisplayOnTwoColumns + switchDirection
            );
        }
        return _.clone(realEstateAds);
    } else {
        const cellsQty = leadingAdsQty + realEstateAdsQty + ((indexOfAdToDisplayOnTwoColumns >= 0) ? 1 : 0);
        const rowQty = Math.ceil(cellsQty / itemsPerRow);
        const adsQty = getAdvertisementsQty(rowQty, itemsPerRow, cellsQty);
        const advertisementIndicesInAdsList = getAdvertisementIndicesInAdsList(
            adsQty,
            itemsPerRow,
            realEstateAdsQty,
            leadingAdsQty
        );
        if (indexOfAdToDisplayOnTwoColumns >= 0) {
            const commercialAdsIndicesBeforePromotedAd = _.filter(
                advertisementIndicesInAdsList,
                function (commercialAdIndex) {
                    return commercialAdIndex <= indexOfAdToDisplayOnTwoColumns;
                }
            );
            const displayOnTwoColumnsItemIndex = indexOfAdToDisplayOnTwoColumns + leadingAdsQty
                + commercialAdsIndicesBeforePromotedAd.length;
            if (isLastElementInRow(itemsPerRow, displayOnTwoColumnsItemIndex)) {
                if (_.isEmpty(commercialAdsIndicesBeforePromotedAd)) {
                    // remove first commercial ad (should be right after promoted ad), then swap AP with next normal ad
                    advertisementIndicesInAdsList[0] = realEstateAdsQty + adsQty + leadingAdsQty;
                    switchPositionInArray(
                        realEstateAds,
                        indexOfAdToDisplayOnTwoColumns,
                        indexOfAdToDisplayOnTwoColumns + 1
                    );
                } else {
                    _.each(commercialAdsIndicesBeforePromotedAd, (position, i) => {
                        if (i == 0) {
                            advertisementIndicesInAdsList[i] = realEstateAdsQty + adsQty + leadingAdsQty;
                        } else {
                            advertisementIndicesInAdsList[i] = position - 1;
                        }
                    });
                }
            }
        }
        return injectCommercialAdsInList(advertisementIndicesInAdsList, _.clone(realEstateAds));
    }
}

function switchPositionInArray(array, index1, index2) {
    const positionTemp = array[index1];
    array[index1] = array[index2];
    array[index2] = positionTemp;
    return array;
}

function injectCommercialAdsInList(advertisementIndicesInAdsList, itemList) {
    _.each(advertisementIndicesInAdsList, (position, indexOfCurrentAd) => {
        itemList.splice(
            position + indexOfCurrentAd,
            0,
            {
                type: 'commercialAd',
                index: indexOfCurrentAd,
                position: position + indexOfCurrentAd,
            }
        );
    });
    return itemList;
}

function getIndexOfFirstPromotedAsExclusiveAds(realEstateAds) {
    return _.findIndex(realEstateAds, (realEstateAd) => {
        return realEstateAd.endOfPromotedAsExclusive > 0;
    });
}

function isLastElementInRow(itemsPerRow, itemIndex) {
    return itemIndex % itemsPerRow == itemsPerRow - 1;
}

function hasEnoughRealEstateAdsForCommercialAds(realEstateAdsQty, leadingAdsQty, itemsPerRow) {
    return realEstateAdsQty + leadingAdsQty > LEADING_ROWS_QUANTITY_WITHOUT_COMMERCIAL_ADS * itemsPerRow;
}

function getAdvertisementsQty(rowQty, itemsPerRow, itemsQty) {
    const adsQtyPerLine = Math.min(getAdsDistributedByLines(rowQty, itemsPerRow), TOTAL_ADVERTISEMENTS_SLOTS);
    if (itemsPerRow == 1 || itemsQty < MAX_REAL_ESTATE_ADS) {
        return adsQtyPerLine;
    } else {
        return Math.min(getRoundAdsQty(rowQty, itemsPerRow, itemsQty), adsQtyPerLine);
    }
}

function getRoundAdsQty(rowQty, itemsPerRow, itemsQty) {
    const adsQtyToFulfillLastLine = itemsPerRow - (itemsQty % itemsPerRow);
    return getAdsQtyWithLineIfNeeded(adsQtyToFulfillLastLine, itemsPerRow, rowQty);
}

function getAdsDistributedByLines(rowQty, itemsPerRow) {
    const lineSpacing = TOTAL_ADVERTISEMENTS_SLOTS + 1 - itemsPerRow;
    return Math.ceil(getRowQtyWithAdvertisements(rowQty) / lineSpacing);
}

function getAdsQtyWithLineIfNeeded(adsQty, itemsPerRow, rowQty) {
    const adsQtyWithOneLine = adsQty + itemsPerRow;
    const shouldAddANewLine = shouldAddNewLines(adsQtyWithOneLine, adsQty, rowQty);
    return shouldAddANewLine ? getAdsQtyWithLineIfNeeded(adsQtyWithOneLine, itemsPerRow, rowQty) : adsQty;
}

function shouldAddNewLines(adsQtyWithOneLine, adsQty, rowQty) {
    return adsQtyWithOneLine <= TOTAL_ADVERTISEMENTS_SLOTS && adsQty < getRowQtyWithAdvertisements(rowQty);
}

function getRowQtyWithAdvertisements(rowQty) {
    return rowQty - LEADING_ROWS_QUANTITY_WITHOUT_COMMERCIAL_ADS;
}

function getAdvertisementIndicesInAdsList(adsQty, itemsPerRow, realEstateAdsQty, leadingAdsQty) {
    const realEstateAdsToSkip = getRealEstateAdsQtyToSkip(itemsPerRow, leadingAdsQty);
    const adsSpacing = Math.ceil(realEstateAdsQty / adsQty);
    return _.range(realEstateAdsToSkip, realEstateAdsQty, adsSpacing);
}

function getRealEstateAdsQtyToSkip(itemsPerRow, leadingAdsQty) {
    return Math.max(LEADING_ROWS_QUANTITY_WITHOUT_COMMERCIAL_ADS * itemsPerRow - leadingAdsQty - 1, 1);
}
