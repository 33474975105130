// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseInputMixin'),
    ],
    props: {
        type: {
            type: String,
            default: 'text',
        },
        nullify: Boolean, // set to null value on empty string
    },
    methods: {
        onInput({target: {value}}) {
            this.internalValue = this.nullify && value === '' ? null : value;
        },
    },
};
