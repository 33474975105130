module.exports = {
    loadImage,
};

function loadImage(url, callback) {
    const image = new Image();
    image.onload = function () {
        callback(null, image);
    };
    image.onerror = function () {
        callback(new Error(`Could not load image at ${url}`));
    };
    image.src = url;
}
