const _ = require('lodash');
const template = require('./NeighborhoodCategory.jade');
const NeighborhoodCategoryItem = require('./NeighborhoodCategoryItem');
const NeighborhoodCategoryGauge = require('./NeighborhoodCategoryGauge');

// @vue/component
module.exports = {
    components: {
        NeighborhoodCategoryItem,
        NeighborhoodCategoryGauge,
    },
    props: {
        color: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        gauge: {
            type: Number,
            required: true,
        },
        items: {
            type: Object,
            required: true,
        },
    },
    computed: {
        backgroundColorStyle() {
            return {
                background: this.color,
            };
        },
    },
    methods: {
        getItemValues(item) {
            return _.isNumber(item) ? [item] : item.values;
        },
    },
    template: template(),
};
