const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');
const moment = require('moment');
const LocationItem = require('./LocationItem');
const ZoneByTimeHelper = require('../utils/ZoneByTimeHelper');
const AddressPositionHelper = require('../utils/AddressPositionHelper');

module.exports = class TravelTimeItem extends LocationItem {
    constructor(options) {
        super(options);
        this.from = 'travelTimeZone';
        this.update(options);
    }

    getValue() {
        return _.compact([
            this.getType(),
            this._getItemId(),
            this._getItemAddress(),
            this._getItemDuration(),
            this._getItemMode(),
        ]).join('-');
    }

    _getItemId() {
        return this.getItem()._id;
    }

    getZoneInfos() {
        const zoneInfos = super.getZoneInfos();
        return _.extend(zoneInfos, {travelTimeInfos: {item: this.getItem()}});
    }

    getItemValuesToStoreInDB() {
        const itemValues = _.pick(this.getItem(), ['mode', 'duration', 'address', 'from']);
        return _.extend(itemValues, super.getItemValuesToStoreInDB());
    }

    getUrlValue() {
        return 'temps-de-transport-' + this._getItemId();
    }

    getSuggestionText() {
        return t('locationField.travelTime');
    }

    _getItemFormattedDuration() {
        const item = this.getItem();
        const momentDuration = moment.duration(Number(item.duration) * 1000);
        const hours = momentDuration.hours();
        const minutes = momentDuration.minutes();
        const hoursDisplay = hours > 0 ? `${hours}h` : '';
        const minutesDisplayFormatted = hours > 0 ? minutes : `${minutes} min`;
        const minutesDisplay = minutes > 0 ? minutesDisplayFormatted : '';
        return `${hoursDisplay}${minutesDisplay}`;
    }

    _getItemMode() {
        return this.getItem().mode;
    }

    _getItemDuration() {
        return this.getItem().duration;
    }

    _getItemAddress() {
        return this.getItem().address;
    }

    _getItemFormattedMode() {
        const mode = this._getItemMode();
        if (mode) {
            return t('travelModes.' + mode);
        }
        return '';
    }

    _onSubmit(callback) {
        if (!this._isZoneSaved() && this._hasValidParams()) {
            ZoneByTimeHelper.compute(this.getItem(), (err, zone) => {
                if (zone) {
                    this._item = zone;
                }
                callback(err, zone);
            });
        } else {
            callback(null, this.getItem());
        }
    }

    update(options = {}) {
        this._withPreposition = !options.naturalLanguageEnabled;
    }

    _hasValidParams() {
        const item = this.getItem();
        return (item.position != null && item.duration != null && item.mode != null && item.address != null);
    }

    _isZoneSaved() {
        return (this._getItemId() != null);
    }

    canBeAdded() {
        return this._isZoneSaved() || this._hasValidParams() || this.getItem().canBeUsedIncomplete;
    }

    _getItemFormattedAddress() {
        const address = this._getItemAddress();
        const preposition = this._getAddressPreposition(address);
        return _.lowerFirst(_.compact([preposition, address]).join(' '));
    }

    _getAddressPreposition(address) {
        if (address) {
            const translationKey = AddressPositionHelper.textToPosition(address) ? 'nearCoordinate' : 'ofAddress';
            return t('transportDurations.' + translationKey);
        }
    }

    getText() {
        const formattedDuration = this._getItemFormattedDuration();
        const formattedMode = this._getItemFormattedMode();
        const formattedAddress = this._getItemFormattedAddress();
        let text = _.compact([formattedDuration, formattedMode, formattedAddress]).join(' ');
        if (text && this._withPreposition) {
            text = `à ${text}`;
        }
        return text || t('transportDurations.defaultTagText');
    }

    getPostalCodes() {
        const match = this._item.address.match(/[0-9]{5}/g);
        return [_.last(match)];
    }
};
