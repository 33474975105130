const HOUSE = 'house';
const FLAT = 'flat';
const PARKING = 'parking';
const TERRAIN = 'terrain';
const BUILDING = 'building';
const CASTLE = 'castle';
const LOFT = 'loft';
const OFFICE = 'office';
const PREMISES = 'premises';
const SHOP = 'shop';
const TOWNHOUSE = 'townhouse';
const ANNEXE = 'annexe';
const PROGRAMME = 'programme';
const RESIDENCE = 'residence';
const DEFAULT_PROPERTY_TYPE = 'unknown';

const PROPERTY_TYPES = {
    HOUSE,
    FLAT,
    PARKING,
    TERRAIN,
    BUILDING,
    CASTLE,
    LOFT,
    OFFICE,
    PREMISES,
    SHOP,
    TOWNHOUSE,
    ANNEXE,
    PROGRAMME,
    RESIDENCE,
};

module.exports = {
    PROPERTY_TYPES,
    DEFAULT_PROPERTY_TYPE,
};
