const _ = require('lodash');

module.exports = {
    inject: {
        account: {
            default: {},
        },
    },
    created() {
        if (!this.value) {
            const path = this.name.replace(/^\./, '');
            this.setInternalValue(_.get(this.account, path));
        }
    },
};
