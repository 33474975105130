const _ = require('lodash');
const GoogleTagManager = require('../stats/GoogleTagManager');
const audienceTargetingProductsGtmSender = require('../audienceTargetingProducts/gtmSender');
const SearchFiltersHelper = require('../../common/SearchFiltersHelper');
const isNullOrEmpty = require('../../common/isNullOrEmpty');

const AD_DATA_SENT_TO_GTM = [ //todo add agenciesIds, paying option, ad scoring, advertiser id
    'adType',
    'adTypeFR',
    'city',
    'customerId',
    'hasToBeBuilt',
    'id',
    'newProperty',
    'postalCode',
    'price',
    'propertyType',
    'roomsQuantity',
    'transactionType',
    'highlightMailContact',
];

module.exports = {
    getAdObjectForGtm,
    onAdListDisplayed,
    onDetailedSheetOpened,
};

/**
 *
 * @param {object} data
 * @param {object[]} data.realEstateAds
 * @param {object[]} data.leadingAds
 * @param {object} data.searchCriteria
 * @param {string} data.mode list mode
 * @param {object} [data.mapStats] map shown info
 */
function onAdListDisplayed(data) {
    let gtmData;
    if (_.isEmpty(data)) {
        gtmData = data;
    } else {
        const {searchCriteria, mode, mapStats, realEstateAds, leadingAds, totalResults, displayedAsHighlightedAdIds} = data;
        const adsInList = realEstateAds.concat(leadingAds);
        let propertyTypes = _.isArray(searchCriteria.propertyType) ?
            searchCriteria.propertyType.join(',') : searchCriteria.propertyType;
        if (searchCriteria.newProperty != null) {
            propertyTypes += searchCriteria.newProperty === true ? ',newProperty' : ',oldProperty';
        } else {
            propertyTypes += ',newProperty,oldProperty';
        }
        const advancedFiltersCheckedFields = _.keys(SearchFiltersHelper.getAdvancedFilters(searchCriteria));

        let locationNames = 'France';
        if (searchCriteria.locations && searchCriteria.locations.length > 0) {
            locationNames = _.map(searchCriteria.locations, function (location) {
                return location.getName();
            });
        }

        gtmData = _.omitBy({
            filterType: searchCriteria.filterType,
            locationNames,
            maxPrice: searchCriteria.maxPrice,
            minPrice: searchCriteria.minPrice,
            maxArea: searchCriteria.maxArea,
            minArea: searchCriteria.minArea,
            maxRooms: searchCriteria.maxRooms,
            minRooms: searchCriteria.minRooms,
            propertyType: propertyTypes,
            advancedFilters: advancedFiltersCheckedFields.join(','),
            mode,
            firstLocationPostalCode: _.first(_.invoke(searchCriteria.locations, '[0].getPostalCodes')),
        }, isNullOrEmpty);

        const realEstateAdsInList = {
            realEstateAds: getAdsObjectForGtm({ads: realEstateAds, displayedAsHighlightedAdIds}),
            leadingAds: getAdsObjectForGtm({ads: leadingAds, displayedAsHighlightedAdIds}),
            totalResults,
        };
        _.extend(gtmData, mapStats, {realEstateAdsInList});
        audienceTargetingProductsGtmSender.onAdListDisplayed(adsInList);
    }
    GoogleTagManager.sendEvent('resultsShown', gtmData);
}

function onDetailedSheetOpened(data, realEstateAd) {
    GoogleTagManager.sendEvent('detailedSheetOpened', data);
    audienceTargetingProductsGtmSender.onDetailedSheetOpened(realEstateAd);
}

function getAdsObjectForGtm({ads, displayedAsHighlightedAdIds}) {
    const realEstateAdsForGtm = {};
    _.forEach(ads, (ad, rank) => {
        const {id} = ad;
        realEstateAdsForGtm[id] = _.extend({rank}, getAdObjectForGtm(ad));
        if (_.includes(displayedAsHighlightedAdIds, id)) {
            realEstateAdsForGtm[id].isHighlighted = true;
        }
    });
    return realEstateAdsForGtm;
}

function getAdObjectForGtm(realEstateAd) {
    const data = GoogleTagManager.pickOrUndefined(realEstateAd, AD_DATA_SENT_TO_GTM);
    const {userRelativeData} = realEstateAd;
    if (userRelativeData) {
        const {accountIds, importAccountId} = userRelativeData;
        _.extend(data, {
            agencyIds: accountIds,
            agencyId: _.first(accountIds),
            importAccountId,
        });
    }
    return _.pickBy(data);
}
