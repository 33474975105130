const _ = require('lodash');
const moment = require('moment');

const Account = require('../authentication/Account');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');
const {getImageUrlFromAlias} = require('../ImageHelper');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');

function getAdsNotificationBoostsHistory({accountId, transactionTypes, startDate, endDate}, callback) {
    Account.authAjax({
        method: 'GET',
        url: '/api/ads-notification-boosts-history',
        data: {
            accountId,
            transactionTypes,
            fromDate: moment(startDate).toISOString(),
            toDate: moment(endDate).add(1, 'day').startOf('day').toISOString(),
        },
        disableErrorPage: true,
        serverErrorMessage: 'getAdNotificationBoostHistoryPage',
        callback,
    });
}

function formatHistoryData(boostsHistory) {
    return _.map(boostsHistory, history => {
        const {date, numberOfAlertsReached, realEstateAd, _id: id} = history;
        return {
            id,
            date,
            adTitle: RealEstateAdTitleGenerator.getTitle(realEstateAd, 'contactRequest'),
            realEstateAd: formatRealEstateAd(realEstateAd),
            emailingSentCount: numberOfAlertsReached,
        };
    });
}

function formatRealEstateAd(realEstateAd) {
    return _.assignIn(VirtualTourHelper.enhanceAd(realEstateAd), {
        photos: _.map(realEstateAd.photos, photo => ({
            url: getImageUrlFromAlias(photo.photo),
        })),
    });
}

function getDefaultSelectedTransactionTypes() {
    return ['rent', 'buy'];
}

module.exports = {
    getAdsNotificationBoostsHistory,
    formatHistoryData,
    getDefaultSelectedTransactionTypes,
};
