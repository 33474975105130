const _ = require('lodash');

const LoanSimulatorFormView = require('../LoanSimulatorFormView');
const template = require('./ptzSimulatorForm.jade');

module.exports = class PtzSimulatorFormView extends LoanSimulatorFormView {
    constructor(options) {
        super(options);
        this.template = template;
    }

    show(options) {
        super.show(options);
        this._bindEvents();
        this.$workCosts = this.$form.find('.workCosts');
        if (_.get(options, 'previousSimulatorData.TypeAchat') !== '20') { // code for 'Ancien avec travaux'
            this.$workCosts.hide();
        }
    }

    _bindEvents() {
        this._eventPack.on(this.$form.find('select[name="TypeAchat"]'), 'change', _.bind(this._updateWorkCostsField, this));
    }

    _updateWorkCostsField(e) {
        this.$workCosts.toggle(e.currentTarget.value == '20');
    }
};
