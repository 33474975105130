const _ = require('lodash');
const ApplicationPage = require('../../pages/ApplicationPage');
const template = require('./RealEstateSellOrRentSubmissionPage.jade');
const VueView = require('../../vue/VueView');

const CONFIGURATION = {
    name: 'submit-ad',
    title: 'Vendre ou louer mon bien avec une agence immobilière',
    gtmCategory: 'BusinessContent',
    bodyClass: 'real-estate-submission__page',
};

module.exports = class RealEstateSellOrRentSubmissionPage extends ApplicationPage {
    constructor() {
        super(CONFIGURATION);
    }

    open(options) {
        this.options = _.extend(options, CONFIGURATION);
        super.open(options);
    }

    getMiddleViews() {
        return new VueView({template});
    }

    getUrlPattern() {
        return '/vendre-ou-louer-mon-bien';
    }
};
