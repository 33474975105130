const $ = require('jquery');
const _ = require('lodash');

const template = require('./Chart.jade');
const Highcharts = require('../../../utils/Highcharts');
const ComponentWithAsyncLoading = require('../../mixins/ComponentWithAsyncLoading');

// @vue/component
module.exports = {
    mixins: [
        ComponentWithAsyncLoading,
    ],
    props: {
        // options contains various parts/concepts of the chart: title, axes, legend,
        // series(set of data to be plotted on the chart), ...
        options: {
            type: Object,
            required: true,
        },
    },
    watch: {
        options: 'updateChart',
    },
    mounted() {
        this.createChart();
    },
    beforeDestroy() {
        _.invoke(this._chart, 'destroy');
    },
    methods: {
        createChart() {
            Highcharts.onReady(function () {
                const {$el} = this;
                const $chartContainer = $($el);
                $chartContainer.highcharts({
                    ...this.options,
                    plotOptions: {
                        ...this.options.plotOptions,
                        series: {
                            ...this.options.plotOptions.series,
                            events: {
                                afterAnimate: _.once(() => {
                                    // Fired after animations complete (will only be executed once).
                                    this.asyncLoadingDone();
                                }),
                            },
                        },
                    },
                });
                this._chart = $chartContainer.highcharts();
            }, this);
        },
        updateChart() {
            if (this._chart) {
                _.each(this.options.series, series => {
                    const foundSeries = _.find(this._chart.series, {name: series.name});
                    if (foundSeries) {
                        foundSeries.update(series);
                    } else {
                        this._chart.addSeries(series);
                    }
                });

                const seriesToDelete = [];
                _.each(this._chart.series, series => {
                    if (!_.includes(_.map(this.options.series, 'name'), series.name)) {
                        seriesToDelete.push(series);
                    }
                });
                _.each(seriesToDelete, serie => serie.remove());
            }
        },
    },
    template: template(),
};
