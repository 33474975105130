const _ = require('lodash');

const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./withValueFromImportOrOwners.jade');

const FIELDS_WITH_DEFAULT_VALUES_TO_DISPLAY = [
    'maxHighlightedAds',
    'maxLeadingAds',
    'maxAutomaticVideosForAds',
    'maxAdsToSendInNotificationsPerMonth',
    'proOptions.localExpertBannerMention',
];

module.exports = function (wrappedComponent) {
    // @vue/component
    return {
        bemName: 'withValueFromImportOrOwners',
        mixins: [
            i18nMixin({
                prefix: 'withValueFromImportOrOwners.',
                keys: [
                    'dataFromImportTitle',
                    'useDataFromImportBtn',
                    'dataFromOwnersTitle',
                ],
            }),
        ],
        inject: {
            account: {
                default: {},
            },
        },
        inheritAttrs: false,
        props: {
            name: {
                type: String,
                required: true,
            },
            // "value" can be of any type
            // eslint-disable-next-line vue/require-prop-types
            value: {
                default: undefined,
            },
            // eslint-disable-next-line vue/require-prop-types
            defaultValue: {
                default: undefined,
            },
        },
        data() {
            const {account, name, value, defaultValue} = this;
            const path = getPathFromName(name);
            // contacts are stored as an array, but in the form, the id of the contact is used as a key to access the data through path
            const accountWithFormattedContacts = formatContacts(account);
            return {
                internalValue: _.chain(value)
                    .defaultTo(_.get(accountWithFormattedContacts, path))
                    .defaultTo(defaultValue)
                    .value(),
            };
        },
        computed: {
            wrappedComponent() {
                return wrappedComponent;
            },
            valueFromImport() {
                return this.getAccountDataValueFromPath('dataFromImport');
            },
            valueFromOwners() {
                return this.getAccountDataValueFromPath('dataFromOwners') || 0;
            },
            valueFromImportShouldBeDisplayed() {
                const {valueFromImport, internalValue} = this;
                return valueFromImport !== undefined
                    && !this.isSameValue(valueFromImport, internalValue);
            },
            valueFromOwnersShouldBeDisplayed() {
                return _.includes(FIELDS_WITH_DEFAULT_VALUES_TO_DISPLAY, this.name);
            },
            valueFromImportAsText() {
                return String(this.valueFromImport);
            },
            componentModifiers() {
                const {valueFromImportShouldBeDisplayed, valueFromOwnersShouldBeDisplayed} = this;
                return {
                    hasExtraData: valueFromImportShouldBeDisplayed || valueFromOwnersShouldBeDisplayed,
                };
            },
        },
        watch: {
            value(value) {
                this.internalValue = value;
            },
            internalValue(internalValue) {
                this.$emit('input', internalValue);
            },
        },
        methods: {
            getWrappedComponent() {
                return this.$refs.wrapped;
            },
            useValueFromImport() {
                this.internalValue = this.valueFromImport;
                this.getWrappedComponent().revalidate();
            },
            isSameValue(v0, v1) {
                const wrappedComponent = this.getWrappedComponent();
                if (wrappedComponent // the component doesn't exist at component init
                    && wrappedComponent.isSameValue) {
                    return wrappedComponent.isSameValue(v0, v1);
                } else {
                    return getCanonicalValue(v0) == getCanonicalValue(v1);
                }
            },
            getAccountDataValueFromPath(dataFromPropertyName) {
                const {account, name} = this;
                const path = getPathFromName(name);
                const dataFrom = _.get(account, dataFromPropertyName);
                // contacts are stored as an array, but in the form, the id of the contact is used as a key to access the data through path
                const dataFromWithFormattedContacts = formatContacts(dataFrom);
                return _.get(dataFromWithFormattedContacts, path);
            },
        },
        template: template(),
    };
};

function getPathFromName(name) {
    return name.replace(/^\./, '');
}

function getCanonicalValue(val) {
    return _.isString(val) ? val.trim() : val;
}

function formatContacts(data) {
    if (data) {
        return _.defaults({
            contacts: _.keyBy(data.contacts, 'id'),
        }, data);
    }
}
