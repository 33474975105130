const _ = require('lodash');
const template = require('./Details.jade');
const AddressFormatter = require('../../../../common/formatters/AddressFormatter');
const PriceFormatter = require('../../../../common/PriceFormatter');
const i18nMixin = require('../../../vue/components/mixins/i18n');

const VARIANTS = [
    'small',
];

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'Details.',
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        variant: {
            type: String,
            default: undefined,
            validator: value => _.includes(VARIANTS, value),
            values: VARIANTS,
        },
        generatedTitleWithHighlight: {
            type: String,
            default: undefined,
        },
        nearFromLocation: {
            type: String,
            default: undefined,
        },
        requireSpaceForPriceRange: Boolean,
        showDescription: Boolean,
    },
    computed: {
        hasPriceRange() {
            return PriceFormatter.isPriceRange(this.realEstateAd.price);
        },
        commonModifiers() {
            return {
                [this.variant]: this.variant !== undefined,
            };
        },
        titleContainerModifiers() {
            return {
                [this.variant]: this.variant !== undefined,
                priceRangeSpaceExtension: this.hasPriceRange && this.requireSpaceForPriceRange,
            };
        },
        formattedAddress() {
            let formattedAddress = AddressFormatter.generateCityAndDistrict(this.realEstateAd);
            const {nearFromLocation} = this;
            if (nearFromLocation) {
                formattedAddress += ` ${this.t('nearFrom', {nearFromLocation})}`;
            }
            return formattedAddress;
        },
        description() {
            return this.realEstateAd.description;
        },
    },
    template: template(),
};
