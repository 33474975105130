const _ = require('lodash');
const template = require('./ContractOrExtensionLabel.jade');
const {CONTRACT_DEFAULT_COLOR} = require('../../colors');

// @vue/component
module.exports = {
    props: {
        label: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: CONTRACT_DEFAULT_COLOR,
        },
        large: Boolean,
    },
    computed: {
        tag() {
            return this.large ? 'div' : 'kimono-badge';
        },
        backgroundColorStyle() {
            const {
                large,
                color: backgroundColor,
            } = this;
            return large ? {backgroundColor} : undefined;
        },
        modifiers() {
            return _.pick(this, 'large');
        },
    },
    template: template(),
};
