const Account = require('../authentication/Account');
const querystring = require('querystring');

module.exports = function sendAgencyContact(query, data, callback) {
    return Account.postJson({
        url: '/sendAgencyContact?' + querystring.stringify(query),
        data,
        disableErrorPage: true,
        callback,
    });
};
