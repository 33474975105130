const UrlHelper = require('../../common/UrlHelper');
const savedOptions = require('../Options').read();

const sessionReferrers = [];

module.exports = {
    init,
    getSessionReferrers,
};

function init(applicationConfiguration) {
    if (applicationConfiguration.referrer) {
        sessionReferrers.push(applicationConfiguration.referrer);
    }
    if (savedOptions.isInEmbeddedMode && document.referrer) {
        sessionReferrers.push(document.referrer);
    }
    const referrerFromUrl = UrlHelper.getURLParameter(location.href, 'referrer');
    if (referrerFromUrl) {
        sessionReferrers.push(referrerFromUrl);
    }
}

function getSessionReferrers() {
    return sessionReferrers;
}
