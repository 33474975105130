module.exports = {
    validate: function (validator, $field) {
        let value = $field.val();
        if (!value) {
            return true;
        }
        value = parseInt(value, 10);
        let factor = $field.attr('data-bv-numberfactor-value');
        if (!factor) {
            throw new Error('Missing data-bv-numberfactor attribute');
        }
        factor = parseInt(factor, 10);

        if (value % factor !== 0) {
            return {valid: false, message: $field.attr('data-bv-numberfactor-warning')};
        } else {
            return true;
        }
    },
};
