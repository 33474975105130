const moment = require('../../common/momentFr');
const _ = require('lodash');

module.exports = {
    isDateInRange,
    hasDateInRanges,
};

function isDateInRange(date, {startDate, endDate}) {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const isAfterStart = !startDate || moment(startDate, DATE_FORMAT).isSameOrBefore(date);
    const isBeforeEnd = !endDate || moment(endDate, DATE_FORMAT).isSameOrAfter(date);
    return isAfterStart && isBeforeEnd;
}

function hasDateInRanges(date, {periods}) {
    return _.isEmpty(periods) ? true : _.some(periods, period => isDateInRange(date, period));
}
