const BrowserDetect = require('browser-detect');
const _ = require('lodash');
const async = require('async');

const AbTesting = require('../AbTesting');

const SearchCriteria = require('./SearchCriteria');
const RealEstateAdLoader = require('../RealEstateAdLoader');
const Account = require('../authentication/Account');
const SavedSearches = require('../search/SavedSearches');
const {get: getDetailedSheetStatsContext} = require('../stats/DetailedSheetStatsContext');
const SearchTitleGenerator = require('../../common/SearchTitleGenerator');
const CriteriaListFormatter = require('../../common/CriteriaListFormatter');
const SearchUrl = require('../../common/SearchUrl');

module.exports = {
    sendAdContact,
    sendAdContactAndLoadSimilarAds,
};

function sendAdContact({realEstateAdIds, contact}, callback) {
    contact = _.omit(contact, ['wantsToReceiveSimilarSuggestions']); // used only to update account, must not be sent
    contact.sender.accountId = Account.getAuthenticatedAccountId();
    const {searchCriteria} = contact;
    if (searchCriteria) {
        const cleanSearchCriteria = SearchCriteria.clean(searchCriteria);
        const cleanSearchCriteriaWithLocations = _.defaults(_.pick(searchCriteria, 'locations'), cleanSearchCriteria);
        _.extend(contact, {
            search: {
                title: SearchTitleGenerator.getTitle(cleanSearchCriteriaWithLocations, 'savedSearch'),
                criteriaList: CriteriaListFormatter.getCapitalizedCriteria(cleanSearchCriteria),
                url: SearchUrl.makeSearchUrl(cleanSearchCriteria),
            },
        });
        contact.searchCriteria = cleanSearchCriteria;
        const matchingSavedSearch = SavedSearches.findMatchingSavedSearch(cleanSearchCriteria);
        if (!matchingSavedSearch) {
            contact.addCreateAlertButton = true;
        }
    }
    _.extend(contact,
        {
            abTesting: AbTesting.getSavedOptionsForContact(),
            isMobile: BrowserDetect.isMobile() || BrowserDetect.isTablet(), //for nexity
            sendEmailToPro: true,
            sendEmailToUser: true,
        },
        _.omit(getDetailedSheetStatsContext(), ['id', 'shouldSkipStat'])
    );
    Account.postJson({
        url: '/api/contactRequests',
        data: {contact, realEstateAdIds},
        disableErrorPage: true,
        callback,
    });
}

function sendAdContactAndLoadSimilarAds(options, cb) {
    async.parallel({
        contact: cb => {
            sendAdContact(options, cb);
        },
        similarAds: cb => {
            const {realEstateAdIds, searchCriteria} = options;
            RealEstateAdLoader.loadSimilarAds({
                searchCriteria,
                realEstateAdId: _.first(realEstateAdIds),
            }, (err, similarAds) => {
                if (err) {
                    console.log('Could not load similar ads', err);
                }
                cb(null, similarAds); //ignore error
            });
        },
    }, cb);
}
