const assert = require('assert');
const async = require('async');
const _ = require('lodash');

const template = require('./EmailSearchForm.jade');
const ResultCard = require('./ResultCard');
const {getEmailStatus, getAccountIdsFromEmail} = require('../index');
const validationMixin = require('../../fields/vue/validationMixin');
const i18nMixin = require('../../vue/components/mixins/i18n');

const EMAILS_SUBMITED_EVENT_NAME = 'emails-submited';

// @vue/component
module.exports = {
    components: {
        ResultCard,
    },
    mixins: [
        i18nMixin({
            prefix: 'EmailSearchForm.',
            keys: [
                'buttonText',
                'label',
                'placeholder',
            ],
        }),
        validationMixin(),
    ],
    model: {
        prop: 'emails',
        event: EMAILS_SUBMITED_EVENT_NAME,
    },
    props: {
        emails: {
            type: Array,
            default: undefined,
        },
    },
    data() {
        return {
            isSubmitting: false,
            results: [],
            accountIds: [],
            emailsInput: convertArrayToString(this.emails),
        };
    },
    watch: {
        emails() {
            this.emailsInput = convertArrayToString(this.emails);
        },
    },
    mounted() {
        if (this.emails) {
            this.submit();
        }
    },
    methods: {
        submit() {
            if (this.validateForm()) {
                this.isSubmitting = true;
                const emailsAddresses = _.chain(this.emailsInput)
                    .replace(/\s/g, '')
                    .split(',')
                    .compact()
                    .uniq()
                    .value();
                this.$emit(EMAILS_SUBMITED_EVENT_NAME, emailsAddresses);
                async.map(emailsAddresses, (email, cb) => {
                    getEmailStatusAndAccountsIdsWithNames(email, cb);
                }, (err, emailStatusAndAccountIds) => {
                    assert.strictEqual(err, null); // both requests always returns a null error
                    this.results = emailStatusAndAccountIds;
                });
                this.isSubmitting = false;
            }
        },
    },
    template: template(),
};

function convertArrayToString(emails) {
    return _.join(emails, ',');
}

function getEmailStatusAndAccountsIdsWithNames(email, cb) {
    async.parallel({
        status: _.partial(getEmailStatus, email),
        accountIds: _.partial(getAccountIdsFromEmail, email),
    }, (err, results) => {
        cb(err, _.extend({
            email,
        }, results));
    });
}
