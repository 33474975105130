const $ = require('jquery');
const fack = require('fack');
const tooltipContainerTemplate = require('./templates/tooltip.jade');
const tooltipDetailsTemplate = require('./templates/tooltipDetails.jade');
const tooltipFooterTemplate = require('./templates/stackedMarkerTooltipFooter.jade');
const Account = require('./authentication/Account');
const TextFormatter = require('../common/TextFormatter');
const RealEstateAdTitleGenerator = require('../common/RealEstateAdTitleGenerator');
const realEstateAdPriceSize = require('./utils/realEstateAdPriceSize');
const AddressFormatter = require('../common/formatters/AddressFormatter');
const {i18n: {translate}} = require('fack');
const imageResConfig = require('./imageResConfig');
const moment = require('../common/momentFr');
const _ = require('lodash');
const VirtualTourHelper = require('./virtualTours/VirtualTourHelper');
const Options = require('./Options');
const savedOptions = Options.read();
const {injectVueViews} = require('./vue/VueInjector');
const ApplicationConfig = require('./app/ApplicationConfig');
const {isAdFavorite} = require('./favorites');

module.exports = {
    getRealEstateAdTooltipContainerHTML,
    getRealEstateAdTooltipDetailsHTML,
    getRealEstateAdTooltipFooterHTML,
};

function getRealEstateAdTooltipContainerHTML() {
    return $(tooltipContainerTemplate());
}

function getRealEstateAdTooltipDetailsHTML(realEstateAd) {
    realEstateAd = VirtualTourHelper.enhanceAd(realEstateAd);
    const $element = $(tooltipDetailsTemplate({
        info: realEstateAd, //deprecated
        realEstateAd,
        TextFormatter,
        RealEstateAdTitleGenerator,
        AddressFormatter,
        resourceUrl: fack.resourceUrl,
        isAuthenticated: Account.isAuthenticated(),
        t: translate,
        photoUrlSizeQueryString: imageResConfig.photoUrlSizeQueryString('tooltipDetails'),
        moment,
        savedOptions,
        _,
    }));
    injectVueViews($element, {
        data() {
            return {
                realEstateAd,
            };
        },
        computed: {
            hasToDisplayFavoritesInCompactAdOverview() {
                return ApplicationConfig.hasToDisplayFavorites &&
                    !Account.isShowRoom() &&
                    isAdFavorite(this.realEstateAd.id); // display icon on tooltip only when ad is favorite
            },
            realEstateAdPriceSize() {
                return realEstateAdPriceSize(this.realEstateAd);
            },
        },
    });
    return $element;
}

function getRealEstateAdTooltipFooterHTML(realEstateAd) {
    if (!realEstateAd.marker) {
        console.warn('realEstateAd without marker (probably missing position)', realEstateAd);
        return null;
    }
    if (realEstateAd.marker.sameLocationMarkers
        && realEstateAd.marker.sameLocationMarkers.length > 1) {
        const templateInfo = {
            listLength: realEstateAd.marker.sameLocationMarkers.length,
        };
        return $(tooltipFooterTemplate(templateInfo));
    } else {
        return null;
    }
}
