const async = require('async');
const $ = require('jquery');
const _ = require('lodash');

const Views = require('../views/Views');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const SearchFilterView = require('./SearchFilterView');
const SearchZoneManager = require('./SearchZoneManager');
const View = require('../views/View');
const adsManager = require('./adsManager');
const SearchResults = require('./SearchResults');
const UrlFormatter = require('../../common/UrlFormatter');
const LocalStorageSavedSearch = require('../utils/localStorage/LocalStorageSavedSearch');
const AsyncHelper = require('../utils/AsyncHelper');
const sessionStorage = require('../utils/sessionStorage');
const ListView = require('./ListView');
const MapFilteredView = require('../MapFilteredView');
const SaveSearchView = require('./SaveSearchView');
const SideMapHelperView = require('../views/SideMapHelperView');
const SearchListModeSelectionView = require('./SearchListModeSelectionView');
const SearchSideView = require('./SearchSideView');
const errorResultTemplate = require('../templates/search/results/errorResult.jade');
const {i18n: {translate}} = require('fack');
const MapCreation = require('../utils/MapCreation');
const Account = require('../authentication/Account');
const BrowserDetect = require('browser-detect');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');
const blurTypes = require('../../common/blurTypes');
const EventPack = require('../utils/EventPack');
const ApplicationConfig = require('../app/ApplicationConfig');
const SearchFiltersHelper = require('../../common/SearchFiltersHelper');
const SearchCriteria = require('../utils/SearchCriteria');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const MapButtonsSearchView = require('../views/MapButtonsSearchView');
const Options = require('../Options');
const savedOptions = Options.read();
const MapApi = require('../MapApi');
const SavedSearches = require('./SavedSearches');
const SearchTitleGenerator = require('../../common/SearchTitleGenerator');
const Fields = require('./Fields');
const RealtimeServer = require('../RealtimeServer');
const sampleSizeWithSeed = require('../utils/sampleSizeWithSeed');
const safeFrameAdUtils = require('../utils/safeFrameAdUtils');
const AdvertisementsHelper = require('../views/utils/AdvertisementsHelper');
const {getSeoContentRequestFunction} = require('../utils/SearchSeoHelper');
const {DISPLAY_MODE_MAP} = require('./constants');

const CAMERA_ANIM_EXIT_AD_DURATION = 400;
const CAMERA_ANIM_WAIT_BEFORE_MOVING_DURATION = 600;
const HIGHLIGHTED_ADS_PER_PAGE_MAX_COUNT = 3;

const SEARCH_TYPE = 'search';

const BreadcrumbHelper = require('../utils/BreadcrumbHelper');

/**
 * @constructor
 * @augments Page
 * @param {object} [configuration]
 * @param {boolean} [configuration.filterEnabled]
 * @param {boolean} [configuration.mapEnabled]
 * @param {boolean} [configuration.showMap]
 * @param {function} [configuration.loadOptions] options to build load request
 * @param {boolean} [configuration.countTotalAds=false] counts unfiltered ads
 * @param {string} configuration.noAdsTemplate custom template when no ads are counted with countTotalAds=true
 * @param {string} [configuration.buyTranslation] translation to use for buy (or sell) - for SearchFilterView
 * @param {boolean} [configuration.filterTypeMultiple] is filterType a multiple select - for SearchFilterView
 * @param {boolean} [configuration.mapFilterBtnEnabled] display button enable/disable map filtering
 * @param {object} [configuration.searchDefaults] default search
 * @param {string[]} [configuration.searchDefaults.filterType] default filterType
 * @param {string[]} [configuration.searchDefaults.propertyType] default propertyType
 * @param {string} configuration.searchDefaults.sortBy field to sort on
 * @param {string} configuration.searchDefaults.sortOrder direction for sorting (asc or desc)
 * @param {boolean} [configuration.searchDefaults.newProperty] true for new only, false for old only, null for both
 * @param {boolean[]} [configuration.searchDefaults.onTheMarket] array that can contains true, false or both
 * @param {boolean} configuration.zoomToResults zoom camera to fit search results
 * @param {boolean} [configuration.saveLastSearchInLocalStorage=true] enable saving last search in local storage
 */
module.exports = class SearchView extends View {
    constructor(configuration) {
        super();
        this.configuration = _.defaultsDeep(configuration || {}, {
            filterEnabled: true,
            statsEnabled: false,
            articleEditionEnabled: false,
            enableOnTheMarketFilter: false,
            advancedFilterEnabled: true,
            keyScrollEnabled: false,
            showMap: true,
            forceMapFiltered: false,
            moreFiltersVisible: false,
            searchBtnEnabled: false,
            saveLastSearchInLocalStorage: true,
            filterTypeMultiple: false,
            mapFilterBtnEnabled: true,
            onlyExclusiveMandates: false,
            referenceFilterEnabled: false,
            loadOptions: {
                requestName: 'Load ads',
                urlSuffix: '',
            },
            minZoomForMarkers: 9,
            searchDefaults: DefaultConfiguration.search,
            enableBreadcrumb: false,
            getTitleOptions: _.noop,
            requestFullAdCallback: _.bind(this.requestFullAd, this),
            abortRequestAdCallback: _.bind(this.abortRequestAd, this),
            loadAllShrunkAdsCallback: _.bind(this._loadAllShrunkAds, this),
            loadMinimalDataAllShrunkAdsCallback: _.bind(this._loadMinimalDataAllShrunkAds, this),
        });
        this.advancedSearchView = this.configuration.advancedSearchView;
        this._sideMapHelperView = new SideMapHelperView(this.configuration);
        this.asyncHelper = new AsyncHelper();
        this.searchFilterView = null;
        this.hoveredRealEstateAd = null;
        this.selectListModeListener = _.bind(this.handleSelectListMode, this);
        this.savedSearch = null;
        this._resetScroll();
        this._mapButtonsSearchView = new MapButtonsSearchView();
        this._eventsWhileMapButtonsShown = new EventPack();
    }

    updateListMode() {
        this._sideMapHelperView.applyListMode();
        this._toggleMap();
    }

    getApplicableListMode(listMode) {
        return this._sideMapHelperView.getApplicableListMode(listMode);
    }

    show(options) {
        this._eventsWhileShown = new EventPack();
        this._eventsWhileShown.on(this._sideMapHelperView, {
            mapExpanded: fullScreen => this.emit('mapExpanded', fullScreen),
            toggledMap: visible => this.emit('toggledMap', visible),
            beforeMapVisibilityChanged: _.bind(this._beforeMapVisibilityChanged, this),
            mapVisibilityChanged: _.bind(this._mapVisibilityChanged, this),
            realEstateAdInfoChanged: _.bind(this._handleRealEstateAdInfoChanged, this),
            adClick: _.bind(this._openDetailedSheetFromMarker, this),
            realEstateAdHovered: _.bind(this._handleReadEstateAdHovered, this),
            realEstateAdUnhovered: _.bind(this._handleReadEstateAdUnhovered, this),
            cameraChanged: _.bind(this._handleCameraChanged, this),
        });

        this._isShown = true;
        this.configuration = _.extend(_.clone(this.configuration), options);
        this._views = [];
        options = options || {};
        this._setCamera(null);
        this.options = options;
        const updateUrl = options.updateUrl;
        if (updateUrl) {
            this.updateUrl = updateUrl;
        }
        const getUrlForAd = options.getUrlForAd;
        if (getUrlForAd) {
            this.getUrlForAd = getUrlForAd;
        }
        this.getPaginationUrl = options.getPaginationUrl;
        this.resultShownEventDataForGTM = null;

        this._sideMapHelperView.show(options);
        this._initListView(options);
        if (this.configuration.mapFilterBtnEnabled) {
            this._initMapFilteredView(options);
        }

        this.savedSearch = options.savedSearch;
        if (this.configuration.filterEnabled) {
            this._initSearchFilterView();
        }

        this._initSearchListModeSelectionView();
        this._initSearchSideView(options);
        this._initSaveSearchView();
        this.bodyClass = this.configuration.filterEnabled ? 'listMapFilter' : 'listMap';
        $('body').addClass(this.bodyClass);

        this._openViews(options);

        this._initHeaderView();
        this._initCamera(options);
        this.limit = null;
        this._updateCamera(); //before _setSearchCriteria to set limit
        this._setSearchCriteria(options);
        this._onSearchCriteriaUpdated();
        this.accountId = Account.getAuthenticatedAccountId();

        if (this.configuration.filterEnabled) {
            this._bindSearchFilterView(); //must be done after the program init to avoid duringInit flag
        }
        this._eventsWhileShown.on(this.searchSideView, 'changePage', _.bind(this._handleChangeResultPage, this));
        this._eventsWhileShown.on(this.listView, 'changePage', _.bind(this._handleChangeResultPage, this));

        if (this.listView && options.previousRealEstateAd) {
            this.listView.scrollToRealEstateAd(options.previousRealEstateAd, options.moreFiltersVisible);
        }

        this._eventsWhileShown.on(Account, 'change', _.bind(this.onAccountChange, this));
        // todo: move the data loading to loadData and ensure the results are displayed here
        if (options.onResultsShown) {
            this.once('resultsShown', options.onResultsShown);
        }

        const searchFilterView = this._getSearchFilterView();
        if (searchFilterView) {
            this._eventsWhileShown.on(searchFilterView, {
                zoneSaved: _.bind(this.handleMobileZoneSaved, this),
                openDrawingMap: _.bind(this.handleOpenDrawingMap, this),
            });
        }
        this._closeDrawingMapListener = _.bind(this.handleCloseDrawingMap, this);
        this.setAuthor(options);
        if (searchFilterView) {
            searchFilterView.useDebouncedChange();
        }
        if (this._shouldShowSaveSearch()) {
            this.showSaveSearch();
        }
        this._updateSearchZone();
        this._registerRealtimeEvents();
    }

    handleOpenDrawingMap() {
        const searchFilterView = this._getSearchFilterView();
        if (searchFilterView) {
            searchFilterView.on('closeDrawingMap', this._closeDrawingMapListener);
        }
    }

    handleCloseDrawingMap() {
        const searchFilterView = this._getSearchFilterView();
        if (searchFilterView) {
            searchFilterView.removeListener('closeDrawingMap', this._closeDrawingMapListener);
        }
    }

    handleMobileZoneSaved() {
        if (BrowserDetect.isMobile()) {
            this._setCamera(null);
            const locations = this._getLocations();
            this.options.search.locations = locations;
            this.options.search.locationNames = _.invokeMap(locations, 'getName');
            this.options.search.limit = null;
            this._setSearchCriteria(this.options);
            this.updateUrl({pushToHistory: true});
        }
    }

    doesWindowSupportFullScreen() {
        return this._sideMapHelperView.doesWindowSupportFullScreen();
    }

    _manageCameraOnUpdate(options) {
        this._updateForcedMapFiltered(options);
        const isMobile = BrowserDetect.isMobile();
        if (isMobile) {
            if (options.type == SEARCH_TYPE) {
                this._initCamera(options);
                this._updateCamera();
            } else {
                this._setCamera(null);
            }
        } else if (options.showMap != null && options.type == SEARCH_TYPE) {
            this._initCamera(options);
            this.handleToggleMap({
                showMap: options.showMap,
                forceUpdateShowMap: null,
            });
            this._updateCamera({
                teleport: false,
                durationInMS: CAMERA_ANIM_EXIT_AD_DURATION,
                asyncCameraOptions: {
                    waitForEventsCallback: _.bind(this._waitForEvents, this),
                    waitDelay: CAMERA_ANIM_WAIT_BEFORE_MOVING_DURATION,
                },
                onArrivalCallback: () => {
                    if (options.limit && SideMapViewSingleton.get().map) {
                        options.limit = SideMapViewSingleton.get().getEncodedLimit();
                    }
                    this.updateMapFilterView(options);
                },
            });
        } else {
            this._setCamera(null);
            this.updateMapFilterView(options);
        }
    }

    _waitForEvents(callback) {
        const eventsToWait = ['animEnded'];
        async.map(eventsToWait, (eventName, cb) => {
            this.once(eventName, cb);
        }, callback);
    }

    update(options) {
        this.options = options || {};
        this._sideMapHelperView.update(options);
        this._updateSearchZone();
        // TODO : update save search button (using saveSearchAllowed)
        const updateUrl = options.updateUrl;
        if (updateUrl) {
            this.updateUrl = updateUrl;
        }
        this.searchSideView.update(_.defaults({
            doesWindowSupportSplitScreen: this.doesWindowSupportSplitScreen(),
        }, options));
        this._onSearchCriteriaUpdated();
        this._manageCameraOnUpdate(options);
        this._updateMapButtonsSearch();
        if (options.type == SEARCH_TYPE) {
            this.restoreScroll(options);
        }
        // todo: move the data loading to loadData and ensure the results are displayed here
        const onResultsShown = options.onResultsShown;
        if (onResultsShown) {
            onResultsShown(this.resultShownEventDataForGTM);
        }
    }

    _updateMapButtonsSearch() {
        this._hideMapButtonsSearch();
        if (this._getShowMapOption()) {
            this._showMapButtonsSearch();
        }
    }

    _showMapButtonsSearch() {
        const sideMapView = SideMapViewSingleton.get();
        if (this.options.mapButtonsSearchEnabled && sideMapView.isShown()) {
            this._eventsWhileMapButtonsShown.on(this._mapButtonsSearchView, {
                zoneByTime: _.bind(this.setDrawnZone, this),
                startDrawing: _.bind(this.openDrawingView, this),
                travelByTime: _.bind(this.openTravelTimeSearch, this),
            });
            this._mapButtonsSearchView.show({$container: sideMapView.$map});
        }
    }

    _hideMapButtonsSearch() {
        this._mapButtonsSearchView.hide();
        this._eventsWhileMapButtonsShown.removeAllListeners();
    }

    setAuthor(options) {
        const {author} = options;
        if (author) {
            this.author = author;
        }
        if (this.configuration.isAnAgency) {
            this.headerView.addAgencyLogo(author);
        }
        SideMapViewSingleton.get().setAccountFilterForPoiAgency(this.author);
    }

    refreshSearchList() {
        this._loadCurrentPageIfNeeded();
        if (this._isMapShown()) {
            this._loadAdsOnMap();
        }
    }

    updateMapFilterView(options) {
        if (options.mapFilterBtnEnabled) {
            let hasLimitChanged = false;
            if (Boolean(options.limit) && options.showMap) {
                const newLimit = SideMapViewSingleton.get().getEncodedLimit();
                hasLimitChanged = newLimit != options.limit;
                if (hasLimitChanged) {
                    options.limit = newLimit;
                }
                this._setLimit(options.limit);
            }
            this._initMapFilteredView(options);
            if (!options.showMap) {
                this.mapFilteredView.hide(options);
            } else {
                this.mapFilteredView.show(options);
            }

            if (this.mapFiltered && hasLimitChanged) {
                this._onSearchFiltersChanged();
            }
        } else if (this.mapFilteredView) {
            this._updateForcedMapFiltered(options);
            this._clearMapFilteredView();
        }
    }

    getListView() {
        return this.listView;
    }

    hide(options, cb = _.noop) {
        if (this._isShown) {
            this._unregisterRealtimeEvents();
            this._eventsWhileShown.removeAllListeners();
            if (this.configuration.isAnAgency) {
                this.headerView.removeAgencyLogo();
            }
            this._isShown = false;
            this._saveSearchView.hide();
            $('body').removeClass(this.bodyClass);
            if (this.configuration.filterEnabled) {
                SearchZoneManager.removeSearchZone(); //abort ongoing zone request
            }
            this.asyncHelper.cancelAll();

            this._clearMapFilteredView();
            this._hideMapButtonsSearch();
            if (this.headerView) {
                this.headerView.hideSearchListModeSelectionView();
            }
            _.each(this._views, function (view) {
                view.hide();
            });
            this._views = [];
        }
        this._sideMapHelperView.hide(options, cb);
    }

    _openViews(options) {
        const views = [this.searchSideView];
        if (this.configuration.mapFilterBtnEnabled) {
            views.push(this.mapFilteredView);
        }
        this._views = _.compact(views);
        this.options = _.extend({
            headerTitle: this.configuration.title,
            isAdmin: Account.isAdmin(),
        }, options);
        _.each(this._views, view => {
            view.show(this.options);
        });
    }

    updateCardClass(ad) {
        this.listView.updateCardClass(ad);
    }

    setDetailedSheetAd(ad, onClickDetailedSheetMarker) {
        this._sideMapHelperView.setDetailedSheetAd(ad, onClickDetailedSheetMarker);
    }

    clearDetailedSheetAd() {
        this._sideMapHelperView.clearDetailedSheetAd();
    }

    _initMapFilteredView(options) {
        if (!this.mapFilteredView) {
            this.mapFilteredView = new MapFilteredView(options);
            this._setMapFiltered(Boolean(options.limit));
            this.mapFilterChangeListener = _.bind(this._handleMapFilterChange, this);
            this.mapFilteredView.on('mapFilterChange', this.mapFilterChangeListener);
        }
    }

    _clearMapFilteredView() {
        if (this.mapFilteredView) {
            this.mapFilteredView.hide();
            this.mapFilteredView.removeListener('mapFilterChange', this.mapFilterChangeListener);
            this.mapFilterChangeListener = null;
            this.mapFilteredView = null;
        }
    }

    _setMapFiltered(mapFiltered) {
        this.mapFiltered = mapFiltered;
    }

    _setCamera(camera) {
        this.camera = camera;
    }

    _setLimit(limit) {
        this.limit = limit;
    }

    _setLimitFromMap() {
        this._setLimit(SideMapViewSingleton.get().getEncodedLimit());
    }

    _handleMapFilterChange(isChecked) {
        this._setMapFiltered(isChecked);
        if (isChecked) {
            this._setLimitFromMap();
        } else {
            this._setLimit(null);
        }
        this._onSearchFiltersChanged({name: 'mapFilter'});
    }

    _initCamera(options) {
        this._setCamera(!_.isObject(options.camera) ? UrlFormatter.cameraFromUrl(options.camera) : options.camera);
        if (this.camera.cameraNotSet) {
            this._setCamera(null);
        }
    }

    _updateCamera(cameraOptions = {}) {
        cameraOptions = _.defaults(cameraOptions, {teleport: true});
        if (this._isMapShown()) {
            const sideMapView = SideMapViewSingleton.get();
            if (this.camera) {
                sideMapView.setCamera(this.camera, _.extend(cameraOptions, {forceCameraChanged: true}));
            } else if (this.options.type == SEARCH_TYPE) {
                const currentRealEstateAds = this._sideMapHelperView.getCurrentRealEstateAdsToFitCamera();
                const locationBounds = this._getLocationBounds();
                if (this.configuration.zoomToResults && currentRealEstateAds.length > 0 && !locationBounds) {
                    sideMapView.fitCameraToRealEstateAds(currentRealEstateAds, cameraOptions);
                } else if (this.limit) {
                    const limitBounds = sideMapView.limitToBounds(this.limit);
                    if (!limitBounds.isEmpty()) {
                        sideMapView.fitCameraToBounds(limitBounds, cameraOptions);
                    } else {
                        this._fitToLocationBounds(cameraOptions);
                    }
                } else {
                    this._fitToLocationBounds(cameraOptions);
                }
            }
        } else if (cameraOptions.onArrivalCallback) {
            cameraOptions.onArrivalCallback();
        }
    }

    _getLocationBounds() {
        const allLocationBounds = _.compact(_.invokeMap(this._getLocations(), 'getBoundingBox'));
        if (_.isEmpty(allLocationBounds)) {
            return null;
        } else {
            return _.reduce(allLocationBounds, (result, bound) => {
                return result.union(new MapApi.api.LatLngBounds({
                    lat: bound.south,
                    lon: bound.west,
                }, {
                    lat: bound.north,
                    lon: bound.east,
                }));
            }, new MapApi.api.LatLngBounds());
        }
    }

    _fitToLocationBounds(cameraOptions) {
        const bounds = this._getLocationBounds();
        if (bounds) {
            if (!this.camera) {
                SideMapViewSingleton.get().fitCameraToBounds(bounds, cameraOptions);
            }
        } else if (!this.options.zoomToResults) {
            SideMapViewSingleton.get().setDefaultBounds(cameraOptions);
        }
    }

    onAccountChange() {
        if (this._isShown && this.options.disableReloadPageOnLogin) {
            if (this.options) {
                this.options.camera = this.camera;
                this.options.limit = this.limit;
            }
            this.update(this.options);
            const currentAccountId = Account.getAuthenticatedAccountId();
            if (this.accountId !== currentAccountId) {
                this.accountId = currentAccountId;

                this._sideMapHelperView.onAccountChange();
                this._onSearchFiltersChanged();
            }
            //TODO if account.additionalFiltersActivated has changed, update searchFilterView to add geocoding & admin related stuff too
        }
    }

    setResultsListVisible(visible) {
        if (this.searchSideView) {
            this.searchSideView.setResultsListVisible(visible);
        }
    }

    _removeMapFilterClicked() {
        this._setLimit(null);
        this._setCamera(null);
        this.onFitLocationBounds();
    }

    onFitLocationBounds() {
        const searchFilterView = this._getSearchFilterView();
        const searchLocations = searchFilterView.getLocations();
        if (!searchLocations.length) {
            this._setCamera(null);
            this._updateCamera();
            if (!this.configuration.zoomToResults) {
                if (SideMapViewSingleton.get().map) {
                    SideMapViewSingleton.get().setDefaultBounds();
                }
                this._onSearchFiltersChanged({
                    name: 'location',
                    search: {
                        locations: ['france'],
                    },
                });
            } else {
                this.updateUrl({pushToHistory: true});
            }
        } else {
            this._onSearchFiltersChanged({
                name: 'location',
                search: {
                    locations: searchLocations,
                },
            });
        }
    }

    _hasGeoLocationTag() {
        const searchFilterView = this._getSearchFilterView();
        return searchFilterView && searchFilterView.hasLocationTagsTypes('circleZone');
    }

    _updateForcedMapFiltered(options) {
        if (options.forceMapFiltered) {
            let mapFiltered = true;
            if (options.search && this._hasGeoLocationTag()) {
                mapFiltered = false;
            }
            this._setMapFiltered(mapFiltered);
        }
    }

    _onSearchCriteriaUpdated() {
        this.searchSideView.onSearchFiltersChanged(this.searchCriteria, this.getCatalogueUrl());
        if (this.searchFilterView) {
            this.searchFilterView.onSearchCriteriaUpdated(this.searchCriteria);
        }
    }

    _setSearchCriteria(options) {
        if (options.search) {
            this._setLimit(options.search.limit);
        }
        this._updateForcedMapFiltered(options);
        if (this.mapFilteredView) {
            this.mapFilteredView.setValue(this.mapFiltered);
        }
        const oldSearchCriteria = this.searchCriteria;
        this.searchCriteria = _.clone(options.search || {});
        _.defaults(this.searchCriteria, this.configuration.searchDefaults);
        if (this.advancedSearchView && this.configuration.advancedFilterEnabled) {
            this.advancedSearchView.setSearchCriteria(this.searchCriteria);
        }
        if (!_.isEqual(oldSearchCriteria, this.searchCriteria)) {
            this._initFromLocation(this.searchCriteria, options.searchResults);
        }
    }

    _createSearchResults(isForMap) {
        let searchCriteria = this.searchCriteria;
        if (isForMap && BrowserDetect.isMobile() && this._hasGeoLocationTag()) {
            searchCriteria = _.omit(searchCriteria, 'locations');
        }
        const options = {
            loadOptions: this.configuration.loadOptions,
            searchCriteria,
            enableAggregates: this.searchSideView.moreFiltersOpen,
        };
        if (savedOptions.savedSearchAdsPageEnabled) {
            options.savedSearchId = _.get(this.options, 'savedSearch._id');
        }
        return new SearchResults(options);
    }

    _initFromLocation(search, searchResults) {
        search = search || {};

        const searchFilterView = this._getSearchFilterView();
        if (searchFilterView) {
            searchFilterView.setSearchForm(search);
        }

        this.computeSearchCriteria({
            updateLocalStorageDisabled: true,
            resetPageIndexDisabled: true,
        });

        // this must be done before asking ads and pins
        if (this._isMapShown()) {
            this._loadNewZone();
        } else {
            // Otherwise make sure zone is nullified to make sure that when the map is show the correct zone is created
            SearchZoneManager.removeSearchZone();
        }

        // check if searchResults was passed during request
        if (searchResults && searchResults.totalResults != null) {
            this.searchResults = searchResults;
            this._loadCurrentPageIfNeeded();
        } else {
            this._loadAllAds();
        }
        if (this._isMapShown() && SideMapViewSingleton.get().map.mapEnabled) {
            this._loadAdsOnMap();
        }
    }

    _setPage(page) {
        this.searchCriteria.page = +page;
        if (this.searchResults) {
            this.searchResults.searchCriteria.page = +page;
        }
    }

    _getPageIndex() {
        return (this.searchCriteria.page || 1) - 1;
    }

    computeSearchCriteria(options) {
        options = options || {};

        let newSearchCriteria;
        //filters
        const searchFilterView = this._getSearchFilterView();
        if (searchFilterView) {
            newSearchCriteria = searchFilterView.getFilters();
        } else {
            newSearchCriteria = _.clone(this.searchCriteria || {});
        }
        //map
        newSearchCriteria.limit = this.limit;
        if (this.searchCriteria) {
            //sorting
            newSearchCriteria.sortBy = this.searchCriteria.sortBy;
            newSearchCriteria.sortOrder = this.searchCriteria.sortOrder;
            //Page
            newSearchCriteria.page = this.searchCriteria.page;
        }

        if (newSearchCriteria.onTheMarket === undefined) {
            //apply default filter of true
            newSearchCriteria.onTheMarket = [true];
        }

        this.searchCriteria = newSearchCriteria;

        if (this.advancedSearchView) {
            this.advancedSearchView.setSearchCriteria(this.searchCriteria);
        }
        this._sideMapHelperView.updateSearchCriteria(this.searchCriteria, options.limitChanged);
        if (!options.updateLocalStorageDisabled && this.configuration.saveLastSearchInLocalStorage) {
            this._saveLastSearchInLocalStorage();
        }
        if (!options.resetPageIndexDisabled) {
            this._setPage(1);
        }
    }

    requestFullAd(realEstateAd, callback) {
        const realEstateAdId = realEstateAd.id;
        const loadOptions = {
            id: realEstateAdId,
            filters: this.searchResults.getFilters(),
        };
        this.asyncHelper.doAsync({
            func: cb => adsManager.loadRealEstateAdByIdWithFilters(loadOptions, cb),
            callback: (err, realEstateAd) => {
                if (err) {
                    if (err != 'abort') {
                        console.error('Error getting real estate ad ' + realEstateAdId + ': ', err);
                    }
                } else if (!realEstateAd) {
                    console.error('No real estate ad with id ' + realEstateAdId);
                } else {
                    callback(realEstateAd);
                }
            },
            name: 'realEstateAdForSmallMarkerHovered',
        });
    }

    abortRequestAd(requestName) {
        this.asyncHelper.cancel(requestName);
    }

    /**
     * Used to set searchState in conf
     * @access private
     */

    getReopenState() {
        return _.extend(this.getSearchState(), {
            searchResults: this.searchResults,
            headerTitle: this.getHeaderTitle(),
        });
    }

    getSearchState() {
        const search = this.searchCriteria;
        return {
            search,
            page: search.page,
            camera: this.camera,
            limit: this.limit,
            listMode: this.getListMode(),
            fullList: this.fullList,
            mapVisible: this._isMapShown(),
            savedSearch: this.savedSearch,
        };
    }

    getSearchOrExtendedSearchState() {
        const state = this.getSearchState();
        if (_.invoke(this, 'searchResults.hasExtendedResults')) {
            state.search = this.searchResults.getExtendedSearchCriteria();
        }
        return state;
    }

    _updateZoneAndCamera(eventInfo) {
        const zoneAndCamera = {
            askZone: false,
            updateCamera: false,
        };
        if (_.get(eventInfo, 'name') == 'locations') {
            //if no zone is selected, we do want to keep limit and camera (free mode)
            zoneAndCamera.updateCamera = true;
            if (_.get(eventInfo, 'value').length) {
                this._setCamera(null);
                this._setLimit(null);
                if (this._isMapShown()) {
                    zoneAndCamera.askZone = true;
                }
            } else if (this._isMapShown()) {
                if (this.mapFiltered) {
                    this._setLimitFromMap();
                } else {
                    this._setLimit(null);
                }
                if (!this.configuration.zoomToResults) {
                    this._setCamera(SideMapViewSingleton.get().getCameraFromMap());
                }
                zoneAndCamera.askZone = true;
            }
        }
        return zoneAndCamera;
    }

    _onSearchFiltersChanged(eventInfo) {
        const zoneAndCamera = this._updateZoneAndCamera(eventInfo);
        this._sideMapHelperView.disableSmallMarkers();
        this.computeSearchCriteria();
        this._onSearchCriteriaUpdated();

        if (this.updateUrl) {
            this.updateUrl({pushToHistory: true});
        }
        if (zoneAndCamera.updateCamera) {
            this._updateCamera();
        } else if (this._isMapShown()) {
            this._loadAdsOnMap();
        }

        this._sideMapHelperView.enableSmallMarkers();

        if (zoneAndCamera.askZone) {
            this._loadNewZone();
        }
        if (BrowserDetect.isTablet()) {
            this.cameraChanged = false;
            if (!this._eventsDuringRequestHandler) { // if keyboard open it changes the camera fixed bug#123
                const eventsDuringRequestHandler = this._eventsDuringRequestHandler = new EventPack();
                eventsDuringRequestHandler.on(this._sideMapHelperView, {
                    cameraChanged: () => {
                        this.cameraChanged = true;
                    },
                });
            }
        }
        if (!sessionStorage.getValue('closedAd')) {
            const adData = AdvertisementsHelper.getAdsData({
                page: 'searchResults',
                type: 'fullWidth',
                searchCriteria: this.searchCriteria,
                safeFrame: true,
            });
            const adUrl = adData.toAdvertisementUrl('hp', 0, true);
            // get the previous value of adDisplayed session item
            const prevAdDisplayingState = sessionStorage.getValue('adDisplayed');
            sessionStorage.remove('adDisplayed');
            // load the ad in hidden iframe to check if we have an ad or an empty html
            $('#hiddenIframe').attr('src', adUrl);
            sessionStorage.waitForItem('adDisplayed', (err, value) => {
                if (err) {
                    console.error(err);
                } else if (value && prevAdDisplayingState !== value) {
                    $('#hiddenIframe').empty();
                    const {options, searchCriteria, configuration} = this;
                    safeFrameAdUtils.renderSafeFrameAdOnSearchResults(options, searchCriteria, configuration.name);
                }
            });
        }
        this._loadAllAds();
    }

    getCatalogueUrl() {
        const filters = _.defaults({}, this.searchCriteria, _.get(this.configuration, 'loadOptions.filters'));
        return '/catalogue-xml?filters=' + encodeURIComponent(JSON.stringify(SearchCriteria.clean(filters))) +
            '&access_token=' + encodeURIComponent(Account.getAccessToken());
    }

    _loadAllAds() {
        this.emit('loadAllAds');
        this.searchResults = this._createSearchResults();
        this._updateSearchPageTitle(); //set title in searchResults
        this._loadCurrentPageIfNeeded();
    }

    _saveLastSearchInLocalStorage() {
        LocalStorageSavedSearch.save(this.searchCriteria);
    }

    /**
     * @private
     */

    _loadAggregateResults() {
        if (!this.searchResults.filterCounts) {
            this.asyncHelper.doAsync({
                func: _.bind(this.searchResults.loadAggregates, this.searchResults),
                callback: err => {
                    if (err) {
                        console.warn('Cannot load aggregates ', err);
                    } else {
                        this.searchFilterView.updateAdvancedFilterCounts(this.searchResults);
                    }
                },
                name: 'loadAggregateResults',
            });
        }
    }

    /**
     * @private
     */

    _onSortChanged(sortBy, sortOrder) {
        this.searchCriteria.sortBy = sortBy;
        this.searchCriteria.sortOrder = sortOrder;
        this.computeSearchCriteria();
        this.updateUrl({pushToHistory: true});
        this.searchResults = this._createSearchResults();
        this._loadCurrentPage();
    }

    _loadCurrentPageIfNeeded() {
        if (this.configuration.keyScrollEnabled) {
            this.listView.takeFocus();
        }
        const pageIndex = this._getPageIndex();
        if (this.searchResults.getPage(pageIndex)) {
            this.listView.hideSearchResults();
            this._handlePageLoaded(pageIndex);
        } else {
            this._loadCurrentPage();
        }
    }

    _loadCurrentPage() {
        if (this.configuration.keyScrollEnabled) {
            this.listView.takeFocus();
        }
        if (this.searchSideView) {
            this.searchSideView.setLoading();
        }
        this.listView.hideSearchResults();
        const pageIndex = this._getPageIndex();
        this._showWaitingForResults();
        this.asyncHelper.doAsync({
            func: cb => this._loadPage(pageIndex, cb),
            callback: _.bind(this._handlePageLoaded, this),
            name: 'loadPage',
        });
    }

    _loadPage(pageIndex, cb) {
        let loadPageRequest;
        let countAdRequest;
        async.auto({
            ads: callback => {
                loadPageRequest = this.searchResults.loadPage(pageIndex, callback);
            },
            totalAdsCount: callback => {
                //counting is done in parallel to loadPage, since most people will open pages when they expect results
                // for those pages. also it speeds up results
                // TODO don't need to count all ads on filters/sort/camera change
                if (this.configuration.countTotalAds) {
                    const {urlSuffix, filters} = this.configuration.loadOptions;
                    countAdRequest = adsManager.countAds({
                        name: urlSuffix,
                        filters,
                    }, callback);
                } else {
                    setImmediate(callback);
                }
            },
            clearSavedSearchWebNotifications: callback => {
                if (this.savedSearch) {
                    SavedSearches.clearSavedSearchWebNotifications(this.savedSearch._id, callback);
                } else {
                    setImmediate(callback);
                }
            },
            hierarchyData: callback => {
                BreadcrumbHelper.loadHierarchy(_.result(this._getSearchFilterView(), 'getLocations'), callback);
            },
            loadSeoContentForSearch: [
                'ads',
                callback => {
                    const seoContentRequestFunction = getSeoContentRequestFunction(this.searchCriteria, {
                        isExtendedWithSearchAround: this.searchResults.hasSearchAroundExtendedResults(),
                        totalResults: this.searchResults.totalResults,
                    });
                    if (_.isFunction(seoContentRequestFunction)) {
                        seoContentRequestFunction((err, data) => {
                            if (!err && data) {
                                this.searchSideView.vueData.seoContent = data.htmlContent;
                                this.searchSideView.vueData.seoLinksCriteria = data.seoLinks;
                            }
                            setImmediate(callback);
                        });
                    } else {
                        setImmediate(callback);
                    }
                },
            ],
        }, (err, results) => {
            if (!err) {
                if (results.totalAdsCount != null) {
                    this.totalAdsCount = results.totalAdsCount;
                }
                if (this.searchResults.pageIndex != pageIndex) {
                    pageIndex = this.searchResults.pageIndex;
                    this._setPage(pageIndex + 1);
                    this.updateUrl({pushToHistory: false});
                }
            }
            cb(pageIndex, err);
        });

        return {
            abort: () => {
                if (loadPageRequest) {
                    loadPageRequest.abort();
                }
                if (countAdRequest) {
                    countAdRequest.abort();
                }
            },
        };

    }

    _getAdvancedFiltersCount() {
        return SearchFiltersHelper.getAdvancedFiltersCount(this.searchCriteria);
    }

    _shouldDisplayCommercialAds() {
        return this.configuration.commercialAdsAllowed && Options.get('commercialAdsEnabled');
    }

    // eslint-disable-next-line complexity
    _handlePageLoaded(pageIndex, err) {
        const eventsDuringRequestHandler = this._eventsDuringRequestHandler;
        if (BrowserDetect.isTablet() && eventsDuringRequestHandler) {
            eventsDuringRequestHandler.removeAllListeners();
            delete this._eventsDuringRequestHandler;
        }
        if (this._isMapShown()) {
            if (this.mapFiltered && _.invoke(SideMapViewSingleton.get(), 'isCameraMoving') && this._skipListResultsOnMove) {
                return;
            }
            if (this.cameraChanged) {
                this._updateCamera();
            }
        }
        this._skipListResultsOnMove = false;
        if (err && err.status == 'abort') {
            console.debug('handleAds abort');
            return;
        }
        if (this.searchSideView && this.searchSideView.$element) {
            this.searchSideView.$element.find('editFilters').show();
        }

        if (err) {
            console.error(`Error while loading ads from page number ${pageIndex + 1}`, err);
            this._showNoResults({
                $element: $(errorResultTemplate({
                    t: translate,
                })),
            });
        } else if (this.totalAdsCount === 0 && this.configuration.noAdsTemplate) {
            const isMobile = BrowserDetect.isMobile() || BrowserDetect.isTablet();
            this._showNoResults({
                $element: $(this.configuration.noAdsTemplate(
                    _.extend(this.configuration.noAdsTemplateOptions || {}, {
                        t: translate,
                        canPublishAd: Account.isAdPublisher(),
                        isMobile,
                    })
                )),
            });
        } else {
            const filters = this.searchResults.searchCriteria;
            let realEstateAds = this.searchResults.getPage(pageIndex);
            let leadingAds = this.searchResults.getLeadingAds(pageIndex);
            realEstateAds = _.map(realEstateAds, function (realEstateAd) {
                return VirtualTourHelper.enhanceAd(realEstateAd);
            });
            if (leadingAds) {
                leadingAds = _.map(leadingAds, function (realEstateAd) {
                    return VirtualTourHelper.enhanceAd(realEstateAd);
                });
            }
            const {
                statsEnabled,
                articleEditionEnabled,
                showOpenSearchBtnIfNoResult,
                authorId,
            } = this.configuration;

            if (realEstateAds) {
                const displayedAsHighlightedAdIds =
                    this.displayedAsHighlightedAdIds = getDisplayedAsHighlightedAdIds(realEstateAds);
                const options = {
                    realEstateAds,
                    leadingAds,
                    searchResults: this.searchResults,
                    pageIndex,
                    sortBy: filters.sortBy,
                    sortOrder: filters.sortOrder,
                    adType: filters.filterType,
                    locations: filters.locations,
                    takeFocus: this.listView.hasFocus(),
                    totalResults: this.totalAdsCount,
                    statsEnabled,
                    articleEditionEnabled,
                    showOpenSearchBtnIfNoResult,
                    showRemoveMapFilter: Boolean(this.limit && this.advancedSearchView),
                    authorId,
                    commercialAdsEnabledInSearchResults: this._shouldDisplayCommercialAds(),
                    lastViewDateForSearch: _.get(this.options, 'savedSearch.lastViewDateForSearch'),
                    displayedAsHighlightedAdIds,
                };
                this._showResults(options);
            } else {
                console.error('no results in this.searchResults for page ', pageIndex);
            }
        }
        this._updateSearchPageTitle();
        safeFrameAdUtils.updateResultsListMaxHeight();
    }

    _loadMapResults(searchResults, requestName, options) {
        this.asyncHelper.doAsync({
            func: cb => searchResults.loadAdsOnMap(options, cb),
            name: requestName,
            callback: (err, realEstateAds) => {
                const sideMapHelperView = this._sideMapHelperView;
                if (err) {
                    console.warn('Cannot load ads on map: ', err);
                    sideMapHelperView.clearMapMarkers(requestName); //reset ads on error, so it will disappear on the next call of this._sideMapHelperView._showMarkers
                } else {
                    sideMapHelperView.showMapMarkers(realEstateAds, searchResults.searchCriteria, requestName);
                }
            },
        });
    }

    _loadAdsOnMap() {
        const options = {
            limit: SideMapViewSingleton.get().getEncodedLimit(),
        };
        if (this._isMapShown()) {
            const searchResults = this._createSearchResults(true);
            const newProperty = searchResults && searchResults.searchCriteria ? searchResults.searchCriteria.newProperty : null;
            const sideMapHelperView = this._sideMapHelperView;
            const newAdsRequestName = 'loadNewAds';
            if (newProperty) {
                this._loadMapResults(searchResults, newAdsRequestName, _.extend({
                    'propertyType.base': ['programme'],
                    programmeWith3dFirst: true,
                }, options));
            } else {
                sideMapHelperView.clearMapMarkers(newAdsRequestName);
                this.asyncHelper.cancel(newAdsRequestName);
            }
            const oldAdsRequestName = 'loadOldAds';
            if (!newProperty) {
                this._loadMapResults(searchResults, oldAdsRequestName, _.extend({
                    newProperty: false,
                }, options));
            } else {
                sideMapHelperView.clearMapMarkers(oldAdsRequestName);
                this.asyncHelper.cancel(oldAdsRequestName);
            }
        }
    }

    handleSelectListMode(mode) {
        if (BrowserDetect.isMobile() && mode == DISPLAY_MODE_MAP) {
            this.saveScroll();
            this.setResultsListVisible(false);
        }
        this._sideMapHelperView.setListMode(mode);
        this._toggleMap();
        this.updateUrl({pushToHistory: false}); //after setListMode
        this.emit('listModeChanged', mode);
        const options = this.options;
        if (BrowserDetect.isMobile() && mode != DISPLAY_MODE_MAP && options.type == SEARCH_TYPE) {
            this.refreshSearchList();
            this.restoreScroll(options);
        }
    }

    _toggleMap() {
        this.handleToggleMap({
            showMap: this.getListMode() == DISPLAY_MODE_MAP,
            forceUpdateShowMap: null,
        });
    }

    _mapVisibilityChanged(visible) {
        if (visible) {
            this._loadNewZone();
            if (this.mapFilteredView) {
                this.mapFilteredView.show(this.options);
            }
            this._showMapButtonsSearch();
        } else {
            SearchZoneManager.removeSearchZone();
            if (this.mapFilteredView) {
                this.mapFilteredView.hide(this.options);
            }
            this._hideMapButtonsSearch();
        }
    }

    _beforeMapVisibilityChanged(visible) {
        if (visible && this._sideMapHelperView.isMapLoaded() && this.options.type == SEARCH_TYPE) {
            //camera already set by detailed sheet
            this._updateCamera();
        }
    }

    handleToggleMap(options) {
        this._sideMapHelperView.handleToggleMap(options);
    }

    openTravelTimeSearch() {
        this._getSearchFilterView().openTravelTimeSearch({
            closeCallback: () => {
                this._updateSearchZone();
            },
        });
    }

    _updateSearchZone() {
        SearchZoneManager.updateSearchZone({travelTimeMarkersClickableAndDraggable: this.options.type == SEARCH_TYPE});
    }

    openDrawingView() {
        const searchFilterView = this._getSearchFilterView();
        searchFilterView.showDrawingMap();
    }

    getListMode() {
        return this._sideMapHelperView.getListMode();
    }

    _handleChangeResultPage(newResultPage, {allowCriteriaChange = true} = {}) {
        this._setPage(newResultPage);
        if (allowCriteriaChange && _.invoke(this, 'searchResults.hasExtendedResults')) {
            this._setSearchCriteria({search: this.searchResults.getExtendedSearchCriteria()});
            this._onSearchCriteriaUpdated();
        }
        this.updateUrl();
        this._loadCurrentPageIfNeeded();
        if (this._isMapShown()) {
            this._loadAdsOnMap();
        }
    }

    _updateSearchPageTitle() {
        let metaDescription;
        if (this.searchResults) {
            metaDescription = SearchTitleGenerator.getTitle(this.searchResults.titleOptions, 'metaDescription');
        }
        const headerTitle = this.getHeaderTitle();
        this.emit('updateTitle', {
            title: this.getPageTitle(),
            headerTitle,
            metaDescription,
        });
    }

    getName() {
        return 'search';
    }

    getPageTitle() {
        return this._generateTitle('title');
    }

    getHeaderTitle() {
        const {searchPageName} = this.configuration.getTitleOptions() || {};
        const [headerTitleFirstPart, resumeSubtitle] = _.map(['h1', 'resumeSubtitle'], _.bind(this._generateTitle, this));
        let headerTitle = headerTitleFirstPart;
        if (_.includes(['search', 'userRealEstateAds'], searchPageName) && resumeSubtitle) {
            headerTitle += ` (${resumeSubtitle})`;
        }
        return headerTitle;
    }

    _generateTitle(titleKey) {
        const options = _.extend({}, this.configuration.getTitleOptions(), this.searchCriteria);
        const titleOptions = this.configuration.getTitleOptions(titleKey);
        return SearchTitleGenerator.getTitle(options, titleKey, titleOptions);
    }

    _handleCameraChanged(eventInfo) {
        if (eventInfo.isHuman) {
            this._setCamera(SideMapViewSingleton.get().getCameraFromMap());
            // we must not change search results when in detailedSheet map
            if (this.mapFiltered && (this.options.forceMapFiltered || this.options.mapFilterBtnEnabled)) {
                this._resetScroll();
                this._setLimitFromMap();
                this._skipListResultsOnMove = true;
                this.computeSearchCriteria({
                    limitChanged: true,
                });
                this._loadAllAds();
            }

            _.defer(() => {
                if (this._isShown) {
                    this.updateUrl({pushToHistory: false});
                }
            });
        }
        this._loadAdsOnMap();
    }

    _getSearchFilterView() {
        return this.searchFilterView || (this.advancedSearchView ? this.advancedSearchView.searchFilterView : null);
    }

    _loadNewZone() {
        const circleZoneItem = BrowserDetect.isMobile() && this._getFirstSearchAroundMe();
        if (circleZoneItem) {
            SideMapViewSingleton.get().setGeoLocationCircleAndMarker(circleZoneItem, true);
        } else {
            const searchLocationInfos = this._getSearchLocationInfos();
            if (this._getSearchFilterView()) {
                this._setTravelTimeZoneCallback(searchLocationInfos);
            }
            this._displayZone(searchLocationInfos);
        }
    }

    _getSearchLocationInfos() {
        const locations = this._getLocations();
        return _.invokeMap(locations, 'getZoneInfos');
    }

    _getFirstSearchAroundMe() {
        const circleZoneSuggestion = _.first(this._getLocations());
        return (circleZoneSuggestion && circleZoneSuggestion.isAroundMe) ? circleZoneSuggestion.getItem() : null;
    }

    _displayZone(searchLocationInfos) {
        SearchZoneManager.loadSearchZone(searchLocationInfos, zone => {
            if (zone) {
                SideMapViewSingleton.get().addOverlay(zone);
            }
        });
    }

    _getLocations() {
        const searchFilterView = this._getSearchFilterView();
        return searchFilterView ? searchFilterView.getLocations() : this.searchCriteria.locations;
    }

    _setTravelTimeZoneCallback(searchLocationInfos) {
        const openTravelTimeSearchCallback = _.bind(this.openTravelTimeSearch, this);
        _.each(searchLocationInfos, locationInfo => {
            if (locationInfo.type == 'travelTimeZone') {
                _.extend(locationInfo.travelTimeInfos, {openTravelTimeSearchCallback});
            }
        });
    }

    _initListView(options) {
        const listView = this.listView = new ListView(_.extend({
            searchListModeModel: this._getSearchListModeModel(),
        }, _.pick(options, 'saveSearchAllowed', 'dataModeListEnabled', 'author', 'from')));

        this._eventsWhileShown.on(listView, {
            resultInListHovered: realEstateAd => {
                this.hoverAd(realEstateAd);
            },
            resultInListLeft: realEstateAd => {
                this.unHoverAd(realEstateAd);
            },
            realEstateAdClick: (realEstateAd, context) => {
                this._openDetailedSheet({realEstateAd, openedFromList: 'fromList', context});
            },
            saveSearchClick: _.bind(this._onSaveSearchClick, this),
            removeAlertClick: _.bind(this._onRemoveAlertClick, this),
        });
    }

    _loadAllShrunkAds(query, options, cb) {
        let filters = _.extend(query, this.configuration.loadOptions.filters);
        filters.blurInfoType = blurTypes.DISPLAYED_ON_MAP;
        filters = _.omit(filters, ['locations', 'zoneIdsByTypes']);
        return adsManager.loadAllShrunkAds({
            name: this.configuration.loadOptions.urlSuffix,
            filters,
            geoHashPrecision: options.geoHashPrecision,
            minimalData: options.minimalData,
        }, cb);
    }

    _loadMinimalDataAllShrunkAds(query, options, cb) {
        options.minimalData = true;
        this._loadAllShrunkAds(query, options, cb);
    }

    _initSearchFilterView() {
        const referenceSearcher = Account.hasRole('referenceSearcher');
        const geocodingSearcher = Account.hasRole('geocodingSearcher');
        const {
            filterTypeMultiple,
            searchWithoutAgencyBtnEnabled,
            saveSearchAllowed,
            enableOnTheMarketFilter,
            referenceFilterEnabled,
            onlyExclusiveMandates,
            searchBtnEnabled,
        } = this.configuration;
        this.searchFilterView = new SearchFilterView({
            filterTypeMultiple: filterTypeMultiple || Account.isAdmin(),
            naturalLanguageEnabled: false,
            moreFiltersEnabled: true,
            hideableMoreFiltersEnabled: true,
            searchWithoutAgencyBtnEnabled,
            saveSearchAllowed,
            fields: _.extend({
                filterType: true,
                //location: true, //too tricky to make generic, depends on $searchButton
                onTheMarket: enableOnTheMarketFilter || Account.isOnTheMarketFilterViewer(),
                reference: referenceFilterEnabled || referenceSearcher,
                geocoding: geocodingSearcher,
                exclusiveMandate: !onlyExclusiveMandates,
                searchBtn: searchBtnEnabled,
            }, Fields.getCommonFields(), Fields.getOptionalFields()),
            style: 'rightAligned',
        });
        this._eventsWhileShown.on(this.searchFilterView, {
            searchWithoutAgency: options => {
                this.emit('searchWithoutAgency', options);
            },
            saveSearchClick: _.bind(this._onSaveSearchClick, this),
            removeAlertClick: _.bind(this._onRemoveAlertClick, this),
        });
    }

    _handleReadEstateAdHovered(realEstateAd) {
        if (this.listView && this.isShown()) {
            this.listView.toggleRealEstateAdHighlight(realEstateAd, true);
        }
    }

    _handleReadEstateAdUnhovered(realEstateAd) {
        if (this.listView && this.isShown()) {
            this.listView.toggleRealEstateAdHighlight(realEstateAd, false);
        }
    }

    _bindSearchFilterView() {
        this._eventsWhileShown.on(this.searchFilterView, {
            change: _.bind(this._onSearchFiltersChanged, this),
            fitLocationBounds: _.bind(this.onFitLocationBounds, this),
        });
    }

    _initSearchListModeSelectionView() {
        this.searchListModeSelectionView = new SearchListModeSelectionView({
            searchListModeModel: this._getSearchListModeModel(),
            dataModeListEnabled: this.options.dataModeListEnabled,
        });
        this._eventsWhileShown.on(this.searchListModeSelectionView, 'selectMode', this.selectListModeListener);
    }

    _initSearchSideView({htmlFooterContent}) {
        const options = {
            searchListModeModel: this._getSearchListModeModel(),
            searchFilterView: this.searchFilterView,
            listView: this.listView,
            searchListModeSelectionView: this.searchListModeSelectionView,
            moreFiltersVisible: this.configuration.moreFiltersVisible,
            disableSearchSideHeader: this.configuration.disableSearchSideHeader,
            saveSearchAllowed: this.configuration.saveSearchAllowed,
            hiddenOverflow: this.configuration.resumeHiddenOverflow,
            hasSearchPageSplitScreen: this.getSplitScreenStatus(),
            doesWindowSupportSplitScreen: this.doesWindowSupportSplitScreen(),
            enableBreadcrumb: this.configuration.enableBreadcrumb,
            footerView: this.configuration.footerView,
            getPaginationUrl: this.getPaginationUrl,
            sortingEnabled: this.configuration.sortingEnabled,
            savedSearchResumeEnabled: this.configuration.savedSearchResumeEnabled,
            savedSearch: this.configuration.savedSearch,
            searchResumeView: this.configuration.searchResumeView,
            enableSeoContent: this.configuration.enableSeoContent,
            getTitleOptions: this.configuration.getTitleOptions,
            htmlFooterContent,
        };
        this.searchSideView = new SearchSideView(options);
        this._eventsWhileShown.on(this.searchSideView, {
            sortChanged: _.bind(this._onSortChanged, this),
            saveSearchClick: _.bind(this._onSaveSearchClick, this),
            removeAlertClick: _.bind(this._onRemoveAlertClick, this),
            removeMapFilterClicked: _.bind(this._removeMapFilterClicked, this),
            moreFiltersOpen: _.bind(this._loadAggregateResults, this),
        });
    }

    _initHeaderView() {
        this.headerView = Views.header;
        if (this.headerView) {
            this.headerView.showSearchListModeSelectionView(this._getSearchListModeModel());
            this._eventsWhileShown.on(this.headerView, {
                saveSearchClick: _.bind(this._onSaveSearchClick, this),
                removeAlertClick: _.bind(this._onRemoveAlertClick, this),
                selectSearchListMode: this.selectListModeListener,
            });
        }
    }

    _initSaveSearchView() {
        this._saveSearchView = new SaveSearchView();
        this._eventsWhileShown.on(this._saveSearchView, {
            savedSearchCreated: _.bind(this._updateAlerts, this),
            updateSearch: _.bind(this._updateAlerts, this),
        });
    }

    showSaveSearch() {
        this._onSaveSearchClick();
    }

    _onSaveSearchClick(extendedCriteria) {
        const advancedFiltersCount = this._getAdvancedFiltersCount();
        this._saveSearchView.show({
            search: {
                saveName: SearchTitleGenerator.getTitle(this.searchCriteria, 'savedSearch'),
                searchCriteria: _.extend({}, this.searchCriteria, extendedCriteria),
                isUsingExtendedFilters: Boolean(extendedCriteria),
            },
            pitchKey: 'pitch',
            suffix: 'criteria',
            criteriaSummaryEnabled: true,
            criteriaModificationEnabled: true,
            criteriaModificationHidden: true,
            advancedCriteriaEnabled: true,
            advancedCriteriaHidden: advancedFiltersCount === 0,
            frequencyModificationEnabled: false,
            searchClassesAdded: 'searchFilterView saveSearchWithSearchFiltersView',
            $targetZone: this.$targetZone,
            modalType: 'criteria',
            optionalFields: {
                exclusiveMandate: !this.configuration.onlyExclusiveMandates,
            },
        });
    }

    _onRemoveAlertClick(savedSearchId) {
        if (savedSearchId != null) {
            this._saveSearchView.removeWithConfirmation(savedSearchId, _.bind(this._updateAlerts, this));
        } else {
            // this can happen if an error occurred while removing the alert, but this one was still removed
            this._updateAlerts();
        }
    }

    _updateAlerts() {
        if (this.searchFilterView) {
            this.searchFilterView.refreshAlert();
        }
        if (this.headerView) {
            this.headerView.updateAlert();
        }
    }

    _handleRealEstateAdInfoChanged(realEstateAd) {
        this.listView.scrollToRealEstateAd(realEstateAd, this.options.moreFiltersVisible);
    }

    _showResults(options) {
        const {realEstateAds, leadingAds, searchResults: {totalResults}, displayedAsHighlightedAdIds} = options;
        const adsInList = realEstateAds.concat(leadingAds);
        this.showListMarkers(adsInList);
        // in Mobile if the map is in fullscreen, skip refreshing undisplayed searchReultsList
        // searchList will be displayed later when going back to list
        if (!BrowserDetect.isMobile() || !this._getShowMapOption()) {
            this.searchSideView.showResults(_.extend({
                getUrlFunction: this.getUrlForAd,
            }, options));
        }
        if (this.configuration.zoomToResults) {
            this._updateCamera();
        }

        //Favorites & live stats
        this.emit('realEstateAds', adsInList);

        const data = {
            realEstateAds,
            leadingAds,
            searchCriteria: this.searchCriteria,
            mode: this.getListMode(),
            totalResults,
            displayedAsHighlightedAdIds,
        };
        if (this._isMapShown()) {
            data.mapStats = MapCreation.getMapStats(SideMapViewSingleton.get().map);
        }
        this.resultShownEventDataForGTM = data;

        const searchFilterView = this._getSearchFilterView();
        if (searchFilterView) {
            if (!searchFilterView.isMoreFiltersOpen) {
                this._sendResultsShown(data);
                searchFilterView.dataBeforeMoreFiltersOpen = data;
            } else {
                searchFilterView.dataAfterMoreFiltersOpen = data;
            }
        } else {
            this._sendResultsShown(data);
        }
    }

    _sendResultsShown(data) {
        this.emit('resultsShown', data);
    }

    _showNoResults(options) {
        this.showListMarkers([]);
        options.showRemoveMapFilter = Boolean(this.limit) && Boolean(this.advancedSearchView);
        this.searchSideView.showNoResults(options);
        this.emit('realEstateAds', []);
        this._sendResultsShown([]);
    }

    showListMarkers(realEstateAds) {
        this._sideMapHelperView.showListMarkers(realEstateAds, this.searchResults.searchCriteria);
    }

    _showWaitingForResults() {
        this.searchSideView.showWaitingForResults();
    }

    restoreScroll(options) {
        if (this._windowScrollTopInSearch != null) {
            this.setResultsListVisible(true);
            window.scrollTo(0, this._windowScrollTopInSearch);
        } else if (options.previousRealEstateAd && this.listView) {
            this.listView.scrollToRealEstateAd(options.previousRealEstateAd, options.moreFiltersVisible);
        }
    }

    saveScroll() {
        if (this.options.type == SEARCH_TYPE) {
            this._windowScrollTopInSearch = $(window).scrollTop();
        }
    }

    _resetScroll() {
        this._windowScrollTopInSearch = 0;
    }

    _openDetailedSheetFromMarker(options) {
        this._openDetailedSheet(_.extend({}, options, {context: this.options.context}));
    }

    _openDetailedSheet({realEstateAd, scrollTo, openedFromList, context}) {
        const {id: realEstateAdId, isSmallMarker} = realEstateAd;
        this.unHoverAd(realEstateAd);
        this.saveScroll();
        let isHighlightedInList = false;
        if (!_.isEmpty(this.displayedAsHighlightedAdIds)) {
            const inHighlightedBox = context && context.inHighlightedBox;
            isHighlightedInList = !inHighlightedBox && _.includes(this.displayedAsHighlightedAdIds, realEstateAdId);
        }
        // Hide blur overlay before creating the new one for the detailed sheet view
        // (overlay is shared between searchview hover and detailedsheet so can be removed by the mouse out event)
        this.emit('adClick', {
            incompleteRealEstateAd: (!realEstateAd || isSmallMarker) ? null : realEstateAd,
            realEstateAd,
            realEstateAdId,
            scrollTo: scrollTo || _.get(context, 'scrollTo'),
            contactOptions: _.get(context, 'contactOptions'),
            context,
            openedFromList,
            lastMapVisible: this._isMapShown(),
            limit: this.mapFiltered ? this.limit : null,
            headerTitle: this.getHeaderTitle(),
            isHighlightedInList,
        });
    }

    getSplitScreenStatus() {
        return this._sideMapHelperView.getSplitScreenStatus();
    }

    doesWindowSupportSplitScreen() {
        return this._sideMapHelperView.doesWindowSupportSplitScreen();
    }

    updateAdViewedStatus(realEstateAd) {
        const date = new Date();
        if (realEstateAd) {
            realEstateAd.userRelativeData.lastViewDate = date;
        }
        const adIndex = this.searchResults.findAdIndex(realEstateAd);
        if (adIndex >= 0) {
            this.searchResults.getAd(adIndex, (error, ad) => {
                if (error) {
                    console.error(error, `Cannot update view status for ad ${realEstateAd.id}`);
                } else {
                    ad.userRelativeData.lastViewDate = date;
                    this._sideMapHelperView.updateAdIcon(ad);
                }
            });
        }
    }

    onGeolocationClicked(cb) {
        this._getSearchFilterView().launchSearchAroundMe(cb);
    }

    setDrawnZone(zone) {
        const searchFilterView = this._getSearchFilterView();
        searchFilterView.setDrawnZone(zone);
        this.handleMobileZoneSaved();
        if (this._isMapShown()) {
            this._fitToLocationBounds();
        }
    }

    getJSONLD() {
        return BreadcrumbHelper.getJSONLD();
    }

    loadData(options, cb) {
        SavedSearches.waitForUndergoingSearchesLoading(err => {
            cb(err, options);
        });
        if (this.listView) {
            this.listView.updateOptions(options);
        }
    }

    _registerRealtimeEvents() {
        const realtimeContext = this._realtimeContext = RealtimeServer.openContext();
        realtimeContext.on('adPhoneDisplayed', _.bind(this.refreshAdOnUserEvent, this, _, 'phoneDisplays'));
    }

    refreshAdOnUserEvent({adId, contactRequest, phoneDisplay}, eventsArrayKey) {
        const userEvent = contactRequest || phoneDisplay;
        if (this.searchResults) {
            this.searchResults.updateAd(adId, _.partial(addUserEventInAd, _, userEvent, eventsArrayKey));
            const ad = this.searchResults.getAdById(adId);
            if (ad) {
                this.listView.refreshAdDisplay(ad);
            }
        }
    }

    _unregisterRealtimeEvents() {
        const realtimeContext = this._realtimeContext;
        if (realtimeContext) {
            realtimeContext.close();
        }
    }

    clearPagesCache() {
        if (this.searchResults) {
            this.searchResults.clearPagesCache();
        }
    }

    getSetHoverEnabledCallback() {
        return this._sideMapHelperView.getSetHoverEnabledCallback();
    }

    setMapModeAvailable(mapModeAvailable) {
        this._sideMapHelperView.setMapModeAvailable(mapModeAvailable);
    }

    _getSearchListModeModel() {
        return this._sideMapHelperView.getSearchListModeModel();
    }

    _isMapShown() {
        return this._sideMapHelperView.isMapShown();
    }

    _getShowMapOption() {
        return this._sideMapHelperView.getShowMapOption();
    }

    openDetailedSheetWhenAdClick(ad, event) {
        this._sideMapHelperView.openDetailedSheetWhenAdClick(ad, event);
    }

    canClickOnDetailedSheetMarker() {
        return this._sideMapHelperView.canClickOnDetailedSheetMarker();
    }

    hoverAd(realEstateAd) {
        this._sideMapHelperView.hoverAd(realEstateAd);
    }

    unHoverAd(realEstateAd) {
        this._sideMapHelperView.unHoverAd(realEstateAd);
    }

    enableMapHover() {
        this._sideMapHelperView.enableMapHover();
    }

    disableMapHover() {
        this._sideMapHelperView.disableMapHover();
    }

    _shouldShowSaveSearch() {
        let shouldShowSaveSearch = false;
        const searchFilterView = this._getSearchFilterView();
        const {
            openSaveSearch,
            saveSearchAllowed,
        } = this.options;
        if (searchFilterView && openSaveSearch && saveSearchAllowed) {
            const currentSearchCriteria = searchFilterView.getFilters();
            const matchingSearch = SavedSearches.findMatchingSavedSearch(currentSearchCriteria);
            if (!matchingSearch) {
                shouldShowSaveSearch = true;
            }
        }
        return shouldShowSaveSearch;
    }
};

function addUserEventInAd(ad, userEvent, eventsArrayKey) {
    ad[eventsArrayKey] = ad[eventsArrayKey] || [];
    ad[eventsArrayKey].unshift(userEvent);
}

function getDisplayedAsHighlightedAdIds(realEstateAds) {
    const highlightedAdIds = _(realEstateAds)
        .filter({status: {highlighted: true}})
        .map('id')
        .value();
    const authenticatedAccountId = Account.getAuthenticatedAccountId();
    return ApplicationConfig.applicationPro ?
        highlightedAdIds : sampleSizeWithSeed(highlightedAdIds, HIGHLIGHTED_ADS_PER_PAGE_MAX_COUNT, authenticatedAccountId);
}
