const _ = require('lodash');
const moment = require('moment');

module.exports = {
    enableByHtml5: function ($field) {
        return 'date' === $field.attr('type');
    },
    init: function (validator, $field) {
        const observer = new MutationObserver((mutationsList) => {
            _.each(mutationsList, mutation => {
                if (_.includes(['min', 'max'], mutation.attributeName)) {
                    validator.revalidateField($field);
                }
            });
        });
        observer.observe($field.get(0), {attributes: true});
    },
    validate: function (validator, $field) {
        const dateValue = $field.val();
        const minDate = $field.attr('min');
        const maxDate = $field.attr('max');
        const messageMin = $field.attr('data-bv-minmaxdates-min-warning');
        const messageMax = $field.attr('data-bv-minmaxdates-max-warning');
        const messageMinMax = $field.attr('data-bv-minmaxdates-min-max-warning');
        const dateValueMoment = moment(dateValue);
        const isStartDateCoherent = maxDate ? dateValueMoment.isSameOrBefore(maxDate, 'day') : true;
        const isEndDateCoherent = minDate ? dateValueMoment.isSameOrAfter(minDate, 'day') : true;
        const areValidDates = !dateValue || (isStartDateCoherent && isEndDateCoherent);
        if (!areValidDates) {
            let message;
            if (minDate && maxDate) {
                message = messageMinMax;
            } else if (minDate) {
                message = messageMin;
            } else if (maxDate) {
                message = messageMax;
            }
            return {valid: areValidDates, message};
        } else {
            return true;
        }
    },
};
