const $ = require('jquery');
const Account = require('../authentication/Account');
const GoogleTagManager = require('../stats/GoogleTagManager');
const {insertUserInfoView} = require('../views/UserInfoView');

module.exports = {
    initializeAgencyMarker,
};

function initializeAgencyMarker({agencyInfo: user, relatedAccounts}, $html) {
    const $blockAgencyInfo = $html ? $html.find('.blockAgencyInfo') : $('.blockAgencyInfo');
    insertUserInfoView($blockAgencyInfo, {
        user,
        relatedAccounts,
        contactGtmSource: 'POI',
        isAdmin: Account.isAdmin(),
    });

    const $userInfoPhoneRevealBtn = $blockAgencyInfo.find('.userInfoPhoneRevealBtn');
    if ($userInfoPhoneRevealBtn.length) {
        $userInfoPhoneRevealBtn.on('click', function () {
            const $btn = $(this);
            const userId = $btn.data('userid');
            GoogleTagManager.sendAgencyPhoneNumberShownEvent(userId, 'fromPOI');
            $blockAgencyInfo.find('.userInfoDetailContainer[data-userId=' + userId + ']').show();
        });
    }
}
