const StaticFileResolver = require('./utils/StaticFileResolver');
const callWithGlobalJQuery = require('./utils/callWithGlobalJQuery');

module.exports = {
    load,
};

function load() {
    callWithGlobalJQuery(function () {
        require('./lib/jquery.cookie');
        require('./lib/jquery.scrollTo');
        require('./lib/jquery.glide');
        require('./lib/jquery.easing.1.3');
        require('./lib/jssor.slider.mini');
        require('./lib/bootstrap-dropdown')(window.jQuery, document);
        require('fack/bootstrap-stylus/js/modal');
        require('bootstrap-select');
        require('./lib/configureBoostrapSelect');
        require('./lib/typeahead.bundle');
        require('./lib/f4tagsinput');
        require('bootstrap-tagsinput');
        require('bootstrap-datepicker');
        require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');
        require('intl-tel-input/build/js/intlTelInput.js');
        require('mailcheck');
        require('bootstrap-daterangepicker');
        window.jQuery.fn.intlTelInput.loadUtils(StaticFileResolver.resolve('intl-tel-input/build/js/utils.js'));
    });
}
