const CONTACT_TYPES = [
    'rent',
    'buy',
    'adSubmissions',
    'accountContacts',
    'estimationRequests',
    'calls',
];

module.exports = CONTACT_TYPES;
