const autosize = require('autosize');
const template = require('./Textarea.jade');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseFieldMixin'),
        require('./mixins/withInternalValue'),
    ],
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },
    watch: {
        internalValue() {
            this.$nextTick(() => {
                autosize.update(this.$refs.textarea);
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            autosize(this.$refs.textarea);
        });
    },
    template: template(),
};
