const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const {resourceUrl} = require('fack');
const {cityNameToInTheCity} = require('@bienici/city-name-formatter');

const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./LocalBanner.jade');
const Account = require('../../authentication/Account');
const LocalBannersHelper = require('../LocalBannersHelper');
const ServerConfig = require('../../ServerConfig');

const CITY_NAME_ELLIPSIS_LENGTH_THRESHOLD = 30;
const CITY_NAME_LINE_BREAK_LENGTH_THRESHOLD = 20;

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'LocalBanner.',
            keys: ['logoAlternativeText', 'templateAlternativeText'],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        enableAnalytics: Boolean,
        searchedPostalCodes: {
            type: Array,
            default: null,
        },
        footerText: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            cityName: null,
            isLoading: false,
        };
    },
    computed: {
        templateSource() {
            const {configuration} = this;
            return ServerConfig.config.wwwUrl +
                resourceUrl(`images/localBanners/${configuration.theme}/${configuration.template.index}.gif`);
        },
        logoUrl() {
            return Account.getAccountImageUrl(this.agency) || '';
        },
        phone() {
            const {agency} = this;
            return new PhoneNumberFormatter(agency.phone || agency.contact.phone).phoneNumberToDisplay;
        },
        cityNameArticle() {
            return cityNameToInTheCity(this.cityName).split(' ')[0];
        },
        callToActionText() {
            const {configuration: {theme}} = this;
            return this.t('callToAction.' + theme);
        },
        mentionText() {
            return this.t('mention', {article: this.cityNameArticle});
        },
        shouldAssumeLineBreakOnCityName() {
            return this.hasBreakableLongCityName(CITY_NAME_LINE_BREAK_LENGTH_THRESHOLD);
        },
        shouldShowCityNameEllipsis() {
            return this.hasBreakableLongCityName(CITY_NAME_ELLIPSIS_LENGTH_THRESHOLD);
        },
        modifiers() {
            return {
                withCityLineBreak: this.shouldAssumeLineBreakOnCityName,
            };
        },
        cityNameToDisplay() {
            const name = this.shouldShowCityNameEllipsis ?
                (this.cityName.slice(0, CITY_NAME_ELLIPSIS_LENGTH_THRESHOLD) + '...')
                : this.cityName;
            return cityNameToInTheCity(name).split(' ').slice(1).join(' ');
        },
        agencyUrl() {
            return this.agency.website;
        },
        showFooterText() {
            return Boolean(this.footerText);
        },
    },
    watch: {
        agency: {
            handler() {
                this.fetchCityName();
            },
            deep: true,
        },
    },
    mounted() {
        this.printEvent();
        this.fetchCityName();
    },
    methods: {
        hasBreakableLongCityName(lengthCheck) {
            return [' ', '-'].some(character => this.cityName.includes(character)) && this.cityName.length > lengthCheck;
        },
        fetchCityName() {
            this.isLoading = true;
            LocalBannersHelper.findCityNameForLocalBanner(this.agency, (err, cityName) => {
                if (err) {
                    console.error(err);
                } else {
                    this.cityName = cityName;
                }
                this.isLoading = false;
            });
        },
        printEvent() {
            this.storeEvent('print');
        },
        clickEvent() {
            this.storeEvent('click');
        },
        storeEvent(type) {
            const {enableAnalytics, agency, configuration, searchedPostalCodes} = this;
            if (enableAnalytics) {
                const event = {
                    agencyId: agency._id,
                    event: type,
                    matchingBannerConfig: configuration,
                    searchedPostalCodes,
                };
                LocalBannersHelper.storeLocalBannerEvent(event, error => {
                    if (error) {
                        console.error(`Could not store ${event.type} event for agency ${agency._id}`, error);
                    }
                });
            }
        },
    },
    template: template(),
};
