const $ = require('jquery');
const _ = require('lodash');
const View = require('./views/View');
const EventPack = require('./utils/EventPack');
const template = require('./templates/IframeWidget.jade');

module.exports = class IframeWidget extends View {
    constructor(options) {
        super({
            template,
        });
        this.options = _.defaults(options || {},
            {
                showTitleContainer: true,
                allowFullScreen: true,
            });
        this.$container = $('body');
        this._eventPack = new EventPack();
    }

    show() {
        this.emit('open');
        super.show(this.options);
        this._eventPack.on(this.$element, {
            click: {
                '.iframe-close': _.bind(this._hide, this),
                // TODO : this has nothing to do with IframeWidget !!!
                '.btn-virtualTour-openContact': _.bind(this.scrollToContactSection, this),
                '.btn-virtualTour-openLiveRoom': () => {
                    this.emit('openLiveRoom');
                    this._hide();
                },
            },
        });
        this._eventPack.on($(document), 'keyup', _.bind(this._onKeyUp, this));
        this._eventPack.on($(window), 'popstate', _.bind(this._hide, this));
    }

    _onKeyUp(event) {
        if (!$(event.target).is('input') && 27 == event.keyCode) { // Esc key
            this._hide();
        }
    }

    _hide() {
        this.emit('close');
        this._eventPack.removeAllListeners();

        if (this.$element) { // on popstate event, $element is not in DOM anymore
            this.$element.addClass('animHide');
            this.$element.on('webkitAnimationEnd oanimationend msAnimationEnd animationend', _.bind(this.hide, this));
        }
    }

    scrollToContactSection() {
        this.emit('openContactSection', 'slideShow');
        this._hide();
    }
};
