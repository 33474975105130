// jscs:disable
// FILE GENERATED FROM KIMONO-SERVER
function computeAdRelevance(_source) {
// /!\ WARNING /!\ this file is also used in www and indexer in javascript, try to maintain compatibility

let NULL_VALUE_PLACEHOLDER = -32767;
let relevance = 0;
let details = "";
let PERCENTILE_80_RELEVANCE_VALUE = 27;
let MIN_DYNAMIC_BONUS = 1;
let DYNAMIC_BONUS_FACTOR = 1/2;

//niveau de floutage
if (_source.blurInfo != null && _source.blurInfo.type == "exact") {
    details += "Pas de floutage: +8" + "\n";
    relevance += 8;
} else if (_source.blurInfo != null && _source.blurInfo.type == "disk") {
    details += "Floutage precis: +2" + "\n";
    relevance += 2;

    if (_source.blurInfo.radius == null) {
        //should not happen
        details += "Sans precision (BUG): 0" + "\n";
    } else if (_source.blurInfo.radius <= 50) {
        details += "Precision: 50m et moins: +5" + "\n";
        relevance += 5;
    } else if (_source.blurInfo.radius <= 100) {
        details += "Precision: 50m - 100m: +4" + "\n";
        relevance += 4;
    } else if (_source.blurInfo.radius <= 250) {
        details += "Precision: 100m - 250m: +3" + "\n";
        relevance += 3;
    } else if (_source.blurInfo.radius <= 500) {
        details += "Precision: 250m - 500m: +2" + "\n";
        relevance += 2;
    } else if (_source.blurInfo.radius <= 750) {
        details += "Precision: 500m - 750m: +1" + "\n";
        relevance += 1;
    }
} else if(_source.blurInfo != null && _source.blurInfo.type == "postalCode") {
    details += "Floutage au code postal: -1" + "\n";
    relevance -= 1;
}

// modèle 3d
let modelReady = _source.model != null && _source.model.ready;
if (modelReady) {
    details += "Modele 3d: +2" + "\n";
    relevance += 2;
}

//neuf, cad programme devant
let isProgramme = (_source.propertyType == "programme");
if (isProgramme) {
    details += "Programme: +3" + "\n";
    relevance += 3;
}

let bestRealEstateRelevance = NULL_VALUE_PLACEHOLDER;
let bestDetails = "";

let nestedAdsCount = 0;
if (_source.realEstateAds != null) {
    nestedAdsCount = _source.realEstateAds.length;
}

let isPreview = (_source.programState == "preview");

//using this `for` form since it works in both painless and javascript
for (let i = 0; i < nestedAdsCount; i++) {
    let realEstateAd = _source.realEstateAds[i];

    if (!realEstateAd.onTheMarket) {
        continue;
    }

    let realEstateRelevance = 0;
    let realEstateDetails = "";

    //mauvais neuf
    if (!isProgramme && realEstateAd.newProperty) {
        realEstateDetails += "Neuf: -2" + "\n";
        realEstateRelevance -= 2;
    }

    //nombre de photos
    let photoCount = 0;
    if (realEstateAd.photoCount > 0) {
        photoCount = realEstateAd.photoCount;
    }
    if (photoCount <= 0) {
        realEstateDetails += "0 photo: 0" + "\n";
    } else if (photoCount <= 1) {
        realEstateDetails += "1 photo: +1" + "\n";
        realEstateRelevance += 1;
    } else if (photoCount <= 3) {
        realEstateDetails += "2-3 photos: +2" + "\n";
        realEstateRelevance += 2;
    } else if (photoCount <= 7) {
        realEstateDetails += "4-7 photos: +3" + "\n";
        realEstateRelevance += 3;
    } else if (photoCount <= 14) {
        realEstateDetails += "8-14 photos: +4" + "\n";
        realEstateRelevance += 4;
    } else {
        realEstateDetails += "15+ photos: +5" + "\n";
        realEstateRelevance += 5;
    }

    //taille de la description
    let description = realEstateAd.description;
    let descriptionLength = NULL_VALUE_PLACEHOLDER;
    if (realEstateAd.descriptionTextLength != null && realEstateAd.descriptionTextLength > 0) {
        descriptionLength = realEstateAd.descriptionTextLength;
    }
    if (descriptionLength == NULL_VALUE_PLACEHOLDER && description != null) {
        descriptionLength = description.length;
    }
    if (descriptionLength > 1000) {
        realEstateRelevance += 4;
        realEstateDetails += "Description >1000: +4" + "\n";
    } else if (descriptionLength > 700) {
        realEstateRelevance += 3;
        realEstateDetails += "Description >700: +3" + "\n";
    } else if (descriptionLength > 400) {
        realEstateRelevance += 2;
        realEstateDetails += "Description >400: +2" + "\n";
    } else if (descriptionLength > 100) {
        realEstateRelevance += 1;
        realEstateDetails += "Description >100: +1" + "\n";
    }

    //précense d'infos essentielles
    // - prix
    if (realEstateAd.price != null || isPreview) {
        realEstateRelevance += 10;
        realEstateDetails += "Avec prix (ou Avant-premiere): +10" + "\n";
    }
    // - surface
    if (realEstateAd.surfaceArea != null || isPreview) {
        realEstateRelevance += 1;
        realEstateDetails += "Surface fournie (ou Avant-premiere): +1" + "\n";
    }
    // - nombre de pièces
    if (realEstateAd.roomsQuantity != null || isPreview) {
        realEstateRelevance += 1;
        realEstateDetails += "Nombre de pieces fourni (ou Avant-premiere): +1" + "\n";
    }

    // - DPE
    if (realEstateAd.energyValue != null || realEstateAd.energyClassification != null ) {
        realEstateRelevance += 1;
        realEstateDetails += "DPE fourni: +1" + "\n";
    }

    // appartement et maison en premier, sauf programme
    let propertyType = realEstateAd.propertyType;
    if (!isProgramme && (propertyType == "flat" || propertyType == "house")) {
        realEstateRelevance += 2;
        realEstateDetails += "Bonus maison et appartement: +2" + "\n";
    }

    // visite 360
    let has360 = false;
    if (realEstateAd.virtualTours != null) {
        let virtualToursCount = realEstateAd.virtualTours.length;
        for (let j = 0; j < virtualToursCount; j++) {
            let content = realEstateAd.virtualTours[j];
            if (content.accepted && content.with360 != null && content.with360)
                has360 = true;
        }
    }
    if (has360) {
        realEstateRelevance += 2;
        realEstateDetails += "Bonus visite virtuelle 360: +2" + "\n";
    }

    // exclusive
    let adType = realEstateAd.adType;
    if ((
            adType == "vente" ||
            adType == "vente de prestige" ||
            adType == "produit d'investissement" ||
            adType == "vente fond de commerce" ||
            adType == "cession de bail"
        ) && realEstateAd.isExclusiveSaleMandate != null && realEstateAd.isExclusiveSaleMandate) {
        realEstateRelevance += 1;
        realEstateDetails += "Bonus exclusivite: +1" + "\n";
    }

    if (adType == "location" && realEstateAd.price > 2000) {
        realEstateRelevance -= 2;
        realEstateDetails += "Location > 2000 €: -2" + "\n";
    }

    if (realEstateAd.relevanceBonus != null && realEstateAd.relevanceBonus != 0 ) {
        realEstateRelevance += realEstateAd.relevanceBonus;
        realEstateDetails += "Bonus : " + realEstateAd.relevanceBonus + "\n";
    }

    //---
    if (bestRealEstateRelevance == NULL_VALUE_PLACEHOLDER || realEstateRelevance > bestRealEstateRelevance) {
        bestRealEstateRelevance = realEstateRelevance;
        bestDetails = realEstateDetails;
    }
}

if (bestRealEstateRelevance != NULL_VALUE_PLACEHOLDER) {
    relevance += bestRealEstateRelevance;
    details += bestDetails;
}

// this block needs to be at the end because boost bonus value depends on the final relevance
if (_source.hasRelevanceDynamicBonus != null && _source.hasRelevanceDynamicBonus) {
    let boostValueForAdsWithScoreLowerThanMajority = Math.ceil((Math.max(PERCENTILE_80_RELEVANCE_VALUE - relevance, 0)) * DYNAMIC_BONUS_FACTOR);
    let relevanceDynamicBonus = parseInt(Math.max(boostValueForAdsWithScoreLowerThanMajority, MIN_DYNAMIC_BONUS));
    relevance += relevanceDynamicBonus;
    details += "Bonus boost: " + relevanceDynamicBonus + "\n";
}

details += 'Total: ' + relevance;

return {score: relevance, details};

}
module.exports = {computeAdRelevance};
