const _ = require('lodash');
const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const i18nMixin = require('../../vue/components/mixins/i18n');
const loadingPlaceholderMixin = require('../../vue/components/mixins/loadingPlaceholderMixin');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');
const {BREAKPOINTS_LIMITS_XS} = require('../../vue/utils/Breakpoints');
const {AGENCY_OVERVIEW_VARIANTS, AGENCY_PAGE_VARIANT} = require('./agencyOverviewVariants');
const {props: {variant: {LINK_VARIANT, PRIMARY_VARIANT}}} = require('../../vue/components/Button');
const OpeningHours = require('./OpeningHours');
const AdsLinkButton = require('./AdsLinkButton');

const template = require('./AgencyOverview.jade');
const Account = require('../../authentication/Account');
const ContactAgencyButton = require('./ContactAgencyButton');
const {agencyInfoPage: {makeAgencyInfoPageUrl}} = require('../../Urls');

const LARGE_IMAGE_MAX_WIDTH = 300;
const LARGE_IMAGE_MAX_HEIGHT = 100;

/**
 * @description display agency name, address and logo
 * @props agency: Object
 */
// @vue/component
module.exports = {
    constants: {
        LOADING_PLACEHOLDER_LINE_PERCENTAGE_LIST: [
            100,
            50,
            40,
            25,
        ],
    },
    components: {
        ContactAgencyButton,
        OpeningHours,
        AdsLinkButton,
    },
    mixins: [
        ContainerQueries({xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS}}),
        i18nMixin({
            prefix: 'AgencyOverview.',
            keys: [
                'linkText',
            ],
        }),
        loadingPlaceholderMixin,
    ],
    props: {
        agency: {
            type: Object,
            default: null,
        },
        showPhoneNumber: Boolean,
        showAgencyPageLink: Boolean,
        showAgencyRating: Boolean,
        addressOnMultipleLines: Boolean,
        showContactUs: Boolean,
        padding: {
            type: String,
            default: null,
        },
        infoBlockMarginTop: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: null,
            validator: variant => _.includes(AGENCY_OVERVIEW_VARIANTS, variant),
        },
        showAgencyAdsLink: Boolean,
        showOpeningHours: Boolean,
    },
    computed: {
        shouldDisplayContactUsForm() {
            return this.showContactUs && _.get(this.agency, 'hasEmail', false);
        },
        isAgencyPageVariant() {
            return this.variant === AGENCY_PAGE_VARIANT;
        },
        displayName() {
            return _.get(this.agency, 'display_name');
        },
        logoUrl() {
            const {agency, isAgencyPageVariant} = this;
            if (!agency) {
                return;
            }
            if (isAgencyPageVariant) {
                return Account.getAccountImageUrl(agency, LARGE_IMAGE_MAX_WIDTH, LARGE_IMAGE_MAX_HEIGHT);
            }
            return Account.getAccountImageUrl(agency);
        },
        address() {
            return _.get(this.agency, 'company.address');
        },
        formattedPhoneNumber() {
            const phoneNumber = _.get(this.agency, 'callTrackedPhoneNumber');
            if (phoneNumber) {
                const formattedPhoneNumber = new PhoneNumberFormatter(phoneNumber).phoneNumberToDisplay;
                return this.t('phoneNumber', {formattedPhoneNumber});
            } else {
                return null;
            }
        },
        shouldDisplayPhoneNumber() {
            return this.showPhoneNumber && Boolean(this.formattedPhoneNumber);
        },
        agencyOverviewStyle() {
            const {padding, inColumn} = this;
            const baseStyle = {padding};
            if (inColumn) {
                return {
                    ...baseStyle,
                    display: 'flex',
                    flexDirection: 'column',
                };
            }
            return baseStyle;
        },
        logoModifiers() {
            return {
                lg: this.isAgencyPageVariant,
                inColumn: this.inColumn,
            };
        },
        iconModifiers() {
            return {lg: this.isAgencyPageVariant};
        },
        infoBlockStyle() {
            const {infoBlockMarginTop: marginTop, inColumn} = this;
            const baseStyle = {marginTop};
            if (inColumn) {
                return {
                    ...baseStyle,
                    textAlign: 'center',
                };
            }
            return baseStyle;
        },
        agencyPageEnabled() {
            return _.get(this.agency, 'agencyPageEnabled');
        },
        linkUrl() {
            return this.agency && makeAgencyInfoPageUrl(this.agency);
        },
        displayLinkUrl() {
            return this.agencyPageEnabled && this.showAgencyPageLink && !Account.isShowRoom();
        },
        agencyId() {
            return _.get(this.agency, 'id');
        },
        contactAddressModifiers() {
            return {agencyPage: this.isAgencyPageVariant};
        },
        infoNameModifiers() {
            return {
                agencyPage: this.isAgencyPageVariant,
                inColumn: this.inColumn,
            };
        },
        actionsModifiers() {
            return {
                agencyPage: this.isAgencyPageVariant,
                inColumn: this.inColumn,
            };
        },
        actionsVariant() {
            return this.isAgencyPageVariant ? PRIMARY_VARIANT : LINK_VARIANT;
        },
        openingHours() {
            return _.get(this.agency, 'openingHours');
        },
        displayOpeningHours() {
            return this.showOpeningHours && Boolean(this.openingHours || !_.get(this.agency, 'description'));
        },
        inColumn() {
            return this.isAgencyPageVariant && this.breakpoints.xsmall;
        },
    },
    template: template(),
};
