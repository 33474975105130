const VueView = require('../vue/VueView');
const template = require('./UrlInputWidget.jade');

module.exports = class UrlInputWidget {
    constructor($container, {name, title: label}) {
        const vueView = new VueView({
            $container,
        });
        this.vueData = {
            value: null,
            name,
            label,
        };
        this.vueView = vueView;
        // @vue/component
        vueView.show(null, {
            name,
            data: () => this.vueData,
            template: template(),
        });
    }

    clear() {
        this.vueView.hide();
    }

    setFieldValue(value) {
        this.vueData.value = value;
    }

    getFieldValue() {
        return this.vueData.value;
    }

    serializeInto(result) {
        const value = this.getFieldValue();
        if (value != null) {
            result[this.vueData.name] = value;
        }
    }
};
