const _ = require('lodash');
const OVERSEAS_FRANCE_PREFIXES = require('./lib/OverseasFrancePrefixes');
const OVERSEAS_FRANCE_PREFIX_LENGTH = 4;
const METROPOLITAN_FRANCE_PREFIX = '+33';
const ZERO_LENGTH = 1;
const FRANCE_OVERSEAS_LOCAL_PHONE_LENGTH = 10;
const SAINT_PIERRE_AND_MIQUELON_LOCAL_PHONE_LENGTH = 10;
const FRANCE_OVERSEAS_INTERNATIONAL_PHONE_NUMBER_WITHOUT_PREFIX_LENGTH = FRANCE_OVERSEAS_LOCAL_PHONE_LENGTH - ZERO_LENGTH;
const SAINT_PIERRE_AND_MIQUELON_INTERNATIONAL_PHONE_NUMBER_WITHOUT_PREFIX = SAINT_PIERRE_AND_MIQUELON_LOCAL_PHONE_LENGTH
    - OVERSEAS_FRANCE_PREFIX_LENGTH;

const ALL_FRENCH_OVERSEAS_PREFIXES = [
    ..._(OVERSEAS_FRANCE_PREFIXES)
        .map()
        .uniq()
        .value(),
];
const ALL_FRENCH_PREFIXES = [
    METROPOLITAN_FRANCE_PREFIX,
    ...ALL_FRENCH_OVERSEAS_PREFIXES,
];
const {SAINT_PIERRE_AND_MIQUELON_PREFIX} = require('./lib/Constants');

module.exports = class PhoneNumberFormatter {
    constructor(rawPhoneNumber) {
        if (rawPhoneNumber) {
            rawPhoneNumber = rawPhoneNumber.trim();
            //keep only numbers
            let prefix = METROPOLITAN_FRANCE_PREFIX;
            rawPhoneNumber = rawPhoneNumber.replace(/^00[\s.]*/, '+');
            if (/^33/.test(rawPhoneNumber)) {
                rawPhoneNumber = '+' + rawPhoneNumber;
            }
            rawPhoneNumber = handleOverseasFranceNumberWithoutInternationalCodeMarker(rawPhoneNumber);
            rawPhoneNumber = handleParentheses(rawPhoneNumber);

            if (/^\+/.test(rawPhoneNumber)) {
                const prefixMatch = /^(\+\d+)\D(.*)$/.exec(rawPhoneNumber);
                const matchingOverseasFrancePrefix = _.find(OVERSEAS_FRANCE_PREFIXES, (prefix) => {
                    return _.startsWith(rawPhoneNumber, prefix);
                });
                if (prefixMatch && !/^\+33/.test(prefixMatch[1])) {
                    prefix = prefixMatch[1]; // +included
                    rawPhoneNumber = prefixMatch[2];
                } else if (matchingOverseasFrancePrefix) { // handle international phone number from Overseas France
                    prefix = matchingOverseasFrancePrefix;
                    if (isSaintPierreAndMiquelonPrefix(prefix)) {
                        rawPhoneNumber = rawPhoneNumber.substring(1);
                    } else {
                        rawPhoneNumber = rawPhoneNumber.substring(prefix.length);
                    }
                } else {
                    prefix = rawPhoneNumber.substring(0, 3);
                    rawPhoneNumber = rawPhoneNumber.substring(3);
                }
                if (isFrenchPrefix(prefix)) {
                    rawPhoneNumber = '0' + rawPhoneNumber;
                }
            }

            const numberOnlyPhone = rawPhoneNumber.replace(/\D+/g, '');
            // handle local phone number from Overseas France
            const matchingOverseasFrancePrefix = _.find(OVERSEAS_FRANCE_PREFIXES, (prefix, localPhonePrefix) => {
                return _.startsWith(numberOnlyPhone, localPhonePrefix);
            });
            if (matchingOverseasFrancePrefix) {
                prefix = matchingOverseasFrancePrefix;
            }

            let phoneToDisplay;
            let phoneToCall;
            if (isFrenchPrefix(prefix)) {
                if (/^0800/.test(numberOnlyPhone)) {
                    phoneToDisplay = numberOnlyPhone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
                } else {
                    phoneToDisplay = numberOnlyPhone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
                }
                let phoneToCallWithoutPrefix = phoneToDisplay.substring(1);
                if (prefix.length === OVERSEAS_FRANCE_PREFIX_LENGTH) {
                    const removeCount = getCharactersToRemoveCount(prefix);
                    if (isSaintPierreAndMiquelonPrefix(prefix)) {
                        phoneToCallWithoutPrefix = numberOnlyPhone.substring(removeCount).replace(/(\d{2})(\d{2})(\d{2})/, '$1 $2 $3');
                    } else {
                        phoneToCallWithoutPrefix = numberOnlyPhone.substring(removeCount).replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4');
                    }
                }
                phoneToCall = prefix + ' ' + phoneToCallWithoutPrefix;
            } else {
                phoneToDisplay = prefix + ' ' + rawPhoneNumber;//keep formatting
                phoneToCall = phoneToDisplay;
            }

            this.prefix = prefix;
            this.localNumber = numberOnlyPhone;
            this.phoneNumberToCall = phoneToCall;
            this.phoneNumberToDisplay = phoneToDisplay.replace(/\s/g, '\u00A0');
            this.internationalPhoneNumber = prefix + numberOnlyPhone.substring(getCharactersToRemoveCount(prefix));
        }
    }

    toJSON() {
        return {
            prefix: this.prefix,
            localNumber: this.localNumber,
            phoneNumberToCall: this.phoneNumberToCall,
            phoneNumberToDisplay: this.phoneNumberToDisplay,
            internationalPhoneNumber: this.internationalPhoneNumber,
        };
    }
};

function isFrenchPrefix(prefix) {
    return _.includes(ALL_FRENCH_PREFIXES, prefix);
}

function isSaintPierreAndMiquelonPrefix(prefix) {
    return prefix === SAINT_PIERRE_AND_MIQUELON_PREFIX;
}

function getCharactersToRemoveCount(prefix) {
    let count = 0;
    if (isFrenchPrefix(prefix)) {
        if (isSaintPierreAndMiquelonPrefix(prefix)) {
            count = 4;
        } else {
            count = 1;
        }
    }
    return count;
}

function handleParentheses(rawPhoneNumber) {
    let phoneNumber = rawPhoneNumber;
    if (/^\+.*\(/.test(rawPhoneNumber)) {
        //parentheses with prefix
        phoneNumber = rawPhoneNumber.replace(/\(.*\)/g, '');
    } else if (/\(/.test(rawPhoneNumber)) {
        //parentheses without prefix
        phoneNumber = rawPhoneNumber.replace(/[()]/g, '');
    }
    return phoneNumber;
}

function handleOverseasFranceNumberWithoutInternationalCodeMarker(rawPhoneNumber) {
    const regExp = _.join([..._(ALL_FRENCH_OVERSEAS_PREFIXES)
        .map(prefix => {
            let numberOfDigitAfterPrefix = FRANCE_OVERSEAS_INTERNATIONAL_PHONE_NUMBER_WITHOUT_PREFIX_LENGTH;
            if (prefix === SAINT_PIERRE_AND_MIQUELON_PREFIX) {
                numberOfDigitAfterPrefix = SAINT_PIERRE_AND_MIQUELON_INTERNATIONAL_PHONE_NUMBER_WITHOUT_PREFIX;
            }
            return `^${_.replace(prefix, '+', '')}\\d{${numberOfDigitAfterPrefix}}$`;
        })
        .uniq()
        .value(),
    ], '|');
    if (new RegExp(regExp).test(rawPhoneNumber)) {
        return '+' + rawPhoneNumber;
    } else {
        return rawPhoneNumber;
    }
}
