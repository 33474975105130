const BrowserDetect = require('browser-detect');

module.exports = {
    check,
    isStartSupported,
    getBrowserInfo,
};

// for cover page
const BROWSER_START_BLACKLIST = {
    ios: {
        'Opera Mini': -1,
    },
    android: {
        'Opera Mini': -1,
    },
    desktop: {
        MSIE: -1,
    },
};

// for banner
const BROWSER_WHITELIST = {
    desktop: {
        Chrome: 30, // Chrome versions < 30 are not supported
        Firefox: 30,
        Safari: 7,
    },
    android: {
        Chrome: 28,
        Firefox: 30,
        Opera: -1, // all versions supported
        Safari: 4,
    },
};

const advisableBrowserList = {
    desktop: [
        {
            name: 'Chrome',
            url: 'http://www.google.com/chrome',
            image: '/images/browserUpgrade/chrome.gif',
        },
        {
            name: 'Firefox',
            url: 'http://www.getfirefox.com/',
            image: '/images/browserUpgrade/firefox.png',
        },
    ],
    ios: [
        {
            name: 'Safari',
            image: '/images/browserUpgrade/Safari.png',
        },
    ],
    android: [
        {
            name: 'Chrome',
            url: 'https://play.google.com/store/apps/details?id=com.android.chrome',
            image: '/images/browserUpgrade/chrome.gif',
        },
    ],
};

function getBrowserInfo(userAgent) {
    const operaMiniVersionMatches = /Opera Mini\/(\d+)/i.exec(userAgent);
    if (operaMiniVersionMatches) {
        return {browser: 'Opera Mini', version: +operaMiniVersionMatches[1]};
    }
    let [, name, version] = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(name)) {
        const versionMatches = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return {browser: 'MSIE', version: +(versionMatches[1] || '')};
    }
    if (name === 'Chrome') {
        const versionMatches = userAgent.match(/\bOPR\/(\d+)/);
        if (versionMatches != null) {
            return {browser: 'Opera', version: +versionMatches[1]};
        }
    }
    if (!version) {
        name = navigator.appName;
        version = navigator.appVersion;
    }
    if (name === 'Safari') {
        //get navigator version from "Version" in useragent only for Safari
        const versionMatches = userAgent.match(/version\/(\d+)/i);
        if (versionMatches != null) {
            version = versionMatches[1];
        }
    }
    return {browser: name, version: +version};
}

function getDeviceType() {
    let deviceType = 'desktop';
    if (BrowserDetect.isIOS()) {
        deviceType = 'ios';
    } else if (BrowserDetect.isAndroid()) {
        deviceType = 'android';
    }
    return deviceType;
}

// return values are : "browserNotSupported", "browserSupported", "browserTooOld" + advisableBrowserList
function check(cb) {
    const deviceType = getDeviceType();

    let isSupported = 'browserNotSupported';
    const browserInfo = getBrowserInfo(navigator.userAgent);
    const browserName = browserInfo.browser;
    const browserVersion = parseInt(browserInfo.version, 10);

    if (BrowserDetect.isBot() || deviceType == 'ios') {
        isSupported = 'browserSupported';
    } else if (BROWSER_WHITELIST[deviceType]) {
        const minimalSupportedVersion = BROWSER_WHITELIST[deviceType][browserName];
        if (minimalSupportedVersion) {
            isSupported = (browserVersion >= minimalSupportedVersion) ? 'browserSupported' : 'browserTooOld';
        }
    }
    cb(isSupported, advisableBrowserList[deviceType]);
}

function isStartSupported(cb) {
    const deviceType = getDeviceType();
    const browserInfo = getBrowserInfo(navigator.userAgent);
    const browserName = browserInfo.browser;
    const browserVersion = parseInt(browserInfo.version, 10);
    let isSupported = true;
    if (BROWSER_START_BLACKLIST[deviceType]
        && (BROWSER_START_BLACKLIST[deviceType][browserName] == -1
            || browserVersion <= BROWSER_START_BLACKLIST[deviceType][browserName])) {
        isSupported = false;
    }
    cb(isSupported, advisableBrowserList[deviceType]);
}
