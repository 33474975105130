const _ = require('lodash');
const urlUtils = require('url');
const ApplicationConfig = require('../app/ApplicationConfig');
const ServerConfig = require('../ServerConfig');

module.exports = {
    generatePrimaryDomainUrl,
    generatePrimaryDomainCguUrl,
    generatePrimaryDomainPrivacyPolicyUrl,
};

function generatePrimaryDomainUrl(originalUrl = '') {
    const absoluteUrl = urlUtils.resolve(location.href, originalUrl);
    const parsedUrl = urlUtils.parse(absoluteUrl);
    const {wwwUrl, proUrl} = ServerConfig.config;
    const {protocol, host} = urlUtils.parse(ApplicationConfig.applicationPro ? proUrl : wwwUrl);
    _.extend(parsedUrl, {protocol, host});
    return urlUtils.format(parsedUrl);
}

function generatePrimaryDomainCguUrl() {
    return generatePrimaryDomainUrl('/informations-legales');
}

function generatePrimaryDomainPrivacyPolicyUrl() {
    return generatePrimaryDomainUrl('/politique-de-confidentialite');
}
