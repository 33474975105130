const _ = require('lodash');
const async = require('async');
const {i18n: {translate: t}} = require('fack');

const LocationItem = require('./LocationItem');
const CircleZoneAroundMeHelper = require('../geolocation/CircleZoneAroundMeHelper');

module.exports = class CircleLocationItem extends LocationItem {
    constructor(options) {
        super(options);
        this.value = 'circleZone';
        this.setIsAroundMe(options.isAroundMe);
    }

    getSuggestionText() {
        return t('locationField.geoLocation');
    }

    getText(options) {
        return this.isAroundMe ? t('locationField.aroundMe') : super.getText(options);
    }

    getUrlValue() {
        return 'cercle-' + this._item._id;
    }

    _onSubmit(cb) {
        if (this.isAroundMe) {
            async.auto({
                geolocation: cb => {
                    CircleZoneAroundMeHelper.geolocate({
                        onAbortCallback: _.partial(cb, 'geolocation aborted'),
                    }, (err, circleZone) => {
                        cb(err, circleZone);
                    });
                },
                circleZone: [
                    'geolocation',
                    (cb, {geolocation}) => {
                        CircleZoneAroundMeHelper.saveGeoLocation(geolocation, (err, circleZone) => {
                            circleZone.geometry.accuracy = geolocation.accuracy; //accuracy is not saved in DB
                            this._item = circleZone;
                            cb(err, circleZone);
                        });
                    },
                ],
            }, cb);

        } else {
            _.defer(cb);
        }
    }

    setIsAroundMe(isAroundMe) {
        this.isAroundMe = isAroundMe;
    }
};
