const browserDetect = require('browser-detect');
const $ = require('jquery');
const _ = require('lodash');

module.exports = {
    giveFocusOnPc,
    loseFocusOnActiveElement,
};

function giveFocusOnPc($input) {
    if (!browserDetect.isMobile() && !browserDetect.isTablet()) {
        //IE sends bogus input events when fields with placeholders containing an accented character are added to the page
        // thus losing the focus, using defer ensure focus action happen AFTER ie bogus events
        _.defer(function () {
            $input.focus();
        });
    }
}

function loseFocusOnActiveElement() {
    $(document.activeElement).blur();
}
