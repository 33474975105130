const $ = require('jquery');
const _ = require('lodash');
const uuid = require('uuid/v1');
const querystring = require('querystring');
const {i18n: {translate}, resourceUrl} = require('fack');
const BrowserDetect = require('browser-detect');

const Options = require('../../Options');
const ServerConfig = require('../../ServerConfig');
const NumberFormatter = require('../../../common/NumberFormatter');
const UrlHelper = require('../../../common/UrlHelper');
const moment = require('../../../common/momentFr');

const {TrackingClasses} = require('../../stats/TrackingClasses');
const UserAgentHelper = require('../../../common/nativeApp/UserAgentHelper');
const {getImageUrlFromAlias} = require('../../ImageHelper');

module.exports = function renderTemplate(template, templateOptions) {
    return $(renderTemplateRaw(template, templateOptions));
};

_.extend(module.exports, {
    renderTemplateRaw,
});

function renderTemplateRaw(template, templateOptions) {
    return template(_.extend({
        _,
        t: translate,
        config: ServerConfig.config,
        resourceUrl,
        formatNumber: NumberFormatter.formatNumber,
        moment,
        slugify: UrlHelper.slugify,
        isMobile: BrowserDetect.isMobile(),
        isTablet: BrowserDetect.isTablet(),
        savedOptions: Options.read(),
        tracking: TrackingClasses,
        isFromNativeApp: UserAgentHelper.isFromNativeApp(),
        uuid,
        querystring,
        getImageUrlFromAlias,
    }, templateOptions));
}
