const {resourceUrl} = require('fack');
const template = require('./ServiceBlock.jade');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');

const BREAKPOINTS_LIMITS_XS = 470;

// @vue/component
module.exports = {
    mixins: [
        ContainerQueries({xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS}}),
    ],
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        logoPath: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            required: true,
        },
        pitch: {
            type: String,
            required: true,
        },
    },
    computed: {
        iconUrl() {
            const {logoPath, icon} = this;
            if (logoPath) {
                return logoPath;
            } else {
                return icon ? resourceUrl(`images/illustrations/${icon}.svg`) : '';
            }
        },
    },
    template: template(),
};
