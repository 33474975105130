const _ = require('lodash');
const uuid = require('uuid');

const template = require('./checkableMixin.jade');

module.exports = (additionalClasses) => {
    // @vue/component
    return {
        mixins: [
            require('./baseFieldMixin'),
        ],
        props: {
            // any type is allowed
            // eslint-disable-next-line vue/require-prop-types
            value: {
                default: undefined,
            },
            checked: Boolean,
            // eslint-disable-next-line vue/require-prop-types
            trueValue: {
                default: true,
            },
            // eslint-disable-next-line vue/require-prop-types
            falseValue: {
                default: false,
            },
            disabled: Boolean,
        },
        data() {
            return {
                internalChecked: this.checked || _.isEqual(this.value, this.trueValue),
            };
        },
        computed: {
            inputId() {
                return uuid();
            },
            legacyIgnoreValue() {
                return _.isBoolean(this.trueValue);
            },
            additionalClasses() {
                return additionalClasses;
            },
        },
        watch: {
            value(newVal) {
                this.setInternalValue(newVal);
            },
            checked(newVal) {
                this.internalChecked = newVal;
            },
            internalChecked(newValue, oldValue) {
                this.emitValueIfWatchChanged(newValue, oldValue);
            },
            trueValue(newValue, oldValue) {
                this.emitValueIfWatchChanged(newValue, oldValue);
            },
            falseValue(newValue, oldValue) {
                this.emitValueIfWatchChanged(newValue, oldValue);
            },
        },
        mounted() {
            this.emitValue();
        },
        methods: {
            setInternalValue(value) {
                this.internalChecked = _.isEqual(value, this.trueValue);
            },
            emitValueIfWatchChanged(newValue, oldValue) {
                // this avoids infinite loops when values are json objects
                if (!_.isEqual(newValue, oldValue)) {
                    this.emitValue();
                }
            },
            emitValue() {
                this.$emit('input', this.internalChecked ? this.trueValue : this.falseValue);
            },
        },
        template: template(),
    };
};
