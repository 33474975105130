const {i18n: {translate}} = require('fack');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseInputMixin'),
    ],
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        extraAttributes() {
            return {
                'data-bv-intlphone': true,
                'data-bv-intlphone-message': translate('formErrorMessages.tel'),
            };
        },
        finalPlaceholder() {
            return this.computePlaceholder('+33 X XX XX XX XX');
        },
    },
    beforeCreate() {
        this.type = 'tel';
    },
    methods: {
        isSameValue(phone0, phone1) {
            return getCanonicalPhone(phone0) === getCanonicalPhone(phone1);
        },
    },
};

function getCanonicalPhone(value) {
    return value && value.replace(/[\s.-]+/g, '');
}
