const _ = require('lodash');
const Account = require('../../authentication/Account');
const ServerConfig = require('../../ServerConfig');
const ContactHelper = require('../../utils/ContactHelper');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const {
    INITIAL_STEP,
    PROPERTY_TYPE_OPTION,
} = require('./../Constants');
const RealEstatePropertyHeader = require('./RealEstatePropertyHeader');
const ContactedAgencies = require('./ContactedAgencies');

// @vue/component
module.exports = {
    components: {
        RealEstatePropertyHeader,
        ContactedAgencies,
    },
    props: {
        firstStepHtmlContent: {
            type: String,
            default: undefined,
        },
        propertyTypePreselection: {
            type: String,
            default: undefined,
        },
        stepToGo: {
            type: Number,
            default: INITIAL_STEP,
        },
    },
    data() {
        return {
            isRequestingAgencies: false,
        };
    },
    computed: {
        landingContentComponent() {
            // @vue/component
            return {
                name: 'LandingContent',
                computed: {
                    commercialAdType() {
                        return this.isMobile ? 'block' : 'halfPage';
                    },
                },
                template: `<div>${this.firstStepHtmlContent}</div>`,
            };
        },
        shouldInlineLabelAndField() {
            return this.step > INITIAL_STEP;
        },
        hasAgenciesToContact() {
            return !_.isEmpty(this.agenciesToContact);
        },
        agenciesToContactForDisplay() {
            return _.sortBy(this.agenciesToContact, 'display_name');
        },
        showMandatoryFieldsExplanation() {
            if (this.step === this.suggestedAgenciesStep && this.hasErrorInSubmission) {
                return false;
            }
            return this.step > INITIAL_STEP && this.step < this.resultStep;
        },
        hasLandingContent() {
            return this.step === INITIAL_STEP && this.firstStepHtmlContent;
        },
        isSuggestedAgenciesStep() {
            return this.step === this.suggestedAgenciesStep;
        },
        hasErrorInSubmission() {
            return this.isSubmission && !this.hasAgenciesToContact;
        },
    },
    watch: {
        'realEstateProperty.address': function () {
            this.agenciesToContact = null;
        },
    },
    created() {
        this.isRequestingAgencies = false;
        _.each(getSenderFromAccount(), (value, key) => {
            this.commitToStore('sender/' + key, value);
        });
        this.commitToStore('step', this.stepToGo);
        const targetPropertyType = _.find(PROPERTY_TYPE_OPTION, {label: this.propertyTypePreselection});
        if (targetPropertyType != null) {
            this.commitRealEstatePropertyStateToStore('propertyType', targetPropertyType.value);
        }
        this.commitToStore('optins', {});
    },
    methods: {
        updateStep(step) {
            this.$emit('update:step', step);
        },
        onPreStepChange(step) {
            this.$emit('on-pre-step-change', step);
        },
        onStepChangeFromUserInteraction(step) {
            this.$emit('on-step-change-from-user-interaction', step);
        },
        updateAccountInfos() {
            const {email, lastName, firstName, phone} = this.sender;
            const updateInfo = {
                email,
                contact: {
                    lastName,
                    firstName,
                    phone,
                },
            };
            _.extend(updateInfo, this.optins);
            ContactHelper.computeUpdateData(updateInfo, (err, updateData) => {
                const featureName = this.translationKeyPrefix.replace('.', ':');
                if (updateData) {
                    Account.update(updateData, (err) => {
                        if (err) {
                            console.error(err, featureName + ' could not update account info');
                        }
                    });
                } else if (err) {
                    console.error(err, featureName + ' could not compute update account info');
                }
            });
        },
        requestAgenciesToContact() {
            const {address} = this.realEstateProperty;
            if (address) {
                let urlParamsText = 'address=' + address;
                if (this.isSubmission) {
                    const submissionType = this.isRental ? 'Rental' : 'Sale';
                    urlParamsText += `&eligibleFor${submissionType}AdSubmissionRequests=true`;
                } else if (this.isEstimation) {
                    urlParamsText += '&eligibleForEstimationRequests=true';
                }
                Account.getAccountAndCreateGuestIfNeeded(() => {
                    this.isRequestingAgencies = true;
                    Account.authAjax({
                        method: 'GET',
                        url: `${ServerConfig.config.estimationUrl}/find-agencies`,
                        data: urlParamsText,
                        disableErrorPage: true,
                        serverErrorMessage: 'find-agencies',
                        callback: (err, result) => {
                            const featureName = this.translationKeyPrefix.replace('.', '-');
                            if (err) {
                                console.error(`Failed looking for matching agencies for ${this.translationKeyPrefix}`);
                            } else {
                                this.agenciesToContact = result;
                                GoogleTagManager.sendEvent(featureName + 'AgenciesToContact');
                            }
                            this.isRequestingAgencies = false;
                        },
                    });
                });
            }
        },
        getAgenciesToContactIds() {
            return _.map(this.agenciesToContact, 'id');
        },
        commitRealEstatePropertyStateToStore(key, value) {
            this.commitToStore('realEstateProperty/' + key, value);
        },
    },
};

function getSenderFromAccount() {
    const account = Account.getAuthenticatedAccount();
    return {
        email: _.get(account, 'email', null),
        firstName: _.get(account, 'contact.firstName', null),
        lastName: _.get(account, 'contact.lastName', null),
        phone: _.get(account, 'contact.phone', null),
    };
}
