/* global Highcharts */

const $ = require('jquery');
const fack = require('fack');
const Errors = require('../utils/Errors');
const LoadScriptHelper = require('../utils/LoadScriptHelper');

let scriptLoader = null;

module.exports = {
    onReady,
};

function onReady(callback, context) {

    if (!scriptLoader) {
        const highchartsUrl = fack.jsUrl('highcharts.js');

        scriptLoader = $.Deferred(function (defer) {
            const onHighchartsLoaded = function () {
                if (window.Highcharts) {
                    // Set highcharts options
                    setOptions();
                    defer.resolve();
                } else {
                    Errors.showUnexpectedError('Failed to load HighCharts');
                    defer.reject();
                }
            };
            if (window.Highcharts) {
                // Already available... handle loaded immediately
                onHighchartsLoaded();
            } else {
                // Set jquery globally required for highcharts jquery module
                const oldJQuery = window.jQuery;
                window.jQuery = $;

                //manual highcharts callback
                window.highChartsLoaded = function () {
                    // Reset jquery
                    window.jQuery = oldJQuery;
                    onHighchartsLoaded();
                };

                LoadScriptHelper.loadScript(highchartsUrl, {
                    crossoriginAllowed: true,
                    errorCallback: function () {
                        // Reset jquery
                        window.jQuery = oldJQuery;
                        // Return error
                        Errors.showUnexpectedError('Failed to load HighCharts at ' + highchartsUrl);
                        defer.reject();
                    },
                });
            }
        });
    }

    // Add callback when api loaded (or already loaded)
    scriptLoader.done(function () {
        //call cb on success
        if (context) {
            callback.call(context);
        } else {
            callback();
        }
    });
}

function setOptions() {
    Highcharts.setOptions({
        lang: {
            months: [
                'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
                'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre',
            ],
            weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            shortMonths: [
                'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin',
                'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc',
            ],
            thousandsSep: ' ',
            decimalPoint: ',',
            resetZoom: 'Dézoomer',
            resetZoomTitle: 'Revenir au zoom initial',
        },
        global: {
            useUTC: false,
        },
    });
}
