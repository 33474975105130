const _ = require('lodash');
const noAdsTemplate = require('../templates/search/results/myRealEstateAdsNoResult.jade');
const UserRealEstateAdsPageBase = require('./UserRealEstateAdsPageBase');

module.exports = class MyRealEstateAdsPage extends UserRealEstateAdsPageBase {
    constructor(configuration) {
        super(_.defaults(configuration || {}, {
            name: 'myRealEstateAds',
            enableOnTheMarketFilter: true,
            isMyRealEstateAds: true,
            noAdsTemplate,
            disableReloadPageOnLogin: false,
            showOwnedAccountSelection: false,
            referenceFilterEnabled: true,
        }));

        //authenticated mode
        this.pageRedirectionOnLogout = '/';
        this.reloadPageOnLogin = true;
        this.requiresRegistration = true;
        this._statsCache = {};
    }

    open(options) {
        this.configuration.loadOptions.filters.onlyOwnData = options.onlyOwnData;
        return super.open(options);
    }
};
