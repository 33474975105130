const {i18n: {translate}} = require('fack');
const template = require('./Ges.jade');
const GesLine = require('./GesLine');

// @vue/component
module.exports = {
    components: {
        GesLine,
    },
    mixins: [
        require('../mixins/commonEnergyDiagnostic'),
    ],
    computed: {
        gesDataConfiguration() {
            return this.dataConfiguration(this.ges.classification);
        },
        gesTitle() {
            return this.gesTranslation('title', {context: 'newEPD'});
        },
        gesSubTitle() {
            return this.gesTranslation('subtitle');
        },
        highestEfficiencyDescription() {
            return this.gesTranslation('highestEfficiencyDescription');
        },
        lowestEfficiencyDescription() {
            return this.gesTranslation('lowestEfficiencyDescription');
        },
        expandedOrActiveGesData() {
            return this.gesDataConfiguration.filter(gesData => {
                return this.isExpandedOrActive(gesData.active);
            });
        },
        showSubTitle() {
            return Boolean(this.ges.value);
        },
    },
    methods: {
        gesTranslation(path, context = null) {
            return translate(`diagnosticModal.ges.${path}`, context);
        },
    },
    template: template(),
};
