const template = require('./AddressInput.jade');

// @vue/component
module.exports = (storeModuleName) => ({
    mixins: [
        require('./mixins/CommonStepMixin')(storeModuleName),
    ],
    props: {
        showMandatoryFieldsExplanation: Boolean,
    },
    computed: {
        allowAddressInMetropolitanAndOverseas() {
            return this.isSubmission;
        },
    },
    template: template(),
});
