const {INITIAL_STEP} = require('../Constants');
const {buildComputed, buildStore} = require('../RealEstatePropertyDescriptorStoreHelpers');

function getState() {
    return {
        step: INITIAL_STEP,
        flatType: null,
        canAnswerEnergyClassification: null,
        showNonRequiredQuestions: false,
        resultLoaded: false,
        estimationDate: null,
        results: null,
        error: null,
        optins: {},
        agenciesToContact: [],
        loading: false,
    };
}

function getRealEstateProperty() {
    return {
        propertyType: null,
        landSurfaceArea: null,
        surfaceArea: null,
        address: null,
        roomsQuantity: null,
        bedroomsQuantity: null,
        bathAndShowerRoomsQuantity: null,
        floorQuantity: null,
        floor: null,
        hasElevator: null,
        hasBalcony: null,
        balconySurfaceArea: null,
        hasTerrace: null,
        terraceSurfaceArea: null,
        hasParking: null,
        hasGarage: null,
        hasCellar: null,
        standard: null,
        yearOfConstruction: null,
        energyClassification: null,
        isLowEnergyBuilding: null,
        viewType: null,
        isBright: null,
        isRefurbished: null,
        toRedecorate: null,
        workToDo: null,
        kitchenType: null,
        hasEquippedKitchen: null,
        heatingType: null,
        hasAirConditioning: null,
        hasBikeShed: null,
        hasPool: null,
        hasConservatory: null,
        showerRoomsQuantity: null,
        bathroomsQuantity: null,
        isFurnished: null,
        parkingPlacesQuantity: null,
        isCalm: null,
        propertyCondition: null,
    };
}

module.exports = {
    computed: buildComputed(getState(), getRealEstateProperty()),
    get: (moduleName) => buildStore(getState(), getRealEstateProperty(), moduleName),
};
