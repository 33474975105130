const _ = require('lodash');
const $ = require('jquery');
const browserDetect = require('browser-detect');
const SuggestionWidget = require('./SuggestionWidget');
const SearchByTravelTimeView = require('./views/SearchByTravelTimeView');
const {loseFocusOnActiveElement} = require('./utils/focus');

const template = require('./templates/suggestionPopupView.jade');
const View = require('./views/View');

module.exports = class SuggestionPopupView extends View {
    constructor(options) {
        super({
            template,
        });
        this.options = options;
        this._closeButtonHandler = _.bind(this.hide, this);
    }

    show(options) {
        this.$container = $('body');
        super.show(options);
        const {$element} = this;
        this.$input = $element.find('#searchLocationPopup');
        this.$deleteButton = $element.find('.block-actions__element_delete');
        this.$closeButton = $element.find('.block-actions__element.block-actions__element_close');
        this.$spinner = $element.find('.block-actions__element_spinner');
        this._initSuggestionWidget();
        this._bindEvents();
        $element.modal('show');
        this.toggleSpinner(false);
        this.fixIOS();
        _.defer(() => {
            if (this._suggestionWidget) {
                this._suggestionWidget.forceFocus();
            }
        }); //Todo maybe a better way to do that
    }

    _bindEvents() {
        this._suggestionWidget.on('change', () => {
            this.emit('change');
        });

        this._suggestionWidget.on('typeahead:asyncrequest', () => {
            this._toggleDeleteButton(this._suggestionWidget.getInputValue());
        });
        this._suggestionWidget.on('typeahead:select', item => {
            this.emit('typeahead:select', item);
            if (item.from === 'travelTimeZone') {
                _.defer(() => { //using defer because we can't blur during event handling (or typeahead sets focus again ?)
                    loseFocusOnActiveElement(); //remove current focus
                });
                this._openTravelTimeSearch();
            }
        });

        this.$deleteButton.on('click', () => {
            this._suggestionWidget.resetInput();
        });
        this.$closeButton.on('click', this._closeButtonHandler);
    }

    _unbindEvents() {
        this.$input.off();
        this.$deleteButton.off();
        this.$closeButton.off();
    }

    toggleSpinner(isLoading) {
        this._isLoading = isLoading;
        this.$spinner.toggleClass('isLoading', isLoading);
    }

    _toggleDeleteButton(query) {
        this.$deleteButton.toggleClass('hasText', query != '');
    }

    fixIOS() {
        if (browserDetect.isIOS()) {
            window.scrollTo(0, 0);
        }
    }

    getFirstSuggestion() {
        const suggestions = this._suggestionWidget.getSelectedLocations();
        if (suggestions && suggestions.length) {
            return suggestions[0];
        }
        return null;
    }

    _initSuggestionWidget() {
        this._suggestionWidget = new SuggestionWidget(_.extend({
            $input: this.$input,
            $spinner: this.$spinner,
        }, this.options));
    }

    _openTravelTimeSearch() {
        SearchByTravelTimeView.open({
            submitCallback: travelTimeZone => {
                const travelTimeTag = this._suggestionWidget.getTravelTimeItem(travelTimeZone);
                this._suggestionWidget.addSuggestion(travelTimeTag);
            },
            closeCallback: browserDetect.isMobile() ? () => this._setInputOnLocationField() : _.noop,
            $container: this.$container,
            defaultItem: this.options.firstTravelTimeItem,
        });
    }

    _setInputOnLocationField() {
        if (this._suggestionWidget) {
            this._suggestionWidget.forceFocus();
        }
    }

    hide(options, cb) {
        this._suggestionWidget.destroy();
        this.emit('close');
        this._unbindEvents();
        this.$element.modal('hide');
        delete this._suggestionWidget;
        super.hide(options, cb);
    }
};
