const _ = require('lodash');
const View = require('../views/View');
const {
    injectVueViews,
    destroyVue,
} = require('./VueInjector');

module.exports = class CompositeVueView extends View {
    _showVueViews(vueOptions) {
        this.injectVueViews(this.$element, vueOptions);
    }

    _hideVueViews() {
        _.each(this._vueViews, view => {
            view.hide();
        });
        delete this._vueViews;
    }

    show(templateOptions, vueOptions = {}) {
        super.show(templateOptions);
        this._showVueViews(vueOptions);
    }

    hide() {
        this._hideVueViews();
        super.hide();
    }

    injectVueViews($element, vueOptions) {
        if (!this._vueViews) {
            this._vueViews = [];
        }
        this._vueViews.push(...injectVueViews($element, vueOptions));
    }

    /**
     * Remove an element from the DOM, ensuring associated Vue instances are destroyed
     * @param $element the element to remove from the DOM
     */
    removeElement($element) {
        destroyVue($element);
        $element.remove();
    }
};
