const PageFactory = require('../../pages/PageFactory');
const ArticlesHubPage = require('./ArticlesHubPage');

module.exports = class ArticlesHubPageFactory extends PageFactory {
    constructor(options) {
        super();
        this.options = options;
    }

    getUrlPattern() {
        return `/${this.options.blog.slug}/:category:/:subcategorySlug:`;
    }

    createPage() {
        return new ArticlesHubPage(this.options);
    }
};
