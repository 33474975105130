const _ = require('lodash');
const LocalStorage = require('./LocalStorage');
const SearchTitleGenerator = require('../../../common/SearchTitleGenerator');

module.exports = {
    get,
    save,
};

const LAST_SEARCH = 'lastSearch';

function save(search) {
    const {locations} = search;
    const locationNames = _.invokeMap(locations, 'getName');
    const title = SearchTitleGenerator.getTitle(search, 'savedSearch');
    LocalStorage.setValue(LAST_SEARCH, _.extend(
        _.omit(search, 'locations', 'page'),
        {
            locationNames,
            title,
        }
    ));
}

function get() {
    const lastSearch = LocalStorage.getValue(LAST_SEARCH);
    if (_.get(lastSearch, 'filterType')) {
        return lastSearch;
    }
}
