const rentalApplicationTrackingMixin = require('../../form/components/mixins/rentalApplicationTrackingMixin');
const RentalApplicationFileUploaderWidget = require('./RentalApplicationFileUploaderWidget');

// @vue/component
module.exports = {
    mixins: [
        require('../../form/components/mixins/fileUploaderMixin'),
        rentalApplicationTrackingMixin,
    ],
    props: {
        value: {
            type: Array,
            default: undefined,
        },
        documentType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            autoProcessQueue: false,
            parallelUploads: 10,
        };
    },
    computed: {
        currentDossier() {
            return this.$store.getters['rentalApplication/currentDossier'];
        },
    },
    watch: {
        documentType(documentType) {
            this.uploader.setDocumentType(documentType);
        },
    },
    created() {
        this.$on('addedfile', () => {
            this.trackDocumentEvent('ajouter');
        });

        this.$on('removedfile', () => {
            this.trackDocumentEvent('retirer');
        });

        this.$on('errormultiple', ({message}) => {
            this.trackDocumentEvent('erreur', message || 'unknown error');
        });
    },
    methods: {
        getUploader() {
            return new RentalApplicationFileUploaderWidget({
                documentType: this.documentType,
                dossierId: this.currentDossier ? this.currentDossier.id : '66b61cf159d9d7ac787b91e3',
            });
        },
        trackDocumentEvent(action, text = '') {
            const placement = this.documentType;
            this.handleDocumentInteraction(action, placement, text);
        },
    },
};
