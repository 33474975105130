const $ = require('jquery');

const TRANSLATION_DURATION_IN_SECOND = 1;
const FADEOUT_DURATION_IN_SECOND = 0.5;
const FALL_DOWN_DISTANCE_IN_PIXELS = 40;

module.exports = {
    translateAndFadeOut,
    fallDown,
};

function createIcon(offset, iconClass) {
    const $body = $('body');
    const $icon = $('<div></div>')
        .addClass(iconClass)
        .css(offset);
    $body.append($icon);
    return $icon;
}

function translateAndFadeOut({$finish, $start, iconClass, finishElementClass} = {}) {
    if ($finish.length && $start && $start.length) {
        const $animatedIcon = createIcon($start.offset(), iconClass);
        $animatedIcon.css({
            transition: 'all ease ' + TRANSLATION_DURATION_IN_SECOND + 's',
            top: $finish.offset().top + $finish.outerHeight() / 2 - $animatedIcon.height() / 2,
            left: $finish.offset().left + $finish.outerWidth() / 2 - $animatedIcon.width() / 2,
        });
        setTimeout(() => {
            $animatedIcon.css({
                transition: 'all ease ' + FADEOUT_DURATION_IN_SECOND + 's',
                opacity: 0,
            });
            $finish.addClass(finishElementClass);
            setTimeout(() => {
                $animatedIcon.remove();
                $finish.removeClass(finishElementClass);
            }, FADEOUT_DURATION_IN_SECOND * 1000);
        }, TRANSLATION_DURATION_IN_SECOND * 1000);
    }
}

function fallDown({$start, iconClass}) {
    const $animatedIcon = createIcon($start.offset(), iconClass);
    $animatedIcon.css({
        transition: 'all ease ' + FADEOUT_DURATION_IN_SECOND + 's',
        top: $animatedIcon.offset().top + FALL_DOWN_DISTANCE_IN_PIXELS,
        opacity: 0,
    });
    setTimeout(() => {
        $animatedIcon.remove();
    }, FADEOUT_DURATION_IN_SECOND * 1000);
}
