const _ = require('lodash');

const template = require('./BaseStat.jade');
const NumberFormatter = require('../../../common/NumberFormatter');

const {BASE_STATS_MODES} = require('./constants');

// @vue/component
module.exports = {
    props: {
        value: {
            type: Number,
            required: true,
        },
        url: {
            type: String,
            default: null,
        },
        isAggregatedForAccount: Boolean,
        mode: {
            type: String,
            default: null,
            validator: mode => _.includes(BASE_STATS_MODES, mode),
        },
        color: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        rootTag() {
            return this.url ? 'a' : 'div';
        },
        modifiers() {
            const {mode} = this;
            return {
                [mode]: Boolean(mode),
            };
        },
        styles() {
            const {color} = this;
            return {
                borderColor: color,
            };
        },
        formattedValue() {
            return NumberFormatter.formatNumber(this.value);
        },
        displayDescription() {
            return this.isAggregatedForAccount;
        },
    },
    template: template(),
};
