const _ = require('lodash');

const template = require('./AgencyExternalLinks.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {SOCIAL_NETWORK_LINKS_KEYS} = require('./constants');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AgencyExternalLinks.',
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
        inColumn: Boolean,
    },
    computed: {
        modifiers() {
            return _.pick(this, 'inColumn');
        },
        website() {
            return this.agency.website;
        },
        socialNetworkLinks() {
            return _.pickBy(_.pick(this.agency, SOCIAL_NETWORK_LINKS_KEYS));
        },
        socialNetworkLinksCount() {
            return _.size(this.socialNetworkLinks);
        },
        displaySocialNetworkLinksButtons() {
            return this.socialNetworkLinksCount > 1;
        },
        hasSocialNetworkLinks() {
            return !_.isEmpty(this.socialNetworkLinks);
        },
        visible() {
            return this.website || this.hasSocialNetworkLinks;
        },
        pitch() {
            if (this.website && !this.hasSocialNetworkLinks) {
                return this.translatePitch('pitchWebsiteOnly');
            } else if (this.website) {
                return this.translatePitch('pitchWebsiteAndSocialNetwork');
            } else {
                return this.translatePitch('pitchSocialNetworkOnly');
            }
        },
        pitchTranslationContext() {
            const context = {
                website: this.website,
                count: this.socialNetworkLinksCount,
            };
            if (this.socialNetworkLinksCount === 1) {
                const socialNetworkKey = _.first(_.keys(this.socialNetworkLinks));
                context.socialNetworkName = this.t(socialNetworkKey);
                context.socialNetworkUrl = this.socialNetworkLinks[socialNetworkKey];
            }
            return context;
        },
    },
    methods: {
        translatePitch(key) {
            return this.t(key, this.pitchTranslationContext);
        },
        icon(key) {
            return `fa-${key}`;
        },
    },
    template: template(),
};
