const _ = require('lodash');
const FieldInputWidget = require('./FieldInputWidget');
const {i18n: {translate}} = require('fack');
const SelectPicker = require('../utils/SelectPicker');
const template = require('../templates/fields/enumField.jade');

module.exports = class EnumWidget extends FieldInputWidget {
    constructor($container, data) {
        const placeholder = data.placeholder = data.placeholder
            || translate(['selectInput.placeholder.' + data.name, 'selectInput.placeholder.default']);
        data = _.extend({
            template,
        }, data);
        super($container, data);
        this.$selectPicker = this.getElement().find('select');
        SelectPicker.enable(this.$selectPicker, {
            title: placeholder,
            noneSelectedText: placeholder,
        });
        if (data.disabled) {
            this.$selectPicker.attr('style', 'display: none !important');
        }
    }

    getFieldValue() {
        return this.$input.val() || null;
    }

    setFieldValue(value) {
        this.$selectPicker.selectpicker('val', value);
    }
};
