const _ = require('lodash');
const i18nMixin = require('../../../vue/components/mixins/i18n');

// @vue/component
module.exports = function (i18nParameters) {
    return {
        mixins: [
            i18nMixin(i18nParameters),
        ],
        props: {
            realEstateAd: {
                type: Object,
                required: true,
            },
            featureName: {
                type: String,
                required: true,
            },
            canBuyFeature: Boolean,
            shrunk: Boolean,
        },
        computed: {
            isFeatured() {
                return Boolean(_.get(this.realEstateAd.status, this.featureName));
            },
            translationKeyPrefix() {
                return 'setFeatured.' + this.featureName + '.';
            },
            expirationDate() {
                const featuredUntilAsString = _.get(this.realEstateAd.status, this.featureName + 'Until');
                if (featuredUntilAsString) {
                    return new Date(featuredUntilAsString);
                }
                return null;
            },
        },
        methods: {
            t(key, option) {
                return this.translate([
                    this.translationKeyPrefix + key,
                    `setFeatured.${this.featureName}.buyForm.` + key,
                ], option);
            },
        },
    };
};
