const _ = require('lodash');
const realEstateAdCreationPublicTemplate = require('../templates/realEstateAdCreationPublicView.jade');
const RealEstateAdCreationBaseView = require('./RealEstateAdCreationBaseView');
const Account = require('../authentication/Account');

module.exports = class RealEstateAdCreationPublicView extends RealEstateAdCreationBaseView {
    constructor() {
        super({
            template: realEstateAdCreationPublicTemplate,
            mode: 'public',
        });
    }

    handleAccountChange() {
        //Edit contact inputs on account 'change' (might do the same with phone)
        const contactWidget = _.get(this, 'sections.contact.fields.contact');
        if (contactWidget) {
            const authenticatedAccount = Account.getAuthenticatedAccount();
            const email = this.getContactFieldValue('emailToDisplay');
            const $email = contactWidget.fields.emailToDisplay.$input;
            if (authenticatedAccount) {
                if (!email) {
                    $email.val(authenticatedAccount.email);
                    this.emailToDisplay = authenticatedAccount.email;
                }
            } else if (this.emailToDisplay == email) {
                $email.val('');
            }

            const phone = this.getContactFieldValue('phoneToDisplay');
            const $phone = contactWidget.fields.phoneToDisplay.$input;
            if (authenticatedAccount) {
                if (!phone && authenticatedAccount.contact && authenticatedAccount.contact.phone) {
                    $phone.val(authenticatedAccount.contact.phone);
                    this.phoneToDisplay = authenticatedAccount.contact.phone;
                }
            } else if (this.phoneToDisplay == phone) {
                $phone.val('');
            }

            const contactName = this.getContactFieldValue('contactNameToDisplay');
            const $contactName = contactWidget.fields.contactNameToDisplay.$input;
            if (authenticatedAccount) {
                if (!contactName && authenticatedAccount.display_name) {
                    $contactName.val(authenticatedAccount.display_name);
                    this.contactNameToDisplay = authenticatedAccount.display_name;
                }
            } else if (this.contactNameToDisplay == contactName) {
                $contactName.val('');
            }
            // Revalidate the fields
            this.$form.data('bootstrapValidator')
                .revalidateField('emailToDisplay')
                .revalidateField('phoneToDisplay')
                .revalidateField('contactNameToDisplay');
        }
    }
};
