const _ = require('lodash');

const VueApplicationPage = require('../../pages/VueApplicationPage');
const History = require('../../utils/History');
const Account = require('../../authentication/Account');
const EmailSearchForm = require('../components/EmailSearchForm');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('../components/EmailStatusPage.jade');

const BASE_URL = '/email-status/';

module.exports = class EmailStatusPage extends VueApplicationPage {
    constructor() {
        super({
            name: 'email-status',
            title: 'Statut des adresses e-mail',
            isRegistrationRequired: true,
            disableReloadPageOnLogin: true,
        });
    }

    getVueComponent(options) {
        const page = this;
        // @vue/component
        return {
            components: {
                EmailSearchForm,
            },
            mixins: [
                i18nMixin({
                    prefix: 'EmailStatusPage.',
                    keys: ['title'],
                }),
            ],
            data() {
                return {
                    emails: options.emails,
                };
            },
            watch: {
                emails(emailsArray) {
                    page._updateUrl(emailsArray);
                },
            },
            template: template(),
        };
    }

    isPageAccessAllowed() {
        return Account.hasRole('emailStatusChecker') || Account.hasRole('dirViewer');
    }

    _updateUrl(emailsArray) {
        const newUrl = BASE_URL + _.join(emailsArray, ',');
        History.pushState({path: newUrl}, this.getTitle(), newUrl);
    }

    parseUrl(url, emails) {
        if (emails) {
            return {emails: emails.split(',')};
        } else {
            return {};
        }
    }

    getUrlPattern() {
        return `${BASE_URL}:emails:`;
    }
};
