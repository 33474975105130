const $ = require('jquery');
const popupHeaderTemplate = require('./templates/popupHeader.jade');
const popupDetailsTemplate = require('./templates/tooltipDetails.jade');
const popupContainerTemplate = require('./templates/popup.jade');
const popupFooterTemplate = require('./templates/stackedMarkerPopupFooter.jade');
const Account = require('./authentication/Account');
const TextFormatter = require('../common/TextFormatter');
const RealEstateAdTitleGenerator = require('../common/RealEstateAdTitleGenerator');
const AddressFormatter = require('../common/formatters/AddressFormatter');
const {i18n: {translate: t}, resourceUrl} = require('fack');
const imageResConfig = require('./imageResConfig');
const moment = require('../common/momentFr');
const _ = require('lodash');
const VirtualTourHelper = require('./virtualTours/VirtualTourHelper');
const Options = require('./Options');
const savedOptions = Options.read();
const {injectVueViews} = require('./vue/VueInjector');
const ApplicationConfig = require('./app/ApplicationConfig');
const realEstateAdPriceSize = require('./utils/realEstateAdPriceSize');

module.exports = {
    getRealEstateAdPopupDetailsHTML,
    getRealEstateAdPopupHeaderHTML,
    getRealEstateAdPopupContainerHTML,
    getRealEstateAdPopupFooterHTML,
};

function getRealEstateAdPopupContainerHTML() {
    return $(popupContainerTemplate());
}

function getRealEstateAdPopupDetailsHTML(realEstateAd) {
    realEstateAd = VirtualTourHelper.enhanceAd(realEstateAd);
    const templateInfo = {
        info: realEstateAd, //deprecated
        realEstateAd,
        TextFormatter,
        RealEstateAdTitleGenerator,
        AddressFormatter,
        resourceUrl,
        t,
        photoUrlSizeQueryString: imageResConfig.photoUrlSizeQueryString('tooltipDetails'),
        moment,
        savedOptions,
        _,
    };
    const $element = $(popupDetailsTemplate(templateInfo));
    // @vue/component
    injectVueViews($element, {
        data() {
            return {
                realEstateAd: _.cloneDeep(realEstateAd),
            };
        },
        computed: {
            hasToDisplayFavoritesInCompactAdOverview() {
                return ApplicationConfig.hasToDisplayFavorites && !Account.isShowRoom();
            },
            realEstateAdPriceSize() {
                return realEstateAdPriceSize(this.realEstateAd);
            },
        },
    });
    return $element;
}

function getRealEstateAdPopupHeaderHTML(realEstateAd) {
    const templateInfo = {
        isAuthenticated: Account.isAuthenticated(),
        isOnTheMarket: realEstateAd.status.onTheMarket,
        isOwner: realEstateAd.userRelativeData.isOwner,
        t,
    };
    return $(popupHeaderTemplate(templateInfo));
}

function getRealEstateAdPopupFooterHTML(realEstateAd, currentPopupAdIndex) {
    const sameLocationMarkersCount = _.get(realEstateAd, 'marker.sameLocationMarkers.length', 0);
    if (sameLocationMarkersCount > 1) {
        const templateInfo = {
            pos: currentPopupAdIndex + 1,
            sameLocationMarkersCount,
        };
        return $(popupFooterTemplate(templateInfo));
    } else {
        return null;
    }
}
