const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const template = require('../templates/PoiInfo.jade');

module.exports.getPoiTooltipHTML = getPoiTooltipHTML;

function getPoiTooltipHTML(marker, isPopup, simpleClickOnPoi) {
    return template({
        name: marker.name,
        tags: marker.tags,
        isPopup,
        isAgency: marker.agencyInfo,
        bgColor: marker.color,
        simpleClickOnPoi, // as opposed to long-click anywhere on the map
        _,
        t: translate,
    });
}
