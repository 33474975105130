const _ = require('lodash');
const $ = require('jquery');

const ApplicationConfig = require('../app/ApplicationConfig');
const EventPack = require('../utils/EventPack');
const Account = require('../authentication/Account');

const AuthenticationPopup = require('../authentication/AuthenticationPopup');
const View = require('./View');
const Views = require('./Views');
const SpinnerHelper = require('./../utils/SpinnerHelper');
const template = require('../templates/views/alertCreationFeedbackModalView.jade');
const savedOptions = require('../Options').read();

module.exports = class AlertCreationFeedbackModalView extends View {

    constructor(options = {}) {
        super(_.defaults(options, {
            $container: $('body'),
        }));
        this.template = template;
        this._eventPack = new EventPack();
    }

    show(options) {
        super.show(_.defaults(options, {
            pitchTranslationKey: 'AlertModalFeedback.pitch',
            displayAvailableAdsTranslationKey: 'AlertModalFeedback.display',
            accountCreationPitchTranslationKey: 'AlertModalFeedback.accountCreationPitch',
            isFromPartnerHost: !(savedOptions.isInEmbeddedMode || ApplicationConfig.isOnPartnersDomain),
        }));
        this.$modal = this.$element;
        this.$confirmModalButton = this.$element.find('.confirmModal');
        this.$accountCreationFormLink = this.$element.find('.linkToAccountCreationForm');
        this.$element.find('.hiddenWhenRegistered').css('display', Account.isRegistered() ? 'none' : 'flex');
        this._setCallback(options);
        this.bindEvents();
        this.$modal.modal('show');
    }

    hide() {
        Account.removeListener('accountCreated', () => this._hideAccountCreationBlock());
        this._eventPack.removeAllListeners();
        this.$modal.modal('hide');
        this.cb = null;
        this.$modal = null;
        this.$confirmModalButton = null;
        super.hide();
    }

    bindEvents() {
        this._eventPack.on(this.$modal, 'hidden.bs.modal', _.bind(this.hide, this));
        this._eventPack.on(this.$confirmModalButton, 'click', _.bind(this._onConfirm, this));
        this._eventPack.on(this.$accountCreationFormLink, 'click', _.bind(this._onAccountCreationLinkClick, this));
    }

    _hideAccountCreationBlock() {
        this.$element.find('.hiddenWhenRegistered').css('display', 'none');
    }

    _onAccountCreationLinkClick() {
        const authenticationPopup = new AuthenticationPopup();
        authenticationPopup.showCreateAccount();
        Account.on('accountCreated', () => this._hideAccountCreationBlock());
    }

    _onConfirm() {
        SpinnerHelper.startButtonSpinner(this.$confirmModalButton);
        this.asyncHelper.doAsync({
            func: this.cb,
            callback: err => {
                if (err) {
                    this._onError(err);
                }
                this.$modal.modal('hide');
            },
        });
    }

    _onError(err) {
        console.error(err);
        Views.volatileFeedback.showError();
    }

    _setCallback({cb}) {
        if (!cb) {
            throw new Error('callback not passed to options');
        }
        this.cb = cb;
    }
};
