const assert = require('assert');
const _ = require('lodash');
const {EventEmitter} = require('events');
const Account = require('../authentication/Account');
const ServiceWorkerHandler = require('./ServiceWorkerHandler');
const LocalStorage = require('../utils/localStorage/LocalStorage');
const Views = require('../views/Views');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');

const emitter = new EventEmitter();

Account.on('change:id', accountChangeHandler);
Account.on('mergeAccounts', transferPushNotificationsEnabled);
Account.addForgetAuthenticationAsyncListener(forgetAuthentication);

module.exports = _.extend(
    emitter,
    {
        areNotificationsCurrentlyEnabled,
        areNotificationsDenied,
        enablePushNotifications,
        disablePushNotifications,
        areNotificationsSupported,
        areNotificationsUnsupportedOrDenied,
    });

function areNotificationsCurrentlyEnabled() {
    return ServiceWorkerHandler.isSubscribed();
}

function areNotificationsDenied() {
    return ServiceWorkerHandler.isPushNotificationDenied();
}

function areNotificationsSupported() {
    return ServiceWorkerHandler.isPushNotificationSupported() || UserAgentHelper.isFromNativeApp();
}

function areNotificationsUnsupportedOrDenied() {
    return !areNotificationsSupported() || areNotificationsDenied();
}

function enablePushNotifications({silent}, cb) {
    if (ServiceWorkerHandler.isPushNotificationSupported()) {
        ServiceWorkerHandler.subscribeSw({silent}, (err, pushRegistration) => {
            if (pushRegistration && !err) {
                setPushNotificationsEnabledInLocalStorage(true);
                setAccountPushRegistration(Account.getAuthenticatedAccountId(), pushRegistration, cb);
            } else {
                cb(err);
            }
            notifyStatusChanged();
        });
    } else {
        _.defer(cb);
    }
}

function disablePushNotifications(cb) {
    if (areNotificationsCurrentlyEnabled()) {
        setPushNotificationsEnabledInLocalStorage(false);
        forgetAuthentication({accountId: Account.getAuthenticatedAccountId()}, err => {
            if (err) {
                console.error(err);
            }
            cb();
        });
    } else {
        cb();
    }
}

function accountChangeHandler({previousAccountId, newAccountId}) {
    const pushNotificationsWasEnabled = isPushEnabledInLocalStorageFor(previousAccountId);
    const pushNotificationsNowEnabled = isPushEnabledInLocalStorageFor(newAccountId);
    if (pushNotificationsNowEnabled) {
        ServiceWorkerHandler.resubscribeSw({silent: true}, (err, pushRegistration) => {
            if (err) {
                console.error('Could not resubscribe to browser notifications', err);
            }
            if (pushRegistration) {
                //this will remove previous account registration
                setAccountPushRegistration(newAccountId, pushRegistration);
            } else if (pushNotificationsWasEnabled) {
                unsubscribeNotificationServiceWorker();
            }
            notifyStatusChanged();
        });
    } else if (pushNotificationsWasEnabled) {
        unsubscribeNotificationServiceWorker();
    }
}

function transferPushNotificationsEnabled({mergedAccountId, targetAccountId}) {
    const pushNotificationsEnabled = isPushEnabledInLocalStorageFor(mergedAccountId);
    const pushNotificationsEnabledInTarget = isPushEnabledInLocalStorageFor(targetAccountId);
    if (pushNotificationsEnabled && !pushNotificationsEnabledInTarget) {
        setPushNotificationsEnabledInLocalStorageFor(pushNotificationsEnabled, targetAccountId);
    }
    LocalStorage.remove(getPushNotificationsEnabledKey(mergedAccountId));
}

function forgetAuthentication({accountId}, cb) {
    unsubscribeNotificationServiceWorker((err, pushSubscription) => {
        assert.strictEqual(err, null); //error already logged in unsubscribeNotificationServiceWorker
        if (pushSubscription) {
            deleteAccountPushRegistration(accountId, pushSubscription, cb);
        } else {
            cb();
        }
    });
}

function unsubscribeNotificationServiceWorker(cb) {
    ServiceWorkerHandler.unsubscribeSw((err, pushSubscription) => {
        if (err) {
            console.error('Could not unsubscribe Notification Service worker', err);
        }
        if (cb) {
            cb(null, pushSubscription);
        }
        notifyStatusChanged();
    });
}

function isPushEnabledInLocalStorageFor(accountId) {
    return accountId && LocalStorage.getValue(getPushNotificationsEnabledKey(accountId));
}

function getPushNotificationsEnabledKey(accountId) {
    return `pushNotificationsEnabled:${accountId}`;
}

function setPushNotificationsEnabledInLocalStorage(allowPushNotifications) {
    setPushNotificationsEnabledInLocalStorageFor(allowPushNotifications, Account.getAuthenticatedAccountId());
}

function setPushNotificationsEnabledInLocalStorageFor(allowPushNotifications, accountId) {
    LocalStorage.setValue(getPushNotificationsEnabledKey(accountId), allowPushNotifications);
}

function setAccountPushRegistration(accountId, pushRegistration, cb) {
    pushRegistration = JSON.parse(JSON.stringify(pushRegistration));
    if (!pushRegistration.keys) {
        //registration system is too old, do not register in account
        Views.volatileFeedback.showError('pushNotification.notCompatible');
    } else {
        Account.updateAccountPushRegistration(accountId, pushRegistration, 'POST', cb);
    }
}

function deleteAccountPushRegistration(accountId, pushRegistration, cb) {
    Account.updateAccountPushRegistration(accountId, pushRegistration, 'DELETE', cb);
}

function notifyStatusChanged() {
    emitter.emit('notificationEnabledChanged');
}
