//TODO move requires inside configureRoutes to improve page loading
const _ = require('lodash');
const Pages = require('../pages/Pages');
const PagesFactories = require('../pages/PagesFactories');
const {addRoute, addPage} = require('../pages/PageManager');
const SearchPageFactory = require('../search/pageFactories/SearchPageFactory');
const DetailedSheetPage = require('../pages/DetailedSheetPage');
const FavoritesPageFactory = require('../search/pageFactories/FavoritesPageFactory');
const MyRealEstateAdsPageFactory = require('../search/pageFactories/MyRealEstateAdsPageFactory');
const RealEstateAdModificationPage = require('../adEditor/RealEstateAdModificationPage');
const ArticleModificationPage = require('../blog/article/edition/ArticleModificationPage');
const ArticleCreationPage = require('../blog/article/edition/ArticleCreationPage');
const RealEstateAdCreationPage = require('../adEditor/RealEstateAdCreationPage');
const GeocodingStatsPage = require('../pages/GeocodingStatsPage');
const Urls = require('../Urls');
const ApplicationRoutesPublicCommon = require('./ApplicationRoutesPublicCommon');
const ApplicationConfig = require('./ApplicationConfig');
const AdSuggestionPage = require('../pages/AdSuggestionPage');
const defaultHeaderTemplate = require('../header/applicationHeaderPublic.jade');

module.exports = configureRoutes;

function configureRoutes(savedOptions) {
    const {commonPages, footerView, commonPagesFactories} = ApplicationRoutesPublicCommon.configureRoutes(savedOptions, {
        defaultHeaderTemplate,
        isMobile: false,
    });

    // Pages and Routes

    const myRealEstateAdsPageFactory = new MyRealEstateAdsPageFactory({
        mapButtonsSearchEnabled: true,
    });
    addPage(myRealEstateAdsPageFactory);

    const favoritesPageFactory = new FavoritesPageFactory({
        mapButtonsSearchEnabled: true,
    });
    addPage(favoritesPageFactory);

    const searchCommonOptions = {
        footerView,
        dataModeListEnabled: !ApplicationConfig.isOnPartnersDomain,
        commercialAdsAllowed: true,
    };
    const searchPageOptions = _.extend({
        moreFiltersVisible: true,
        mapButtonsSearchEnabled: true,
        enableBreadcrumb: true,
        enableSeoContent: !ApplicationConfig.isOnPartnersDomain,
    }, searchCommonOptions);
    const searchPageFactory = new SearchPageFactory(searchPageOptions);
    addPage(searchPageFactory);

    const savedSearchAdsPageFactory = ApplicationRoutesPublicCommon.createSavedSearchFactoryPage({
        savedOptions,
        searchCommonOptions,
        searchPageOptions,
    });

    const detailedSheetPage = new DetailedSheetPage({
        mapFilterBtnEnabled: false,
        enableCguLinkFullText: ApplicationConfig.isOnPartnersDomain,
        footerView,
        dataModeListEnabled: !ApplicationConfig.isOnPartnersDomain,
    });
    addRoute(Urls.detailedSheet.pattern, detailedSheetPage);

    const adSuggestionPage = new AdSuggestionPage({
        mapFilterBtnEnabled: false,
        bodyClass: 'suggestionPage',
    });
    addPage(adSuggestionPage);

    // only in desktop
    const adModificationPage = new RealEstateAdModificationPage({
        mode: 'public',
    });
    addPage(adModificationPage);
    addPage(new RealEstateAdCreationPage('public'));
    addPage(new GeocodingStatsPage());

    const articleModificationPage = new ArticleModificationPage();
    addPage(articleModificationPage);
    addPage(new ArticleCreationPage());

    _.extend(Pages, {
        detailedSheet: detailedSheetPage,
        adModification: adModificationPage,
        articleModificationPage,
        adSuggestionPage,
    }, commonPages);

    _.extend(PagesFactories, {
        savedSearchAdsPageFactory,
        myRealEstateAdsPageFactory,
        searchPageFactory,
    }, commonPagesFactories);
}
