const _ = require('lodash');
const template = require('./ResultsLoader.jade');

// @vue/component
module.exports = {
    props: {
        steps: {
            type: Object,
            default: () => {
                return {
                    900: 'Chargement...',
                };
            },
        },
        delayBeforeFinish: {
            type: Number,
            default: 500,
        },
        finish: { // the loading will not finish unless this property is set to true
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startLoadingDate: null,
            timeSpentLoading: 0,
            maxTimeToWait: 0,
        };
    },
    watch: {
        finish() {
            if (this.timeSpentLoading >= this.maxTimeToWait && this.finish) {
                this.emitFinish();
            }
        },
    },
    mounted() {
        this.startLoadingDate = new Date();
        this.maxTimeToWait = _(this.steps)
            .keys()
            .map(parseFloat)
            .max();
        this.updateTimeSpent();
    },
    destroyed() {
        clearTimeout(this.timeout);
    },
    methods: {
        getEndingTimeOfPreviousStep(object, index) {
            return _.keys(object)[index - 1] || 0;
        },
        updateTimeSpent() {
            const now = new Date();
            if (this.startLoadingDate) {
                this.timeSpentLoading = now - this.startLoadingDate;
                if (this.timeSpentLoading < this.maxTimeToWait) {
                    this.timeout = setTimeout(() => {
                        this.updateTimeSpent();
                    }, 10);
                } else if (this.finish) {
                    this.emitFinish();
                }
            }
        },

        emitFinish() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$emit('finish');
            }, this.delayBeforeFinish);
        },
    },
    template: template(),
};
