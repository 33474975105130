const Vue = require('vue');

const {
    REMOVE_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH,
    SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH,
    SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCHES,
} = require('../store/Mutations');

module.exports = {
    namespaced: true,
    state() {
        return {
            savedSearchesIdToNewAdsCount: {},
        };
    },
    getters: {
        getSavedSearchCount: (state) => (savedSearchesId) => {
            return state.savedSearchesIdToNewAdsCount[savedSearchesId];
        },
    },
    mutations: {
        [SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH]: (state, {savedSearchId, newAdsCount}) => {
            Vue.set(state.savedSearchesIdToNewAdsCount, savedSearchId, newAdsCount);
        },
        [SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCHES]: (state, savedSearchesIdToNewAdsCount) => {
            state.savedSearchesIdToNewAdsCount = savedSearchesIdToNewAdsCount;
        },
        [REMOVE_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH]: (state, savedSearchId) => {
            Vue.delete(state.savedSearchesIdToNewAdsCount, savedSearchId);
        },
    },
};
