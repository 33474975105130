const _ = require('lodash');
const template = require('./Img.jade');
const {
    backgroundLightColor: LOADING_SPINNER_COLOR,
} = require('../../colors');

/**
 * Available values for 'parent-fit' property. If set to anything else than 'undefined', the image is resized to fit its container.
 * Their effects can be visualized at https://developer.mozilla.org/fr/docs/Web/CSS/object-fit
 * @readonly
 * @enum {string}
 */
const FITS = [
    'fill',
    'contain',
    'cover',
    'none',
];

// @vue/component
module.exports = {
    inheritAttrs: false,
    constants: {
        LOADING_SPINNER_COLOR,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        errorSrc: {
            type: String,
            default: undefined,
        },
        parentFit: {
            type: String,
            default: undefined,
            values: FITS,
            validator: fit => _.includes(FITS, fit),
        },
        parentPosition: {
            type: String,
            default: undefined,
        },
        loadingSpinner: Boolean,
    },
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        displaySpinner() {
            const {loadingSpinner, isLoading} = this;
            return loadingSpinner && isLoading;
        },
        imgStyles() {
            const {parentFit, parentPosition} = this;
            return {
                objectFit: parentFit,
                objectPosition: parentPosition,
            };
        },
        fitToParentModifiers() {
            const {parentFit, parentPosition} = this;
            return {
                fitToParent: Boolean(parentFit || parentPosition),
            };
        },
    },
    watch: {
        src() {
            this.isLoading = true;
        },
    },
    methods: {
        onLoad() {
            this.hideSpinner();
        },
        onError(event) {
            this.hideSpinner();
            const {errorSrc, $listeners} = this;
            if (errorSrc) {
                event.target.src = errorSrc;
            } else if ($listeners.error) {
                this.$emit('error');
            }
        },
        hideSpinner() {
            this.isLoading = false;
        },
    },
    template: template(),
};
