const _ = require('lodash');

const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./AccountLogo.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AccountLogo.',
            keys: [
                'photoWatermarkAlternativeText',
            ],
        }),
    ],
    props: {
        accountDisplayName: {
            type: String,
            required: true,
        },
        photoWatermarkUrl: {
            type: String,
            default: undefined,
        },
        tracking: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            hasImageError: false,
        };
    },
    computed: {
        internalTracking() {
            if (this.href) {
                return {
                    class: 'account-logo',
                    ..._.pick(this.tracking, ['type', 'field']),
                };
            } else {
                return undefined;
            }
        },
        href() {
            return _.get(this.tracking, 'href');
        },
        rel() {
            return _.get(this.tracking, 'rel');
        },
        componentTag() {
            return this.href ? 'a' : 'div';
        },
        showWatermark() {
            return Boolean(this.photoWatermarkUrl) && !this.hasImageError;
        },
        modifiers() {
            return {
                slideshowFullscreen: _.includes(_.get(this.tracking, 'field'), 'slideshow-fullscreen'),
            };
        },
    },
    watch: {
        photoWatermarkUrl() {
            this.hasImageError = false;
        },
    },
    methods: {
        onImageError() {
            this.hasImageError = true;
        },
    },
    template: template(),
};
