const assert = require('assert');
const _ = require('lodash');
const {
    NAMESPACE,
    ELEMENT_SEPARATOR,
    MODIFIER_SEPARATOR,
} = require('../Constants');

/**
 * A directive to generate BEM CSS classes.
 * Usage:
 * ```html
 * <div v-bem>
 *     <div v-bem:element-name></div>
 *     <div v-bem:element-name="{modifierName: true}"></div>
 *     <div v-bem:element-name.modifier-name.other-modifier-name="{modifierName: true}"></div>
 * </div>
 * ```
 */
module.exports = {
    getClasses,
    directive: {
        install(Vue) {
            Vue.directive('bem', {
                inserted($el, binding, vnode) {
                    const observer = new MutationObserver(() => {
                        addClassesToElement($el);
                    });
                    $el.observer = observer;
                    observer.observe($el, {
                        attributes: true,
                        attributesFilter: ['class'],
                    });
                    const {arg, value, modifiers} = binding;
                    const classes = getClasses(arg, value, modifiers, vnode);
                    $el.vueBemDirectiveClasses = classes;
                    addClassesToElement($el);
                },
                update($el, binding, vnode) {
                    const {arg, value, oldValue, modifiers} = binding;
                    if (!_.isEqual(value, oldValue)) {
                        const classes = getClasses(arg, value, modifiers, vnode);
                        const oldClasses = getClasses(arg, oldValue, modifiers, vnode);
                        const removedClasses = _.difference(oldClasses, classes);
                        removeClassesFromElement($el, removedClasses);
                        $el.vueBemDirectiveClasses = classes;
                        addClassesToElement($el);
                    }
                },
                unbind($el) {
                    const {observer} = $el;
                    if (observer) {
                        observer.disconnect();
                    }
                },
            });
        },
    },
};

function addClassesToElement($el) {
    const {classList, vueBemDirectiveClasses} = $el;
    _.each(vueBemDirectiveClasses, klass => {
        if (!classList.contains(klass)) { // to avoid an endless loop by triggering MutationObserver on '.contains()'
            classList.add(klass);
        }
    });
}

function removeClassesFromElement($el, classes) {
    _.each(classes, klass => $el.classList.remove(klass));
}

function getClasses(arg, values, modifiers, vnode) {
    const blockName = getBlockName(vnode);
    const classPrefix = blockName + (arg ? ELEMENT_SEPARATOR + _.kebabCase(arg) : '');
    if (_.isArray(values)) {
        values = _(values)
            .map(value => [value, true])
            .fromPairs()
            .value();
    } else {
        assert.ok(_.every(values, value => value == null || _.isBoolean(value)),
            'BEM directive curently only accepts boolean modifiers');
    }
    const bemModifiersClasses = _({})
        .extend(values, modifiers)
        .pickBy()
        .map((_active, mod) => classPrefix + MODIFIER_SEPARATOR + _.kebabCase(mod))
        .value();
    return [classPrefix].concat(bemModifiersClasses);
}

function getBlockName(vnode) {
    const {context} = vnode;
    const {$options} = context;
    const name = $options.bemName || $options.name || context.$vnode.componentOptions.tag;
    return _.kebabCase(name.replace(NAMESPACE, ''));
}
