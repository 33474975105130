module.exports = {
    // types des champs présents par défaut (pour affichage avant récupération du schéma) - string par défaut
    street: {
        type: 'address',
    },
    address: {
        type: 'ignore',
    },
    postalCode: {
        type: 'ignore',
    },
    city: {
        type: 'ignore',
    },
    description: {
        type: 'textarea',
    },
    contactNameToDisplay: {
        type: 'contactName',
    },
    emailToDisplay: {
        type: 'contactEmail',
    },
    phoneToDisplay: {
        type: 'contactPhone',
    },
    contact: {
        type: 'contactFields',
    },
    cgu: {
        type: 'confirmCheckbox',
    },
    isLeading: {
        type: 'confirmCheckbox',
    },
    highlighted: {
        type: 'confirmCheckbox',
    },
    is3dHighlighted: {
        type: 'confirmCheckbox',
    },
    displayDistrictName: {
        type: 'confirmCheckbox',
    },
    price: {
        data: {
            min: {
                rent: 1,
                buy: 100,
            },
        },
    },
};
