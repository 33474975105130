const $ = require('jquery');
const SatisfactionSurveyModal = require('./components/SatisfactionSurveyModal');
const Views = require('../views/Views');

module.exports = {
    display,
    setActive,
};

function display(url, probability) {
    if (shouldDisplayModal(probability)) {
        const modal = new SatisfactionSurveyModal();
        modal.show({url});
    }
}

function shouldDisplayModal(n) {
    return !isAnyModalDisplayed()
        && Math.random() < n;
}

function setActive() {
    window.SatisfactionSurvey = this;
}

function isAnyModalDisplayed() {
    const $body = $(document.body);
    const {header} = Views;
    return $body.hasClass('modal-open')
        || header.isAdvancedSearchViewOpen()
        || header.isSideMenuOpen();
}
