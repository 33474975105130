const $ = require('jquery');
const template = require('./DateRangePicker.jade');
const StatsHelper = require('../../stats/StatsHelper');
const moment = require('../../../common/momentFr');
const _ = require('lodash');
const i18nMixin = require('../../vue/components/mixins/i18n');
const RANGES_CONF = [
    {
        key: 'lastDays',
        count: 7,
        type: 'day',
    },
    {
        key: 'lastDays',
        count: 30,
        type: 'day',
    },
    'week',
    'month',
    'year',
];

// @vue/component
module.exports = {
    bemName: 'date-range-picker',
    mixins: [
        i18nMixin({
            prefix: 'DateRangePicker.',
            keys: [
                {
                    'dateFormatting.startDatePrefix': 'startDatePrefix',
                    'dateFormatting.endDatePrefix': 'endDatePrefix',
                },
                'firstDay',
                'dateFormat',
            ],
        }),
    ],
    props: {
        startDate: {
            type: [String, Object],
            default: undefined,
        },
        endDate: {
            type: [String, Object],
            default: undefined,
        },
    },
    data() {
        return {
            container: null,
            internalStartDate: null,
            internalEndDate: null,
        };
    },
    computed: {
        defaultDates() {
            return StatsHelper.getDefaultPeriod();
        },
        formattedStartDate() {
            return moment(this.internalStartDate).format(this.dateFormat);
        },
        formattedEndDate() {
            return moment(this.internalEndDate).format(this.dateFormat);
        },
    },
    watch: {
        startDate() {
            this.refreshInternalDates();
        },
        endDate() {
            this.refreshInternalDates();
        },
    },
    mounted() {
        this.refreshInternalDates();
        this.initPicker();
    },
    methods: {
        initPicker() {
            const {internalStartDate, internalEndDate} = this;
            if (this.container) {
                this.container.daterangepicker('destroy');
            }
            const container = $(this.$refs.dateRangePicker);
            const config = this.makeBaseConfiguration();
            container.daterangepicker({
                ...config,
                startDate: internalStartDate,
                endDate: internalEndDate,
            });
            container.on('apply.daterangepicker', (ev, picker) => {
                this.$emit('dates-changed', {
                    startDate: (this.internalStartDate = picker.startDate),
                    endDate: (this.internalEndDate = picker.endDate),
                });
            });
            this.container = container;
        },
        refreshInternalDates() {
            const {startDate, defaultDates: {startDate: defaultStartDate, endDate: defaultEndDate}, endDate, dateFormat} = this;
            this.internalStartDate = (startDate ? moment(startDate) : null) || defaultStartDate;
            this.internalEndDate = (endDate ? moment(endDate) : null) || defaultEndDate;
            if (this.container) {
                this.container.data('daterangepicker').setStartDate(this.internalStartDate.format(dateFormat));
                this.container.data('daterangepicker').setEndDate(this.internalEndDate.format(dateFormat));
            }
        },
        makeBaseConfiguration() {
            const {dateFormat, firstDay, t} = this;
            const locale = {
                ...t('defaultLocale', {
                    returnObjectTrees: true,
                }),
                firstDay: Number(firstDay),
                format: dateFormat,
            };
            return {
                autoApply: true,
                locale,
                opens: 'center',
                ranges: this.getRanges(),
                dateFormat,
            };
        },
        getRanges() {
            const currentDate = moment();
            return _.zipObject(
                _.map(RANGES_CONF, this.getRangeKey),
                _.map(RANGES_CONF, configItem => this.getRangeFromConf(currentDate, configItem))
            );
        },
        getRangeKey(rangeConf) {
            const key = _.get(rangeConf, 'key', `current${_.capitalize(rangeConf)}`);
            const count = _.get(rangeConf, 'count');
            return this.t(`ranges.${key}`, {count});
        },
        getRangeFromConf(currentDate, rangeConf) {
            let type;
            let count;
            if (_.isObject(rangeConf)) {
                ({type, count} = rangeConf);
            } else {
                type = rangeConf;
            }
            const startOfCurrentDate = currentDate.clone().startOf(type);
            const startDateByType = count ?
                startOfCurrentDate.subtract(count - 1, `${type}s`) : startOfCurrentDate;
            return [
                startDateByType,
                currentDate.clone().endOf(type),
            ];
        },
    },
    template: template(),
};
