const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const template = require('./ChargingStationsDetailCell.jade');

// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        displayRecomputeStations: {
            type: Boolean,
        },
        isAdPositionExact: {
            type: Boolean,
        },
    },
    computed: {
        providers() {
            return _.get(this.realEstateAd.chargingStations, 'providers');
        },
        prefixText() {
            const {providers} = this;
            let key;
            if (providers == null) {
                key = 'nullProviders';
            } else if (_.isEmpty(providers)) {
                key = 'emptyProviders';
            } else if (_.isEqual(providers, ['zeplug'])) {
                key = 'prefixWithoutProviders';
            } else {
                key = 'prefix';
            }
            return getDetailText(key);
        },
    },
    methods: {
        providerUrl(provider) {
            return getProviderText(provider, 'url');
        },
        providerName(provider) {
            return getProviderText(provider, 'name');
        },
    },
    template: template(),
};

function getText(key) {
    return translate('chargingStations.' + key);
}

function getDetailText(key) {
    return getText('detailCell.' + key);
}

function getProviderText(provider, key) {
    return getText(`providers.${provider}.${key}`);
}
