const _ = require('lodash');

const ApplicationPage = require('./ApplicationPage');
const FormContactView = require('../views/FormContactView');
const PageManager = require('./PageManager');
const Pages = require('./Pages');
const EventPack = require('../utils/EventPack');
const Urls = require('../Urls');
const Account = require('../authentication/Account');

module.exports = class ContactPage extends ApplicationPage {
    constructor(configuration) {
        configuration = _.defaults(configuration, {
            name: 'unammed',
            title: 'Bien’ici',
            formType: 'contactUs',
        });
        super(configuration);
        this.configuration = configuration;
        this._eventPack = new EventPack();
        this.initFormContactView();
    }

    open(options) {
        this.options = _.assignIn(options, this.configuration);
        super.open(options);
        this._bindEvents();
        this.formContactView.setDataFromUrl(this.formData);
    }

    close() {
        this._eventPack.removeAllListeners();
        super.close();
    }

    getMiddleViews() {
        return this.formContactView;
    }

    initFormContactView() {
        this.formContactView = new FormContactView(this.configuration);
    }

    _bindEvents() {
        this._eventPack.on(this.formContactView, 'closePage', () => {
            PageManager.openPage(this.previousPage, this.previousPageOptions);
        });
    }

    _loadData(options, callback) {
        this.previousPage = PageManager.getCurrentPage() || Pages.home;
        this.previousPageOptions = _.get(this.previousPage, 'options', {});
        Account.getAccountAndCreateGuestIfNeeded(err => {
            callback(err, options);
        });
    }

    parseUrl(url) {
        this.formData = Urls.formContact.parseUrl(url);
        return this.formData;
    }
};
