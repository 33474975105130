const _ = require('lodash');
const {isMobile} = require('browser-detect');

const template = require('./AdsNotificationBoostsHistory.jade');
const DataTable = require('../../pro/components/DataTable');
const DateRangePicker = require('../../form/components/DateRangePicker');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');
const BemClassesMixin = require('../../vue/mixins/BemClasses');
const {BREAKPOINTS_LIMITS_XS} = require('../../vue/utils/Breakpoints');
const {getDefaultPeriod} = require('../../stats/StatsHelper');
const {humanizeDay} = require('../../../common/formatters/DateFormatter');
const {
    getAdsNotificationBoostsHistory,
    formatHistoryData,
    getDefaultSelectedTransactionTypes,
} = require('../AdsNotificationBoostsHistoryHelpers');

const TRANSACTION_TYPE_OPTIONS = [
    {label: 'Locations', value: 'rent'},
    {label: 'Transactions', value: 'buy'},
];
const COLUMNS = [
    {
        label: 'date',
        sortable: true,
    },
    {
        label: 'adTitle',
        sortable: false,
    },
    {
        label: 'emailingSentCount',
        sortable: true,
    },
];
const DEFAULT_SORT_KEY = 'date';

// @vue/component
module.exports = {
    components: {
        DateRangePicker,
        DataTable,
    },
    mixins: [
        ContainerQueries({xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS}}),
        i18nMixin({
            prefix: 'AdsNotificationBoostsHistory.',
            keys: [
                'title',
                'transactionTypeSelectPlaceHolder',
                'transactionTypeDataCountSelectedText',
            ],
        }),
        BemClassesMixin,
    ],
    constants: {
        TRANSACTION_TYPE_OPTIONS,
        DEFAULT_SORT_KEY,
    },
    props: {
        accountId: {
            type: String,
            required: true,
        },
        boostsHistory: {
            type: Array,
            default: undefined,
        },
    },
    data() {
        const {startDate, endDate} = getDefaultPeriod();
        return {
            hasError: false,
            isLoading: true,
            selectedTransactionTypes: getDefaultSelectedTransactionTypes(),
            startDate,
            endDate,
            data: [],
        };
    },
    computed: {
        showTitle() {
            return !isMobile();
        },
        columns() {
            return _.map(COLUMNS, column => {
                const {t, dataTableTranslate} = this;
                const {label} = column;
                if (label === 'emailingSentCount') {
                    column.defaultValue = dataTableTranslate('waiting');
                }
                return {
                    ...column,
                    formattedLabel: t(`dataTable.header.${label}`),
                };
            });
        },
        dataTableTranslationTexts() {
            const {startDate, endDate, dataTableTranslate} = this;
            return {
                noResultsMessage:
                    dataTableTranslate('noResultsMessage', {startDate: humanizeDay(startDate), endDate: humanizeDay(endDate)}),
                titleSuffix: dataTableTranslate('titleSuffix'),
                titleSuffixPlural: dataTableTranslate('titleSuffix', {context: 'plural'}),
                loadingMessage: dataTableTranslate('loading'),
            };
        },
    },
    watch: {
        selectedTransactionTypes() {
            this.fetchData();
        },
    },
    mounted() {
        if (this.boostsHistory) {
            this.data = this.boostsHistory;
            this.isLoading = false;
        } else {
            this.fetchData();
        }
    },
    methods: {
        periodChanged(newVal) {
            const {startDate, endDate} = newVal;
            this.startDate = startDate;
            this.endDate = endDate;
            this.selectedPeriod = newVal;
            this.fetchData();
        },
        fetchData() {
            this.isLoading = true;
            const {selectedTransactionTypes: transactionTypes, startDate, endDate, accountId} = this;
            getAdsNotificationBoostsHistory({accountId, transactionTypes, startDate, endDate}, (err, result) => {
                this.isLoading = false;
                if (err || !result.success) {
                    this.hasError = true;
                } else {
                    this.data = formatHistoryData(result.data.boostsHistory);
                }
            });
        },
        dataTableTranslate(key, options = {}) {
            return this.t('dataTable.' + key, options);
        },
        getAdHref(item) {
            if (!item) {
                return null;
            } else {
                const {realEstateAd} = item;
                return realEstateAd && '/annonce/' + realEstateAd.id;
            }
        },
        getDataTableCellClass(cellName) {
            return this.getBemClasses(`cell-${cellName}`)[0];
        },
    },
    template: template(),
};
