const _ = require('lodash');

const template = require('./RealEstateAdStatsChart.jade');
const StatsConfig = require('../StatsConfig');
const KimonoChart = require('../../vue/components/Chart');
const MomentValidator = require('../../vue/validators/Moment');
const {DEFAULT_CHART_OPTIONS, getChartSeries, mergeChartOptions} = require('../../utils/statsChart');
const moment = require('../../../common/momentFr');

const PRICE_AXIS = {
    yAxis: [
        {
            allowDecimals: false,
            title: {
                text: '',
            },
            id: 'price',
            opposite: true,
            labels: {
                format: '{value} €',
            },
        },
    ],
};
const SERIES_CONFIG = {
    price: {
        yAxis: 'price',
        type: 'line',
    },
};

// @vue/component
module.exports = {
    components: {
        KimonoChart,
    },
    constants: {
        CHART_OPTIONS: mergeChartOptions(DEFAULT_CHART_OPTIONS, PRICE_AXIS),
    },
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
        startDate: {
            type: Object,
            required: true,
            validator: MomentValidator,
        },
        endDate: {
            type: Object,
            required: true,
            validator: MomentValidator,
        },
        enabledStats: {
            type: Array,
            default: null,
        },
    },
    computed: {
        chartOptions() {
            const {startDate, endDate, priceHistoryGraphSeries, CHART_OPTIONS, enabledStats} = this;
            // if stats props has no statistics properties yet, default options object is sent to the chart
            // otherwise, if we have statistics values in the stats props, we update the options series to update the chart,
            if (this.stats.views) {
                const stats = _.extend(
                    {
                        price: {
                            data: priceHistoryGraphSeries,
                        },
                    },
                    this.stats
                );
                const availableStats = StatsConfig.getAvailableStats();
                const statNames = _.intersection(enabledStats || availableStats, availableStats);
                const chartSeries = getChartSeries({
                    statNames,
                    stats,
                    days: {startDate, endDate},
                    seriesConfig: SERIES_CONFIG,
                });
                return {
                    ...CHART_OPTIONS,
                    series: chartSeries,
                };
            } else {
                return this.CHART_OPTIONS;
            }
        },
        oldestDateForStats() {
            // start before the publication date to see the graphs start with zero
            return this.realEstateAd.thresholdDate || moment(this.realEstateAd.publicationDate).subtract(1, 'day').toDate();
        },
        priceHistoryGraphSeries() {
            const {realEstateAd} = this;
            if (!realEstateAd.price) {
                return [];
            }
            const priceSeries = [];

            let previousDate = new Date(this.oldestDateForStats).valueOf();
            if (realEstateAd.priceHistory) {
                _.each(realEstateAd.priceHistory, function (historyEntry) {
                    const toDate = new Date(historyEntry.entryDate).valueOf();
                    priceSeries.push([previousDate + 1, historyEntry.price]);
                    priceSeries.push([toDate, historyEntry.price]);
                    previousDate = toDate;
                });
            }
            priceSeries.push([previousDate + 1, realEstateAd.price]);
            priceSeries.push([new Date().valueOf(), realEstateAd.price]);
            return priceSeries;
        },
    },
    template: template(),
};
