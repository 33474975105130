const async = require('async');
const _ = require('lodash');

module.exports = {
    geolocationSupported,
    getCurrentPosition,
};

function geolocationSupported() {
    return _.get(navigator, 'geolocation.getCurrentPosition');
}

function getCurrentPosition(options, cb) {
    if (geolocationSupported()) {
        navigator.geolocation.getCurrentPosition(pos => cb(null, pos), cb, options);
    } else {
        async.setImmediate(cb);
    }
}
