const $ = require('jquery');
const _ = require('lodash');
const {EventEmitter} = require('events');
const Popup = require('./Popup');
const PopupLayerHelper = require('./PopupLayerHelper');
const dialogTemplate = require('./templates/dialog.jade');
const Tooltip = require('./Tooltip');
const SideMapViewSingleton = require('./views/SideMapViewSingleton');

module.exports = class MarkerInfo extends EventEmitter {
    constructor(realEstateAd, options) {
        super();
        this.realEstateAd = realEstateAd;
        this.onSelectedCallback = options.onSelectedCallback;
        this.searchCriteria = options.searchCriteria;
        const realEstateAdStackedList = options.realEstateAdStackedList;
        if (realEstateAdStackedList) {
            this.realEstateAdStackedList = realEstateAdStackedList;
            this.currentIndex = _.indexOf(realEstateAdStackedList, _.find(realEstateAdStackedList, {id: realEstateAd.id}));
        }
        this.isPopup = options.isPopup;
        if (this.isPopup) {
            this._initPopup();
        } else {
            this._initTooltip();
        }
        PopupLayerHelper.openPopup(SideMapViewSingleton.get().popupLayer, this.realEstateAd.marker, this.$templateInfo);
    }

    updateMarkerInfo(realEstateAd) {
        this.oldRealEstateAd = this.realEstateAd;
        this.realEstateAd = realEstateAd;
        const $oldDetailsTemplate = this.$templateDetails;
        this._initPopupDetails();
        this.$templateContainer.find('.infoDetails').append(this.$templateDetails);
        this._updatePopupId();
        $oldDetailsTemplate.remove();
        PopupLayerHelper.setMarker(SideMapViewSingleton.get().popupLayer, this.realEstateAd.marker);
    }

    _initPopup() {
        this.$templateContainer = Popup.getRealEstateAdPopupContainerHTML();
        this._initPopupHeader();
        this._initPopupDetails();
        this.$templateFooter = Popup.getRealEstateAdPopupFooterHTML(this.realEstateAd, this.currentIndex);
        if (this.$templateFooter) {
            this._bindPopupFooter();
        }
        this._initHtml();
    }

    _initTooltip() {
        this.$templateContainer = Tooltip.getRealEstateAdTooltipContainerHTML();
        this._initTooltipDetails();
        this.$templateFooter = Tooltip.getRealEstateAdTooltipFooterHTML(this.realEstateAd);
        this._initHtml();
    }

    _initHtml() {
        this.$templateInfo = this.$templateContainer;
        if (this.$templateHeader) {
            this.$templateInfo.find('.infoHeader').append(this.$templateHeader);
        }
        if (this.$templateDetails) {
            const $infoDetails = this.$templateInfo.find('.infoDetails');
            $infoDetails.append(this.$templateDetails);
            const isUndisclosedAddress = SideMapViewSingleton.get().mustShowUndisclosedAddressMessage(this.realEstateAd);
            if (isUndisclosedAddress) {
                const $dialogDialog = $(dialogTemplate({isPopup: true}));
                $infoDetails.append($dialogDialog);
            }
        }
        if (this.$templateFooter) {
            this.$templateInfo.find('.infoFooter').append(this.$templateFooter);
        }
        this._updatePopupId();
    }

    _initPopupHeader() {
        if (this.$templateHeader) {
            this.$templateHeader.remove();
        }
        this.$templateHeader = Popup.getRealEstateAdPopupHeaderHTML(this.realEstateAd);
        this._bindPopupHeader();
    }

    _initPopupDetails() {
        this.$templateDetails = Popup.getRealEstateAdPopupDetailsHTML(this.realEstateAd);
        const {onSelectedCallback} = this;
        if (onSelectedCallback) {
            this.$templateDetails.on('click', event => {
                onSelectedCallback(event);
            });
        }
    }

    _initTooltipDetails() {
        this.$templateDetails = Tooltip.getRealEstateAdTooltipDetailsHTML(this.realEstateAd);
    }

    _bindPopupFooter() {
        this._bindNavigationButton('.previousPopupAd', -1);
        this._bindNavigationButton('.nextPopupAd', 1);
    }

    _bindNavigationButton(selector, offset) {
        const $navButton = this.$templateFooter.find(selector); // next or previous
        $navButton.on('click', () => {
            const {length: numberOfAds} = this.realEstateAdStackedList;
            const adIndexToDisplay = (numberOfAds + this.currentIndex + offset) % numberOfAds;
            this._updatedAdInfoAndPopupTemplate(adIndexToDisplay);
        });
    }

    _updatedAdInfoAndPopupTemplate(adIndexToDisplay) {
        const {realEstateAdStackedList} = this;
        const realEstateAdToDisplay = realEstateAdStackedList[adIndexToDisplay];
        this.updateInfoContent(realEstateAdToDisplay, realEstateAdStackedList);
        const {oldRealEstateAd, realEstateAd} = this; // they might have been updated by updateInfoContent
        this.emit('realEstateAdInfoChanged', oldRealEstateAd, realEstateAd);
        this._updatePopupTemplateContainer();
    }

    _bindPopupHeader() {
        const $closePopup = this.$templateHeader.find('.closePopup');
        $closePopup.on('click touchstart', event => {
            this.emit('infoClosedClicked', this.realEstateAd);
            this.closeInfo(event);
            event.stopPropagation();
            event.preventDefault();
        });
    }

    _updatePopupId() {
        this.$templateContainer.find('.kimono-popup').attr('data-id', this.realEstateAd.id);
    }

    _updatePopupDetails(adIndexToDisplay) {
        this.oldRealEstateAd = this.realEstateAd;
        this.realEstateAd = this.realEstateAdStackedList[adIndexToDisplay];
        this._updatePopupTemplateContainer();
        this._updatePopupId();
    }

    _updatePopupTemplateContainer() {
        const $oldDetailsTemplate = this.$templateDetails;
        this._initPopupDetails();
        this.$templateContainer.find('.infoDetails').append(this.$templateDetails);
        $oldDetailsTemplate.remove();
    }

    _updatePopupHeader() {
        this.oldHeaderTemplate = this.$templateHeader;
        this._initPopupHeader();
        this.$templateContainer.find('.infoHeader').append(this.$templateHeader);
        this.oldHeaderTemplate.remove();
    }

    updatePopup(adIndexToDisplay) {
        if (this.realEstateAd != this.realEstateAdStackedList[adIndexToDisplay]) {
            this._updatePopupDetails(adIndexToDisplay);
            this._updatePopupHeader();
        }
    }

    _updateNavigationCounter(realEstateAdStackedList) {
        if (this.$templateFooter) {
            if (realEstateAdStackedList && this.realEstateAdStackedList != realEstateAdStackedList) {
                this.realEstateAdStackedList = realEstateAdStackedList;
            }
            const $nbPopupTotal = this.$templateFooter.find('.nbPopupTotal strong');
            const $popupCounter = this.$templateFooter.find('.popupCounter');
            $nbPopupTotal.text(this.realEstateAdStackedList.length);
            $popupCounter.text((this.currentIndex + 1) + '/' + this.realEstateAdStackedList.length);
        }
    }

    setMarker(realEstateAd) {
        PopupLayerHelper.setMarker(SideMapViewSingleton.get().popupLayer, realEstateAd.marker);
    }

    updateInfoContent(realEstateAd, realEstateAdStackedList, searchCriteria) {
        const currentIndex = _.indexOf(realEstateAdStackedList, _.find(realEstateAdStackedList, {id: realEstateAd.id}));
        if (searchCriteria && !_.isEqual(this.searchCriteria, searchCriteria)) {
            this.searchCriteria = searchCriteria;
        }
        if (realEstateAd != this.realEstateAd) {
            this.updatePopup(currentIndex);
        }
        if (currentIndex != this.currentIndex || realEstateAdStackedList != this.realEstateAdStackedList) {
            this.realEstateAdStackedList = realEstateAdStackedList;
            this.currentIndex = currentIndex;
            this._updateNavigationCounter(realEstateAdStackedList);
        }
    }

    updateInfoPosition() {
        PopupLayerHelper.movePopup(SideMapViewSingleton.get().popupLayer);
    }

    closeInfo() {
        PopupLayerHelper.closePopup(SideMapViewSingleton.get().popupLayer);
    }
};
