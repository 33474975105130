const _ = require('lodash');

module.exports = {
    swapKeysValues,
    getConfigFromParsedQuery,
};

function swapKeysValues(obj) { // todo: use lodash invert
    const reversed = {};
    _.each(obj, function (value, key) {
        reversed[value] = key;
    });
    return reversed;
}

function toBoolean(value, isForward) {
    if (value == 'oui' || value == 'true') {
        return isForward;
    } else if (/-/.test(value)) {
        return _.map(value.split('-'), function (subValue) {
            return toBoolean(subValue, isForward);
        });
    } else {
        return !isForward;
    }
}

function getConfigFromParsedQuery({
    parsedQuery,
    queryNameToOptionName,
    valueToQueryValue,
    booleanValuesFor,
    numberValuesFor,
    jsonifyValuesFor,
    arrayValuesFor,
} = {}) {
    const config = {};
    _.each(parsedQuery, function (value, key) {
        const optionName = queryNameToOptionName[key];
        if (optionName) {
            const valueMapping = valueToQueryValue[optionName];
            if (valueMapping) {
                value = swapKeysValues(valueMapping)[value];
            } else if (_.includes(numberValuesFor, optionName)) {
                value = _.toNumber(value);
            } else if (booleanValuesFor && booleanValuesFor[optionName] != null) {
                value = toBoolean(value, booleanValuesFor[optionName]);
            } else if (arrayValuesFor && _.includes(arrayValuesFor, optionName)) {
                value = _.isString(value) ? value.split(',') : value; // might already be an array
            }
            if (null != value) {
                config[optionName] = value;
                if (jsonifyValuesFor && jsonifyValuesFor[optionName]) {
                    try {
                        config[optionName] = JSON.parse(config[optionName]);
                    } catch (e) {
                        console.warn('failed reading json value from url for ' + optionName + ' : ' + e);
                    }
                }
            }
        }
    });
    return config;
}
