const _ = require('lodash');
const template = require('../templates/userDirectory/userSortingView.jade');
const View = require('./View');
const EventPack = require('../utils/EventPack');
const SelectPicker = require('../utils/SelectPicker');

module.exports = class UserSortingView extends View {
    constructor(options) {
        super(_.defaults({
            template,
        }, options));
        this._eventPack = new EventPack();
    }

    show(options) {
        super.show(options);
        this.$select = this.$element.find('select');
        SelectPicker.enable(this.$select);
        this._eventPack.on(this.$element, 'change', () => {
            this.emit('sortChanged', this.getSortingValue());
        });
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
    }

    getSortingValue() {
        return this.$select.val();
    }
};
