module.exports = {
    isEnabled,
    setEnabled,
};

let _isEnabled = false;

function setEnabled() {
    _isEnabled = true;
}

function isEnabled() {
    return _isEnabled;
}
