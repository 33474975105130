module.exports = [
    'duplicate',
    'temporary',
    'outstandingDebt',
    'noContract',
    'contractRefused',
    'waitingFeedback',
    'contractTermination',
    'userAccountRemove',
    'otherReason',
];
