const _ = require('lodash');
const BrowserDetect = require('browser-detect');
const View = require('../views/View');
const template = require('../templates/homePage/searchFilterView.jade');
const templateNatural = require('../templates/homePage/searchFilterViewNatural.jade');
const SearchFieldsHandler = require('../search/SearchFieldsHandler');
const EventPack = require('../utils/EventPack');
const GeolocationHelper = require('../geolocation/GeolocationHelper');
const SearchByTravelTimeView = require('../views/SearchByTravelTimeView');
const {housingAndBusiness} = require('../../common/PropertyTypes');
const {i18n: {translate}} = require('fack');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const LocalStorageSavedSearch = require('../utils/localStorage/LocalStorageSavedSearch');
const lastSearchView = require('./lastSearchView.jade');
const VueView = require('../vue/VueView');
const intersects = require('../../common/intersects');

module.exports = class HomePageSearchFilterView extends View {
    constructor() {
        const useNaturalLanguage = !BrowserDetect.isMobile();
        super({
            template: useNaturalLanguage ? templateNatural : template,
        });
        this.useNaturalLanguage = useNaturalLanguage;
        this._eventPack = new EventPack();
    }

    show({search}) {
        // 1+ rooms is not selected on null value in homepage
        if (search.minRooms === null) {
            delete search.minRooms;
        }

        super.show({
            searchType: search.filterType,
            searchTypeSentences: this._getSearchTypeSentences(search),
        });
        const aroundMeEnabled = GeolocationHelper.geolocationSupported() && BrowserDetect.isMobile();
        this._searchFieldsHandler = new SearchFieldsHandler(this.$element, {
            submit: _.bind(this._submit, this),
            $button: this.$element.find('#hpDoSearch'),
            locationsField: {
                naturalLanguageEnabled: this.useNaturalLanguage,
                aroundMeEnabled,
            },
            validatorOptions: {
                group: '.fieldsGroup',
            },
        });
        this._eventPack.on(this._searchFieldsHandler, {
            change: ({name}) => {
                if (name == 'filterType') {
                    this._updateSearchTypeSentence();
                }
            },
            travelTimeSelected: () => {
                this.openTravelTimeSearch();
            },
        });
        this._searchFieldsHandler.preventLetterTypingInNumberPlaceholder();
        this._searchFieldsHandler.setFieldsValuesFromSearch(search);
        this._displayLastSearchView();
    }

    _displayLastSearchView() {
        const lastSearch = LocalStorageSavedSearch.get();
        if (!_.isEmpty(lastSearch)) {
            this._lastSearchView = new VueView({
                template: lastSearchView,
                $container: this.$element.find('.lastSearchContainerView'),
            });
            this._lastSearchView.show(null, {
                data() {
                    return {
                        title: lastSearch.title,
                    };
                },
                methods: {
                    onLastSearchClicked() {
                        PageManager.openPage(PagesFactories.searchPageFactory, {search: lastSearch});
                    },
                    t: translate,
                },
            });
        }
    }

    _getSearchTypeSentences(filters) {
        const filterType = filters.buyNewPropertySetInFilterType ? 'buyNew' : filters.filterType || 'buy';
        let propertyTypeTranslation = null;
        if (_.size(filters.propertyType) === 1) {
            propertyTypeTranslation = translate('propertyTypeWithDeterminer.' + _.first(filters.propertyType));
        }
        return {
            before: `J’aimerais ${filterType == 'rent' ? 'louer' : 'acheter'} ${propertyTypeTranslation || 'un&nbsp;'}`,
            after: filterType == 'buyNew' ? '&nbsp;neuf' : '',
        };
    }

    _updateSearchTypeSentence() {
        this._setSearchTypeSentence(this._searchFieldsHandler.getFilters());
    }

    _setSearchTypeSentence(filters) {
        const {before, after} = this._getSearchTypeSentences(filters);
        this.$element.find('.hpSearchSentenceBefore').html(before);
        this.$element.find('.hpSearchSentenceAfter').html(after);
        const minRoomsFilterEnabled = intersects(housingAndBusiness, filters.propertyType);
        const $minRoomsHomePageSelectPicker = this.$element.find('.minRoomsHomePageSelectPicker');
        const $elementToToggle = this.useNaturalLanguage ? $minRoomsHomePageSelectPicker : $minRoomsHomePageSelectPicker.parent();
        $elementToToggle.toggle(minRoomsFilterEnabled);
    }

    hide() {
        super.hide();
        if (this._lastSearchView) {
            this._lastSearchView.hide();
        }
        this._searchFieldsHandler.destroyFields();
        this._eventPack.removeAllListeners();
    }

    _submit(err, cb) {
        if (!err) {
            this.emit('search', this._searchFieldsHandler.getFilters());
        } else {
            this.emit('disabled', false);
            if (err.isTravelTime) {
                SearchByTravelTimeView.showError(err);
                this.openTravelTimeSearch();
            }
        }
        this.emit('submit', err);
        cb();
    }

    openTravelTimeSearch({launchSearchWhenSubmit = false, context = ''} = {}) {
        SearchByTravelTimeView.open({
            submitCallback: item => {
                this._searchFieldsHandler.setTravelTimeSearch(item);
                if (launchSearchWhenSubmit) {
                    this._searchFieldsHandler.submit();
                }
            },
            defaultItem: this._searchFieldsHandler.getFirstTravelTimeItem(),
            context,
            parentView: this,
        });
    }
};
