const _ = require('lodash');
const $ = require('jquery');
const AddressSuggestionHelper = require('../utils/AddressSuggestionHelper');
const MapApi = require('../MapApi');
const {resourceUrl} = require('fack');
const PopupLayerHelper = require('../PopupLayerHelper');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const EventPack = require('./EventPack');
const MarkerZIndex = require('./MarkerZIndex');

const eventPack = new EventPack();
const markersAndItems = [];
let clickAndDraggable = true;

const zoneByTimeMarkerPopupTemplate = require('../templates/zoneByTimeMarkerPopup.jade');
const TARGET_MARKER_ABOVE_OTHER_MARKERS_BELOW_ZOOM = 12;
module.exports = {
    createMarkers,
    deleteMarkers,
    updateMarkers,
};

function getMarkerIconUrl(mode) {
    const iconName = mode ? (`images/map/pins/${mode}.png`) : null;
    return iconName ? resourceUrl(iconName) : null;
}

function updateMarker({marker, item}) {
    marker.setClickable(clickAndDraggable);
    marker.setDraggable(clickAndDraggable);
    if (!clickAndDraggable) {
        setPopupVisible(marker, clickAndDraggable);
    }
    const position = item.from;
    if (position) {
        marker.setPosition(new MapApi.api.LatLng(position.lat, position.lng));
    }
}

function createMarkers(searchLocationInfos) {
    _.each(searchLocationInfos, searchLocationInfo => {
        if (searchLocationInfo.type == 'travelTimeZone') {
            createMarker(searchLocationInfo.travelTimeInfos);
        }
    });
}

function deleteMarkers() {
    _.each(markersAndItems, deleteMarker);
    markersAndItems.length = 0;
    eventPack.removeAllListeners();
}

function updateMarkers({travelTimeMarkersClickableAndDraggable}) {
    clickAndDraggable = travelTimeMarkersClickableAndDraggable;
    _.each(markersAndItems, updateMarker);
}

function createMarker({item, openTravelTimeSearchCallback}) {
    const map = SideMapViewSingleton.get().map;
    if (map) {
        const iconUrl = getMarkerIconUrl(item.mode);
        const position = item.from;
        const isMarkerClickableAndDraggable = clickAndDraggable && openTravelTimeSearchCallback;
        const marker = new MapApi.api.Marker({
            map,
            clickable: isMarkerClickableAndDraggable,
            draggable: isMarkerClickableAndDraggable,
            position: new MapApi.api.LatLng(position.lat, position.lng),
            icon: {url: iconUrl},
        });
        if (isMarkerClickableAndDraggable) {
            const openTravelTimeSearchDebounced = _.debounce(openTravelTimeSearchCallback);
            eventPack.on(marker, {
                dragend: () => {
                    const latLng = marker.getPosition();
                    item.address = AddressSuggestionHelper.formatAddress(item.address, {
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                    });
                    openTravelTimeSearchDebounced();
                },
                click: () => {
                    setPopupVisible(marker, false);
                    openTravelTimeSearchDebounced();
                },
            });
            createPopup(marker);
        }
        eventPack.on(map, 'zoom_changed', () => {
            updateMarkerZIndex(marker, map.getZoom());
        });
        markersAndItems.push({marker, item});
        updateMarkerZIndex(marker, map.getZoom());
    }
}

function deleteMarker({marker}) {
    if (marker) {
        deletePopup(marker);
        marker.setMap(null);
    }
}

function updateMarkerZIndex(marker, zoom) {
    const inFront = zoom <= TARGET_MARKER_ABOVE_OTHER_MARKERS_BELOW_ZOOM;
    marker.setZIndex(inFront ? MarkerZIndex.max : MarkerZIndex.min);
}

function createPopup(marker) {
    const $templateInfo = $(zoneByTimeMarkerPopupTemplate());
    const popupLayer = marker.popupLayer || PopupLayerHelper.create(SideMapViewSingleton.get().map);
    PopupLayerHelper.openPopup(popupLayer, marker, $templateInfo);
    eventPack.on(marker, {
        mouseover: () => {
            setPopupVisible(marker, true);
            PopupLayerHelper.movePopup(popupLayer);
        },
        mouseout: () => {
            setPopupVisible(marker, false);
        },
    });
    setPopupVisible(marker, false);
}

function setPopupVisible({popupLayer}, visible) {
    if (popupLayer) {
        popupLayer.$html.toggle(visible);

    }
}

function deletePopup({popupLayer}) {
    if (popupLayer) {
        PopupLayerHelper.closePopup(popupLayer);
    }
}
