const _ = require('lodash');
const {
    getColorAsHsl,
    ESTIMATIONS_MAX_AGE_IN_DAYS,
} = require('../estimationsMarkersDisplayHelper');
const template = require('./ProspectingMapLegend.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

const NUMBER_OF_SHADES = 5;

// @vue/component
module.exports = {
    constants: {
        COLOR_GRADIENT_STEPS: _.range(NUMBER_OF_SHADES),
    },
    mixins: [
        i18nMixin({
            prefix: 'ProspectingMapLegend.',
            keys: [
                'newEstimations',
            ],
        }),
    ],
    computed: {
        oldEstimations() {
            return this.t('oldEstimations', {numberOfDays: ESTIMATIONS_MAX_AGE_IN_DAYS});
        },
    },
    methods: {
        getStyle(n) {
            return {background: getColorAsHsl(n * ESTIMATIONS_MAX_AGE_IN_DAYS / (NUMBER_OF_SHADES - 1))};
        },
    },
    template: template(),
};
