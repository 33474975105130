const SearchPageBaseFactory = require('./SearchPageBaseFactory');
const SavedSearchAdsPage = require('../../pages/SavedSearchAdsPage');

module.exports = class SavedSearchAdsPageFactory extends SearchPageBaseFactory {
    getUrlPattern() {
        return '/mon-alerte/{savedSearchId}/:savedSearchName:';
    }

    createPage() {
        return new SavedSearchAdsPage(this._pageConfiguration);
    }
};
