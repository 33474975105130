const _ = require('lodash');

const SelectorContainerView = require('../views/SelectorContainerView');
const template = require('./homePage.jade');
const Options = require('../Options');
const homePageFeatures = require('../data/homePageFeatures');
const AppInstallBannerContainer = require('../android/AppInstallBannerContainer');
const SlideshowView = require('./slideshow/SlideshowView');
const {openFinancialPartnerTab} = require('../financialPartners');
const i18nMixin = require('../vue/components/mixins/i18n');

module.exports = class HomePageContainerView extends SelectorContainerView {
    constructor(options) {
        super(options);
        this.template = template;
    }

    show(options) {
        const {
            isRegionalPartner,
            homepageBackgroundFilename,
        } = options;

        const commercialAdsEnabled = Options.get('commercialAdsEnabled');
        this.setView('.slideshow-view', new SlideshowView({
            isRegionalPartner,
            homepageBackgroundFilename,
            commercialAdsEnabled,
            commercialAdsSlideOverride: Options.get('commercialAdsSlideOverride'),
        }));

        const vueOptions = {
            provide: {
                page: this.pageName,
                searchCriteria: this.searchCriteria,
            },
            mixins: [
                i18nMixin(),
            ],
            computed: {
                homePageFeatures() {
                    return homePageFeatures;
                },
            },
            methods: {
                handleFeatureClick(name) {
                    if (name === 'financialPartnerMortgageSimulator') {
                        openFinancialPartnerTab({context: 'homePage'});
                    }
                },
                getHref(href) {
                    const {wwwUrl, hasFeatureLinksOnWwwUrl} = options;
                    return (href && wwwUrl && hasFeatureLinksOnWwwUrl) ? wwwUrl + href : href;
                },
                featureName(name) {
                    return this.t('homePageFeatures.' + name);
                },
            },
        };
        const isInEmbeddedMode = Options.get('isInEmbeddedMode');
        super.show(_.defaults({
            isInEmbeddedMode,
            isHomePageFeaturesEnabled: Options.get('isHomePageFeaturesEnabled'),
            homePageFeatures,
        }, options), vueOptions);

        AppInstallBannerContainer.searchAndUpdateContainer(this.$element);
    }

    hide(options) {
        AppInstallBannerContainer.searchAndUpdateContainer(null);
        super.hide(options);
    }
};
