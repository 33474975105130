const moment = require('../../../../common/momentFr');
const {EXTERNAL_BANNER_TARGETING_MONTH_PLUS_2_DAY_THRESHOLD} = require('../constants');

function getStartDateOfExternalBannerTargeting() {
    const date = moment();
    const startDate = date.date() >= EXTERNAL_BANNER_TARGETING_MONTH_PLUS_2_DAY_THRESHOLD
        ? date.add(2, 'month')
        : date.add(1, 'month');
    return startDate.startOf('month').format('YYYY-MM-DD');
}

module.exports = require('./optionComponentFactory')({
    productOption: 'externalBannerTargeting',
    getAutofillStartDate: () => getStartDateOfExternalBannerTargeting(),
});
