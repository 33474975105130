const template = require('./AgenciesWithNotorietyExtension.jade');
const Account = require('../../authentication/Account');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'agencies.WithNotorietyExtension.',
            keys: ['allNotorietyAgenciesBtn'],
        }),
    ],
    props: {
        zoneIds: {
            type: Array,
            required: true,
        },
        placeTitle: {
            type: String,
            required: true,
        },
        allAgenciesUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            agenciesList: [],
        };
    },
    computed: {
        showAgenciesList() {
            return this.agenciesList.length > 0;
        },
        title() {
            return this.t('agencyTitle', {placeTitle: this.placeTitle});
        },
    },
    mounted() {
        this.getVisibleAgenciesOnPlaceDiscoveryPage();
    },
    methods: {
        getVisibleAgenciesOnPlaceDiscoveryPage() {
            const searchCriteria = {
                locations: this.zoneIds,
                disabled: false,
                closed: false,
                'visible-in-user-directory': true,
                visibleOnPlaceDiscoveryPage: true,
            };
            Account.find(searchCriteria, (err, data) => {
                if (err) {
                    console.log(err);
                } else if (data) {
                    this.agenciesList = data.accounts;
                }
            });
        },
    },
    template: template(),
};
