const LineClamp = require('line-clamp');

const template = require('./CriteriaSummary.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'CriteriaSummary.',
            keys: [
                'modifySearchCriteria',
                'seeSearchCriteria',
            ],
        }),
    ],
    props: {
        saveName: {
            type: String,
            default: null,
        },
        criteriaList: {
            type: Array,
            default: undefined,
        },
        emailNotificationMode: {
            type: String,
            default: null,
        },
        pushNotificationMode: {
            type: String,
            default: null,
        },
        showNotificationIcon: Boolean,
        showNotificationState: Boolean,
        showPushNotificationState: Boolean,
        criteriaModificationEnabled: Boolean,
        criteriaModificationHidden: Boolean,
        isOwner: Boolean,
        compact: Boolean,
    },
    computed: {
        modificationButtonLabel() {
            return this.isOwner ? this.modifySearchCriteria : this.seeSearchCriteria;
        },
        modifiers() {
            return {
                compact: this.compact,
                extended: !this.compact,
            };
        },
    },
    mounted() {
        LineClamp(this.$refs.title);
    },
    template: template(),
};
