const urlUtil = require('url');
const diacritics = require('diacritics');
const _slugify = require('underscore.string/slugify');
const _ = require('lodash');

const TRACKING_PREFIX = 'at_';
const DEPRECATED_TRACKING_PARAMETER = 'xtor';

module.exports = {
    getURLParameter,
    getURLTrackingParameters,
    removeURLParameter,
    addURLParameters,
    slugify,
};

function getURLParameter(url, key) {
    const parsedUrl = urlUtil.parse(url, true);
    //parsedUrl.query is guaranteed to be not null
    return parsedUrl.query[key];
}

function getURLTrackingParameters(url) {
    const {query} = urlUtil.parse(url, true);
    return _.pickBy(
        query,
        (value, key) => _.startsWith(key, TRACKING_PREFIX) || key === DEPRECATED_TRACKING_PARAMETER // keep backward compatibility
    );
}
function removeURLParameter(url, key) {
    const parsedUrl = urlUtil.parse(url, true);
    if (key in parsedUrl.query) {
        delete parsedUrl.query[key];
        delete parsedUrl.search; //force format to use parsedUrl.query
        return urlUtil.format(parsedUrl);
    }
    return url;
}

function addURLParameters(url, params) {
    const parsedUrl = urlUtil.parse(url, true);
    _.each(params, (value, key) => {
        parsedUrl.query[key] = value;
    });
    delete parsedUrl.search; //force format to use parsedUrl.query
    return urlUtil.format(parsedUrl);
}

function slugify(name) {
    if (name) {
        const accent = diacritics.remove(name); // only for æ and œ that underscore.string does not (yet) handle well
        return _slugify(accent.replace(/'/g, '-'));
    } else {
        return name;
    }
}
