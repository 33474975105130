const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./AgencyPageCustomerRatings.jade');
const CustomerReviews = require('./CustomerReviews');

// @vue/component
module.exports = {
    components: {
        CustomerReviews,
    },
    mixins: [
        i18nMixin({
            prefix: 'AgencyPageCustomerRatings.',
            keys: [
                'title',
            ],
        }),
    ],
    props: {
        agencyId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isCustomerReviewsDisplayed: false,
            isAgencyRatingDisplayed: false,
        };
    },
    computed: {
        hasAgencyRatingAndReviews() {
            const {isCustomerReviewsDisplayed, isAgencyRatingDisplayed} = this;
            return isCustomerReviewsDisplayed && isAgencyRatingDisplayed;
        },
    },
    methods: {
        displayCustomerReviews(value) {
            this.isCustomerReviewsDisplayed = value;
        },
        displayAgencyRating() {
            this.isAgencyRatingDisplayed = true;
        },
    },
    template: template(),
};
