const VueConstants = require('vue-constants');
const BrowserDetect = require('browser-detect');
const ContainerQueries = require('./directives/ContainerQueries');
const Bem = require('./directives/Bem');
const tracking = require('./directives/tracking');
const {handleJsException} = require('../utils/Errors');

module.exports = {
    install(Vue) {
        Vue.directive('tracking', tracking);
        Vue.config.errorHandler = handleJsException;
        if (process.env.NODE_ENV !== 'production') {
            Vue.config.warnHandler = handleVueWarning;
        }
        Vue.use(VueConstants);
        Vue.use(ContainerQueries);
        Vue.use(Bem.directive);
        // @vue/component
        Vue.mixin({
            computed: {
                isMobile() {
                    return BrowserDetect.isMobile();
                },
                isTablet() {
                    return BrowserDetect.isTablet();
                },
            },
        });
    },
};

function handleVueWarning(msg, vm, trace = '') {
    // `trace` is the component hierarchy trace
    handleJsException(new Error(msg + trace));
}
