const template = require('./AgencyFeesUrl.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AgencyPageHeader.',
            keys: [
                'agencyFees',
            ],
        }),
    ],
    props: {
        feesUrl: {
            type: String,
            default: null,
        },
    },
    template: template(),
};
