const {getDescription} = require('../../../common/Roles');
const template = require('./InfoRole.jade');

// @vue/component
module.exports = {
    props: {
        role: {
            type: String,
            required: true,
        },
    },
    computed: {
        description() {
            return getDescription(this.role);
        },
    },
    template: template(),
};
