const Vue = require('vue');
const VueView = require('../../VueView');
const template = require('./HoistedComponent.jade');

// @vue/component
module.exports = {
    mounted() {
        this.viewContentData = Vue.observable({
            slot: this.$slots.default,
        });
        this.createView();
    },
    updated() {
        this.viewContentData.slot = this.$slots.default;
    },
    destroyed() {
        this.destroyView();
    },
    methods: {
        createView() {
            const view = this.view = new VueView();
            const {viewContentData} = this;
            // @vue/component
            view.show(null, {
                name: 'HoistedComponent',
                components: {
                    SlotContent: {
                        functional: true,
                        render: () => viewContentData.slot,
                    },
                },
                template: template(),
            });
        },
        destroyView() {
            this.view.hide();
            this.view = null;
        },
    },
    render(h) {
        return h();
    },
};
