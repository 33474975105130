const View = require('./View');
const EventPack = require('../utils/EventPack');
const template = require('../templates/mapButtonsSearchView.jade');

module.exports = class MapButtonsSearchView extends View {
    constructor() {
        super({
            template,
        });
        this._eventPack = new EventPack();
    }

    show(options) {
        this.$container = options.$container;
        super.show(options);
        this._eventPack.on(this.$element, 'click', '.travel-by-time', () => {
            this.emit('travelByTime');
        });
        this._eventPack.on(this.$element, 'click', '.kimono-drawing_Button', () => {
            this.emit('startDrawing');
        });

    }

    hide() {
        if (this.isShown()) {
            this._eventPack.removeAllListeners();
            super.hide();
        }
    }
};
