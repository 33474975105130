const {i18n: {translate}} = require('fack');

const template = require('./RealEstatePropertyHeader.jade');
const {INITIAL_STEP, ESTIMATOR_TRANSLATION_KEY_PREFIX} = require('../Constants');

// @vue/component
module.exports = {
    constants: {
        INITIAL_STEP,
    },
    props: {
        step: {
            type: Number,
            required: true,
        },
        propertyTypePreselection: {
            type: String,
            default: undefined,
        },
        transactionType: {
            type: String,
            default: undefined,
        },
        translationKeyPrefix: {
            type: String,
            required: true,
        },
    },
    computed: {
        headerOptions() {
            const {propertyTypePreselection, transactionType} = this;
            const translationOptions = {context: propertyTypePreselection || transactionType};
            return {
                title: translate(this.translationKeyPrefix + 'title', translationOptions),
                subtitle: translate(this.translationKeyPrefix + 'subtitle', translationOptions),
            };
        },
        computing() {
            return translate(ESTIMATOR_TRANSLATION_KEY_PREFIX + 'computing');
        },
    },
    template: template(),
};
