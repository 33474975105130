const _ = require('lodash');
const template = require('../loanContactForm.jade');
const LoanContactFormViewWithRequiredPartnerOffersAcceptance = require('../LoanContactFormViewWithRequiredPartnerOffersAcceptance');

module.exports = class PinelFormContactView extends LoanContactFormViewWithRequiredPartnerOffersAcceptance {
    constructor(configuration) {
        super(_.extend({
            name: 'PinelFormContactView',
            gtmEventName: 'PinelContactFormSubmitted',
        }, configuration));
        this.template = template;
    }
};
