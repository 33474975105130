const _ = require('lodash');
const $ = require('jquery');

const ApplicationConfig = require('../app/ApplicationConfig');
const CompositeVueView = require('../vue/CompositeVueView');
const template = require('../templates/detailedSheet/relatedAds.jade');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');
const EventPack = require('../utils/EventPack');
const TextFormatter = require('../../common/TextFormatter');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const TextFiltersFormatter = require('../../common/TextFiltersFormatter');
const Urls = require('../Urls');
const PriceFormatter = require('../../common/PriceFormatter');
const PropertyTypes = require('../../common/PropertyTypes');
const KeyboardHelper = require('../utils/KeyboardHelper');
const Account = require('../authentication/Account');

const ADS_LIST_SLIDE_ANIM_DURATION = 300;

const SORT_OVERRIDE = [
    ...PropertyTypes.single,
    'others',
    'annexe',
];
module.exports = class RelatedAdsView extends CompositeVueView {
    constructor(options = {}) {
        super(options);
        this._eventPack = new EventPack();
        this.template = template;
    }

    /**
     *
     * @param {object} options
     * @param {string} options.closePageUrl current page to get back to after visiting a relatedAd
     * @param {string} options.metaAdPropertyType
     * @param {array} options.relatedAds
     * @param {AdContentHandler} options.adContentHandler
     */
    show(options) {
        this.adContentHandler = options.adContentHandler;
        const {relatedAds} = options;
        if (relatedAds) {
            this.relatedAds = _.map(relatedAds, ad => this._prepareAd(ad));
        }
        this.closePageUrl = options.closePageUrl;
        this.canShowContentLinks = options.canShowContentLinks;
        this.enableNoContentButton = options.enableNoContentButton;
        this.areFilteredAdsVisible = false;
        const relatedAdsView = this;
        // @vue/component
        const vueOptions = {
            data() {
                return {
                    relatedAds: relatedAdsView.relatedAds,
                };
            },
            computed: {
                hasToDisplayFavoritesInRelatedAdsTable() {
                    return ApplicationConfig.hasToDisplayFavorites && !Account.isShowRoom();
                },
            },
        };
        super.show(_.extend({}, options, this._getTemplateOptions()), vueOptions);
        if (!_.isEmpty(this.relatedAds)) {
            this.toggleAds(true);
            this._bindEvents();
            this._openFirstVisibleAdsGroup();
            this._updateTableRowColors();
        }
    }

    getClassElement(ad) {
        const classesCheck = {
            filteredOut: ad.matchFilters === false,
            reducedVat: ad.reducedVat,
            offTheMarket: ad.status && ad.status.onTheMarket === false,
        };
        return _.keys(_.pickBy(classesCheck));
    }

    hide() {
        this._eventPack.removeAllListeners();
        if (this.adContentHandler) {
            this.adContentHandler.cleanup(_.map(this.relatedAds, 'id'));
        }
        super.hide();
    }

    /**
     *
     * @param {object} options
     * @param {string} options.closePageUrl
     * @param {string} options.metaAdPropertyType
     * @param {array} options.relatedAds
     * @param {AdContentHandler} options.adContentHandler
     */
    update(options) {
        this.hide();
        this.show(options);
    }

    toggleSpinner(show) {
        this.$element.find('.centerLoadingSpinner').toggle(show);
    }

    toggleAdsContainer(show) {
        this.$element.find('.adsContainer').toggle(show);
    }

    toggleAds(show) {
        this.toggleSpinner(!show);
        this.toggleAdsContainer(show);
    }

    getRelatedAds() {
        return this.relatedAds;
    }

    _getTemplateOptions() {
        const relatedAdsByCategory = this._getFormattedRelatedAdsByCategory();
        this.displayInGroupMode = displayInGroupMode(relatedAdsByCategory);
        const [matchingFiltersAds, filteredOutAds] = _.partition(this.relatedAds, 'matchFilters');
        return {
            displayFloorColumn: this._displayFloorColumn(),
            displayContentColumn: this._displayContentColumn(),
            hasFilteredOutAds: this._hasFilteredOutAds(),
            getClassElement: this.getClassElement,
            enableNoContentButton: this.enableNoContentButton,
            relatedAds: sortAds(this.relatedAds),
            relatedAdsByCategory,
            displayInGroupMode: this.displayInGroupMode,
            hasReducedVat: _.some(matchingFiltersAds, 'reducedVat'),
            filteredOutAdsHaveReducedVat: _.some(filteredOutAds, 'reducedVat'),
            TextFormatter,
            RealEstateAdTitleGenerator,
            TextFiltersFormatter,
        };
    }

    _hasFilteredOutAds() {
        return _.some(this.relatedAds, ad => ad.matchFilters === false);
    }

    _displayContentColumn() {
        return this.canShowContentLinks
            && _.some(this.relatedAds, ad => Boolean(ad.pdf && ad.pdf.length) || ad.with360 || ad.nothingBehindForm);
    }

    _displayFloorColumn() {
        return _.some(this.relatedAds, ad => 'floor' in ad);
    }

    _bindEvents() {
        this._eventPack.on(this.$element, {
            mouseenter: {
                '.realEstateAdLink': e => {
                    this._onMouseEnter(this._getAdFromEvent(e));
                },
            },
            mouseleave: {
                '.realEstateAdLink': e => {
                    this._onMouseLeave(this._getAdFromEvent(e));
                },
            },
            click: {
                '.relatedAdLink': e => {
                    if (!KeyboardHelper.eventHasModifierKey(e)) {
                        const ad = this._getAdFromEvent(e);
                        this.emit('adClick', {
                            realEstateAd: ad,
                            realEstateAdId: ad.id,
                        });
                        e.stopPropagation();
                        e.preventDefault();
                    }
                },
                '.toggleFilteredOut': _.bind(this._toggleFilteredOutRelatedAds, this),
                '.adsGroupLabel': function () {
                    const $adsGroup = $(this).parent('.adsGroup');
                    toggleAdsListInGroup($adsGroup);
                },
            },
        });
    }

    _getAdFromEvent(e) {
        return this._getAdById(e.currentTarget.getAttribute('data-realEstateAdId'));
    }

    _prepareAd(ad) {
        this.adContentHandler.updateContent(ad.id, ad);
        const preparedAd = _.extend(VirtualTourHelper.enhanceAd(ad), {
            adLinkUrl: Urls.detailedSheet.makeUrl(ad, this.closePageUrl),
        });
        hidePropertyInAd(preparedAd, 'programme');
        hidePropertyInAd(preparedAd, 'residence');
        return preparedAd;
    }

    _onMouseEnter(ad) {
        this._onMouseEnterOrLeave(true, ad);
    }

    _onMouseLeave(ad) {
        this._onMouseEnterOrLeave(false, ad);
    }

    _onMouseEnterOrLeave(hovered, ad) {
        this.emit('lotMouseHovered', ad, hovered);
    }

    _getAdById(id) {
        return _.find(this.relatedAds, {id});
    }

    _toggleFilteredOutRelatedAds() {
        this.$element.toggleClass('showFilteredOut');
        this.$element.find('.showFilteredOutButton, .hideFilteredOutButton, .filteredOut').toggle();

        this.areFilteredAdsVisible = !this.areFilteredAdsVisible;

        if (this.displayInGroupMode) {
            const $adsGroupFullyFiltered = this.$element.find('.adsGroup.onlyContainsFilteredOutAds');
            $adsGroupFullyFiltered.slideToggle(ADS_LIST_SLIDE_ANIM_DURATION, function () {
                //using jQuery this pointing to adsGroup being toggled
                const $adsGroup = $(this);
                closeAdsGroup($adsGroup);
            });
        }

        this._updateTableRowColors();
    }

    _getFormattedRelatedAdsByCategory() {
        return _(this.relatedAds)
            .groupBy(({propertyType, roomsQuantity, isLotType, id}) => {
                if (isLotType) { //disable grouping for lotType
                    return id;
                } else {
                    return _.compact([propertyType, roomsQuantity]).join('-');
                }
            })
            .map(ads => {
                const [matchingFiltersAds, filteredOutAds] = _.partition(ads, 'matchFilters');
                const matchingFiltersAdsCount = matchingFiltersAds.length;
                const onlyContainsFilteredOutAds = _.isEmpty(matchingFiltersAds);

                const priceRangeForMatchingAds = generatePriceRange(matchingFiltersAds);
                const priceRangeForAllAds = generatePriceRange(ads);

                return {
                    titleForAllAds: generateAdsGroupLabel(ads),
                    titleForMatchingAds: generateAdsGroupLabel(matchingFiltersAds),
                    priceRangeForAllAds,
                    priceRangeForMatchingAds,
                    ads: sortAds(ads),
                    totalAdsCount: ads.length,
                    matchingFiltersAdsCount,
                    onlyContainsFilteredOutAds,
                    hasReducedVat: _.some(matchingFiltersAds, 'reducedVat'),
                    filteredOutAdsHaveReducedVat: _.some(filteredOutAds, 'reducedVat'),
                };
            })
            .sortBy(group => {
                const {propertyType, roomsQuantity} = _.first(group.ads);
                let mainSorting = _.indexOf(SORT_OVERRIDE, propertyType);
                if (mainSorting < 0) {
                    mainSorting = Number.MAX_SAFE_INTEGER;
                }
                return [mainSorting, roomsQuantity || Number.MAX_SAFE_INTEGER];
            })
            .value();
    }

    _openFirstVisibleAdsGroup() {
        const $firstVisibleAdsGroup = this.$element.find('.adsGroup:not(.onlyContainsFilteredOutAds)').first();
        toggleAdsListInGroup($firstVisibleAdsGroup);
    }

    _updateTableRowColors() {
        const tableRowsToTarget = this.areFilteredAdsVisible ? 'tr' : 'tr:not(.filteredOut)';
        _.each(this.$element.find('table'), table => {
            _.each($(table).find(tableRowsToTarget), (row, index) => {
                $(row).toggleClass('even', index % 2 == 1);
            });
        });
    }
};

function toggleAdsListInGroup($adsGroup) {
    $adsGroup.toggleClass('isOpen');
    $adsGroup.find('.adsListInGroup').slideToggle(ADS_LIST_SLIDE_ANIM_DURATION);
}

function closeAdsGroup($adsGroup) {
    if ($adsGroup.hasClass('isOpen')) {
        toggleAdsListInGroup($adsGroup);
    }
}

function displayInGroupMode(adsGroups) {
    return _.some(adsGroups, ({ads}) => ads.length > 1);
}

function generateAdsGroupLabel(ads) {
    if (_.isEmpty(ads)) {
        return null;
    }
    const {propertyType, roomsQuantity} = _.first(ads);
    const isStudio = _.every(ads, 'isStudio');
    const isDuplex = _.every(ads, 'isDuplex');
    return RealEstateAdTitleGenerator.getTitle({
        isStudio,
        isDuplex,
        propertyType,
        roomsQuantity,
    }, 'relatedAdTitle');
}

function generatePriceRange(adsGroup) {
    return _.reduce(adsGroup, ([groupMin, groupMax], {price: priceOrPriceRange}) => {
        const [adMin, adMax] = PriceFormatter.toRange(priceOrPriceRange);

        //null => price starting from
        if (groupMin == null || adMin == null) {
            groupMin = null;
        } else if (adMin < groupMin) {
            groupMin = adMin;
        }

        //null => price up to
        if (groupMax == null || adMax == null) {
            groupMax = null;
        } else if (adMax > groupMax) {
            groupMax = adMax;
        }

        return [groupMin, groupMax];
    }, [Number.MAX_VALUE, Number.MIN_VALUE]);
}

function sortAds(ads) {
    return _.sortBy(ads, [
        'propertyType',
        'roomsQuantity',
        'price',
        ad => ad.isDuplex ? 1 : 0, //duplex last, using ternary condition because isDuplex might be undefined
        'surfaceArea',
        'floor',
    ]);
}

function hidePropertyInAd(ad, propertyName) {
    const value = ad[propertyName];
    Object.defineProperty(ad, propertyName, {
        configurable: true,
        value,
    });
}
