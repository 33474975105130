const template = require('./RealEstatePropertyProgress.jade');
const {INITIAL_STEP} = require('../Constants');

// @vue/component
module.exports = {
    props: {
        step: {
            type: Number,
            required: true,
        },
        suggestedAgenciesStep: {
            type: Number,
            required: true,
        },
        resultStep: {
            type: Number,
            required: true,
        },
    },
    computed: {
        progressBarStyle() {
            const progressPercentagePerStep = calculateProgressPercentagePerStep(
                this.suggestedAgenciesStep,
                this.resultStep
            );
            return {
                width: `${progressPercentagePerStep[this.step] || 0}%`,
            };
        },
        realEstatePropertyProgressStyle() {
            const {step} = this;
            return {
                'is-visible': step > INITIAL_STEP && step < this.resultStep,
            };
        },
    },
    template: template(),
};

function calculateProgressPercentagePerStep(suggestedAgenciesStep, resultStep) {
    const progressPercentage = [];
    const percentageEachStep = Math.round(100 / (resultStep - 1));
    for (let step = 1; step < suggestedAgenciesStep; step++) {
        progressPercentage[step] = percentageEachStep * step;
    }
    progressPercentage[suggestedAgenciesStep] = 100;
    progressPercentage[resultStep] = 100;
    return progressPercentage;
}
