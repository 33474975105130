const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const {methods} = require('../../RealEstatePropertyDescriptorStoreHelpers');
const {computed: estimatorComputed} = require('../../estimator/RealEstateEstimatorStore');
const {computed: submissionComputed} = require('../../submission/RealEstateSubmissionStore');
const {
    ESTIMATOR_TRANSLATION_KEY_PREFIX,
    ESTIMATOR_STORE_MODULE_NAME,
    SUBMISSION_TRANSLATION_KEY_PREFIX,
    SUBMISSION_STORE_MODULE_NAME,
} = require('../../Constants');

// @vue/component
module.exports = (storeModuleName) => ({
    props: {
        transactionType: {
            type: String,
            default: undefined,
        },
    },
    computed: _.extend(storeModuleName === ESTIMATOR_STORE_MODULE_NAME ? estimatorComputed : submissionComputed, {
        moduleName() {
            return this.transactionType + storeModuleName;
        },
        translationKeyPrefix() {
            return storeModuleName === ESTIMATOR_STORE_MODULE_NAME
                ? ESTIMATOR_TRANSLATION_KEY_PREFIX : SUBMISSION_TRANSLATION_KEY_PREFIX;
        },
        context() {
            if (this.isHouseLike) {
                return 'house';
            } else if (this.isFlatLike) {
                return 'flat';
            } else {
                return null;
            }
        },
        isHouseLike() {
            return this.propertyType == 'house';
        },
        isFlatLike() {
            return isFlatLikePropertyType(this.propertyType);
        },
        propertyType() {
            return this.realEstateProperty.propertyType;
        },
        isRental() {
            return this.transactionType === 'rent';
        },
        isBuy() {
            return this.transactionType === 'buy';
        },
        isFlatRental() {
            return this.isRental && this.propertyType === 'flat';
        },
        isSubmission() {
            return storeModuleName === SUBMISSION_STORE_MODULE_NAME;
        },
        isEstimation() {
            return storeModuleName === ESTIMATOR_STORE_MODULE_NAME;
        },
    }),
    watch: {
        isFlatRental(isFlatRental) {
            if (!isFlatRental && this.isEstimation) {
                this.clearRealEstatePropertyStateInStore([
                    'isFurnished',
                    'parkingPlacesQuantity',
                ]);
            }
        },
    },
    methods: _.extend(methods, {
        commitToStore(type, payload) {
            this.$store.commit(`${this.moduleName}/${type}`, payload);
        },
        realEstatePropertyDescriptorTranslate(key, options) {
            return this.t(
                this.translationKeyPrefix + key,
                _.extend({context: this.context}, options)
            );
        },
        getQuestion(key, options) {
            const translationOptions = this.isSubmission ? {...options, context: this.transactionType} : options;
            return this.realEstatePropertyDescriptorTranslate(`${key}.question`, translationOptions);
        },
        translateOptions(options, baseKey) {
            return _.map(options, option => {
                return _.defaults({
                    label: _.upperFirst(this.realEstatePropertyDescriptorTranslate(`${baseKey}.answers.${option.label}`)),
                }, option);
            });
        },
        getPlaceholder(key) {
            return this.t([
                this.translationKeyPrefix + key + '.placeholder',
                'selectInput.placeholder.default',
            ], {context: this.context});
        },
        getFormattedOptions(translationKey) {
            const translationValues = this.realEstatePropertyDescriptorTranslate(translationKey, {returnObjectTrees: true});
            return _.map(translationValues, (label, value) => {
                return {value, label};
            });
        },
        getSelectOptions(key) {
            return this.getFormattedOptions(key + '.values');
        },
        clearRealEstatePropertyStateInStore(keys) {
            const keysArray = _.isArray(keys) ? keys : [keys];
            this.commitToStore('clearRealEstateEstimationKeys', keysArray);
        },
        t: translate,
    }),
});

function isFlatLikePropertyType(propertyType) {
    return _.includes(['flat', 'loft'], propertyType);
}
