const _ = require('lodash');
const template = require('./InfoRow.jade');

// @vue/component
module.exports = {
    props: {
        icon: {
            type: String,
            required: true,
        },
        overflowWrapAnywhere: Boolean,
    },
    computed: {
        contentModifiers() {
            return _.pick(this, 'overflowWrapAnywhere');
        },
    },
    template: template(),
};
