const _ = require('lodash');

const DEFAULT_BINDING_SETTING = {
    autoSendEvent: true,
};

/**
 * The structure used to configure the "prop / internal value" binding
 * @typedef {Object} PropBinding
 * @property {String} prop Prop name
 * @property {boolean} [autoSendEvent=true] Indicates whether the mixin automatically sends an event on internal value update
 */

/**
 * This mixin automatically creates:
 *   - internal values for each prop given as input
 *   - everything needed for two-way binding of those props with '.sync' keyword
 *
 * @param {object} options
 * @param {String[]|PropBinding[]} options.props A array of prop binding rules which needs to have an internal value in the component
 */
module.exports = function ({props} = {}) {
    const propsBindingSettings = _.map(props, prop => {
        const bindingSettings = _.isString(prop) ? {prop} : prop;
        return _.defaults({}, bindingSettings, DEFAULT_BINDING_SETTING);
    });
    // @vue/component
    return {
        data() {
            return _(propsBindingSettings)
                .map(({prop}) => [getInternalPropName(prop), this[prop]])
                .fromPairs()
                .value();
        },
        mounted() {
            // see https://github.com/vuejs/vue/issues/844
            _.each(propsBindingSettings, ({prop, autoSendEvent}) => {
                if (autoSendEvent) {
                    this.$watch(getInternalPropName(prop), () => {
                        this.sendUpdateEvent(prop);
                    });
                }
                this.$watch(prop, newValue => {
                    this.setInternalValue(prop, newValue);
                });
            });
        },
        methods: {
            setInternalValue(propName, value) {
                this[getInternalPropName(propName)] = value;
            },
            sendUpdateEvent(propName) {
                this.$emit(`update:${propName}`, this[getInternalPropName(propName)]);
            },
        },
    };
};

function getInternalPropName(propName) {
    return `internal${_.upperFirst(propName)}`;
}
