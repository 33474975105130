const Vue = require('vue');
const Vuex = require('vuex');
Vue.use(Vuex);
const RealEstateEstimatorStore = require('../realEstatePropertyDescriptor/estimator/RealEstateEstimatorStore');
const RealEstateSubmissionStore = require('../realEstatePropertyDescriptor/submission/RealEstateSubmissionStore');
const {buyEstimator} = RealEstateEstimatorStore.get('buyEstimator');
const {rentEstimator} = RealEstateEstimatorStore.get('rentEstimator');
const {sellSubmission} = RealEstateSubmissionStore.get('sellSubmission');
const {rentSubmission} = RealEstateSubmissionStore.get('rentSubmission');
const cmp = require('../cmp/cmpStore');
const favoriteAds = require('../favorites/favoriteAdsStore');
const realEstateAdContacts = require('../realEstateAdServices/realEstateAdContactsStore');
const stats = require('../stats/StatsStore');
const rentalApplication = require('../rentalApplication/store');
const savedSearches = require('../search/SavedSearchesStore');

let store;

module.exports = {
    get,
    commit,
};

function get() {
    if (!store) {
        store = new Vuex.Store({
            strict: process.env.NODE_ENV !== 'production',
            modules: {
                buyEstimator,
                rentEstimator,
                sellSubmission,
                rentSubmission,
                cmp,
                realEstateAdContacts,
                stats,
                favoriteAds,
                rentalApplication,
                savedSearches,
            },
            state() {
                return {
                    tactileKeyboardVisible: false,
                };
            },
            mutations: {
                setTactileKeyboardVisible(state, visible) {
                    state.tactileKeyboardVisible = visible;
                },
            },
        });
        store.dispatch('cmp/init');
        store.dispatch('favoriteAds/init');
    }
    return store;
}

function commit(...arg) {
    return get().commit(...arg);
}
