const {resourceUrl} = require('fack');
const template = require('./PresentationModal.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const internalValue = require('../../form/components/mixins/withInternalValue');
const AuthenticationPopup = require('../../authentication/AuthenticationPopup');
const Account = require('../../authentication/Account');
const PrivacyLink = require('./PrivacyLink');
const DocumentListBlock = require('./DocumentListBlock');
const rentalApplicationTrackingMixin = require('../../form/components/mixins/rentalApplicationTrackingMixin');

const IMAGE_URL = resourceUrl('images/stayintouch.jpg');

// @vue/component
module.exports = {
    name: 'PresentationModal',
    components: {
        PrivacyLink,
        DocumentListBlock,
    },
    mixins: [
        i18nMixin({
            prefix: 'PresentationModal.',
            keys: [
                'title',
                'firstParagraph',
                'titleList',
                'secondParagraph',
                'thirdParagraph',
                'fourthParagraph',
                'fifthParagraph',
                'finalParagraph',
                'buttonContinue',
                'buttonCancel',
                'buttonCreateAccount',
            ],
        }),
        internalValue,
        rentalApplicationTrackingMixin,
    ],
    props: {
        value: Boolean,
    },
    constants: {
        IMAGE_URL,
    },
    computed: {
        documentList() {
            return this.t('documents', {
                returnObjectTrees: true,
            });
        },
        isRegistered() {
            return Account.isRegistered();
        },
        confirmButtonText() {
            return this.isRegistered ? this.t('buttonContinue') : this.t('buttonCreateAccount');
        },
    },
    methods: {
        handleContinueButton() {
            if (this.isRegistered) {
                this.internalValue = false;
                this.$emit('next');
            } else {
                this.openAuthenticationPopup();
            }
            this.$store.dispatch('rentalApplication/updatePopupSource', 'createFolder');
        },
        openAuthenticationPopup() {
            this.internalValue = false;
            const authenticationPopup = new AuthenticationPopup();
            authenticationPopup.showCreateAccount();
        },
    },
    template: template(),
};
