const {SET_STATS} = require('../store/Mutations');

module.exports = {
    namespaced: true,
    state() {
        return {
            stats: {},
        };
    },
    getters: {
        getStats: (state) => (realEstateAdId) => {
            return state.stats[realEstateAdId];
        },
    },
    mutations: {
        [SET_STATS]: (state, {realEstateAdId, stats}) => {
            state.stats = {
                ...state.stats,
                [realEstateAdId]: stats,
            };
        },
    },
};
