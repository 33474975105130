const _ = require('lodash');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const {getClosedByAccountInfo} = require('./helpers');
const template = require('./NotOnTheMarketOverlay.jade');
const CancelUnpublishButton = require('./CancelUnpublishButton');
const isSoldRealEstateAd = require('../../../utils/isSoldRealEstateAd');

// @vue/component
module.exports = {
    components: {
        CancelUnpublishButton,
    },
    mixins: [
        i18nMixin({
            prefix: 'NotOnTheMarketOverlay.',
            keys: [
                'explanation',
                'manuallyDisabled',
                'manuallyDisabledBySelf',
                'manuallyDisabledByOwner',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        noInteraction: Boolean,
    },
    data() {
        return {
            closedByAccountName: undefined,
            closedByAccountIdOrNamespace: undefined,
        };
    },
    computed: {
        realEstateAdStatus() {
            return this.realEstateAd.status;
        },
        userRelativeData() {
            return this.realEstateAd.userRelativeData;
        },
        displayOverlay() {
            const {
                realEstateAdStatus,
            } = this;
            return !realEstateAdStatus.onTheMarket && !isSoldRealEstateAd(this.realEstateAd);
        },
        closedByUser() {
            return this.realEstateAdStatus.closedByUser;
        },
        canChangeOnTheMarket() {
            return this.userRelativeData.canChangeOnTheMarket;
        },
        isInteractive() {
            return !this.noInteraction;
        },
        pitch() {
            const {
                closedByUser,
                closedByAccountName,
                canChangeOnTheMarket,
                userRelativeData: {
                    isClosedBySelf,
                    isClosedByOwner,
                },
                explanation,
                manuallyDisabled,
                manuallyDisabledBySelf,
                closedByAccountNamePitch,
                manuallyDisabledByOwner,
            } = this;

            let pitch = explanation;
            if (closedByUser) {
                if (isClosedBySelf) {
                    pitch = manuallyDisabledBySelf;
                } else if (closedByAccountName) {
                    pitch = closedByAccountNamePitch;
                } else if (canChangeOnTheMarket) {
                    if (isClosedByOwner) {
                        pitch = manuallyDisabledByOwner;
                    } else {
                        pitch = manuallyDisabled;
                    }
                }
            }
            return pitch;
        },
        closedByAccountNamePitch() {
            const {
                closedByAccountName,
                closedByAccountIdOrNamespace,
                isInteractive,
                userRelativeData: {
                    isClosedByOwner,
                },
            } = this;

            let context = 'By';
            let accountStatsLink;
            if (isClosedByOwner) {
                context += 'Owner';
            }
            if (closedByAccountIdOrNamespace && isInteractive) {
                accountStatsLink = '/statistiques/' + closedByAccountIdOrNamespace;
                context += 'WithLink';
            }
            return this.t('manuallyDisabledBy', {
                context,
                closedBy: _.escape(closedByAccountName),
                adLink: accountStatsLink,
            });
        },
        displayCancelUnpublishButton() {
            const {
                closedByUser,
                canChangeOnTheMarket,
                isInteractive,
            } = this;
            return closedByUser && canChangeOnTheMarket && isInteractive;
        },
    },
    mounted() {
        const {
            userRelativeData: {
                closedBy,
                isClosedBySelf,
            },
        } = this;
        if (!isClosedBySelf && closedBy) {
            getClosedByAccountInfo(closedBy, (err, closedBy) => {
                if (!err && closedBy) {
                    this.closedByAccountName = closedBy.name;
                    this.closedByAccountIdOrNamespace = closedBy.accountIdOrNamespace;
                }
            });
        }
    },
    methods: {
        onBackOnTheMarket(modifiedAd) {
            this.$emit('back-on-the-market', modifiedAd);
        },
    },
    template: template(),
};
