function Error404(message) {
    Error.call(this, message);
    this.message = message;
    this.statusCode = 404;
    this.code = this.statusCode;
}

Error404.prototype = new Error();
Error404.prototype.constructor = Error404;

module.exports = Error404;
