const {loadImage} = require('./ImageLoader');

const WEB_PSAMPLE_URI = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';

let _isSupported;

module.exports = {
    detect,
    isSupported,
};

function detect(cb) {
    loadImage(WEB_PSAMPLE_URI, (err, image) => {
        if (err) {
            _isSupported = false;
        } else {
            _isSupported = image.width === 2 && image.height === 1;
        }
        cb();
    });
}

function isSupported() {
    return _isSupported;
}
