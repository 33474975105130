const Account = require('../authentication/Account');
const MyAccountContactsPage = require('./pro/MyAccountContactsPage');
const _ = require('lodash');
const Error404 = require('../Error404');
const async = require('async');
const BrowserDetect = require('browser-detect');
const SelectOwnedAccountView = require('../views/SelectOwnedAccountView');
const PageManager = require('./PageManager');
const Pages = require('./Pages');
const EventPack = require('../utils/EventPack');

module.exports = class AccountContactsPage extends MyAccountContactsPage {
    constructor() {
        super({
            name: 'accountContacts',
            title: 'Contacts',
        });
        this._selectOwnedAccountView = new SelectOwnedAccountView({
            menu: 'accountContacts',
        });
        this._eventPack = new EventPack();
    }

    getUrlPattern() {
        return '/contacts/{id}';
    }

    parseUrl(url, accountId) {
        return _.extend({accountId}, MyAccountContactsPage.getContactsQueryParameters(url));
    }

    open(options) {
        super.open(options);
        if (this._selectOwnedAccountView) {
            this._eventPack.on(this._selectOwnedAccountView, 'accountSelected', _.bind(this._onAccountSelected, this));
        }
    }

    close() {
        this._eventPack.removeAllListeners();
        if (this._selectOwnedAccountView) {
            this._selectOwnedAccountView.removeListener('accountSelected', this._onAccountSelected);
        }
        super.close();
    }

    getMiddleViews() {
        const views = [this.accountContactsView];
        if (this.isPageAccessAllowed() && !BrowserDetect.isMobile()) {
            views.unshift(this._selectOwnedAccountView);
        }
        return views;
    }

    isPageAccessAllowed() {
        return !this.isOpen || Account.hasRole('contactViewer') || this.isAncestorOfDisplayedAccount;
    }

    _loadData(options, cb) {
        if (options.accountId) {
            async.auto({
                account: _.partial(Account.get, options.accountId),
                isAncestorOfDisplayedAccount: [
                    'account', function (callback, {account}) {
                        Account.isAncestorOfAccount(account && account.id, callback);
                    },
                ],
            }, (err, results) => {
                if (err) {
                    if (err.code === 404) {
                        cb(new Error404(err.message));
                    } else {
                        cb(err);
                    }
                } else if (results.account) {
                    this.isAncestorOfDisplayedAccount = results.isAncestorOfDisplayedAccount;
                    cb(null, _.extend(options, {
                        account: results.account,
                        isAncestorOfDisplayedAccount: results.isAncestorOfDisplayedAccount,
                    }));
                } else {
                    cb(new Error404('account not found'));
                }
            });
        } else {
            cb(new Error404('no account id'));
        }
    }

    _onAccountSelected(accountId, onlyOwnData) {
        if (accountId == Account.getAuthenticatedAccountId() && Pages.myAccountStatsPage) {
            PageManager.openPage(Pages.myAccountStatsPage, {onlyOwnData});
        } else {
            PageManager.openPage(Pages.contactsPage, {
                accountId,
                onlyOwnData,
            });
        }
    }
};
