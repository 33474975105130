const _ = require('lodash');
const FieldInputWidget = require('./FieldInputWidget');
const ImageUploaderWidget = require('../ImageUploaderWidget');

module.exports = class PhotoWidget extends FieldInputWidget {
    constructor($container, data) {
        super($container, data);
        this.imageUploaderWidget = new ImageUploaderWidget();
        this.imageUploaderWidget.init({
            $container: this.getElement().find('#logoUpload'),
            maxFiles: null,
            required: data.required,
            name: data.name,
        });
    }

    whenReady(cb) {
        if (this.imageUploaderWidget.isUploadingFile()) {
            //this won't work with multiple simultaneous calls :(
            this.imageUploaderWidget.setUploadFinishedCallback(cb);
        } else {
            _.defer(cb);
        }
    }

    getFieldValue() {
        return this.imageUploaderWidget.getFiles();
    }

    setFieldValue(value) {
        return this.imageUploaderWidget.setFiles(value);
    }

    serializeInto(result) {
        const value = this.getFieldValue();
        const photos = [];
        _.each(value, function (image) {
            photos.push({
                photo: image,
            });
        });

        if (value != null) {
            result[this.name] = photos;
        }
    }
};
