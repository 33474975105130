const _ = require('lodash');

const LocationItem = require('./LocationItem');
const CityLocationItem = require('./CityLocationItem');

module.exports = {
    getInstance,
};

function getInstance(item, options = {}) {
    if (item instanceof LocationItem) {
        return item;
    }
    const canonicalItem = _.extend({}, item, {
        type: item.type.replace(/^alias-/, ''),
    });

    const locationItems = {
        default: LocationItem,
        neighborhood: require('./NeighborhoodLocationItem'),
        city: CityLocationItem,
        arrondissement: CityLocationItem,
        'delegated-city': CityLocationItem,
        department: require('./DepartmentLocationItem'),
        circleZone: require('./CircleLocationItem'),
        drawnZone: require('./DrawingLocationItem'),
        travelTimeZone: require('./TravelTimeItem'),
        postalCode: require('./PostalCodeItem'),
    };
    const locationItem = locationItems[canonicalItem.type] || locationItems.default;
    return new locationItem(_.extend(options, {item: canonicalItem}));
}
