const _ = require('lodash');
const template = require('./NeighborhoodStats.jade');
const {statToGauge} = require('../../../../common/KelQuartierHelper');
const {i18n: {translate}} = require('fack');
const NeighborhoodCategory = require('./NeighborhoodCategory');

const CATEGORY_PROPERTIES = {
    goOut: {
        color: '#f07eb8',
        icon: 'md-local-bar',
        gauge: 33,
        scope: [
            {
                'Commerce et Service': ['Bar', 'Restaurant'],
            },
            'Culture',
            'Loisirs',
        ],
    },
    shopping: {
        color: '#fdb82a',
        icon: 'md-local-mall',
        gauge: 35,
        scope: [
            {
                'Commerce et Service': [
                    'Banque', 'Boucherie',
                    'Boulangerie', 'Bureau de poste',
                    'Marché de quartier', 'Presse et Tabac',
                    'Supérette', 'Supermarché et Hypermarché',
                ],
            },
        ],
    },
    school: {
        color: '#a18565',
        icon: 'md-school',
        gauge: 213,
        scope: [
            'Education',
            'Petite enfance',
        ],
    },
    transports: {
        color: '#0ea4cd',
        icon: 'md-directions-transit',
        gauge: 217,
        isTransports: true,
        scope: [
            {
                Transport: [
                    'Autolib\'', 'Gare ferroviaire',
                    'Location de vélo', 'Parking',
                    'Station de taxi', 'Station service', 'Vélib\'',
                ],
            },
        ],
    },
    health: {
        color: '#43b36e',
        icon: 'md-local-hospital',
        gauge: 110,
        scope: [
            'Santé',
        ],
    },
};

// @vue/component
module.exports = {
    components: {
        NeighborhoodCategory,
    },
    props: {
        neighborhoodInfo: {
            type: Object,
            required: true,
        },
        department: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            neighborhoodStats: this.neighborhoodInfo.stats,
        };
    },
    computed: {
        sectionTitle() {
            return this.t('sectionTitle.', this.neighborhoodInfo.type);
        },
        categories() {
            return _(CATEGORY_PROPERTIES)
                .map(this.createCategoryData)
                .filter(({items}) => !_.isEmpty(items))
                .value();
        },
    },
    methods: {
        t(prefix, key) {
            return translate('NeighborhoodStats.' + prefix + key);
        },
        gauge(key) {
            return statToGauge(this.neighborhoodStats[key]) * 100 / 6;
        },
        createCategoryData(properties, name) {
            const items = {};
            const {
                department,
                neighborhoodInfo: {
                    transports,
                    poiCounts = {},
                },
            } = this;
            if (properties.isTransports) {
                const departmentClass = department ? 'department-' + department : undefined;
                _.forEach(transports, (values, type) => {
                    items[type] = {
                        class: 'transport-' + type,
                        subItemClassPrefix: 'transport-line-',
                        departmentClass,
                        values,
                    };
                });
            }
            _.extend(items, mergeMapResults(properties.scope, filter => filterPoiCountsData(poiCounts, filter)));
            return {
                title: this.t('categories.', name),
                color: properties.color,
                icon: properties.icon,
                gauge: this.gauge(properties.gauge),
                items,
            };
        },
    },
    template: template(),
};

function mergeMapResults(collection, iteratee) {
    return _.merge({}, ..._.map(collection, iteratee));
}

function filterPoiCountsData(poiCounts, filter) {
    if (_.isObject(filter)) {
        return mergeMapResults(filter, (subFields, key) => {
            return _.pick(poiCounts[key], subFields);
        });
    } else if (_.isString(filter)) {
        return poiCounts[filter];
    }
}
