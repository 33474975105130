const $ = require('jquery');
const _ = require('lodash');

const TEXT_INPUT_FIELDS_SELECTOR = require('../utils/textInputFieldsSelector');

module.exports = {
    init,
    clear,
};

function init($form) {
    $form.on('keypress.focusnextonenter', 'input[name]', _.partial(onKeyPress, $form));
}

function clear($form) {
    $form.off('keypress.focusnextonenter');
}

function onKeyPress($form, event) {
    if (event.which == '13') {
        onEnterPressed($form, event);
    }
}

function onEnterPressed($form, event) {
    const target = event.target;
    let isValid = true;
    const bootstrapValidator = $form.data('bootstrapValidator');
    if (bootstrapValidator) {
        bootstrapValidator.validateField($(target));
        isValid = bootstrapValidator.isValidField($(target));
    }
    if (isValid) {
        const inputsList = $form.find(TEXT_INPUT_FIELDS_SELECTOR);
        const currentInputIndex = _.indexOf(inputsList, target);

        if (-1 != currentInputIndex && currentInputIndex + 1 < inputsList.length) {
            const nextInput = inputsList[currentInputIndex + 1];
            nextInput.focus();
            event.preventDefault();
        }
    } else {
        event.preventDefault();
    }
}
