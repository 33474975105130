const _ = require('lodash');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const Views = require('../../views/Views');
const AdsRequestManager = require('../../AdsRequestManager');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const Account = require('../../authentication/Account');
const template = require('./SetFeaturedAction.jade');
const SetFeaturedBuyingForm = require('./SetFeaturedBuyingForm');
const ActionAndButton = require('./mixins/ActionAndButton');
const RealtimeServer = require('../../RealtimeServer');
const EVENT_FEATURE_VALUE_TO_SET = 'featured-value-set';

// @vue/component
module.exports = {
    components: {
        SetFeaturedBuyingForm,
    },
    mixins: [
        ActionAndButton({
            keys: [
                'title',
            ],
        }),
    ],
    model: {
        // Use v-model to accept the value to be set, and immediately reset it to null,
        // ready to accept another value. However the value might not be applied, depending on rights and potentially payment
        prop: 'featuredValueToSet',
        event: EVENT_FEATURE_VALUE_TO_SET,
    },
    props: {
        featuredValueToSet: Boolean,
    },
    data() {
        return {
            modalVisible: false,
        };
    },
    computed: {
        realEstateAdId() {
            return this.realEstateAd.id;
        },
    },
    watch: {
        featuredValueToSet(newValue) {
            if (newValue != null) {
                this.setFeaturedValue(newValue);
            }
        },
        realEstateAdId() {
            this.listenToRealEstateAdFeatureStatusUpdate();
        },
        featureName() {
            this.listenToRealEstateAdFeatureStatusUpdate();
        },
    },
    mounted() {
        if (this.featuredValueToSet) {
            this.setFeaturedValue(!this.isFeatured);
        }
        this.listenToRealEstateAdFeatureStatusUpdate();
    },
    beforeDestroy() {
        this.realtimeContext.close();
    },
    methods: {
        listenToRealEstateAdFeatureStatusUpdate() {
            if (this.realtimeContext) {
                this.realtimeContext.close();
            }
            const realtimeContext = this.realtimeContext = RealtimeServer.openContext();
            realtimeContext.on(`ad:${this.realEstateAdId}:featured:${this.featureName}:status`,
                message => this.adFeaturedMessageHandler(message));
            realtimeContext.join('ad#' + this.realEstateAdId);
        },
        showExpirationDateVolatile() {
            Views.volatileFeedback.showSuccess(this.t('locked', {
                humanizedEndDate: DateFormatter.humanizeEndDate(this.expirationDate).toLowerCase(),
            }));
        },
        setFeaturedValue(value) {
            this.$emit('pending-request', true);
            const {realEstateAdId, featureName} = this;
            AdsRequestManager.setFeaturedProperty(
                featureName,
                realEstateAdId,
                value,
                (err, result) => {
                    this.$emit('pending-request', false);
                    if (err) {
                        if (value && this.shouldDisplayBuyModal(err)) {
                            this.modalVisible = true;
                        } else if (this.isRemovableFeaturedAdError(err)) {
                            this.showExpirationDateVolatile();
                        } else {
                            this.showError(err, realEstateAdId);
                        }
                    } else {
                        const {
                            modifiedAd: {
                                [featureName]: value,
                                [featureName + 'Until']: endDate,
                                modificationDate,
                            },
                            indexed,
                        } = result;
                        this.onFeatureChange({value, endDate, modificationDate, indexed});
                    }
                }
            );
            this.$emit(EVENT_FEATURE_VALUE_TO_SET, null);
        },
        hideModal() {
            this.modalVisible = false;
        },
        showError(err, realEstateAdId) {
            if (!isTooManyFeaturedAdsError(err)) {
                console.error(err, `Could not set property ${this.featureName} on ${realEstateAdId}`);
            }
            const errorKeyPrefix = this.translationKeyPrefix + 'errors.';
            Views.volatileFeedback.showError(this.translate([
                errorKeyPrefix + err.statusCode,
                errorKeyPrefix + 'unknownError',
            ], {count: _.get(err, 'info.maxFeaturedAdsCount')}));
        },
        onFeatureChange({value, endDate, modificationDate, indexed}) {
            this.sendGTMTracking();
            if (!indexed) {
                Views.volatileFeedback.showSuccess(this.t('notIndexedYet'));
            }
            this.$emit('featured-property-changed', this.featureName, {value, modificationDate, endDate});
        },
        sendGTMTracking() {
            GoogleTagManager.sendEvent('toggleRealEstateAdLeadingStatus', {
                realEstateAdId: this.realEstateAdId,
                agencyId: Account.getAuthenticatedAccountId(),
            });
        },
        shouldDisplayBuyModal(err) {
            return this.canBuyFeature && isTooManyFeaturedAdsError(err);
        },
        isRemovableFeaturedAdError(err) {
            return err.statusCode == 401 && !_.get(err.info, `canRemove${_.capitalize(this.featureName)}`);
        },
        adFeaturedMessageHandler(message) {
            const {value, modificationDate, indexed, endDate} = message;
            this.onFeatureChange({value, endDate, modificationDate, indexed});
        },
    },
    template: template(),
};

function isTooManyFeaturedAdsError(err) {
    return err.statusCode == 403 && _.has(err.info, 'maxFeaturedAdsCount');
}
