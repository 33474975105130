const AbTesting = require('../AbTesting');
const ABTestingHelper = require('../../common/ABTestingHelper');
const Analytics = require('../utils/Analytics');

const ANALYTICS_PHONE_CONTACT = 'contacts.phone';

module.exports = {
    increment,
};

function increment() {
    Analytics.incCounter(ANALYTICS_PHONE_CONTACT + '.' + ABTestingHelper.getABTestingString(AbTesting.getSavedOptionsForContact()));
}
