const _ = require('lodash');
const async = require('async');

const LoanSimulatorBasePage = require('./LoanSimulatorBasePage');
const Account = require('../authentication/Account');

module.exports = class LoanSimulatorResultsPage extends LoanSimulatorBasePage {
    constructor(configuration) {
        configuration = _.extend({
            name: 'LoanSimulatorResultsPage',
            title: 'Résultat de la simulation financière',
        }, configuration);
        super(configuration);
    }

    open(options) {
        super.open(options);
        this.results = options.results;
        this.sendGtmEvent(`${this.configuration.gtmPrefix}-resultsShown`, {results: this.results});
    }

    _bindEvents() {
        this._eventPack.on(this.currentBuiltInView, 'backToSimulator', _.bind(this._openSimulatorPage, this));
    }

    _openSimulatorPage() {
        super._openSimulatorPage(this._getReopenSimulatorOptions());
    }

    _getReopenSimulatorOptions() {
        return {
            previousSimulatorData: this.options.simulatorData,
            contactData: this.options.contactData,
        };
    }

    _loadData(options, cb) {
        if (!options.results) {
            async.auto({
                account: Account.getAccountAndCreateGuestIfNeeded,
                results: [
                    'account',
                    cb => {
                        this._requestSimulation(options, cb);
                    },
                ],
                // eslint-disable-next-line handle-callback-err
            }, (err, {results}) => {
                super._loadData(_.extend({results}, options), cb);
            });
        } else {
            super._loadData(options, cb);
        }
    }

    _getBuiltInView() {
        return this.resultsView;
    }

    _setBuiltInViewContainer() {
        this.currentBuiltInView.setContainer(this._getBuiltInContainer());
    }

    _getBuiltInContainer() {
        return this.contentView.$element.find('.loanSimulatorResultsContainer');
    }
};
