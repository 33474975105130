const _ = require('lodash');
const {isMobile, isTablet} = require('browser-detect');
const template = require('./MapTutorial.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'MapTutorial.',
            keys: [
                'containerTitle',
                'closeTutorialTitle',
                'tactileSwitchBtnTitle',
                'mouseSwitchBtnTitle',
                'mouseLeftTitle',
                'mouseTutorialMouseLeftDescription',
                'mouseWheelTitle',
                'mouseWheelDescription',
                'mouseRightTitle',
                'mouseTutorialMouseRightDescription',
                'tactileTutorialMouseLeftDescription',
                'tactileTutorialMouseRightDescription',
                'zoomAndRotateTitle',
                'zoomAndRotateDescription',
            ],
        }),
    ],
    props: {
        position: {
            type: String,
            default: 'top-left',
        },
    },
    data() {
        return {
            isTutorialOpen: false,
            isMouseTutorial: true,
            isTactileTutorial: false,
            containerCloseAnimationClass: '',
        };
    },
    computed: {
        positionModifiers() {
            const {position} = this;
            return {
                [position]: true,
            };
        },
        mouseTutorials() {
            return [
                {
                    tuto: 'mouseLeft',
                    cursor: 'cursor',
                    title: this.mouseLeftTitle,
                    description: this.mouseTutorialMouseLeftDescription,
                    gifAnimation: 'mouseLeftCursor',
                },
                {
                    tuto: 'mouseWheel',
                    cursor: 'cursor',
                    title: this.mouseWheelTitle,
                    description: this.mouseWheelDescription,
                    gifAnimation: 'mouseWheelCursor',
                },
                {
                    tuto: 'mouseRight',
                    cursor: 'cursor',
                    title: this.mouseRightTitle,
                    description: this.mouseTutorialMouseRightDescription,
                    gifAnimation: 'mouseRightCursor',
                },
            ];
        },
        tactileTutorials() {
            return [
                {
                    tuto: 'mouseLeft',
                    cursor: 'onefinger',
                    title: this.mouseLeftTitle,
                    description: this.tactileTutorialMouseLeftDescription,
                    gifAnimation: 'mouseLeftOneFinger',
                },
                {
                    tuto: 'mouseRight',
                    cursor: 'twofingers',
                    title: this.mouseRightTitle,
                    description: this.tactileTutorialMouseRightDescription,
                    gifAnimation: 'mouseRightTwofingers',
                },
                {
                    tuto: 'touchZoomAndRotate',
                    cursor: 'twofingerszoom',
                    title: this.zoomAndRotateTitle,
                    description: this.zoomAndRotateDescription,
                    gifAnimation: 'touchZoomAndRotateTwofingerszoom',
                },
            ];
        },
    },
    mounted() {
        if (isMobile() || isTablet()) {
            this.isTactileTutorial = true;
            this.isMouseTutorial = false;
        }
    },
    methods: {
        openVersion(version) {
            const isTactile = version === 'tactileVersion';
            this.isTactileTutorial = isTactile;
            this.isMouseTutorial = !isTactile;
        },
        toggleTutorialModal() {
            if (this.isTutorialOpen) {
                this.containerCloseAnimationClass = 'hideWithAnimation';
                _.delay(() => {
                    this.isTutorialOpen = !this.isTutorialOpen;
                }, 300);
            } else {
                this.isTutorialOpen = !this.isTutorialOpen;
                this.containerCloseAnimationClass = '';
            }
        },
    },
    template: template(),
};
