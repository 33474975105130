const template = require('./MenuItem.jade');
const MenuLine = require('./MenuLine');

// @vue/component
module.exports = {
    components: {
        MenuLine,
    },
    props: {
        href: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
    },
    template: template(),
};
