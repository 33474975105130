const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const AsyncHelper = require('../utils/AsyncHelper');
const MarkerScaleHelper = require('../utils/MarkerScaleHelper');
const util = require('util');
const _ = require('lodash');
const fack = require('fack');
const mercatorProjection = require('mercatorProjection');
const isSoldRealEstateAd = require('../utils/isSoldRealEstateAd');
const BrowserDetect = require('browser-detect');
const $ = require('jquery');
const ngeohash = require('ngeohash');
const {EventEmitter} = require('events');
const MarkerZIndex = require('../utils/MarkerZIndex');

module.exports = AllResultsWebGLGrid;

const ICON_URL = fack.resourceUrl('images/map/markers/marker_noprice.png');

const NUMBER_OF_VERTICES_PER_MARKER = 4;

const TILE_ZOOMS = [
    {zoom: 3, requestZoom: 6, scale: 0.1, geoHashPrecision: 6},
    {zoom: 4, requestZoom: 6, scale: 0.1, geoHashPrecision: 6},
    {zoom: 5, requestZoom: 6, scale: 0.1, geoHashPrecision: 6},
    {zoom: 6, requestZoom: 6, scale: 0.1, geoHashPrecision: 6},
    {zoom: 7, requestZoom: 6, scale: 0.1, geoHashPrecision: 6},
    {zoom: 8, requestZoom: 6, scale: 0.2, geoHashPrecision: 6},
    {zoom: 9, requestZoom: 9, scale: 0.2, geoHashPrecision: 9},
    {zoom: 10, requestZoom: 9, scale: 0.3, geoHashPrecision: 9},
    {zoom: 11, requestZoom: 9, scale: 0.3, geoHashPrecision: 9},
    {zoom: 12, requestZoom: 9, scale: 0.4, geoHashPrecision: 9},
    {zoom: 13, requestZoom: 9, scale: 0.4, geoHashPrecision: 9},
    {zoom: 14, requestZoom: 9, scale: 0.5, geoHashPrecision: 9},
    {zoom: 15, requestZoom: 9, scale: 0.5, geoHashPrecision: 9},
    {zoom: 16, requestZoom: 13, scale: 0.7, geoHashPrecision: 9},
    {zoom: 17, requestZoom: 13, scale: 0.8, geoHashPrecision: 9},
    {zoom: 18, requestZoom: 13, scale: 1, geoHashPrecision: 9},
    {zoom: 19, requestZoom: 13, scale: 1, geoHashPrecision: 9},
    {zoom: 20, requestZoom: 13, scale: 1, geoHashPrecision: 9},
];
const PROGRAM_NAME = 'rapidMarkerRenderer';

function AllResultsWebGLGrid(options) {
    this.options = options;
    this.searchCriteria = null;
    this.searchOptions = null;
    this._tilesToLoad = {};
    this._tilesToUpdate = {};
    this._alreadyHiddenDisplayedAds = {};
    this._adsIdToHide = [];
    this.loadTilesDebounced = _.debounce(_.bind(this._loadTiles, this), 1);
    this._scaleDensity = 1.0;
    // Handle scaling steps for markers
    this._markerScaleHelper = new MarkerScaleHelper(TILE_ZOOMS);
    this._currentMarkerScale = 1.0;
    this._markerSize = 10.0;
    this._pixelRatio = 1.0;
    this._markerZIndex = MarkerZIndex.min;
    this._markerTouchScaling = 3.0;
    this._markerDataToDelete = [];
    this._hoverEnabled = true;
    this._clickEnabled = true;
    if (BrowserDetect.isMobile() || BrowserDetect.isTablet()) {
        this._markerTouchScaling = 3.0;
    }
    this._zoomChangedListener = _.bind(this._handleZoomChanged, this);
    this._cameraChangedCallback = _.bind(this._handleCameraChanged, this);
    this._on3dChangedCallback = _.bind(this._handle3dChanged, this);
    this._maxMarkerPerTile = 0;
    this._maxMarkerPerTileToForceScale = 5000;
    this._minMarkerPerTileToForceScale = 20;
    this._enabled = true;
    this._markerMaterials = {};
    this._markerMaterials[ICON_URL] = null;
    this._lineMaterial = null;
    this._allMarkersParent = null;
    this._allLinesParent = null;
    this._pickingHandler = _.bind(this._handleMouseEvent, this);

    this._vector3d = null;
    this._screenPositionsDirty = false;
    this._adsInScreen = [];
    this._currentAdAtMouse = null;
    this._cursorClickable = 'hover-clickable';
    this._cursorZoomable = 'hover-zoomable';
    this._alwaysInFront = options.alwaysInFront;
    this._disableLine = options.disableLine;
    this._disableHeight = options.disableHeight;
    this._geohashClickedAsyncHelper = new AsyncHelper();
    // Get grid zoom for minimal data
    this._gridZoomForMinimalData = this.options.zoomForMinimalData;
    this._pendingRequests = {};
    this._onUseDevicePixelRatioChangedListener = _.bind(this._onPixelRatioChanged, this);
    this._onDivRemovedHandler = _.bind(this._onDivRemoved, this);
    this._elevationModeChangedCbk = _.bind(this._elevationModeChanged, this);
    this._onTileElevationsUpdatedCbk = _.bind(this._onTileElevationsUpdated, this);
    this._onMapResizeHandler = _.bind(this._onMapResize, this);
}

util.inherits(AllResultsWebGLGrid, EventEmitter);

const proto = AllResultsWebGLGrid.prototype;

proto.isMapCreated = function () {
    const sideMapView = SideMapViewSingleton.get();
    return sideMapView.map && sideMapView.map._renderer;
};

proto.setEnabled = function (enabled) {
    if (this._enabled != enabled) {
        this._enabled = enabled;
        if (this._enabled && this._isShown) {
            const gridLayer = this._gridLayer;
            gridLayer.forceUpdateTiles();
            gridLayer.forEach(this._loadAllResults, this);
        }
    }
};

proto._onDivRemoved = function () {
    this.hide();
};

proto.show = function () {
    const sideMapView = SideMapViewSingleton.get();
    if (!this._isShown && this.isMapCreated() && sideMapView.isWebglEnabled()) {
        this._isShown = true;
        const {map} = sideMapView;
        let gridLayer = this._gridLayer;
        if (!gridLayer) {
            const PoiGridLayer = require('../poi/PoiGridLayer');
            const cameraZoom = map.getZoom();
            const gridZoom = this._getGridZoomForCameraZoom(cameraZoom);
            this._geoHashPrecision = gridZoom.geoHashPrecision;
            gridLayer = this._gridLayer = new PoiGridLayer({
                minZoom: 3,
                zoom: gridZoom.zoom,
                maxDistance: 7000,
            }, this);
            gridLayer.on('tile', _.bind(this._handleTile, this));
            map.on('recreate', _.bind(this._recreate, this));
        }
        // Create marker materials
        this._initRenderElements();

        // Force zoom
        this._handleZoomChanged();
        map.on('resize', this._onMapResizeHandler);

        map.on('divRemoved', this._onDivRemovedHandler);
        if (gridLayer.map != map) {
            gridLayer.setMap(map);
            const onUseDevicePixelRatioChangedListener = this._onUseDevicePixelRatioChangedListener;
            map.on('usedevicepixelratio_changed', onUseDevicePixelRatioChangedListener);
            map.on('devicePixelRatioOnMove_changed', onUseDevicePixelRatioChangedListener);
        }

        map.on('zoom_changed', this._zoomChangedListener);
        map._renderer.on('cameraChanged', this._cameraChangedCallback);
        map.on('on3dchange', this._on3dChangedCallback);
        // Add custom picking handler
        map._renderer.addCustomPickingHandler(this._pickingHandler);

        // Add listeners for the elevations
        const elevationModeChangedCbk = this._elevationModeChangedCbk;
        map.on('elevation_changed', elevationModeChangedCbk);
        map._renderer.on('enter2d', elevationModeChangedCbk);
        map._renderer.on('enter3d', elevationModeChangedCbk);
        if (map.elevation) {
            map._renderer.on('tile_elevations_updated', this._onTileElevationsUpdatedCbk);
        }

        // Force update only if search criteria have changed
        if (this.searchCriteria && this._searchCriteriaDirty) {
            this._searchCriteriaDirty = false;
        }
        gridLayer.forEach(this._loadAllResults, this);
    }
};

proto._recreate = function () {
    this.update();
};

proto.setHoverEnabled = function (enabled) {
    this._hoverEnabled = enabled;
};

proto.setClickEnabled = function (enabled) {
    this._clickEnabled = enabled;
    const sideMapView = SideMapViewSingleton.get();
    if (this.isMapCreated() && sideMapView.isWebglEnabled()) {
        const renderer = sideMapView.map._renderer;
        const pickingHandler = this._pickingHandler;
        if (enabled) {
            renderer.addCustomPickingHandler(pickingHandler);
        } else {
            renderer.removeCustomPickingHandler(pickingHandler);
        }
    }
};

proto.hide = function () {
    // Make sure element is hidden before unsetting map: needed to ensure that markers are released
    // Release tiles after hiding
    const sideMapView = SideMapViewSingleton.get();
    const gridLayer = this._gridLayer;
    if (this.isMapCreated() && sideMapView.isWebglEnabled() && gridLayer && gridLayer.map) {
        // Remove root node
        this.clearAllMarkers();
        gridLayer.setMap(null);
        const {map} = sideMapView;
        map.removeListener('resize', this._onMapResizeHandler);
        map.removeListener('divRemoved', this._onDivRemovedHandler);
        const renderer = map._renderer;
        const renderEngine = renderer.getRenderEngine();
        if (this._markerMaterials[ICON_URL]) {
            renderer.$parentNode.removeClass(this._cursorClickable);
            renderer.getScene().remove(this._allMarkersParent);
            delete this._allMarkersParent;
            const onUseDevicePixelRatioChangedListener = this._onUseDevicePixelRatioChangedListener;
            map.removeListener('usedevicepixelratio_changed', onUseDevicePixelRatioChangedListener);
            map.removeListener('devicePixelRatioOnMove_changed', onUseDevicePixelRatioChangedListener);

            const allLinesParent = this._allLinesParent;
            if (allLinesParent) {
                if (allLinesParent.parent) {
                    renderer.getScene().remove(allLinesParent);
                }
                delete this._allLinesParent;
            }

            const materials = _.clone(this._markerMaterials);
            _.each(materials, function (material) {
                renderEngine.releaseMaterial(material);
                const {texture} = material.uniforms.texture;
                if (texture) {
                    renderEngine.abortLoadingTexture(texture);
                    renderEngine.releaseTexture(texture);
                    delete material.uniforms.texture.texture;
                }
            });
            this._markerMaterials = {};
        }
        const lineMaterial = this._lineMaterial;
        if (lineMaterial) {
            renderEngine.releaseMaterial(lineMaterial);
            delete this._lineMaterial;
        }

        // Add custom picking handler
        renderer.removeCustomPickingHandler(this._pickingHandler);
        renderer.removeListener('cameraChanged', this._cameraChangedCallback);
        map.removeListener('on3dchange', this._on3dChangedCallback);
        // Remove building layer listeners
        map.removeListener('zoom_changed', this._zoomChangedListener);

        // Remove listeners for the elevations
        const elevationModeChangedCbk = this._elevationModeChangedCbk;
        map.removeListener('elevation_changed', elevationModeChangedCbk);
        renderer.removeListener('enter2d', elevationModeChangedCbk);
        renderer.removeListener('enter3d', elevationModeChangedCbk);
        if (map.elevation) {
            renderer.removeListener('tile_elevations_updated', this._onTileElevationsUpdatedCbk);
        }

        this._isShown = false;
    }
};

proto.clearAllMarkers = function () {
    const {asyncHelper} = this;
    if (asyncHelper) {
        asyncHelper.cancelAll();
    }
    this._gridLayer.releaseAllTiles();
    this._removePreviousZoomMeshes();
    const tilesToLoad = _.clone(this._tilesToLoad);
    _.each(tilesToLoad, tile => {
        this._abortLoadingTile(tile);
    });
    this._tilesToUpdate = {};
};

proto._initRenderElements = function () {
    const {map} = SideMapViewSingleton.get();
    const renderer = map._renderer;
    const engine = this._engine = renderer.getEngineHelpers();
    this._maxFloatCount = 65536 * 3;
    this._maxAdsPerGeometry = Math.floor(this._maxFloatCount / (NUMBER_OF_VERTICES_PER_MARKER * 3));
    const renderEngine = renderer.getRenderEngine();
    const shaderLibrary = renderEngine.getShaderLibrary();

    this._pixelRatio = renderEngine._webGL._pixelRatio;

    if (this._vector3d == null) {
        this._vector3d = new this._engine.Vector3();
    }

    // Create shader if needed
    const {effects} = shaderLibrary;
    if (!effects[PROGRAM_NAME]) {
        const {programs} = shaderLibrary;
        programs.vertex[PROGRAM_NAME] = require('../../assets/shaders/rapidMarkerRenderer.vshader');
        programs.fragment[PROGRAM_NAME] = require('../../assets/shaders/rapidMarkerRenderer.fshader');

        effects[PROGRAM_NAME] = {
            commonUniformKeys: ['viewport'],
            uniforms: {
                size: {type: 'f', value: 10.0},
                scale: {type: 'f', value: 1.0},
                color: {type: 'c', value: new this._engine.Color(0xffffff)},
                texture: {type: 't', slot: 0, texture: null},
                in3d: {type: 'i', value: 0},
                depth: {type: 'f', value: 1.0 / 16.0},
                clippingWorld: {type: 'v2', value: new this._engine.Vector2(3200, 2000)},
            },
            vertexProgramName: PROGRAM_NAME,
            fragmentProgramName: PROGRAM_NAME,
        };
    }

    // Create parents if needed
    if (this._allMarkersParent == null) {
        this._allMarkersParent = new engine.Object3D();
        this._allLinesParent = new engine.Object3D();
    }

    // Create materials if needed
    const markerMaterials = this._markerMaterials;
    if (markerMaterials[ICON_URL] == null) {
        markerMaterials[ICON_URL] = this._createMaterialForIconUrl(renderEngine, ICON_URL);
        this._lineMaterial = renderEngine.createMaterial('nolight', this._engine.Constants.NoBlending);

    } else {
        // Add to scene immediately if materials ready
        this._addMeshToScene(this._allMarkersParent);
    }
    if (!this._disableLine) {
        this._addMeshToScene(this._allLinesParent);
    }
    // Hide/show lines
    const isIn3d = map._renderer.isIn3d();
    this._setLinesVisible(isIn3d);
    this._computeClipping();
};

proto._setLinesVisible = function (visible) {
    if (!this._disableLine && this.isMapCreated()) {
        const {map} = SideMapViewSingleton.get();
        const allLinesParent = this._allLinesParent;
        const {parent} = allLinesParent;
        const scene = map._renderer.getScene();
        if (visible) {
            if (!parent) {
                scene.add(allLinesParent);
            }
        } else if (parent) {
            scene.remove(allLinesParent);
        }
    }
};

proto._onMapResize = function () {
    this._computeClipping();
};

proto._computeClipping = function () {
    if (this.isMapCreated()) {
        SideMapViewSingleton.get().map._renderer.computeClipping(this._markerMaterials[ICON_URL].uniforms.clippingWorld);
    }
};

proto._createMaterialForIconUrl = function (renderEngine, url) {

    // Create texture
    const engineConstants = this._engine.Constants;
    const mainMarkerTexture = renderEngine.getTexture(url, engineConstants.ClampToEdgeWrapping);
    mainMarkerTexture.generateMipmaps = false;
    mainMarkerTexture.magFilter = engineConstants.NearestFilter;
    mainMarkerTexture.minFilter = engineConstants.NearestFilter;

    // Create material
    const material = renderEngine.createMaterial('rapidMarkerRenderer');
    material.transparent = engineConstants.SuperGradual;
    material.blending = engineConstants.NormalBlending;
    const {uniforms} = material;
    uniforms.texture.texture = mainMarkerTexture;
    uniforms.scale.value = this._currentMarkerScale;
    this._setMaterialPixelSize(material);
    const isIn3d = SideMapViewSingleton.get().map._renderer.isIn3d();
    uniforms.in3d.value = isIn3d ? 1 : 0;
    let depth = -1.0 / this._markerZIndex;
    if (this._alwaysInFront) {
        depth -= depth;
        depth *= -0.1;
    }
    uniforms.depth.value = depth;

    // Request texture url
    mainMarkerTexture.onSuccess = _.bind(this._handleTextureReceived, this, url);
    renderEngine.askTextureToServer(mainMarkerTexture);

    return material;
};

proto.getMarkerSizeInPixels = function () {
    return this._markerSize * this._pixelRatio;
};

proto.update = function () {
    //TODO do update function cheaper ?
    this.hide();
    this.show();
};

proto._onPixelRatioChanged = function () {
    if (this.isMapCreated()) {
        const {map} = SideMapViewSingleton.get();
        const renderer = map._renderer;
        const renderEngine = renderer.getRenderEngine();
        this._pixelRatio = renderEngine._webGL._pixelRatio;
        _.each(this._markerMaterials, (material) => {
            this._setMaterialPixelSize(material);
        });
        this._recalculateMarkerScale();
    }
};

proto._setMaterialPixelSize = function (material) {
    material.uniforms.size.value = this.getMarkerSizeInPixels();
};

proto._handleTextureReceived = function (url) {
    if (this._isShown && url == ICON_URL) {
        this._addMeshToScene(this._allMarkersParent);
    }
};

proto._addMeshToScene = function (mesh) {
    if (this.isMapCreated()) {
        const {map} = SideMapViewSingleton.get();
        if (map) {
            map._renderer.getScene().add(mesh);
        }
    }
};

proto._handleZoomChanged = function () {
    if (this.isMapCreated()) {
        const renderer = SideMapViewSingleton.get().map._renderer;
        const isIn3d = renderer.isIn3d();
        this._markerMaterials[ICON_URL].uniforms.in3d.value = isIn3d ? 1 : 0;
        // Hide/show lines
        this._setLinesVisible(isIn3d);
        this._recalculateMarkerScale();
        const currentAdAtMouse = this._currentAdAtMouse;
        if (this._shouldUseMinimalData() && currentAdAtMouse) {
            this._handleMarkerMouseOut(currentAdAtMouse);
        }
    }
};

proto._handleCameraChanged = function () {
    this._screenPositionsDirty = true;
};

proto._handle3dChanged = function () {
    // Hide/show lines
    if (this.isMapCreated() && this.isShown()) {
        const isIn3d = SideMapViewSingleton.get().map._renderer.isIn3d();
        this._setLinesVisible(isIn3d);
        this._markerMaterials[ICON_URL].uniforms.in3d.value = isIn3d ? 1 : 0;
    }
};

proto.isShown = function () {
    return this._isShown;
};

proto.setSearchOptions = function (searchOptions) {
    this.searchOptions = searchOptions;
};

proto.setSearchCriteria = function (searchCriteria) {
    this.searchCriteria = searchCriteria;
    if (this._isShown && this._enabled) {
        const that = this;
        if (that.searchCriteria) {
            that._gridLayer.forEach(that._loadAllResults, that);
        } else {
            that._gridLayer.forEach(that._removeAllMarkersNow, that);
        }
    } else {
        this._searchCriteriaDirty = true;
    }
};

proto._onMapIdle = function () {
    if (!this._enabled) {
        return;
    }

    const tilesToUpdate = this._tilesToUpdate;
    _.each(tilesToUpdate, (callback, idx) => {
        _.defer(() => {
            if (callback) {
                delete tilesToUpdate[idx];
                callback();
            }
        });
    });
    if (this.isMapCreated()) {
        const cameraZoom = SideMapViewSingleton.get().map.getZoom();
        const gridZoom = this._getGridZoomForCameraZoom(cameraZoom);
        const oldGeoHashPrecision = this._geoHashPrecision;
        const newGeoHashPrecision = this._geoHashPrecision = gridZoom.geoHashPrecision;
        let forceRequest = false;
        const isMinimalRequest = (cameraZoom <= this._gridZoomForMinimalData);
        if (this._isMinimalRequest != isMinimalRequest) {
            this._isMinimalRequest = isMinimalRequest;
            forceRequest = true;
        }
        const gridLayer = this._gridLayer;
        if (gridLayer.zoom != gridZoom.zoom || forceRequest || oldGeoHashPrecision != newGeoHashPrecision) {
            gridLayer.zoom = gridZoom.zoom;
            gridLayer.forEach(this._loadAllResults, this);
            gridLayer.dirty = true;
        }
        this._recalculateMarkerScale();
    }
};

proto._isMoving = function () {
    const {map} = this._gridLayer;
    return map && map._renderer && map._renderer.isMoving();
};

proto._loadTiles = function (useMinimalData) {
    let tilesToProcess;
    let tilesCount = 0;
    let request;
    if (this.isMapCreated() && this._isShown && this.searchCriteria) {
        if (!this.asyncHelper) {
            this.asyncHelper = new AsyncHelper();
        }

        const searchCriteria = _.clone(this.searchCriteria);
        searchCriteria.tileKeys = [];
        tilesToProcess = this._pendingRequests;
        this._pendingRequests = {};
        const tilesIds = [];
        _.each(tilesToProcess, (pendingRequest) => {
            ++tilesCount;
            const tile = pendingRequest.tile;
            tile.aborted = false;
            tilesIds.push(tile.id);
            tile.abortRequest = _.partial(abort, tile);
            tile.useMinimalData = useMinimalData;
            const tileKey = tile.key;
            const gridZoomForMinimalData = this._gridZoomForMinimalData;
            if (useMinimalData && tileKey.z <= gridZoomForMinimalData
                || !useMinimalData && tileKey.z >= gridZoomForMinimalData) {
                searchCriteria.tileKeys.push(tile.id);
            }

        });
        if (searchCriteria.tileKeys.length == 0) {
            console.trace('no tileKeys found');
            return;
        }

        const searchOptions = _.extend({
            geoHashPrecision: this._geoHashPrecision,
        }, this.searchOptions);
        const {options} = this;
        const loader = useMinimalData ? options.loadMinimal : options.load;
        request = this.asyncHelper.doAsync({
            func: cb => loader(searchCriteria, searchOptions, cb),
            callback: (err, allResults) => {
                _.each(tilesIds, tileId => {
                    const tileRequest = tilesToProcess[tileId];
                    if (tileRequest) {
                        delete tileRequest.tile.abortRequest;
                        delete this._tilesToLoad[tileRequest.tile.id];
                    }
                });
                if (!err) {
                    _.each(tilesIds, (tileId, index) => {
                        const tileRequest = tilesToProcess[tileId];
                        if (tileRequest) {
                            const resultsByTile = allResults[index];
                            if (tilesToProcess[tileId]) {
                                this._handleTileMarkersCount(tileRequest.tile, resultsByTile);
                            }
                        }
                    });
                    this._recalculateMarkerScale();
                }
                _.each(tilesIds, (tileId, index) => {
                    const tileRequest = tilesToProcess[tileId];
                    if (tileRequest) {
                        const resultsByTile = !err && allResults[index];
                        if (tilesToProcess[tileId]) {
                            this._handleTileLoaded(tileRequest.tile, err, resultsByTile);
                        }
                    }
                });
                // create each tiles
            },
        });
    }

    function abort(tile) {
        delete tilesToProcess[tile.id];
        --tilesCount;
        if (tilesCount <= 0 && request) {
            request.abort();
            request = null;
        }
    }
};

proto._loadTile = function (tile) {
    this._tilesToLoad[tile.id] = tile;
    const tileRequest = {tile: tile};
    this._pendingRequests[tile.id] = tileRequest;
    if (this.isMapCreated() && this._isShown) {
        const cameraZoom = SideMapViewSingleton.get().map.getZoom();
        const useMinimalData = (cameraZoom <= this._gridZoomForMinimalData);
        this.loadTilesDebounced(useMinimalData);
    }
    return tileRequest;
};

proto._loadAllResults = function (tile) {
    delete this._tilesToUpdate[tile.id];
    this._abortLoadingTile(tile);
    this._loadTile(tile);
};

proto._handleTileMarkersCount = function (tile, shrunkRealEstateAds) {
    const ads = shrunkRealEstateAds.ads || [];
    tile.nbrMarkers += ads.length;
};

proto._handleTileLoaded = function (tile, err, shrunkRealEstateAds) {
    const gridLayer = this._gridLayer;
    if (err) {
        console.error('Error getting all ads', err);
        this._removeAllMarkersNow(tile);
    } else if (gridLayer.map && gridLayer.map._renderer) {
        const handler = _.bind(this._setRealEstateAds, this, tile, shrunkRealEstateAds);
        if (this._isMoving()) {
            this._tilesToUpdate[tile.id] = handler;
        } else {
            _.defer(handler);
        }
    }
};

proto._handleTile = function (tile) {
    // Tile created
    tile.nbrMarkers = 0;
    this._loadAllResults(tile);
    tile.on('released', () => {
        this._abortLoadingTile(tile);
        delete this._pendingRequests[tile.id];
        this._delayedRemoveAllMarkers(tile);
    });
};

proto.getGeometryIdxInfo = function (index) {
    return {
        geometryIdx: Math.floor(index / this._maxAdsPerGeometry),
        offsetInGeometry: index % this._maxAdsPerGeometry,
    };
};

proto._createMarkerData = function (url, tile, ads, parentMesh) {
    const markerData = {
        adDetails: {},
        adIds: [],
        geometries: [],
        lineGeometries: [],
        meshes: [],
        lineMeshes: [],
        iconUrl: url,
        count: 0,
        visibleCount: 0,
        anchorPosition: {x: tile.center.x, y: tile.center.y, z: 0},
        isMinimalData: false,
        tileId: tile.id,
    };

    const numberOfAds = _.size(ads);
    if (numberOfAds > 0) {
        // See if we need to create new arrays for the data
        // Use indexed data for marker geometry
        const useHeight = this._createGeometries(markerData, ads, parentMesh);

        const adDetails = markerData.adDetails = {};
        const adIds = markerData.adIds;
        let frontCounter = 0;
        let endCounter = numberOfAds - 1;
        const useMinimalData = tile.useMinimalData;
        for (let idxAd = 0; idxAd < numberOfAds; idxAd++) {
            // Create dummy id for ad if using minimal data
            let ad;
            if (useMinimalData) {
                const geoHashData = ads[idxAd];
                const latLon = ngeohash.decode(geoHashData);
                ad = {
                    id: markerData.tileId + '_' + idxAd,
                    lat: latLon.latitude,
                    lon: latLon.longitude,
                    isFakeId: true,
                };

            } else {
                ad = ads[idxAd];
                ad.isSmallMarker = true;
            }

            const visible = !(ad.id in this._alreadyHiddenDisplayedAds);
            let fullIndex;
            if (visible) {
                fullIndex = frontCounter;
                frontCounter++;
            } else {
                fullIndex = endCounter;
                endCounter--;
            }
            const indexGeometry = this.getGeometryIdxInfo(fullIndex);

            const positionArray = markerData.geometries[indexGeometry.geometryIdx]._positionArray;
            const lineGeometry = markerData.lineGeometries[indexGeometry.geometryIdx];
            const linePositionArray = lineGeometry ? lineGeometry._positionArray : null;

            const offsetInGeometry = indexGeometry.offsetInGeometry;
            const lonLat = this._convertLatLngTo900913(ad.lat, ad.lng || ad.lon);

            // Store ad data with graphical index
            adDetails[ad.id] = {
                index: fullIndex,
                ad,
                lonLat,
                screenRect: {},
                visible,
                markerData,
            };
            adIds[fullIndex] = ad.id;

            const randomX = useMinimalData ? Math.random() * 2000 - 1000 : 0;
            const randomY = useMinimalData ? Math.random() * 2000 - 1000 : 0;

            // Set position data
            const {anchorPosition} = markerData;
            const x = lonLat.lon - anchorPosition.x + randomX;
            const y = lonLat.lat - anchorPosition.y - randomY;
            const z = useHeight ? this._getMarkerHeightAtPosition(lonLat) : 0;

            // marker heads
            let positionOffset;
            for (let iv = 0; iv < NUMBER_OF_VERTICES_PER_MARKER; iv++) {
                positionOffset = (offsetInGeometry * NUMBER_OF_VERTICES_PER_MARKER * 3) + (iv * 3);
                positionArray[positionOffset + 0] = x;
                positionArray[positionOffset + 1] = y;
                positionArray[positionOffset + 2] = z;
            }

            // marker lines
            if (linePositionArray) {
                positionOffset = (offsetInGeometry * 2 * 3);
                linePositionArray[positionOffset + 0] = x;
                linePositionArray[positionOffset + 1] = y;
                linePositionArray[positionOffset + 2] = 0.0;
                linePositionArray[positionOffset + 3] = x;
                linePositionArray[positionOffset + 4] = y;
                linePositionArray[positionOffset + 5] = z;
            }
        }
        // Verify that the counters are coherent (bug otherwise)
        if (frontCounter != endCounter + 1) {
            console.error('Error filling all results geometry');
        }
        // Update count and visible count
        markerData.count = numberOfAds;
        markerData.visibleCount = frontCounter;
        this._screenPositionsDirty = true;
        this.updateGeometriesVisibility(markerData);
    } else {
        // Update count and visible count
        markerData.count = numberOfAds;
        markerData.visibleCount = numberOfAds;
    }
    return markerData;
};

proto._createGeometries = function (markerData, ads, parentMesh) {
    const url = markerData.iconUrl;
    const {map} = SideMapViewSingleton.get();
    const renderer = map._renderer;
    const renderEngine = renderer.getRenderEngine();
    const numberOfAds = ads.length;
    const lastNbrAds = numberOfAds % this._maxAdsPerGeometry;
    const nbrGeometry = Math.floor(numberOfAds / this._maxAdsPerGeometry) + 1;
    let lineGeometry = null;
    for (let gIdx = 0; gIdx < nbrGeometry; ++gIdx) {
        const geometry = new this._engine.Geometry();
        markerData.geometries.push(geometry);
        const mesh = renderEngine.createMesh(geometry, this._markerMaterials[url]);
        markerData.meshes.push(mesh);

        const geometryAdsNumber = (gIdx < nbrGeometry - 1) ? this._maxAdsPerGeometry : lastNbrAds;
        mesh.position.set(markerData.anchorPosition.x, markerData.anchorPosition.y, 0);
        parentMesh.add(mesh);

        geometry._positionArray = new Float32Array(geometryAdsNumber * NUMBER_OF_VERTICES_PER_MARKER * 3);
        const uvArray = geometry._uvArray = new Float32Array(geometryAdsNumber * NUMBER_OF_VERTICES_PER_MARKER * 2);
        const indexArray = geometry._indexArray = new Uint16Array(geometryAdsNumber * 6);

        // Create lines geometry
        if (!this._disableHeight) {
            lineGeometry = new this._engine.Geometry(this._engine.Constants.LINES);
            markerData.lineGeometries.push(lineGeometry);
            lineGeometry.fixedColor = new this._engine.Color(renderer.conf.get('pins.lineColor'));
            lineGeometry._positionArray = new Float32Array(geometryAdsNumber * 2 * 3);
            const lineMesh = renderEngine.createMesh(lineGeometry, this._lineMaterial);
            markerData.lineMeshes.push(lineMesh);
            lineMesh.position.set(markerData.anchorPosition.x, markerData.anchorPosition.y, 0);
            this._allLinesParent.add(lineMesh);
            lineGeometry.setPointsCount(geometryAdsNumber * 2);
        }

        // Add extra constant vertex data
        for (let ia = 0; ia < geometryAdsNumber; ia++) {
            const uvOffset = ia * NUMBER_OF_VERTICES_PER_MARKER * 2;
            const indexOffset = ia * 6;
            const vertexOffset = ia * NUMBER_OF_VERTICES_PER_MARKER;
            uvArray[uvOffset + 0] = 0;
            uvArray[uvOffset + 1] = 0;
            uvArray[uvOffset + 2] = 1;
            uvArray[uvOffset + 3] = 0;
            uvArray[uvOffset + 4] = 0;
            uvArray[uvOffset + 5] = 1;
            uvArray[uvOffset + 6] = 1;
            uvArray[uvOffset + 7] = 1;
            indexArray[indexOffset + 0] = vertexOffset + 0;
            indexArray[indexOffset + 1] = vertexOffset + 1;
            indexArray[indexOffset + 2] = vertexOffset + 2;
            indexArray[indexOffset + 3] = vertexOffset + 2;
            indexArray[indexOffset + 4] = vertexOffset + 1;
            indexArray[indexOffset + 5] = vertexOffset + 3;
        }
    }

    // Use height if disableHeight is false or using elevations
    let useHeight = false;
    if (renderer.isIn3d()) {
        useHeight = !this._disableHeight || (map.elevation && renderer.isIn3d());
    }

    return useHeight;
};

proto._convertLatLngTo900913 = function (lat, lng) {
    return {
        lon: mercatorProjection.convertLon4326To900913(lng),
        lat: mercatorProjection.convertLat4326To900913(lat),
    };
};

proto._getMarkerHeightAtPosition = function (lonLat) {
    if (this.isMapCreated()) {
        const {map} = SideMapViewSingleton.get();
        const mapRenderer = map._renderer;

        let height = 0.0;
        if (!this._disableHeight) {
            height = mapRenderer.conf.get('pins.minHeight');
            const buildingHeight = _.reduce(map.getLayerByType('buildings'), function (memo, buildingLayer) {
                const buildingHeight = buildingLayer.getBuildingHeightAt(lonLat);
                if (memo == null) {
                    return buildingHeight;
                } else if (null != buildingHeight) {
                    return Math.max(memo, buildingHeight);
                }
                return memo;
            }, null);

            if (buildingHeight != null) {
                height = Math.max(buildingHeight, height);
            }

        } else if (map.elevation) {
            const z = mapRenderer.findHeight({x: lonLat.lon, y: lonLat.lat});
            if (z != null) {
                height += z;
            }
        }
        return height;
    }
    return 0.0;
};

proto._elevationModeChanged = function () {
    // Add/remove elevation data to all points
    this._gridLayer.forEach(function (tile) {
        if (tile.markerData) {
            this._updateMarkerHeights(tile.markerData);
        }
    }, this);
};

proto._onTileElevationsUpdated = function (elevationTileKey, elevationBounds) {
    // Determine which points need to be updated in relation to the elevation bounds
    this._gridLayer.forEach(function (tile) {
        const tileBounds = tile.bounds;
        if (tileBounds.xMin < elevationBounds.xMax &&
            tileBounds.xMax > elevationBounds.xMin &&
            tileBounds.yMin < elevationBounds.yMax &&
            tileBounds.yMax > elevationBounds.yMin) {

            const {markerData} = tile;
            if (markerData) {
                this._updateMarkerHeights(markerData);
            }
        }

    }, this);
};

proto._updateMarkerHeights = function (markerData) {
    const map = SideMapViewSingleton.get().map;
    const useHeight = !this._disableHeight || (map.elevation && map._renderer.isIn3d());

    const {geometries} = markerData;
    if (geometries && geometries.length) {
        // Iterate over all visible markers
        let isDirty = false;
        for (let ia = 0; ia < markerData.count; ia++) {
            const adId = markerData.adIds[ia];
            const adInfo = markerData.adDetails[adId];
            const height = useHeight ? this._getMarkerHeightAtPosition(adInfo.lonLat) : 0;
            const geometryInfo = this.getGeometryIdxInfo(ia);
            const geometry = geometries[geometryInfo.geometryIdx];
            const positionArray = geometry._positionArray;
            const lineGeometry = markerData.lineGeometries[geometryInfo.geometryIdx];
            let linePositionArray = null;
            if (lineGeometry) {
                linePositionArray = lineGeometry._positionArray;
            }
            // Modify marker z value
            let positionIndex;
            for (let iv = 0; iv < NUMBER_OF_VERTICES_PER_MARKER; iv++) {
                positionIndex = (geometryInfo.offsetInGeometry * NUMBER_OF_VERTICES_PER_MARKER + iv) * 3 + 2;
                if (positionArray[positionIndex] != height) {
                    positionArray[positionIndex] = height;
                    isDirty = true;
                }
            }

            // Modify line top z value
            positionIndex = geometryInfo.offsetInGeometry * 2 * 3 + 5;

            if (linePositionArray && linePositionArray[positionIndex] != height) {
                linePositionArray[positionIndex] = height;
            }
            isDirty = geometry.__isPositionDirty || isDirty;
            geometry.__isPositionDirty = isDirty;
            if (lineGeometry) {
                lineGeometry.__isPositionDirty = isDirty;
            }
        }
    }
};

proto._setAlreadyDisplayedAdsTile = function (markerData, newRealEstateAdIdsToHide) {
    if (!markerData) {
        return;
    }

    const alreadyHiddenDisplayedAds = this._alreadyHiddenDisplayedAds;
    // Show ads if they are no longer hidden
    _.each(alreadyHiddenDisplayedAds, (ignored, realEstateAdId) => {
        if (!(realEstateAdId in newRealEstateAdIdsToHide)) {
            const adDetails = markerData.adDetails[realEstateAdId];
            if (adDetails) {
                this._setMarkerVisible(markerData, adDetails.index, true);
            }
        }
    });

    // Hide ads if they aren't already hidden
    _.each(newRealEstateAdIdsToHide, (ignored, realEstateAdId) => {
        if (!(realEstateAdId in alreadyHiddenDisplayedAds)) {
            const adDetails = markerData.adDetails[realEstateAdId];
            if (adDetails) {
                this._setMarkerVisible(markerData, adDetails.index, false);
            }
        }
    });
};

proto._setMarkerVisible = function (markerData, markerIndex, isVisible) {
    let adInfo;
    const visibleMarkersCount = markerData.visibleCount;
    if (isVisible) {
        // If we're making something visible and already everything is visible then there is a bug...
        if (visibleMarkersCount < markerData.count && markerIndex >= visibleMarkersCount) {

            // Swap marker data
            adInfo = this._swapAds(markerData, markerIndex, visibleMarkersCount);

            // Increase visible count after
            markerData.visibleCount++;
        }
    } else if (markerIndex < visibleMarkersCount) { // check that not already invisible

        // Decrease visible count before
        markerData.visibleCount--;

        // Swap marker data
        adInfo = this._swapAds(markerData, markerIndex, markerData.visibleCount);
    }

    if (adInfo) {
        adInfo.visible = isVisible;
    }
    this.updateGeometriesVisibility(markerData);
};

proto.updateGeometriesVisibility = function (markerData) {
    const maxAdsPerGeometry = this._maxAdsPerGeometry;
    const {geometries} = markerData;
    for (let i = 0; i < geometries.length; ++i) {
        const geometryInfo = this.getGeometryIdxInfo(markerData.visibleCount);
        const geometry = geometries[i];
        const lineGeometry = markerData.lineGeometries[i];
        if (i < geometryInfo.geometryIdx) {
            geometry.setPointsCount(maxAdsPerGeometry * NUMBER_OF_VERTICES_PER_MARKER);
            geometry.setIndexCount(maxAdsPerGeometry * 6);
            if (lineGeometry) {
                lineGeometry.setPointsCount(maxAdsPerGeometry * 2);
            }
        } else {
            const offsetInGeometry = geometryInfo.offsetInGeometry;
            geometry.setPointsCount(offsetInGeometry * NUMBER_OF_VERTICES_PER_MARKER);
            geometry.setIndexCount(offsetInGeometry * 6);
            if (lineGeometry) {
                lineGeometry.setPointsCount(offsetInGeometry * 2);
            }
        }
    }
};

proto._swapAds = function (markerData, index1, index2) {
    const {adIds, adDetails} = markerData;
    const ad1Id = adIds[index1];
    const ad1Info = adDetails[ad1Id];
    const indexInfo1 = this.getGeometryIdxInfo(index1);
    const indexInfo2 = this.getGeometryIdxInfo(index2);
    if (index1 != index2) {
        const {geometries} = markerData;
        const geometryIdx1 = indexInfo1.geometryIdx;
        const geometryIdx2 = indexInfo2.geometryIdx;
        const geometry1 = geometries[geometryIdx1];
        const geometry2 = geometries[geometryIdx2];
        const positionArray1 = geometry1._positionArray;
        const positionArray2 = geometry2._positionArray;
        if (positionArray1 && positionArray2) {
            const {lineGeometries} = markerData;
            const lineGeometry1 = lineGeometries[geometryIdx1];
            const lineGeometry2 = lineGeometries[geometryIdx2];
            const linePositionArray1 = lineGeometry1 ? lineGeometry1._positionArray : null;
            const linePositionArray2 = lineGeometry1 ? lineGeometry2._positionArray : null;

            // Get position indices and copy data from one marker
            let positionIndex1;
            let positionIndex2;
            let tmp;
            let iv;
            const offsetInGeometry1 = indexInfo1.offsetInGeometry;
            const offsetInGeometry2 = indexInfo2.offsetInGeometry;
            for (iv = 0; iv < NUMBER_OF_VERTICES_PER_MARKER * 3; iv++) {
                positionIndex1 = (offsetInGeometry1 * NUMBER_OF_VERTICES_PER_MARKER * 3) + iv;
                positionIndex2 = (offsetInGeometry2 * NUMBER_OF_VERTICES_PER_MARKER * 3) + iv;

                // Swap position data
                tmp = positionArray1[positionIndex1];
                positionArray1[positionIndex1] = positionArray2[positionIndex2];
                positionArray2[positionIndex2] = tmp;
            }
            geometry1.__isPositionDirty = true;
            geometry2.__isPositionDirty = true;

            // Swap line data
            for (iv = 0; iv < 2 * 3; iv++) {
                positionIndex1 = (offsetInGeometry1 * 2 * 3) + iv;
                positionIndex2 = (offsetInGeometry2 * 2 * 3) + iv;

                // Swap position data
                if (linePositionArray1) {
                    tmp = linePositionArray1[positionIndex1];
                    linePositionArray1[positionIndex1] = linePositionArray2[positionIndex2];
                    linePositionArray2[positionIndex2] = tmp;
                }
            }

            geometry1.__isPositionDirty = true;
            geometry2.__isPositionDirty = true;

            if (lineGeometry1) {
                lineGeometry1.__isPositionDirty = true;
            }
            if (lineGeometry2) {
                lineGeometry2.__isPositionDirty = true;
            }

            // Swap ads
            const ad2Id = adIds[index2];
            const ad2Info = adDetails[ad2Id];

            adIds[index1] = ad2Id;
            adIds[index2] = ad1Id;
            ad1Info.index = index2;
            ad2Info.index = index1;
        } else {
            let errorLog = 'bug #2035 occurred';
            errorLog += ' trying to swap ads empty: ';
            errorLog += ' positionArray1 is not null=' + Boolean(positionArray1 != null);
            errorLog += ' positionArray2 is not null=' + Boolean(positionArray2 != null);
            errorLog += ' indexInfo1.geometryIdx=' + geometryIdx1;
            errorLog += ' indexInfo2.geometryIdx=' + geometryIdx2;
            errorLog += ' markerData.geometries length=' + geometries.length;
            errorLog += ' isMapCreated=' + this.isMapCreated();
            errorLog += ' isShown=' + this._isShown;
            throw new Error(errorLog);
        }
    }
    // Return the ad at the specified index
    return ad1Info;
};

proto.addAdToHide = function (adId) {
    this._adsIdToHide[adId] = true;
    this._updateHiddenAds();
};

proto.removeAdToHide = function (adId) {
    const adsIdToHide = this._adsIdToHide;
    if (adsIdToHide[adId]) {
        delete adsIdToHide[adId];
        this._updateHiddenAds();
    }
};

proto.setAlreadyDisplayedAds = function (newRealEstateAdIdsToHide) {
    this.alreadyDisplayedAds = _.zipObject(newRealEstateAdIdsToHide, []);
    this._updateHiddenAds();
};

proto._updateHiddenAds = function () {
    const allNewRealEstateAdIdsToHide = _.extend({}, this.alreadyDisplayedAds, this._adsIdToHide);
    if (this.isShown()) {
        this._gridLayer.forEach(function (tile) {
            this._setAlreadyDisplayedAdsTile(tile.markerData, allNewRealEstateAdIdsToHide);
        }, this);
    }
    this._alreadyHiddenDisplayedAds = allNewRealEstateAdIdsToHide;
};

proto._abortLoadingTile = function (tile) {
    delete this._tilesToLoad[tile.id];
    delete this._tilesToUpdate[tile.id];
    if (tile.abortRequest) {
        tile.abortRequest();
    }
};

proto._releaseObjects = function (markerData) {
    const map = SideMapViewSingleton.get().map;
    if (map && map._renderer) {
        const renderEngine = map._renderer.getRenderEngine();
        if (markerData) {
            _.each(['meshes', 'lineMeshes'], key => {
                _.each(markerData[key], mesh => {
                    renderEngine.releaseObject(mesh);
                });
                markerData[key] = [];
            });
        }
    }
};

proto._removeAllMarkersNow = function (tile) {
    this._abortLoadingTile(tile);
    this._releaseObjects(tile.markerData);
    this._screenPositionsDirty = true;
};

proto._delayedRemoveAllMarkers = function (tile) {
    this._abortLoadingTile(tile);
    // Release geometry and mesh for markers
    this._oldZoom = tile.zoom;

    const {markerData} = tile;
    if (markerData && markerData.meshes.length) {
        markerData.tileId = tile.id;
        this._markerDataToDelete.push(markerData);
    }
    if (!tile.useMinimalData) {
        this._screenPositionsDirty = true;
    }
};

proto._setRealEstateAds = function (tile, shrunkRealEstateAds) {
    if (!this._isShown) {
        return;
    }

    if (_.every([
        this._pendingRequests,
        this._tilesToLoad,
        this._tilesToUpdate,
    ], _.isEmpty)) {
        this._removePreviousZoomMeshes();
    }
    this._removeAllMarkersNow(tile);
    // Create structures
    const ads = shrunkRealEstateAds.ads;
    tile.markerData = this._createMarkerData(ICON_URL, tile, ads, this._allMarkersParent);
    const {map} = SideMapViewSingleton.get();
    if (map) {
        map._renderer.forceRender();
    }
};

proto._removePreviousZoomMeshes = function () {
    const {map} = SideMapViewSingleton.get();
    if (map && map._renderer) {
        _.each(this._markerDataToDelete, markerData => {
            this._releaseObjects(markerData);
        });
    }
    this._screenPositionsDirty = true;
    this._markerDataToDelete = [];
};

proto._computeScaleDensity = function () {
    let scaleDensity = 1.0;
    if (!_.every([
        this._pendingRequests,
        this._tilesToLoad,
        this._tilesToUpdate,
    ], _.isEmpty)) {
        return this._scaleDensity;
    }

    let maxMarkersPerTile = 0;
    const zoom = SideMapViewSingleton.get().map.zoom;
    const tilesZoom = this._gridLayer.zoom;
    this._gridLayer.forEach(function (tile) {
        maxMarkersPerTile = Math.max(tile.nbrMarkers, maxMarkersPerTile);
    }, this);
    let density = maxMarkersPerTile / Math.pow(4, zoom - tilesZoom);

    maxMarkersPerTile = 0;
    let oldDensity = 0;
    const oldZoom = this._oldZoom;
    if (oldZoom) {
        _.each(this._markerDataToDelete, function (markerData) {
            maxMarkersPerTile = Math.max(markerData.count, maxMarkersPerTile);
        });
        oldDensity = maxMarkersPerTile / Math.pow(4, zoom - oldZoom);
        density = (density + oldDensity) * 0.5;
    }
    const lowMarkersPerTile = 25;
    const highMarkersPerTile = 2000;
    if (density < lowMarkersPerTile) {
        scaleDensity = 1.0 + (1 - (density / lowMarkersPerTile));
    } else if (density > highMarkersPerTile) {
        scaleDensity = 0.8;
    }
    return scaleDensity;
};

proto._recalculateMarkerScale = function () {
    if (this.isMapCreated()) {
        const scaleDensity = this._computeScaleDensity();
        this._scaleDensity = scaleDensity;
        const {map} = SideMapViewSingleton.get();
        const scale = Math.min(this._markerScaleHelper.getMarkerScaleForZoom(map.zoom) * scaleDensity, 1.0);
        this._currentMarkerScale = scale;
        // Set uniform in materials
        this._markerMaterials[ICON_URL].uniforms.scale.value = scale;
        if (map) {
            map._renderer.forceRender();
        }
    }
};

// eslint-disable-next-line complexity
proto._handleMouseEvent = function (mouseEvent) {
    let handler = null;
    this._lastMouseEvent = mouseEvent;
    let x = mouseEvent.clientX;
    let y = mouseEvent.clientY;
    const $window = $(window);
    const {map} = SideMapViewSingleton.get();
    const parentOffset = $(map.getDiv()).offset();
    x -= (parentOffset.left + $window.scrollLeft());
    y -= (parentOffset.top + $window.scrollTop());

    const mousePosition = {
        x, y,
    };
    const eventType = mouseEvent.type;
    if (!this._isMoving() && this.isMapCreated() && eventType != 'touchmove' && eventType != 'touchstart') {
        const cameraZoom = map.getZoom();
        const useMinimalData = (cameraZoom <= this._gridZoomForMinimalData);
        // Update screen positions if needed
        if (this._screenPositionsDirty) {
            this._adsInScreen = [];
            if (!useMinimalData) {
                this._calculateMarker2dRects();
            }
            this._screenPositionsDirty = false;
        }

        let adInfo;
        if (eventType == 'mousemove' || eventType == 'pointermove' || eventType == 'mouseover') {
            handler = this.handleMouseMove(mousePosition, useMinimalData);
        } else if (eventType == 'mouseup'
            || ((eventType == 'touchend' || eventType == 'pointerup')
                && !map._renderer.hasTouchMoved())) {
            // Check for click
            adInfo = this._getAdAtMousePosition(mousePosition);
            if ((adInfo && adInfo.visible)) {
                handler = {
                    handlePicking: _.bind(this._handleMarkerClick, this, adInfo),
                    zIndex: this._markerZIndex,
                };
            }
        } else if (eventType == 'mouseout' && this._currentAdAtMouse) {
            // Handle immediately a mouse out event
            this._handleMarkerMouseOut(this._currentAdAtMouse);
        }
    }
    return handler;
};

proto.handleMouseMove = function (mousePosition, useMinimalData) {
    let handler;
    const mapRenderer = SideMapViewSingleton.get().map._renderer;
    if (this._hoverEnabled) {
        // Check for hover
        const adInfo = this._getAdAtMousePosition(mousePosition);
        const currentAdAtMouse = this._currentAdAtMouse;
        if (adInfo && adInfo.visible && !useMinimalData) {
            mapRenderer.$parentNode.addClass(this._cursorClickable);
            if (adInfo != currentAdAtMouse) {
                // Return handled for mouse over with a z index
                handler = {
                    handlePicking: _.bind(this._handleMarkerMouseOver, this, adInfo),
                    zIndex: this._markerZIndex,
                };
            }

        } else if (currentAdAtMouse) {
            // Handle immediately a mouse out event
            this._handleMarkerMouseOut(currentAdAtMouse);
        }
    }
    return handler;
};

proto._calculateMarker2dRects = function () {
    this._adsInScreen = [];
    this._gridLayer.forEach(this._calculateMarker2dRectsForTile, this);
    _.each(this._markerDataToDelete, markerData => {
        this._calculateMarker2dRectsForMarkerData(markerData);
    });
};

proto._calculateMarker2dRectsForTile = function (tile) {
    this._calculateMarker2dRectsForMarkerData(tile.markerData);
};

proto._calculateMarker2dRectsForMarkerData = function (markerData) {
    if (!markerData || !markerData.geometries.length) {
        return;
    }
    // compute modelViewMatrix on CPU to fix precision issue in shader matrix multiplication

    const {map} = SideMapViewSingleton.get();
    const mapRenderer = map._renderer;
    const frustum = mapRenderer.getFrustumFull();
    const markerPixelSize = 0.5 * this.getMarkerSizeInPixels() * this._currentMarkerScale;
    const markerPixelTouchBorder = (this._markerTouchScaling - 1.0) * markerPixelSize;
    const anchorPosition = markerData.anchorPosition;
    const mapWidth = mapRenderer.width;
    const mapHeight = mapRenderer.height;
    const isIn3d = map._renderer.isIn3d();
    const in3dOffset = isIn3d ? 1 : 0;
    const position = this._vector3d;
    _.each(markerData.adDetails, adInfo => {
        const index = adInfo.index;
        const indexGeometry = this.getGeometryIdxInfo(index);
        const positionArray = markerData.geometries[indexGeometry.geometryIdx]._positionArray;
        if (positionArray) {
            const positionIndex = (indexGeometry.offsetInGeometry * NUMBER_OF_VERTICES_PER_MARKER * 3);
            this._vector3d.set(
                positionArray[positionIndex + 0] + anchorPosition.x,
                positionArray[positionIndex + 1] + anchorPosition.y,
                isIn3d ? positionArray[positionIndex + 2] + anchorPosition.z : 0);

            const xInFrustum = position.x >= frustum.xMin && position.x <= frustum.xMax;
            const yInFrustum = position.y >= frustum.yMin && position.y <= frustum.yMax;
            if (xInFrustum && yInFrustum) {
                const posScreen = mapRenderer.computeScreenPosInPixels(this._vector3d);
                const screenRect = adInfo.screenRect;
                screenRect.xMin = posScreen.x - markerPixelSize - markerPixelTouchBorder;
                screenRect.xMax = posScreen.x + markerPixelSize + markerPixelTouchBorder;
                screenRect.yMin = posScreen.y - (1.0 + in3dOffset) * markerPixelSize - markerPixelTouchBorder;
                screenRect.yMax = posScreen.y + (1.0 - in3dOffset) * markerPixelSize + markerPixelTouchBorder;
                if (!(posScreen.x < -markerPixelSize ||
                    posScreen.x > mapWidth + markerPixelSize ||
                    posScreen.y < -2.0 * markerPixelSize ||
                    posScreen.y > mapHeight)) {
                    this._adsInScreen.push(adInfo);
                }
            }
        }
    });
};

proto._getAdAtMousePosition = function (mousePosition) {
    let pickedAd = null;
    let minDistance = 999999999.0;

    const isInRect = function (rect, position) {
        return (rect.xMin <= position.x &&
            rect.xMax >= position.x &&
            rect.yMin <= position.y &&
            rect.yMax >= position.y);
    };

    _.each(this._adsInScreen, function (adInfo) {
        if (isInRect(adInfo.screenRect, mousePosition)) {
            const deltaX = 0.5 * (adInfo.screenRect.xMax + adInfo.screenRect.xMin) - mousePosition.x;
            const deltaY = 0.5 * (adInfo.screenRect.yMax + adInfo.screenRect.yMin) - mousePosition.y;

            const distance = (deltaX * deltaX + deltaY * deltaY);
            if (distance < minDistance) {
                pickedAd = adInfo;
                minDistance = distance;
            }
        }
    });

    return pickedAd;
};

proto._shouldUseMinimalData = function () {
    const cameraZoom = SideMapViewSingleton.get().map.getZoom();
    return (cameraZoom <= this._gridZoomForMinimalData);
};

proto._handleMarkerMouseOver = function (adInfo) {
    if (this._hoverEnabled && this.isMapCreated()) {
        this._currentAdAtMouse = adInfo;
        const useMinimalData = this._shouldUseMinimalData();
        if (!adInfo.ad.isFakeId && !useMinimalData) {
            this.emit('mouseOver', adInfo.ad);
        }
    }
};

proto._handleMarkerMouseOut = function () {
    const currentAdAtMouse = this._currentAdAtMouse;
    const previousAd = currentAdAtMouse && currentAdAtMouse.ad || null;
    this._currentAdAtMouse = null;
    SideMapViewSingleton.get().map._renderer.$parentNode.removeClass(this._cursorClickable);
    this.emit('mouseOut', previousAd);
};

proto._handleMarkerClick = function (adInfo, mouseEvent) {
    if (!this._clickEnabled || isSoldRealEstateAd(adInfo.ad)) {
        return;
    }

    let realEstateAd;
    if (adInfo) {
        realEstateAd = adInfo.ad;
    }

    // Backward compatibility if mouseEvent is not passed
    if (!mouseEvent) {
        mouseEvent = this._lastMouseEvent;
    }

    if (realEstateAd && !realEstateAd.isFakeId && this.isMapCreated()) {
        this.emit('click', realEstateAd, null, mouseEvent);
    }
};

proto.onMarkerDetailsClosed = function (realEstateAdId) {
    const currentAdAtMouse = this._currentAdAtMouse;
    if (currentAdAtMouse && currentAdAtMouse.id == realEstateAdId) {
        this._currentAdAtMouse = null;
    }
};

proto._getGridZoomForCameraZoom = function (cameraZoom) {
    let zoom = TILE_ZOOMS[0].requestZoom;
    let geoHashPrecision = 9;
    for (let i = 0; i < TILE_ZOOMS.length; i++) {
        const zoomInfo = TILE_ZOOMS[i];
        if (zoomInfo.zoom <= cameraZoom) {
            zoom = zoomInfo.requestZoom;
            geoHashPrecision = zoomInfo.geoHashPrecision;
        }
    }
    return {
        zoom,
        geoHashPrecision,
    };
};
