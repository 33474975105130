const template = require('./UnpublishButton.jade');
const AdsRequestManager = require('../../../AdsRequestManager');
const i18nMixin = require('../../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'UnpublishButton.',
            keys: [
                'text',
                'modalTitle',
                'modalDescription',
                'confirmMessage',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        shrunk: Boolean,
    },
    data() {
        return {
            modalDisplayed: false,
            processing: false,
        };
    },
    methods: {
        showModal() {
            this.modalDisplayed = true;
        },
        unpublishAd(cb) {
            this.processing = true;
            AdsRequestManager.setOnTheMarket(this.realEstateAd.id, false, (err, editedRealEstateAd) => {
                if (err) {
                    console.error(err);
                } else {
                    this.$emit('out-of-the-market', editedRealEstateAd);
                }
                this.processing = false;
                cb(err);
            });
        },
    },
    template: template(),
};
