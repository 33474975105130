const _ = require('lodash');
const MapApi = require('./MapApi');

const ProgrammeModels = require('./ProgrammeModels');

const TRANSPARENT_BUILDING = 0xffffffff;
const antiZFightCrop = 0.001;

module.exports = class ProgrammeModelsWithCrop extends ProgrammeModels {
    setMap(map) {
        if (this.map != map) {
            super.setMap(map);

            // Create camera animation if needed
            if (this.map) {
                this._setCropHeightModelBuilding();
            } else {
                this._unsetCropHeightModelBuilding();
            }
        }
    }

    _onAllLotsLoaded(programme, lots) {
        super._onAllLotsLoaded(programme, lots);
        _.each(this._lots, lot => {
            this._updateCropMaterialVisible(lot);
        });
        this._setCropHeightModelBuilding();
    }

    _updateCropMaterialVisible(lot) {
        lot.setColor(this._getLotColor(lot));
        const materialVisible = (this._getMaterialModelBuildingVisible(lot) && this._getCropModelBuildingVisible(lot));
        lot.setMaterialVisible(materialVisible);
    }

    _updateLot(lot, fade) {
        this._updateCropMaterialVisible(lot);
        super._updateLot(lot, fade);
    }

    _selectLot(lot) {
        if (this._selectedLotAdId != lot.id) {
            super._selectLot(lot);
            this._setCropHeightModelBuilding();
            this._updateLots();
        }
    }

    _unselectLot() {
        this._unsetCropHeightModelBuilding();
        this._updateModelBuilding();
        super._unselectLot();
        this._updateLots();
    }

    _updateModelBuilding() {
        if (_.get(this._model, 'model.mesh') && this._isSelected) {
            if (this._cropHeight) {
                this._model.model.setUniformColor(new MapApi.api.engine.Color(TRANSPARENT_BUILDING, false));
            } else {
                this._model.model.setUniformColor(null);
            }
        }
    }

    _updateLots() {
        if (this._facadesVisible) {
            this._setCropHeightModelBuilding();
        }
        this._updateModelBuilding();
        super._updateLots();
    }

    _unsetCropHeightModelBuilding() {
        if (this._model && this._model.model && this._cropHeight) {
            delete this._cropHeight;
            this._model.model.setCropAtHeight(null);
        }
        this._programmeModelsManager.setSceneryModelsVisible(true);
    }

    _setCropHeightModelBuilding() {
        const lot = this._lots[this._selectedLotAdId];
        if (lot && this._model && this._model.model
            && lot.cropHeightMax
            && this._cropHeight != lot.cropHeightMax) {
            this._cropHeight = lot.cropHeightMax;
            this._cropHeightMax = lot.cropHeightMax;
            this._cropHeightMin = lot.cropHeightMin;
            this._model.model.setCropAtHeight(this._cropHeight - antiZFightCrop);
            this._programmeModelsManager.setSceneryModelsVisible(false);
        }
    }

    _highlightLot(lot) {
        if (this._highlightedLotAdId != lot.id) {
            super._highlightLot(lot);
            this._updateLots();
        }
    }

    _unhighlightLot() {
        super._unhighlightLot();
        this._updateLots();
    }

    _getCropModelBuildingVisible(lot) {
        if (this._isLotHighlighted(lot)) {
            return true;
        }
        return !(this._cropHeight && lot.cropHeightMax && lot.cropHeightMin && lot.cropHeightMin >= this._cropHeightMax);

    }

    _getMaterialModelBuildingVisible(lot) {
        return _.get(this._model, 'model.mesh')
            && (this._isSelected || this._isLotSelected(lot) || this._isLotHighlighted(lot));
    }

    _setFacadesVisible(visible) {
        super._setFacadesVisible(visible);
        if (this._facadesVisible) {
            this._updateModelBuilding();
        }
    }
};
