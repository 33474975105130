const _ = require('lodash');
const async = require('async');
const AdFormattingHelper = require('../../../../common/AdFormattingHelper');
const Account = require('../../../authentication/Account');
const GoogleTagManager = require('../../../stats/GoogleTagManager');

module.exports = {
    updateMarketStatusProperties,
    getClosedByAccountInfo,
};

/**
 * Copy realEstateAd and apply properties changed by a market status change. Return this copy
 * @param {Object} realEstateAd - initial real estate ad
 * @param {Object} editedRealEstateAd - modifications returned by a market status change
 * @returns {Object} - updated copy of `realEstateAd`
 */
function updateMarketStatusProperties(realEstateAd, editedRealEstateAd) {
    const clonedAd = _.cloneDeep(realEstateAd);
    clonedAd.status = AdFormattingHelper.getStatusFromAd(editedRealEstateAd);
    if (editedRealEstateAd.modificationDate) {
        clonedAd.modificationDate = editedRealEstateAd.modificationDate;
    }
    clonedAd.userRelativeData.isClosedBySelf = clonedAd.status.closedByUser
        && (Account.getAuthenticatedAccountId() === editedRealEstateAd.closedBy);

    GoogleTagManager.sendEvent('toggleRealEstateAdMarketStatus', {
        realEstateAdId: clonedAd.id,
        agencyId: Account.getAuthenticatedAccountId(),
    });
    return clonedAd;
}

function getClosedByAccountInfo(accountId, callback) {
    async.waterfall([
        cb => {
            Account.getAccountsNames([accountId], (err, accountIdsNames) => {
                if (err) {
                    cb(err);
                } else {
                    cb(null, accountIdsNames[accountId]);
                }
            });
        },
        (name, cb) => {
            // the id of the account that disabled the ad will only be useful if we have to display the name
            // and the 'accountStatsViewer' role (as the link will redirect to the stats page)
            if (name && Account.hasRole('accountStatsViewer')) {
                Account.get(accountId, function (err, closedByAccount) {
                    if (err) {
                        cb(err);
                    } else {
                        cb(null, {
                            name,
                            accountIdOrNamespace: closedByAccount.namespace || closedByAccount.id,
                        });
                    }
                });
            } else {
                async.setImmediate(() => {
                    cb(null, {name});
                });
            }
        },
    ], callback);
}
