const {getClasses} = require('../directives/Bem');

// @vue/component
module.exports = {
    methods: {
        getBemClasses(element, modifiers = {}) {
            return getClasses(element, null, modifiers, {context: this});
        },
    },
};
