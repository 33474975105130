const template = require('./OpeningHours.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'OpeningHours.',
            keys: [
                'legend',
                'empty',
            ],
        }),
    ],
    props: {
        openingHours: {
            type: String,
            default: undefined,
        },
        inColumn: Boolean,
    },
    computed: {
        withLineBreak() {
            return /[\n\r]/.test(this.openingHours);
        },
        modifiers() {
            const {inColumn} = this;
            return {
                centered: inColumn,
                forceColumn: this.withLineBreak && inColumn,
            };
        },
    },
    template: template(),
};
