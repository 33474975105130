const $ = require('jquery');
const CompositeVueView = require('../../../vue/CompositeVueView');
const template = require('./editArticleView.jade');
const SearchByTravelTimeView = require('../../../views/SearchByTravelTimeView');
const BlogArticleForm = require('../../components/form/BlogArticleForm');

module.exports = class EditArticleView extends CompositeVueView {
    constructor(options) {
        super({
            template,
        });
        this.article = options.article;
        this.pageTitle = options.pageTitle;
    }

    show() {
        const view = this;
        // @vue/component
        const vueOptions = {
            components: {
                BlogArticleForm,
            },
            data() {
                return {
                    article: view.article,
                };
            },
            methods: {
                onSaveArticle(article) {
                    view.emit('saveArticle', article);
                },
                onOpenTimeTravelSearch(locationField) {
                    view._openTravelTimeSearch(locationField);
                },
            },
        };
        const $element = this.$element = this.createElement({
            pageTitle: this.pageTitle,
        });
        this.injectVueViews($element, vueOptions);
        $('body').find('.articlesHub__article-container').append($element);
    }

    _openTravelTimeSearch(locationField) {
        SearchByTravelTimeView.open({
            submitCallback: travelTimeZone => {
                locationField.setTravelTimeSearch(travelTimeZone);
            },
            defaultItem: locationField.getFirstTravelTimeItem(),
            parentView: this,
            requireAllFields: false,
            context: 'save',
        });
    }
};
