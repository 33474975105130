const _ = require('lodash');
//when editing this file, maintain sorting for related ads

const {PROPERTY_TYPES} = require('../common/constants/PropertyType');

const FLAT_AND_HOUSE = [
    PROPERTY_TYPES.FLAT,
    PROPERTY_TYPES.HOUSE,
];

const DEFAULT_USER_SEARCH = [
    PROPERTY_TYPES.HOUSE,
    PROPERTY_TYPES.FLAT,
    PROPERTY_TYPES.LOFT,
    PROPERTY_TYPES.CASTLE,
    PROPERTY_TYPES.TOWNHOUSE,
];

//sorted for related ads
const business = [
    PROPERTY_TYPES.BUILDING,
    PROPERTY_TYPES.SHOP,
    PROPERTY_TYPES.PREMISES,
    PROPERTY_TYPES.OFFICE,
];

//TODO factorize with kimono-importer/ImportDataHelper.js
//sorted for related ads
const housingSingle = [
    ...FLAT_AND_HOUSE,
    PROPERTY_TYPES.LOFT,
    PROPERTY_TYPES.TOWNHOUSE,
    PROPERTY_TYPES.CASTLE,
    PROPERTY_TYPES.BUILDING,
];

const programmeAndResidence = [
    PROPERTY_TYPES.PROGRAMME,
    PROPERTY_TYPES.RESIDENCE,
];

const DB_TO_FRENCH_SLUG = {
    [PROPERTY_TYPES.HOUSE]: 'maisonvilla',
    [PROPERTY_TYPES.FLAT]: 'appartement',
    [PROPERTY_TYPES.PARKING]: 'parking',
    [PROPERTY_TYPES.TERRAIN]: 'terrain',
    [PROPERTY_TYPES.BUILDING]: 'batiment',
    [PROPERTY_TYPES.CASTLE]: 'chateau',
    [PROPERTY_TYPES.LOFT]: 'loft',
    [PROPERTY_TYPES.OFFICE]: 'bureau',
    [PROPERTY_TYPES.PREMISES]: 'local',
    [PROPERTY_TYPES.SHOP]: 'commerce',
    [PROPERTY_TYPES.TOWNHOUSE]: 'hotel',
    [PROPERTY_TYPES.ANNEXE]: 'annexe',
    others: 'autres',
};

const FRENCH_SLUG_TO_DB = _.extend({
    parkingbox: PROPERTY_TYPES.PARKING, //for backward compatibility
    maison: PROPERTY_TYPES.HOUSE, //for backward compatibility
}, _.invert(DB_TO_FRENCH_SLUG));

const ALL_PROPERTY_TYPES = _.union(housingSingle, programmeAndResidence, business, [
    PROPERTY_TYPES.PARKING,
    PROPERTY_TYPES.TERRAIN,
]);
const propertyTypes = {
    business,
    programmeAndResidence,
    housingSingle,
    housing: _.union(housingSingle, programmeAndResidence),
    //sorted for related ads
    withEnergyDiagnostic: _.union(housingSingle, business),
    singleBuilt: _.union(housingSingle, business, [PROPERTY_TYPES.PARKING]),
    //sorted for related ads
    single: _.union(housingSingle, [PROPERTY_TYPES.TERRAIN, PROPERTY_TYPES.PARKING], business),
    housingAndBusiness: _.union(housingSingle, programmeAndResidence, business),
    all: ALL_PROPERTY_TYPES,
    FLAT_AND_HOUSE,
    FOR_USER_SEARCH: _.without(ALL_PROPERTY_TYPES, PROPERTY_TYPES.ANNEXE),
};

const PROPERTY_TYPES_FOR_CREDIT_SIMULATION = _.difference(ALL_PROPERTY_TYPES, [
    PROPERTY_TYPES.SHOP,
    PROPERTY_TYPES.OFFICE,
    PROPERTY_TYPES.PREMISES,
]);

_.each(propertyTypes, (propertyTypes) => {
    propertyTypes.push('unknown');
});

module.exports = _.extend({
    canSimulateCreditForPropertyType,
    DB_TO_FRENCH_SLUG,
    FRENCH_SLUG_TO_DB,
    DEFAULT_USER_SEARCH,
}, propertyTypes);

function canSimulateCreditForPropertyType(propertyType) {
    return _.includes(PROPERTY_TYPES_FOR_CREDIT_SIMULATION, propertyType);
}
