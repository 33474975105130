const template = require('./LineWithIconFirst.jade');

// @vue/component
module.exports = {
    props: {
        icon: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    template: template(),
};
