const $ = require('jquery');
const _ = require('lodash');
const template = require('./NewsletterForm.jade');
const FormUtils = require('../../fields/FormUtils');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        require('../../fields/vue/validationMixin')(),
        i18nMixin({
            keys: [
                'placeholder',
            ],
        }),
        require('./newsletterTranslateMixin'),
    ],
    computed: {
        emailErrorMessage() {
            return this.translate('formErrorMessages.email');
        },
    },
    methods: {
        subscribeToNewsletter() {
            if (this.validateForm()) {
                const $form = $(this.$refs.form);
                const userMail = _.get(FormUtils.readValues($form), 'newsLetter.subscribe');
                this.$emit('subscribeToNewsletter', userMail);
            }
        },
    },
    template: template(),
};
