const polyline = require('polyline');
const _ = require('lodash');
const MapApi = require('./MapApi');
const $ = require('jquery');
const parseLimit = require('../common/geometry/parseLimit');

module.exports = {
    getEncodedLimit,
    limitToBounds,
    getEncodeLimitFromBounds,
    getEncodeLimitFromBoundsForKarto,
};

const MIN_VIEW_SIZE_IN_PX = 20;

function limitToBounds(limit) {
    let polyLimit;
    try {
        polyLimit = parseLimit(limit);
    } catch (err) {
        console.error('error decoding limit to bounds', err);
    }
    const latLngBounds = new MapApi.api.LatLngBounds();
    if (polyLimit && polyLimit.coordinates && polyLimit.coordinates.length) {
        _.each(polyLimit.coordinates, function (coordinateArray) {
            _.each(coordinateArray, function (coordinate) {
                const latLng = new MapApi.api.LatLng(coordinate[1], coordinate[0]);
                latLngBounds.extend(latLng);
            });
        });
    }
    return latLngBounds;
}

function getEncodeLimitFromBounds(nw, ne, se, sw) {
    const limit = _createGeoJsonBounds(nw, ne, se, sw);
    if (limit && !_limitIsEmpty(limit)) {
        return _encodeLimit(limit);
    }
    return null;
}

function getEncodeLimitFromBoundsForKarto(nw, ne, se, sw) {
    const limit = _createGeoJsonBoundsForKarto(nw, ne, se, sw);
    if (limit && !_limitIsEmpty(limit)) {
        return _encodeLimit(limit);
    }
    return null;
}

function getEncodedLimit(map, overlayView, maxSize) {
    if (map && overlayView) {
        const limit = _computeFrustum(map, overlayView, maxSize);
        if (limit && !_limitIsEmpty(limit)) {
            return _encodeLimit(limit);
        }
    }
    return null;
}

function _encodeLimit(limit) {
    const path = _.map(limit.coordinates[0], function (lngLat) {
        return [lngLat[1], lngLat[0]];
    }).slice(0, 4);
    return polyline.encode(path);
}

function _limitIsEmpty(limit) {
    let previousEdge = null;
    let edge;
    const points = limit.coordinates[0];
    const l = points.length - 1;
    for (let i = 0; i < l; ++i) {
        edge = [points[i + 1][0] - points[i][0], points[i + 1][1] - points[i][1]];
        if (edge[0] === 0 && edge[1] === 0) {
            continue;
        }
        if (!previousEdge) {
            previousEdge = edge;
            continue;
        }
        const crossProduct = edge[0] * previousEdge[1] - edge[1] * previousEdge[0];
        if (crossProduct !== 0) {
            return false;
        }
    }

    return true;
}

function _computeFrustum(map, overlayView, maxSize) {
    if (!map || !map._renderer) {
        return null;
    }
    const $element = $(map.getDiv());
    const width = $element.width();
    const height = $element.height();

    // reduced scale frustum for size marker
    let right = width;
    let bottom = height;
    let left = 0;
    let top = 0;
    if (maxSize) {
        const halfMarkerWidth = maxSize.width * 0.5;
        if (width > halfMarkerWidth && height > halfMarkerWidth) {
            right -= halfMarkerWidth;
            bottom -= halfMarkerWidth;
            left = +halfMarkerWidth;
            top = +halfMarkerWidth;
        }
    }
    if (right - left <= MIN_VIEW_SIZE_IN_PX) {
        left = Math.floor(width * 0.5) - (MIN_VIEW_SIZE_IN_PX / 2);
        right = left + MIN_VIEW_SIZE_IN_PX;
    }
    if (bottom - top <= MIN_VIEW_SIZE_IN_PX) {
        top = Math.floor(height * 0.5) - (MIN_VIEW_SIZE_IN_PX / 2);
        bottom = top + MIN_VIEW_SIZE_IN_PX;
    }
    const projection = overlayView.getProjection();
    const noWrap = true;
    const nw = _roundLatLng(projection.fromContainerPixelToLatLng({x: left, y: top}, noWrap, null), noWrap);
    const ne = _roundLatLng(projection.fromContainerPixelToLatLng({x: right, y: top}, noWrap, null), noWrap);
    const se = _roundLatLng(projection.fromContainerPixelToLatLng({x: right, y: bottom}, noWrap, null), noWrap);
    const sw = _roundLatLng(projection.fromContainerPixelToLatLng({x: left, y: bottom}, noWrap, null), noWrap);
    return _createGeoJsonBounds(nw, ne, se, sw);
}

function _createGeoJsonBounds(nw, ne, se, sw) {
    const points = [];
    let positionSwitch;
    if (ne.lng() < nw.lng()) {
        positionSwitch = ne;
        ne = nw;
        nw = positionSwitch;
    }
    if (se.lng() < sw.lng()) {
        positionSwitch = se;
        se = sw;
        sw = positionSwitch;
    }

    points.push([nw.lng(), nw.lat()]);
    points.push([ne.lng(), ne.lat()]);
    points.push([se.lng(), se.lat()]);
    points.push([sw.lng(), sw.lat()]);
    points.push([nw.lng(), nw.lat()]);
    return {
        type: 'Polygon',
        coordinates: [
            points,
        ],
    };
}

function _createGeoJsonBoundsForKarto(nw, ne, se, sw) {
    const points = [];
    let positionSwitch;
    if (ne.lng < nw.lng) {
        positionSwitch = ne;
        ne = nw;
        nw = positionSwitch;
    }
    if (se.lng < sw.lng) {
        positionSwitch = se;
        se = sw;
        sw = positionSwitch;
    }

    points.push([nw.lng, nw.lat]);
    points.push([ne.lng, ne.lat]);
    points.push([se.lng, se.lat]);
    points.push([sw.lng, sw.lat]);
    points.push([nw.lng, nw.lat]);
    return {
        type: 'Polygon',
        coordinates: [
            points,
        ],
    };
}

function _roundValue(value, precision) {
    return Math.round(value * precision) / precision;
}

function _roundLatLng(latLng, noWrap) {
    const precision = 100000000000;
    return new MapApi.api.LatLng(_roundValue(latLng.lat(), precision), _roundValue(latLng.lng(), precision), noWrap);
}
