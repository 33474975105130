const _ = require('lodash');
const UrlHelper = require('../../common/UrlHelper');
const ApplicationPage = require('./ApplicationPage');
const SimpleView = require('../views/SimpleView');
const template = require('../templates/NeighborhoodGuide.jade');
const AsyncHelper = require('../utils/AsyncHelper');
const PlaceDetailsHelper = require('../PlaceDetailsHelper');
const SearchPlacesHelper = require('../SearchPlacesHelper');
const async = require('async');

const CONFIGURATION = {
    name: 'decouvrez-votre-futur-quartier',
    title: 'Découvrez votre futur quartier',
    isRegistrationRequired: false,
    scrollWindowToTopOnOpen: 'anchor',
    bodyClass: 'decouvrez-votre-futur-quartier',
    gtmCategory: 'DiscoveryContent',
};

module.exports = class NeighborhoodGuidePage extends ApplicationPage {
    constructor() {
        super(CONFIGURATION);
        this.asyncHelper = new AsyncHelper();
    }

    open(options) {
        this.options = _.extend(options, CONFIGURATION);
        super.open(options);
    }

    getMiddleViews() {
        return new SimpleView(template);
    }

    getUrlPattern() {
        return '/decouvrez-votre-futur-quartier';
    }

    _loadData(options, callback) {
        async.parallel([
            function (cb) {
                SearchPlacesHelper.getAllDepartments(function (error, departments) {
                    if (error) {
                        cb(error);
                    } else {
                        options = _.extend(options, {departments: departments});
                        cb();
                    }
                });
            },
            function (cb) {
                PlaceDetailsHelper.getEditorialCities(function (error, editorialDepartments) {
                    if (error) {
                        cb(error);
                    } else {
                        let editorialCities = [];
                        _.each(editorialDepartments, function (cities, department) {
                            _.each(cities, function (city) {
                                const cityData = {
                                    city,
                                    url: '/immobilier/' + UrlHelper.slugify(department) + '/' + UrlHelper.slugify(city),
                                };
                                editorialCities.push(cityData);
                            });
                        });
                        editorialCities = _.sortBy(editorialCities, function (data) {
                            return data.city;
                        });
                        options = _.extend(options, {editorialCities: editorialCities});
                        cb();
                    }
                });
            },
        ], function (err) {
            callback(err, options);
        });
    }
};
