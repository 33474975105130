module.exports = [
    {
        label: 'Adresse exacte avec numéro et rue',
        color: '#74f969',
        details: "L'adresse donnée contient un numéro et est parfaitement trouvée",
        value: {
            'detailPrecision.number': ['exact', 'good'],
            'detailPrecision.street': ['exact', 'good'],
            'detailPrecision.postalCode': ['exact', 'good', null],
            'detailPrecision.city': ['exact', 'good', 'arrondissement', null],
        },
    },
    {
        label: 'Numéro approximatif',
        color: '#b2f252',
        details: "On a tout trouvé mais le numéro n'est pas le bon",
        value: {
            'detailPrecision.number': ['approximate', 'partial'],
            'detailPrecision.street': ['exact', 'good'],
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Adresse exacte sans numéro mais avec rue',
        color: '#d0ee47',
        details: "L'adresse donnée ne contient pas de numéro et est parfaitement trouvée",
        value: {
            'detailPrecision.number': [null],
            'detailPrecision.street': ['exact', 'good'],
            'detailPrecision.postalCode': ['exact', 'good', null],
            'detailPrecision.city': ['exact', 'good', 'arrondissement', null],
        },
    },
    {
        label: 'Numéro non trouvé',
        color: '#eaea3d',
        details: 'On a tout trouvé sauf le numéro',
        value: {
            'detailPrecision.number': ['not-found'],
            'detailPrecision.street': ['exact', 'good'],
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Rue partielle',
        color: '#FFD760',
        details: "La ville et le code postal sont exacts, mais la rue n'est pas complète",
        value: {
            'detailPrecision.street': ['partial'],
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Rue approximative',
        color: '#fab738',
        details: 'La ville et le code postal sont exacts, mais la rue est différente',
        value: {
            'detailPrecision.street': ['approximate'],
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Rue non trouvée',
        color: '#aa1d1d',
        details: "La ville et le code postal sont exacts, mais pas la rue n'est pas trouvée",
        value: {
            'detailPrecision.street': ['not-found'],
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Adresse exacte sans numéro ni rue',
        color: '#aa1d1d',
        details: "L'adresse donnée ne contient ni numéro, ni rue et est parfaitement trouvée",
        value: {
            'detailPrecision.number': [null],
            'detailPrecision.street': [null],
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Ville approximative',
        color: '#aa1d1d',
        details: 'Le code postal est exact, mais pas la ville',
        value: {
            'detailPrecision.postalCode': ['exact', 'good'],
            'detailPrecision.city': ['approximate', 'partial', null, 'not-found'],
        },
    },
    {
        label: 'Code postal approximatif',
        color: '#aa1d1d',
        details: 'La ville est exacte, mais pas le code postal',
        value: {
            'detailPrecision.postalCode': ['approximate', 'partial', null, 'not-found'],
            'detailPrecision.city': ['exact', 'good', 'arrondissement'],
        },
    },
    {
        label: 'Ville et code postal approximatifs',
        color: '#aa1d1d',
        details: 'Double approximation',
        value: {
            'detailPrecision.postalCode': ['approximate', 'partial', null, 'not-found'],
            'detailPrecision.city': ['approximate', 'partial', null, 'not-found'],
        },
    },
    {
        label: 'Non trouvé',
        color: '#333',
        details: 'On a rien trouvé :(',
        value: {
            precision: ['not-found'],
        },
    },
    {
        label: 'Pas de géocodage',
        color: '#000',
        details: "Pas d'information de géocodage",
        value: {
            precision: [null],
        },
    },
];
