const template = require('./Link.jade');

// @vue/component
module.exports = {
    props: {
        href: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
    template: template(),
};
