const template = require('../templates/searchResumeView.jade');
const View = require('../views/View');
const _ = require('lodash');
const SearchTitleGenerator = require('../../common/SearchTitleGenerator');

module.exports = class SearchResumeView extends View {
    constructor(options) {
        super({
            template,
        });
        this.getTitleOptions = options.getTitleOptions;
        _.defaults(options, {
            hiddenOverflow: true,
        });
        this.saveSearchAllowed = options.saveSearchAllowed;
        this.hiddenOverflow = options.hiddenOverflow;
        this.showCatalogueLink = options.showCatalogueLink;
    }

    show() {
        super.show({
            context: this.context,
            saveSearchAllowed: this.saveSearchAllowed,
            hiddenOverflow: this.hiddenOverflow,
            showCatalogueLink: this.showCatalogueLink,
        });

        this.$element.find('#editFilters').on('click', _.bind(this.handleEditFiltersClick, this));
    }

    handleEditFiltersClick() {
        this.emit('editFiltersClick');
    }

    onSearchFiltersChanged(filters, catalogueUrl) {
        if (this.isShown()) {
            this.currentFilters = filters;
            const resumeTitle = this._getSearchResultTitle(filters);
            const resumeSubtitle = SearchTitleGenerator.getTitle(filters, 'searchCriteria');

            const $element = this.$element;
            $element.find('.searchResultTitle').empty().append(resumeTitle); //replace Title
            $element.find('.searchResultSubTitle')
                .html(resumeSubtitle)
                .attr('title', resumeSubtitle);
            $element.find('.searchResultSubTitleContainer').toggle(!_.isEmpty(resumeSubtitle)); //hide subtitle container if empty
            $element.find('.searchResultTitleContainer .editFiltersTitle')
                .toggleClass('neverShow', !_.isEmpty(resumeSubtitle)); //hide modify btn of title if subtitle
            if (_.isString(resumeTitle)) { // can it really be something else than is string?
                $element.find('.searchResultTitle').attr('title', resumeTitle);
            }
            $element.find('.catalogue-gmc').attr('href', catalogueUrl);
            $element.find('.catalogue-fda').attr('href', catalogueUrl + '&format=facebook');
        }
    }
    _getSearchResultTitle(filters) {
        return SearchTitleGenerator.getTitle(_.extend({}, this.getTitleOptions(), filters), 'h1');
    }
};
