const _ = require('lodash');
const template = require('./SenderActions.jade');
const getTranslationKeysMatchingContact = require('../getTranslationKeysMatchingContact');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ContactBlockWithTitle = require('./BlockWithTitle');
const LIVE_TOUR_TYPE = 'liveTour';
const PERSONALIZED_VISIT_TYPE = 'personalizedVisit';

// @vue/component
module.exports = {
    components: {
        ContactBlockWithTitle,
    },
    mixins: [
        i18nMixin({
            prefix: 'contactsPro.',
        }),
    ],
    props: {
        actionType: {
            type: String,
            required: true,
        },
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isPersonalizedVisit() {
            return this.liveTourOptions.type === PERSONALIZED_VISIT_TYPE;
        },
        senderActions() {
            return getTranslationKeysMatchingContact(this.contact, `${this.actionType}.subjects`);
        },
        liveTourOptions() {
            return this.contact.liveTour;
        },
        hasSenderActions() {
            return !_.isEmpty(this.senderActions);
        },
        keysForTranslation() {
            const {type: context, appointmentDate} = this.liveTourOptions;
            const day = DateFormatter.humanizeDay(appointmentDate);
            const time = DateFormatter.humanizeTime(appointmentDate);
            return {
                context,
                day,
                time,
            };
        },
    },
    methods: {
        isLiveTour(senderAction) {
            return senderAction === LIVE_TOUR_TYPE;
        },
    },
    template: template(),
};
