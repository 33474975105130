const _ = require('lodash');
const template = require('./PartnerServices.jade');
const {i18n: {translate}} = require('fack');
const {isDateInRange} = require('../../utils/checkDate');
const Ajax = require('../../utils/Ajax');
const moment = require('../../../common/momentFr');
const ServerConfig = require('../../ServerConfig');
const PropertyTypes = require('../../../common/PropertyTypes');
const {FINANCIAL_SERVICE_RANK} = require('../../data/partnerServices');

// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        services: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            financialServicePartner: undefined,
        };
    },
    computed: {
        title() {
            return translate('partnerServicesView.title');
        },
        availableServices() {
            const {financialServicePartner, services} = this;
            const partnerServices = _.concat(services, financialServicePartner || []);
            return _(partnerServices).filter(this.isServiceAvailable).sortBy('rank').value();
        },
        hasAvailableServices() {
            return this.availableServices.length > 0;
        },
    },
    created() {
        const loadingTimer = setTimeout(() => {
            this.displayComponent = true;
            this.isLoading = true;
        }, 1000);
        this.getFinancialServicePartner((err, financialServicePartner) => {
            if (err) {
                console.error(err);
            } else if (!_.isEmpty(financialServicePartner)) {
                const {name: partnerName, logo, url} = financialServicePartner;
                this.financialServicePartner = {
                    name: 'financialServicePartnerFundQuotation',
                    partnerName,
                    url,
                    logoPath: `${ServerConfig.config.financialUrl}/logo/${logo}`,
                    isAdCriteriaMatchingServicePreRequest: (realEstateAd) => {
                        return _.includes(PropertyTypes.housing, realEstateAd.propertyType);
                    },
                    rank: FINANCIAL_SERVICE_RANK,
                };
            }
            this.isLoading = false;
            clearTimeout(loadingTimer);
        });
    },
    methods: {
        getFinancialServicePartner(cb) {
            Ajax.request({
                method: 'GET',
                url: `${ServerConfig.config.financialUrl}/financial-service-partner`,
                disableErrorPage: true,
                callback: cb,
            });
        },
        isDateMatchingDateRange(currentDate, {startDate, endDate}) {
            return (startDate && endDate) ? isDateInRange(currentDate, {startDate, endDate}) : true;
        },
        isServiceAvailable(service) {
            const {startDate, endDate, isAdCriteriaMatchingServicePreRequest = _.stubTrue} = service;
            return isAdCriteriaMatchingServicePreRequest(this.realEstateAd)
                && this.isDateMatchingDateRange(moment.utc(), {startDate, endDate});
        },
    },
    template: template(),
};
