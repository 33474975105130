const UrlHelper = require('../UrlHelper');
const _ = require('lodash');
const {defaultBlogOptions, blogs} = require('./blogTagsData');

const PUBLIC_BLOG_OLD_PATHS = [
    '/preparez-votre-projet',
    '/guide-et-conseils',
    '/guide-et-conseils-immobiliers',
    '/guides-et-conseils-immobiliers',
];

const PUBLIC_BLOG_PATH = '/actualites-et-conseils-immobiliers';

_.each(blogs, function (blog) {
    blog = _.defaults(blog, defaultBlogOptions);
    _.each(blog.tags, function (tag) {
        tag.slug = UrlHelper.slugify(tag.name);
        tag.path = [blog];
        if (!tag.isHome) {
            tag.path.push(tag);
        }
        _.each(tag.subTags, function (subTag) {
            subTag.slug = UrlHelper.slugify(subTag.name);
            subTag.path = [blog, tag, subTag];
        });
    });
    blog.name = _.find(blog.tags, {isHome: true}).name;
    blog.slug = UrlHelper.slugify(blog.name);
});

module.exports = {
    blogs,
    PUBLIC_BLOG_OLD_PATHS,
    PUBLIC_BLOG_PATH,
};
