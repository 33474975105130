const Field = require('./Field');
const SelectPicker = require('../utils/SelectPicker');

module.exports = class SelectPickerField extends Field {
    constructor(...args) {
        super(...args);
        SelectPicker.enable(this.$element, {
            noneSelectedText: 'Aucune sélection',
        });
    }

    setDisabled(disabled) {
        super.setDisabled(disabled);
        this.refresh();
    }

    //call this when you add/delete an option
    refresh() {
        this.$element.selectpicker('refresh');
    }
};
