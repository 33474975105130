const GoogleTagManager = require('./stats/GoogleTagManager');
const {getShareContent} = require('./AdSharingHelper.js');

module.exports = {
    ad: shareAdByEmail,
};

function shareAdByEmail(realEstateAd) {
    const {title, sharedText} = getShareContent(realEstateAd);
    location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(sharedText)}`;
    GoogleTagManager.sendRealEstateAdShareEvent('email', realEstateAd);
}
