const _ = require('lodash');

const template = require('./PositionDataAddress.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const GeocodingPrecisionMatching = require('../../GeocodingPrecisionMatching');

const ACTION_BUTTONS = [
    'geocodeAd',
    'updateDistrictForAd',
    'computeBlurForAd',
    'indexAd',
];

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PositionDataAddress.',
            keys: [
                'desiredPositionTitle',
                'foundPositionTitle',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    computed: {
        canSeeExactPosition() {
            return _.get(this.realEstateAd, 'userRelativeData.canSeeExactPosition');
        },
        geocodingPrecision() {
            return GeocodingPrecisionMatching.findMatching(this.realEstateAd);
        },
        geocodingPrecisionColor() {
            return _.get(this.geocodingPrecision, 'color');
        },
        iconStyle() {
            const iconStyle = {};
            const {geocodingPrecisionColor} = this;
            if (geocodingPrecisionColor) {
                iconStyle.backgroundColor = geocodingPrecisionColor;
            }
            return iconStyle;
        },
        iconTitle() {
            let iconTitle = null;
            const {geocodingPrecision} = this;
            if (geocodingPrecision) {
                const {label, details} = geocodingPrecision;
                iconTitle = `${label} (${details})`;
            }
            return iconTitle;

        },
        realEstateAdId() {
            return this.realEstateAd.id;
        },
        realEstateAdPosition() {
            return this.realEstateAd.position;
        },
        positionTexts() {
            const {positionText, desiredPositionText, foundPositionText} = this;
            return _.compact([
                positionText,
                desiredPositionText,
                foundPositionText,
            ]);
        },
        positionTextTranslationContext() {
            let translationContext;
            const {realEstateAdPosition} = this;
            if (realEstateAdPosition) {
                const origin = _.get(realEstateAdPosition, 'origin');
                if (_.get(realEstateAdPosition, 'isManual')) {
                    translationContext = 'manual';
                } else if (_.includes(['flow', 'graphicModel'], origin)) {
                    translationContext = origin;
                }
            }
            return translationContext;
        },
        positionText() {
            let positionText;

            const {
                realEstateAdPosition,
                positionTextTranslationContext,
            } = this;

            if (positionTextTranslationContext) {
                const {
                    lat,
                    lon,
                } = realEstateAdPosition;
                positionText = {
                    htmlTitle: this.t('position', {
                        context: positionTextTranslationContext,
                    }),
                    description: this.t('coordinates', {
                        lat,
                        lon,
                    }),
                };
            }

            return positionText;
        },
        desiredPositionText() {
            let descriptionDesiredPositionText;
            const {desiredPositionTitle, realEstateAdPosition} = this;
            const query = _.get(realEstateAdPosition, 'query');

            if (query) {
                descriptionDesiredPositionText = formatPositionText(query);
            } else {
                const {
                    actualCity,
                    address,
                    city,
                    country,
                    postalCode,
                    rawCity,
                    street,
                } = this.realEstateAd;

                const cityAndActual = actualCity && actualCity != city ? `${actualCity} ou ${city}` : city;
                const displayedCity = rawCity ? `${rawCity} (${cityAndActual})` : cityAndActual;
                descriptionDesiredPositionText = formatPositionText({
                    street: street || address,
                    postalCode,
                    city: displayedCity,
                    country,
                });
            }

            return {
                htmlTitle: desiredPositionTitle,
                description: descriptionDesiredPositionText,
            };
        },
        foundPositionText() {
            const {foundPositionTitle, realEstateAdPosition} = this;
            const match = _.get(realEstateAdPosition, 'match');
            return {
                htmlTitle: foundPositionTitle,
                description: match,
            };
        },
        actionsTranslations() {
            return _(ACTION_BUTTONS)
                .map(action => [action, this.t(`actionsButtons.${action}`)])
                .fromPairs()
                .value();
        },
    },
    template: template(),
};

function formatPositionText({street, postalCode, city, country}) {
    return compactAndJoinStrings([
        street,
        compactAndJoinStrings([postalCode, city], ' '),
        country,
    ], ', ');
}

function compactAndJoinStrings(strings, separator) {
    return _(strings)
        .compact()
        .join(separator);
}
