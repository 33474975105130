const $ = require('jquery');
const template = require('./BootstrapModal.jade');

// @vue/component
module.exports = {
    props: {
        open: Boolean,
    },
    watch: {
        open(open) {
            if (!open) {
                this.hide();
            }
        },
    },
    mounted() {
        const $modal = this.getJqueryModalElement();
        $modal.modal();
        $modal.on('hidden.bs.modal', () => {
            this.$emit('close');
        });
    },
    destroyed() {
        this.hide();
    },
    methods: {
        hide() {
            const $modal = this.getJqueryModalElement();
            $modal.modal('hide');
        },
        getJqueryModalElement() {
            return $(this.$el);
        },
    },
    template: template(),
};
