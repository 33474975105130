const _ = require('lodash');

const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./Tribool.jade');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/withInternalValue'),
        require('./mixins/validatableMixin'),
        i18nMixin({
            prefix: 'Tribool.',
            keys: _.map([true, false], value => `default${value}Label`),
        }),
    ],
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        trueLabel: {
            type: String,
            default: undefined,
        },
        falseLabel: {
            type: String,
            default: undefined,
        },
        nullLabel: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            default: null,
        },
        disabled: Boolean,
    },
    computed: {
        finalTrueLabel() {
            return this.trueLabel || this.t('defaultTrueLabel');
        },
        finalFalseLabel() {
            return this.falseLabel || this.t('defaultFalseLabel');
        },
        options() {
            return [
                ..._.map([true, false], value => ({
                    label: this[`final${_.upperFirst(value)}Label`],
                    value,
                })),
                {
                    label: this.nullLabel,
                    value: null,
                },
            ];
        },
    },
    template: template(),
};
