const _ = require('lodash');
const async = require('async');
const {i18n: {translate}} = require('fack');

const SavedSearchesView = require('../search/SavedSearchesView');
const ApplicationPage = require('./ApplicationPage');
const AsyncHelper = require('../utils/AsyncHelper');
const SaveSearchView = require('../search/SaveSearchView');
const Urls = require('../Urls');
const SavedSearches = require('../search/SavedSearches');
const Account = require('../authentication/Account');
const EventPack = require('../utils/EventPack');
const CancelableCallback = require('../utils/CancelableCallback');
const LocationManager = require('../LocationManager');
const CriteriaListFormatter = require('../../common/CriteriaListFormatter');
const ModalMessage = require('../utils/ModalMessage');

module.exports = class SavedSearchesPage extends ApplicationPage {
    constructor(options) {
        options = _.defaults(options || {}, {
            isRegistrationRequired: false,
            title: 'Mes alertes',
            name: 'savedSearches',
            gtmCategory: 'Account',
            bodyClass: 'savedSearchesPage',
        });
        super(options);
        this.asyncHelper = new AsyncHelper();
        this._eventPack = new EventPack();
        this.savedSearchesCount = null;
        this.savedSearches = {};
        this.savedSearchesView = new SavedSearchesView();
        this._saveSearchView = new SaveSearchView();
    }

    getMiddleViews() {
        return this.savedSearchesView;
    }

    getUrlPattern() {
        return '/mes-alertes';
    }

    open(options) {
        super.open(options);
        this._callbacksToCancel = [];
        this.savedSearchesView.startLoading();
        this.getAllSavedSearches(_.bind(this._handleSavedSearches, this));
        this._eventPack.on(this.savedSearchesView, {
            editFrequency: _.bind(this._onEditSavedSearch, this),
            editCriteria: _.bind(this._onEditSavedSearchCriteria, this),
            deleteSaveSearch: _.bind(this._onDeleteSaveSearch, this),
            createSaveSearch: _.bind(this._onCreateSaveSearch, this),
        });
        this._eventPack.on(this._saveSearchView, {
            updateSearch: _.bind(this._handleUpdateSearch, this),
            removeSearch: _.bind(this.savedSearchesView.removeSavedSearch, this.savedSearchesView),
            savedSearchCreated: _.bind(this._handleAddSearch, this),
        });
        if (options.reactivatedSavedSearchId) {
            if (options.reactivatedSavedSearchErr) {
                ModalMessage.show({
                    title: translate('savedSearchReactivation.failedTitle'),
                    message: options.reactivatedSavedSearchErr,
                });
            } else {
                ModalMessage.show({
                    title: translate('savedSearchReactivation.success'),
                    message: translate('savedSearchReactivation.message'),
                    actionLink: {
                        href: '/mon-alerte/' + options.reactivatedSavedSearchId,
                        title: translate('savedSearchReactivation.openSavedSearch'),
                        target: '_self',
                    },
                });
            }
        }
    }

    getAllSavedSearches(cb) {
        const pendingCb = new CancelableCallback(cb);
        this._callbacksToCancel.push(pendingCb);
        async.auto({
            savedSearches: SavedSearches.getAll,
            withLocations: ['savedSearches', _.bind(_.rearg(this.getLocationsFromLocationNames, [1, 0]), this)],
        }, (err, {savedSearches} = {}) => {
            pendingCb(err, savedSearches);
            _.pull(this._callbacksToCancel, pendingCb);
        });
    }

    getLocationsFromLocationNames({savedSearches}, cb) {
        async.map(savedSearches, ({searchCriteria}, mapCb) => {
            if (searchCriteria.locationNames && !searchCriteria.locations) {
                LocationManager.addLocationNamesFromItemsInSearchCriteria(searchCriteria, mapCb);
            } else {
                async.nextTick(mapCb);
            }
        }, cb);
    }

    close() {
        ModalMessage.hide();
        _.invokeMap(this._callbacksToCancel, 'cancel');
        this._callbacksToCancel = [];

        this._saveSearchView.hide();
        this.asyncHelper.cancelAll();
        this._eventPack.removeAllListeners();
        super.close(arguments);
    }

    _handleSavedSearches(err, data) {
        if (err) {
            console.warn('_handleSavedSearches error : ', err);
            this.savedSearchesView.showErrorMessage();
        } else {
            this.savedSearches = _.zipObject(_.map(data, '_id'), data);
            _.each(this.savedSearches, function (searchData) {
                searchData.url = Urls.savedSearch.makeUrl(searchData);
            });
            this.savedSearchesView.showSavedSearches(data);
        }
    }

    _onEditSavedSearch(id) {
        const search = this.savedSearches[id];
        if (search) {
            this._saveSearchView.show({
                search,
                pitchKey: 'pitch',
                suffix: 'frequency',
                criteriaSummaryEnabled: true,
                criteriaModificationEnabled: false,
                frequencyModificationEnabled: true,
                modalType: 'frequency',
            });
        } else {
            console.error('No search found for id ' + id);
        }
    }

    _onEditSavedSearchCriteria(id) {
        const search = this.savedSearches[id];
        if (search) {
            this._showSaveSearchView(search);
        }
    }

    _onDeleteSaveSearch(id) {
        this._saveSearchView.removeWithConfirmation(id);
    }

    _onCreateSaveSearch() {
        this._showSaveSearchView();
    }

    _showSaveSearchView(search) {
        this._saveSearchView.showSaveSearchModificationPopup(search, 'saved-searches-alertpage');
    }

    _handleUpdate(err, updatedSearch, search, successCallback = _.noop) {
        if (_.isFunction(search)) {
            successCallback = search;
            search = {};
        }
        if (!err) {
            const updatedId = updatedSearch._id;
            const {_id: previousId, searchCriteria} = search;
            this.completeUpdatedSearch(updatedSearch, searchCriteria, () => {
                this.updateSavedSearches(updatedSearch, updatedId, previousId);
                successCallback(updatedSearch, previousId);
            });
        }
    }

    completeUpdatedSearch(updatedSearch, searchCriteria, callback) {
        searchCriteria = _.defaults(searchCriteria, updatedSearch.searchCriteria);
        _.assignIn(updatedSearch, {
            searchCriteria,
            url: Urls.savedSearch.makeUrl(updatedSearch),
            criteriaList: CriteriaListFormatter.getCapitalizedCriteria(searchCriteria),
        });
        this.getLocationsFromLocationNames({savedSearches: [updatedSearch]}, err => {
            if (err) {
                console.error('could not get locations for search ', err);
            }
            callback();
        });
    }

    updateSavedSearches(updatedSearch, updatedId, previousId) {
        this.savedSearches[updatedId] = updatedSearch;
        if (previousId != updatedId) {
            delete this.savedSearches[previousId];
        }
    }

    _handleUpdateSearch(err, updatedSearch, search) {
        this._handleUpdate(err, updatedSearch, search, _.bind(this.savedSearchesView.updateSavedSearch, this.savedSearchesView));
    }

    _handleAddSearch(err, addedSearch) {
        this._handleUpdate(err, addedSearch, _.bind(this.savedSearchesView.addSavedSearch, this.savedSearchesView));
    }

    _loadData(options, callback) {
        Account.getAccountAndCreateGuestIfNeeded((err) => {
            const reactivatedSavedSearchId = options.reactivatedSavedSearchId;
            if (!err && reactivatedSavedSearchId) {
                Account.reactivateSavedSearch(reactivatedSavedSearchId, (err, {success} = {}) => {
                    if (err || !success) {
                        options.reactivatedSavedSearchErr = translate('savedSearchReactivation.failed');
                    }
                    callback(null, options);
                });
            } else {
                callback(err, options);
            }
        });
    }

    parseUrl(url) {
        const parsedUrl = Urls.savedSearches.parseUrl(url);
        return {
            reactivatedSavedSearchId: _.get(parsedUrl, 'reactivation-alerte')
            || _.get(parsedUrl, 'reactivatedSavedSearchId'), // "reactivatedSavedSearchId" for compatibility
        };
    }
};
