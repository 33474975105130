const Ajax = require('../../utils/Ajax');
const ServerConfig = require('../../ServerConfig');

module.exports = {
    getRating,
};

function getRating(agencyId, callback) {
    Ajax.request({
        method: 'GET',
        url: `${ServerConfig.config.customerRatingsUrl}/ratings/${agencyId}`,
        returnJqXHR: true,
        disableErrorPage: true,
        callback,
    });
}
