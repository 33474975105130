const _ = require('lodash');
const {addPage} = require('../pages/PageManager');
const ApplicationConfig = require('./ApplicationConfig');
const Views = require('../views/Views');
const PlaceDiscoveryPage = require('../pages/PlaceDiscoveryPage');
const AccountStatsPage = require('../pages/AccountStatsPage');
const ContactUsPage = require('../pages/ContactUsPage');
const AgencyContactPage = require('../pages/AgencyContactPage');
const NeighborhoodGuidePage = require('../pages/NeighborhoodGuidePage');
const RealEstateSellOrRentSubmissionPage =
    require('../realEstatePropertyDescriptor/submission/RealEstateSellOrRentSubmissionPage');
const AccountManagementPage = require('../pages/AccountManagementPage');
const LostPasswordPage = require('../pages/LostPasswordPage');
const ArticlesHubPage = require('../blog/hub/ArticlesHubPage');
const ResetPasswordPage = require('../pages/ResetPasswordPage');
const AgencyInfoPage = require('../agencyInfoPage/AgencyInfoPage');
const AdvicePage = require('../pages/AdvicePage');
const SavedSearchesPage = require('../pages/SavedSearchesPage');
const UserRealEstateAdsPageFactory = require('../search/pageFactories/UserRealEstateAdsPageFactory');
const ArticlePage = require('../blog/article/ArticlePage');
const SearchLandingPage = require('../pages/SearchLandingPage');
const SitemapPage = require('../pages/SitemapPage');
const searchLandingPages = require('../../common/data/searchLandingPages');
const SimpleContentPage = require('../pages/SimpleContentPage');
const SimpleContentPagesPublic = require('../pages/SimpleContentPagesPublic');
const BlogHelper = require('../blog/BlogHelper');
const BlogPages = require('../blog/BlogPages');
const LoanSimulatorPages = require('../loanSimulators/LoanSimulatorPages');
const NewPropertyLandingPage = require('../pages/NewPropertyLandingPage');
const InvestNewPropertyLandingPage = require('../pages/InvestNewPropertyLandingPage');
const PublicHomePage = require('../homePage/PublicHomePage');
const RegionalPartnerHomePage = require('../homePage/RegionalPartnerHomePage');
const EmailStatusPage = require('../emailStatus/components/EmailStatusPage');
const SearchUrlPrefixes = require('../../common/SearchUrlPrefixes');
const AccountContactsPage = require('../pages/AccountContactsPage');
const ImportsPage = require('../pages/ImportsPage');
const RealEstateBuyEstimatorPage = require('../realEstatePropertyDescriptor/estimator/RealEstateBuyEstimatorPage');
const RealEstateRentEstimatorPage = require('../realEstatePropertyDescriptor/estimator/RealEstateRentEstimatorPage');
const RealEstateSellSubmissionPage = require('../realEstatePropertyDescriptor/submission/RealEstateSellSubmissionPage');
const RealEstateRentSubmissionPage = require('../realEstatePropertyDescriptor/submission/RealEstateRentSubmissionPage');
const regionalPartners = require('../../common/data/regionalPartners');
const SavedSearchSearchPageFactory = require('../search/pageFactories/SavedSearchSearchPageFactory');
const SavedSearchAdsPageFactory = require('../search/pageFactories/SavedSearchAdsPageFactory');
const UserDirectoryPage = require('../pages/UserDirectoryPage');
const AgencyProspectingMapPage = require('../../js/prospectingMapPage/AgencyProspectingMapPage');
const AccountAdsNotificationBoostsHistoryPage = require('../../js/notificationBoost/AccountAdsNotificationBoostsHistoryPage');

module.exports = {
    configureRoutes,
    createSavedSearchFactoryPage,
};

function getHeaderAndFooterViews({savedOptions, headerTemplate, isMobile}) {
    const HeaderView = _.get(savedOptions, 'headerView', require('../header/HeaderView'));
    const FooterView = _.get(savedOptions, 'footerView', require('../footer/FooterView'));

    const headerView = new HeaderView(_.extend({
        headerTemplate,
        headerTitleEnabled: isMobile,
        logoAlwaysEnabled: !isMobile,
        hasToDisplayFavorites: ApplicationConfig.hasToDisplayFavorites,
    }, savedOptions));
    const footerView = new FooterView();
    return {
        headerView,
        footerView,
    };
}

function configureRoutes(savedOptions, {defaultHeaderTemplate, isMobile, AdvancedSearchView}) {
    const headerTemplate = _.get(savedOptions, 'headerTemplate', defaultHeaderTemplate);

    const {headerView, footerView} = getHeaderAndFooterViews({
        savedOptions,
        headerTemplate,
        isMobile,
    });

    let advancedSearchView;
    if (AdvancedSearchView) {
        advancedSearchView = new AdvancedSearchView();
        headerView.setAdvancedSearchView(advancedSearchView);
    }

    _.extend(Views, {
        header: headerView,
        footer: footerView,
    });

    // todo: extract more common pages
    const accountStatsPage = new AccountStatsPage();
    addPage(accountStatsPage);
    addPage(new NeighborhoodGuidePage());
    addPage(new PlaceDiscoveryPage()); // must be after DiscoverPage and NeighborhoodGuidePage because of a url parsing bug
    addPage(new ContactUsPage());
    addPage(new AgencyContactPage());
    addPage(new RealEstateSellOrRentSubmissionPage());
    const myAccountManagementPage = new AccountManagementPage({
        myAccount: true,
    });
    addPage(myAccountManagementPage);
    addPage(new AccountManagementPage());
    addPage(new LostPasswordPage());
    addPage(new ResetPasswordPage());
    addPage(new AgencyInfoPage());
    addPage(new AdvicePage());
    addPage(new SavedSearchesPage());

    const userRealEstateAdsPageOptions = {
        filterEnabled: !isMobile,
        advancedFilterEnabled: !isMobile,
        keyScrollEnabled: !isMobile,
        showMap: !isMobile,
        disableSearchSideHeader: isMobile,
        mapFilterBtnEnabled: !isMobile,
        scrollWindowToTopOnOpen: !isMobile,
        advancedSearchView: isMobile ? advancedSearchView : null,
        moreFiltersVisible: !isMobile,
        mapButtonsSearchEnabled: true,
        dataModeListEnabled: !ApplicationConfig.isOnPartnersDomain,
        footerView,
    };
    const userRealEstateAdsPageFactory = new UserRealEstateAdsPageFactory(userRealEstateAdsPageOptions);
    addPage(userRealEstateAdsPageFactory);

    const amepiRealEstateAdsPageFactory = new UserRealEstateAdsPageFactory(_.extend({
        urlPrefixPattern: SearchUrlPrefixes.amepiPattern,
        name: 'amepiRealEstateAds',
        onlyExclusiveMandates: true,
        authorIdPrefix: 'fichier-amepi-',
    }, userRealEstateAdsPageOptions));
    addPage(amepiRealEstateAdsPageFactory);

    BlogPages.registerPages();

    const loanSimulators = {};
    _.each(LoanSimulatorPages, (page, pageName) => {
        const pageToAdd = new page();
        addPage(pageToAdd);
        loanSimulators[pageName] = pageToAdd;
    });

    _.each(SimpleContentPagesPublic, options => {
        addPage(new SimpleContentPage(options));
    });

    const partnerName = _.get(savedOptions, 'partnerName');
    let home;
    if (partnerName) {
        home = new RegionalPartnerHomePage({
            headerView,
            footerView,
            partnerName,
            url: '/',
        });
    } else {
        const HomePage = _.get(savedOptions, 'HomePage', PublicHomePage);
        home = new HomePage({headerView, footerView});
    }
    addPage(home);

    _.each(regionalPartners, (partnerName) => {
        addPage(new RegionalPartnerHomePage({headerView, footerView, partnerName}));
    });

    addPage(new NewPropertyLandingPage());
    addPage(new InvestNewPropertyLandingPage());

    const contactsPage = new AccountContactsPage();
    addPage(contactsPage);

    const accountAdsNotificationBoostsHistoryPage = new AccountAdsNotificationBoostsHistoryPage();
    addPage(accountAdsNotificationBoostsHistoryPage);

    //landingPage locations
    _.each(searchLandingPages, options => {
        addPage(new SearchLandingPage(options));
    });
    _.extend(Views, {
        articlesHubMenuViews: BlogHelper.createHubMenuViews(),
    });

    const emailStatusPage = new EmailStatusPage();
    addPage(emailStatusPage);

    addPage(new RealEstateBuyEstimatorPage());
    addPage(new RealEstateRentEstimatorPage());
    addPage(new RealEstateSellSubmissionPage());
    addPage(new RealEstateRentSubmissionPage());
    addPage(new ImportsPage());
    const userDirectoryPage = new UserDirectoryPage();
    addPage(userDirectoryPage);
    addPage(new SitemapPage());
    addPage(new AgencyProspectingMapPage());

    return {
        commonPages: _.extend({
            accountStatsPage,
            home,
            ArticlePage,
            ArticlesHubPage,
            contactsPage,
            userDirectoryPage,
            accountAdsNotificationBoostsHistoryPage,
        }, loanSimulators),
        commonPagesFactories: {
            userRealEstateAdsPageFactory,
        },
        footerView,
        advancedSearchView,
    };
}

function createSavedSearchFactoryPage({savedOptions, searchCommonOptions, searchPageOptions}) {
    let savedSearchAdsPageFactory;
    if (savedOptions.savedSearchAdsPageEnabled) {
        savedSearchAdsPageFactory = new SavedSearchAdsPageFactory(searchCommonOptions);
    } else {
        savedSearchAdsPageFactory = new SavedSearchSearchPageFactory(searchPageOptions);
    }
    addPage(savedSearchAdsPageFactory);
    return savedSearchAdsPageFactory;
}
