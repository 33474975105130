const template = require('../templates/account/authenticationPopup.jade');
const AuthenticationViewController = require('./AuthenticationViewController');
const _ = require('lodash');
const UrlHelper = require('../../common/UrlHelper');
const $ = require('jquery');
const ApplicationConfig = require('../app/ApplicationConfig');

module.exports = class AuthenticationPopup extends AuthenticationViewController {
    constructor() {
        super({
            template,
            $container: $('body'),
        });
    }

    getSections() {
        const {$element} = this;
        return _.extend({
            $body: $element.find('.modal-body .modalContainer'),
            $title: $element.find('.modal-title'),
        }, super.getSections());
    }

    showLogin(suggestedEmail) {
        this._showCommon();
        super.fillDomForLogin(suggestedEmail);
    }

    showCreateAccount(suggestedEmail) {
        this._showCommon();
        super.fillDomForCreateAccount(suggestedEmail);
    }

    _showCommon() {
        super.show({
            showSocialConnection: AuthenticationViewController.options.showSocialConnection,
            disableAccountCreation: ApplicationConfig.disableAccountCreation,
        });

        super.initEvents();
        const {$element} = this;
        $element.modal({keyboard: true});
        $element.on('hidden.bs.modal', () => {
            $element.find(':focus').blur(); //fix #812
            this.emit('close');
            super.hide();
            this.setURLOnClose();
        });
    }

    setURLOnClose() {
        const currentURL = document.location.href;
        const newURL = UrlHelper.removeURLParameter(currentURL, 'creer-mon-compte');
        const stateObj = {
            title: 'Toutes les annonces immobilières dans le neuf et l’ancien - Bien’ici',
            url: newURL,
        };
        window.history.pushState(stateObj, stateObj.title, stateObj.url);
    }

    hide() {
        if (this.isShown()) {
            // will call super.hide() through hidden.bs.modal event
            this.$element.modal('hide');
        }
    }
};
