const {getUserAgent, isAndroid} = require('browser-detect');

const androidVersionHelper = require('./android/versionsHelper');
const iosVersionHelper = require('./ios/versionsHelper');

module.exports = {
    isFromNativeApp,
    isFromNativeIosApp,
    isFromNativeAndroidApp,
    shouldEnableFeatureForAndroid,
    shouldEnableFeatureForIos,
};

function isFromNativeApp(ua) {
    return /kimono\/app/i.test(getUserAgent(ua));
}

function isFromNativeIosApp(ua) {
    return isFromNativeApp(ua) && !isFromNativeAndroidApp(ua);
}

function isFromNativeAndroidApp(ua) {
    return isFromNativeApp(ua) && isAndroid(ua);
}

function shouldEnableFeatureForAndroid(featureName, ua) {
    return !isFromNativeAndroidApp(ua) || androidVersionHelper.isEnabledFeatureForVersion(featureName, getUserAgent(ua));
}

function shouldEnableFeatureForIos(featureName, ua) {
    return !isFromNativeIosApp(ua) || iosVersionHelper.isEnabledFeatureForVersion(featureName, getUserAgent(ua));
}
