const {SAINT_PIERRE_AND_MIQUELON_PREFIX} = require('./Constants');

module.exports = {
    // localPhonePrefix: prefix
    '0590': '+590',
    '0594': '+594',
    '0596': '+596',
    '0262': '+262',
    '0263': '+262',
    '0269': '+262',
    '0508': SAINT_PIERRE_AND_MIQUELON_PREFIX,
    '0690': '+590',
    '0691': '+590',
    '0694': '+594',
    '0639': '+262',
    '0692': '+262',
    '0693': '+262',
    '0696': '+596',
    '0697': '+596',
    '07005': '+590',
    '07006': '+594',
    '07007': '+596',
    '07008': '+262',
    '07009': '+262',
    '09760': '+590',
    '09761': '+590',
    '09768': '+590',
    '09764': '+594',
    '09765': '+594',
    '09766': '+596',
    '09767': '+596',
    '09762': '+262',
    '09763': '+262',
    '09769': '+262',
};
