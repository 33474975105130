const _ = require('lodash');

const RealtimeServer = require('../../RealtimeServer');

const {SET_STATS} = require('../../store/Mutations');
const STATS_STORE = 'stats';

// @vue/component
module.exports = {
    constants: {
        IS_AGGREGATED_FOR_ACCOUNT: false,
    },
    extends: require('./BaseStats'),
    mixins: [
        require('./BaseStatsTemplate'),
    ],
    props: {
        realEstateAdId: {
            type: String,
            required: true,
        },
    },
    computed: {
        statsFromStore() {
            return this.$store.getters[`${STATS_STORE}/getStats`](this.realEstateAdId);
        },
        stats() {
            return this.makeStats(this.statsFromStore);
        },
    },
    watch: {
        realEstateAdId() {
            this.listenToRealEstateAdStatsUpdate();
        },
        stats() {
            this.emitStats();
        },
    },
    beforeDestroy() {
        this.closeRealtimeContext();
    },
    mounted() {
        this.listenToRealEstateAdStatsUpdate();
        if (this.stats) {
            this.emitStats();
        }
    },
    methods: {
        closeRealtimeContext() {
            if (this.realtimeContext) {
                this.realtimeContext.close();
            }
        },
        listenToRealEstateAdStatsUpdate() {
            this.closeRealtimeContext();
            this.realtimeContext = RealtimeServer.openContext();
            const {realtimeContext, realEstateAdId} = this;
            realtimeContext.on(`ad:stats:${realEstateAdId}`, this.setStats);
            realtimeContext.join(`ad#${realEstateAdId}:stats`);
        },
        setStats({stats}) {
            this.$store.commit(`${STATS_STORE}/${SET_STATS}`, {
                realEstateAdId: this.realEstateAdId,
                stats,
            });
        },
        emitStats() {
            this.$emit('update:stats', _.extend({}, this.statsFromStore));
        },
    },
};
