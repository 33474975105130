const _ = require('lodash');
const template = require('./Chart.jade');
const {resourceUrl, i18n: {translate}} = require('fack');
const isAcceptedEnergyPerformanceDiagnosticType = require('./isAcceptedEnergyPerformanceDiagnosticType');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/commonEnergyChartAndRating'),
    ],
    props: {
        type: {
            type: String,
            required: true,
            validator: isAcceptedEnergyPerformanceDiagnosticType,
        },
    },
    data() {
        return {
            energyDescriptionClass: this.type,
        };
    },
    computed: {
        classificationClass() {
            const {classification} = this;
            return classification ? 'note-' + classification.toLowerCase() : '';
        },
        imageClass() {
            return this.hasClassification ? '' : 'diagnostic-chart__image-background--not-applicable';
        },
        imageUrl() {
            return resourceUrl(`images/diagnostic/diagnostic-${this.type}-base.png`);
        },
        title() {
            return this.t('title');
        },
        unit() {
            return this.t('unit');
        },
        value() {
            const {info: {value}, classification} = this;
            return (_.isNumber(value) && (value < 1000)) ? value : classification;
        },
        highestEfficiencyDescription() {
            return this.t('highestEfficiencyDescription');
        },
        lowestEfficiencyDescription() {
            return this.t('lowestEfficiencyDescription');
        },
    },
    methods: {
        t(key) {
            return translate(`diagnosticModal.${this.type}.${key}`);
        },
    },
    template: template(),
};
