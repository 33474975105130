const {i18n: {translate}} = require('fack');
const template = require('./ReportAdButton.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ReportAdButton.',
            keys: [
                'title',
                'pitch',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            modalVisible: false,
        };
    },
    computed: {
        text() {
            return translate('detailedSheetView.reportBtn');
        },
    },
    template: template(),
};
