const template = require('./SearchSeoContainer.jade');

// @vue/component
module.exports = {
    props: {
        seoLinksCriteria: {
            type: Object,
            required: true,
        },
        seoContent: {
            type: String,
            required: true,
        },
    },
    template: template(),
};
