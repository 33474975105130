const _ = require('lodash');
const {EventEmitter} = require('events');

module.exports = CancelableCallback;

function CancelableCallback(func) {
    const emitter = new EventEmitter();
    const self = _.extend(wrapper, {
        canceled: false,
        cancel,
        on,
    });
    return self;

    function wrapper() {
        if (!self.canceled) {
            func.apply(this, arguments);
        }
    }

    function cancel() {
        if (!this.canceled) {
            this.canceled = true;
            emitter.emit('canceled');
        }
    }

    function on() {
        emitter.on.apply(emitter, arguments);
    }
}
