const template = require('./VerificationEditModal.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const internalValue = require('../../form/components/mixins/withInternalValue');
const PrivacyLink = require('./PrivacyLink');
const {initDossier} = require('../Dossier');
const rentalApplicationTrackingMixin = require('../../form/components/mixins/rentalApplicationTrackingMixin');

// @vue/component
module.exports = {
    name: 'VerificationEditModal',
    components: {
        PrivacyLink,
    },
    mixins: [
        i18nMixin({
            prefix: 'VerificationEditModal.',
            keys: [
                'modalTitle',
                'modalDescription',
                'modalFinalParagraph',
                'modalConfirmationMessage',
                'modalCheckboxText',
                'buttonCancel',
            ],
        }),
        internalValue,
        rentalApplicationTrackingMixin,
    ],
    props: {
        value: Boolean,
    },
    computed: {
        currentDossier() {
            return this.$store.getters['rentalApplication/currentDossier'];
        },
    },
    methods: {
        initializeDossier() {
            this.handleFolderModificationTracking();
            const {id: existingDossierId} = this.currentDossier;

            initDossier(existingDossierId, (err, dossier) => {
                if (err) {
                    console.error(err);
                }
                this.internalValue = false;
                this.$store.commit('rentalApplication/setCurrentDossier', dossier);
                this.$emit('init');
            });
        },
    },
    template: template(),
};
