const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./PhoneContactInfo.jade');
const TextFormatter = require('../../../common/TextFormatter');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PhoneContactInfo.',
            keys: [
                'cancelPhoneNumberText',
                'tollFreePhoneNumberText',
            ],
        }),
    ],
    props: {
        phoneToCall: {
            type: String,
            required: true,
        },
        phoneToDisplay: {
            type: String,
            required: true,
        },
        phoneToDisplayFromAd: {
            type: String,
            default: null,
        },
        reference: {
            type: String,
            default: null,
        },
        expirationDate: {
            type: String,
            default: null,
        },
        callTrackedPhoneNumberId: {
            type: String,
            default: null,
        },
    },
    computed: {
        rootTag() {
            return this.isMobile ? 'a' : 'div';
        },
        href() {
            return this.isMobile ? 'tel:' + this.phoneToCall : null;
        },
        isTollFreePhoneNumber() {
            return /^08\u00A0?0[0-5]/.test(this.phoneToDisplay);
        },
        formattedReference() {
            return TextFormatter.formatAndTranslateField(this.reference, 'reference');
        },
        formattedExpirationDate() {
            return TextFormatter.formatDetailedDate(this.expirationDate);
        },
        expirationDateText() {
            return this.t(
                'expirationDate',
                {expirationDate: this.formattedExpirationDate}
            );
        },
    },
    template: template(),
};
