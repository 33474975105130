const _ = require('lodash');

module.exports = {
    convertGteToNumber,
    convertGteToArray,
};

function convertGteToNumber(estimatorData) {
    return _.mapValues(estimatorData, _.partial(convertGteTo, _, 'Number'));
}

function convertGteToArray(estimatorData) {
    return _.mapValues(estimatorData, _.partial(convertGteTo, _, 'Array'));
}

function convertGteTo(value, type) {
    let newValue = value;
    if (_.has(value, 'gte')) {
        switch (type) {
            case 'Number':
                newValue = value.gte;
                break;
            case 'Array':
                newValue = [value.gte, null];
                break;
            default:
                console.log(`unknown type to convert gte to ${type}`);
                newValue = value;
                break;
        }
    }
    return newValue;
}
