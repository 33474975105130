const _ = require('lodash');

module.exports = intersects;

/**
 * Returns true when arrays intersection is not empty
 * - non-array and non-nil items are casted to array before comparison
 * - equality comparison uses SameValueZero
 * @param {...*} args
 * @returns {boolean}
 */
function intersects(...args) {
    return !_.some(args, _.isNil) && !_.isEmpty(_.intersection(..._.map(args, _.castArray)));
}
