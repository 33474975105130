const $ = require('jquery');
const _ = require('lodash');
const SelectPicker = require('../../utils/SelectPicker');
const template = require('./Select.jade');
const withInternalValue = require('./mixins/withInternalValue');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseFieldMixin'),
        withInternalValue,
    ],
    inheritAttrs: false,
    props: {
        // any type is allowed
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: undefined,
        },
        options: {
            type: Array,
            validator: areValidOptions,
            required: true,
        },
        multiple: Boolean,
    },
    computed: {
        canonicalOptions() {
            return _.map(this.options, option => {
                if (this.isOptGroup(option)) {
                    _.extend(option, {
                        options: _.map(option.options, formatOption),
                        key: JSON.stringify(option),
                    });
                } else {
                    option = formatOption(option);
                }
                return option;
            });
        },
        optionsValues() {
            return _.flatMap(this.canonicalOptions, option => {
                if (this.isOptGroup(option)) {
                    return _.map(option.options, 'value');
                } else {
                    return option.value;
                }
            });
        },
        validValue() {
            let validValue;
            if (this.multiple) {
                validValue = _.intersection(this.internalValue, this.optionsValues);
            } else if (_.includes(this.optionsValues, this.internalValue)) {
                validValue = this.internalValue;
            }
            return validValue;
        },
    },
    watch: {
        validValue(validValue) {
            if (!_.isEqual(this.internalValue, validValue)) {
                this.internalValue = validValue;
            }
        },
    },
    created() {
        this.internalValue = this.multiple ? this.value || [] : this.value;
    },
    mounted() {
        const $select = $(this.$refs.select);
        SelectPicker.enable($select, {dropdownAlignRight: 'auto'});
    },
    updated() {
        this.refresh();
    },
    methods: {
        refresh() {
            const $select = $(this.$refs.select);
            $select.selectpicker({
                title: this.finalPlaceholder,
            });
            $select.selectpicker('refresh');
        },
        isOptGroup(option) {
            return Boolean(option.options);
        },
    },
    template: template(),
};

function formatOption(option) {
    if (isRawOption(option)) {
        option = {label: option};
    }
    const {value, label} = option;
    return {
        value: ('value' in option) ? value : label,
        label,
        key: JSON.stringify(value),
    };
}

function areValidOptions(options) {
    return _.every(options, _.overSome(isRawOption, isOptionObject));
}

function isRawOption(option) {
    return _.isString(option) || _.isNumber(option);
}

function isOptionObject(maybeOption) {
    return _.isObject(maybeOption)
        && (!maybeOption.options || areValidOptions(maybeOption.options));
}
