const _ = require('lodash');
const template = require('./Collapsible.jade');
const i18nMixin = require('../mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'Collapsible.',
            keys: [
                {
                    'toggleActionIndicator.open': 'toggleActionOpenLabel',
                    'toggleActionIndicator.close': 'toggleActionCloseLabel',
                },
            ],
        }),
    ],
    props: {
        collapsed: Boolean,
        toggleable: Boolean,
        title: {
            type: String,
            default: undefined,
        },
        subtitle: {
            type: String,
            default: undefined,
        },
        link: {
            type: Object,
            default: undefined,
        },
        hideTitle: Boolean,
        compact: Boolean,
        variant: {
            type: String,
            default: '',
        },
    },
    computed: {
        modifiers() {
            const {toggleable, collapsed, hideTitle, compact} = this;
            return {
                toggleable: toggleable && !hideTitle,
                collapsed: !hideTitle && toggleable && collapsed,
                compact,
                [this.variant]: true,
            };
        },
        hasLink() {
            const {toggleable, link} = this;
            return !toggleable && link && !_.isEmpty(link);
        },
        toggleActionLabel() {
            const {collapsed, toggleActionOpenLabel, toggleActionCloseLabel} = this;
            return collapsed ? toggleActionOpenLabel : toggleActionCloseLabel;
        },
    },
    methods: {
        toggleActive() {
            const {toggleable, collapsed} = this;
            if (toggleable) {
                this.$emit('toggle', !collapsed);
            }
        },
    },
    template: template(),
};
