const fack = require('fack');
const ServerConfig = require('../ServerConfig');
const Ajax = require('./Ajax');

module.exports = {
    getJsonResource,
    resourceUrl,
};

function getJsonResource(name, data, callback) {
    return Ajax.request({
        url: fromResDomain(name + '.json'),
        data,
        disableErrorPage: true,
        serverErrorMessage: 'getJsonResource',
        callback,
    });
}

function resourceUrl(path) {
    return fromResDomain(fack.resourceUrl(path));
}

function fromResDomain(path) {
    return ServerConfig.config.resUrl + path.replace(/^\/*/, '/');
}
