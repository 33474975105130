const RealEstateEstimatorPage = require('./RealEstateEstimatorPage');

const SLUG = 'estimation';

module.exports = class RealEstateBuyEstimatorPage extends RealEstateEstimatorPage {
    constructor() {
        super({
            transactionType: 'buy',
        });
    }

    getCanonicalUrl() {
        let propertyName;
        switch (this.getPageContext()) {
            case 'flat':
                propertyName = 'appartement';
                break;
            case 'house':
                propertyName = 'maison';
                break;
            default:
                propertyName = 'immobiliere';
                break;
        }
        return `/${SLUG}-${propertyName}`;
    }

    getUrlPattern() {
        return new RegExp(
            '^/' + SLUG + '-(immobiliere|appartement|maison)'
        );
    }
};
