const Ajax = require('../utils/Ajax');
const ServerConfig = require('../ServerConfig');

module.exports = {
    getLocationFromIp,
};

function getLocationFromIp({timeout}, callback) {
    const url = ServerConfig.config.geoipUrl + '/city/client';
    return Ajax.request({
        url,
        contentType: 'application/json',
        disableErrorPage: true,
        timeout,
        callback,
    });
}
