const _ = require('lodash');
const template = require('./RealEstateAdNotificationBoostButton.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');
const ServerConfig = require('../../ServerConfig');
const Views = require('../../views/Views');
const RealEstateAdNotificationBoostHistory = require('./RealEstateAdNotificationBoostHistory');

// @vue/component
module.exports = {
    bemName: 'ad-notification-boost',
    components: {
        RealEstateAdNotificationBoostHistory,
    },
    mixins: [
        i18nMixin({
            prefix: 'RealEstateAdNotificationBoostButton.',
            keys: [
                'adNotificationBoostButtonText',
                'errorMessage',
                'successMessage',
                'history',
            ],
        }),
    ],
    props: {
        realEstateAdId: {
            type: String,
            required: true,
        },
        shrunk: Boolean,
    },
    data() {
        return {
            isModalOpened: false,
            hasSentBoostSucceeded: null,
            submitting: false,
            isLoading: false,
            boostData: {
                canBoost: false,
                numberOfRemainingBoosts: 0,
                adHistory: [],
            },
        };
    },
    computed: {
        modifiers() {
            return {
                'shrunk': this.shrunk,
            };
        },
        canBoost() {
            return this.boostData.canBoost;
        },
        numberOfRemainingBoosts() {
            return this.boostData.numberOfRemainingBoosts;
        },
        adHistory() {
            return this.boostData.adHistory;
        },
        modalTitle() {
            const context = this.canBoost ? {} : {context: 'error'};
            return this.t('modalTitle', context);
        },
        modalDescription() {
            const {canBoost, numberOfRemainingBoosts} = this;
            let context = {};
            if (!canBoost) {
                context = {context: numberOfRemainingBoosts > 0 ? 'boostSent' : 'outOfCredit'};
            }
            return this.t('modalDescription', context);
        },
        numberOfRemainingBoostsText() {
            return this.t('numberOfRemainingBoostsText', {count: this.numberOfRemainingBoosts});
        },
        showHistory() {
            return this.adHistory.length > 0;
        },
        historyContainerModifiers() {
            return _.pick(this, 'canBoost');
        },
    },
    methods: {
        openModal() {
            this.isLoading = true;
            Account.authAjax({
                method: 'GET',
                url: `${ServerConfig.config.adsNotificationBoostUrl}/real-estate-ad/${this.realEstateAdId}/boost`,
                disableErrorPage: true,
                serverErrorMessage: 'getBoostAdData',
                callback: (err, result) => {
                    this.isLoading = false;
                    if (err || !result.success) {
                        Views.volatileFeedback.showError(this.errorMessage);
                    } else {
                        this.isModalOpened = true;
                        this.boostData = result.data;
                    }
                },
            });
        },
        boostAd() {
            this.submitting = true;
            Account.postJson({
                url: `${ServerConfig.config.adsNotificationBoostUrl}/real-estate-ad/${this.realEstateAdId}/boost`,
                disableErrorPage: true,
                serverErrorMessage: 'boostAd',
                callback: (err, result) => {
                    this.isModalOpened = false;
                    this.submitting = false;
                    if (err || !result.success) {
                        Views.volatileFeedback.showError(this.errorMessage);
                    } else {
                        Views.volatileFeedback.showSuccess(this.successMessage);
                    }
                },
            });
        },
    },
    template: template(),
};
