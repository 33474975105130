const i18nMixin = require('../../vue/components/mixins/i18n');
const ContactInfoBlock = require('./ContactInfoBlock');
const template = require('./ContactBanner.jade');

const FORM_SUCCESS_DISPLAY_DURATION_IN_MS = 3000;

// @vue/component
module.exports = {
    components: {
        ContactInfoBlock,
    },
    mixins: [
        i18nMixin({
            prefix: 'PhoneAndContactForm.',
            keys: {
                success: 'successText',
            },
        }),
    ],
    props: {
        contact: {
            type: Object,
            default: null,
        },
        onTheMarket: Boolean,
        statisticsUrl: {
            type: String,
            default: undefined,
        },
        showFormSuccess: Boolean,
        onlineBookingUrl: {
            type: String,
            default: null,
        },
        onlineMeetingScheduleLink: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            formSuccessVisible: false,
        };
    },
    watch: {
        showFormSuccess(showFormSuccess) {
            this.formSuccessVisible = showFormSuccess;
        },
        formSuccessVisible(showFormSuccess) {
            if (showFormSuccess) {
                setTimeout(() => {
                    this.formSuccessVisible = false;
                }, FORM_SUCCESS_DISPLAY_DURATION_IN_MS);
            }
            this.$emit('update:show-form-success', showFormSuccess);
        },
    },
    template: template(),
};
