const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const Account = require('../authentication/Account');
const GeocodingStatsView = require('../views/GeocodingStatsView');
const PageManager = require('./PageManager');
const PagesFactories = require('./PagesFactories');
const DefaultConfiguration = require('../../common/DefaultConfiguration');

module.exports = class GeocodingStatsPage extends ApplicationPage {
    constructor() {
        const configuration = {
            name: 'geocodingStats',
            title: 'Statistiques de géocoding',
            isRegistrationRequired: true,
        };
        super(configuration);
        this.geocodingStatsView = new GeocodingStatsView();
        this.configuration = configuration;
        this.geocodingStatsView.on('openGeocodingSearch', this.onOpenGeocodingSearch);
    }

    open(options) {
        this.options = _.extend(options, this.configuration);
        super.open(options);
    }

    _loadData(options, callback) {
        if (this.isPageAccessAllowed()) {
            const data = {
                filters: JSON.stringify(_.map(this.geocodingStatsView.getFilters(), 'value')),
            };
            Account.authAjax({
                url: '/geocodingStats.json',
                data,
                serverErrorMessage: 'geocodingStats',
                callback: function (err, data) {
                    if (!err && data) {
                        options.geocodingStats = data;
                    }
                    callback(err, options);
                },
            });
        } else {
            callback();
        }
    }

    getMiddleViews() {
        return this.geocodingStatsView;
    }

    isPageAccessAllowed() {
        return Account.isAdmin();
    }

    getUrlPattern() {
        return '/geocoding-stats';
    }

    onOpenGeocodingSearch(data) {
        PageManager.openPage(PagesFactories.searchPageFactory, {
            search: {
                filterType: ['buy, rent'],
                propertyType: DefaultConfiguration.allPropertyTypes,
                onTheMarket: [true, false],
                newProperty: null, //null is both new and old
                geocoding: data.geocoding,
            },
        });
    }
};
