const Field = require('./Field');
const _ = require('lodash');

const RADIO_TO_COMPLEX_FILTERS = {
    buyNew: {
        buyNewPropertySetInFilterType: true,
        newProperty: true,
        filterType: 'buy',
    },
    buyTerrain: {
        propertyType: ['terrain'],
        filterType: 'buy',
        minRooms: null,
    },
};

module.exports = class FilterTypeRadioField extends Field {
    convertToSearch(filters) {
        _.extend(filters, RADIO_TO_COMPLEX_FILTERS[filters.filterType]);
    }

    convertFromSearch(search) {
        let filterType = search.filterType;
        if (search.newProperty) {
            if (filterType === 'buy') {
                filterType = 'buyNew';
            } else if (_.equals(search.propertyType, ['terrain'])) {
                filterType = 'buyTerrain';
            }
        }
        search.filterType = filterType;
    }
};
