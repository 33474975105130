const History = require('../utils/History');
const realEstateAdCreationProTemplate = require('../templates/realEstateAdCreationProView.jade');
const RealEstateAdCreationBaseView = require('./RealEstateAdCreationBaseView');
const Account = require('../authentication/Account');
const Router = require('../utils/Router');

module.exports = class RealEstateAdCreationProView extends RealEstateAdCreationBaseView {
    constructor() {
        super({
            template: realEstateAdCreationProTemplate,
            mode: 'pro',
        });
    }

    handleAccountChange() {
        if (!Account.isRegistered()) {
            const url = '/';
            History.pushState(null, null, url);
            Router.parseUrl(url);
        }
    }
};
