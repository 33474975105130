const _ = require('lodash');
const MapApi = require('./MapApi');

module.exports = {
    animateScale,
    updateIconScaledSize,
};

let animIdx = 0;

function animateScale(marker, {finalScale, initialScale, duration, easing}, onFinishedCbk) {
    const startAnimIdx = marker.animIdx = animIdx++;
    initialScale = initialScale != null ? initialScale : 1;
    marker.finalScale = finalScale;
    const isReverted = (initialScale > finalScale);
    const start = Date.now();
    if (isReverted) {
        [initialScale, finalScale] = [finalScale, initialScale];
    }
    animate();

    function animate() {
        const icon = marker.icon;
        const skipAnimation = (!marker.map || !marker.map.conf.enableWebGL || !marker.visible);
        const currentDuration = Date.now() - start;
        let scale = easing(currentDuration / duration, currentDuration, initialScale, finalScale, duration);
        if (_.isNaN(scale) || scale >= finalScale || skipAnimation || marker.animIdx != startAnimIdx) {
            scale = finalScale;
            delete marker.animIdx;
            if (onFinishedCbk) {
                onFinishedCbk();
            }
        } else {
            _.defer(animate);
        }
        updateIconScaledSize(icon, isReverted ? 1 - scale : scale);
        marker.icon_changed();
    }
}

function updateIconScaledSize(icon, scale) {
    if (!icon.originalSize) {
        icon.originalSize = icon.scaledSize || icon.size;
    }
    const size = icon.originalSize;
    icon.scaledSize = new MapApi.api.Size(size ? size.width * scale : 0, size ? size.height * scale : 0);
    icon.scale = scale;
}
