const _ = require('lodash');

module.exports = {
    generateCityAndDistrict,
    generateCityAndPostalCode,
    generateFullAddress,
};

function generateCityAndDistrict(ad) {
    if (_.get(ad, 'blurInfo.type') == 'postalCode') {
        return ad.postalCode;
    } else if (ad.isEstimationRequest || ad.isAdSubmissionRequest) {
        return ad.address;
    } else {
        let cityAndDistrict = generateCityAndPostalCode(ad);
        if (ad.district && ad.district.type_id == 1 && ad.district.libelle && ad.displayDistrictName) {
            cityAndDistrict += ` (${ad.district.libelle})`;
        }
        return cityAndDistrict;
    }
}

function generateCityAndPostalCode({city, postalCode}) {
    return _.compact([postalCode, city]).join('\u00A0');
}

function generateFullAddress(ad) {
    const cityAndDistrict = generateCityAndDistrict(ad);
    return (ad.street ? `${ad.street}, ` : '') + cityAndDistrict;
}
