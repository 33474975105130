const _ = require('lodash');
const $ = require('jquery');
const browserDetect = require('browser-detect');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const sessionStorage = require('./sessionStorage');
const SimpleContentWithAdsView = require('../views/SimpleContentWithAdsView');
const fullWidthCommercialAd = require('../templates/homePage/fullWidthCommercialAd.jade');
const {
    DISPLAY_MODE_GALLERY,
    DISPLAY_MODE_LIST,
    DISPLAY_MODE_MAP,
} = require('../search/constants');

const SAFEFRAME_ID = '#safeframeAd';
const MAP_ID = '#map';
const SEARCH_SIDE_VIEW_ID = '#searchSideView';
const SECTION_HEADER_CLASS = '.section-header';
const SEARCH_SIDE_HEADER_ID = '#searchSideHeader';
const SAFEFRAME_CONTAINER = 'safeFrameContentFullWidth';
const MOBILE_SAFEFRAME_CONTAINER_ID = '.sfMobileAd';
const HOME_PAGE_CONTAINER_CLASS = '.homePageContainer';
const SEARCH_SIDE_VIEW_CONTAINER_CONSTANT_WIDTH_CLASS = '.searchSideViewContainerConstantWidth';
const HP_FULL_HEIGHT_PANEL_CLASS = '.hpFullHeightPanel';
const SEARCH_FILTER_FORM_CONTAINER_CLASS = '.searchFilterFormContainer';
const DROPDOWN_MENU_CLASS = '.dropdown-menu';
const NAVIGATION_DRAWER_CLASS = '.navigationDrawer';
const SEARCH_RESULTS_CONTAINER_CONSTANT_WIDTH_CLASS = '.searchResultsContainerConstantWidth';
const SEARCH_SIDE_VIEW_CONTAINER_CLASS = '.searchSideViewContainer';
const ZINDEX_CLASS = 'zindexAfterSafeFrame';
const HIDDEN_SAFEFRAME_CLASS = 'hiddenMobileSafeFrame';
const COLLAPSED_SAFEFRAME_CLASS = 'collapsedSafeFrameAd';
const EXPANDED_SAFEFRAME_CLASS = 'expandedSafeFrameAd';
const SEARCH_RESULTS_CONTAINER_MARGIN_TOP = 'searchResultsContainerMarginTop';
const HP_MOBILE_SAFEFRAME_CONTAINER_CLASS = 'hpMobileSafeFrameContainer';
const AD_DISPLAYED_SESSION_ITEM = 'adDisplayed';
const APP_INSTALL_BANNER_SELECTOR = '.app-install-banner';

const CONTAINERS_MARGIN_TOP_CASES = {
    'collapse': COLLAPSED_SAFEFRAME_CLASS,
    'exp-ovr': EXPANDED_SAFEFRAME_CLASS,
};

let safeFrameHeight;
let searchMode;
let safeFrameAdState;

module.exports = {
    renderSafeFrameAdOnSearchResults,
    updateHomePageContainer,
    updateSearchPageMainContainers,
    updateStyleForMobileAdDisplay,
    updateDropdownStyle,
    resetSearchPageContainersStyle,
    resetHomePageContainer,
    removeAdAndResetSafeFrameRelatedStyles,
    setSearchModeAndUpdateStyle,
    updateResultsListMaxHeight,
    displaySafeFrameContainerOnMobile,
    geomUpdate,
    destroySafeFrame,
    areAllSelectorsPresent,
    handleAdEventsTracking,
    updatePageMainContainer,
};

function renderSafeFrameAdOnSearchResults(options, searchCriteria, name) {
    const safeFrameContainerSelector = getDefaultSaveFrameContainerSelector();
    $(safeFrameContainerSelector).empty();
    // don't display ad on user realEstate ads page
    if (name === 'search') {
        const piximediaAd = new SimpleContentWithAdsView({
            overTemplate: fullWidthCommercialAd,
            searchCriteria,
            pageName: 'searchResults',
        });
        piximediaAd.setContainer($('.' + safeFrameContainerSelector));
        piximediaAd.show(options);
    }
}

function updateHomePageContainer(size, state) {
    updateHomePagePanelMinHeight(size);
    updateElementMarginTop(HOME_PAGE_CONTAINER_CLASS, state);
}

function updateHomePagePanelMinHeight(size) {
    if (document.querySelector(APP_INSTALL_BANNER_SELECTOR)) {
        size = parseInt(size, 10) + document.querySelector(APP_INSTALL_BANNER_SELECTOR).offsetHeight + 'px';
    }
    $(HP_FULL_HEIGHT_PANEL_CLASS).css({'min-height': `calc(100% - ${size})`});
}

function updateSearchPageMainContainers(size, state) {
    safeFrameHeight = size;
    if (state) {
        safeFrameAdState = state;
    }
    if (browserDetect.isMobile()) {
        updateSearchSideViewCountAndSortBlock(size);
    } else {
        if (_.includes([DISPLAY_MODE_GALLERY, DISPLAY_MODE_LIST], searchMode)) {
            const headersTotalHeight = $(SECTION_HEADER_CLASS).height() + $(SEARCH_SIDE_HEADER_ID).height();
            $(SEARCH_SIDE_VIEW_ID).css({
                'top': Number(size.replace('px', '')) + headersTotalHeight + 'px',
            });
            addElementClasses(
                [SEARCH_SIDE_VIEW_CONTAINER_CONSTANT_WIDTH_CLASS, SEARCH_FILTER_FORM_CONTAINER_CLASS],
                [SEARCH_RESULTS_CONTAINER_MARGIN_TOP]
            );
        } else {
            updateElementMarginTop(SEARCH_SIDE_VIEW_ID, safeFrameAdState);
            updateElementMarginTop(MAP_ID, safeFrameAdState);
            SideMapViewSingleton.get().resizeMap();
        }
    }
}

function updateStyleForMobileAdDisplay() {
    if ($(HOME_PAGE_CONTAINER_CLASS).length > 0) {
        addElementClasses([MOBILE_SAFEFRAME_CONTAINER_ID], [HP_MOBILE_SAFEFRAME_CONTAINER_CLASS]);
        addElementClasses([NAVIGATION_DRAWER_CLASS], [ZINDEX_CLASS]);
    }
}

function updateDropdownStyle() {
    addElementClasses([DROPDOWN_MENU_CLASS], [ZINDEX_CLASS]);
}

function resetSearchPageContainersStyle() {
    removeElementClasses([MAP_ID, SEARCH_SIDE_VIEW_ID], [COLLAPSED_SAFEFRAME_CLASS, EXPANDED_SAFEFRAME_CLASS]);
    $(SEARCH_SIDE_VIEW_ID).css('top', '');
    removeElementClasses(
        [SEARCH_SIDE_VIEW_CONTAINER_CONSTANT_WIDTH_CLASS, SEARCH_FILTER_FORM_CONTAINER_CLASS],
        [SEARCH_RESULTS_CONTAINER_MARGIN_TOP]
    );
    if (browserDetect.isMobile()) {
        removeElementClasses([MOBILE_SAFEFRAME_CONTAINER_ID], [HIDDEN_SAFEFRAME_CLASS]);
        resetElementStyles([SEARCH_SIDE_VIEW_CONTAINER_CLASS, SEARCH_RESULTS_CONTAINER_CONSTANT_WIDTH_CLASS], ['position', 'top']);
    }
}

function resetHomePageContainer() {
    removeElementClasses([HOME_PAGE_CONTAINER_CLASS], [COLLAPSED_SAFEFRAME_CLASS, EXPANDED_SAFEFRAME_CLASS]);
    $(HP_FULL_HEIGHT_PANEL_CLASS).css({'min-height': ''});
    if (browserDetect.isMobile()) {
        removeElementClasses([MOBILE_SAFEFRAME_CONTAINER_ID], [HP_MOBILE_SAFEFRAME_CONTAINER_CLASS]);
    }
}

function removeAdAndResetSafeFrameRelatedStyles() {
    sessionStorage.setValue(AD_DISPLAYED_SESSION_ITEM, false);
    destroySafeFrame();
    safeFrameHeight = null;
    const safeFrameContainerSelector = getDefaultSaveFrameContainerSelector();
    $('.' + safeFrameContainerSelector).removeAttr('style');
    resetSearchPageContainersStyle();
    resetHomePageContainer();
    removeZindexClass();
    $(SEARCH_RESULTS_CONTAINER_CONSTANT_WIDTH_CLASS).css({
        'max-height': '',
    });
    SideMapViewSingleton.get().resizeMap();
}

function removeZindexClass() {
    removeElementClasses([NAVIGATION_DRAWER_CLASS, DROPDOWN_MENU_CLASS], [ZINDEX_CLASS]);
}

function destroySafeFrame() {
    const sf = getSafeFrameObject();
    const safeFrameContainerSelector = getDefaultSaveFrameContainerSelector();
    if (sf && sf.host) {
        sf.host.nuke('*');
    }
    $('.' + safeFrameContainerSelector).empty();
}

function setSearchModeAndUpdateStyle(mode) {
    searchMode = mode;
    if (safeFrameHeight) {
        resetSearchPageContainersStyle();
        updateSearchPageMainContainers(safeFrameHeight);
    }
}

function updateSearchSideViewCountAndSortBlock(size) {
    if (searchMode === DISPLAY_MODE_MAP) {
        addElementClasses([MOBILE_SAFEFRAME_CONTAINER_ID], [HIDDEN_SAFEFRAME_CLASS]);
    } else {
        const headerHeight = $(SECTION_HEADER_CLASS).height();
        removeElementClasses([MOBILE_SAFEFRAME_CONTAINER_ID], [HIDDEN_SAFEFRAME_CLASS]);
        updateResultsListMaxHeight();
        $(SEARCH_SIDE_VIEW_CONTAINER_CLASS).css({
            position: 'fixed',
            top: Number(size.replace('px', '')) + headerHeight + 'px',
        });
        $(SEARCH_RESULTS_CONTAINER_CONSTANT_WIDTH_CLASS).css({
            position: 'fixed',
            top: Number(size.replace('px', '')) + headerHeight + 'px',
        });
    }
}

function updateResultsListMaxHeight() {
    const sf = getSafeFrameObject();
    if (sf && browserDetect.isMobile() && sessionStorage.getValue(AD_DISPLAYED_SESSION_ITEM) && safeFrameHeight) {
        const headerHeight = $(SECTION_HEADER_CLASS).height();
        const heightToSubstract = Number(safeFrameHeight.replace('px', '')) + headerHeight + 'px';
        // update max height in order to enable scrolling of result list container
        $(SEARCH_RESULTS_CONTAINER_CONSTANT_WIDTH_CLASS).css({
            'max-height': `calc(100% - ${heightToSubstract})`,
        });
    }
}

function getDefaultSaveFrameContainerSelector() {
    return browserDetect.isMobile() ? MOBILE_SAFEFRAME_CONTAINER_ID.substring(1) : SAFEFRAME_CONTAINER;
}

function displaySafeFrameContainerOnMobile({selector, page, state}) {
    const safeFrameElement = document.querySelector(SAFEFRAME_ID);
    const safeFrameContainerSelector = selector || getDefaultSaveFrameContainerSelector();
    if (browserDetect.isMobile() && safeFrameElement) {
        const iframeHeight = safeFrameElement.style.height;
        updatePageMainContainer(page, iframeHeight, state);
        $('.' + safeFrameContainerSelector).css('visibility', 'visible');
    }
}

function updatePageMainContainer(page, iframeHeight, state) {
    if (page === 'home') {
        updateHomePageContainer(iframeHeight, state);
    } else if (page === 'searchResults') {
        updateSearchPageMainContainers(iframeHeight, state);
    }
}

function updateElementMarginTop(element, state) {
    const marginTopClass = CONTAINERS_MARGIN_TOP_CASES[state] || '';
    const classToRemove = _.values(_.omit(CONTAINERS_MARGIN_TOP_CASES, state))[0] || '';
    $(element)
        .removeClass(classToRemove)
        .addClass(marginTopClass);
}

function geomUpdate() {
    const sf = getSafeFrameObject();
    sf.lib.dom.msghost.send('safeframeAd', 'cmd=geom-update');
}

function getSafeFrameObject() {
    // eslint-disable-next-line no-undef
    if (typeof $sf !== 'undefined' && $sf) {
        // eslint-disable-next-line no-undef
        return $sf;
    }
}

function removeElementClasses(elements, classes) {
    elements.forEach(element => {
        $(element).removeClass(classes.join(' '));
    });
}

function addElementClasses(elements, classes) {
    elements.forEach(element => {
        $(element).addClass(classes.join(' '));
    });
}

function resetElementStyles(elements, styles) {
    elements.forEach(element => {
        styles.forEach(style => {
            $(element).css(style, '');
        });
    });
}

function areAllSelectorsPresent(page) {
    const selectors = getSelectorsForPage(page);

    for (const selector of selectors) {
        if (!document.querySelector(selector)) {
            return false;
        }
    }

    return true;
}

function getSelectorsForPage(page) {
    const genericSelectors = [SECTION_HEADER_CLASS, DROPDOWN_MENU_CLASS];
    const mobileGenericSelectors = [NAVIGATION_DRAWER_CLASS];

    let specificSelectors = [];

    switch (page) {
        case 'searchResults':
            specificSelectors = [
                SEARCH_SIDE_VIEW_ID,
                SEARCH_SIDE_HEADER_ID,
                SEARCH_SIDE_VIEW_CONTAINER_CONSTANT_WIDTH_CLASS,
                SEARCH_FILTER_FORM_CONTAINER_CLASS,
                SEARCH_RESULTS_CONTAINER_CONSTANT_WIDTH_CLASS,
                SEARCH_SIDE_VIEW_CONTAINER_CLASS,
                MAP_ID,
            ];
            break;

        case 'home':
            specificSelectors = [HOME_PAGE_CONTAINER_CLASS, HP_FULL_HEIGHT_PANEL_CLASS];
            break;
        default:
            break;
    }

    const allSelectors = _.concat(genericSelectors, specificSelectors);

    return browserDetect.isMobile() ? _.concat(allSelectors, mobileGenericSelectors) : allSelectors;
}

function handleAdEventsTracking(action) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'PixAd',
        action,
    });
}
