const _ = require('lodash');
const template = require('./baseInputMixin.jade');

// @vue/component
module.exports = {
    mixins: [
        require('./baseFieldMixin'),
        require('./withInternalValue'),
    ],
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: undefined,
        },
        autofocus: Boolean,
    },
    computed: {
        attributes() {
            return _.defaults({}, this.$attrs, this.extraAttributes);
        },
        extraAttributes() {
            return {}; // Override this computed to set dynamic attributes, $attrs is read-only.
        },
        unit() {
            return undefined; // Override this computed to set unit
        },
        hasLabel() {
            return Boolean(this.labelText || this.$slots.label);
        },
    },
    watch: {
        autofocus(newVal) {
            if (newVal) {
                this.$refs.input.focus();
            }
        },
    },
    methods: {
        onFocus() {
            this.$emit('focus');
        },
        onBlur() {
            this.$emit('blur');
        },
        onKeyDownEnter() {
            this.$emit('keyDownEnter');
        },
    },
    template: template(),
};
