const _ = require('lodash');
const MapApi = require('../MapApi');
const MapCreationHelper = require('./MapCreationHelper');
const {initializeAgencyMarker} = require('../poi/AgencyPoi');

module.exports = {
    createMap,
    createSimpleMap,
    getMapStats,
};

function createMap(options) {
    const node = options.$map.get(0);
    delete options.$map;
    createKimonoMap(node, options);
}

/**
 * Create a simple map. It uses low graphical level and default kimono-specific options
 * @param {node} node - the HTML node where the map will be appended
 * @param {MapApi.api.Map~MapOptions} mapOptions
 * @param {Object} advancedOptions - Additional options to customize the map, mainly POIs
 * @param {function} onMapCreatedCallback - callback called once map is created
 */
function createSimpleMap(node, mapOptions, advancedOptions, onMapCreatedCallback) {
    const options = _.extend({
        onMapCreatedCallback,
        level: 'low',
        getMapOptionsCallback: () => {
            return _.extend({
                // Controls from all kimono maps should be top left corner
                mapTypeControlOptions: {
                    position: MapApi.api.ControlPosition.TOP_LEFT,
                },
            }, mapOptions);
        },
    }, advancedOptions);
    createKimonoMap(node, options);
}

function createKimonoMap(node, options) {
    const poiInterfaceCreatedCallback = options.enablePoiAgencies ? initializeAgencyMarker : undefined;

    MapCreationHelper.createMapHelper(node, _.extend({
        poiInterfaceCreatedCallback,
    }, options));
}

function getMapStats(map) {
    return MapCreationHelper.getMapStats(map);
}
