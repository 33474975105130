const _ = require('lodash');
const template = require('./Contact.jade');
const accountFormTranslate = require('../../utils/accountForm/accountFormTranslate');
const shouldRoleDisplayOverridePhoneInAdsCheckbox = require('../../utils/accountForm/shouldRoleDisplayOverridePhoneInAdsCheckbox');
const getNameForContactField = require('../../utils/accountForm/getNameForContactField');
const internalValues = require('../../form/components/mixins/internalValues');

// @vue/component
module.exports = {
    mixins: [
        internalValues({
            props: [
                'firstName',
                'lastName',
                'phone',
                'email',
                'role',
                'overridePhoneInAds',
                'alwaysReceiveCarbonCopy',
            ],
        }),
    ],
    props: {
        firstName: {
            type: String,
            default: undefined,
        },
        lastName: {
            type: String,
            default: undefined,
        },
        phone: {
            type: String,
            default: undefined,
        },
        email: {
            type: String,
            default: undefined,
        },
        overridePhoneInAds: Boolean,
        alwaysReceiveCarbonCopy: Boolean,
        id: {
            type: String,
            required: true,
        },
        role: {
            type: String,
            default: undefined,
        },
        roles: {
            type: Array,
            default: () => [],
        },
        displayRoleField: Boolean,
    },
    data() {
        return {
            oldCheckboxState: false,
        };
    },
    computed: {
        displayOverridePhoneInAdsCheckbox() {
            const roles = this.internalRole ? [this.internalRole] : this.roles;
            return _.some(roles, shouldRoleDisplayOverridePhoneInAdsCheckbox);
        },
        displayAlwaysReceiveCarbonCopyCheckbox() {
            const roles = this.role ? [this.role] : this.roles;
            return !_.includes(roles, 'report');
        },
    },
    watch: {
        displayOverridePhoneInAdsCheckbox(newValue, oldValue) {
            if (!newValue && oldValue) {
                this.oldCheckboxState = this.internalOverridePhoneInAds;
                this.internalOverridePhoneInAds = false;
            } else if (newValue && !oldValue) {
                this.internalOverridePhoneInAds = this.oldCheckboxState;
            }
        },
    },
    methods: {
        getNameForField(fieldName) {
            return getNameForContactField(fieldName, this.id);
        },
        accountFormTranslate,
        getNameForContactField,
    },
    template: template(),
};
