const _ = require('lodash');
const template = require('./Badge.jade');

const SIZES = [
    'small',
    'big',
];
const VARIANTS = [
    'primary',
    'secondColor',
    'secondary',
    'main',
    'success',
];

// @vue/component
module.exports = {
    bemName: 'KimonoBadge',
    props: {
        variant: {
            type: String,
            default: null,
            validator: variant => _.includes(VARIANTS, variant),
            values: VARIANTS,
        },
        size: {
            type: String,
            default: null,
            validator: size => _.includes(SIZES, size),
            values: SIZES,
        },
        slight: Boolean,
        color: {
            type: String,
            default: null,
        },
    },
    computed: {
        modifiers() {
            const {size, variant, slight} = this;
            return {
                [variant]: true,
                [size]: true,
                slight,
            };
        },
        backgroundColorStyle() {
            return {backgroundColor: this.color};
        },
    },
    template: template(),
};
