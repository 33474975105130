const _ = require('lodash');
const UrlHelper = require('../../../common/UrlHelper');

module.exports = function getNewPropertiesSearchUrlAroundPrice(price, extraParameters) {
    return UrlHelper.addURLParameters('/recherche/achat/france', _.extend({
        neuf: 'oui',
        'prix-max': Math.round(price * 1.1 / 1000) * 1000,
        'prix-min': Math.round(price * 0.8 / 1000) * 1000,
    }, extraParameters));
};
