const _ = require('lodash');
const $ = require('jquery');
const callWithGlobalJQuery = require('../utils/callWithGlobalJQuery');
const {i18n: {translate}} = require('fack');

module.exports = {
    init: _.once(init),
};

function init() {
    callWithGlobalJQuery(function () {
        require('fack/bootstrap-stylus/js/tooltip'); //bootstrap-popover dependency
        require('fack/bootstrap-stylus/js/popover'); //required by bootstrapValidator for error feedback
        require('bootstrap-tagsinput');
        require('bootstrapValidator');
        require('bootstrapValidator/dist/js/language/fr_FR.js');
        require('./validators/EnhanceBootstrapValidators');
    });
    initDefaultErrorMessages();
}

function initDefaultErrorMessages() {
    const defaultMessages = {
        notEmpty: 'fieldRequired',
        intlPhone: 'tel',
    };
    _.forEach(defaultMessages, (translationKey, validator) => {
        $.fn.bootstrapValidator.i18n[validator] = $.extend($.fn.bootstrapValidator.i18n[validator] || {}, {
            default: translate(`formErrorMessages.${translationKey}`),
        });
    });
}
