const template = require('./NeighborhoodCategoryGauge.jade');

// @vue/component
module.exports = {
    props: {
        value: {
            type: Number,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
    computed: {
        valueBarStyle() {
            return {
                background: this.color,
                width: this.value + '%',
            };
        },
    },
    template: template(),
};
