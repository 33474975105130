const _ = require('lodash');
const {resourceUrl} = require('fack');
const moment = require('moment');
const i18nMixin = require('../../vue/components/mixins/i18n');
const RealEstateAdTitleGenerator = require('../../../common/RealEstateAdTitleGenerator');
const template = require('./EstimationOverview.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'EstimationOverview.',
        }),
    ],
    props: {
        estimation: {
            type: Object,
            required: true,
        },
    },
    computed: {
        imageUrl() {
            const propertyType = _.get(this.estimation.realEstateProperty, 'propertyType');
            const svgName = propertyType === 'flat' ? 'home-modern' : 'home-variant';
            return resourceUrl(`images/prospectingMap/${svgName}.svg`);
        },
        title() {
            return RealEstateAdTitleGenerator.getTitle(this.estimation.realEstateProperty, 'estimationResults');
        },
        locationText() {
            const locationElements = _(['postalCode', 'city'])
                .map(subPath => {
                    return _.get(this.estimation, ['realEstateProperty', 'position', 'address', subPath]);
                })
                .compact()
                .value();
            return locationElements.join(' ');
        },
        priceData() {
            const {estimation: {minPrice, maxPrice}} = this.estimation;
            return {
                price: [minPrice, maxPrice],
            };
        },
        dateText() {
            const count = moment().diff(moment(this.estimation.estimationDate).startOf('days'), 'days');
            return this.t('date' + (count === 0 ? '_zero' : ''), {count});
        },
    },
    template: template(),
};
