const _ = require('lodash');
const template = require('./BlogCategory.jade');

// @vue/component
module.exports = {
    props: {
        category: {
            type: Object,
            default: undefined,
        },
        smallMenuEnabled: Boolean,
        dropdownMenuItem: Boolean,
        subCategory: Boolean,
    },
    computed: {
        categoryName() {
            return this.category.name;
        },
        isHome() {
            return this.category.isHome;
        },
        showHomeIcon() {
            return this.isHome && !this.smallMenuEnabled && !this.dropdownMenuItem;
        },
        icon() {
            if (this.showHomeIcon) {
                return 'fa-home';
            } else if (this.dropdownMenuItem) {
                return 'fa-chevron-right';
            } else if (this.category.subTags || this.smallMenuEnabled) {
                return 'fa-chevron-down';
            } else {
                return '';
            }
        },
        categoryModifiers() {
            return _.pick(this, 'subCategory', 'dropdownMenuItem');
        },
    },
    template: template(),
};
