const $ = require('jquery');
const _ = require('lodash');

module.exports = {
    updateNextPrevLinks,
    setCanonicalLink,
};

function updateNextPrevLinks(urls = {prev: null, next: null}) {
    _.forEach(urls, _updateRelAttribute);
}

function _updateRelAttribute(url, att) {
    $(`head link[rel=${att}]`).remove();
    if (url) {
        $('<link/>').attr({
            rel: att,
            href: url,
        }).appendTo('head');
    }
}

function setCanonicalLink(href) {
    if (!href.startsWith(window.location.origin)) {
        href = window.location.origin + href;
    }

    const currentCanonical = document.querySelector('link[rel="canonical"]');
    if (currentCanonical) {
        currentCanonical.href = href;
    } else {
        const canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', href);
        document.head.appendChild(canonicalLink);
    }
}
