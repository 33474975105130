const assert = require('assert');
const _ = require('lodash');
const moment = require('../../../common/momentFr');
const UserSearchZone = require('../../geolocation/UserSearchZone');
const {getImageUrlAndConvertToWebpIfSupported} = require('../../utils/ImageConverterHelper');
const {hasDateInRanges} = require('../../utils/checkDate');

module.exports = {
    getMatchingSlides,
    getMatchingSlidesData,
    getCurrentGlobalSlides,
    transformDataToSlides,
};

/**
 * @param {SlideData[]} slidesData Input data
 * @param {string} overrideSlideName Use the slide with this name if found inside slidesData
 * @param {function(null, Object[]?)} cb Result callback, never returning an error
 */
function getMatchingSlides(slidesData, overrideSlideName, cb) {
    const overrideSlide = getOverrideSlide(slidesData, overrideSlideName);
    if (overrideSlide) {
        setImmediate(() => {
            cb(null, [overrideSlide]);
        });
    } else {
        getMatchingSlidesData(slidesData, (err, matchingSlidesData) => {
            assert.strictEqual(err, null); // getMatchingSlidesData always returns null

            // Default slide is always there, so we remove it when there is matching slides
            if (matchingSlidesData.filter(slide => !slide.isCommercialAd).length > 1) {
                matchingSlidesData = matchingSlidesData.filter(slide => !slide.isDefault);
            }

            cb(null, transformDataToSlides(matchingSlidesData));
        });
    }
}

/**
 * Return current global slides directly, without using any callback to make sure this function is synchronous
 * @param {SlideData[]} slidesData Input data
 */
function getCurrentGlobalSlides(slidesData) {
    const [, globalSlides] = getLocalAndGlobalCurrentSlides(slidesData);
    return transformDataToSlides(globalSlides);
}

function getLocalAndGlobalCurrentSlides(slidesData) {
    const today = moment().startOf('day');
    const possibleSlidesNow = _.filter(slidesData, slideData => hasDateInRanges(today, slideData));
    return _.partition(possibleSlidesNow, 'postalCodesPrefixes');
}

/**
 * @param {SlideData[]} slidesData Input data
 * @param {function(null, Object[])} cb Result callback, never returning an error
 */
function getMatchingSlidesData(slidesData, cb) {
    const [slidesWithZones, globalSlides] = getLocalAndGlobalCurrentSlides(slidesData);

    UserSearchZone.filterMatchingZones(slidesWithZones, (err, slidesMatchingUserZone) => {
        if (err) {
            console.warn('Could not filter slides matching the user zone, no slide with zone selected', err);
        }

        const matchingSlides = sortSlides(slidesMatchingUserZone, globalSlides);
        cb(null, matchingSlides);
    });
}

/**
 * Build a sorted array of all slides, high priority slides first.
 * Slides are sorted by local/global location, then priority property
 *
 * @param {?SlideData[]} slidesMatchingUserZone Local slides data
 * @param {SlideData[]} globalSlides Global slides data
 */
function sortSlides(slidesMatchingUserZone, globalSlides) {
    const sortedGlobalSlides = globalSlides.sort(compareSlidesPriority);
    if (_.isEmpty(slidesMatchingUserZone)) {
        return sortedGlobalSlides;
    } else {
        // Higher priority for the slides with localisation
        return _.concat(slidesMatchingUserZone.sort(compareSlidesPriority), sortedGlobalSlides);
    }
}

function compareSlidesPriority(a, b) {
    return (b.priority || 0) - (a.priority || 0);
}

function getImageUrl(slideName, suffix) {
    return getImageUrlAndConvertToWebpIfSupported(`images/homepage/slides/${slideName}/${suffix}`);
}

/**
 * Transform a {@link SlideData} into a slide object usable by SlideShowView
 * @param {SlideData} data
 * @returns {Object}
 */
function transformDataToSlide({
    name,
    description,
    topLeftPicture,
    bottomRightPicture,
    campaign,
    descriptionBackgroundColor,
    externalLink,
    logoFrom,
    hasLogo = true,
    isCommercialAd,
    backgroundUrl,
}) {
    const slide = {
        backgroundUrl: backgroundUrl ? backgroundUrl : getImageUrl(name, 'background.jpg'),
    };

    if (isCommercialAd) {
        _.extend(slide, {
            description: buildCommercialAdDescription({description, hasLogo, logoFrom, name}),
            descriptionBackgroundColor,
            duration: 10e3,
            gtmData: {
                campaign,
                occurence: 0,
            },
            externalLink,
        });
    }

    if (topLeftPicture) {
        const CORNER_PICTURE_EXTENSION = '.png';
        _.extend(slide, {
            topLeftPictureUrl: getImageUrl(name, topLeftPicture + CORNER_PICTURE_EXTENSION),
            bottomRightPictureUrl: getImageUrl(name, bottomRightPicture + CORNER_PICTURE_EXTENSION),
        });
    }
    return slide;
}

function buildCommercialAdDescription({
    description,
    hasLogo,
    logoFrom,
    name,
}) {
    let slideDescription;
    if (description) {
        slideDescription = `<p>${description}</p>`;
        if (hasLogo) {
            const logoUrl = getImageUrl(_.defaultTo(logoFrom, name), 'logo.png');
            slideDescription += `<img src="${logoUrl}" alt="logo">`;
        }
    }
    return slideDescription;
}

function transformDataToSlides(slides) {
    return _.map(slides, transformDataToSlide);
}

function getOverrideSlide(slidesData, overrideSlideName) {
    if (overrideSlideName) {
        const overrideSlideData = _.find(slidesData, {name: overrideSlideName});
        if (overrideSlideData) {
            return transformDataToSlide(overrideSlideData);
        }
    }
}
