const $ = require('jquery');
const _ = require('lodash');
const View = require('../../views/View');
const articlesBlocksTemplate = require('./articlesBlocks.jade');
const Account = require('../../authentication/Account');
const {getSmallSizePhotoUrl} = require('../article/ArticleHelper');

module.exports = class ArticlesListView extends View {
    constructor(options) {
        super();
        this.currentArticle = options.currentArticle;
        this.articlesList = options.articlesList;
        this.maxByStepArticles = options.maxByStepArticles;
        this.currentCategory = options.currentCategory;
        this.scrollTopValue = 0;
    }

    show() {
        this.hasLoadArticles = false;
    }

    setArticlesContainer($articlesContainer) {
        this.$articlesContainer = $articlesContainer;
    }

    getCurrentCategory() {
        return this.currentCategory;
    }

    saveScrollTopValue() {
        this.scrollTopValue = $(window).scrollTop();
    }

    setScrollTopValue() {
        window.scrollTo(0, this.scrollTopValue);
    }

    addArticles(fromArticle, useHTMLHeadingsTags) {
        this.$articlesContainer
            .show()
            .removeClass('notVisible');
        const isBlogger = Account.isBlogger();
        const $element = this.renderTemplate(articlesBlocksTemplate, {
            articles: this.articlesList.articles,
            size: this.articlesList.articles.length,
            currentArticle: this.currentArticle,
            isBlogger,
            useHTMLHeadingsTags,
            getSmallSizePhotoUrl,
        });
        this.$articlesContainer.append($element);

        const $articlesHubArticle = $element.find('.articlesHub__article');
        if (fromArticle) {
            $element.find('.articlesHub__article .openArticle[data-slug!=' + fromArticle.slug + ']').parent().addClass('animate');
        } else {
            $articlesHubArticle.addClass('animate');
        }
        _.delay(_.bind(function () {
            $articlesHubArticle.removeClass('animate');
        }, this), 1000);
        $articlesHubArticle.addClass('visible');

        $element.find('.openArticle').linkClick(_.bind(function (event) {
            event.preventDefault();
            this.emit('openArticle', event);
        }, this));
    }

    getImgTarget(slug) {
        const $container = this.$articlesContainer.find('.articlesHub__article-content[data-slug="' + slug + '"]');
        const $target = $container.find('.articlesHub__article__photo-container');
        if ($target.length == 1 && $container.length == 1) {
            return {
                $target,
                $container,
            };
        } else {
            return null;
        }
    }

    hideArticles() {
        this.$articlesContainer.find('.articlesHub__article').removeClass('visible');
        this.$articlesContainer.hide();
    }

    showArticles({fromArticle, scrollToArticle, useHTMLHeadingsTags} = {}) {
        if (!this.hasLoadArticles) {
            this.addArticles(fromArticle, useHTMLHeadingsTags);
            this.hasLoadArticles = true;
        }
        this.$articlesContainer.show();
        _.delay(_.bind(function () {
            if (scrollToArticle) {
                this.setScrollTopValue();
            }
            this.$articlesContainer.find('.articlesHub__article').addClass('visible');
        }, this), 1);
    }

    unloadSearch() {
        if (this.$articlesContainer) {
            this.$articlesContainer.empty().hide();
        }
    }

    hide(options, cb = _.noop) {
        this.unloadSearch();
        cb();
    }
};
