const _ = require('lodash');
const template = require('./Diagnostic.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {formatEnergyValueAndClassification} = require('./helper/energyDiagnosticsHelper');

const ACCEPTED_TYPES = ['dpe', 'ges'];

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'EnergyDiagnostics.',
            keys: {
                dpe: 'dpeText',
                ges: 'gesText',
                openModal: 'openModalText',
            },
        }),
    ],
    props: {
        type: {
            type: String,
            required: true,
            validator: value => _.includes(ACCEPTED_TYPES, value),
        },
        info: {
            type: Object,
            required: true,
        },
        openComponent: {
            type: Boolean,
            default: false,
        },
        hasNoLabelForDpeAndGes: Boolean,
    },
    data() {
        return {
            isFullyOpen: false,
        };
    },
    computed: {
        formattedInfo() {
            if (this.hasNoLabelForDpeAndGes) {
                return {
                    classification: 'missing',
                    label: this.translate('enum.classification.missing'),
                };
            } else {
                return formatEnergyValueAndClassification(this.info);
            }
        },
        containerClass() {
            return `energy-diagnostic__${this.type}`;
        },
        chartClass() {
            return `diagnostic-${this.type}`;
        },
        label() {
            return this[`${this.type}Text`];
        },
    },
    watch: {
        openComponent(open) {
            this.isFullyOpen = open;
        },
    },
    methods: {
        displayFullMode() {
            this.$emit('open-all-diagnostics-components');
            this.isFullyOpen = true;
        },
    },
    template: template(),
};
