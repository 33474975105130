const _ = require('lodash');
const template = require('./AdContactInfo.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');

const FIELDS_TO_DISPLAY = [
    'name',
    'phone',
    'email',
];

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AdContactInfo.',
            keys: [
                'title',
                ..._.map(FIELDS_TO_DISPLAY, key => getTranslationKeyName(key)),
            ],
        }),
    ],
    props: {
        contactInfo: {
            type: Object,
            required: true,
        },
    },
    computed: {
        displayedFields() {
            return _(this.contactInfo)
                .pick(FIELDS_TO_DISPLAY)
                .map((value, key) => {
                    return {
                        name: this[getTranslationKeyName(key)],
                        value,
                    };
                })
                .value();
        },
    },
    template: template(),
};

function getTranslationKeyName(key) {
    return `${key}Text`;
}
