const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');
const async = require('async');

const Urls = require('../Urls');
const ServerConfig = require('../ServerConfig');
const LocationItemHelper = require('../locationItems/LocationItemHelper');
const CancelableCallback = require('./CancelableCallback');
const SearchTitleGenerator = require('../../common/SearchTitleGenerator');
const SearchPlacesHelper = require('../SearchPlacesHelper');

const ADMIN_LIMITS = [
    'department',
    'city',
    'delegated-city',
    'arrondissement',
    'neighborhood',
];

let breadcrumbParts = [];
let hierarchy = [];
let pendingHierarchyCallback = null;

module.exports = {
    getBreadcrumbPartsFromSearchResults({searchResults}) {
        if (hierarchy.length) {
            breadcrumbParts = _getBreadcrumbPartsFromSearchResults(searchResults);
        } else {
            breadcrumbParts = [];
        }
        return breadcrumbParts;
    },
    getBreadcrumbPartsFromItems(items) {
        return _getBreadcrumbPartsFromItems(items);
    },
    loadHierarchy(locationItems, cb) {
        const locationItemsWithAdminLimits = _.filter(locationItems, isAdminLimit);
        _clearPendingHierarchy();
        if (locationItemsWithAdminLimits.length != 1) {
            _emptyHierarchy(cb);
        } else {
            const locationItem = _.first(locationItemsWithAdminLimits);
            pendingHierarchyCallback = CancelableCallback((err, data) => {
                hierarchy = _prepareHierarchy(data, locationItem);
                if (err) {
                    console.error('error on loading hierarchy', err);
                }
                cb(); // err is not transmitted on purpose. We can get the ads without the hierarchy
            });
            const data = _getHierarchyLocationData(locationItem);
            if (data.inseeCode) {
                SearchPlacesHelper.getHierarchyLocation(data, pendingHierarchyCallback);
            } else {
                _emptyHierarchy(cb);
            }
        }
    },
    getJSONLD() {
        return _.compact([_getBreadcrumbSchema()]);
    },
};

function _getBreadcrumbPartsFromSearchResults(searchResults) {
    const filtersWithoutLocation = _.omit(searchResults.getFilters(), 'locations');
    return [
        _getBreadcrumbPart(),
        _getBreadcrumbPart(filtersWithoutLocation),
        ..._.map(hierarchy, locationItem => {
            const filters = _.extend({locations: [locationItem]}, filtersWithoutLocation);
            return _getBreadcrumbPart(filters);
        }),
    ];
}

function _getBreadcrumbPartsFromItems(items) {
    let breadcrumbItems = [];
    if (items.length) {
        const firstItem = _.first(items);
        let slugAccumulator = `/${firstItem.slug}`;
        breadcrumbItems = [
            {
                'text': firstItem.name,
                'url': slugAccumulator,
            },
            ..._.map(items.slice(1), path => {
                slugAccumulator += `/${path.slug}`;
                return {
                    'text': path.name,
                    'url': path.slug ? slugAccumulator : null,
                };
            }),
        ];
    }
    return breadcrumbItems;
}

function _getBreadcrumbPart(filters) {
    if (filters) {
        return {
            text: SearchTitleGenerator.getTitle(filters, 'breadcrumb'),
            url: _getBreadcrumbPartUrl(filters),
        };
    } else {
        return {
            text: t('searchResults.breadcrumbSearchHomePage'),
            url: '/',
        };
    }
}
function _getBreadcrumbPartUrl({locations, ...filters}) {
    const locationName = _.invoke(_.first(locations), 'getUrlValue');
    return Urls.search.makeUrl(_.extend({
        locationNames: [locationName],
    }, filters));
}

function _getBreadcrumbSchema() {
    return breadcrumbParts.length ? {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: _.map(breadcrumbParts, ({url, text}, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': `${ServerConfig.config.wwwUrl}${url}`,
                name: text,
            },
        })),
    } : null;
}

function _clearPendingHierarchy() {
    if (pendingHierarchyCallback) {
        pendingHierarchyCallback.cancel();
        pendingHierarchyCallback = null;
    }
}

function _getHierarchyLocationData(locationItem) {
    return {
        inseeCode: _.invoke(locationItem, 'getItemInseeCode'),
        type: _.invoke(locationItem, 'getType'),
    };
}

function _emptyHierarchy(cb) {
    hierarchy = [];
    async.setImmediate(cb);
}

function _prepareHierarchy(data, locationItem) {
    const hierarchy = _(data)
        .filter(o => o.place_type != 'region')
        .map(({name, postal_codes: postalCodes, ref, place_type: type}) => {
            return LocationItemHelper.getInstance({
                name,
                postalCodes,
                ref,
                type,
            });
        })
        .value();

    if (locationItem.getType() == 'neighborhood') {
        hierarchy.push(locationItem);
    }
    return hierarchy;
}

function isAdminLimit(locationItem) {
    return _.includes(ADMIN_LIMITS, locationItem.getType());
}
