module.exports = {
    isWebGL2Supported,
};

function isWebGL2Supported() {
    if (window.WebGLRenderingContext) {
        const canvas = document.createElement('canvas');
        try {
            const webGL2Context = canvas.getContext('webgl2');
            if (webGL2Context) {
                canvas.remove();
                return true;
            }
        } catch (e) {
            console.error('WebGL is not fully supported or disabled on your browser');
        }
        canvas.remove();
        return false;
    }
    return false;
}
