// @vue/component
module.exports = {
    mixins: [
        require('./mixins/configMultiselectRadioButton'),
    ],
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            options: [
                {label: 'A', value: 'A'},
                {label: 'B', value: 'B'},
                {label: 'C', value: 'C'},
                {label: 'D', value: 'D'},
                {label: 'E', value: 'E'},
                {label: 'F', value: 'F'},
                {label: 'G', value: 'G'},
            ],
        };
    },
};
