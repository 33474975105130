const {resourceUrl} = require('fack');
const template = require('./DpeLine.jade');
const {isBadEnergyPerformanceClassification} = require('../helper/energyDiagnosticsHelper');
const i18nMixin = require('../../../vue/components/mixins/i18n');

const DPE_COLORS = {
    A: '#00ae80',
    B: '#50bd68',
    C: '#74c585',
    D: '#efea36',
    E: '#f1b62a',
    F: '#f08a44',
    G: '#de2f31',
};

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'EnergyDiagnostics.',
            keys: [
                'dpeLegendTitle',
                'dpeTitleSubTitle',
                'gesLegendTitle',
                'legendEnergyPerformanceTitle',
                'legendEnergyPerformanceSubTitle',
                'legendGreenhouseGazTitle',
                'badEnergyPerformanceTextTop',
                'badEnergyPerformanceTextBottom',
            ],
        }),
        require('../mixins/commonEnergyDiagnosticLine'),
    ],
    props: {
        dpeValue: {
            type: Number,
            default: undefined,
        },
        gesValue: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        style() {
            return {
                background: DPE_COLORS[this.classification],
                ...this.classificationLineWidth,
            };
        },
        imageUrl() {
            return resourceUrl('images/diagnostic/passoire-energetique.png');
        },
        isBadEnergyPerformanceClassification() {
            return isBadEnergyPerformanceClassification(this.classification) && this.active;
        },
    },
    template: template(),
};
