const _ = require('lodash');

// Les caractères dont le charCode qui n'est pas compris dans l'une de ces ranges aura un poid de 2 dans la
// longueur d'un Tweet : https://developer.twitter.com/en/docs/developer-utilities/twitter-text.html
const SINGLE_WEIGHT_CHAR_CODES_RANGES = [
    {
        start: 0,
        end: 4351,
    },
    {
        start: 8192,
        end: 8205,
    },
    {
        start: 8208,
        end: 8223,
    },
    {
        start: 8242,
        end: 8247,
    },
];

const SOURCE_OF_THE_TWEET = 'bienici';
const TWITTER_CHARACTER_LIMIT = 280; // https://developer.twitter.com/en/docs/tweets/tweet-updates
const TWITTER_URL_SIZE = 24; // One space + 23 from transformedURLLength (https://developer.twitter.com/en/docs/developer-utilities/twitter-text.html)
const TWITTER_VIA = ` via @${SOURCE_OF_THE_TWEET}`;
const TRUNCATION_OMISSION = '…';

module.exports = {
    calculateTextLength,
    findTheMaxLength,
    getMessage,
    SOURCE_OF_THE_TWEET,
};

function getMessage(text) {
    const maxTitleLength = _getTitleMaxSize();
    const mustBeTruncated = calculateTextLength(text) > maxTitleLength;
    return mustBeTruncated ? truncateMessage(text, maxTitleLength) : text;
}

function truncateMessage(text, maxTitleLength) {
    const length = findTheMaxLength(text, maxTitleLength) -
        (_countAsASingleCharacter(TRUNCATION_OMISSION) ? 0 : 1);
    return _.truncate(text, {
        length,
        omission: TRUNCATION_OMISSION,
    });
}

function calculateTextLength(text) {
    let length = 0;
    _.forEach(text, value => {
        const charCode = value.charCodeAt();
        length += _countAsASingleCharacter(charCode) ? 1 : 2;
    });
    return length;
}

function findTheMaxLength(text, maxLength) {
    let limit = 0;
    const max = maxLength;
    _.forEach(text, letter => {
        limit++;
        const charCode = letter.charCodeAt();
        if (!_countAsASingleCharacter(charCode)) {
            maxLength--;
            limit++;
        }
        if (limit >= max) {
            return false;
        }
    });
    return maxLength;
}

function _countAsASingleCharacter(charCode) {
    return _.find(SINGLE_WEIGHT_CHAR_CODES_RANGES, ({start, end}) => _.inRange(charCode, start, end));
}

function _getTitleMaxSize() {
    return TWITTER_CHARACTER_LIMIT - (calculateTextLength(TWITTER_VIA) + TWITTER_URL_SIZE);
}
