const template = require('./CreditLink.jade');

// @vue/component
module.exports = {
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    template: template(),
};
