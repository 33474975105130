const template = require('./CustomerReview.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const ContainerQueries = require('../../vue/mixins/ContainerQueries');
const {BREAKPOINTS_LIMITS_XS} = require('../../vue/utils/Breakpoints');

// @vue/component
module.exports = {
    mixins: [
        ContainerQueries({
            xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS},
        }),
        i18nMixin({
            prefix: 'CustomerReview.',
            keys: [
                'replyTitle',
                'linkText',
            ],
        }),
    ],
    props: {
        review: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formattedDate() {
            const {date} = this.review;
            return date && DateFormatter.humanizeDay(date);
        },
        reviewIdText() {
            return this.t('id', {id: this.review.id});
        },
        reviewTitle() {
            return this.t('reviewTitle', {title: this.review.title});
        },
        reply() {
            return this.review.reply;
        },
        externalLink() {
            return this.review.externalLink;
        },
    },
    template: template(),
};
