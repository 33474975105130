const _ = require('lodash');

const GoogleTagManager = require('./../stats/GoogleTagManager');

module.exports = class LocationGTMHandler {
    constructor() {
        this._locationList = [];
        this._inputValue = '';
    }

    saveLocationList(locations) {
        this._locationList = locations;
    }

    saveInputValue(value) {
        this._inputValue = value;
    }

    sentGTMEvent(item) {
        if (item.getItemGTMInfo) {
            GoogleTagManager.sendEvent('suggestionStats', this._getGTMInfos(item));
            this.reset();
        }
    }

    reset() {
        this._locationList = [];
        this._inputValue = '';
    }

    _getGTMInfos(item) {
        return _.extend({
            rank: _.indexOf(this._locationList, item) + 1,
            inputValue: this._inputValue,
        }, item.getItemGTMInfo());
    }
};
