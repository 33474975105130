const crossroads = require('crossroads');
const urlUtils = require('url');
const History = require('./History');
const _ = require('lodash');

const routes = {};
let lastUrl;

module.exports = {
    addRoute,
    parseUrl,
    parseCurrentUrl,
    redirectToUrl,
    routes,
};

History.Adapter.bind(window, 'popstate', () => {
    const {url} = History.getState();
    lastUrl = url;
});

function addRoute(pattern, handler) {
    const urlPatternWithQuery = addQueryToPatternIfString(pattern);
    routes[urlPatternWithQuery] = crossroads.addRoute(urlPatternWithQuery, handler);
}

function addQueryToPatternIfString(pattern) {
    if (_.isString(pattern)) {
        return pattern + ':?query:';
    } else {
        return pattern;
    }
}

function parseUrl(relativeUrl) {
    const absoluteUrl = urlUtils.resolve(location.href, relativeUrl);
    crossroads.parse(urlUtils.parse(absoluteUrl).pathname);
}

function parseCurrentUrl({parseOnlyIfChanged} = {}) {
    const {url} = History.getState();
    if (!parseOnlyIfChanged || url != lastUrl) {
        parseUrl(url);
    }
}

function redirectToUrl(url) {
    History.replaceState(null, null, url);
    parseUrl(url);
}
