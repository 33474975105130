const RealEstateEstimatorPage = require('./RealEstateEstimatorPage');

module.exports = class RealEstateRentEstimatorPage extends RealEstateEstimatorPage {
    constructor() {
        super({
            transactionType: 'rent',
        });
    }

    getUrlPattern() {
        return '/estimation-location-immobiliere';
    }
};
