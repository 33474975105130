const _ = require('lodash');
const $ = require('jquery');
const util = require('util');
const BrowserDetect = require('browser-detect');
const TrackingJail = require('./TrackingJail');
const AbTesting = require('../AbTesting');
const Account = require('../authentication/Account');
const Options = require('../Options');
const {onDidomiReady} = require('../cmp/DidomiHelper');
const LoadScriptHelper = require('../utils/LoadScriptHelper');
const {isNewUser} = require('../utils/NewUserDetector');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');

const scriptDeferred = $.Deferred();

module.exports = {
    init,
    injectTrackingJail: TrackingJail.injectTrackingJail,
    pickOrUndefined,
    sendEvent,
    sendChangePageEvent,
    setHost,
    setLoadedCallbacks,
    sendAgencyPhoneNumberShownEvent,
    sendAgencyContactFormShownEvent,
    sendAgencyContactFormSubmittedEvent,
    setReferrer,
    sendContactEvent,
    sendRealEstateAdShareEvent,
};

const PAGES_CATEGORIES_TO_LEVELS = {
    Unclassified: 0,
    Results: 1,
    DetailedSheets: 2,
    Forms: 3,
    Account: 4,
    Content: 5,
    Clicks: 6,
    Pro: 7,
    Directory: 8,
    Homepage: 9,
    DiscoveryContent: 10,
    BlogContent: 11,
    ErrorPages: 12,
    BusinessContent: 13,
    ToolsAndSimulators: 14,
};

let dataLayer;
let isPro = false;
let debugMode;
const eventsData = {};
let currentPage = null;
let referrer;
const KEYS_TO_SEND_TO_GTM = [
    'adTypeFR',
    'transactionType',
    'newProperty',
    'propertyType',
    'roomsQuantity',
    'price',
    'onlineBookingUrl',
];
let shouldPostponeEvents = true;
let postponedEvents;

onDidomiReady(function () {
    shouldPostponeEvents = false;
    if (dataLayer) { // not initialized in tests
        _.each(postponedEvents, (postponedEvent) => {
            dataLayer.push(postponedEvent);
        });
    }
});

function setHost(isApplicationPro) {
    isPro = isApplicationPro || false;
}

function setLoadedCallbacks(options) {
    const state = scriptDeferred.state();
    if (state == 'pending') {
        scriptDeferred.done(options.successCallback);
        scriptDeferred.fail(options.errorCallback);
    } else if (state == 'resolved') {
        options.successCallback();
    } else if (state == 'rejected') {
        options.errorCallback();
    }
}

function isInPrerenderer() {
    return /bot-prerenderer/.test(navigator.userAgent);
}

function init(containerId) {
    if (isInPrerenderer()) {
        console.log('prerenderer detected, disabling GTM');
        return;
    }
    const savedOptions = Options.read();
    debugMode = savedOptions.GoogleTagManagerDebugMode;
    dataLayer = window.dataLayer = window.dataLayer || [];
    postponedEvents = [];
    TrackingJail.init({dataLayer});
    sendInitData();
    LoadScriptHelper.loadScript('/gtm.js?id=' + containerId, {
        crossoriginAllowed: false,
        errorCallback: function () {
            scriptDeferred.reject();
        },
        successCallback: function () {
            // https://marthijnhoiting.com/detect-if-someone-is-blocking-google-analytics-or-google-tag-manager/
            if (window.google_tag_manager) {
                scriptDeferred.resolve();
            } else {
                scriptDeferred.reject();
            }
        },
    });
    Account.on('accountCreated', function (details) {
        sendEvent('accountCreated', details);
    });
    Account.on('accountUpdated', function (details) {
        sendEvent('accountUpdated', details);
    });
    Account.on('change:id', function () {
        cleanData();
        let accountInfo;
        const authenticatedAccount = Account.getAuthenticatedAccount();
        // we can't only check isPro as it may not have already been set
        if (isPro || (authenticatedAccount && authenticatedAccount.company)) {
            // if no account, explicitly clear dataLayer data by sending undefined values
            accountInfo = _.extend(pickOrUndefined(authenticatedAccount, [
                'accountType',
                'company.address.postalCode',
            ]), {
                contractType: _.get(authenticatedAccount, 'highestContract._id'),
                extensions: authenticatedAccount && _.map(authenticatedAccount.extensions, '_id'),
                accountOptions: authenticatedAccount && _.pick(
                    authenticatedAccount,
                    ['maxLeadingAds', 'virtualTourEnabled', 'callTrackingEnabled']
                ),
            });
        }
        sendEvent('accountChanged', accountInfo);
    });
}

function pickOrUndefined(o, paths) {
    const res = {};
    _.each(paths, path => {
        _.set(res, path, _.get(o, path));
    });
    return res;
}

function sendInitData() {
    setReferrer(Account.getReferrer());
    Account.on('referrerChanged', setReferrer);

    const isWebglSupported = BrowserDetect.supportWebGL();
    sendEvent('gtm.js', {
        'gtm.start': new Date().getTime(),
        referrer,
        isWebglSupported,
        abTesting: AbTesting.getSavedOptions(),
        isMobile: BrowserDetect.isMobile(),
        isFromApp: UserAgentHelper.isFromNativeApp(),
        isTablet: BrowserDetect.isTablet(),
        isIOS: BrowserDetect.isIOS(),
        isNewUser: isNewUser(),
    }, {
        persistent: true,
    });

    // this event is still sent for compatibility reasons (current used by a tracking tag)
    sendEvent('isWebglSupported', {
        isWebglSupported,
    }, {
        persistent: true,
    });
}

function setReferrer(param) {
    referrer = param;
}

function sendChangePageEvent(page) {
    currentPage = page;
    const pageName = currentPage.getName();
    let patternUrl = currentPage.getUrlPattern && currentPage.getUrlPattern();
    if (pageName == 'search') {
        patternUrl = '/recherche/';
    } else if (pageName == 'detailedSheet') {
        patternUrl = '/annonce/';
    }
    cleanData();
    const pageLevel = isPro ? PAGES_CATEGORIES_TO_LEVELS.Pro : PAGES_CATEGORIES_TO_LEVELS[currentPage.getGTMCategory()] || 0;
    sendEvent('pageChanged', {
        pageLevel,
        patternUrl,
        pageName,
    }, {
        persistent: true,
    });
}

function getCurrentPagePersistentFields() {
    return currentPage && currentPage.getGTMPersistentFields();
}

function cleanData() {
    const removedData = {};
    _.each(_.omit(eventsData, getCurrentPagePersistentFields()), function (data, key) {
        removedData[key] = undefined;
        delete eventsData[key];
    });
    if (!_.isEmpty(removedData)) {
        if (debugMode) {
            console.log('send undefined values for : ' + _.keys(removedData) + ' to googleTagManager');
        }
        getDataLayer().push(removedData);
    }
}

/**
 * @param {string} event
 * @param {object} data
 * @param {object} {persistent}
 */
function sendEvent(event, data, {persistent = false} = {}) {
    if (!getDataLayer()) {
        //tests
        return;
    }
    let dataToSend = {};
    if (persistent) {
        _.extend(dataToSend, data);
    } else if (!_.isEmpty(data)) {
        dataToSend[event] = data;
        _.extend(eventsData, dataToSend);
    }
    _.extend(dataToSend, {
        event,
        accountId: Account.getAuthenticatedAccountId(),
        isRegistered: Account.isRegistered() ? 1 : 2,
    });
    _.extend(dataToSend, data); // for compatibility with old events
    if (!dataToSend.accountId) {
        dataToSend = _.omit(dataToSend, 'accountId');
    }
    const pushedData = prepareData(dataToSend);
    if (debugMode) {
        console.log('sending event to GTM: ' + util.inspect(pushedData));
    }
    getDataLayer().push(pushedData);
}

function prepareData(data) {
    return _.mapValues(data, toDataString);
}

function toDataString(stuff) {
    if (undefined == stuff) {
        return undefined;
    } else if (_.isString(stuff)) {
        return stuff;
    } else if (_.isArray(stuff)) {
        return _.map(stuff, toDataString).join(',');
    } else if (_.isObject(stuff)) {
        return _.mapValues(stuff, function (value) {
            return toDataString(value);
        });
    } else {
        return String(stuff);
    }
}

function sendContactEvent(eventName, data) {
    sendEvent(eventName, _.extend(_.omit(data, 'realEstateAd'), getExtraContactData(eventName, data)));
}

function getExtraContactData(eventName, {realEstateAdId, realEstateAd}) {
    const extraData = _.extend({
        referrer,
        userId: Account.getAuthenticatedAccountId(),
    }, _.pick(realEstateAd, KEYS_TO_SEND_TO_GTM));
    if (eventName == 'contactFormSent') {
        const isRentalApplication = _.get(realEstateAd, 'contactRequests[0].sender.isRentalApplication', false);
        _.extend(extraData, {
            contactId: realEstateAdId + Account.getAuthenticatedAccountId(),
            agencyIds: _.get(realEstateAd, 'userRelativeData.accountIds', ''),
            with_folder: isRentalApplication,
        });
    }
    return extraData;
}

function sendAgencyPhoneNumberShownEvent(agencyId, source) {
    sendEvent('agencyPhoneNumberShown', {
        agencyId,
        source,
        referrer, // for compatibility with old events
        userId: Account.getAuthenticatedAccountId(), // for compatibility with old events
    });
}

function sendAgencyContactFormShownEvent(agencyId, source) {
    const event = 'proAccountContactFormShown';
    sendEvent(event, {
        agencyId,
        source,
        referrer, // for compatibility with old events
        userId: Account.getAuthenticatedAccountId(), // for compatibility with old events
    });
}

function sendAgencyContactFormSubmittedEvent(agencyId, subject, source) {
    const event = 'proAccountContactFormSubmitted';
    sendEvent(event, {
        agencyId,
        source,
        subject,
    });
}

function getDataLayer() {
    if (shouldPostponeEvents) {
        return postponedEvents;
    } else {
        return dataLayer;
    }
}

function sendRealEstateAdShareEvent(shareType, ad) {
    const event = 'realEstateAdShare';
    sendEvent(event, {
        shareType,
        realEstateAdId: ad.id,
    });
}
