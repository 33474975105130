const {i18n: {translate}} = require('fack');
const template = require('./templates/resetPasswordView.jade');
const CompositeVueView = require('./vue/CompositeVueView');
const _ = require('lodash');
const FormUtils = require('./fields/FormUtils');
const FormLoadingState = require('./FormLoadingState');
const Router = require('./utils/Router');
const History = require('./utils/History');
const Account = require('./authentication/Account');

module.exports = class ResetPasswordView extends CompositeVueView {
    constructor() {
        super({
            template,
        });
    }

    show(options) {
        const vueOptions = {
            computed: {
                headerTitle() {
                    return this.resetPasswordTranslate('headerTitle');
                },
                headerSubtitle() {
                    return this.resetPasswordTranslate('headerSubtitle');
                },
            },
            methods: {
                resetPasswordTranslate(key) {
                    return translate(`resetPassword.${key}`);
                },
            },
        };
        super.show(options, vueOptions);
        this.resetPasswordToken = options.resetPasswordToken;
        if (this.resetPasswordToken) {
            this.$form = this.$element.find('form');
            FormUtils.init({
                $form: this.$form,
                submit: _.bind(this.submitForm, this),
            });
        } else {
            const $leaveButton = this.$element.find('button[type="submit"]');
            $leaveButton.on('click', _.bind(this.handleLeaveClick, this));
        }
    }

    hide(options, cb) {
        if (this.$form) {
            FormUtils.clear({
                $form: this.$form,
            });
            this.$form = null;
        }
        super.hide(options, cb);
    }

    submitForm() {
        const data = FormUtils.readValues(this.$form);
        delete data['password-confirmation'];

        const $validateButton = this.$form.find('button[type="submit"]');
        this.$element.find('input').blur();
        this.loadingState = new FormLoadingState({
            $form: this.$form,
            $button: $validateButton,
        });
        this.loadingState.show();

        Account.resetAccountPassword(this.resetPasswordToken, data, _.bind(this.handleSubmitResult, this));
    }

    handleSubmitResult(err) {
        if (err) {
            alert('Erreur: ' + err);
        } else {
            let successUrl = '/';
            if (History.getCurrentIndex() > 0) {
                successUrl = History.getStateByIndex(History.getCurrentIndex() - 1).url;
            }
            this.$element.find('.successModal')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true,
                })
                .one('hidden.bs.modal', function () {
                    _.defer(function () {
                        History.pushState(null, null, successUrl);
                        Router.parseUrl(successUrl);
                    });
                });
        }
        if (this.loadingState) {
            this.loadingState.hide();
            this.loadingState = null;
        }
    }

    handleLeaveClick(event) {
        event.preventDefault();
        const homePageUrl = '/';
        _.defer(function () {
            History.pushState(null, null, homePageUrl);
            Router.parseUrl(homePageUrl);
        });
    }
};
