const {ResizeObserver} = require('@juggle/resize-observer');
const template = require('./AgencyPageSection.jade');

/**
 * This component is in charge of listening to its child presence by checking its height. If a child is present, it applies
 * a --collapsed modifier that sets the padding of the section component to 0. V-show directive is not used here
 * because the child component always needs to have a height so the parent knows what to do (it wouldn't be the case with
 * a display property set to none.
 */

module.exports = {
    props: {
        backgroundLight: Boolean,
    },
    data() {
        return {
            displayComponent: true,
        };
    },
    computed: {
        modifiers() {
            const {backgroundLight, displayComponent} = this;
            return {
                backgroundLight,
                collapsed: !displayComponent,
            };
        },
    },
    mounted() {
        const contentRef = this.$refs.content;
        const observer = new ResizeObserver((entries) => {
            this.displayComponent = entries[0].contentRect.height !== 0;
        });
        observer.observe(contentRef);
        this.observer = observer;
    },
    destroyed() {
        this.observer.disconnect();
    },
    template: template(),
};
