const _ = require('lodash');

const SimpleContentView = require('../../views/SimpleContentView');
const PtzResultsView = require('./PtzResultsView');
const LoanSimulatorResultsPage = require('../LoanSimulatorResultsPage');
const Urls = require('../../Urls');
const Pages = require('../../pages/Pages');

module.exports = class PtzResultsPage extends LoanSimulatorResultsPage {
    constructor(configuration) {
        configuration = _.extend({
            gtmCategory: 'ToolsAndSimulators',
            gtmPrefix: 'ptz',
            requestUrl: '/getPTZSimulation',
            requestName: 'getPTZSimulation',
            name: 'PtzResultsPage',
            title: 'Résultat simulation prêt à taux zéro',
            contentPath: 'ptz-results',
        }, configuration);
        super(configuration);
    }

    _createViews() {
        this.contentView = new SimpleContentView();
        this.resultsView = new PtzResultsView();
    }

    getUrlPattern() {
        return Urls.ptz.pattern;
    }

    getUrl() {
        return Urls.ptz.makeUrl(this.options || {});
    }

    parseUrl(url) {
        return {
            simulatorData: Urls.ptz.parseUrl(url),
        };
    }

    _getSimulatorPage() {
        return Pages.ptzSimulatorPage;
    }
};
