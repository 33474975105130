const ErrorPage = require('./ErrorPage');
const template = require('./AdModificationForbiddenAccess.jade');

module.exports = class AdModificationForbiddenAccess extends ErrorPage {
    constructor() {
        super({
            name: 'forbiddenAccess',
            template,
        });
    }
};
