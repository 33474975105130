const _ = require('lodash');
const TextFormatter = require('../../../common/TextFormatter');
const imageResConfig = require('../../imageResConfig');
const ContactBlockWithTitle = require('./BlockWithTitle');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./AdOverview.jade');

// @vue/component
module.exports = {
    components: {
        ContactBlockWithTitle,
    },
    mixins: [
        i18nMixin({
            prefix: 'AdOverview.',
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return this.t('title', {context: this.contactType});
        },
        tag() {
            return this.href ? 'a' : 'div';
        },
        contactType() {
            const {isEstimation, isAdSubmission} = this;
            let contactType;
            if (isEstimation) {
                contactType = 'estimationRequests';
            } else if (isAdSubmission) {
                contactType = 'adSubmissions';
            }
            return contactType;
        },
        href() {
            const {realEstateAd: {id}} = this;
            return id ? `/annonce/${id}` : undefined;
        },
        isEstimation() {
            return this.realEstateAd.isEstimationRequest;
        },
        isAdSubmission() {
            return this.realEstateAd.isAdSubmissionRequest;
        },
        photoUrlSizeQueryString() {
            return imageResConfig.photoUrlSizeQueryString('tooltipDetails');
        },
        hidePhoto() {
            return Boolean(this.contactType);
        },
        estimationCriteria() {
            const {realEstateAd} = this;
            let propertyCriteriaForEstimate = ['bedroomsQuantity'];
            if (realEstateAd.propertyType == 'house') {
                propertyCriteriaForEstimate = _.concat(propertyCriteriaForEstimate, [
                    'landSurfaceArea',
                    'floorQuantity',
                    'hasGarage',
                ]);
            } else {
                propertyCriteriaForEstimate = _.concat(propertyCriteriaForEstimate, [
                    'hasBalcony',
                    'floor',
                    'hasElevator',
                    'hasParking',
                    'hasCellar',
                ]);
            }
            return _(propertyCriteriaForEstimate)
                .map(criterion => {
                    const value = realEstateAd[criterion];
                    return TextFormatter.formatAndTranslateField(
                        value,
                        criterion,
                        realEstateAd,
                        {translationContext: 'estimationResults'}
                    );
                })
                .compact()
                .value();
        },
    },
    template: template(),
};
