const template = require('./ImportFilters.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const internalValues = require('../../../form/components/mixins/internalValues');

const LIMIT_VALUES = [
    10,
    50,
    100,
    2000,
];

// @vue/component
module.exports = {
    constants: {
        LIMIT_VALUES,
    },
    mixins: [
        i18nMixin({
            prefix: 'ImportFilters.',
            keys: [
                'limitPlaceholder',
                'filenamePlaceholder',
                'applyFilenameFilterText',
            ],
        }),
        internalValues({
            props: [
                'displayLimit',
                {
                    prop: 'filenameRegex',
                    autoSendEvent: false,
                },
            ],
        }),
    ],
    props: {
        displayLimit: {
            type: Number,
            default: undefined,
        },
        filenameRegex: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        applyFilenameFilter() {
            this.sendUpdateEvent('filenameRegex');
        },
    },
    template: template(),
};
