const _ = require('lodash');

const ResourceManager = require('./utils/ResourceManager');
const LocationItemHelper = require('./locationItems/LocationItemHelper');

module.exports = {
    ask,
    getEmptyGeolocationItem,
    getTravelTimeItem,
    getDrawingItem,
    convertToLocationItems,
};

function ask(query, callback) {
    return ResourceManager.getJsonResource('suggest', query, callback);
}

function convertToLocationItems(suggestions, options) {
    return _.map(suggestions, suggestion => LocationItemHelper.getInstance(suggestion, options));
}

function getTravelTimeItem(travelTimeZone, options) {
    return LocationItemHelper.getInstance(travelTimeZone || {type: 'travelTimeZone'}, options);
}

function getEmptyGeolocationItem(options) {
    return LocationItemHelper.getInstance({type: 'circleZone'}, options);
}

function getDrawingItem(drawnZone = {}) {
    return LocationItemHelper.getInstance(_.assignIn(drawnZone, {type: 'drawnZone'}));
}
