const _ = require('lodash');
const $ = require('jquery');
const ServerConfig = require('./ServerConfig');
const Errors = require('./utils/Errors');
const LoadScriptHelper = require('./utils/LoadScriptHelper');

let scriptLoader = null;

const f4Proxy = {
    api: null,
    init: function initApi(callback, context) {
        if (!scriptLoader) {
            const apiUrl = ServerConfig.config.apiUrl;
            scriptLoader = $.Deferred(function deferredScriptLoader(defer) {
                const onF4Loaded = function () {
                    if (!f4Proxy.api) {
                        // set the api
                        f4Proxy.api = window.f4.map;
                    }

                    defer.resolve();
                };

                if (window.f4) {
                    onF4Loaded();

                } else {
                    window.onF4MapApiLoaded = onF4Loaded;
                    const apiOptions = {
                        callback: 'onF4MapApiLoaded',
                    };
                    if (ServerConfig.config.mapApiKeyForClient) {
                        apiOptions.api_key = ServerConfig.config.mapApiKeyForClient;
                    }
                    _.extend(apiOptions, ServerConfig.f4mapApiOptions);
                    const scriptQuerystring = _.map(apiOptions, (value, key) => key + '=' + value).join('&');
                    const scriptUrl = apiUrl + '?' + scriptQuerystring;
                    LoadScriptHelper.loadScript(scriptUrl, {
                        crossoriginAllowed: true,
                        errorCallback: function afterF4Loaded(error) {
                            // Return error
                            Errors.showUnexpectedError('Failed to load F4 Map api at ' + apiUrl + ' : ' + (error && error.message));
                            defer.reject(error);
                        },
                        attributes: {
                            'data-f4map': 'true', // Set attribute in script so that we can obtain core url in apiUrl.
                            crossorigin: 'anonymous', // for Errorception
                        },
                    });
                }
            });
        }

        // Add callback when api loaded
        scriptLoader.done(function () {
            //call cb on success
            if (context) {
                callback.call(context);
            } else {
                callback();
            }
        });
    },
};

module.exports = f4Proxy;
