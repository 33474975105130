const LocationField = require('./LocationField');
const CheckboxesField = require('./CheckboxesField');
const SelectPickerField = require('./SelectPickerField');
const MinMaxField = require('./MinMaxField');
const NewPropertyField = require('./NewPropertyField');
const GeocodingSelectPickerField = require('./GeocodingSelectPickerField');
const NamedCheckboxField = require('./NamedCheckboxField');
const FilterTypeSelectPickerField = require('./FilterTypeSelectPickerField');
const FilterTypeRadioField = require('./FilterTypeRadioField');
const HousingMinMaxField = require('./HousingMinMaxField');

const SearchFields = require('./SearchFields');

module.exports = {init};

function init() {
    SearchFields.registerFieldClass({
        locations: LocationField,
        filterTypeSelectPicker: FilterTypeSelectPickerField,
        filterTypeRadio: FilterTypeRadioField,
        checkboxes: CheckboxesField,
        minRooms: SelectPickerField,
        maxRooms: SelectPickerField,
        minMaxPrice: MinMaxField,
        minMaxRooms: MinMaxField,
        minMaxBedrooms: HousingMinMaxField,
        minMaxArea: MinMaxField,
        minMaxGardenSurfaceArea: HousingMinMaxField,
        newProperty: NewPropertyField,
        onTheMarketCheckboxes: CheckboxesField,
        geocoding: GeocodingSelectPickerField,
        boolean: NamedCheckboxField,
        energyClassification: NamedCheckboxField,
        extensionType: SelectPickerField,
    });
}
