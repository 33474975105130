const _ = require('lodash');
const FacadeCameraControllerView = require('./FacadeCameraControllerView');
const Options = require('./Options');
const savedOptions = Options.read();
const ProgrammeModels = savedOptions.cutProgramme ? require('./ProgrammeModelsWithCrop') : require('./ProgrammeModels');
const {EventEmitter} = require('events');
const PROGRAMME_MARKER_MIN_HEIGHT = 5;
const EventPack = require('./utils/EventPack');
const {computeDefaultFacadesCameras} = require('./utils/FacadeCameras');

module.exports = class ProgrammeModelsManager extends EventEmitter {
    constructor(map, sideMapView) {
        super();
        this._sideMapView = sideMapView;
        this._mapEventPack = new EventPack();
        this._programmeModels = {};
        this._sceneryModels = {};
        this._hideSceneryModels = false;
        this._animationTimerId = null;
        this._selectedProgramme = null;
        this._loadedSelectedProgrammeId = null;
        this._facadeCameraControllerView = new FacadeCameraControllerView({
            map,
            cameraManager: sideMapView.getCameraManager(),
            sunAnimator: this._sideMapView.sunAnimatorView.getSunAnimator(),
        });
        this.setMap(map);
    }

    setLotHovered(realEstateAd, hovered) {
        const programmeId = getLotProgrammeId(realEstateAd);
        if (programmeId) {
            _.each(this._getModelsForProgramme(programmeId), programmeModel => {
                programmeModel.setLotHovered(realEstateAd, hovered);
            });
        }
    }

    setSelectedLot(realEstateAdLot, selected) {
        const lotId = _.get(realEstateAdLot, 'id');
        const selectedLotId = _.get(this._selectedLot, 'id');

        if (lotId != selectedLotId || !selected) {
            // remove current lot
            this._selectedLot = null;
            const programmeProperlyLoaded = _.has(realEstateAdLot, 'programme.relatedAdsIds');
            if (lotId && selected && programmeProperlyLoaded) {
                this._selectedLot = realEstateAdLot;
                this._updateSelectedLot(realEstateAdLot, selected);
            } else if (!lotId) {
                this._updateSelectedLot(null, selected);
            }
        }
    }

    _updateSelectedLot(realEstateAdLot, selected) {
        const programme = _.get(realEstateAdLot, 'programme');
        if (programme && selected) {
            this.setSelectedProgramme(programme, true);
        }
        const lotId = _.get(realEstateAdLot, 'id');
        _.each(this._programmeModels, programmeModel => {
            programmeModel.setSelectedLotAdId(lotId, selected);
        });
    }

    setSelectedProgramme(realEstateAdProgramme, selected) {
        const selectedProgrammeId = _.get(this._selectedProgramme, 'id');
        const programmeId = _.get(realEstateAdProgramme, 'id');
        if (programmeId != selectedProgrammeId || !selected) {
            this._selectedProgramme = null;
            this._facadeCameraControllerView.clearProgrammeId();
            if (programmeId && selected) { // select new programme
                this._selectedProgramme = realEstateAdProgramme;
                this._propagateSelectedProgramme(realEstateAdProgramme, selected);
                this._selectedProgrammeLoaded();
            } else {
                this._propagateSelectedProgramme(realEstateAdProgramme, selected);
                this._loadedSelectedProgrammeId = null;
                this._setBuildingsHeightAroundProgramme();
                this._notifyProgrammeModelSelected(false);
            }
        } else if (realEstateAdProgramme == null) {
            this._selectedProgramme = null;
            this._updateFacadeCameraVisibility(null, null);
            this._notifyProgrammeModelSelected(false);
        }
        this._updateProgrammeModelMarkerHeight(); // when leaving lot of cropped building, we need to refresh marker height
    }

    _getProgrammeModelHeight(realEstateAdProgramme) {
        let modelHeight = null;
        _.each(this._getModelsForProgramme(realEstateAdProgramme.id), programmeModel => {
            const programmeModelHeight = programmeModel.getModelHeight();
            if (programmeModelHeight != null) {
                modelHeight = Math.max(modelHeight, programmeModelHeight);
            }
        });
        return modelHeight;
    }

    _updateProgrammeModelMarkerHeight() {
        if (this._selectedProgramme) {
            const modelHeight = this._getProgrammeModelHeight(this._selectedProgramme);
            if (modelHeight != null) {
                const markerHeight = modelHeight + PROGRAMME_MARKER_MIN_HEIGHT;
                _.defer(() => {
                    //call it after a frame so _detailedSheetAdOnMapView had a chance to change current ad
                    this._sideMapView.updateMarkerHeight(this._selectedProgramme, markerHeight);
                });
            }
        }
    }

    _selectedProgrammeLoaded() {
        const modelsForProgramme = this._getModelsForProgramme(this._selectedProgramme.id);
        if (!_.isEmpty(modelsForProgramme) && this._loadedSelectedProgrammeId !== this._selectedProgramme.id) {
            this._updateFacadeCameraVisibility(modelsForProgramme, this._selectedProgramme.id);
            this._setBuildingsHeightAroundProgramme(modelsForProgramme);
            this._hideBlurOverlays();
            this._updateProgrammeModelMarkerHeight();
            this._notifyProgrammeModelSelected(true);
            // avoid the whole loop if already done for this programme
            this._loadedSelectedProgrammeId = this._selectedProgramme.id;
        }
    }

    _updateFacadeCameraVisibility(modelsForProgramme, programmeId) {
        // Just use the first program model: all facade cameras should be the same
        const modelToUse = _.first(modelsForProgramme);
        if (modelToUse) {
            this._facadeCameraControllerView.show({
                facadeCameras: modelToUse.getFacadeCameras(),
                facadeCamerasId: modelToUse.getFacadeCamerasId(),
                programmeId,
            });
        } else {
            this._facadeCameraControllerView.hide();
        }
    }

    _hideBlurOverlays() {
        this._sideMapView.setBlurOverlaysVisible(false);
    }

    _notifyProgrammeModelSelected(selected) {
        this.emit('programmeModelSelected', selected);
    }

    getFacadeCameraControllerView() {
        return this._facadeCameraControllerView;
    }

    _propagateSelectedProgramme(realEstateAd, selected) {
        _.each(this._programmeModels, programmeModel => {
            programmeModel.setSelectedProgramme(selected ? realEstateAd : null);
        });
    }

    _setBuildingsHeightAroundProgramme(modelsForProgramme = null) {
        const mustBeLow = Boolean(_.find(modelsForProgramme, programmeModel => {
            return programmeModel.getModel()._lowerBuildingAround;
        }));
        this._facadeCameraControllerView.setBuildingsHeightAroundProgramme(mustBeLow);
        this._facadeCameraControllerView.updateBuildingsHeightAroundProgramme();
    }

    _getMapForBuildingModel() {
        return this._isIn3d ? this.map : null;
    }

    _onModelBuildingLoaded(buildingModel) {
        if (!_.isEmpty(buildingModel.programIds)) {
            const {facadeCameras, modelBoundingBox} = buildingModel;
            const programmeModel = new ProgrammeModels(this, {
                map: this._getMapForBuildingModel(),
                model: buildingModel,
                facadeCameras: facadeCameras || computeDefaultFacadesCameras(this.map, modelBoundingBox),
                facadeCamerasId: buildingModel.facadeCamerasId,
            });
            if (this._selectedProgramme) {
                programmeModel.setSelectedProgramme(this._selectedProgramme);
            }
            if (this._selectedLot) {
                programmeModel.setSelectedLotAdId(this._selectedLot.id, true);
            }
            this._programmeModels[buildingModel.id] = programmeModel;
            if (this._selectedProgramme && programmeModel.hasProgramme(this._selectedProgramme.id)) {
                this._selectedProgrammeLoaded();
            }
        }
        if (buildingModel.isModelScenery) {
            this._sceneryModels[buildingModel.id] = buildingModel;
            if (this._hideSceneryModels) {
                setBuildingModelVisible(buildingModel, false);
            }
        }
    }

    _onModelBuildingUnloaded(building) {
        if (this._programmeModels[building.id]) {
            this._programmeModels[building.id].unload();
        }
        delete this._programmeModels[building.id];
        delete this._sceneryModels[building.id];
    }

    setSceneryModelsVisible(visible) {
        if (this._hideSceneryModels !== visible) {
            this._hideSceneryModels = visible;
            _.each(this._sceneryModels, _.partial(setBuildingModelVisible, _, visible));
        }
    }

    setMap(map) {
        this._mapEventPack.removeAllListeners();
        this.map = map;
        if (map) {
            this._isIn3d = map.isIn3d();
            this._mapEventPack.on(map, {
                on3dchange: (isIn3d) => {
                    if (this._isIn3d != isIn3d) {
                        this._isIn3d = isIn3d;
                        const mapForModel = this._getMapForBuildingModel();
                        _.each(this._programmeModels, programmeModel => {
                            programmeModel.setMap(mapForModel);
                        });
                    }
                },
            });
            this._mapEventPack.on(map.buildingLayer, {
                modelBuildingLoaded: _.bind(this._onModelBuildingLoaded, this),
                modelBuildingUnloaded: _.bind(this._onModelBuildingUnloaded, this),
            });
            this._preloadMaterial(map);
        } else {
            this._isIn3d = false;
        }
        const mapForModel = this._getMapForBuildingModel();
        _.each(this._programmeModels, programmeModel => {
            programmeModel.setMap(mapForModel);
        });
        this._facadeCameraControllerView.setMap(map);
    }

    _preloadMaterial(map) {
        if (!this.hasPreloadedMaterials) {
            this.hasPreloadedMaterials = true;
            if (map._renderer && map._renderer._renderEngine) {
                const renderEngine = map._renderer._renderEngine;
                // TODO check if crop materials can be skipped when savedOptions.cutProgramme is not set
                renderEngine.createMaterial('dirlight_texture_envmap_uniformColor_heightCropping');
                renderEngine.createMaterial('dirlight_texture_envmap_heightCropping');
                renderEngine.createMaterial('dirlight_texture_envmap');
                renderEngine.createMaterial('dirlight_texture_envmap_uniformColor');
                renderEngine.createMaterial('nolight');
            }
        }
    }

    hasLotModel(realEstateAdLot) {
        const programmeModel = this._getProgrammeModelForLot(realEstateAdLot);
        return (programmeModel != null && programmeModel.hasLotModel(realEstateAdLot.id));
    }

    updateLotPositionAndHeightFromModel(realEstateAdLot) {
        const programmeModel = this._getProgrammeModelForLot(realEstateAdLot);
        if (programmeModel) {
            _.extend(realEstateAdLot, programmeModel.getLotPositionAndHeightFromModel(realEstateAdLot));
        }
    }

    _getProgrammeModelForLot(realEstateAdLot) {
        return _.first(_.filter(this._programmeModels, model => model.hasLot(realEstateAdLot.id)));
    }

    centerOnAd(realEstateAd) {
        const programmeId = adToProgrammeId(realEstateAd);
        const modelsForProgramme = this._getModelsForProgramme(programmeId);
        this._updateFacadeCameraVisibility(modelsForProgramme, programmeId);
    }

    isCameraAlreadyHandled(realEstateAd) {
        // if there is an already loaded model for ad, then it'll handle camera on its own
        const programmeId = adToProgrammeId(realEstateAd);
        const modelsForProgramme = this._getModelsForProgramme(programmeId);
        if (_.isEmpty(modelsForProgramme)) {
            return false;
        } else {
            return !_.isEmpty(_.first(modelsForProgramme).getFacadeCameras());
        }
    }

    isBlurOverlayHidden(realEstateAd) {
        const programmeId = adToProgrammeId(realEstateAd);
        const modelsForProgramme = this._getModelsForProgramme(programmeId);
        return !_.isEmpty(modelsForProgramme);
    }

    canDisplayMarkerForAd(realEstateAd) {
        const programmeId = getLotProgrammeId(realEstateAd);
        if (!programmeId) {
            return true;
        } else {
            const modelsForProgramme = this._getModelsForProgramme(programmeId);
            return _.isEmpty(modelsForProgramme);
        }
    }

    _getModelsForProgramme(programmeId) {
        return _.filter(this._programmeModels, model => model.hasProgramme(programmeId));
    }

    onLotMouseOver(realEstateAd) {
        this.emit('lotMouseOver', realEstateAd);
    }

    onLotMouseOut(realEstateAd) {
        this.emit('lotMouseOut', realEstateAd);
    }

    onCameraMarkerClick(facadeCameraInfo) {
        this.emit('cameraMarkerClick', facadeCameraInfo);
    }
};

function getLotProgrammeId(realEstateAd) {
    return realEstateAd.programme ? realEstateAd.programme.id : realEstateAd.programmeRef;
}

function adToProgrammeId(realEstateAd) {
    return getLotProgrammeId(realEstateAd) || realEstateAd.id;
}

function setBuildingModelVisible(buildingModel, visible) {
    if (_.has(buildingModel, 'model.mesh')) {
        buildingModel.model.mesh.visible = visible;
    }
}
