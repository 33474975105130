const template = require('./RealEstateEstimatorSecondaryDescriptor.jade');
const CommonStepMixin = require('../../components/mixins/CommonStepMixin');
const {ESTIMATOR_STORE_MODULE_NAME} = require('../../Constants');

const FLAT_OPTIONS = [
    {label: 'flat', value: {}},
    {label: 'duplex', value: {isDuplex: true}},
    {label: 'triplex', value: {isTriplex: true}},
];

// @vue/component
module.exports = {
    mixins: [
        CommonStepMixin(ESTIMATOR_STORE_MODULE_NAME),
    ],
    computed: {
        title() {
            return this.realEstatePropertyDescriptorTranslate('sections.describeMore', {context: this.context});
        },
        flatTypeOptions() {
            return this.translateOptions(FLAT_OPTIONS, 'flatType');
        },
        shouldAskForBalconyOrTerrace() {
            return this.isFlatLike;
        },
        shouldAskForParking() {
            return this.isFlatLike;
        },
        shouldAskForGarage() {
            return this.isHouseLike;
        },
        shouldAskForFlatType() {
            return this.isFlatLike;
        },
        showToRedecorate() {
            return this.realEstateProperty.isRefurbished === false;
        },
        showWorkToDo() {
            return this.showToRedecorate && this.realEstateProperty.toRedecorate === false;
        },
        shouldAskForBikeShed() {
            return this.isFlatLike;
        },
        shouldAskForPool() {
            return this.isHouseLike;
        },
        shouldAskForConservatory() {
            return this.isHouseLike;
        },
    },
    watch: {
        canAnswerEnergyClassification: function () {
            this.clearRealEstatePropertyStateInStore('energyClassification');
        },
        shouldAskForBalconyOrTerrace(shouldAskForBalconyOrTerrace) {
            if (!shouldAskForBalconyOrTerrace) {
                this.clearRealEstatePropertyStateInStore([
                    'hasBalcony',
                    'hasTerrace',
                ]);
            }
        },
        shouldAskForParking(shouldAskForParking) {
            if (!shouldAskForParking) {
                this.clearRealEstatePropertyStateInStore('hasParking');
            }
        },
        shouldAskForGarage(shouldAskForGarage) {
            if (!shouldAskForGarage) {
                this.clearRealEstatePropertyStateInStore('hasGarage');
            }
        },
        shouldAskForBikeShed(shouldAskForBikeShed) {
            if (!shouldAskForBikeShed) {
                this.clearRealEstatePropertyStateInStore('hasBikeShed');
            }
        },
        shouldAskForPool(shouldAskForPool) {
            if (!shouldAskForPool) {
                this.clearRealEstatePropertyStateInStore('hasPool');
            }
        },
        shouldAskForConservatory(shouldAskForConservatory) {
            if (!shouldAskForConservatory) {
                this.clearRealEstatePropertyStateInStore('hasConservatory');
            }
        },
        shouldAskForFlatType(shouldAskForFlatType) {
            if (!shouldAskForFlatType) {
                this.clearFlatTypePropertyStateInStore();
            }
        },
    },
    methods: {
        clearFlatTypePropertyStateInStore() {
            this.commitToStore('clearFlatType');
        },
    },
    template: template(),
};
