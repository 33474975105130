const _ = require('lodash');
const FormUtils = require('../fields/FormUtils');
const Account = require('../authentication/Account');
const ApplicationConfig = require('../app/ApplicationConfig');
const LocalStorage = require('./localStorage/LocalStorage');

module.exports = {
    getUpdater,
    getApprovalQuestionsWithOptions,
    getUserInfo,
    getDefaultApprovalQuestions,
    computeUpdateData,
    accountToForm,
};

const defaultApprovalQuestions = [
    'wantsToReceiveDirectOffersAndNewsLetter',
    'wantsToReceivePartnerOffers',
];

const accountProperties = _.union([
    'email',
    'phone',
    'name',
    'firstName',
    'lastName',
    'title',
    'postalCode',
    'wantsToReceiveSimilarSuggestions',
], defaultApprovalQuestions);

function getDefaultApprovalQuestions() {
    return defaultApprovalQuestions;
}

function getUpdater(options) {
    const $form = options.$form;
    const formPaths = options.paths || {};
    const destinationPaths = options.destinationPaths || getAccountPaths();
    if (options.shouldFillForm !== false) {
        fillWithUserInfos();
    }

    return {
        fillWithUserInfos,
        updateAccount,
    };

    function fillWithUserInfos() {
        const account = Account.getAuthenticatedAccount() || {};
        FormUtils.setValues($form, accountToForm(account, formPaths, destinationPaths));
    }

    function updateAccount(callback) {
        callback = callback || _.noop;
        const sender = FormUtils.readValues($form);
        if (sender) {
            const accountUpdates = formToAccount(sender);
            setLocalStorage(accountUpdates);
            computeUpdateData(accountUpdates, (err, updateData) => {
                if (updateData) {
                    Account.update(updateData, callback, {emitAccountChange: false});
                } else {
                    callback(err);
                }
            });
        } else {
            callback(null);
        }
    }

    function formToAccount(formValues) {
        return convert(formValues, formPaths, destinationPaths);
    }
}

function setLocalStorage(accountUpdates) {
    if (accountUpdates.wantsToReceiveSimilarSuggestions === false) {
        LocalStorage.setValue('wantsToReceiveSimilarSuggestionsLastUpdate', new Date());
    }
}

function getUserInfo() {
    const account = Account.getAuthenticatedAccount() || {};
    const accountPaths = getAccountPaths();
    return convert(account, accountPaths);
}

function convert(source, sourcePaths, destPaths) {
    const result = {};
    _.each(accountProperties, property => {
        const sourcePath = sourcePaths && sourcePaths[property] || property;
        const destPath = destPaths && destPaths[property] || property;
        const value = _.get(source, sourcePath);
        if (!_.isUndefined(value)) {
            _.set(result, destPath, value);
        }
    });
    return result;
}

function accountToForm(account, formPaths, destinationPaths = getAccountPaths()) {
    return convert(account, destinationPaths, formPaths);
}

/**
 * @description keep properties from 'source' that should be updated, and returns the new object
 * @param {Object} source
 * @param {Object} dest
 * @returns {Object}
 */
function keepOnlyPropertiesToUpdate(source, dest = {}) {
    return _.pickBy(
        _.mapValues(source, (value, key) => {
            if (_.isObject(value)) {
                const result = keepOnlyPropertiesToUpdate(value, dest[key]);
                return _.isEmpty(result) ? null : result;
            } else {
                return shouldUpdate(value, dest[key]) ? value : null;
            }
        }),
        _.negate(_.isNull)
    );
}

function shouldUpdate(source, dest) {
    // update if dest value is falsy or boolean, and if both values are different
    return (!dest || _.isBoolean(dest)) && (source !== dest);
}

function getApprovalQuestionsWithOptions(questions = defaultApprovalQuestions) {
    return _.transform(questions, (accumulator, question) => {
        if (_.isString(question)) {
            question = {name: question};
        }
        accumulator[question.name] = _.extend({display: shouldDisplayQuestion(question)}, question);
    }, {});
}

function shouldDisplayQuestion(question) {
    const account = Account.getAuthenticatedAccount() || {};
    if (_.isFunction(question.shouldDisplay)) {
        return question.shouldDisplay(account);
    } else {
        const answer = account[question.name];
        return !_.isBoolean(answer) || (answer === false && !question.isReversed);
    }
}

function computeUpdateData(accountUpdate, callback) {
    Account.getAccountAndCreateGuestIfNeeded(function (err, account) {
        if (err) {
            callback(err);
        } else {
            //don't save email when you are registered and you already have an email
            if (Account.isRegistered() && account.email) {
                delete accountUpdate.email;
            }
            if (Account.isRegistered()) {
                accountUpdate = keepOnlyPropertiesToUpdate(accountUpdate, account);
            }

            if (!_.isEmpty(accountUpdate)) {
                accountUpdate.id = account.id;
                callback(null, accountUpdate);
            } else {
                callback();
            }
        }
    });
}

function getAccountPaths() {
    return {
        phone: 'contact.phone',
        name: 'contact.name',
        firstName: 'contact.firstName',
        lastName: 'contact.lastName',
        postalCode: ApplicationConfig.applicationPro ? 'company.address.postalCode' : 'contact.postalCode',
        title: 'contact.title',
    };
}
