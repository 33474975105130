const StatsManager = require('../stats/StatsManager');
const Account = require('../authentication/Account');
const {getTrackedOriginValues} = require('../app/TrackedOrigin');
const AdLabelHelper = require('../../common/AdLabelHelper');
const TextFormatter = require('../../common/TextFormatter');

let context;

module.exports = {
    init,
    get,
};

function init(realEstateAd, {inHighlightedBox, inLeadingAdsBox, openedFrom, isHighlightedInList}) {
    const residenceTags = TextFormatter.getFormattedResidenceTags(realEstateAd);
    const {
        id,
        status: {highlighted},
        with3dModel,
        endOfPromotedAsExclusive,
        onlineBookingUrl,
    } = realEstateAd;
    context = {
        id,
        shouldSkipStat: StatsManager.shouldSkipStat(realEstateAd),
        referrer: Account.getReferrer(),
        inHighlightedBox, // shown as a leading ad in results
        inLeadingAdsBox,
        highlighted,
        isHighlightedInList,
        openedFrom,
        trackedOrigin: getTrackedOriginValues(),
        with3dModel,
        isAdWithVirtualTour360: AdLabelHelper.isAdWithVirtualTour360(realEstateAd),
        isAdWithHighlightedVideo: AdLabelHelper.isAdWithHighlightedVideo(realEstateAd),
        residenceTags: residenceTags || [],
        isPromotedAsExclusive: endOfPromotedAsExclusive > 0,
        hasOnlineBooking: Boolean(onlineBookingUrl),
    };
    return context;
}

function get() {
    return context;
}
