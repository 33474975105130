const requiresRoleTemplate = require('../templates/requiresRoleTemplate.jade');
const VueView = require('../vue/VueView');
const i18nMixin = require('../vue/components/mixins/i18n');

module.exports = class RequiresRoleView extends VueView {
    show() {
        // @vue/component
        super.show(null, {
            mixins: [
                i18nMixin({
                    prefix: 'errorPages.requiresRole.',
                    keys: [
                        'message',
                    ],
                }),
            ],
            template: requiresRoleTemplate(),
        });
    }
};
