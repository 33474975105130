const namespaceComponents = require('../../../vue/components/helpers/namespaceComponents');
const Details = require('./Details');
const Photo = require('./Photo');
const OverviewGallery = require('./OverviewGallery');
const OverviewList = require('./OverviewList');
const Description = require('./Description');

module.exports = namespaceComponents('overview', {
    details: Details,
    gallery: OverviewGallery,
    list: OverviewList,
    photo: Photo,
    description: Description,
});
