const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const LostPasswordView = require('../authentication/LostPasswordView');

module.exports = class LostPasswordPage extends ApplicationPage {
    constructor(options) {
        options = options || {};
        const configuration = {
            name: 'lostPassword',
            title: options.title || 'Réinitialiser le mot de passe',
            isRegistrationRequired: false,
        };
        super(configuration);
        this.configuration = configuration;
    }

    open(options) {
        options = _.extend({}, options, this.configuration);
        this.options = options;
        super.open(options);
    }

    getMiddleViews() {
        return new LostPasswordView();
    }

    getUrlPattern() {
        return '/mot-de-passe-perdu';
    }
};
