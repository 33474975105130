/* global Stripe */
const ServerConfig = require('../ServerConfig');
const LoadScriptHelper = require('../utils/LoadScriptHelper');

let promise;

module.exports = {
    loadStripe,
};

function loadStripe() {
    if (!promise) {
        promise = new Promise((resolve, reject) => {
            const url = ServerConfig.config.stripeJsUrl;
            LoadScriptHelper.loadScript(url, {
                crossoriginAllowed: true,
                successCallback() {
                    if (window.Stripe) {
                        const stripe = Stripe(ServerConfig.config.stripePublicKey);
                        resolve(stripe);
                    } else {
                        reject(new Error(`Stripe.js not available at ${url}`));
                    }
                },
                errorCallback(err) {
                    reject(err);
                },
            });
        });
    }
    return promise;
}
