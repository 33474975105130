const template = require('./AppInstallBanner.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

const COMPONENT_NAME = 'AppInstallBanner';

// @vue/component
module.exports = {
    name: COMPONENT_NAME,
    mixins: [
        i18nMixin({
            prefix: `${COMPONENT_NAME}.`,
            keys: [
                'installButtonText',
                'title',
                'subtitle',
            ],
        }),
    ],
    props: {
        score: {
            type: Number,
            required: true,
        },
        iconUrl: {
            type: String,
            required: true,
        },
    },
    template: template(),
};
