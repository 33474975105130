const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const Ajax = require('../../utils/Ajax');
const PriceFormatter = require('../../../common/PriceFormatter');
const template = require('./InsuranceEstimation.jade');
const {isRentAd} = require('../../../common/AdTypeHelper');
const {FLAT_AND_HOUSE} = require('../../../common/PropertyTypes');
const Options = require('../../Options');

const BASE_URL = 'https://www.luko.eu/fr/page/bien-ici-partenariat-luko';

// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            insuranceEstimation: null,
        };
    },
    computed: {
        amountPerYear() {
            return _.get(this.insuranceEstimation, 'amount');
        },
        amountPerMonth() {
            return this.amountPerYear / 12;
        },
        visible() {
            return this.amountPerYear > 0;
        },
        formattedAmountPerMonth() {
            return PriceFormatter.formatForHtmlWithDecimal(this.amountPerMonth);
        },
        url() {
            const roundedAmountPerMonthStr = this.amountPerMonth.toFixed(2);
            const base64Amount = btoa(roundedAmountPerMonthStr);
            return `${BASE_URL}?price=${base64Amount}`;
        },
        text() {
            return translate('insuranceEstimation.linkText', {formattedAmountPerMonth: this.formattedAmountPerMonth});
        },
    },
    watch: {
        visible(visible) {
            this.$emit('update:visible', visible);
        },
    },
    created() {
        const realEstateAd = this.realEstateAd;
        if (canEstimateInsurance(realEstateAd)) {
            getInsuranceEstimation(realEstateAd, (err, insuranceEstimation) => {
                if (err) {
                    console.warn('Could not get insurance estimation, the link will not appear', err);
                } else {
                    this.insuranceEstimation = insuranceEstimation;
                }
            });
        }
    },
    template: template(),
};

function getInsuranceEstimation(realEstateAd, cb) {
    Ajax.request({
        url: '/insuranceEstimation',
        data: _.pick(realEstateAd, [
            'propertyType',
            'postalCode',
            'city',
            'surfaceArea',
            'roomsQuantity',
            'isGroundFloor',
            'isOnLastFloor',
            'floorQuantity',
        ]),
        disableErrorPage: true,
        callback: cb,
        contentType: 'application/json',
    });
}

function canEstimateInsurance(ad) {
    const {displayInsuranceEstimation = true, propertyType} = ad;
    return Options.get('insuranceEstimationEnabled')
        && displayInsuranceEstimation && isRentAd(ad) && _.includes(FLAT_AND_HOUSE, propertyType);
}
