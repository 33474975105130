const _ = require('lodash');

const template = require('./AgencyFee.jade');
const {getFileUrlFromAlias} = require('../../FileHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AgencyFee.',
            keys: [
                'agencyFeeUrl',
                'uploadFileLabel',
                'fileUrlLabel',
            ],
        }),
    ],
    props: {
        agencyFees: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        url: null,
        agencyFeeFilename: null,
    }),
    watch: {
        agencyFeeFilename(newVal, oldVal) {
            if (this.agencyFeeFilename !== null && newVal !== oldVal) {
                this.url = getFileUrlFromAlias(this.agencyFeeFilename);
            } else {
                this.url = null;
            }
        },
        url(newVal, oldVal) {
            if (newVal !== oldVal) {
                const component = this.$refs.agencyFeeUploader;
                const isUrlEmpty = !this.url;
                const isUrlChangedManually = newVal !== getFileUrlFromAlias(this.agencyFeeFilename);

                if (isUrlEmpty || isUrlChangedManually) {
                    component.uploader.setFiles([]);
                }
            }
        },
    },
    mounted() {
        this.agencyFeeFilename = _.get(this, 'agencyFees.filename', null);
    },
    template: template(),
};
