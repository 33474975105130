const _ = require('lodash');

const template = require('./AddSavedSearch.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AddSavedSearch.',
            keys: [
                'createSavedSearch',
            ],
        }),
    ],
    data() {
        return {
            isHovering: false,
            isActive: false,
        };
    },
    computed: {
        hoveringModifier() {
            return _.pick(this, 'isHovering');
        },
        svgModifiers() {
            return _.pick(this, [
                'isActive',
                'isHovering',
            ]);
        },
    },
    template: template(),
};
