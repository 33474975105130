const Account = require('../authentication/Account');
const _ = require('lodash');
const async = require('async');

module.exports = {
    compute,
};

function compute({address, position, duration, mode}, cb) {
    const data = {
        duration: +duration,
        lat: position.lat,
        lng: position.lng,
        address,
        mode,
    };
    async.waterfall([
        Account.getAccountAndCreateGuestIfNeeded,
        (account, cb) => {
            data.accountId = account.id;
            Account.postJson({
                url: '/zone-by-time',
                timeout: 30000, //30 seconds
                data,
                serverErrorMessage: 'zone-by-time',
                disableErrorPage: true,
                callback: (err, result) => {
                    if (!err && _.get(result, 'success')) {
                        cb(null, result.zone);
                    } else {
                        cb(err || new Error('No result found for /zone-by-time request.'));
                    }
                },
            });
        },
    ], (err, zone) => {
        if (err) {
            err.isTravelTime = true;
            cb(err);
        } else {
            cb(null, zone);
        }
    });
}
