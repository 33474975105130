const template = require('./AlertButton.jade');

const i18nMixin = require('../../vue/components/mixins/i18n');
const SavedSearches = require('../../search/SavedSearches');
const EventPack = require('../../utils/EventPack');
const Options = require('../../Options');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            keys: [
                {
                    'goToSavedSearchPage': 'goToSavedSearchPageText',
                },
                'saveSearch',
                'removeSearch',
            ],
        }),
    ],
    props: {
        fab: Boolean,
        disabled: Boolean,
        removeOrGoToAlertButtonDisplayable: Boolean,
        extended: {
            type: Boolean,
            default: true,
        },
        searchCriteria: {
            type: Object,
            required: true,
        },
        translationContext: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            matchingSavedSearch: null,
            savedSearchNewAdsCount: null,
        };
    },
    computed: {
        showAddAlertButton() {
            return this.matchingSavedSearch == null;
        },
        showButton() {
            return this.showAddAlertButton || this.removeOrGoToAlertButtonDisplayable;
        },
        savedSearchAdsPageEnabled() {
            return Options.get('savedSearchAdsPageEnabled');
        },
        countClass() {
            return {
                'go-to-alert-button__ads-count-card--no-new-ads': this.savedSearchNewAdsCount === 0,
            };
        },
    },
    watch: {
        searchCriteria() {
            this.refreshAlert();
        },
    },
    mounted() {
        this.eventWhileShown = new EventPack();
        this.eventWhileShown.on(SavedSearches, ['savedSearchesLoaded', 'savedSearchesCountChanged'], () => {
            this.refreshAlert();
        });
        this.refreshAlert();
    },
    destroyed() {
        this.eventWhileShown.removeAllListeners();
    },
    methods: {
        t(key) {
            return this.translate(`alertButton.${key}`, {context: this.translationContext});
        },
        refreshAlert() {
            this.matchingSavedSearch = this.searchCriteria && SavedSearches.findMatchingSavedSearch(this.searchCriteria);
            this.$emit('update:disabled', false);
            const {matchingSavedSearch} = this;
            if (matchingSavedSearch) {
                const savedSearchesCount = SavedSearches.getSavedSearchesCount();
                this.savedSearchNewAdsCount = savedSearchesCount[matchingSavedSearch._id] || 0;
            }
        },
        removeAlert() {
            this.$emit('remove-alert-click', this.matchingSavedSearch._id);
            this.$emit('update:disabled', true);
        },
        goToAlert() {
            this.$emit('go-to-alert-page-click', this.matchingSavedSearch._id);
        },
    },
    template: template(),
};
