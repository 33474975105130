const $ = require('jquery');
const _ = require('lodash');

const Account = require('./authentication/Account');
const CompositeVueView = require('./vue/CompositeVueView');
const template = require('./templates/contactView.jade');
const EventPack = require('./utils/EventPack');
const phoneContactCounter = require('./analytics/phoneContactCounter');
const FavoritesManager = require('./favorites/FavoritesManager');

module.exports = class ContactView extends CompositeVueView {
    constructor() {
        super({
            template,
        });
        this._eventPack = new EventPack();
    }

    setMapEnabled(enabled) {
        const {$element} = this;
        if ($element) {
            $element.toggleClass('isDetailedSheetFullScreen', !enabled);
        }
    }

    show(options) {
        this.options = options;
        const {
            contact,
            author,
            ownerIds,
            realEstateAdId,
            askingFor,
            showMap,
            realEstateAd,
            openedFrom,
        } = options;
        this.author = author;
        if (!contact.searchCriteria) {
            contact.searchCriteria = options.searchCriteria;
        }
        const {
            onlineBookingUrl,
            onlineMeetingScheduleLink,
            status,
        } = realEstateAd;
        const view = this;

        const vueData = {
            contact,
            onlineBookingUrl,
            onlineMeetingScheduleLink,
            showFormSuccess: false,
            onTheMarket: _.defaultTo(status.onTheMarket, true),
            statisticsUrl: Account.isAccountStatsViewer() ? `/statistiques/${_.first(ownerIds)}` : undefined,
            realEstateAd,
            contactFormOptions: {
                askingFor,
                source: 'fromContactForm',
                displayPhoneNumber: false,
                author,
                openedFrom,
            },
        };
        this.vueData = vueData;
        // @vue/component
        const vueOptions = {
            data: vueData,
            computed: {
                contactNameToDisplay() {
                    return _.get(contact, 'contactNameToDisplay');
                },
            },
            methods: {
                showPhoneNumber(phoneButton, displayPhoneNumber = false) {
                    view.showPhoneNumber({
                        source: 'fromContactForm',
                        event: buildEventWithTargetFromVue(phoneButton),
                        displayPhoneNumber,
                    });
                },
                openContactForm() {
                    view.emit('openContactSection', 'footer');
                },
                openContactFormForOnlineMeeting() {
                    view.emit('openContactSection', 'footer_onlineMeeting', {askingFor: ['meeting']});
                },
                showPhoneNumberWithCounter(phoneButton) {
                    phoneContactCounter.increment();
                    this.showPhoneNumber(phoneButton, true);
                },
                callPhoneNumber(phoneButton) {
                    view.showPhoneNumber({
                        source: 'fromDetailedSheetFooter',
                        event: buildEventWithTargetFromVue(phoneButton),
                        displayPhoneNumber: true,
                    });
                },
                contactEmailSent(contactEmailSentOptions) {
                    view.closeContactSection();
                    view.emit('contactEmailSent', contactEmailSentOptions);
                },
            },
        };
        super.show({
            contact,
            getAccountImageUrl: Account.getAccountImageUrl,
        }, vueOptions);
        this.realEstateAdId = realEstateAdId;
        const $element = this.$element;
        this.bindEvents();
        this.$toggleContactInfo = $element.find('.contactToggleInfo');

        this.setMapEnabled(showMap);
    }

    updateContactList(contactRequests) {
        this.vueData.realEstateAd = {
            ...this.vueData.realEstateAd,
            contactRequests,
        };
    }

    bindEvents() {
        this._eventPack.on(this.$element, {
            click: {
                '.closeContactSectionBtn': () => {
                    this.emit('closeContactSection');
                },
            },
        });
    }

    toggleContactInfo(isVisible) {
        if (this.isShown()) {
            this.$toggleContactInfo.toggleClass('scrollUp', isVisible).toggleClass('scrollDown', !isVisible);
        }
    }

    showPhoneNumber({source, event, displayPhoneNumber = false}) {
        this.vueData.contactFormOptions.source = source;
        this.vueData.contactFormOptions.displayPhoneNumber = displayPhoneNumber;
        FavoritesManager.toggle(this.realEstateAdId, true, {$start: $(event.currentTarget)});
        //for GTM, only send one event
        if (this._isContactSectionClosed()) {
            this.emit('openContactSection', 'phone');
        } else { //no js code depending on this
            this.emit('showPhoneNumber', source);
        }
    }

    toggleMoreInfoContact(isVisible, options) {
        if (this.$element && this.$toggleContactInfo) {
            this.toggleContactInfo(!isVisible);
            this.$element.toggleClass('isVisible', isVisible);
            if (isVisible) {
                this.vueData.contactFormOptions.openedFrom = options && options.openedFrom;
            }
            if (options) {
                const {showPhoneFromSlideShow, askingFor} = options;
                if (showPhoneFromSlideShow) {
                    this.showPhoneNumber({source: 'fromSlideShow', event: options.event, displayPhoneNumber: true});
                }
                if (askingFor) {
                    this.vueData.contactFormOptions.askingFor = askingFor;
                }
            }
        } else {
            console.warn('toggleMoreInfoContact when ContactView is not shown');
            console.trace();
        }
        if (this.options && this.options.realEstateAdId) {
            $('#section-detailedSheet_' + this.options.realEstateAdId).css('position', isVisible ? 'fixed' : '');
        } //Dirty but fix mobile bug
        if (isVisible) {
            $('.contactSectionContainer').scrollTop(0);
        }
    }

    closeContactSection() {
        this.emit('closeContactSection');
        this.vueData.showFormSuccess = true;
    }

    update(options) {
        this.hide();
        this.show(_.defaults(options, this.options));
    }

    hide(options, cb) {
        if (this.isShown()) {
            this.$element.find(':focus').blur(); //remove bootstrap validator popover
        }
        this._eventPack.removeAllListeners();
        super.hide(options, cb);
    }

    _isContactSectionClosed() {
        return !this.$element.hasClass('isVisible');
    }
};

function buildEventWithTargetFromVue({$el}) {
    return {
        currentTarget: $el,
    };
}
