const _ = require('lodash');
const moment = require('../../../common/momentFr');
const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./ContactListContact.jade');
const Account = require('../../authentication/Account');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'contactView.contactList.',
            keys: [
                'failedContactMessage',
                'noMessage',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isRegistered() {
            return Account.isRegistered();
        },
        formattedDate() {
            const {date} = this.contact;
            const now = new Date();
            const dayLimitToUseRelativeDate = 1;
            const useRelativeDate = moment(now).diff(date, 'days') < dayLimitToUseRelativeDate;
            return useRelativeDate ? moment(date).fromNow() : moment(date).format('dddd DD MMMM YYYY [à] HH[h]mm');
        },
        hasError() {
            const {contact} = this;
            return contact.error || contact.failedReason;
        },
        message() {
            return this.contact.message;
        },
        footerText() {
            const {
                sender: {
                    name,
                    firstName,
                    lastName,
                    email,
                    phone,
                },
            } = this.contact;

            const usableName = _.compact([firstName, lastName]).join(' ') || name;
            const formattedPhone = new PhoneNumberFormatter(phone).phoneNumberToDisplay;

            return _.compact([
                usableName,
                email,
                formattedPhone,
            ]).join(' - ');
        },
    },
    template: template(),
};
