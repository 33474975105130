const _ = require('lodash');
const $ = require('jquery');
const WindowMessages = require('../utils/events/WindowMessages');
const {GoogleTagManagerDebugMode: debugMode} = require('../Options').read();

module.exports = {
    init,
    injectTrackingJail,
};

let jailWindow;
let jailReady = false;
const bufferedJailMessages = [];

function init({dataLayer}) {
    overrideDataLayerPushToSendToIframeJail(dataLayer);
}

function injectTrackingJail() {
    WindowMessages.on('jailReady', onJailReady);
    if (!debugMode) {
        const $trackingJail = $('<iframe src="/jail" width=0 height=0 style="display:none"></iframe>');
        $('body').append($trackingJail);
        try {
            jailWindow = $trackingJail[0].contentWindow;
        } catch (e) {
            console.log('Could not get jail window, it is probably blocked', e);
        }
    } else {
        const width = 1280;
        const height = 600;
        const left = (screen.width / 2) - (width / 2);
        const top = (screen.height / 2) - (height / 2);
        jailWindow = window.open('/jail', '_blank', 'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left,
            +',menubar=no,toolbar=no,status=no,location=no,personalbar=no,dependent=yes');
    }
}

function sendToJail(message) {
    if (jailReady) {
        try {
            // stringify message to make it serializable (click events contain HTML elements)
            const serializableMessage = JSON.stringify(message, (key, value) => {
                return value instanceof HTMLElement ? value.outerHTML : value;
            });

            jailWindow.postMessage(serializableMessage, '*');
        } catch (e) {
            console.error('Could not send message to tracking iframe, ignoring it', e);
        }
    } else {
        bufferedJailMessages.push(message);
    }
}

function onJailReady() {
    jailReady = true;
    _.each(bufferedJailMessages, sendToJail);
    bufferedJailMessages.length = 0;
}

function overrideDataLayerPushToSendToIframeJail(dataLayer) {
    const origPush = dataLayer.push;
    dataLayer.push = function (pushedData) {
        origPush.apply(dataLayer, arguments);
        sendToJail({command: 'dataLayerPush', payload: pushedData});
    };
}
