const _ = require('lodash');
const Account = require('../authentication/Account');
const VueApplicationPage = require('../pages/VueApplicationPage');
const ProspectingMapPageMixin = require('./components/ProspectingMapPageMixin');

module.exports = class AgencyProspectingMapPage extends VueApplicationPage {
    constructor() {
        super({
            name: 'prospectingMapPage',
            title: 'Carte de prospection',
            isRegistrationRequired: true,
        });
    }

    getVueComponent(options) {
        // @vue/component
        return {
            mixins: [
                ProspectingMapPageMixin,
            ],
            computed: {
                agency() {
                    return options.agency;
                },
                canDisplayProspectingMap() {
                    return Account.hasRole('prospectingMapViewer');
                },
            },
        };
    }

    getUrlPattern() {
        return '/carte-de-prospection/{agencyId}';
    }

    loadData(options, cb) {
        const {agencyId} = options;
        Account.get(agencyId, (err, agency) => {
            if (err) {
                cb(err);
            } else {
                cb(null, {agency});
            }
        });
    }

    parseUrl(url) {
        return {
            agencyId: _.last(url.split('/')),
        };
    }
};
