const _ = require('lodash');
const template = require('./IconBienici.jade');

const DEFAUT_IMG_COLOR = '#ffb90c';

// @vue/component
module.exports = {
    props: {
        animated: Boolean,
        color: {
            type: String,
            default: DEFAUT_IMG_COLOR,
        },
    },
    computed: {
        style() {
            return {
                fill: this.color,
            };
        },
        modifiers() {
            return _.pick(this, 'animated');
        },
    },
    template: template(),
};
