const _ = require('lodash');

const {i18n: {translate, exists: translationExists}} = require('fack');
const {isTerrainProgramme} = require('./ProgrammeHelper');

module.exports = {
    cleanupRawTitle,
    translateAndCleanup,
    getPropertyTypeForTitle,
};

function translateAndCleanup(translationKeys, options) {
    const propertyType = getPropertyTypeForTitle(options);
    const context = getAppropriateContext(translationKeys, options);
    options = _.extend(
        {},
        options,
        {
            propertyType,
            context,
            defaultValue: '',
        }
    );
    return cleanupRawTitle(translate(translationKeys, _.omitBy(options, _.isNil)));
}

function cleanupRawTitle(title) {
    const cleanTitle = _.trim(title
        .replace(/__((?!(__)).)*__/g, '') // remove unfilled variables
        .replace(/((\()\s*,*\s*|,*\s*(\)))/g, '$2$3') // remove space and comma after '(' or before ')'
        .replace(/\s*\(\s*\)/g, '') // remove empty parenthesis '()'
        .replace(/\s*[-:,]+(\s*[-:,]\s*)/g, '$1') // remove consecutive punctuation marks
        .replace(/\s*[-:,]\s*$/g, '') // remove trailing punctuation mark
        .replace(/\s*(-\s+|:)\s*/g, '\u00a0$1 ') // fix spaces around '-' and ':'
        .replace(/\s+(,)/g, '$1 ') // replace spaces before ',' by a space after
        .replace(/\D,/g, '$& ') // add a space after a ',' following a non-digit character
        .replace(/(,)(\D)/g, '$1 $2') // add a space after a ',' preceding a non-digit character
        .replace(/\s*(\.)\s*/g, '$1 ') // fix spaces around '.'
        .replace(/(\s)+/g, '$1') // remove superfluous spaces
    );
    if (cleanTitle == title) {
        return _.map(cleanTitle.split(/\s+-\s+/), _.upperFirst).join(' - ');
    } else {
        return cleanupRawTitle(cleanTitle);
    }
}

function getPropertyTypeForTitle({
    propertyType = 'default',
    isStudio,
    isDuplex,
    isTriplex,
    relatedAdsIds,
    minRooms,
    maxRooms,
    isLifeAnnuitySaleOnly,
    isPropertyWithoutUsufructOnly,
}) {
    if (_.includes(['others', 'unknown'], propertyType)) {
        propertyType = 'default';
    }
    if (propertyType === null) {
        if (isLifeAnnuitySaleOnly) {
            propertyType = 'lifeAnnuitySaleOnly';
        } else if (isPropertyWithoutUsufructOnly) {
            propertyType = 'propertyWithoutUsufructOnly';
        }
    } else if (propertyType == 'flat') {
        if (isStudio || maxRooms == 1) {
            propertyType = 'studio';
        } else if (isDuplex) {
            propertyType = 'duplex';
        } else if (isTriplex) {
            propertyType = 'triplex';
        } else if (minRooms == 2 && maxRooms == 2) {
            propertyType = 'twoRoomsFlat';
        } else if (minRooms == 3 && maxRooms == 3) {
            propertyType = 'threeRoomsFlat';
        }
    } else if (isTerrainProgramme({propertyType, relatedAdsIds})) {
        propertyType = 'terrainProgramme';
    }
    return propertyType;
}

function getAppropriateContext(translationKeys, {context, contexts}) {
    if (_.isEmpty(contexts)) {
        return context;
    } else if (contexts.length == 1) {
        return contexts[0];
    } else {
        return _.find(contexts, context => translationExists([translationKeys, context].join('_')));
    }
}
