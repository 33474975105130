require('es6-promise/auto');

// for IE
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove#Polyfill
(function (arr) {
    arr.forEach(function (item) {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode !== null) {
                    this.parentNode.removeChild(this);
                }
            },
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
