const SpinnerHelper = require('../../utils/SpinnerHelper');

module.exports = {
    onSubmitStart,
    onSubmitEnd,
};

function onSubmitStart($button) {
    if ($button.length) {
        SpinnerHelper.startButtonSpinner($button);
    }
}

function onSubmitEnd(button) {
    if (button.length) {
        SpinnerHelper.stopButtonSpinner(button);
    }
}
