const _ = require('lodash');
const defaultConf = require('./DefaultConfiguration');
const PropertyTypes = require('./PropertyTypes');

module.exports = {
    getDetailedSheetRegexp,
    getAgencyRealEstateAdsRegexp,
    getAgencyInfosRegexp,
    filterTypeFromUrl,
    filterTypeToUrl,
    propertyTypesFromUrl,
    propertyTypesToUrl,
    cameraToUrl,
    cameraFromUrl,
    getPtzPattern,
    getPinelPattern,
    getSearchPattern,
};

const CAMERA_REGEXP = new RegExp('^([^_]+)_([^_]+)_([^_]+)(_([^_]+)_([^_]+))?');
const DETAILED_SHEET_REGEXP = new RegExp('^/annonce/(?:(.*?)/)??([^/]+)(?:/(contact|mettre-a-la-une))?$');
const AGENCY_REALESTATEADS_REGEXP = new RegExp('^/annonces-([^/]+)$');
const AGENCY_INFOS_REGEXP = new RegExp(
    '^/(?:agence|flux|reseau|annonceur|promoteur|constructeur|particulier|pro|mandataire|commercialisateur)-([^/]+)$');
const PTZ_PATTERN = '/resultat-simulation-ptz';
const PINEL_PATTERN = '/resultats-simulation-pinel';
const DEFAULT_FILTER_TYPE = 'achat';

function getPtzPattern() {
    return PTZ_PATTERN;
}

function getPinelPattern() {
    return PINEL_PATTERN;
}

function getDetailedSheetRegexp() {
    return DETAILED_SHEET_REGEXP;
}

function getAgencyRealEstateAdsRegexp() {
    return AGENCY_REALESTATEADS_REGEXP;
}

function getAgencyInfosRegexp() {
    return AGENCY_INFOS_REGEXP;
}

function filterTypeFromUrl(value) {
    const mapping = {
        'achat': 'buy',
        'location': 'rent',
    };
    if (_.isString(value)) {
        value = _.compact(value.split(','));
    }
    return _.map(value, function (filterType) {
        return mapping[filterType];
    });
}

function filterTypeToUrl(value, defaults = []) {
    const mapping = {
        'buy': 'achat',
        'rent': 'location',
    };
    if (_.isString(value)) {
        value = value.split(',');
    }
    if (_.xor(value, defaults).length == 0) {
        return '';
    }
    return _.uniq(_.map(value, function (filterType) {
        return mapping[filterType] || (_.includes(_.values(mapping), filterType) ? filterType : DEFAULT_FILTER_TYPE);
    })).join(',');
}

function propertyTypesFromUrl(values) {
    const propertyTypes = _(values)
        .split(',')
        .map((value => PropertyTypes.FRENCH_SLUG_TO_DB[value]))
        .compact()
        .value();
    return _.isEmpty(propertyTypes) ? defaultConf.search.propertyType : propertyTypes;
}

function propertyTypesToUrl(values, defaults = []) {
    if (!values) {
        return '';
    }
    if (_.isString(values)) {
        values = values.split(',');
    }
    if (_.xor(values, defaults).length == 0) {
        return '';
    }
    return _.map(values, function (value) {
        return PropertyTypes.DB_TO_FRENCH_SLUG[value];
    }).join(',');
}

function cameraToUrl(values) {
    const valuesToConcatenate = [values.zoom, values.lon, values.lat];
    if (values['3d']) {
        valuesToConcatenate.push(values.theta, values.phi);
    }
    return valuesToConcatenate.join('_');
}

function cameraFromUrl(source) {
    const match = source ? CAMERA_REGEXP.exec(source) : null;
    if (!match) {
        return {cameraNotSet: true};
    }
    const camera = {
        zoom: +match[1],
        lon: +match[2],
        lat: +match[3],
        cameraNotSet: false,
    };
    if (match[4]) {
        camera['3d'] = true;
        camera.theta = +match[5];
        camera.phi = +match[6];
    } else {
        camera['3d'] = false;
        camera.theta = 0;
        camera.phi = 0;
    }
    return camera;
}

function getSearchPattern(prefix) {
    return new RegExp('^/' + prefix + '(?:/?([^/]+)/([^/]+)/([^/]+)(?:/([^/]+))*)?');
}
