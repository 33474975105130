const {linearInterpolation} = require('./lerpHelper');

const HUE = 349;
const SATURATION_IN_PERCENT = 78;
const LIGHTNESS_FOR_MOST_RECENT_ESTIMATION_IN_PERCENT = 50;
const LIGHTNESS_FOR_OLDEST_ESTIMATION_IN_PERCENT = 87;
const ESTIMATIONS_MAX_AGE_IN_DAYS = 90;

module.exports = {
    getColorAsHsl,
    getZIndex,
    MARKER_WIDTH_IN_PX: 15,
    MARKER_HEIGHT_IN_PX: 15,
    ESTIMATIONS_MAX_AGE_IN_DAYS,
};

function getColorAsHsl(ageInDays) {
    return `hsl(${HUE}, ${SATURATION_IN_PERCENT}%, ${getInterpolatedLightness(ageInDays)}%)`;
}

function getInterpolatedLightness(ageInDays) {
    return linearInterpolation(
        LIGHTNESS_FOR_MOST_RECENT_ESTIMATION_IN_PERCENT,
        LIGHTNESS_FOR_OLDEST_ESTIMATION_IN_PERCENT,
        ageInDays / ESTIMATIONS_MAX_AGE_IN_DAYS
    );
}

/**
 *
 * @param ageInDays
 * @param maxZIndex
 * @returns {number} Here, z-index can be a float (between 0 and maxZIndex => 16), which works with the map.
 */
function getZIndex(ageInDays, maxZIndex) {
    return linearInterpolation(maxZIndex, 0, (ageInDays / ESTIMATIONS_MAX_AGE_IN_DAYS));
}
