const util = require('util');

const originalFunctions = {};
let enabled = false;
let _socket;

module.exports = {
    init,
    destroy,
    enable,
    disable,
};

function init(socket) {
    if (enabled && _socket != socket) {
        console.error('Remote logger already enabled on another socket');
    } else if (_socket != socket) {
        _socket = socket;
        socket.on('remotelog', handleRemoteLogEvent);
    }
}

function handleRemoteLogEvent(state) {
    if (state == 'on' || state == 'true') {
        enable();
    } else {
        disable();
    }
}

function destroy() {
    if (_socket) {
        disable();
        _socket.off('remotelog', handleRemoteLogEvent);
        _socket = null;
    }
}

function enable() {
    if (!enabled) {
        wrapLogger('log', 'info');
        wrapLogger('info', 'info');
        wrapLogger('warn', 'warn');
        wrapLogger('error', 'error');
        console.log('Remote log enabled');
        enabled = true;
    }

    function wrapLogger(name, level) {
        if (!originalFunctions[name]) {
            const oldFunc = originalFunctions[name] = console[name];
            console[name] = function () {
                oldFunc.apply(console, arguments);
                _socket.emit('log', {
                    level: level,
                    message: util.format.apply(util, arguments),
                });
            };
        }
    }
}

function disable() {
    if (enabled) {
        console.log('Remote log disabled');
        originalFunctions.forEach((func, name) => {
            console[name] = func;
            delete originalFunctions[name];
        });
        enabled = false;
    }
}
