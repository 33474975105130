const $ = require('jquery');
const _ = require('lodash');
const {EventEmitter} = require('events');
const BrowserDetect = require('browser-detect');
const {DISPLAY_MODE_MAP} = require('./constants');

const APPLICATION_MAX_WIDTH = 1280; // as defined in stylus file constants.styl
const APPLICATION_MAX_WIDTH_GALLERY_3_COLUMNS_WITHOUT_RESPONSIVE = 1000;
const COMMERCIAL_AD_BANNER_WIDTH = 728;
const COMMERCIAL_AD_BLOCK_WIDTH = 300;

module.exports = class SearchListModeModel extends EventEmitter {
    constructor(defaultMode) {
        super();
        this.mode = defaultMode || DISPLAY_MODE_MAP;
        this.mapModeAvailable = true;
        const listConf = {
            itemsPerRowConf: () => 1,
            standardBlockSizeGap: COMMERCIAL_AD_BANNER_WIDTH,
        };
        this._modesConf = {
            map: {
                itemsPerRowConf: () => 2,
                standardBlockSizeGap: APPLICATION_MAX_WIDTH,
            },
            gallery: {
                itemsPerRowConf: _.bind(this._getGalleryItemsPerRow, this),
                standardBlockSizeGap: APPLICATION_MAX_WIDTH_GALLERY_3_COLUMNS_WITHOUT_RESPONSIVE,
            },
            list: listConf,
        };
        this._updateCurrentConf();
        this.itemsPerRow = this.getItemsQtyPerRaw();
        this.rowWidthUnderStandardValue = this.isRowWidthUnderStandardValue();
    }

    setMode(mode) {
        this._updateCurrentConf(mode);
        this._storeAndEmitOnChange(mode, 'mode');
        this._storeAndEmitOnChange(this.getItemsQtyPerRaw(), 'itemsPerRow');
        this._storeAndEmitOnChange(this.isRowWidthUnderStandardValue(), 'rowWidthUnderStandardValue');
    }

    _updateCurrentConf(mode = this.mode) {
        this._currentConf = this._modesConf[mode];
    }

    isRowWidthUnderStandardValue() {
        return $(window).width() <= this._getStandardBlockSizeGapConf(this._currentConf.standardBlockSizeGap);
    }

    _getStandardBlockSizeGapConf(desktopValue) {
        return BrowserDetect.isMobile() ? COMMERCIAL_AD_BLOCK_WIDTH : desktopValue;
    }

    getMode() {
        return this.mode;
    }

    setMapModeAvailable(available) {
        this._storeAndEmitOnChange(available, 'mapModeAvailable');
    }

    getMapModeAvailable() {
        return this.mapModeAvailable;
    }

    getItemsQtyPerRaw() {
        return this._currentConf.itemsPerRowConf();
    }

    _storeAndEmitOnChange(value, key) {
        if (this[key] != value) {
            this[key] = value;
            this.emit(key, value);
        }
    }

    _getMainPageContainerWidth() {
        return $('.mainPageContainer').width();
    }

    _getGalleryItemsPerRow() {
        if (BrowserDetect.isMobile()) {
            return 1;
        }
        return this._getMainPageContainerWidth() > APPLICATION_MAX_WIDTH ? 4 : 3;
    }
};
