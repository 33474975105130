const _ = require('lodash');
const template = require('./ContactedAgencies.jade');
const {i18n: {translate}} = require('fack');

// @vue/component
module.exports = {
    props: {
        agencies: {
            type: Array,
            required: true,
        },
        translationKeyPrefix: {
            type: String,
            required: true,
        },
        isSubmission: Boolean,
    },
    computed: {
        agenciesToContactSubtitle() {
            return this.realEstatePropertyDescriptorTranslate('agenciesToContactSubtitle', {
                count: _.size(this.agencies),
            });
        },
    },
    methods: {
        realEstatePropertyDescriptorTranslate(key, options) {
            return translate(this.translationKeyPrefix + key, options);
        },
    },
    template: template(),
};
