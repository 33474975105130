const {i18n: {translate}} = require('fack');
const {formatNumber} = require('../common/NumberFormatter');
const template = require('./templates/hideFullSearchView.jade');
const VueView = require('./vue/VueView');
const _ = require('lodash');
const ApplicationConfig = require('./app/ApplicationConfig');

module.exports = class HideFullSearchView extends VueView {
    constructor(options) {
        super();
        this.options = options;
        this.saveSearchAllowed = options.saveSearchAllowed;
        this.data = {
            totalResults: null,
            adsTotalLimit: null,
            isLoading: false,
            isPro: ApplicationConfig.applicationPro,
            isExtendedSearch: false,
        };
    }

    show() {
        const view = this;
        // @vue/component
        super.show(null, {
            data() {
                return view.data;
            },
            computed: {
                isSpinnerDisplayed() {
                    return this.isLoading || this.totalResults == null;
                },
                buttonText() {
                    const {totalResults} = this;
                    if (this.isSpinnerDisplayed) {
                        return translate('searchFilterView.seeResults');
                    } else if (totalResults == 0) {
                        return translate('hideResults.noResult');
                    } else if (totalResults > this.adsTotalLimit) {
                        return translate('searchFilterView.seeResults');
                    } else {
                        const translationKey = 'hideResults.seeResult' + (this.isExtendedSearch ? '_extended' : '');
                        return translate(translationKey, {count: totalResults, formattedCount: formatNumber(totalResults)});
                    }
                },
            },
            methods: {
                handleHideFullSearch() {
                    view.emit('moreFilterClose');
                },
            },
            template: template(),
        });
    }

    setLoading() {
        this.data.isLoading = true;
        if (this.isShown()) {
            this.resize();
        }
    }

    stopLoading() {
        this.data.isLoading = false;
        if (this.isShown()) {
            this.resize();
        }
    }

    resize() {
        this.emit('resizeButtonCriteriaAdvanced');
    }

    updateNbResults(searchResults) {
        const {totalResults, adsTotalLimit} = searchResults;
        _.extend(this.data, {
            totalResults,
            adsTotalLimit,
            isExtendedSearch: Boolean(_.invoke(searchResults, 'hasExtendedResults')), //searchResults can be a plain object
        });
        this.stopLoading();
    }
};
