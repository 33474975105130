const _ = require('lodash');
const $ = require('jquery');
const template = require('./AgencyContactForm.jade');
const PhoneContact = require('../../agencyInfoPage/components/PhoneContact/PhoneContact');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');
const Views = require('../../views/Views');
const ContactHelper = require('../../utils/ContactHelper');
const withoutNilValue = require('../../utils/withoutNilValue');
const sendAgencyContact = require('../sendAgencyContact');

const ACCOUNT_UPDATE_FORM_PATHS = {
    email: 'sender.email',
    firstName: 'sender.firstName',
    lastName: 'sender.lastName',
    phone: 'sender.phone',
};
const ACCOUNT_UPDATE_FIELDS = _.keys(ACCOUNT_UPDATE_FORM_PATHS);
const DEFAULT_REASON = 'search';

/**
 * @description Form to contact Agency from AgencyPage
 * @param {Event} submitted - event fired when contact is successfully sent to agency
 */
// @vue/component
module.exports = {
    components: {
        PhoneContact,
    },
    mixins: [
        i18nMixin({
            prefix: 'AgencyContactForm.',
            keys: [
                'lastName',
                'firstName',
                'email',
                'phone',
                'message',
                'submitButtonTitle',
                'successMessage',
                'errorMessage',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
    },
    data() {
        const userAccount = Account.getAuthenticatedAccount();
        const sender = withoutNilValue(_.pick(
            ContactHelper.accountToForm(
                userAccount,
                ACCOUNT_UPDATE_FORM_PATHS
            ).sender,
            ACCOUNT_UPDATE_FIELDS
        ));
        const message = this.t(`reason.options.${DEFAULT_REASON}.message`);
        return {
            formData: {
                sender,
                message,
            },
            selectedReason: DEFAULT_REASON,
            submitting: false,
        };
    },
    computed: {
        reason() {
            return this.t('reason', {returnObjectTrees: true});
        },
        reasonOptions() {
            return _.map(this.reason.options, ({subject}, key) => ({value: key, label: subject}));
        },
        hasAgencyContactPhone() {
            return _.get(this.agency.contact, 'phone');
        },
        approvalQuestions() {
            return _(ContactHelper.getApprovalQuestionsWithOptions())
                .filter('display')
                .map('name')
                .value();
        },
    },
    methods: {
        submit() {
            if (this.$refs.agencyContactForm.validateForm()) {
                this.submitting = true;
                const contact = {};
                const {sender, message} = this.formData;
                contact.sender = _.omitBy(sender, _.isNil);
                if (!_.isNil(this.message)) {
                    contact.message = message;
                }
                this.submitContact(contact);
            }
        },
        onContactError() {
            this.submitting = false;
            Views.volatileFeedback.showError(this.errorMessage);
        },
        onContactSuccess() {
            this.submitting = false;
            Views.volatileFeedback.showSuccess(this.successMessage);
            const formattedReason = this.t(`reason.options.${this.selectedReason}.subject`);
            this.$emit('submitted', formattedReason);
        },
        submitContact(contact) {
            Account.getAccountAndCreateGuestIfNeeded((err) => {
                if (err) {
                    console.error('Could not create guest account to submit agency contact form', err);
                    this.onContactError();
                } else {
                    this.updateAccount();
                    sendAgencyContact({
                        accountId: this.agency.id,
                        access_token: Account.getAccessToken(),
                    }, contact, err => {
                        if (err) {
                            this.onContactError();
                        } else {
                            this.onContactSuccess();
                        }
                    });
                }
            });
        },
        updateAccount() {
            ContactHelper.getUpdater({
                $form: $(this.$refs.agencyContactForm.$el),
                paths: ACCOUNT_UPDATE_FORM_PATHS,
                shouldFillForm: false,
            }).updateAccount(err => {
                if (err) {
                    console.error('account data cannot be updated from agency contact form :', err);
                }
            });
        },
        onReasonChange(event) {
            this.formData.message = this.reason.options[event].message;
        },
    },
    template: template(),
};
