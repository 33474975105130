const CompositeVueView = require('../vue/CompositeVueView');
const accountStatsTemplate = require('../templates/accountStats.jade');
const Highcharts = require('../utils/Highcharts');
const _ = require('lodash');
const urlUtil = require('url');
const {StatsChart} = require('../utils/statsChart');
const LoadUserAdsHelper = require('../search/LoadUserAdsHelper');
const {getImageUrlFromAlias} = require('../ImageHelper');
const Account = require('../authentication/Account');
const fack = require('fack');
const UserAdminActions = require('./utils/UserAdminActions');
const SearchUrl = require('../../common/SearchUrl');
const UrlHelper = require('../../common/UrlHelper');
const AdTypesSelectorView = require('./AdTypesSelectorAccountStatsView');
const {insertUserInfoView} = require('./UserInfoView');
const EventPack = require('../utils/EventPack');
const DateRangePicker = require('../form/components/DateRangePicker');
const StatsHelper = require('../stats/StatsHelper');
const RealEstateAdSummary = require('../agency/components/RealEstateAdSummary');
const localBannersStats = require('../../js/stats/components/LocalBannersStats');

const sortByForStats = {
    'views.total': 'views',
};

const accountContactTypes = ['buy', 'rent', 'accountContacts'];

const {
    DASHBOARD_PRO_MODE,
    DASHBOARD_AND_STATS_PRO_MOBILE_MODE,
} = require('../stats/components/constants');
const STATS_PER_CHART = {
    views: [
        'views.total',
    ],
    contacts: [
        'calls.total',
        'phoneDisplays.total',
        'contactRequests.total',
        'adSubmissions.total',
        'estimationRequests.total',
    ],
    favorites: [
        'followers.total',
    ],
};

module.exports = class AccountStatsView extends CompositeVueView {
    constructor($container) {
        super();
        this.template = accountStatsTemplate;
        this.$container = $container;
        this._eventsWhileShown = new EventPack();
    }

    hide() {
        if (this.$element) {
            _.each(this._charts, chart => {
                chart.hide();
            });
            this._charts = {};
            if (this._userAdminActions) {
                this._userAdminActions.unbind();
            }
            if (this._adTypesSelectorView) {
                this._adTypesSelectorView.hide();
                this._adTypesSelectorView = null;
            }
            super.hide();
            this._eventsWhileShown.removeAllListeners();
        }
    }

    _onAdTypeChanged() {
        this.vueData.transactionTypes = this.getTransactionTypeValue();
    }

    update(options) {
        this.hide();
        this.show(options);
    }

    show(options) {
        const {
            startDate,
            endDate,
        } = StatsHelper.getDefaultPeriod();

        this.dates = {
            startDate,
            endDate,
        };

        const account = options.account;
        this._account = account;
        const isAuthor = ((account && account.id) === Account.getAuthenticatedAccountId());
        this.isAuthor = isAuthor;
        if (options.isAncestorOfDisplayedAccount) {
            this.isAncestorOfDisplayedAccount = options.isAncestorOfDisplayedAccount;
        }
        const displayUserInfo = !isAuthor;
        if (displayUserInfo) {
            options.agencyBackgroundImageUrl = getImageUrlFromAlias(account.agencyBackgroundImage)
                || fack.resourceUrl('images/homepage/hp_pro.jpg');
        }
        options.displayUserInfo = displayUserInfo;

        const vueData = this.vueData = {
            startDate,
            endDate,
            transactionTypes: null,
            realEstateAdSummaryOptions: {},
        };

        // @vue/component
        const accountStatsView = this;
        const vueOptions = {
            components: {
                DateRangePicker,
                RealEstateAdSummary,
                localBannersStats,
            },
            data() {
                return vueData;
            },
            computed: {
                account() {
                    return options.account;
                },
                accountId() {
                    return options.accountId;
                },
                onlyOwnData() {
                    return options.onlyOwnData;
                },
                accountStatsMode() {
                    return this.isMobile ? DASHBOARD_AND_STATS_PRO_MOBILE_MODE : DASHBOARD_PRO_MODE;
                },
            },
            methods: {
                onReceivedStats(stats) {
                    accountStatsView._showGraphs(stats);
                },
                buildUrlForStat(statName) {
                    return accountStatsView._buildUrlForStat(statName);
                },
                periodChanged(dates) {
                    accountStatsView._onDatesChanged(dates);
                },
            },
        };
        super.show(options, vueOptions);
        if (displayUserInfo) {
            this._displayUserInfo(options, isAuthor);
        }
        this._updateAdCount(options.onlyOwnData);
        this._charts = {};
        if (options.reportData) {
            this._showGraphs(options.reportData);
        }
        if (!this._adTypesSelectorView) {
            this._adTypesSelectorView = new AdTypesSelectorView({$container: this.$element.find('.selector')});
            this._adTypesSelectorView.show();
            this._eventsWhileShown.on(this._adTypesSelectorView, 'adTypeChanges', _.bind(this._onAdTypeChanged, this));
        }
    }

    _displayUserInfo(options, isAuthor) {
        insertUserInfoView(this.$element.find('.accountInfoContainer'), {
            user: _.defaults(options.account, {ownedAccountCount: options.ownedAccounts && options.ownedAccounts.length}),
            relatedAccounts: options.relatedAccounts,
            isAuthor,
            hideActionsLinks: true,
            canSeeAccountStats: false,
        });
        this._userAdminActions = new UserAdminActions({$element: this.$element});
        this._userAdminActions.bind();
        const $imgLoader = this.$element.find('#imgLoader');
        this._eventsWhileShown.on($imgLoader, 'load', () => {
            this.$element.find('.accountInfoBlock').addClass('bgIsLoaded');
            $imgLoader.remove();
        });
        if ($imgLoader[0].complete) {
            $imgLoader.load();
        }
    }

    _createLinksToUserAds(options) {
        const realEstateAdSummaryOptions = _.pick(options, ['author', 'onlyOwnData', 'origin', 'userAdsResume']);
        this.vueData.realEstateAdSummaryOptions = realEstateAdSummaryOptions;
    }

    _onDatesChanged(dates) {
        this.dates = dates;
        const {startDate, endDate} = dates;
        _.extend(this.vueData, {
            startDate,
            endDate,
        });
    }

    _updateAdCount(onlyOwnData) {
        this.asyncHelper.doAsync({
            func: cb => LoadUserAdsHelper.loadUserAds({
                author: this._account,
                origin: 'dashboard',
                onlyOwnData,
            }, cb),
            callback: _.bind(this._createLinksToUserAds, this),
            name: 'loadUserAds',
        });
    }

    _showGraphs(stats) {
        if (stats.accountStats) {
            Highcharts.onReady(() => {
                if (this.$element) {
                    //still displayed
                    this._highchartsDisplay(stats);
                }
            });
        }
    }

    _highchartsDisplay({accountStats: stats, days}) {
        if (!this.$element) {
            return;
        }

        _.each(STATS_PER_CHART, (statNames, chartName) => {
            let chart = this._charts[chartName];
            if (!chart) {
                chart = this._charts[chartName] = new StatsChart(this.$element.find(`.${chartName}GraphContainer`));
            }
            chart.update({statNames, stats, days});
        });
    }

    _buildUrlForStat(statName) {
        const [statType] = statName.split('.');
        const canLinkToContacts = this.isAuthor || this.isAncestorOfDisplayedAccount || Account.hasRole('contactViewer');
        if (_.includes([
            'contactRequests',
            'adSubmissions',
            'estimationRequests',
            'calls',
        ], statType) && canLinkToContacts) {
            return this._buildContactsUrlForStat(statType);
        } else {
            return this._buildAdsUrlForStat(statName);
        }
    }

    _buildAdsUrlForStat(statName) {
        const [statType] = statName.split('.');
        const sortBy = sortByForStats[statName] || statType;
        const sort = SearchUrl.sortToUrlParameter({
            sortBy,
            sortOrder: 'desc',
        });
        const linkToAds = LoadUserAdsHelper.getLinkToAds(this._account.id);
        return UrlHelper.addURLParameters(linkToAds, sort);
    }

    _buildContactsUrlForStat(statType) {
        let linkToContacts;
        if (this.isAuthor) {
            linkToContacts = '/mes-contacts';
        } else {
            linkToContacts = '/contacts/' + this._account.id;
        }
        return urlUtil.format({
            pathname: linkToContacts,
            query: {
                fromDate: this.dates.startDate.toISOString(true),
                toDate: this.dates.endDate.toISOString(true),
                transactionType: statType === 'contactRequests' ? this.getTransactionTypeValue() : statType,
            },
        });
    }

    getTransactionTypeValue() {
        const adTypesSelector = this._adTypesSelectorView && this._adTypesSelectorView.getValue();
        return adTypesSelector == null ? accountContactTypes : adTypesSelector;
    }
};
