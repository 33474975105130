const View = require('../../views/View');
const template = require('./articlesContainer.jade');

module.exports = class ArticlesContainerView extends View {
    constructor(options) {
        super({
            template,
        });
        this.options = options;
    }

    show() {
        super.show();
        this.$articlesListContainer = this.$container.find('.articlesHub__articlesList-container');
        this.$articleContainer = this.$container.find('.articlesHub__article-container');
    }

    getArticlesListContainer() {
        return this.$articlesListContainer;
    }

    emptyArticleContainer() {
        this.$articleContainer.empty();
    }
};
