const _ = require('lodash');

const FormUtils = require('./FormUtils');

const plugins = [
    require('./plugins/CheckBoxPlugin'),
    require('./plugins/DatePlugin'),
    require('./plugins/NumberPlugin'),
    require('./plugins/PhonePlugin'),
    require('./plugins/RadioPlugin'),
    require('./plugins/TagsInputPlugin'),
    require('./plugins/SpinnerPlugin'),
    require('./plugins/SelectPickerPlugin'),
    require('./plugins/MailCheckPlugin'),
];

module.exports = {
    init,
};

function init() {
    _.each(plugins, function (plugin) {
        FormUtils.registerPlugin(plugin);
    });
}
