const ApplicationPage = require('./ApplicationPage');
const Views = require('../views/Views');
const _ = require('lodash');
const ViewManager = require('../views/ViewManager');

module.exports = class AuthenticationPage extends ApplicationPage {
    constructor({requiredForPage} = {}) {
        super();
        this.requiredForPage = requiredForPage;
        this.reloadPageOnLogin = true;
    }

    open(options) {
        ViewManager.openViews(this.getViews(), _.extend({
            headerTitle: this.options.title || null,
            requiredForPage: this.requiredForPage,
        }, options));
    }

    getMiddleViews() {
        return Views.authentication;
    }
};
