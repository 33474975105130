const _ = require('lodash');
const urlUtil = require('url');
const {i18n: {translate: t}} = require('fack');

const template = require('./UserActionsDropdown.jade');
const Account = require('../../authentication/Account');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {
    zoho: {makeZohoAccountUrl},
    agencyInfoPage: {makeAgencyInfoPageUrl},
} = require('../../Urls');
const ConfirmationModal = require('../../utils/ConfirmationModal');
const {
    deleteCredentials,
    generateAutologinLink,
    generateStockExportLink,
    geocodeAccount,
    reindexAccountAds,
} = require('../index');
const ModalMessage = require('../../utils/ModalMessage');
const ServerConfig = require('../../ServerConfig');
const JwtLightParser = require('../../utils/JwtLightParser');
const {PARTICULAR_ACCOUNT_TYPE} = require('../../../common/AccountTypes');
const moment = require('../../../common/momentFr');
const SendWelcomeEmailView = require('../../views/SendWelcomeEmailView');
const DisableAccountFormView = require('../../views/DisableAccountFormView');
const AddOwnerAccountView = require('../../views/AddOwnerAccountView');

const USER_ACTIONS_DROPDOWN_TRANSLATION_PREFIX = 'UserActionsDropdown.';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: USER_ACTIONS_DROPDOWN_TRANSLATION_PREFIX,
            keys: _([
                'accountModification',
                'addOwnerAccount',
                'ads',
                'checkEmailStatus',
                'contactedAds',
                'contacts',
                'adsNotificationBoostsHistory',
                'deleteCredentials',
                'disableAccount',
                'disableSavedSearchesForEmail',
                'enableAccount',
                'generateAutologinLink',
                'generateTokensStockAds',
                'geocodeAccount',
                'importAccounts',
                'imports',
                'prospectingMap',
                'publicPage',
                'reindexAds',
                'seeZohoAccount',
                'statistics',
                'welcomeEmail',
            ])
                .map(translationKey => [translationKey, `${translationKey}Label`])
                .fromPairs()
                .value(),
        }),
    ],
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        userNamespaceOrId() {
            const {user: {namespace, id}} = this;
            return namespace || id;
        },
        canSeeAccountStats() {
            return Account.isAccountStatsViewer();
        },
        canGenerateAutologinLink() {
            return !this.userIsDisabled && !this.user.closed && Account.hasRole('timedTokenGenerator');
        },
        isAdmin() {
            return Account.isAdmin();
        },
        isContactViewer() {
            return Account.hasRole('contactViewer');
        },
        isAdsNotificationBoostsHistoryViewer() {
            return Account.hasRole('adsNotificationBoostsHistoryViewer');
        },
        isWelcomeSender() {
            return Account.isWelcomeSender();
        },
        isAccountModifier() {
            return Account.isAccountModifier();
        },
        isImportViewer() {
            return Account.hasRole('importsViewer');
        },
        isQueryStringSearcher() {
            return Account.hasRole('queryStringSearcher');
        },
        isEmailStatusChecker() {
            return Account.hasRole('emailStatusChecker');
        },
        isProspectingMapViewer() {
            return Account.hasRole('prospectingMapViewer');
        },
        canEnableOrDisableAccount() {
            return Account.isAccountDisabler() &&
                (!_.includes(['import', 'contractType'], this.userAccountType) || Account.isAdmin());
        },
        userAccountType() {
            return this.user.accountType;
        },
        isParticularUserAccountType() {
            return this.userAccountType === 'particular';
        },
        userId() {
            return this.user.id;
        },
        userIsDisabled() {
            return this.user.disabled;
        },
        userZohoId() {
            return this.user.zohoId;
        },
        userImport() {
            return this.user.import;
        },
        userOwnerIds() {
            return this.user.ownerIds;
        },
        userCompanyName() {
            return _.get(this.user.company, 'name');
        },
        enableOrDisableAccountLabel() {
            return this.userIsDisabled ? this.enableAccountLabel : this.disableAccountLabel;
        },
        enableOrDisableAccountIcon() {
            return this.userIsDisabled ? 'md-done' : 'md-block';
        },
        zohoUrl() {
            return makeZohoAccountUrl(this.userZohoId);
        },
        allAccountEmails() {
            return Account.getAllEmails(this.user).join(',');
        },
        agencyInfoPageUrl() {
            return makeAgencyInfoPageUrl(this.user);
        },
    },
    mounted() {
        this.addOwnerAccountView = new AddOwnerAccountView();
        this.addOwnerAccountView.on('accountUpdated', () => this.$emit('account-update'));
        this.disableAccountFormView = new DisableAccountFormView();
        this.disableAccountFormView.on('accountUpdated', () => this.$emit('account-update'));
        this.sendWelcomeEmailView = new SendWelcomeEmailView();
    },
    destroyed() {
        this.addOwnerAccountView.hide();
        this.disableAccountFormView.hide();
        this.sendWelcomeEmailView.hide();
    },
    methods: {
        displayErrorModalMessage(err, errorTranslationKey) {
            ModalMessage.show({
                title: t('userDirectory.failInterjection'),
                message: t([
                    `userDirectory.${err && err.message}`,
                    `userDirectory.${errorTranslationKey}`,
                ]),
            });
        },
        displayModalMessageWithGeneratedLink({url, title, message, actionTitle}) {
            ModalMessage.show({
                title,
                message,
                actionLink: {
                    href: url,
                    title: actionTitle,
                },
            });
        },
        deleteCredentialsWithConfirmation() {
            ConfirmationModal.showConfirm({
                cb: cb => deleteCredentials(this.userId, cb),
                translationsKeysPath: 'userDirectory.deleteCredentialsConfirmationModal',
            });
        },
        geocodeAccount() {
            geocodeAccount(this.userId);
        },
        reindexAccountAdsWithConfirmation() {
            const accountId = this.userId;
            if (!accountId) {
                console.error('no account Id');
                return;
            }
            if (window.confirm(`Attention, vous allez ré-indexer toutes les annonces de l'agence ${accountId}`)) {
                reindexAccountAds(accountId);
            }
        },
        generateAutologinLink() {
            const {userId, userAccountType: accountType} = this;
            generateAutologinLink(userId, accountType, (err, results) => {
                if (err || !results || !results.success) {
                    console.error(err);
                    this.displayErrorModalMessage(err, 'tokenGeneration.actionFailed');
                } else {
                    const {token} = results;
                    const {exp} = JwtLightParser(token);
                    const baseUrl = accountType == PARTICULAR_ACCOUNT_TYPE ?
                        ServerConfig.config.wwwUrl : ServerConfig.config.proUrl;
                    const accountTypeName = accountType == PARTICULAR_ACCOUNT_TYPE ? 'COMPTE PARTICULIER' : 'COMPTE PRO';
                    const url = `${baseUrl}/?timedToken=${encodeURIComponent(token)}`;
                    const expireDateTxt = moment(new Date(exp * 1e3)).format('lll');
                    const modalOptions = {
                        url,
                        title: t('userDirectory.tokenGeneration.modalTitle'),
                        message: '<a href="' + url + '" target="_blank"> ' + accountTypeName + ' - ' + userId + '</a>'
                            + "<br/> Valable jusqu'au " + expireDateTxt,
                        actionTitle: t('userDirectory.tokenGeneration.actionBtnTitle'),
                    };
                    this.displayModalMessageWithGeneratedLink(modalOptions);
                }
            });
        },
        generateStockExportLink() {
            const {userId} = this;
            generateStockExportLink(userId, (err, results) => {
                if (err || !results || !results.success) {
                    console.error(err);
                    this.displayErrorModalMessage(err, 'stockTokenGeneration.actionFailed');
                } else {
                    const {token} = results;
                    const {protocol, host} = urlUtil.parse(ServerConfig.config.stockExporterUrl);
                    const url = urlUtil.format({
                        protocol,
                        host,
                        pathname: '/docs',
                    });
                    const modalOptions = {
                        url,
                        title: t('userDirectory.stockTokenGeneration.modalTitle'),
                        message: `Compte: ${userId}, <br>Token: <span class="stockToken">${token}</span>`,
                        actionTitle: t('userDirectory.stockTokenGeneration.actionBtnTitle'),
                    };
                    this.displayModalMessageWithGeneratedLink(modalOptions);
                }
            });
        },
        enableOrDisableAccount() {
            const {
                disableAccountFormView,
                userId: accountId,
            } = this;
            if (this.userIsDisabled) {
                disableAccountFormView.enableAccount(accountId);
            } else {
                disableAccountFormView.show({
                    accountId,
                    userName: this.userCompanyName,
                });
            }
        },
        addOwnerAccount() {
            this.addOwnerAccountView.show({
                accountId: this.userId,
                userName: this.userCompanyName,
                ownerIds: this.userOwnerIds,
            });
        },
        sendWelcomeEmail() {
            this.sendWelcomeEmailView.show({
                userId: this.userId,
                userEmail: this.user.email,
            });
        },
        disableSavedSearchesForEmail() {
            ConfirmationModal.showConfirm({
                cb: _.bind(Account.disableSavedSearchesForEmail, this, this.user.email),
                translationsKeysPath: 'disableSavedSearchesForEmailModal',
            });
        },
    },
    template: template(),
};
