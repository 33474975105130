module.exports = {
    isMobile,
    isTablet,
    isAndroid,
    isIOS,
    supportWebGL,
    getWebGlContext,
    supportIosNativeScroll,
    detectWebGL,
    isFirefox,
    isGoogleChrome,
    isMSIE,
    isWindowsXP,
    isBot,
    getBrowser,
    getIOSVersion,
    getAndroidVersion,
    isEdge,
    isTouchDevice,
    getUserAgent, // exposed for additional user agent processing, like native app checks
};

function isTouchDevice() {
    return (('ontouchstart' in window)
        || (navigator.MaxTouchPoints > 0)
        || (navigator.msMaxTouchPoints > 0));
}


function isEdge(ua) {
    return /Edge/.test(getUserAgent(ua));
}

function isBlackBerry(ua) {
    return /BlackBerry/i.test(getUserAgent(ua))
        || /BB10/i.test(getUserAgent(ua))
        || /PlayBook/i.test(getUserAgent(ua))
        || /RIM/i.test(getUserAgent(ua));
}

function isBlackBerryMobile(ua) {
    return isBlackBerry(ua) && /mobile/i.test(getUserAgent(ua));
}

function isBlackBerryTablet(ua) {
    return isBlackBerry(ua) && /tablet/i.test(getUserAgent(ua));
}

function isAndroid(ua) {
    return /android/i.test(getUserAgent(ua));
}

function isAndroidMobile(ua) {
    // mobi for 'Opera Mobi'
    return isAndroid(ua) && /mobi/i.test(getUserAgent(ua));
}

function isWindowsPhone(ua) {
    return /Windows Phone/i.test(getUserAgent(ua));
}

function isIPhone(ua) {
    return /iPhone/i.test(getUserAgent(ua));
}

function isMobile(ua) {
    return isIPhone(ua) || isIPod(ua) || isAndroidMobile(ua) || isWindowsPhone(ua) || isBlackBerryMobile(ua);
}

function isAndroidTablet(ua) {
    //galaxy tab (SCH-I800) have "mobile" in user-agent
    //motorola xoom tablet can have "mobile" in user-agent
    return isAndroid(ua) &&
        (!/mobile/i.test(getUserAgent(ua))
            || /SCH-I800/i.test(getUserAgent(ua))
            || /Xoom/i.test(getUserAgent(ua)));
}

function isKindle(ua) {
    return /Silk/i.test(getUserAgent(ua));
}

function isIPad(ua) {
    return /iPad/i.test(getUserAgent(ua));
}

function isIPod(ua) {
    return /iPod/i.test(getUserAgent(ua));
}

function isTablet(ua) {
    return isIPad(ua) || isAndroidTablet(ua) || isBlackBerryTablet(ua) || isKindle(ua);
}

function isIOS(ua) {
    return isIPad(ua) || isIPhone(ua) || isIPod(ua);
}

function supportWebGL(contextAttributes) {
    const info = detectWebGL(contextAttributes);
    return info.supported;
}

function getWebGlContext(canvas, contextAttributes) {
    const contextNames = ['webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d'];
    let ctx = null;
    for (let i = 0; i < contextNames.length; i++) {
        try {
            ctx = canvas.getContext(contextNames[i], contextAttributes);
            if (ctx) {
                break;
            }
        } catch (e) {
            // ignore e
        }
    }
    return ctx;
}

function supportIosNativeScroll(ua) {
    if (isIOS(ua)) {
        const version = getIOSVersion(getUserAgent(ua));
        if (version && version.major && version.major >= 6) {
            return true;
        }
    }
    return false;
}

function getIOSVersion(ua) {
    const match = / OS ([0-9_]+)/i.exec((getUserAgent(ua)));
    if (match) {
        return {
            major: match[1].split('_')[0],
            minor: match[1].split('_')[1],
            full: match[1],
        };
    }
    return null;
}

function getAndroidVersion(ua) {
    const match = / Android ([0-9.]+)/i.exec((getUserAgent(ua)));
    if (match) {
        return {
            major: match[1].split('.')[0],
            minor: match[1].split('.')[1],
            full: match[1],
        };
    }
    return null;
}

function detectWebGL(contextAttributes) {
    const webgGLParams = ['VERSION', 'VENDOR', 'RENDERER', 'SHADING_LANGUAGE_VERSION'];

    const info = {
        supported: false,
    };
    if (typeof Ejecta == "object") {
        info.ejecta = true;
        //todo more info
        return info;
    }
    const canvas = document.createElement('canvas');
    //noinspection EmptyCatchBlockJS,UnusedCatchParameterJS
    const gl = getWebGlContext(canvas, contextAttributes);

    if (gl) {
        info.supported = true;

        webgGLParams.forEach(function (param) {
            info[param] = gl.getParameter(gl[param]);
        });
    }
    return info;
}

function isFirefox(ua) {
    return /firefox\//i.test(getUserAgent(ua));
}

function isGoogleChrome(ua) {
    //chrome without the slash might be IE
    return /chrome\//i.test(getUserAgent(ua));
}

function isMSIE(ua) {
    return /MSIE/i.test(getUserAgent(ua)) || /Trident/i.test(getUserAgent(ua));
}

function isWindowsXP(ua) {
    return /(Windows (?:NT 5\.2|NT 5\.1))/.test(getUserAgent(ua));
}

function isBot(ua) {
    return /bot|crawl|spider|slurp|snippet|externalhit|google-structured-data-testing-tool/i.test(getUserAgent(ua));
}

//uaMatch and getBrowser from https://github.com/jquery/jquery-migrate/blob/master/src/core.js
function uaMatch(ua) {
    ua = ua.toLowerCase();

    const match = /(chrome)[ /]([\w.]+)/.exec(ua) ||
        /(webkit)[ /]([\w.]+)/.exec(ua) ||
        /(opera)(?:.*version|)[ /]([\w.]+)/.exec(ua) ||
        /(msie) ([\w.]+)/.exec(ua) ||
        ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
        [];

    return {
        browser: match[1] || "",
        version: match[2] || "0",
    };
}

function getBrowser(ua) {
    const matched = uaMatch(getUserAgent(ua));
    const browser = {};

    if (matched.browser) {
        browser[matched.browser] = true;
        browser.version = matched.version;
    }

    // Chrome is Webkit, but Webkit is also Safari.
    if (browser.chrome) {
        browser.webkit = true;
    } else if (browser.webkit) {
        browser.safari = true;
    }

    return browser;
}

function getUserAgent(ua) {
    if (null == ua && typeof navigator === "object") {
        return navigator.userAgent;
    } else if (ua) {
        return ua;
    } else {
        return ""; // must be a string, usages do not handle null values
    }
}
