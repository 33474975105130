const _ = require('lodash');
const LocalStorage = require('../utils/localStorage/LocalStorage');
const template = require('./appInstallBannerContainer.jade');
const savedOptions = require('../Options').read();
const EventPack = require('../utils/EventPack');
const OpenWindow = require('../utils/OpenWindow');
const Ajax = require('../utils/Ajax');
const {isAndroid} = require('browser-detect');
const VueView = require('../vue/VueView');
const AppInstallBanner = require('./components/AppInstallBanner');
const ServerConfig = require('../ServerConfig');
const PlayStoreUrl = require('./PlayStoreUrl');

const USER_CLOSING_DATE_LOCAL_STORAGE_KEY = 'appInstallBannerUserClosingDate';
const DISPLAY_DELAY_AFTER_USER_CLOSED_BANNER = 5 * 24 * 60 * 60 * 1000;

let promptAllowedByEvent = false;
let appDetails = null;
let $appInstallBannerContainer;
let hasUserOpenedTheStore = false;
let vueView = null;

const eventPack = new EventPack();

module.exports = {
    setContainer,
    searchAndUpdateContainer,
    bind,
};

function setContainer($container) {
    if (_.isEmpty($container)) {
        hide();
    } else if ($appInstallBannerContainer !== $container) {
        hide();
        $appInstallBannerContainer = $container;
        displayIfAllowed();
    }
}

function searchAndUpdateContainer($element) {
    setContainer($element ? $element.find('.appInstallBannerContainer') : null);
}

function bind() {
    if (shouldRegisterListeners()) {
        eventPack.on(window, {
            beforeinstallprompt() {
                promptAllowedByEvent = true;
                displayIfAllowed();
            },
        });
    }
}

function shouldRegisterListeners() {
    return isAndroid()
        && !savedOptions.isInEmbeddedMode
        && !hasBannerBeenClosedRecently();
}

function isDisplayPromptAllowed() {
    return promptAllowedByEvent
        && !hasBannerBeenClosedRecently()
        && !hasUserOpenedTheStore;
}

function hasBannerBeenClosedRecently() {
    const lastTime = LocalStorage.getValue(USER_CLOSING_DATE_LOCAL_STORAGE_KEY);
    if (lastTime) {
        const delta = Date.now() - lastTime;
        return delta <= DISPLAY_DELAY_AFTER_USER_CLOSED_BANNER;
    } else {
        return false;
    }
}

function displayIfAllowed() {
    if (isDisplayPromptAllowed() && $appInstallBannerContainer) {
        if (appDetails) {
            showInElement($appInstallBannerContainer);
        } else {
            fetchDetails((err, details) => {
                if (!err && details) {
                    appDetails = details;
                    displayIfAllowed();
                }
            });
        }
    }
}

function showInElement($element) {
    const {score, icon} = appDetails;

    vueView = new VueView({
        $container: $element,
    });

    // @vue/component
    vueView.show(null, {
        name: 'AppInstallBannerContainer',
        components: {
            AppInstallBanner,
        },
        data() {
            return {
                score,
                iconUrl: icon,
            };
        },
        computed: {
            playStoreUrl() {
                return PlayStoreUrl.generate({
                    from: _.kebabCase('app-install-banner'),
                });
            },
        },
        methods: {
            onClick() {
                OpenWindow.open({url: this.playStoreUrl});
                hide();
                hasUserOpenedTheStore = true;
            },
            onClose() {
                LocalStorage.setValue(USER_CLOSING_DATE_LOCAL_STORAGE_KEY, Date.now());
                hide();
            },
        },
        template: template(),
    });
}

function fetchDetails(callback) {
    Ajax.request(ServerConfig.config.androidAppInfoUrl + '/googlePlayStore/appDetails.json', {
        disableErrorPage: true,
        callback,
    });
}

function hide() {
    $appInstallBannerContainer = null;
    if (vueView) {
        vueView.hide();
        vueView = null;
    }
}
