module.exports = {
    'boolean-radio-group': require('./BooleanRadioGroup'),
    'checkbox': require('./Checkbox'),
    'city-field': require('./CityField'),
    'default-form-page-header': require('./DefaultFormPageHeader'),
    'email-field': require('./EmailField'),
    'fake-input': require('./FakeInput'),
    'field-with-dropdown': require('./FieldWithDropdown'),
    'image-uploader': require('./ImageUploader'),
    'number-field': require('./NumberField'),
    'phone-field': require('./PhoneField'),
    'postal-code-field': require('./PostalCodeField'),
    'price-field': require('./PriceField'),
    'toggle-group': require('./ToggleGroup'),
    'range-field': require('./RangeField'),
    'select': require('./Select'),
    'surface-area-field': require('./SurfaceAreaField'),
    'switch': require('./Switch'),
    'text-field': require('./TextField'),
    'textarea': require('./Textarea'),
    'tribool': require('./Tribool'),
    'url-field': require('./UrlField'),
    'validatable-form': require('./ValidatableForm'),
    'file-uploader': require('./FileUploader'),
    'agency-fee': require('../../agency/components/AgencyFee'),
};
