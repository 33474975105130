const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const _toSentence = require('underscore.string/toSentence');

const View = require('../views/View');
const EventPack = require('../utils/EventPack');
const PriceFormatter = require('../../common/PriceFormatter');

const TOOLTIP_CONTAINER_SELECTOR = '.toolTipContainer';

module.exports = class LoanResultsView extends View {
    constructor(configuration) {
        super(_.extend({
            name: 'LoanResultsView',
        }, configuration));
        this._eventPack = new EventPack();
    }

    show(options) {
        const showOptions = _.extend({PriceFormatter, searchAdsUrl: this._getSearchAdsUrl(options)}, options);
        const {userDataRecipients} = options.results;
        if (!_.isEmpty(userDataRecipients)) {
            const partners = translate('loanSimulator.partners', {returnObjectTrees: true});
            const userDataRecipientsText = _toSentence(_.map(userDataRecipients,
                recipient => {
                    const {displayName, displayNameForResults, address} = partners[recipient];
                    const name = displayNameForResults || displayName;
                    return `${name} (${address})`;
                }), ', ', ' et ');
            showOptions.sentContactNotice = translate('loanSimulator.results.sentContactNotice', {
                partners: userDataRecipientsText,
                count: userDataRecipients.length,
            });
        }
        super.show(showOptions);
        this._bindEvents();
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
    }

    _bindEvents() {
        this._eventPack.on(this.$element.find('.backToSimulation'), 'click', () => {
            this.emit('backToSimulator');
        });
        this._eventPack.on(this.$element, ['click', 'mouseenter'], TOOLTIP_CONTAINER_SELECTOR, e => this._toggleTooltip(true, e));
        this._eventPack.on(this.$element, 'mouseleave', TOOLTIP_CONTAINER_SELECTOR, e => this._toggleTooltip(false, e));
    }

    _toggleTooltip(show, e) {
        this.$element.find('.tooltip.tooltip_' + this._getTooltipClassNameSuffix(e)).toggle(show);
    }

    _getTooltipClassNameSuffix(e) {
        return e.currentTarget.getAttribute('data-toolTip');
    }
};
