const _ = require('lodash');
const {i18n: {translate}} = require('fack');

module.exports = function getTranslationKeysMatchingContact(contact, availableTranslationsListKey) {
    return _(translate(`contactsPro.${availableTranslationsListKey}`, {returnObjectTrees: true}))
        .keys()
        .filter(action => {
            if (action === 'hasPropertyToSell') {
                return contact[action] && _.get(contact, 'fromAgencyWithEnabledHasPropertyToSellOption', true);
            } else {
                return contact[action];
            }
        })
        .value();
};
