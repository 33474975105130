const _ = require('lodash');
const SearchPageBaseFactory = require('./SearchPageBaseFactory');
const UserRealEstateAdsPage = require('../../pages/UserRealEstateAdsPage');
const SearchUrlPrefixes = require('../../../common/SearchUrlPrefixes');

module.exports = class UserRealEstateAdsPageFactory extends SearchPageBaseFactory {
    constructor(pageConfiguration) {
        super(_.defaults(pageConfiguration, {
            urlPrefixPattern: SearchUrlPrefixes.userRealEstateAdsPattern,
        }));
    }

    createPage() {
        return new UserRealEstateAdsPage(this._pageConfiguration);
    }
};
