const _ = require('lodash');
const namespaceComponents = require('../../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'estimator';

module.exports = _.extend(namespaceComponents(NAMESPACE, {
    'results-loader': require('./ResultsLoader'),
    'balcony-or-terrace': require('./BalconyOrTerraceField'),
    'standard-field': require('./StandardField'),
    'kitchen-field': require('./KitchenField'),
    'energy-classification-field': require('./EnergyClassificationField'),
    'services-presentation': require('./ServicesPresentation'),
    'prices-result': require('./PricesResult'),
    'formatted-price': require('./FormattedPrice'),
}), {
    'real-estate-estimator': require('./RealEstateEstimator'),
});
