const _ = require('lodash');
const urlUtil = require('url');
const querystring = require('querystring');
const BrowserDetect = require('browser-detect');
const async = require('async');

const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const ApplicationPage = require('../pages/ApplicationPage');
const LocalStorageSavedSearch = require('../utils/localStorage/LocalStorageSavedSearch');
const AsyncHelper = require('../utils/AsyncHelper');
const EventPack = require('../utils/EventPack');
const Account = require('../authentication/Account');
const AuthenticationPopup = require('../authentication/AuthenticationPopup');
const SaveSearchView = require('../search/SaveSearchView');
const Views = require('../views/Views');
const HomePageContainerView = require('./ContainerView');
const HomePageSearchFilterView = require('./SearchFilterView');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const Options = require('../Options');
const ApplicationConfig = require('../app/ApplicationConfig');
const SimpleContentWithAdsView = require('../views/SimpleContentWithAdsView');
const fullWidthCommercialAd = require('../templates/homePage/fullWidthCommercialAd.jade');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const safeFrameAdUtils = require('../utils/safeFrameAdUtils');
const {AD_TYPES} = require('../../common/constants/AdType');

const SAVE_SEARCH_URL_OPTION = 'creer-une-alerte';
const TRAVEL_TIME_URL_OPTION = 'temps-de-transport';
const CREATE_ACCOUNT_URL_OPTION = 'creer-mon-compte';

module.exports = class HomePageBase extends ApplicationPage {
    constructor(configuration) {
        super(_.extend({
            name: 'home',
            gtmCategory: 'Homepage',
            bodyClass: 'homePage',
        }, configuration));
        this._configuration = configuration;

        this._searchFilterView = new HomePageSearchFilterView();
        this._saveSearchView = new SaveSearchView({
            analyticsEventName: 'saved-searches-homepage',
            locationRequired: true,
        });

        this._eventPack = new EventPack();
        this._asyncHelper = new AsyncHelper();
    }

    getUrlPattern() {
        return this._configuration.url;
    }

    getUrl() {
        return this._configuration.url;
    }

    parseUrl(url) {
        const loc = urlUtil.parse(url);
        const query = loc.search;
        const parsedQuery = query ? querystring.parse(query.substring(1)) : {};
        const keysInQuery = _.keys(parsedQuery);
        return {
            openSaveSearch: _.includes(keysInQuery, SAVE_SEARCH_URL_OPTION),
            openCreateAccount: _.includes(keysInQuery, CREATE_ACCOUNT_URL_OPTION),
            openTravelTimeSearch: _.includes(keysInQuery, TRAVEL_TIME_URL_OPTION),
        };
    }

    open(options) {
        // moving this two line from constructor to open method as the elements are not loaded when moving between pages
        this._containerView = new HomePageContainerView();
        this._containerView.setViews(this._getContainedViews());
        options = _.defaults({}, options, {
            hasContent: hasHomePageContent(),
            hasFeatureLinksOnWwwUrl: !ApplicationConfig.isOnPartnersDomain,
        });
        super.open(options);
        this._eventPack.on(this._searchFilterView, {
            search: _.bind(this._search, this),
        });
        this._bindSaveSearchViewEvents(options);
        if (options.openCreateAccount) {
            this._openCreateAccountPopup();
        }
    }

    close() {
        this._eventPack.removeAllListeners();
        this._asyncHelper.cancelAll();
        safeFrameAdUtils.removeAdAndResetSafeFrameRelatedStyles();
        super.close();
    }

    getViews() {
        return [this._configuration.headerView, this._containerView];
    }

    _loadData(options, callback) {
        async.parallel(options.tasks || {}, (err, results = {}) => {
            delete options.tasks;
            callback(err, _.extend(
                {},
                options,
                results,
                {
                    search: this.processDefaultSearchBasedOnLastSearch(),
                }
            ));
        });
    }

    _openCreateAccountPopup() {
        if (!Account.isRegistered() || !ApplicationConfig.isOnPartnersDomain) {
            Views.authenticationPopup.showCreateAccount();
        } else {
            const AuthPopUp = new AuthenticationPopup();
            AuthPopUp.setURLOnClose();
        }
    }

    _bindSaveSearchViewEvents(options) {
        if (this._saveSearchView) {
            if (options.openSaveSearch) {
                this._openSaveSearchView();
            }
            if (options.openTravelTimeSearch) {
                this._openSearchByTime();
            }
        }
    }

    _openSaveSearchView(options) {
        this._saveSearchView.show(_.extend({
            pitchKey: 'pitchHomePage',
            modalType: 'criteria',
            searchClassesAdded: 'searchFilterView saveSearchWithSearchFiltersView',
            suffix: 'criteria',
            criteriaSummaryEnabled: false,
            criteriaModificationEnabled: true,
            criteriaModificationHidden: false,
            frequencyModificationEnabled: false,
            advancedCriteriaEnabled: true,
            advancedCriteriaHidden: true,
        }, options));
    }

    _openSearchByTime() {
        this._searchFilterView.openTravelTimeSearch({
            launchSearchWhenSubmit: true,
            context: 'directSearch',
        });
    }

    _search(search) {
        LocalStorageSavedSearch.save(search);
        if (BrowserDetect.isMobile()) {
            this._configuration.headerView.search(search);
        } else {
            PageManager.openPage(PagesFactories.searchPageFactory, {search});
        }
    }

    _getContainedViews() {
        return _.extend({
            '.searchEngineView': this._searchFilterView,
            '.footerView': this._configuration.footerView, // :(
        }, this._getHpContentViewIfNeeded());
    }

    _getHpContentViewIfNeeded() {
        if (hasHomePageContent()) {
            const safeFrameAdContainerId = BrowserDetect.isMobile() ? '.sfMobileAd' : '.safeFrameContentFullWidth';
            return {
                [safeFrameAdContainerId]: new SimpleContentWithAdsView({
                    overTemplate: fullWidthCommercialAd,
                    pageName: this.name,
                }),
                '.hpContent': new SimpleContentWithAdsView({
                    pageName: this.name,
                }),
            };
        }
    }
    processDefaultSearchBasedOnLastSearch() {
        const defaultSearch = _.cloneDeep(DefaultConfiguration.search);
        const lastSearch = LocalStorageSavedSearch.get() || {};
        if (lastSearch.filterType === 'rent') {
            defaultSearch.filterType = AD_TYPES.RENT;
        }
        if (lastSearch.newProperty) {
            defaultSearch.newProperty = true;
        } else {
            delete defaultSearch.newProperty;
        }
        return defaultSearch;
    }
};

function hasHomePageContent() {
    return Options.get('isHomePageContentEnabled') && !UserAgentHelper.isFromNativeApp();
}
