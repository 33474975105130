const _ = require('lodash');
const url = require('url');
const ServerConfig = require('../ServerConfig');
const GoogleTagManager = require('../stats/GoogleTagManager');
const UrlHelper = require('../../common/UrlHelper');
const TwitterHelper = require('../utils/TwitterHelper');

module.exports = {
    shareOnFacebook,
    shareOnTwitter,
};

function shareOnFacebook(urlToShare, gtmEventName, gtmParameters) {
    let redirectUri = ServerConfig.config.wwwUrl;
    if (/f4-group.com/.test(redirectUri)) {
        redirectUri = 'https://www.theproject.beer';
    }

    const fbUrl = url.format({
        host: 'www.facebook.com',
        pathname: 'dialog/share',
        query: {
            app_id: ServerConfig.config.facebookAppId,
            redirect_uri: redirectUri + '/autoClose',
            display: 'popup',
            href: urlToShare,
        },
    });

    const winWidth = 530;
    const winHeight = 320;
    const winTop = (window.screen.height / 2) - (winHeight / 2);
    const winLeft = (window.screen.width / 2) - (winWidth / 2);
    const windowOptions = 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight;
    window.open(fbUrl, 'fbShare', windowOptions);
    if (gtmEventName) {
        GoogleTagManager.sendEvent(gtmEventName, _.extend({
            shareType: 'facebook',
        }, gtmParameters));
    }
}

function shareOnTwitter(text, url, gtmEventName, gtmParameters) {
    const twitterShareUrl = UrlHelper.addURLParameters('https://twitter.com/share', {
        text: TwitterHelper.getMessage(text),
        url,
        via: TwitterHelper.SOURCE_OF_THE_TWEET,
    });
    window.open(twitterShareUrl, 'twitterPopup', 'width=530,height=320,dependent');
    if (gtmEventName) {
        GoogleTagManager.sendEvent(gtmEventName, _.extend({
            shareType: 'twitter',
        }, gtmParameters));
    }
}
