const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./HighlightedRealEstateAdCaption.jade');

// @vue/component
module.exports = {
    constants: {
        HEART_ICON_COLOR: '#fff',
    },
    mixins: [
        i18nMixin({
            prefix: 'HighlightedRealEstateAdCaption.',
            keys: {
                prefix: 'highlightedTextPrefix',
            },
        }),
    ],
    props: {
        accountType: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        highlightedTextSuffix() {
            return this.t('suffix', {context: this.accountType});
        },
    },
    template: template(),
};
