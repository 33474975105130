const Vue = require('vue');
const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const Account = require('../../authentication/Account');
const {getApprovalQuestionsWithOptions} = require('../../utils/ContactHelper');

const template = require('./Optins.jade');

module.exports = Vue.extend({
    mixins: [
        require('./mixins/baseFieldMixin'),
    ],
    props: {
        value: {
            type: Object,
            default: undefined,
        },
        questions: {
            type: Array,
            default: undefined,
        },
    },
    data() {
        return {
            approvalQuestions: getApprovalQuestionsWithOptions(this.questions),
            internalValue: this.value,
        };
    },
    watch: {
        internalValue(value) {
            this.$emit('input', value);
        },
    },
    created() {
        this.onAccountUpdatedListener = _.bind(this.onAccountUpdated, this);
        Account.on('accountUpdated', this.onAccountUpdatedListener);
    },
    mounted() {
        this.emitCurrentAccountValues();
    },
    destroyed() {
        Account.removeListener('accountUpdated', this.onAccountUpdatedListener);
    },
    methods: {
        onOptinChanged(name, value) {
            this.internalValue = _.defaults({[name]: value}, this.internalValue);
        },
        onAccountUpdated() {
            this.approvalQuestions = getApprovalQuestionsWithOptions(this.questions);
            this.emitCurrentAccountValues();
        },
        t: translate,
        emitCurrentAccountValues() {
            const currentAccount = Account.getAuthenticatedAccount();
            _.each(this.approvalQuestions, ({name}) => {
                const value = _.get(currentAccount, name);
                if (!_.isNil(value)) {
                    this.onOptinChanged(name, value);
                }
            });
        },
    },
    template: template(),
});
