const Account = require('../authentication/Account');
const BrowserDetect = require('browser-detect');
const urlUtil = require('url');

const APP_ID = 'com.bienici.prod';

module.exports = {
    generate,
};

function generate({from}) {
    if (BrowserDetect.isAndroid()) {
        const appInstallReferrer = JSON.stringify({
            url: location.href,
            accessToken: Account.getAccessToken(),
            from,
        });
        return urlUtil.format({
            protocol: 'market',
            slashes: true,
            hostname: 'details', // to avoid an additional slash, we need to set details in hostname
            query: {
                id: APP_ID,
                referrer: appInstallReferrer,
            },
        });
    } else {
        return urlUtil.format({
            protocol: 'https',
            hostname: 'play.google.com',
            pathname: '/store/apps/details',
            query: {
                id: APP_ID,
            },
        });
    }
}
