const _ = require('lodash');
const template = require('./Rating.jade');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/commonEnergyChartAndRating'),
    ],
    computed: {
        value() {
            const {value} = this.info;
            return (_.isNumber(value) && (value < 1000)) ? value : '';
        },
    },
    template: template(),
};
