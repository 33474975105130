const _ = require('lodash');
const HomePageBase = require('./HomePageBase');
const Ajax = require('../utils/Ajax');

module.exports = class PublicHomePage extends HomePageBase {
    constructor(configuration) {
        _.defaults(configuration, {
            url: '/',
        });
        super(configuration);
    }

    _loadData(options, callback) {
        options.tasks = {
            htmlContent: callback => {
                const contentUrl = '/html/homePageContent.html';
                Ajax.request({
                    url: contentUrl,
                    serverErrorMessage: 'simpleContentLoadData',
                    callback,
                });
            },
        };
        super._loadData(options, callback);
    }
};
