const urlUtil = require('url');
const _ = require('lodash');
const DEFAULT_OPTIONS = require('./DefaultOptions');

const EMBEDDED_MODES = [
    require('./Boursorama'),
    require('./MeilleurTaux'),

    // EBRA
    require('./BienPublic'),
    require('./Dna'),
    require('./EstRepublicain'),
    require('./LAlsace'),
    require('./LeProgres'),
    require('./LeDauphine'),
    require('./LeJsl'),
    require('./RepublicainLorrain'),
    require('./VosgesMatin'),
];

function getOptions(url) {
    const parsedUrl = urlUtil.parse(url);
    const {host, path} = parsedUrl;
    const embeddedMode = _.find(EMBEDDED_MODES, embeddedMode => {
        const regexEscapedPartnerDomain = _.escapeRegExp(embeddedMode.DOMAIN);
        let isMatchingPartner = false;
        if (regexEscapedPartnerDomain) {
            const hostRegex = new RegExp('\\.' + regexEscapedPartnerDomain + '$');
            const pathRegex = new RegExp(regexEscapedPartnerDomain);
            isMatchingPartner = hostRegex.test(host) || pathRegex.test(path);
        }
        return isMatchingPartner;
    });
    return _.get(embeddedMode, 'OPTIONS', DEFAULT_OPTIONS);
}

module.exports = {
    getOptions,
};
