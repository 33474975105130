const _ = require('lodash');

// @vue/component
module.exports = {
    render() {
        return _.map(this.$scopedSlots.default(), vnode => {
            const {data} = vnode;
            _.set(data, 'class.dropdown-toggle', true);
            _.set(data, 'attrs.data-toggle', 'dropdown');
            return vnode;
        });
    },
};
