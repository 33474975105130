const DEFAULT_MAX_IMAGE_SIZE = 90;
const {getImageUrlFromAliasAndFileUrl} = require('./FileServerUrlGenerator');

module.exports = {
    getAccountImageUrl,
    DEFAULT_MAX_IMAGE_SIZE,
};

function getAccountImageUrl(fileUrl, account, maxWidth = DEFAULT_MAX_IMAGE_SIZE, maxHeight = DEFAULT_MAX_IMAGE_SIZE) {
    let imageUrl;
    if (account.imageName) {
        imageUrl = getImageUrlFromAliasAndFileUrl(fileUrl, account.imageName, {width: maxWidth, height: maxHeight});
    } else if (account.profile_image_url) {
        imageUrl = account.profile_image_url;
    } else if (account.emailMd5) {
        imageUrl = 'https://www.gravatar.com/avatar/' + account.emailMd5 + '?d=mm&s=' + Math.min(maxWidth, maxHeight);
    }
    return imageUrl;
}
