const template = require('./ErrorContent.jade');
const {resourceUrl} = require('fack');

// @vue/component
module.exports = {
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        message: {
            type: String,
            required: true,
        },
        backgroundImage: {
            type: String,
            default: null,
        },
        action: {
            type: String,
            default: undefined,
        },
        href: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        backgroundImageStyle() {
            return {
                background: `url(${resourceUrl(this.backgroundImage)}) center center`,
                backgroundSize: 'cover',
                color: 'white',
            };
        },
        style() {
            return this.backgroundImage ? this.backgroundImageStyle : {};
        },
        events() {
            return (!this.href && this.$listeners.click) ? {click: () => this.$emit('click')} : null;
        },
    },
    template: template(),
};
