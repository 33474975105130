const _ = require('lodash');

const CompositeVueView = require('../vue/CompositeVueView');
const FormUtils = require('../fields/FormUtils');
const ContactHelper = require('../utils/ContactHelper');
const EventPack = require('../utils/EventPack');
const SpinnerHelper = require('../utils/SpinnerHelper');

module.exports = class LoanFormView extends CompositeVueView {
    constructor(configuration) {
        super(configuration);
    }

    show(options) {
        const vueOptions = {
            computed: {
                context() {
                    return options.name;
                },
            },
        };
        super.show(options, vueOptions);
        this.$form = this._getForm();
        this._eventPack = new EventPack();
        this._initForm(options);
        this._contactUpdater = ContactHelper.getUpdater({
            $form: this.$form,
        });
    }

    hide() {
        this._eventPack.removeAllListeners();
        super.hide();
    }

    _getForm() {
        return this.$element.find('form');
    }

    _initForm() {
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this._submitForm, this),
        });
    }

    _submitForm() {
        this.showSpinner();
        this.emit('submit', this._getSubmitData());
    }

    _getFormData() {
        return FormUtils.readValues(this.$form);
    }

    showSpinner() {
        SpinnerHelper.startButtonSpinner(this.$element.find('button[type="submit"]'));
    }

    _getGtmInfos() {
        return this._getSubmitData();
    }
};
