const VueView = require('../../vue/VueView');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./SatisfactionSurveyModal.jade');
const ApplicationConfig = require('../../app/ApplicationConfig');
const OpenWindow = require('../../utils/OpenWindow');
const {TrackingClasses} = require('../../stats/TrackingClasses');
const {
    resourceUrl,
} = require('fack');

module.exports = class SatisfactionSurveyView extends VueView {
    show({url}) {
        const view = this;
        // @vue/component
        super.show(null, {
            constants: {
                OPEN_BUTTON_TRACKING_CLASSES: [TrackingClasses.openSatisfactionSurveyModal],
                CLOSE_BUTTON_TRACKING_CLASSES: [TrackingClasses.closeSatisfactionSurveyModal],
                DONT_SHOW_BUTTON_TRACKING_CLASSES: [TrackingClasses.dontShowSatisfactionSurveyModal],
            },
            mixins: [
                i18nMixin({
                    keys: [
                        'title',
                        'pitch',
                        'proposition',
                        'no',
                        'reply',
                        'later',
                    ],
                }),
            ],
            computed: {
                imageUrl() {
                    const filenameSuffix = ApplicationConfig.applicationPro ? 'Pro' : '';
                    return resourceUrl('images/satisfactionSurvey' + filenameSuffix + '.jpg');
                },
            },
            methods: {
                t(suffix) {
                    return this.translate('satisfactionSurvey.' + suffix, {
                        context: ApplicationConfig.applicationPro ? 'pro' : '',
                    });
                },
                openSurvey() {
                    OpenWindow.open({url});
                    this.hide();
                },
                hide() {
                    view.hide();
                },
            },
            template: template(),
        });
    }
};
