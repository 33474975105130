const _ = require('lodash');

module.exports = {
    init: function (validator, $field) {
        // Attach the intlTelInput on field
        $field.intlTelInput({
            initialCountry: 'fr',
            preferredCountries: ['fr'],
            autoPlaceholder: true,
            formatOnInit: true,
        });
        //defer to give time to setup value
        _.defer(function () {
            const value = $field.val();
            if (value) {
                $field.intlTelInput('setNumber', value);
            }
            //keep this before setting initial value
            $field.on('countrychange', function () {
                validator.revalidateField($field);
            });
        });

    },

    destroy: function (validator, $field) {
        $field.intlTelInput('destroy');
        $field.off('countrychange');
    },

    validate: function (validator, $field) {
        let isValidNumber = $field.intlTelInput('isValidNumber');
        if (isValidNumber == null) {
            console.error('Invalid value returned from international phone input isValidNumber method: ', isValidNumber);
            isValidNumber = false;
        }
        if (!$field.data('required') && !$field.val()) {
            isValidNumber = true;
        }
        return isValidNumber;
    },
};
