const _ = require('lodash');
const TextFormatter = require('../../../../common/TextFormatter.js');
const template = require('./MainInfo.jade');
const AD_INFO_FIELDS = [
    'modificationDate',
    'closingDate',
    'reference',
];

// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    computed: {
        textContent() {
            const realEstateAd = this.realEstateAd;
            return _(AD_INFO_FIELDS)
                .map(fieldName => {
                    const value = realEstateAd[fieldName];
                    return value && TextFormatter.formatAndTranslateField(value, fieldName, realEstateAd);
                })
                .compact()
                .join('\u00a0|\u00a0');
        },
    },
    template: template(),
};
