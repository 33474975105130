const _ = require('lodash');
const FileUploaderWidget = require('./FileUploaderWidget');
const ServerConfig = require('./ServerConfig');
const Account = require('./authentication/Account');
const {
    ACCEPTED_FILES,
    TRANSLATION_KEYS,
    getFileUrlFromAlias,
} = require('../js/FileHelper');

module.exports = class AllFileUploaderWidget extends FileUploaderWidget {
    constructor(fileFormat) {
        super();
        this.fileFormat = fileFormat;
        this.setFileFormat(fileFormat);
    }
    _getAcceptedFiles() {
        return ACCEPTED_FILES[this.fileFormat];
    }

    setFileFormat(fileFormat) {
        this.fileFormat = fileFormat;
    }

    _getTranslationKey() {
        return TRANSLATION_KEYS[this.fileFormat];
    }

    _getFileUniqueIdentifier(response) {
        return response.filename;
    }

    _getFileDownloadUrl(fileId) {
        return this._filesUrls[fileId];
    }

    _getUploadServerUrl() {
        return ServerConfig.config.uploadUrl + '/upload?access_token=' + encodeURIComponent(Account.getAccessToken());
    }

    setFiles(filesAliases) {
        if (!_.isEqual(filesAliases, this.getFiles()) && filesAliases) {
            this._filesUrls = [];
            const self = this;
            filesAliases.forEach(file => {
                self._filesUrls.push(getFileUrlFromAlias(file));
            });
            this._setFiles(filesAliases);
        }
    }
};
