const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'agency';

module.exports = namespaceComponents(NAMESPACE, {
    'overview': require('./AgencyOverview'),
    'rating': require('./AgencyRating'),
    'local-expert-banner': require('./LocalExpertBanner'),
    'agencies-with-notoriety-extension': require('./AgenciesWithNotorietyExtension'),
    'fee': require('../../agency/components/AgencyFee'),
});
