const $ = require('jquery');
const _ = require('lodash');
const ApplicationPage = require('../../../pages/ApplicationPage');
const ArticlesContainerView = require('../../list/ArticlesContainerView');
const EditArticleView = require('./EditArticleView');
const PageManager = require('../../../pages/PageManager');
const Pages = require('../../../pages/Pages');
const Account = require('../../../authentication/Account');

module.exports = class ArticleCreationOrModificationPage extends ApplicationPage {
    constructor(configuration) {
        configuration = _.extend({
            isRegistrationRequired: true,
        }, configuration);
        super(configuration);
        this.configuration = configuration;
    }

    open(options) {
        this.bodyClass = 'blog-modification';
        this.options = _.extend(options, this.configuration);
        super.open(this.options);
        this._saveArticleHandler = _.bind(function (article) {
            this.openArticle(article);
        }, this);
        if (this.editArticleView) {
            this.editArticleView.on('saveArticle', this._saveArticleHandler);
        }
    }

    close() {
        if (this.editArticleView) {
            this.editArticleView.removeListener('saveArticle', this._saveArticleHandler);
        }
        this._saveArticleHandler = null;
        super.close();
    }

    openArticle(article) {
        const ArticlePageOptions = {
            article,
        };
        PageManager.openPage(new Pages.ArticlePage(), ArticlePageOptions);
    }

    getMiddleViews() {
        this.articlesContainerView = new ArticlesContainerView();
        this.editArticleView = new EditArticleView({
            $container: $('.article__main'),
            article: this.options.article,
            pageTitle: this.configuration.title,
        });
        return [this.articlesContainerView, this.editArticleView];
    }

    isPageAccessAllowed() {
        return Account.isBlogger();
    }

    getName() {
        return this.configuration.name;
    }
};
