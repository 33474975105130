const _ = require('lodash');
const template = require('./CompactLinks.jade');
const i18nMixin = require('./mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'CompactLinks.',
            keys: [
                'showLess',
                'showMore',
            ],
        }),
    ],
    props: {
        title: {
            type: String,
            default: undefined,
        },
        titleLevel: {
            type: String,
            default: 'div',
        },
        links: {
            type: [Array, String],
            default: () => [],
        },
        creditSimulation: Boolean,
        initialVisibleLinksCount: {
            type: Number,
            default: undefined,
        },
        noPadding: Boolean,
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    computed: {
        formattedLinks() {
            const {links} = this;
            if (_.isArray(links)) {
                return links;
            }
            return JSON.parse(links);
        },
        shouldShowExpandButton() {
            const {initialVisibleLinksCount, formattedLinks} = this;
            return initialVisibleLinksCount && formattedLinks.length > initialVisibleLinksCount;
        },
        toggleText() {
            return (this.isExpanded ? this.showLess : this.showMore);
        },
        containerModifier() {
            return _.pick(this, ['noPadding']);
        },
    },
    methods: {
        formatLinkContent(link) {
            const {translationKey, content} = link;
            return translationKey ? this.t(translationKey) : content;
        },
        linkModifiers(index) {
            const {initialVisibleLinksCount, isExpanded} = this;
            if (initialVisibleLinksCount && !isExpanded && index >= initialVisibleLinksCount) {
                return {
                    'hidden': true,
                };
            }
            return {};
        },
        toggleExpandButton() {
            this.isExpanded = !this.isExpanded;
        },
    },
    template: template(),
};
