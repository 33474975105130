const async = require('async');
const LoadScriptHelper = require('./utils/LoadScriptHelper');
const ServerConfig = require('./ServerConfig');

function loadSafeFrame(cb) {
    const scripts = [
        `${ServerConfig.config.advertisementUrl}/static/js/safeframe/safeFrameBase.js`,
        `${ServerConfig.config.advertisementUrl}/static/js/safeframe/safeFrameHost.js`,
        `${ServerConfig.config.advertisementUrl}/static/js/safeframe/safeFrameBoot.js`,
    ];
    async.waterfall(
        scripts.map(scriptUrl => {
            return (next) => {
                LoadScriptHelper.loadScript(scriptUrl, {
                    crossoriginAllowed: true,
                    successCallback: next,
                });
            };
        }),
        cb
    );
}

module.exports = loadSafeFrame;
