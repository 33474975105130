const _ = require('lodash');

const SimpleContentView = require('../../views/SimpleContentView');
const PinelResultsView = require('./PinelResultsView');
const LoanSimulatorResultsPage = require('../LoanSimulatorResultsPage');
const Urls = require('../../Urls');
const Pages = require('../../pages/Pages');

module.exports = class PinelResultsPage extends LoanSimulatorResultsPage {
    constructor(configuration) {
        configuration = _.extend({
            gtmCategory: 'ToolsAndSimulators',
            gtmPrefix: 'pinel',
            requestUrl: '/getPinelSimulation',
            requestName: 'getPinelSimulation',
            name: 'pinelSimulatorResults',
            title: 'Résultat simulation Pinel',
            contentPath: 'pinel-results',
        }, configuration);
        super(configuration);
    }

    _createViews() {
        this.contentView = new SimpleContentView();
        this.resultsView = new PinelResultsView();
    }

    _bindEvents() {
        super._bindEvents();
        this._eventPack.on(this.resultsView, 'tabChanged', data => {
            this.sendGtmEvent(`${this.configuration.gtmPrefix}-resultsShown`, data);
        });
    }

    getUrlPattern() {
        return Urls.pinel.pattern;
    }

    getUrl() {
        return Urls.pinel.makeUrl(this.options || {});
    }

    parseUrl(url) {
        return {
            simulatorData: Urls.pinel.parseUrl(url),
        };
    }

    _getSimulatorPage() {
        return Pages.pinelSimulatorPage;
    }

    _getReopenSimulatorOptions() {
        return _.omit(super._getReopenSimulatorOptions(), ['contactData']);
    }
};
