const _ = require('lodash');
const DEFAULT_OPTIONS = require('./DefaultOptions');

module.exports = EbraOptions;

function EbraOptions(partnerName, partnerDomain) {
    return {
        OPTIONS: _.defaults({
            partnerName,
            isHomePageContentEnabled: true,
            isHomePageFeaturesEnabled: true,
            homepageBackgroundFilename: 'ebra.jpg',
        }, DEFAULT_OPTIONS),
        DOMAIN: partnerDomain,
    };
}
