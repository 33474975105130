const AD_TYPES = {
    BUY: 'buy',
    RENT: 'rent',
    INVESTMENT: 'investment',
    BUSINESS_TAKE_OVER: 'businessTakeOver',
    PRESTIGE: 'prestige',
    LEASEBACK: 'leaseback',
    LIFE_ANNUITY_SALE: 'lifeAnnuitySale',
    HOLIDAYS: 'holidays',
};

const AD_TYPES_FRENCH = {
    BUY: 'vente',
    RENT: 'location',
    INVESTMENT: 'produit d\'investissement',
    BUSINESS_TAKE_OVER: 'vente fond de commerce',
    PRESTIGE: 'vente de prestige',
    LEASEBACK: 'cession de bail',
    LIFE_ANNUITY_SALE: 'viager',
    HOLIDAYS: 'location vacances',
};

const AD_TYPES_ENABLED = [
    AD_TYPES.BUY,
    AD_TYPES.RENT,
    AD_TYPES.INVESTMENT,
    AD_TYPES.BUSINESS_TAKE_OVER,
    AD_TYPES.PRESTIGE,
    AD_TYPES.LEASEBACK,
    AD_TYPES.LIFE_ANNUITY_SALE,
    // AD_TYPES.HOLIDAYS,
];

const BUY_AD_TYPES = [
    AD_TYPES.BUY,
    AD_TYPES.INVESTMENT,
    AD_TYPES.PRESTIGE,
    AD_TYPES.BUSINESS_TAKE_OVER,
    AD_TYPES.LIFE_ANNUITY_SALE,
];

module.exports = {
    AD_TYPES,
    AD_TYPES_FRENCH,
    AD_TYPES_ENABLED,
    BUY_AD_TYPES,
};
