const $ = require('jquery');
const _ = require('lodash');

const CompositeVueView = require('../../vue/CompositeVueView');
const articleTemplate = require('./articleView.jade');
const Account = require('../../authentication/Account');
const ArticleHelper = require('./ArticleHelper');
const SpinnerHelper = require('../../utils/SpinnerHelper');
const BlogHelper = require('../BlogHelper');
const ShareHelper = require('../../utils/ShareHelper');
const UserAgentHelper = require('../../../common/nativeApp/UserAgentHelper');
const CompactSearchFilterView = require('../../search/CompactSearchFilterView');
const EventPack = require('../../utils/EventPack');
const {PUBLIC_BLOG_PATH} = require('../../../common/data/blogData');

const landingPageTemplate = require('./articleLandingView.jade');
const acquisitionPageTemplate = require('./articleAcquisitionView.jade');
const LandingPageSearchFormView = require('../../views/landingPages/LandingPageSearchFormView');
const AdvertisementsHelper = require('../../views/utils/AdvertisementsHelper');

module.exports = class ArticleView extends CompositeVueView {
    constructor(options) {
        let template;
        const tags = options.article.tags;
        let isLandingPage = true;
        if (_.includes(tags, 'template-landing')) {
            template = landingPageTemplate;
        } else if (_.includes(tags, 'template-acquisition')) {
            template = acquisitionPageTemplate;
        } else {
            template = articleTemplate;
            isLandingPage = false;
        }
        super({
            template,
        });
        _.extend(this, options);
        this.isLandingPage = isLandingPage;
    }

    show({commercialAdsEnabled: commercialAdsEnabledInArticles}) {
        this.$container = $('body').find('.articlesHub__article-container');
        const blog = this.blog || BlogHelper.getBlogFromTags(this.article.tags);
        const blogTags = _.map(blog.tags, 'slug');
        let tags = _.reject(_.intersection(this.article.tags, blogTags), slug => {
            return BlogHelper.getBlog(slug, this.isPro);
        });
        if (_.isEmpty(tags)) {
            tags = [blog.slug];
        }
        const article = this.article;
        const compactSearchForm = article.compactSearchForm;
        this._eventPack = new EventPack();
        const templateOptions = {
            article,
            category: this.category,
            tags,
            blog,
            isBlogger: Account.isBlogger(),
            canShowShareButtons: !Account.isShowRoom() && !UserAgentHelper.isFromNativeApp(),
            commercialAdsEnabledInArticles,
            ArticleHelper,
            PUBLIC_BLOG_PATH,
        };
        if (commercialAdsEnabledInArticles) {
            templateOptions.advertisementData = AdvertisementsHelper.getAdsData({
                page: 'blog',
                searchCriteria: _.get(compactSearchForm, 'enabled') ? compactSearchForm : {},
                tags,
            });
        }
        const vueOptions = {
            data: {
                article,
            },
        };
        super.show(templateOptions, vueOptions);

        const $element = this.$element;
        this.$imgContainer = $element.find('.articlesHub__article__photo-container-full');
        this.$imgToLoad = this.$imgContainer.find('.img_full');
        this.$imgpreload = this.$imgContainer.find('.img_small_preloading');
        const iframeSrc = article.iframeSrc;
        if (iframeSrc) {
            this.iframeSrc =
                $('<iframe class="articleIframe" src=' + iframeSrc + ' frameborder="0" allowfullscreen="true"></iframe>');
        }
        this.$imgToLoad.on('load', _.bind(function () {
            this.$imgContainer.addClass('loaded');
            if (this.iframeSrc) {
                this.$imgContainer.addClass('loadIframe');
                this.$imgContainer.append(this.iframeSrc);
            }
        }, this));

        $element
            .find('.publicationState')
            .addClass(article.published ? 'published' : 'notPublished');

        this._eventPack.on($element, {
            click: {
                '.shareOnFacebook': _.bind(this._shareOnFacebook, this),
                '.shareOnTwitter': _.bind(this._shareOnTwitter, this),
                '.editArticle': () => this.emit('editArticle'),
                '.deleteArticle': () => this.emit('deleteArticle'),
            },
            linkClick: {
                '.closeArticle': event => {
                    event.preventDefault();
                    this.emit('closeArticle');
                },
                '.publicationState': _.bind(this.togglePublicationState, this),
            },
        });

        if (this.blog) {
            let href = '/' + this.blog.slug;
            if (this.category) {
                href += (this.category.slug ? '/' + this.category.slug : '');
            }
            $element
                .find('.closeArticle')
                .attr('href', href);
        }

        // Fix blog anchor marker position
        const BLOG_ANCHOR_TOP_OFFSET = 20;
        $element.find('a[href^="#"]').on('click', function (event) {
            const target = $(this.getAttribute('href'));
            if (target.length) {
                event.preventDefault();
                const $blogMenuElement = $('body').find('.blog-category-selector');
                const blogMenuPosition = $blogMenuElement.offset().top - window.scrollY + $blogMenuElement.outerHeight(true);

                window.scrollTo({
                    top: Math.floor(target.offset().top - blogMenuPosition - BLOG_ANCHOR_TOP_OFFSET),
                    behavior: 'instant',
                });
            }
        });

        this._showSearchFormView();
    }

    _showSearchFormView() {
        const compactSearchForm = this.article.compactSearchForm;
        const showOptions = {
            defaultSearch: {
                filterType: _.get(compactSearchForm, 'filterType', 'buy'),
                locations: _.get(compactSearchForm, 'locations'),
            },
        };
        if (compactSearchForm && compactSearchForm.enabled) {
            if (this.isLandingPage) {
                this._searchFormView = new LandingPageSearchFormView({
                    $container: this.$element.find('.landingSearchBlock'),
                });
                _.extend(showOptions, {
                    title: this.article.pageTitle || this.article.title,
                    subtitle: this.article.pitch,
                    imageFilename: _.get(this.article, 'photos[0].filename'),
                });
            } else {
                this._searchFormView = new CompactSearchFilterView({
                    $container: this.$element.find('.compactSearchForm'),
                });
                _.extend(showOptions, {
                    addressPlaceholderContext: 'small',
                });
            }
            this._searchFormView.show(showOptions);
            this._eventPack.on(this._searchFormView, 'search', _.bind(this.emit, this, 'search'));
        }
    }

    hide(options, cb) {
        if (this._searchFormView) {
            this._searchFormView.hide();
            this._searchFormView = null;
        }
        this._eventPack.removeAllListeners();
        super.hide(options, cb);
    }

    _shareOnFacebook() {
        ShareHelper.shareOnFacebook(window.location.href, 'articleShare', {
            articleId: this.article && this.article.id,
        });
    }

    _shareOnTwitter() {
        ShareHelper.shareOnTwitter(this.article && this.article.title, window.location.href, 'articleShare', {
            articleId: this.article && this.article.id,
        });
    }

    getImgTarget() {
        const target = {
            $target: this.$imgpreload,
            $container: this.$imgContainer,
        };
        if (target.$target.length == 1 && target.$container.length == 1) {
            return target;
        } else {
            return null;
        }
    }

    getArticlesSideContainer() {
        return this.$container.find('.article__side_articleSimilarContainer');
    }

    togglePublicationState(e) {
        SpinnerHelper.startButtonSpinner($(e.currentTarget));
        if (this.article && this.article.id) {
            updateArticlePublishedState(
                this.article.id,
                !this.article.published,
                _.bind(function (err, updatedArticle) {
                    if (err) {
                        ArticleHelper.showError(err, 'togglePublicationState');
                    } else {
                        this.emit('publicationStateUpdated', updatedArticle);
                    }
                }, this)
            );
        }
    }
};

function updateArticlePublishedState(id, published, callback) {
    return ArticleHelper.updateArticlePublishedState({
        method: 'PUT',
        id,
        published,
        callback,
    });
}
