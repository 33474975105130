const _ = require('lodash');

//from https://github.com/vuejs/Discussion/issues/157#issuecomment-273301588
module.exports = function triggerNativeEvent($input, eventName) {
    let event;
    if (_.isFunction(Event)) {
        event = new Event(eventName, {bubbles: true, cancelable: true});
    } else { //IE
        event = document.createEvent('HTMLEvents');
        event.initEvent(eventName, true, true);
    }
    $input.get(0).dispatchEvent(event);
};
