const _ = require('lodash');
const {isMobile} = require('browser-detect');

const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./BlogAdminMenu.jade');
const blogData = require('../../../../common/data/blogData');
const UrlHelper = require('../../../../common/UrlHelper');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'BlogAdminMenu.',
            keys: [
                'createArticle',
            ],
        }),
    ],
    props: {
        pageTitle: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        displayCreateArticleLink() {
            return !isMobile(); // keep isMobile as article creation route is not defined on mobile
        },
        blogs() {
            return _.map(blogData.blogs, ({name}) => {
                return {
                    name,
                    url: '/' + UrlHelper.slugify(name),
                };
            });
        },
    },
    methods: {
        getLinkModifiers(name) {
            return {
                active: name === this.pageTitle,
            };
        },
    },
    template: template(),
};
