const {EventEmitter} = require('events');
const util = require('util');
const Options = require('../Options');
const savedOptions = Options.read();
const MapApi = require('../MapApi');
const _ = require('lodash');
const onecolor = require('onecolor');
const VirtualTour = require('./VirtualTour');

// TODO detect crop automatically using interiorModel ?
// To allow both mode to work at once
let enableCrop = false;
if (savedOptions.cutProgramme) {
    enableCrop = true;
}

const MP = require('mercatorProjection');

const FADE_DURATION = 300;

module.exports = Lot;

function Lot(lotInfo, map) {
    this.id = lotInfo.id;
    this.setMap(map);
    this._clickable = false;
    this._virtualTours = _.map(lotInfo.virtualTours, (virtualTourInfo) => {
        const virtualTour = new VirtualTour(virtualTourInfo);
        virtualTour.on('open', () => {
            this.emit('showVirtualTour');
        });
        virtualTour.on('close', () => {
            this.emit('hideVirtualTour');
        });
        return virtualTour;
    });

    this._lotInfo = lotInfo;
    this.interiorModel = lotInfo.interiorModel;
    this.facadeModel = lotInfo.facadeModel;
    this._latBuilding900913 = lotInfo.lat;
    this._lonBuilding900913 = lotInfo.lon;
    this._scaledBuilding = lotInfo.scaled;
    this._latLngBuilding = MapApi.api.LatLng.from900913(this._latBuilding900913, this._lonBuilding900913);
    this._lotPreRenderFuncHandler = _.bind(this._lotPreRenderFunc, this);
    this._lotPostRenderFuncHandler = _.bind(this._lotPostRenderFunc, this);
    this._lotMeshCleanupCallback = _.bind(this._onLotMeshCleanup, this);
    this._color = new MapApi.api.engine.Color(0x00000000);
    this._materialVisible = false;
}

util.inherits(Lot, EventEmitter);

const proto = Lot.prototype;

proto.setMaterialVisible = function (materialVisible) {
    if (this._materialVisible != materialVisible) {
        this._materialVisible = materialVisible;
        this._updateMarkerModel();
    }
};

proto.hasModels = function () {
    return enableCrop ? this._lotInfo.interiorModelExists : this._lotInfo.facadeModelExists;
};

proto.load = function () {
    // Determine if the desired model exists and load it if it does
    if (this.hasModels()) {
        this._createMarker();
    } else {
        this.emit('lotModelLoaded');
    }
};

proto._startFade = function () {
    if (this.facadeModel3d || this.interiorModel3d) {
        this._stopFade();
        this._startFadeTime = new Date();
        this._updateFade();
    }
};
proto._stopFade = function () {
    if (this._requestId) {
        window.cancelAnimationFrame(this._requestId);
        delete this._requestId;
    }
};

proto._requestFade = function () {
    this._requestId = requestAnimationFrame(_.bind(this._updateFade, this));
};

proto._updateFade = function () {
    if (enableCrop) {
        const model = this.interiorModel;
        if (model && model.materialVisible) {
            this.transparency = 1.0;
        } else {
            this.transparency = 0.0;
        }
        this._stopFade();
        this._updateMarkerModel();
        return;
    }

    let currentFadeDuration = new Date() - this._startFadeTime;
    if (currentFadeDuration >= this._fadeDuration) {
        currentFadeDuration = this._fadeDuration;
        this._stopFade();
    } else {
        this._requestFade();
    }
    const ratio = currentFadeDuration / this._fadeDuration;
    this.transparency = (this._endTransparency - this._startTransparency) * ratio + this._startTransparency;
    this.wfTransparency = (this._endWfTransparency - this._startWfTransparency) * ratio + this._startWfTransparency;
    this._color.r = (this._endColor.r - this._startColor.r) * ratio + this._startColor.r;
    this._color.g = (this._endColor.g - this._startColor.g) * ratio + this._startColor.g;
    this._color.b = (this._endColor.b - this._startColor.b) * ratio + this._startColor.b;
    this._updateMarkerModel();
};

proto._getAlphaLot = function () {
    if (enableCrop) {
        const model = this.interiorModel;
        if (model && model.materialVisible) {
            return 1.0;
        } else {
            return 0.0;
        }
    } else {
        return this.transparency;
    }
};

proto._getAlphaLotWireFrame = function () {
    return this.wfTransparency;
};

proto._updateMarkerModel = function () {
    if (this.facadeModel3d || this.interiorModel3d) {
        const visible = (this.transparency > 0);
        let model;
        if (this.facadeModel3d && this.facadeModel3d.mesh) {
            this.facadeModel3d.mesh.material.visible = visible;
            model = this.facadeModel;
        } else if (this.interiorModel3d && this.interiorModel3d.mesh) {
            this.interiorModel3d.mesh.material.visible = visible;
            model = this.interiorModel;
            model.materialVisible = this._materialVisible;
        }
        if (model) {
            model.uniformColor = this._getLotColor();
            const wireframeMesh = this.facadeModel3d && this.facadeModel3d.mesh && this.facadeModel3d.mesh.wireframeMesh;
            if (wireframeMesh) {
                wireframeMesh.geometry.fixedColor.a = this._getAlphaLotWireFrame(this);
                wireframeMesh.geometry.__isColorDirty = true;
            }
            this.marker.setOptions({
                model,
                visible: true,
            });
        }
    }
};

proto._getModelName = function (modelPath) {
    const splitModelPath = modelPath.split('/');
    return splitModelPath[splitModelPath.length - 1].replace('.bis', '');
};

proto._createMarker = function () {
    const model = this._createModel();
    model.onLoadedCallback = _.bind(this._onMarkerModelLoaded, this);

    this.marker = new MapApi.api.Marker({
        position: this._latLngBuilding,
        map: this.map,
        model,
        visible: true,
        disableHeight: true,
        clickable: this._clickable,
    });
    const marker = this.marker;
    marker.on('click', _.bind(this.click, this));
    marker.on('mouseover', _.bind(this.mouseOver, this));
    marker.on('mouseout', _.bind(this.mouseOut, this));
};

proto._createModel = function () {
    let model;
    if (enableCrop) {
        model = this.interiorModel;
        model.noLight = false;
        model.handleRenderPass = {
            colorBeforeProcess: true,
            depth: true,
            shadowDepth: true,
        };

    } else {
        model = this.facadeModel;
        model.noLight = true;
        model.handleRenderPass = {
            colorBeforeProcess: true,
        };
    }

    this.transparency = 0;
    this.wfTransparency = 0;
    model.materialVisible = true;
    model.uniformColor = this._getLotColor();
    model.doubleSided = true;
    model.transparent = true;
    return model;
};

proto._onMarkerModelLoaded = function (error, model3d) {
    if (!error) {
        let scale = 1.00;
        const scaleExit = enableCrop ? 0.01 : 0.0;
        if (!this._scaledBuilding) {
            scale = this.getScaleFromLatitude(this._latBuilding900913);
        }
        const boundingBox = model3d.mesh.geometry.getBoundingBox();

        if (enableCrop) {
            this.cropHeightMax = boundingBox.maxZ;
            this.cropHeightMin = boundingBox.minZ;
            this.interiorModel3d = model3d;

        } else {
            // Set pre & post-render functions
            model3d.mesh.preRenderFunc = this._lotPreRenderFuncHandler;
            model3d.mesh.postRenderFunc = this._lotPostRenderFuncHandler;
            model3d.mesh.cleanupCallback = this._lotMeshCleanupCallback;
            model3d.mesh.updateRenderPriority(Infinity);
            // Create wireframe mesh now if we can
            const wireframeMesh = this._createWireframeMesh(model3d.mesh);
            if (wireframeMesh) {
                model3d.mesh.wireframeMesh = wireframeMesh;
            }
            this.facadeModel3d = model3d;
        }

        const newPosition = new MapApi.api.LatLng.from900913(
            boundingBox.center.y * scale + this._latBuilding900913,
            boundingBox.center.x * scale + this._lonBuilding900913);
        const position = {lat: newPosition.lat(), lon: newPosition.lng()};
        this.lon = position.lon;
        this.lat = position.lat;
        this.height = boundingBox.maxZ * scale + 1;
        model3d.mesh.scale.set(scale + scaleExit, scale + scaleExit, scale);
        model3d.mesh.updateMatrix();
        this._updateMarkerModel();
    }
    this.emit('lotModelLoaded', error);
};

proto._lotPreRenderFunc = function (webglRenderer) {
    const Constants = MapApi.api.engine.Constants;

    if (webglRenderer.stencilAvailable) {

        // Clear stencil for each lot
        webglRenderer.clearStencil();

        // Enable stencil: draw pixel only if stencil is not equal to value, write stencil value too.
        webglRenderer.setStencilFunction(2, Constants.NOTEQUAL, Constants.REPLACE);

    } else {
        // Use a polygon offset to render the solid facades closer to camera (use depth buffer to hide most wireframe)
        webglRenderer._gl.enable(webglRenderer._gl.POLYGON_OFFSET_FILL);
        webglRenderer._gl.polygonOffset(-2.0, 1.0);
    }
};

proto._lotPostRenderFunc = function (webglRenderer, mesh) {

    if (!webglRenderer.stencilAvailable) {
        // Disable polygon offset
        webglRenderer._gl.disable(webglRenderer._gl.POLYGON_OFFSET_FILL);
        webglRenderer._gl.polygonOffset(0.0, 0.0);
    }

    if (mesh.wireframeMesh) {
        // Copy world matrix from main mesh and apply to wireframe mesh
        const wireframeMesh = mesh.wireframeMesh;
        wireframeMesh.matrixWorld.copy(mesh.matrixWorld);

        // Update and render wireframe
        webglRenderer.updateObject(wireframeMesh);
        webglRenderer.renderObject(wireframeMesh);

        // Render wireframe at different viewport origins to make line thicker
        const viewportX = webglRenderer._viewportX;
        const viewportY = webglRenderer._viewportY;
        const width = 3;
        const hw = (width - 1) / 2;

        for (let j = -hw; j <= hw; j++) {
            if (j != 0) {
                webglRenderer.setViewportOrigin(viewportX, viewportY + j);
                webglRenderer.renderObject(wireframeMesh);
            }
        }
        for (let i = -hw; i <= hw; i++) {
            if (i != 0) {
                webglRenderer.setViewportOrigin(viewportX + i, viewportY);
                webglRenderer.renderObject(wireframeMesh);
            }
        }

        // Reset viewport origin
        webglRenderer.setViewportOrigin(viewportX, viewportY);

    } else {
        this._verifyLotWireframeMeshes();
    }

    if (webglRenderer.stencilAvailable) {
        // Remove stencil test/write
        webglRenderer.setStencilFunction(null);
    }

};

proto._onLotMeshCleanup = function (mesh, renderEngine) {
    if (mesh.wireframeMesh) {
        renderEngine.releaseObject(mesh.wireframeMesh);

        delete mesh.wireframeMesh;
    }
};

proto._verifyLotWireframeMeshes = function () {
    if (this.map && this.facadeModel3d && this.facadeModel3d.mesh && !this.facadeModel3d.mesh.wireframeMesh) {
        const model3d = this.facadeModel3d;
        // Create wireframe mesh
        model3d.mesh.wireframeMesh = this._createWireframeMesh(model3d.mesh);
    }
};

proto._createWireframeMesh = function (mesh) {
    if (this.map) {
        const wireframeGeometry = mesh.geometry.clone();
        wireframeGeometry.convertTrianglesToLines();

        const renderEngine = this.map._renderer.getRenderEngine();

        wireframeGeometry.fixedColor = new MapApi.api.engine.Color(0xffffff);
        const material = renderEngine.createMaterial('nolight');

        return renderEngine.createMesh(wireframeGeometry, material);
    } else {
        return null;
    }
};

proto._getLotColor = function () {
    const colorRGBA = this._color.clone();
    colorRGBA.a = this._getAlphaLot();
    return colorRGBA;
};

proto.getId = function () {
    return this.id;
};

proto.getVirtualTours = function () {
    return _.filter(this._virtualTours, (virtualTour) => virtualTour.tour.type == 'virtualTour360');
};

proto.setClickable = function (clickable) {
    this._clickable = clickable;
    if (this.marker) {
        this.marker.setClickable(clickable);
    }
};

proto.fadeTo = function (color, duration) {
    this._startColor = this._color.clone();
    this._startTransparency = this.transparency;
    this._startWfTransparency = this.wfTransparency;
    const oColor = onecolor(color);
    this._endColor = new MapApi.api.engine.Color(oColor.hex());
    this._endTransparency = oColor.alpha();
    this._endWfTransparency = (this._endTransparency > 0.1) ? 1.0 : 0.0;
    this._fadeDuration = duration != null ? duration : FADE_DURATION;
    this._startFade();
};

proto.click = function (event) {
    this.emit('click', event);
};

proto.mouseOver = function (event) {
    this.emit('mouseover', event);
};

proto.mouseOut = function (event) {
    this.emit('mouseout', event);
};

proto.setColor = function (color) {
    this._stopFade();
    const oColor = onecolor(color);
    this.transparency = oColor.alpha();
    this.wfTransparency = (this.transparency > 0.1) ? 1.0 : 0.0;
    this._color = new MapApi.api.engine.Color(oColor.hex());
    this._updateMarkerModel();
};

proto.getScaleFromLatitude = function (lat) {
    return Math.cosh(Math.PI * lat / MP.MAX_EXTENT);
};

proto.setMap = function (map) {
    this.map = map;
    if (this.marker) {
        this.marker.setMap(map);
    }
};
