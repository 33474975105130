const template = require('./AddLinkIfNeeded.jade');

// @vue/component
module.exports = {
    props: {
        href: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        tag() {
            return this.href ? 'a' : 'div';
        },
    },
    template: template(),
};
