const _ = require('lodash');

const ActionsDropdown = require('../savedSearches/components/ActionsDropdown');
const template = require('./SavedSearch.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const NumberFormatter = require('../../../common/NumberFormatter');
const CriteriaListFormatter = require('../../../common/CriteriaListFormatter');
const {NO_ALERT_EMAIL_NOTIFICATION_MODE} = require('../../../lib/utils/constants');

const SAVED_SEARCHES_STORE = 'savedSearches';
const DEFAULT_NEW_ADS_COUNT = 0;

// @vue/component
module.exports = {
    components: {
        ActionsDropdown,
    },
    mixins: [
        i18nMixin({
            prefix: 'SavedSearch.',
            keys: [
                'enableNotificationsButtonText',
                'viewAdsButtonText',
            ],
        }),
    ],
    props: {
        savedSearch: {
            type: Object,
            required: true,
        },
        criteriaModificationEnabled: Boolean,
        criteriaModificationHidden: Boolean,
        showNotificationState: Boolean,
        showPushNotificationState: Boolean,
        error: Boolean,
    },
    computed: {
        criteriaList() {
            return CriteriaListFormatter.getCapitalizedCriteria(this.savedSearch.searchCriteria);
        },
        emailNotificationMode() {
            return this.savedSearch.emailNotificationMode;
        },
        pushNotificationMode() {
            return this.savedSearch.pushNotificationMode;
        },
        saveName() {
            return this.savedSearch.saveName;
        },
        savedSearchId() {
            return this.savedSearch._id;
        },
        areNotificationsEnabledForSavedSearch() {
            const {emailNotificationMode, pushNotificationMode} = this;
            return _.some([
                emailNotificationMode,
                pushNotificationMode,
            ], notificationState => notificationState !== NO_ALERT_EMAIL_NOTIFICATION_MODE);
        },
        formattedNewAdsCount() {
            return NumberFormatter.formatNumber(this.newAdsCount);
        },
        newAdsCountText() {
            return this.t('newAd', {
                count: this.newAdsCount,
            });
        },
        isNewModifier() {
            return {
                new: Boolean(this.newAdsCount),
            };
        },
        modifier() {
            return _.pick(this, 'error');
        },
        newAdsCount() {
            return this.$store.getters[`${SAVED_SEARCHES_STORE}/getSavedSearchCount`](this.savedSearchId) || DEFAULT_NEW_ADS_COUNT;
        },
    },
    watch: {
        error(value) {
            if (value) {
                _.delay(() => this.$emit('update:error', false), 1e3);
            }
        },
    },
    template: template(),
};
