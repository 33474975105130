const _ = require('lodash');
const async = require('async');
const {i18n: {translate: t}} = require('fack');
const {cityNameToInTheCity} = require('@bienici/city-name-formatter');

const VueApplicationPage = require('../pages/VueApplicationPage');
const AgencyPage = require('./components/AgencyPage');
const Account = require('../authentication/Account');
const Error404 = require('../Error404');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const {getRating} = require('../agency/components/Ratings');
const Views = require('../views/Views');

module.exports = class AgencyInfoBasePage extends VueApplicationPage {
    constructor(configuration) {
        super(_.extend({
            bodyClass: 'agency-info',
        }, configuration));
        this.configuration = configuration;
    }

    open(options) {
        this.options = _.extend(options, this.configuration);
        if (this._canOpenPage(options)) {
            super.open(this.options);
            this.updatePageHeaderTitle();
        } else {
            PageManager.openPage(PagesFactories.userRealEstateAdsPageFactory, options);
        }
    }

    getVueComponent(options) {
        return {
            components: {AgencyPage},
            computed: {
                author() {
                    return options.author;
                },
            },
            template: '<agency-page :agency="author" />',
        };
    }

    _loadData(options, cb) {
        const idOrNamespace = this._getAccountIdOrNamespace(options);
        async.parallel({
            author: cb => this.loadAccount(idOrNamespace, cb),
            rating: cb => this.loadRating(idOrNamespace, cb),
        }, (err, data) => {
            if (err) {
                cb(err);
            } else {
                const {author, rating} = data;
                cb(err, _.extend(options, {
                    author,
                    rating,
                }));
            }
        });
    }

    _canOpenPage(/* options */) {
        return true;
    }

    getTitle() {
        const {author} = this.options;
        const name = this.getAuthorName(author);
        const city = this.getAuthorCity(author);

        if (name && city) {
            const location = cityNameToInTheCity(city);
            return t('agency.pageTitle', {
                name,
                location,
            });
        }

        if (name) {
            return t('agency.pageTitleWithoutLocation', {
                name,
            });
        }

        return this.configuration.title;
    }

    getAuthorName(author) {
        return (author && author.display_name) || (this._author && this._author.display_name);
    }

    getAuthorCity(author) {
        return _.get(author, 'company.address.city');
    }

    loadRating(idOrNamespace, cb) {
        getRating(idOrNamespace, (err, data) => {
            const rating = _.get(data, 'rating');
            if (err || !rating || !rating.count) {
                if (err) {
                    console.error(err, 'Could not get rating, richsnippet will not be filled');
                }
                cb();
            } else {
                cb(null, _.pick(rating, [
                    'count',
                    'score',
                ]));
            }
        });
    }

    loadAccount(idOrNamespace, cb) {
        Account.get(idOrNamespace, (err, account) => {
            if (err) {
                if (err.code == 404) {
                    cb(new Error404(err.message));
                } else {
                    cb(err);
                }
            } else if (account) {
                cb(err, account);
            } else {
                cb(new Error404('user ' + idOrNamespace + ' not found'));
            }
        });
    }

    getHeaderTitle() {
        const {author} = this.options;
        const name = this.getAuthorName(author);
        if (name) {
            return t('agency.headerTitle', {
                name,
            });
        }
        return this.configuration.title;
    }

    updatePageHeaderTitle() {
        Views.header.update({
            headerTitle: this.getHeaderTitle(),
        });
    }
};
