const _ = require('lodash');
const seedrandom = require('seedrandom');

module.exports = function (array, size, seed) {
    // inspired by Lodash's _.sampleSize (himself inspired by Fisher-Yates shuffle)
    // https://en.wikipedia.org/wiki/Fisher-Yates_shuffle
    size = size == null ? 1 : size;
    const length = array == null ? 0 : array.length;
    if (!length || size < 1) {
        return [];
    }
    size = size > length ? length : size;
    const randomNumberGenerator = seedrandom(seed);
    const result = _.clone(array);
    let index = -1;
    const lastIndex = length - 1;
    while (++index < size) {
        const rand = index + Math.floor(randomNumberGenerator() * (lastIndex - index + 1));
        [result[index], result[rand]] = [result[rand], result[index]];
    }
    return result.slice(0, size);
};
