const UrlQueryHelper = require('../../common/UrlQueryHelper');
const urlUtils = require('url');

module.exports = {
    parseUrl,
};

const contactUsNameToQueryName = {
    senderTypes: 'vous-etes',
};

const contactUsValueToQueryValue = {
    senderTypes: {
        agency: 'agence',
        developer: 'promoteur',
    },
};

const contactUsQueryNameToOptionName = UrlQueryHelper.swapKeysValues(contactUsNameToQueryName);

function parseUrl(url) {
    return UrlQueryHelper.getConfigFromParsedQuery({
        parsedQuery: urlUtils.parse(url, true).query,
        queryNameToOptionName: contactUsQueryNameToOptionName,
        valueToQueryValue: contactUsValueToQueryValue,
    });
}
