const _ = require('lodash');
const template = require('./CriteriaListContainer.jade');

// @vue/component
module.exports = {
    props: {
        criteriaList: {
            type: Array,
            default: undefined,
        },
    },
    computed: {
        hasCriteria() {
            return !_.isEmpty(this.criteriaList);
        },
    },
    template: template(),
};
