const _ = require('lodash');
const template = require('./Icon.jade');
const ICON_MAPPING = require('./IconMapping');

// @vue/component
module.exports = {
    props: {
        icon: {
            type: String,
            required: true,
            values: _.keys(ICON_MAPPING),
        },
    },
    computed: {
        classes() {
            const {icon} = this;
            let iconClass = ICON_MAPPING[icon];
            if (!iconClass) {
                if (process.env.NODE_ENV !== 'production') {
                    console.warn('Please use a functional icon name instead of %s', icon);
                }
                iconClass = icon;
            }
            return _(iconClass)
                .castArray()
                .flatMap(iconClass => {
                    const [iconLib] = iconClass.split('-');
                    return [iconLib, iconClass];
                })
                .value();
        },
    },
    template: template(),
};
