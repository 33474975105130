const _ = require('lodash');
const $ = require('jquery');
const template = require('./BlogArticleForm.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const LocationField = require('../../../searchFields/LocationField');
const BlogHelper = require('../../BlogHelper');
const {blogs: ALL_BLOGS} = require('../../../../common/data/blogData');
const ArticleHelper = require('../../article/ArticleHelper');
const Views = require('../../../views/Views');

const ALL_BLOGS_OPTIONS = toOptions(ALL_BLOGS);
const ALL_BLOGS_OPTIONS_VALUES = _.map(ALL_BLOGS_OPTIONS, 'value');

const ALL_CATEGORIES = BlogHelper.getAllTags(function (tag) {
    return !tag.isHome;
});
const ALL_CATEGORIES_VALUES = _.map(ALL_CATEGORIES, 'slug');

const ALL_SUB_CATEGORIES = BlogHelper.getAllSubTags();
const ALL_SUB_CATEGORIES_OPTIONS = toOptions(ALL_SUB_CATEGORIES);
const ALL_SUB_CATEGORIES_OPTIONS_VALUES = _.map(ALL_SUB_CATEGORIES_OPTIONS, 'value');

const SEARCH_TYPES = ['buy', 'rent', 'buyNew', 'buyTerrain'];

const ARTICLE_DEFAULT_VALUES = {
    title: '',
    pageTitle: '',
    pitch: '',
    photos: [],
    iframeSrc: '',
    descriptionMarkdown: '',
    tags: [],
    rank: null,
    published: false,
    creditSimulationBlockEnabled: '',
    advertisingImage: [],
    advertisingLink: '',
    compactSearchForm: {
        locations: [],
        filterType: 'buy',
        enabled: false,
    },
};

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'BlogArticleForm.',
            keys: [
                'articleBodyTitle',
                'titleLabel',
                'pageTitleLabel',
                'pitchLabel',
                'photoLabel',
                'iframeSrcLabel',
                'descriptionMarkdownLabel',
                'articleLocationTitle',
                'blogsLabel',
                'blogsPlaceholder',
                'blogsNotEmptyErrorMessage',
                'categoriesLabel',
                'categoriesPlaceholder',
                'subCategoriesLabel',
                'subCategoriesPlaceholder',
                'rankLabel',
                'rankPlaceholder',
                'publishedLabel',
                'articleExtraTitle',
                'creditSimulatorLegend',
                'creditSimulationBlockEnabledLabel',
                'advertisingLegend',
                'advertisingImageLabel',
                'advertisingLinkLabel',
                'compactSearchFormLegend',
                'filterTypeLabel',
                'filterTypePlaceholder',
                'locationGroup',
                'compactSearchFormEnabledLabel',
                'submitButtonText',
                'cancelButtonText',
                'createArticleErrorMessage',
                'updateArticleErrorMessage',
            ],
        }),
    ],
    props: {
        article: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            form: _.defaults({}, this.article, ARTICLE_DEFAULT_VALUES),
            isSubmitting: false,
            blogsOptions: ALL_BLOGS_OPTIONS,
            selectedBlogs: [],
            selectedCategories: [],
            selectedSubCategories: [],
            photos: [],
            advertisingImage: [],
            photoUploading: false,
            advertisingImageUploading: false,
        };
    },
    computed: {
        editing() {
            return Boolean(this.article);
        },
        filterTypes() {
            return _.map(SEARCH_TYPES, (value) => {
                return {
                    value,
                    label: this.t(`filterType.${value}`),
                    selected: value === 'buy',
                };
            });
        },
        articleForSave() {
            const article = _.pick(this.form, _.keys(ARTICLE_DEFAULT_VALUES));
            const locations = _.map(this.locationField.getLocations(), location => location.getItemValuesToStoreInDB());
            article.compactSearchForm.locations = locations;
            article.tags = _.union(this.selectedBlogs, this.selectedCategories, this.selectedSubCategories);
            article.photos = this.getFilesToSave(this.photos);
            article.advertisingImage = this.getFilesToSave(this.advertisingImage);
            article.rank = _.defaultTo(article.rank, 0);
            return article;
        },
        uploadings() {
            return this.photoUploading || this.advertisingImageUploading;
        },
        categoriesOptions() {
            const availableCategories = _(ALL_BLOGS)
                .filter(blog => _.includes(this.selectedBlogs, blog.slug))
                .flatMap('tags')
                .reject('isHome')
                .value();
            return toOptions(availableCategories);
        },
        categorySelectionDisabled() {
            return _.isEmpty(this.categoriesOptions);
        },
        subCategoriesOptions() {
            const availableSubCategories = _(ALL_CATEGORIES)
                .filter(category => _.includes(this.selectedCategories, category.slug))
                .flatMap('subTags')
                .compact()
                .value();
            return toOptions(availableSubCategories);
        },
        subCategorySelectionDisabled() {
            return _.isEmpty(this.subCategoriesOptions);
        },
    },
    watch: {
        uploadings(value) {
            if (!value && this.isSubmitting) {
                this.submit();
            }
        },
    },
    created() {
        if (this.article) {
            const {tags, photos, advertisingImage} = this.article;
            this.selectedSubCategories = _.intersection(tags, ALL_SUB_CATEGORIES_OPTIONS_VALUES);
            const categoriesFromSelectedSubCategories = getCategoriesFromSubCategories(this.selectedSubCategories);
            this.selectedCategories = _.union(_.intersection(tags, ALL_CATEGORIES_VALUES), categoriesFromSelectedSubCategories);
            const blogsFromSelectedCategories = getBlogsFromCategories(this.selectedCategories);
            this.selectedBlogs = _.union(_.intersection(tags, ALL_BLOGS_OPTIONS_VALUES), blogsFromSelectedCategories);
            this.photos = _.map(photos, 'filename');
            this.advertisingImage = _.map(advertisingImage, 'filename');
        }
    },
    mounted() {
        this.locationField = new LocationField('locations', $(this.$el), {
            autoSelect: true,
            aroundMeEnabled: false,
            placeholderKey: 'userDirectory.placeholder.where',
        });
        this.locationField.setSuggestions(_.get(this.article, 'compactSearchForm.locations'));
        this.locationField.on('travelTimeSelected', () => this.$emit('open-time-travel-search', this.locationField));
    },
    methods: {
        submit() {
            this.isSubmitting = true;
            const isValid = this.$refs.blogArticleForm.validateForm();
            if (!this.uploadings && isValid) {
                if (this.editing) {
                    this.updateArticle();
                } else {
                    this.createArticle();
                }
            } else if (!isValid) {
                this.isSubmitting = false;
            }
        },
        updateArticle() {
            ArticleHelper.updateArticle(this.articleForSave, this.article.id, (err, updatedArticle) => {
                this.isSubmitting = false;
                if (err) {
                    console.error('Could not update blog article', err);
                    Views.volatileFeedback.showError(this.updateArticleErrorMessage);
                } else {
                    this.$emit('save-article', updatedArticle);
                }
            });
        },
        createArticle() {
            ArticleHelper.createArticle(this.articleForSave, (err, createdArticle) => {
                this.isSubmitting = false;
                if (err) {
                    console.error('Could not create blog article', err);
                    Views.volatileFeedback.showError(this.createArticleErrorMessage);
                } else {
                    this.$emit('save-article', createdArticle);
                }
            });
        },
        getFilesToSave(value) {
            if (!value || (_.isArray(value) && value.length === 0)) {
                return [];
            }
            const filename = Array.isArray(value) ? value[0] : value;
            return [{filename}];
        },
    },
    template: template(),
};

function toOptions(tags) {
    return _.map(tags, function (tag) {
        return {
            value: tag.slug,
            label: tag.name,
        };
    });
}

function getBlogsFromCategories(slugs) {
    return _(slugs)
        .map(slug => {
            const matchingBlog = _.find(ALL_BLOGS, ({tags}) => _.includes(_.map(tags, 'slug'), slug));
            return _.get(matchingBlog, 'slug');
        })
        .compact()
        .uniq()
        .value();
}

function getCategoriesFromSubCategories(slugs) {
    return _(slugs)
        .map(slug => {
            const matchingCategory = _.find(ALL_CATEGORIES, ({subTags}) => _.includes(_.map(subTags, 'slug'), slug));
            return _.get(matchingCategory, 'slug');
        })
        .compact()
        .uniq()
        .value();
}
