const {ResizeObserver} = require('@juggle/resize-observer');
const _ = require('lodash');

const BREAKPOINTS_RESOLVERS = {
    maxWidth(rect, desired) {
        return rect.width <= desired;
    },
    minWidth(rect, desired) {
        return rect.width > desired;
    },
    minHeight(rect, desired) {
        return rect.height > desired;
    },
};

module.exports = function (breakpoints) {
    // @vue/component
    return {
        data() {
            return {
                contentRect: {},
            };
        },
        computed: {
            breakpoints() {
                const {contentRect} = this;
                return _.mapValues(breakpoints, breakpoint => {
                    return isMatchingBreakpoint(contentRect, breakpoint);
                });
            },
        },
        watch: {
            breakpoints(breakpoints) {
                this.$emit('$cq:resize', breakpoints);
            },
        },
        mounted() {
            const observer = new ResizeObserver(entries => {
                _.each(entries, entry => {
                    this.contentRect = _.clone(entry.contentRect);
                });
            });
            observer.observe(this.$el);
            this.observer = observer;
        },
        destroyed() {
            this.observer.disconnect();
        },
    };
};

function isMatchingBreakpoint(rect, breakpoint) {
    return _.every(breakpoint, (value, name) => BREAKPOINTS_RESOLVERS[name](rect, value));
}
