const semver = require('semver');

const versionsMapping = {
    'phoneNumberToCallWithSpace': '1.0.8362',
};

module.exports = {
    isEnabledFeatureForVersion,
};

function getIosAppVersion(ua = navigator.userAgent) {
    const version = /kimono\/app\/([^\s]*)/.exec(ua);
    return version && version[1];
}

function isEnabledFeatureForVersion(featureName, userAgent) {
    const version = getIosAppVersion(userAgent);
    return version && isVersionHigherOrEqual(version, getFeatureVersion(featureName));
}

function getFeatureVersion(featureName) {
    return versionsMapping[featureName];
}

function isVersionHigherOrEqual(currentVersion, version) {
    return semver.gte(currentVersion, version);
}
