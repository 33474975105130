const _ = require('lodash');

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseInputMixin'),
    ],
    props: {
        value: {
            type: Number,
            default: undefined,
        },
    },
    beforeCreate() {
        this.type = 'number';
    },
    methods: {
        // override withInternalValue behavior: do nothing, the handling is done in onInput
        onInternalValueChange: _.noop,
        onInput({target}) {
            this.internalValue = target.value;
            const {valid, valueMissing, badInput} = target.validity;
            if (valid) {
                if (target.value) {
                    // most browsers convert the input from the locale to English, but not all (like IE)
                    const numberValue = parseLocaleNumber(target.value);
                    if (!_.isNaN(numberValue)) { // IE considers some bad inputs as valid (like "1..")
                        this.$emit('input', numberValue);
                    }
                } else {
                    this.$emit('input', null);
                }
            } else if (valueMissing && !badInput) {
                this.$emit('input', null);
            }
        },
    },
};

function parseLocaleNumber(stringNumber) {
    if ((0).toLocaleString) {
        const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
        if (thousandSeparator) {
            stringNumber = stringNumber.replace(new RegExp('\\' + thousandSeparator, 'g'), '');
        }
        const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');
        if (decimalSeparator) {
            stringNumber = stringNumber.replace(new RegExp('\\' + decimalSeparator), '.');
        }
    }
    return parseFloat(stringNumber);
}
