const {i18n: {translate}} = require('fack');
const {hasRole, authAjax} = require('../../authentication/Account');
const PageManager = require('../../pages/PageManager');
const PagesFactories = require('../../pages/PagesFactories');
const template = require('./ReinitializeSearchesAdsButton.jade');

// @vue/component
module.exports = {
    props: {
        savedSearch: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            isOnError: false,
        };
    },
    computed: {
        hasRoleSearchesAdsReinitializer() {
            return hasRole('searchesAdsReinitializer');
        },
        buttonText() {
            return this.saveSearchViewTranslate('reinitializeSearchesAdsButton');
        },
        errorText() {
            return this.saveSearchViewTranslate('searchesAdsReinitializationError');
        },
    },
    methods: {
        reinitializeSearchesAds() {
            this.isLoading = true;
            const {_id: savedSearchId} = this.savedSearch;
            authAjax({
                url: '/reinitializeSearchesAds',
                method: 'POST',
                disableErrorPage: true,
                dataType: 'text',
                contentType: 'application/json',
                data: JSON.stringify({savedSearchId}),
                callback: err => {
                    if (err) {
                        this.isOnError = true;
                        console.error(err, 'Could not reinitialize searches ads for saved search %s', savedSearchId);
                    } else {
                        this.isOnError = false;
                        PageManager.openPage(PagesFactories.savedSearchAdsPageFactory, {savedSearch: this.savedSearch});
                    }
                    this.isLoading = false;
                },
            });
        },
        saveSearchViewTranslate(key) {
            return translate(`saveSearchView.${key}`);
        },
    },
    template: template(),
};
