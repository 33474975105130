const template = require('./ContactList.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ContactListContact = require('./ContactListContact');

// @vue/component
module.exports = {
    components: {
        ContactListContact,
    },
    mixins: [
        i18nMixin({
            prefix: 'contactView.contactList.',
        }),
    ],
    props: {
        accountType: {
            type: String,
            default: undefined,
        },
        contactRequests: {
            type: Array,
            required: true,
        },
    },
    computed: {
        title() {
            return this.t('title', {
                accountType: this.accountType,
            });
        },
    },
    template: template(),
};
