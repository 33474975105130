const _ = require('lodash');
const template = require('./PitchBlock.jade');

const MODIFIERS_NAMES = [
    'reverseBackgroundColor',
    'withSpeechBubbleTail',
    'secondColor',
];
const DEFAULT_SPEECH_BUBBLE_TAIL_DIRECTION = 'top';
const SPEECH_BUBBLE_TAIL_DIRECTIONS = [DEFAULT_SPEECH_BUBBLE_TAIL_DIRECTION, 'left'];

// @vue/component
module.exports = {
    props: _.extend(
        _(MODIFIERS_NAMES)
            .map(name => [name, Boolean])
            .fromPairs()
            .value(),
        {
            speechBubbleTailDirection: {
                type: String,
                default: DEFAULT_SPEECH_BUBBLE_TAIL_DIRECTION,
                validator: direction => _.includes(SPEECH_BUBBLE_TAIL_DIRECTIONS, direction),
                values: SPEECH_BUBBLE_TAIL_DIRECTIONS,
            },
        }
    ),
    computed: {
        modifiers() {
            const {
                reverseBackgroundColor,
                withSpeechBubbleTail,
                speechBubbleTailDirection,
                secondColor,
            } = this;
            const arrowModifier = `with${_.upperFirst(speechBubbleTailDirection)}SpeechBubbleTail`;
            return {
                reverseBackgroundColor,
                secondColor,
                [arrowModifier]: withSpeechBubbleTail,
                [`${arrowModifier}AndReverseBackgroundColor`]: withSpeechBubbleTail && reverseBackgroundColor,
            };
        },
    },
    template: template(),
};
