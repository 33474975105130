const ModalFormView = require('../views/ModalFormView');
const template = require('../templates/views/directOffersAndNewsLetterPopupView.jade');
const Account = require('../authentication/Account');
const _ = require('lodash');
const FormUtils = require('../fields/FormUtils');
const Views = require('../views/Views');
const GoogleTagManager = require('../stats/GoogleTagManager');

module.exports = class DirectOffersAndNewsLetterPopupView extends ModalFormView {
    constructor() {
        super({template});
    }

    show(options) {
        super.show(options);
        const account = Account.getAuthenticatedAccount();
        if (account) {
            FormUtils.setValues(this.$form, {
                'newsLetter.subscribe': account.email,
            });
        }
        this._bindEvents();
    }

    _bindEvents() {
        this._eventsWhileShown.on(this.$modal, {
            click: {
                '.btnCloseModal': () => this.emit('close'),
                '.validateBtn': _.bind(this.hide, this),
            },
        });
    }

    submitForm() {
        const userMail = _.get(FormUtils.readValues(this.$form), 'newsLetter.subscribe');
        Account.subscribeToNewsletter(userMail, err => {
            if (err) {
                console.error('subscribe newsLetter failed from popup', err);
                Views.volatileFeedback.showError('newsLetter.errorMessage');
                this.hide();
            } else {
                GoogleTagManager.sendEvent('newsLetterSubscription', {
                    userMail,
                });
                this.$element.find('.directOffersAndNewsLetterPopupDescription').hide();
                this.$element.find('.directOffersAndNewsLetterSuccessFeedback').show();
            }
        });
    }
};
