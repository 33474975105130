const _ = require('lodash');
const template = require('./ContactAgencyButton.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {props: {variant: {PRIMARY_VARIANT, LINK_VARIANT}}} = require('../../vue/components/Button');
const AgencyContactForm = require('./AgencyContactForm');
const GoogleTagManager = require('../../stats/GoogleTagManager');

const GTM_SOURCE = 'fromAgency';

// @vue/component
module.exports = {
    components: {
        AgencyContactForm,
    },
    mixins: [
        i18nMixin({
            prefix: 'ContactAgencyButton.',
            keys: [
                'contactUsText',
                'neutralContactText',
                'contactUsFormTitle',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
        variant: {
            type: String,
            default: PRIMARY_VARIANT,
            validator: variant => _.includes([PRIMARY_VARIANT, LINK_VARIANT], variant),
        },
        fab: Boolean,
        displayName: {
            type: String,
            default: undefined,
        },
        gtmSource: {
            type: String,
            default: GTM_SOURCE,
        },
    },
    data() {
        return {
            isFormDisplayed: false,
        };
    },
    computed: {
        contactText() {
            const {displayName, contactUsText, neutralContactText} = this;
            return displayName ? neutralContactText : contactUsText;
        },
        contactFormTitle() {
            const {
                displayName,
                contactUsFormTitle,
            } = this;
            return displayName ? this.t('neutralContactFormTitle', {displayName}) : contactUsFormTitle;
        },
    },
    methods: {
        displayForm() {
            this.isFormDisplayed = true;
            this.sendOpenContactModalGTMEvent();
        },
        handleAgencyContactFormSubmit(reason) {
            const {
                gtmSource,
                agency: {id},
            } = this;
            GoogleTagManager.sendAgencyContactFormSubmittedEvent(id, reason, gtmSource);
            this.isFormDisplayed = false;
        },
        sendOpenContactModalGTMEvent() {
            const {
                gtmSource,
                agency: {id},
            } = this;
            GoogleTagManager.sendAgencyContactFormShownEvent(id, gtmSource);
        },
    },
    template: template(),
};
