const $ = require('jquery');
const triggerNativeEvent = require('../triggerNativeEvent');

const template = require('../../templates/fields/mailcheck.jade');

module.exports = {
    init,
    listenOnInput,
};

// still failing :
// - pierrett@orange.fre.clochette
// - audrey.pmr@gmaim.com
function init({$form}) {
    listenOnInput($form.find('input[type=email]'));
}

function listenOnInput($input) {
    $input.on('blur', function () {
        $(this).mailcheck({
            domains: [
                'gmail.com', 'free.fr', 'orange.fr', 'wanadoo.fr', 'sfr.fr', 'numericable.fr', 'noos.fr', 'bbox.fr', 'bouygues.fr',
                'cegetel.net', 'icloud.com', 'laposte.net', 'neuf.fr', 'numericable.com',
                'hotmail.com', 'yahoo.com', 'outlook.com', 'live.com',
                'hotmail.fr', 'yahoo.fr', 'outlook.fr', 'live.fr',
            ],
            topLevelDomains: ['com', 'net', 'org', 'fr'],
            suggested: function (element, suggestion) {
                removeSuggestionDiv(element);
                addSuggestionDiv(element, suggestion);
            },
            empty: function (element) {
                removeSuggestionDiv(element);
            },
        });
    });
}

function removeSuggestionDiv(element) {
    $(element).parent().find('.mailcheck_suggestion').remove();
}

function addSuggestionDiv(element, suggestion) {
    const $node = $(template({suggestion}));
    const $element = $(element);
    $element.parent().append($node);
    const find = $node.find('.applySuggestion');
    find.on('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
        $element.val(suggestion.full);
        triggerNativeEvent($element, 'input');
        removeSuggestionDiv(element);
    });
}
