const _ = require('lodash');
const template = require('./Pagination.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const SeoUtils = require('../../utils/SeoUtils');

const MAIN_PAGINATION_PAGE_COUNT = 10;
const DEFAULT_MAX_AUTHORIZE_RESULTS = 1e6;
const SECONDARY_PAGINATION_OFFSET = 10;
const SECONDARY_PAGINATION_ELEMENTS_COUNT = 10;

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'Pagination.',
        }),
    ],
    props: {
        searchResults: {
            type: Object,
            default: null,
        },
        pageIndex: {
            type: Number,
            default: 0,
        },
        buildUrlForPage: {
            type: Function,
            default: null,
        },
    },
    computed: {
        isPaginationNeeded() {
            const isExtendedWithSearchAround = _.get(this.searchResults, 'isExtendedWithSearchAround');
            return !isExtendedWithSearchAround && this.totalResults > this.resultsPerPage;
        },
        showSecondaryPagination() {
            return this.totalPages > MAIN_PAGINATION_PAGE_COUNT;
        },
        secondaryPaginationModifiers() {
            return {
                firstPages: this.pageNumber < MAIN_PAGINATION_PAGE_COUNT,
            };
        },
        pageNumber() {
            return this.pageIndex + 1;
        },
        totalResults() {
            return _.get(this.searchResults, 'totalResults', 0);
        },
        resultsPerPage() {
            return _.get(this.searchResults, 'resultsPerPage', 0);
        },
        previousButtonParams() {
            return this.buildNavigationButtonParams('back');
        },
        nextButtonParams() {
            return this.buildNavigationButtonParams('forward');
        },
        mainPaginations() {
            const {pageNumber, linkOffset} = this;
            const paginations = [];
            for (let i = 0; i < MAIN_PAGINATION_PAGE_COUNT; i++) {
                const targetPageNumber = pageNumber + i - linkOffset;
                if (this.isLinkShown(targetPageNumber)) {
                    const pagination = this.buildPaginationObject(targetPageNumber, String(targetPageNumber));
                    paginations.push(pagination);
                }
            }
            return paginations;
        },
        secondaryPaginations() {
            const paginations = [];
            for (let i = 0; i <= SECONDARY_PAGINATION_ELEMENTS_COUNT; i++) {
                const targetPageNumber = i === 0 ? 1 : i * SECONDARY_PAGINATION_OFFSET;
                const suffix = targetPageNumber !== this.totalPages ? '+' : '';
                if (this.isLinkShown(targetPageNumber)) {
                    const pagination = this.buildPaginationObject(targetPageNumber, targetPageNumber + suffix);
                    paginations.push(pagination);
                }
            }
            return paginations;
        },
        totalPages() {
            const {totalResults, resultsPerPage} = this;
            const maxAuthorizedResults = _.get(this.searchResults, 'maxAuthorizedResults', 0);
            const effectiveMaxAuthorizedResults = maxAuthorizedResults || DEFAULT_MAX_AUTHORIZE_RESULTS;
            return resultsPerPage === 0 ? 0 : Math.ceil(Math.min(totalResults, effectiveMaxAuthorizedResults) / resultsPerPage);
        },
        linkOffset() {
            const {totalPages, pageIndex} = this;
            const middlePositionIndex = Math.floor(MAIN_PAGINATION_PAGE_COUNT / 2);
            if (pageIndex < middlePositionIndex) {
                return pageIndex;
            } else if (pageIndex + middlePositionIndex >= totalPages) {
                return MAIN_PAGINATION_PAGE_COUNT - (totalPages - pageIndex);
            } else {
                return middlePositionIndex;
            }
        },
    },
    watch: {
        searchResults() {
            this.updateHeadLinks();
        },
    },
    mounted() {
        this.updateHeadLinks();
    },
    methods: {
        updateHeadLinks() {
            const {searchResults, isPaginationNeeded, pageNumber, totalPages} = this;
            if (!searchResults || !isPaginationNeeded) {
                SeoUtils.updateNextPrevLinks(); // clean up head links
            } else {
                if (_.isFunction(this.buildUrlForPage)) {
                    SeoUtils.updateNextPrevLinks({
                        prev: pageNumber > 1 ? this.buildUrlForPage(pageNumber - 1) : null,
                        next: pageNumber < totalPages ? this.buildUrlForPage(pageNumber + 1) : null,
                    });
                }
            }
        },
        navButtonsTranslate(key) {
            const {pageNumber, totalPages} = this;
            const context = (pageNumber === totalPages || pageNumber === 1) ? {context: 'end'} : {};
            return this.t(key, context);
        },
        isLinkShown(targetPageNumber) {
            return 0 < targetPageNumber && targetPageNumber <= this.totalPages;
        },
        buildPaginationObject(targetPageNumber, text) {
            const isCurrentPage = this.pageNumber === targetPageNumber;
            return {
                key: text + String(isCurrentPage),
                url: _.isFunction(this.buildUrlForPage) ? this.buildUrlForPage(targetPageNumber) : null,
                text,
                pageIndex: targetPageNumber - 1,
                isCurrentPage,
            };
        },
        buildNavigationButtonParams(buttonType) {
            const targetPageNumber = buttonType === 'back' ? this.pageNumber - 1 : this.pageNumber + 1;
            return {
                show: this.isLinkShown(targetPageNumber),
                ...this.buildPaginationObject(targetPageNumber, this.navButtonsTranslate(buttonType)),
            };
        },
    },
    template: template(),
};
