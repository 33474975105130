const template = require('./NeighborhoodCategoryItem.jade');

// @vue/component
module.exports = {
    props: {
        className: {
            type: String,
            default: null,
        },
        subItemClassPrefix: {
            type: String,
            default: null,
        },
        departmentClass: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            required: true,
        },
        values: {
            type: Array,
            required: true,
        },
    },
    computed: {
        itemStyle() {
            return this.className ? null : {fontWeight: 'bold'};
        },
    },
    methods: {
        subItemClass(value) {
            return this.subItemClassPrefix ? this.subItemClassPrefix + value : null;
        },
    },
    template: template(),
};
