const _ = require('lodash');
const {generateCityAndPostalCode} = require('../../../common/formatters/AddressFormatter');
const template = require('./ContactAddress.jade');

// @vue/component
module.exports = {
    props: {
        address: {
            type: Object,
            default: undefined,
        },
        showStreet: {
            type: Boolean,
            default: true,
        },
        multiline: Boolean,
    },
    computed: {
        street() {
            if (this.showStreet) {
                return this.address.street;
            }
            return undefined;
        },
        postalCodeAndCity() {
            return generateCityAndPostalCode(this.address);
        },
        addressText() {
            return _.compact([
                this.street,
                this.postalCodeAndCity,
            ]).join(' - ');
        },
    },
    template: template(),
};
