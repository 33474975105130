const DateFormatter = require('../../../common/formatters/DateFormatter');
const ActionAndButton = require('./mixins/ActionAndButton');
const template = require('./SetFeaturedButton.jade');
const _ = require('lodash');

const FEATURES_TO_ICON = {
    isLeading: {
        set: 'leading-off',
        reset: 'leading',
    },
    highlighted: {
        set: 'highlighted-off',
        reset: 'highlighted',
    },
};

// @vue/component
module.exports = {
    mixins: [
        ActionAndButton(),
    ],
    computed: {
        text() {
            if (this.isLocked) {
                return DateFormatter.humanizeEndDate(this.expirationDate);
            } else {
                return this.t(this.isFeatured ? 'reset' : 'set');
            }
        },
        isLocked() {
            return this.isTemporary && this.isFeatured;
        },
        isTemporary() {
            return Boolean(this.expirationDate);
        },
        className() {
            const unlocked = this.isFeatured ? 'on' : 'off';
            const modifier = this.isLocked ? 'locked' : unlocked;
            const {disabled} = this;
            return {
                ['set-featured-button_' + modifier]: true,
                disabled,
                'btn-highlighted': this.icon === FEATURES_TO_ICON.highlighted.reset,
            };
        },
        icon() {
            return _.get(FEATURES_TO_ICON[this.featureName], this.isFeatured ? 'reset' : 'set');
        },
    },
    template: template(),
};
