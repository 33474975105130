// @vue/component
module.exports = {
    mixins: [
        require('./NumberField'),
    ],
    computed: {
        extraAttributes() {
            return {
                min: 1,
                step: 1,
            };
        },
        unit() {
            return '€';
        },
    },
};
