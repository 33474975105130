const _ = require('lodash');
const SimpleContentPagesCommon = require('./SimpleContentPagesCommon');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');

const COMMON_CENSI_BOUVARD_CONFIGURATION = {
    contentPath: 'censi-bouvard-simulator',
    title: 'Calculez votre Censi-Bouvard',
    gtmCategory: 'ToolsAndSimulators',
};

const publicPages = [
    {
        contentPath: 'legal-information' + (UserAgentHelper.isFromNativeApp() ? '-app' : ''),
        title: 'Informations légales',
        bodyClass: 'defaultPageWithNumberedTitles',
        isRegistrationRequired: false,
        gtmCategory: 'Content',
    },
    {
        contentPath: 'about',
        title: 'Découvrez Bien’ici',
        urlPattern: 'decouvrez-bienici',
        gtmCategory: 'Content',
        bodyClass: 'decouvrez-bienici',
    },
    {
        contentPath: 'legal-information-app',
        title: 'Informations légales application',
        bodyClass: 'defaultPageWithNumberedTitles',
        isRegistrationRequired: false,
        gtmCategory: 'Content',
    },
    _.extend({
        urlPattern: 'loi-censi-bouvard',
    }, COMMON_CENSI_BOUVARD_CONFIGURATION),
    _.extend({
        urlPattern: 'resultats-simulation-censi-bouvard',
    }, COMMON_CENSI_BOUVARD_CONFIGURATION),
];

module.exports = _.union(publicPages, SimpleContentPagesCommon);
