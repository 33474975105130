const NewPropertyLandingPage = require('./NewPropertyLandingPage');

module.exports = class InvestNewPropertyLandingPage extends NewPropertyLandingPage {
    constructor() {
        super({
            name: 'investNewPropertyLandingPage',
            title: 'Investir en locatif dans l’immobilier neuf',
        });
    }

    getUrlPattern() {
        return '/acheter-pour-investir-en-locatif-dans-l-immobilier-neuf';
    }
};
