const _ = require('lodash');
const View = require('./views/View');
const template = require('./templates/browserWarning.jade');
const Cookies = require('./Cookies.js');

module.exports = class BrowserWarningView extends View {
    constructor() {
        super({
            template,
        });
    }

    show(options) {
        super.show(options);
        const {$element} = this;
        $element.find('.acceptBrowserWarning').on('click', _.bind(this.hide, this));
        $element.find('a').on('click', _.bind(this.hide, this));
        setTimeout(() => {
            this.toggleMessage(true);
        }, 1000);
    }

    toggleMessage(isVisible) {
        this.$element.toggleClass('isVisible', isVisible);
    }

    hide(options, cb) {
        Cookies.registerNotSupportedDisclaimerDismissed();
        this.toggleMessage(false);
        setTimeout(() => {
            super.hide(options, cb);
        }, 1000);
    }
};
