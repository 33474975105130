const template = require('./AdModificationLink.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AdModificationLink.',
        }),
    ],
    props: {
        realEstateAdId: {
            type: String,
            required: true,
        },
        displayText: Boolean,
        useLocationOnlyText: Boolean,
    },
    computed: {
        url() {
            return `/modifier-une-annonce/${this.realEstateAdId}`;
        },
        text() {
            let text;
            if (this.displayText) {
                text = this.t('text', {
                    context: this.useLocationOnlyText ? 'locationOnly' : null,
                });
            }
            return text;
        },
    },
    template: template(),
};
