const _ = require('lodash');
const url = require('url');
const Ajax = require('./utils/Ajax');

module.exports = {
    load,
};

/**
 *
 * @param {string|object} options
 * @param {function} [callback]
 */
function load(options, callback) {
    const {apiKey, f4mapApiOptions} = options;
    Ajax.request({
        url: url.resolve(options.baseUrl || '', '/config.json'),
        data: {
            api_key: apiKey,
            referrer: location.href,
        },
        callback: function (err, config) {
            if (!err) {
                _.extend(module.exports, {
                    config,
                    apiKey,
                    f4mapApiOptions,
                });
            }
            callback(err, config);
        },
    });
}
