const _ = require('lodash');

const SearchPageBase = require('./SearchPageBase');
const SavedSearches = require('../search/SavedSearches');
const Error404 = require('../Error404');

module.exports = class SavedSearchSearchPage extends SearchPageBase {
    constructor(configuration) {
        super(_.extend({
            name: 'savedSearchSearchPage',
        }, configuration));
    }

    parseUrl(url) {
        const splitUrl = url.split('/');
        const id = _.find(splitUrl, (part) => {
            return new RegExp('[0-9a-f]{24}').exec(part);
        });
        return {
            savedSearch: {
                _id: id,
            },
        };
    }

    _loadData({savedSearch}, cb) {
        this._loadSavedSearchById(savedSearch._id, cb);
    }

    _loadSavedSearchById(id, cb) {
        if (!id) {
            cb(new Error404('No saved search id'));
        } else {
            SavedSearches.getOneById(id, {
                updateLastInteractionDate: true,
                updateLastViewDateForSearch: true,
            }, (err, savedSearch) => {
                if (err) {
                    cb(err);
                } else if (!savedSearch) {
                    cb(new Error404('No saved search found with this id'));
                } else {
                    const options = {
                        search: _.extend({}, savedSearch.searchCriteria, {
                            sortBy: 'publicationDate',
                            sortOrder: 'desc',
                        }),
                        savedSearch: _.omit(savedSearch, 'searchCriteria'),
                    };
                    super._loadData(options, cb);
                }
            });
        }
    }
};
