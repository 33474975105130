// from https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners

/* Feature detection */
let optionParameterSupported = false;

try {
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line getter-return
        get: function () {
            optionParameterSupported = {passive: true};
        },
    }));
} catch (err) {
    // ignore err
}

module.exports = {
    optionParameterSupported,
};
