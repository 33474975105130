const $ = require('jquery');

module.exports = {
    sendError,
    incCounter,
    sendTiming,
};

function post(path, data) {
    const analyticsUrl = getAnalyticsUrl();
    if (analyticsUrl) {
        $.ajax({
            url: analyticsUrl + path,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data),
        });
    }
}

function sendError(category, errorStr) {
    post('/error/' + category.type, {
        detail: errorStr,
    });
}

function incCounter(name) {
    post('/count/inc/' + name);
}

function sendTiming(name, data) {
    post('/timing/' + name, data);
}

function getAnalyticsUrl() {
    const ServerConfig = require('../ServerConfig'); // errors could happen before the config is loaded
    return ServerConfig.config && ServerConfig.config.analyticsUrl;
}
