const template = require('./FindProAdsOnThePublicSiteBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ServerConfig = require('../../ServerConfig');

// @vue/component
module.exports = {
    constants: {
        BASE_URL: '/annonces-',
    },
    mixins: [
        i18nMixin({
            prefix: 'FindProAdsOnThePublicSiteBlock.',
            keys: [
                'websiteLinkLabel',
            ],
        }),
    ],
    props: {
        account: {
            type: Object,
            required: true,
        },
        compact: Boolean,
    },
    data() {
        return {
            collapsedBlock: true,
        };
    },
    computed: {
        websiteLink() {
            const {account, BASE_URL} = this;
            return ServerConfig.config.wwwUrl + BASE_URL + (account.namespace || account.id);
        },
    },
    template: template(),
};
