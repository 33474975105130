const _ = require('lodash');
const VueView = require('./VueView');
const WebsitesUrls = require('./mixins/WebsitesUrls');

module.exports = class SimpleContentVueView extends VueView {
    show(templateOptions, vueOptions) {
        super.show(templateOptions, _.extend({
            mixins: [
                WebsitesUrls,
            ],
            template: '<div>' + (templateOptions.htmlContent || '') + '</div>',
        }, vueOptions));
    }

    update(options) {
        this.hide();
        this.show(options);
    }
};
