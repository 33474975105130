const ArticleCreationOrModificationPage = require('./ArticleCreationOrModificationPage');

const PATH = '/creer-article';

module.exports = class ArticleCreationPage extends ArticleCreationOrModificationPage {
    constructor() {
        super({
            name: 'articleCreationPage',
            title: 'Créer un article',
        });
    }

    getUrlPattern() {
        return PATH;
    }

    getUrl() {
        return PATH;
    }
};
