const polyline = require('polyline');
const _ = require('lodash');

module.exports = function parseLimit(limit) {
    if (_.isString(limit)) {
        const points = polyline.decode(limit);
        const coordinates = _.map(points, function (latLng) {
            return [latLng[1], latLng[0]]; // swap for GeoJSON
        });
        if (!_.isEqual(coordinates[0], coordinates[coordinates.length - 1])) {
            coordinates.push(coordinates[0]);
        }
        return {
            type: 'Polygon',
            coordinates: [coordinates],
        };
    } else {
        return limit;
    }
};
