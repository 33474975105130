const template = require('./HeaderLink.jade');

// @vue/component
module.exports = {
    inject: ['isSelected'],
    props: {
        href: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: undefined,
        },
        textSmall: {
            type: String,
            default: undefined,
        },
        activeHeaderItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            hasSlot: null,
        };
    },
    computed: {
        rootClass() {
            return {
                active: this.isSelected(this.activeHeaderItems),
            };
        },
    },
    mounted() {
        this.setHasSlot();
        const observer = new MutationObserver(this.setHasSlot);
        observer.observe(this.$el, {
            childList: true,
            subtree: true,
        });
        this.observer = observer;
    },
    destroy() {
        this.observer.disconnect();
    },
    methods: {
        setHasSlot() {
            this.hasSlot = Boolean(this.$slots.default);
        },
    },
    template: template(),
};
