const History = require('../utils/History');
const $ = require('jquery');
const _ = require('lodash');
const Urls = require('../Urls');
const ViewManager = require('../views/ViewManager');
const Views = require('../views/Views');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const SideMapPage = require('./SideMapPage');
const Account = require('../authentication/Account');
const PageManager = require('./PageManager');
const SearchDataLoader = require('../search/SearchDataLoader');
const SearchView = require('../search/SearchView');
const Title = require('../pages/Title');
const Cookies = require('../Cookies');
const metaTagHelper = require('../utils/metaTagHelper');
const Prerenderer = require('../utils/Prerenderer');
const Pages = require('../pages/Pages');
const PagesFactories = require('../pages/PagesFactories');
const GoogleTagManager = require('../stats/GoogleTagManager');
const ApplicationConfig = require('../app/ApplicationConfig');
const EventPack = require('../utils/EventPack');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const moment = require('../../common/momentFr');
const UserTracking = require('../stats/UserTracking');
const safeFrameAdUtils = require('../utils/safeFrameAdUtils');
const {
    DISPLAY_MODE_GALLERY,
    DISPLAY_MODE_MAP,
} = require('../search/constants');

const titlesOptions = {
    title: {
        showLocationCode: true,
        locationCodeFormat: 'department',
    },
};

/**
 * @constructor
 * @augments ApplicationPage
 * @param {object} [configuration]
 * @param {string} [configuration.title] optional page title - this will disable automatic titles
 * @param {boolean} [configuration.filterEnabled]
 * @param {boolean} [configuration.mapEnabled]
 * @param {boolean} [configuration.showMap]
 * @param {boolean} [configuration.countTotalAds=false] counts unfiltered ads
 * @param {string} configuration.noAdsTemplate custom template when no ads are counted with countTotalAds=true
 * @param {object} [configuration.searchDefaults] default search
 * @param {string[]} [configuration.searchDefaults.filterType] default filterType
 * @param {string[]} [configuration.searchDefaults.propertyType] default propertyType
 * @param {string} configuration.searchDefaults.sortBy field to sort on
 * @param {string} configuration.searchDefaults.sortOrder direction for sorting (asc or desc)
 * @param {boolean} [configuration.searchDefaults.newProperty] true for new only, false for old only, null for both
 * @param {boolean[]} [configuration.searchDefaults.onTheMarket] array that can contains true, false or both
 * @param {boolean} configuration.zoomToResults zoom camera to fit search results
 */
module.exports = class SearchPageBase extends SideMapPage {
    constructor(configuration = {}) {
        const finalConfiguration = _.defaults({}, configuration, {
            showMap: true,
            defaultListMode: DISPLAY_MODE_MAP,
            type: 'search',
            appendReturnUrlToDetailedSheetUrl: true,
            moreFiltersVisible: false,
            saveSearchAllowed: true,
            searchFiltersFromHeaderEnabled: false,
            disableReloadPageOnLogin: true,
            mapFilterBtnEnabled: true,
            isAnAgency: false,
            searchWithoutAgencyBtnEnabled: false,
            mapButtonsSearchEnabled: false,
            searchDefaults: DefaultConfiguration.search,
            gtmPersistentFields: ['resultsShown'],
            gtmCategory: 'Results',
            enableBreadcrumb: false,
            dataModeListEnabled: true,
        });

        if (!finalConfiguration.showMap && finalConfiguration.defaultListMode == DISPLAY_MODE_MAP) {
            finalConfiguration.defaultListMode = DISPLAY_MODE_GALLERY;
        }
        super(finalConfiguration);
        this.configuration = finalConfiguration;
        this._eventPack = new EventPack();
    }

    open(options, cb) {
        if (Account.isShowRoom()) {
            this.configuration.saveSearchAllowed = false;
        }
        options = options || {};
        $('body').addClass(this.bodyClass);
        const searchView = this.searchView = this._getSearchView(options);
        this._openViews(options, cb);
        this._setOpenPageOptions(_.defaults({}, this.configuration, options));
        this.emit('readyToBeShown');

        this._eventPack.on(searchView, {
            updateTitle: _.bind(this._updateTitle, this),
            realEstateAds: _.bind(this._handleResults, this),
            listModeChanged: _.bind(this.onListModeChanged, this),
            searchWithoutAgency: _.bind(this._searchWithoutAgency, this),
            resultsShown: _onResultsShown,
            adClick: options => {
                this._openPage(Pages.detailedSheet, options);
            },
        });

        this._eventPack.on(Views.header, {
            editFiltersOpened: _.bind(this.searchView.saveScroll, this.searchView),
            editFiltersClosed: _.bind(this.searchView.restoreScroll, this.searchView),
        });

        this._eventPack.on(SideMapViewSingleton.get(), {
            mapExpanded: _.bind(this._onMapExpanded, this),
            geolocationClicked: _.bind(this.onGeolocationClicked, this),
            togglePoi: data => {
                GoogleTagManager.sendEvent('togglePoi', data);
            },
        });

        this._eventPack.on($(window), 'resize', _.bind(this.handleWindowResize, this));

        this.listView = this.getListView();
        this.searchView.updateListMode();
        SideMapViewSingleton.get().onSearchPageOpen();
        if (searchView.resultShownEventDataForGTM) {
            _onResultsShown(searchView.resultShownEventDataForGTM);
        }
        safeFrameAdUtils.renderSafeFrameAdOnSearchResults(options, this.searchView.searchCriteria, this.configuration.name);
    }

    onGeolocationClicked(cb) {
        this.searchView.onGeolocationClicked(cb);
    }

    _searchWithoutAgency(options) {
        const openOptions = {search: options};
        PageManager.openPage(PagesFactories.searchPageFactory, openOptions);
    }

    _onMapExpanded(isFullScreen) {
        if (!isFullScreen) {
            this._updateShowMap();
        }
    }

    handleWindowResize() {
        const width = $(window).width();
        if (width != this._lastWidth) {
            if (!SideMapViewSingleton.get().isMapExpanded()) {
                this._updateShowMap();
            }
            this._lastWidth = width;
        }
    }

    _updateShowMap() {
        this.searchView.updateListMode();
    }

    onListModeChanged(mode) {
        const newMode = mode;
        if (this.searchView && this.searchView.configuration && this.searchView.configuration.defaultListMode) {
            this.searchView.configuration.defaultListMode = newMode;
        }
        Cookies.saveListMode(this.getName(), newMode);
        GoogleTagManager.sendEvent('listModeChanged', {mode: mode});
    }

    close() {
        safeFrameAdUtils.removeAdAndResetSafeFrameRelatedStyles();
        metaTagHelper.removeMeta('robots');
        this._eventPack.removeAllListeners();
        SideMapPage.prototype.close.apply(this, arguments);
    }

    _handleResults(realEstateAds) {
        this.resultsCount = realEstateAds.length;
        if (this.resultsCount == 0) {
            metaTagHelper.setMetaTags({robots: 'noindex'});
        } else {
            metaTagHelper.removeMeta('robots');
        }
    }

    getListView() {
        return this.searchView.getListView();
    }

    _shouldShowMap() {
        const listMode = this.searchView.getListMode();
        const mapModeHasBeenChanged = listMode != this.configuration.defaultListMode;
        return ((this.configuration.showMap || mapModeHasBeenChanged)
            && listMode == DISPLAY_MODE_MAP && this.searchView.doesWindowSupportFullScreen());
    }

    _getSearchView(options) {
        //init default listMode with cookie
        const savedListMode = Cookies.getListModeForPage(this.getName());
        if (savedListMode && !ApplicationConfig.applicationPro) {
            this.configuration.defaultListMode = savedListMode;
        }
        if (!this.configuration.showMap && this.configuration.defaultListMode == DISPLAY_MODE_MAP) {
            this.configuration.defaultListMode = DISPLAY_MODE_GALLERY;
        }
        return this.searchView
            || options.searchView
            || new SearchView(_.extend(
                {getTitleOptions: _.bind(this.getTitleOptions, this)},
                this.configuration,
                options
            ));
    }

    getViews(options) {
        this.searchView = this._getSearchView(options);
        const views = [Views.header, this.searchView];
        const showMap = this._shouldShowMap();
        if (showMap) {
            this.sideMapView = SideMapViewSingleton.get();
            views.push(this.sideMapView);
        }
        return views;
    }

    _openViews(options, cb) {
        const views = this.getViews(options);
        const viewsOptions = _.extend({
            isAdmin: Account.isAdmin(),
            getUrlForAd: _.bind(this.getUrlForAd, this),
            getPaginationUrl: _.bind(this.getPaginationUrl, this),
            updateUrl: _.bind(this._updateUrl, this),
            onResultsShown: cb, // todo: fix SearchView so that on open, the results are displayed
        }, this.configuration, options);
        viewsOptions.showMap = this._shouldShowMap();
        ViewManager.openViews(views, viewsOptions);
        if (options.volatileFeedbackOnOpen) {
            displayVolatileFeedback(options.volatileFeedbackOnOpen);
        }
    }

    getUrlForAd(realEstateAd, {inHighlightedBox, scrollTo}) {
        const addSearchOptionsToUrl = !Prerenderer.insidePrerenderer() && this.options.appendReturnUrlToDetailedSheetUrl;
        const closePageUrl = addSearchOptionsToUrl ? this.getUrl() : null;
        return Urls.detailedSheet.makeUrl(realEstateAd, closePageUrl, scrollTo, null, {inHighlightedBox});
    }

    getPaginationUrl(pageNumber) {
        const state = _.cloneDeep(_.invoke(this, 'searchView.getSearchOrExtendedSearchState') || {});
        _.set(state, 'search.page', pageNumber);
        return Urls.search.makeUrl(state, this.getDefaultOptionsForUrl(), this.getUrlPrefix());
    }

    _loadData(options, cb) {
        SearchDataLoader.loadSearchData(options, cb);
    }

    parseUrl(url) {
        const parsedUrl = Urls.search.parseUrl(url, this.configuration.urlPrefixPattern);
        const searchCriteria = _.omit(parsedUrl, [
            'listMode',
            'camera',
            'fullList',
            'openSaveSearch',
        ]);
        if (searchCriteria.filterType && searchCriteria.filterType.length == 0) {
            delete searchCriteria.filterType;
        }

        return {
            camera: parsedUrl.camera,
            limit: parsedUrl.limit,
            search: searchCriteria,
            listMode: parsedUrl.listMode,
            fullList: parsedUrl.fullList,
            openSaveSearch: parsedUrl.openSaveSearch,
        };
    }

    getUrl() {
        return Urls.search.makeUrl(this.getSearchState(), this.getDefaultOptionsForUrl(), this.getUrlPrefix());
    }

    _updateUrl(options) {
        options = _.defaults(options || {}, {
            pushToHistory: true,
        });
        const newUrl = this.getUrl();
        const state = this.getSearchState();
        if (options.pushToHistory) {
            History.pushState(state, this.getTitle(), newUrl);
        } else {
            History.replaceState(state, this.getTitle(), newUrl);
        }
    }

    getDefaultOptionsForUrl() {
        const {searchView, configuration: {searchDefaults, defaultListMode}} = this;
        return _.defaults({}, searchDefaults, {
            listMode: searchView.getApplicableListMode(defaultListMode),
            fullList: false,
        });
    }

    getSearchState() {
        return this.searchView && this.searchView.getSearchState() || {};
    }

    getUrlPrefix() {
        return this.configuration.urlPrefix;
    }

    getTitle() {
        return this.searchView && this.searchView.getPageTitle();
    }

    _updateTitle({title, headerTitle, metaDescription}) {
        Views.header.renderHeaderTitle({headerTitle});
        Title.setTitle(title);
        this.updateMetaTags({
            title,
            description: `${moment().format('ll')} - ✅ ${metaDescription}`,
            'og:description': metaDescription,
            'twitter:description': metaDescription,
        });
    }

    updateMetaTags(metaTags) {
        if (!metaTags && this.searchView) {
            this.searchView._updateSearchPageTitle();
        } else {
            super.updateMetaTags(metaTags);
        }
    }

    setDrawnZone(zone) {
        if (this.searchView) {
            this.searchView.setDrawnZone(zone);
        }
    }

    getJSONLD() {
        return this.searchView.getJSONLD();
    }

    getCanonicalUrl() {
        let url = this.getUrl();
        if (url === '/recherche/location/arcachon-33120') {
            url += '/appartement';
        }
        const postalCodes = _.get(this.searchView.searchCriteria, 'locations[0]._item.postalCodes');
        const sortedPostalCodes = _.sortBy(postalCodes, (code) => parseInt(code, 10)); // sort array to make sure we get same postalCode for all neighborhoods of a city
        const cityPostalCode = _.first(sortedPostalCodes);
        if (cityPostalCode) {
            const regex = /\/([a-z-]+)-(\d{5})(?:\/|$)/;
            const match = url.match(regex);
            if (match) {
                const cityName = match[1];
                url = url.replace(regex, `/${cityName}-${cityPostalCode}/`);
            }
        }
        return url;
    }

    getTitleOptions(titleKey) {
        return _.defaults({}, titlesOptions[titleKey], {
            pageTranslationContext: this.name, // do not use this.getName() to avoid circular dependency with this.getTitle()
        });
    }

    _getBackTitle() {
        const searchView = this.searchView;
        if (searchView) {
            return searchView.getHeaderTitle();
        }
    }
};

function displayVolatileFeedback({type, translationKey}) {
    if (type === 'error') {
        Views.volatileFeedback.showError(translationKey);
    } else {
        Views.volatileFeedback.showSuccess(translationKey);
    }
}

function _onResultsShown(data) {
    UserTracking.onAdListDisplayed(data);
}
