const DEVELOPER_ACCOUNT_TYPE = 'developer';
const AGENCY_ACCOUNT_TYPE = 'agency';
const NETWORK_ACCOUNT_TYPE = 'network';
const MANDATARY_ACCOUNT_TYPE = 'mandatary';
const BUILDER_ACCOUNT_TYPE = 'builder';
const BROKER_ACCOUNT_TYPE = 'broker';
const PARTICULAR_ACCOUNT_TYPE = 'particular';
const PRO_ACCOUNT_TYPE = 'pro';
const IMPORT_ACCOUNT_TYPE = 'import';
const ADVERTISER_ACCOUNT_TYPE = 'advertiser';
const CONTRACT_ACCOUNT_TYPE = 'contractType';

const PROMOTEUR_ACCOUNT_TYPES = [
    DEVELOPER_ACCOUNT_TYPE,
    BUILDER_ACCOUNT_TYPE,
];

const CLIENT_ACCOUNT_TYPES = [
    ...PROMOTEUR_ACCOUNT_TYPES,
    AGENCY_ACCOUNT_TYPE,
    NETWORK_ACCOUNT_TYPE,
    MANDATARY_ACCOUNT_TYPE,
    BROKER_ACCOUNT_TYPE,
];

const ALL_ACCOUNT_TYPES = [
    ...CLIENT_ACCOUNT_TYPES,
    PARTICULAR_ACCOUNT_TYPE,
    PRO_ACCOUNT_TYPE,
    IMPORT_ACCOUNT_TYPE,
    ADVERTISER_ACCOUNT_TYPE,
    CONTRACT_ACCOUNT_TYPE,
];

module.exports = {
    ALL_ACCOUNT_TYPES,
    CLIENT_ACCOUNT_TYPES,
    PROMOTEUR_ACCOUNT_TYPES,
    DEVELOPER_ACCOUNT_TYPE,
    AGENCY_ACCOUNT_TYPE,
    NETWORK_ACCOUNT_TYPE,
    MANDATARY_ACCOUNT_TYPE,
    BUILDER_ACCOUNT_TYPE,
    BROKER_ACCOUNT_TYPE,
    PARTICULAR_ACCOUNT_TYPE,
    PRO_ACCOUNT_TYPE,
    IMPORT_ACCOUNT_TYPE,
    ADVERTISER_ACCOUNT_TYPE,
    CONTRACT_ACCOUNT_TYPE,
};
