const {getTrackedOriginValues} = require('../app/TrackedOrigin');
const Account = require('../authentication/Account');
const RealtimeServer = require('../RealtimeServer');

module.exports = function (realEstateAdId, newState, cb) { // todo: move this function in './index.js' as soon as FavoritesManager.js is deleted
    Account.getAccountAndCreateGuestIfNeeded((err, account) => {
        if (err) {
            console.error('Could not get account info');
        } else {
            RealtimeServer.emit('ad:follower:toggle', {
                useRedisList: true,
                realEstateAdId,
                access_token: account.access_token,
                follow: newState,
                referrer: Account.getReferrer(),
                trackedOrigin: getTrackedOriginValues(),
            });
        }
        cb(err);
    });
};
