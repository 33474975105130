const CompositeVueView = require('../vue/CompositeVueView');
const WebsitesUrls = require('../vue/mixins/WebsitesUrls');

module.exports = class SimpleContentView extends CompositeVueView {
    show(options) {
        this.template = () => options.htmlContent;
        // @vue/component
        const vueOptions = {
            mixins: [
                WebsitesUrls,
            ],
        };
        super.show(options, vueOptions);
    }

    update(options) {
        this.hide();
        this.show(options);
    }
};
