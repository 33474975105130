const _ = require('lodash');
const {i18n: {translate: t}} = require('fack');
const template = require('./EdpField.jade');
const edpOptions = require('./EdpFieldOptions');
const {TOGGLE_GROUP_CHECKBOX_TYPE} = require('../../form/components/constants');

// @vue/component
module.exports = {
    props: {
        defaultSelected: {
            type: Array,
            default: () => [],
        },
        selectedPropertyType: {
            type: Array,
            default: () => [],
        },
        toggleGroupType: {
            type: String,
            default: TOGGLE_GROUP_CHECKBOX_TYPE,
        },
    },
    data() {
        return {
            selectedOptions: this.defaultSelected,
            attributes: {
                'data-field': 'energyClassification',
                'data-type': 'energyClassification',
            },
            options: edpOptions,
        };
    },
    computed: {
        title() {
            return t('EnergyDiagnostics.dpe');
        },
    },
    watch: {
        selectedOptions(newVal) {
            this.$emit('change', newVal);
        },
    },
    mounted() {
        this.options = _.map(this.options, (o) => {
            o.checked = _.includes(this.defaultSelected, o.value);
            return o;
        });
    },
    methods: {
        selectOption(options) {
            this.selectedOptions = _.map(_.filter(options, 'checked'), 'value');
        },
    },
    template: template(),
};
