// @vue/component
module.exports = {
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        contact: {
            type: Object,
            default: undefined,
        },
        enableCguLinkFullText: Boolean,
    },
};
