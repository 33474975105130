const {i18n: {translate}} = require('fack');

const template = require('./EndUserLicenseAgreement.jade');
const {generatePrimaryDomainCguUrl} = require('../../utils/PrimaryDomainUrl');
const savedOptions = require('../../Options').read();
const UserAgentHelper = require('../../../common/nativeApp/UserAgentHelper');
const ApplicationConfig = require('../../app/ApplicationConfig');

// @vue/component
module.exports = {
    props: {
        context: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            isFromPartnerHost: savedOptions.isInEmbeddedMode || ApplicationConfig.isOnPartnersDomain,
            cguPageUrl: generatePrimaryDomainCguUrl(),
            isFromNativeApp: UserAgentHelper.isFromNativeApp(),
        };
    },
    computed: {
        legalDescription() {
            return translate('endUserLicenseAgreement.text', {
                cguPageUrl: this.cguPageUrl || '/informations-legales',
                product: this.isFromNativeApp ? 'app' : this.isFromPartnerHost ? 'partner' : 'default',
                context: this.context,
            });
        },
    },
    template: template(),
};
