const _ = require('lodash');
const mercatorProjection = require('mercatorProjection');
const MapApi = require('../MapApi');

module.exports = {
    computeDefaultFacadesCameras,
};

const CAMERA_VERTICAL_ANGLE_IN_DEGREES = 70;
const FULL_ROTATION_ANGLE_IN_DEGREES = 360;
const CAMERA_HEIGHT_FACTOR = 1.5;

function computeDefaultFacadesCameras(map, boundingBox) {
    const {maxX, minX, maxY, minY, maxZ, minZ} = boundingBox;
    const positionProgramme = {
        x: _.mean([maxX, minX]),
        y: _.mean([maxY, minY]),
        z: _.mean([maxZ, minZ]),
    };
    const {x: centerX, y: centerY} = positionProgramme;

    const maxWidth = maxX - minX;
    const maxHeight = maxY - minY;
    const maxWidthHeight = Math.max(maxWidth, maxHeight);
    const options = {
        positionProgramme,
        cameraHeight: maxZ * CAMERA_HEIGHT_FACTOR,
        distanceFromProgramme: maxWidthHeight,
        angleTheta: CAMERA_VERTICAL_ANGLE_IN_DEGREES,
    };
    return _.map([
        { // south
            cameraLon: centerX,
            cameraLat: minY,
        },
        { // east
            cameraLon: maxX,
            cameraLat: centerY,
        },
        { // north
            cameraLon: centerX,
            cameraLat: maxY,
        },
        { // west
            cameraLon: minX,
            cameraLat: centerY,
        },
        { // top
            cameraLon: centerX,
            cameraLat: centerY,
            angleTheta: null, // overload 70, use default
        },
    ], params => computeFacadeCamera(map, {...options, ...params}));
}

function computeFacadeCamera(map, {
    cameraLon,
    cameraLat,
    cameraHeight,
    positionProgramme,
    distanceFromProgramme,
    angleTheta,
}) {
    const {Vector3} = MapApi.api.engine;
    const positionCamera = new Vector3(cameraLon, cameraLat, cameraHeight);
    const positionTarget = new Vector3(positionProgramme.x, positionProgramme.y, 0);
    const cameraConfig = convertTargetAndCameraPosToCameraConfig(
        map,
        positionCamera,
        positionTarget,
        distanceFromProgramme,
        angleTheta
    );
    return {cameraConfig};
}

function convertTargetAndCameraPosToCameraConfig(map, camPos, camTarget, distanceCamera, forcedTheta) {
    const {
        lat: camTargetY,
        lon: camTargetX,
        camera,
        zoom,
    } = MapApi.api.utils.CameraHelper.convertTargetAndCameraPosToCameraConfig(
        {
            camPos,
            camTarget,
        },
        map._renderer.conf,
        distanceCamera
    );

    return {
        lat: mercatorProjection.convertLat900913To4326(camTargetY),
        lon: mercatorProjection.convertLon900913To4326(camTargetX),
        theta: normalizeAngle(forcedTheta || camera.theta),
        phi: normalizeAngle(camera.phi),
        zoom,
    };
}

function normalizeAngle(angle) {
    angle = angle % FULL_ROTATION_ANGLE_IN_DEGREES;
    if (angle < 0) {
        angle += FULL_ROTATION_ANGLE_IN_DEGREES;
    }
    return angle;
}
