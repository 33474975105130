const _ = require('lodash');
const async = require('async');
const ResourceManager = require('./utils/ResourceManager');
const LocationItemHelper = require('./locationItems/LocationItemHelper');

module.exports = {
    addLocationNamesFromItemsInSearchCriteria,
};

function findPlace(text, callback) {
    let type = null;
    if (/-associee$/.test(text)) {
        type = 'delegated-city';
        text = text.replace(/-associee$/, '');
    }
    const params = {
        q: text,
    };
    if (type) {
        params.type = type;
    }
    ResourceManager.getJsonResource('place', params, callback);
}

function addLocationNamesFromItemsInSearchCriteria(searchCriteria, callback) {
    const locationNames = _.get(searchCriteria, 'locationNames'); // searchCriteria might be null
    if (locationNames) {
        getLocationFromNamesWithOptions(locationNames, {convertToLocationItem: true}, (err, locationSuggestions) => {
            if (err) {
                console.error('Could not fetch locations suggestions from names', err, locationNames);
            } else if (locationSuggestions) {
                searchCriteria.locations = _.compact(locationSuggestions);
            }
            callback(err);
        });
    } else {
        _.defer(callback);
    }
}

function getLocationFromNamesWithOptions(locationNames, options, callback) {
    if (!locationNames || locationNames.length == 0) {
        callback(null, []);
    } else {
        async.map(locationNames, function (locationName, cb) {
            findPlace(locationName, function (err, place) {
                if (err) {
                    cb(err);
                } else {
                    let result = place;
                    if (options.convertToLocationItem && place) {
                        result = LocationItemHelper.getInstance(place);
                    }
                    cb(null, result);
                }
            });
        }, callback);
    }
}
