module.exports = {
    create,
    destroy,
    openPopup,
    closePopup,
    setRelativePosition,
    movePopup,
    setMarker,

};

function create(map) {
    const apiExtra = require('f4-map-extra');
    return new apiExtra.PopupLayer({map: map});
}

function destroy(popupLayer) {
    popupLayer.setMap(null);
    delete popupLayer._map;
}

function setMarker(popupLayer, marker) {
    popupLayer.marker = marker;
    marker.popupLayer = popupLayer;
    if (marker.getVisible()) {
        movePopup(popupLayer);
    }
}

function openPopup(popupLayer, marker, $html) {
    popupLayer.$html = $html;
    popupLayer.showPopup(marker, $html);
    setRelativePosition(popupLayer, 10);
    setMarker(popupLayer, marker);
}

function setRelativePosition(popupLayer, boundToleranceInPx) {
    const map = popupLayer.getMap();
    if (map && map._renderer) {
        popupLayer.setRelativePosition(boundToleranceInPx);
    }
}

function movePopup(popupLayer) {
    popupLayer.popupPos = null;
    const map = popupLayer.getMap();
    if (map && map._renderer) {
        popupLayer.movePopup();
    }
}

function closePopup(popupLayer) {
    if (popupLayer.marker) {
        popupLayer.marker.popupLayer = null;
    }

    const map = popupLayer.getMap();
    if (map && map._renderer) {
        popupLayer.hidePopup();
    }
}
