const _ = require('lodash');
const template = require('./SetFeaturedBuyingForm.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const validationMixin = require('../../fields/vue/validationMixin');
const {
    getBuyableProducts,
    createPaymentIntent,
    updatePaymentIntent,
    validatePayment,
    sendBillingInformation,
} = require('..');
const {sendErrorToAnalytics} = require('../../utils/Errors');
const Views = require('../../views/Views');
const FAKE_PRODUCTS_NUMBER = 3;

const RealtimeServer = require('../../RealtimeServer');
const StripeCreditCard = require('./StripeCreditCard');
const BillingInformation = require('./BillingInformation');
const ErrorMessage = require('./ErrorMessage');
const SetFeaturedProduct = require('./SetFeaturedProduct');
const SetFeaturedProductPlaceholder = require('./SetFeaturedProductPlaceholder');
const ServerConfig = require('../../ServerConfig');

const STATE_REQUESTING_PRODUCTS = 'isRequestingProducts';
const STATE_NO_PRODUCT_AVAILABLE = 'noProductAvailable';
const STATE_PICK_A_PRODUCT = 'pickAProduct';
const STATE_PAYMENT_INTENT_UNDERWAY = 'paymentIntentUnderway';
const STATE_PAYMENT_INTENT_READY = 'paymentIntentReady';
const STATE_FATAL_PAYMENT_ERROR = 'fatalPaymentError';
const STATE_PAYMENT_UNDERWAY = 'paymentUnderway';
const STATE_PRODUCT_ACTIVATION_UNDERWAY = 'productActivationUnderway';
const STATE_PRODUCT_ACTIVATED = 'productActivated';
const STATE_PRODUCT_NOT_ACTIVATED = 'productNotActivated';

/**
 * @module
 * @mermaid
 *   stateDiagram-v2
 *     [*] --> productLoading
 *     isRequestingProducts --> noProductAvailable : no product avaible or error during retrival
 *     note right of isRequestingProducts: with loading placeholders
 *     noProductAvailable --> [*] : message displayed, user can only close modal using the closing cross
 *     isRequestingProducts --> pickAProduct : available products retrieved
 *     pickAProduct --> paymentIntentUnderway : user picks a product
 *     paymentIntentUnderway --> paymentIntentReady : payment intent created / updated
 *     paymentIntentUnderway --> pickAProduct : error during product selection, display message
 *     note right of paymentIntentUnderway: Button is replaced with a message + spinner
 *     paymentIntentReady --> paymentIntentUnderway : user picks another product
 *     paymentIntentReady --> paymentUnderway : user validates form (clicks on Pay button)
 *     paymentUnderway --> paymentIntentReady : error during payment, display message
 *     paymentUnderway --> fatalPaymentError : fatal error during payment, integration is broken
 *     fatalPaymentError --> [*] : integration is broken, user can only close form
 *     paymentUnderway --> productActivationUnderway : payment okay
 *     note right of paymentUnderway: Button is replaced with a message + spinner, products selection no longer possible
 *     productActivationUnderway --> productNotActivated : on error
 *     productActivationUnderway --> productActivated : on success
 *     note right of productActivationUnderway: Button is replaced with a message + spinner
 *     productActivated --> [*] : product activated, popup closes automatically, volatile popin shown
 *     productNotActivated --> [*] : product not activated, message displayed to user telling to contact support
 */
// @vue/component
module.exports = {
    components: {
        SetFeaturedProduct,
        SetFeaturedProductPlaceholder,
        StripeCreditCard,
        ErrorMessage,
        BillingInformation,
    },
    mixins: [
        i18nMixin({
            keys: [
                'submit',
                'noProduct',
                'errorSelectingProduct',
                'durationSelectorLabel',
                'productSelectionSectionTitle',
                'paymentSectionTitle',
            ],
        }),
        validationMixin(),
    ],
    constants: {
        LOADING_PRODUCTS: _.times(FAKE_PRODUCTS_NUMBER, count => {
            return {value: count};
        }),
    },
    props: {
        featureName: {
            type: String,
            required: true,
        },
        realEstateAdId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedProduct: null,
            paymentIntent: null,
            products: null,
            hadAnErrorSelectingAProduct: false,
            canSelectAProduct: true,
            state: STATE_REQUESTING_PRODUCTS,
            paymentValidationErrorMessage: null,
            card: null,
            billing: {},
        };
    },
    computed: {
        translationKeyPrefixes() {
            return _.map([this.featureName, 'default'], key => `setFeatured.${key}.buyForm.`);
        },
        durationSelectorClass() {
            return {'has-error': this.hadAnErrorSelectingAProduct};
        },
        isInWaitingMode() {
            return this.isInAnyState([
                STATE_PAYMENT_INTENT_UNDERWAY,
                STATE_PAYMENT_UNDERWAY,
                STATE_PRODUCT_ACTIVATION_UNDERWAY,
            ]);
        },
        waitingMessage() {
            return this.t(this.state);
        },
        legalMentions() {
            return this.t('legalMentions', {
                href: ServerConfig.config.proUrl + '/conditions-generales-de-vente/#options-payantes',
            });
        },
        canSubmit() {
            return this.state === STATE_PAYMENT_INTENT_READY;
        },
        productSelectionVisible() {
            return this.selectedProductInPreviewMode || this.isInAnyState([
                STATE_PICK_A_PRODUCT,
                STATE_PAYMENT_INTENT_READY,
            ]);
        },
        paymentSectionDisplayed() {
            return this.creditCardDisplayed || this.paymentValidationErrorMessageDisplayed;
        },
        creditCardDisplayed() {
            return this.isInAnyState([
                STATE_PAYMENT_INTENT_READY,
                STATE_PAYMENT_UNDERWAY, // stripe requires element to still be mounted to work
            ]);
        },
        creditCardDisabled() {
            return this.isInAnyState([
                STATE_PAYMENT_UNDERWAY,
            ]);
        },
        facturationFieldsDisplayed() {
            return this.creditCardDisplayed;
        },
        facturationFieldsDisabled() {
            return this.isInAnyState([
                STATE_PAYMENT_UNDERWAY,
            ]);
        },
        paymentValidationErrorMessageDisplayed() {
            return this.paymentValidationErrorMessage && this.isInAnyState([
                STATE_PAYMENT_INTENT_READY,
                STATE_FATAL_PAYMENT_ERROR,
            ]);
        },
        selectedProductInPreviewMode() {
            return this.isInAnyState([
                STATE_PAYMENT_INTENT_UNDERWAY,
                STATE_PAYMENT_UNDERWAY,
                STATE_PRODUCT_ACTIVATION_UNDERWAY,
                STATE_PRODUCT_ACTIVATED,
                STATE_PRODUCT_NOT_ACTIVATED,
            ]);
        },
        ..._([
            STATE_REQUESTING_PRODUCTS,
            STATE_NO_PRODUCT_AVAILABLE,
            STATE_PAYMENT_INTENT_READY,
            STATE_FATAL_PAYMENT_ERROR,
            STATE_PRODUCT_ACTIVATION_UNDERWAY,
            STATE_PRODUCT_ACTIVATED,
            STATE_PRODUCT_NOT_ACTIVATED,
        ])
            .mapKeys()
            .mapValues(state => {
                return function () {
                    return this.state === state;
                };
            })
            .value(),
    },
    watch: {
        selectedProduct(selectedProduct) {
            if (selectedProduct) {
                this.updatePaymentIntentForSelectedProduct();
            } else {
                this.paymentIntent = null;
            }
        },
        creditCardDisabled(disabled) {
            const {card} = this;
            if (card) {
                card.update({disabled});
            }
        },
    },
    mounted() {
        getBuyableProducts(this.featureName, this.realEstateAdId, (err, results) => {
            if (err) {
                // Same behaviour and message for retrieval error and empty product list
                this.products = [];
                this.state = STATE_NO_PRODUCT_AVAILABLE;
            } else {
                this.products = _.map(results.products, product => {
                    return {
                        value: product,
                    };
                });
                this.state = STATE_PICK_A_PRODUCT;
            }
        });
    },
    methods: {
        isInAnyState(states) {
            return _.includes(states, this.state);
        },
        updatePaymentIntentForSelectedProduct() {
            this.hadAnErrorSelectingAProduct = false;
            const selectedProductToken = this.selectedProduct.token;
            const callback = (err, res) => {
                if (err) {
                    this.selectedProduct = null;
                    this.state = STATE_PICK_A_PRODUCT;
                    sendErrorToAnalytics(err);
                    this.hadAnErrorSelectingAProduct = true;
                } else {
                    this.paymentIntent = res.paymentIntent;
                    this.state = STATE_PAYMENT_INTENT_READY;
                }
            };
            if (!this.paymentIntent) {
                createPaymentIntent(selectedProductToken, callback);
            } else {
                updatePaymentIntent(this.paymentIntent.id, selectedProductToken, callback);
            }
            this.state = STATE_PAYMENT_INTENT_UNDERWAY;
        },
        submitForm() {
            if (!this.canSubmit || !this.validateForm()) {
                return;
            }
            this.paymentValidationErrorMessage = null;
            this.state = STATE_PAYMENT_UNDERWAY;
            sendBillingInformation(this.paymentIntent.id, this.billing, err => {
                if (err) {
                    this.paymentValidationErrorMessage = this.t('billingNotSaved');
                    this.state = STATE_PAYMENT_INTENT_READY;
                } else {
                    this.validatePayment();
                }
            });
        },
        validatePayment() {
            const realtimeContext = this.realtimeContext = RealtimeServer.openContext();
            realtimeContext.on(`transaction:${this.paymentIntent.clientSecret}`, message => {
                if (message === 'transaction:complete') {
                    this.activationComplete();
                } else if (message === 'transaction:error') {
                    this.state = STATE_PRODUCT_NOT_ACTIVATED;
                }
                realtimeContext.close();
            });
            realtimeContext.join('transaction#' + this.paymentIntent.clientSecret);
            validatePayment(this.paymentIntent, {
                paymentMethod: {card: this.card},
                email: this.billing.email,
            }, (err) => {
                if (err) {
                    if (err.type === 'integration') {
                        this.state = STATE_FATAL_PAYMENT_ERROR;
                    } else {
                        this.state = STATE_PAYMENT_INTENT_READY;
                    }
                    if (err.type !== 'validation_error') {
                        // avoids displaying message twice: here and in credit card error feedback
                        this.paymentValidationErrorMessage = err.message;
                    }
                    realtimeContext.close();
                } else {
                    this.state = STATE_PRODUCT_ACTIVATION_UNDERWAY;
                }
            });
        },
        activationComplete() {
            this.state = STATE_PRODUCT_ACTIVATED;
            Views.volatileFeedback.showSuccess(this.t('productActivated'));
            this.$emit('complete');
        },
        t(key, options) { // used by i18n mixin
            return this.translate(_.map(this.translationKeyPrefixes, prefix => prefix + key), options);
        },
        isSelected(product) {
            return product == this.selectedProduct;
        },
    },
    template: template(),
};
