const BLUE = '#009db0';

module.exports = {
    yellow: '#fbba20',
    blue: BLUE,
    'blue-grey': '#495960',
    red: '#ea516c',
    green: '#2DA764',
    grey: '#999999',
    backgroundLightColor: '#f1f3f4',

    // Functional colors
    CONTRACT_DEFAULT_COLOR: BLUE,
};
