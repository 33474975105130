const i18nMixin = require('../../vue/components/mixins/i18n');

const TRANSLATION_PREFIX = 'UrlField.';

// @vue/component
module.exports = {
    mixins: [
        require('./mixins/baseInputMixin'),
        i18nMixin({
            prefix: TRANSLATION_PREFIX,
            keys: [
                'errorMessage',
                'defaultPlaceholder',
            ],
        }),
    ],
    props: {
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        extraAttributes() {
            return {
                'data-bv-uri-message': this.errorMessage,
            };
        },
        finalPlaceholder() {
            return this.computePlaceholder(this.defaultPlaceholder);
        },
    },
    beforeCreate() {
        this.type = 'url';
    },
};
