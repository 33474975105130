const $ = require('jquery');
const DropdownToggle = require('./DropdownToggle');
const template = require('./Dropdown.jade');

// @vue/component
module.exports = {
    components: {
        DropdownToggle,
    },
    provide() {
        return {
            dropdownState: this.state,
        };
    },
    data() {
        return {
            state: {
                open: false,
            },
        };
    },
    mounted() {
        this.setupDropdownListeners('on');
    },
    beforeDestroy() {
        this.setupDropdownListeners('off');
    },
    methods: {
        setupDropdownListeners(action) {
            const dropdown = $(this.$el);
            dropdown[action]('hidden.bs.dropdown', this.close);
            dropdown[action]('shown.bs.dropdown', this.open);
        },
        setOpen(open) {
            this.state.open = open;
        },
        close() {
            this.$emit('close');
            this.setOpen(false);
        },
        open() {
            this.$emit('open');
            this.setOpen(true);
        },
        forceClose() {
            $(this.$el).removeClass('open');
        },
    },
    template: template(),
};
