const _ = require('lodash');

const template = require('./LocalBannersManagement.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const LocalBannersHelper = require('../../../agency/LocalBannersHelper');
const ContainerQueries = require('../../../vue/mixins/ContainerQueries');
const {BREAKPOINTS_LIMITS_XS} = require('../../../vue/utils/Breakpoints');
const LocalBannerSettings = require('./LocalBannerSettings');
const LocalBanner = require('../../../agency/components/LocalBanner');

// @vue/component
module.exports = {
    components: {
        LocalBannerSettings,
        LocalBanner,
    },
    mixins: [
        ContainerQueries({xsmall: {maxWidth: BREAKPOINTS_LIMITS_XS}}),
        i18nMixin({
            prefix: 'LocalBannersManagement.',
            keys: [
                'enableText',
                'addBannerText',
                'removeBannerText',
                'missingCompanyDetailsText',
            ],
        }),
    ],
    props: {
        value: {
            type: Object,
            default: () => ({enabled: false, banners: []}),
        },
        agency: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localBannersConfiguration: _.cloneDeep(this.value),
            templatesCounts: {},
            localBannerKeys: [],
        };
    },
    computed: {
        isDisabled() {
            const {agency} = this;
            return !_.get(agency, 'company.address.city') || !_.get(agency, 'company.address.postalCode');
        },
        modifier() {
            return _.pick(this.breakpoints, 'xsmall');
        },
        usedThemes() {
            return _.map(this.localBannersConfiguration.banners, banner => banner.theme);
        },
        availableThemes() {
            const {templatesCounts, usedThemes} = this;
            return _.reject(
                _.keys(templatesCounts),
                theme => _.includes(usedThemes, theme)
            );
        },
    },
    watch: {
        localBannersConfiguration: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },
    },
    mounted() {
        LocalBannersHelper.fetchBannersTemplatesCount((error, templatesCounts) => {
            if (error) {
                console.error(error);
            } else {
                this.templatesCounts = templatesCounts;
            }
        });
    },
    methods: {
        addBanner() {
            this.localBannersConfiguration.banners.push({
                theme: this.availableThemes[0],
                template: {
                    index: 0,
                },
                postalCodes: [],
            });
        },
        removeBanner(bannerConfig) {
            const index = this.localBannersConfiguration.banners.indexOf(bannerConfig);
            this.localBannersConfiguration.banners.splice(index, 1);
        },
        getBannerKey(banner) {
            return banner.theme + '-' + banner.template.index;
        },
        onSettingsChange(bannerConfig, index) {
            this.localBannersConfiguration = {
                ...this.localBannersConfiguration,
            };
            this.localBannersConfiguration.banners[index] = bannerConfig;
        },
        getLocalBannerKey(index) {
            if (!this.localBannerKeys[index]) {
                this.localBannerKeys[index] = _.uniqueId('localBanner');
            }
            return this.localBannerKeys[index];
        },
    },
    template: template(),
};
