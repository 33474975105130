const {i18n: {translate: t}} = require('fack');
const AddressSuggestionHelper = require('../../utils/AddressSuggestionHelper');
const template = require('../../templates/poiAndAddressSuggestionItem.jade');

// @vue/component
module.exports = {
    extends: require('../../form/components/TextField'),
    mixins: [
        require('./mixins/withTranslations'),
        require('../../form/components/mixins/suggestionsMixin'),
    ],
    props: {
        tags: {
            type: Array,
            default() {
                return ['street', 'housenumber'];
            },
        },
        showTags: { // if true, display the tags of each suggestion
            type: Boolean,
            default: false,
        },
        noSuggestionsMessage: {
            type: String,
            default() {
                return t('noAddressFound');
            },
        },
        allowAddressInMetropolitanAndOverseas: Boolean,
    },
    computed: {
        extraAttributes() {
            let regexpOptions = {
                message: t('addressNotInMetropolitanFrance'),
                pattern: '.*\\b(0[1-9]|[1-8]\\d|9[0-5])\\d{3}\\b.*',
            };
            if (this.allowAddressInMetropolitanAndOverseas) {
                regexpOptions = {
                    message: t('noAddressFound'),
                    pattern: '.*\\b(0[1-9]|[1-8]\\d|9[0-7])\\d{3}\\b.*',
                };
            }
            return {
                autocomplete: 'off', // browser autocomplete does not work well with typeahead (ignored by many browsers)
                autocorrect: 'off', // automatic correction is more annoying than useful on addresses
                spellcheck: 'false', // spellcheck is useless on addresses
                'data-bv-regexp-message': regexpOptions.message,
                pattern: regexpOptions.pattern,
            };
        },
    },
    methods: {
        querySuggestions(text, callback) {
            AddressSuggestionHelper.getAddressSuggestions({
                q: text,
                tags: this.tags.join(','),
            }, callback);
        },
        getSuggestionValue({displayName}) {
            return displayName;
        },
        formatSuggestion({displayName, tagsToDisplay}) {
            return template({
                displayName,
                tagsToDisplay: this.showTags ? tagsToDisplay : [],
            });
        },
        getSuggestionDisplayName(suggestion) {
            return AddressSuggestionHelper.getDisplayNameWithHashTags(suggestion);
        },
    },
};
