const _ = require('lodash');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const adsManager = require('./adsManager');
const async = require('async');
const Account = require('../authentication/Account');

module.exports = {
    loadUserAds,
    getLinkToAds,
    loadUserAdsCount,
};

function getLinkToAds(authorId) {
    const isAuthor = (authorId != null && authorId === Account.getAuthenticatedAccountId());
    return isAuthor ? '/mes-annonces' : '/annonces-' + authorId;
}

function loadUserAds(options, callback) {
    return loadUserAdsCount(options, (err, results) => {
        if (err) {
            console.error(err);
        } else {
            callback(results);
        }
    });
}

function loadUserAdsCount(options, callback) {
    const countsHandler = {};
    async.parallel({
        rent: _.partial(countRequest, 'rent'),
        buy: _.partial(countRequest, 'buy'),
    }, function (err, results) {
        if (err) {
            callback(err);
        } else {
            options.userAdsResume = {
                rent: results.rent,
                buy: results.buy,
            };
            callback(null, options);
        }
    });

    function countRequest(type, cb) {
        countsHandler[type] = countAds(options, type, cb);
    }

    return {
        abort: () => {
            _.each(countsHandler, (handler) => handler.abort());
        },
    };
}

function countAds(options, type, cb) {
    const searchDefaults = {
        propertyType: DefaultConfiguration.allPropertyTypes,
        onTheMarket: [true],
        author: options.author.id,
        onlyOwnData: options.onlyOwnData,
    };
    return adsManager.countAds({
        filters: _.extend({}, searchDefaults, {filterType: [type]}),
        name: options.origin,
    }, cb);
}
