const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const STATS_COLORS_PALETTE = {
    blue: '#0dabd0',
    red: '#fa6b67',
    darkRed: '#A94442',
    yellow: '#ffb82f',
    gray: '#B2BECC',
    green: 'green',
    pink: 'pink',
};

const SHARED_STATS_COLOR = {
    'views.total': STATS_COLORS_PALETTE.blue,
    'contactRequests.total': STATS_COLORS_PALETTE.red,
    'phoneDisplays.total': STATS_COLORS_PALETTE.darkRed,
    'followers.total': STATS_COLORS_PALETTE.yellow,
    'calls.total': STATS_COLORS_PALETTE.pink,
    'adSubmissions.total': STATS_COLORS_PALETTE.gray,
    'estimationRequests.total': STATS_COLORS_PALETTE.green,
    price: STATS_COLORS_PALETTE.green,
};

module.exports = {
    STATS_COLORS_PALETTE,
    SHARED_STATS_COLOR,
    getAvailableStats,
    getColor,
    getLabel,
};

function getAvailableStats() {
    return _.compact([
        'views.total',
        'calls.total',
        'phoneDisplays.total',
        'contactRequests.total',
        'followers.total',
        'adSubmissions.total',
        'estimationRequests.total',
        'price',
    ]);
}

function getColor(statName) {
    return SHARED_STATS_COLOR[statName];
}

function getLabel(key) {
    return translate(`BaseStats.${key}.label`, {
        context: 'plural',
    });
}
