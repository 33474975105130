const legalMentionsTemplate = require('./LegalMentions.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {
    generatePrimaryDomainCguUrl,
    generatePrimaryDomainPrivacyPolicyUrl,
} = require('../../utils/PrimaryDomainUrl');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'LegalMentions.',
        }),
    ],
    props: {
        context: {
            type: String,
            default: null,
        },
        showToggleButton: {
            type: Boolean,
            default: true,
        },
        shortMention: {
            type: String,
            default: null,
        },
        longMention: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            legalMentionsDisplayed: !this.showToggleButton,
        };
    },
    computed: {
        internalLongMention() {
            const defaultTranslationContext = {
                cguPageUrl: generatePrimaryDomainCguUrl(),
                context: this.context,
            };
            return this.longMention || this.t('longMention', defaultTranslationContext);
        },
        internalShortMention() {
            return this.shortMention || this.t('shortMention');
        },
        privacyPolicyMention() {
            return this.t('privacyPolicyMention', {
                privacyPolicyPageUrl: generatePrimaryDomainPrivacyPolicyUrl(),
            });
        },
    },
    methods: {
        displayLegalMentions() {
            this.legalMentionsDisplayed = true;
        },
    },
    template: legalMentionsTemplate(),
};
