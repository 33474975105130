const _ = require('lodash');

const RowInformation = require('./dataTable/RowInformation');
const DataTableHeader = require('./dataTable/DataTableHeader');
const template = require('./DataTable.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ScrollHelper = require('../../utils/ScrollHelper');

const REQUIRED_TRANSLATION_KEYS = ['noResultsMessage', 'titleSuffix', 'titleSuffixPlural', 'loadingMessage'];
const DEFAULT_RESULTS_PER_PAGE = 20;

// @vue/component
module.exports = {
    components: {
        DataTableHeader,
        RowInformation,
    },
    mixins: [
        i18nMixin({
            prefix: 'DataTable.',
            keys: ['errorMessage'],
        }),
    ],
    provide() {
        const {buildCellClass} = this;
        return {
            getAdditionalCellClass(column) {
                return buildCellClass(_.kebabCase(column.label));
            },
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        defaultSortKey: {
            type: String,
            required: true,
        },
        translationTexts: {
            type: Object,
            required: true,
            validator: (translationTexts) => _.isEqual(REQUIRED_TRANSLATION_KEYS.sort(), _.keys(translationTexts).sort()),
        },
        dataPerPage: {
            type: Number,
            default: DEFAULT_RESULTS_PER_PAGE,
        },
        isLoading: Boolean,
        hasError: Boolean,
        buildCellClass: {
            type: Function,
            default: () => null,
        },
        hideTitle: Boolean,
        maxVisibleElements: {
            type: Number,
            default: undefined,
        },
        scrollIntoViewOnPageChange: Boolean,
    },
    data() {
        return {
            internalData: this.data,
            pageIndex: 0,
        };
    },
    computed: {
        title() {
            const {translationTexts: {titleSuffix, titleSuffixPlural}, internalData} = this;
            const count = _.size(internalData);
            const context = {
                count,
                suffix: count > 1 ? titleSuffixPlural : titleSuffix,
            };
            return this.t('title', context);
        },
        showNoResultsMessage() {
            return _.size(this.internalData) === 0;
        },
        pageData() {
            const {internalData, dataPerPage, pageIndex} = this;
            return _.slice(internalData, dataPerPage * pageIndex, dataPerPage * (pageIndex + 1));
        },
        paginationData() {
            const {internalData, dataPerPage: resultsPerPage} = this;
            return {
                totalResults: internalData.length,
                resultsPerPage,
            };
        },
    },
    watch: {
        data() {
            this.pageIndex = 0;
            this.sortDataByDefaultValue();
        },
    },
    mounted() {
        this.sortDataByDefaultValue();
    },
    methods: {
        sortData(sortKey, isReverseSorting) {
            const {data, maxVisibleElements} = this;
            let sortedData = _.sortBy(data, 'date');
            let mustReverseSort = false;
            if (sortKey === 'date') {
                mustReverseSort = !isReverseSorting;
            } else {
                // ensure secondary sort will be by descending date
                if (isReverseSorting) {
                    mustReverseSort = true;
                } else {
                    sortedData = _.reverse(sortedData);
                }
                const {sortBy} = _.find(this.columns, {label: sortKey});
                sortedData = _.sortBy(sortedData, row => {
                    const value = sortBy ? sortBy(row) : _.get(row, sortKey);
                    if (_.isString(value)) {
                        return _.deburr(value).toLowerCase();
                    } else {
                        return value;
                    }
                });
            }
            this.internalData = mustReverseSort ? sortedData.reverse() : sortedData;
            if (maxVisibleElements) {
                this.internalData = _.slice(this.internalData, 0, maxVisibleElements);
            }
        },
        sortDataByDefaultValue() {
            this.sortData(this.defaultSortKey, false);
        },
        changePage(pageIndex) {
            this.pageIndex = pageIndex;
            this.$nextTick(() => {
                const options = this.scrollIntoViewOnPageChange ? {intoView: true, instant: true} : {animated: true};
                ScrollHelper.scrollToElement(this.$el, options);
            });
        },
        getCellSlotName(column) {
            return 'cell-' + _.kebabCase(column.label);
        },
        isCellSlotUsed(column) {
            const {$scopedSlots, getCellSlotName} = this;
            return Boolean($scopedSlots[getCellSlotName(column)]);
        },
    },
    template: template(),
};
