const $ = require('jquery');
const _ = require('lodash');
const template = require('../templates/slideshowFullscreen.jade');
const SlideshowBaseView = require('./SlideshowBaseView');
const EventPack = require('../utils/EventPack');

const ANIMATION_END_EVENTS = 'webkitAnimationEnd oanimationend msAnimationEnd animationend';

module.exports = class SlideshowFullscreenView extends SlideshowBaseView {
    constructor(options) {
        options = _.extend({}, options, {
            template,
            $container: $('body'),
            noPhotoUrl: 'images/no_photo_inverse.png',
            stretchSmallImages: false,
            fullscreenAllowed: false,
            imageResConfigName: 'slideshowFullscreen',
        });
        super(options);
        this._eventPackRemoveFullElements = new EventPack();
    }

    _bindEvents() {
        super._bindEvents();
        this._eventPack.on(this.$container, {
            click: {
                '.contactFullscreenSlideshow': () => {
                    this.emit('openContactSection', 'slideShow');
                },
                '.exitFullscreen': _.bind(this._exitFullscreen, this),
                '.openLiveRoom': () => {
                    this.emit('openLiveRoom');
                },
            },
        });
    }

    show(options) {
        super.show(options);
        if (!options.skipEnterExitAnimation) {
            this.$element.addClass('animShow');
        }
    }

    hide(options) {
        if (this.isShown()) {
            options = options || {};
            if (!options.skipEnterExitAnimation) {
                this._hideWithAnimation();
            } else {
                super.hide();
            }
        }
    }

    _getContainerSize() {
        return this._getDefaultContainerSize();
    }

    _hideWithAnimation() {
        this._eventPack.removeAllListeners();
        const {$element} = this;
        this._eventPackRemoveFullElements.on($element, ANIMATION_END_EVENTS, () => {
            this._eventPackRemoveFullElements.removeAllListeners();
            super.hide();
        });
        $element.addClass('animHide');
    }

    _resize() {
        super._resize({skipEnterExitAnimation: true});
    }

    _exitFullscreen(event) {
        this._stopEvent(event);
        this.emit('exitFullscreen');
    }
};
