const _ = require('lodash');
const {loadStripe} = require('../../utils/stripeLoader');
const {sendErrorToAnalytics} = require('../../utils/Errors');
const template = require('./StripeCreditCard.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ErrorMessage = require('./ErrorMessage');
const baseFieldMixin = require('../../form/components/mixins/baseFieldMixin');
const {
    i18n: {translate},
    cssUrl,
} = require('fack');
const ServerConfig = require('../../ServerConfig');

// @vue/component
module.exports = {
    components: {
        ErrorMessage,
    },
    mixins: [
        i18nMixin({
            keys: [
                'errorLoadingStripe',
            ],
        }),
        baseFieldMixin,
    ],
    props: {
        name: { // required by base field mixin
            type: String,
            default: () => _.uniqueId('stripe-credit-card-'),
        },
        required: { // required by base field mixin
            type: Boolean,
            default: true,
        },
        label: { // required by base field mixin
            type: String,
            default: function () {
                return t('label');
            },
        },
    },
    data() {
        return {
            stripeCouldNotLoad: false,
            errorMessage: null,
        };
    },
    mounted() {
        loadStripe().then(stripe => {
            const elements = stripe.elements({
                locale: 'fr',
                fonts: [
                    {
                        cssSrc: ServerConfig.config.resUrl + cssUrl('font'),
                    },
                ],
            });
            const baseColor = '#495960'; // from input in inputDefault.styl
            const errorColor = '#ea516c'; // from .has-error in form.styl
            const card = this.card = elements.create('card', {
                style: {
                    base: {
                        fontSize: '15px', // from .form-control in form.styl
                        fontFamily: "'Roboto-Condensed', arial", // from font-family.styl
                        iconColor: baseColor,
                        color: baseColor,
                        '::placeholder': {
                            // stripe does not accept opacity here
                            color: '#808b90', // from input in inputDefault.styl, applied opacity to color assuming a white background
                        },
                    },
                    invalid: {
                        iconColor: errorColor,
                        color: errorColor,
                    },
                },
            });
            card.on('change', ({error}) => {
                // error is an object with code, type and message
                this.errorMessage = error && error.message;
                this.revalidate();
            });
            card.mount(this.$refs.card);
            this.$emit('card', card);
        }, err => {
            console.error(err);
            sendErrorToAnalytics(err);
            this.stripeCouldNotLoad = true;
        });
    },
    beforeUnmount() {
        this.card.destroy();
    },
    methods: {
        t, // used by i18n mixin
    },
    template: template(),
};

function t(key) {
    return translate('setFeatured.stripe.' + key);
}
