const $ = require('jquery');
const _ = require('lodash');

const CheckboxesField = require('./CheckboxesField');

module.exports = class NewPropertyField extends CheckboxesField {
    convertToSearch(filters) {
        const newProperty = _.get(filters, 'newProperty') || [];
        this.forceFieldsValuesUpdate(filters);
        if (newProperty.length == 2 || newProperty.length == 0) {
            filters.newProperty = null;
        } else if (newProperty.length == 1) {
            filters.newProperty = filters.newProperty[0] == 'new';
        }
    }

    convertFromSearch(search) {
        if (!_.isBoolean(search.newProperty) || this.shouldDisableFilter(search)) {
            search.newProperty = ['new', 'old'];
        } else {
            search.newProperty = [search.newProperty ? 'new' : 'old'];
        }
    }

    updateVisibility(filters) {
        this.$element.toggle(!filters.buyNewPropertySetInFilterType && !this.shouldDisableFilter(filters));
    }

    shouldDisableFilter({filterType}) {
        return (filterType && filterType.length === 1 && filterType[0] === 'rent') || filterType === 'rent';
    }

    buyNewPropertyFlagChanged(filters) {
        return !filters.buyNewPropertySetInFilterType && this.previousBuyNewPropertySetInFilterType;
    }

    forceFieldsValuesUpdate(filters) {
        if (this.shouldDisableFilter(filters) || this.buyNewPropertyFlagChanged(filters)) {
            _.forEach(this.$element.find('input'), input => {
                $(input).prop('checked', true);
            });
        }
        this.previousBuyNewPropertySetInFilterType = filters.buyNewPropertySetInFilterType;
    }
};
