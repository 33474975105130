const {i18n: {translate}} = require('fack');
const moment = require('../momentFr');

module.exports = {
    humanizeDay,
    humanizeTime,
    humanizeDuration,
    humanizeEndDate,
    humanizeEndDateFromDuration,
    humanizeDayLocale,
};

function humanizeDay(date) {
    return moment(date).format('DD/MM/YYYY');
}

function humanizeTime(date) {
    return moment(date).format('LT');
}

function humanizeDuration(duration) {
    return moment.duration(duration).humanize();
}

function humanizeEndDate(endDate) {
    return translate('endDateFromDuration', {date: humanizeDay(endDate)});
}

function humanizeEndDateFromDuration(duration) {
    return humanizeEndDate(moment().add(duration));
}
function humanizeDayLocale(date) {
    return moment(date).format('LL');
}
