const geocodingStatsTemplate = require('../templates/geocodingStatsTemplate.jade');
const geocodingStatsDataTemplate = require('../templates/geocodingStatsData.jade');
const View = require('./View');
const $ = require('jquery');
const _ = require('lodash');
const UrlHelper = require('../../common/UrlHelper');
const geocodingFilters = require('../geocodingFilters');
const Highcharts = require('../utils/Highcharts');

const filters = [
    {
        label: 'Toutes les annonces',
        details: '',
        value: {},
    },
].concat(geocodingFilters);

module.exports = class GeocodingStatsView extends View {
    constructor() {
        super();
        this.template = geocodingStatsTemplate;
    }

    getFilters() {
        return filters;
    }

    show(options) {
        const that = this;
        const totalAds = options.geocodingStats && options.geocodingStats[0];
        const $element = this.$element = this.createElement(_.extend({
            totalAds,
        }, options));
        const stats = [];
        const rows = [];

        _.each(filters, function (filter, index) {
            const value = options.geocodingStats && options.geocodingStats[index];
            const total = options.geocodingStats && options.geocodingStats[0];

            const percent = value / total * 100;
            if (index > 0) {
                stats.push({name: filter.label, y: value, color: filter.color});

                const geocodingRef = UrlHelper.slugify(filter.label);
                rows.push({
                    color: filter.color,
                    label: filter.label,
                    value,
                    percent,
                    details: filter.details,
                    link: '/recherche/achat,location/france/maisonvilla,appartement,parkingbox,autres?'
                    + 'onTheMarket=onMarket,offMarket&'
                    + 'geocoding=' + geocodingRef,
                    geocodingRef,
                });
            }
        });
        const $table = $element.find('.geocodingStats');
        $table.html(this.renderTemplate(geocodingStatsDataTemplate, {
            rows,
            totalAds,
        }));

        this.$element.on('click', 'a', function (event) {
            const geocodingRef = $(event.currentTarget).attr('data-geocoding');
            that.emit('openGeocodingSearch', {geocoding: geocodingRef});
            event.stopPropagation();
            event.preventDefault(); //avoid basic a handling
        });
        this.$container.append($element);
        Highcharts.onReady(function () {
            if (this.$element) {
                const chartContainer = this.$element.find('#geocodingStatsChart');
                chartContainer.highcharts({
                    credits: {
                        enabled: false,
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                    },
                    title: {
                        text: '',
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    },
                    plotOptions: {
                        pie: {
                            size: 500,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    color: '#5d728b',
                                },
                            },
                            startAngle: -90,
                            endAngle: 90,
                            center: ['50%', '90%'],
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            innerSize: '70%',
                            name: 'Part des annonces',
                            data: stats,
                        },
                    ],
                });
            }
        }, this);
    }
};
