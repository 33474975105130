const Account = require('../authentication/Account');
const NativeInterfaceProxy = require('../utils/NativeInterfaceProxy');
const Views = require('../views/Views');

module.exports = {init};

function init() {
    window.NativeGate = {
        authenticateWithAccessToken,
        closeSideMenu,
    };
}

function authenticateWithAccessToken(accessToken) {
    Account.authenticateWithAccessToken(accessToken, (err = null) => {
        NativeInterfaceProxy.onAuthenticateWithAccessToken(err);
    });
}

function closeSideMenu() {
    Views.header.closeSideMenu();
}
