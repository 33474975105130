const $ = require('jquery');
const View = require('./View');
const _ = require('lodash');

module.exports = class ImgAnimationView extends View {
    constructor(options) {
        super();
        this.$img = options.$img;
    }

    show() {
        this.updateOriginalPosition();
        this.$imgMoving = this.$img.clone();
        this.$imgMoving.css(this.$img.options);
        this.$imgMoving.appendTo($('body'));
        this.$imgMoving.hide();
    }

    showImg() {
        if (this.$imgMoving) {
            this.$imgMoving.show();
        }
    }

    updateOriginalPosition() {
        const top = this.$img.offset().top - $(window).scrollTop();
        const left = this.$img.offset().left;
        const transform1 = 'translate3D(' + left + 'px,' + top + 'px,0)';
        const transform2 = 'scale(1,1)';
        this.$img.options = {
            position: 'fixed',
            width: this.$img.innerWidth(),
            height: this.$img.innerHeight(),
            zIndex: '1',
            top: 0,
            left: 0,
            transform: transform1 + ' ' + transform2,
            transition: 'all ease 0.5s',
            transformOrigin: '0 0',
        };
    }

    hide(options, cb = _.noop) {
        if (this.$imgMoving) {
            this.$imgMoving.remove();
            this.$imgMoving = null;
        }
        cb();
    }

    isTargetPositionInScreen($target) {
        return ($target.offset().top >= (0 - $target.innerHeight()));
    }

    getTargetOptions(target) {
        const top = target.$target.offset().top - $(window).scrollTop();
        const left = target.$target.offset().left;
        const scale = target.$target.innerWidth() / this.$img.options.width;
        const transform1 = 'translate3D(' + left + 'px,' + top + 'px,0)';
        const transform2 = 'scale(' + scale + ',' + scale + ')';
        target.$target.options = {};
        target.$target.options.transform = transform1 + ' ' + transform2;
        return target;
    }

    animToTarget(target, hasToBeClose) {
        const hideAnimation = _.bind(function () {
            $(window).off('scroll');
            target.$target.css('opacity', 1);
            if (this.$imgMoving) {
                this.$imgMoving.hide();
            }
            target.$container.removeClass('animationIsPlaying');
            if (hasToBeClose) {
                this.hide();
            }
            this.emit('endAnimation');
        }, this);
        if (this.$imgMoving) {
            this.$imgMoving.show();
        }
        target.$target.css('opacity', 0);
        if (this.$imgMoving && this.isTargetPositionInScreen(this.$imgMoving)) {
            _.delay(_.bind(function () {
                target = this.getTargetOptions(target);
                $(window).on('scroll', hideAnimation);
                target.$container.addClass('animationIsPlaying');
                if (this.$imgMoving && this.isTargetPositionInScreen(target.$target)) {
                    this.$imgMoving.css(target.$target.options);
                    _.delay(hideAnimation, 500);
                }
            }, this), 1);
        } else {
            hideAnimation();
        }
    }
};
