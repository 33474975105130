module.exports = {
    getStatusFromAd,
};

function getStatusFromAd(ad) {
    return {
        onTheMarket: ad.onTheMarket,
        closedByUser: Boolean(ad.dataFromUser && ad.dataFromUser.onTheMarket === false),
        autoImported: Boolean(ad.publicationImportId),
        isLeading: isFeaturedActive('isLeading', ad),
        highlighted: isFeaturedActive('highlighted', ad),
        is3dHighlighted: Boolean(ad.is3dHighlighted),
    };
}

function isFeaturedActive(featureName, ad) {
    const expirationDateAsText = ad[`${featureName}Until`];
    const isFeatured = Boolean(ad[featureName]);
    if (!expirationDateAsText) {
        return isFeatured;
    }
    return isFeatured && new Date(ad[`${featureName}Until`]) > Date.now();
}
