const _ = require('lodash');
const assert = require('assert');
const {isMobile} = require('browser-detect');
const template = require('./FavoriteToggleAnimation.jade');
const HoistedComponent = require('../../vue/components/internal/HoistedComponent');

const FALL_DOWN_DISTANCE_IN_PIXELS = 40;
const EXPANDED_CIRCLE_RADIUS_TO_END_EL_TARGET_RATIO = 2;

// @vue/component
module.exports = {
    components: {
        HoistedComponent,
    },
    props: {
        value: Boolean,
        animationInProgress: Boolean,
        endElement: {
            type: HTMLElement,
            default: null,
        },
        icon: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            iconPosition: {top: 0, left: 0},
            iconTranslation: {x: 0, y: 0},
            circlePosition: {top: 0, left: 0},
            circleScaling: 1,
            translateAndFadeOutInProgress: false,
            circleAnimationInProgress: false,
            fallDownInProgress: false,
        };
    },
    computed: {
        iconStyle() {
            const {
                iconPosition: {left, top},
                iconTranslation: {x, y},
            } = this;
            return {
                left: `${left}px`,
                top: `${top}px`,
                transform: `translate(${x}px, ${y}px)`,
            };
        },
        circleStyle() {
            const {
                circlePosition: {left, top},
                circleScaling,
            } = this;
            return {
                left: left + 'px',
                top: top + 'px',
                transform: `scale(${circleScaling})`,
            };
        },
    },
    watch: {
        value(value) {
            // TODO: find the code that injects Vue outside of the DOM and consequently pollutes component detection which makes 'isConnected' test a requirement
            const {$el, animationInProgress} = this;
            if ($el.isConnected && animationInProgress) {
                if (value) {
                    if (!isMobile()) {
                        this.initTranslateAndFadeOut();
                    }
                } else {
                    this.initFallDown();
                }
            }
        },
    },
    mounted() {
        const getIconElement = _.get(this.$slots, 'default[0].componentInstance.getIconElement');
        assert.ok(_.isFunction(getIconElement), 'Missing slot component method');
    },
    methods: {
        getStartElementBoundingRect() {
            const startElement = this.$slots.default[0].componentInstance.getIconElement(); // retrieve target start icon by method defined in kimono-button
            return startElement.getBoundingClientRect();
        },
        getEndElementBoundingRect() {
            const {endElement} = this;
            if (endElement) {
                return endElement.getBoundingClientRect();
            } else {
                return null;
            }
        },
        getIconStartPosition(el) {
            const {width: animatedIconWidth, height: animatedIconHeight} = el.getBoundingClientRect();
            const {top, left, width, height} = this.getStartElementBoundingRect();
            return {
                top: top + (height - animatedIconHeight) / 2,
                left: left + (width - animatedIconWidth) / 2,
            };
        },
        initTranslateAndFadeOut() {
            this.translateAndFadeOutInProgress = true;
        },
        addTranslation(el) {
            const {
                top: startElTop,
                left: startElLeft,
                width: startElWidth,
                height: startElHeight,
            } = this.getStartElementBoundingRect();
            this.iconPosition = this.getIconStartPosition(el);
            const {
                top: endElTop,
                left: endElLeft,
                width: endElWidth,
                height: endElHeight,
            } = this.getEndElementBoundingRect();
            this.iconTranslation = {
                x: (endElLeft - startElLeft) + (endElWidth - startElWidth) / 2,
                y: (endElTop - startElTop) + (endElHeight - startElHeight) / 2,
            };
        },
        hideAnimatedIcon() {
            this.translateAndFadeOutInProgress = false;
            this.fallDownInProgress = false;
            this.$emit('animation-over');
        },
        resetTranslation() {
            this.iconTranslation = {x: 0, y: 0};
        },
        initCircleAnimation() {
            this.circleAnimationInProgress = true;
        },
        addCircleExpansion(el) {
            const {width: radius} = el.getBoundingClientRect();
            const {top, left, width, height} = this.getEndElementBoundingRect();
            this.circlePosition = {
                top: top + (height - radius) / 2,
                left: left + (width - radius) / 2,
            };
            this.circleScaling = EXPANDED_CIRCLE_RADIUS_TO_END_EL_TARGET_RATIO * (height / radius);
        },
        hideCircleAnimation() {
            this.circleAnimationInProgress = false;
        },
        addCircleShrinking() {
            // remark: If we were to use v-if instead of v-show, component would be destroyed and we would have to set style directly on element.
            this.circleScaling = 0;
        },
        resetCircleScaling() {
            this.circleScaling = 1;
        },
        initFallDown() {
            this.fallDownInProgress = true;
        },
        addFallDownTranslation(el) {
            this.iconPosition = this.getIconStartPosition(el);
            this.iconTranslation = {
                x: 0,
                y: FALL_DOWN_DISTANCE_IN_PIXELS,
            };
        },
    },
    template: template(),
};
