const _ = require('lodash');
const template = require('./SetFeaturedProduct.jade');
const PriceFormatter = require('../../../common/PriceFormatter');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'SetFeaturedProduct.',
            keys: {
                includingTaxes: 'includingTaxesMention',
            },
        }),
    ],
    props: {
        product: {
            type: Object,
            required: true,
        },
        selected: Boolean,
    },
    computed: {
        priceAsText() {
            return PriceFormatter.formatForHtmlWithDecimal(this.product.price);
        },
        oldPriceAsText() {
            return PriceFormatter.formatForHtmlWithDecimal(this.product.oldPrice);
        },
        durationAsText() {
            const {days} = this.product.duration;
            return this.translateDuration(days);
        },
        effectiveDurationInDays() {
            const {product: {bonusDuration, duration: {days}}} = this;
            const durationMultiplier = _.defaultTo(bonusDuration, 1);
            return days * durationMultiplier;
        },
        effectiveDurationAsText() {
            return this.translateDuration(this.effectiveDurationInDays);
        },
        dateAsText() {
            return DateFormatter.humanizeEndDateFromDuration({
                days: this.effectiveDurationInDays,
            });
        },
        modifiers() {
            return _.pick(this, 'selected');
        },
        hasSpecialOfferDuration() {
            return _.has(this.product, 'bonusDuration');
        },
    },
    methods: {
        translateDuration(durationInDays) {
            return this.t('duration', {count: durationInDays});
        },
    },
    template: template(),
};
