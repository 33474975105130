const _ = require('lodash');
const VueView = require('../vue/VueView');
const template = require('../templates/views/confirmationModalView.jade');
const i18nMixin = require('../vue/components/mixins/i18n');

class ConfirmationModalView extends VueView {
    show(options) {
        const view = this;
        this._setCallback(options);
        // @vue/component
        const vueOptions = {
            mixins: [
                i18nMixin({
                    prefix: `${options.translationsKeysPath}.`,
                    keys: [
                        'title',
                        'text',
                        'confirm',
                    ],
                }),
            ],
            data() {
                return {
                    modalDisplayed: true,
                    errorTranslationKey: options.errorTranslationKey,
                };
            },
            methods: {
                onClose() {
                    view.hide();
                },
                onConfirm(cb) {
                    view.onConfirm(cb);
                },
            },
            template: template(),
        };
        super.show(null, vueOptions);
    }

    hide() {
        this.onClose();
        super.hide();
    }

    _setCallback({cb, onClose = _.noop}) {
        this.onConfirm = cb;
        this.onClose = onClose;
    }
}

/**
 *
 * @param {Object} options
 * @param {function} options.cb function to call on confirm
 * @param {function} [options.onClose]
 * @param {String} [options.errorTranslationKey]
 * @param {String} [options.translationsKeysPath] path of the translation object containing modal texts
 */
function showConfirm(options) {
    const confirm = new ConfirmationModalView();
    confirm.show(options);
}

module.exports = {
    showConfirm,
};
