const _ = require('lodash');
const _naturalCmp = require('underscore.string/naturalCmp');
const PropertyTypes = require('./PropertyTypes');

module.exports = {
    computeAdValuesFromRelatedAdsValues,
    buildOfficeAddress,
    extendAdWithResidenceOrProgramme,
    isProgrammeOrResidence,
    getProgrammeOrResidenceId,
    isProgrammeOrResidenceOrLot,
    isProgrammeWithSolePropertyType,
    isTerrainProgramme,
    isProgrammeWithAtLeastOneLotHousing,
    isProgrammeWithoutTitle,
    isProgramme,
    isResidence,
    isLot,
    extendAdWithMetaAd, // for tests
};

function computeValueFromValues(values) {
    values = _.sortedUniq(values);
    if (values.length == 1) {
        return values[0];
    } else if (values.length > 1) {
        const arrayValues = _.filter(values, _.isArray);
        if (_.isEmpty(arrayValues)) {
            values.sort(_naturalCmp);
            return _.sortedUniq([values[0], values[values.length - 1]]);
        } else {
            let minResult = _.reduce(arrayValues, function (memo, value) {
                const min = value[0];
                return (memo == undefined || min == undefined) ? undefined :
                    (min < memo ? min : memo);
            }, Infinity);
            let maxResult = _.reduce(arrayValues, function (memo, value) {
                const max = value[value.length - 1];
                return (memo == undefined || max == undefined) ? undefined :
                    (max > memo ? max : memo);
            }, 0);
            const numberValues = _.filter(values, _.isNumber);
            _.each(numberValues, function (value) {
                minResult = value < minResult ? value : minResult;
                maxResult = value > maxResult ? value : maxResult;
            });
            if (minResult == maxResult) {
                return minResult;
            } else {
                return [minResult, maxResult];
            }
        }
    } else {
        return null;
    }
}

function computeAdValuesFromRelatedAdsValues(relatedAds) {
    const relatedAdsWithoutTerrain = _.reject(relatedAds, ad => ad.propertyType == 'terrain');
    let availableDate = computeValueFromAds(relatedAds, 'availableDate');
    if (_.isArray(availableDate)) {
        availableDate = availableDate[0];
    }
    const adValues = {
        price: computeValueFromAds(relatedAds, 'price'),
        pricePerSquareMeter: computeValueFromAds(relatedAds, 'pricePerSquareMeter'),
        surfaceArea: computeValueFromAds(relatedAds, 'surfaceArea'),
        landSurfaceArea: computeValueFromAds(relatedAds, 'landSurfaceArea'),
        roomsQuantity: computeValueFromAds(relatedAdsWithoutTerrain, 'roomsQuantity'),
        agencyRentalFee: computeValueFromAds(relatedAds, 'agencyRentalFee'), //used for GMC export for residence
        hasLotWith360: _.some(relatedAds, 'with360'),
        hasLotWithHighlightedVideo: _.some(relatedAds, 'withHighlightedVideo'),
        availableDate,
    };
    return _.omitBy(adValues, _.isNil);
}

function computeValueFromAds(ads, key) {
    return computeValueFromValues(
        _.compact(_.map(ads, key)).sort(compareArrayOrNumber)
    );
}

function compareOther(a, b) {
    if (a < b) {
        return -1;
    } else if (b == a) {
        return 0;
    } else {
        return 1;
    }
}

function compareNumbers(a, b) {
    return a - b;
}

function compareArrayOrNumber(a, b) {
    if (_.isNumber(a) && _.isNumber(b)) {
        return compareNumbers(a, b);
    } else if (_.isArray(a) && _.isArray(b)) {
        return compareArrayOrNumber(a[0], b[0]);
    } else {
        return compareOther(a, b);
    }
}

function extendAdWithResidenceOrProgramme(ad, {residence, programme}) {
    if (ad.residenceReference && residence) {
        extendAdWithMetaAd(ad, residence);
    }
    if (ad.programmeRef && programme) {
        extendAdWithMetaAd(ad, programme);
    }
}

// copied from f4/kimono-indexer/lib/AdFormatterHelper@extendWithMetaAdIfNeeded
function extendAdWithMetaAd(ad, metaAd) {
    const photos = _.union(ad.photos || [], metaAd.photos || []);
    _.extend(ad, _.pick(metaAd, [
        'position',
        'district',
        'blurInfo',
        //'zoneIds', //no need to do this client side, used only for search request
        'country',
        'city',
        'postalCode',
        'street',
    ]), {
        photos,
    });
    _.defaults(ad, _.pick(metaAd, [
        'availableDate',
        'description',
        'onlineBookingUrl',
        //'searchAccountIds', //no need to do this client side, used only for search request
        //'displayLoweredPriceAds', //no need to do this client side, used only for search request
    ]));
    ad.isInStudentResidence = Boolean(ad.isInStudentResidence || metaAd.isInStudentResidence);
    ad.isInSeniorResidence = Boolean(ad.isInSeniorResidence || metaAd.isInSeniorResidence);
    ad.isInTourismResidence = Boolean(ad.isInTourismResidence || metaAd.isInTourismResidence);
}

function buildOfficeAddress(ad) {
    const addressInfos = [];
    const street = ad.POSStreet1 || ad.POSStreet2 || ad.POSStreet3;
    if (street) {
        addressInfos.push(street);
    }
    if (ad.POSPostalCode) {
        addressInfos.push(ad.POSPostalCode);
    }
    if (ad.POSCity) {
        addressInfos.push(ad.POSCity);
    }
    return addressInfos.join(' ');
}

/**
 *
 * @param {object} realEstateAd
 * @param {string} realEstateAd.propertyType
 * @returns {boolean}
 */
function isProgrammeOrResidence(realEstateAd) {
    return (isProgramme(realEstateAd) || isResidence(realEstateAd));
}

function getProgrammeOrResidenceId(ad) {
    return ad.programmeRef || ad.residenceReference;
}

function isProgramme(realEstateAd) {
    return realEstateAd.propertyType == 'programme';
}

function isResidence(realEstateAd) {
    return realEstateAd.propertyType == 'residence';
}

function isProgrammeWithSolePropertyType(realEstateAd) {
    return isProgramme(realEstateAd) && _.size(_.groupBy(realEstateAd.relatedAdsIds, 'propertyType')) == 1;
}

function isProgrammeWithoutTitle(realEstateAd) {
    return isProgramme(realEstateAd) && !realEstateAd.title;
}

function isProgrammeOrResidenceOrLot(realEstateAd) {
    return (isProgrammeOrResidence(realEstateAd) || getProgrammeOrResidenceId(realEstateAd));
}

function isTerrainProgramme({propertyType, relatedAdsIds}) {
    return (propertyType == 'programme' && !_.isEmpty(relatedAdsIds) && _.every(relatedAdsIds, {propertyType: 'terrain'}));
}

function isProgrammeWithAtLeastOneLotHousing({propertyType, relatedAdsIds}) {
    return propertyType == 'programme' && !_.isEmpty(relatedAdsIds)
        && _.some(relatedAdsIds, (relatedAdsId) => _.includes(PropertyTypes.housingSingle, relatedAdsId.propertyType));
}

function isLot(realEstateAd) {
    return Boolean(getProgrammeOrResidenceId(realEstateAd));
}
