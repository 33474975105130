module.exports = [
    {
        name: 'realEstateEstimator',
        icon: 'evaluation',
        href: '/estimation-immobiliere',
    },
    {
        name: 'investInNewProperties',
        icon: 'conseil',
        href: '/acheter-pour-investir-en-locatif-dans-l-immobilier-neuf',
    },
    {
        name: 'financialPartnerMortgageSimulator',
        icon: 'simulateur',
        rel: 'nofollow',
    },
];
