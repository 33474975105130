const intersects = require('../../common/intersects');

const MinMaxField = require('./MinMaxField');
const PropertyTypes = require('../../common/PropertyTypes');

module.exports = class HousingMinMaxField extends MinMaxField {
    updateVisibility(filters) {
        const isFilterVisible = !this.shouldDisableFilter(filters);
        this.$element.toggleClass('hidden', !isFilterVisible);
        if (!isFilterVisible) {
            this.clearFields();
        }
    }

    shouldDisableFilter({propertyType}) {
        return !intersects(propertyType, PropertyTypes.housing);
    }
};
