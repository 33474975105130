const _ = require('lodash');
const {resourceUrl} = require('fack');
const moment = require('moment');
const ProspectingMapLegend = require('./ProspectingMapLegend');
const template = require('./ProspectingMap.jade');
const {
    getColorAsHsl,
    getZIndex,
    MARKER_HEIGHT_IN_PX,
    MARKER_WIDTH_IN_PX,
    ESTIMATIONS_MAX_AGE_IN_DAYS,
} = require('../estimationsMarkersDisplayHelper');
const {clampedInverseLinearInterpolation} = require('../lerpHelper');
const MapApi = require('../../MapApi');
const AgencyMap = require('../../agency/components/AgencyMap');
const EstimationOverview = require('./EstimationOverview');
const {drawSimpleSquareCanvas} = require('../../MarkerIcon');
const {getLastInquiryEstimationsByTimeRange} = require('../getLastInquiryEstimationsByTimeRange');
const {BLUR_OVERLAY_OPTIONS} = require('../../BlurHelper');
const {BLUR_OVERLAY_KARTO_OPTIONS} = require('../../BlurHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');

const INITIAL_ZOOM = 15;
const POIS_ZOOM_LEVEL_APPEARANCE = 16; // Zoom level of pois that could be mistaken for estimations markers
const MIN_MARKER_SCALE = 0.3;
const MIN_MARKER_SCALE_LIMIT_ZOOM = 10;
const MAX_MARKER_SCALE = 1.5;
const MARKERS_IMAGES_FOLDER_PATH = 'images/poi/';

// Compute urls only once(and not at each marker creation) and stash results in cache in order to avoid performance
// downgrade when displaying a lot of markers
const getMemoizedCanvasUrl = _.memoize(generateCanvasUrl);

// @vue/component
module.exports = {
    constants: {
        MARKER_ICON_URL: resourceUrl(`${MARKERS_IMAGES_FOLDER_PATH}agency.svg`),
        THREE_D_POINTS_OPTIONS: {
            defaultHeight: 15,
            fnColor: (feature) => {
                return feature.properties.color;
            },
            dimensionPixel: 15,
            line: {
                enable: true,
                color: '#000',
                width: 1,
            },
            clickable: true,
            hoverable: true,
        },
    },
    components: {
        AgencyMap,
        ProspectingMapLegend,
        EstimationOverview,
    },
    mixins: [
        i18nMixin({
            prefix: 'ProspectingMap.',
            keys: [
                'title',
                'loadingErrorMessage',
                'reloadButtonText',
            ],
        }),
    ],
    props: {
        agency: {
            type: Object,
            required: true,
        },
        useKarto: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            estimations: null,
            selectedEstimation: null,
            hoveredEstimation: null,
            loadingError: false,
            sizeFactor: 1,
            threeDPoints: null,
            collectionOf3dPoints: {
                type: 'FeatureCollection',
                features: [],
            },
        };
    },
    computed: {
        estimationsLoading() {
            return this.estimations == null;
        },
        loadingOverlayVariant() {
            return this.loadingError ? 'dark' : 'light';
        },
    },
    watch: {
        estimations() {
            this.updateMarkers();
        },
        selectedEstimation(estimation, previousEstimation) {
            if (!_.isEqual(estimation, previousEstimation)) {
                this.removeElementFromMap(this.blurCircle);
                const isHoveredEstimation = _.isEqual(estimation, this.hoveredEstimation);
                this.blurCircle = isHoveredEstimation ? this.hoveredBlurCircle : this.createBlurCircle(estimation);
                if (isHoveredEstimation) {
                    this.hoveredEstimation = null;
                }
            }
        },
        hoveredEstimation(estimation) {
            if (!_.isEqual(this.hoveredBlurCircle, this.blurCircle)) {
                this.removeElementFromMap(this.hoveredBlurCircle);
            }
            if (!_.isEqual(this.selectedEstimation, estimation)) {
                this.hoveredBlurCircle = this.createBlurCircle(estimation);
            }
        },
        sizeFactor() {
            this.updateMarkers();
        },
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loadingError = false;
            getLastInquiryEstimationsByTimeRange(ESTIMATIONS_MAX_AGE_IN_DAYS, (err, estimations) => {
                if (err) {
                    console.log('Could not retrieve estimations', err);
                    this.loadingError = true;
                } else {
                    this.estimations = estimations;
                }
            });
        },
        getScale() {
            const zoom = this.map.getZoom();
            const poisZooomLevelAppearance = this.useKarto ? POIS_ZOOM_LEVEL_APPEARANCE - 1 : POIS_ZOOM_LEVEL_APPEARANCE;
            if (zoom >= poisZooomLevelAppearance) {
                return MAX_MARKER_SCALE;
            } else if (zoom >= MIN_MARKER_SCALE_LIMIT_ZOOM && zoom <= poisZooomLevelAppearance) {
                return clampedInverseLinearInterpolation(0, INITIAL_ZOOM, zoom);
            } else {
                return MIN_MARKER_SCALE;
            }
        },
        handleMapCreated(createdMap) {
            this.map = createdMap;
            this.updateMarkers();
            if (this.useKarto) {
                this.sizeFactor = this.getScale();
                this.map.on('click', () => {
                    this.unselectEstimation();
                });
                this.map.on('zoom', () => {
                    this.sizeFactor = this.getScale();
                });
                this.map.on('selection', (event) => {
                    if (event.type === 'Points3D') {
                        this.selectedEstimation = event.result.feature.properties.estimation;
                    }
                });
                this.map.on('mousein', (event) => {
                    if (event.type === 'Points3D') {
                        this.hoveredEstimation = event.result.feature.properties.estimation;
                    }
                });
                this.map.on('mouseout', (event) => {
                    if (event.type === 'Points3D') {
                        this.hoveredEstimation = null;
                    }
                });
            } else {
                createdMap.on('click', () => {
                    this.unselectEstimation();
                });
                createdMap.on('zoom_changed', () => {
                    this.updateMarkersSize();
                });
            }
        },
        unselectEstimation() {
            this.selectedMarker = null;
            this.selectedEstimation = null;
        },
        updateMarkersFromEstimations() {
            const {estimations} = this;
            this.markers = _(estimations)
                .map(estimation => this.createEstimationMarker(estimation))
                .compact()
                .value();
        },
        updateMarkers() {
            const {estimations, map, THREE_D_POINTS_OPTIONS, sizeFactor} = this;
            if (map && _.size(estimations) > 0) {
                if (this.useKarto) {
                    const creationOptions = _.extend(THREE_D_POINTS_OPTIONS, {sizeFactor});
                    if (!this.markers || this.markers.length == 0) {
                        this.removeEstimationsFromMap();
                        this.updateMarkersFromEstimations();
                        this.threeDPoints = this.map.create3DPoints(this.collectionOf3dPoints, creationOptions);
                    } else {
                        this.collectionOf3dPoints.features.length = 0;
                        this.updateMarkersFromEstimations();
                        this.map.update3DPoints(this.threeDPoints, this.collectionOf3dPoints, creationOptions);
                    }
                } else {
                    this.removeEstimationsFromMap();
                    this.updateMarkersFromEstimations();
                }
            }
        },
        createEstimationMarker(estimation) {
            const estimationAgeInDays = moment().diff(estimation.estimationDate, 'days');
            const {position} = getBlurCircleParameters(estimation);
            if (this.useKarto) {
                if (position) {
                    const markerFeature = {
                        id: estimation.id,
                        type: 'Feature',
                        geometry: {
                            coordinates: [
                                position.lng,
                                position.lat,
                                20,
                            ],
                            type: 'Point',
                        },
                        properties: {
                            color: getColorAsHsl(estimationAgeInDays),
                            estimation,
                        },
                    };
                    this.collectionOf3dPoints.features.push(markerFeature);
                    return markerFeature;
                }
            } else {
                const {Marker} = MapApi.api;
                const zIndex = getZIndex(estimationAgeInDays, Marker.MAX_ZINDEX);
                let marker = null;
                if (position) {
                    marker = new Marker({
                        map: this.map,
                        position,
                        icon: {
                            url: getMemoizedCanvasUrl(estimationAgeInDays),
                            ...this.getMarkerIconScaledSizeAndAnchor(),
                        },
                        zIndex,
                    });
                    marker.on('click', () => {
                        this.selectedMarker = marker;
                        this.selectedEstimation = estimation;
                    });
                    marker.on('mouseover', () => {
                        this.hoveredEstimation = estimation;
                    });
                    marker.on('mouseout', () => {
                        this.hoveredEstimation = null;
                    });
                }
                return marker;
            }
        },
        createBlurCircle(estimation) {
            const {position, radius} = getBlurCircleParameters(estimation);
            if (this.useKarto && radius) {
                const shapeGroup = this.map.createShapeGroup();
                shapeGroup.addFeature(
                    new kartoEngine.Circle(
                        [
                            position.lng,
                            position.lat,
                        ],
                        radius
                    )
                );
                shapeGroup.setPaint(BLUR_OVERLAY_KARTO_OPTIONS);
                return shapeGroup;
            } else {
                if (radius) {
                    return new MapApi.api.Circle(_.extend({
                        map: this.map,
                        center: position,
                        radius,
                    }, BLUR_OVERLAY_OPTIONS));
                }
            }
        },
        updateMarkersSize() {
            const {markers} = this;
            _.each(markers, marker => {
                marker.setIcon({
                    url: marker.getIcon().url,
                    ...this.getMarkerIconScaledSizeAndAnchor(),
                });
            });
        },
        getMarkerIconScaledSizeAndAnchor() {
            const {Point, Size} = MapApi.api;
            const markerScale = this.getScale();
            const markerPointWidthInPx = markerScale * MARKER_WIDTH_IN_PX;
            const markerPointHeightInPx = markerScale * MARKER_HEIGHT_IN_PX;
            return {
                scaledSize: new Size(markerPointWidthInPx, markerPointHeightInPx),
                anchor: new Point(markerPointWidthInPx / 2, markerPointHeightInPx / 2),
            };
        },
        removeMarkersFromMap(markers) {
            if (!this.useKarto) {
                _.each(markers, this.removeElementFromMap);
            }
        },
        removeElementFromMap(element) {
            if (this.useKarto) {
                _.invoke(element, 'removeFromMap', null);
            } else {
                _.invoke(element, 'setMap', null);
            }
        },
        remove3dPoints(id) {
            if (id) {
                const points3dManager = this.map.getPoints3DManager();
                points3dManager.removePoints3D(id);
            }
        },
        removeEstimationsFromMap() {
            if (this.useKarto) {
                this.collectionOf3dPoints.features.length = 0;
                this.remove3dPoints(this.threeDPoints);
            } else {
                this.removeMarkersFromMap(this.markers);
            }
        },
    },
    template: template(),
};

function generateCanvasUrl(ageInDays) {
    const colorAsHsl = getColorAsHsl(ageInDays);
    const canvas = drawSimpleSquareCanvas(colorAsHsl);
    return canvas.toDataURL();
}

function getBlurCircleParameters(estimation) {
    return _.get(estimation, 'realEstateProperty.position.blurInfo', {});
}
