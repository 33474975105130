const template = require('./DetailCell.jade');

// @vue/component
module.exports = {
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    template: template(),
};
