const _ = require('lodash');

const template = require('./MinMaxField.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const SearchFiltersConfiguration = require('../SearchFiltersConfiguration');
const RangeTextFormatter = require('../../../common/RangeTextFormatter');

const OPTIONS = {
    'price': {
        title: 'Votre budget',
        label: {
            configMethod: 'getPriceConfiguration',
            unitOrValue: 'unit',
        },
        componentType: 'PriceField',
        inputToFocus: 'max',
        lowPriorityInput: 'min',
    },
    'rooms': {
        title: 'Nombre de pièces',
        label: {
            configMethod: 'getRoomQuantityConfiguration',
            unitOrValue: 'value',
        },
        componentType: 'NumberField',
        inputToFocus: 'min',
        lowPriorityInput: 'max',
    },
    'area': {
        title: 'Surface du bien',
        label: {
            configMethod: 'getAreaSizeConfiguration',
            unitOrValue: 'unit',
        },
        componentType: 'SurfaceAreaField',
        inputToFocus: 'min',
        lowPriorityInput: 'max',
    },
    'bedrooms': {
        title: 'Nombre de chambres',
        label: {
            configMethod: 'getBedroomQuantityConfiguration',
            unitOrValue: 'value',
        },
        componentType: 'NumberField',
        inputToFocus: 'min',
        lowPriorityInput: 'max',
    },
    'gardenSurfaceArea': {
        title: 'Surface du terrain',
        label: {
            configMethod: 'getGardenAreaSizeConfiguration',
            unitOrValue: 'unit',
        },
        componentType: 'SurfaceAreaField',
        inputToFocus: 'min',
        lowPriorityInput: 'max',
    },
};

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'MinMaxField.',
        }),
    ],
    props: {
        name: {
            type: String,
            required: true,
        },
        min: {
            type: [Number, String],
            default: null,
        },
        max: {
            type: [Number, String],
            default: null,
        },
    },
    computed: {
        options() {
            return OPTIONS[this.name];
        },
        fakeInputName() {
            return 'minMax' + _.upperFirst(this.name);
        },
        isSet() {
            return Boolean(this.min || this.max);
        },
        title() {
            return this.options.title;
        },
        label() {
            return this.getFilterLabel();
        },
        componentType() {
            return this.options.componentType;
        },
        inputToFocus() {
            return this.options.inputToFocus;
        },
        lowPriorityInput() {
            return this.options.lowPriorityInput;
        },
    },
    methods: {
        onInput($event) {
            this.$emit('change', $event);
        },
        onOpenDropdown() {
            this.$refs.range.inputToFocus = this.inputToFocus;
        },
        onCloseDropdown() {
            this.$refs.range.inputToFocus = this.inputToFocus === 'min' ? 'max' : 'min';
        },
        getFilterLabel() {
            const conf = SearchFiltersConfiguration[this.options.label.configMethod]();
            return this.getLabelFromRange(
                this.min,
                this.max,
                this.options.label.unitOrValue === 'unit' ? conf.summary.unit : conf.summary.value
            ) || conf.summary.label;
        },
        getLabelFromRange(min, max, unit) {
            const label = RangeTextFormatter.formatRange({
                min,
                max,
                unit,
            });

            return label !== '' ? label : null;
        },
    },
    template: template(),
};
