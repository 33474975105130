const {PLAYSTORE_THEME} = require('../constants');

// @vue/component
module.exports = {
    props: {
        theme: {
            type: String,
            validator: theme => theme === PLAYSTORE_THEME,
            default: undefined,
        },
    },
};
