const $ = require('jquery');

const _ = require('lodash');

const FormEnterKeyHandler = require('./FormEnterKeyHandler');
const ScrollHelper = require('../utils/ScrollHelper');
const StringToBoolean = require('../utils/StringToBoolean');
const hasBooleanAttribute = require('./utils/hasBooleanAttribute');

const plugins = [];

const DEFAULT_VALIDATOR_OPTIONS = {
    excluded: ':disabled', // validate hidden inputs (for bootstrap-select hidden select)
    container: 'popover',
    feedbackIcons: {
        valid: 'fa fa-check',
        invalid: 'md md-close',
        validating: 'md-rotate-right md-spin',
    },
    events: {
        formInit: 'bv-init.form.bv',
        formError: 'bv-error.form.bv',
        formSuccess: 'bv-success.form.bv',
        fieldAdded: 'bv-added.field.bv',
        fieldRemoved: 'bv-removed.field.bv',
        fieldInit: 'bv-init.field.bv',
        fieldError: 'bv-error.field.bv',
        fieldSuccess: 'bv-success.field.bv',
        fieldStatus: 'bv-status.field.bv',
        validatorError: 'bv-error.validator.bv',
        validatorSuccess: 'bv-success.validator.bv',
    },
};

// avoid autocomplete from browser (e.g. name=".password")
const IGNORED_FIELD_NAME_PREFIX = '.';
const IGNORE_AUTOCOMPLETE_FIELD_NAME_REGEX = new RegExp('^' + _.escapeRegExp(IGNORED_FIELD_NAME_PREFIX));
const FORCE_UNSET_VALUE = 'FORCE_UNSET_VALUE';
const IGNORE_VALUE_FIELD_NAME_REGEX = /^v-/; // ignore fields values when they are completely handled by Vue

module.exports = {
    registerPlugin,
    init,
    clear,
    resetForm,
    initValidator,
    clearValidator,
    readValues,
    setValues,
    eachInputWithName,
    getInputValue,
    getInputValueByName,
    setInputValue,
    findInputWithName,
    findFormGroupInputWithName,
    findParentsInputWithName,
    DEFAULT_VALIDATOR_OPTIONS,
};

function registerPlugin(plugin) {
    plugins.push(plugin);
}

/**
 * Handle form validation and navigation on enter key.
 * @param {object} options
 * @param {node} options.$form jquery node pointing to form
 * @param {node} [options.$button] jquery node pointing to submit button, used to display a spinner
 * @param {function} options.submit function called on submit
 * @param {function} [options.afterSubmit] callback called after submit
 * @param {function} [options.fieldSuccess] Triggered when any field is valid
 * @param {function} [options.formError] Triggered when the form is invalid
 */
function init(options) {
    const $form = options.$form;
    initValidator(options);
    FormEnterKeyHandler.init($form);
    invokeEachPlugin('init', options);
}

/**
 * Clear form validation and navigation.
 * @param {object} options
 * @param {node} options.$form jquery node pointing to form
 */
function clear(options) {
    const $form = options.$form;
    clearValidator(options);
    FormEnterKeyHandler.clear($form);
}

function resetForm($form) {
    $form.data('bootstrapValidator').resetForm();
}

function initValidator(options) {
    const customOptions = _.get(options, 'validatorOptions', {});
    const customFeedbackIcons = _.get(customOptions, 'feedbackIcons', {});
    const validatorOptions = _.assignIn({}, DEFAULT_VALIDATOR_OPTIONS, customOptions);
    if (customFeedbackIcons && _.size(customFeedbackIcons) > 0) {
        validatorOptions.feedbackIcons = _.defaultsDeep(customFeedbackIcons, DEFAULT_VALIDATOR_OPTIONS.feedbackIcons);
    }
    options.$form.bootstrapValidator(validatorOptions)
        .on(validatorOptions.events.fieldStatus, (e, data) => {
            //submit button always available
            data.bv.disableSubmitButtons(false);
        })
        .on(validatorOptions.events.formSuccess, e => {
            const $submitButton = _.get(options, '$button', options.$form.find('button[type="submit"]'));
            invokeEachPlugin('onSubmitStart', $submitButton);
            //prevent submit button from being disabled on submit (for example when a login popup is displayed and then closed)
            options.$form.data('bootstrapValidator').disableSubmitButtons(false);
            e.preventDefault(); // Prevent form submission
            const submitFunction = _.get(options, 'submit', () => {
                throw new Error('submit function must be implemented');
            });
            submitFunction(() => {
                invokeEachPlugin('onSubmitEnd', $submitButton);
                _.get(options, 'afterSubmit', _.noop)();
            });
        })
        .on(validatorOptions.events.formError, () => {
            options.$form.data('bootstrapValidator').disableSubmitButtons(false);
            _.get(options, 'formError', _.noop)();
            scrollToErrorIfEnable(options);
        })
        .on(validatorOptions.events.fieldSuccess, _.get(options, 'fieldSuccess', _.noop));
}

function invokeEachPlugin(methodName, ...args) {
    _.forEach(plugins, function (plugin) {
        const method = plugin[methodName];
        if (method) {
            method.apply(plugin, args);
        }
    });
}

function scrollToErrorIfEnable({$form, enableScrollOnError}) {
    if (enableScrollOnError) {
        const errors = $form.find('.has-error');
        if (errors.length) {
            ScrollHelper.scrollToElement(errors[0]);
        }
    }
}

function clearValidator(options) {
    const bootstrapValidator = options.$form.data('bootstrapValidator');
    if (bootstrapValidator) {
        bootstrapValidator.destroy();
    }
}

function findInputWithName($form, name) {
    return $form.find(`input[name="${name}"],select[name="${name}"],textarea[name="${name}"]`);
}

function findFormGroupInputWithName($form, name) {
    return findParentsInputWithName($form, name, '.form-group');
}

function findParentsInputWithName($form, name, parentSelector) {
    return findInputWithName($form, name).parents(parentSelector);
}

function findInputsHavingName($form) {
    return $form.find('input[name],select[name],textarea[name]');
}

function eachInputWithName($form, iterator) {
    const $inputs = findInputsHavingName($form);
    _.forEach($inputs, input => {
        const $input = $(input);
        iterator($input, $input.attr('name'));
    });
}

function findInputsWithNameAndValue($form, name) {
    let inputs = _.concat(findInputWithName($form, name).toArray(),
        findInputWithName($form, IGNORED_FIELD_NAME_PREFIX + name).toArray());
    const [firstInput] = inputs;
    if (firstInput) {
        inputs = _.get(findPluginHandlingField($(firstInput)), 'filterInputsWithValue', _.identity)(inputs);
    }
    return $(inputs);
}

function eachInputWithNameAndValue($form, iterator) {
    const $inputs = findInputsHavingName($form);
    const names = _($inputs)
        .map(input => getCleanName($(input).attr('name')))
        .reject(name => IGNORE_VALUE_FIELD_NAME_REGEX.test(name))
        .uniq()
        .value()
    ;
    _.forEach(names, name => {
        const $inputs = findInputsWithNameAndValue($form, name);
        _.each($inputs, input => {
            iterator($(input), name);
        });
    });
}

// todo: change the default value of removeUndefined to false, it allows to distinguish between a missing field and an empty field
function readValues($form, {removeUndefined = true} = {}) {
    const values = {};
    eachInputWithNameAndValue($form, function ($input, name) {
        const inputValue = getInputValue($input);
        if (removeUndefined && undefined === inputValue) {
            return;
        }
        const matchArrayName = getArrayNameMatch(name);
        const path = matchArrayName ? matchArrayName[1] : getCleanName(name);
        const value = matchArrayName ? getValueToSet(matchArrayName[1], inputValue, values) : inputValue;
        _.set(values, path, value);
    });
    return values;
}

function getValueToSet(key, inputValue, values) {
    return getConcatValues(inputValue, key, values);
}

function getConcatValues(inputValue, key, values) {
    return _.isNil(inputValue) ? values[key] : [].concat(values[key] || [], [inputValue]);
}

function getInputValue($input) {
    let val = _.get(findPluginHandlingField($input), 'getValue', defaultGetValue)($input);
    if (val !== undefined && hasJsonInputValue($input)) {
        val = parseJsonValue(val);
    }
    return val;
}

function parseJsonValue(val) {
    if (val === '') {
        return undefined;
    } else if (_.isArray(val)) {
        return _.map(val, parseJsonValue);
    } else {
        return JSON.parse(val);
    }
}

function hasJsonInputValue($input) {
    return hasBooleanAttribute($input, 'data-value-json');
}

function getInputValueByName($form, name) {
    return getInputValue(findInputWithName($form, name));
}

function defaultGetValue($input) {
    const val = $input.val();
    return _.isNil(val) ? undefined : val;
}

function setValues($form, values) {
    eachInputWithName($form, function ($input, name) {
        if (!IGNORE_VALUE_FIELD_NAME_REGEX.test(name)) {
            const inputValue = getValueByName($input, name, values);
            if (undefined !== inputValue) {
                setInputValue($input, inputValue === FORCE_UNSET_VALUE ? undefined : inputValue);
            }
        }
    });
}

function getValueByName($input, name, values) {
    const matchArrayName = getArrayNameMatch(name);
    if (matchArrayName) {
        name = matchArrayName[1];
    }
    const value = _.get(values, getCleanName(name));
    return matchArrayName ? getValidValueForInput($input, value) : value;
}

function getValidValueForInput($input, testedValues) {
    if (!_.isUndefined(testedValues)) {
        testedValues = [].concat(testedValues); //ensure testedValues is an array
        const inputValue = $input.attr('value');
        const boolValue = StringToBoolean(inputValue);
        const expectedValue = _.isBoolean(boolValue) ? boolValue : inputValue;
        const value = _.find(testedValues, searchValue => searchValue === expectedValue);
        return _.isUndefined(value) ? FORCE_UNSET_VALUE : value;
    }
}

function getArrayNameMatch(name) {
    return name.match(/(.*)\[\]/);
}

function getCleanName(name) {
    return name.replace(IGNORE_AUTOCOMPLETE_FIELD_NAME_REGEX, '');
}

function setInputValue($input, val) {
    if (val !== undefined && hasJsonInputValue($input)) {
        val = JSON.stringify(val);
    } // todo: handle arrays for multiple selects
    _.get(findPluginHandlingField($input), 'setValue', defaultSetValue)($input, val);
}

function defaultSetValue($input, val) {
    $input.val(val);
}

function findPluginHandlingField($field) {
    return _.find(plugins, plugin => plugin.handlesField && plugin.handlesField($field));
}
