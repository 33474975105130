const _ = require('lodash');
const {getLinkToAds} = require('../../search/LoadUserAdsHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./RealEstateAdSummary.jade');
const NumberFormatter = require('../../../common/NumberFormatter');
const UrlFormatter = require('../../../common/UrlFormatter');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'RealEstateAdSummary.',
            keys: [
                'adTypeLinksSeparator',
            ],
        }),
    ],
    props: {
        options: {
            type: Object,
            required: true,
        },
        agencyView: Boolean,
    },
    computed: {
        ready() {
            return !_.isEmpty(this.options);
        },
        textStart() {
            const {t, agencyView} = this;
            return t('textStart', {
                context: agencyView ? 'agencyView' : '',
            });
        },
        textEnd() {
            const {t, agencyView, options: {author: {accountType}}} = this;
            if (!agencyView) {
                return '';
            }
            return t(`end.${accountType}`, {
                defaultValue: '',
            });
        },
        textNoAds() {
            const {t, textEnd} = this;
            return t('textNoAds') + textEnd;
        },
        adTypeLinks() {
            const {options: {userAdsResume}, t, linkToAds, agencyView} = this;
            return _(userAdsResume).map((value, key) => {
                if (value) {
                    const href = linkToAds + '/' + UrlFormatter.filterTypeToUrl(key);
                    const formattedCount = NumberFormatter.formatNumber(value);
                    const text = t('adsCount', {
                        count: value,
                        formattedCount,
                        context: _.join(_.compact([key, agencyView ? 'agencyView' : null]), '_'),
                    });
                    return {
                        href, formattedCount, text,
                    };
                }
                return null;
            }).compact().value();
        },
        totalAds() {
            const {options: {userAdsResume}} = this;
            return _(userAdsResume)
                .values()
                .sum();
        },
        linkToAds() {
            const {options: {author}} = this;
            return getLinkToAds(author.id);
        },
        linkToAdsText() {
            const {t, totalAds} = this;
            return t('publishedAds', {
                count: totalAds,
                formattedCount: NumberFormatter.formatNumber(totalAds),
            });
        },
    },
    template: template(),
};
