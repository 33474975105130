const Ajax = require('../utils/Ajax');
const _ = require('lodash');
const ServerConfig = require('../ServerConfig');
const BrowserDetect = require('browser-detect');
const FlashDetect = require('flash-detect');
const Account = require('../authentication/Account');
const {resourceUrl} = require('fack');

const flashAvailable = new FlashDetect().installed;

module.exports = {
    getVirtualToursForProgramme,
    canOpenVirtualTourInIframe,
    getUrlForTour,
    enhanceAd,
    getAvailableTours,
    getPreviewPictureAndText,
    is360VirtualTour,
    getVirtualToursWithLiveRoom,
};

const PICTURES_DIR = 'images/';
const PICTURES_EXT = '.jpg';
const VIRTUAL_TOURS_PICTURES_DIR = 'virtualTours/';
const DEFAULT_VISIT_PICTURE = PICTURES_DIR + 'visite' + PICTURES_EXT;
const VIDEO_VISITE_PICTURE = PICTURES_DIR + VIRTUAL_TOURS_PICTURES_DIR + 'video' + PICTURES_EXT;
const VIDEO_VISITE_THUMBNAILS_PICTURE = PICTURES_DIR + VIRTUAL_TOURS_PICTURES_DIR + 'mini-video' + PICTURES_EXT;
const THREE_D_PROJECTION_PICTURE = PICTURES_DIR + VIRTUAL_TOURS_PICTURES_DIR + 'visite-orbital-3D' + PICTURES_EXT;
const THREE_D_PROJECTION_THUMBNAILS_PICTURE = PICTURES_DIR + VIRTUAL_TOURS_PICTURES_DIR + 'mini-visite-orbital-3D' + PICTURES_EXT;
const NEW_BUILD_360_PICTURE = PICTURES_DIR + VIRTUAL_TOURS_PICTURES_DIR + 'visite-360' + PICTURES_EXT;
const OLD_PROPERTY_360_PICTURE = PICTURES_DIR + '360_old' + PICTURES_EXT;
const VISITE_360_THUMBNAILS_PICTURE = PICTURES_DIR + VIRTUAL_TOURS_PICTURES_DIR + 'mini-visite-360' + PICTURES_EXT;

const VIRTUAL_TOURS_3D_PROJECTION_TYPES = [
    'modèle 3d, déplacement libre',
    'visite orbitale',
    'modèle 3d, controle camera de haut',
    "création d'habitation 3d",
    'modèle 3d, déplacement suivi',
    'vue aerienne avec incrustation de modèle 3d',
    'présentation de programme avec 360, vue aerienne, visite orbitale et modeles 3d',
    'visite orbitale avec découpe par étage',
    'plan de masse avec navigation vers modèles 3d de lots',
    'modèle 3d configurable',
];

function getVirtualToursForProgramme(programmeId, cb) {
    const url = ServerConfig.config.wwwUrl + '/relatedRealEstateAds.json';
    return Ajax.request({
        url,
        disableErrorPage: true,
        data: {
            id: programmeId,
            propertyType: 'programme',
            allowNotOnTheMarketFilter: true,
            filters: {
                onTheMarket: [true, false],
            },
        },
        callback: (err, relatedAds) => {
            if (relatedAds) {
                relatedAds = _.compact(
                    _.map(relatedAds, (ad) => _.isEmpty(ad.virtualTours) ? null : _.pick(ad, 'id', 'virtualTours'))
                );
            }
            cb(err, relatedAds);
        },
    });
}

function canOpenVirtualTourInIframe(tour) {
    if (tour.type == 'video-file') {
        return false; //no player, so open in new tab so browser can try a render on its own, or start download
    }
    if (BrowserDetect.isIOS()) { //https://git.f4-group.com/f4/kimono/issues/3038
        return false;
    }
    if (window.location.protocol == 'https:') {
        return tour.https;
    } else {
        return true;
    }
}

function getUrlForTour(tour) {
    let url = tour.url;
    if (tour.https && /^http:/.test(url)) {
        url = url.replace(/^http:/, 'https:');
    } else if (!tour.https && /^https:/.test(url)) {
        url = url.replace(/^https:/, 'http:');
    }
    return url;
}

function enhanceAd(ad) {
    const first360VirtualTourId = _.get(_.first(getAvailable360VirtualTours(ad.virtualTours)), 'id');
    const with360 = Boolean(first360VirtualTourId);
    const with360WithoutVirtualTourTesterRole = getAvailable360VirtualToursWithoutVirtualTourTesterRole(ad.virtualTours).length > 0;
    const withHighlightedVideo = hasAvailableHighlightedVideoVirtualTour(ad.virtualTours);
    const withLiveRoom = !_.isEmpty(getVirtualToursWithLiveRoom(ad.virtualTours));
    return _.extend({}, ad, {
        first360VirtualTourId,
        withHighlightedVideo,
        with360,
        with360BecauseOfVirtualTourTesterRole: !with360WithoutVirtualTourTesterRole && with360,
        withLiveRoom,
    });
}

function getVirtualToursWithLiveRoom(virtualTours) {
    return _(virtualTours).thru(getAvailableTours).filter('envisiteLiveRoomUrl').value();
}

function is360VirtualTour(virtualTour) {
    return is360VirtualTourType(virtualTour.type);
}

function is360VirtualTourType(virtualTourType) {
    return /360|3d/.test(virtualTourType);
}

function isVideoVirtualTourType(virtualTourType) {
    return _.includes(['video', 'video-file'], virtualTourType);
}

function is3dProjectionVirtualTourType(type) {
    return _.includes(VIRTUAL_TOURS_3D_PROJECTION_TYPES, type);
}

function getAvailable360VirtualTours(virtualTours) {
    return _.filter(getAvailableTours(virtualTours), is360VirtualTour);
}

function isHighlightedVideoVirtualTour(virtualTour) {
    return /^video/.test(virtualTour.type) && virtualTour.highlighted;
}

function hasAvailableHighlightedVideoVirtualTour(virtualTours) {
    return _.some(getAvailableTours(virtualTours), isHighlightedVideoVirtualTour);
}

function getAvailable360VirtualToursWithoutVirtualTourTesterRole(virtualTours) {
    return _.filter(getAvailableTours(virtualTours, {checkVirtualTourTesterRole: false}), is360VirtualTour);
}

function getPreviewPictureAndText({type, previewImageUrl}, {newProperty = false} = {}) {
    let picture = DEFAULT_VISIT_PICTURE;
    let textValues = {prefix: 'Visite', text: 'Virtuelle'};
    let specialType;
    let thumbnailsUrl;
    let is3dProjection = false;
    if (is3dProjectionVirtualTourType(type)) {
        picture = THREE_D_PROJECTION_PICTURE;
        is3dProjection = true;
        thumbnailsUrl = resourceUrl(THREE_D_PROJECTION_THUMBNAILS_PICTURE);
        textValues = {prefix: 'Projetez-vous en', text: '3D'};
    } else if (is360VirtualTourType(type)) {
        picture = newProperty ? NEW_BUILD_360_PICTURE : OLD_PROPERTY_360_PICTURE;
        thumbnailsUrl = resourceUrl(VISITE_360_THUMBNAILS_PICTURE);
        textValues.text = '360°';
        specialType = 'only360';
    } else if (isVideoVirtualTourType(type)) {
        textValues.text = 'Vidéo';
        picture = VIDEO_VISITE_PICTURE;
        thumbnailsUrl = resourceUrl(VIDEO_VISITE_THUMBNAILS_PICTURE);
    }
    const pictureUrl = previewImageUrl || resourceUrl(picture);
    return {textValues, pictureUrl, specialType, is3dProjection, thumbnailsUrl};
}

function getAvailableTours(virtualTours, {checkVirtualTourTesterRole = true} = {}) {
    const isMobile = BrowserDetect.isMobile();
    return _.filter(virtualTours, function (content) {
        const isPhoto = content.fileServerUrl;
        let valid = content.accepted || (checkVirtualTourTesterRole ? Account.isVirtualTourTester() : false);
        if (content.error
            || (content.type == 'site immobilier')
            || (isMobile && !content.mobileSupport && !isPhoto)
            || (!flashAvailable && content.techno == 'flash')
        ) {
            valid = false;
        }
        return valid;
    });
}
