const template = require('./AgencyBanner.jade');
// @vue/component
module.exports = {
    props: {
        imageUrl: {
            type: String,
            required: true,
        },
        agencyName: {
            type: String,
            required: true,
        },
        modifiers: {
            type: Object,
            required: true,
        },
    },
    template: template(),
};
