const _ = require('lodash');

const BaseStat = require('./BaseStat');

const {BASE_STATS_MODES} = require('./constants');

// @vue/component
module.exports = {
    constants: {
        IS_AGGREGATED_FOR_ACCOUNT: true,
    },
    components: {
        BaseStat,
    },
    mixins: [
        require('./BaseStatsTemplate'),
    ],
    props: {
        displayStats: Boolean,
        modifiers: {
            type: Object,
            required: true,
        },
        stats: {
            type: Array,
            default: null,
        },
        buildUrlForStat: {
            type: Function,
            required: true,
        },
        mode: {
            type: String,
            default: null,
            validator: mode => _.includes(BASE_STATS_MODES, mode),
        },
    },
    methods: {
        getUrl(statsName) {
            return this.buildUrlForStat(statsName);
        },
    },
};
