const $ = require('jquery');
const _ = require('lodash');
const EventPack = require('../../../utils/EventPack');
const triggerNativeEvent = require('../../../fields/triggerNativeEvent');

// @vue/component
module.exports = {
    props: {
        noSuggestionsMessage: {
            type: String,
            required: true,
        },
    },
    mounted() {
        const datasetsConf = [
            {
                display: this.getSuggestionDisplayName ? suggestion => this.getSuggestionDisplayName(suggestion) : null,
                source: _.throttle(_.bind(this.querySuggestions, this), 300),
                templates: {
                    suggestion: _.bind(this.formatSuggestion, this),
                    empty: this.getNoSuggestionsHtml(),
                },
            },
        ];
        const $input = $(this.$refs.input);
        $input.f4typeahead({
            hint: true,
            highlight: true,
            minLength: 1,
            autoselect: 'first',
        }, datasetsConf);
        this._eventPack = new EventPack();
        this._eventPack.on($input, 'f4typeahead:selected', (e, selectedItem) => {
            if (selectedItem) { // f4typeahead was hacked to send the `selected` event even when nothing is selected
                const inputValue = this.getSuggestionValue(selectedItem);
                if (inputValue != null) {
                    triggerNativeEvent($input, 'input'); // for the component and bootstrap validator
                }
            }
        });
    },
    destroyed() {
        this._eventPack.removeAllListeners();
    },
    methods: {
        getNoSuggestionsHtml() {
            return "<div class='noSuggestions'>" + this.noSuggestionsMessage + '</div>';
        },
    },
};
