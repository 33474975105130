const SideMapView = require('../SideMapView');
const Views = require('./Views');

let instance;

module.exports = {
    get: function () {
        if (!instance) {
            instance = new SideMapView();
            Views.map = instance; //for ApplicationPage
        }
        return instance;
    },
};
