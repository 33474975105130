const ArticleCreationOrModificationPage = require('./ArticleCreationOrModificationPage');
const UrlHelper = require('../../../../common/UrlHelper');
const Error404 = require('../../../Error404');
const ArticleHelper = require('../ArticleHelper');

const PATH_PREFIX = '/modifier-article/';

module.exports = class ArticleModificationPage extends ArticleCreationOrModificationPage {
    constructor() {
        super({
            name: 'articleModificationPage',
            title: "Modifier l'article",
        });
    }

    getUrlPattern() {
        return PATH_PREFIX + ':slug*:';
    }

    getUrl() {
        return PATH_PREFIX + this.options.article.slug;
    }

    getTitle() {
        return this.options.article.title;
    }

    parseUrl(url, slug) {
        if (slug) {
            const parts = slug.split('/');
            const articleSlug = UrlHelper.slugify(parts.shift());
            return {
                articleSlug,
            };
        } else {
            return {};
        }
    }

    loadData(options, cb) {
        if (options.article) {
            ArticleHelper.fillMissingLocationData([options.article]);
            cb(null, options);
        } else if (options.articleSlug) {
            ArticleHelper.getArticleFromSlug(options.articleSlug, function (err, article) {
                if (err || !article) {
                    cb(new Error404());
                } else {
                    options.article = article;
                    cb(null, options);
                }
            });
        } else {
            cb(new Error404());
        }
    }
};
