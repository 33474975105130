const defaultBlogOptions = {
    pro: false,
    isCategoriesMenuVisibleInArticle: true,
    isSimilarArticlesBlockVisible: true,
    isArticleMainTagVisible: true,
    isArticleCloseCrossVisible: true,
    isMoreArticlesLinkVisible: true,
    moreArticleTitle: 'Voir les articles suivants dans la liste',
    sideListAdsTitle: 'Pour aller plus loin',
};
const NEWSROOM_CONTACT_EMAIL = 'presse@bienici.com';

const blogs = [
    {
        sideListTitle: 'Articles similaires',
        tags: [
            {
                name: 'Actualités et Conseils immobiliers',
                isHome: true,
                description: 'Découvrez tous nos conseils pratiques pour bien préparer votre projet immobilier, '
                    + 'que vous souhaitiez acheter, louer, vendre, investir, financer votre acquisition ou déménager.',
            },
            {
                name: 'J’explore le marché',
                description: 'Vous avez envie de vous lancer dans un projet immobilier ? Retrouvez dans cette rubrique '
                    + 'les dernières informations sur l’évolution du marché immobilier et tous nos conseils pour votre recherche '
                    + 'du bien idéal.',
                subTags: [
                    {
                        name: 'Recherche immobilière',
                        description: 'Explorez toutes nos astuces pour utiliser Bien’ici, décrypter les '
                            + 'annonces immobilières et sélectionner les biens qui correspondent à vos envies.',
                    },
                    {
                        name: 'Évolutions du marché',
                        description: 'Prix de vente, taux de crédit, nouvelles tendances de consommation, '
                            + 'les critères les plus recherchés, projets de lois… Découvrez l’actualité du marché '
                            + 'immobilier et son impact sur votre recherche immobilière.',
                    },
                    {
                        name: 'Immobilier neuf',
                        description: 'Vous souhaitez acheter un bien immobilier neuf pour y vivre ou pour '
                            + 'un investissement locatif ? Retrouvez toutes les informations à connaître '
                            + 'sur ce type d’achat immobilier.',
                    },
                    {
                        name: 'Viager',
                        description: 'Le viager est une forme de contrat de vente qui permet au vendeur '
                            + 'et à l’acheteur d’étaler les paiements sur le long terme. '
                            + 'Découvrez comment fonctionne cette transaction si particulière.',
                    },
                    {
                        name: 'Résidence secondaire',
                        description: 'Vous rêvez de profiter de la nature ou d’une vie ultra-citadine '
                            + 'mais seulement quelques mois par an ? Pourquoi ne pas envisager '
                            + 'l’acquisition d’une résidence secondaire. Voici nos conseils pour la choisir.',
                    },
                    {
                        name: 'Inspirations',
                        description: 'Habitat atypiques, quartiers en vue au sein des grandes villes'
                            + '… Élargissez votre recherche immobilière avec d’autres types de biens '
                            + 'ou de nouvelles localisations.',
                    },
                ],
            },
            {
                name: 'Je calcule mon budget',
                description: 'Que vous soyez acheteur, vendeur, locataire ou bailleur, vous ne pouvez pas négliger les '
                    + 'sujets financiers induits par votre projet immobilier. Nous avons regroupé ici tous les conseils '
                    + 'pour calculer votre budget.',
                subTags: [
                    {
                        name: 'Prêt immobilier',
                        description: 'Épargne, capacité d’achat, taux d’usure, assurance emprunteur… '
                            + 'Découvrez nos conseils pour financer au mieux votre projet d’achat immobilier.',
                    },
                    {
                        name: 'Estimation',
                        description: 'Pour vendre rapidement votre logement, il est important de fixer un prix de vente '
                            + 'cohérent avec le marché immobilier local. Découvrez comment procéder à une estimation '
                            + 'immobilière et les critères qui impactent la valeur de votre logement.',
                    },
                    {
                        name: 'Loyer',
                        description: 'Loyer TTC, charges locatives, assurance loyers impayés… Découvrez nos conseils '
                            + 'et les règlementations pour fixer et réviser le montant d’un loyer.',
                    },
                    {
                        name: 'Aides au logement',
                        description: 'Il existe de nombreuses aides pour louer ou pour acheter un logement. Retrouvez '
                            + 'l’ensemble des dispositifs existants et les conditions pour en bénéficier.',
                    },
                    {
                        name: 'Fiscalité',
                        description: 'Peu d’activités immobilières échappent à la fiscalité ! Informez-vous sur toutes '
                            + 'les taxes et déclarations concernant votre projet, d’achat, de vente, de location ou d’aménagement.',
                    },
                    {
                        name: 'Dispositifs d’investissement locatif',
                        description: 'Plusieurs dispositifs permettent de bénéficier de réductions fiscales dans le cadre '
                            + 'd’un bien mis en location. Découvrez les conditions à respecter pour profiter de ces avantages.',
                    },
                ],
            },
            {
                name: 'J’ai un projet',
                description: 'Vous avez défini votre intention d’achat, de vente ou de location ? Il est temps de '
                    + 'vous plonger dans les différentes étapes de votre projet.',
                subTags: [
                    {
                        name: 'Acheter',
                        description: 'Que ce soit la première fois ou non, le processus d’achat d’un bien immobilier '
                            + 'passe par plusieurs étapes avec chacune leurs spécificités.',
                    },
                    {
                        name: 'Vendre',
                        description: 'Diagnostics, estimation, mandat de vente… Quelles sont les démarches à effectuer '
                            + 'pour vendre son logement ?',
                    },
                    {
                        name: 'Louer',
                        description: 'Découvrez tous nos conseils concernant la location d’un bien immobilier, que vous '
                            + 'soyez locataire ou bailleur.',
                    },
                    {
                        name: 'Investir',
                        description: 'Pour sélectionner le bien adapté à votre investissement locatif et connaître les '
                            + 'points majeurs de la gestion de location, n’hésitez pas à consulter nos articles.',
                    },
                    {
                        name: 'Construire',
                        description: 'Vous souhaitez faire construire votre maison de A à Z ? Découvrez nos conseils pour '
                            + 'acheter votre terrain et trouver un constructeur de maisons individuelles.',
                    },
                ],
            },
            {
                name: 'Je passe à l’action',
                description: 'Votre projet immobilier se précise ? Apprenez-en plus sur les documents'
                    + ' (bail de location, offre d’achat, diagnostics…) qui formaliseront l’achat, la vente,'
                    + ' la location ou la mise en location de votre logement.',
                subTags: [
                    {
                        name: 'Offre d’achat et compromis de vente',
                        description: 'L’offre d’achat et le compromis de vente sont deux documents essentiels dans le cadre '
                            + 'd’une transaction immobilière. Leurs rédactions doivent respecter un certain formalisme et '
                            + 'différentes clauses peuvent être spécifiquement ajoutées.',
                    },
                    {
                        name: 'Bail de location',
                        description: 'Le bail de location est un contrat passé entre le bailleur et le locataire du logement. '
                            + 'Certaines mentions sont obligatoires et ont un impact sur l’ensemble de la durée de la location.',
                    },
                    {
                        name: 'Dossier de location',
                        description: 'Le dossier de location est une étape cruciale pour décrocher un logement. Sa composition '
                            + 'est strictement encadrée et permet au bailleur de faire son choix de locataire d’une manière juste.',
                    },
                    {
                        name: 'Diagnostics immobiliers',
                        description: 'Les diagnostics immobiliers attestent de l’état d’un logement. Ils sont obligatoires '
                            + 'pour la vente ou la location afin d’informer les futurs occupants.',
                    },
                    {
                        name: 'Location meublée',
                        description: 'Équipements obligatoires, bail et résiliation de bail, fixation du loyer, '
                            + 'déclaration de revenus… La location meublée est soumise à des règles de location spécifiques.',
                    },
                    {
                        name: 'Assurances',
                        description: 'Il existe de nombreux moyens de protéger votre logement avec des assurances adaptées. '
                            + 'Certaines sont obligatoires, d’autres vous apportent une garantie supplémentaire.',
                    },
                    {
                        name: 'Colocation',
                        description: 'La colocation est possible pour tous les profils et pour tous les âges. '
                            + 'Découvrez les informations essentielles à connaître sur ce mode de vie et d’investissement.',
                    },
                ],
            },
            {
                name: 'Je m’installe',
                description: 'Le moment est venu de vous installer chez vous ! Déménagement, décoration, économies…'
                    + ' Nous vous aidons à améliorer votre quotidien et à rendre votre logement vraiment unique, comme vous.',
                subTags: [
                    {
                        name: 'Déménagement',
                        description: 'Parce que le jour J arrive vite, retrouvez tous nos conseils pour préparer sereinement '
                            + 'votre déménagement aussi bien sur le plan financier que physique.',
                    },
                    {
                        name: 'Décoration et aménagement',
                        description: 'Besoin de transformer votre lieu de vie ? Découvrez nos idées d’aménagement et nos conseils '
                            + 'pour choisir les couleurs, les matières et les accessoires qui changeront totalement '
                            + 'votre décoration d’intérieur.',
                    },
                    {
                        name: 'Travaux et entretien du logement',
                        description: 'Qu’ils soient nécessaires ou uniquement esthétiques, les travaux sont indispensables dans '
                            + 'un logement. Du permis de construire au nettoyage du frigo, tous nos conseils d’entretien sont ici.',
                    },
                    {
                        name: 'Jardin et extérieurs',
                        description: 'Fleurs, entretien du potager, barbecue, piscine… Exploitez le potentiel de vos balcons, '
                            + 'terrasses et jardin avec nos conseils de jardinage et de décoration.',
                    },
                    {
                        name: 'Énergie, chauffage et climatisation',
                        description: 'Chauffage et électricité sont deux postes de dépenses importants dans un logement. '
                            + 'Découvrez nos conseils pour vous équiper et gérer votre consommation.',
                    },
                    {
                        name: 'Consommation et écologie',
                        description: 'Recyclage, mobilité, nouvelles tendances… Découvrez nos conseils pour adopter des '
                            + 'comportements de consommation plus écologiques et plus économiques !',
                    },
                    {
                        name: 'Domotique et sécurité',
                        description: 'Installation Wi-fi, ampoules connectées, détecteurs de fumée, caméras de surveillance… '
                            + 'Retrouvez ici toutes les informations à connaître sur les objets qui '
                            + 'facilitent le quotidien et améliorent la sécurité de votre logement.',
                    },
                ],
            },
            {
                name: 'J’ai des droits',
                description: 'Le logement est encadré par un ensemble de lois qui régissent les relations entre individus'
                    + ' (bailleur, locataire, copropriétaire, couple…) et les types de transactions '
                    + '(achat, location, transmission…).',
                subTags: [
                    {
                        name: 'Lois logement',
                        description: 'Loi Alur, loi ELAN, loi Lagleize, loi Dalo… Découvrez nos articles détaillés sur '
                            + 'les différentes lois régissant l’immobilier en France.',
                    },
                    {
                        name: 'Copropriété',
                        description: 'Chaque copropriété est soumise à un ensemble règles afin d’organiser la vie au sein '
                            + 'd’un immeuble ou d’une résidence. Charges de copropriété, assemblée générale, syndic… '
                            + 'Découvrez nos différents articles sur le sujet.',
                    },
                    {
                        name: 'Voisinage',
                        description: 'Choisir son logement, c’est également choisir ses voisins ! Comment entretenir de '
                            + 'bonnes relations et réagir en cas de troubles de voisinage ?',
                    },
                    {
                        name: 'Divorce et succession',
                        description: 'Régime de la communauté, rachat de soulte, partage d’héritage… Quels sont les '
                            + 'impacts d’un divorce ou d’un décès sur le logement ?',
                    },
                    {
                        name: 'Bailleur et locataire',
                        description: 'Bailleur et locataire ont des droits et des devoirs l’un envers l’autre et vis-à-vis '
                            + 'du logement. Maintenir un dialogue entre les parties permet d’éviter de nombreux conflits.',
                    },
                    {
                        name: 'Société civile immobilière',
                        description: 'L’achat d’un logement à plusieurs peut passer par la création d’une SCI. '
                            + 'Découvrez nos conseils de gestion pour toutes les situations.',
                    },
                ],
            },
        ],
    },
    {
        pro: true,
        sideListTitle: 'Nos autres offres',
        moreArticleTitle: 'Voir nos offres suivantes dans la liste',
        sideListAdsTitle: 'Lancez-vous !',
        tags: [
            {
                name: 'Nos offres partenaires',
                isHome: true,
                description: 'Pour mieux vous accompagner au quotidien dans la mise en avant de vos mandats, '
                    + 'Bien’ici vous propose des services innovants à des tarifs avantageux avec des partenaires exclusifs '
                    + 'dédiés aux professionnels de l’immobilier.',
            },
            {
                name: 'Vous êtes une agence',
                description: 'Pour accélérer vos ventes, promouvoir vos mandats et offrir toujours plus de '
                    + 'services à vos clients, Bien’ici vous propose des offres partenaires innovantes à des tarifs '
                    + 'très avantageux.',
                accountType: 'agency',
            },
            {
                name: 'Vous êtes un promoteur',
                description: 'Pour faciliter la mise en avant de vos programmes et permettre à vos futurs '
                    + 'acquéreurs de toujours mieux se projeter, Bien’ici vous propose des solutions innovantes issues '
                    + 'de partenaires sélectionnés.',
            },
        ],
    },
    {
        pro: true,
        sideListTitle: 'Nos autres conseils',
        moreArticleTitle: 'Voir nos conseils suivants dans la liste',
        tags: [
            {
                name: 'Nos conseils pour les pros',
                isHome: true,
                description: 'Accédez à l’essentiel de l’actualité immobilière et retrouvez tous nos conseils '
                    + 'pour bien utiliser votre espace PRO sur Bien’ici.',
            },
            {
                name: 'Actualités',
                description: 'Nouvelles mesures, bilans, études… Le secteur immobilier bouge en permanence ! '
                    + 'Retrouvez ici l’essentiel de l’actualité immobilière et suivez les évolutions du site Bien’ici.',
            },
            {
                name: 'Conseils',
                description: 'Bien’ici met à votre disposition tous les outils dont vous avez besoin pour suivre et '
                    + 'améliorer la performance de vos mandats. Retrouvez l’ensemble de nos conseils pour bien utiliser votre '
                    + 'espace PRO et tirer le meilleur parti de votre forfait de diffusion.',
            },
        ],
    },
    {
        sideListTitle: 'Nos bons plans',
        moreArticleTitle: 'Voir nos bons plans dans la liste',
        tags: [
            {
                name: 'Bons plans',
                isHome: true,
                description: 'Découvrez les offres bons plans de nos partenaires pour vous accompagner dans tous ' +
                    'vos projets immobiliers. Que vous souhaitiez financer votre achat, réaliser des travaux, organiser ' +
                    'votre déménagement ou changer de fournisseur d’énergie, Bien’ici vous propose une sélection de ' +
                    'professionnels à votre service.',
            },
        ],
    },
    {
        sideListTitle: 'Articles similaires',
        tags: [
            {
                name: 'Pourquoi investir dans le neuf\u202f?',
                isHome: true,
                description: 'Investir dans l’immobilier neuf permet de répondre à différents objectifs personnels, ' +
                    'de la constitution d’un patrimoine à la préparation de sa retraite. ' +
                    'Bien’ici vous aide à déterminer l’objectif principal de votre investissement immobilier ' +
                    'grâce aux articles ci-dessous.',
            },
        ],
    },
    {
        isCategoriesMenuVisibleInArticle: false,
        isSimilarArticlesBlockVisible: false,
        isArticleMainTagVisible: false,
        isArticleCloseCrossVisible: false,
        isMoreArticlesLinkVisible: false,
        requiresBloggerRole: true,
        tags: [
            {
                name: 'Articles indépendants',
                isHome: true,
                description: 'Ci-dessous tous les articles indépendants qui ne contiennent aucun lien ni menu '
                    + 'lorsqu’ils sont ouverts.',
            },
            {
                name: 'template landing',
                description: 'Utilisation du template de landing page classique',
            },
            {
                name: 'template acquisition',
                description: 'Utilisation du template de landing page inversé',
            },
        ],
    },
    {
        isSimilarArticlesBlockVisible: false,
        tags: [
            {
                name: 'Newsroom',
                isHome: true,
                description: 'Vous êtes journaliste ou souhaitez simplement en savoir plus sur Bien’ici\u202f?<br/>' +
                    'Retrouvez ici nos communiqués de presse et les dernières retombées.<br/>' +
                    '<div style="padding-top:10px;font-size:16px;line-height:22px">Contact presse\u00a0: ' +
                    `<a style="text-decoration: underline" href="mailto:${NEWSROOM_CONTACT_EMAIL}">${NEWSROOM_CONTACT_EMAIL}</a>` +
                    '</div>',
            },
        ],
    },
];

module.exports = {
    defaultBlogOptions,
    blogs,
};
