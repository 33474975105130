const template = require('./PoiCategory.jade');

// @vue/component
module.exports = {
    props: {
        category: {
            type: Object,
            default: () => {
                return {};
            },
        },
        mainCategory: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isSelected: this.category.selected || false,
        };
    },
    computed: {
        activeClass() {
            return this.isSelected ? 'active' : '';
        },
        iconClass() {
            return this.isSelected ? 'fa fa-check-square-o' : 'fa fa-square-o';
        },
        bgColor() {
            const {color} = this.category;
            return {'background-color': color};
        },
    },
    methods: {
        toggleCategory() {
            this.isSelected = !this.isSelected;
            this.$emit('category-selected', {
                id: this.category.id,
                value: this.isSelected,
                mainCategory: this.mainCategory,
                mainClass: this.category.class,
                classes: this.category.classes,
            });
        },
    },
    template: template(),
};
