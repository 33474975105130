const _ = require('lodash');
const async = require('async');
const {EventEmitter} = require('events');
const {i18n: {translate}} = require('fack');

const template = require('../templates/account/accountSelectorWithPreview.jade');
const Account = require('../authentication/Account');
const renderTemplate = require('../views/utils/renderTemplate');
const {insertUserInfoView} = require('../views/UserInfoView');

module.exports = class AccountSelectorWithPreview extends EventEmitter {
    constructor(currentAccountId, $container, translationBasePath = '') {
        super();
        this.currentAccountId = currentAccountId;
        this.$element = renderTemplate(template, {translationBasePath});
        $container.append(this.$element);
        this.$accountSelectorPreview = this.$element.find('.accountSelectorPreview');
        this.$accountSearchWord = this.$element.find('input[name="accountSearchWord"]');
        this.$accountSearchWord.on('change input', _.bind(this._checkSelectedAccount, this));
        this.translationBasePath = translationBasePath;
    }

    _checkSelectedAccount() {
        const accountSearchWord = this._getAccountSearchWord();
        this.emit('loading');
        this.selectedId = null;
        if (!accountSearchWord) {
            this._selectedAccountErrorFeedback('accountSelectorError_empty');
        } else {
            const {$accountSelectorPreview} = this;
            $accountSelectorPreview.html('<i class="md md-rotate-right md-spin"></i>');
            this._findAccount(accountSearchWord, (err, account) => {
                if (this._getAccountSearchWord() === accountSearchWord) { //answer to current query
                    if (err) {
                        this._selectedAccountErrorFeedback(err);
                    } else if (!account) {
                        this._selectedAccountErrorFeedback('accountSelectorError_noMatch');
                    } else if (account.id === this.currentAccountId) {
                        this._selectedAccountErrorFeedback('accountSelectorError_sameAsCurrent');
                    } else {
                        this.selectedId = account.id;

                        $accountSelectorPreview.empty();
                        insertUserInfoView($accountSelectorPreview, {
                            user: account,
                            hideActionsLinks: true,
                        });
                        this.emit('ready');
                    }
                }
            });
        }
    }

    _findAccount(search, cb) {
        async.parallel({
            name: cb => {
                Account.find({
                    search,
                    'is-pro': true,
                    closed: false,
                    disabled: false,
                    size: 1,
                }, (err, body) => {
                    if (err) {
                        cb(err);
                    } else {
                        cb(null, _.first(body.accounts));
                    }
                });
            },
            id: cb => {
                Account.get(search, (err, account) => {
                    if (err && err.code === 404) {
                        err = null;
                        account = null;
                    }
                    cb(err, account);
                });
            },
        }, (err, results) => {
            if (err) {
                cb(err);
            } else {
                const {name, id} = results;
                cb(null, id || name);
            }
        });
    }

    _selectedAccountErrorFeedback(error) {
        let text = translate(`${this.translationBasePath}${error}`, {defaultValue: ''});
        if (!text) {
            text = translate(error, {defaultValue: error});
        }
        this.$accountSelectorPreview.html('<span class="error">' + text + '</span>');
    }

    _getAccountSearchWord() {
        return this.$accountSearchWord.val().trim();
    }

    getSelectedId() {
        return this.selectedId;
    }
};
