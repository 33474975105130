const _ = require('lodash');
const $ = require('jquery');
const {i18n: {translate: t}} = require('fack');
const mercatorProjection = require('mercatorProjection');

const Account = require('../authentication/Account');
const MapApi = require('../MapApi');
const CircleZoneHelper = require('../../common/CircleZoneHelper');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');

const geoLocationModalsTemplate = require('./geoLocationModals.jade');

const blueColor = '#1A7EE6';

const CIRCLE_OPTIONS = {
    strokeColor: '#000',
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: blueColor,
    fillOpacity: 0.1,
    zIndex: 3,
};

const FIRST_CIRCLE_RADIUS = 10;
const FIRST_CIRCLE = {
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: blueColor,
    fillOpacity: 1,
    zIndex: 11,
};

const SECOND_CIRCLE_RADIUS = 12;
const SECOND_CIRCLE = {
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: '#fff',
    fillOpacity: 1,
    zIndex: 10,
};

const THIRD_CIRCLE_RADIUS = 20;
const THIRD_CIRCLE = {
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: blueColor,
    fillOpacity: 0.4,
    zIndex: 5,
};

const WAIT_DURATION_WATCH = 5000;
const SEARCH_RADIUS = 500;
const MIN_ACCURACY = 250;
const MAX_ACCURACY = 3000;
const POSITION_NOT_PRECISE = 4;
const GEOLOCATION_NOT_SUPPORTED = 5;
let screenWidth = 4096;
let screenHeight = 4096;

module.exports = {
    geolocate,
    saveGeoLocation,
    createGeoJSONCircle,
    createGeolocationCircles,
    abortGeolocation,
    isFatalError,
    scaleGeolocationCircles,
    onResize,
};

let currentGeolocate = null;

function onResize(width, height) {
    screenWidth = width;
    screenHeight = height;
}

function abortGeolocation() {
    if (currentGeolocate) {
        currentGeolocate.abort(true);
    }
}

function geolocate(options, callback) {
    abortGeolocation();
    currentGeolocate = {
        abort: cancel,
    };
    let $element = null;
    let $errorModal = null;
    let $retryButton = null;
    let $exitButton = null;
    let idListening = null;
    let isAppended = false;
    let bestCircleZone = null;
    let timeout = null;
    const handleAbort = {aborted: false};
    if (navigator.geolocation) {
        timeout = setTimeout(forcedTimeout, WAIT_DURATION_WATCH); // Fixes IE/IOS/Firefox timeout not always triggered
        idListening = navigator.geolocation.watchPosition(successListening, errorListening, {
            enableHighAccuracy: true,
            timeout: WAIT_DURATION_WATCH,
            maximumAge: 0,
        });
    } else {
        onErrorGeoLocate({code: GEOLOCATION_NOT_SUPPORTED}, null);
    }

    function cancel(isAborted) {
        currentGeolocate = null;
        clearForcedTimeout();
        handleAbort.aborted = true;
        clearWatchPosition();
        removePopup();
        if (options.onAbortCallback && isAborted) {
            options.onAbortCallback();
        }
    }

    function retry() {
        cancel();
        geolocate(options, callback);
    }

    function forcedTimeout() {
        if (!handleAbort.aborted && bestCircleZone) {
            clearWatchPosition();
            showResultsWithBestAccuracy(bestCircleZone);
        }
    }

    function clearForcedTimeout() {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    }

    function errorListening(err) {
        if (handleAbort.aborted) {
            return;
        }
        if (bestCircleZone && err.code == err.TIMEOUT) {
            showResultsWithBestAccuracy(bestCircleZone);
        } else {
            onErrorGeoLocate(err, bestCircleZone);
        }
    }

    function successListening(geoPosition) {
        if (handleAbort.aborted) {
            return;
        }

        const circleZone = createCircleZone(geoPosition);
        if (geoPosition.coords.accuracy <= MIN_ACCURACY) {
            showResults(circleZone);
        } else if (!bestCircleZone) {
            bestCircleZone = circleZone;
        } else if (bestCircleZone.accuracy > circleZone.accuracy) {
            bestCircleZone = circleZone;
        }
    }

    function onErrorGeoLocate(error, circleZone) {
        clearForcedTimeout();
        clearWatchPosition();
        let errorKeyText = 'error';
        switch (error.code) {
            case GEOLOCATION_NOT_SUPPORTED:
                errorKeyText = 'GeolocationNotSupported';
                break;
            case POSITION_NOT_PRECISE:
                errorKeyText = 'PositionNotPrecise';
                break;
            case error.PERMISSION_DENIED:
                errorKeyText = 'PermissionDenied';
                break;
            case error.POSITION_UNAVAILABLE:
                errorKeyText = 'PositionUnavailable';
                break;
            case error.TIMEOUT:
                errorKeyText = 'Timeout';
                break;
        }
        let text = t('geoLocation.' + errorKeyText, {context: UserAgentHelper.isFromNativeApp() ? 'app' : ''});
        const errorGeolocation = new Error('geoLocation.' + errorKeyText);
        errorGeolocation.isGeolocation = true;
        appendPopup(errorGeolocation, circleZone, cancel);
        if (circleZone) {
            let accuracy = circleZone.accuracy;
            if (accuracy > 999) {
                accuracy = Math.round(accuracy / 1000);
                text += ' (' + accuracy + 'km)';
            } else {
                accuracy = Math.round(accuracy);
                text += ' (' + accuracy + 'm)';
            }
            $retryButton.show();
        }
        if ($errorModal) {
            $errorModal.find('.modal-text span').text(text);
            $errorModal.modal('show');
        }
    }

    function showResultsWithBestAccuracy(circleZone) {
        if (circleZone.accuracy <= MAX_ACCURACY) {
            showResults(circleZone);
        } else {
            onErrorGeoLocate({code: POSITION_NOT_PRECISE}, circleZone);
        }
    }

    function showResults(circleZone) {
        cancel();
        callback(null, circleZone, cancel);
    }

    function appendPopup(error, circleZone, cancel) {
        if (!isAppended) {
            $element = $(geoLocationModalsTemplate({
                t,
            }));
            $errorModal = $element.find('.errorModal');
            $errorModal.modal({
                backdrop: 'static',
                show: false,
            });
            $('body').append($element);
            $errorModal.on('hidden', hideModal);
            $retryButton = $errorModal.find('.retryButton').on('click', retry);
            $exitButton = $errorModal.find('.exitButton').on('click', () => {
                callback(error, circleZone, cancel);
            });
            $retryButton.hide();
            isAppended = true;

        }

        function hideModal() {
            $(this).data('modal', null);
        }
    }

    function removePopup() {
        if (isAppended) {
            isAppended = false;
            $errorModal.modal('hide');
            $errorModal.off('hidden.bs.modal');
            $errorModal.remove();
            $retryButton.off('click', retry);
            $exitButton.off('click', cancel);
            $element.remove();
        }
    }

    function createCircleZone(geoPosition) {
        return {
            radius: SEARCH_RADIUS,
            point: {
                lon: geoPosition.coords.longitude,
                lat: geoPosition.coords.latitude,
            },
            accuracy: geoPosition.coords.accuracy,
        };
    }

    function clearWatchPosition() {
        if (idListening != null) {
            navigator.geolocation.clearWatch(idListening);
            idListening = null;
        }
    }
}

function scaleCircle(circle, radiusInPixel, projection) {
    const position = circle.getCenter();
    const radius = getRadiusFromMeter(position, radiusInPixel, projection);
    if (radius != null) {
        const map = circle.getMap();
        circle.setMap(null);
        circle.setRadius(radius);
        circle.setMap(map);
    }
}

function scaleGeolocationCircles(circles, projection) {
    if (circles && circles.length == 4) {
        scaleCircle(circles[1], THIRD_CIRCLE_RADIUS, projection);
        scaleCircle(circles[2], SECOND_CIRCLE_RADIUS, projection);
        scaleCircle(circles[3], FIRST_CIRCLE_RADIUS, projection);
    }
}

function createGeolocationCircles(circleZone, projection) {
    const position = new MapApi.api.LatLng(circleZone.point.lat, circleZone.point.lon);
    const circles = [];
    circles.push(new MapApi.api.Circle(_.extend({
        center: position,
        radius: circleZone.accuracy,
    }, CIRCLE_OPTIONS)));
    circles.push(new MapApi.api.Circle(_.extend({
        center: position,
        radius: 1,
        useWorker: false,
    }, THIRD_CIRCLE)));
    circles.push(new MapApi.api.Circle(_.extend({
        center: position,
        radius: 1,
        useWorker: false,
    }, SECOND_CIRCLE)));
    circles.push(new MapApi.api.Circle(_.extend({
        center: position,
        radius: 1,
        useWorker: false,
    }, FIRST_CIRCLE)));
    scaleGeolocationCircles(circles, projection);
    return circles;
}

function getRadiusFromMeter(position, nbrPixels, projection) {
    const posInPixel = projection.fromLatLngToContainerPixel(position);
    posInPixel.x -= nbrPixels;
    if (posInPixel.x < 0 || posInPixel.y < 0 || posInPixel.x > screenWidth || posInPixel.y > screenHeight) {
        return null;
    }
    const newLatLng = projection.fromContainerPixelToLatLng(posInPixel);
    const radius = MapApi.api.geometry.spherical.computeDistanceBetween(position, newLatLng);
    const lat900913 = mercatorProjection.convertLat4326To900913(position.lat());
    return radius / CircleZoneHelper.getScaleFromLatitude(lat900913);
}

function createGeoJSONCircle(lon, lat, radius, sides, rotation) {
    sides = sides || 100;
    rotation = rotation || 0;
    const lon900913 = mercatorProjection.convertLon4326To900913(lon);
    const lat900913 = mercatorProjection.convertLat4326To900913(lat);
    const scale = Math.cosh(Math.PI * lat900913 / mercatorProjection.MAX_EXTENT);
    const lonRadius = Math.abs(lon - mercatorProjection.convertLon900913To4326(lon900913 - radius)) * scale;
    const latRadius = Math.abs(lat - mercatorProjection.convertLat900913To4326(lat900913 - radius)) * scale;
    let angle = Math.PI * ((1 / sides) - (1 / 2));
    if (rotation) {
        angle += (rotation / 180) * Math.PI;
    }
    const points = [];
    for (let i = 0; i < sides; ++i) {
        const rotatedAngle = angle + (i * 2 * Math.PI / sides);
        points.push([lon + (lonRadius * Math.cos(rotatedAngle)), lat + (latRadius * Math.sin(rotatedAngle))]);
    }
    return {
        type: 'Polygon',
        coordinates: [points],
    };
}

function saveGeoLocation(circleZone, callback) {
    return Account.postJson({
        url: '/saveCircleZone',
        timeout: 30000, //30 seconds
        data: {
            options: circleZone,
        },
        serverErrorMessage: 'saveGeoLocation',
        callback,
    });
}

function isFatalError(err) {
    return (err && (err.code == GEOLOCATION_NOT_SUPPORTED || err.code == err.PERMISSION_DENIED));
}
