const _ = require('lodash');
const {resourceUrl} = require('fack');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {TrackingClasses} = require('../../stats/TrackingClasses');
const template = require('./DocumentBlock.jade');

const DOCUMENT_IMAGE_SIZE = '150px';

// @vue/component
module.exports = {
    constants: {
        DOCUMENTATION_IMAGE: resourceUrl('images/documentation.png'),
    },
    mixins: [
        i18nMixin({
            keys: [
                'title',
                'buttonText',
                'imageAlternativeText',
            ],
        }),
    ],
    props: {
        propertyType: {
            type: String,
            default: undefined,
        },
        contentType: {
            type: String,
            required: true,
        },
        specialType: {
            type: String,
            default: undefined,
        },
        openContactFormBeforeDisplayingContent: Boolean,
    },
    computed: {
        hasNoContent() {
            return this.contentType === 'nothing';
        },
        trackingClass() {
            let action;
            if (this.hasNoContent) {
                action = 'ask';
            } else {
                action = 'open';
            }
            return TrackingClasses[_.camelCase(`${action}-${this.specialType}`)];
        },
        documentImgStyle() {
            return {width: DOCUMENT_IMAGE_SIZE};
        },
    },
    methods: {
        emitClickToGetDocument() {
            this.$emit('click-to-get-document', {
                openContactFormBeforeDisplayingContent: this.openContactFormBeforeDisplayingContent,
            });
        },
        t(key) {
            const {specialType} = this;
            if (key === 'buttonText') {
                key = (this.hasNoContent) ? 'askForDocumentation' : 'showDocument';
            }
            return this.translate(`documents.documentBlock.${key}`, {
                context: specialType,
                realEstateAdContext: this.propertyType,
            });
        },
    },
    template: template(),
};
