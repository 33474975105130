const _ = require('lodash');
const $ = require('jquery');
const Account = require('../authentication/Account');
const CompositeVueView = require('../vue/CompositeVueView');
const ScrollHelper = require('../utils/ScrollHelper');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const SavedSearches = require('../search/SavedSearches');
const Title = require('../pages/Title');
const NumberFormatter = require('../../common/NumberFormatter');
const History = require('../utils/History');
const currentPageTemplate = require('./currentPage.jade');
const SearchListModeSelectionHeaderView = require('../search/SearchListModeSelectionHeaderView');
const {onPreferenceOpen, onSideMenuStateChanged} = require('../utils/NativeInterfaceProxy');
const EventPack = require('../utils/EventPack');
const savedOptions = require('../Options').read();
const SwipeHelper = require('../utils/SwipeHelper');
const ApplicationConfig = require('../app/ApplicationConfig');
const BlogHelper = require('../blog/BlogHelper');
const Referrers = require('../app/Referrers');
const {i18n: {translate}} = require('fack');
const Urls = require('../Urls');
const regionalPartners = require('../../common/data/regionalPartners');
const embeddedHeaderTemplate = require('./embeddedHeaderTemplate.jade');
const BrowserDetect = require('browser-detect');
const ServerConfig = require('../ServerConfig');
const {IMPORT_ACCOUNT_TYPE} = require('../../common/AccountTypes');
const {PUBLIC_BLOG_PATH} = require('../../common/data/blogData');

const MARGIN_RIGHT_AFTER_PARTNER_LOGO_IN_PX = 20;
const PARTNER_IMG_MIN_WIDTH_IN_PX = 10;
const MAX_NOTIFICATION_COUNT_TO_DISPLAY = 100;

const SIDE_MENU_OPEN_BODY_CLASS = 'applicationSideMenuOpen';

const $body = $('body');

module.exports = class HeaderView extends CompositeVueView {
    constructor(options) {
        super({
            template: options.headerTemplate,
        });
        this.logoAlwaysEnabled = options.logoAlwaysEnabled;
        this._onAdvancedSearchCbk = (filters) => this.onAdvancedSearch(filters);
        this.headerTitleEnabled = options.headerTitleEnabled;
        this.saveSearchAllowed = false;
        this.isShowRoom = Account.isShowRoom();
        this.dataModeListEnabled = options.dataModeListEnabled;
        this.allowNativePrefs = Boolean(onPreferenceOpen);
        this._eventPack = new EventPack();
        this._eventPackWhileModeSelectionShown = new EventPack();
        this.b2bUrl = options.b2bUrl;
        this.vueData = {
            isRegistered: Account.isRegistered(),
        };
    }

    createElement(options) {
        options = options || {};
        this.options = options;
        this.$container = $('header');
        this.handleAccountChange();
        this.renderHeaderTitle({
            headerTitle: options.headerTitle || '',
            isEditable: options.searchFiltersFromHeaderEnabled,
        });
        return this.$element;
    }

    handleAccountChange() {
        this.renderHeader(this.options);
        this.closeSideMenu();
        this.toggleUserInfos();
        this.vueData.isRegistered = Account.isRegistered();
    }

    _showPopupsIfNeeded() {
        // Implemented by child
    }

    bindListeners() {
        this._findAndSetPartnerLogoBehaviorWhenExisting();
        const eventPack = this._eventPack;
        eventPack.on(Account, {
            change: _.bind(this.handleAccountChange, this),
            'change:id': _.bind(this._showPopupsIfNeeded, this),
        });
        eventPack.on(PageManager, 'open', (page) => this.onPageChanged(page));
        eventPack.on(SavedSearches, 'savedSearchesCountChanged', () => this._updateNotificationTotal());
        const advancedSearchView = this.advancedSearchView;
        if (advancedSearchView) {
            eventPack.on(advancedSearchView, 'search', this._onAdvancedSearchCbk);
        }
    }

    _findAndSetPartnerLogoBehaviorWhenExisting() {
        const $partnerImg = $('.partnerImg');
        if ($partnerImg.length > 0) {
            if ($partnerImg.prop('complete')) {
                this.setPartnerImgBehavior($partnerImg);
            } else {
                $partnerImg.css({
                    width: 1,
                });
                this._eventPack.on($partnerImg, 'load', () => {
                    this.setPartnerImgBehavior($partnerImg);
                });
            }
        }
    }

    addAgencyLogo(author) {
        const logoAgencySrc = Account.getAccountImageUrl(author, 200, 40);
        if (!ApplicationConfig.applicationPro && logoAgencySrc && !BrowserDetect.isMobile()
            && (author.accountType == IMPORT_ACCOUNT_TYPE || author.displayLogoOnSearchPage)) {
            this.$partnerLogo =
                $("<span class='partnerWith'>avec</span><img class='partnerImg' src='" + logoAgencySrc + "'/></span>");
            $('#logo').append(this.$partnerLogo);
        }
    }

    removeAgencyLogo() {
        const $partnerLogo = this.$partnerLogo;
        if ($partnerLogo) {
            $partnerLogo.remove();
        }
    }

    setPartnerImgBehavior($partnerImg) {
        const imgElement = $partnerImg.get(0);
        const partnerImgRatio = imgElement.naturalWidth / imgElement.naturalHeight;
        this.updatePartnerImgSizeAndPosition($partnerImg, partnerImgRatio);
        _.defer(() => { // fixes safari 10 small logo
            this.updatePartnerImgSizeAndPosition($partnerImg, partnerImgRatio);
        });
        this._eventPack.on($(window), 'resize', () => {
            this.updatePartnerImgSizeAndPosition($partnerImg, partnerImgRatio);
        });
    }

    updatePartnerImgSizeAndPosition($partnerImg, partnerImgRatio) {
        const windowWidth = $(window).width();
        const headerHeight = $('.section-header').outerHeight();
        const isMobile = BrowserDetect.isMobile();
        const marginTopPartnerLogo = isMobile ? MARGIN_RIGHT_AFTER_PARTNER_LOGO_IN_PX : 30;
        const widthThatIsAlreadyUsedInHeader =
            $('.headerLogo img:not(.partnerImg)').outerWidth(true)
            + $('.partnerWith').outerWidth(true)
            + MARGIN_RIGHT_AFTER_PARTNER_LOGO_IN_PX
            + (isMobile ? (2 * $('.headerBtn').outerWidth(true)) : $('.actual-content').width());
        const partnerImgMaxWidth = (headerHeight - marginTopPartnerLogo) * partnerImgRatio;
        const partnerImgWidth = Math.max(
            Math.min(windowWidth - widthThatIsAlreadyUsedInHeader, partnerImgMaxWidth),
            PARTNER_IMG_MIN_WIDTH_IN_PX
        );
        const partnerImgHeight = partnerImgWidth / partnerImgRatio;
        $partnerImg.css({
            width: partnerImgWidth + 'px',
            marginTop: (headerHeight - partnerImgHeight) / 2 + 'px',
        });
    }

    onPageChanged(page) {
        this._findAndSetPartnerLogoBehaviorWhenExisting();
        this.closeSideMenu();
        const scrollWindowToTopOnOpen = page.scrollWindowToTopOnOpen;
        if (scrollWindowToTopOnOpen === true || (scrollWindowToTopOnOpen == 'anchor' && !History.getHash())) {
            ScrollHelper.scrollToTop();
        }
        if (this.advancedSearchView && page.name === 'home') {
            this.advancedSearchView.show(page.options);
        }
    }

    updateActiveLinkPro(selectedHeaderItem) {
        _.each(this.$element.find('a[data-active]'), function (element) {
            const $link = $(element);
            const shouldToggle = _.includes($link.attr('data-active').split(','), selectedHeaderItem);
            $link.toggleClass('active', shouldToggle);
        });
    }

    show(options = {}) {
        this.saveSearchAllowed = options.saveSearchAllowed;
        const view = this;
        // @vue/component
        const vueOptions = {
            data() {
                return view.vueData;
            },
        };
        super.show(_.extend({
            b2bUrl: ServerConfig.config.b2bUrl,
        }, options), vueOptions);
        const advancedSearchView = this.advancedSearchView;
        if (advancedSearchView) {
            advancedSearchView.show(options);
        }
        const isInEmbeddedMode = savedOptions.isInEmbeddedMode;
        if (isInEmbeddedMode || ApplicationConfig.isOnPartnersDomain) {
            this.addBackToHomeBtn(isInEmbeddedMode ? location.href : '/');
        }
        this.bindListeners();
        this.bindButtons();
        this.bindSwipe();
        this._updateNotificationTotal();
        this._showPopupsIfNeeded();
    }

    _cancelHasPasswordRequest() {
        const pendingCallback = this._pendingCallback;
        if (pendingCallback) {
            pendingCallback.cancel();
            this._pendingCallback = null;
        }
    }

    _updateNotificationTotal() {
        const count = SavedSearches.getTotalSavedSearchesCount();
        this.setNotifications(count);
        Title.setPrefix(count > 0 ? '(' + getLabelForNotificationCount(count) + ')' : null);
    }

    setNotifications(value) {
        this.$element.find('.userCardNotifications')
            .text(getLabelForNotificationCount(value))
            .toggle(value > 0);
    }

    update(options) {
        options = options || {};
        if (this.advancedSearchView) {
            this.closeAdvancedSearchView();
        }
        this.saveSearchAllowed = options.saveSearchAllowed;
        this.renderHeaderTitle({
            headerTitle: options.headerTitle || '',
            isEditable: options.saveSearchAllowed,
        });
    }

    search(searchCriteria) {
        const advancedSearchView = this.advancedSearchView;
        if (advancedSearchView) {
            advancedSearchView.search(searchCriteria);
        }
    }

    updateAlert() {
        if (this.alertButtonVueData) {
            this.alertButtonVueData.disableRemoveAlertButton = false;
        }
    }

    onAdvancedSearch(filters) {
        const openOptions = {search: filters};
        PageManager.openPage(PagesFactories.searchPageFactory, openOptions);
    }

    setAdvancedSearchView(advancedSearchView) {
        this.advancedSearchView = advancedSearchView;
    }

    renderHeaderTitle(options) {
        const previousHeaderTitleOptions = this.headerTitleOptions;
        const hasChanged = !_.isMatch(previousHeaderTitleOptions, options);
        const isAdvancedSearchViewOpen = _.invoke(this.advancedSearchView, 'isOpen');
        if (this.headerTitleEnabled && hasChanged && !isAdvancedSearchViewOpen) {
            const newHeaderTitleOptions = _.extend(previousHeaderTitleOptions, options);
            this.headerTitleOptions = newHeaderTitleOptions;
            const {headerTitle, isClosable} = newHeaderTitleOptions;
            const $element = this.$element;
            const $currentPage = $element.find('.currentPage');
            $currentPage
                .removeClass('visible')
                .empty()
                .hide();
            const logoAlwaysEnabled = this.logoAlwaysEnabled;
            if (headerTitle) {
                if (!logoAlwaysEnabled) {
                    $element.find('#logo a').hide();
                    const $newCurrentPageContent = $(currentPageTemplate(newHeaderTitleOptions));
                    $currentPage
                        .append($newCurrentPageContent)
                        .css('display', 'inline-block')
                        .addClass('visible');
                    const advancedSearchView = this.advancedSearchView;
                    this.alertButtonVueData = {
                        disableRemoveAlertButton: false,
                        searchCriteria: advancedSearchView ? advancedSearchView.searchCriteria : {},
                    };
                    // @vue/component
                    const vueOptions = {
                        data: () => this.alertButtonVueData,
                        methods: {
                            saveSearchClick: () => {
                                this.emit('saveSearchClick');
                            },
                            removeAlertClick: (matchingSavedSearchId) => {
                                this.emit('removeAlertClick', matchingSavedSearchId);
                            },
                            goToAlertPage: function (matchingSavedSearchId) {
                                PageManager.openPage(PagesFactories.savedSearchAdsPageFactory, {
                                    savedSearch: {
                                        _id: matchingSavedSearchId,
                                    },
                                });
                            },
                        },
                    };
                    this.injectVueViews($newCurrentPageContent, vueOptions);
                }
            } else if (!logoAlwaysEnabled) {
                $element.find('#logo a').show();
            }
            $element.find('.backHomeBtn').toggle(Boolean(!isClosable && headerTitle));
        }
    }

    addBackToHomeBtn(href) {
        if (!this.$backToHomeBtn) {
            const $backToHomeBtn = this.renderTemplate(embeddedHeaderTemplate, {
                href,
            });
            this.$backToHomeBtn = $backToHomeBtn;
            this.$container.append($backToHomeBtn);
            $body.addClass('backBtnEnabled');
        }
    }

    showSearchListModeSelectionView(searchListModeModel) {
        this.hideSearchListModeSelectionView();
        const searchListModeSelectionView = new SearchListModeSelectionHeaderView({
            searchListModeModel,
            dataModeListEnabled: this.dataModeListEnabled,
        });
        this.searchListModeSelectionView = searchListModeSelectionView;
        this._eventPackWhileModeSelectionShown.on(searchListModeSelectionView, 'selectMode',
            (mode) => this.emit('selectSearchListMode', mode));
        searchListModeSelectionView.$container = this.$element.find('.searchListModeSelectionContainer');
        searchListModeSelectionView.show();
    }

    hideSearchListModeSelectionView() {
        const searchListModeSelectionView = this.searchListModeSelectionView;
        if (searchListModeSelectionView) {
            searchListModeSelectionView.hide();
            this._eventPackWhileModeSelectionShown.removeAllListeners();
            this.searchListModeSelectionView = null;
        }
    }

    toggleUserInfos() {
        let userName;
        let userImgUrl;
        if (Account.isRegistered() && !this.isShowRoom) {
            const authenticatedAccount = Account.getAuthenticatedAccount();
            userName = authenticatedAccount.display_name;
            userImgUrl = Account.getAccountImageUrl(authenticatedAccount);
        }
        this.toggleUserName(userName);
        this.toggleUserPicture(userImgUrl);
    }

    toggleUserName(name) {
        this.$element
            .find('.userCard>.userInfoName')
            .text(name)
            .css('display', name ? 'inline-block' : 'none');
    }

    toggleUserPicture(userImgUrl) {
        const placeholder = userImgUrl ? '' : "<i class='md md-2x md-account-circle'></i>";
        const background = userImgUrl ? `url('${userImgUrl}')` : 'none';
        this.$element
            .find('.userCard>.userInfoPict')
            .css('background-image', background)
            .html(placeholder);
    }

    renderHeader(options) {
        this.isShowRoom = Account.isShowRoom();
        const referrers = Referrers.getSessionReferrers();
        const partner = _.first(_.intersection(referrers, _.map(regionalPartners, _.toLower)));
        const $header = this.renderTemplate(this.template, _.defaults({}, options, {
            isShowRoom: this.isShowRoom,
            partner,
            showRoomLogo: this.isShowRoom && Account.getAccountImageUrl(Account.getAuthenticatedAccount()),
            allowNativePrefs: this.allowNativePrefs,
            hasToDisplayFavorites: this.options && this.options.hasToDisplayFavorites,
            canSeeOptions: Account.hasRole('betaTester') && !this.isShowRoom,
            isPro: ApplicationConfig.applicationPro,
            _t: (key, options) => {
                const context = ApplicationConfig.applicationPro ? 'pro' : 'public';
                return translate('applicationHeader.' + key, _.extend({context}, options));
            },
            showMenuButton: !savedOptions.isInEmbeddedMode && !ApplicationConfig.isOnPartnersDomain,
            showOwnedAccountLink: this.showOwnedAccountLink(),
            Account,
            registeredPartnerOffersLink: this.getRegisteredPartnerOffersLink(),
            franceDesignEnabled: savedOptions.franceDesignEnabled,
            christmasDesignEnabled: savedOptions.christmasDesignEnabled,
            halloweenDesignEnabled: savedOptions.halloweenDesignEnabled,
            rugbyDesignEnabled: savedOptions.rugbyDesignEnabled,
            homePageUrl: Urls.homePage.makeUrl(),
            canSeeProspectingMap: _.get(Account.getAuthenticatedAccount(), 'canSeeProspectingMap'),
            canAccessAdsNotificationBoostsHistoryPage: Account.canDisplayAdsNotificationBoostsHistory(),
            canEnablePublicPage: Account.canEnablePublicPage(),
            PUBLIC_BLOG_PATH,
        }));
        if (this.$element) {
            // header already exists ; only update account dependent content
            $header.find('.accountDependentContent').replaceAll(this.$element.find('.accountDependentContent'));
        } else {
            this.$element = $header;
        }
        this._updateNotificationTotal();
    }

    showOwnedAccountLink() {
        const ownedAccounts = Account.getAuthenticatedAccountOwnedAccounts();
        return !Account.hasOnlyClosedOrDisabledOwnedAccounts(ownedAccounts);
    }

    openSideMenu() {
        this.toggleSideMenu(true);
    }

    closeSideMenu() {
        this.toggleSideMenu(false);
    }

    isSideMenuOpen() {
        return $body.hasClass(SIDE_MENU_OPEN_BODY_CLASS);
    }

    toggleSideMenu(show) {
        $body
            .toggleClass('applicationSideMenuClose', !show)
            .toggleClass(SIDE_MENU_OPEN_BODY_CLASS, show);
        if (onSideMenuStateChanged) {
            onSideMenuStateChanged(show);
        }
        this.$element
            .find('.closeNavigationDrawer')
            .toggleClass('closeNavigationDrawerAnimate', show);
    }

    hasAdvancedSearchView() {
        return Boolean(this.advancedSearchView);
    }

    isAdvancedSearchViewOpen() {
        return this.hasAdvancedSearchView()
            && this.advancedSearchView.isOpen();
    }

    closeAdvancedSearchView() {
        const searchListModeSelectionView = this.searchListModeSelectionView;
        if (searchListModeSelectionView && searchListModeSelectionView.isShown()) {
            searchListModeSelectionView.$element.show();
        }
        this.advancedSearchView.close();
        this.doCssStuff(false);
        this.renderHeaderTitle(_.extend({}, this.lastHeaderTitleOptions, {isClosable: false}));
        this.emit('editFiltersClosed');
    }

    openAdvancedSearchView() {
        this.emit('editFiltersOpened'); // Should be sent before changing CSS because scrollList height is Saved with this event in searchPage
        const searchListModeSelectionView = this.searchListModeSelectionView;
        if (searchListModeSelectionView && searchListModeSelectionView.isShown()) {
            searchListModeSelectionView.$element.hide();
        }
        this.lastHeaderTitleOptions = _.clone(this.headerTitleOptions);
        this.renderHeaderTitle({
            headerTitle: 'Ma recherche',
            isEditable: false,
            isClosable: true,
        });
        this.advancedSearchView.open();
        this.doCssStuff(true);
        ScrollHelper.scrollToTop();
    }

    doCssStuff(open) {
        $('.mainPageContainer').toggle(!open);
        $body.toggleClass('advancedSearch', open);
    }

    bindButtons() {
        const click = {
            '.openSideMenu': _.bind(this.openSideMenu, this),
            '.navigationDrawerBackground': _.bind(this.closeSideMenu, this),
            '.closeNavigationDrawer': _.bind(this.closeSideMenu, this),
            '.linkToLogout': () => {
                const $btn = this.$element.find('#btn-user-info');
                $btn.addClass('disabled');
                Account.forgetAuthentication(() => {
                    $btn.removeClass('disabled');
                });
            },
        };
        const advancedSearchView = this.advancedSearchView;
        if (advancedSearchView) {
            _.extend(click, {
                '#resetSearch': _.bind(this._resetSearch, this),
                '.closeEditFilters': () => {
                    this.closeAdvancedSearchView();
                    this.closeSideMenu();
                },
                '.openEditFilters': () => {
                    this.openAdvancedSearchView();
                    this.closeSideMenu();
                },
            });
        }
        if (this.allowNativePrefs) {
            _.extend(click, {
                '.showPrefs': onPreferenceOpen,
            });
        }
        this._eventPack.on(this.$element, {
            click,
        });
    }

    bindSwipe() {
        const $element = this.$element;
        const $navigationDrawerMenu = $element.find('.navigationDrawerMenu');
        this._eventPack.on($element, {
            touchstart: _.partial(SwipeHelper.touchStart, $navigationDrawerMenu),
            touchmove: event => {
                if (this.menuShouldSwipe(event)) {
                    SwipeHelper.touchMove(event);
                }
            },
            touchend: _.partial(SwipeHelper.touchEnd, _.bind(this.closeSideMenu, this)),
        }, '.navigationDrawerBackground,.navigationDrawerScrollableContainer');
    }

    menuShouldSwipe(event) {
        return $body.hasClass('applicationSideMenuOpen') && SwipeHelper.shouldMoveMenu(event);
    }

    _resetSearch() {
        this.closeSideMenu();
        this.openAdvancedSearchView();
        this.advancedSearchView.resetSearch();
    }

    getSectionHeaderElement() {
        return this.$container.find('.section-header');
    }

    getRegisteredPartnerOffersLink() {
        const mainBlogTag = 'nos-offres-partenaires';
        const account = Account.getAuthenticatedAccount();
        const blogTag = account && BlogHelper.getProBlogTagWithFilters(mainBlogTag, {accountType: account.accountType});
        let link = '/' + mainBlogTag;
        if (blogTag) {
            link += '/' + blogTag;
        }
        return link;
    }
};

function getLabelForNotificationCount(count) {
    return count > MAX_NOTIFICATION_COUNT_TO_DISPLAY
        ? MAX_NOTIFICATION_COUNT_TO_DISPLAY + '+'
        : NumberFormatter.formatNumber(count);
}
