const _ = require('lodash');
const TextFormatter = require('../../../common/TextFormatter');
const Views = require('../../views/Views');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {sendTestEmail} = require('..');
const {red, green} = require('../../colors');
const template = require('./ResultCard.jade');

const TEST_EMAIL_FEEDBACK_TRANSLATION_PREFIX = 'emailStatus.sendTestEmailFeedback.';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ResultCard.',
            keys: [
                'statusTitle',
                'accountLinksTitle',
                'accountLinksTitleNotFound',
                'sendTestEmailText',
                'softBounceCounterTitle',
                _(['title', 'pitch', 'message'])
                    .map(translationKey => [
                        `confirmationStatusMail.${translationKey}`,
                        `confirm${_.startCase(translationKey)}`,
                    ])
                    .fromPairs()
                    .value(),
            ],
        }),
    ],
    props: {
        email: {
            type: String,
            required: true,
        },
        status: {
            type: Object,
            default: undefined,
        },
        accountIds: {
            type: Array,
            default: undefined,
        },
    },
    data() {
        return {
            modalDisplayed: false,
        };
    },
    computed: {
        date() {
            return TextFormatter.formatDetailedDate(_.get(this.lastEvent, 'date'));
        },
        hasValidStatus() {
            return !this.status.error;
        },
        lastEvent() {
            const {lastErrorEvent, lastSuccessEvent} = this.status;
            return _.maxBy([lastErrorEvent, lastSuccessEvent], 'date');
        },
        isEmailAddressOk() {
            return this.hasValidStatus && (this.lastEvent === this.status.lastSuccessEvent);
        },
        successiveSoftBounces() {
            if (!this.isEmailAddressOk) {
                return this.status.successiveSoftBounces;
            } else {
                return undefined;
            }
        },
        textColor() {
            const {statusKey} = this;
            if (statusKey === 'ok') { // Not a constant because it will be removed with BEM
                return green;
            } else if (statusKey === 'invalid') { // Not a constant because it will be removed with BEM
                return red;
            } else {
                return '';
            }
        },
        statusModifiers() {
            return [this.statusKey];
        },
        statusKey() {
            if (!this.hasValidStatus) {
                return this.status.error;
            } else if (this.isEmailAddressOk) {
                return 'ok';
            } else if (this.status.isInvalid) {
                return 'invalid';
            } else {
                return 'softBounce';
            }
        },
        statusText() {
            return this.t('status.' + this.statusKey).toUpperCase();
        },
        dateTitle() {
            return this.t(this.isEmailAddressOk ? 'lastSuccessDateTitle' : 'lastErrorDateTitle');
        },
        eventDetailTitle() {
            return this.t(this.isEmailAddressOk ? 'successEventTypeTitle' : 'errorMotiveTitle');
        },
        eventDetail() {
            const {lastEvent: {event, relevantError}} = this;
            if (this.isEmailAddressOk) {
                return this.t('successEventType.' + event.event);
            } else {
                return relevantError || event.error;
            }
        },
        modalErrorTranslationKey() {
            return `${TEST_EMAIL_FEEDBACK_TRANSLATION_PREFIX}error`; // todo: find a better way to customize error message
        },
        isAccountIdsEmpty() {
            return _.isEmpty(this.accountIds);
        },
    },
    methods: {
        showModal() {
            this.modalDisplayed = true;
        },
        sendTestEmail(cb) {
            sendTestEmail(this.email, err => {
                if (!err) {
                    Views.volatileFeedback.showSuccess(`${TEST_EMAIL_FEEDBACK_TRANSLATION_PREFIX}success`);
                }
                cb(err);
            });
        },
        getAccountLink(accountId) {
            return `/statistiques/${accountId}`;
        },
        getAccountSourcesText(sources) {
            return _.map(sources, source => this.t(`accountSources.${source}`)).join(', ');
        },
    },
    template: template(),
};
