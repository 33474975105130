const template = require('./OnlineBookingButton.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'OnlineBookingButton.',
            keys: [
                'text',
                'shortText',
            ],
        }),
    ],
    props: {
        url: {
            type: String,
            required: true,
        },
        short: Boolean,
    },
    computed: {
        formattedText() {
            return this.short ? this.shortText : this.text;
        },
    },
    template: template(),
};
