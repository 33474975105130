const $ = require('jquery');

module.exports = {
    startButtonSpinner,
    stopButtonSpinner,
    toggleButtonSpinner,
};

function startButtonSpinner($button) {
    toggleButtonSpinner($button, true);
}

function stopButtonSpinner($button) {
    toggleButtonSpinner($button, false);
}

function toggleButtonSpinner($button, enabled) {
    $button.find('i').remove();
    if (enabled) {
        $button.append($('<i>').addClass('md md-rotate-right md-spin'));
    }
    $button
        .toggleClass('disabled', enabled)
        .attr('disabled', enabled);
}
